/* @flow */
import { firebase } from '../system/Firebase';
import {
  SIGNIN_METHOD,
  PROVIDER_GOOGLE,
  PROVIDER_FACEBOOK,
  PROVIDER_APPLE
} from '../profile/constants';
import {
  STRIPE_LIFETIME,
  STRIPE_MONTHY,
  STRIPE_YEARLY
} from '../shop/constants';
import _ from 'lodash';
import {
  isMobile,
  redirectWithPromo,
  redirectToStripe,
  toggleSignUpForm,
  getEmailLoginError,
  toggle
} from './';
import { store } from '..';
import { isUserSubscribed } from '../profile/selectors';

export const handleAfterLogin = async (
  history: Object,
  itemInfo: Object,
  unitIdBussiness: number,
  targetLangCode: string,
  nativeLangCode: string,
  fetchUnitData: Function,
  toggleCheckout: Function
) => {
  const state = store.getState();
  const isProUser = isUserSubscribed(state);
  if (unitIdBussiness === 42) {
    fetchUnitData(targetLangCode, nativeLangCode, 42, () => {
      history.push('/unit/42');
    });
  } else {
    if (!_.isEmpty(itemInfo)) {
      toggleCheckout();
    } else {
      history.push(isProUser ? '/home' : '/shop');
    }
  }
};

export const signInWithGoogleRedirect = (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    firebase.auth().signInWithRedirect(new firebase.auth.GoogleAuthProvider());
    resolve(true);
  });
};

export const signInWithFbRedirect = (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithRedirect(new firebase.auth.FacebookAuthProvider());
    resolve(true);
  });
};

export const signInWithAppleRedirect = (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithRedirect(new firebase.auth.OAuthProvider('apple.com'));
    resolve(true);
  });
};

export const loginGoogleRedirect = () => {
  // eslint-disable-next-line
  localStorage.setItem(SIGNIN_METHOD, PROVIDER_GOOGLE);
  signInWithGoogleRedirect();
};

export const loginFbRedirect = () => {
  // eslint-disable-next-line
  localStorage.setItem(SIGNIN_METHOD, PROVIDER_FACEBOOK);
  signInWithFbRedirect();
};

export const loginAppleRedirect = () => {
  // eslint-disable-next-line
  localStorage.setItem(SIGNIN_METHOD, PROVIDER_APPLE);
  signInWithAppleRedirect();
};

export const handleSignupWithEmail = (
  signMsg: any[],
  isProUser: boolean,
  history: Object,
  receipt: string,
  productName: string,
  isDev: boolean,
  currency: string,
  functions: string,
  updateUserData: Function,
  user: Object,
  itemInfo: any[],
  redeemId: string,
  email_error_alreadyUsed: string,
  email_error_userNotFound: string,
  email_error_wrongPassword: string,
  stateChangeHandler: Function
) => {
  if (signMsg[0]) {
    if (isProUser && !isMobile) {
      history.push('/');
    } else if (!_.isEmpty(receipt)) {
      redirectWithPromo(`${productName}`, isDev, receipt);
    } else {
      redirectToStripe(
        currency,
        functions,
        updateUserData,
        history,
        isDev,
        user,
        itemInfo,
        redeemId,
        productName
      );
    }
  } else {
    stateChangeHandler(
      'errorMsgFirebase',
      getEmailLoginError(
        signMsg[1],
        email_error_alreadyUsed,
        email_error_userNotFound,
        email_error_wrongPassword
      ).message
    );
  }
};

export const toggleChildCreateAccountLanguagePicker = (child: any) => {
  child.toggleCreateAccountLanguagePicker();
};

export const toggleChildCreateAccount = (
  productName: string,
  child: any,
  productId: string,
  currencySymbol: Object,
  price: number,
  usdPrice: number,
  promoDetail: Object
) => {
  if (
    productName === STRIPE_MONTHY ||
    productName === STRIPE_YEARLY ||
    productName === STRIPE_LIFETIME
  ) {
    child.toggleCreateAccount(
      productId,
      productName,
      (price !== 0 ? price : usdPrice) / 100,
      currencySymbol.code
    );
  } else {
    child.toggleCreateAccount(
      productId,
      productName,
      promoDetail.value,
      currencySymbol.code
    );
  }
};

export const googleLogin = async (
  signInWithGoogle: Function,
  fetchUnitData: Function,
  targetLangCode: string,
  nativeLangCode: string,
  toggleCheckout: Function,
  history: Object,
  itemInfo: Object,
  unitIdBussiness: number,
  isLoginModalOpen: boolean,
  toggleLoginModal: Function,
  isBusinessSignUp: boolean,
  setIsBusinessSignUp: Function,
  pauseDialog: Function,
  modal: boolean,
  stateChangeHandler: Function
) => {
  if (await signInWithGoogle()) {
    toggle(
      isLoginModalOpen,
      toggleLoginModal,
      isBusinessSignUp,
      setIsBusinessSignUp,
      pauseDialog,
      modal,
      stateChangeHandler
    );
    handleAfterLogin(
      history,
      itemInfo,
      unitIdBussiness,
      targetLangCode,
      nativeLangCode,
      fetchUnitData,
      toggleCheckout
    );
  }
};

export const fbLogin = async (
  signInWithFacebook: Function,
  fetchUnitData: Function,
  targetLangCode: string,
  nativeLangCode: string,
  toggleCheckout: Function,
  history: Object,
  itemInfo: Object,
  unitIdBussiness: number,
  isLoginModalOpen: boolean,
  toggleLoginModal: Function,
  isBusinessSignUp: boolean,
  setIsBusinessSignUp: Function,
  pauseDialog: Function,
  modal: boolean,
  stateChangeHandler: Function
) => {
  if (await signInWithFacebook()) {
    toggle(
      isLoginModalOpen,
      toggleLoginModal,
      isBusinessSignUp,
      setIsBusinessSignUp,
      pauseDialog,
      modal,
      stateChangeHandler
    );
    handleAfterLogin(
      history,
      itemInfo,
      unitIdBussiness,
      targetLangCode,
      nativeLangCode,
      fetchUnitData,
      toggleCheckout
    );
  }
};

export const appleLogin = async (
  signInWithApple: Function,
  fetchUnitData: Function,
  targetLangCode: string,
  nativeLangCode: string,
  toggleCheckout: Function,
  history: Object,
  itemInfo: Object,
  unitIdBussiness: number,
  isLoginModalOpen: boolean,
  toggleLoginModal: Function,
  isBusinessSignUp: boolean,
  setIsBusinessSignUp: Function,
  pauseDialog: Function,
  modal: boolean,
  stateChangeHandler: Function
) => {
  if (await signInWithApple()) {
    toggle(
      isLoginModalOpen,
      toggleLoginModal,
      isBusinessSignUp,
      setIsBusinessSignUp,
      pauseDialog,
      modal,
      stateChangeHandler
    );
    handleAfterLogin(
      history,
      itemInfo,
      unitIdBussiness,
      targetLangCode,
      nativeLangCode,
      fetchUnitData,
      toggleCheckout
    );
  }
};

export const onLogin = async (
  signInWithEmailAndPassword: Function,
  email_error_alreadyUsed: string,
  email_error_userNotFound: string,
  email_error_wrongPassword: string,
  toggleCheckout: Function,
  targetLangCode: string,
  nativeLangCode: string,
  fetchUnitData: Function,
  history: Object,
  user: Object,
  isLoginModalOpen: boolean,
  toggleLoginModal: Function,
  isBusinessSignUp: boolean,
  setIsBusinessSignUp: Function,
  pauseDialog: Function,
  itemInfo: Object,
  unitIdBussiness: number,
  modal: boolean,
  stateChangeHandler: Function
) => {
  const { email, password } = user;
  stateChangeHandler('isLoading', true);
  stateChangeHandler('errorMsgFirebase', '');

  const signMsg = await signInWithEmailAndPassword(email, password);

  if (signMsg[0]) {
    toggle(
      isLoginModalOpen,
      toggleLoginModal,
      isBusinessSignUp,
      setIsBusinessSignUp,
      pauseDialog,
      modal,
      stateChangeHandler
    );
    handleAfterLogin(
      history,
      itemInfo,
      unitIdBussiness,
      targetLangCode,
      nativeLangCode,
      fetchUnitData,
      toggleCheckout
    );
  } else {
    stateChangeHandler(
      'errorMsgFirebase',
      getEmailLoginError(
        signMsg[1],
        email_error_alreadyUsed,
        email_error_userNotFound,
        email_error_wrongPassword
      ).message
    );
    stateChangeHandler('isLoading', false);
  }
};

export const closeEmailSent = (
  isResetPassword: boolean,
  stateChangeHandler: Function
) => {
  stateChangeHandler('isEmailSent', false);
  toggleSignUpForm('isResetPassword', isResetPassword, stateChangeHandler);
};

export const toggleCreateAccount = (
  itemInfo: Object,
  productName: string,
  price: number,
  currency: number,
  isLoginModalOpen: boolean,
  toggleLoginModal: Function,
  isBusinessSignUp: boolean,
  setIsBusinessSignUp: Function,
  pauseDialog: Function,
  modal: boolean,
  stateChangeHandler: Function
) => {
  stateChangeHandler('itemInfo', itemInfo);
  stateChangeHandler('productName', productName);
  stateChangeHandler('price', price);
  stateChangeHandler('currency', currency);
  toggle(
    isLoginModalOpen,
    toggleLoginModal,
    isBusinessSignUp,
    setIsBusinessSignUp,
    pauseDialog,
    modal,
    stateChangeHandler
  );
};

export const toggleCreateAccountBusiness = (
  unitIdBussiness: number,
  unitId: number,
  isLoginModalOpen: boolean,
  toggleLoginModal: Function,
  isBusinessSignUp: boolean,
  setIsBusinessSignUp: Function,
  pauseDialog: Function,
  modal: boolean,
  stateChangeHandler: Function
) => {
  unitIdBussiness = unitId;
  toggle(
    isLoginModalOpen,
    toggleLoginModal,
    isBusinessSignUp,
    setIsBusinessSignUp,
    pauseDialog,
    modal,
    stateChangeHandler
  );
};

export const onSignUpWithEmail = async (
  isBusinessSignUp: boolean,
  setSignUpBusinessSuccess: Function,
  fetchUnitData: Function,
  targetLangCode: string,
  nativeLangCode: string,
  history: Object,
  email_error_alreadyUsed: string,
  email_error_userNotFound: string,
  email_error_wrongPassword: string,
  isLoginModalOpen: boolean,
  toggleLoginModal: Function,
  setIsBusinessSignUp: Function,
  pauseDialog: Function,
  modal: boolean,
  itemInfo: Object,
  unitIdBussiness: number,
  signUpWithEmail: Function,
  toggleCheckout: Function,
  user: Object,
  stateChangeHandler: Function
) => {
  const { name, email, password } = user;
  stateChangeHandler('isLoading', true);
  stateChangeHandler('errorMsgFirebase', '');
  const signMsg = await signUpWithEmail(name, email, password);
  if (signMsg[0]) {
    toggle(
      isLoginModalOpen,
      toggleLoginModal,
      isBusinessSignUp,
      setIsBusinessSignUp,
      pauseDialog,
      modal,
      stateChangeHandler
    );
    handleAfterLogin(
      history,
      itemInfo,
      unitIdBussiness,
      targetLangCode,
      nativeLangCode,
      fetchUnitData,
      toggleCheckout
    );
  } else {
    stateChangeHandler(
      'errorMsgFirebase',
      getEmailLoginError(
        signMsg[1],
        email_error_alreadyUsed,
        email_error_userNotFound,
        email_error_wrongPassword
      ).message
    );
    stateChangeHandler('isLoading', false);
  }
};
