/* @flow */
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import LanguageContainer from './common/containers/LanguageContainer';
import AppContainer from './AppContainer';
import BugTracker from './util/BugTracker';
import ClaimContainer from './common/containers/ClaimContainer';
import React, { Component } from 'react';
import LessonsSceneContainer from './lessons/containers/LessonsSceneContainer';
import GameScreenContainer from './games/containers/GameScreenContainer';
import ShopSceneContainer from './shop/containers/ShopSceneContainer';
import ThankYouSceneContainer from './shop/containers/ThankYouSceneContainer';
import ChatbotsSceneContainer from './chatbots/containers/ChatbotsSceneContainer';
import ConversationSceneContainer from './chatbots/containers/ConversationSceneContainer';
import ProfileSceneContainer from './profile/containers/ProfileSceneContainer';
import ReviewContainer from './lessons/containers/ReviewContainer';
import VocabReviewContainer from './lessons/containers/VocabReviewContainer';
import GrammarReviewContainer from './lessons/containers/GrammarReviewContainer';
import DialogReviewContainer from './lessons/containers/DialogReviewContainer';
import AchievementSceneContainer from './profile/containers/AchievementSceneContainer';
import LeaderSceneContainer from './profile/containers/LeaderSceneContainer';
import SettingSceneContainer from './profile/containers/SettingSceneContainer';
import ManageSubscriptionSceneContainer from './profile/containers/ManageSubscriptionSceneContainer';
import ReactGA from 'react-ga';
import { initializeSmartLook } from './util/Analytic';
import AffiliateContainer from './common/containers/AffiliateContainer';
import PurchaseScene from './purchase/components/PurchaseScene';
import HomeScene from './components/scenes/HomeScene/HomeScene';
import SignUpPage from './games/components/sub-components/SignUpPage';
import PlanPreparationContainer from './onboarding/components/PlanPreparationScreen';
import PaywallRoot from './onboarding/components/PaywallComponents/PaywallRoot';

type Props = {};

export default class RouterComponent extends Component<Props> {
  componentDidMount() {
    try {
      initializeSmartLook();
      ReactGA.pageview(window.location.pathname);
    } catch (e) {
      BugTracker.notify(e);
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // if (this.props.location) {
    // console.log(`loca: ${this.props.location}`);
    // }
  }

  render() {
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <div className="App">
          <AppContainer>
            <Route
              exact
              path="/unit/:id/lesson/:lessonId"
              component={GameScreenContainer}
            />
            <Route
              exact
              path="/unit/:id/exam"
              component={GameScreenContainer}
            />
            <Route exact path="/tutorial" component={HomeScene} />
            <Route exact path="/randomTest" component={GameScreenContainer} />
            <Route
              exact
              path="/unit/:id/speaking"
              component={GameScreenContainer}
            />
            <Route
              exact
              path="/unit/:id/writing"
              component={GameScreenContainer}
            />
            <Route path="/signup" component={SignUpPage} />
            <Route exact path="/unit/:id" component={LessonsSceneContainer} />
            <Route path="/shop" exact component={ShopSceneContainer} />
            <Route path="/purchase" exact component={PurchaseScene} />
            <Route path="/promo_shop" exact component={ShopSceneContainer} />
            <Route path="/chatbot" exact component={ChatbotsSceneContainer} />
            <Route path="/learn" exact render={() => <Redirect to="/" />} />
            <Route
              path="/payment/:type/:receipt"
              exact
              component={ThankYouSceneContainer}
            />
            <Route
              path="/payment/:type/:receipt/:promoName"
              exact
              component={ThankYouSceneContainer}
            />
            <Route path="/thanks" exact component={ThankYouSceneContainer} />
            <Route path="/" exact component={HomeScene} />
            <Route
              path="/achievementList"
              exact
              component={AchievementSceneContainer}
            />
            <Route path="/home" exact component={HomeScene} />
            <Route path="/profile" exact component={ProfileSceneContainer} />
            <Route path="/languagepicker" exact component={LanguageContainer} />
            <Route path="/review" exact component={ReviewContainer} />
            <Route path="/vocabReview" exact component={VocabReviewContainer} />
            <Route
              path="/grammarReview"
              exact
              component={GrammarReviewContainer}
            />
            <Route path="/setting" exact component={SettingSceneContainer} />
            <Route
              path="/dialogReview"
              exact
              component={DialogReviewContainer}
            />

            <Route path="/leader" exact component={LeaderSceneContainer} />
            <Route
              path="/subscription"
              exact
              component={ManageSubscriptionSceneContainer}
            />
            <Route
              path="/conversation/:id"
              exact
              component={ConversationSceneContainer}
            />
            <Route path="/claim" exact component={ClaimContainer} />
            <Route
              path="/claim/:type/:receipt"
              exact
              component={ClaimContainer}
            />
            <Route path="/affiliate/" exact component={AffiliateContainer} />
            <Route path="/price/" exact component={AffiliateContainer} />
            <Route
              path="/affiliate/:type/:receipt"
              exact
              component={AffiliateContainer}
            />
            <Route path="/paywall" exact component={PaywallRoot} />
            <Route
              path="/planPreparation"
              exact
              component={PlanPreparationContainer}
            />
          </AppContainer>
        </div>
      </BrowserRouter>
    );
  }
}
