/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import { PREFIX_HEADER, WHITE, GREY } from '../common/constants';
import { CheckButton } from './components/sub-components';
import { BUTTON_CONTINUE } from './constants';

type Props = {
  pressCloseMessage: Function,
  motivatedMgsText: string,
  btn_continue: string
};

type State = {
  imageIsReady: boolean
};

const MotivatedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-item: center;
  justify-content: center;
  height: 100vh;
`;

const MotivatedMsgText = styled.p`
  font-size: 16px;
  background: ${WHITE};
  position: relative;
  border: solid 1px ${GREY};
  padding: 20px 40px;
  border-radius: 10px;

  &:before {
    content: '';
    position: absolute;
    top: calc(100% - 10px);
    left: 50%;
    height: 20px;
    width: 20px;
    background: ${WHITE};
    transform: translateX(-50%) rotate(45deg);
    ${'' /* transform: translateX(-50%); */}

    border-bottom: inherit;
    border-right: inherit;
    box-shadow: inherit;
  }
`;

const lingHelloImg = `${PREFIX_HEADER}master-ling-hi-circle-image.svg`;

class MotivatedMessageCard extends React.Component<Props, State> {
  constructor(props: Object) {
    super(props);
    this.state = {
      imageIsReady: false
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-undef
    const img = new Image();
    img.src = lingHelloImg;
    img.onload = () => {
      // when it finishes loading, update the component state
      this.setState({ imageIsReady: true });
    };
    // $flow-disable-line
    document.addEventListener('keydown', this.handleEnter);
  }
  componentWillUnmount() {
    // $flow-disable-line
    document.removeEventListener('keydown', this.handleEnter);
  }
  handleEnter = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { pressCloseMessage } = this.props;
    if (event.keyCode === 13 && pressCloseMessage) {
      pressCloseMessage();
    }
  };
  render() {
    const {
      pressCloseMessage,
      btn_continue,
      motivatedMgsText = "Don't give up! You're still learning!"
    } = this.props;
    const { imageIsReady } = this.state;

    if (imageIsReady) {
      return (
        <React.Fragment>
          <MotivatedContainer>
            <MotivatedMsgText>{motivatedMgsText}</MotivatedMsgText>
            <img src={lingHelloImg} alt={lingHelloImg} />
          </MotivatedContainer>
          <CheckButton
            onPressNext={pressCloseMessage}
            mode={BUTTON_CONTINUE}
            btn_continue={btn_continue}
            gameType={''}
            answeredWrong={false}
          />
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

export { MotivatedMessageCard };
