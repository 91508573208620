import _ from 'lodash';
import fp from 'lodash/fp';
import {
  FLASHCARD,
  QUIZ,
  TRANSLATEGAME,
  MATCHING,
  CORRECT_SENTENCE,
  ORDER_SENTENCE,
  ORDER_SENTENCE_REVERSE,
  DIALOGUE,
  SPEAKING,
  WRITING,
  LISTENING
} from '../constants';

const introductionBaseOrder: Object[] = [
  {
    gameType: FLASHCARD,
    wordId: 0
  },
  {
    gameType: FLASHCARD,
    wordId: 1
  },
  {
    gameType: QUIZ,
    wordId: 0
  },
  {
    gameType: FLASHCARD,
    wordId: 2
  },
  {
    gameType: QUIZ,
    wordId: 1
  },
  {
    gameType: MATCHING
  },
  {
    gameType: FLASHCARD,
    wordId: 3
  },
  {
    gameType: FLASHCARD,
    wordId: 4
  },
  {
    gameType: QUIZ,
    wordId: 2
  },
  {
    gameType: FLASHCARD,
    wordId: 5
  },
  {
    gameType: MATCHING
  }
];

const mainBaseOrder: Object[] = [
  {
    gameType: LISTENING,
    wordId: 3
  },
  {
    gameType: ORDER_SENTENCE_REVERSE,
    sentenceId: 0
  },
  {
    gameType: ORDER_SENTENCE_REVERSE,
    sentenceId: 2
  },
  {
    gameType: ORDER_SENTENCE,
    sentenceId: 3
  },
  {
    gameType: ORDER_SENTENCE,
    sentenceId: 5
  },
  {
    gameType: QUIZ,
    wordId: 4
  },
  {
    gameType: QUIZ,
    wordId: 5
  },
  {
    gameType: TRANSLATEGAME,
    sentenceId: 1
  },
  {
    gameType: CORRECT_SENTENCE,
    sentenceId: 4
  },
  {
    gameType: CORRECT_SENTENCE,
    sentenceId: 5
  }
];

export const getGameOrder = (
  wordIds: string[],
  sentenceIds: string[],
  dialogueIds: string[]
) => {
  const initialGame = getInitialGames(wordIds);
  const mainGame = getMainGames(wordIds, sentenceIds);
  return [
    ...initialGame,
    ...mainGame,
    {
      gameType: DIALOGUE
    }
  ];
};

const getMainGames = (wordIds, sentenceIds) => {
  return mainBaseOrder
    .map(({ gameType, wordId, sentenceId }) => ({
      gameType,
      phraseId: wordId !== undefined ? wordIds[wordId] : sentenceIds[sentenceId]
    }))
    .filter(gameValid);
};

const getInitialGames = wordIds => {
  const initialGames = introductionBaseOrder
    .map(({ gameType, wordId }) => {
      const newGame: Object = {
        gameType
      };
      if (wordId !== undefined) {
        newGame.phraseId = wordIds[wordId];
      }
      return newGame;
    })
    .filter(gameValid);

  const lastMatching = _.findLast(
    initialGames,
    ({ gameType }) => gameType === MATCHING
  );
  if (lastMatching) {
    lastMatching.showGrammar = true;
  }
  return initialGames;
};

const gameValid = ({ gameType, phraseId }) => gameType === MATCHING || phraseId;

export const newGameOrderWhenAnsweredWrong = (
  gameOrder: Object[],
  nextScreen: number,
  currentAnswerWrong: boolean = false
) => {
  if (currentAnswerWrong) {
    const pastGames = _.take(gameOrder, nextScreen);
    const dialogues = _.takeRightWhile(
      gameOrder,
      ({ gameType }) => gameType === DIALOGUE
    );

    return _.concat(
      pastGames,
      fp.compose(
        fp.shuffle,
        fp.concat(fp.last(pastGames)),
        fp.slice(nextScreen, gameOrder.length - dialogues.length)
      )(gameOrder),
      dialogues
    );
  }
  return gameOrder;
};

export const getExamOrder = (
  wordIds: string[],
  sentenceIds: string[],
  dialogueIds: string[]
) => {
  return getExamMainGames(wordIds, sentenceIds);
};

export const getSpeakingOrder = (wordIds: string[], sentenceIds: string[]) => {
  const words = _.take(_.shuffle(wordIds), 4);
  const sentences = _.take(_.shuffle(sentenceIds), 4);
  return getSpeakingMainGames(_.concat(words, sentences));
};

const getSpeakingMainGames = wordIds => {
  return fp.compose(fp.shuffle, pushGame(wordIds, SPEAKING))([]);
};

export const getWritingOrder = (characterIds: string[]) => {
  return getWritingMainGames(characterIds);
};

const getWritingMainGames = (characterIds: Array<string>) => {
  // const sentences = fp.compose(fp.chunk(2), fp.shuffle, fp.clone)(sentenceIds);
  return fp.compose(pushGame(characterIds, WRITING))([]);
};

const getExamMainGames = (wordIds, sentenceIds) => {
  const sentences = fp.compose(fp.chunk(2), fp.shuffle, fp.clone)(sentenceIds);
  return fp.compose(
    fp.shuffle,
    pushGame(sentences[3], CORRECT_SENTENCE),
    pushGame(sentences[2], TRANSLATEGAME),
    pushGame(sentences[1], ORDER_SENTENCE_REVERSE),
    pushGame(sentences[0], ORDER_SENTENCE),
    pushGame(sampleTwo(wordIds), LISTENING)
  )([]);
};

const sampleTwo = ids => fp.sampleSize(2, ids);

export const pushGame = fp.curry(
  (ids: Array<string>, gameType: string, oldGameOrder: Array<any>) =>
    _.reduce(
      ids,
      (gameOrder, phraseId) => [
        ...gameOrder,
        {
          gameType: gameType,
          phraseId
        }
      ],
      oldGameOrder
    )
);
