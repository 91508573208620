/* eslint-disable max-lines */
/* @flow */
import React, { Component, Fragment } from 'react';
import type {
  ClaimSceneProps as Props,
  ClaimSceneState as State
} from './ClaimScene.types';
import styles, {
  Container,
  ContentContainer,
  ModalBodyText,
  FormContainer,
  FormHeader,
  WarningText,
  ModalContentContainer,
  Logo,
  Title,
  InputLabel,
  ModalBodyContainer,
  ContinueContainer,
  InputGroupContainer,
  SpinnerContainer,
  LoginLink,
  InputPlace,
  LoginStyle,
  ResetPasswordContainer,
  ComfirmButton,
  BottomText,
  SignUpSuggestionStyle,
  SocialButton,
  ErrorTextStyle,
  InputErrorTextStyle,
  ResetPasswordImg,
  ForgotTextStyle,
  LoginButtonContainer,
  GiftFireworkStyle,
  HeaderTitle,
  CheckoutFormContainer,
  CheckoutContainer,
  LeftEmptySpace
} from './ClaimScene.styles';
import { ModalBody, InputGroup } from 'reactstrap';
import _ from 'lodash';
import axios from 'axios';
import stripe from '../../util/PurchaseUtils';
import { FormHeader as CheckoutFormHeader } from '../../shop/components/sub-components/FormHeader';
import {
  getCurrency,
  findCurrency,
  getLocalStorage,
  setLocalStorage,
  isMobile,
  handleGTMDataLayerClassComponent,
  removePromoLocalStorage,
  LOCAL_STORAGE_CONSTANTS,
  getPriceAfterDiscount
} from '../../util';
import { setUTM, getUTM } from '../../util/UtmHelper';
import { isValidEmail } from '../../common/actions/AuthActions';
import { getUrlParam } from '../../common/actions';
import { firebase } from '../../system/Firebase';
import { fetchRemoteDatabase } from '../../lessons/sagas';
import {
  IMAGE_PATH,
  PREFIX_HEADER,
  PREFIX_LESSON,
  CURRENCY,
  CARD_PAID,
  CARD_SUCCEEDED,
  PROMO_NAME_LIST,
  UTM_CAMPAIGN_USERS_CANCELED,
  LING_WEB_BASE_URL
} from '../../common/constants';
import {
  SIGNIN_METHOD,
  PROVIDER_GOOGLE,
  PROVIDER_FACEBOOK,
  PROVIDER_APPLE,
  PROVIDER_EMAIL
} from '../../profile/constants';
import {
  STRIPE_MONTHY,
  STRIPE_YEARLY,
  STRIPE_LIFETIME,
  PRODUCT_TYPE,
  PROMO_NAME,
  ID
} from '../../shop/constants';
import BugTracker from '../../util/BugTracker';
import { CheckoutForm } from '../../shop/components/main-components/CheckoutForm/CheckoutForm';
import Analytic from '../../util/Analytic';
import { getProductCurrencySymbol } from '../../util/ProductHelper';
import { sendAmplitudeData } from '../../util/Amplitude';
const couponAPI = 'usedCoupons';

const CNX_LANGUAGE_EXCHANGE = 'cnxlanguageexchange';

const redirect = (product: string, isDev: string, currency: string) => {
  const { utm_source, utm_medium, utm_campaign } = getUTM();
  console.log('Is Dev is', isDev);
  if (product === STRIPE_MONTHY) {
    Analytic.logGA(Analytic.key.A12, {
      category: Analytic.key.A12,
      action: 'Check',
      label: currency
    });
  } else if (product === STRIPE_YEARLY) {
    Analytic.logGA(Analytic.key.A13, {
      category: Analytic.key.A13,
      action: 'Check',
      label: currency
    });
  } else if (product === STRIPE_LIFETIME) {
    Analytic.logGA(Analytic.key.A14, {
      category: Analytic.key.A14,
      action: 'Check',
      label: currency
    });
  }
  const paymentPath = `${LING_WEB_BASE_URL}/payment/${product}/{CHECKOUT_SESSION_ID}`;
  if (utm_source && utm_medium) {
    const paymentPathWithUTM = `${paymentPath}?utm_source=${utm_source}&utm_medium=${utm_medium}`;
    if (_.isEmpty(utm_campaign)) {
      return paymentPathWithUTM;
    } else {
      return `${paymentPathWithUTM}&utm_campaign=${utm_campaign}`;
    }
  } else {
    return paymentPath;
  }
};

const redirectThankyou = (
  product: string,
  isDev: string,
  checkoutId: string
) => {
  const promoName = getLocalStorage(PROMO_NAME) || '';
  const paymentPath = `${LING_WEB_BASE_URL}/payment/${product}/${checkoutId}`;
  const paymentUrl =
    promoName === 'null' ? paymentPath : `${paymentPath}/${promoName}`;
  return window.location.replace(paymentUrl);
};

const redirectLogin = (
  product: string,
  isDev: string,
  currency: string,
  CHECKOUT_SESSION_ID: string,
  cardholderEmail: string
) => {
  console.log('Is Dev is', typeof isDev);
  const { utm_source, utm_medium, utm_campaign } = getUTM();

  if (product === `offer_stripe_${PRODUCT_TYPE.monthly}`) {
    Analytic.logGA(Analytic.key.A12, {
      category: Analytic.key.A12,
      action: 'Check',
      label: currency
    });
  } else if (product === `offer_stripe_${PRODUCT_TYPE.yearly}`) {
    Analytic.logGA(Analytic.key.A13, {
      category: Analytic.key.A13,
      action: 'Check',
      label: currency
    });
  } else {
    Analytic.logGA(Analytic.key.A14, {
      category: Analytic.key.A14,
      action: 'Check',
      label: currency
    });
  }
  let loginPath = `https://play.ling-app.com/claim/${product}/${CHECKOUT_SESSION_ID}?cardholderEmail=${cardholderEmail}`;
  if (utm_source && utm_medium) {
    const paymentPathWithUTM = `${loginPath}&utm_source=${utm_source}&utm_medium=${utm_medium}`;
    if (_.isEmpty(utm_campaign)) {
      loginPath = paymentPathWithUTM;
    } else {
      loginPath = `${paymentPathWithUTM}&utm_campaign=${utm_campaign}`;
    }
  }
  return window.location.replace(loginPath);
};
const purchaseFailed = () =>
  window.location.replace('http://play.ling-app.com/');

const lingAppShadowIcon = `${IMAGE_PATH}lingAppShadowIcon.png`;

const dangerIcon = `${PREFIX_HEADER}basecolor-rocket-circle-yellow-176-px.svg`;
const lingLoading = `${IMAGE_PATH}ling-loader.gif`;
const giftFirework = `${PREFIX_LESSON}gift-firework.svg`;
const confirmIcon = `${PREFIX_HEADER}icon-24-circle.svg`;
const fbIcon = `${PREFIX_HEADER}f-logo-login.svg`;
const googleIcon = `${PREFIX_HEADER}g-logo-login.svg`;
const appleIcon = `${PREFIX_HEADER}Apple_logo_white.svg`;
class ClaimScene extends Component<Props, State> {
  item: any;
  lineItems: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      affiliateId: [],
      isExpolingua: false,
      err: false,
      emailErrorMsg: '',
      passwordErrorMsg: '',
      formErrorMsg: '',
      isSignUp: true,
      id: 30,
      itemInfo: [{ plan: '', quantity: 1 }],
      productName: 'stripe_yearly',
      isLoading: true,
      successMsg: '',
      errorMsgFirebase: '',
      isEmailSent: false,
      userForm: {
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
      },
      isResetPassword: false,
      promoName: '',
      redeemId: '',
      receipt: '',
      isCheckout: false,
      monthPrice: 0,
      yearPrice: 7999,
      usdMonthPrice: 1149,
      usdYearPrice: 7999,
      lifeTimePrice: 14999,
      usdLifeTimePrice: 14999,
      currencySymbol: {},
      isUpdatingCard: false,
      currency: '',
      productId: '',
      packageName: '',
      currencyCode: ''
    };
  }

  async componentDidMount() {
    const { location, functions, addToSubscribeNewsList } = this.props;
    window.scrollTo(0, 0);
    let auth = firebase.auth();
    const { search } = location;
    setUTM();
    const redeemId = search.match(/redeemCode=([^&]*)/);
    const receipt = _.get(this.props, 'match.params.receipt', false);
    const productName = _.get(this.props, 'match.params.type', false);
    const isExpolingua = getLocalStorage('isExpolingua') === 'true';
    const utm_campaign = getLocalStorage('utm_campaign') || '';

    this.setState({
      affiliateId:
        search.match(/(?<=affiliateId=)[\d|\w]*(?!=\?)/) ||
        this.state.affiliateId
    });

    if (utm_campaign.includes(UTM_CAMPAIGN_USERS_CANCELED)) {
      this.setState({ isSignUp: false });
    }
    this.setState(prevState => {
      return {
        isExpolingua: isExpolingua,
        receipt: receipt,
        productName: productName
      };
    });
    if (!getLocalStorage('redeemCode') && redeemId) {
      setLocalStorage('redeemCode', redeemId[1]);
      await this.logOut();
    }

    if (redeemId) {
      this.setState({
        redeemId: redeemId[1]
      });
      if (
        !_.isEmpty(redeemId) &&
        !(
          redeemId[1] === 'monthly' ||
          redeemId[1] === 'yearly' ||
          redeemId[1] === 'lifetime'
        )
      ) {
        axios
          .get(`${functions}/validateLingWeb-stripeApi/coupon/${redeemId[1]}`)
          .then(res => {
            if (res.status === 200) {
              if (
                !res.data.success &&
                res.data.status &&
                res.data.status === 'invalid'
              ) {
                alert('The code is incorrect.');
                window.location.replace(
                  'https://ling-app.com/ling-gift-redeem/'
                );
              }
            }
          })
          // eslint-disable-next-line handle-callback-err
          .catch(err => {});
      }

      auth.onAuthStateChanged(user => {
        if (!user) {
          auth
            .signInAnonymously()
            .then(result => {
              const { user } = result;

              this.fetchUser(user);
              this.onCheckout(redeemId[1], 'redeem');
            })
            .catch(error => {
              // Handle Errors here.
              var errorCode = error.code;

              if (errorCode === 'auth/operation-not-allowed') {
                alert(
                  'You must enable Anonymous auth in the Firebase Console.'
                );
              }
            });
        } else {
          fetchRemoteDatabase(`users/${user.uid}`).then(data => {
            const { isProUser, isDev } = this.props;
            const { receipt } = this.state;
            this.setState({ isLoading: true, errorMsgFirebase: '' });
            const { saveUserData, createNewDatabaseUser } = this.props;
            const value = data.val();
            if (value) {
              saveUserData(value);
              if (isProUser && !isMobile()) {
                removePromoLocalStorage();
                this.props.history.push('/');
              } else if (!_.isEmpty(receipt)) {
                redirectThankyou(`${this.state.productName}`, isDev, receipt);
              }
            } else {
              createNewDatabaseUser(
                user,
                (user.providerData[0] &&
                  user.providerData[0].providerId.split('.')[0]) ||
                  'anonymous'
              );
            }
            this.onCheckout(redeemId[1], 'redeem');
          });
        }
      });
    } else {
      auth.onAuthStateChanged(async user => {
        if (!user) {
          auth
            .signInAnonymously()
            .then(result => {
              const { user } = result;

              this.fetchUser(user);
              this.onCheckout();
            })
            .catch(error => {
              // Handle Errors here.
              var errorCode = error.code;

              if (errorCode === 'auth/operation-not-allowed') {
                alert(
                  'You must enable Anonymous auth in the Firebase Console.'
                );
              }
            });
        } else {
          fetchRemoteDatabase(`users/${user.uid}`).then(data => {
            const { saveUserData, createNewDatabaseUser } = this.props;
            const value = data && data.val();
            if (value) {
              saveUserData(value);
            } else {
              createNewDatabaseUser(
                user,
                (user.providerData[0] &&
                  user.providerData[0].providerId.split('.')[0]) ||
                  'anonymous'
              );
            }
            if (
              user.isAnonymous === false &&
              getLocalStorage('isExpolingua') === 'true'
            ) {
              addToSubscribeNewsList();
            }
            this.onCheckout();
          });
        }
      });
    }
  }
  onCheckout(redeemCode: string = '', type: string = '') {
    const {
      location,
      isDev,
      user,
      isProUser,
      monthly_one_time_usd,
      monthly_one_time_eur,
      monthly_one_time_pound,
      monthly_one_time_thb,
      monthly_one_time_asd,
      monthly_one_time_rub,
      monthly_usd,
      monthly_eur,
      monthly_pound,
      monthly_thb,
      monthly_asd,
      monthly_rub,
      monthly_usd_20,
      monthly_eur_20,
      monthly_pound_20,
      monthly_thb_20,
      monthly_asd_20,
      monthly_rub_20,
      monthly_usd_30,
      monthly_eur_30,
      monthly_pound_30,
      monthly_thb_30,
      monthly_asd_30,
      monthly_rub_30,
      yearly_one_time_usd,
      yearly_one_time_eur,
      yearly_one_time_pound,
      yearly_one_time_thb,
      yearly_one_time_asd,
      yearly_one_time_rub,
      lifetime_one_time_usd,
      lifetime_one_time_eur,
      lifetime_one_time_pound,
      lifetime_one_time_thb,
      lifetime_one_time_asd,
      lifetime_one_time_rub,
      lifetime_usd_20,
      lifetime_eur_20,
      lifetime_pound_20,
      lifetime_thb_20,
      lifetime_asd_20,
      lifetime_rub_20,
      lifetime_usd_25,
      lifetime_eur_25,
      lifetime_pound_25,
      lifetime_thb_25,
      lifetime_asd_25,
      lifetime_rub_25,
      lifetime_usd_30,
      lifetime_eur_30,
      lifetime_pound_30,
      lifetime_thb_30,
      lifetime_asd_30,
      lifetime_rub_30,
      lifetime_usd_40,
      lifetime_eur_40,
      lifetime_thb_40,
      lifetime_pound_40,
      lifetime_rub_40,
      lifetime_asd_40,
      lifetime_usd_50,
      lifetime_eur_50,
      lifetime_thb_50,
      lifetime_pound_50,
      lifetime_rub_50,
      lifetime_asd_50,
      yearly,
      yearly_eur,
      yearly_pound,
      yearly_thb,
      yearly_asd,
      yearly_rub,
      yearly_20,
      yearly_eur_20,
      yearly_pound_20,
      yearly_thb_20,
      yearly_rub_20,
      yearly_asd_20,
      yearly_25,
      yearly_eur_25,
      yearly_pound_25,
      yearly_thb_25,
      yearly_rub_25,
      yearly_asd_25,
      yearly_30,
      yearly_eur_30,
      yearly_pound_30,
      yearly_thb_30,
      yearly_rub_30,
      yearly_asd_30,
      yearly_40,
      yearly_eur_40,
      // yearly_pound_40,
      // yearly_thb_40,
      // yearly_rub_40,
      // yearly_asd_40,
      yearly_50,
      yearly_eur_50,
      yearly_pound_50,
      yearly_thb_50,
      yearly_rub_50,
      yearly_asd_50,
      yearly_krw_50,
      yearly_jpy_50
    } = this.props;
    const { productName, receipt } = this.state;
    const { search } = location;
    let promoName = '';
    const cardholderEmail = getUrlParam('cardholderEmail');
    const language = window.navigator.language;
    const currency = getCurrency(language)
      ? getCurrency(language).toUpperCase()
      : CURRENCY.USD;
    if (
      redeemCode === PRODUCT_TYPE.monthly ||
      redeemCode === PRODUCT_TYPE.yearly ||
      redeemCode === PRODUCT_TYPE.lifetime
    ) {
      let productId = '';
      if (redeemCode === PRODUCT_TYPE.yearly) {
        switch (currency) {
          case CURRENCY.USD:
            productId = yearly_one_time_usd;
            break;
          case CURRENCY.EUR:
            productId = yearly_one_time_eur;
            break;
          case CURRENCY.THB:
            productId = yearly_one_time_thb;
            break;
          case CURRENCY.GBP:
            productId = yearly_one_time_pound;
            break;
          case CURRENCY.RUB:
            productId = yearly_one_time_rub;
            break;
          case CURRENCY.AUD:
            productId = yearly_one_time_asd;
            break;
          default:
            productId = yearly_one_time_usd;
        }
      } else if (redeemCode === PRODUCT_TYPE.monthly) {
        switch (currency) {
          case CURRENCY.USD:
            productId = monthly_one_time_usd;
            break;
          case CURRENCY.EUR:
            productId = monthly_one_time_eur;
            break;
          case CURRENCY.THB:
            productId = monthly_one_time_thb;
            break;
          case CURRENCY.GBP:
            productId = monthly_one_time_pound;
            break;
          case CURRENCY.RUB:
            productId = monthly_one_time_rub;
            break;
          case CURRENCY.AUD:
            productId = monthly_one_time_asd;
            break;
          default:
            productId = monthly_one_time_usd;
        }
      } else if (redeemCode === PRODUCT_TYPE.lifetime) {
        switch (currency) {
          case CURRENCY.USD:
            productId = lifetime_one_time_usd;
            break;
          case CURRENCY.EUR:
            productId = lifetime_one_time_eur;
            break;
          case CURRENCY.THB:
            productId = lifetime_one_time_thb;
            break;
          case CURRENCY.GBP:
            productId = lifetime_one_time_pound;
            break;
          case CURRENCY.RUB:
            productId = lifetime_one_time_rub;
            break;
          case CURRENCY.AUD:
            productId = lifetime_one_time_asd;
            break;
          default:
            productId = lifetime_one_time_usd;
        }
      }
      stripe
        .redirectToCheckout({
          lineItems: [
            {
              price: productId,
              quantity: 1
            }
          ],
          mode: 'payment',
          // Do not rely on the redirect to the successUrl for fulfilling
          // purchases, customers may not always reach the success_url after
          // a successful payment.
          // Instead use one of the strategies described in
          // https://stripe.com/docs/payments/checkout/fulfillment
          // successUrl: `${redirect(`redeem_ + ${redeemCode}`, isDev, currency)}`,
          successUrl: `${redirect(`redeem_${redeemCode}`, isDev, currency)}`,
          cancelUrl: 'https://ling-app.com/ling-gift-christmas/'
        })
        .then(function(result) {
          if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer.
            alert(result.error.message);
          }
        });
    } else {
      const queryId = search.match(/id=([^&]*)/);
      if (type !== 'redeem') {
        if (search) {
          if (queryId) {
            promoName = queryId[1];
            this.setState({
              promoName: queryId[1]
            });
          }
        }
      }
      if (type !== 'redeem') {
        const packageName = search.match(/package=([^&]*)/);

        const language = window.navigator.language;
        const currency = getCurrency(language)
          ? getCurrency(language).toUpperCase()
          : CURRENCY.USD;
        let productId = '';
        if (packageName && packageName[1] === PRODUCT_TYPE.yearly) {
          if (parseInt(promoName, 10) === 20) {
            switch (currency) {
              case CURRENCY.USD:
                productId = yearly_20;
                break;
              case CURRENCY.EUR:
                productId = yearly_eur_20;
                break;
              case CURRENCY.THB:
                productId = yearly_thb_20;
                break;
              case CURRENCY.GBP:
                productId = yearly_pound_20;
                break;
              case CURRENCY.RUB:
                productId = yearly_rub_20;
                break;
              case CURRENCY.AUD:
                productId = yearly_asd_20;
                break;
              default:
                productId = yearly_20;
            }
          } else if (parseInt(promoName, 10) === 25) {
            switch (currency) {
              case CURRENCY.USD:
                productId = yearly_25;
                break;
              case CURRENCY.EUR:
                productId = yearly_eur_25;
                break;
              case CURRENCY.THB:
                productId = yearly_thb_25;
                break;
              case CURRENCY.GBP:
                productId = yearly_pound_25;
                break;
              case CURRENCY.RUB:
                productId = yearly_rub_25;
                break;
              case CURRENCY.AUD:
                productId = yearly_asd_25;
                break;
              default:
                productId = yearly_25;
            }
          } else if (parseInt(promoName, 10) === 30) {
            switch (currency) {
              case CURRENCY.USD:
                productId = yearly_30;
                break;
              case CURRENCY.EUR:
                productId = yearly_eur_30;
                break;
              case CURRENCY.THB:
                productId = yearly_thb_30;
                break;
              case CURRENCY.GBP:
                productId = yearly_pound_30;
                break;
              case CURRENCY.RUB:
                productId = yearly_rub_30;
                break;
              case CURRENCY.AUD:
                productId = yearly_asd_30;
                break;
              default:
                productId = yearly_30;
            }
          } else if (parseInt(promoName, 10) === 40) {
            switch (currency) {
              case CURRENCY.USD:
                productId = yearly_40;
                break;
              case CURRENCY.EUR:
                productId = yearly_eur_40;
                break;
              default:
                productId = yearly_40;
            }
          } else if (parseInt(promoName, 10) === 50) {
            switch (currency) {
              case CURRENCY.USD:
                productId = yearly_50;
                break;
              case CURRENCY.EUR:
                productId = yearly_eur_50;
                break;
              case CURRENCY.THB:
                productId = yearly_thb_50;
                break;
              case CURRENCY.GBP:
                productId = yearly_pound_50;
                break;
              case CURRENCY.RUB:
                productId = yearly_rub_50;
                break;
              case CURRENCY.AUD:
                productId = yearly_asd_50;
                break;
              case CURRENCY.KRW:
                productId = yearly_krw_50;
                break;
              case CURRENCY.JPY:
                productId = yearly_jpy_50;
                break;
              default:
                productId = yearly_50;
            }
          } else if (parseInt(promoName, 10) === 0) {
            switch (currency) {
              case CURRENCY.USD:
                productId = yearly;
                break;
              case CURRENCY.EUR:
                productId = yearly_eur;
                break;
              case CURRENCY.THB:
                productId = yearly_thb;
                break;
              case CURRENCY.GBP:
                productId = yearly_pound;
                break;
              case CURRENCY.RUB:
                productId = yearly_rub;
                break;
              case CURRENCY.AUD:
                productId = yearly_asd;
                break;
              default:
                productId = yearly;
            }
          }
          this.setState(prevState => {
            return {
              packageName: STRIPE_YEARLY
            };
          });
        } else if (packageName && packageName[1] === PRODUCT_TYPE.monthly) {
          if (parseInt(promoName, 10) === 20) {
            switch (currency) {
              case CURRENCY.USD:
                productId = monthly_usd_20;
                break;
              case CURRENCY.EUR:
                productId = monthly_eur_20;
                break;
              case CURRENCY.THB:
                productId = monthly_thb_20;
                break;
              case CURRENCY.GBP:
                productId = monthly_pound_20;
                break;
              case CURRENCY.RUB:
                productId = monthly_rub_20;
                break;
              case CURRENCY.AUD:
                productId = monthly_asd_20;
                break;
              default:
                productId = monthly_usd_20;
            }
          } else if (parseInt(promoName, 10) === 30) {
            switch (currency) {
              case CURRENCY.USD:
                productId = monthly_usd_30;
                break;
              case CURRENCY.EUR:
                productId = monthly_eur_30;
                break;
              case CURRENCY.THB:
                productId = monthly_thb_30;
                break;
              case CURRENCY.GBP:
                productId = monthly_pound_30;
                break;
              case CURRENCY.RUB:
                productId = monthly_rub_30;
                break;
              case CURRENCY.AUD:
                productId = monthly_asd_30;
                break;
              default:
                productId = monthly_usd_30;
            }
          } else if (parseInt(promoName, 10) === 0) {
            switch (currency) {
              case CURRENCY.USD:
                productId = monthly_usd;
                break;
              case CURRENCY.EUR:
                productId = monthly_eur;
                break;
              case CURRENCY.THB:
                productId = monthly_thb;
                break;
              case CURRENCY.GBP:
                productId = monthly_pound;
                break;
              case CURRENCY.RUB:
                productId = monthly_rub;
                break;
              case CURRENCY.AUD:
                productId = monthly_asd;
                break;
              default:
                productId = monthly_usd;
            }
          }
          this.setState(prevState => {
            return {
              packageName: STRIPE_MONTHY
            };
          });
        } else if (packageName && packageName[1] === PRODUCT_TYPE.lifetime) {
          if (parseInt(promoName, 10) === 20) {
            switch (currency) {
              case CURRENCY.USD:
                productId = lifetime_usd_20;
                break;
              case CURRENCY.EUR:
                productId = lifetime_eur_20;
                break;
              case CURRENCY.THB:
                productId = lifetime_thb_20;
                break;
              case CURRENCY.GBP:
                productId = lifetime_pound_20;
                break;
              case CURRENCY.RUB:
                productId = lifetime_rub_20;
                break;
              case CURRENCY.AUD:
                productId = lifetime_asd_20;
                break;
              default:
                productId = lifetime_usd_20;
            }
          } else if (parseInt(promoName, 10) === 25) {
            switch (currency) {
              case CURRENCY.USD:
                productId = lifetime_usd_25;
                break;
              case CURRENCY.EUR:
                productId = lifetime_eur_25;
                break;
              case CURRENCY.THB:
                productId = lifetime_thb_25;
                break;
              case CURRENCY.GBP:
                productId = lifetime_pound_25;
                break;
              case CURRENCY.RUB:
                productId = lifetime_rub_25;
                break;
              case CURRENCY.AUD:
                productId = lifetime_asd_25;
                break;
              default:
                productId = lifetime_usd_25;
            }
          } else if (parseInt(promoName, 10) === 30) {
            switch (currency) {
              case CURRENCY.USD:
                productId = lifetime_usd_30;
                break;
              case CURRENCY.EUR:
                productId = lifetime_eur_30;
                break;
              case CURRENCY.THB:
                productId = lifetime_thb_30;
                break;
              case CURRENCY.GBP:
                productId = lifetime_pound_30;
                break;
              case CURRENCY.RUB:
                productId = lifetime_rub_30;
                break;
              case CURRENCY.AUD:
                productId = lifetime_asd_30;
                break;
              default:
                productId = lifetime_usd_30;
            }
          } else if (parseInt(promoName, 10) === 40) {
            switch (currency) {
              case CURRENCY.USD:
                productId = lifetime_usd_40;
                break;
              case CURRENCY.EUR:
                productId = lifetime_eur_40;
                break;
              case CURRENCY.THB:
                productId = lifetime_thb_40;
                break;
              case CURRENCY.GBP:
                productId = lifetime_pound_40;
                break;
              case CURRENCY.RUB:
                productId = lifetime_rub_40;
                break;
              case CURRENCY.AUD:
                productId = lifetime_asd_40;
                break;
              default:
                productId = lifetime_usd_40;
            }
          } else if (parseInt(promoName, 10) === 50) {
            switch (currency) {
              case CURRENCY.USD:
                productId = lifetime_usd_50;
                break;
              case CURRENCY.EUR:
                productId = lifetime_eur_50;
                break;
              case CURRENCY.THB:
                productId = lifetime_thb_50;
                break;
              case CURRENCY.GBP:
                productId = lifetime_pound_50;
                break;
              case CURRENCY.RUB:
                productId = lifetime_rub_50;
                break;
              case CURRENCY.AUD:
                productId = lifetime_asd_50;
                break;
              default:
                productId = lifetime_usd_50;
            }
          } else if (parseInt(promoName, 10) === 0) {
            switch (currency) {
              case CURRENCY.USD:
                productId = lifetime_one_time_usd;
                break;
              case CURRENCY.EUR:
                productId = lifetime_one_time_eur;
                break;
              case CURRENCY.THB:
                productId = lifetime_one_time_thb;
                break;
              case CURRENCY.GBP:
                productId = lifetime_one_time_pound;
                break;
              case CURRENCY.RUB:
                productId = lifetime_one_time_rub;
                break;
              case CURRENCY.AUD:
                productId = lifetime_one_time_asd;
                break;
              default:
                productId = lifetime_one_time_usd;
            }
          }
          this.setState(prevState => {
            return {
              packageName: 'lifetime'
            };
          });
        }
        this.item = [{ plan: productId, quantity: 1 }];

        this.lineItems = [
          {
            price: productId,
            quantity: 1
          }
        ];
        this.setState({
          productId: productId,
          itemInfo: this.item
        });
      }
      let userEmail = '';
      if (user.facebook) {
        userEmail = user.facebook.email;
      } else if (user.google) {
        userEmail = user.google.email;
      } else if (user.email) {
        userEmail = user.email.email;
      } else if (user.apple) {
        userEmail = user.apple.email;
      }
      if (_.isNull(queryId) && userEmail === '') {
        this.setState({
          isLoading: false
        });
      }
      if (!_.isEmpty(userEmail)) {
        if (isProUser && !isMobile()) {
          removePromoLocalStorage();
          this.props.history.push('/');
        } else if (!_.isEmpty(receipt)) {
          userEmail === cardholderEmail
            ? redirectThankyou(`${this.state.productName}`, isDev, receipt)
            : purchaseFailed();
        } else if (redeemCode) {
          this.validateRedeemCode(redeemCode);
        } else {
          this.logOut();
        }
      } else {
        if (
          !_.isEmpty(getLocalStorage('isExpolingua')) ||
          (!_.isEmpty(getLocalStorage(ID)) && _.isEmpty(queryId && queryId[1]))
        ) {
          this.setState({
            id: parseInt(getLocalStorage(ID), 10) || 0,
            isLoading: false
          });
        } else {
          const promoName = search.match(/promoName=([^&]*)/);
          const id = search.match(/id=([^&]*)/);
          setLocalStorage(PROMO_NAME, promoName && promoName[1]);
          setLocalStorage(ID, id && id[1]);
          this.setState({
            isLoading: false,
            isCheckout: true
          });
          //TODO keep old logic until new checkout logic work smootly
          // stripe
          //   .redirectToCheckout({
          //     items: this.item,
          //     // customerEmail: userEmail,
          //     // Do not rely on the redirect to the successUrl for fulfilling
          //     // purchases, customers may not always reach the success_url after
          //     // a successful payment.
          //     // Instead use one of the strategies described in
          //     // https://stripe.com/docs/payments/checkout/fulfillment
          //     successUrl: `${redirectLogin(
          //       'offer_stripe_yearly',
          //       isDev,
          //       currency
          //     )}`,
          //     cancelUrl: `${purchaseFailed(isDev)}`
          //   })
          //   .then(function(result) {
          //     if (result.error) {
          //       // If `redirectToCheckout` fails due to a browser or network
          //       // error, display the localized error message to your customer.
          //       alert(result.error.message);
          //     }
          //   });
        }
      }

      Analytic.log(Analytic.key.A4, {
        category: Analytic.key.A4,
        action: 'Click',
        label: productName
      });
      Analytic.logGA(Analytic.key.A4, {
        category: Analytic.key.A4,
        action: 'Click',
        label: productName
      });
    }
  }
  fetchUser(user: Object) {
    fetchRemoteDatabase(`users/${user.uid}`).then(data => {
      const {
        saveUserData,
        createNewDatabaseUser,
        location,
        updateUserData
      } = this.props;
      const value = data && data.val();
      if (value) {
        saveUserData(value);
      } else {
        createNewDatabaseUser(
          user,
          (user.providerData[0] &&
            user.providerData[0].providerId.split('.')[0]) ||
            'anonymous'
        );
      }
      const { search } = location;
      const queryReferrer = search.match(/referrer=([^&]*)/);
      if (queryReferrer) {
        const referrer = queryReferrer[1];
        updateUserData({ referrer });
      }
    });
  }

  refreshPage() {
    window.location.reload();
  }

  validateRedeemCode = (redeemCode: string) => {
    const { functions, updateUserData } = this.props;
    if (
      redeemCode !== 'monthly' &&
      redeemCode !== 'yearly' &&
      redeemCode !== 'lifetime'
    ) {
      axios
        .post(`${functions}${couponAPI}`, {
          uid: this.props.user.uid,
          couponCode: redeemCode
        })
        .then(res => {
          if (res.status === 200) {
            !res.data.success &&
              alert(
                'This coupon is already redeemed. Please contact support@simyasolutions.com if you have not redeemed this coupon before.'
              );

            updateUserData({ subscription: res.data.subscription });
            // eslint-disable-next-line no-undef
            localStorage.removeItem('redeemCode');
            isMobile()
              ? (window.location = 'https://ling-app.com/redeem-success/')
              : this.props.history.push('/');
          }
        })
        .catch(err => {
          BugTracker.notify(err);
        });
    }
  };

  validateResetPaswordForm = () => {
    const { email_error_fieldsMissing, email_error_wrongFormat } = this.props;
    const { userForm } = this.state;
    const { email } = userForm;

    let isError = false;
    let emailErrorMsg = '';
    let formErrorMsg = '';
    if (email.length === 0) {
      isError = true;
      formErrorMsg = email_error_fieldsMissing;
    } else {
      if (!isValidEmail(email)) {
        isError = true;
        emailErrorMsg = email_error_wrongFormat;
      }
    }

    this.setState({
      formErrorMsg: formErrorMsg,
      emailErrorMsg: emailErrorMsg
    });

    return isError;
  };

  resetErrorMessage() {
    this.setState({
      err: false,
      emailErrorMsg: '',
      passwordErrorMsg: '',
      formErrorMsg: ''
    });
  }

  getProductPriceByCampaign = (
    originalPrice: number,
    discountPercentage: number
  ) => {
    const currentCampaignName = getLocalStorage(PROMO_NAME);
    const { location } = this.props;
    const { search } = location;
    const packageName = search.match(/package=([^&]*)/);
    const packageNameId = packageName && packageName[1];
    if (
      CNX_LANGUAGE_EXCHANGE === currentCampaignName &&
      packageNameId !== 'lifetime'
    ) {
      return getPriceAfterDiscount(originalPrice, discountPercentage);
    } else {
      return originalPrice;
    }
  };

  getProductInfo = () => {
    const { functions } = this.props;
    const { productId, promoName } = this.state;
    const accessKey = process.env.REACT_APP_EXCHANGE_RATE_API_ACCESS_KEY || '';
    const idType =
      productId.split('_').shift() === 'prod'
        ? 'product'
        : productId.split('_').shift() === 'price'
        ? 'price'
        : productId.split('_').shift() === 'plan'
        ? 'plan'
        : 'sku';
    setLocalStorage('category', 'promotion');
    setLocalStorage('productId', productId);
    axios
      .get(
        `${functions}/validateLingWeb-stripeApi/products/${idType}/${productId}`
      )
      .then(async res => {
        console.log('res', res);
        if (res.data) {
          if (_.isUndefined(res.data.unit_amount)) {
            this.setState(prevState => {
              return {
                monthPrice:
                  findCurrency(
                    (res.data.currency
                      ? res.data.currency
                      : CURRENCY.USD
                    ).toUpperCase()
                  ).symbol +
                  getPriceAfterDiscount(
                    res.data.amount / 100,
                    _.toNumber(promoName)
                  )
              };
            });
            const rateDate = await axios.get(
              `https://api.exchangeratesapi.io/latest?base=${res.data.currency.toUpperCase()}&access_key=${accessKey}`
            );

            const rate = rateDate.data.rates['USD'];
            const convertedAmount = (res.data.amount / 100) * rate;
            setLocalStorage(
              'revenue',
              //$FlowFixMe
              res.data.currency === CURRENCY.USD
                ? this.getProductPriceByCampaign(
                    res.data.amount / 100,
                    _.toNumber(promoName)
                  )
                : this.getProductPriceByCampaign(
                    convertedAmount,
                    _.toNumber(promoName)
                  )
            );
          } else {
            this.setState(prevState => {
              return {
                monthPrice:
                  findCurrency(
                    (res.data.currency
                      ? res.data.currency
                      : CURRENCY.USD
                    ).toUpperCase()
                  ).symbol +
                  this.getProductPriceByCampaign(
                    [CURRENCY.KRW, CURRENCY.JPY].includes(
                      res.data.currency.toUpperCase()
                    )
                      ? parseInt(res.data.unit_amount_decimal)
                      : parseInt(res.data.unit_amount_decimal) / 100,
                    _.toNumber(promoName)
                  )
              };
            });
            const rateDate = await axios.get(
              `https://api.exchangeratesapi.io/latest?base=${res.data.currency.toUpperCase()}&access_key=${accessKey}`
            );
            const rate = rateDate.data.rates['USD'];
            const convertedAmount =
              (parseInt(res.data.unit_amount_decimal) / 100) * rate;
            setLocalStorage(
              'revenue',
              //$FlowFixMe
              res.data.currency === CURRENCY.USD
                ? this.getProductPriceByCampaign(
                    parseInt(res.data.unit_amount_decimal) / 100,
                    _.toNumber(promoName)
                  )
                : [CURRENCY.KRW, CURRENCY.JPY].includes(
                    res.data.currency.toUpperCase()
                  )
                ? parseInt(res.data.unit_amount_decimal)
                : this.getProductPriceByCampaign(
                    convertedAmount,
                    _.toNumber(promoName)
                  )
            );
          }
        }
      })
      .catch(err => BugTracker.notify(err));

    return '';
  };
  validate = (isLoginForm: boolean) => {
    const {
      email_error_matchPasswords,
      email_error_fieldsMissing,
      email_error_longerPassword,
      email_error_wrongFormat
    } = this.props;
    const { userForm } = this.state;
    let isError = false;
    let emailErrorMsg = '';
    let passwordErrorMsg = '';
    let formErrorMsg = '';
    if (isLoginForm) {
      const { email, password } = userForm;
      if (email.length === 0 || password.length === 0) {
        isError = true;
        formErrorMsg = email_error_fieldsMissing;
      } else {
        if (!isValidEmail(email)) {
          isError = true;
          emailErrorMsg = email_error_wrongFormat;
        }
        if (password.length < 6) {
          isError = true;
          passwordErrorMsg = email_error_longerPassword;
        }
      }
    } else {
      const { name, email, password, confirmPassword } = userForm;
      if (
        name.length === 0 ||
        email.length === 0 ||
        password.length === 0 ||
        confirmPassword.length === 0
      ) {
        isError = true;
        formErrorMsg = email_error_fieldsMissing;
      } else {
        if (!isValidEmail(email)) {
          isError = true;
          emailErrorMsg = email_error_wrongFormat;
        }
        if (password.length < 6) {
          isError = true;
          passwordErrorMsg = email_error_longerPassword;
        }
        if (confirmPassword !== password) {
          isError = true;
          passwordErrorMsg = email_error_matchPasswords;
        }
      }
    }

    this.setState({
      formErrorMsg: formErrorMsg,
      emailErrorMsg: emailErrorMsg,
      passwordErrorMsg: passwordErrorMsg
    });
    return isError;
  };

  handleLoginSubmit = async (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    this.resetErrorMsg();
    const isLoginForm = true;
    const isErr = this.validate(isLoginForm);
    this.setState({ err: isErr });

    if (!isErr) {
      setLocalStorage(SIGNIN_METHOD, PROVIDER_EMAIL);
      this.login();
    }
  };
  login = async () => {
    const { userForm, receipt } = this.state;
    const { email, password } = userForm;
    this.setState({ isLoading: true, errorMsgFirebase: '' });
    const { signInWithEmailAndPassword, isProUser, isDev } = this.props;
    const signMsg = await signInWithEmailAndPassword(email, password);

    if (signMsg[0]) {
      if (isProUser && !isMobile()) {
        removePromoLocalStorage();
        this.props.history.push('/');
      } else if (!_.isEmpty(receipt)) {
        redirectThankyou(`${this.state.productName}`, isDev, receipt);
      } else {
        this.redirectToStripe();
      }
    } else {
      this.setState({
        errorMsgFirebase: this.getEmailLoginError(signMsg[1]).message
      });
      this.setState({ isLoading: false });
    }
  };

  loginGoogleRedirect = () => {
    const { signInWithGoogleRedirect } = this.props;
    setLocalStorage(SIGNIN_METHOD, PROVIDER_GOOGLE);
    signInWithGoogleRedirect();
  };

  loginFbRedirect = () => {
    const { signInWithFbRedirect } = this.props;
    setLocalStorage(SIGNIN_METHOD, PROVIDER_FACEBOOK);
    signInWithFbRedirect();
  };

  loginAppleRedirect = () => {
    const { signInWithAppleRedirect } = this.props;
    setLocalStorage(SIGNIN_METHOD, PROVIDER_APPLE);
    signInWithAppleRedirect();
  };

  logOut = async () => {
    const { signOut, signInAnonymously } = this.props;
    const signMsg = await signOut();

    if (signMsg[0]) {
      await signInAnonymously();
    }
  };
  handleSignUpSubmit = async (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    this.resetErrorMsg();
    const isLoginForm = false;
    const isErr = this.validate(isLoginForm);
    this.setState({ err: isErr });
    if (!isErr) {
      setLocalStorage(SIGNIN_METHOD, PROVIDER_EMAIL);
      this.signUpWithEmail();
    }
  };
  toggleResetPassword = (e: SyntheticEvent<HTMLButtonElement>) => {
    const { toggleResetPassword } = this.props;
    this.resetErrorMessage();
    document.forms[('loginForm': any)] &&
      document.forms[('loginForm': any)].reset();
    toggleResetPassword();
  };
  closeEmailSent = () => {
    this.setState({ isEmailSent: false, isResetPassword: false });
  };
  signUpWithEmail = async () => {
    const { signUpWithEmail, isProUser, isDev, updateUserData } = this.props;
    const { userForm, receipt } = this.state;
    const { name, email, password } = userForm;
    this.setState({ isLoading: true, errorMsgFirebase: '' });

    const signMsg = await signUpWithEmail(name, email, password);
    if (signMsg[0]) {
      if (isProUser && !isMobile()) {
        removePromoLocalStorage();
        this.props.history.push('/');
      } else if (!_.isEmpty(receipt)) {
        redirectThankyou(`${this.state.productName}`, isDev, receipt);
        updateUserData({ isProUser: true });
      } else {
        this.redirectToStripe();
      }
      // this.toggle();
    } else {
      this.setState({
        errorMsgFirebase: this.getEmailLoginError(signMsg[1]).message
      });
      this.setState({ isLoading: false });
    }
  };
  getEmailLoginError = ({ code, message }: Object) => {
    const {
      email_error_alreadyUsed,
      email_error_userNotFound,
      email_error_wrongPassword
    } = this.props;
    switch (code) {
      case 'auth/user-not-found':
        return {
          message: email_error_userNotFound
        };
      case 'auth/wrong-password':
        return {
          message: email_error_wrongPassword
        };
      case 'auth/email-already-in-use':
        return {
          message: email_error_alreadyUsed
        };
      default:
        return {
          message
        };
    }
  };
  handleResetPasswordSubmit = async (
    event: SyntheticEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    this.resetErrorMsg();
    const isErr = this.validateResetPaswordForm();
    this.setState({ err: isErr });

    if (!isErr) {
      this.resetPassword();
    }
  };
  toggleIsCheckout = () => {
    window.scrollTo(0, 0);
    this.setState({ isCheckout: !this.state.isCheckout });
  };
  resetPassword = async () => {
    const { sendPasswordResetEmail } = this.props;
    const { userForm } = this.state;
    const { email } = userForm;
    this.setState({ isLoading: true, errorMsgFirebase: '' });
    const signMsg = await sendPasswordResetEmail(email);

    if (true) {
      this.setState({ isEmailSent: true });
    } else {
      this.setState({ errorMsgFirebase: signMsg[1].message });
    }
    this.setState({ isLoading: false });
  };

  toggleSignUp = () => {
    const { isResetPassword, isSignUp } = this.state;
    document.forms[('loginForm': any)] &&
      document.forms[('loginForm': any)].reset();
    isResetPassword
      ? this.setState({
          isResetPassword: !isResetPassword,
          isEmailSent: false
        })
      : this.setState({ isSignUp: !isSignUp });
  };

  resetErrorMsg = () => {
    this.resetErrorMessage();
  };

  toggleResetPassword = () => {
    this.resetErrorMessage();
    document.forms[('loginForm': any)] &&
      document.forms[('loginForm': any)].reset();
    this.setState(prevState => ({
      isResetPassword: !prevState.isResetPassword,
      errorMsgFirebase: ''
    }));
  };

  toggle = (e: SyntheticEvent<HTMLButtonElement>) => {
    this.resetErrorMsg();
  };

  createPaymentMethodAndCustomer = async (
    cardNumber: Object,
    email: string
  ) => {
    const { isLoading } = this.state;
    stripe
      .createPaymentMethod({
        type: 'card',
        card: cardNumber,
        billing_details: { name: this.props.user.uid }
      })
      .then(result => {
        console.log('result', result);
        const language = window.navigator.language;
        const currency = getCurrency(language)
          ? getCurrency(language).toUpperCase()
          : CURRENCY.USD;
        handleGTMDataLayerClassComponent(this.props, currency);
        if (result.error) {
          alert(result.error.message);
        } else {
          this.setState({ isLoading: !isLoading });
          this.createCustomer(result.paymentMethod.id, email, {
            firebaseUserID: this.props.user.uid
          });
        }
      });
  };

  createCustomer = async (
    paymentMethod: string,
    cardholderEmail: string,
    metadata: Object
  ) => {
    const { location } = this.props;
    const { search } = location;
    const packageName = search.match(/package=([^&]*)/);
    const { functions } = this.props;
    const { productId, currency } = this.state;
    const packageNameId = packageName && packageName[1];
    const currentCampaignName = getLocalStorage(PROMO_NAME) || '';
    axios
      .post(
        `${functions}/validateLingWeb-stripeApi/create-customer/${packageNameId}`,
        {
          email: cardholderEmail,
          payment_method: paymentMethod,
          priceId: productId,
          currency: currency,
          promotionalCampaign: currentCampaignName,
          metadata
        }
      )
      .then(subscription => {
        this.handleSubscription(subscription, cardholderEmail);
      })
      .catch(error => {
        console.log('error: do parsing', error);
      });
  };

  handleSubscription = (subscription: Object, cardholderEmail: string) => {
    const { affiliateId, currency, isLoading } = this.state;
    const { isDev, location } = this.props;
    const { search } = location;
    const { data } = subscription;
    const { code } = data;
    const packageName = search.match(/package=([^&]*)/);
    this.setRedirectThankyouURL();
    if (code === 200) {
      const { invoice, subscription } = data;
      if (invoice) {
        const { status } = invoice;
        if (status === CARD_PAID) {
          if (affiliateId.length) {
            console.log('sending to amplitude');
            sendAmplitudeData('affiliate_purchase', {
              affiliateId: affiliateId[0]
            });
          }
          redirectLogin(
            `offer_stripe_${packageName && packageName[1]}`,
            isDev,
            currency,
            invoice.id,
            cardholderEmail
          );
        }
      } else {
        const { status } = subscription;
        if (status === CARD_SUCCEEDED) {
          if (affiliateId.length) {
            console.log('sending to amplitude');
            sendAmplitudeData('affiliate_purchase', {
              affiliateId: affiliateId[0]
            });
          }
          redirectLogin(
            `offer_stripe_${packageName && packageName[1]}`,
            isDev,
            currency,
            subscription.id,
            cardholderEmail
          );
        }
      }
    } else {
      const { raw } = data;
      const { message } = raw;
      alert(message);
      this.setState({ isLoading: !isLoading });
      purchaseFailed();
    }
  };

  setRedirectThankyouURL = () => {
    const { yearly_25, yearly_40 } = this.props;
    const { productId } = this.state;
    // Currently, there is no way to distinguish between promo product for Ling & Ling Live
    // We will have to change the URL for now to either:
    // - "https://ling-app.com/thank-you"
    // - "https://ling-app.com/thank-you-ling-live"
    // - "" for no redirect
    if (_.includes([yearly_25, yearly_40], productId)) {
      const thankyouURL = '';
      setLocalStorage(
        LOCAL_STORAGE_CONSTANTS.REDIRECT_THANKYOU_URL,
        thankyouURL
      );
    }
  };

  redirectToStripe = () => {
    const { location, functions, updateUserData } = this.props;
    const { search } = location;
    const { isDev, user } = this.props;
    const { facebook, apple, google, email } = user;
    const { itemInfo } = this.state;
    const language = window.navigator.language;
    const currency = getCurrency(language)
      ? getCurrency(language).toUpperCase()
      : CURRENCY.USD;
    const redeemId = search.match(/redeemCode=([^&]*)/)
      ? search.match(/redeemCode=([^&]*)/)[1]
      : null;
    let userEmail = '';
    if (facebook) {
      userEmail = facebook.email;
    } else if (google) {
      userEmail = google.email;
    } else if (email) {
      userEmail = email.email;
    } else if (apple) {
      userEmail = apple.email;
    }
    if (
      redeemId === 'monthly' ||
      redeemId === 'yearly' ||
      redeemId === 'lifetime'
    ) {
      stripe
        .redirectToCheckout({
          items: itemInfo,
          customerEmail: userEmail,
          // Do not rely on the redirect to the successUrl for fulfilling
          // purchases, customers may not always reach the success_url after
          // a successful payment.
          // Instead use one of the strategies described in
          // https://stripe.com/docs/payments/checkout/fulfillment
          successUrl: `${redirect(
            `offer_${this.state.productName}`,
            isDev,
            currency
          )}`,
          cancelUrl: `${purchaseFailed()}`
        })
        .then(function(result) {
          const { error } = result;
          if (error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer.
            alert(error.message);
          }
        });
    } else {
      if (
        redeemId !== 'monthly' &&
        redeemId !== 'yearly' &&
        redeemId !== 'lifetime'
      ) {
        axios
          .post(`${functions}${couponAPI}`, {
            uid: this.props.user.uid,
            couponCode: redeemId
          })
          .then(res => {
            if (res.status === 200) {
              !res.data.success &&
                alert(
                  'This coupon is already redeemed. Please contact support@simyasolutions.com if you have not redeemed this coupon before.'
                );
              updateUserData({ subscription: res.data.subscription });
              isMobile()
                ? (window.location = 'https://ling-app.com/redeem-success/')
                : this.props.history.push('/');
            }
          })
          .catch(err => BugTracker.notify(err));
      }
    }
  };
  getPriceNumber = (productName: string) => {
    const { monthPrice, currencySymbol, currencyCode } = this.state;
    return (
      getProductCurrencySymbol(currencyCode, currencySymbol.symbol) +
      monthPrice / 100
    );
  };
  render() {
    const {
      login_field_name,
      login_field_firstName,
      login_msg_reenterPassword,
      login_field_email,
      login_field_password,
      btn_continue,
      reset_password_txt_description,
      sign_up_txt_title,
      login_txt_title,
      email_txt_loginNow_link,
      claim_warning_txt,
      already_register_txt,
      login_txt_forget_password_title,
      login_txt_forget_password_web,
      dont_have_account_yet_txt,
      at_least_6_password_txt,
      functions,
      card_number,
      card_expiration,
      email_error_empty,
      card_error_empty,
      card_error_number_not_correct,
      trial_date_description_txt,
      card_error_invalid_date,
      card_error_invalid_cvc,
      shop_txt_month,
      shop_txt_year,
      purchase_txt_option_lifeTime,
      shop_txt_checkout_title_top,
      email_error_wrongFormat,
      random_test_modal_back_txt,
      profile_txt_name_card,
      profile_txt_card_number,
      profile_txt_expire_date,
      profile_txt_recurring_billing,
      profile_txt_terms_condition,
      btn_submit,
      redeem_gift_txt,
      get_7_days_free_trial,
      unlock_60_languages,
      get_txt,
      off_txt,
      to_txt,
      setTrialUsedIP,
      expoligua_msg_login,
      claim_txt_first_trial_free,
      claim_txt_free_trial_auto_converted,
      claim_txt_cancel_before_trial_end,
      txt_email_field_placeholder
    } = this.props;
    const {
      modalBodyContainer,
      inputGroup,
      inputContainer,
      labelStyle,
      signUpStyle,
      space,
      loginFormSpaceBottom,
      otherLoginGroup,
      otherLoginButton,
      googleIconStyle,
      appleIconStyle
    } = styles;
    const {
      isSignUp,
      errorMsgFirebase,
      isResetPassword,
      isEmailSent,
      successMsg,
      isLoading,
      err,
      emailErrorMsg,
      userForm,
      formErrorMsg,
      passwordErrorMsg,
      redeemId,
      isCheckout,
      id,
      packageName,
      monthPrice,
      promoName,
      isExpolingua
    } = this.state;
    const productName = _.get(this.props, 'match.params.type', false);
    return (
      <Container isCheckout={isCheckout}>
        {!isCheckout ||
          (getLocalStorage('redeemCode') && (
            <GiftFireworkStyle src={giftFirework} alt={giftFirework} />
          ))}
        <ContentContainer>
          {isLoading ? (
            <SpinnerContainer>
              <img src={lingLoading} alt={lingLoading} />
            </SpinnerContainer>
          ) : isCheckout && !getLocalStorage('redeemCode') ? (
            <CheckoutFormContainer>
              {parseInt(promoName, 10) === 0 &&
                packageName === STRIPE_YEARLY && (
                  <LeftEmptySpace></LeftEmptySpace>
                )}
              <CheckoutContainer>
                <CheckoutForm
                  isClaim={true}
                  login_field_email={login_field_email}
                  card_number={card_number}
                  card_expiration={card_expiration}
                  email_error_empty={email_error_empty}
                  card_error_empty={card_error_empty}
                  email_error_wrongFormat={email_error_wrongFormat}
                  card_error_number_not_correct={card_error_number_not_correct}
                  card_error_invalid_date={card_error_invalid_date}
                  card_error_invalid_cvc={card_error_invalid_cvc}
                  login_field_name={login_field_name}
                  functions={functions}
                  toggleIsCheckout={this.toggleIsCheckout}
                  packageName={packageName}
                  shop_txt_checkout_title={
                    packageName === STRIPE_MONTHY
                      ? '1 ' + shop_txt_month
                      : packageName === STRIPE_YEARLY
                      ? '1 ' + shop_txt_year
                      : purchase_txt_option_lifeTime
                  }
                  shop_txt_checkout_price={
                    monthPrice === 0 ? this.getProductInfo() : monthPrice
                  }
                  trial_date_description_txt={trial_date_description_txt}
                  shop_txt_checkout_title_top={
                    parseInt(promoName, 10) !== 0
                      ? ` <span>${parseInt(
                          promoName,
                          10
                        )}% OFF</span> for 60+ Languages`
                      : packageName === STRIPE_YEARLY
                      ? '7 day <span>Free</span> for 60+ Languages'
                      : shop_txt_checkout_title_top
                  }
                  random_test_modal_back_txt={random_test_modal_back_txt}
                  profile_txt_name_card={profile_txt_name_card}
                  profile_txt_card_number={profile_txt_card_number}
                  profile_txt_expire_date={profile_txt_expire_date}
                  profile_txt_recurring_billing={profile_txt_recurring_billing}
                  createPaymentMethodAndCustomer={
                    this.createPaymentMethodAndCustomer
                  }
                  profile_txt_terms_condition={profile_txt_terms_condition}
                  btn_submit={btn_submit}
                  setTrialUsedIP={setTrialUsedIP}
                  isShowingPaypal={false}
                  handlePayWithPayPal={() => {}}
                  txt_email_field_placeholder={txt_email_field_placeholder}
                />
              </CheckoutContainer>
              {parseInt(promoName, 10) === 0 &&
                packageName === STRIPE_YEARLY && (
                  <CheckoutFormHeader
                    isHeader={false}
                    claim_txt_first_trial_free={claim_txt_first_trial_free}
                    claim_txt_free_trial_auto_converted={
                      claim_txt_free_trial_auto_converted
                    }
                    claim_txt_cancel_before_trial_end={
                      claim_txt_cancel_before_trial_end
                    }
                  />
                )}
            </CheckoutFormContainer>
          ) : (
            <Fragment>
              <HeaderTitle>
                <Logo src={lingAppShadowIcon} />
                <Title
                  dangerouslySetInnerHTML={{
                    __html: isExpolingua
                      ? `${get_txt} 14 days free </br> <span style="font-weight: normal;">${to_txt} access 60+ languages</span>`
                      : redeemId !== ''
                      ? redeem_gift_txt
                      : PROMO_NAME_LIST.includes(getLocalStorage(PROMO_NAME))
                      ? `${get_7_days_free_trial}<br/><span style="font-weight: normal;">${unlock_60_languages}</span>`
                      : `${get_txt} ${id}% ${off_txt}</br> <span style="font-weight: normal;">${to_txt} ${unlock_60_languages}</span>`
                  }}
                />
              </HeaderTitle>
              <FormContainer>
                {!isEmailSent && (
                  <FormHeader>
                    {isResetPassword
                      ? login_txt_forget_password_title
                      : isSignUp
                      ? sign_up_txt_title
                      : login_txt_title}
                  </FormHeader>
                )}
                {isExpolingua && (
                  <WarningText>{expoligua_msg_login}</WarningText>
                )}
                {productName === 'offer_stripe_yearly' && (
                  <WarningText>{claim_warning_txt}</WarningText>
                )}
                <ModalContentContainer>
                  <ModalBodyContainer>
                    <ModalBody style={modalBodyContainer}>
                      <ModalBodyText>
                        {isResetPassword ? (
                          <Fragment>
                            {isEmailSent ? (
                              <ResetPasswordContainer>
                                <ResetPasswordImg
                                  src={dangerIcon}
                                  alt={dangerIcon}
                                />
                                <ModalBodyText
                                  dangerouslySetInnerHTML={{
                                    __html: reset_password_txt_description
                                  }}
                                />
                                <div>
                                  <ComfirmButton
                                    onClick={() => this.closeEmailSent()}
                                    className="confirmButton"
                                  >
                                    <img src={confirmIcon} alt={confirmIcon} />
                                  </ComfirmButton>
                                </div>
                              </ResetPasswordContainer>
                            ) : (
                              <form
                                id="loginForm"
                                onSubmit={e =>
                                  this.handleResetPasswordSubmit(e)
                                }
                              >
                                <div>
                                  <div style={inputGroup}>
                                    <InputGroup style={inputContainer}>
                                      <InputLabel
                                        htmlFor="email"
                                        style={labelStyle}
                                        isError={err}
                                        errorMsg={emailErrorMsg}
                                      >
                                        {login_field_email}
                                      </InputLabel>
                                      <InputPlace
                                        placeholder={
                                          txt_email_field_placeholder
                                        }
                                        id="email"
                                        type="text"
                                        onChange={event =>
                                          this.setState({
                                            userForm: {
                                              ...userForm,
                                              email: event.target.value
                                            }
                                          })
                                        }
                                      />
                                      <ErrorTextStyle>
                                        {err && emailErrorMsg !== ''
                                          ? emailErrorMsg
                                          : null}
                                        {errorMsgFirebase
                                          ? errorMsgFirebase
                                          : null}
                                      </ErrorTextStyle>
                                    </InputGroup>
                                  </div>
                                  <ErrorTextStyle>
                                    {err && formErrorMsg !== ''
                                      ? formErrorMsg
                                      : null}
                                  </ErrorTextStyle>
                                </div>
                                {successMsg ? successMsg : null}
                                <ContinueContainer>
                                  <LoginStyle type="submit">
                                    {btn_continue}
                                  </LoginStyle>
                                </ContinueContainer>
                              </form>
                            )}
                          </Fragment>
                        ) : (
                          <Fragment>
                            {isSignUp ? (
                              <Fragment>
                                <form
                                  id="loginForm"
                                  onSubmit={e => this.handleSignUpSubmit(e)}
                                >
                                  <div style={inputGroup}>
                                    <InputGroup style={inputContainer}>
                                      <label htmlFor="name" style={labelStyle}>
                                        {login_field_name}
                                      </label>
                                      <InputPlace
                                        placeholder={login_field_firstName}
                                        id="name"
                                        onChange={event1 =>
                                          this.setState({
                                            userForm: {
                                              ...userForm,
                                              name: event1.target.value
                                            }
                                          })
                                        }
                                      />
                                      <InputErrorTextStyle />
                                    </InputGroup>
                                    <InputGroup
                                      className="border-top"
                                      style={inputContainer}
                                    >
                                      <InputLabel
                                        htmlFor="email"
                                        style={labelStyle}
                                        isError={err}
                                        errorMsg={emailErrorMsg}
                                      >
                                        {login_field_email}
                                      </InputLabel>
                                      <InputPlace
                                        placeholder={
                                          txt_email_field_placeholder
                                        }
                                        id="email"
                                        onChange={event =>
                                          this.setState({
                                            userForm: {
                                              ...userForm,
                                              email: event.target.value
                                            }
                                          })
                                        }
                                      />
                                      <InputErrorTextStyle>
                                        {err && emailErrorMsg !== ''
                                          ? emailErrorMsg
                                          : null}
                                        {errorMsgFirebase
                                          ? errorMsgFirebase
                                          : null}
                                      </InputErrorTextStyle>
                                    </InputGroup>
                                    <InputGroup
                                      className="border-top"
                                      style={inputContainer}
                                    >
                                      <InputLabel
                                        htmlFor="password"
                                        style={labelStyle}
                                        isError={err}
                                        errorMsg={passwordErrorMsg}
                                      >
                                        {login_field_password}
                                      </InputLabel>
                                      <InputPlace
                                        placeholder={at_least_6_password_txt}
                                        id="password"
                                        type="password"
                                        onChange={event =>
                                          this.setState({
                                            userForm: {
                                              ...userForm,
                                              password: event.target.value
                                            }
                                          })
                                        }
                                      />
                                      <InputErrorTextStyle>
                                        {err && passwordErrorMsg !== ''
                                          ? passwordErrorMsg
                                          : null}
                                      </InputErrorTextStyle>
                                    </InputGroup>
                                    <InputGroup
                                      className="border-top"
                                      style={inputContainer}
                                    >
                                      <label
                                        htmlFor="confirmPassword"
                                        style={labelStyle}
                                      >
                                        {login_msg_reenterPassword}
                                      </label>
                                      <InputPlace
                                        placeholder={at_least_6_password_txt}
                                        id="confirmPassword"
                                        type="password"
                                        onChange={event =>
                                          this.setState({
                                            userForm: {
                                              ...userForm,
                                              confirmPassword:
                                                event.target.value
                                            }
                                          })
                                        }
                                      />
                                    </InputGroup>
                                  </div>
                                  <ErrorTextStyle>
                                    {err && formErrorMsg !== ''
                                      ? formErrorMsg
                                      : null}
                                  </ErrorTextStyle>
                                  <LoginButtonContainer>
                                    <LoginStyle type="submit">
                                      {' '}
                                      {sign_up_txt_title}
                                    </LoginStyle>
                                  </LoginButtonContainer>
                                </form>
                                <div style={otherLoginGroup}>
                                  <SocialButton
                                    style={{
                                      ...otherLoginButton,
                                      ...appleIconStyle
                                    }}
                                    onClick={() => this.loginAppleRedirect()}
                                  >
                                    <img src={appleIcon} alt={appleIcon} />
                                  </SocialButton>
                                  <SocialButton
                                    style={{ ...otherLoginButton }}
                                    onClick={() => this.loginFbRedirect()}
                                  >
                                    <img src={fbIcon} alt={fbIcon} />
                                  </SocialButton>
                                  <SocialButton
                                    style={{
                                      ...otherLoginButton,
                                      ...googleIconStyle
                                    }}
                                    onClick={() => this.loginGoogleRedirect()}
                                  >
                                    <img src={googleIcon} alt={googleIcon} />
                                  </SocialButton>
                                </div>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <form
                                  id="loginForm"
                                  onSubmit={e => this.handleLoginSubmit(e)}
                                >
                                  <InputGroupContainer>
                                    <InputGroup style={inputContainer}>
                                      <InputLabel
                                        htmlFor="email"
                                        style={labelStyle}
                                        isError={err}
                                        errorMsg={emailErrorMsg}
                                      >
                                        {login_field_email}
                                      </InputLabel>
                                      <InputPlace
                                        placeholder={
                                          txt_email_field_placeholder
                                        }
                                        id="email1"
                                        type="text"
                                        className="InputPlace"
                                        onChange={event =>
                                          this.setState({
                                            userForm: {
                                              ...userForm,
                                              email: event.target.value
                                            }
                                          })
                                        }
                                      />
                                      <InputErrorTextStyle>
                                        {err && emailErrorMsg !== ''
                                          ? emailErrorMsg
                                          : null}
                                      </InputErrorTextStyle>
                                    </InputGroup>
                                    <InputGroup
                                      type="email"
                                      className="border-top"
                                      style={inputContainer}
                                    >
                                      <InputLabel
                                        htmlFor="password"
                                        style={labelStyle}
                                        isError={err}
                                        errorMsg={passwordErrorMsg}
                                      >
                                        {login_field_password}
                                      </InputLabel>
                                      <InputPlace
                                        className="InputPlace"
                                        type="password"
                                        placeholder={at_least_6_password_txt}
                                        onChange={event =>
                                          this.setState({
                                            userForm: {
                                              ...userForm,
                                              password: event.target.value
                                            }
                                          })
                                        }
                                      />
                                      <InputErrorTextStyle>
                                        {err && passwordErrorMsg !== ''
                                          ? passwordErrorMsg
                                          : null}
                                        {errorMsgFirebase
                                          ? errorMsgFirebase
                                          : null}
                                      </InputErrorTextStyle>
                                    </InputGroup>
                                  </InputGroupContainer>
                                  <ErrorTextStyle>
                                    {err && formErrorMsg !== ''
                                      ? formErrorMsg
                                      : null}
                                  </ErrorTextStyle>
                                  <ForgotTextStyle
                                    id="ForgotTextStyle"
                                    onClick={() => this.toggleResetPassword()}
                                  >
                                    {login_txt_forget_password_web}
                                  </ForgotTextStyle>
                                  <div style={space} />
                                  <LoginButtonContainer>
                                    <LoginStyle type="submit">
                                      {' '}
                                      {isSignUp
                                        ? sign_up_txt_title
                                        : login_txt_title}
                                    </LoginStyle>
                                  </LoginButtonContainer>
                                </form>
                                <div style={otherLoginGroup}>
                                  <SocialButton
                                    style={{
                                      ...otherLoginButton,
                                      ...appleIconStyle
                                    }}
                                    onClick={() => this.loginAppleRedirect()}
                                  >
                                    <img src={appleIcon} alt={appleIcon} />
                                  </SocialButton>
                                  <SocialButton
                                    style={{ ...otherLoginButton }}
                                    onClick={() => this.loginFbRedirect()}
                                  >
                                    <img src={fbIcon} alt={fbIcon} />
                                  </SocialButton>
                                  <SocialButton
                                    style={{
                                      ...otherLoginButton,
                                      ...googleIconStyle
                                    }}
                                    onClick={() => this.loginGoogleRedirect()}
                                  >
                                    <img src={googleIcon} alt={googleIcon} />
                                  </SocialButton>
                                </div>
                              </Fragment>
                            )}
                          </Fragment>
                        )}
                      </ModalBodyText>
                      {!isEmailSent && (
                        <SignUpSuggestionStyle>
                          {isResetPassword || isSignUp ? (
                            <BottomText>
                              {already_register_txt}{' '}
                              <LoginLink
                                id="LoginLink"
                                style={signUpStyle}
                                onClick={() => this.toggleSignUp()}
                              >
                                {email_txt_loginNow_link}
                              </LoginLink>
                            </BottomText>
                          ) : (
                            <BottomText>
                              {dont_have_account_yet_txt}{' '}
                              <LoginLink
                                id="LoginLink"
                                style={signUpStyle}
                                onClick={() => this.toggleSignUp()}
                              >
                                {sign_up_txt_title}
                              </LoginLink>
                            </BottomText>
                          )}
                          <div style={loginFormSpaceBottom} />
                        </SignUpSuggestionStyle>
                      )}
                    </ModalBody>
                  </ModalBodyContainer>
                </ModalContentContainer>
              </FormContainer>
            </Fragment>
          )}
        </ContentContainer>
      </Container>
    );
  }
}

export { ClaimScene };
