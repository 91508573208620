/* @flow */
import React, { Component } from 'react';
import styled from 'styled-components';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import Analytic from '../../../util/Analytic';
import {
  PREFIX_LESSON,
  MARIGOLD,
  GREYISH_BROWN,
  IMAGE_PATH,
  PREFIX_EXAM,
  EGG_YELLOW,
  WHITE,
  LIGHT_GREY_TRANSCRIPT_2,
  PREFIX_HEADER,
  GREY,
  BLACK,
  VERY_LIGHT_PINK,
  DARK_GREY_1,
  VERY_LIGHT_PINK_THREE
} from '../../../common/constants';
import { ShowPerfect } from './ShowPerfect';
import { ReplayButton } from '../sub-components/ReplayButton';
import MaterialButton from '../../../components/sub-components/MaterialButton';

const lingNoSuccess = `${PREFIX_LESSON}Monkey-failed.png`;
const lingSuccess = `${PREFIX_LESSON}Monkey-success.png`;
const redo = `${PREFIX_LESSON}redo-button-60x60.png`;
const logo = `${IMAGE_PATH}Logo.png`;
const lingTutorial = `${PREFIX_EXAM}master-ling-crown-complete.png`;
const lingDancingBG = `${PREFIX_LESSON}masterling-dancing-bg-img.png`;
const Container = styled.div`
  height: ${props =>
    !props.isChatbot && (props.isShowTutorial || props.showTutorial)
      ? 'calc(100vh - 100px)'
      : '590px'};
  display: flex;
  flex-direction: column;
  border-radius: ${props =>
    !props.isChatbot && (props.isShowTutorial || props.showTutorial)
      ? 'unset'
      : '50px'};
  transform: ${props =>
    !props.isChatbot && (props.isShowTutorial || props.showTutorial)
      ? 'unset'
      : 'translate(-50%,-50%)'};
  top: ${props =>
    !props.isChatbot && (props.isShowTutorial || props.showTutorial)
      ? 'unset'
      : '50%'};
  left: ${props =>
    !props.isChatbot && (props.isShowTutorial || props.showTutorial)
      ? 'unset'
      : '50%'};
  position: ${props =>
    !props.isChatbot && (props.isShowTutorial || props.showTutorial)
      ? 'unset'
      : 'absolute'};
  background-color: ${EGG_YELLOW};
  box-shadow: ${props =>
    !props.isChatbot && (props.isShowTutorial || props.showTutorial)
      ? 'unset'
      : '0 2px 20px 0 rgba(0, 0, 0, 0.2)'};
`;

const ContentContainer = styled.div`
  height: ${props =>
    !props.isChatbot && (props.isShowTutorial || props.showTutorial)
      ? 'unset'
      : '281px'};
  display: flex;
  flex: ${props =>
    !props.isChatbot && (props.isShowTutorial || props.showTutorial)
      ? '0.8'
      : 'unset'};
  flex-direction: column;
  align-items: center;
  padding: 20px;
  justify-content: ${props =>
    !props.isChatbot && (props.isShowTutorial || props.showTutorial)
      ? 'space-evenly'
      : 'unset'};
  border-radius: ${props =>
    !props.isChatbot && (props.isShowTutorial || props.showTutorial)
      ? '10px 10px 0 0'
      : '50px 50px 0 0'};
  background: ${props =>
    !props.isChatbot && (props.isShowTutorial || props.showTutorial)
      ? MARIGOLD
      : WHITE};
`;

const TitleText = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: ${GREYISH_BROWN};
  align: center;
  margin: 16px 0 0 0 !important;
`;

const ContentCenterContainer = styled.div`
  display: flex;
  position: relative;
`;

const BackgroundImage = styled.img`
  position: relative;
`;

const InnerImage = styled.img`
  height: 90px;
  top: 133px;
  left: -37px;
`;

const LingDanceStyle = styled.img`
  width: 487px;
  height: 226px;
  margin: 0 auto;
`;

const ContentImageContainer = styled.div`
  width: 100%;
  position: relative;
  justify-content: center;
  display: ${props =>
    !props.isChatbot && (props.isShowTutorial || props.showTutorial)
      ? 'none'
      : 'flex'};
  border-top: ${props =>
    props.isChatbot || !props.isShowTutorial
      ? `1px ${VERY_LIGHT_PINK_THREE} solid `
      : 'unset'};
`;

const ContentImage = styled.img`
  width: 60px;
  height: 60px;
  margin: 0 20px;
  position: absolute;
  transform: translateX(-50%);
  &:hover {
    cursor: pointer;
  }
`;

const FooterContainer = styled.div`
  justify-content: ${props =>
    !props.isChatbot && props.isShowTutorial ? 'unset' : 'space-between'};
  padding-left: 35px;
  padding-right: 35px;
  display: flex;
  flex: 0.2;
  align-items: center;
  justify-content: ${props =>
    !props.isChatbot && props.isShowTutorial ? 'center' : 'space-between'};
  position: ${props =>
    !props.isChatbot && props.isShowTutorial ? 'unset' : 'absolute'};
  bottom: ${props => (!props.isChatbot && props.isShowTutorial ? 'unset' : 0)};
  width: ${props =>
    !props.isChatbot && props.isShowTutorial ? 'unset' : '514px'};
  height: ${props =>
    !props.isChatbot && props.isShowTutorial ? 'unset' : '127px'};
  border-radius: ${props =>
    !props.isChatbot && props.isShowTutorial ? 'unset' : '25px'};
  box-shadow: ${props =>
    !props.isChatbot && props.isShowTutorial
      ? 'unset'
      : `0 2px 5px 0 rgba(0, 0, 0, 0.2),
    inset 0 -4px 0 0 rgba(0, 0, 0, 0.5)`};
  background-color: ${props =>
    !props.isChatbot && props.isShowTutorial ? WHITE : LIGHT_GREY_TRANSCRIPT_2};
`;

const OtherShareGroup = styled.div`
  margin-bottom: 25px;
`;
const SocialButton = styled.button`
  border-radius: 22px;
  padding: 0px;
  width: 112px;
  height: 42px;
  background: ${WHITE};
  color: ${BLACK};
  box-shadow: inset 0 -4px 0 0 ${VERY_LIGHT_PINK};
  border: ${GREY} solid 1px;
  margin: 0 6px;
  margin-top: 5px;
  margin-right: ${props => (props.isFBButton ? 6 : 0)}px;
  margin-left: ${props => (props.isFBButton ? 0 : 6)}px;
  &:hover {
    box-shadow: none;
    opacity: 0.4;
  }
  &:active {
    box-shadow: none;
    opacity: 1;
  }
`;
const Icon = styled.img`
  width: 23px;
`;
const ShareToText = styled.p`
  margin: 0px !important;
  margin-top: 10px !important;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: ${DARK_GREY_1};
  &:first-letter {
    text-transform: capitalize;
  }
`;
type Props = {
  show: boolean,
  wrongAnswered?: number,
  onNext: () => void,
  examFailed?: boolean,
  coinPerfect?: boolean,
  onReplay: () => void,
  complete_txt_failExam?: string,
  complete_txt_earned: string,
  score?: number,
  showCompleteLabel: string,
  txt_result_tutorial: string,
  chatbot_txt_complete_title: string,
  landing_txt_connectInternet: string,
  btn_continue: string,
  isChatbot?: boolean,
  goToStore?: () => void,
  chatbot_txt_complete_body: string,
  isRandomTest?: boolean,
  complete_txt_earned: string,
  isShowTutorial: boolean,
  showTutorial: boolean,
  tutorial_tooltips_btn: string,
  promo_txt_congrats: string,
  isExam: boolean,
  btn_replay: string,
  game_txt_you_got: string,
  game_txt_keep_up_good_work: string,
  game_txt_and_1_coin: string,
  complete_screen_share_txt: string,
  inviteMessage: string,
  isSpeaking: boolean,
  isWriting: boolean,
  gameSkipCount: number
};

const fbIcon = `${PREFIX_HEADER}f-logo-login.svg`;
const twitterIcon = `${PREFIX_HEADER}twitter-logo.svg`;

export class CompleteScreen extends Component<Props> {
  componentDidMount() {
    // $flow-disable-line
    document.addEventListener('keydown', this.handleEnter);
  }
  componentWillUnmount() {
    // $flow-disable-line
    document.removeEventListener('keydown', this.handleEnter);
  }
  handleEnter = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { onNext } = this.props;
    if (event.keyCode === 13) {
      if (onNext) {
        onNext();
      }
    }
  };

  render() {
    const {
      chatbot_txt_complete_body = '',
      chatbot_txt_complete_title,
      complete_txt_failExam = '',
      complete_txt_earned = '',
      wrongAnswered = 0,
      examFailed = false,
      isExam,
      onNext = () => {},
      onReplay = () => {},
      score = 0,
      showCompleteLabel,
      txt_result_tutorial,
      btn_continue,
      isChatbot,
      isRandomTest = false,
      isShowTutorial,
      showTutorial,
      tutorial_tooltips_btn,
      btn_replay,
      game_txt_you_got,
      game_txt_keep_up_good_work,
      game_txt_and_1_coin,
      promo_txt_congrats,
      complete_screen_share_txt,
      inviteMessage,
      isSpeaking,
      isWriting,
      gameSkipCount
    } = this.props;
    const imageSuccess = () =>
      examFailed
        ? lingNoSuccess
        : !isChatbot && (isShowTutorial || showTutorial)
        ? lingTutorial
        : lingSuccess;
    if (examFailed) {
      Analytic.log(Analytic.key.L6, {
        category: Analytic.key.L6,
        action: 'Check'
      });
      Analytic.logGA(Analytic.key.L6, {
        category: Analytic.key.L6,
        action: 'Check'
      });
    }

    const styles = {
      button: { color: 'common.white', fontWeight: 'bold', py: 3, px: 5 }
    };

    return (
      <Container
        id="1"
        isChatbot={isChatbot}
        isShowTutorial={isShowTutorial}
        showTutorial={showTutorial}
      >
        {(isChatbot || !isShowTutorial) && (
          <LingDanceStyle src={lingDancingBG} />
        )}
        <ContentContainer
          isChatbot={isChatbot}
          isShowTutorial={isShowTutorial}
          showTutorial={showTutorial}
          examFailed={examFailed}
        >
          <TitleText>
            {isChatbot
              ? chatbot_txt_complete_title
              : examFailed
              ? complete_txt_failExam.toUpperCase()
              : !isChatbot && isShowTutorial
              ? txt_result_tutorial.toUpperCase()
              : isExam || isRandomTest
              ? showCompleteLabel.toUpperCase()
              : promo_txt_congrats.toUpperCase()}
          </TitleText>
          <ContentCenterContainer>
            {!isChatbot && isShowTutorial ? (
              <BackgroundImage src={imageSuccess()} alt="lingImage" />
            ) : null}
            {examFailed ? (
              <InnerImage src={logo} alt="logo" />
            ) : !isChatbot && (isShowTutorial || showTutorial) ? null : (
              <ShowPerfect
                isSpeaking={isSpeaking}
                isWriting={isWriting}
                gameSkipCount={gameSkipCount}
                isExam={isExam}
                isChatbot={isChatbot}
                isShowTutorial={isShowTutorial}
                game_txt_you_got={game_txt_you_got}
                game_txt_and_1_coin={game_txt_and_1_coin}
                game_txt_keep_up_good_work={
                  isChatbot
                    ? chatbot_txt_complete_body
                    : game_txt_keep_up_good_work
                }
                wrongAnswered={wrongAnswered}
                examFailed={examFailed}
                isRandomTest={isRandomTest}
                score={score}
                complete_txt_earned={complete_txt_earned}
              />
            )}
          </ContentCenterContainer>
          <ContentImageContainer
            isChatbot={isChatbot}
            isShowTutorial={isShowTutorial}
            showTutorial={showTutorial}
          >
            <OtherShareGroup>
              <ShareToText>{complete_screen_share_txt}</ShareToText>
              <div>
                <FacebookShareButton
                  url={'https://ling-app.com/'}
                  quote={inviteMessage}
                >
                  <SocialButton isFBButton={true}>
                    <img src={fbIcon} alt={fbIcon} />
                  </SocialButton>
                </FacebookShareButton>
                <TwitterShareButton
                  url={'https://ling-app.com/'}
                  title={inviteMessage}
                >
                  <SocialButton isFBButton={false}>
                    <Icon src={twitterIcon} alt={twitterIcon} />
                  </SocialButton>
                </TwitterShareButton>
              </div>
            </OtherShareGroup>
            {!isChatbot && isShowTutorial && (
              <ContentImage onClick={onReplay} src={redo} alt="redo" />
            )}
          </ContentImageContainer>
        </ContentContainer>
        <FooterContainer isChatbot={isChatbot} isShowTutorial={isShowTutorial}>
          {!(isShowTutorial && showTutorial) && (
            <ReplayButton onNext={onReplay} btn_replay={btn_replay} />
          )}
          <MaterialButton
            sx={styles.button}
            size="large"
            buttonText={
              !isChatbot && (isShowTutorial || showTutorial)
                ? tutorial_tooltips_btn
                : btn_continue
            }
            clickAction={onNext}
          />
        </FooterContainer>
      </Container>
    );
  }
}
