/* @flow */
// $FlowFixMe for useState, useEffect
import { getLocalStorage } from '.';
// $FlowFixMe for useParams
import _ from 'lodash';
import { LOCAL_STORAGE_CONSTANTS } from '.';

export const handleGTMDataLayerClassComponent = (
  props: Object,
  currency: string
) => {
  const receipt = _.get(props, 'match.params.receipt', false);
  const productName = _.get(props, 'match.params.type', false);
  const promoName = getLocalStorage(LOCAL_STORAGE_CONSTANTS.PROMO_NAME);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'purchase_ga-ua',
    event_action: 'purchase',
    event_category: 'ecommerce',
    event_label: receipt,
    event_value: getLocalStorage(LOCAL_STORAGE_CONSTANTS.REVENUE),
    userId: 'anonymous',
    ecommerce: {
      currencyCode: currency,
      purchase: {
        actionField: {
          affiliation: 'Web',
          coupon: promoName,
          id: receipt,
          revenue: getLocalStorage(LOCAL_STORAGE_CONSTANTS.REVENUE)
        },
        products: [
          {
            name: productName,
            id: getLocalStorage(LOCAL_STORAGE_CONSTANTS.PRODUCT_ID),
            price: getLocalStorage(LOCAL_STORAGE_CONSTANTS.REVENUE),
            category: getLocalStorage(LOCAL_STORAGE_CONSTANTS.CATEGORY),
            variant: '',
            quantity: 1
          }
        ]
      }
    }
  });

  window.dataLayer.push({
    event: 'purchase_ga4',
    userId: 'anonymous',
    ecommerce: {
      affiliation: 'Web',
      coupon: promoName,
      currency: currency,
      items: [
        {
          affiliation: 'Web',
          coupon: promoName,
          currency: currency,
          discount: '',
          index: 0,
          item_brand: 'ling-app',
          item_category: getLocalStorage(LOCAL_STORAGE_CONSTANTS.CATEGORY),
          item_id: getLocalStorage(LOCAL_STORAGE_CONSTANTS.PRODUCT_ID),
          item_name: productName,
          price: getLocalStorage(LOCAL_STORAGE_CONSTANTS.REVENUE),
          quantity: 1
        }
      ],
      transaction_id: receipt,
      value: getLocalStorage(LOCAL_STORAGE_CONSTANTS.REVENUE)
    }
  });
};
