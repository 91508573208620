/* @flow */
import _ from 'lodash';
import {
  SET_TARGET_LANGUAGE,
  DEFAULT_NATIVE_LANGUAGE_CODE,
  DEFAULT_TARGET_LANGUAGE_CODE,
  LOAD_NATIVE_LANGUAGE_FEATURES,
  LOAD_LANGUAGE_FEATURES,
  FETCH_LANGUAGE_DATA,
  CANCEL_DOWNLOAD
} from '../constants';
import { SAVE_LANGUAGE_DATA, SAVE_UNIT_CACHE } from '../../common/constants';
import { TUTORIAL_KEY } from '../../games/constants';

type State = {
  native: string,
  target: string,
  activeLangCode: string,
  nativeLanguageFeatures: Object,
  fetchingUnitId: any
};

const INITIAL_STATE = {
  native: DEFAULT_NATIVE_LANGUAGE_CODE,
  target: DEFAULT_TARGET_LANGUAGE_CODE,
  activeLangCode: DEFAULT_TARGET_LANGUAGE_CODE,
  nativeLanguageFeatures: {},
  fetchingUnitId: '1'
};

const getSpecifyLangState = (state: State) => {
  const { activeLangCode } = state;
  return _.get(state, activeLangCode, {
    words: {},
    sentences: {},
    grammar: {},
    characters: {},
    dialogues: {},
    nativeLangCode: ''
  });
};

const setTargetLanguage = (state: State, { targetLangCode }: Object) => ({
  ...state,
  activeLangCode: targetLangCode || DEFAULT_TARGET_LANGUAGE_CODE
});

const loadNativeLanguageFeatures = (state: State, { features }) => ({
  ...state,
  nativeLanguageFeatures: features
});
const saveUnitsCache = (state: State, { unitId }) => {
  const { activeLangCode } = state;
  const oldState = getSpecifyLangState(state);

  return {
    ...state,
    [activeLangCode]: {
      ...oldState,
      unitsCached: _.uniq(
        unitId ? [TUTORIAL_KEY, unitId] : _.get(oldState, 'unitsCached', [])
      )
    }
  };
};
const loadLanguageData = (state: State, { data, target }: Object): Object => {
  const { fetchingUnitId, activeLangCode, nativeLanguageFeatures } = state;
  const oldState = getSpecifyLangState(state);
  const {
    words,
    sentences,
    dialogues,
    grammar,
    characters,
    nativeLangCode
  } = data;

  const oldFeatures = oldState.features;
  const newFeatures = data.features;
  return {
    activeLangCode,
    nativeLanguageFeatures: { ...nativeLanguageFeatures },
    [activeLangCode]: {
      ...oldState,
      ...data,
      features: {
        ...oldFeatures,
        ...newFeatures
      },
      words,
      sentences,
      dialogues,
      grammar,
      characters,
      characterCached: true,
      unitsCached: _.uniq(
        fetchingUnitId
          ? [TUTORIAL_KEY, fetchingUnitId]
          : _.get(oldState, 'unitsCached', [])
      ),
      unitCachedLanguage: _.uniqWith(
        fetchingUnitId
          ? [
              ..._.get(oldState, 'unitCachedLanguage', []),
              {
                byId: fetchingUnitId,
                langCode: nativeLangCode
              }
            ]
          : _.get(oldState, 'unitCachedLanguage', []),
        _.isEqual
      )
    }
  };
};

const loadLanguageFeatures = (state, { features }) => {
  const { activeLangCode } = state;
  const {
    hasWriting,
    hasSpeaking,
    hasGrammar,
    googleLink,
    iosLink,
    isSpacedLang,
    hasTranscript,
    langCodeSpeakingAndroid,
    langCodeSpeakingIos,
    rtlLanguage,
    hasGoogle
  } = features;

  const oldState = getSpecifyLangState(state);
  const oldFeatures = _.get(oldState, 'features', {});

  return {
    ...state,
    [activeLangCode]: {
      ...oldState,
      storeLinks: {
        googleLink,
        iosLink
      },
      features: {
        ...oldFeatures,
        hasWriting,
        hasSpeaking,
        hasGrammar,
        isSpacedLang,
        hasTranscript,
        langCodeSpeakingAndroid,
        langCodeSpeakingIos,
        rtlLanguage,
        hasGoogle
      }
    }
  };
};

const fetchLanguageData = (state: State, { unitId }: Object) => ({
  ...state,
  fetchingUnitId: unitId
});

const cancelDownload = (state: State) => ({
  ...state,
  fetchingUnitId: null
});

export const CoursesReducer = (
  state: State = INITIAL_STATE,
  action: Object
): Object => {
  switch (action.type) {
    case SET_TARGET_LANGUAGE:
      return setTargetLanguage(state, action);
    case SAVE_LANGUAGE_DATA:
      return loadLanguageData(state, action);
    case SAVE_UNIT_CACHE:
      return saveUnitsCache(state, action);
    case LOAD_LANGUAGE_FEATURES:
      return loadLanguageFeatures(state, action);
    case LOAD_NATIVE_LANGUAGE_FEATURES:
      return loadNativeLanguageFeatures(state, action);
    case FETCH_LANGUAGE_DATA:
      return fetchLanguageData(state, action);
    case CANCEL_DOWNLOAD:
      return cancelDownload(state);
    default:
      break;
  }
  return state;
};
