import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import {
  PREFIX_LESSON,
  BLACK_OPACITY_SHADOW,
  LIGHT_GREY,
  WHITE,
  VERY_LIGHT_PINK_TWO,
  DARK_GREY,
  BROWN_GREY_TWO,
  PREFIX_BEDGE,
  SLASH_SIGN
} from '../../../common/constants';

const AchievementContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 64px;
`;

const AchievementRow = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 20px 0 ${BLACK_OPACITY_SHADOW};
  border: solid 1px ${LIGHT_GREY};
  background-color: ${WHITE};
  border: 0;
  margin-top: 5px;
  align-items: center;
  padding: 12px 30px;
`;
const BedgePic = styled.img`
  width: 90px;
  height: 90px;
  margin-right: 35px;
`;
const TextContainer = styled.div`
  flex: 1;
`;
const AchievementTitle = styled.p`
  font-size: 16px;
  color: ${DARK_GREY};
  margin: 0 !important;
`;
const AchievementCoin = styled.p`
  font-size: 14px;
  color: ${BROWN_GREY_TWO};
  margin: 0 !important;
`;
const AchievementPointContainer = styled.div``;
const AchievementPoint = styled.div`
  border-radius: 15px;
  border: solid 1px ${VERY_LIGHT_PINK_TWO};
  display: flex;
  padding: 2px 5px;
`;
const StarPic = styled.img`
  height: 24px;
  width: 24px;
`;
const StarPoint = styled.p`
  margin: 0 7px !important;
  font-weight: bold;
  font-size: 16px;
`;

const goldStar = `${PREFIX_LESSON}fill-yellow-star-21-px@2x.png`;
const greyStar = `${PREFIX_LESSON}fill-star-21-px@2x.png`;
const badgeBanana = `${PREFIX_BEDGE}icons-badges-banana.png`;
const badgeBananaGrey = `${PREFIX_BEDGE}icons-badges-banana-gray.png`;
const badgeCrownActive = `${PREFIX_BEDGE}icons-badges-crown-active.png`;
const badgeCrownActiveGrey = `${PREFIX_BEDGE}icons-badges-crown-active-gray.png`;
const badgeGoal = `${PREFIX_BEDGE}icons-badges-goal.png`;
const badgeGoalGrey = `${PREFIX_BEDGE}icons-badges-goal-gray.png`;
const badgeLike = `${PREFIX_BEDGE}icons-badges-like.png`;
const badgeLikeGrey = `${PREFIX_BEDGE}icons-badges-like-gray.png`;
const badgeStrength = `${PREFIX_BEDGE}icons-badges-strength.png`;
const badgeStrengthGrey = `${PREFIX_BEDGE}icons-badges-strength-gray.png`;
const MAX_LEVEL_ACHIEVEMENT = 3;

type Props = {
  levelChamp: number,
  levelSpender: number,
  levelDiligent: number,
  levelAchiever: number,
  levelWealthy: number,
  achievement_txt_diligentMaster: string,
  diligentDescription: string,
  achievement_txt_bigSpender: string,
  spenderDescription: string,
  achievement_txt_champion: string,
  championDescription: string,
  achievement_txt_wealthy: string,
  wealthyDescription: string,
  achievement_txt_overAchiever: string,
  overAchieveDescription: string
};
class AchievementList extends Component<Props> {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const {
      achievement_txt_diligentMaster,
      diligentDescription,
      achievement_txt_bigSpender,
      spenderDescription,
      achievement_txt_champion,
      championDescription,
      achievement_txt_wealthy,
      wealthyDescription,
      achievement_txt_overAchiever,
      overAchieveDescription,
      levelChamp,
      levelSpender,
      levelDiligent,
      levelAchiever,
      levelWealthy
    } = this.props;
    const achieveList = [
      {
        badges: badgeStrength,
        badgeGrey: badgeStrengthGrey,
        title: achievement_txt_diligentMaster,
        progress: levelDiligent,
        description: diligentDescription
      },
      {
        badges: badgeLike,
        badgeGrey: badgeLikeGrey,
        title: achievement_txt_bigSpender,
        progress: levelSpender,
        description: spenderDescription
      },
      {
        badges: badgeCrownActive,
        badgeGrey: badgeCrownActiveGrey,
        title: achievement_txt_champion,
        progress: levelChamp,
        description: championDescription
      },
      {
        badges: badgeBanana,
        badgeGrey: badgeBananaGrey,
        title: achievement_txt_wealthy,
        progress: levelWealthy,
        description: wealthyDescription
      },
      {
        badges: badgeGoal,
        badgeGrey: badgeGoalGrey,

        title: achievement_txt_overAchiever,
        progress: levelAchiever,
        description: overAchieveDescription
      }
    ];

    const achieveRender = achieveList.map((item, key) => {
      return (
        <AchievementRow key={key}>
          <BedgePic src={item.progress === 0 ? item.badgeGrey : item.badges} />
          <TextContainer>
            <AchievementTitle>{item.title}</AchievementTitle>
            <AchievementCoin>{item.description}</AchievementCoin>
          </TextContainer>
          <AchievementPointContainer>
            <AchievementPoint>
              <StarPic src={item.progress >= 1 ? goldStar : greyStar} />
              <StarPic src={item.progress >= 2 ? goldStar : greyStar} />
              <StarPic src={item.progress >= 3 ? goldStar : greyStar} />
              <StarPoint>
                {item.progress}
                {SLASH_SIGN}
                {MAX_LEVEL_ACHIEVEMENT}
              </StarPoint>
            </AchievementPoint>
          </AchievementPointContainer>
        </AchievementRow>
      );
    });
    return (
      <Fragment>
        <AchievementContainer>{achieveRender}</AchievementContainer>
      </Fragment>
    );
  }
}
export { AchievementList };
