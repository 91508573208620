export const ChangeCampaign = 'CHANGE_CAMPAIGN';
export const ChangeScreen = 'CHANGE_SCREEN';
export const SetSelection = 'SET_SELECTION';

export const ResetCampaign = 'RESET_CAMPAIGN';

export const CompleteCampaign = 'COMPLETE_CAMPAIGN';

export const RedirectShop = 'REDIRECT_SHOP';

export const SetMonthlyPrice = 'SET_MONTHLY_PRICE';
export const SetYearlyPrice = 'SET_YEARLY_PRICE';
export const SetQuarterlyPrice = 'SET_QUARTERLY_PRICE';

export const SetMonthlyPriceStripeUk = 'SET_MONTHLY_PRICE_STRIPE_UK';
export const SetQuarterlyPriceStripeUk = 'SET_QUARTERLY_PRICE_STRIPE_UK';
export const SetYearlyPriceStripeUk = 'SET_YEARLY_PRICE_STRIPE_UK';
