/* @flow */
import * as React from 'react';
import { playSound } from '../../../util';
import _ from 'lodash';
import {
  MARIGOLD,
  BLACK,
  LIGHT_GREY_HOVER,
  DARK_GREY
} from '../../../common/constants';
import styled from 'styled-components';
import { VOICE_VERY_SLOW } from '../../../profile/constants';

const snailImageActive = require('../../../img/icons/cirecleTurtleVvSlow40Px@2x.png');

type Props = {
  soundFile: string,
  voiceSpeed: number,
  isFlashcard: boolean,
  setPlayingPhrase: Function,
  removePlayingPhrase: Function
};

const StylePad = styled.img`
  width: 40px;
  height: 40px;
  border: solid ${MARIGOLD} 1px;
  border-radius: 50px;
  padding: 0px;
`;

const PadButton = styled.div`
  width: 60px;
  height: 60px;
  background: ${DARK_GREY};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SoundButton = styled.button`
  padding: 0;
  border: none;
  border-radius: 50px;
  margin-left: 10px;

  &:hover {
    #soundBut {
      background: ${LIGHT_GREY_HOVER};
    }
    .soundBut {
      opacity: 0.6;
    }
  }
  &:active {
    #soundBut {
      background: ${BLACK};
    }
  }
`;

const VoiceSpeedButtonGold = ({
  setPlayingPhrase,
  removePlayingPhrase,
  soundFile,
  isFlashcard = false,
  voiceSpeed
}: Props) => {
  return (
    <SoundButton
      onClick={() =>
        onPlay(setPlayingPhrase, soundFile, voiceSpeed, removePlayingPhrase)
      }
    >
      <PadButton id="soundBut">
        <StylePad className="soundBut" src={snailImageActive} />
      </PadButton>
    </SoundButton>
  );
};

const onPlay = (
  beforeCallback: Function,
  soundFile: string,
  voiceSpeed: number,
  afterCallback: Function
) => {
  const slowVoiceSpeed = _.isEqual(VOICE_VERY_SLOW, voiceSpeed)
    ? VOICE_VERY_SLOW
    : voiceSpeed - VOICE_VERY_SLOW;
  beforeCallback && beforeCallback();
  playSound(soundFile, slowVoiceSpeed, undefined, 1, () => {
    afterCallback && afterCallback();
  });
};
export { VoiceSpeedButtonGold };
