/* @flow */
import { connect } from 'react-redux';
import { GameScore } from '../components/sub-components/GameScore';
import { toggleTranscript } from '../actions/GamescreenActions';
import {
  getUserBananas,
  getUserCoins,
  getTranscriptionState
} from '../../profile/selectors';
import { getLabels, getLabel } from '../../util';

export const mapStateToProps = (state: Object, ownProps: Object) => {
  return {
    ...ownProps,
    modal_error_other: getLabel(state, 'modal_error_other', 'Other'),
    modal_error_answer_should_be_accepted: getLabel(
      state,
      'modal_error_answer_should_be_accepted',
      'My answer should be accepted.'
    ),
    modal_error_answer_duplicate: getLabel(
      state,
      'modal_error_answer_duplicate',
      'One or more answers is a duplicate.'
    ),
    modal_error_image_missing: getLabel(
      state,
      'modal_error_image_missing',
      'An image is missing.'
    ),
    modal_error_audio_not_match: getLabel(
      state,
      'modal_error_audio_not_match',
      'The audio does not match with the text.'
    ),
    modal_error_audio_missing: getLabel(
      state,
      'modal_error_audio_missing',
      'The audio is missing.'
    ),
    modal_error_audio_not_clear: getLabel(
      state,
      'modal_error_audio_not_clear',
      'The audio is not clear.'
    ),
    modal_error_report: getLabel(state, 'modal_error_report', 'Error Report'),
    modal_error_report_txt: getLabel(state, 'modal_error_report_txt', 'Report'),
    modal_error_faq: getLabel(state, 'modal_error_faq', 'FAQ'),
    transcript: getTranscriptionState(state),
    bananas: getUserBananas(state),
    coins: getUserCoins(state),
    faq: state.data.faq,
    ...getLabels(state, 'btn_continue')
  };
};

export const mapDispatchToProps = (dispatch: Function) => {
  return {
    toggleTranscript: () => {
      dispatch(toggleTranscript());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GameScore);
