/* @flow */
import * as React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import {
  PREFIX_LESSON,
  WHITE,
  GREY,
  GREY2,
  BLACK
} from '../../../common/constants';
import { DailyGoalList } from '../../../games/components/sub-components/DailyGoalList';

export const LeftContent = styled.div`
  background: url(${props => props.loginPurchaseBG});
  justify-content: center;
  display: flex;
  align-items: center;
  width: 175px;
  border-radius: 20px 0 0 20px;
`;

const ModalContent = styled.div`
  width: 100%;
  margin: 0 35px;
  align-items: center;
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: bold;
    color: ${BLACK};
  }
`;
const ModalContentContainer = styled.div`
  display: flex;
  height: 320px;
  background: BLACK;
  align-items: center;
  justify-content: center;
  background: ${WHITE};
  border-radius: 30px;
`;

const ModalCloseButton = styled.button`
  position: fixed;
  opacity: 1;
  margin: 0.5rem 1rem 1rem -1rem !important;
  padding: 0 !important;
`;

const CreateAccountButton = styled.button`
  min-width: 216px;
  width: 100%;
  height: 50px;
  box-shadow: rgb(247, 181, 0) 0px -4px 0px 0px inset;
  background-color: rgb(255, 204, 0);
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(247, 181, 0);
  border-image: initial;
  color: #ffffff;
  &:hover {
    box-shadow: none;
    opacity: 0.4;
    border-color: rgb(255, 204, 0);
  }
`;

const styles = {
  modalContainer: {
    maxWidth: '485px',
    height: '300px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: 30
  },
  modalBodyContainer: {
    padding: 0,
    paddingBottom: 20
  },
  buttonStyle: {},
  cancelButtonStyle: {
    border: 'none',
    boxShadow: 'none',
    width: 100
  },
  confirmButtonStyle: {
    borderColor: GREY2,
    background: WHITE,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    width: 133,
    height: 41
  },
  modalHeader: {
    borderBottom: 'none',
    flexDirection: 'row-reverse',
    justifyContent: 'normal',
    padding: 0
  },
  modalFooter: {
    borderTop: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 30,
    paddingRight: 32,
    paddingLeft: 32
  },
  contentContainer: {
    borderRadius: 30,
    width: 'auto'
  },
  modalHeaderContainer: {
    'modal-title': 'w-100 text-center'
  },
  loadingStyle: {
    margin: '134px 38px',
    color: '#ffcc00'
  }
};

// const dangerIcon = `${PREFIX_LESSON}basecolor-round-warning-120-px.svg`;
const closeIcon = `${PREFIX_LESSON}basecolor-black.svg`;

type Props = {
  toggle: Function,
  onSelectedSetGoal: Function,
  saveDailyGoal?: Function,
  isOpenSetGoalModal: boolean,
  edit_profile_save_txt: string,
  my_daily_goal_txt: string,
  dailyGoalSelected: number | null
};

const SetDailyGoalModal = ({
  toggle,
  isOpenSetGoalModal,
  dailyGoalSelected,
  onSelectedSetGoal,
  saveDailyGoal,
  my_daily_goal_txt,
  edit_profile_save_txt
}: Props) => {
  const closeBtn = (
    <ModalCloseButton className="close" onClick={() => toggle()}>
      <img src={closeIcon} alt={closeIcon} />
    </ModalCloseButton>
  );

  const { modalContainer, modalHeader } = styles;
  return (
    <Modal
      isOpen={isOpenSetGoalModal}
      toggle={toggle}
      style={modalContainer}
      contentClassName="contentContainer"
      centered
    >
      <ModalHeader close={closeBtn} style={modalHeader} />
      <ModalContentContainer>
        <ModalContent>
          <p>{my_daily_goal_txt}</p>
          <DailyGoalList
            isProfileScene={true}
            dailyGoalSelected={dailyGoalSelected}
            onSelectedSetGoal={onSelectedSetGoal}
          />
          <CreateAccountButton onClick={() => saveDailyGoal && saveDailyGoal()}>
            {edit_profile_save_txt}
          </CreateAccountButton>
        </ModalContent>
      </ModalContentContainer>
    </Modal>
  );
};

export { SetDailyGoalModal };
