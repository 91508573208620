/* @flow */
import { matchingStatus } from '../constants';
import * as types from '../constants';
import { gameSound as utilGameSound } from '../../util';
import _ from 'lodash';
import Analytic from '../../util/Analytic';
import { getIsEffectEnabled } from '../../chatbots/selectors';

export const pressMatchingCard = (cardId: number, status: string) => {
  return (dispatch: Function) => {
    if (status !== matchingStatus.INVISIBLE) {
      dispatch({
        type: types.PRESS_MATCHING_CARD,
        cardId
      });
      dispatch(playSound());
    }
  };
};
export const playSound = () => {
  return (dispatch: Function, getState: Function) => {
    const state = getState();
    const byId = getState().matchingCardsDisplayed.byId;
    const isEffectEnabled = getIsEffectEnabled(state);
    const gameSound = isEffectEnabled ? utilGameSound : arg => {};
    if (_.find(byId, { status: matchingStatus.MATCH })) {
      gameSound('correct');
    } else if (_.find(byId, { status: matchingStatus.NO_MATCH })) {
      gameSound('incorrect');
    }
  };
};

export const noMatchAnimationFinished = () => {
  return {
    type: types.NO_MATCH_ANIMATION_FINISHED
  };
};

export const matchAnimationFinished = () => {
  return {
    type: types.MATCH_ANIMATION_FINISHED
  };
};

export const analyticMatching = () => {
  return (dispatch: Function, getState: Function) => {
    const { game, routes } = getState();
    Analytic.log(Analytic.key.G1, {
      game_type: routes.gameType,
      content: game.key
    });
    Analytic.logGA(Analytic.key.G1, {
      category: Analytic.key.G1,
      action: 'Check',
      label: routes.gameType
    });
  };
};
