/* @flow */
import { connect } from 'react-redux';
import { LeaderSceneWithRouter } from '../scenes/LeaderScene';
import { getUser, getFlagLanguage } from '../selectors';
import {
  triggerFetchLeaderboard,
  setLangCode
} from '../actions/AccountActions';
import { getLabels, getLabel } from '../../util';

export const mapStateToProps = (state: Object) => {
  const targetLangCode = state.data.targetLangCode;
  const { storage, stats } = state;
  const { user } = storage;
  return {
    ...state,
    ...getLabels(state, 'leader_txt_title'),
    targetLangCode,
    ...getUser(state),
    ...getFlagLanguage(state),
    user,
    stats,
    leaders: state.stats.leaders,
    leader_txt_thisMonth: getLabel(state, 'leader_txt_thisMonth', 'This Month'),
    leader_txt_today: getLabel(state, 'leader_txt_today', 'Today'),
    leader_txt_allTime: getLabel(state, 'leader_txt_allTime', 'All Time')
  };
};

export default connect(mapStateToProps, {
  triggerFetchLeaderboard,
  setLangCode
})(LeaderSceneWithRouter);
