/* @flow */
import React, { Component } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { playSound, stopSound } from '../../../util';
import { QuizCardsWrapper } from '../main-components/QuizCardWrapper';
import { CheckButton, QuizQuestion } from '../sub-components';
import { quizStatus } from '../../../common/constants/CardStatus';
import { SCREEN_SIZE } from '../../../common/constants';
import {
  QUIZ,
  BUTTON_DISABLED,
  BUTTON_CHECK_DISABLED,
  BUTTON_CONTINUE_DISABLED,
  BUTTON_SEND_DISABLED,
  KEYCODE_1,
  KEYCODE_2,
  KEYCODE_3,
  KEYCODE_4,
  KEYCODE_ENTER
} from '../../constants';

export const QuizPageContainer = styled.div`
  max-width: 1024px;
  width: 100%;
  margin: auto;
`;

const QuizCardWrapperContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;
const Space = styled.div`
  height: ${props => (props.isShowTutorial ? 110 : 0)}px;
`;
const QuizQuestionContainer = styled.div`
  margin: 70px 0;
  margin-top: 0;
  @media (max-height: ${SCREEN_SIZE.medium_2}) {
    margin: 30px 0;
  }
  @media (max-height: ${SCREEN_SIZE.medium}) {
    margin: 0;
  }
`;
const QuizGameSceneContainer = styled.div`
  padding-bottom: ${props => (props.isShowTutorial ? '110px' : ' unset')};
  margin-top: ${props => (props.isShowTutorial ? '30px' : ' unset')};
  height: 100vh;
  align-items: center;
  flex: 1;
  display: flex;
  min-height: ${props => (props.isShowTutorial ? 'unset' : ' 570px')};
`;
type Props = {
  pressQuizCard: Function,
  byId: Object,
  correctWord: Object,
  mode: string,
  pressQuizNextButton: Function,
  quiz_txt_title: string,
  btn_check: string,
  btn_continue: string,
  btn_hint: string,
  transcriptState: string,
  coins: number,
  voiceSpeed: number,
  frozen: boolean,
  isShowTutorial: boolean,
  isTargetLangSymbol: boolean,
  writing_skip: string,
  onCloseTutorialMotivate: Function,
  setShowTutorial: Function
};

export type State = {
  isShowImage: Array<boolean>
};

class Quiz extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isShowImage: [false, false, false, false]
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    // $flow-disable-line
    document.addEventListener('keydown', this.handleEnter);
  }
  componentWillUnmount() {
    // $flow-disable-line
    document.removeEventListener('keydown', this.handleEnter);
  }
  handleEnter = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { pressQuizNextButton, mode, byId, voiceSpeed } = this.props;
    if (
      event.keyCode === KEYCODE_ENTER &&
      pressQuizNextButton &&
      mode !== BUTTON_DISABLED &&
      mode !== BUTTON_CHECK_DISABLED &&
      mode !== BUTTON_CONTINUE_DISABLED &&
      mode !== BUTTON_SEND_DISABLED
    ) {
      pressQuizNextButton();
    } else if (
      event.keyCode === KEYCODE_1 ||
      event.keyCode === KEYCODE_2 ||
      event.keyCode === KEYCODE_3 ||
      event.keyCode === KEYCODE_4
    ) {
      playSound(byId[parseInt(event.key, 10)].soundFile, voiceSpeed);
      this.props.pressQuizCard(byId[parseInt(event.key, 10)].id);
      this.updateShowImage(parseInt(event.key, 10) - 1);
    }
  };

  UNSAFE_componentWillReceiveProps(newProps: Props) {
    const { soundFile } = newProps.correctWord;
    const { soundFile: thisSoundFile } = this.props.correctWord;
    if (soundFile !== thisSoundFile) {
      this.setState({
        isShowImage: [false, false, false, false]
      });
    }
  }

  updateShowImage(index: number) {
    const { isShowImage } = this.state;
    isShowImage[index] = true;
    this.setState({ isShowImage });
  }

  onPressCard(
    wordId: string,
    soundFile: string,
    voiceSpeed: number,
    triggerShowImage: Function
  ): () => void {
    return () => {
      playSound(soundFile, voiceSpeed);
      this.props.pressQuizCard(wordId);
      triggerShowImage && triggerShowImage();
    };
  }

  render() {
    const {
      byId,
      correctWord,
      mode,
      pressQuizNextButton,
      quiz_txt_title,
      btn_check,
      btn_continue,
      btn_hint,
      transcriptState,
      coins,
      voiceSpeed,
      frozen,
      isShowTutorial,
      isTargetLangSymbol,
      writing_skip,
      onCloseTutorialMotivate,
      setShowTutorial
    } = this.props;
    const { isShowImage } = this.state;
    const translation = _.get(correctWord, 'translation', '');

    const quizMatchAnswer = _.find(byId, item => {
      return item.status === quizStatus.MATCH;
    });
    const quizNoMatchAnswer = _.find(byId, item => {
      return item.status === quizStatus.NO_MATCH;
    });

    const quizAnswer = quizNoMatchAnswer ? quizNoMatchAnswer : quizMatchAnswer;

    return (
      <QuizGameSceneContainer isShowTutorial={isShowTutorial}>
        <QuizPageContainer>
          <QuizQuestionContainer>
            <QuizQuestion title={quiz_txt_title} question={translation} />
          </QuizQuestionContainer>
          <QuizCardWrapperContainer>
            <QuizCardsWrapper
              index={1}
              isShowTutorial={isShowTutorial}
              transcriptState={transcriptState}
              isTargetLangSymbol={isTargetLangSymbol}
              onPressCard={this.onPressCard(
                byId[1].id,
                byId[1].soundFile,
                voiceSpeed,
                () => this.updateShowImage(0)
              )}
              card={byId[1]}
              isShowImage={isShowImage[0] || frozen}
            />
            <QuizCardsWrapper
              index={2}
              isShowTutorial={isShowTutorial}
              transcriptState={transcriptState}
              isTargetLangSymbol={isTargetLangSymbol}
              onPressCard={this.onPressCard(
                byId[2].id,
                byId[2].soundFile,
                voiceSpeed,
                () => this.updateShowImage(1)
              )}
              card={byId[2]}
              isShowImage={isShowImage[1] || frozen}
            />
            <QuizCardsWrapper
              index={3}
              isShowTutorial={isShowTutorial}
              transcriptState={transcriptState}
              isTargetLangSymbol={isTargetLangSymbol}
              onPressCard={this.onPressCard(
                byId[3].id,
                byId[3].soundFile,
                voiceSpeed,
                () => this.updateShowImage(2)
              )}
              card={byId[3]}
              isShowImage={isShowImage[2] || frozen}
            />
            <QuizCardsWrapper
              index={4}
              isShowTutorial={isShowTutorial}
              transcriptState={transcriptState}
              isTargetLangSymbol={isTargetLangSymbol}
              onPressCard={this.onPressCard(
                byId[4].id,
                byId[4].soundFile,
                voiceSpeed,
                () => this.updateShowImage(3)
              )}
              card={byId[4]}
              isShowImage={isShowImage[3] || frozen}
            />
          </QuizCardWrapperContainer>
        </QuizPageContainer>
        <Space isShowTutorial={isShowTutorial} />
        <CheckButton
          quizAnswer={quizAnswer}
          onPressNext={() => {
            stopSound();
            pressQuizNextButton();
          }}
          mode={mode}
          btn_check={btn_check}
          btn_continue={btn_continue}
          btn_hint={btn_hint}
          gameType={QUIZ}
          coins={coins}
          isShowTutorial={isShowTutorial}
          writing_skip={writing_skip}
          onCloseTutorialMotivate={onCloseTutorialMotivate}
          setShowTutorial={setShowTutorial}
        />
      </QuizGameSceneContainer>
    );
  }
}

export default Quiz;
export { Quiz };
