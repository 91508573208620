/* @flow */
import React from 'react';
import { sprintf } from 'sprintf-js';
import { PREFIX_SHOP } from '../../../common/constants';
import { ShopSlices } from './ShopSlices';

type Props = {
  promoDetail: Object,
  isPromo: boolean,
  animating: any,
  promotion_txt_benifit_1: string,
  purchase_txt_benefit_details_05: string,
  promotion_txt_benifit_2: string,
  promotion_txt_benifit_des_2: string,
  promotion_txt_benifit_des_3: string,
  promotion_txt_benifit_3: string,
  promo_txt_benefit_05: string
};

const promoBenefit02 = `${PREFIX_SHOP}ling-promo-benefit-02.png`;
const promoBenefit03 = `${PREFIX_SHOP}ling-promo-benefit-03.png`;
const promoBenefit04 = `${PREFIX_SHOP}ling-promo-benefit-04.png`;
const promoBenefit05 = `${PREFIX_SHOP}ling-promo-benefit-05.png`;

const SlidesPromotion = ({
  isPromo,
  animating,
  promotion_txt_benifit_1,
  purchase_txt_benefit_details_05,
  promotion_txt_benifit_2,
  promotion_txt_benifit_des_2,
  promotion_txt_benifit_des_3,
  promotion_txt_benifit_3,
  promoDetail,
  promo_txt_benefit_05
}: Props) => {
  const promoteEntries = [
    {
      img: promoBenefit02,
      title: promotion_txt_benifit_1,
      des: purchase_txt_benefit_details_05
    },
    {
      img: promoBenefit03,
      title: promotion_txt_benifit_2,
      des: promotion_txt_benifit_des_2
    },
    {
      img: promoBenefit04,
      title: promotion_txt_benifit_3,
      des: promotion_txt_benifit_des_3
    },
    {
      img: promoBenefit05,
      title: promo_txt_benefit_05
    }
  ];
  const { percentOff, type: promoType } = promoDetail;
  promoteEntries[0].title = sprintf(
    `${promotion_txt_benifit_1}`,
    percentOff + '%',
    promoType
  );

  return (
    <ShopSlices
      isPromo={isPromo}
      animating={animating}
      shopSlideEntries={promoteEntries}
    />
  );
};

export { SlidesPromotion };
