import _ from 'lodash';
import {
  LOAD_LANGUAGE_DATA,
  TOGGLE_PERMISSION_MODAL,
  LOAD_OVERVIEW
} from '../constants';
import { getUnitEntries } from '../selectors';

type State = {
  lessons: Array<Object>,
  unitId: ?number,
  permissionModalVisible: boolean
};

const INITIAL_STATE = {
  lessons: [],
  unitId: 0,
  permissionModalVisible: false
};

const loadLanguageData = (state: State, { data, unitId }: Object) => {
  const lessons = [];
  const unitEntries = getUnitEntries(data.words, unitId);
  _.forEach(unitEntries, ({ byId, allIds }) => {
    const title: string = _.reduce(
      (allIds: string[]),
      (title: string, id: string, index: number): string => {
        const { translation } = byId[id];
        return index === 0 ? translation : _.join([title, translation], ', ');
      },
      ''
    );
    lessons.push({
      lessonId: _.head(_.values(byId)).lesson,
      title
    });
  });

  return {
    ...state,
    lessons,
    unitId
  };
};

const loadOverview = (state: State, { unitOverview }: Object) => {
  return {
    ...state,
    unitOverview: unitOverview
  };
};

const togglePermissionModal = (state: State) => ({
  ...state,
  permissionModalVisible: !state.permissionModalVisible
});

export const LessonsReducer = (
  state: State = INITIAL_STATE,
  action: Object
) => {
  switch (action.type) {
    case LOAD_LANGUAGE_DATA:
      return loadLanguageData(state, action);
    case TOGGLE_PERMISSION_MODAL:
      return togglePermissionModal(state);
    case LOAD_OVERVIEW:
      return loadOverview(state, action);
    default:
      return state;
  }
};
