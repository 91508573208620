/* @flow */
import * as React from 'react';
import { playSound } from '../../../util';
import {
  MARIGOLD,
  BLACK,
  LIGHT_GREY_HOVER,
  DARK_GREY
} from '../../../common/constants';
import styled from 'styled-components';

const playImage = require('./img/sound/sound-icon.png');
const sentenceIcon = require('../sub-components/img/flashcard/sound-icon.svg');
type Props = {
  soundFile: string,
  isFlashcard: boolean,
  voiceSpeed: number,
  onPress?: Function
};

const StylePad = styled.img`
  width: 20px;
  height: 20px;
  border: solid ${MARIGOLD} 1px;
  border-radius: 50px;
  padding: 12px;
`;

const PadButton = styled.div`
  width: 60px;
  height: 60px;
  background: ${DARK_GREY};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SoundButton = styled.button`
  padding: 0;
  border: none;
  border-radius: 50px;

  &:hover {
    #soundBut {
      background: ${LIGHT_GREY_HOVER};
    }
  }
  &:active {
    #soundBut {
      background: ${BLACK};
    }
  }
`;

function PlayButtonGold({
  soundFile,
  isFlashcard = false,
  voiceSpeed,
  onPress
}: Props) {
  return (
    <SoundButton
      onClick={isFlashcard ? onPress : onPlay(soundFile, voiceSpeed)}
    >
      <PadButton id="soundBut">
        <StylePad src={isFlashcard ? sentenceIcon : playImage} />
      </PadButton>
    </SoundButton>
  );
}

const onPlay = (soundFile: string, voiceSpeed: number) => {
  return () => playSound(soundFile, voiceSpeed);
};

export { PlayButtonGold };
