import { createSelector } from 'reselect';

export const getStorageState = state => state.storage;

export const getUserState = createSelector(
  getStorageState,
  storage => storage.user
);

export const getAnonymousState = createSelector(getUserState, user =>
  user.google || user.facebook || user.apple || user.email ? false : true
);
