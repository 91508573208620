/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import { PREFIX_LESSON } from '../../../common/constants';
import {
  DARK_BLUE_BG,
  MARIGOLD,
  WHITE,
  LIGHT_GREY_TRANSCRIPT_2
} from '../../../common/constants/Color';

const GameContainer = styled.div`
  width: 220px;
  height: 274px;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #f1f1f1;
  background-color: white;
  display: flex;
  margin: 17px;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const ImgContainer = styled.div`
  width: 79px;
  height: 88px;
  border-radius: 85px;
  background-color: ${DARK_BLUE_BG};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImgIcon = styled.img``;

const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #454545;
`;

const ScoreContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const CheckIcon = styled.img`
  margin-right: 15px;
`;

const ReviewButton = styled.button`
  width: 150px;
  height: 55px;
  border-radius: 10px;
  box-shadow: inset 0 -4px 0 0 #f7b500;
  background: ${MARIGOLD};
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  font-size: 16px;
  color: ${WHITE};
  margin-bottom: 22px;
  &.review {
    color: ${LIGHT_GREY_TRANSCRIPT_2};
  }
  &:hover {
    cursor: pointer;
    opacity: 0.4;
  }
`;

const uncheckImg = `${PREFIX_LESSON}check-unactive-icon.svg`;
const checkImg = `${PREFIX_LESSON}check-active-icon.svg`;

const yesChecked = () => <CheckIcon src={checkImg} />;
const noChecked = () => <CheckIcon src={uncheckImg} />;

type Props = {
  title: string,
  coins: number,
  finished: boolean,
  imgSrc: string,
  lesson_txt_play: string,
  onPress: Function
};

const GameCard = ({
  title,
  coins = 0,
  finished = false,
  imgSrc,
  onPress = () => {},
  lesson_txt_play
}: Props) => (
  <GameContainer onClick={onPress}>
    <ScoreContainer>
      {coins >= 1 ? yesChecked() : noChecked()}
      {coins >= 2 ? yesChecked() : noChecked()}
      {coins >= 3 ? yesChecked() : noChecked()}
    </ScoreContainer>
    <ImgContainer>
      <ImgIcon src={imgSrc} />
    </ImgContainer>
    <Title>{title}</Title>
    <ReviewButton>{lesson_txt_play}</ReviewButton>
  </GameContainer>
);

export { GameCard };
