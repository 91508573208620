/* @flow */
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  fetchLanguageData,
  setLanguageData,
  triggerLoadLanguageLabel,
  triggerLoadLanguageData,
  fetchUnitData,
  triggerLoadAllTargetLanguages
} from '../actions/LanguageActions';
import {
  triggerLoadInitialTutorial,
  updateUserData
} from '../actions/GlobalActions';
import { saveUserData, createNewDatabaseUser } from '../actions/AuthActions';
import { triggerFetchUnitMetaData } from '../actions';
import { setNativeLanguage, setTargetLanguage } from '../../lessons/actions';
import {
  getFlagImageUrl,
  getLanguageLabel,
  getLanguageNativeLang,
  addToSubscribeNewsList
} from '../../util';
import { toggleChatbotLoaded } from '../../games/actions';
import LanguagePicker from '../../onboarding/selectorComponents/LanguagePicker';
import { getLabels } from '../../util/LabelHelper';

export const mapStateToProps = (state: Object) => {
  const { data } = state;
  const { nativeLangCode, targetLangCode } = data;
  const langs = _.get(state, 'storage.langs', {});
  const targetLangCodes = _.get(data, 'targetLangCodes', []);
  const nativeLanguages = _.chain(langs)
    .map(language => ({
      ...language
    }))
    .filter('isNative')
    .sortBy('lang')
    .value();

  const targetLanguages = _.map(targetLangCodes, targetLangCode => {
    return {
      langCode: targetLangCode
    };
  });

  return {
    ...state.storage,
    nativeLanguages,
    targetLanguages,
    targetLangCodes,
    getFlagImageUrl,
    getLanguageLabel: langCode => {
      return getLanguageLabel(state, langCode);
    },
    getLanguageNativeLang: langCode => {
      return getLanguageNativeLang(state, langCode);
    },
    labels: getLabels(
      state,
      'chooseCourse_txt_title',
      'iSpeak_txt_title',
      'searchLanguages_txt_title',
      'txt_tell_us_about_yourself',
      'chooseCourse_txt_i_want_to_learn',
      'txt_select_language',
      'btn_continue'
    ),
    nativeLangCode,
    targetLangCode
  };
};

export default connect(mapStateToProps, {
  addToSubscribeNewsList,
  triggerLoadAllTargetLanguages,
  fetchUnitData,
  fetchLanguageData,
  setLanguageData,
  createNewDatabaseUser,
  updateUserData,
  saveUserData,
  setNativeLanguage,
  setTargetLanguage,
  triggerLoadLanguageData,
  triggerLoadInitialTutorial,
  triggerLoadLanguageLabel,
  toggleChatbotLoaded,
  triggerFetchUnitMetaData
})(LanguagePicker);
