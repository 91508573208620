import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import OnboardingButton from '../selectorComponents/subComponents/OnboardingButton';
import { useTheme } from '@mui/material/styles';
import { IMAGE_PATH } from '../../common/constants';
import { useMediaQuery } from '@mui/material';

const GlobalRecognitionScreen = ({
  headerData,
  handleScreenChange,
  setSelected,
  labels
}) => {
  const { btn_continue } = labels;

  const theme = useTheme();
  const imgPath = image => `${IMAGE_PATH}onboarding/${image}`;

  useEffect(() => {
    setSelected(1);
  }, [setSelected]);

  const styles = {
    container: {
      backgroundColor: '#EAF3FC',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      py: 7,
      px: 3,
      textAlign: 'center',
      overflowY: 'hidden',
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      mt: 15,
      [theme.breakpoints.up('sm')]: {
        fontSize: '36px',
        py: 12
      }
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      px: 5,
      minWidth: '345px',
      justifyContent: 'space-between',
      fontColor: '#111111',
      mb: 8
    },
    button: {
      width: '85%',
      maxWidth: '558px',
      backgroundColor: theme.palette.newPrimary.main,
      borderRadius: 9,
      textTransform: 'none',
      color: '#FFFFFF',
      fontSize: '18px',
      fontWeight: 700,
      mt: 9,
      [theme.breakpoints.up('sm')]: {
        mt: 12
      },
      position: 'absolute',
      bottom: 4
    },
    text: {
      fontSize: '30px',
      fontColor: '#111111',
      [theme.breakpoints.up('sm')]: {
        fontSize: '36px'
      }
    },
    image: {
      [theme.breakpoints.up('sm')]: {
        width: '60%'
      },
      width: '80%',
      maxWidth: '750px'
    }
  };
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Box sx={styles.container}>
      <Box sx={styles.textContainer}>
        <Typography variant="text" sx={styles.text}>
          {headerData.text}
        </Typography>
      </Box>
      <Box
        component="img"
        src={
          isLargeScreen
            ? imgPath('global-recognition.png')
            : imgPath('global-recognition-mobile.png')
        }
        sx={styles.image}
      />
      <OnboardingButton
        disabled={false}
        size="large"
        clickAction={() => {
          handleScreenChange(1);
        }}
        buttonText={btn_continue}
        style={styles.button}
      />
    </Box>
  );
};

export default GlobalRecognitionScreen;
