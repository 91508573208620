/* @flow */
import * as React from 'react';

type Props = {
  children?: React.Element<any>,
  style?: any,
  onPress?: () => void,
  invisible?: boolean,
  touchAble?: boolean
};

const CardQuiz = ({
  children,
  style,
  onPress,
  invisible = false,
  touchAble
}: Props) => {
  return (
    <div
      style={
        !invisible
          ? {
              ...styles.containerStyle,
              ...style
            }
          : {
              ...styles.containerStyle2,
              ...style
            }
      }
      onClick={invisible ? () => {} : onPress}
      disabled={invisible || touchAble}
    >
      {children}
    </div>
  );
};

const styles = {
  containerStyle: {
    cursor: 'pointer',
    justifyContent: 'space-around',
    border: '4px solid #f1f1f1',
    borderRadius: '20px',
    margin: '10px',
    height: '230.4px',
    width: '244.8px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'inset 0 -8px 0 0 rgba(241, 241, 241, 0.65)'
  },
  containerStyle2: {
    justifyContent: 'space-around',
    border: '4px solid #f1f1f1',
    borderRadius: '20px',
    height: '230.4px',
    width: '244.8px',
    display: 'flex',
    alignItems: 'center',
    margin: '10px',
    opacity: '0.2'
  }
};
export { CardQuiz };
