/* @flow */
import React from 'react';
import styled from 'styled-components';

import {
  WHITE,
  PREFIX_LESSON,
  LIGHT_GREY_TRANSCRIPT_2
} from '../../../common/constants';

const Button = styled.div`
  height: 55px;
  width: 134px;
  border-radius: 15px;
  box-shadow: inset 0 -4px 0 0 ${WHITE};
  border: solid 1px ${WHITE};
  background-color: ${LIGHT_GREY_TRANSCRIPT_2};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    box-shadow: none;
    opacity: 0.4;
    cursor: pointer;
  }
  &:active {
    box-shadow: none;
  }
`;
const ReplayImg = styled.img`
  margin-right: 9px;
`;
const Text = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${WHITE};
  margin: 0;
  text-transform: capitalize;
`;
const redoWhite = `${PREFIX_LESSON}outline-bold-replay.svg`;

const ReplayButton = ({ btn_replay, onNext }: Object) => (
  <Button onClick={onNext}>
    <ReplayImg src={redoWhite} alt={redoWhite} />
    <Text>{btn_replay}</Text>
  </Button>
);

export { ReplayButton };
