/* @flow */
import _ from 'lodash';
import {
  updateCurrentChatbot,
  toggleCompleteScreen,
  updateProgressChatbot
} from '../../games/actions';
import { SOUND_PATH } from '../../common/constants';
import { TRIGGER_LOAD_CHATBOT_DATA } from '../../lessons/constants';
import {
  LOAD_CHATBOT,
  UPDATE_CURRENT,
  RESET_CHATBOT,
  UPDATE_SELECTED_CHOICE,
  UPDATE_CHOICE,
  LOAD_CHATBOT_NAME,
  TOGGLE_CHATBOT_AUTO_PLAY,
  UPDATE_HISTORY,
  UPDATE_ERROR_IN_HISTORY,
  RESET_CHATBOT_HISTORY,
  CHATBOT_PLAYING
} from '../../games/constants';
import { getCourseUser } from '../../profile/selectors';
import { playSoundWithCallback } from '../../util';
import { getVoiceSpeed } from '../selectors';

export const startChatBot = (chatbotId: string) => (dispatch: Function) => {
  dispatch(updateCurrentChatbot(chatbotId));
  dispatch({
    type: TRIGGER_LOAD_CHATBOT_DATA,
    chatbotTopic: chatbotId
  });
};

export const loadChatbotsData = (data: Array<Object>) => (
  dispatch: Function
) => {
  dispatch({ type: LOAD_CHATBOT, data });
};

export const loadChatbotsName = (data: string) => (dispatch: Function) => {
  dispatch({ type: LOAD_CHATBOT_NAME, data });
};

export const updateCurrent = () => (dispatch: Function, getState: Function) => {
  const state = getState();
  const {
    chatbots: { remainChatbots }
  } = state;
  if (remainChatbots.length > 0) {
    const currentChat = remainChatbots.pop();
    dispatch({ type: UPDATE_CURRENT, currentChat });
  } else {
    dispatch({ type: UPDATE_CURRENT, currentChat: {} });
  }
};

export const finishChatbot = () => (dispatch: Function, getState: Function) => {
  const state = getState();
  const { currentChatbotId } = getCourseUser(state);
  const chatbotId = getNextChatbot(currentChatbotId);
  dispatch(updateProgressChatbot());
  dispatch(updateCurrentChatbot(chatbotId));
  dispatch(toggleCompleteScreen());
};

export const toggleChatbotPlaying = (playing: boolean) => (
  dispatch: Function
) => {
  dispatch({
    type: CHATBOT_PLAYING,
    playing
  });
};

export const updateSelectedChoice = (selectedChoice: string) => (
  dispatch: Function
) => {
  dispatch({ type: UPDATE_SELECTED_CHOICE, selectedChoice });
};

export const updateChoices = (choice: Object) => (
  dispatch: Function,
  getState: Function
) => {
  const state = getState();
  const {
    chatbots: { choices, remainChatbots }
  } = state;
  let lastChat = {};

  if (!_.isEmpty(choice)) {
    choices.push(choice);
  }
  let nextRemain = _.last(remainChatbots);

  if (nextRemain && !nextRemain.isBot && nextRemain.order === choice.order) {
    lastChat = remainChatbots.pop();
    dispatch({ type: UPDATE_CURRENT, currentChat: lastChat });
    dispatch(updateChoices(lastChat));
  } else {
    dispatch({ type: UPDATE_CHOICE, choices });
  }
};

export const nextChatbot = (
  isAnswer: boolean = false,
  specificChat: Object = {}
) => (dispatch: Function) => {
  dispatch(updateHistories(isAnswer, specificChat));
};

const chatbotPrefix = 'chat';

const getNextChatbot = (currentChatbotId: string) => {
  const chatbotIdNumber = Number(currentChatbotId.replace(chatbotPrefix, ''));
  const nextChatbotIdNumber = (chatbotIdNumber + 1).toString();
  return `${chatbotPrefix}${_.padStart(String(nextChatbotIdNumber), 2, '0')}`;
};

const getPrevChatbot = (currentChatbotId: string) => {
  const chatbotIdNumber = Number(currentChatbotId.replace(chatbotPrefix, ''));
  const nextChatbotIdNumber = (chatbotIdNumber - 1).toString();
  return `${chatbotPrefix}${_.padStart(String(nextChatbotIdNumber), 2, '0')}`;
};

export const replayChatbot = () => (dispatch: Function, getState: Function) => {
  const state = getState();
  const { currentChatbotId } = getCourseUser(state);
  const prevChatbot = getPrevChatbot(currentChatbotId);

  dispatch(resetChatbot());
  dispatch(toggleCompleteScreen());
  dispatch(startChatBot(prevChatbot));
};

export const exitChatbot = (isBack: boolean = false) => (
  dispatch: Function
) => {
  dispatch(resetChatbot());
};

export const toggleChatbotAutoPlay = () => (
  dispatch: Function,
  getState: Function
) => {
  const state = getState();
  const {
    chatbots: { autoplay }
  } = state;
  dispatch({ type: TOGGLE_CHATBOT_AUTO_PLAY, autoplay: !autoplay });
};

export const resetChatbot = () => (dispatch: Function) => {
  dispatch({ type: RESET_CHATBOT });
};

export const resetChatbotsHistory = () => (dispatch: Function) => {
  dispatch({ type: RESET_CHATBOT_HISTORY });
};

export const updateHistories = (
  isAnswer: boolean = false,
  specificChat: Object = {},
  isError: boolean = false,
  callback?: Function
) => (dispatch: Function, getState: Function) => {
  const state = getState();
  const {
    chatbots: {
      chatHistories: prevChatHistories,
      currentChat,
      choices,
      selectedChoice,
      autoplay
    },
    data
  } = state;

  const { targetLangCode } = data;
  const { isBot } = currentChat;
  const chatHistories = _.cloneDeep(prevChatHistories);
  const voiceSpeed = getVoiceSpeed(state);
  if (!isError) {
    if (!_.isEmpty(specificChat)) {
      chatHistories.push(specificChat);
    } else if (isAnswer) {
      const answer = _.find(choices, { id: selectedChoice });
      chatHistories.push(answer);
    } else {
      chatHistories.push(currentChat);
    }
    dispatch({ type: UPDATE_HISTORY, chatHistories });

    if (isBot && autoplay) {
      const { soundFile } = currentChat;
      playSoundWithCallback(
        soundFile,
        voiceSpeed,
        `${SOUND_PATH}/${targetLangCode}/`,
        () => dispatch(updateCurrent()),
        () => {},
        () => {}
      );
    } else {
      dispatch(updateCurrent());
    }
  } else {
    const { soundFile } = specificChat;
    chatHistories.push(specificChat);
    dispatch({ type: UPDATE_ERROR_IN_HISTORY, chatHistories });
    if (autoplay) {
      playSoundWithCallback(
        soundFile,
        voiceSpeed,
        `${SOUND_PATH}/${targetLangCode}`,
        () => callback && callback(),
        () => {},
        () => {}
      );
    } else {
      callback && callback();
    }
  }
};
