/* @flow */
import React, { Fragment, Component } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {
  BLACK,
  BLACK_OPACITY_SHADOW,
  LIGHT_GREY,
  WHITE,
  GREY,
  SHARP_SIGN
} from '../../common/constants';

const LeaderContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 64px;
  margin-right: 40px;
  margin-left: 40px;
`;

const LeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 20px 0 ${BLACK_OPACITY_SHADOW};
  border: solid 1px ${LIGHT_GREY};
  background-color: ${WHITE};
  border: 0;
  margin-top: 5px;
  height: 100px;
`;
const MainStyle = styled.div`
  display: flex;
  align-items: center;
  flex: 0.8;
`;
const TextRank = styled.div`
  text-align: center;
  color: ${GREY};
  font-weight: bold;
  width: 74px;
  &.user {
    color: ${BLACK};
  }
`;
const LeaderImage = styled.img`
  width: 80px;
  height: 80px;
  margin-right: 28px;
  margin-left: 10px;
  border-radius: 50%;
`;
const TextName = styled.div`
  flex: 0.8;
  color: ${GREY};
  font-weight: bold;
  &.user {
    color: ${BLACK};
  }
`;
const ScoreStyle = styled.div`
  flex: 0.2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 28px;
`;
const ScoreText = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

type Props = {
  datas: Array<Object>
};
class LeaderList extends Component<Props> {
  render() {
    const { datas } = this.props;
    const leaderList = _.map(datas, (item, index) => (
      <LeaderRow key={index}>
        <MainStyle>
          <TextRank className={item.leaderCurrent ? 'user' : null}>
            {SHARP_SIGN}
            {item.index > 0 ? Number(item.index) : '?'}
          </TextRank>
          <LeaderImage src={item.leaderImages} alt={item.leaderImages} />
          <TextName className={item.leaderCurrent ? 'user' : null}>
            {item.leaderName}
          </TextName>
        </MainStyle>
        <ScoreStyle>
          <ScoreText>{Number(item.leaderBananas).toLocaleString()}</ScoreText>
        </ScoreStyle>
      </LeaderRow>
    ));
    return (
      <Fragment>
        <LeaderContainer>{leaderList}</LeaderContainer>
      </Fragment>
    );
  }
}
export { LeaderList };
