import {
  LOAD_STATS,
  SET_TARGET_LANGUAGE,
  SET_NATIVE_LANGUAGE,
  DEFAULT_TARGET_LANGUAGE_CODE,
  LOAD_NATIVE_LANGUAGE_FEATURES,
  LOAD_LANGUAGE_FEATURES,
  LOAD_LABELS,
  FETCH_LANGUAGE_DATA,
  CANCEL_DOWNLOAD,
  LOAD_DIALOG_HEADER,
  LOAD_CHATBOTS,
  LOAD_STOP_WORDS,
  INIT_FAQ,
  FILE_DOWNLOADED,
  DOWNLOAD_FILES,
  LOAD_TARGET_LANG_CODES
} from '../constants';
import Analytic from './../../util/Analytic';
import {
  SAVE_LANGUAGE_DATA,
  LOAD_LANGUAGE_DATA,
  SAVE_UNIT_CACHE
} from '../../common/constants';
import { mergeLanguagesAPI } from '../../common/contents/ContentActions';
export const loadLanguageData = (data: Object, unitId: number) => ({
  type: LOAD_LANGUAGE_DATA,
  data,
  unitId
});
export const saveLanguageData = (data: Object, unitId: number) => ({
  type: SAVE_LANGUAGE_DATA,
  data,
  unitId
});
export const saveUnitCache = (unitId: number) => ({
  type: SAVE_UNIT_CACHE,
  unitId
});
export const cancelDownload = () => ({
  type: CANCEL_DOWNLOAD
});

export const downloadFiles = (totalFileSize: number) => ({
  type: DOWNLOAD_FILES,
  totalFileSize
});

export const loadLanguageFeatures = (features: Object) => ({
  type: LOAD_LANGUAGE_FEATURES,
  features
});

export const loadNativeLanguageFeatures = (features: Object) => ({
  type: LOAD_NATIVE_LANGUAGE_FEATURES,
  features
});

export const fileDownloaded = (fileSize: ?number = 0) => ({
  type: FILE_DOWNLOADED,
  fileSize
});

export const loadStats = (
  targetLangCode: string,
  totalWordCount: number,
  totalSentenceCount: number,
  units: Object
) => ({
  type: LOAD_STATS,
  targetLangCode,
  totalWordCount,
  totalSentenceCount,
  units
});

export const setTargetLanguage = (targetLangCodeInput?: string) => (
  dispatch: Function
) => {
  const targetLangCode = targetLangCodeInput || DEFAULT_TARGET_LANGUAGE_CODE;
  Analytic.log(Analytic.key.A8, {
    targetLangCode
  });
  Analytic.logGA(Analytic.key.A8, {
    category: Analytic.key.A8,
    action: 'Check',
    label: targetLangCode
  });

  Analytic.updateTargetLanguageInUser(targetLangCode);

  dispatch({
    type: SET_TARGET_LANGUAGE,
    targetLangCode
  });
};

export const setNativeLanguage = (nativeLangCode: string) => ({
  type: SET_NATIVE_LANGUAGE,
  nativeLangCode
});

export const loadChatbots = (chatbots: Object) => ({
  type: LOAD_CHATBOTS,
  chatbots
});

export const loadLabels = (labels: Object) => ({
  type: LOAD_LABELS,
  labels
});

export const updateFAQ = (faq: Object) => ({
  type: INIT_FAQ,
  faq
});

export const loadDialogHeaders = (dialogHeaders: Object) => ({
  type: LOAD_DIALOG_HEADER,
  dialogHeaders
});

export const fetchData = (unitId: number) => ({
  type: FETCH_LANGUAGE_DATA,
  unitId
});

export const loadStopWords = (stopWords: string[]) => ({
  type: LOAD_STOP_WORDS,
  stopWords: stopWords
});

export const loadTargetLangCodes = (langCodes: Array<string>) => {
  return { type: LOAD_TARGET_LANG_CODES, langCodes };
};

export const fetchUnitData = ({ target, native, unitId }: Object) => {
  const merge = mergeLanguagesAPI(target, native, unitId);
  if (unitId) {
    saveLanguageData(merge, target);
  } else {
    saveLanguageData(merge['mergedTarget'], target);
  }
};
