/* @flow */
import * as React from 'react';
import { playSound, stopSound } from '../../../util';
import {
  CardColumn,
  TranslateElementCard,
  Container,
  CheckButton
} from '../sub-components';
import type {
  ByIdProps,
  AllIdsProps,
  QuizCardProps
} from '../../reducers/QuizReducer';
import {
  TRANSLATEGAME,
  BUTTON_DISABLED,
  BUTTON_CHECK_DISABLED,
  BUTTON_CONTINUE_DISABLED,
  BUTTON_SEND_DISABLED,
  KEYCODE_1,
  KEYCODE_2,
  KEYCODE_3,
  KEYCODE_4,
  KEYCODE_ENTER
} from '../../constants';
import { GREY2, PREFIX_LISTENING } from '../../../common/constants';

import styled from 'styled-components';
const TranslateGameSceneContainer = styled.div`
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100vw;
  display: flex;
  min-height: 680px;
`;
export type Props = {
  byId: ByIdProps,
  allIds: AllIdsProps,
  correctWord: Object,
  pressQuizCard: Function,
  getCardStyle: Function,
  pressQuizNextButton: Function,
  animationFinished: Function,
  loadAnimationFinished: boolean,
  nextGameScreen: () => void,
  mode: string,
  isAnswerWrong: boolean,
  listening_txt_what_did_you_hear: string,
  transcriptState: any,
  btn_check: string,
  btn_continue: string,
  btn_hint: string,
  coins: number,
  voiceSpeed: number
};

const listeningSpeakerBGImg = `${PREFIX_LISTENING}waveBgYellow.png`;
const listeningSpeakerIcon = `${PREFIX_LISTENING}basecolorWhiteOpacity90Px.png`;
const TranslatePageContainer = styled.div`
  max-width: 1024px;
  width: 100%;
  margin-top: 150px;
  @media (min-height: 730px) {
    margin-top: 0px;
  }
`;
const ListeningSpeakerBg = styled.div`
  background-image: url(${listeningSpeakerBGImg});
  background-repeat: no-repeat;
  margin: auto;
  width: 190px;
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:active {
    opacity: 0.5;
  }
`;
const ListeningSpeakerIcon = styled.img`
  width: 90px;
  height: 90px;
`;
const CardsContainer = styled.div`
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding: 2px 25px;
  margin-bottom: ${props =>
    props.gameType === TRANSLATEGAME ? '0px' : 'unset'};
  @media (max-height: 730px) {
    margin-bottom: 110px;
  }
`;

const TranslateStyle = styled.p`
  font-size: 18px;
  margin: 10px !important;
`;

const TrabslateContentContainer = styled.div`
  max-width: 1024px;
`;

const Translation = styled.div`
  flex-direction: 'column';
  align-content: 'center';
  padding: 1;
  flex-wrap: 'wrap';
`;
const TitleHead = styled.p`
  font-size: 16px;
  margin-top: 14px;
  color: ${GREY2};
  font-weight: bold;
  text-align: center;
`;

class Listening extends React.Component<Props> {
  componentDidMount() {
    const { voiceSpeed, correctWord } = this.props;

    const { soundFile } = correctWord;
    window.scrollTo(0, 0);

    // $flow-disable-line
    document.addEventListener('keydown', this.handleEnter);

    this.onPlay(soundFile, voiceSpeed);
  }
  componentWillUnmount() {
    // $flow-disable-line
    document.removeEventListener('keydown', this.handleEnter);
  }
  handleEnter = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { pressQuizNextButton, mode, byId, pressQuizCard } = this.props;
    if (
      event.keyCode === KEYCODE_ENTER &&
      pressQuizNextButton &&
      mode !== BUTTON_DISABLED &&
      mode !== BUTTON_CHECK_DISABLED &&
      mode !== BUTTON_CONTINUE_DISABLED &&
      mode !== BUTTON_SEND_DISABLED
    ) {
      pressQuizNextButton();
    } else if (
      event.keyCode === KEYCODE_1 ||
      event.keyCode === KEYCODE_2 ||
      event.keyCode === KEYCODE_3 ||
      event.keyCode === KEYCODE_4
    ) {
      const key = parseInt(event.key, 10);
      switch (key) {
        case 1:
          pressQuizCard(byId[2].id);
          break;
        case 2:
          pressQuizCard(byId[1].id);
          break;
        case 3:
          pressQuizCard(byId[4].id);
          break;
        case 4:
          pressQuizCard(byId[3].id);
          break;
        default:
          break;
      }
    }
  };

  render() {
    const {
      byId,
      correctWord,
      pressQuizNextButton,
      mode,
      isAnswerWrong,
      listening_txt_what_did_you_hear,
      btn_check,
      btn_continue,
      btn_hint,
      coins,
      voiceSpeed
    } = this.props;

    const { soundFile } = correctWord;

    return (
      <TranslateGameSceneContainer>
        <TrabslateContentContainer>
          <TranslatePageContainer>
            {/* <QuestionHeader
              isTranslateGame={isTranslateGame}
              soundFile={soundFile}
              instruction={listening_txt_what_did_you_hear}
              transcript={transcriptState ? transcript : ''}
              voiceSpeed={voiceSpeed}
            >
              <ListeningSpeakerBg>
                <ListeningSpeakerIcon src="" alt="" />
              </ListeningSpeakerBg>
            </QuestionHeader> */}

            <Translation>
              <TitleHead>{listening_txt_what_did_you_hear}</TitleHead>
              <ListeningSpeakerBg
                onClick={() => this.onPlay(soundFile, voiceSpeed)}
              >
                <ListeningSpeakerIcon
                  src={listeningSpeakerIcon}
                  alt={listeningSpeakerIcon}
                />
              </ListeningSpeakerBg>
              {/* <SoundButton>
                <PlayButton soundFile={soundFile} voiceSpeed={voiceSpeed} />
                <VoiceSpeedButton
                  soundFile={soundFile}
                  voiceSpeed={voiceSpeed}
                />
              </SoundButton> */}
            </Translation>
          </TranslatePageContainer>
          <Container gameType={TRANSLATEGAME}>
            <CardsContainer gameType={TRANSLATEGAME}>
              <CardColumn
                rightCard={this.getElementFromCard(byId[1], 2)}
                leftCard={this.getElementFromCard(byId[2], 1)}
              />
              <CardColumn
                rightCard={this.getElementFromCard(byId[3], 4)}
                leftCard={this.getElementFromCard(byId[4], 3)}
              />
            </CardsContainer>
          </Container>
        </TrabslateContentContainer>

        <CheckButton
          quizAnswer={''}
          answeredWrong={isAnswerWrong}
          onPressNext={() => {
            stopSound();
            pressQuizNextButton();
          }}
          mode={mode}
          btn_check={btn_check}
          btn_continue={btn_continue}
          btn_hint={btn_hint}
          gameType={TRANSLATEGAME}
          coins={coins}
        />
      </TranslateGameSceneContainer>
    );
  }

  getElementFromCard(card: QuizCardProps, index: number): React.Element<any> {
    const { status, id, original, transcript } = card;
    const { getCardStyle, transcriptState } = this.props;
    const content = (
      <div>
        <TranslateStyle>{original}</TranslateStyle>
        <TranslateStyle> {transcriptState ? transcript : null}</TranslateStyle>
      </div>
    );

    const wordCardStyle = getCardStyle(status);
    return (
      <TranslateElementCard
        onPress={this.onPressCard(id)}
        style={wordCardStyle}
        onAnimationEnd={this.onAnimationEnd()}
        content={content}
        status={status}
        index={index}
      />
    );
  }

  onAnimationEnd() {
    return () => {
      const { loadAnimationFinished, animationFinished } = this.props;
      animationFinished(loadAnimationFinished);
    };
  }

  onPressCard(wordId: string): () => void {
    return () => {
      this.props.pressQuizCard(wordId);
    };
  }
  onPlay = (soundFile: string, voiceSpeed: number) =>
    playSound(soundFile, voiceSpeed);
  onPressTitle(soundFile: string, voiceSpeed: number): () => void {
    return () => {
      playSound(soundFile, voiceSpeed);
    };
  }
}

export { Listening };
