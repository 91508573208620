/* @flow */
import React, { Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import {
  onAfterDeleteAcc,
  onCancelMethod,
  toggleDeleteAccount
} from '../../util';
import {
  GREYISH_BROWN,
  PREFIX_LESSON,
  PREFIX_HEADER,
  IMAGE_PATH,
  WHITE,
  GREY,
  GREY2,
  MARIGOLD,
  DARK_RED_COLOR,
  RED_COLOR,
  DARK_YELLOW,
  PAST_DUE
} from '../../common/constants';

const ModalImage = styled.img`
  background: ${MARIGOLD};
  border-radius: 65px;
`;
const LingLoading = styled.img`
  margin: 134px 38px;
`;
const ModalTitle = styled.h1`
  font-size: 28px;
  margin-bottom: 13px;
  color: ${GREYISH_BROWN};
  font-weight: bold;
  margin-top: 0px !important;
`;
const ModalCloseButton = styled.button`
  position: fixed;
  opacity: 1;
  padding: 0px 20px !important;
  padding-right: 0;
`;
const ModalBodyText = styled.p`
  font-size: 18px;
  color: ${GREYISH_BROWN};
  line-height: 31px;
  margin: 0px;
  margin-top: 13px !important;
`;
const ModalBodyContainer = styled.div`
  display: ${props => (props.isLoading ? 'block' : 'none')};
  display: flex;
  justify-content: center;
`;
const ModalContentContainer = styled.div`
  margin: auto 25px;
`;

const styles = {
  modalContainer: {
    width: '485px',
    height: '457px',
    textAlign: 'center',
    borderRadius: 30
  },
  modalBodyContainer: {
    padding: 0,
    paddingBottom: 20,
    textAlign: 'center'
  },
  buttonStyle: {
    borderRadius: 15,
    width: '100%',
    height: 55,
    background: WHITE,
    border: 'solid 1px',
    borderColor: GREY2,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    boxShadowColor: GREY2,
    fontWeight: 'bold',
    fontSize: 18,
    margin: 0,
    marginBottom: 15
  },
  cancelButtonStyle: {
    border: 'none',
    boxShadow: 'none',
    width: 100
  },
  confirmButtonStyle: {
    borderColor: DARK_RED_COLOR,
    background: RED_COLOR,
    color: WHITE,
    boxShadow: `inset 0 -4px 0 0 ${DARK_RED_COLOR}`,
    height: 41
  },
  okButtonStyle: {
    borderColor: DARK_YELLOW,
    background: MARIGOLD,
    color: WHITE,
    boxShadow: `inset 0 -4px 0 0 ${DARK_YELLOW}`,
    height: 41
  },
  stopButtonStyle: {
    borderColor: GREY2,
    background: WHITE,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    height: 41
  },
  modalHeader: {
    borderBottom: 'none',
    flexDirection: 'row-reverse',
    justifyContent: 'normal',
    padding: 0,
    marginTop: 32,
    marginBottom: 29
  },
  modalFooter: {
    borderTop: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    paddingBottom: 30,
    paddingRight: 50,
    paddingLeft: 50,
    paddingTop: 22
  },
  contentContainer: {
    borderRadius: 30
  },
  modalHeaderContainer: {
    'modal-title': 'w-100 text-center'
  }
};

const closeIcon = `${PREFIX_LESSON}basecolor-black.svg`;
const sadIcon = `${PREFIX_HEADER}circle-ling-sad-modal-120-px.png`;
const lingLoading = `${IMAGE_PATH}ling-loader.gif`;

type Props = {
  isOpen: boolean,
  cancel_subscription_txt_profile: string,
  modal_txt_cancel_sub_desc: string,
  cancel_subscription_success_txt: string,
  cancel_subscription_success_txt_desc: string,
  random_test_modal_confirm_txt: string,
  isDeleteSuccess: boolean,
  modal_txt_cancel_sub_button: string,
  modal_txt_not_cancel_sub_button: string,
  isLoading?: boolean,
  user: Object,
  functions: string,
  isDeleteAcc: boolean,
  manageSceneStateChangeHandler: Function,
  profile_txt_past_due_cancel_modal_des: string,
  updateUserProfile: Function,
  subscriptionData: Object,
  subscriptionStatus: string
};

const DeleteAccountModal = ({
  isOpen,
  isDeleteSuccess,
  subscriptionData,
  random_test_modal_confirm_txt,
  cancel_subscription_txt_profile,
  modal_txt_cancel_sub_desc,
  modal_txt_cancel_sub_button,
  modal_txt_not_cancel_sub_button,
  cancel_subscription_success_txt,
  cancel_subscription_success_txt_desc,
  user,
  functions,
  isDeleteAcc,
  manageSceneStateChangeHandler,
  isLoading = false,
  profile_txt_past_due_cancel_modal_des,
  subscriptionStatus,
  updateUserProfile
}: Props) => {
  const closeBtn = (
    <ModalCloseButton
      className="close"
      onClick={() =>
        toggleDeleteAccount(isDeleteAcc, manageSceneStateChangeHandler)
      }
    >
      <img src={closeIcon} alt={closeIcon} />
    </ModalCloseButton>
  );
  const {
    modalHeader,
    modalBodyContainer,
    modalFooter,
    buttonStyle,
    confirmButtonStyle,
    modalContainer,
    stopButtonStyle,
    okButtonStyle
  } = styles;
  return (
    <Modal
      centered
      style={modalContainer}
      isOpen={isOpen}
      contentClassName="contentContainer"
    >
      {isLoading ? null : (
        <ModalHeader
          close={closeBtn}
          toggle={() =>
            toggleDeleteAccount(isDeleteAcc, manageSceneStateChangeHandler)
          }
          style={modalHeader}
        >
          {isDeleteSuccess ? <ModalImage src={sadIcon} alt={sadIcon} /> : null}
        </ModalHeader>
      )}
      {!isLoading ? (
        <React.Fragment>
          <ModalContentContainer>
            <ModalBody style={modalBodyContainer}>
              {!isDeleteSuccess ? (
                <React.Fragment>
                  <ModalTitle>{cancel_subscription_txt_profile}</ModalTitle>
                  <ModalBodyText>{modal_txt_cancel_sub_desc}</ModalBodyText>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <ModalTitle>{cancel_subscription_success_txt}</ModalTitle>
                  <ModalBodyText>
                    {subscriptionStatus === PAST_DUE
                      ? profile_txt_past_due_cancel_modal_des
                      : cancel_subscription_success_txt_desc}
                  </ModalBodyText>
                </React.Fragment>
              )}
            </ModalBody>
          </ModalContentContainer>

          <ModalFooter style={modalFooter}>
            {!isDeleteSuccess ? (
              <Fragment>
                <Button
                  className="confirmButton"
                  style={{ ...buttonStyle, ...confirmButtonStyle }}
                  onClick={() =>
                    onCancelMethod(
                      subscriptionData,
                      functions,
                      isDeleteSuccess,
                      manageSceneStateChangeHandler,
                      subscriptionStatus,
                      updateUserProfile
                    )
                  }
                >
                  {modal_txt_cancel_sub_button}
                </Button>
                <Button
                  className="confirmButton"
                  style={{ ...buttonStyle, ...stopButtonStyle }}
                  onClick={() =>
                    toggleDeleteAccount(
                      isDeleteAcc,
                      manageSceneStateChangeHandler
                    )
                  }
                >
                  {modal_txt_not_cancel_sub_button}
                </Button>
              </Fragment>
            ) : (
              <Button
                className="confirmButton"
                style={{ ...buttonStyle, ...okButtonStyle }}
                onClick={() =>
                  onAfterDeleteAcc(
                    user,
                    functions,
                    isDeleteAcc,
                    manageSceneStateChangeHandler
                  )
                }
              >
                {random_test_modal_confirm_txt}
              </Button>
            )}
          </ModalFooter>
        </React.Fragment>
      ) : (
        <ModalBodyContainer isLoading={isLoading}>
          <LingLoading src={lingLoading} />
        </ModalBodyContainer>
      )}
    </Modal>
  );
};

export { DeleteAccountModal };
