/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const CardRowContainer = styled.div`
  flex-direction: 'row';
  justify-content: 'center';
  display: flex;
`;

type Props = {
  leftCard: React.Element<any>,
  centerCard: React.Element<any>,
  rightCard: React.Element<any>
};

function createCard(card: React.Element<any>) {
  return React.cloneElement(card, {
    style: { ...styles.cardStyle, ...card.props.style }
  });
}

function CardRow({ leftCard, centerCard, rightCard }: Props) {
  return (
    <CardRowContainer style={styles.containerStyle}>
      {createCard(leftCard)}
      {centerCard === '' ? '' : createCard(centerCard)}
      {createCard(rightCard)}
    </CardRowContainer>
  );
}

const styles = {
  containerStyle: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  cardStyle: {
    marginBottom: 3
  }
};

export { CardRow };
