import { IMAGE_PATH } from '../../common/constants';
export const imgPath = image => `${IMAGE_PATH}onboarding/${image}`;

// Empty Template
// 0: [
//   {
//     id: 1,
//     text: ``,
//     selectionImage: imgPath('.png')
//   },
//   {
//     id: 2,
//     text: '',
//     selectionImage: imgPath('.png')
//   },
//   {
//     id: 3,
//     text: '',
//     selectionImage: imgPath('.png')
//   }
// ],

const CampaignScreenText = labels => ({
  0: [
    {
      id: 1,
      text: '',
      subtext: '',
      selectionImage: '',
      analyticValue: ''
    }
  ],
  1: [
    {
      id: 1,
      text: '',
      subtext: '',
      selectionImage: '',
      analyticValue: ''
    }
  ],
  2: [
    {
      id: 1,
      text: labels['txt_male'],
      subtext: '👨',
      selectionImage: '',
      analyticValue: 'Male'
    },
    {
      id: 2,
      text: labels['txt_female'],
      subtext: '👩‍🦰',
      selectionImage: '',
      analyticValue: 'Female'
    },
    {
      id: 3,
      text: labels['txt_other'],
      subtext: '🔮',
      selectionImage: '',
      analyticValue: 'Other'
    }
  ],
  3: [
    {
      id: 1,
      text: '12-17',
      subtext: '🐷',
      selectionImage: '',
      analyticValue: '12-17'
    },
    {
      id: 2,
      text: '18-24',
      subtext: '🐰',
      selectionImage: '',
      analyticValue: '18-24'
    },
    {
      id: 3,
      text: '25-34',
      subtext: '🐮',
      selectionImage: '',
      analyticValue: '25-34'
    },
    {
      id: 4,
      text: '35-44',
      subtext: '🦊',
      selectionImage: '',
      analyticValue: '35-44'
    },
    {
      id: 5,
      text: '45-54',
      subtext: '🐻',
      selectionImage: '',
      analyticValue: '45-54'
    },
    {
      id: 6,
      text: '55-65',
      subtext: '🦉',
      selectionImage: '',
      analyticValue: '55-65'
    },
    {
      id: 7,
      text: '66+',
      subtext: '🦁',
      selectionImage: '',
      analyticValue: '66+'
    }
  ],
  4: [
    {
      id: 1,
      text: '',
      subtext: '',
      selectionImage: '',
      analyticValue: ''
    }
  ],
  5: [
    {
      id: 1,
      text: labels['modals_btt_no'],
      subtext: '',
      selectionImage: '',
      analyticValue: 'no'
    },
    {
      id: 2,
      text: labels['modals_btt_yes'],
      subtext: '',
      selectionImage: '',
      analyticValue: 'yes'
    }
  ],
  6: [
    {
      id: 1,
      text: labels['modals_btt_no'],
      subtext: '',
      selectionImage: '',
      analyticValue: 'no'
    },
    {
      id: 2,
      text: labels['modals_btt_yes'],
      subtext: '',
      selectionImage: '',
      analyticValue: 'yes'
    }
  ],
  7: [
    {
      id: 1,
      text: labels['modals_btt_no'],
      subtext: '',
      selectionImage: '',
      analyticValue: 'no'
    },
    {
      id: 2,
      text: labels['modals_btt_yes'],
      subtext: '',
      selectionImage: '',
      analyticValue: 'yes'
    }
  ],
  8: [
    {
      id: 1,
      text: labels['modals_btt_no'],
      subtext: '',
      selectionImage: '',
      analyticValue: 'no'
    },
    {
      id: 2,
      text: labels['modals_btt_yes'],
      subtext: '',
      selectionImage: '',
      analyticValue: 'yes'
    }
  ],
  9: [
    {
      id: 1,
      text: labels['modals_btt_no'],
      subtext: '',
      selectionImage: '',
      analyticValue: 'no'
    },
    {
      id: 2,
      text: labels['modals_btt_yes'],
      subtext: '',
      selectionImage: '',
      analyticValue: 'yes'
    }
  ],
  10: [
    {
      id: 1,
      text: labels['unitLevel_txt_basic'],
      subtext: labels['txt_learning_basics'],
      selectionImage: imgPath('earth-excited.png'),
      analyticValue: 'Beginner'
    },
    {
      id: 2,
      text: labels['txt_elementary_level'],
      subtext: labels['txt_answer_simple_questions'],
      selectionImage: imgPath('earth-wear-hat.png'),
      analyticValue: 'Elementary'
    },
    {
      id: 3,
      text: labels['unitLevel_txt_beginner'],
      subtext: labels['txt_describe_experiences'],
      selectionImage: imgPath('earth-wear-glasses.png'),
      analyticValue: 'Intermediate'
    },
    {
      id: 4,
      text: labels['unitLevel_txt_intermediate'],
      subtext: labels['txt_discuss_complex_topics'],
      selectionImage: imgPath('guru-globe.png'),
      analyticValue: 'Upper-Intermediate'
    },
    {
      id: 5,
      text: labels['unitLevel_txt_advance'],
      subtext: labels['txt_can_speak_native'],
      selectionImage: imgPath('star-globe.png'),
      analyticValue: 'Expert'
    }
  ],
  11: [
    {
      id: 1,
      text: labels['txt_elementary_level'],
      subtext: labels['txt_goal_level_basic_topics'],
      selectionImage: imgPath('earth-excited.png'),
      analyticValue: ''
    },
    {
      id: 2,
      text: labels['unitLevel_txt_beginner'],
      subtext: labels['txt_goal_level_start_communicating'],
      selectionImage: imgPath('earth-wear-hat.png'),
      analyticValue: ''
    },
    {
      id: 3,
      text: labels['unitLevel_txt_intermediate'],
      subtext: labels['txt_goal_level_express_opinions'],
      selectionImage: imgPath('earth-wear-glasses.png'),
      analyticValue: ''
    },
    {
      id: 4,
      text: labels['unitLevel_txt_advance'],
      subtext: labels['txt_goal_level_become_fluent'],
      selectionImage: imgPath('guru-globe.png'),
      analyticValue: ''
    },
    {
      id: 5,
      text: labels['unitLevel_txt_proficient'],
      subtext: labels['txt_goal_level_become_guru'],
      selectionImage: imgPath('star-globe.png'),
      analyticValue: ''
    }
  ],
  12: [
    {
      id: 1,
      text: labels['txt_spelling'],
      subtext: '',
      selectionImage: imgPath('spelling.png'),
      selectType: 'multi',
      analyticValue: 'Spelling'
    },
    {
      id: 2,
      text: labels['overview_txt_vocab'],
      subtext: '',
      selectionImage: imgPath('dictionary.png'),
      selectType: 'multi',
      analyticValue: 'Vocabulary'
    },
    {
      id: 3,
      text: labels['txt_tenses'],
      subtext: '',
      selectionImage: imgPath('feather.png'),
      selectType: 'multi',
      analyticValue: 'Tenses'
    },
    {
      id: 4,
      text: labels['txt_phrasal_verbs'],
      subtext: '',
      selectionImage: imgPath('skateboard.png'),
      selectType: 'multi',
      analyticValue: 'Phrasal Verbs'
    },
    {
      id: 5,
      text: labels['txt_language_styles'],
      subtext: '',
      selectionImage: imgPath('paint.png'),
      selectType: 'multi',
      analyticValue: 'Language Styles'
    }
  ],
  13: [{ id: 0, text: '', subtext: '', selectionImage: '', analyticValue: '' }],
  14: [
    {
      id: 1,
      text: labels['txt_time_per_week'],
      subtext: '',
      selectionImage: imgPath('sleep-globe.png'),
      analyticValue: '1 time per week'
    },
    {
      id: 2,
      text: labels['txt_times_per_week']?.replace('%d', 2),
      subtext: '',
      selectionImage: imgPath('happy-globe.png'),
      analyticValue: '2 times per week'
    },
    {
      id: 3,
      text: labels['txt_times_per_week']?.replace('%d', 3),
      subtext: '',
      selectionImage: imgPath('heart-globe.png'),
      analyticValue: '3 times per week'
    },
    {
      id: 4,
      text: labels['txt_times_per_week']?.replace('%d', 4),
      subtext: '',
      selectionImage: imgPath('guru-globe.png'),
      analyticValue: '4 times per week'
    },
    {
      id: 5,
      text: labels['txt_times_per_week']?.replace('%d', 5),
      subtext: '',
      selectionImage: imgPath('star-globe.png'),
      analyticValue: '5 times per week'
    }
  ],
  15: [
    {
      id: 1,
      text: labels['survey_question_2_title_choice_1'],
      subtext: labels['txt_words_per_week']?.replace('%d', 25),
      selectionImage: imgPath('global-shock.png'),
      analyticValue: '5 min'
    },
    {
      id: 2,
      text: labels['survey_question_2_title_choice_2'],
      subtext: labels['txt_words_per_week']?.replace('%d', 50),
      selectionImage: imgPath('earth-excited.png'),
      analyticValue: '10 min'
    },
    {
      id: 3,
      text: labels['survey_question_2_title_choice_3'],
      subtext: labels['txt_words_per_week']?.replace('%d', 75),
      selectionImage: imgPath('earth-sing.png'),
      analyticValue: '15 min'
    },
    {
      id: 4,
      text: labels['survey_question_2_title_choice_4'],
      subtext: labels['txt_words_per_week']?.replace('%d', 100),
      selectionImage: imgPath('globe-hug.png'),
      analyticValue: '20 min',
      customStyle: { width: 55, pl: 3.5 }
    }
  ],
  16: [
    {
      id: 1,
      text: labels['txt_technology'],
      subtext: '',
      selectionImage: imgPath('startup.png'),
      selectType: 'multi',
      analyticValue: 'Technology'
    },
    {
      id: 2,
      text: labels['units_txt_unit21'],
      subtext: '',
      selectionImage: imgPath('suitcase.png'),
      selectType: 'multi',
      analyticValue: 'Traveling'
    },
    {
      id: 3,
      text: labels['txt_career'],
      subtext: '',
      selectionImage: imgPath('helmet.png'),
      selectType: 'multi',
      analyticValue: 'Career'
    },
    {
      id: 4,
      text: labels['units_txt_unit41'],
      subtext: '',
      selectionImage: imgPath('briefcase.png'),
      selectType: 'multi',
      analyticValue: 'Business'
    },
    {
      id: 5,
      text: labels['txt_daily_life'],
      subtext: '',
      selectionImage: imgPath('coffee.png'),
      selectType: 'multi',
      analyticValue: 'Daily Life'
    },
    {
      id: 6,
      text: labels['txt_health'],
      subtext: '',
      selectionImage: imgPath('heart.png'),
      selectType: 'multi',
      analyticValue: 'Health & Fitness'
    },
    {
      id: 7,
      text: labels['btn_food'],
      subtext: '',
      selectionImage: imgPath('food.png'),
      selectType: 'multi',
      analyticValue: 'Food'
    },
    {
      id: 8,
      text: labels['units_txt_unit28'],
      subtext: '',
      selectionImage: imgPath('double-heart.png'),
      selectType: 'multi',
      analyticValue: 'Relationship'
    }
  ],
  17: [
    {
      id: 1,
      text: labels['survey_question_4_choice_1'],
      subtext: '',
      selectionImage: imgPath('high-five.png'),
      analyticValue: 'Friends/Family'
    },
    {
      id: 2,
      text: labels['setting_txt_googleSearch'],
      subtext: '',
      selectionImage: imgPath('magnifier.png'),
      analyticValue: 'Google Search'
    },
    {
      id: 3,
      text: labels['setting_txt_appMarket'],
      subtext: '',
      selectionImage: imgPath('store.png'),
      analyticValue: 'Google Play/App Store'
    },
    {
      id: 4,
      text: labels['survey_question_4_choice_fb_ig'],
      subtext: '',
      selectionImage: imgPath('facebook-insta.png'),
      analyticValue: 'Facebook/Instagram'
    },
    {
      id: 5,
      text: `${labels['survey_question_4_choice_tiktok']}/${labels['survey_question_4_choice_youtube']}`,
      subtext: '',
      selectionImage: imgPath('tiktok-youtube.png'),
      analyticValue: 'TikTok/YouTube'
    },
    {
      id: 6,
      text: labels['survey_question_4_choice_3'],
      subtext: '',
      selectionImage: imgPath('podcast.png'),
      analyticValue: 'Podcast'
    },
    {
      id: 7,
      text: labels['survey_question_4_choice_4'],
      subtext: '',
      selectionImage: imgPath('megaphone.png'),
      analyticValue: 'Ads'
    },
    {
      id: 8,
      text: labels['txt_other'],
      subtext: '',
      selectionImage: imgPath('3-dots.png'),
      analyticValue: 'Other'
    }
  ]
});

export default CampaignScreenText;
