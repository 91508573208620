/* @flow */
import React, { PureComponent, Fragment } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {
  LAVENDER_COLOR,
  DARK_GREY,
  MARIGOLD,
  WHITE,
  GREY,
  VERY_LIGHT_PINK,
  PREFIX_HEADER
} from '../../../common/constants';
import { Table, Row, Rows } from '../../../games/components/sub-components';
import GrammarHelpTranslateModal from '../../../games/components/main-components/GrammarHelpTranslateModal';

const GrammarContainer = styled.div`
  width: 96%;
  margin: auto;
  margin-left: 44px;
  margin-right: 44px;
  margin-bottom: 173px;
  min-height: 415px;
  height: 100%;
  overflow-y: hidden;
  padding-right: 17px;
  box-sizing: content-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const HeadText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BigTextStyle = styled.p`
  font-size: 32px;
  font-weight: bold;
  color: ${DARK_GREY};
`;

const SubtitleFont = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${DARK_GREY};
`;

const GapStyle = styled.div`
  margin-top: 35px !important;
  margin-bottom: 35px !important;
  border-bottom: solid ${LAVENDER_COLOR}
    ${props => (props.isGrammarTable || props.lastItem ? 0 : 1)}px;
`;
const GrammarScreenContainer = styled.div`
  max-width: 1024px;
  width: 60%;
  margin: auto;
  margin-top: 92px;
  margin-bottom: 110px;
`;
const SuggestButton = styled.button`
  width: 55px;
  height: 55px;
  border-radius: 15px;
  box-shadow: inset 0 -4px 0 0 ${VERY_LIGHT_PINK};
  border: solid 2px ${GREY};
  padding: 0;
  &:hover {
    opacity: 0.4;
  }
  &:active {
    opacity: 1;
  }
`;
const CommentImg = styled.img`
  width: 32px;
  height: 32px;
`;
type State = {
  modalState: boolean
};
type Props = {
  btn_send: string,
  grammar_help_txt: string,
  item: Object,
  random_test_modal_confirm_txt: string
};

const OutlineComment = `${PREFIX_HEADER}outline-comment-28-px@2x.png`;
class GrammarReview extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalState: false
    };
  }

  toggleGrammarHelpModal = () => {
    this.setState(prevState => ({ modalState: !prevState.modalState }));
  };

  render() {
    const {
      item,
      btn_send,
      grammar_help_txt,
      random_test_modal_confirm_txt
    } = this.props;
    const { text, details } = item;
    const sortDetails = _.sortBy(details, function(detail) {
      return detail.isGrammarTable === true ? 0 : 1;
    });
    const sortDetailLen = sortDetails.length;
    const { textStyle, tableStyle, rowHeader, textHeader } = styles;

    return (
      <Fragment>
        <GrammarScreenContainer>
          <GrammarContainer>
            <HeadText>
              <BigTextStyle>{text}</BigTextStyle>
              <SuggestButton onClick={() => this.toggleGrammarHelpModal()}>
                <CommentImg src={OutlineComment} />
              </SuggestButton>
            </HeadText>
            {sortDetails.map((section, index) => (
              <GapStyle
                lastItem={sortDetailLen - 1 === index}
                key={index}
                isGrammarTable={section.isGrammarTable}
              >
                <SubtitleFont>
                  {_.toUpper(_.get(section, 'subtitle.text', ''))}
                </SubtitleFont>
                {section && section.isGrammarTable ? (
                  <Table style={tableStyle}>
                    <Row
                      data={_.get(section, 'text.header')}
                      style={rowHeader}
                      textStyle={textHeader}
                    />
                    <Rows
                      data={_.get(section, 'text.data')}
                      style={styles.row}
                      textStyle={styles.text}
                    />
                  </Table>
                ) : (
                  <p style={textStyle}>{section.text}</p>
                )}
              </GapStyle>
            ))}
          </GrammarContainer>
        </GrammarScreenContainer>
        <GrammarHelpTranslateModal
          random_test_modal_confirm_txt={random_test_modal_confirm_txt}
          isOpen={this.state.modalState}
          toggleReport={this.toggleGrammarHelpModal}
          btn_send={btn_send}
          grammar_help_txt={grammar_help_txt}
        />
      </Fragment>
    );
  }
}

const styles = {
  headText: {
    paddingBottom: 5,
    flexDirection: 'row'
  },
  bigTextStyle: {
    fontSize: 34,
    fontWeight: 'bold',
    color: '#454545'
  },
  subtitleFont: {
    fontWeight: 'bold',
    fontSize: 18,
    color: '#ffca45'
  },
  textStyle: { fontSize: 16 },
  rowHeader: {
    backgroundColor: MARIGOLD
  },
  textHeader: {
    textAlign: 'center',
    margin: 10,
    color: WHITE,
    fontWeight: 'bold'
  },
  row: { backgroundColor: WHITE },
  text: { textAlign: 'center', margin: 10 },
  tableStyle: { width: '100%', marginBottom: 37, paddingBottom: 37 }
};

export { GrammarReview };
