/* @flow */
import _ from 'lodash';
import { requestFirebaseNotificationPermission } from '../system/FirebaseMessage';
import BugTracker from '../util/BugTracker';

export const setArrayCheckedRemindedDays = (
  weekDays: Array<Object>,
  stateChangeHandler: Function
) => {
  let newWeekDay = ['Everyday', 'Someday'];
  const selectCount = _.countBy(weekDays, value => value.isChecked === true)
    .true;

  if (selectCount !== 7) {
    stateChangeHandler('remindedSelectDays', newWeekDay[1]);
  } else {
    stateChangeHandler('remindedSelectDays', newWeekDay[0]);
  }
};

export const handleReminderChange = (
  isReminder: boolean,
  setClientToken: Function,
  stateChangeHandler: Function
) => {
  if (
    //$FlowFixMe
    Notification.permission === 'denied' // eslint-disable-line
  ) {
    alert('Notification is disabled, please enable it and try again');
  } else {
    requestFirebaseNotificationPermission()
      .then(firebaseToken => {
        console.log('token', firebaseToken);
        setClientToken(firebaseToken);
        stateChangeHandler('isReminder', isReminder);
        stateChangeHandler('isChange', true);
      })
      .catch(err => {
        BugTracker.notify(err);
        return err;
      });
  }
};

export const weekDays: Object[] = [
  {
    id: 0,
    day: 'M',
    dayShort: 'Mon',
    isChecked: true
  },
  {
    id: 1,
    day: 'T',
    dayShort: 'Tue',
    isChecked: true
  },
  {
    id: 2,
    day: 'W',
    dayShort: 'Wed',
    isChecked: true
  },
  {
    id: 3,
    day: 'T',
    dayShort: 'Thu',
    isChecked: true
  },
  {
    id: 4,
    day: 'F',
    dayShort: 'Fri',
    isChecked: true
  },
  {
    id: 5,
    day: 'S',
    dayShort: 'Sat',
    isChecked: true
  },
  {
    id: 6,
    day: 'S',
    dayShort: 'Sun',
    isChecked: true
  }
];
