/* @flow */
import _ from 'lodash';
import { call, put, select } from 'redux-saga/effects';
import { fetchLanguageFeatures } from './RemoteDatabaseSagas';
import {
  loadLanguageFeatures,
  loadNativeLanguageFeatures,
  loadStopWords
} from '../actions/LanguageActions';
import {
  getTargetLangCode,
  getNativeLangCode,
  getStopwords
} from '../selectors';

export function* downloadFeaturesIntoStateForTargetLanguage(): Object {
  const targetLangCode = yield select(getTargetLangCode);
  yield call(downloadFeaturesIntoState, targetLangCode);
}

export function* downloadFeaturesIntoStateForNativeLanguage(): Object {
  const nativeLangCode = yield select(getNativeLangCode);
  const features = yield call(fetchFeaturesForLanguage, nativeLangCode);
  if (process.env.REACT_APP_DEV) {
    console.log('XXX', features);
  }
  yield put(loadNativeLanguageFeatures(features));
  const stopWords = yield select(getStopwords);
  yield put(loadStopWords(stopWords));
}

export function* downloadFeaturesIntoState(langCode: string): Object {
  const features = yield call(fetchFeaturesForLanguage, langCode);
  yield put(loadLanguageFeatures(features));
}

export const loadTargetLanguages = async () => {
  const byId = await fetchLanguageFeaturesById();
  return _.chain(byId)
    .filter({ isTarget: true })
    .map(function(o) {
      return { ...o, indexLang: Number(o.indexLang) };
    })
    .sortBy('indexLang')
    .map('langCode')
    .value();
};

const fetchFeatures = async () => {
  const instance = await fetchLanguageFeatures();
  return instance.val();
};

export const fetchLanguageFeaturesById = async () => {
  const features = await fetchFeatures();
  return _.get(features, 'byId');
};

export const fetchFeaturesForLanguage = async (langCode: string) => {
  const features = await fetchLanguageFeaturesById();
  return features[langCode];
};
