const languageCodeAndNameMap = {
  af: 'Afrikaans',
  ar: 'Arabic',
  bg: 'Bulgarian',
  bn: 'Bengali',
  bs: 'Bosnian',
  ca: 'Catalan',
  cs: 'Czech',
  da: 'Danish',
  de: 'German',
  el: 'Greek',
  en: 'English',
  es: 'Spanish',
  et: 'Estonian',
  fa: 'Persian',
  fi: 'Finnish',
  fil: 'Tagalog',
  fr: 'French',
  ga: 'Irish',
  he: 'Hebrew',
  hi: 'Hindi',
  hr: 'Croatian',
  hu: 'Hungarian',
  hy: 'Armenian',
  in: 'Indonesian',
  it: 'Italian',
  ja: 'Japanese',
  ka: 'Georgian',
  km: 'Khmer',
  kn: 'Kannada',
  ko: 'Korean',
  lo: 'Lao',
  lt: 'Lithuanian',
  lv: 'Latvian',
  ml: 'Malayalam',
  mn: 'Mongolian',
  mr: 'Marathi',
  ms: 'Malaysian',
  my: 'Burmese',
  ne: 'Nepali',
  nl: 'Dutch',
  no: 'Norwegian',
  pl: 'Polish',
  pt: 'Portuguese',
  pu: 'Punjabi',
  ro: 'Romanian',
  ru: 'Russian',
  sb: 'Sorbian',
  sk: 'Slovak',
  sl: 'Slovenian',
  sq: 'Albanian',
  sr: 'Serbian',
  sv: 'Swedish',
  sw: 'Swahili',
  ta: 'Tamil',
  te: 'Telugu',
  th: 'Thai',
  tr: 'Turkish',
  uk: 'Ukrainian',
  ur: 'Urdu',
  vi: 'Vietnamese',
  yue: 'Cantonese',
  zh: 'Chinese',
  'zh-TW': 'Chinese'
};

export const getLangNameFromCode = (langCode: string) => {
  return languageCodeAndNameMap[langCode] ?? '';
};
