/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import {
  VERY_LIGHT_PINK_TWO,
  GREYISH_BROWN,
  GREY,
  VERY_LIGHT_PINK
} from '../../../common/constants';
const FollowUsContainer = styled.div`
  margin-top: 65px;
  margin-bottom: 65px;
  height: 134px;
  border-radius: 15px;
  border: solid 2px ${VERY_LIGHT_PINK_TWO};
`;
const FollowUsTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${GREYISH_BROWN};
  text-align: center;
`;
const FollowUsButtons = styled.div`
  margin: 0px 45px;
  display: flex;
  justify-content: space-around;
`;
const FollowButton = styled.button`
  width: 113px;
  height: 45px;
  border-radius: 15px;
  box-shadow: inset 0 -4px 0 0 ${VERY_LIGHT_PINK};
  border: solid 1px ${GREY};
  cursor: pointer;
  &:hover {
    box-shadow: none;
  }
`;
const SocialFollowIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const youtube = require('../../../img/icons/youtube-color-24-px.svg');
const twitter = require('../../../img/icons/twitter-color-24-px.svg');
const instagram = require('../../../img/icons/instagram-color-24-px.svg');
const facebook = require('../../../img/icons/facebook-color-24-px.svg');

const facebook_link = 'https://www.facebook.com/simya.learn.languages/';
const youtube_link = 'https://www.youtube.com/channel/UCjM2-M1SS2X0_ESkL3efhKw';
const twitter_link = 'https://twitter.com/ling_languages';
const instagram_link = 'https://www.instagram.com/ling_app/';

type Props = {
  profile_txt_follow_us: string
};

const goToLink = url => {
  window.open(url, '_blank');
};

const FollowUsSection = ({ profile_txt_follow_us }: Props) => {
  return (
    <FollowUsContainer>
      <FollowUsTitle>{profile_txt_follow_us}</FollowUsTitle>
      <FollowUsButtons>
        <FollowButton title="facebook" onClick={() => goToLink(facebook_link)}>
          <SocialFollowIcon src={facebook} alt={facebook} />
        </FollowButton>
        <FollowButton
          title="instagram"
          onClick={() => goToLink(instagram_link)}
        >
          <SocialFollowIcon src={instagram} alt={instagram} />
        </FollowButton>
        <FollowButton title="twitter" onClick={() => goToLink(twitter_link)}>
          <SocialFollowIcon src={twitter} alt={twitter} />
        </FollowButton>
        <FollowButton title="youtube" onClick={() => goToLink(youtube_link)}>
          <SocialFollowIcon src={youtube} alt={youtube} />
        </FollowButton>
      </FollowUsButtons>
    </FollowUsContainer>
  );
};

export { FollowUsSection };
