/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import { Quiz } from '../components/scenes/Quiz';
import { getQuizCards } from '../reducers';
import { mapDispatchToQuizProps } from '../selectors';
import { getLabels } from '../../util';
import { getTranscriptionState, getUserCoins } from '../../profile/selectors';
import { getVoiceSpeed } from '../../chatbots/selectors';

const QuizContainer = (props: Object) => {
  return <Quiz {...props} />;
};

export const mapStateToProps = (state: Object) => {
  const transcriptState = getTranscriptionState(state);
  const coins = getUserCoins(state);
  const voiceSpeed = getVoiceSpeed(state);

  const { quizCards, storage } = state;
  const { isShowTutorial, isTargetLangSymbol } = storage;
  const { frozen } = quizCards;
  return {
    frozen: frozen,
    coins,
    isTargetLangSymbol,
    transcriptState,
    voiceSpeed,
    isShowTutorial,
    ...getQuizCards(state),
    ...getLabels(
      state,
      'quiz_txt_title',
      'btn_check',
      'btn_continue',
      'btn_hint',
      'writing_skip'
    )
  };
};

export { QuizContainer };
export default connect(mapStateToProps, mapDispatchToQuizProps)(QuizContainer);
