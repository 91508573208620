/* @flow */
import React, { Component } from 'react';
import { CardQuiz } from '.';
import styled from 'styled-components';
import {
  BLACK,
  GREY,
  BLUE,
  DARK_GREEN_COLOR,
  BTN_DARK_RED_COLOR,
  LIGHT_GREY,
  DARK_GREY_3,
  GREEN_COLOR,
  RED_COLOR,
  LIGHT_BLUE,
  quizStatus
} from '../../../common/constants';
import { getUnitContentImageUrl } from '../../../util';
import { MATCHING } from '../../constants';
const { MATCH, NO_MATCH, SELECTED, VISIBLE } = quizStatus;

const TextContainer = styled.div`
  align-items: center;
`;

const ImageContainer = styled.img`
  align-self: center;
  width: 80px;
  height: 80px;
`;

const CenteryContainer = styled.p`
  font-weight: bold;
  text-align: center;
  color: !invisible ? ${BLACK} : ${GREY};
  font-size: 18px;
  padding:0 10px;
  &.content-style{
    font-size:25px;
  }
`;

const KeyBoardSymbol = styled.span`
  border-radius: 11px;
  color: ${props => {
    const cardStatue = props.cardStatue;
    if (cardStatue === SELECTED) {
      return BLUE;
    } else if (cardStatue === MATCH) {
      return DARK_GREEN_COLOR;
    } else if (cardStatue === NO_MATCH) {
      return BTN_DARK_RED_COLOR;
    } else if (cardStatue === VISIBLE) {
      return LIGHT_GREY;
    } else {
      return DARK_GREY_3;
    }
  }};
  font-size: 15px;
  font-weight: 700;
  height: 30px;
  line-height: 30px;
  width: 30px;
  position: absolute;
  left: 5px;
  top: 5px;
  border: solid 4px
    ${props => {
      const cardStatue = props.cardStatue;
      if (cardStatue === SELECTED) {
        return BLUE;
      } else if (cardStatue === MATCH) {
        return DARK_GREEN_COLOR;
      } else if (cardStatue === NO_MATCH) {
        return BTN_DARK_RED_COLOR;
      } else if (cardStatue === VISIBLE) {
        return LIGHT_GREY;
      } else {
        return DARK_GREY_3;
      }
    }};
  background-color: ${props => {
    const cardStatue = props.cardStatue;
    if (cardStatue === SELECTED) {
      return '';
    } else if (cardStatue === MATCH) {
      return GREEN_COLOR;
    } else if (cardStatue === NO_MATCH) {
      return RED_COLOR;
    } else {
      return '';
    }
  }};
  background-image: ${props => {
    const cardStatue = props.cardStatue;
    if (cardStatue === SELECTED) {
      return LIGHT_BLUE;
    } else {
      return '';
    }
  }};
`;

const CardToShow = ({
  style,
  content,
  id,
  onPress,
  isImage = true,
  invisible = false,
  transcriptState = true,
  transcript,
  checkBox,
  touchAble,
  isTargetLangSymbol,
  index,
  status
}) => {
  const styles = {
    containerStyle: {
      height: 172,
      position: 'relative',
      textAlign: 'center'
    }
  };
  return (
    <CardQuiz
      style={{ ...styles.containerStyle, ...style }}
      onPress={onPress}
      invisible={invisible}
      touchAble={touchAble}
      gameType={MATCHING}
    >
      <KeyBoardSymbol cardStatue={status}>{index}</KeyBoardSymbol>
      {isImage ? (
        <div>
          <ImageContainer src={getUnitContentImageUrl(id)} />
          {checkBox}
        </div>
      ) : (
        <TextContainer>
          <CenteryContainer
            className={isTargetLangSymbol ? 'content-style' : null}
          >
            {content}
          </CenteryContainer>
          <CenteryContainer>
            {transcriptState ? transcript : ''}
          </CenteryContainer>
        </TextContainer>
      )}
    </CardQuiz>
  );
};

type Props = {
  id: string,
  style?: Object,
  content: string,
  onPress?: () => void,
  isImage?: boolean,
  invisible?: boolean,
  animation?: ?string,
  onAnimationEnd?: Function,
  transcriptState?: boolean,
  transcript?: string,
  checkBox?: Object,
  touchAble?: boolean,
  isTargetLangSymbol: boolean,
  index: number,
  status: string
};

class WordElementCard extends Component<Props> {
  render() {
    const { animation, onAnimationEnd } = this.props;

    if (animation) {
      return (
        <div
          // eslint-disable-next-line react/no-unknown-property
          animation={animation}
          onAnimationEnd={onAnimationEnd}
          // eslint-disable-next-line react/no-unknown-property
          duration={360}
        >
          {CardToShow(this.props)}
        </div>
      );
    }
    return CardToShow(this.props);
  }
}

export { WordElementCard };
