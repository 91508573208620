/* @flow */
import { connect } from 'react-redux';
import { getLabel, getUnitImageUrl } from '../../util';
import { triggerLoadOverview } from '../actions';
import { triggerFetchUnitMetaData } from '../../common/actions';
import _ from 'lodash';
import { ReviewLessonSceneWithRouter } from '../components/scenes/ReviewLessonScene';

export const mapStateToProps = (state: Object) => {
  const unitOverview = _.get(state, 'lessons.unitOverview', {});
  const unitId = _.get(state, 'lessons.unitId', '1');
  const { countObject, title, countDialogue, countGrammar } = unitOverview;

  return {
    countObject,
    title,
    countDialogue,
    countGrammar,
    currentUnit: unitId,
    overView_txt_lesson: getLabel(
      state,
      'overView_txt_lesson',
      'Review Lesson'
    ),
    unitLevel_txt_unit: getLabel(state, 'unitLevel_txt_unit', 'Unit'),
    overView_txt_grammar: getLabel(state, 'overView_txt_grammar', 'Grammars'),
    overView_txt_dialog: getLabel(state, 'overView_txt_dialog', 'Dialogues'),
    overView_txt_vocab: getLabel(state, 'overView_txt_vocab', 'Vocabulary'),
    image: getUnitImageUrl(unitId)
  };
};

export default connect(mapStateToProps, {
  triggerLoadOverview,
  triggerFetchUnitMetaData
})(ReviewLessonSceneWithRouter);
