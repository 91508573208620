/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import { TRANSLATEGAME, CORRECT_SENTENCE, SPELLING } from '../../constants';

export const ContainerStyle = styled.div`
  backgroundcolor: 'white';
  margin-top: ${props =>
    props.gameType === TRANSLATEGAME ||
    props.gameType === CORRECT_SENTENCE ||
    props.gameType === SPELLING
      ? 0
      : 100}px;
  margin-bottom: ${props =>
    props.gameType === TRANSLATEGAME ||
    props.gameType === CORRECT_SENTENCE ||
    props.gameType === SPELLING
      ? 0
      : 110}px;
  ${
    //TODO to fix gamescreen small screen on another card
    '' /* padding-bottom: ${props => (props.isShowTutorial ? '150px' : 'unset')}; */
  }
  padding-top: ${props => (props.isShowTutorial ? '0px' : 'unset')};
`;
type Props = {
  children?: Object,
  style?: Object,
  gameType?: string,
  isShowTutorial?: boolean
};
function Container({ children, style, gameType, isShowTutorial }: Props) {
  try {
    return (
      <ContainerStyle
        gameType={gameType}
        style={{ ...style }}
        isShowTutorial={isShowTutorial}
      >
        {children}
      </ContainerStyle>
    );
  } catch (e) {
    return <ContainerStyle style={{ ...style }}>{children}</ContainerStyle>;
  }
}

export { Container };
