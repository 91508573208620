/* @flow */
export const matchingStatus = {
  VISIBLE: 'VISIBLE',
  SELECTED: 'SELECTED',
  MATCH: 'MATCH',
  NO_MATCH: 'NO_MATCH',
  INVISIBLE: 'INVISIBLE'
};

export const quizStatus = {
  VISIBLE: 'VISIBLE',
  SELECTED: 'SELECTED',
  MATCH: 'MATCH',
  NO_MATCH: 'NO_MATCH'
};

export const translateGameStatus = {
  VISIBLE: 'VISIBLE',
  SELECTED: 'SELECTED',
  MATCH: 'MATCH',
  NO_MATCH: 'NO_MATCH'
};

export const spellingStatus = {
  VISIBLE: 'VISIBLE',
  CORRECT: 'CORRECT',
  WRONG: 'WRONG'
};

export const TOP_CARDS = 'topCards';
export const DIALOGCARD = 'dialog';
export const IS_WRONG = 'isWrong';
export const LEFT = 'left';
export const RIGHT = 'right';
