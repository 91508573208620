/* @flow */
import _ from 'lodash';
import {
  BANANAS,
  CROWNS,
  PROGRESS,
  LAST_USER_UNIT_ID,
  CURRENT_STREAK,
  LONGEST_STREAK,
  TRANSCRIPTION,
  SPENDER,
  LAST_DAY_PLAYED,
  SIGNIN_METHOD,
  PROVIDER_FACEBOOK,
  PROVIDER_APPLE,
  PROVIDER_GOOGLE,
  PROVIDER_EMAIL,
  COINS
} from '../constants';

import { getLabels, getLabel } from '../../util';
import { EXAM_INDEX } from '../../games/constants';

const TOTAL_UNIT = 50;

export const getActiveTargetLanguage = (state: Object) =>
  _.get(state, 'data.targetLangCode');

export const getCourseUser = (state: Object) =>
  _.get(state, `storage.user.${getActiveTargetLanguage(state)}`, {});

export const getUserCrowns = (
  state: Object,
  unitId: number,
  lessonId: number
) => {
  return _.get(getUserLessonProgress(state, unitId, lessonId), CROWNS, 0);
};

export const getUserExamCrowns = (state: Object, unitId: number) => {
  return getUserCrowns(state, unitId, EXAM_INDEX);
};

export const get = (state: Object, attribute: string, defaultValue: ?any) => {
  let result = _.get(getCourseUser(state), attribute);
  //For users with the old data structure: Get the data directly from storage.
  if (result === undefined) {
    result = _.get(state, `storage.user.${attribute}`);
  }
  if (result === undefined) {
    return defaultValue;
  }
  if (
    attribute === 'transcription' &&
    !_.isNull(result) &&
    !_.isNull(defaultValue)
  ) {
    return result && defaultValue;
  }
  return result;
};

export const getAttributes = (
  state: Object,
  ...attributes: string[]
): Object => {
  return _.reduce(
    attributes,
    (result, attribute) => ({
      ...result,
      [attribute]: get(state, attribute)
    }),
    {}
  );
};

export const getUserCoins = (state: Object) => {
  const coins = get(state, COINS);
  return coins;
};

export const getSpender = (state: Object) => get(state, SPENDER);

export const getUserBananas = (state: Object) => get(state, BANANAS);

export const getCurrentStreak = (state: Object) => get(state, CURRENT_STREAK);

export const getLastDayPlayed = (state: Object) => get(state, LAST_DAY_PLAYED);

export const getLongestStreak = (state: Object) => get(state, LONGEST_STREAK);

export const getHasTranscript = (state: Object) => {
  const activeLangCode = _.get(state, 'courses.activeLangCode', null);
  return _.get(
    state,
    `courses.${activeLangCode}.features.hasTranscript`,
    false
  );
};

export const isUserSubscribed = (state: Object) => {
  return (
    _.get(state, 'storage.payments.isProUser', false) ||
    _.get(state, 'storage.payments.isLifetime', false) ||
    _.get(state, 'storage.user.lifetime', false) ||
    _.get(state, 'storage.user.subscribed', false) ||
    _.get(state, 'storage.user.subscription.isPromo', false) ||
    _.get(state, 'storage.user.isProUser', false)
  );
};

export const getTranscriptionState = (state: Object) => {
  return get(state, TRANSCRIPTION, getHasTranscript(state));
};

export const getUserLessonsProgress = (state: Object, unitId: number) =>
  get(state, `${PROGRESS}[${unitId}].lessons`, {});

export const getUserLessonProgress = (
  state: Object,
  unitId: number,
  lessonId: number
) => _.get(getUserLessonsProgress(state, unitId), `${lessonId}`);

export const getUserProgress = (state: Object) => {
  return get(state, PROGRESS, []);
};

export const getUser = (state: Object) => {
  const { storage = {}, units = {}, stats = {} } = state;
  const {
    bananas = 0,
    coins = 0,
    progress,
    currentStreak,
    longestStreak
  } = getAttributes(
    state,
    BANANAS,
    COINS,
    PROGRESS,
    CURRENT_STREAK,
    LONGEST_STREAK
  );
  const { email, name, photoURL } = getNameAndEmail(storage);
  const loggedIn = isUserLoggedIn(state);
  const crownsEarned = getCrownsEarned(progress);
  const totalCrowns = getTotalCrowns(units.units);
  const fluency = getFluency(state);
  const {
    totalWordCount,
    totalSentenceCount,
    totalDialogCount
  } = getTotalStats(stats);
  const wordsLearned = getCurrentStats(stats, progress, 'wordCount');
  const sentencesLearned = getCurrentStats(stats, progress, 'sentenceCount');
  const dialogLearned = getCurrentStats(stats, progress, 'dialogCount');
  return {
    ...getLabels(
      state,
      'profile_txt_crownsEarned',
      'profile_txt_myStatus',
      'profile_txt_wordsLearned',
      'profile_txt_sentencesLearned',
      'profile_txt_currentStreak',
      'profile_txt_longestStreak',
      'profile_txt_headerProfile',
      'profile_txt_nameGuest',
      'profile_txt_dayStreak',
      'login_msg_loseProgressDialog'
    ),
    promo_txt_fluency: getLabel(state, 'promo_txt_fluency', 'Fluency'),
    profile_txt_viewAll: getLabel(state, 'profile_txt_viewAll', 'View All'),
    createAccount_txt_title: getLabel(
      state,
      'createAccount_txt_title',
      'Create Account'
    ),
    createAccount_txt_body: getLabel(
      state,
      'createAccount_txt_body',
      'Please create your account.'
    ),
    createAccount_txt_createProfile: getLabel(
      state,
      'createAccount_txt_createProfile',
      'Create Profile'
    ),
    createAccount_txt_later: getLabel(
      state,
      'createAccount_txt_later',
      'Later'
    ),
    profile_txt_leaderboard: getLabel(
      state,
      'profile_txt_leaderboard',
      'Leader Board'
    ),
    profile_txt_todayAchieve: getLabel(
      state,
      'profile_txt_todayAchieve',
      'Today Achieve'
    ),
    email,
    name,
    photoURL,
    bananas,
    coins,
    loggedIn,
    crownsEarned,
    totalCrowns,
    fluency,
    totalWordCount,
    totalSentenceCount,
    wordsLearned,
    sentencesLearned,
    dialogLearned,
    totalDialogCount,
    currentStreak,
    longestStreak
  };
};

const getTotalStats = ({ byId = {}, targetLangSelected }: Object) => {
  const result = byId[targetLangSelected];
  return result
    ? result
    : {
        totalWordCount: null,
        totalSentenceCount: null,
        totalDialogCount: null
      };
};

const getCurrentStats = ({ units }: Object, progress: Object[], type: string) =>
  progress && units
    ? _.reduce(
        progress,
        (phrasesLearned, unit, unitId) =>
          phrasesLearned + getLessonPhrases(unit, units[unitId], type),
        0
      )
    : 0;

const getLessonPhrases = (
  unit: Object,
  lessonsStats: Object[],
  type: string
) => {
  return unit
    ? _.reduce(
        unit.lessons,
        (lessonPhrases, lesson, lessonId: number) =>
          lessonPhrases +
          (lesson && lesson.finished && lessonsStats && lessonsStats[lessonId]
            ? lessonsStats[lessonId][type]
            : 0),
        0
      )
    : 0;
};

const getTotalCrowns = (units: Object[]) =>
  _.reduce(units, totalCrowns => totalCrowns + 3, 0);

const getCrownsEarned = (progress: Object) =>
  progress
    ? _.reduce(
        progress,
        (unitCrowns, unit) => unitCrowns + getLessonCrowns(unit),
        0
      )
    : 0;

const getLessonCrowns = (unit: Object) =>
  unit
    ? _.reduce(
        unit.lessons,
        (lessonCrowns, lesson) => lessonCrowns + (lesson ? lesson.crowns : 0),
        0
      )
    : 0;

export const getCurrentUnitId = (state: Object) =>
  get(state, LAST_USER_UNIT_ID);

export const getLastUserUnitId = (state: Object) =>
  get(state, LAST_USER_UNIT_ID);

export const isUserLoggedIn = (state: Object) => {
  const { storage = {} } = state;

  const { email, name } = getNameAndEmail(storage);
  return Boolean(email || name);
};

export const setGoogleUserInfo = (userInfo: Object, google: Object) => {
  if (google) {
    const { email, name, photoURL } = google;
    if (!userInfo.name) {
      userInfo.name = name;
    }
    if (!userInfo.email) {
      userInfo.email = email;
    }
    if (!userInfo.photo) {
      userInfo.photoURL = photoURL;
    }
  }
};

export const setAnonymousUserInfo = (userInfo: Object, anonymous: Object) => {
  if (anonymous) {
    userInfo.name = 'Anonymous';
  }
};

export const setFacebookUserInfo = (userInfo: Object, facebook: Object) => {
  if (facebook) {
    const { email, name, photoURL } = facebook;
    userInfo.name = name;
    userInfo.email = email;
    userInfo.photoURL = photoURL;
  }
};

export const setAppleUserInfo = (userInfo: Object, apple: Object) => {
  if (apple) {
    const { email, name, photoURL } = apple;
    userInfo.name = name || email;
    userInfo.email = email;
    userInfo.photoURL = photoURL;
  }
};

export const setEmailUserInfo = (
  userInfo: Object,
  emailUser: Object,
  name: string
) => {
  if (!userInfo.name) {
    userInfo.name = name;
  }
  if (emailUser && !userInfo.email) {
    userInfo.email = emailUser.email;
  }

  if (emailUser && !userInfo.photoURL) {
    userInfo.photoURL = emailUser.photoURL;
  }
};

export const getNameAndEmail = ({ user = {} }: Object): Object => {
  if (_.isEmpty(user)) {
    return {};
  }

  const { name, email, google, facebook, apple, anonymous } = user;
  let userInfo = {};
  //Here, we change the userInfo as a reference!
  // eslint-disable-next-line
  const signin_method = localStorage.getItem(SIGNIN_METHOD);
  switch (signin_method) {
    case PROVIDER_FACEBOOK:
      setFacebookUserInfo(userInfo, facebook);
      break;
    case PROVIDER_APPLE:
      setAppleUserInfo(userInfo, apple);
      break;
    case PROVIDER_GOOGLE:
      setGoogleUserInfo(userInfo, google);
      break;
    case PROVIDER_EMAIL:
      setEmailUserInfo(userInfo, email, name);
      break;
    default:
      setAnonymousUserInfo(userInfo, anonymous);
      break;
  }

  return userInfo;
};

export const getNumberFluency = (state: Object) => {
  const progress = getCourseUser(state).progress;

  const lessonPass = _.filter(progress, unit => {
    const crowns = _.get(unit, 'crowns', 0);
    return crowns >= 1;
  });
  return lessonPass.length;
};

export const getFluency = (state: Object) => {
  const result = (getNumberFluency(state) / TOTAL_UNIT) * 100;
  return Math.floor(result);
};
