/* @flow */
import _ from 'lodash';
import fp from 'lodash/fp';
import BugTracker from '../../util/BugTracker';
import Analytic from '../../util/Analytic';
import {
  pushGameScreen,
  refreshGameScreen,
  newGameScreen,
  increaseCompletedScreen,
  increaseAnsweredWrong,
  toggleCompleteScreen,
  toggleGrammarScreen,
  increaseAnsweredWrongCount,
  resetAnsweredWrongCount,
  closeCompleteScreen
} from './GamescreenActions';
import { getUserCrowns, getUserProgress } from '../../profile/selectors';
import {
  dialogResetGame,
  stopDialogSound,
  dialogMessageDeactive
} from './DialogActions';
import {
  getLessonId,
  getUpdatedWeeklyGoal,
  prepareProgress,
  updateProgress
} from './ProgressActions';
import {
  START_GAME,
  LOAD_TRANSCRIPT,
  FLASHCARD,
  MATCHING,
  QUIZ,
  CORRECT_SENTENCE,
  TRANSLATEGAME,
  SPELLING,
  ORDER_SENTENCE,
  ORDER_SENTENCE_REVERSE,
  COMPLETE_SENTENCE,
  DIALOGUE,
  SPEAKING,
  WRITING,
  LOAD_ANIMATION_FINISHED,
  EXAM_MAX_HEARTS,
  SPEAKING_GAME_INDEX,
  START_EXAM,
  EXAM_INDEX,
  START_SPEAKING,
  START_WRITING,
  WRITING_GAME_INDEX,
  START_RANDOM_TEST,
  TUTORIAL_KEY,
  LISTENING
} from '../constants';
import {
  getGame,
  newGameOrderWhenAnsweredWrong,
  getCheckGrammar,
  getExam,
  getSpeaking,
  getWriting
} from '../selectors';
import { getCurrentCourseData, hasTranscript } from '../../lessons/selectors';
import { getTranscriptionState } from '../../profile/selectors';
import { split } from '../../util/CharacterSplitter';
import { getIndexedArray } from '../../util/ArrayHelper';
import { handleSpeakingError } from '../../util/ErrorHandlers';
import { getCurrentUserGoalData } from '../../util/UserDataHalper';
import { getRandomTestGame } from '../../lessons/actions/RandomTestActions';
import {
  toggleTutorialMotivate,
  updateUserData
} from '../../common/actions/GlobalActions';
import { pressSpellingNextButton } from './SpellingActions';

const PREFILLED_CHARS: string[] = [' ', '-'];
const PREFILLED_CHARS_COUNT: number = 5;

export const logStartGame = (unitId: number, lessonId: number) => {
  Analytic.log(Analytic.key.L1, {
    content: `${unitId},${lessonId}`
  });
  Analytic.logGA(Analytic.key.L1, {
    category: Analytic.key.L1,
    action: 'Log',
    label: `${unitId},${lessonId}`
  });
};

export const logRestartGame = (
  unitId: number,
  lessonId: number,
  state: Object
) => {
  const coins = getUserCrowns(state, unitId, lessonId);
  if (coins) {
    Analytic.log(Analytic.key.L5, {
      content: `${unitId},${lessonId}`,
      coins
    });
    Analytic.logGA(Analytic.key.L5, {
      category: Analytic.key.L5,
      action: 'Log',
      label: `${unitId},${lessonId}`,
      value: coins
    });
  }
};

export const startSpeaking = (unitId: number, isRestart: boolean = false) => (
  dispatch: Function,
  getState: Function
) => {
  console.log('Speaking');
  console.log('GetLesson');

  try {
    dispatch(
      start(getSpeaking, START_SPEAKING, unitId, SPEAKING_GAME_INDEX, isRestart)
    );
  } catch (e) {
    BugTracker.notify(e);
    handleSpeakingError(e);
  }
};

export const startWriting = (unitId: number, isRestart: boolean = false) => (
  dispatch: Function,
  getState: Function
) => {
  dispatch(
    start(getWriting, START_WRITING, unitId, WRITING_GAME_INDEX, isRestart)
  );
};

export const toggleGameCompleteScreen = () => (dispatch: Function) => {
  dispatch(closeCompleteScreen());
};

export const closeTutorialMotivate = () => (dispatch: Function) => {
  dispatch(toggleTutorialMotivate());
  dispatch(stopDialogSound());
  dispatch(dialogMessageDeactive());
  dispatch(nextGameScreen());
  dispatch(pressSpellingNextButton());
};

export const startRandomTest = (randomTestUnits: Array<number>) => (
  dispatch: Function,
  getState: Function
) => {
  dispatch(start(getRandomTestGame, START_RANDOM_TEST, randomTestUnits, 0));
};

export const startExam = (unitId: number, isRestart: boolean = false) => (
  dispatch: Function,
  getState: Function
) => {
  dispatch(start(getExam, START_EXAM, unitId, EXAM_INDEX, isRestart));
};

export const startTutorial = () => (dispatch: Function, getState: Function) => {
  dispatch(start(getGame, START_GAME, 0, 0));
};

export const startGame = (
  lessonId: number,
  unitId: number,
  isRestart: boolean = false
) => (dispatch: Function, getState: Function) => {
  const state = getState();

  logRestartGame(unitId, lessonId, state);
  dispatch(dialogResetGame());
  dispatch(start(getGame, START_GAME, unitId, lessonId, isRestart));
};

export const start = (
  getLesson: Function,
  type: string,
  unitId: any,
  lessonId: number,
  isRestart: boolean = false
) => (dispatch: Function, getState: Function) => {
  const state = getState();
  const lesson = getLesson(state, unitId, lessonId);
  logStartGame(unitId, lessonId);
  dispatch({
    type,
    lesson
  });
  dispatch(loadTranscript(state));
  dispatch(doPushGameScreen(lesson, isRestart));
};

export const loadTranscript = (state: Object) => {
  return {
    type: LOAD_TRANSCRIPT,
    hasTranscript: hasTranscript(state)
  };
};

export const doPushGameScreen = (lesson: Object, isRestart: boolean) => (
  dispatch: Function,
  getState: Function
) => {
  console.log('lesson', lesson);
  dispatch(
    pushGameScreen(
      getGameData(0, lesson, lesson.gameOrder, getState()),
      lesson.gameOrder,
      isRestart
    )
  );
};

export const getGameData = (
  gameScreensCompleted: number,
  game: Object,
  gameOrder: Array<Object>,
  state: Object
): Object => {
  const isTranscript = getTranscriptionState(state);
  const { words, sentences, dialogues, characters } = game;
  const gameInfo = _.get(
    gameOrder,
    `[${gameScreensCompleted}]`,
    gameOrder[0] || { gameType: '' }
  );
  const writtenNumbers = _.get(
    getCurrentCourseData(state),
    'nativewrittenNumbers.byId',
    {}
  );

  switch (gameInfo.gameType) {
    case FLASHCARD:
      return getFlashcardData(sentences, gameInfo);
    case QUIZ:
      return getQuizData(words, gameInfo);
    case MATCHING:
      return getMatchingData(words);
    case TRANSLATEGAME:
      return getTranslateGameData(sentences, gameInfo);
    case CORRECT_SENTENCE:
      return getCorrectSentenceData(sentences, gameInfo, words);
    case SPELLING:
      return getSpellingData(words, gameInfo, isTranscript || false);
    case ORDER_SENTENCE:
      return getOrderSentenceData(sentences, gameInfo, isTranscript || false);
    case ORDER_SENTENCE_REVERSE:
      return getOrderSentenceReverseData(sentences, gameInfo, writtenNumbers);
    case COMPLETE_SENTENCE:
      return getCompleteSentenceData(
        sentences,
        gameInfo,
        isTranscript || false
      );
    case DIALOGUE:
      return getDialogueSentences(dialogues, isTranscript || false);
    case SPEAKING:
      return getSpeakingData(words, sentences, gameInfo);
    case WRITING:
      return getWritingData(characters, gameInfo);
    case LISTENING:
      return getListeningData(words, gameInfo);
    default:
      return {};
  }
};

// $FlowFixMe
export const without = fp.curry((a, b) => _.without(b, a));

export const getFlashcardData = ({ byId }: Object, { phraseId }: Object) => {
  return {
    gameType: FLASHCARD,
    wordId: phraseId,
    sentenceId: _.findKey(byId, sentence => sentence.word === phraseId)
  };
};

export const getQuizData = ({ allIds }: Object, { phraseId }: Object) => {
  return getQuizLikeData(allIds, phraseId, QUIZ);
};

export const getMatchingData = ({ allIds }: Object) => ({
  gameType: MATCHING,
  wordIds: takeRandomFirst(3, allIds),
  cardIds: _.shuffle([1, 2, 3, 4, 5, 6])
});

export const getTranslateGameData = (
  { allIds }: Object,
  { phraseId }: Object
) => {
  return getQuizLikeData(allIds, phraseId, TRANSLATEGAME);
};

export const getCorrectSentenceData = (
  { byId }: Object,
  { phraseId }: Object,
  words: Object
) => {
  const { original, transcript, translation } = byId[phraseId];
  const originalWords = splitSentence(original);
  const transcriptWords = splitSentence(transcript);
  const singleWords = originalWords.map((word, index) => ({
    original: word,
    transcript: transcriptWords[index]
  }));
  const randomWords = _.sampleSize(words.allIds, 3);
  const isSingleWord = singleWords.length === 1;
  const getLongerSentenceById = getLongerSentence(singleWords, words);
  return {
    byId: {
      '1': {
        ...transformToSentence(
          isSingleWord
            ? getLongerSentenceById(randomWords[0])
            : getShorterSentence(singleWords)
        ),
        id: 1,
        correct: false
      },
      '2': {
        original,
        transcript,
        translation,
        id: 2,
        correct: true
      },
      '3': {
        ...transformToSentence(
          isSingleWord
            ? getLongerSentenceById(randomWords[1])
            : getSwapSentence(singleWords)
        ),
        id: 3,
        correct: false
      },
      '4': {
        ...transformToSentence(getLongerSentenceById(randomWords[2])),
        id: 4,
        correct: false
      }
    },
    allIds: _.shuffle([1, 2, 3, 4]),
    gameType: CORRECT_SENTENCE
  };
};

export const getListeningData = ({ allIds }: Object, { phraseId }: Object) => {
  return getQuizLikeData(allIds, phraseId, LISTENING);
};

export const getSpellingData = (
  { byId }: Object,
  { phraseId }: Object,
  isTranscript: boolean
) => {
  const { original, transcript, imageSource } = byId[phraseId];
  const word = isTranscript ? transcript : original;
  const characters = split(word);
  const indexedArray = getIndexedArray(characters);
  const order = shuffledArray(indexedArray);
  const pressedIds = getPressedIds(characters, indexedArray);

  return {
    gameType: SPELLING,
    wordId: phraseId,
    characters,
    pressedIds: _.isEmpty(pressedIds) ? null : pressedIds,
    imageSource,
    order
  };
};

const getOrderSentenceData = (
  { byId }: Object,
  { phraseId: wordId }: Object,
  isTranscript: boolean
): Object =>
  getOrderSentenceGeneralData(
    wordId,
    ORDER_SENTENCE,
    getOrderSentenceChars(byId, wordId, isTranscript)
  );

export const getOrderSentenceReverseData = (
  { byId }: Object,
  { phraseId: wordId }: Object,
  writtenNumbers: Object
) => {
  const removeDot = fp.map(word => _.replace(word, /\.$/, ''));
  const correctWords = removeDot(
    splitSentence(byId[wordId].translation.trim())
  );
  const getWords = fp.compose(splitSentence, fp.get('translation'));
  const writtenNumbersArr = _.map(writtenNumbers, value => {
    return value;
  });
  const numberArr = _.map(writtenNumbers, (value, key) => {
    return key;
  });
  const wrongWords = fp.compose(
    fp.sampleSize(2),
    fp.difference.convert({
      rearg: true
    })(correctWords),
    fp.difference.convert({
      rearg: true
    })(writtenNumbersArr),
    fp.difference.convert({
      rearg: true
    })(numberArr),
    fp.uniq,
    removeDot,
    fp.flatten,
    fp.map(getWords),
    fp.omit(wordId)
  )(byId);
  const data = getOrderSentenceGeneralData(
    wordId,
    ORDER_SENTENCE_REVERSE,
    correctWords.concat(wrongWords)
  );
  return {
    ...data,
    additionalWords: data.order.filter(id => id > correctWords.length)
  };
};

export const getCompleteSentenceData = (
  { byId }: Object,
  gameInfo: Object,
  isTranscript: boolean
) => {
  const sentenceId = gameInfo.phraseId;
  const { transcript, original } = byId[sentenceId];
  const characters = split(isTranscript ? transcript : original);

  return {
    gameType: COMPLETE_SENTENCE,
    wordId: sentenceId,
    characters,
    order: fp.sampleSize(
      5,
      characters.reduce(
        (indexes, char, index) =>
          PREFILLED_CHARS.includes(char) ? indexes : indexes.concat(index + 1),
        []
      )
    )
  };
};

export const getDialogueSentences = (
  { allIds, byId }: Object,
  isTranscript: boolean,
  questions: ?(string[]) = _.sortBy(takeRandomFirst(5, allIds))
) => {
  const manipulated = _.cloneDeep(byId);
  _.forEach(manipulated, (data, key) => {
    data.id = key;
    data.active = false;
  });
  const characters = [];
  const questionIds = [];
  if (questions) {
    for (const question of questions) {
      const dialogue = manipulated[question];
      const originalSplit = dialogue.original.split(' ');
      const index = _.random(0, originalSplit.length - 1);
      questionIds.push(index);
      characters.push(
        isTranscript
          ? dialogue.transcript
            ? dialogue.transcript.split(' ')[index]
            : ' '
          : originalSplit[index]
      );
    }
  }
  return {
    gameType: DIALOGUE,
    dialogues: manipulated,
    dialogueIds: allIds,
    questions,
    questionIds,
    characters,
    order: shuffledArray(characters)
  };
};

export const getSpeakingData = (
  words: Object,
  sentences: Object,
  { phraseId }: Object
) => {
  console.log('words', words);
  console.log('sentences', sentences);
  console.log('phraseId', phraseId);
  const word = words.byId[phraseId];
  let newWord;
  let newPhaseId;
  if (word) {
    const hasSlash = _.includes(word.original, '/');
    if (hasSlash) {
      newWord = false;
      newPhaseId = setCharAt(phraseId, 5, '1');
    } else {
      newWord = word;
    }
  } else {
    newPhaseId = phraseId;
  }
  const sentence = sentences.byId[newPhaseId];
  const phrase =
    newWord ||
    (sentence && {
      ...sentence,
      imageSource: words.byId[sentence.word].imageSource
    });
  if (!phrase) {
    throw new Error(
      `SPEAKING_GAME: Did not find phrase for phraseId ${phraseId}, word: ${JSON.stringify(
        word,
        null,
        4
      )}, sentence: ${JSON.stringify(sentence, null, 4)}`
    );
  }
  return {
    gameType: SPEAKING,
    phrase,
    phraseId,
    isWord: Boolean(word)
  };
};

export const getWritingData = (characters: Object, gameInfo: Object) => {
  const character = characters.byId[gameInfo.phraseId];
  return {
    gameType: WRITING,
    character: character
  };
};

export const splitSentence = (sentence: string) => _.split(sentence, ' ');

export const getSwapSentence = (words: Object[]) => {
  let wordRemoved = [];
  let wordToSwap = [];
  if (words.length === 2) {
    wordRemoved = words;
    wordToSwap = words;
  } else {
    wordRemoved = getShorterSentence(words);
    wordToSwap = _.sampleSize(wordRemoved, 2);
  }
  const indexes = wordToSwap.map(value => wordRemoved.indexOf(value));
  const result = [...wordRemoved];
  result[indexes[0]] = wordToSwap[1];
  result[indexes[1]] = wordToSwap[0];
  return result;
};

export const getLongerSentence = _.curry(
  (singleWords: Object[], words: Object, wordId: string) => {
    const { byId } = words;
    const { original, transcript } = byId[wordId];
    const result = [...singleWords];
    result.splice(singleWords.indexOf(_.sample(singleWords)), 0, {
      original,
      transcript
    });
    return result;
  }
);

export const getShorterSentence = (words: Object[]): Object[] => {
  const wordToRemove = words.indexOf(_.sample(words));
  return words.filter((value, index) => index !== wordToRemove);
};

export const transformToSentence = (toTransform: Object[]) => {
  return {
    original: reduceToSentence(toTransform, 'original'),
    transcript: reduceToSentence(toTransform, 'transcript')
  };
};

export const reduceToSentence = (toTransform: Object[], type: string) => {
  return _.reduce(
    toTransform,
    (sentence: string, word) => {
      let result = _.get(word, type, '');
      return `${sentence} ${result}`;
    },
    ''
  ).trim();
};

export const getQuizLikeData = (
  allIds: string[],
  phraseId: string,
  gameType: string
) => {
  return {
    gameType,
    wordIds: fp.compose(
      fp.shuffle,
      fp.concat(phraseId),
      fp.sampleSize(3),
      without(phraseId)
    )(allIds),
    correctWord: phraseId
  };
};

export const getPressedIds = (
  chars: string[],
  indexedArray: number[]
): Array<any> => {
  const prefilledIds = indexedArray.filter((_, id) =>
    PREFILLED_CHARS.includes(chars[id])
  );

  const leftToPrefill =
    chars.length - PREFILLED_CHARS_COUNT - prefilledIds.length;

  if (leftToPrefill > 0) {
    prefilledIds.push(
      ..._.sampleSize(_.difference(indexedArray, prefilledIds), leftToPrefill)
    );
  }

  return indexedArray.map(element =>
    prefilledIds.includes(element) ? element : null
  );
};

export const getOrderSentenceGeneralData = (
  wordId: string,
  gameType: string,
  words: Array<any>
) => ({
  gameType,
  wordId,
  characters: words,
  order: shuffledArray(words)
});

export const getOrderSentenceChars = (
  byId: Object,
  sentenceId: string,
  isTranscript: boolean = false
) => {
  const { transcript, original } = byId[sentenceId];
  return splitSentence(isTranscript ? transcript : original);
};

export const setCharAt = (str: string, index: number, chr: string) => {
  if (index > str.length - 1) {
    return str;
  } else {
    return str.substr(0, index) + chr + str.substr(index + 1);
  }
};

export const shuffledArray = (elements: any[]) =>
  _.shuffle(getIndexedArray(elements));

export const takeRandomFirst = fp.curry((length: number, elements: string[]) =>
  _.take(_.shuffle(elements), length)
);

export const finishLoadAnimation = (numberOfCards: number) => {
  let numberOfCalls = 0;
  return (dispatch: Function) => {
    numberOfCalls++;
    if (numberOfCalls === numberOfCards) {
      dispatch({
        type: LOAD_ANIMATION_FINISHED
      });
      numberOfCalls = 0;
    }
  };
};

export const nextGameScreen = () => (
  dispatch: Function,
  getState: Function
) => {
  try {
    doNextGameScreen(dispatch, getState);
  } catch (error) {
    BugTracker.notify(error);
    handleSpeakingError(error);
  }
};

export const doNextGameScreen = (dispatch: Function, getState: Function) => {
  trackGameProgress(dispatch, getState);

  const state = getState();
  const { game, routes } = state;
  const { features } = getCurrentCourseData(state);
  const { hasGrammar } = features;
  const {
    gameScreensCompleted,
    isExam,
    gameWrongAnswered,
    gameType,
    isCurrentGameWrong,
    isRandomTest
  } = routes;
  const { key } = game;
  const gameOrder =
    isRandomTest ||
    isExam ||
    [DIALOGUE, SPEAKING, QUIZ].includes(gameType) ||
    key === TUTORIAL_KEY
      ? game.gameOrder
      : newGameOrderWhenAnsweredWrong(
          game.gameOrder,
          gameScreensCompleted,
          isCurrentGameWrong
        );
  const checkGrammar = getCheckGrammar(state);
  const totalGame = gameOrder.length;
  if (
    (isExam && gameWrongAnswered === EXAM_MAX_HEARTS + 1) ||
    (isRandomTest && gameWrongAnswered === EXAM_MAX_HEARTS)
  ) {
    dispatch(toggleCompleteScreen(true));
  } else if (gameScreensCompleted === totalGame) {
    const lessonId = getLessonId(state);
    const progress = getUserProgress(state);
    const { unitId } = state.lessons;
    const unit = prepareProgress(progress, unitId);
    dispatch(updateProgress(lessonId, unit, progress));
    dispatch(toggleCompleteScreen());
  } else {
    const currentGame = gameOrder[gameScreensCompleted + 1];
    if (currentGame && currentGame.showGrammar && hasGrammar && checkGrammar) {
      dispatch(toggleGrammarScreen());
    }
    dispatch(
      newGameScreen(
        getGameData(gameScreensCompleted, game, gameOrder, state),
        gameOrder
      )
    );
  }
};

export const trackGameProgress = (dispatch: Function, getState: Function) => {
  if (getState().routes.isCurrentGameWrong) {
    dispatch(increaseAnsweredWrong());
    dispatch(increaseAnsweredWrongCount());
  } else if (getState().routes.isCurrentGameWrong === false) {
    resetAnsweredWrongCount();
  }
  dispatch(increaseCompletedScreen());
};

export const getDialogueChars = (
  byId: Object,
  questions: any,
  questionIds: any,
  isTranscript: boolean = false
) => {
  const characters = [];
  for (const i in questions) {
    const dialogue = byId[questions[i]];
    const originalSplit = dialogue.original.split(' ');
    characters.push(
      isTranscript
        ? dialogue.transcript
          ? dialogue.transcript.split(' ')[questionIds[i]]
          : ' '
        : originalSplit[questionIds[i]]
    );
  }
  return characters;
};

export const refreshGame = () => (dispatch: Function, getState: Function) => {
  const state = getState();
  const { game, spelling, routes } = state;
  const { gameScreensCompleted } = routes;
  if (!spelling.isResultShown) {
    dispatch(
      refreshGameScreen(
        getGameData(gameScreensCompleted, game, game.gameOrder, state),
        game.gameOrder
      )
    );
  }
};

export const updateWeeklyGraph = (bananas: number) => (
  dispatch: Function,
  getState: Function
) => {
  const state = getState();
  const goal = getCurrentUserGoalData(state);
  const updatedGoal = getUpdatedWeeklyGoal(goal, bananas);
  dispatch(updateUserData({ goal: updatedGoal }));
};
