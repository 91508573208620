/* @flow */
import _ from 'lodash';
import * as types from '../constants';
import { SOUND_PATH } from '../../common/constants';
import { nextGameScreen } from './GameActions';
import { increaseAnsweredWrong } from './GamescreenActions';
import { playSoundWithCallback } from '../../util/SoundPlayer';
import Analytic from '../../util/Analytic';
import { getVoiceSpeed } from '../../chatbots/selectors';
let currentSound = 0;
export const dialogSaveScrollFunction = (func: Function) => ({
  type: types.DIALOGUE_SAVE_SCROLL_FUNCTION,
  func
});

export const dialogResetGame = () => ({
  type: types.DIALOGUE_RESET_GAME
});

export const pauseDialog = () => {
  return (dispatch: Function, getState: Function) => {
    const state = getState();
    const { dialog } = state;
    const { pausing } = dialog;
    const { dialogues } = dialog;
    if (dialogues.length !== 0 && pausing) {
      dispatch({
        type: types.PAUSE_DIALOGUE,
        currentSound
      });
      dispatch(playSequenceSound(currentSound + 1));
    } else {
      dispatch({
        type: types.PAUSE_DIALOGUE,
        currentSound
      });
    }
  };
};

export const dialogStartGame = () => ({
  type: types.DIALOGUE_START_GAME
});

export const dialogRefreshGame = () => ({
  type: types.DIALOGUE_REFRESH_GAME
});

export const dialogMessageActive = (id: number) => ({
  type: types.DIALOGUE_MESSAGE_ACTIVE,
  id
});

export const dialogMessageDeactive = () => ({
  type: types.DIALOGUE_MESSAGE_DEACTIVE
});

export const dialogDismissNotify = () => ({
  type: types.DIALOGUE_DISMISS_NOTIFY
});

export const dialogReplay = () => ({
  type: types.DIALOGUE_REPLAY
});

export const dialogValidate = (status: boolean, dialogues: Array<any>) => ({
  type: types.DIALOGUE_CHECK_ANSWER,
  status,
  dialogues
});

export const dialogTranslate = () => ({
  type: types.DIALOGUE_TRANSLATE
});

export const stopDialogSound = () => ({
  type: types.DIALOGUE_SOUND_STOP
});

export const saveSequenceObj = (obj: Object, sequence: any) => {
  return {
    type: types.DIALOGUE_SOUND_START,
    sound: obj,
    sequence: sequence
  };
};

export const dialogCheckAnswer = () => {
  return (dispatch: Function, getState: Function) => {
    const state = getState();
    let pass = true;
    const checking = _.map(state.dialog.dialogues, dialog => {
      if (dialog.isGame) {
        if (
          dialog.word.transcript !== dialog.answer.transcript &&
          dialog.word.original !== dialog.answer.original
        ) {
          pass = false;
          return {
            ...dialog,
            status: 2
          };
        } else {
          return {
            ...dialog,
            status: 1
          };
        }
      }
      return dialog;
    });

    dispatch({ type: types.DIALOGUE_RESET_VIEW });
    if (state.dialog.toggleCheck) {
      if (!pass) {
        dispatch(increaseAnsweredWrong());
      }
      const { game, routes } = getState();
      Analytic.log(Analytic.key.G1, {
        game_type: routes.gameType,
        content: game.key,
        answer: pass
      });
      Analytic.logGA(Analytic.key.G1, {
        category: Analytic.key.G1,
        action: 'Check',
        label: routes.gameType,
        value: pass
      });
      dispatch(nextGameScreen());
    } else {
      dispatch(dialogValidate(pass, checking));
    }
  };
};

export const playDialogSound = (soundId: any, sequence: boolean = false) => (
  dispatch: Function
) => {
  if (sequence) {
    dispatch(playSequenceSound(soundId));
  } else {
    dispatch(playSingleSound(soundId));
  }
};

export const playSingleSound = (soundId: string) => {
  return (dispatch: Function, getState: Function) => {
    const state = getState();
    const { targetLangCode } = state.data;
    const voiceSpeed = getVoiceSpeed(state);
    dispatch(stopDialogSound());
    playSoundWithCallback(
      soundId,
      voiceSpeed,
      `${SOUND_PATH}/${targetLangCode}`,
      () => {
        dispatch(dialogMessageDeactive());
        dispatch(stopDialogSound());
      }
    );
  };
};

export const playSequenceSound = (soundKey: number) => {
  return (dispatch: Function, getState: Function) => {
    const state = getState();
    const { dialog, data } = state;
    const { targetLangCode } = data;
    const { pausing, playing } = dialog;
    const dialogues = dialog.dialogues;
    const soundsId = dialog.dialogueIds;
    const voiceSpeed = getVoiceSpeed(state);
    let soundId = '';

    if (playing && !pausing) {
      if (_.size(dialogues) <= soundKey) {
        currentSound = 0;
        dispatch(stopDialogSound());
        dispatch({ type: types.DIALOGUE_RESET_VIEW });
        return;
      } else {
        let id = soundsId[soundKey] ? soundsId[soundKey] : soundKey;
        soundId = dialogues[id] ? dialogues[id].soundFile : '';
      }
      currentSound = soundKey;

      playSoundWithCallback(
        soundId,
        voiceSpeed,
        `${SOUND_PATH}/${targetLangCode}`,
        () => {
          if (dialog.playing) {
            dispatch(playSequenceSoundWithCallback(soundKey, soundId));
          }
        },
        soundObject => {
          dispatch(dialogMessageActive(soundKey));
          dispatch(saveSequenceObj(soundObject));
        }
      );
    }
  };
};

export const playSequenceSoundWithCallback = (
  soundKey: number,
  soundId: string
) => {
  return (dispatch: Function, getState: Function) => {
    const { dialog } = getState();
    const dialogues = dialog.dialogues;
    if (dialog.state === 0 && dialog.playing) {
      if (_.size(dialogues) > soundKey) {
        dispatch(dialogMessageDeactive());
        dispatch(playDialogSound(soundKey + 1, true));
      } else {
        dispatch(playSingleSound(soundId));
      }
    } else {
      currentSound = 0;

      dispatch(stopDialogSound());
      dispatch(dialogMessageDeactive());
    }
  };
};

export const loadDialogue = (dialogues: Object[]) => ({
  type: types.LOAD_DIALOGUE,
  dialogues: _.keyBy(dialogues, 'id'),
  dialogueIds: _.map(dialogues, 'id')
});
