/* @flow */
import {
  RESET_WRITING_UI_STATE,
  FINISH_WRITING,
  RESET_GUIDE_ACTIVE,
  ON_GUIDE_ACTIVE
} from '../constants/WritingTypes';
import { REFRESH_GAME, CLOSE_GAME } from '../constants/ActionTypes';
import { WRITING } from '../constants/GameTypes';
export const INITIAL_STATE = {
  character: null,
  isWritingFinished: false,
  isGuideActive: false
};

const loadWriting = (state: Object, { character }: Object): Object => {
  return {
    ...INITIAL_STATE,
    character
  };
};

const finishWriting = state => {
  return {
    ...state,
    isWritingFinished: true
  };
};

const resetWritingUIState = state => {
  return INITIAL_STATE;
};

const resetGuideActive = state => {
  return {
    ...state,
    isGuideActive: false
  };
};

const onGuideActive = state => {
  return {
    ...state,
    isGuideActive: !state.isGuideActive
  };
};

export const WritingReducer = (
  state: Object = INITIAL_STATE,
  action: Object
) => {
  const { type } = action;
  switch (type) {
    case CLOSE_GAME:
      return INITIAL_STATE;
    case REFRESH_GAME:
      if (action.gameType === WRITING) {
        return loadWriting(state, action);
      }
      break;
    case RESET_WRITING_UI_STATE:
      return resetWritingUIState(state);
    case FINISH_WRITING:
      return finishWriting(state);
    case RESET_GUIDE_ACTIVE:
      return resetGuideActive(state);
    case ON_GUIDE_ACTIVE:
      return onGuideActive(state);
    default:
      return state;
  }
  return state;
};
