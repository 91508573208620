import LanguageOnboardingContainer from '../../common/containers/LanguageOnboardingContainer';
import TimeSelector from '../selectorComponents/TimeSelector';
import CampaignScreenText, { imgPath } from './CampaignScreenText';
import MotivationalScreen from '../components/MotivationalScreen';
import IntroductionScreen from '../components/IntroductionScreen';
import UserInfoSelector from '../selectorComponents/UserInfoSelector';
import GlobalRecognitionScreen from '../components/GlobalRecognitionScreen';
import UserLanguageLevel from '../components/UserLanguageLevel';
import NewSurveySelector from '../selectorComponents/NewSurveySelector';

// Empty Template
// 2: {
//   text: ``,
//   icon: imgPath('.png')
// }

const CampaignList = (target, labels = {}) => ({
  1: {
    userProps: {
      // Note: 0 was:
      // 0: { eventName: '', screenName: 'intro_screen' },
      // We updated because of https://app.clickup.com/t/9003040700/LING-5181
      0: { eventName: '', screenName: '' },
      1: { eventName: 'select_language', screenName: '' },
      2: { eventName: 'screen_view', screenName: 'gender' },
      3: { eventName: 'age', screenName: '' },
      4: { eventName: 'screen_view', screenName: 'global_recognition' },
      5: { eventName: 'screen_view', screenName: 'motivation_career' },
      6: { eventName: 'screen_view', screenName: 'motivation_relocation' },
      7: { eventName: 'screen_view', screenName: 'motivation_journey' },
      8: { eventName: 'screen_view', screenName: 'motivation_discussion' },
      9: { eventName: 'screen_view', screenName: 'motivation_updates' },
      10: { eventName: 'screen_view', screenName: 'language_skills' },
      11: { eventName: 'screen_view', screenName: 'goal_level' },
      12: { eventName: 'language_aspects', screenName: '' },
      13: { eventName: 'time_for_learning', screenName: '' },
      14: { eventName: 'studying_frequency', screenName: '' },
      15: { eventName: 'study_time', screenName: '' },
      16: {
        eventName: 'topics',
        screenName: '',
        userProps: 'topics_for_learning'
      },
      17: {
        eventName: 'heard_about_ling',
        screenName: '',
        userProps: 'traffic_source'
      }
    },
    headerData: {
      0: {
        text: labels['txt_boost_language_skills'],
        subtext: labels['txt_desc_answer_some_questions'],
        icon: ''
      },
      1: {
        text: labels['txt_language_to_learn'],
        icon: ''
      },
      2: {
        text: labels['txt_tell_us_about_yourself'],
        subtext: labels['txt_what_gender'],
        icon: imgPath('survey-ling-full.png')
      },
      3: {
        text: labels['txt_tell_us_about_yourself'],
        subtext: labels['txt_how_old'],
        icon: imgPath('survey-ling-full.png')
      },
      4: {
        text: labels['txt_trust_with_5_million_learners'],
        icon: ''
      },
      5: {
        text: labels['txt_why_learn_language']?.replace('%s', target),
        subtext: labels['txt_statement_boost_career'],
        icon: imgPath('survey-ling-full.png')
      },
      6: {
        text: labels['txt_why_learn_language']?.replace('%s', target),
        subtext: labels['txt_statement_relocate'],
        icon: imgPath('survey-ling-full.png')
      },
      7: {
        text: labels['txt_why_learn_language']?.replace('%s', target),
        subtext: labels['txt_statement_ready_for_journeys'],
        icon: imgPath('survey-ling-full.png')
      },
      8: {
        text: labels['txt_why_learn_language']?.replace('%s', target),
        subtext: labels['txt_statement_discuss_topics_with_friends'],
        icon: imgPath('survey-ling-full.png')
      },
      9: {
        text: labels['txt_why_learn_language']?.replace('%s', target),
        subtext: labels['txt_statement_want_to_stay_informed']?.replace(
          'X',
          target
        ), // It's X in the label instead of %s for this one somehow
        icon: imgPath('survey-ling-full.png')
      },
      10: {
        text: labels['txt_how_your_language_skills']?.replace('%s', target),
        subtext: labels['txt_desc_rate_skills']?.replace('%s', target),
        icon: imgPath('survey-ling-full.png')
      },
      11: {
        text: labels['txt_goal_for_learning']?.replace('%s', target),
        icon: imgPath('survey-ling-full.png')
      },
      12: {
        text: labels['txt_aspects_to_improve'],
        icon: imgPath('survey-ling-full.png')
      },
      13: {
        text: labels['txt_time_to_learn']?.replace('%s', target),
        icon: imgPath('survey-ling-full.png')
      },
      14: {
        text: labels['txt_studying_frequency'],
        icon: imgPath('survey-ling-full.png')
      },
      15: {
        text: labels['survey_question_2'],
        icon: imgPath('survey-ling-full.png')
      },
      16: {
        text: labels['txt_topics'],
        icon: imgPath('survey-ling-full.png')
      },
      17: {
        text: labels['survey_question_4'],
        icon: imgPath('survey-ling-full.png'),
        customStyle: { maxWidth: 540 }
      }
    },
    screens: [
      IntroductionScreen,
      LanguageOnboardingContainer,
      UserInfoSelector,
      UserInfoSelector,
      GlobalRecognitionScreen,
      MotivationalScreen,
      MotivationalScreen,
      MotivationalScreen,
      MotivationalScreen,
      MotivationalScreen,
      UserLanguageLevel,
      NewSurveySelector,
      NewSurveySelector,
      TimeSelector,
      NewSurveySelector,
      NewSurveySelector,
      NewSurveySelector,
      NewSurveySelector
    ],
    screenText: CampaignScreenText(labels)
  }
});

export default CampaignList;
