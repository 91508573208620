import styled from 'styled-components';
import { WHITE } from '../../../common/constants';

const IndicatorsSpace = styled.div`
  height: 40px;
`;

const HeroContentSubTextStyle = styled.div`
  line-height: initial;
  margin-top: 7px;
`;

const CarouselContainer = styled.div`
  margin-bottom: ${props => (props.isPromo ? 5 : 50)}px;
  z-index: 1;
`;

const HeroContentStyle = styled.div`
  width: 400px;
  height: 185px;
  margin: auto;
  margin-bottom: ${props =>
    props.isPromo ? (props.isClaimReward ? 179 : 165) : 88}px;
  margin-top: ${props =>
    props.isPromo ? (props.isClaimReward ? '100px' : '93px') : 'unset'};
  text-align: center;
`;

const HeroContentImageContainerStyle = styled.div``;

const HeroContentImageStyle = styled.img`
  height: ${props => (props.isClaimReward ? 140 : 120)}px;
  margin-bottom: 10px;
  border-radius: 50%;
`;

const HeroContentHeaderStyle = styled.div`
  text-transform: capitalize;
  font-size: 21px;
  font-weight: bold;
`;

const HeroContentTextStyle = styled.div`
  color: ${WHITE};
  margin-top: ${props =>
    props.isPromo ? (props.isClaimReward ? 0 : 15) : 23}px;
  margin-bottom: 85px;
`;

export {
  HeroContentTextStyle,
  HeroContentHeaderStyle,
  HeroContentImageStyle,
  HeroContentImageContainerStyle,
  HeroContentStyle,
  CarouselContainer,
  HeroContentSubTextStyle,
  IndicatorsSpace
};
