/* @flow */
import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import {
  PREFIX_LESSON,
  IMAGE_PATH,
  WHITE,
  GREY,
  GREY2,
  DARK_GREY_1
} from '../../common/constants';

const ModalImage = styled.img`
  width: 209px;
  height: 141px;
  margin-top: 70px !important;
`;

const ModalCloseButton = styled.button`
  position: fixed;
  opacity: 1;
  &.close {
    padding: 32px 16px 1px 1px !important;
  }
`;
const ModalBodyText = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${DARK_GREY_1};
  width: 277px;
  line-height: 22px;
  margin: auto;
  margin-top: 0 !important;
`;
export const ModalBodyContainer = styled.div`
  display: ${props => (props.isLoading ? 'block' : 'none')};
  display: flex;
  justify-content: center;
`;
const ModalContentContainer = styled.div`
  margin: auto 25px;
`;
const LingLoading = styled.img`
  margin: 134px 38px;
`;
const styles = {
  modalContainer: {
    width: '485px',
    height: '457px',
    textAlign: 'center',
    borderRadius: 30,
    transition: 'none'
  },
  modalBodyContainer: {
    padding: 0,
    paddingBottom: 20,
    textAlign: 'center'
  },
  buttonStyle: {
    borderRadius: 15,
    height: 55,
    background: WHITE,
    border: 'solid 1px',
    borderColor: GREY2,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    boxShadowColor: GREY2,
    fontWeight: 'bold',
    fontSize: 18,
    margin: 'auto',
    paddingRight: 42,
    paddingLeft: 42
  },
  cancelButtonStyle: {
    border: 'none',
    boxShadow: 'none',
    width: 100
  },
  confirmButtonStyle: {
    borderColor: GREY2,
    background: WHITE,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    width: 133,
    height: 41
  },
  modalHeader: {
    borderBottom: 'none',
    flexDirection: 'row-reverse',
    justifyContent: 'normal',
    padding: 0,
    marginBottom: 29
  },
  modalFooter: {
    borderTop: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 30,
    paddingRight: 32,
    paddingLeft: 32,
    paddingTop: 22
  },
  contentContainer: {
    borderRadius: 30
  },
  modalHeaderContainer: {
    'modal-title': 'w-100 text-center'
  }
};

const lingLoading = `${IMAGE_PATH}ling-loader.gif`;
const closeIcon = `${PREFIX_LESSON}basecolor-black.svg`;
type Props = {
  isOpen: boolean,
  goToHome: Function,
  toggle: Function,
  modal_btn_redeem_go_home: string,
  modal_txt_redeem: string,
  redeemSuccessImg: string,
  isLoading: boolean
};

const RedeemSuccessModal = ({
  isOpen,
  goToHome,
  toggle,
  redeemSuccessImg,
  modal_txt_redeem,
  modal_btn_redeem_go_home,
  isLoading
}: Props) => {
  const closeBtn = (
    <ModalCloseButton className="close" onClick={toggle}>
      <img src={closeIcon} alt={closeIcon} />
    </ModalCloseButton>
  );
  const {
    modalHeader,
    modalBodyContainer,
    modalFooter,
    buttonStyle,
    confirmButtonStyle,
    modalContainer
  } = styles;
  return (
    <Modal
      centered
      style={modalContainer}
      isOpen={isOpen}
      contentClassName="contentContainer"
    >
      {isLoading ? null : (
        <ModalHeader style={modalHeader} close={closeBtn}>
          <ModalImage src={redeemSuccessImg} alt={redeemSuccessImg} />
        </ModalHeader>
      )}
      {!isLoading ? (
        <React.Fragment>
          <ModalContentContainer>
            <ModalBody style={modalBodyContainer}>
              <React.Fragment>
                <ModalBodyText>{modal_txt_redeem}</ModalBodyText>
              </React.Fragment>
            </ModalBody>
          </ModalContentContainer>

          <ModalFooter style={modalFooter}>
            <Button
              className="confirmButton"
              style={{ ...buttonStyle, ...confirmButtonStyle }}
              onClick={goToHome}
            >
              {modal_btn_redeem_go_home}
            </Button>
          </ModalFooter>
        </React.Fragment>
      ) : (
        <ModalBodyContainer isLoading={isLoading}>
          <LingLoading src={lingLoading} />
        </ModalBodyContainer>
      )}
    </Modal>
  );
};

export { RedeemSuccessModal };
