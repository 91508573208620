/* @flow */
import React, { Component } from 'react';
import { firebase } from '../../system/Firebase';
import BugTracker from '../../util/BugTracker';
import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import { SHOW_TUTORIAL, TRUE } from '../../common/constants';
import { fetchRemoteDatabase } from '../../lessons/sagas';
import { toggleChildCreateAccountLanguagePicker } from '../../util/AuthenticatedMethod';
import NativeLanguageSelector from './subComponents/NativeLanguageSelector';
import CourseRender from './subComponents/CourseRender';
import { imgPath } from '../data/CampaignScreenText';

type Props = {
  labels: Object,
  location: any,
  addToSubscribeNewsList: Function,
  nativeLangCode: string,
  targetLangCode: string,
  triggerFetchUnitMetaData: Function,
  getFlagImageUrl: Function,
  getLanguageLabel: Function,
  getLanguageNativeLang: Function,
  fetchLanguageData: Function,
  setLanguageData: Function,
  setNativeLanguage: Function,
  setTargetLanguage: Function,
  triggerLoadLanguageLabel: Function,
  triggerLoadInitialTutorial: Function,
  triggerLoadLanguageData: Function,
  saveUserData: Function,
  updateUserData: Function,
  createNewDatabaseUser: Function,
  toggleChatbotLoaded: Function,
  fetchUnitData: Function,
  nativeLanguages: Array<Object>,
  targetLanguages: Array<Object>,
  history: any,
  triggerLoadAllTargetLanguages: Function,
  setSelected: Function,
  handleScreenChange: Function,
  trackScreenView: Function
};

type State = {
  native: string,
  target: string,
  search: string,
  dropDownSearch: string,
  isNativeDropdownOpen: boolean,
  isLargeScreen: boolean
};
class LanguagePicker extends Component<Props, State> {
  child: any;
  constructor(props: Props) {
    super(props);
    const { nativeLangCode, targetLangCode } = this.props;
    this.state = {
      native: nativeLangCode,
      target: targetLangCode,
      search: '',
      dropDownSearch: '',
      isNativeDropdownOpen: false,
      isLargeScreen: window.innerWidth > 600
    };
    // this.ref = createRef();
  }

  componentDidMount() {
    this.props.trackScreenView('language_picker');
    window.addEventListener('resize', this.handleResize);
    const {
      setNativeLanguage,
      setLanguageData,
      location,
      targetLangCode,
      triggerLoadAllTargetLanguages
    } = this.props;
    if (!_.isEmpty(targetLangCode)) {
      this.props.setSelected(targetLangCode);
    }
    const { search = '' } = location || {};
    if (search) {
      const queryNative = search.match(/native=([^&]*)/);
      const queryTarget = search.match(/target=([^&]*)/);
      console.log('Query', queryNative);
      console.log('Query', queryTarget);

      if (queryNative && queryTarget) {
        const native = queryNative[1];
        const target = queryTarget[1];
        setNativeLanguage(native);
        setLanguageData(target, native);
      }
    }

    const { fetchLanguageData, triggerLoadLanguageLabel } = this.props;
    window.scrollTo(0, 0);

    let auth = firebase.auth();

    auth.onAuthStateChanged(user => {
      if (!user) {
        auth
          .signInAnonymously()
          .then(result => {
            const { user } = result;

            this.fetchUser(user);
            fetchLanguageData();
            triggerLoadLanguageLabel();
            triggerLoadAllTargetLanguages();
          })
          .catch(error => {
            // Handle Errors here.
            var errorCode = error.code;

            if (errorCode === 'auth/operation-not-allowed') {
              alert('You must enable Anonymous auth in the Firebase Console.');
            }
            BugTracker.notify(error);
          });
      } else {
        fetchLanguageData();
        triggerLoadLanguageLabel();
        triggerLoadAllTargetLanguages();
      }
    });
  }

  handleResize = () => {
    this.setState({
      isLargeScreen: window.innerWidth > 600
    });
  };

  fetchUser(user: Object) {
    fetchRemoteDatabase(`users/${user.uid}`).then(data => {
      const { saveUserData, createNewDatabaseUser } = this.props;
      const value = data && data.val();
      if (value) {
        saveUserData(value);
      } else {
        createNewDatabaseUser(user, 'anonymous');
      }
    });
  }

  handleNativeLanguageSelect = (langCode: string) => {
    this.setState({ native: langCode });
    this.props.setSelected(langCode);
    this.props.setNativeLanguage(langCode);
    this.props.triggerLoadLanguageData(this.state.target, langCode);
  };

  handleCourseSelect = (langCode: string) => {
    this.setState({ target: langCode });
    this.props.setSelected(langCode);
    this.props.setNativeLanguage(this.state.native);
    this.props.setTargetLanguage(langCode);
    this.props.setLanguageData(langCode, this.state.native);
    // eslint-disable-next-line
    if (localStorage.getItem(SHOW_TUTORIAL) === TRUE) {
      this.props.triggerLoadInitialTutorial();
      this.props.toggleChatbotLoaded();
    } else {
      //NOTE:this use to load lesson data when user change language
      this.props.fetchUnitData(langCode, this.state.native, 1, () => {
        this.props.toggleChatbotLoaded();
        // this.props.history.push('/');
      });
    }
    this.props.toggleChatbotLoaded(false);
    this.props.triggerFetchUnitMetaData();
  };

  onSeachInputChange = (event: SyntheticInputEvent<HTMLInputElement>) =>
    this.setState({ search: event.target.value });

  onDropdownSeachInputChange = (event: SyntheticInputEvent<HTMLInputElement>) =>
    this.setState({ dropDownSearch: event.target.value });

  toggleNativeDropdown = () =>
    this.setState({
      isNativeDropdownOpen: !this.state.isNativeDropdownOpen,
      dropDownSearch: ''
    });

  toggleCreateAccount = () =>
    toggleChildCreateAccountLanguagePicker(this.child);

  render() {
    const { isLargeScreen } = this.state;
    const { getLanguageLabel } = this.props;

    const styles = {
      main: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      },
      container: {
        display: 'flex',
        paddingTop: 0,
        alignItems: 'center',
        justifyContent: 'center',
        mt: 10
      },
      courseContainer: {
        display: 'flex',
        justifyContent: isLargeScreen ? 'center' : 'flex-start',
        width: '90%'
      },
      languageSelectorContainer: {
        color: '#B3B3B3',
        fontSize: isLargeScreen ? 36 : 30,
        width: '100%',
        textAlign: 'right',
        textWrap: 'nowrap'
      },
      selectorTextContainer: {
        display: 'flex',
        justifyContent: 'center'
      },
      topContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        textAlign: 'center',
        mb: 16
      },
      img: {
        height: '65px',
        mb: 6
      },
      header: {
        fontWeight: '700',
        fontSize: isLargeScreen ? 20 : 18,
        color: '#111111'
      }
    };

    const { labels } = this.props;
    const { txt_tell_us_about_yourself } = labels;

    return (
      <Box sx={styles.main}>
        <Box sx={styles.container}>
          <Box sx={styles.topContainer}>
            <Box
              component="img"
              src={imgPath('survey-ling-full.png')}
              sx={styles.img}
            />
            <Typography variant="subtext" sx={styles.header}>
              {txt_tell_us_about_yourself}
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.courseContainer}>
          <Box sx={styles.selectorTextContainer}>
            <NativeLanguageSelector
              dropDownSearch={this.state.dropDownSearch}
              native={this.state.native}
              nativeLanguages={this.props.nativeLanguages}
              getLanguageLabel={getLanguageLabel}
              getLanguageNativeLang={this.props.getLanguageNativeLang}
              handleNativeLanguageSelect={this.handleNativeLanguageSelect}
              getFlagImageUrl={this.props.getFlagImageUrl}
              labels={labels}
            />
          </Box>
          <CourseRender
            native={this.state.native}
            getFlagImageUrl={this.props.getFlagImageUrl}
            getLanguageLabel={getLanguageLabel}
            handleCourseSelect={this.handleCourseSelect}
            target={this.state.target}
            targetLanguages={this.props.targetLanguages}
            search={this.state.search}
            handleScreenChange={this.props.handleScreenChange}
            labels={labels}
          />
        </Box>
      </Box>
    );
  }
}

export default LanguagePicker;
