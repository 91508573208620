/* @flow */
import { connect } from 'react-redux';
import { Affiliate } from '../../components/main-components/Affiliate';
import { getLabels } from '../../util';
import {
  linkWithGoogleRedirect,
  signInWithFbRedirect,
  onRedirectSignInWithCredential,
  linkWithFbRedirect,
  linkWithAppleRedirect,
  sendPasswordResetEmail,
  signUpWithEmail,
  signInWithEmailAndPassword,
  saveUserData,
  createNewDatabaseUser,
  triggerLoadLanguageLabel
} from '../actions';

export const mapStateToProps = (state: Object): Object => {
  const functions = process.env.REACT_APP_FUNCTIONS;
  const isDev = process.env.REACT_APP_DEV;

  return {
    functions,
    isDev,
    ...getLabels(
      state,
      'reset_password_txt_description',
      'login_field_email',
      'btn_continue',
      'login_field_name',
      'login_field_firstName',
      'login_field_password',
      'login_msg_reenterPassword',
      'at_least_6_password_txt',
      'sign_up_txt_title',
      'login_txt_title',
      'login_txt_forget_password_title',
      'login_txt_forget_password_web',
      'already_register_txt',
      'email_txt_loginNow_link',
      'claim_warning_txt',
      'dont_have_account_yet_txt',
      'email_error_fieldsMissing',
      'email_error_wrongFormat',
      'email_error_matchPasswords',
      'email_error_longerPassword',
      'email_error_alreadyUsed',
      'email_error_userNotFound',
      'email_error_wrongPassword',
      'login_txt_forget_password_title',
      'txt_email_field_placeholder'
    )
  };
};

export const mapDispatchToProps = (dispatch: Function) => {
  return {
    onRedirectSignInWithCredential,
    triggerLoadLanguageLabel: () => dispatch(triggerLoadLanguageLabel()),
    signInWithFbRedirect: () => dispatch(signInWithFbRedirect()),
    linkWithGoogleRedirect: () => dispatch(linkWithGoogleRedirect()),
    linkWithFbRedirect: () => dispatch(linkWithFbRedirect()),
    linkWithAppleRedirect: () => dispatch(linkWithAppleRedirect()),
    sendPasswordResetEmail: (email: string) =>
      dispatch(sendPasswordResetEmail(email)),
    signUpWithEmail: (name: string, email: string, password: string) =>
      dispatch(signUpWithEmail(name, email, password)),
    signInWithEmailAndPassword: (email: string, password: string) =>
      dispatch(signInWithEmailAndPassword(email, password)),
    saveUserData: (user: Object) => dispatch(saveUserData(user)),
    createNewDatabaseUser: (
      authUser: any,
      type: string,
      existingUser: ?Object = {}
    ) => dispatch(createNewDatabaseUser(authUser, type, existingUser))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Affiliate);
