/* @flow */
import * as React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import styled from 'styled-components';
import { BLACK } from '../../../../common/constants';
import { isMobile } from '../../../../util';
export const LeftContent = styled.div`
  background: url(${props => props.loginPurchaseBG});
  justify-content: center;
  display: flex;
  align-items: center;
  width: 175px;
  border-radius: 20px 0 0 20px;
`;

const styles = {
  modalContainer: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    minWidth: '100vw',
    margin: 'auto',
    backgroundColor: '#E7F9E8',
    borderRadius: 0,
    overflowY: 'auto'
  }
};
const InstallButton = styled.button`
  width: ${window.innerWidth - 75}px;
  max-width: 345px;
  height: 40px;
  border-radius: 10px;
  background-color: #ff9900;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(247, 181, 0);
  border-image: initial;
  z-index: 3;
  color: ${BLACK};
  &:hover {
    box-shadow: none;
    opacity: 0.4;
    border-color: rgb(255, 204, 0);
  }
`;

type Props = {
  title?: String,
  description?: String,
  buttonName?: string
};

const WelcomeLingModal = ({
  title = 'Welcome to Ling',
  description = `We're happy you're here! Install the mobile app to start learning.`,
  buttonName = 'Install',
}: Props) => {
  const { modalContainer } = styles;
  return (
    <Modal isOpen={true} toggle={() => {}} fullscreen style={modalContainer}>
      <ModalBody
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: '#E7F9E8',
          margin: -1,
          minWidth: 320
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 50,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center'
          }}
        >
          <img
            src={require('../../../../img/icons/snow.png')}
            alt="snow"
            style={{
              zIndex: 1,
              position: 'absolute',
              top: 0,
              left: 0,
              height: '50%',
              width: '100%',
              paddingTop: -100
            }}
          />
          <img
            src={require('../../../../img/icons/welcome-ling.png')}
            alt="Welcome Ling"
            style={{
              maxWidth: '100%',
              height: 378,
              paddingBottom: 20,
              zIndex: 2
            }}
          />
          <div
            style={{
              width: window.innerWidth - 40,
              maxWidth: 345
            }}
          >
            <p
              style={{
                fontSize: 28,
                fontWeight: 700,
                color: BLACK,
                textAlign: isMobile() ? 'left' : 'center',
                paddingLeft: 10
              }}
            >
              {title}
            </p>
            <p
              style={{
                fontSize: 17,
                fontWeight: 400,
                color: BLACK,
                paddingLeft: 10,
                zIndex: 3,
                textAlign: isMobile() ? 'left' : 'center'
              }}
            >
              {description}
            </p>
            <img
              alt="snow"
              src={require('../../../../img/icons/snow.png')}
              style={{
                position: 'absolute',
                right: 0,
                bottom: 120,
                height: '40%',
                width: '100%',
                opacity: 0.7
              }}
            />
          </div>
        </div>
        <InstallButton
          onClick={() => {
            window.location.href = 'https://lingapp.page.link/oyik';
          }}
          style={{
            marginBottom: 30,
            fontWeight: 'bold',
            margin: 'auto'
          }}
        >
          {buttonName}
        </InstallButton>
      </ModalBody>
    </Modal>
  );
};

export { WelcomeLingModal };
