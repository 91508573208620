/* @flow */
export const INIT_FAQ: string = 'INIT_FAQ';
export const INIT_APP: string = 'INIT_APP';
export const SET_QUEUE: string = 'SET_QUEUE';
export const FINISH_TOOLTIP_QUEUE: string = 'FINISH_TOOLTIP_QUEUE';

export const SHOW_NETWORK_ERROR_DIALOG: string = 'SHOW_NETWORK_ERROR_DIALOG';
export const HIDE_NETWORK_ERROR_DIALOG: string = 'HIDE_NETWORK_ERROR_DIALOG';

export const TOGGLE_SPINNER: string = 'TOGGLE_SPINNER';
export const TOGGLE_CUSTOM_DIALOG: string = 'TOGGLE_CUSTOM_DIALOG';
export const SET_VOICE_SPEED: string = 'SET_VOICE_SPEED';
export const SET_SHOW_TUTORIAL: string = 'SET_SHOW_TUTORIAL';
export const SET_DAILY_GOAL: string = 'SET_DAILY_GOAL';
export const SET_LOADING: string = 'SET_LOADING';
export const SET_LOADING_PARAM: string = 'SET_LOADING_PARAM';
export const SET_CHATBOT_LOADED: string = 'SET_CHATBOT_LOADED';
export const SET_BUSINESS: string = 'SET_BUSINESS';
export const SET_IS_LANG_SYMBOL: string = 'SET_IS_LANG_SYMBOL';
export const TOGGLE_LOGIN_MODAL: string = 'TOGGLE_LOGIN_MODAL';
export const SET_IS_BUSINESS_SIGN_UP: string = 'SET_IS_BUSINESS_SIGN_UP';
export const SET_SIGN_UP_BUSINESS_SUCCESS: string =
  'SET_SIGN_UP_BUSINESS_SUCCESS';
export const SET_TRIAL_USED_IP: string = 'SET_TRIAL_USED_IP';
export const SET_REFERRER: string = 'SET_REFERRER';

export const TOGGLE_TUTORIAL_MOTIVATE: string = 'TOGGLE_TUTORIAL_MOTIVATE';
export const CLOSE_TUTORIAL_MOTIVATE: string = 'CLOSE_TUTORIAL_MOTIVATE';

export const TOGGLE_SOUND_EFFECT: string = 'TOGGLE_SOUND_EFFECT';
export const TOGGLE_REMINDER: string = 'TOGGLE_REMINDER';

export const TRIGGER_SIGN_OUT: string = 'TRIGGER_SIGN_OUT';
export const LOAD_LANGUAGE_DATA: string = 'LOAD_LANGUAGE_DATA';
export const CLEAR_USER: string = 'CLEAR_USER';
export const LOAD_LANG_CODES_INDEX: string = 'LOAD_LANG_CODES_INDEX';
export const LOAD_UNITS_DATA: string = 'LOAD_UNITS_DATA';
export const FETCH_UNITS_DATA: string = 'FETCH_UNITS_DATA';
export const FETCH_UNITS_DATA_RANDOM_TEST: string =
  'FETCH_UNITS_DATA_RANDOM_TEST';
export const SAVE_UNITS_DATA: string = 'SAVE_UNITS_DATA';
export const SET_LANGUAGE: string = 'SET_LANGUAGE';
export const TRIGGER_LOAD_LANGUAGE_DATA: string = 'TRIGGER_LOAD_LANGUAGE_DATA';
export const TRIGGER_LOAD_LANGUAGE_LABEL: string =
  'TRIGGER_LOAD_LANGUAGE_LABEL';
export const COURSE_MIGRATION: string = 'COURSE_MIGRATION';
export const SAVE_LANGUAGE_DATA: string = 'SAVE_LANGUAGE_DATA';
export const SAVE_UNIT_CACHE: string = 'SAVE_UNIT_CACHE';
export const USER_SAVE_DATA: string = 'USER_SAVE_DATA';
export const USER_IS_GUEST: string = 'USER_IS_GUEST';
export const USER_IS_NOT_GUEST: string = 'USER_IS_NOT_GUEST';
export const USER_GAME_SUCCESS: string = 'USER_GAME_SUCCESS';
export const USER_LESSON_SUCCESS: string = 'USER_LESSON_SUCCESS';
export const FETCH_TODOS: string = 'FETCH_TODOS';
export const DEFAULT_TARGET_LANGUAGE_CODE: string = 'th';

export const LOAD_STOP_WORDS: string = 'LOAD_STOP_WORDS';
export const USER_DEFAULT_MAX_ID: number = 6;
export const UPLOAD_FILE_SUCCESS: string = 'success';
export const couponAPI = 'usedCoupons';
export const NOLANG = 'NoLanguage';
export const SET_NO_ACCESS_UNITS = 'SET_NO_ACCESS_UNITS';
export const SET_NO_ACCESS_CHATBOT_UNITS = 'SET_NO_ACCESS_CHATBOT_UNITS';
export const UPDATE_SUBSCRIPTIONS = 'UPDATE_SUBSCRIPTIONS';
