/* @flow */
export const PROFILE: string = 'profile';
export const ACHIEVEMENT_LIST: string = 'achievementList';
export const LEADER: string = 'leader';
export const CHOOSE_COURSE: string = 'chooseCourse';
export const SETTING: string = 'setting';
export const LESSONS_LIST: string = 'lessonsList';
export const OPENSETTING: string = 'openSetting';
export const SHOP: string = 'shop';
export const PURCHASE: string = 'PURCHASE';
export const PURCHASE_SCREEN: string = 'purchase';
export const CHATBOTS_LIST: string = 'chatbotslist';
export const LANDING: string = 'landing';
export const REVIEW: string = 'review';
export const SUBSCRIBE: string = 'SUBSCRIBE';
export const BASE_LINK = document.location.origin;
export const FIRST_TIME: string = 'first_time';
export const SHOW_TUTORIAL: string = 'show_tutorial';
export const SHOW_REMINDER: string = 'show_reminder';
export const IS_BANANAS: boolean = true;
export const TRUE: string = 'true';
export const FALSE: string = 'false';
