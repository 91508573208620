import { createSelector } from 'reselect';
import {
  getOnboardingState,
  selectCampaignSelections
} from '../onboarding/selectors';

export const getStorageState = state => state.storage;

export const getDataState = state => state.data;

export const getTrackerState = state => state.tracker;

export const selectUserState = createSelector(
  getStorageState,
  storage => storage.user
);

export const selectLanguageData = createSelector(selectUserState, user => [
  user.targetLanguageCode,
  user.nativeLanguageCode
]);

export const selectEventData = createSelector(
  selectUserState,
  getOnboardingState,
  selectCampaignSelections,
  (user, onboarding, selections) => ({
    userId: user.id,
    screen: 'campaign',
    campaignId: onboarding.campaignId,
    selections
  })
);

export const selectTargetLangCodes = createSelector(
  getDataState,
  getStorageState,
  (data, storage) => ({
    targetLanguages: storage.langs.filter(l => l.isNative).sortBy('lang') || '',
    nativeLanguages: data.targetLanguageCode.map(l => ({ langCode: l })) || ''
  })
);

export const selectLanguages = createSelector(
  getStorageState,
  storage => storage.langs
);
