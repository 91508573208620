/* @flow */
export const RESET_ERROR: string = 'RESET_ERROR';
export const FORM_REGISTER_ERROR_UPDATE: string = 'FORM_REGISTER_ERROR_UPDATE';
export const TOGGLE_ALREADY_LINKED_DIALOG: string =
  'TOGGLE_ALREADY_LINKED_DIALOG';
export const LOGIN_TO_FACEBOOK: string = 'LOGIN_TO_FACEBOOK';
export const LOGIN_TO_GOOGLE: string = 'LOGIN_TO_GOOGLE';
export const REGISTER_BY_EMAIL: string = 'REGISTER_BY_EMAIL';
export const LOGIN_BY_EMAIL: string = 'LOGIN_BY_EMAIL';
export const LINK_GOOGLE: string = 'LINK_GOOGLE';
export const LINK_FACEBOOK: string = 'LINK_FACEBOOK';
export const RESET_PASSWORD: string = 'RESET_PASSWORD';
export const TOOGLE_PASSWORD_RESET_SEND_MODAL =
  'TOOGLE_PASSWORD_RESET_SEND_MODAL';
export const USER_SIGNOUT: string = 'USER_SIGNOUT';
export const USER_SKIP_LOGIN: string = 'USER_SKIP_LOGIN';
export const SET_LANGCODE: string = 'SET_LANGCODE';
// leaderboard
export const LEADERBOARD_FETCH: string = 'LEADERBOARD_FETCH';
