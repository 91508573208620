import React, { useEffect } from 'react';
import NewSelectorCell from '../../lessons/selectors/sub-components/NewSelectorCell';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import OnboardingButton from './subComponents/OnboardingButton';
import { useMediaQuery } from '@mui/material';

export const NewSurveySelector = ({
  headerData,
  screenText,
  selected,
  setSelected,
  handleScreenChange,
  activeScreen,
  labels
}) => {
  useEffect(() => {
    setSelected(null);
    // eslint-disable-next-line
  }, [activeScreen]);

  const { txt_tell_us_about_yourself, btn_continue } = labels;

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const isMultiSelect = screenText[0].selectType === 'multi';

  const matchSelections = selected => {
    return selected.map(selection => {
      const match = screenText.find(text => text.id === selection);
      return match.analyticValue;
    });
  };

  const toggleMultiSelectItems = id => {
    if (selected && selected.includes(id)) {
      setSelected(prev => prev.filter(i => i !== id));
    } else {
      selected ? setSelected(prev => [...prev, id]) : setSelected([id]);
    }
  };

  const isSelectionEmpty =
    isMultiSelect && selected ? selected.length === 0 : !selected;

  const handleSelection = id => {
    isMultiSelect ? toggleMultiSelectItems(id) : setSelected(id);
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      mt: 4
    },
    topContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      textAlign: 'center',
      my: 10
    },
    bottomContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      width: '50%',
      minWidth: '345px',
      maxWidth: '558px',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        width: '100%'
      }
    },
    header: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      mb: 8
    },
    questionText: {
      mr: 4,
      maxWidth: 500
    },
    headerText: {
      fontWeight: '700',
      fontSize: '16px',
      color: '#111111',
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px'
      }
    },
    headerIcon: { height: '65px', mb: 4 },
    survey: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexBasis: 80,
      overflow: 'none'
    },
    text: {
      textAlign: 'left',
      textWrap: 'wrap',
      fontSize: 30,
      [theme.breakpoints.up('sm')]: {
        fontSize: 36
      }
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '85%',
      mb: 10,
      [theme.breakpoints.up('sm')]: {
        width: '100%'
      }
    },
    onboardingButton: {
      width: '100%',
      maxWidth: '520px',
      backgroundColor: theme.palette.newPrimary.main,
      borderRadius: 9,
      textTransform: 'none',
      color: '#FFFFFF',
      fontSize: '18px',
      fontWeight: 700,
      my: 8,
      [theme.breakpoints.up('sm')]: {
        mt: 14
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.newPrimary.main,
        opacity: 0.5,
        color: '#FFFFFF'
      }
    }
  };
  return (
    <Box sx={styles.container}>
      <Box sx={styles.topContainer}>
        <Box component="img" src={headerData.icon} sx={styles.headerIcon} />
        <Typography variant="subtext" sx={styles.headerText}>
          {txt_tell_us_about_yourself}
        </Typography>
      </Box>
      <Box sx={styles.bottomContainer}>
        <Box sx={styles.header}>
          <Box sx={[styles.questionText, headerData.customStyle]}>
            <Typography variant="text" sx={styles.text}>
              {headerData.text}
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.survey}>
          {(screenText || []).map(
            ({ id, text, subtext, selectionImage, customStyle }) => (
              <NewSelectorCell
                active={
                  isMultiSelect
                    ? selected &&
                      Array.isArray(selected) &&
                      selected.includes(id)
                    : selected === id
                }
                key={id}
                text={text}
                subtext={subtext}
                image={selectionImage}
                customStyle={customStyle}
                clickAction={() => handleSelection(id)}
                isLargeScreen={isLargeScreen}
              />
            )
          )}
        </Box>
      </Box>
      <Box sx={styles.buttonContainer}>
        <OnboardingButton
          disabled={isSelectionEmpty}
          size="large"
          clickAction={() => {
            handleScreenChange(
              isMultiSelect ? matchSelections(selected) : selected
            );
          }}
          buttonText={btn_continue}
          style={styles.onboardingButton}
        />
      </Box>
    </Box>
  );
};

export default NewSurveySelector;
