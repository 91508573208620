import { connect } from 'react-redux';
import SettingScene from '../scenes/SettingScene';
import { getLabels, getLabel } from '../../util';
import {
  getName,
  signOut,
  saveName,
  saveProfilePic
} from '../../common/actions/AuthActions';
import {
  SET_VOICE_SPEED,
  TOGGLE_SOUND_EFFECT,
  WEEK_DAYS_DATA
} from '../../common/constants';
import { getNameAndEmail } from '../actions/UserSelector';
import {
  updateUserProfile,
  setReminders,
  setClientToken,
  toggleLoadingParam,
  setDailyGoal
} from '../../common/actions/GlobalActions';

import { getUser } from '../selectors';
import _ from 'lodash';
export const mapStateToProps = (state: Object) => {
  const { storage, data } = state;
  const { user, isLoading } = storage;
  const { voiceSpeed, isEffectEnabled, reminders } = user;
  const { nativeLangCode } = data;

  const currentVoiceSpeed = voiceSpeed || 1;
  const currentEffectEnabled = isEffectEnabled;

  const currentVoiceSpeedText = getLabel(
    state,
    `setting_voice_speed_${currentVoiceSpeed}_txt`,
    `setting_voice_speed_${currentVoiceSpeed}_txt`
  );
  const reminder = _.get(reminders, 'clientToken', false);
  const checkReminder = reminders
    ? { ...reminders }
    : {
        isSetReminder: false,
        timeReminder: '09:30',
        weekDays: WEEK_DAYS_DATA
      };
  const functions = process.env.REACT_APP_FUNCTIONS;
  return {
    name: getName(user),
    isLoading,
    functions,
    reminders,
    nativeLangCode,
    isReminder: reminder,
    newReminder: checkReminder,
    btn_save: getLabel(state, 'settings_btn_save', 'Save Change'),
    redeem_txt_desc: getLabel(state, 'redeem_txt_desc', 'Redeem your gift'),
    redeem_btn_redeem: getLabel(state, 'redeem_btn_redeem', 'Redeem'),
    redeem_txt_input: getLabel(state, 'redeem_txt_input', 'Input your coupon'),
    profile_edit_profile_txt: getLabel(
      state,
      'profile_edit_profile_txt',
      'edit profile'
    ),
    redeem_txt_invalid: getLabel(
      state,
      'redeem_txt_invalid',
      'This coupon is invalid or already redeemed.'
    ),
    modal_txt_redeem: getLabel(
      state,
      'modal_txt_redeem',
      "You've successfully redeemed this coupon. Enjoy Ling Premium Version."
    ),
    modal_btn_redeem_go_home: getLabel(
      state,
      'modal_btn_redeem_go_home',
      'Go home'
    ),
    other_txt: getLabel(state, 'settings_txt_other', 'Other'),
    footer_txt_profile: getLabel(
      state,
      'footer_txt_profile',
      'footer_txt_profile'
    ),
    edit_profile_save_txt: getLabel(state, 'edit_profile_save_txt', 'Save'),
    loading_txt_cancel: getLabel(state, 'loading_txt_cancel', 'Cancel'),
    edit_profile_photo_txt: getLabel(state, 'edit_profile_photo_txt', 'Photo'),
    edit_profile_edit_txt: getLabel(state, 'edit_profile_edit_txt', 'Edit'),
    login_field_name: getLabel(state, 'login_field_name', 'Name'),
    setting_txt_day_select: getLabel(
      state,
      'setting_txt_day_select',
      'What days whould you like to be reminded to learn?'
    ),
    setting_txt_time_select: getLabel(
      state,
      'setting_txt_time_select',
      'What time of the day?'
    ),
    setting_txt_reminder_title: getLabel(
      state,
      'setting_txt_reminder_title',
      'Reminder'
    ),
    setting_txt_version: getLabel(state, 'setting_txt_version', 'Version'),

    modal_txt_notification_permission_title: getLabel(
      state,
      'modal_txt_notification_permission_title',
      'Daily reminder'
    ),
    userInfo: getNameAndEmail(storage),
    currentEffectEnabled,
    currentVoiceSpeed,
    currentVoiceSpeedText,
    ...getUser(state),
    ...getLabels(
      state,
      'my_daily_goal_txt',
      'setting_txt_settingHeader',
      'setting_txt_contactUs',
      'report_text_sendfeedback',
      'setting_txt_aboutUs',
      'createAccount_txt_title',
      'setting_txt_otherApp',
      'btn_continue',
      'loginPurchase_txt',
      'logout_txt',
      'setting_txt_effect',
      'setting_txt_voice_speed',
      'setting_voice_speed_1_txt',
      'setting_voice_speed_2_txt',
      'setting_voice_speed_3_txt',
      'setting_voice_speed_4_txt',
      'setting_voice_speed_5_txt'
    )
  };
};

export const mapDispatchToProps = (dispatch: Function) => {
  return {
    signOut: () => {
      dispatch(signOut());
    },
    setDailyGoal: (goal: number) => {
      dispatch(setDailyGoal(goal));
    },
    saveChange: (speed: number, isEffect: boolean, reminder: Object) => {
      dispatch(toggleLoadingParam(false));
      dispatch({ type: SET_VOICE_SPEED, speed });
      dispatch({ type: TOGGLE_SOUND_EFFECT, isEffect });
      dispatch(setReminders(reminder));
    },
    updateUserProfile: (newUser: Object) => {
      dispatch(updateUserProfile(newUser));
    },
    setReminders: (reminderData: Object) => {
      dispatch(setReminders(reminderData));
    },
    setClientToken: (firebaseToken: string) => {
      dispatch(setClientToken(firebaseToken));
    },
    saveName: (user: Object, newName: string) => saveName(user, newName),
    saveProfilePic: (user: Object, url: Object, selectedFile: Object) =>
      saveProfilePic(user, url, selectedFile)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingScene);
