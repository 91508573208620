/* @flow */
import _ from 'lodash';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import PreHeader from './PreHeader';
import { FLASHCARD, GRAMMAR, DIALOGUE } from '../../../games/constants';
import { FooterReview } from './FooterReview';
import { FlashCardReview } from './FlashCardReview';
import { GrammarReview } from './GrammarReview';
import {
  REVIEW_CONTENT_TOP_MAGGIN,
  PREFIX_LESSON
} from '../../../common/constants';
import { DialogReview } from './DialogReview';

type Props = {
  title: string,
  screen: string,
  history: Object,
  dataCollection: any,
  transcriptState: boolean,
  _loadDialogue: Function,
  playDialogSound: Function,
  headerDialog: Object[],
  unmountDialog: Function,
  dialogSaveScrollFunc: Function,
  pauseDialog: Function,
  transcriptState: boolean,
  random_test_modal_confirm_txt: string,
  translateState: boolean,
  onTranslate: Function,
  dialog_btn_translate: string,
  hasTranscript: boolean,
  playing: boolean,
  onReplay: Function,
  onStopSound: Function,
  writing_txt_replay: string,
  dialog_btn_stop: string,
  triggerDialogAutoPlay: Function,
  grammar_help_txt: string,
  btn_send: string,
  voiceSpeed: number
};

type State = {
  currentUnit: number,
  currentIndex: number,
  height: number
};

const Display = styled.div`
  min-width: 100vw;
  height: ${props => props.height}px;
  overflow-y: hidden;
`;

const DisplayContainer = styled.div`
  display: flex;
  overflow-x: hidden;
  height: ${props => (props.screen !== DIALOGUE ? '100vh' : 'auto')};
  margin-bottom: ${props =>
    props.screen === DIALOGUE
      ? '10px'
      : props.screen === GRAMMAR
      ? '110px'
      : 'auto'};
`;

const translatePic = `${PREFIX_LESSON}basecolor-black-translate.svg`;

class LessonReview extends React.Component<Props, State> {
  refs: Object;
  constructor(props: Props) {
    super(props);
    this.state = {
      currentUnit: 0,
      currentIndex: 0,
      height: 0
    };
  }
  componentDidMount() {
    if (this.props.screen === GRAMMAR) {
      const height =
        // eslint-disable-next-line react/no-string-refs
        this.refs[this.state.currentIndex].children[0].clientHeight +
        REVIEW_CONTENT_TOP_MAGGIN;
      this.setState({ height });
    } else if (this.props.screen === DIALOGUE) {
      this.props._loadDialogue(this.props.dataCollection[0]);
      const height =
        // eslint-disable-next-line react/no-string-refs
        this.refs[this.state.currentIndex].children[1].clientHeight + 260;
      this.setState({ height });
    }
  }

  renderItem = (dataCollection: any) => {
    const { transcriptState, voiceSpeed } = this.props;
    return dataCollection.map((item, index) => (
      <Display key={index} ref={index}>
        <FlashCardReview
          voiceSpeed={voiceSpeed}
          item={item}
          transcriptState={transcriptState}
        />
      </Display>
    ));
  };

  renderGrammar = (dataCollection: any) => {
    const {
      grammar_help_txt,
      btn_send,
      random_test_modal_confirm_txt
    } = this.props;
    return dataCollection.map((item, index) => (
      <Display key={index} ref={index} height={this.state.height}>
        <GrammarReview
          random_test_modal_confirm_txt={random_test_modal_confirm_txt}
          item={item}
          grammar_help_txt={grammar_help_txt}
          btn_send={btn_send}
        />
      </Display>
    ));
  };

  renderDialog = (dataCollection: any) => {
    const {
      playDialogSound,
      headerDialog,
      unmountDialog,
      dialogSaveScrollFunc,
      transcriptState,
      translateState,
      triggerDialogAutoPlay
    } = this.props;
    while (dataCollection.length > headerDialog.length) {
      dataCollection.splice(-1, 1);
    }

    return dataCollection.map((item, index) => (
      <Display key={index} ref={index}>
        <DialogReview
          triggerDialogAutoPlay={triggerDialogAutoPlay}
          items={item}
          headerDialog={headerDialog[index]}
          unmountDialog={unmountDialog}
          dialogSaveScrollFunc={dialogSaveScrollFunc}
          onPress={playDialogSound}
          transcriptState={transcriptState}
          translateState={translateState}
        />
      </Display>
    ));
  };

  nextUnit = () => {
    const { currentIndex } = this.state;
    const index =
      currentIndex + 1 > _.size(this.props.dataCollection) - 1
        ? 0
        : currentIndex + 1;
    const behavior =
      currentIndex + 1 > _.size(this.props.dataCollection) - 1
        ? 'instant'
        : 'smooth';
    this.onIndexChange(index, behavior);
  };
  prevUnit = () => {
    const { currentIndex } = this.state;
    const index =
      currentIndex - 1 < 0
        ? _.size(this.props.dataCollection) - 1
        : currentIndex - 1;
    const behavior = currentIndex - 1 < 0 ? 'instant' : 'smooth';
    this.onIndexChange(index, behavior);
  };

  onIndexChange = (index: number, behavior: string) => {
    const { screen, onStopSound, _loadDialogue, dataCollection } = this.props;
    // eslint-disable-next-line react/no-string-refs
    this.refs[index].scrollIntoView({
      block: 'start',
      behavior: behavior
    });
    if (screen === GRAMMAR) {
      const height =
        // eslint-disable-next-line react/no-string-refs
        this.refs[index].children[0].clientHeight + REVIEW_CONTENT_TOP_MAGGIN;
      this.setState({ currentIndex: index, height });
    } else if (screen === DIALOGUE) {
      onStopSound();

      _loadDialogue(dataCollection[index]);
      const height =
        // eslint-disable-next-line react/no-string-refs
        this.refs[index].children[1].clientHeight + 260;
      this.setState({ currentIndex: index, height });
    } else {
      this.setState({ currentIndex: index });
    }
  };

  checkScreen = (screen: string) => {
    switch (screen) {
      case FLASHCARD:
        return this.renderItem(this.props.dataCollection);
      case GRAMMAR:
        return this.renderGrammar(this.props.dataCollection);
      case DIALOGUE:
        return this.renderDialog(this.props.dataCollection);
      default:
        return this.renderItem(this.props.dataCollection);
    }
  };
  render() {
    const {
      title,
      history,
      screen,
      headerDialog,
      dialog_btn_translate,
      translateState,
      hasTranscript,
      onTranslate,
      writing_txt_replay,
      dialog_btn_stop,
      playing,
      onReplay,
      onStopSound,
      pauseDialog
    } = this.props;
    const isReview = true;
    const sizeData =
      screen === GRAMMAR
        ? _.size(this.props.dataCollection)
        : screen === DIALOGUE
        ? _.size(headerDialog)
        : _.size(this.props.dataCollection);
    const { currentIndex } = this.state;

    return (
      <Fragment>
        <PreHeader
          title={title}
          history={history}
          isReview={isReview}
          hasTranscript={hasTranscript}
          screen={screen}
        />
        <DisplayContainer screen={screen}>
          {this.checkScreen(screen)}
        </DisplayContainer>
        <FooterReview
          screen={screen}
          size={sizeData}
          index={currentIndex + 1}
          nextUnit={this.nextUnit}
          prevUnit={this.prevUnit}
          onTranslate={onTranslate}
          translatePic={translatePic}
          dialog_btn_translate={dialog_btn_translate}
          translateState={translateState}
          dialog_btn_stop={dialog_btn_stop}
          writing_txt_replay={writing_txt_replay}
          playing={playing}
          onReplay={onReplay}
          onStopSound={onStopSound}
          pauseDialog={pauseDialog}
          isReview={isReview}
        />
      </Fragment>
    );
  }
}

export { LessonReview };
