/* @flow */
import { store } from '../';
import { Howl } from 'howler';
import { SOUND_PATH, SOUND_ASSET_PATH } from '../common/constants';
import { DEFAULT_VOICE_SPEED_LEVEL } from '../profile/constants';

let CURRENT_SOUND;

export const playSound = (
  soundFile: string,
  speed: number,
  path?: string,
  volume?: number = 1,
  afterCallback?: Function
) => {
  if (!soundFile) {
    return;
  }
  const { data } = store.getState();
  const { targetLangCode } = data;
  const targetPath = path || `${SOUND_PATH}/${targetLangCode}/`;

  const sound = new Howl({
    src: [`${targetPath}${soundFile}`],
    html5: true,
    volume,
    onend: function() {
      if (afterCallback) {
        afterCallback();
      }
    }
  });
  if (CURRENT_SOUND) {
    CURRENT_SOUND.stop();
  }
  CURRENT_SOUND = sound;
  sound.play();
  sound.rate(speed);
};

export const playSoundWithCallback = async (
  soundFile: string,
  speed: number,
  path?: string,
  callback: Function,
  beforeCallback?: Function,
  afterCallback?: Function,
  isChatbot?: boolean = false,
  id?: string = ''
) => {
  const { data } = store.getState();
  const { targetLangCode } = data;
  const targetPath = path || `${SOUND_PATH}/${targetLangCode}/`;

  const sound = new Howl({
    src: [`${targetPath}/${soundFile}`],
    html5: true,
    onend: function() {
      if (callback) {
        callback(sound);
      }
      if (afterCallback) {
        afterCallback(sound);
        console.log('sound', sound);
      }
    }
  });

  if (beforeCallback) {
    beforeCallback(sound);
  }
  if (CURRENT_SOUND) {
    CURRENT_SOUND.stop();
  }
  CURRENT_SOUND = sound;
  sound.play();
  sound.rate(speed);
};

export const gameSound = (typeSound: string) => {
  let soundFile;
  let volume = 1;
  switch (typeSound) {
    case 'correct':
      soundFile = 'correct.mp3';
      break;
    case 'incorrect':
      soundFile = 'wrong.mp3';
      break;
    case 'finish':
      soundFile = 'finished.mp3';
      volume = 0.5;
      break;
    case 'hint':
      soundFile = 'hint.mp3';
      break;
    case 'fail':
      soundFile = 'fail.mp3';
      volume = 0.5;
      break;
    default:
      soundFile = 'correct.mp3';
      break;
  }
  playSound(soundFile, DEFAULT_VOICE_SPEED_LEVEL, SOUND_ASSET_PATH, volume);
};

export const onPlaySound = (soundFile: string, speed: number) => () =>
  playSound(soundFile, speed || DEFAULT_VOICE_SPEED_LEVEL);

export const stopSound = () => {
  try {
    if (CURRENT_SOUND) {
      CURRENT_SOUND.stop();
    }
  } catch (e) {
    console.log(e);
  }
};
