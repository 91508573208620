/* eslint-disable max-lines */
/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { firebase } from '../../../system/Firebase';
import {
  getData,
  loadUserDataHomeScreen,
  openWordsLearn,
  saveDailyGoal
} from '../../../util';
import {
  PREFIX_HEADER,
  SHOW_TUTORIAL,
  SHOW_REMINDER,
  TRUE,
  FALSE
} from '../../../common/constants';
import { isUserSubscribed } from '../../../profile/actions/UserSelector';
import { getUser } from '../../../profile/actions/UserSelector';
import {
  getCurrentCourseData,
  isUnitLocked,
  isCompleteTwoUnits
} from '../../../lessons/selectors';
import { getLastUserUnitId } from '../../../profile/selectors';
import { getConfigState } from '../../../config/selectors';

import { getUnitImageUrl } from '../../../util/AssetHelper';
import { calIndexLevel } from '../../../util/FluxRouter';
import { getLabel, getLabels } from '../../../util/LabelHelper';
import { isCrackedUnit } from '../../../games/actions';
import {
  getCurrentUserGoalData,
  getCurrentWeekDayMondayBased,
  getUnitProgressLingTwoUser
} from '../../../util';
import { ContainerStyle, HeaderContainer } from './HomeScene.styles';
import BugTracker from '../../../util/BugTracker';
import DefaultHeaderContainer from '../../../common/containers/DefaultHeaderContainer';
import { store } from '../../../index';
import { RandomReview } from '../../sub-components/RandomReview';
import { DailyReminderPopUp } from '../../sub-components/DailyReminderPopUp';
import { RandomReviewWarningModal } from '../../sub-components/RandomReviewWarningModal';
import { HomeSceneContent } from '../../sub-components/HomeSceneContent';
import { fetchRemoteDatabase } from '../../../lessons/sagas';
import { setReferralCode } from '../../../common/actions/DynamicLinkAction';
import {
  getHandledVoiceSpeed,
  signInWithCustomToken
} from '../../../common/actions/AuthActions';

import * as LessonActions from '../../../lessons/actions';
import * as CommonActions from '../../../common/actions';
import * as ProfileActions from '../../../profile/actions';
import * as GameActions from '../../../games/actions';
import * as ShopActions from '../../../shop/actions';
import { bindActionCreators } from 'redux';
import {
  selectCampaign,
  selectCampaignStatus,
  selectCompletedCampaigns,
  selectRedirectStatus
} from '../../../onboarding/selectors';
import OnboardingRoot from '../../../onboarding/components/OnboardingRoot';
import { getCookie } from '../../../util/CookieHelper';

type CustomRefs = {
  customRefs?: {}
};

type HomeSceneProps = {
  common: {
    addLoginListener: Function,
    createNewDatabaseUser: Function,
    fetchUnitData: Function,
    fetchRandomTestData: Function,
    saveUserData: Function,
    setBusiness: Function,
    setClientToken: Function,
    setDailyGoal: Function,
    setReferrer: Function,
    setIsBusinessSignUp: Function,
    setIsLangSymbol: Function,
    setLanguageData: Function,
    setReferrerCode: Function, // DynamicLink
    setReminders: Function,
    setShowTutorial: Function,
    toggleLoginModal: Function,
    toggleLoadingParam: Function,
    triggerLoadInitialTutorial: Function,
    triggerLoadLanguageData: Function,
    updateUserData: Function,
    updateUserProfile: Function
  },
  game: {
    // TODO: review to remove this since we connect directly now
    // isCrackedUnit: Function,
    // resetWeeklyGoal: Function
  },
  lesson: {
    setNativeLanguage: Function,
    setTargetLanguage: Function,
    triggerUpdateUnitsProgress: Function
  },
  state: any,
  shop: {
    disableRepairedUnitTooltips: Function
  },
  units: Object,
  currentCourse: Object,

  courses: Object,
  downloading: Object,
  lastSelectedUnit: number,

  triggerLoadUnitData: Function,
  history: Object,
  location: Object,
  unitContinue: any,
  unitLevel_txt_basic: string,
  unitLevel_txt_beginner: string,
  unitLevel_txt_intermediate: string,
  unitLevel_txt_afterintermediate: string,
  unitLevel_txt_advance: string,
  unitLevel_txt_unit: string,
  user: Object,

  isUnitLocked: Function,

  wordsLearned: number,
  random_test_review_btn: string,
  random_test_review_btn: string,
  random_test_modal_confirm_txt: string,
  random_test_modal_txt: string,
  isCompleteTwoUnits: boolean,
  startRandomTest: Function,

  isShowTutorial: boolean,
  isLoginModalOpen: boolean,
  match: Object,
  targetLangCode: string,
  nativeLangCode: string,

  createAccount_txt_title: string,
  loginPurchase_txt: string,
  isLoading: boolean,
  isReminder: any,
  promo_txt_wordsLearned: string,
  newReminder: Object,
  isBusiness: boolean,
  edit_profile_save_txt: string,
  setting_txt_reminder_title: string,
  setting_txt_day_select: string,
  setting_txt_time_select: string,
  spaced_repetition_open_txt: string,
  firstCrackedUnitId: number,
  questions: Array<string>,
  isShowRepairedUnitTooltipsAlready: boolean,
  goal: Object,
  dailyBananas: number,
  noAccessUnits: Array<number>,
  isProUser: boolean,
  signInWithCustomToken: Function
};

type HomeSceneState = {
  isFinishTwoUnit: boolean,
  showTutorial: boolean,
  isFinishTutorial: boolean,
  isRemiderOpen: boolean,
  isShowPopover: boolean
};

const random_test_img = `${PREFIX_HEADER}masterLing250Px.png`;

class HomeScene extends React.Component<HomeSceneProps, HomeSceneState> {
  customRefs: CustomRefs;
  child: any;
  constructor(props) {
    super(props);
    this.customRefs = {};
    this.state = {
      isFinishTwoUnit: true,
      showTutorial: false,
      isFinishTutorial: false,
      isRemiderOpen: false,
      collection: [],
      isShowPopover: true
    };
  }

  componentDidMount(): void {
    const {
      common: {
        setClientToken,
        setIsLangSymbol,
        setShowTutorial,
        toggleLoginModal,
        triggerLoadLanguageData
      },
      courses,
      location,
      isReminder,
      firstCrackedUnitId,
      currentCourse,
      history,
      match,
      questions
    } = this.props;
    const receipt = _.get(this.props, 'match.params.receipt', false);
    const productName = _.get(this.props, 'match.params.type', false);
    let auth = firebase.auth();
    loadUserDataHomeScreen(
      history,
      currentCourse,
      courses,
      isReminder,
      location,
      match,
      questions,
      setShowTutorial,
      triggerLoadLanguageData,
      setIsLangSymbol,
      toggleLoginModal,
      setClientToken,
      this.stateChangeHandler
    );
    auth.onAuthStateChanged(user => {
      if (!user) {
        auth
          .signInAnonymously()
          .then(result => {
            const { user } = result;

            this.fetchUser(user);
          })
          .catch(error => {
            // Handle Errors here.
            var errorCode = error.code;

            if (errorCode === 'auth/operation-not-allowed') {
              alert('You must enable Anonymous auth in the Firebase Console.');
            }
            BugTracker.notify(error);
          });
      } else {
        if (process.env.REACT_APP_DEV) {
          console.log('Create User Listener', user);
        }
        const { search } = location;
        const queryReferrer = search.match(/referrer=([^&]*)/);
        if (queryReferrer) {
          const referrer = queryReferrer[1];
          user.referrer = referrer;
        }
        this.props.common.addLoginListener(user);
        this.fetchUser(user, { receipt, productName });
      }
    });
    setReferralCode();
    firstCrackedUnitId &&
      this.setState(_prevState => ({
        isShowPopover: true
      }));
    // Note: we put it here because when redirect back directly to /thanks
    // on Firebase Hosting all data were reset, and cannot continue
    if (this.props.location.search.includes('checkoutType=paypal')) {
      this.props.history.replace(`/thanks${this.props.location.search}`);
    }

    if (new URLSearchParams(location.search).get('loginType') === 'token') {
      const authToken = getCookie('__ling_app_auth_token');
      if (authToken) {
        this.props.signInWithCustomToken(authToken);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      common: { triggerLoadInitialTutorial },
      lesson: { triggerUpdateUnitsProgress },
      units,
      currentCourse,
      downloading
    } = this.props;
    const { units: nextUnits } = nextProps;
    const { words } = currentCourse;
    if (_.isEmpty(units) && !_.isEmpty(nextUnits)) {
      triggerUpdateUnitsProgress();
    }
    if (_.isEmpty(words) && !downloading) {
      // eslint-disable-next-line
      if (localStorage.getItem(SHOW_TUTORIAL) === TRUE) {
        triggerLoadInitialTutorial();
      }
    }
  }
  stateChangeHandler = (name: string, value: any) => {
    this.setState({
      [name]: value
    });
  };

  fetchUser(user: Object, receipt: any = false) {
    fetchRemoteDatabase(`users/${user.uid}`).then(data => {
      const {
        common: {
          createNewDatabaseUser,
          saveUserData,
          setBusiness,
          setLanguageData,
          setReferrerCode,
          triggerLoadInitialTutorial,
          triggerLoadLanguageData,
          updateUserData
        },
        lesson: {
          setNativeLanguage,
          setTargetLanguage,
          triggerUpdateUnitsProgress
        },
        history,
        courses,
        units,
        downloading,
        lastSelectedUnit,
        location
      } = this.props;
      const value = data.val();
      if (value) {
        const voiceSpeed = _.get(value, 'voiceSpeed', 1);
        const currentVoiceSpeed = getHandledVoiceSpeed(voiceSpeed);
        saveUserData({ ...value, voiceSpeed: currentVoiceSpeed });
      } else {
        createNewDatabaseUser(user, 'anonymous');
      }

      if (receipt) {
        const { productName, receipt: ReceiptData } = receipt;
        const subscription = _.get(
          store.getState(),
          'storage.user.subscription',
          {}
        );
        if (productName) {
          const receiptInput = {
            subscription: {
              ...subscription,
              activeSubscription: productName.split('_').join('.'),
              purchaseHistory: {
                ..._.get(subscription, 'purchaseHistory', {}),
                [productName]: {
                  productId: productName,
                  ReceiptData,
                  type: 'stripe'
                }
              }
            }
          };
          updateUserData(receiptInput);
        }
      }
      getData(
        history,
        triggerLoadLanguageData,
        courses,
        units,
        triggerUpdateUnitsProgress,
        downloading,
        lastSelectedUnit,
        setNativeLanguage,
        setTargetLanguage,
        setLanguageData,
        setBusiness,
        location,
        triggerLoadInitialTutorial,
        setReferrerCode,
        this.getCustomRef,
        this.scrollToUnit
      );
    });
  }

  getCustomRef = (refName: string) => {
    // $flow-disable-line
    return this['customRefs'][refName];
  };

  setCustomRef = (refName: string, ref: string) => {
    // $flow-disable-line
    this['customRefs'][refName] = ref;
  };

  scrollToUnit = (unitNumber: number) => {
    const position = _.get(
      this,
      `customRefs.unitRef${unitNumber}.current.offsetTop`,
      0
    );
    window.scrollTo(0, position);
  };

  openWordsLearn = () => {
    //TODO if user finished two unit
    const { isFinishTwoUnit } = this.state;
    if (this.props.isCompleteTwoUnits) {
      const {
        common: { fetchRandomTestData },
        history,
        nativeLangCode,
        targetLangCode
      } = this.props;
      fetchRandomTestData(targetLangCode, nativeLangCode, () => {
        history.push('/randomTest');
      });
    } else {
      this.stateChangeHandler('isFinishTwoUnit', !isFinishTwoUnit);
    }
  };

  toggleReminder = () => {
    this.setState({
      isRemiderOpen: !this.state.isRemiderOpen
    });
    // eslint-disable-next-line
    localStorage.setItem(SHOW_REMINDER, FALSE);
  };
  closeReminder = () => {
    const { setReminders } = this.props.common;
    const { nativeLangCode, newReminder } = this.props;
    const { weekDays } = newReminder;
    this.toggleReminder();
    setReminders({
      nativeCode: nativeLangCode,
      weekDays: weekDays,
      timeReminder: '9:30',
      isSetReminder: false
    });
  };

  toggleCreateAccountBusiness = (unitId: number) => {
    this.props.common.setIsBusinessSignUp();
    this.child.toggleCreateAccountBusiness(unitId);
  };

  render() {
    const {
      common: {
        fetchUnitData,
        fetchRandomTestData,
        setClientToken,
        setReminders,
        updateUserData
      },
      shop: { disableRepairedUnitTooltips },
      history,
      units,
      unitContinue,
      unitLevel_txt_basic,
      unitLevel_txt_beginner,
      unitLevel_txt_intermediate,
      unitLevel_txt_afterintermediate,
      unitLevel_txt_advance,
      unitLevel_txt_unit,
      currentCourse,
      user,
      location,
      isUnitLocked,
      wordsLearned,
      state,
      random_test_review_btn,
      random_test_modal_txt,
      random_test_modal_confirm_txt,
      isShowTutorial,
      targetLangCode,
      nativeLangCode,
      promo_txt_wordsLearned,
      isLoading,
      isBusiness,
      createAccount_txt_title,
      loginPurchase_txt,
      edit_profile_save_txt,
      setting_txt_reminder_title,
      setting_txt_day_select,
      setting_txt_time_select,
      firstCrackedUnitId,
      spaced_repetition_open_txt,
      isShowRepairedUnitTooltipsAlready,
      isCompleteTwoUnits,
      goal,
      dailyBananas,
      noAccessUnits
    } = this.props;

    const {
      showTutorial,
      isFinishTutorial,
      isRemiderOpen,
      isFinishTwoUnit
    } = this.state;
    const currentUser = firebase.auth().currentUser;
    const isAnonymous = _.get(currentUser, 'isAnonymous', true);
    const isWebFunnel =
      new URLSearchParams(window.location.search).get('utm_source') ===
      'LingAppWebsite'; // Returning users will see the flickering if has `utm_source`

    return state.onboarding.campaignId !== 0 &&
      !state.onboarding.shopRedirected &&
      isAnonymous &&
      isWebFunnel ? (
      <OnboardingRoot />
    ) : (
      <ContainerStyle
        isShowTutorial={isShowTutorial}
        showTutorial={showTutorial}
      >
        <HeaderContainer>
          <DefaultHeaderContainer
            isSrollable={true}
            history={history}
            location={location}
            isShowTutorial={isShowTutorial}
            showTutorial={showTutorial}
            onRef={ref => (this.child = ref)}
          />
          <RandomReview
            dailyBananas={dailyBananas}
            goal={goal}
            promo_txt_wordsLearned={promo_txt_wordsLearned}
            isLoading={isLoading}
            isShowTutorial={isShowTutorial}
            showTutorial={showTutorial}
            user={user}
            currentCourse={currentCourse}
            wordsLearned={wordsLearned}
            openWordsLearn={() =>
              openWordsLearn(
                isCompleteTwoUnits,
                isFinishTwoUnit,
                history,
                nativeLangCode,
                targetLangCode,
                fetchRandomTestData,
                this.stateChangeHandler
              )
            }
            random_test_review_btn={random_test_review_btn}
          />
        </HeaderContainer>
        <HomeSceneContent
          createAccount_txt_title={createAccount_txt_title}
          currentCourse={currentCourse}
          disableRepairedUnitTooltips={disableRepairedUnitTooltips}
          fetchUnitData={fetchUnitData}
          firstCrackedUnitId={firstCrackedUnitId}
          getCustomRef={this.getCustomRef}
          history={history}
          isBusiness={isBusiness}
          isFinishTutorial={isFinishTutorial}
          isLoading={isLoading}
          isShowRepairedUnitTooltipsAlready={isShowRepairedUnitTooltipsAlready}
          isShowTutorial={isShowTutorial}
          isUnitLocked={isUnitLocked}
          loginPurchase_txt={loginPurchase_txt}
          nativeLangCode={nativeLangCode}
          setCustomRef={this.setCustomRef}
          showTutorial={showTutorial}
          spaced_repetition_open_txt={spaced_repetition_open_txt}
          targetLangCode={targetLangCode}
          toggleCreateAccountBusiness={this.toggleCreateAccountBusiness}
          unitContinue={unitContinue}
          unitLevel_txt_advance={unitLevel_txt_advance}
          unitLevel_txt_afterintermediate={unitLevel_txt_afterintermediate}
          unitLevel_txt_basic={unitLevel_txt_basic}
          unitLevel_txt_beginner={unitLevel_txt_beginner}
          unitLevel_txt_intermediate={unitLevel_txt_intermediate}
          unitLevel_txt_unit={unitLevel_txt_unit}
          units={units}
          user={user}
          restrictedUnits={noAccessUnits}
          updateUserData={updateUserData}
        />
        <DailyReminderPopUp
          stateChangeHandler={this.stateChangeHandler}
          isShowTutorial={isShowTutorial}
          nativeLangCode={nativeLangCode}
          isRemiderOpen={isRemiderOpen}
          user={user}
          toggleReminder={this.toggleReminder}
          closeReminder={this.closeReminder}
          setReminders={setReminders}
          setClientToken={setClientToken}
          edit_profile_save_txt={edit_profile_save_txt}
          setting_txt_reminder_title={setting_txt_reminder_title}
          setting_txt_day_select={setting_txt_day_select}
          setting_txt_time_select={setting_txt_time_select}
        />
        <RandomReviewWarningModal
          toggle={() =>
            this.stateChangeHandler('isFinishTwoUnit', !isFinishTwoUnit)
          }
          isOpen={!isFinishTwoUnit}
          random_test_img={random_test_img}
          random_test_modal_txt={random_test_modal_txt}
          random_test_modal_confirm_txt={random_test_modal_confirm_txt}
        />
      </ContainerStyle>
    );
  }
}

export const mapStateToProps = (state: Object) => {
  const {
    data: {
      courses,
      fetchingUnitId: downloading,
      targetLangCode,
      nativeLangCode
    },
    units: { units: rawUnits },
    storage,
    dialog,
    shop,
    temporary
  } = state;
  const goal = getCurrentUserGoalData(state);
  const { isSetDailyGoal = null, userDailyGoal, weeklyGraphData } = goal;
  const todayWeekday = getCurrentWeekDayMondayBased();
  const arrayIndex = todayWeekday === 0 ? 6 : todayWeekday - 1;
  const dailyBananas = weeklyGraphData[arrayIndex];
  const { isShowRepairedUnitTooltipsAlready } = shop;
  const { isFetchProgressFinished } = temporary;
  const { questions } = dialog;
  const {
    user,
    isShowTutorial,
    isLoading,
    isLoginModalOpen,
    isBusiness,
    isBusinessSignUp,
    configs,
    noAccessUnits = []
  } = storage;
  const { reminders, dailyGoal } = user;
  const { isEnabledSpacedRepetition } = configs;
  const reminder = _.get(reminders, 'clientToken', false);
  let units = [];
  if (rawUnits) {
    units = _.mapValues(state.units.units, unit => {
      const { key, title, lastDayPlayed, repairedCount } = unit;
      const currentProgress = getUnitProgressLingTwoUser(
        user,
        targetLangCode,
        key,
        unit.progress
      );
      return {
        ...unit,
        image: getUnitImageUrl(key),
        title: getLabel(state, title),
        isCrackedUnit:
          isEnabledSpacedRepetition && isFetchProgressFinished
            ? isCrackedUnit(lastDayPlayed, repairedCount)
            : false,
        level: calIndexLevel(key),
        progress: currentProgress
      };
    });
  }

  const firstCrackedUnit = _.find(units, 'isCrackedUnit');
  const repaired = _.find(units, unit => unit.repairedCount > 0);
  let firstCrackedUnitId = 0;
  if (!_.isEmpty(firstCrackedUnit) && _.isEmpty(repaired)) {
    // $FlowFixMe
    firstCrackedUnitId = firstCrackedUnit.key;
  }
  const currentCourse = getCurrentCourseData(state);
  const lastSelectedUnit = _.get(state, 'lessons.unitId') || 1;
  return {
    dailyGoal,
    questions: questions,
    units,
    isBusiness,
    noAccessUnits,
    isBusinessSignUp,
    unitContinue: getLastUserUnitId(state) || 1,
    isReminder: reminder,
    newReminder: reminders,
    courses,
    currentCourse,
    downloading,
    targetLangCode,
    nativeLangCode,
    isLoading,
    lastSelectedUnit,
    isLoginModalOpen,
    isSetDailyGoal,
    userDailyGoal,
    dailyBananas,
    user,
    goal,
    isProUser: isUserSubscribed(state),
    isShowTutorial,
    edit_profile_save_txt: getLabel(state, 'edit_profile_save_txt', 'Save'),
    setting_txt_reminder_title: getLabel(
      state,
      'setting_txt_reminder_title',
      'Reminder'
    ),
    setting_txt_day_select: getLabel(
      state,
      'setting_txt_day_select',
      'What days whould you like to be reminded to learn?'
    ),
    setting_txt_time_select: getLabel(
      state,
      'setting_txt_time_select',
      'What time of the day?'
    ),
    ...getUser(state),
    ...getLabels(
      state,
      'my_daily_goal_txt',
      'unitLevel_txt_basic',
      'unitLevel_txt_beginner',
      'unitLevel_txt_intermediate',
      'unitLevel_txt_afterintermediate',
      'unitLevel_txt_advance',
      'unitLevel_txt_unit',
      'random_test_modal_txt'
    ),
    spaced_repetition_open_txt: getLabel(
      state,
      'spaced_repetition_open_txt',
      'Open this unit to review and repair the unit.'
    ),
    random_test_review_btn: getLabel(state, 'random_test_review_btn', 'Review'),
    random_test_modal_txt: getLabel(
      state,
      'random_test_modal_txt',
      'Please come back \n after you finish at least 2 Units and get at least 1 crown per unit'
    ),
    random_test_modal_confirm_txt: getLabel(
      state,
      'random_test_modal_confirm_txt',
      'Ok'
    ),
    createAccount_txt_title: getLabel(
      state,
      'createAccount_txt_title',
      'Create Account'
    ),
    promo_txt_wordsLearned: getLabel(
      state,
      'promo_txt_wordsLearned',
      'Words Learned'
    ),
    loginPurchase_txt: getLabel(state, 'loginPurchase_txt', 'Create Account'),
    // $FlowFixMe
    isUnitLocked: id => isUnitLocked(state, id),
    isCompleteTwoUnits: isCompleteTwoUnits(state),
    isShowRepairedUnitTooltipsAlready,
    firstCrackedUnitId,
    state: {
      config: getConfigState(state),
      onboarding: {
        completeCampaigns: selectCompletedCampaigns(state) || [],
        campaignComplete: selectCampaignStatus(state) || false,
        shopRedirected: selectRedirectStatus(state),
        campaignId: selectCampaign(state)
      }
    }
  };
};

const mapDispatchToProps = dispatch => ({
  common: bindActionCreators(CommonActions, dispatch),
  game: bindActionCreators(GameActions, dispatch),
  lesson: bindActionCreators(LessonActions, dispatch),
  profile: bindActionCreators(ProfileActions, dispatch),
  shop: bindActionCreators(ShopActions, dispatch),
  saveDailyGoal: bindActionCreators(saveDailyGoal, dispatch),
  signInWithCustomToken: token => dispatch(signInWithCustomToken(token))
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeScene);
