import { select, put } from 'redux-saga/effects';
import { getUnits } from '../selectors';
import {
  onUpdateUnitsProgress,
  toggleIsUnitsProgressUpdated
} from '../actions';

export function* updateUnitProgress(): Object {
  const units = yield select(getUnits);
  yield put(onUpdateUnitsProgress(units));
  yield put(toggleIsUnitsProgressUpdated());
}
