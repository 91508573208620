import React from 'react';
import { Line } from 'react-chartjs-2';
const DailyGoalChartWithoutMemo = ({ weeklyGraphData }: number[]) => {
  const data = {
    labels: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
    datasets: [
      {
        label: 'Daily Goal',
        data: weeklyGraphData,
        fill: false,
        backgroundColor: '#ffcc00',
        borderColor: 'rgba(255, 204, 0, 0.5)'
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#cccccc'
        },
        grid: {
          display: false,
          drawBorder: true,
          borderColor: 'rgba(255, 204, 0)'
        }
      },
      y: {
        position: 'right',
        ticks: {
          color: '#cccccc',
          stepSize: 100
        },
        suggestedMax: 400,
        min: 0,
        beginAtZero: true,
        grid: {
          borderDash: [1, 3],
          drawBorder: false,
          color: '#cccccc'
        }
      }
    }
  };

  const LineChart = () => (
    <>
      <Line data={data} options={options} />
    </>
  );

  return <>{LineChart()}</>;
};

export const DailyGoalChart = React.memo(DailyGoalChartWithoutMemo);
