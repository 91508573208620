/* @flow */
import _ from 'lodash';
import {
    FETCH_LANGUAGE_DATA,
    DOWNLOAD_FILES,
    FILE_DOWNLOADED,
    LOAD_LABELS,
    CANCEL_DOWNLOAD,
    SET_TARGET_LANGUAGE,
    SET_NATIVE_LANGUAGE,
    CHANGE_LABELS,
    FINISH_DOWNLOAD_GLOBAL_ASSETS,
    INIT_FAQ,
    LOAD_TARGET_LANG_CODES,
    LOAD_LANGUAGE_DATA,
    LOAD_LANG_CODES_INDEX,
    SET_HAS_ASKED_REMINDER_PERMISSION,
    TOGGLE_NOTIFICATION_ENABLED,
    LOAD_DIALOG_HEADER,
    DIALOG_DOWNLOADED,
    DEFAULT_NATIVE_LANGUAGE_CODE,
    DEFAULT_TARGET_LANGUAGE_CODE,
    SET_NOTIFICATION_ALLOWED,
    SET_DOWNLOAD_PHASE_LABEL,
    SET_TOGGLE_ONBOARDING,
    LOAD_CHATBOTS,
} from '../constants';

import {
    // LOAD_LANGUAGE_DATA,
    SAVE_UNITS_DATA,
    SET_LANGUAGE,
} from '../../common/constants/ActionTypes';

import { COURSE_MIGRATION } from '../../common/constants';

type Labels = {
    byId: {
        [string]: {
            id: string,
            content: string,
        },
    },
    allIds: string[],
};

export type State = {
    fetchingUnitId: ?number,
    totalFileSize: ?number,
    fileSizeDownloaded: ?number,
    labels: Labels,
    targetLangCode: ?string,
    nativeLangCode: ?string,
    globalAssetsDownloaded: boolean,
    faq: Object,
    targetLangCodes: string[],
    hasAskedReminderPermissions: boolean,
    isNotificationsEnabled?: boolean,
    dialogHeaders: Object,
    dialogHeadersDownloaded: boolean,
    stopWords: string[],
    setToggleOnBoarding: boolean,
    targetLangCodesIndex: any,
    chatbots: Object,
};

const INITIAL_STATE: State = {
    fetchingUnitId: null,
    totalFileSize: null,
    fileSizeDownloaded: null,
    labels: { byId: {}, allIds: [] },
    targetLangCode: DEFAULT_TARGET_LANGUAGE_CODE,
    nativeLangCode: DEFAULT_NATIVE_LANGUAGE_CODE,
    globalAssetsDownloaded: false,
    faq: {},
    targetLangCodes: [],
    hasAskedReminderPermissions: true,
    setToggleOnBoarding: true,
    dialogHeaders: { byId: {}, allIds: [] },
    dialogHeadersDownloaded: false,
    stopWords: [],
    targetLangCodesIndex: [],
    chatbots: {},
    courses: {
        native: DEFAULT_NATIVE_LANGUAGE_CODE,
        target: DEFAULT_TARGET_LANGUAGE_CODE,
    },
};

const setHasAskedReminderPermission = (state: State) => {
    return {
        ...state,
        hasAskedReminderPermissions: !state.hasAskedReminderPermissions,
    };
};
const setToggleOnBoarding = (state: State) => {
    return {
        ...state,
        setToggleOnBoarding: !state.setToggleOnBoarding,
    };
};
const toggleNotificationEnabled = (state: State) => ({
    ...state,
    isNotificationsEnabled: !state.isNotificationsEnabled,
});

const setNotificationsAllowed = (state: State, { isAllowed }: Object) => ({
    ...state,
    isNotificationAllowed: isAllowed,
});
const loadLanguageData = (state: State) => ({
    ...state,
});
const fetchLanguageData = (state: State, { unitId }: Object) => ({
    ...state,
    fetchingUnitId: unitId,
});

const downloadFiles = (state: State, { totalFileSize }: Object) => ({
    ...state,
    totalFileSize,
});

const fileDownloaded = (state: State, { fileSize }: Object) => ({
    ...state,
    fileSizeDownloaded: state.fileSizeDownloaded + fileSize,
});

const cancelDownload = (state: State) => ({
    ...state,
    fetchingUnitId: null,
    totalFileSize: 0,
    fileSizeDownloaded: 0,
});

const loadLabels = (state: State, { labels }: Object) => ({
    ...state,
    labels,
});

const loadChatbots = (state: State, { chatbots }: Object) => ({
    ...state,
    chatbots,
});

const loadDialogHeaders = (state: State, { dialogHeaders }: Object) => ({
    ...state,
    dialogHeaders,
});

const setTargetLanguage = (state: State, { targetLangCode }: Object) => ({
    ...state,
    targetLangCode: targetLangCode || DEFAULT_TARGET_LANGUAGE_CODE,
});

const setNativeLanguage = (state: State, { nativeLangCode }: Object) => ({
    ...state,
    nativeLangCode: nativeLangCode || DEFAULT_NATIVE_LANGUAGE_CODE,
});

const loadTargetLanguages = (state: State, { langCodes }: Object) => ({
    ...state,
    targetLangCodes: langCodes,
});

const initLangCodes = (state: State, { langCodes }: Object) => ({
    ...state,
    targetLangCodesIndex: langCodes,
});

const setDialogHeadersDownloaded = (state: State) => {
    return {
        ...state,
        dialogHeadersDownloaded: true,
    };
};

const finishDownloadGlobalAssets = (state: State) => {
    return {
        ...state,
        globalAssetsDownloaded: true,
    };
};

const changeLabels = (state: State, { newLabels }: Object) => {
    const allIds = newLabels.map(({ id }) => id);
    const byId = newLabels.reduce((byId, { id, content }) => {
        return {
            ...byId,
            [id]: { content, id },
        };
    }, {});

    return {
        ...state,
        labels: {
            ...state.labels,
            allIds: _.union(state.labels.allIds, allIds),
            byId: { ...state.labels.byId, ...byId },
        },
    };
};

const initFAQ = (state, { faq }) => ({ ...state, faq });
const eradicateOldStructure = state => {
    const {
        fetchingUnitId,
        totalFileSize,
        fileSizeDownloaded,
        labels,
        targetLangCode,
        nativeLangCode,
        globalAssetsDownloaded,
        faq,
    } = state;
    return {
        fetchingUnitId,
        totalFileSize,
        fileSizeDownloaded,
        labels,
        targetLangCode,
        nativeLangCode,
        globalAssetsDownloaded,
        faq,
    };
};
const setDownloadPhase = (state, { progressPhase }) => ({
    ...state,
    progressPhase,
});

// old
// const saveCourseData = (state = INITIAL_STATE, courses) => {
//   return {
//     ...state,
//     courses: {
//       ...courses
//     }
//   };
// };

const saveUnit = (state = INITIAL_STATE, lesson) => {
    return {
        ...state,
        lesson: lesson,
    };
};

const setLang = (state = INITIAL_STATE, lang) => {
    return {
        ...state,
        courses: {
            native: lang.native,
            target: lang.target,
        },
    };
};

export const LoadLanguagesDataReducer = (
    state: Object = INITIAL_STATE,
    action: Object
): Object => {
    switch (action.type) {
        case LOAD_LANGUAGE_DATA:
            return loadLanguageData(state);
        case FETCH_LANGUAGE_DATA:
            return fetchLanguageData(state, action);
        case DOWNLOAD_FILES:
            return downloadFiles(state, action);
        case FILE_DOWNLOADED:
            return fileDownloaded(state, action);
        case LOAD_LABELS:
            return loadLabels(state, action);
        case LOAD_CHATBOTS:
            return loadChatbots(state, action);
        case CANCEL_DOWNLOAD:
            return cancelDownload(state);
        case SET_TARGET_LANGUAGE:
            return setTargetLanguage(state, action);
        case SET_NATIVE_LANGUAGE:
            return setNativeLanguage(state, action);
        case CHANGE_LABELS:
            return changeLabels(state, action);
        case FINISH_DOWNLOAD_GLOBAL_ASSETS:
            return finishDownloadGlobalAssets(state);
        case DIALOG_DOWNLOADED:
            return setDialogHeadersDownloaded(state);
        case INIT_FAQ:
            return initFAQ(state, action);
        case LOAD_TARGET_LANG_CODES:
            return loadTargetLanguages(state, action);
        case LOAD_LANG_CODES_INDEX:
            return initLangCodes(state, action);
        case COURSE_MIGRATION:
            return eradicateOldStructure(state);
        case SET_HAS_ASKED_REMINDER_PERMISSION:
            return setHasAskedReminderPermission(state);
        case SET_TOGGLE_ONBOARDING:
            return setToggleOnBoarding(state);
        case TOGGLE_NOTIFICATION_ENABLED:
            return toggleNotificationEnabled(state);
        case LOAD_DIALOG_HEADER:
            return loadDialogHeaders(state, action);
        case SET_NOTIFICATION_ALLOWED:
            return setNotificationsAllowed(state, action);
        case SET_DOWNLOAD_PHASE_LABEL:
            return setDownloadPhase(state, action);
        // old
        // case LOAD_LANGUAGE_DATA:
        //   return saveCourseData(state, action.courses);
        case SAVE_UNITS_DATA:
            return saveUnit(state, action.lesson);
        case SET_LANGUAGE:
            return setLang(state, action);
        default:
            return state;
    }
};
