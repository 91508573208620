/* @flow */
import _ from 'lodash';
import { eventChannel } from 'redux-saga';
import { call, put, select, take } from 'redux-saga/effects';
import Firebase from '../../util/Firebase';
import { triggerUpdateUnitsProgress } from '../../lessons/actions';
import { getTargetLangCode } from '../../lessons/selectors';
import BugTracker from '../../util/BugTracker';
import {
  registerError,
  toggleAlreadyLinkedDialog
} from '../actions/AccountActions';
import { saveUserData } from '../../common/actions/AuthActions';
import {
  toggleSpinner,
  openLandingScreen
} from '../../common/actions/GlobalActions';
import {
  PROVIDER_FACEBOOK,
  PROVIDER_GOOGLE,
  PROVIDER_APPLE
} from '../constants';
// import BugTracker from '../../util/BugTracker';
import { getLabel } from '../../util';
import { UPDATE_SUBSCRIPTIONS } from '../../common/constants';
import firebase from '../../util/Firebase';

const INITIAL_BANANAS = 0;
const INITIAL_COINS = 5;
const INITIAL_COINS_SPEND = 0;

export const INITIAL_USER_PROGESS = {
  bananas: INITIAL_BANANAS,
  coins: INITIAL_COINS,
  coinsSpent: INITIAL_COINS_SPEND,
  progress: [],
  currentChatbotId: '',
  progressChatbot: {}
};

const signInWithCredential = (credential: Object) => {
  const { currentUser } = Firebase.auth();
  if (currentUser) {
    return currentUser.linkWithCredential(credential);
  }
  return Firebase.auth().signInWithCredential(credential);
};

export function* linkProvider(provider: string, getUser: Function): Object {
  yield call(loginToProvider, provider, getUser, true);
}

export function* loginToProvider(
  provider: string,
  getUser: Function,
  isLinkInSettings: boolean = false
): Object {
  try {
    yield put(toggleSpinner());
    // const user = yield call(getUser);
    // if (!isLinkInSettings) {
    //   yield put(handleAfterLogin());
    // }
  } catch (error) {
    BugTracker.notify(error);
    console.warn(
      `Login to ${provider} failed with error!`,
      error.message,
      error.code
    );
    yield call(handleLoginError, error);
  } finally {
    yield put(toggleSpinner());
  }
}

function* handleLoginError(error: Object): Object {
  const { code } = error;
  switch (code) {
    case 'auth/credential-already-in-use':
      yield put(toggleAlreadyLinkedDialog());
      break;
    case 'auth/user-not-found':
      yield put(
        registerError(
          { email: true },
          yield select(getLabel, 'email_error_userNotFound') || error.message
        )
      );
      break;
    default:
      BugTracker.notify(error);
      console.warn('Login Error', error);
  }
}

export const userFactory = (
  provider: string,
  doLoginUser: Function,
  getCredential: Function
) => {
  return function*(): Object {
    return yield call(loginUser, provider, doLoginUser, getCredential);
  };
};

function* loginUser(
  provider: string,
  loginUser: Function,
  getCredential: Function
): Object {
  const auth = Firebase.auth();
  // const { currentUser } = auth;
  let credential;
  try {
    const token = yield call(loginUser);
    credential = yield call(getCredential, token);
    const { user: authUser } = yield call(signInWithCredential, credential);
    yield call(getCurrentUserAndLink, authUser, provider);
    return authUser;
  } catch (error) {
    // const { code } = error;
    // if (code !== 'auth/credential-already-in-use') {
    //   BugTracker.notify(error);
    // }

    // console.log(
    //   'Trying to signin user with provider credential',
    //   yield select(getLabel, 'email_txt_login'),
    //   credential
    // );

    // console.log('error', error.email);
    // console.log(
    //   'Linking user failed! Trying to use existing account...',
    //   currentUser
    // );
    const { user: authUser } = yield call(() =>
      auth.signInWithCredential(credential)
    );
    yield call(getDatabaseUserAndLink, authUser, provider);
    return authUser;
  }
}

export const getProviderSpecificData = (
  authUser: Object,
  type: string
): Object => {
  const { providerData } = authUser;
  const providerProfile = extractProviderProfile(providerData, type);
  const { displayName, email, photoURL, uid } = providerProfile
    ? providerProfile
    : authUser;

  return {
    [type]: {
      id: uid,
      name: displayName,
      email,
      photoURL
    }
  };
};

const extractProviderProfile = (providerData: Object[], type: string) => {
  const findPredicate = ({ providerId }) =>
    providerId ===
    ([PROVIDER_FACEBOOK, PROVIDER_GOOGLE, PROVIDER_APPLE].includes(type)
      ? `${type}.com`
      : type);
  const profile = _.find(providerData, findPredicate);
  if (profile) {
    const { email, displayName, photoURL, uid } = profile;
    return { email, displayName, photoURL, uid };
  }
};

const getUserReference = (authUser: Object) => {
  return Firebase.database().ref(`users/${authUser.uid}`);
};

export const getUserChannel = (authUser: Object) => {
  return eventChannel(emit => {
    const callback = snapshot => {
      const newUser = snapshot.val();
      if (newUser) {
        emit(newUser);
      }
    };
    const ref = getUserReference(authUser);
    ref.on('value', callback);

    return () => {
      ref && ref.off();
    };
  });
};

export const getUserSubscriptionDetails = (authUser: Object) => {
  const { uid } = authUser;
  return eventChannel(emit => {
    const callback = snapshot => {
      const newUser = snapshot.val();
      if (newUser) {
        emit(newUser);
      }
    };
    const ref = firebase.database().ref(`payments/${uid}`);
    ref.on('value', callback);
    return () => {
      ref && ref.off();
    };
  });
};

const updateUserPayments = payments => ({
  type: UPDATE_SUBSCRIPTIONS,
  payments
});

export function* watchUserChanges(authUser: Object): Object {
  const channel = yield call(getUserChannel, authUser);
  while (true) {
    const changedUser = yield take(channel);
    yield put(saveUserData(changedUser));
  }
}

export function* watchPaymentChanges(authUser: Object): Object {
  const payments = yield call(getUserSubscriptionDetails, authUser);
  while (true) {
    const updatedPayment = yield take(payments);
    yield put(updateUserPayments(updatedPayment));
  }
}

export const getDatabaseUser = (authUser: Object): Promise<string> => {
  return new Promise((resolve, reject) => {
    const callback = snapshot => {
      resolve(snapshot);
    };
    getUserReference(authUser)
      .once('value', callback)
      .catch(error => {
        console.warn('getDatabaseUser Firebase error', error, authUser);
        BugTracker.breadcrumb('getDatabaseUser Firebase error', { authUser });
        BugTracker.notify(error);
        reject(error);
      });
  });
};

export function* getDatabaseUserAndLink(
  authUser: Object,
  provider: string
): Object {
  const snapshot = yield call(getDatabaseUser, authUser);
  const databaseUser = snapshot.val();
  yield call(linkDatabaseUser, authUser, provider, databaseUser);
}

export function* getCurrentUserAndLink(
  authUser: Object,
  provider: string
): Object {
  const snapshot = yield call(getDatabaseUser, authUser);
  const userData = snapshot.val();
  yield call(linkDatabaseUser, authUser, provider, userData);
}

function* linkDatabaseUser(
  authUser: Object,
  provider: string,
  existingUser: Object
) {
  yield call(createNewDatabaseUser, authUser, provider, existingUser);
}

export const mergeOldUser = (
  targetLangCode: string,
  providerSpecificData: Object,
  uid: string,
  existingUser: ?Object = {}
) => {
  const newUser = {
    uid,
    ...existingUser,
    ...providerSpecificData,
    targetLangCode
  };

  // if (isLanguageUniversal(targetLangCode)) {
  //   newUser[targetLangCode] = {
  //     ...INITIAL_USER_PROGESS
  //   };
  // }
  return newUser;
};

export function* createNewDatabaseUser(
  authUser: Object,
  type: string,
  existingUser: ?Object = {}
): Object {
  const { uid } = authUser;
  const targetLangCode = yield select(getTargetLangCode);
  const providerSpecificData = getProviderSpecificData(authUser, type);
  const user = mergeOldUser(
    targetLangCode,
    providerSpecificData,
    uid,
    existingUser
  );

  try {
    yield call(() =>
      Firebase.database()
        .ref(`users/${uid}`)
        .set(user)
    );
  } catch (error) {
    BugTracker.notify(error);
    console.warn(
      'Create user data failed. Could not create Firebase user!',
      error.message,
      uid
    );
  }
  yield put(saveUserData(user));
  yield put(triggerUpdateUnitsProgress());
}

export function* signOut(): Object {
  try {
    yield put(toggleSpinner());
    yield call(() => Firebase.auth().signOut());
    const authUser = (yield call(() => Firebase.auth().signInAnonymously()))
      .user;
    yield call(createNewDatabaseUser, authUser, 'anonymous');
    yield call(openLandingScreen);
  } catch (error) {
    console.warn('Error signing out!', error);
  } finally {
    yield put(toggleSpinner());
  }
}
