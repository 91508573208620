/* @flow */
import React, { Fragment, Component } from 'react';
import { DialogGame, DialogDisplay } from '../main-components';
import DialogHeaderContainer from '../../containers/DialogHeaderContainer';
// import { isScreenshotsApp } from '../../../util/AssetHelper';

type Props = {
  dialog: Object,
  spelling: Object,
  transcriptState: boolean,
  translateState: boolean,
  startGame: Function,
  onTranslate: Function,
  onStopSound: Function,
  onReplay: Function,
  playDialogSound: Function,
  triggerDialogAutoPlay: Function,
  getCharacterCards: Function,
  getDialogueCards: Function,
  pressCharacterCard: Function,
  spendHint: Function,
  pressSpellingNextButton: Function,
  dialogSaveScrollFunc: Function,
  unmountDialog: Function,
  btn_hint?: string,
  btn_check?: string,
  btn_continue?: string,
  error_txt_game_wrong?: string,
  dialog_txt_title?: string,
  dialog_btn_translate?: string,
  dialog_btn_stop?: string,
  writing_txt_replay?: string,
  title_txt_complete?: string,
  rtlLanguage: boolean,
  nativeRtlLanguage: boolean,
  coins: number,
  voiceSpeed: number,
  isShowTutorial: boolean,
  toggleTutorialMotivate: Function,
  closeTutorialMotivate: Function,
  isTargetLangSymbol: boolean,
  writing_skip: string,
  onCloseTutorialMotivate: Function,
  setShowTutorial: Function
};
type State = {
  isCollapsed: boolean
};

class Dialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isCollapsed: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  toggleHeader = (event: any) => {
    this.setState({
      isCollapsed: event
    });
  };

  dialogDiaplay = () => {
    const {
      dialog,
      transcriptState,
      translateState,
      onTranslate,
      onStopSound,
      onReplay,
      playDialogSound,
      dialogSaveScrollFunc,
      writing_txt_replay,
      triggerDialogAutoPlay,
      startGame,
      unmountDialog,
      dialog_txt_title,
      dialog_btn_translate,
      dialog_btn_stop,
      btn_continue,
      btn_hint,
      coins,
      voiceSpeed,
      isShowTutorial,
      toggleTutorialMotivate,
      isTargetLangSymbol,
      writing_skip,
      onCloseTutorialMotivate,
      setShowTutorial
    } = this.props;
    const { isCollapsed } = this.state;
    return (
      <DialogDisplay
        isCollape={isCollapsed}
        {...dialog}
        // autoplay={!isScreenshotsApp()}
        transcriptState={transcriptState}
        translateState={translateState}
        onTranslate={onTranslate}
        onStopSound={onStopSound}
        onReplay={onReplay}
        onPress={playDialogSound}
        dialogSaveScrollFunc={dialogSaveScrollFunc}
        triggerDialogAutoPlay={triggerDialogAutoPlay}
        unmountDialog={unmountDialog}
        startGame={startGame}
        title={dialog_txt_title}
        dialog_btn_translate={dialog_btn_translate}
        dialog_btn_stop={dialog_btn_stop}
        writing_txt_replay={writing_txt_replay}
        btn_continue={btn_continue}
        btn_hint={btn_hint}
        coins={coins}
        voiceSpeed={voiceSpeed}
        isShowTutorial={isShowTutorial}
        toggleTutorialMotivate={toggleTutorialMotivate}
        isTargetLangSymbol={isTargetLangSymbol}
        writing_skip={writing_skip}
        onCloseTutorialMotivate={onCloseTutorialMotivate}
        setShowTutorial={setShowTutorial}
      />
    );
  };

  dialogGame = () => {
    const {
      dialog,
      spelling,
      transcriptState,
      translateState,
      onStopSound,
      playDialogSound,
      dialogSaveScrollFunc,
      btn_continue,
      btn_hint,
      coins,
      voiceSpeed,
      isShowTutorial,
      isTargetLangSymbol,
      writing_skip,
      onCloseTutorialMotivate,
      setShowTutorial,
      pressCharacterCard,
      spendHint,
      pressSpellingNextButton,
      getCharacterCards,
      getDialogueCards,
      btn_check,
      error_txt_game_wrong,
      title_txt_complete,
      rtlLanguage,
      nativeRtlLanguage
    } = this.props;
    const { isCollapsed } = this.state;

    return (
      <DialogGame
        isCollape={isCollapsed}
        {...spelling}
        dialog={{
          byId: dialog.dialogues,
          allIds: dialog.dialogueIds,
          questions: dialog.questions,
          questionIds: dialog.questionIds
        }}
        translation={''}
        transcriptState={transcriptState}
        translateState={translateState}
        pressCharacterCard={pressCharacterCard}
        spendHint={spendHint}
        coins={coins}
        pressSpellingNextButton={pressSpellingNextButton}
        getCharacterCards={getCharacterCards}
        getDialogueCards={getDialogueCards}
        pressMessage={playDialogSound}
        dialogSaveScrollFunc={dialogSaveScrollFunc}
        onStopSound={onStopSound}
        btn_hint={btn_hint}
        btn_check={btn_check}
        btn_continue={btn_continue}
        error_txt_game_wrong={error_txt_game_wrong}
        title_txt_complete={title_txt_complete}
        rtlLanguage={rtlLanguage}
        nativeRtlLanguage={nativeRtlLanguage}
        voiceSpeed={voiceSpeed}
        isShowTutorial={isShowTutorial}
        isTargetLangSymbol={isTargetLangSymbol}
        writing_skip={writing_skip}
        onCloseTutorialMotivate={onCloseTutorialMotivate}
        setShowTutorial={setShowTutorial}
      />
    );
  };

  render() {
    const { dialog } = this.props;
    const { state } = dialog;
    const isDisplay = state === 0;
    const checkDialog = isDisplay ? this.dialogDiaplay() : this.dialogGame();

    return (
      <Fragment>
        <DialogHeaderContainer toggleHeader={this.toggleHeader} />
        {checkDialog}
      </Fragment>
    );
  }
}

export { Dialog };
