/* @flow */
import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import {
  BLACK,
  WHITE,
  BLACK_HOVER,
  GREYISH_BROWN,
  VERY_LIGHT_GREY,
  GREY2,
  PREFIX_HEADER,
  BLACK_OPACITY_COLOR,
  GREY
} from '../../../common/constants';
import {
  BUTTON_CONTINUE,
  SPEAKING,
  KEYCODE_ENTER,
  IS_SPEAKING_RECORDED,
  MIC_BUTTON_ID
} from '../../constants';
import { CheckButton } from '../sub-components';
import { playSound, stopSound } from '../../../util';
import BugTracker from '../../../util/BugTracker';
import _ from 'lodash';
import { evaluate } from '../../../util/SpeakingEvaluator';
import { speakingGameDefaultError } from '../../actions';
import { SpeakingNotSupportModal } from '../sub-components/SpeakingNotSupportModal';
import { VoiceSpeedButton } from '../sub-components/VoiceSpeedButton';

type Props = {
  mode: string,
  speaking_txt_instruction: string,
  writing_txt_skip: string,
  lang: Object,
  writing_txt_skip_description: string,
  btn_hint: string,
  transcript: string,
  soundFile: string,
  transcriptState: boolean,
  original: string,
  coins: number,
  btn_check: string,
  btn_continue: string,
  onSkip: Function,
  voiceSpeed: number,
  answeredWrong: boolean,
  speakingErrorMsg: string,
  noMatch: Function,
  networkError: Function,
  onCloseModal: Function,
  pressNext: Function,
  increaseGameSkipCount: Function,
  modal_txt_speaking_no_supported_title: string,
  afterBuy_txt_back: string,
  modal_txt_speaking_no_supported_desc: string,
  history: Object,
  unitId: number,
  rtlLanguage: boolean,
  nativeRtlLanguage: boolean
};

const Container = styled.div`
  margin-bottom: 120px;
  margin-top: 95px;
  width: 100%;
  min-height: 450px;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
const ContentContainer = styled.div`
  width: 738px;
  margin: auto;
  margin-bottom: 130px;
`;

const RecorderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 95px !important;
  margin-bottom: 26px !important;
  position: relative;
`;

const QuestionContainer = styled.div`
  position: relative;
  width: 140px;
  margin: auto;
  margin-top: 15px;
  display: flex;
  justify-content: center;
`;

const MicButton = styled.button`
  padding: 0;
  border: none;
  outline: none;
  border-radius: 50%;
  height: 71px;
  box-shadow: 0 2px 20px 0 ${BLACK_OPACITY_COLOR};
  background: ${props => (props.isRecord ? BLACK : '')};

  &:hover {
    background: ${BLACK_HOVER};
    #buttonIcon1 {
      opacity: 0.4;
    }
  }
  &:active {
    background: ${BLACK};
    #buttonIcon1 {
      display: inline !important;
    }
  }
`;
const RecorderBarContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CancelButton = styled.div`
  display: ${props => (props.isRecord ? 'flex' : 'none')};
  flex-direction: row;
  margin-left: 17px;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const Space1 = styled.div``;
const Space = styled.div`
  height: 110px;
`;
const Image = styled.img`
  width: 20px;
  height: 20px;
`;

const PadButton1 = styled.div`
  background: ${GREYISH_BROWN};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MicStylePad = styled.img`
  border: 0;
  border-radius: 100px;
  height: 29px;
  width: 20px;
  padding: 20px 25px;
`;

const InstructionsHeader = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${GREY2};
  text-align: center;
  margin-top: 25px !important;
  margin-bottom: 0px !important;
`;

const TextStyle = styled.p`
  font-size: 26px;
  font-weight: bold;
  color: ${BLACK};
  text-align: center;
  margin-top: 15px !important;
  margin-bottom: 9px !important;
  padding-bottom: 10px;
  padding-top: 10px;
  border: 2px #ccccc0 dashed;
  border-radius: 10px;
  width: 70%;
  margin: auto;
`;
const TranscriptStyle = styled.p`
  font-size: 16px;
  color: ${VERY_LIGHT_GREY};
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  text-align: center;
`;

const PlayButtonContainer = styled.div`
  border: solid 1px ${BLACK};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  bottom: -10px;
  margin-right: 5px;
  background: ${WHITE};
  cursor: pointer;
  left: 50%;
  top: -15px;
  &:hover {
    border-color: ${BLACK_HOVER};
    #buttonIcon {
      opacity: 0.4;
    }
  }
  &:active {
    background: ${BLACK};
    #buttonIcon1 {
      display: inline !important;
    }
  }
`;

const SpeakerImage = styled.img`
  position: relative;
`;

const SpeakerImageActive = styled.img`
  display: none;
  position: absolute;
  zindex: 99;
`;

const SpeakErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-item: center;
  position: absolute;
  top: 92px;
  z-index: 4;
`;

const SpeakErrorImg = styled.img`
  width: 45px;
`;
const SpeakErrorText = styled.p`
  text-align: center;
`;
const SpeakErrorTClose = styled.img`
  width: 14px;
  height: 14px;
  cursor: pointer;
  opacity: 0.3;
`;
const SpeakErrorMsgText = styled.div`
  font-size: 16px;
  background: ${WHITE};
  position: relative;
  border: solid 1px ${GREY};
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 307px;
  height: 54px;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  z-index: 5;
  &:before {
    content: '';
    position: absolute;
    top: -11px;
    left: 50%;
    height: 20px;
    width: 20px;
    background: ${WHITE};
    transform: translateX(-50%) rotate(45deg);
    ${'' /* transform: translateX(-50%); */}

    border-top: inherit;
    border-left: inherit;
    box-shadow: inherit;
  }
`;

const speakerImage = require('../../../img/icons/outline-sound-icon.svg');
const micImage = require('../../../img/icons/yellow@2x.png');
const speakerImageActive = require('../../../img/icons/outline-sound-icon-active.svg');

type State = {
  isSpeaking: number,
  result: string,
  recognized: boolean,
  toggleCheck: boolean,
  isRecord: number,
  percentile: number,
  correctAnswer: any,
  percentile: number,
  isShowReplay: boolean,
  isResultShown: boolean,
  isSpeakSupported: boolean,
  isModalShow: boolean
};
const lingHelloImg = `${PREFIX_HEADER}master-ling-hi-circle-image.svg`;
const speakErrorClose = `${PREFIX_HEADER}close-icon.svg`;
// eslint-disable-next-line no-undef
let recognition;

class Speaking extends Component<Props, State> {
  constructor(props: Props) {
    if (_.isEmpty(recognition)) {
      recognition = new window.webkitSpeechRecognition();
    }
    super(props);

    this.state = {
      isRecord: 0,
      isSpeaking: 0,
      result: '',
      recognized: false,
      toggleCheck: false,
      percentile: 100,
      correctAnswer: [],
      isShowReplay: false,
      isResultShown: false,
      isSpeakSupported: false,
      isModalShow: false
    };

    this.stopRecording = this.stopRecording.bind(this);
  }

  startRecording = (recognition: Function) => {
    this.props.onCloseModal();
    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      console.log('SpeechRecognition supported');
    } else {
      this.toggleSpeakingWarningModal();
    }

    recognition.start();
    this.setState({
      isRecord: 1
    });
  };

  stopRecording = (recognition: Function, result: string) => {
    const { isModalShow } = this.state;
    console.log('Result', result);
    this.setState({
      isRecord: 0,
      result
    });
    recognition.stop();

    const successCallback = (correctAnswer: any) => {
      this.setState(state => ({
        ...state,
        recognized: true,
        toggleCheck: true,
        percentile: 100,
        isSpeaking: IS_SPEAKING_RECORDED,
        correctAnswer: correctAnswer,
        isResultShown: true
      }));
    };
    const failCallback = (correctAnswer: any, percentile: number) => {
      this.setState(state => ({
        ...state,
        recognized: false,
        toggleCheck: true,
        isResultShown: true,
        correctAnswer: correctAnswer,
        percentile: percentile,
        isSpeaking: IS_SPEAKING_RECORDED,
        isShowReplay: true
      }));

      console.log('Answer wrong');
    };
    const remind = () => {
      this.props.noMatch();
      // $flow-disable-line
      this.timeoutID = undefined;
    };
    const cancel = () => {
      // $flow-disable-line
      window.clearTimeout(this.timeoutID);
    };
    const setup = () => {
      // $flow-disable-line
      if (typeof this.timeoutID === 'number') {
        cancel();
      }
      // $flow-disable-line
      this.timeoutID = window.setTimeout(
        function(isModalShow) {
          if (isModalShow) {
            remind();
          }
        },
        500,
        isModalShow
      );
    };
    if (result) {
      cancel();
      evaluate(this.props, result, successCallback, failCallback);
    } else {
      setup();
    }
  };

  onPlay = (soundFile: string, voiceSpeed: number) => {
    return () => playSound(soundFile, voiceSpeed);
  };
  goToHome() {
    const { unitId, history } = this.props;
    history.push(`/unit/${unitId}`);
  }

  componentDidMount(): void {
    window.scrollTo(0, 0);
    const {
      lang: { langCodeSpeakingAndroid },
      noMatch,
      networkError
    } = this.props;
    window.addEventListener('online', () => {
      this.props.onCloseModal();
    });
    if (recognition) {
      recognition.interimResults = false;
      recognition.continuous = true;
      recognition.lang = langCodeSpeakingAndroid;
      recognition.onaudiostart = function() {
        console.log('Audio capture start');
      };
      recognition.onaudioend = function() {
        console.log('Audio capture stop');
      };
      recognition.onerror = function(event) {
        this.setState({
          isRecord: 0
        });
        switch (event.error) {
          case 'not-allowed':
            speakingGameDefaultError();
            break;
          case 'network':
            networkError();
            break;
          case 'no-speech':
            noMatch();
            break;
          default:
            speakingGameDefaultError();
        }
      }.bind(this);
      recognition.onresult = function(event) {
        const { resultIndex } = event;
        console.log(
          _.get(event, `results.${resultIndex}.${resultIndex}.transcript`, '')
        );

        this.stopRecording(
          recognition,
          _.get(event, `results.${resultIndex}.${resultIndex}.transcript`, '')
        );
        this.props.onCloseModal();
      }.bind(this);
    }
    // $flow-disable-line
    document.addEventListener('keydown', this.handleEnter);
  }
  componentWillUnmount() {
    // $flow-disable-line
    document.removeEventListener('keydown', this.handleEnter);
  }
  handleEnter = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { pressNext } = this.props;
    const { isSpeaking, correctAnswer } = this.state;
    if (event.keyCode === KEYCODE_ENTER && pressNext) {
      this.setState({
        isModalShow: false
      });
      // $flow-disable-line
      document.getElementById(MIC_BUTTON_ID).blur();
      if (isSpeaking === IS_SPEAKING_RECORDED) {
        this.setState({
          isRecord: 0,
          isSpeaking: 0,
          result: '',
          recognized: false,
          toggleCheck: false,
          correctAnswer: correctAnswer,
          isShowReplay: false,
          isResultShown: false,
          isModalShow: false
        });
        stopSound();
        pressNext();
      } else {
        this.stopRecording(recognition, '');
        pressNext();
      }
    }
  };

  toggleSpeakingWarningModal = () => {
    this.setState({
      isSpeakSupported: !this.state.isSpeakSupported
    });
  };

  getRecordState() {
    const {
      btn_hint,
      btn_check,
      btn_continue,
      coins,
      pressNext = () => {},
      onSkip = () => {},
      answeredWrong,
      increaseGameSkipCount
    } = this.props;
    const { isSpeaking, isResultShown, correctAnswer, percentile } = this.state;
    switch (isSpeaking) {
      case 0:
        return (
          <CheckButton
            quizAnswer={''}
            mode={BUTTON_CONTINUE}
            btn_check={btn_check}
            btn_continue={btn_continue}
            btn_hint={btn_hint}
            gameType={SPEAKING}
            coins={coins}
            onPressNext={() => {
              this.setState({
                isModalShow: false
              });
              increaseGameSkipCount();
              onSkip();
            }}
          />
        );
      case 2:
        return (
          <CheckButton
            quizAnswer={''}
            mode={BUTTON_CONTINUE}
            btn_check={btn_check}
            btn_continue={btn_continue}
            btn_hint={btn_hint}
            gameType={SPEAKING}
            coins={coins}
            isResultShown={isResultShown}
            answeredWrong={answeredWrong}
          />
        );
      default:
        return (
          <CheckButton
            quizAnswer={''}
            mode={BUTTON_CONTINUE}
            onPressNext={() => {
              this.setState({
                isRecord: 0,
                isSpeaking: 0,
                result: '',
                recognized: false,
                toggleCheck: false,
                correctAnswer: correctAnswer,
                isShowReplay: false,
                isResultShown: false,
                isModalShow: false
              });
              stopSound();
              pressNext();
            }}
            btn_check={btn_check}
            correctAnswer={correctAnswer}
            btn_continue={btn_continue}
            btn_hint={btn_hint}
            gameType={SPEAKING}
            coins={coins}
            isResultShown={isResultShown}
            answeredWrong={answeredWrong}
            percentile={percentile}
          />
        );
    }
  }

  render() {
    const { isRecord, isSpeakSupported } = this.state;
    const {
      transcriptState,
      soundFile,
      nativeRtlLanguage,
      rtlLanguage,
      transcript,
      original,
      speaking_txt_instruction,
      voiceSpeed,
      speakingErrorMsg,
      onCloseModal,
      modal_txt_speaking_no_supported_title,
      afterBuy_txt_back,
      modal_txt_speaking_no_supported_desc
    } = this.props;

    return (
      <React.Fragment>
        <Space1 />

        <Container>
          <ContentContainer>
            <InstructionsHeader>{speaking_txt_instruction}</InstructionsHeader>

            <TextStyle dir={rtlLanguage || nativeRtlLanguage ? 'rtl' : 'ltr'}>
              {original}
              {transcriptState ? (
                <TranscriptStyle>{transcript}</TranscriptStyle>
              ) : (
                ''
              )}
            </TextStyle>
            <QuestionContainer>
              <PlayButtonContainer onClick={this.onPlay(soundFile, voiceSpeed)}>
                <SpeakerImage id="buttonIcon" src={speakerImage} />
                <SpeakerImageActive id="buttonIcon1" src={speakerImageActive} />
              </PlayButtonContainer>
              <VoiceSpeedButton soundFile={soundFile} voiceSpeed={voiceSpeed} />
            </QuestionContainer>

            <RecorderContainer>
              <MicButton
                isRecord={isRecord}
                id={MIC_BUTTON_ID}
                onClick={() => {
                  this.setState({
                    isModalShow: true
                  });
                  if (isRecord) {
                    try {
                      this.stopRecording(recognition, '');
                    } catch (e) {
                      console.log('recording already stop');
                      BugTracker.notify(e);
                    }
                  } else {
                    try {
                      this.startRecording(recognition);
                    } catch (e) {
                      console.log('recording already start');
                      BugTracker.notify(e);
                    }
                  }
                }}
              >
                <PadButton1 id="buttonIcon1">
                  <MicStylePad src={micImage} />
                </PadButton1>
              </MicButton>
              {speakingErrorMsg || false ? (
                <SpeakErrorContainer>
                  <SpeakErrorMsgText>
                    <SpeakErrorImg src={lingHelloImg} alt={lingHelloImg} />
                    <SpeakErrorText>{speakingErrorMsg}</SpeakErrorText>
                    <SpeakErrorTClose
                      onClick={() => onCloseModal()}
                      src={speakErrorClose}
                      alt={speakErrorClose}
                    />
                  </SpeakErrorMsgText>
                </SpeakErrorContainer>
              ) : null}
            </RecorderContainer>
            <RecorderBarContainer>
              <div className="progress-bar">
                {isRecord ? (
                  <div className="progress-bar-value" />
                ) : (
                  <div className="progress-bar-value-stop" />
                )}
              </div>
              <CancelButton
                isRecord={isRecord}
                onClick={() => {
                  console.log('Stop');
                  this.stopRecording(recognition, '');
                }}
              >
                <Image src={`${PREFIX_HEADER}close-icon.svg`} />
              </CancelButton>
            </RecorderBarContainer>
          </ContentContainer>
        </Container>
        <Space />
        {this.getRecordState()}
        <SpeakingNotSupportModal
          goToHome={() => {
            this.toggleSpeakingWarningModal();
            this.goToHome();
          }}
          isOpen={isSpeakSupported}
          modal_txt_speaking_no_supported_title={
            modal_txt_speaking_no_supported_title
          }
          afterBuy_txt_back={afterBuy_txt_back}
          modal_txt_speaking_no_supported_desc={
            modal_txt_speaking_no_supported_desc
          }
        />
      </React.Fragment>
    );
  }
}

const SpeakingWithRouter = withRouter(Speaking);
export { SpeakingWithRouter };
