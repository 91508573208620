import { Button } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const MotivationButton = ({
  setSelected,
  motivationChoice,
  buttonStyle,
  buttonText
}) => {
  const styles = {
    icon: { color: 'white', height: '20px', m: 1 }
  };
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={() => setSelected(motivationChoice)}
        sx={buttonStyle}
      >
        {motivationChoice === 1 ? (
          <CloseIcon sx={styles.icon} />
        ) : (
          <CheckIcon sx={styles.icon} />
        )}
          {buttonText}
      </Button>
    </>
  );
};

export default MotivationButton;
