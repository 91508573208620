/* @flow */
import { TOGGLE_FLASHCARD } from '../constants';
import Analytic from '../../util/Analytic';

export const toggleFlashcard = () => ({ type: TOGGLE_FLASHCARD });

export const analyticFlashcard = () => {
  return (dispatch: Function, getState: Function) => {
    const { game, routes } = getState();
    Analytic.log(Analytic.key.G1, {
      game_type: routes.gameType,
      content: game.key
    });
    Analytic.logGA(Analytic.key.G1, {
      category: Analytic.key.G1,
      action: 'Check',
      label: routes.gameType
    });
  };
};
