/* @flow */
import React from 'react';
import styled from 'styled-components';
import {
  PREFIX_LESSON,
  GREYISH_BROWN,
  WHITE,
  GREY
} from '../../../common/constants';

const replayPic = `${PREFIX_LESSON}basecolor-play-black-30-px.svg`;
const stopPic = `${PREFIX_LESSON}stop-icon24x24.png`;
const replayPicReivew = `${PREFIX_LESSON}circle-fill-play-30-px@2x.png`;

const ButtonWrapper = styled.div`
  display: flex;
  height: 55px;
  width: 55px;
  border-radius: 15px;
  flex-direction: row;
  margin: 0 6px 0 0;
  border-width: 2px;
  justify-content: center;
  align-items: center;
  border-style: solid;
  cursor: pointer;
`;

const ButtonStopWrapper = styled(ButtonWrapper)`
  background-color: ${GREYISH_BROWN};
  border-color: ${GREYISH_BROWN};
`;

const ButtonPlayWrapper = styled(ButtonWrapper)`
  background-color: ${WHITE};
  border-color: ${GREY};
`;

const Image = styled.img`
  height: 30px;
  width: 30px;
`;

type Props = {
  playing: boolean,
  onStopSound: Function,
  onReplay: Function,
  dialog_btn_stop: string,
  writing_txt_replay: string,
  isReview: boolean
};

const DialogReplayButton = ({
  playing,
  onStopSound,
  onReplay,
  dialog_btn_stop,
  writing_txt_replay,
  isReview
}: Props) => {
  return playing ? (
    <ButtonStopWrapper onClick={onStopSound} title={dialog_btn_stop}>
      <Image src={stopPic} alt={stopPic} />
    </ButtonStopWrapper>
  ) : (
    <ButtonPlayWrapper onClick={onReplay} title={writing_txt_replay}>
      {isReview ? (
        <Image src={replayPicReivew} alt={replayPicReivew} />
      ) : (
        <Image src={replayPic} alt={replayPic} />
      )}
    </ButtonPlayWrapper>
  );
};

export { DialogReplayButton };
