/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import { BLACK, GREY2 } from '../../../common/constants';

const QuizQuestionContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const QuizText = styled.p`
    font-size: 16px;
    font-weight: bold;
    color: ${GREY2};
    margin: 2px;
    @media (max-height: 700px) {
        margin: 0px !important;
    }
`;

const QuizQuestionText = styled.p`
    font-size: 26px;
    font-weight: bold;
    color: ${BLACK};
    border-bottom: 2px black solid;
    padding: 8px;
    padding-top: 4px;
    margin: 0px;
    @media (max-height: 700px) {
        margin: 0px !important;
    }
`;

const QuizQuestion = ({ title, question }: Object) => (
    <QuizQuestionContainer>
        <QuizText>{title}</QuizText>
        <QuizQuestionText>{question}</QuizQuestionText>
    </QuizQuestionContainer>
);
export { QuizQuestion };
