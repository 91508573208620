import firebase from 'firebase';
import RemoteConfigDefaults from './remoteConfigDefaults';

let remoteConfig;

export default class RemoveConfigService {
  static initService() {
    return new Promise((res, rej) => {
      console.log('init service');
      try {
        remoteConfig = firebase.remoteConfig();
        remoteConfig.settings = {
          fetchTimeoutMillis: 60000,
          minimumFetchIntervalMillis: 1
        };
        remoteConfig.defaultConfig = RemoteConfigDefaults;
        if (remoteConfig.ensureInitialized()) {
          res();
        }
      } catch (error) {
        rej(error);
      }
    });
  }

  static fetchConfig() {
    return new Promise((res, rej) => {
      remoteConfig
        .fetchAndActivate(RemoteConfigDefaults)
        .then(() => {
          const data = remoteConfig.getAll();
          res(data);
        })
        .catch(error => {
          rej(error);
        });
    });
  }
}
