/* @flow */
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { FALSE, SHOW_TUTORIAL, WHITE } from '../../../common/constants';
import DefaultHeaderContainer from '../../../common/containers/DefaultHeaderContainer';
import { ChatbotsList } from '../main-components/ChatbotsList';
import styled from 'styled-components';

const Container = styled.div`
  flex: 1;
  background: ${WHITE};
`;

type Props = {
  chatbots: Array<Object>,
  currentChatbotId: string,
  flagTarget: string,
  flagUri: string,
  chatbot_txt_learning: string,
  loadChatbotsData: Function,
  modal_txt_internet_offline: string,
  btn_continue: string,
  targetLangCode: string,
  fetchUnitData: Function,
  nativeLangCode: string,
  versions: Object,
  history: Object,
  resetChatbot: Function,
  toggleChatbotLoaded: Function,
  isLoading: boolean,
  chatbotLoaded: string,
  noAccessChatbotUnits: Array<string>
};

class ChatbotsScene extends React.Component<Props> {
  topRef: Object;
  constructor(props: Props) {
    super(props);
    this.topRef = React.createRef();
  }
  componentDidMount() {
    this.props.resetChatbot();
    window.scrollTo(0, 0);
  }

  triggerOpenShop = () => {
    this.props.history.push('/shop');
  };

  loadChatbotsData = (chatbotsOrder, chatbotsId) => {
    const {
      fetchUnitData,
      nativeLangCode,
      targetLangCode,
      history,
      loadChatbotsData,
      toggleChatbotLoaded,
      chatbotLoaded
    } = this.props;
    if (chatbotLoaded === chatbotsId) {
      loadChatbotsData(chatbotsId);
      history.push(`/conversation/${chatbotsOrder}`);
    } else {
      fetchUnitData(targetLangCode, nativeLangCode, 0, () => {
        loadChatbotsData(chatbotsId);
        // eslint-disable-next-line
        if (localStorage.getItem(SHOW_TUTORIAL) === FALSE) {
          toggleChatbotLoaded(chatbotsId);
        }
        history.push(`/conversation/${chatbotsOrder}`);
      });
    }
  };
  render() {
    const {
      chatbots,
      chatbot_txt_learning,
      currentChatbotId,
      btn_continue,
      isLoading,
      noAccessChatbotUnits
    } = this.props;
    return (
      <Container>
        <DefaultHeaderContainer isSrollable={false} />
        <ChatbotsList
          isLoading={isLoading}
          chatbots={chatbots}
          restrictedUnits={noAccessChatbotUnits}
          currentChatbotId={currentChatbotId}
          chatbot_txt_learning={chatbot_txt_learning}
          loadChatbotsData={this.loadChatbotsData}
          triggerOpenShop={this.triggerOpenShop}
          btn_continue={btn_continue}
        />
      </Container>
    );
  }
}

const ChatbotsSceneWithRouter = withRouter(ChatbotsScene);
export { ChatbotsSceneWithRouter };
