import {
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage
} from './LocalStorageHelper';

export const setUTM = () => {
  const queryString = window.location.search;
  // eslint-disable-next-line no-undef
  const urlParams = new URLSearchParams(queryString);
  const utm_source = urlParams.get('utm_source');
  const utm_medium = urlParams.get('utm_medium');
  const utm_campaign = urlParams.get('utm_campaign');

  if (utm_source && utm_medium && utm_campaign) {
    setLocalStorage('utm_source', utm_source);
    setLocalStorage('utm_medium', utm_medium);
    setLocalStorage('utm_campaign', utm_campaign);
  }
};

export const getUTM = () => {
  const utm_source = getLocalStorage('utm_source');
  const utm_medium = getLocalStorage('utm_medium');
  const utm_campaign = getLocalStorage('utm_campaign');
  removeLocalStorage('utm_source');
  removeLocalStorage('utm_medium');
  removeLocalStorage('utm_campaign');
  return { utm_source, utm_medium, utm_campaign };
};
