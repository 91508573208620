/* @flow */
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';

import {
  PRODUCT_LING_PREMIUM,
  PRODUCT_PLAN_NAME,
  PRODUCT_TYPE,
  PROMO_LIFETIME,
  SPRIPE_CANCELED
} from '../shop/constants';
import { CURRENCY, PAST_DUE, STRIPE } from '../common/constants';
import { getStripeProductId } from './';
import { getPlanData } from './StripeProductsHandlers';

export const fetchStringShopPrice = (
  functions: string,
  currency: string,
  user: Object,
  stateChangeHandler: Function
) => {
  axios.get(`${functions}validateLingWeb-stripeApi/products/get`).then(res => {
    if (res.data.code !== 400) {
      _.forEach(res.data.plans.data, value => {
        if (
          value.interval === 'year' &&
          value.currency.toUpperCase() ===
            (currency ? currency : 'usd').toUpperCase() &&
          _.includes([value.id], getStripeProductId(currency, 'yearly', user))
        ) {
          stateChangeHandler('yearPrice', value.amount);
        } else if (
          value.interval === 'month' &&
          value.currency.toUpperCase() ===
            (currency ? currency : 'usd').toUpperCase() &&
          _.includes([value.id], getStripeProductId(currency, 'monthly', user))
        ) {
          stateChangeHandler('monthPrice', value.amount);
        } else {
          if (
            value.interval === 'year' &&
            value.currency.toUpperCase() === CURRENCY.USD
          ) {
            stateChangeHandler('usdYearPrice', value.amount);
          } else if (
            value.interval === 'month' &&
            value.currency.toUpperCase() === CURRENCY.USD
          ) {
            stateChangeHandler('usdMonthPrice', value.amount);
          }
        }
      });
      _.forEach(res.data.skus.data, value => {
        if (!_.isEmpty(value)) {
          if (
            value.currency.toUpperCase() ===
              (currency ? currency : 'usd').toUpperCase() &&
            _.includes(
              [value.id],
              getStripeProductId(currency, 'lifetime', user)
            )
          ) {
            stateChangeHandler('lifeTimePrice', value.price);
          } else if (
            value.currency.toUpperCase() === CURRENCY.USD &&
            _.includes(
              [value.id],
              getStripeProductId(currency, 'lifetime', user)
            )
          ) {
            stateChangeHandler('usdLifeTimePrice', value.price);
          }
        }
      });
    }
  });
};

export const getCheckoutTitle = (
  productType: string,
  shop_txt_month: string,
  shop_txt_year: string,
  purchase_txt_option_lifeTime: string
) => {
  switch (productType) {
    case PRODUCT_TYPE.monthly:
      return `1 ${shop_txt_month}`;
    case PRODUCT_TYPE.yearly:
      return `1 ${shop_txt_year}`;
    case PRODUCT_TYPE.quarterly:
      return `3 ${shop_txt_month}`; // TODO: LOCALIZATION - Acquire the label for plural for "month"
    default:
      return purchase_txt_option_lifeTime;
  }
};

export const getPriceNumber = (
  price: number,
  usdPrice: number,
  currencySymbol: Object
) => {
  return currencySymbol.symbol + price / 100;
};

export const getUserSubscriptionDataDetail = (
  user: Object,
  functions: string,
  updateUserData: Function,
  stateChangeHandler: Function
) => {
  const { subscription, ListOfSubscriptions } = user;
  if (!_.isUndefined(subscription)) {
    if (_.get(subscription, 'activeSubscription', false)) {
      const activeSubscription = _.get(
        subscription,
        `purchaseHistory.${subscription.activeSubscription
          .split('.')
          .join('_')}`,
        false
      );
      console.log('activeSubscription', activeSubscription);
      if (activeSubscription.type === 'stripe') {
        axios
          .get(
            `${functions}validateLingWeb-stripeApi/${activeSubscription.ReceiptData}`
          )
          .then(res => {
            const { data } = res;
            const { current_period_end, status } = data;
            if (data.code !== 400) {
              stateChangeHandler('subscriptionData', {
                ...data,
                type: activeSubscription.type,
                nextPayment: moment(current_period_end * 1000).format(
                  'MMMM DD, YYYY'
                )
              });
            }
            if (status === SPRIPE_CANCELED) {
              updateUserData({ isProUser: false });
            }
          });
      } else if (
        activeSubscription.type === 'android' ||
        activeSubscription.type === 'IOS'
      ) {
        let productPlan;

        let expiryTime = new Date(
          parseInt(
            activeSubscription.type === 'IOS'
              ? activeSubscription.receipt.expires_date_ms
              : activeSubscription.expiryTimeMillis,
            10
          )
        ).toLocaleDateString();
        if (_.includes(subscription.activeSubscription, PROMO_LIFETIME)) {
          productPlan = PRODUCT_LING_PREMIUM;
        } else {
          if (
            _.includes(subscription.activeSubscription, PRODUCT_TYPE.yearly)
          ) {
            productPlan = PRODUCT_TYPE.yearly;
          } else if (
            _.includes(subscription.activeSubscription, PRODUCT_TYPE.monthly)
          ) {
            productPlan = PRODUCT_TYPE.monthly;
          } else if (
            _.includes(subscription.activeSubscription, PRODUCT_TYPE.lifetime)
          ) {
            productPlan = PRODUCT_TYPE.lifetime;
          }
        }
        stateChangeHandler('subscriptionData', {
          nextPayment: _.includes(
            subscription.activeSubscription,
            PRODUCT_TYPE.lifetime
          )
            ? ''
            : activeSubscription.type === 'android'
            ? "You've subscribed with mobile device."
            : expiryTime,
          productPlan: productPlan,
          type: activeSubscription.type
        });
      }
    } else {
      if (_.get(subscription, 'isPromo', false)) {
        stateChangeHandler('subscriptionData', {
          nextPayment: subscription.promoExpirationDate,
          type: 'promo'
        });
      }
    }
  } else {
    if (_.get(subscription, 'activeSubscription', false)) {
      const subscriptionDetail = _.last(ListOfSubscriptions);
      let activeSubscription = '';
      let purchaseDate = new Date(subscriptionDetail.purchaseTime);
      if (subscriptionDetail.productId.search('yearly') !== -1) {
        activeSubscription = 'yearly';
        purchaseDate.setFullYear(purchaseDate.getFullYear() + 1);
      } else if (subscriptionDetail.productId.search('monthly') !== -1) {
        activeSubscription = 'monthly';
        purchaseDate.setMonth(purchaseDate.getMonth() + 1);
      } else if (subscriptionDetail.productId.search('lifetime') !== -1) {
        activeSubscription = 'lifetime';
      }
      stateChangeHandler('subscriptionData', {
        nextPayment: _.includes(activeSubscription, 'lifetime')
          ? ''
          : purchaseDate.toLocaleDateString(),
        type: 'android'
      });
    } else {
      stateChangeHandler('subscriptionData', {
        type: ''
      });
    }
  }
};

export const getStripeYearlyPrice = (
  functions: string,
  currency: string,
  user: Object,
  stateChangeHandler: Function
) => {
  axios.get(`${functions}validateLingWeb-stripeApi/products/get`).then(res => {
    if (res.data.code !== 400) {
      _.forEach(res.data.plans.data, value => {
        if (
          value.interval === 'year' &&
          value.currency.toUpperCase() ===
            (currency ? currency : 'usd').toUpperCase() &&
          _.includes(
            [value.id],
            getStripeProductId(currency, 'stripe_yearly', user)
          )
        ) {
          stateChangeHandler('yearPrice', value.amount);
        } else {
          if (
            value.interval === 'year' &&
            value.currency.toUpperCase() === CURRENCY.USD
          ) {
            stateChangeHandler('usdYearPrice', value.amount);
          }
        }
      });
    }
  });
};

export const getUserManageSubscriptionText = (
  user: Object,
  subscriptionData: Object,
  currency: string,
  profile_txt_plan: string,
  profile_txt_on: string,
  profile_txt_for: string,
  profile_txt_lifetime_plan: string,
  profile_txt_charged: string,
  profile_txt_of: string,
  shop_txt_checkout_title_monthly: string,
  shop_txt_checkout_title_yearly: string,
  shop_txt_checkout_title_lifetime: string,
  profile_txt_1_year_ling_pro: string,
  profile_txt_1_month_ling_pro: string,
  profile_txt_past_due_msg: string
) => {
  const { subscription } = user;
  let planData;
  const subscriptionStatus = subscriptionData.status;
  planData = getProfilePlanData(subscription, subscriptionData).planText;
  let periodEnd = _.get(subscriptionData, 'current_period_end', false);
  let planName = '';
  let isLifetime = false;
  periodEnd = moment(periodEnd * 1000).format('YYYY.MM.DD');
  let card = "You've subscribed with mobile device.";
  if (subscriptionData.type === STRIPE) {
    const {
      planDataStripe,
      planNameStripe,
      cardStripe,
      isLifetimeStripe
    } = onSetStripePlanName(
      subscriptionData,
      profile_txt_plan,
      profile_txt_lifetime_plan,
      profile_txt_on,
      periodEnd,
      profile_txt_charged,
      profile_txt_for,
      profile_txt_of,
      shop_txt_checkout_title_monthly,
      shop_txt_checkout_title_yearly,
      shop_txt_checkout_title_lifetime,
      profile_txt_1_year_ling_pro,
      profile_txt_1_month_ling_pro,
      profile_txt_past_due_msg
    );
    planData = planDataStripe;
    planName = planNameStripe;
    card = cardStripe;
    isLifetime = isLifetimeStripe;
  } else if (isMobileOrLifetimeSubscription(subscriptionData, subscription)) {
    planName = onSetLifetimePlanName(subscriptionData, subscription).planName;
    isLifetime = onSetLifetimePlanName(subscriptionData, subscription)
      .isLifetime;
  }
  return {
    subscriptionStatus,
    planName,
    periodEnd,
    isLifetime,
    planData,
    card
  };
};

const isMobileOrLifetimeSubscription = (
  subscriptionData: Object,
  subscription: Object
) =>
  subscriptionData.type === 'android' ||
  subscriptionData.type === 'IOS' ||
  (subscription && subscription.isPromo);

const onSetLifetimePlanName = (
  subscriptionData: Object,
  subscription: Object
) => {
  let planName, isLifetime;
  if (_.includes(subscription.activeSubscription, PROMO_LIFETIME)) {
    planName = PRODUCT_LING_PREMIUM;
    isLifetime = true;
  } else {
    planName =
      subscriptionData.productPlan === PRODUCT_PLAN_NAME
        ? subscriptionData.productPlan
        : PRODUCT_LING_PREMIUM +
          ' ' +
          (subscriptionData && subscriptionData.type);
    isLifetime =
      subscriptionData.productPlan === PRODUCT_PLAN_NAME ? true : false;
  }
  return { planName, isLifetime };
};

const onSetStripePlanName = (
  subscriptionData: Object,
  profile_txt_plan: string,
  profile_txt_lifetime_plan: string,
  profile_txt_on: string,
  periodEnd: string,
  profile_txt_charged: string,
  profile_txt_for: string,
  profile_txt_of: string,
  shop_txt_checkout_title_monthly: string,
  shop_txt_checkout_title_yearly: string,
  shop_txt_checkout_title_lifetime: string,
  profile_txt_1_year_ling_pro: string,
  profile_txt_1_month_ling_pro: string,
  profile_txt_past_due_msg: string
) => {
  let planDataStripe, planNameStripe, cardStripe, isLifetimeStripe;
  if (
    subscriptionData.display_items &&
    subscriptionData.display_items[0].type === 'sku'
  ) {
    const payment = subscriptionData.paymentMethod;
    planDataStripe = `${profile_txt_plan} ${profile_txt_lifetime_plan}`;
    planNameStripe = `${profile_txt_lifetime_plan}`;
    cardStripe = `${payment.card.brand} ending in ${payment.card.last4}`;
    isLifetimeStripe = true;
  } else {
    const plan = subscriptionData.plan;
    const payment = subscriptionData.paymentMethod;
    console.log(subscriptionData.plan);
    if (subscriptionData.status === PAST_DUE) {
      planDataStripe = profile_txt_past_due_msg;
    } else {
      planDataStripe = getPlanData(
        profile_txt_on,
        periodEnd,
        profile_txt_charged,
        profile_txt_for,
        plan.currency,
        plan.amount,
        plan.interval,
        profile_txt_1_month_ling_pro,
        profile_txt_1_year_ling_pro
      );
    }
    cardStripe = `${payment.card.brand} ending in ${payment.card.last4}`;
    planNameStripe =
      'Ling ' +
      getCurrentPlanLabel(
        subscriptionData.plan.interval,
        shop_txt_checkout_title_monthly,
        shop_txt_checkout_title_yearly,
        shop_txt_checkout_title_lifetime
      );
    isLifetimeStripe = false;
  }
  return {
    planDataStripe,
    planNameStripe,
    cardStripe,
    isLifetimeStripe
  };
};

const getProfilePlanData = (subscription: Object, subscriptionData: Object) => {
  let planText;
  if (subscription && subscription.isPromo) {
    if (subscription.promoExpirationDate === 'lifetime') {
      planText = `Your subscription for Ling Premium Version is ${subscription.promoExpirationDate}`;
    } else {
      planText = `Your subscription for Ling Premium Version will end on  ${subscription.promoExpirationDate}`;
    }
  } else {
    planText = `Next payment date is ${subscriptionData.nextPayment}`;
  }
  return { planText };
};

const getCurrentPlanLabel = (
  interval: string,
  shop_txt_checkout_title_monthly: string,
  shop_txt_checkout_title_yearly: string,
  shop_txt_checkout_title_lifetime: string
) => {
  const currentPlanLabel = {
    month: shop_txt_checkout_title_monthly,
    year: shop_txt_checkout_title_yearly,
    lifetime: shop_txt_checkout_title_lifetime
  };
  return currentPlanLabel[interval];
};

// Note: calculate to show discounted price and using coupon to give discount at backend
export const getPriceAfterDiscount = (
  originalPrice: number,
  discountPercentage: number
) => {
  if (!_.isNumber(originalPrice)) {
    return originalPrice;
  }
  const discountedAmount = (originalPrice * discountPercentage) / 100;
  const priceAfterDiscount = originalPrice - discountedAmount;
  return _.toNumber(priceAfterDiscount.toFixed(2));
};
