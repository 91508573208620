/* @flow */
import * as React from 'react';
import { Character } from './Character';
import { parseSvg } from '../../../games/util/writingGame/WritingGame';
import { PURE_ORANGE, GRAYISH_BLUE } from '../../../common/constants';
import { DrawingScene } from '../../../components/main-components/writing/DrawingScene';

type Props = {
  coordinates: string,
  isShowGuide: boolean,
  isShowAnimation: boolean,
  onDone: Function,
  onCorrect: Function,
  onFail: Function,
  guideCoordinates: string,
  strokeColor?: string,
  strokeDashoffset: number,
  onAnimationFinished?: Function,
  isLockTouches: boolean,
  gamePlay: number,
  isFailed: boolean
};

type State = {
  isShowCharacter: boolean,
  coordinates: string,
  correctCoordinates: boolean[],
  baseWidth: number,
  baseHeight: number,
  guideCoordinates: string[],
  guideStep: number
};

class WritingCanvas extends React.Component<Props, State> {
  static defaultProps: Props;
  state: Object;
  coordinates: string;
  guideCoordinates: string;
  absoluteCoordinate: Object[];
  drawnCoordinates: string;
  strokeNumber: number;
  _x: number;
  _y: number;
  _yOffset: number;
  _xShift: number;
  _yShift: number;
  _baseWidth: number;
  _baseHeight: number;
  _absWidth: number;
  _absHeight: number;
  _canvas: ?Object;
  _animate: any;
  _drawer: Object;
  _mock: Array<Object>;
  _character: ?Object;
  constructor(props: Props) {
    super(props);
    this.updateCharacters();

    this.state = {
      correctCoordinates: [],
      baseWidth: 0,
      baseHeight: 0,
      guideCoordinate: [],
      coordinates: null,
      isShowCharacter: false,
      characterStyle: { width: 0, height: 0 },
      guideStep: 1,
      strokeNumber: 0
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.coordinates !== this.props.coordinates) {
      this.clearAnswer();
      this.updateCharacters();
      this.setState({
        coordinates: this.props.coordinates
      });
    }
  }

  updateCharacters() {
    this.coordinates = parseSvg(this.props.coordinates);
    this.guideCoordinates = parseSvg(this.props.guideCoordinates);
    this.strokeNumber = this.getStrokeNumber(this.coordinates);
  }

  updateCoordinate(x: number, y: number) {
    this.drawnCoordinates += `${x} ${y},`;
    this.setState(state => ({ ...state }));
  }

  clearCoordinate() {
    this.drawnCoordinates = '';
    this._drawer.clearSketch();
    this.setState(state => ({ ...state }));
  }
  clearAnswer() {
    this.guideCoordinates = '';
    this.coordinates = '';
  }

  pathArray(d: string) {
    const newstringreplaced = d.replace(/M/gi, '|M');
    const pathArray = newstringreplaced.split('|');
    pathArray.shift();
    this.strokeNumber = pathArray.length;
    return pathArray;
  }

  getStrokeNumber(d: string) {
    const newstringreplaced = d.replace(/M/gi, '|M');
    const pathArray = newstringreplaced.split('|');
    pathArray.shift();
    return pathArray.length;
  }

  increaseGuideStep = () => {
    this.setState({
      guideStep: this.state.guideStep + 1
    });
    if (this.strokeNumber === this.state.guideStep) {
      this.updateCharacters();
      setTimeout(() => {
        this.clearAnswer();
        this.props.onAnimationFinished && this.props.onAnimationFinished();
      }, 1000);
    }
  };

  render() {
    const {
      isShowGuide,
      isShowAnimation,
      strokeColor,
      strokeDashoffset,
      onAnimationFinished,
      isLockTouches,
      gamePlay,
      onCorrect,
      onDone,
      onFail,
      isFailed
    } = this.props;
    const { guideStep } = this.state;

    const onAnimationFinishedInCanvas = () => {
      this.updateCharacters();
      this.clearAnswer();
      onAnimationFinished && onAnimationFinished();
    };

    const { canvasStyle, character, guideCharacter } = styles;
    const pathArray = this.pathArray(this.coordinates);

    return (
      <React.Fragment>
        {!isLockTouches ? (
          <DrawingScene
            path={pathArray[guideStep - 1]}
            nextStep={this.increaseGuideStep.bind(this)}
            onCorrect={onCorrect}
            onDone={onDone}
            onFail={onFail}
            strokeNumber={this.strokeNumber}
            guideStep={guideStep}
          />
        ) : null}
        <div
          style={canvasStyle}
          ref={ref => {
            this._canvas = ref;
          }}
        >
          <Character
            style={character}
            guideStep={guideStep}
            paths={pathArray}
            d={this.coordinates}
            strokeColor={strokeColor}
            strokeDashoffset={strokeDashoffset}
            noAnimation={!isShowAnimation}
            onAnimationFinished={onAnimationFinishedInCanvas}
            gamePlay={gamePlay}
            isFailed={isFailed}
          />
          <Character
            style={{ ...character, ...guideCharacter }}
            d={this.coordinates}
            guideStep={guideStep}
            paths={pathArray}
            strokeColor={isShowAnimation ? PURE_ORANGE : 'transparent'}
            strokeDashoffset={strokeDashoffset}
            isShowGuide={isShowGuide}
            noAnimation={!isShowAnimation}
            onAnimationFinished={onAnimationFinishedInCanvas}
            gamePlay={gamePlay}
            isFailed={isFailed}
          />
        </div>
      </React.Fragment>
    );
  }
}

const styles = {
  canvasStyle: {
    borderRadius: 1,
    borderWidth: 1,
    borderColor: GRAYISH_BLUE,
    position: 'relative',
    flex: 1,
    wight: '100%',
    height: '100%'
  },
  character: {
    width: '100%',
    height: '100%'
  },
  guideCharacter: {
    position: 'absolute',
    top: 0,
    left: 0
  }
};

export { WritingCanvas };
