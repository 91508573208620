/* @flow */
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actions from '../../games/actions';
import { ConversationSceneWithRouter } from '../components/scenes/ConversationScene';
import {
  replayChatbot,
  nextChatbot,
  updateChoices,
  updateSelectedChoice,
  finishChatbot,
  toggleChatbotAutoPlay,
  resetChatbotsHistory,
  updateHistories,
  toggleChatbotPlaying
} from '../actions';
import {
  getLabels,
  getLabel,
  playSound,
  playSoundWithCallback
} from '../../util';
import {
  getTranscriptionState,
  getCourseUser,
  getUser
} from '../../profile/selectors';
import {
  getChatbotTopicsInfoData,
  getChatbotGlobalsInfoData,
  getChatbotPharsesInfoData,
  getVoiceSpeed
} from '../selectors';
import {
  toggleTranscript,
  closeCompleteScreen,
  resetSpeakingErrorMsg,
  speakingGameOffline,
  speakingGameNoMatch,
  checkSpeakingAnswer,
  clearSpeakingErrorMsg
} from '../../games/actions';
import { getCurrentCourseData } from '../../sagas/DataSelector';

export const mapStateToProps = (state: Object, ownProps: Object) => {
  const voiceSpeed = getVoiceSpeed(state);

  const {
    routes: { showCompleteScreen },
    chatbots: {
      chatHistories,
      choices,
      currentChat,
      autoplay,
      progressMax,
      remainChatbots,
      selectedChoice,
      playing
    },
    dialog: { translate: translationState },
    data: { targetLangCode },
    speaking,
    courses,
    storage
  } = state;
  const { isTargetLangSymbol } = storage;
  const { speakingErrorMsg } = speaking;
  const { features } = getCurrentCourseData(state);
  const { isBot } = currentChat;
  const { currentChatbotId } = getCourseUser(state);
  const chatbotsInfo = getChatbotTopicsInfoData(state);
  const chatbotLabel = _.get(chatbotsInfo, `${currentChatbotId}.label`);
  const chatbotGlobals = getChatbotGlobalsInfoData(state);
  const PhrasesInfo = getChatbotPharsesInfoData(state);
  const coursesData = _.get(courses, courses.activeLangCode);

  return {
    ...getLabels(state, 'btn_continue'),
    isTargetLangSymbol,
    voiceSpeed,
    coursesData,
    speakingErrorMsg,
    targetLangCode,
    currentChatbotId,
    choices,
    chatHistories,
    currentChat,
    chatbotGlobals,
    PhrasesInfo,
    showCompleteScreen,
    isBot,
    selectedChoice,
    playing,
    translationState,
    autoplay: autoplay,
    progress: progressMax - remainChatbots.length,
    progressMax: progressMax,
    transcriptState: getTranscriptionState(state),
    translateState: translationState,
    lang: features,
    chatLabel: getLabel(state, chatbotLabel, chatbotLabel),
    ...getUser(state),
    state,
    ...getLabels(state, 'chatbot_txt_complete_header')
  };
};

export const mapDispatchToProps = (dispatch: Function) => ({
  toggleTranscript: () => {
    dispatch(toggleTranscript());
  },
  toggleChatbotAutoPlay: () => dispatch(toggleChatbotAutoPlay()),
  onTranslate() {
    dispatch(actions.dialogTranslate());
  },
  toggleChatbotPlaying: (isPlaying: boolean) =>
    dispatch(toggleChatbotPlaying(isPlaying)),
  clearSpeakingErrorMsg: () => dispatch(clearSpeakingErrorMsg()),
  noMatch: () => dispatch(speakingGameNoMatch()),
  onCloseModal: () => dispatch(resetSpeakingErrorMsg()),
  networkError: () => dispatch(speakingGameOffline()),
  resetChatbotsHistory: () => dispatch(resetChatbotsHistory()),
  finishChatbot: () => dispatch(finishChatbot()),
  replayChatbot: () => dispatch(replayChatbot()),
  nextChatbot: (isAnswer: boolean) => dispatch(nextChatbot(isAnswer)),
  nextChatbotVoice: (choice: Object) => dispatch(nextChatbot(false, choice)),
  updateChoices: (firstChoice: Object) => dispatch(updateChoices(firstChoice)),
  updateSelectedChoice: (choiceId: string) =>
    dispatch(updateSelectedChoice(choiceId)),
  updateHistories: (chat: Object, callback: Function) =>
    dispatch(updateHistories(false, chat, true, callback)),
  toggleGameCompleteScreen: () => dispatch(closeCompleteScreen()),
  checkSpeakingAnswer: (answerNum: number) =>
    dispatch(checkSpeakingAnswer(answerNum)),
  playSound,
  playSoundWithCallback
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationSceneWithRouter);
