/* @flow */
import _ from 'lodash';
import { splitByCharAt, split } from '../util/CharacterSplitter';
import bugtracker from './BugTracker';
import { spellingStatus } from '../common/constants';

//START Process Result//
export const evaluate = (
  props: Object,
  recognized: string,
  successCallback: Function,
  failCallback: Function
) => {
  const {
    writtenNumber,
    writtenNumberRegex,
    isWord,
    original: rawSpokenPhrase,
    onCheckAnswer,
    targetLanguage,
    checkSpeakingAnswer
  } = props;
  if (_.isNil(recognized)) {
    throw new Error('Recognized must be defined!');
  }
  let spokenPhrase = '';
  let recognizedPhrases = '';

  if (_.isNumber(recognized)) {
    const result = preparePhrases(
      rawSpokenPhrase,
      _.get(writtenNumber, recognized, recognized)
    );
    spokenPhrase = result.spokenPhrase;
    recognizedPhrases = result.recognizedPhrases;
  } else {
    const result = preparePhrases(rawSpokenPhrase, recognized);

    spokenPhrase = result.spokenPhrase;
    recognizedPhrases = result.recognizedPhrases;
  }

  const { answer, bestNumberErrors } = getAnswer(
    spokenPhrase,
    recognizedPhrases,
    writtenNumberRegex,
    writtenNumber,
    isWord
  );

  checkSpeakingAnswer(bestNumberErrors);
  console.log('rawSpokenPhrase', rawSpokenPhrase);
  console.log('spokenPhrase', spokenPhrase);
  console.log('recognizedPhrases', recognizedPhrases);
  console.log('answer', answer);

  const correctAnswer = getCorrectAnswer(
    rawSpokenPhrase,
    answer,
    targetLanguage
  );

  evaluateAnswer(
    bestNumberErrors,
    correctAnswer,
    spokenPhrase,
    successCallback,
    failCallback,
    onCheckAnswer,
    recognized
  );
};

export const evaluateChatbot = (
  props: Object,
  recognized: string,
  successCallback: Function,
  failCallback: Function
) => {
  const { writtenNumber, original: rawSpokenPhrase } = props;
  if (_.isNil(recognized)) {
    throw new Error('Recognized must be defined!');
  }
  let recognizedPhrases = '';

  if (_.isNumber(recognized)) {
    const result = preparePhrases(
      rawSpokenPhrase,
      _.get(writtenNumber, recognized, recognized)
    );
    recognizedPhrases = result.recognizedPhrases;
  } else {
    const result = preparePhrases(rawSpokenPhrase, recognized);
    recognizedPhrases = result.recognizedPhrases;
  }
  const { choices } = props;

  let scores = [];
  let index = 0;

  for (const choice of choices) {
    const template = {
      answer: {},
      percentile: 0
    };

    scores.push(template);
    const { original } = choice;
    const originalPhrase = removeSpecialCharsAndSpaces(original);
    let bestNumberErrors = originalPhrase.length;
    let bestDiffLength = originalPhrase.length;
    const { numberErrors } = getMatches(originalPhrase, recognizedPhrases);

    const diffLength = Math.abs(
      originalPhrase.length - recognizedPhrases.length
    );

    if (numberErrors <= bestNumberErrors && diffLength <= bestDiffLength) {
      bestNumberErrors = numberErrors;
      bestDiffLength = diffLength;
      scores[index].answer = choice;

      scores[index].percentile = Math.round(
        Math.abs(
          ((bestNumberErrors + bestDiffLength) / originalPhrase.length) * 100 -
            100
        )
      );
    }
    // }
    index++;
  }

  const finalAnswer = scores.reduce(
    (bestScore: Object, score) => {
      return score.percentile > bestScore.percentile ? score : bestScore;
    },
    {
      percentile: 0,
      answer: ''
    }
  );

  if (finalAnswer.percentile >= 66) {
    successCallback(finalAnswer.answer);
  } else {
    failCallback(finalAnswer.answer, finalAnswer.percentile);
  }
};
//End Process Result//

const preparePhrases = (rawSpokenPhrase: string, recognized: string) => {
  return {
    spokenPhrase: removeSpecialCharsAndSpaces(rawSpokenPhrase).toUpperCase(),
    recognizedPhrases: removeSpecialCharsAndSpaces(recognized)
  };
};

export const removeSpecialCharsAndSpaces = (input: string) =>
  _.replace(input, /[()。,?.!！？។ ]/g, '');

const getAnswer = (
  spokenPhrase: string,
  recognizedPhrases: string,
  writtenNumberRegex: string,
  writtenNumber: Object,
  isWord: boolean
) => {
  let bestNumberErrors = spokenPhrase.length;
  let answer = [];

  const convertedRecognizedPhrase = convertWord(
    writtenNumberRegex,
    writtenNumber,
    recognizedPhrases,
    isWord,
    spokenPhrase
  );
  const { numberErrors, matches } = getMatches(
    convertedRecognizedPhrase,
    spokenPhrase
  );
  if (numberErrors <= bestNumberErrors) {
    bestNumberErrors = numberErrors;
    answer = matches;
  }
  console.log({
    answer,
    bestNumberErrors
  });
  return { answer, bestNumberErrors };
};

const EXCEPTION_LANGUAGE = ['km', 'my'];

const getCorrectAnswer = (
  rawSpokenPhrase: string,
  answer: Object[],
  targetLanguage: string
) => {
  let answerIndex = 0;
  const splitWord = EXCEPTION_LANGUAGE.includes(targetLanguage)
    ? split(rawSpokenPhrase)
    : splitByCharAt(rawSpokenPhrase);
  return _.reduce(
    splitWord,
    (result: Object[], character: string, index: number) => {
      const answerCharacter: ?Object = _.find(answer, ['index', answerIndex]);
      character !== ' ' && answerIndex++;
      let status;
      if (character !== ' ' && !(answerCharacter && answerCharacter.correct)) {
        status = spellingStatus.WRONG;
      } else {
        status = spellingStatus.CORRECT;
      }

      return [
        ...result,
        {
          character,
          status
        }
      ];
    },
    []
  );
};

const evaluateAnswer = (
  bestNumberErrors: number,
  correctAnswer: Object[],
  spokenPhrase: string,
  successCallback: Function,
  failCallback: Function,
  onCheckAnswer: Function,
  recognized: string
) => {
  if (bestNumberErrors === 0) {
    successCallback(correctAnswer);
  } else {
    const percentile = Math.round(
      Math.abs((bestNumberErrors / spokenPhrase.length) * 100 - 100)
    );
    failCallback(correctAnswer, percentile);
  }
  if (onCheckAnswer) {
    onCheckAnswer(recognized);
  }
};

const convertWord = (
  writtenNumberRegex,
  writtenNumber,
  word,
  isWord,
  spokenPhrase
) => {
  if (isWord) {
    let proveValid = null;
    const customRegex = `(${writtenNumberRegex}){${word.length}}`;
    const regexNumber = new RegExp(customRegex);
    proveValid = (regexNumber.exec(word): ?(any[]));
    console.log('isNumber ?', proveValid);
    console.log('writtenNumber ?', writtenNumber);
    const numberValid =
      proveValid && proveValid[0] && writtenNumber[proveValid[0]]
        ? writtenNumber[proveValid[0]]
        : '';
    if (proveValid) {
      return word.replace(`${proveValid[0]}`, numberValid);
    }
  } else {
    // Get list of synonyms from the question (either matches with key or value).
    const lowerCaseSpokenPhrase = spokenPhrase.toLowerCase();
    const synonymWord = _.pickBy(
      writtenNumber,
      (value: string, key: string) => {
        return (
          lowerCaseSpokenPhrase.includes(value.toString().toLowerCase()) ||
          lowerCaseSpokenPhrase.includes(key.toString().toLowerCase())
        );
      }
    );
    console.log('synonym word:', synonymWord);

    // Loop through list of synonyms and change word from recognize to same as question.
    if (synonymWord) {
      _.forEachRight(synonymWord, (value: string, key: string) => {
        // check if it matches with key, then replace key with value. otherwise, replace value with key.
        if (lowerCaseSpokenPhrase.includes(value.toString().toLowerCase())) {
          word = word.replace(key, value);
        } else {
          word = word.replace(value, key);
        }
      });
    }
  }
  return word;
};

const removeMinus = (removing: string) => _.replace(removing, /[-]/g, '');

export const getMatches = (recognizedPhrase: string, spokenPhrase: string) => {
  const matches = [];
  let numberErrors = 0;
  const upperRecognizedPhrase = recognizedPhrase.toLowerCase();
  const spokenPhraseRemovedMinus = removeMinus(spokenPhrase).toLowerCase();
  try {
    let match;
    let numberMatches = 0;
    const spokenPhraseRegex = new RegExp(`[${spokenPhraseRemovedMinus}]`, 'g');
    while ((match = (spokenPhraseRegex.exec(upperRecognizedPhrase): ?Object))) {
      if (match[0] === spokenPhraseRemovedMinus[match.index]) {
        match.correct = true;
        numberMatches++;
      } else {
        match.correct = false;
      }
      matches.push(match);
    }
    numberErrors = Math.abs(numberMatches - spokenPhraseRemovedMinus.length);
  } catch (e) {
    console.warn('Regex Error', e);
    bugtracker.notify(e);
  }
  return {
    numberErrors,
    matches
  };
};
