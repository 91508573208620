/* @flow */
import React from 'react';
import styled from 'styled-components';
import {
  PREFIX_LESSON,
  MARIGOLD,
  WHITE,
  BLACK,
  DARK_GREY
} from '../../../common/constants';

const magicImage = `${PREFIX_LESSON}outline-white-magic-icon.svg`;

const HintButtonContainer = styled.button`
  width: 160px;
  height: 55px;
  border-radius: 10px;
  border: solid 2px ${BLACK};
  background-color: ${WHITE};
  justify-content: center;
  display: flex;
  align-items: center;
  box-shadow: inset 0 -4px 0 0 #000000;
  background-color: ${DARK_GREY};

  &:hover:enabled {
    box-shadow: none;
    opacity: 0.4;
    background-color: ${BLACK};
  }
  &:active:enabled {
    box-shadow: none;
    opacity: 1;
    background-color: ${MARIGOLD};
    border-color: ${MARIGOLD};
    background: ${WHITE};
  }
  &:disabled {
    box-shadow: none;
    opacity: 1;
    background-color: ${MARIGOLD};
    border-color: ${MARIGOLD};
    background: ${WHITE};
  }
`;

const HintButtonIconContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: ${MARIGOLD};
  justify-content: center;
  display: flex;
  align-items: center;
`;

const HintButtonIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const HintButtonText = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-left: 12px;
  color: ${MARIGOLD};
`;

type Props = {
  onPress: () => void,
  disabled?: boolean,
  btn_hint?: string,
  hintMode?: boolean,
  hintButtonEnabled: boolean,
  coins: number
};

const HintButton = ({
  onPress,
  btn_hint,
  disabled,
  hintMode,
  hintButtonEnabled,
  coins
}: Props) => {
  return (
    <HintButtonContainer
      disabled={hintMode || !hintButtonEnabled}
      onClick={onPress}
    >
      <HintButtonIconContainer>
        <HintButtonIcon src={magicImage} />
      </HintButtonIconContainer>
      <HintButtonText>{coins}</HintButtonText>
    </HintButtonContainer>
  );
};

export { HintButton };
