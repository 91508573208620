/* @flow */
import React, { Fragment } from 'react';
import styled from 'styled-components';
import {
  PREFIX_HEADER,
  MARIGOLD,
  LIGHT_GREY,
  WHITE,
  VERY_LIGHT_PINK,
  PREFIX_EXAM,
  GREYISH_BROWN,
  BLACK,
  BROWN_GREY_TWO,
  SLASH_SIGN
} from '../../../common/constants';
import GameScoreContainer from '../../../games/containers/GameScoreContainer';
import { LingProgressBar } from '../../../components/sub-components/LingProgressBar';
import { BAR_HEIGHT, BAR_EXAM_HEIGHT } from '../../constants';

const FILLED_HEART = `${PREFIX_EXAM}basecolor-fill-heart-25-px.svg`;
const BLANK_HEART = `${PREFIX_EXAM}basecolor-heart-25-px.svg`;

const GameHeaderContainer = styled.div`
  display: ${props =>
    props.isShowTutorial || props.showTutorial ? 'none' : 'block'};
  position: fixed;
  top: 0;
  background: ${WHITE};
  z-index: 99;
`;

const Container = styled.div`
  width: 100vw;
  height: 70px;
  margin-top: 20px;
  border-bottom: 2px solid ${LIGHT_GREY};
`;

const HeaderContainer = styled.div`
  max-width: 1024px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
`;

const CloseArea = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 13px;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const Image = styled.img`
  width: 25px;
  height: 26px;
`;

const BarContainer = styled.div`
  width: 100%;
  margin: 0 15px;
`;

const BarExamContainer = styled.div`
  width: 100%;
  margin: 0 15px;
  border-radius: 10px;
  background-color: ${GREYISH_BROWN};
  max-height: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  padding: 0 15px;
`;

const HeartContainer = styled.div`
  min-width: 161px;
  max-width: 161px;
  border-radius: 10px;
  background-color: ${VERY_LIGHT_PINK};
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 5px;
  max-height: 35px;
  min-height: 35px;
`;

const HeartImg = styled.img`
  width: 24px;
  height: 20px;
`;

const ProgressText = styled.p`
  font-size: 22px;
  font-weight: bold;
  color: ${WHITE};
  margin: 0 !important;
`;

const ProgressMaxText = styled.p`
  font-size: 16px;
  color: ${BROWN_GREY_TWO};
  margin: 0 !important;
  margin-right: 15px !important;
`;

type Props = {
  progress: number,
  hasTranscript: boolean,
  progressMax: number,
  onBack: Function,
  isExam: boolean,
  heartCount: number,
  isRandomTest?: boolean,
  isShowTutorial: boolean,
  showTutorial: boolean
};

const GameHeader = ({
  progress = 0,
  hasTranscript = true,
  isRandomTest = false,
  progressMax = 100,
  onBack,
  isExam,
  heartCount = 3,
  isShowTutorial,
  showTutorial
}: Props) => {
  const isGameHeader = true;
  return (
    <GameHeaderContainer
      isShowTutorial={isShowTutorial}
      showTutorial={showTutorial}
    >
      <Container>
        <HeaderContainer>
          <CloseArea onClick={onBack}>
            <Image src={`${PREFIX_HEADER}close-icon.svg`} />
          </CloseArea>
          {isExam || isRandomTest ? (
            <Fragment>
              <BarExamContainer>
                <ProgressText>{progress}&nbsp;</ProgressText>
                <ProgressMaxText>
                  {SLASH_SIGN}&nbsp;{progressMax}
                </ProgressMaxText>
                <LingProgressBar
                  backgroundColor={BLACK}
                  color={MARIGOLD}
                  progress={progress}
                  progressMax={progressMax}
                  barHeight={BAR_EXAM_HEIGHT}
                />
              </BarExamContainer>
              {!isRandomTest && (
                <HeartContainer>
                  <HeartImg
                    src={heartCount >= 0 ? FILLED_HEART : BLANK_HEART}
                  />
                  <HeartImg
                    src={heartCount >= 1 ? FILLED_HEART : BLANK_HEART}
                  />
                  <HeartImg
                    src={heartCount >= 2 ? FILLED_HEART : BLANK_HEART}
                  />
                  <HeartImg
                    src={heartCount >= 3 ? FILLED_HEART : BLANK_HEART}
                  />
                </HeartContainer>
              )}
            </Fragment>
          ) : (
            <BarContainer>
              <LingProgressBar
                backgroundColor={LIGHT_GREY}
                color={MARIGOLD}
                progress={progress}
                progressMax={progressMax}
                barHeight={BAR_HEIGHT}
              />
            </BarContainer>
          )}
          <GameScoreContainer
            hasTranscript={hasTranscript}
            isGameHeader={isGameHeader}
            isExam={isExam}
          />
        </HeaderContainer>
      </Container>
    </GameHeaderContainer>
  );
};

export { GameHeader };
