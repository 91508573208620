/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import { PREFIX_QUIZ, quizStatus } from '../../../common/constants';
import {
  DIALOGUE_GAME,
  FLASHCARD,
  ORDER_SENTENCE_REVERSE,
  ORDER_SENTENCE
} from '../../constants';

const ResultTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ResultIcon = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
`;

type Props = { quizAnswer: Object, answeredWrong: Object, gameType: string };

const ResultText = ({
  quizAnswer = {},
  answeredWrong = {},
  gameType
}: Props) => {
  const { status } = quizAnswer;
  const { NO_MATCH } = quizStatus;

  return (
    <ResultTextContainer>
      {gameType === '' ? null : (
        <ResultIcon
          status={status}
          answeredWrong={answeredWrong}
          gameType={gameType}
        >
          {(status ||
            gameType !== FLASHCARD ||
            gameType === ORDER_SENTENCE_REVERSE ||
            gameType === ORDER_SENTENCE ||
            gameType === DIALOGUE_GAME) && (
            <img
              src={
                `${PREFIX_QUIZ}` +
                (status === NO_MATCH || answeredWrong === true
                  ? 'icon-wrong-red.svg'
                  : 'icon-correct-green.svg')
              }
              alt={
                `${PREFIX_QUIZ}` +
                (status === NO_MATCH || answeredWrong === true
                  ? 'icon-wrong-red.svg'
                  : 'icon-correct-green.svg')
              }
            />
          )}
        </ResultIcon>
      )}
      {/* {status === NO_MATCH && <ResultTextStyle>{status}</ResultTextStyle>} */}
    </ResultTextContainer>
  );
};

export { ResultText };
