import { AnyAction } from 'redux';
import RemoteConfigService from '../services/remoteConfig';
import * as ConfigFilters from './constants';
import RemoveConfigService from '../services/remoteConfig';

export const startRemoteConfig = () => dispatch => {
  dispatch(startInit());
  RemoveConfigService.initService()
    .then(() => {
      dispatch(initRemoteConfig());
      dispatch(fetchRemoteConfig());
    })
    .catch(error => console.error(error));
};

export const fetchRemoteConfig = () => dispatch => {
  dispatch(requestRemoteData());
  RemoteConfigService.fetchConfig()
    .then(data => {
      dispatch(setRemoteConfig(data));
    })
    .catch(error => {
      console.error(error);
    });
};

export function startInit(): AnyAction {
  return { type: ConfigFilters.startInit };
}

export function initRemoteConfig(): AnyAction {
  return { type: ConfigFilters.initConfig };
}

export function setRemoteConfig(data): AnyAction {
  return { type: ConfigFilters.dataUpdate, data };
}

export function completeDataRequest(): AnyAction {
  return { type: ConfigFilters.dataComplete };
}

export function requestRemoteData(): AnyAction {
  return { type: ConfigFilters.dataRequest };
}
