/* @flow */
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  leftCard: React.Element<any>,
  rightCard: React.Element<any>
};

const CardLayout = styled.div`
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: flex;
`;

function createCard(card: React.Element<any>) {
  return React.cloneElement(card, {
    style: [card.props.style]
  });
}

function CardColumn({ leftCard, rightCard }: Props) {
  return (
    <CardLayout>
      {createCard(leftCard)}
      {createCard(rightCard)}
    </CardLayout>
  );
}

export { CardColumn };
