/* @flow */

import axios from 'axios';
import { NOLANG } from '../common/constants';
import bugtracker from '../util/BugTracker';
import Analytic from '../util/Analytic';
import moment from 'moment';

import {
  PROVIDER_APPLE,
  PROVIDER_EMAIL,
  PROVIDER_FACEBOOK,
  PROVIDER_GOOGLE
} from '../profile/constants';
import _ from 'lodash';
import { updateUserData } from '../games/actions';
import { getLocalStorage, updateUserPromotion } from '.';

export const addToSubscribeNewsList = () => (
  dispatch: Function,
  getState: Function
) => {
  // eslint-disable-next-line no-undef
  const functions = process.env.REACT_APP_FUNCTIONS || '';
  const state = getState();
  const { storage } = state;
  const { user } = storage;
  const {
    facebook = false,
    google = false,
    email = false,
    apple = false
  } = user;
  Analytic.updateExpolinguaUser(true);

  Analytic.logGA(Analytic.key.A7);
  Analytic.log(Analytic.key.A7);
  let outputUser = {};
  if (facebook) {
    outputUser = createUser(user, PROVIDER_FACEBOOK);
  } else if (google) {
    outputUser = createUser(user, PROVIDER_GOOGLE);
  } else if (email) {
    outputUser = createUser(user, PROVIDER_EMAIL);
  } else if (apple) {
    outputUser = createUser(user, PROVIDER_APPLE);
  }
  outputUser.UID = user.uid;
  outputUser.nativeLangCode = user.nativeLangCode || NOLANG;
  outputUser.subscribed =
    user.isProUser || user.subscribed || user.lifetime || false;
  outputUser.targetLangCode = user.targetLangCode
    ? user.targetLangCode
    : NOLANG;
  // if (localStorage.getItem('isExpolingua') === 'true') {

  const info = {
    ...outputUser,
    'Full Name': _.isUndefined(outputUser.LastName)
      ? outputUser.FirstName
      : outputUser.FirstName + outputUser.LastName,
    'First Name': outputUser.FirstName,
    'Last Name': _.isUndefined(outputUser.LastName) ? ' ' : outputUser.LastName,
    'Contact Email': outputUser.email_address,
    Note: getLocalStorage('isExpolingua') === 'true' ? 'expolingua' : 'ling'
  };

  axios
    .post(`${functions}/zoho-init/confirmEmail/`, { info: info })
    .then(response => {
      return response;
    })
    .catch(error => {
      bugtracker.notify(error);
      console.log('error:', error);
    });
  if (getLocalStorage('isExpolingua') === 'true') {
    dispatch(updateUserPromotion(functions, user));
    dispatch(
      updateUserData({
        isExpolingua: true,
        isSubscribeNews: true,
        subscription: {
          isPromo: true,
          promoExpirationDate: moment(Date.now() + 86400000 * 14).format(
            'YYYY-MM-DD'
          )
        }
      })
    );
  }
  // eslint-disable-next-line no-undef
  localStorage.removeItem('isExpolingua');
};

const createUser = (user, provider) => {
  console.log('Provider is', { provider, user });

  const output = {};
  let fullname = '';
  let name = '';
  if (user.name) {
    fullname = _.get(user, 'name', '');
    name = _.split(fullname, ' ', 2);
  } else {
    fullname = _.get(user, `${provider}.name`, '');
    name = _.split(fullname, ' ', 2);
  }
  output.FullName = fullname;
  output.name = fullname;
  if (name.length === 2) {
    output.FirstName = name[0];
    output.LastName = name[1];
  } else {
    output.FirstName = name[0];
  }
  output.email_address = _.get(user, `${provider}.email`, '');
  return output;
};
