/* @flow */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import { LessonCardsWrapper } from '../main-components/LessonCardsWrapper';
import { ExamCard, GameCard } from '../sub-components';
import Analytic from '../../../util/Analytic';
import RepairedUnitModalContainer from '../../containers/RepairedUnitModalContainer';
import PreHeader from '../main-components/PreHeader';
import {
  PREFIX_LESSON,
  VERY_LIGHT_PINK,
  MARIGOLD,
  LIGHT_GREY_TRANSCRIPT_2,
  WHITE
} from '../../../common/constants';

const GameContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 17px;
  margin-top: 17px;
`;

const ReviewContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: ${VERY_LIGHT_PINK};
  border-radius: 15px;
  width: 720px;
  height: 165px;
  margin: auto;
  margin-bottom: 52px;
  position: relative;
`;

const LessonContainerStyle = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: ${VERY_LIGHT_PINK};
  border-radius: 15px;
  width: 720px;
  height: 720px;
  margin: auto;
  margin-bottom: 52px;
  position: relative;
`;

const SpeakingContainerStyle = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: ${VERY_LIGHT_PINK};
  border-radius: 15px;
  width: 720px;
  height: 420px;
  margin: auto;
  margin-bottom: 52px;
  position: relative;
`;

const ExamContainerStyle = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: ${VERY_LIGHT_PINK};
  border-radius: 15px;
  width: 720px;
  height: 395px;
  margin: auto;
  margin-bottom: 52px;
  position: relative;
`;

const ReviewButton = styled.button`
  width: 188px;
  height: 51px;
  border-radius: 10px;
  box-shadow: inset 0 -4px 0 0 #f7b500;
  background: ${MARIGOLD};
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  font-size: 16px;
  color: ${WHITE};
  &.review {
    color: ${LIGHT_GREY_TRANSCRIPT_2};
  }
  &:hover {
    cursor: pointer;
    opacity: 0.4;
  }
`;

const ReviewContentContainer = styled.div`
  position: absolute;
  top: -50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HeaderIconContainer = styled.div`
  width: 110px;
  height: 110px;
  box-sizing: border-box !important;
  border: solid ${WHITE} 10px;
  border-radius: 50%;
  background: ${VERY_LIGHT_PINK};
  margin-bottom: 20px;
  position: relative;
`;
const IconContainer = styled.div`
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
  width: 82px;
  height: 82px;
  background: white;
  border-radius: 50%;
`;
const IconImg = styled.img`
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
`;

type Props = {
  lessons: any[],
  startGame: Function,
  startExam: Function,
  startWriting: Function,
  unitId: number,
  progress: Object,
  lesson_txt_writing: string,
  lesson_txt_exam: string,
  lesson_txt_speaking: string,
  hasSpeaking: string,
  hasWriting: string,
  overView_txt_viewAll: string,
  title: string,
  image: string,
  history: Object,
  data: Object,
  loadLanguageData: Function,
  updateUserProfile: Function,
  isUnitLocked: Function,
  hasLangCode: boolean,
  startSpeaking: Function,
  exitChatbot: Function,
  targetLangCode: string,
  nativeLangCode: string,
  lesson_txt_play: string
};

// NOTE: Commented out as instructed in https://trello.com/c/FgFNISro/
// TODO: Uncomment when features ready

class LessonsScene extends Component<Props> {
  topRef: Object;
  constructor(props: Props) {
    super(props);
    this.topRef = React.createRef();
  }

  UNSAFE_componentWillMount() {
    this.props.updateUserProfile({ lastUserUnitId: this.props.unitId });
  }

  componentDidMount() {
    const {
      loadLanguageData,
      data,
      unitId,
      exitChatbot,
      updateUserProfile
    } = this.props;
    window.scrollTo(0, this.topRef.offsetTop);
    exitChatbot();
    updateUserProfile({ currentUnitId: this.props.unitId });

    Analytic.log(Analytic.key.U2, {
      category: Analytic.key.U2,
      action: 'Check',
      label: unitId.toString()
    });
    Analytic.logGA(Analytic.key.U2, {
      category: Analytic.key.U2,
      action: 'Check',
      label: unitId.toString()
    });

    if (!_.isEmpty(data)) {
      loadLanguageData(data, unitId);
    }
  }

  goToReview() {
    this.props.history.push('/review');
  }

  render() {
    const {
      lessons = [],
      startGame,
      startExam,
      startWriting,
      unitId,
      progress = {},
      lesson_txt_writing,
      lesson_txt_exam,
      hasSpeaking,
      hasWriting,
      lesson_txt_speaking,
      overView_txt_viewAll,
      title,
      image,
      history,
      startSpeaking,
      isUnitLocked,
      lesson_txt_play
    } = this.props;
    const speakingCrowns = progress[5] ? progress[5].crowns : 0;
    const writingCrowns = progress[6] ? progress[6].crowns : 0;
    const examCrowns = progress[7] ? progress[7].crowns : 0;
    return (
      <Fragment>
        <PreHeader title={title} history={history} ref={this.topRef} />
        <ReviewContainer>
          <ReviewContentContainer>
            <HeaderIconContainer>
              <IconContainer>
                <IconImg
                  src={`${PREFIX_LESSON}book-magnifying-glass.svg`}
                  alt="book-magnifying-glass"
                />
              </IconContainer>
            </HeaderIconContainer>
            <ReviewButton
              className="review"
              onClick={() => {
                this.goToReview();
              }}
              title={overView_txt_viewAll}
            >
              {overView_txt_viewAll}
            </ReviewButton>
          </ReviewContentContainer>
        </ReviewContainer>
        <LessonContainerStyle>
          <ReviewContentContainer>
            <HeaderIconContainer>
              <IconContainer>
                <IconImg
                  src={`${PREFIX_LESSON}blackboard.svg`}
                  alt="blackboard"
                />
              </IconContainer>
            </HeaderIconContainer>
            <div>
              <LessonCardsWrapper
                lessons={lessons}
                startGame={startGame}
                unitId={unitId}
                progress={progress}
                history={history}
                isUnitLocked={isUnitLocked}
              />
            </div>
          </ReviewContentContainer>
        </LessonContainerStyle>

        {(hasSpeaking || hasWriting) && (
          <SpeakingContainerStyle>
            <ReviewContentContainer>
              <HeaderIconContainer>
                <IconContainer>
                  <IconImg
                    src={`${PREFIX_LESSON}game-controller-1.svg`}
                    alt="game-controller"
                  />
                </IconContainer>
              </HeaderIconContainer>
              <GameContainer>
                {hasSpeaking && (
                  <GameCard
                    coins={speakingCrowns}
                    imgSrc={`${PREFIX_LESSON}microphone.svg`}
                    title={lesson_txt_speaking}
                    onPress={async () => {
                      startSpeaking(unitId);
                      history.push(`/unit/${unitId}/speaking`);
                    }}
                    lesson_txt_play={lesson_txt_play}
                  />
                )}
                {hasWriting && (
                  <GameCard
                    coins={writingCrowns}
                    imgSrc={`${PREFIX_LESSON}edit.svg`}
                    title={lesson_txt_writing}
                    onPress={async () => {
                      startWriting(unitId);
                      history.push(`/unit/${unitId}/writing`);
                    }}
                    lesson_txt_play={lesson_txt_play}
                  />
                )}
              </GameContainer>
            </ReviewContentContainer>
          </SpeakingContainerStyle>
        )}

        <ExamContainerStyle>
          <ReviewContentContainer>
            <HeaderIconContainer>
              <IconContainer>
                <IconImg
                  src={`${PREFIX_LESSON}goldcrown.svg`}
                  alt="goldcrown"
                />
              </IconContainer>
            </HeaderIconContainer>
            <ExamCard
              crowns={examCrowns}
              title={lesson_txt_exam}
              image={image}
              startExam={startExam}
              unitId={unitId}
              history={history}
              lesson_txt_play={lesson_txt_play}
            />
          </ReviewContentContainer>
        </ExamContainerStyle>
        <RepairedUnitModalContainer />
      </Fragment>
    );
  }
}

export const LessonsSceneWithRouter = withRouter(LessonsScene);
