/* @flow */
import React from 'react';
import { PREFIX_HEADER } from '../../../common/constants';
import './../SettingScene.css';
import { DeleteAccountModal } from '../../sub-components/DeleteAccountModal';
import { ManageSubscriptionRightSection } from '../../sub-components';
import { ManageSubscriptionLeftSection } from '../../sub-components/ManageSubscriptionLeftSection/ManageSubscriptionLeftSection';
import { PurchaseLoginModal } from '../../../shop/components/main-components/PurchaseLoginModal';
import {
  Title,
  Image,
  BackArea,
  SettingContainer
} from './ManageSubscriptionSection.styles';
type Props = {
  btn_submit: string,
  cancel_subscription_success_txt: string,
  cancel_subscription_txt_profile: string,
  card: string,
  card_error_empty: string,
  card_error_invalid_cvc: string,
  card_error_invalid_date: string,
  card_error_number_not_correct: string,
  card_expiration: string,
  card_number: string,
  createAccount_txt_title: string,
  currency: string,
  email_error_empty: string,
  email_error_wrongFormat: string,
  footer_txt_profile: string,
  functions: string,
  history: Object,
  isCheckout: boolean,
  isDeleteAcc: boolean,
  isDeleteSuccess: boolean,
  isLifetime: boolean,
  isLoading?: boolean,
  isLoginPurchase: boolean,
  isProUser: boolean,
  isUpdateMethod: boolean,
  isUpdatingCard: boolean,
  location: Object,
  loginPurchase_txt: string,
  login_field_name: string,
  logout_txt: string,
  manageSceneStateChangeHandler: Function,
  modal_txt_cancel_sub_button: string,
  modal_txt_cancel_sub_desc: string,
  modal_txt_not_cancel_sub_button: string,
  name: string,
  onClick: Function,
  onReactivateSubscription: Function,
  photoURL: string,
  profile_txt_cancel_comfirm: string,
  profile_txt_cancel_denies: string,
  profile_txt_cancel_subscription: string,
  profile_txt_cancel_subscription_des: string,
  profile_txt_card_number: string,
  profile_txt_current_plan: string,
  profile_txt_expire_date: string,
  profile_txt_name_card: string,
  profile_txt_next_payment: string,
  profile_txt_on: string,
  profile_txt_payment_method: string,
  profile_txt_reactivate: string,
  profile_txt_subscription_expire: string,
  profile_txt_success_dec: string,
  profile_txt_success_title: string,
  profile_txt_visa_expire: string,
  purchase_button_subscribe_now: string,
  purchase_txt_benefit_details_01: string,
  random_test_modal_back_txt: string,
  random_test_modal_confirm_txt: string,
  setting_txt_settingHeader: string,
  shop_txt_7_day_trial: string,
  signOut: Function,
  subscriptionData: Object,
  toggleCreateAccount: Function,
  update_subscription_methob_txt_profile: string,
  user: Object,
  userInfo: Object,
  userSubscribedData: Object,
  validCardCvc: boolean,
  validCardExpiry: boolean,
  validCardNumber: boolean,
  profile_txt_past_due_cancel_modal_des: string,
  updateUserProfile: Function
};

const ManageSubscriptionSection = ({
  btn_submit,
  cancel_subscription_success_txt,
  cancel_subscription_txt_profile,
  card,
  card_error_empty,
  card_error_invalid_cvc,
  card_error_invalid_date,
  card_error_number_not_correct,
  card_expiration,
  card_number,
  createAccount_txt_title,
  currency,
  email_error_empty,
  email_error_wrongFormat,
  functions,
  history,
  isCheckout,
  isDeleteAcc,
  isDeleteSuccess,
  isLifetime,
  isLoading = false,
  isLoginPurchase,
  isProUser,
  isUpdateMethod,
  isUpdatingCard,
  loginPurchase_txt,
  login_field_name,
  manageSceneStateChangeHandler,
  modal_txt_cancel_sub_button,
  modal_txt_cancel_sub_desc,
  modal_txt_not_cancel_sub_button,
  onReactivateSubscription,
  profile_txt_cancel_subscription,
  profile_txt_card_number,
  profile_txt_current_plan,
  profile_txt_expire_date,
  profile_txt_name_card,
  profile_txt_next_payment,
  profile_txt_on,
  profile_txt_payment_method,
  profile_txt_reactivate,
  profile_txt_subscription_expire,
  profile_txt_visa_expire,
  purchase_button_subscribe_now,
  purchase_txt_benefit_details_01,
  random_test_modal_confirm_txt,
  shop_txt_7_day_trial,
  subscriptionData,
  toggleCreateAccount,
  validCardCvc,
  validCardExpiry,
  validCardNumber,
  update_subscription_methob_txt_profile,
  userSubscribedData,
  profile_txt_cancel_subscription_des,
  user,
  random_test_modal_back_txt,
  onClick,
  signOut,
  logout_txt,
  setting_txt_settingHeader,
  location,
  footer_txt_profile,
  profile_txt_success_dec,
  profile_txt_cancel_comfirm,
  profile_txt_success_title,
  profile_txt_cancel_denies,
  photoURL,
  name,
  userInfo,
  profile_txt_past_due_cancel_modal_des,
  updateUserProfile
}: Props) => {
  return (
    <>
      <SettingContainer isUpdateMethod={isUpdateMethod}>
        <BackArea
          onClick={onClick}
          isUpdateMethod={isUpdateMethod}
          isUpdatingCard={isUpdatingCard}
        >
          <Image src={`${PREFIX_HEADER}back-icon.svg`} />
          <Title>{random_test_modal_back_txt}</Title>
        </BackArea>
        <ManageSubscriptionLeftSection
          isLoginPurchase={isLoginPurchase}
          currency={currency}
          history={history}
          isCheckout={isCheckout}
          isDeleteAcc={isDeleteAcc}
          manageSceneStateChangeHandler={manageSceneStateChangeHandler}
          btn_submit={btn_submit}
          card={userSubscribedData.card}
          card_error_empty={card_error_empty}
          card_error_invalid_cvc={card_error_invalid_cvc}
          card_error_invalid_date={card_error_invalid_date}
          card_error_number_not_correct={card_error_number_not_correct}
          card_expiration={card_expiration}
          card_number={card_number}
          functions={functions}
          email_error_empty={email_error_empty}
          email_error_wrongFormat={email_error_wrongFormat}
          handleChange={manageSceneStateChangeHandler}
          handleTouch={manageSceneStateChangeHandler}
          isLifetime={userSubscribedData.isLifetime}
          isProUser={isProUser}
          isUpdateMethod={isUpdateMethod}
          isUpdatingCard={isUpdatingCard}
          login_field_name={login_field_name}
          onReactivateSubscription={onReactivateSubscription}
          periodEnd={userSubscribedData.periodEnd}
          planData={userSubscribedData.planData}
          planName={userSubscribedData.planName}
          profile_txt_cancel_subscription={profile_txt_cancel_subscription}
          profile_txt_card_number={profile_txt_card_number}
          profile_txt_current_plan={profile_txt_current_plan}
          profile_txt_expire_date={profile_txt_expire_date}
          profile_txt_name_card={profile_txt_name_card}
          profile_txt_next_payment={profile_txt_next_payment}
          profile_txt_on={profile_txt_on}
          profile_txt_payment_method={profile_txt_payment_method}
          profile_txt_reactivate={profile_txt_reactivate}
          profile_txt_subscription_expire={profile_txt_subscription_expire}
          profile_txt_visa_expire={profile_txt_visa_expire}
          purchase_button_subscribe_now={purchase_button_subscribe_now}
          purchase_txt_benefit_details_01={purchase_txt_benefit_details_01}
          shop_txt_7_day_trial={shop_txt_7_day_trial}
          subscriptionData={subscriptionData}
          update_subscription_methob_txt_profile={
            update_subscription_methob_txt_profile
          }
          user={user}
          validCardCvc={validCardCvc}
          validCardExpiry={validCardExpiry}
          validCardNumber={validCardNumber}
        />
        <ManageSubscriptionRightSection
          signOut={signOut}
          logout_txt={logout_txt}
          setting_txt_settingHeader={setting_txt_settingHeader}
          location={location}
          footer_txt_profile={footer_txt_profile}
          history={history}
          isUpdateMethod={isUpdateMethod}
          photoURL={photoURL}
          name={name}
          userInfo={userInfo}
        />
      </SettingContainer>
      <DeleteAccountModal
        updateUserProfile={updateUserProfile}
        functions={functions}
        isDeleteAcc={isDeleteAcc}
        manageSceneStateChangeHandler={manageSceneStateChangeHandler}
        user={user}
        subscriptionData={subscriptionData}
        random_test_modal_confirm_txt={random_test_modal_confirm_txt}
        isDeleteSuccess={isDeleteSuccess}
        isOpen={isDeleteAcc}
        cancel_subscription_txt_profile={cancel_subscription_txt_profile}
        cancel_subscription_success_txt={profile_txt_success_title}
        cancel_subscription_success_txt_desc={`${profile_txt_success_dec} ${userSubscribedData.periodEnd}`}
        modal_txt_cancel_sub_button={profile_txt_cancel_comfirm}
        modal_txt_not_cancel_sub_button={profile_txt_cancel_denies}
        modal_txt_cancel_sub_desc={profile_txt_cancel_subscription_des}
        profile_txt_past_due_cancel_modal_des={
          profile_txt_past_due_cancel_modal_des
        }
        subscriptionStatus={userSubscribedData.subscriptionStatus}
      />
      <PurchaseLoginModal
        createAccount_txt_title={createAccount_txt_title}
        loginPurchase_txt={loginPurchase_txt}
        isLoginPurchase={isLoginPurchase}
        manageSceneStateChangeHandler={manageSceneStateChangeHandler}
        toggleCreateAccount={toggleCreateAccount}
      />
    </>
  );
};
export { ManageSubscriptionSection };
