/* @flow */
import React, { Component } from 'react';
import styled from 'styled-components';
import {
  LIGHT_BEIGE,
  SAND_YELLOW,
  WHITE,
  GREYISH_BROWN,
  BROWN_GREY,
  PREFIX_LESSON,
  PREFIX_CHATBOT,
  MARIGOLD
} from '../../../common/constants';
import BugTracker from './../../../util/BugTracker';

const LeftContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 26px;
  opacity: ${props => (props.isHide ? 0 : 1)};
`;

const LeftImageContainer = styled.img`
  width: 60px;
  height: 59px;
  margin-right: 16px;
  border-radius: 50%;
`;

const LeftContentContainer = styled.div`
  border-radius: 30px;
  border: solid 1px ${MARIGOLD};
  background-color: ${props => (props.active ? WHITE : LIGHT_BEIGE)};
  cursor: pointer;
`;

const LeftInnerContent = styled.div`
  margin: 15px 30px;
  text-align: left;
`;

const RightContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin: 26px;
  opacity: ${props => (props.isHide ? 0 : 1)};
`;

const RightImageContainer = styled.img`
  width: 60px;
  height: 59px;
  margin-left: 16px;
`;

const RightContentContainer = styled.div`
  border-radius: 30px;
  border: solid 1px ${MARIGOLD};
  background-color: ${props => (props.active ? WHITE : SAND_YELLOW)};
  cursor: pointer;
`;

const RightInnerContent = styled.div`
  margin: 15px 30px;
  text-align: left;
`;

const MainWord = styled.p`
  font-size: ${props => (props.isTargetLangSymbol ? 25 : 16)}px;
  color: ${GREYISH_BROWN};
  diplay: block;
`;

const TranscriptWord = styled.p`
  font-size: 14px;
  color: ${BROWN_GREY};
  diplay: block;
`;

const TranslateWord = styled.p`
  font-size: 14px;
  color: ${GREYISH_BROWN};
  diplay: block;
`;

type Props = {
  isBot: boolean,
  progress: number,
  progressMax: number,
  chatLabel: string,
  chatHistories: Array<Object>,
  translationState: boolean,
  transcriptState: boolean,
  playSound: Function,
  photoURL?: string,
  transcriptState: boolean,
  isBot: boolean,
  handleScroll: Function,
  currentChatbotId: string,
  voiceSpeed: number,
  isTargetLangSymbol: boolean
};

const lingMale = `${PREFIX_LESSON}avatar-circle-male-60-px.svg`;
const lingFemale = `${PREFIX_LESSON}avatar-circle-female-60-px.svg`;
const BOT_IMAGE = {
  chat01: `${PREFIX_CHATBOT}speaker/chat01.png`,
  chat02: `${PREFIX_CHATBOT}speaker/chat02.png`,
  chat03: `${PREFIX_CHATBOT}speaker/chat03.png`,
  chat04: `${PREFIX_CHATBOT}speaker/chat04.png`,
  chat05: `${PREFIX_CHATBOT}speaker/chat05.png`,
  chat06: `${PREFIX_CHATBOT}speaker/chat08.png`,
  chat07: `${PREFIX_CHATBOT}speaker/chat07.png`,
  chat08: `${PREFIX_CHATBOT}speaker/chat08.png`,
  chat09: `${PREFIX_CHATBOT}speaker/chat09.png`,
  chat10: `${PREFIX_CHATBOT}speaker/chat10.png`,
  chat11: `${PREFIX_CHATBOT}speaker/chat11.png`,
  chat12: `${PREFIX_CHATBOT}speaker/chat12.png`,
  chat13: `${PREFIX_CHATBOT}speaker/chat13.png`,
  chat14: `${PREFIX_CHATBOT}speaker/chat14.png`,
  chat15: `${PREFIX_CHATBOT}speaker/chat15.png`,
  chat16: `${PREFIX_CHATBOT}speaker/chat16.png`,
  chat17: `${PREFIX_CHATBOT}speaker/chat17.png`,
  chat18: `${PREFIX_CHATBOT}speaker/chat18.png`,
  chat19: `${PREFIX_CHATBOT}speaker/chat19.png`,
  chat20: `${PREFIX_CHATBOT}speaker/chat20.png`
};
class ChatReviews extends Component<Props> {
  scrollView: any;
  ref: Object;

  getMainWord = (original: string, isTargetLangSymbol: boolean) => {
    return (
      <MainWord isTargetLangSymbol={isTargetLangSymbol}>{original}</MainWord>
    );
  };
  getTranscript = (transcriptState: boolean, transcript: string) => {
    return transcriptState ? (
      <TranscriptWord>{transcript}</TranscriptWord>
    ) : null;
  };
  getTranslate = (translationState: boolean, translation: string) => {
    return translationState ? (
      <TranslateWord>{translation}</TranslateWord>
    ) : null;
  };
  renderBot(chat: Object) {
    const {
      transcriptState,
      translationState,
      playSound,
      currentChatbotId,
      voiceSpeed,
      isTargetLangSymbol
    } = this.props;

    const {
      original,
      transcript,
      id,
      translation,
      soundFile,
      order,
      speaker
    } = chat;

    const defaultImageSource = speaker ? lingMale : lingFemale;
    const imageSource = BOT_IMAGE[currentChatbotId]
      ? BOT_IMAGE[currentChatbotId]
      : defaultImageSource;

    return (
      <LeftContainer key={`${order}${id}`}>
        <LeftImageContainer src={imageSource} alt="speakerA" />
        <LeftContentContainer
          id="LeftContentContainer"
          ref={this.ref}
          onClick={() => {
            playSound(soundFile, voiceSpeed);
          }}
        >
          <LeftInnerContent>
            {this.getMainWord(original, isTargetLangSymbol)}
            {this.getTranscript(transcriptState, transcript)}
            {this.getTranslate(translationState, translation)}
          </LeftInnerContent>
        </LeftContentContainer>
      </LeftContainer>
    );
  }

  renderUser(chat: Object) {
    const {
      transcriptState,
      translationState,
      playSound,
      voiceSpeed,
      isTargetLangSymbol
    } = this.props;

    const { original, transcript, translation, soundFile } = chat;

    return (
      <RightContainer>
        <RightContentContainer
          ref={this.ref}
          id="RightContentContainer"
          onClick={() => {
            playSound(soundFile, voiceSpeed);
          }}
        >
          <RightInnerContent>
            {this.getMainWord(original, isTargetLangSymbol)}
            {this.getTranscript(transcriptState, transcript)}
            {this.getTranslate(translationState, translation)}
          </RightInnerContent>
        </RightContentContainer>
        <RightImageContainer src={lingMale} alt="speakerB" />
      </RightContainer>
    );
  }
  renderChatHistory(chat: Object) {
    try {
      return chat.isBot ? this.renderBot(chat) : this.renderUser(chat);
    } catch (error) {
      BugTracker.notify(error);
    }
  }

  render() {
    const { chatHistories } = this.props;

    return (
      <div>
        {chatHistories &&
          chatHistories.map(chat => this.renderChatHistory(chat))}
      </div>
    );
  }
}

export { ChatReviews };
