/* @flow */
import _ from 'lodash';

const X_PATH_OFFSET = 0;
const Y_PATH_OFFSET = 0;

const ERROR_TOLERANCE = 45;

export const parseSvg = (coordinates: string = '') => {
  return coordinates;
};

export const createStrokes = (coordinates: Object[][], height: number) => {
  const correctCoordinates = [];

  const strokes = _.map(coordinates, paths => {
    const stroke = _.reduce(
      paths,
      (stroke: string, path: Object, key: number) =>
        `${stroke}${convertPathToString(path, height)} `,
      ''
    );
    correctCoordinates.push(false);
    return stroke;
  });

  return { correctCoordinates, strokes };
};

const transformPathForDrawing = (path: Object, getPath: Function) => {
  return transformPath(path, getPath, getPath.bind(null, path.y, path.x));
};

const transformPathForCorrection = (path: Object, getPath: Function) => {
  return transformPath(path, getPath, getPath.bind(null, path.x, path.y));
};

export const transformPath = (
  path: Object,
  getPath: Function,
  getHorizontalPath: Function
) => {
  const { code, x, y, x1, y1, x2, y2 } = path;
  switch (code) {
    case 'M':
    case 'L':
    case 'T':
    case 'V':
      return [getPath(x, y)];
    case 'H':
      return [getHorizontalPath()]; // Read the spec of H it start reading y before x
    case 'Q':
      return [getPath(x1, y1), getPath(x, y)];
    case 'S':
      return [getPath(x2, y2), getPath(x, y)];
    case 'C':
      return [getPath(x1, y1), getPath(x2, y2), getPath(x, y)];
    default:
      return [];
  }
};

const getPathString = (height: number) => (x: number, y: number) =>
  `${getSinglePath(height, x, X_PATH_OFFSET)} ${getSinglePath(
    height,
    y,
    Y_PATH_OFFSET
  )}`;

const getPathObject = (height: number, xOffset: number, yOffset: number) => (
  x: number,
  y: number
) => {
  return {
    x: getSinglePath(height, x, xOffset),
    y: getSinglePath(height, y, yOffset)
  };
};

const getSinglePath = (height, origin, offset) =>
  (origin / 100) * height + offset;

export const convertPathToString = (path: Object, height: number) => {
  return `${path.code}${_.join(
    transformPathForDrawing(path, getPathString(height)),
    ' '
  )}`;
};

export const convertPathToObject = (
  path: Object,
  height: number,
  xOffset: number,
  yOffset: number
) => {
  return transformPathForCorrection(
    path,
    getPathObject(height, xOffset, yOffset)
  );
};

export const getCorrectPath = (
  coordinate: Object[],
  height: number,
  xOffset: number
) => {
  const yOffset = 0;

  return _.reduce(
    coordinate,
    (correct: Array<Object>, path: Object) => [
      ...correct,
      ...convertPathToObject(path, height, xOffset, yOffset)
    ],
    []
  );
};

export const getCorrectIndex = (
  correctCoordinates: boolean[],
  drawnCoordinates: string,
  coordinates: Object[][],
  width: number,
  height: number,
  x: number,
  y: number
) => {
  const current = _.findIndex(correctCoordinates, o => !o);

  const coordinate = coordinates[current];
  if (!coordinate) {
    return;
  }

  const xOffset = Math.abs(height - width) * 2;
  const first = coordinate[0];

  const firstX = (first.x / 100) * height + xOffset;
  const firstY = (first.y / 100) * height;
  const shiftX = firstX - x;
  const shiftY = firstY - y;

  const correctPath = getCorrectPath(coordinate, height, xOffset);
  const drawn = drawnCoordinates.split(',');

  const isCorrect = _.reduce(
    drawn,
    (result, item) => {
      const point = item.split(' ');
      if (
        correctPath[0] &&
        _.size(correctPath) > 0 &&
        Math.abs(Number(point[0]) - correctPath[0].x + shiftX) <
          ERROR_TOLERANCE &&
        Math.abs(Number(point[1]) - correctPath[0].y + shiftY) < ERROR_TOLERANCE
      ) {
        correctPath.shift();
      }
      return _.size(correctPath) <= 0;
    },
    false
  );
  if (isCorrect) {
    return current;
  } else {
    return false;
  }
};
