/* @flow */
import React, { Fragment, Component, createRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { TopCharacterCard } from '../main-components/TopCharacterCard';
import {
  dialogMessageActive,
  dialogMessageDeactive
} from '../../actions/DialogActions';
import {
  PREFIX_LESSON,
  SAND_YELLOW,
  LIGHT_BEIGE,
  GREYISH_BROWN,
  GREEN_COLOR,
  MARIGOLD
} from '../../../common/constants';

const lingMale = `${PREFIX_LESSON}avatar-circle-male-60-px.svg`;
const lingFemale = `${PREFIX_LESSON}avatar-circle-female-60-px.svg`;

const LeftContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 26px;
`;

const LeftImageContainer = styled.img`
  width: 60px;
  height: 59px;
  margin-right: 16px;
`;

const LeftContentContainer = styled.div`
  border-radius: 30px;
  border: solid 1px ${MARIGOLD};
  background-color: ${props => (props.active ? 'white' : LIGHT_BEIGE)};
`;

const LeftInnerContent = styled.div`
  margin: 15px 30px;
`;

const RightContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin: 26px;
`;

const RightImageContainer = styled.img`
  width: 60px;
  height: 59px;
  margin-left: 16px;
`;

const RightContentContainer = styled.div`
  border-radius: 30px;
  border: solid 1px ${MARIGOLD};
  background-color: ${props => (props.active ? 'white' : SAND_YELLOW)};
`;

const RightInnerContent = styled.div`
  margin: 15px 30px;
  text-align: right;
`;

const MainContainer = styled.div`
  display: flex;
  white-space: nowrap;
`;

const MainCorrectContainer = styled.div`
  display: flex;
  margin: 0 30px;
`;

const MainWord = styled.p`
  font-size: ${props => (props.isTargetLangSymbol ? 25 : 16)}px;
  color: ${GREYISH_BROWN};
  diplay: block;
  display: flex;
  align-items: center;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  display: -webkit-box;
`;

const CorrectAnswer = styled.p`
  font-size: 16px;
  color: ${GREEN_COLOR};
  diplay: block;
`;

const CommonInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeftInnerContainer = styled(CommonInnerContainer)`
  align-items: flex-start;
`;

const RightInnerContainer = styled(CommonInnerContainer)`
  align-items: flex-end;
`;

export const getSentenceStartAndEnd = (sentence: string, index: number) => {
  const split1 = sentence.split(' ');
  return {
    oneFix: split1.slice(0, index).join(' '),
    two: split1.slice(index + 1, split1.length).join(' ')
  };
};

type Props = {
  id: string,
  active: boolean,
  speaker: number,
  original: string,
  soundFile: string,
  question: Object,
  answer: string,
  transcript: string,
  transcriptState: boolean,
  translation: string,
  translateState: boolean,
  gameState: number,
  card: Object,
  onPressCard: Function,
  onPress: Function,
  onActive: Function,
  showCorrect: boolean,
  questionOrders?: number,
  rtlLanguage: boolean,
  nativeRtlLanguage: boolean,
  order: number,
  selected: number,
  current: number,
  isTargetLangSymbol: boolean
};

type State = {
  shouldScroll: boolean
};

class DialogQuestion extends Component<Props, State> {
  ref: Object;
  constructor(props: Props) {
    super(props);
    this.ref = createRef();
    this.state = {
      shouldScroll: false
    };
  }

  componentDidMount() {
    const { order, gameState } = this.props;
    if (order === 0 && gameState) {
      this.handleScroll();
    }
  }

  componentDidUpdate() {
    const { current, order, selected } = this.props;
    const { shouldScroll } = this.state;
    if (current === order && shouldScroll) {
      this.handleScroll();
    } else if (!shouldScroll && selected !== -1) {
      this.setState({ shouldScroll: true });
    }
  }

  handleScroll() {
    this.ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }

  render() {
    const {
      id = '',
      speaker = 0,
      original = 'Temporary Message',
      soundFile = 'vtr11.mp3',
      question,
      transcript = '',
      transcriptState = true,
      translation = '',
      translateState = false,
      gameState = 1,
      card,
      questionOrders = 0,
      onPressCard,
      onPress,
      showCorrect = false
    } = this.props;

    const getQuestion = () => {
      let sentence = '';
      if (gameState) {
        sentence = transcriptState ? transcript : original;
      } else {
        sentence = translateState ? translation : original;
      }
      return getSentenceStartAndEnd(sentence, questionOrders);
    };

    const getMainWord = () => {
      const { isTargetLangSymbol } = this.props;
      const split = getQuestion();

      const result = (
        <MainContainer>
          <MainWord isTargetLangSymbol={isTargetLangSymbol}>
            {split.oneFix}
          </MainWord>
          <TopCharacterCard
            {...card}
            onPress={onPressCard}
            isDialog
            isTargetLangSymbol
          />
          <MainWord isTargetLangSymbol={isTargetLangSymbol}>
            {split.two}
          </MainWord>
        </MainContainer>
      );
      return result;
    };

    const getAnsweredWrong = () => {
      const split = getQuestion();

      return showCorrect ? (
        <MainCorrectContainer>
          <MainWord>{split.oneFix}</MainWord>
          {split.oneFix === '' ? null : <p>&nbsp;</p>}
          <CorrectAnswer>{question.character}</CorrectAnswer>
          {split.two === '' ? null : <p>&nbsp;</p>}
          <MainWord>{split.two}</MainWord>
        </MainCorrectContainer>
      ) : null;
    };

    const leftSpeaker = (
      <LeftContainer ref={this.ref}>
        <LeftImageContainer src={lingFemale} alt="lingFemale" />
        <LeftInnerContainer>
          <LeftContentContainer>
            <LeftInnerContent
              onClick={() => {
                onPress(soundFile, id);
              }}
            >
              {getMainWord()}
            </LeftInnerContent>
          </LeftContentContainer>
          {getAnsweredWrong()}
        </LeftInnerContainer>
      </LeftContainer>
    );

    const rightSpeaker = (
      <RightContainer ref={this.ref}>
        <RightInnerContainer>
          <RightContentContainer>
            <RightInnerContent
              onClick={() => {
                onPress(soundFile, id);
              }}
            >
              {getMainWord()}
            </RightInnerContent>
          </RightContentContainer>
          {getAnsweredWrong()}
        </RightInnerContainer>
        <RightImageContainer src={lingMale} alt="lingMale" />
      </RightContainer>
    );

    return (
      <Fragment>{Number(speaker) === 0 ? leftSpeaker : rightSpeaker}</Fragment>
    );
  }
}

const mapStateToProps: Object = state => {
  const { storage } = state;
  const { isTargetLangSymbol } = storage;
  return {
    isTargetLangSymbol
  };
};
export const mapDispatchToProps = (dispatch: Function) => {
  return {
    onActive: (id: number) => {
      dispatch(dialogMessageDeactive());
      dispatch(dialogMessageActive(id));
    }
  };
};
export { DialogQuestion };
export default connect(mapStateToProps, mapDispatchToProps)(DialogQuestion);
