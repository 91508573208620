/* @flow */
import { connect } from 'react-redux';
import _ from 'lodash';
import { LessonReview } from '../components/main-components/LessonReview';
import { getLabel, getLabels } from '../../util';
import {
  getCurrentUnitId,
  getTranscriptionState
} from '../../profile/selectors';
import { getCurrentCourseData } from '../selectors/DataSelectors';
import * as actions from '../../games/actions';
import { FLASHCARD, TUTORIAL_KEY } from '../../games/constants';
import { getVoiceSpeed } from '../../chatbots/selectors';

export const mapStateToProps = (state: Object): Object => {
  const { features } = getCurrentCourseData(state);
  const { hasTranscript } = features;
  const transcript = getTranscriptionState(state);
  const currentUnitId = getCurrentUnitId(state);
  const voiceSpeed = getVoiceSpeed(state);

  const { words, sentences } = _.get(
    state,
    `courses.${state.courses.activeLangCode}`,
    false
  );

  const newWord = _.reduce(
    _.omit(words, TUTORIAL_KEY),
    (result, n) => {
      _.forEach(_.get(n, 'byId', []), item => {
        result.push(item);
      });
      return result;
    },
    []
  );

  const newSentence = _.reduce(
    sentences,
    (result, n) => {
      _.forEach(_.get(n, 'byId', []), item => {
        result.push(item);
      });
      return result;
    },
    []
  );

  const listData = _.map(newWord, (item, index) => {
    const sentencesObject = _.find(newSentence, i => {
      return i.word === item.id;
    });

    const flashCardObject = {
      words: item,
      sentences: sentencesObject ? sentencesObject : false
    };

    return flashCardObject;
  });

  const newListData = _.filter(listData, item => {
    if (!_.isEmpty(item.words)) {
      return item.words.unit === currentUnitId;
    } else {
      return false;
    }
  });

  return {
    transcriptState: transcript,
    voiceSpeed,
    screen: FLASHCARD,
    dataCollection: newListData,
    title: getLabel(state, 'overView_txt_vocab', 'Vocabulary'),
    hasTranscript,
    ...getLabels(
      state,
      'random_test_modal_confirm_txt',
      'modal_error_faq',
      'modal_error_report',
      'modal_error_description',
      'report_text_sendfeedback',
      'report_text_cancle',
      'landing_txt_connectInternet',
      'btn_continue'
    )
  };
};

export default connect(mapStateToProps, actions)(LessonReview);
