import React, { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FiberManualRecord as BulletIcon } from '@mui/icons-material';
import StartButton from './subComponents/StartButton';
import { getFaqData } from './data/paywallData';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

const FAQ = ({ initiateBuy, labels }) => {
  const { txt_faq, btn_get_my_plan } = labels;

  const theme = useTheme();
  const styles = {
    container: {
      mt: 40,
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    headerContainer: {
      width: '80%',
      pb: 5
    },
    headerText: {
      fontSize: '30px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '36px'
      }
    },
    questionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      my: 7,
      width: '90%',
      [theme.breakpoints.up('sm')]: {
        width: '100%'
      }
    },
    questionBox: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%'
    },
    questionText: {
      fontSize: '20px',
      fontWeight: 600,
      textAlign: 'left',
      pl: 5,
      [theme.breakpoints.up('sm')]: {
        fontSize: '24px',
        pl: 0
      }
    },
    questionTextContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      pb: 8
    },
    boxShadow: {
      boxShadow: '0px 2px 0px 0px #F1F1F1'
    },
    answerContainer: {
      width: '90%',
      maxWidth: '680px',
      boxShadow: '0px 2px 0px 0px #F1F1F1',
      pb: 8,
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      px: 4,
      [theme.breakpoints.up('sm')]: {
        p: 0,
        mt: 5
      }
    },
    answerText: {
      fontSize: '18px'
    },
    bulletIcon: {
      height: '10px',
      width: '10px',
      pr: 2,
      mt: 3
    },
    listBox: {
      display: 'flex',
      alignItems: 'flex-start'
    },
    listItem: {
      fontSize: '16px',
      fontWeight: 400,
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px'
      }
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      mb: 40,
      mt: 5
    },
    button: {
      width: '90%',
      [theme.breakpoints.up('sm')]: {
        width: '223px'
      }
    },
    iconButton: {
      display: 'flex',
      alignItems: 'flex-start',
      pt: 0
    },
    arrowIcon: {
      fontSize: '32px',
      color: '#111111'
    }
  };

  const [expandedQuestions, setExpandedQuestions] = useState([]);

  const toggleDropdown = questionId => {
    if (expandedQuestions.includes(questionId)) {
      setExpandedQuestions(prevState =>
        prevState.filter(id => id !== questionId)
      );
    } else {
      setExpandedQuestions(prevState => [...prevState, questionId]);
    }
  };

  const renderQuestions = questionsData => {
    return questionsData.map(
      ({ id, question, answer, additionalInfo, endText }) => (
        <Box key={id} sx={styles.questionsContainer}>
          <Box
            sx={[
              styles.questionTextContainer,
              expandedQuestions.includes(id) ? {} : styles.boxShadow
            ]}
          >
            <Typography variant="text" sx={styles.questionText}>
              {question}
            </Typography>
            <IconButton
              sx={styles.iconButton}
              onClick={() => toggleDropdown(id)}
            >
              {expandedQuestions.includes(id) ? (
                <ExpandLessIcon sx={styles.arrowIcon} />
              ) : (
                <ExpandMoreIcon sx={styles.arrowIcon} />
              )}
            </IconButton>
          </Box>
          {expandedQuestions.includes(id) && (
            <Box sx={styles.answerContainer}>
              <Typography variant="subtext" sx={styles.answerText}>
                {answer}
              </Typography>
              {additionalInfo && (
                <List>
                  {additionalInfo.map((info, index) => (
                    <ListItem key={index} sx={styles.listBox}>
                      <BulletIcon sx={styles.bulletIcon} />
                      <ListItemText
                        sx={styles.listItem}
                        primary={
                          <Typography variant="subtext" sx={styles.listItem}>
                            {info}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              )}
              {endText && (
                <Typography variant="subtext" sx={styles.answerText}>
                  {endText}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      )
    );
  };

  const faqData = getFaqData(labels);
  return (
    <Box sx={styles.container}>
      <Box sx={styles.headerContainer}>
        <Typography variant="text" sx={styles.headerText}>
          {txt_faq}
        </Typography>
      </Box>
      {renderQuestions(faqData)}
      <Box sx={styles.buttonContainer}>
        <StartButton
          buttonStyle={styles.button}
          text={btn_get_my_plan}
          buttonAction={initiateBuy}
        />
      </Box>
    </Box>
  );
};

export default FAQ;

FAQ.propTypes = {
  initiateBuy: PropTypes.func
};
