/* @flow */

export const STRIPE_MONTHY: string = 'stripe_monthly';
export const STRIPE_YEARLY: string = 'stripe_yearly';
export const STRIPE_LIFETIME: string = 'stripe_lifetime';
export const PROMO_LIFETIME: string = 'promo';
export const PROMO_MONTYLY_20: string = 'monthly20';
export const PROMO_YEARLY_20: string = 'yearly20';
export const PROMO_LIFETIME_20: string = 'promo20';
export const PRODUCT_PLAN_NAME: string = 'Lifetime';
export const PRODUCT_LING_PREMIUM: string = 'Ling Premium';
export const PROMO_NAME: string = 'promoName';
export const ID: string = 'id';
export const SPRIPE_CANCELED: string = 'canceled';

export const PRODUCT_TYPE = {
  monthly: 'monthly',
  yearly: 'yearly',
  lifetime: 'lifetime',
  quarterly: 'quarterly'
};

export const PRODUCT_DETAIL_TABLE_COLUMN = {
  '0': 'name',
  '1': 'isBasic',
  '2': 'isSubscription',
  '3': 'isLifeTime'
};

export type IProductTypes = {
  isSubscription: boolean,
  name: string,
  price: string,
  yearlyPrice?: number
};
