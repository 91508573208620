/* @flow */
import Analytic from '../../util/Analytic';
import {
  FINISH_WRITING,
  RESET_WRITING_UI_STATE,
  CLICK_WRITING,
  CLICK_PLAY_ANIMATION,
  CLICK_SHOW_RED_ARROW,
  RESET_GUIDE_ACTIVE,
  ON_GUIDE_ACTIVE
} from '../constants/WritingTypes';
import { gameSound as utilGameSound } from '../../util/SoundPlayer';
import { getIsEffectEnabled } from '../../chatbots/selectors';

export const logWritingGame = (answer: boolean) => {
  return (dispatch: Function, getState: Function) => {
    const { game, routes } = getState();
    Analytic.log(Analytic.key.G1, {
      game_type: routes.gameType,
      content: game.key,
      answer: answer
    });
    Analytic.logGA(Analytic.key.G1, {
      category: Analytic.key.G1,
      action: routes.gameType,
      label: answer.toString()
    });
  };
};

export const finishWriting = () => (dispatch: Function, getState: Function) => {
  const state = getState();
  const isEffectEnabled = getIsEffectEnabled(state);
  const gameSound = isEffectEnabled ? utilGameSound : arg => { };
  gameSound('correct');
  return {
    type: FINISH_WRITING
  };
};

export const resetWritingUIState = () => ({
  type: RESET_WRITING_UI_STATE
});

export const clickWriting = () => {
  return { type: CLICK_WRITING };
};

export const clickPlayAnimation = () => {
  return { type: CLICK_PLAY_ANIMATION };
};

export const clickShowRedArrow = () => {
  return { type: CLICK_SHOW_RED_ARROW };
};

export const onGuideActive = () => {
  return { type: ON_GUIDE_ACTIVE };
};

export const resetGuideActive = () => {
  return { type: RESET_GUIDE_ACTIVE };
};
