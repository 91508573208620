/* @flow */
import { getLanguageLabel } from '../../util/LabelHelper';
import { getFlagImageUrl } from '../../util/AssetHelper';

export const getFlagTargetLanguage = (state: Object, learnLang: ?string) => {
  const flagTarget: any =
    learnLang !== undefined ? learnLang : state.data.targetLangCode;
  const flagUri = getFlagImageUrl(flagTarget);
  return {
    flagUri: flagUri,
    flagTarget: getLanguageLabel(state, flagTarget)
  };
};

export const getFlagLanguage = (state: Object) => {
  const flagTarget: any = state.stats.targetLangSelected;
  const flagUri = getFlagImageUrl(flagTarget);
  return {
    flagUri: flagUri,
    flagTarget: getLanguageLabel(state, flagTarget)
  };
};
