/* @flow */
import styled from 'styled-components';
const ShopContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HeroStyle = styled.div`
  display: block;
  background: gray;
  justify-content: space-around;
  text-align: center;
  flex-direction: column;
  background: url(${props => props.imgUrl});
  background-size: cover;
  background-repeat-y: no-repeat;
  padding-top: 107px;
`;

const SaleSectionStyle = styled.div`
  display: flex;
  margin: auto;
  width: 716px;
  justify-content: space-around;
`;
const SpinnerContainer = styled.div`
  display: ${props => (props.isUpdateMethod ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  height: calc(100vh - 225px);
  width: 431px;
  margin: auto;
`;

const SaleSectionContainerButtomStyle = styled.div`
  background: gray;
  justify-content: space-around;
  text-align: center;
  flex-direction: column;
  background: url(${props => props.imgUrl});
  background-size: cover;
  background-repeat-y: no-repeat;
  padding-top: 107px;
`;
export {
  SpinnerContainer,
  HeroStyle,
  SaleSectionContainerButtomStyle,
  SaleSectionStyle,
  ShopContainerStyle
};
