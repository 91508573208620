// This file should only be used for static data,
//  data that does not need to change or be updated

type DayObject = {
  day: string,
  dayShort: string,
  id: number,
  isChecked: boolean
};

export const WeekDayData: Array<DayObject> = [
  {
    day: 'M',
    dayShort: 'Mon',
    id: 0,
    isChecked: true
  },
  {
    day: 'T',
    dayShort: 'Tue',
    id: 1,
    isChecked: true
  },
  {
    day: 'W',
    dayShort: 'Wed',
    id: 2,
    isChecked: true
  },
  {
    day: 'T',
    dayShort: 'Thu',
    id: 3,
    isChecked: true
  },
  {
    day: 'F',
    dayShort: 'Fri',
    id: 4,
    isChecked: true
  },
  {
    day: 'S',
    dayShort: 'Sat',
    id: 5,
    isChecked: true
  },
  {
    day: 'S',
    dayShort: 'Sun',
    id: 6,
    isChecked: true
  }
];
