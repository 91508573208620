/* @flow */
import * as React from 'react';
import { PrimaryText } from './PrimaryText';

type Props = {
  children?: string,
  style?: Object,
  textColor?: string,
  isDialog?: boolean,
  isTargetLangSymbol?: boolean
};

function Title({
  children,
  style,
  textColor,
  isDialog,
  isTargetLangSymbol
}: Props) {
  return (
    <PrimaryText
      style={{
        ...style,
        color: textColor,
        fontSize: isTargetLangSymbol ? '25px' : isDialog ? '16px' : '22px'
      }}
    >
      {children}
    </PrimaryText>
  );
}

export { Title };
