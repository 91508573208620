/* @flow */
import React, { Component } from 'react';
type Props = {
  style: Object,
  borderStyle: Object,
  children: Object
};
class Table extends Component<Props, any> {
  static defaultProps: any;
  _renderChildren(props) {
    return React.Children.map(props.children, child => {
      if (props.borderStyle && child.type.displayName !== 'ScrollView') {
        return React.cloneElement(child, {
          borderStyle: props.borderStyle
        });
      } else {
        return React.cloneElement(child);
      }
    });
  }

  render() {
    let borderWidth, borderColor;
    if (
      this.props.borderStyle &&
      this.props.borderStyle.borderWidth !== undefined
    ) {
      borderWidth = this.props.borderStyle.borderWidth;
    } else {
      borderWidth = 3;
    }
    if (this.props.borderStyle && this.props.borderStyle.borderColor) {
      borderColor = this.props.borderStyle.borderColor;
    } else {
      borderColor = '#fff';
    }

    return (
      <div
        style={
          (this.props.style,
          {
            borderLeftWidth: borderWidth,
            borderBottomWidth: borderWidth,
            borderColor: borderColor
          })
        }
      >
        {this._renderChildren(this.props)}
      </div>
    );
  }
}

class TableWrapper extends Component<Props, any> {
  static defaultProps: any;
  _renderChildren(props) {
    return React.Children.map(props.children, child => {
      if (this.props.borderStyle) {
        return React.cloneElement(child, {
          borderStyle: this.props.borderStyle
        });
      } else {
        return React.cloneElement(child);
      }
    });
  }

  render() {
    const { style } = this.props;
    return <div style={style}>{this._renderChildren(this.props)}</div>;
  }
}

export { Table, TableWrapper };
