/* @flow */
import React from 'react';
import styled from 'styled-components';

import { MARIGOLD, DARK_YELLOW, WHITE } from '../../../common/constants';

const Button = styled.div`
    height: 55px;
    border-radius: 15px;
    box-shadow: inset 0 -4px 0 0 ${DARK_YELLOW};
    border: solid 1px ${DARK_YELLOW};
    background-color: ${MARIGOLD};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
    &:hover {
        box-shadow: none;
        opacity: 0.4;
        cursor: pointer;
    }
    &:active {
        box-shadow: none;
    }
`;

const Text = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: ${WHITE};
    margin: 0;
`;

const ContinueButton = ({ btn_continue, onNext }: Object) => (
    <Button onClick={onNext}>
        <Text>{btn_continue}</Text>
    </Button>
);

export { ContinueButton };
