import { Box, Typography } from '@mui/material';
import React from 'react';
import { IMAGE_PATH } from '../../../../common/constants';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const TestimonialCarouselLarge = ({
  currentPosition,
  testimonialData,
  setCurrentPosition,
  styles
}) => {
  const nextSlide = () => {
    if (
      currentPosition >
      testimonialData.length - (testimonialData.length + 1)
    ) {
      const newPosition = currentPosition - 1;
      setCurrentPosition(newPosition);
    }
  };

  const prevSlide = () => {
    if (currentPosition <= 1) {
      const newPosition = currentPosition + 1;
      setCurrentPosition(newPosition);
    }
  };

  const imgPath = image => `../${IMAGE_PATH}/onboarding/${image}`;

  return (
    <>
      <Box sx={styles.testimonialContainer}>
        {testimonialData.map((data, i) => {
          return (
            <Box key={i} sx={styles.contentContainer}>
              <Box
                component="img"
                src={imgPath('quotes.png')}
                sx={styles.quoteImg}
              />
              <Typography variant="subtext" sx={styles.text}>
                {data.text}
              </Typography>
              <Box sx={styles.userInfoContainer}>
                <Box component="img" src={data.img} sx={styles.userImg} />
                <Box>
                  <Typography variant="subtext" sx={styles.userNameText}>
                    {data.userName}
                  </Typography>
                  <Box sx={styles.ratingContainer}>
                    <Box
                      component="img"
                      src={imgPath('star.png')}
                      sx={styles.star}
                    />
                    <Typography variant="text" sx={styles.ratingText}>
                      {data.rating}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box sx={styles.arrowContainer}>
        <IconButton onClick={prevSlide} sx={styles.icon}>
          <ArrowBackIosNewIcon sx={styles.arrowIcon} />
        </IconButton>
        <IconButton onClick={nextSlide} sx={styles.icon}>
          <ArrowForwardIosIcon sx={styles.arrowIcon} />
        </IconButton>
      </Box>
    </>
  );
};

export default TestimonialCarouselLarge;
