import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// $flow-disable-line
import ReactGA from 'react-ga';
type Props = {};
class AppContainer extends Component<Props> {
  UNSAFE_componentWillMount() {
    this.props.history.listen((location, action) => {
      ReactGA.pageview(location.pathname);
    });
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}
export default withRouter(AppContainer);
