/* @flow */
import { connect } from 'react-redux';
import { DialogHeader } from '../components/sub-components/DialogHeader';
import { getDialogHeader } from '../selectors/GameSelectors';
import { getLabel, getLabels } from '../../util';

export const mapStateToProps = (state: Object) => {
  const { storage } = state;
  const { isShowTutorial } = storage;
  const { speaker0, speaker1, place, formality } = getDialogHeader(state);
  return {
    speaker0: getLabel(state, speaker0),
    speaker1: getLabel(state, speaker1),
    place: getLabel(state, place),
    formality: formality,
    isShowTutorial,
    ...getLabels(
      state,
      'dialogue_title_formality_formal',
      'dialogue_title_formality_informal'
    )
  };
};

export { DialogHeader };
export default connect(mapStateToProps)(DialogHeader);
