/* @flow */
import * as React from 'react';
import { DEFAULT_FONT } from '../../../util/AssetHelper';
import _ from 'lodash';
import styled from 'styled-components';
import { RED_COLOR, GREEN_COLOR } from '../../../common/constants';
import { spellingStatus } from '../../constants';

const ContainerStyle = styled.div`
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 28px;
  flex: 1;
  display: flex;
`;

const FontAnswer = styled.div`
  font-size: 21px;
  font-family: ${DEFAULT_FONT};
  margin-top: 2px;
  margin-bottom: 2px;
  text-align: left;
`;

type Props = {
  answeredWrong: Object,
  isResultShown: boolean,
  correctAnswer?: Object[],
  byId?: Object
};
const getCorrectAnswer = (correctAnswer: Object[] = []) => {
  return _.map(correctAnswer, ({ character, status }, key) => (
    <span
      key={key}
      style={{
        color: status !== spellingStatus.CORRECT ? RED_COLOR : GREEN_COLOR,
        fontFamily: DEFAULT_FONT
      }}
    >
      {character}
    </span>
  ));
};

const CorrectAnswer = ({
  answeredWrong,
  isResultShown,
  correctAnswer,
  byId
}: Props) => {
  return isResultShown ? (
    <ContainerStyle>
      <FontAnswer>{getCorrectAnswer(correctAnswer)}</FontAnswer>
    </ContainerStyle>
  ) : null;
};

export { CorrectAnswer };
