/* @flow */
import React from 'react';
import { detect } from 'detect-browser';
import { DailyReminderModal } from '../../games/components/sub-components/DailyReminderModal/DailyReminderModal';

type Props = {
  nativeLangCode: string,
  isRemiderOpen: boolean,
  user: Object,
  toggleReminder: Function,
  closeReminder: Function,
  setReminders: Function,
  setClientToken: Function,
  edit_profile_save_txt: string,
  setting_txt_reminder_title: string,
  setting_txt_day_select: string,
  setting_txt_time_select: string,
  isShowTutorial: boolean,
  stateChangeHandler: Function
};
export const DailyReminderPopUp = ({
  nativeLangCode,
  isRemiderOpen,
  user,
  toggleReminder,
  closeReminder,
  setReminders,
  setClientToken,
  edit_profile_save_txt,
  setting_txt_reminder_title,
  setting_txt_day_select,
  setting_txt_time_select,
  isShowTutorial,
  stateChangeHandler
}: Props) => {
  const isModalOpen =
    isRemiderOpen &&
    (user.facebook || user.google || user.email || user.apple) &&
    !isShowTutorial;
  return (
    detect() &&
    detect()
      .name.toLowerCase()
      .indexOf('safari') === -1 && (
      <DailyReminderModal
        stateChangeHandler={stateChangeHandler}
        nativeLangCode={nativeLangCode}
        isHomePage={true}
        isOpen={isModalOpen}
        toggle={toggleReminder}
        closeReminder={closeReminder}
        setReminders={setReminders}
        setClientToken={setClientToken}
        edit_profile_save_txt={edit_profile_save_txt}
        setting_txt_reminder_title={setting_txt_reminder_title}
        setting_txt_day_select={setting_txt_day_select}
        setting_txt_time_select={setting_txt_time_select}
      />
    )
  );
};
