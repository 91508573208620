import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box, Typography } from '@mui/material';
import { IMAGE_PATH } from '../../../common/constants';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from '@mui/material/styles';
import OnboardingButton from './OnboardingButton';
import { handleOutsideRefClick } from '../../utils/handleOutsideRefClick';

const CourseRender = ({
  getLanguageLabel,
  handleCourseSelect,
  targetLanguages = [],
  search,
  native,
  handleScreenChange,
  labels
}) => {
  const {
    chooseCourse_txt_i_want_to_learn,
    txt_select_language,
    btn_continue
  } = labels;

  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState('');

  // TODO: LOCALIZATION - change this to count characters instead after localization
  const longLanguages = ['in', 'ru', 'sl', 'uk'];

  const ref = useRef(null);

  useEffect(() => {
    const listener = handleOutsideRefClick(ref, () => setDropDownOpen(false));
    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };
  }, []);

  const theme = useTheme();
  const styles = {
    main: {
      width: '90%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      position: 'absolute',
      top: longLanguages.includes(native) ? 390 : 360,
      [theme.breakpoints.up('sm')]: {
        alignItems: 'flex-end',
        width: 'auto',
        top: 360
      }
    },
    container: {
      width: '100%',
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center'
      }
    },
    labelTextContainer: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      flexWrap: 'wrap',
      [theme.breakpoints.up('sm')]: {
        ml: 3
      }
    },
    flagIcon: {
      objectFit: 'contain',
      mx: 4,
      maxWidth: 45
    },
    langText: {
      color: '#808080',
      textWrap: 'wrap',
      textAlign: 'left',
      fontSize: 30,
      [theme.breakpoints.up('sm')]: {
        fontSize: 36
      }
    },
    labelText: {
      color: '#B3B3B3',
      textAlign: 'right',
      fontSize: 30,
      [theme.breakpoints.up('sm')]: {
        fontSize: 36,
        textAlign: 'right'
      }
    },
    dropDownText: {
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: 16,
      textAlign: 'left'
    },
    checkContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      mr: 3
    },
    arrowIcon: {
      fontSize: 30
    },
    dropDownContainer: {
      width: 300,
      height: 300,
      overflowY: 'auto',
      boxShadow: '0px 0px 6px 0px #00000014',
      borderRadius: 3,
      zIndex: 2,
      backgroundColor: '#FFFFFF',
      mt: 2.5,
      [theme.breakpoints.up('sm')]: {
        width: 350
      }
    },
    dropDown: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      flexWrap: dropDownOpen ? 'nowrap' : 'wrap'
    },
    menuItem: {
      display: 'flex',
      py: 3,
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    hidden: {
      display: 'none'
    },
    flexCenter: { display: 'flex', alignItems: 'center' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    fixedPosition: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 200,
      [theme.breakpoints.up('sm')]: {
        top: 53
      }
    },
    onboardingButton: {
      marginLeft: 'auto',
      marginRight: 'auto',
      my: 4,
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      width: '75%',
      backgroundColor: theme.palette.newPrimary.main,
      borderRadius: 9,
      textTransform: 'none',
      color: '#FFFFFF',
      fontSize: '18px',
      fontWeight: 700,
      '&.Mui-disabled': {
        backgroundColor: theme.palette.newPrimary.main,
        opacity: 0.5,
        color: '#FFFFFF'
      },
      [theme.breakpoints.up('sm')]: {
        mt: 15,
        width: 520
      }
    }
  };

  // set selected language to null when page first loads
  useEffect(() => {
    handleCourseSelect(null);
  }, [handleCourseSelect]);

  const selectLanguage = e => {
    setSelectedLang(e.currentTarget.id);
    handleCourseSelect(e.currentTarget.id);
    setDropDownOpen(false);
  };

  const languages = targetLanguages.filter(language =>
    getLanguageLabel(language.langCode)
      .toLowerCase()
      .includes(search.toLowerCase())
  );

  const lastFilterResult = _.isEmpty(languages) ? [] : languages;
  const toRender = _.isEmpty(languages) ? lastFilterResult : languages;
  const imgPath = langCode => `${IMAGE_PATH}/onboarding/flags/${langCode}`;

  // $flow-disable-line
  return (
    <Box sx={styles.main}>
      <Box ref={ref} sx={styles.container}>
        <Box sx={styles.flexCenter}>
          <Typography variant="text" sx={styles.labelText}>
            {chooseCourse_txt_i_want_to_learn}
          </Typography>
          {selectedLang && (
            <Box
              component="img"
              sx={styles.flagIcon}
              src={imgPath(`${selectedLang}-flag.png`)}
            />
          )}
        </Box>
        <Box
          sx={[
            styles.flexCenter,
            { cursor: 'pointer', pr: 8 } // Allow user to click nearby, until proper design update
          ]}
          onClick={() => setDropDownOpen(!dropDownOpen)}
        >
          <Box sx={styles.labelTextContainer}>
            <Typography
              variant="text"
              sx={[styles.langText, selectedLang && { color: 'black' }]}
            >
              {selectedLang
                ? getLanguageLabel(selectedLang)
                : txt_select_language}
            </Typography>
          </Box>
          <Box>
            {dropDownOpen ? (
              <ArrowDropUpIcon sx={styles.arrowIcon} />
            ) : (
              <ArrowDropDownIcon sx={styles.arrowIcon} />
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={[styles.dropDownContainer, !dropDownOpen && styles.hidden]}>
        <Box sx={styles.flexColumn}>
          {toRender
            //sort alphabetically
            .sort((a, b) =>
              getLanguageLabel(a.langCode).localeCompare(
                getLanguageLabel(b.langCode)
              )
            )
            .map(language => {
              const { langCode } = language;
              return (
                <Box
                  onClick={selectLanguage}
                  key={langCode}
                  id={langCode}
                  sx={styles.menuItem}
                >
                  <Box sx={styles.dropDown}>
                    <Box
                      component="img"
                      sx={styles.flagIcon}
                      src={imgPath(`${langCode}-flag.png`)}
                      alt={`${langCode.toUpperCase()} Flag`}
                      id={langCode}
                    />
                    <Typography
                      id={langCode}
                      variant="text"
                      sx={[
                        styles.dropDownText,
                        { fontWeight: selectedLang === langCode ? 700 : 400 }
                      ]}
                    >
                      {getLanguageLabel(langCode)}
                    </Typography>
                  </Box>
                  {selectedLang === langCode && (
                    <Box sx={styles.checkContainer}>
                      <Box component="img" src={imgPath('orange-check.png ')} />
                    </Box>
                  )}
                </Box>
              );
            })}
        </Box>
      </Box>
      <OnboardingButton
        disabled={!selectedLang}
        size="large"
        clickAction={() => {
          handleScreenChange(selectedLang);
        }}
        buttonText={btn_continue}
        style={[styles.onboardingButton, dropDownOpen && styles.fixedPosition]}
      />
    </Box>
  );
};

CourseRender.propTypes = {
  getFlagImageUrl: PropTypes.func,
  getLanguageLabel: PropTypes.func,
  handleCourseSelect: PropTypes.func,
  target: PropTypes.string,
  targetLanguages: PropTypes.arrayOf(PropTypes.object),
  search: PropTypes.string
};

export default CourseRender;
