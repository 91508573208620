/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import { matchingStatus } from './constants';
import {
  PREFIX_TRANSLATE,
  LIGHT_GREY,
  DARK_GREEN_COLOR,
  BLOOD_RED,
  DARK_RED_COLOR,
  GREEN_COLOR,
  RED_COLOR,
  WHITE,
  SILVER_COLOR,
  BTN_DARK_RED_COLOR,
  LIGHT_BLUE
} from '../common/constants';
import _ from 'lodash';

type Props = {
  children?: React.Element<any>,
  style?: any,
  onPress?: () => void,
  invisible?: boolean,
  touchAble?: boolean,
  gameType?: string,
  status?: string,
  index: number
};

const FadedText = styled.div`
  opacity: 0.8;
`;
const TranslateButton = styled.div`
  position: relative;
  text-align: left;
  border-color: ${LIGHT_GREY};
  border-radius: 10px;
  border-style: solid;
  border-width: 1.5px;
  box-shadow: 0px -4px 0px 0px inset ${props => {
      if (props.status === matchingStatus.MATCH) {
        return `${DARK_GREEN_COLOR}`;
      } else if (props.status === matchingStatus.NO_MATCH) {
        return `${BLOOD_RED}`;
      } else {
        return _.values(props.buttonStyle[0])[0];
      }
    }};
  flex-direction: row;
  margin: 10px 15px;
  min-height: 100px;
  width: 440px;
  border-color: ${props => {
    if (props.status === matchingStatus.MATCH) {
      return `${DARK_GREEN_COLOR}`;
    } else if (props.status === matchingStatus.NO_MATCH) {
      return `${DARK_RED_COLOR}`;
    } else {
      return _.values(props.buttonStyle[0])[0];
    }
  }};
  background: ${props => {
    if (props.status === matchingStatus.MATCH) {
      return `${GREEN_COLOR}`;
    } else if (props.status === matchingStatus.NO_MATCH) {
      return `${RED_COLOR}`;
    } else {
      return `${WHITE}`;
    }
  }};
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  padding-bottom: 5px;
  &:active {
    border-style: solid;
  }
`;

const LeftBox = styled.div`
  height: 96px;
  min-width: 100px;
  border-right-color: ${props => _.values(props.buttonStyle[0])[0]};
  border-right-width: 1px;
  border-right-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 32px;
`;

const CheckBox = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  border: solid 1px ${props => _.values(props.buttonStyle[0])[0]};
  width: ${props => props.imgSize};
  height: ${props => props.imgSize};
  border-radius: ${props =>
    props.status === matchingStatus.MATCH ||
    props.status === matchingStatus.NO_MATCH
      ? 'none'
      : '12px'};
  background-repeat: no-repeat;
  background: ${props =>
    props.status === matchingStatus.SELECTED
      ? _.values(props.buttonStyle[0])[0]
      : ''};
`;

export const KeyBoardSymbol = styled.span`
  border-radius: 8px;
  color: ${props => {
    const cardStatue = props.cardStatue;
    if (cardStatue === matchingStatus.SELECTED) {
      return _.values(props.buttonStyle[0])[0];
    } else if (cardStatue === matchingStatus.MATCH) {
      return DARK_GREEN_COLOR;
    } else if (cardStatue === matchingStatus.NO_MATCH) {
      return BTN_DARK_RED_COLOR;
    } else {
      return SILVER_COLOR;
    }
  }};
  font-size: 15px;
  font-weight: 700;
  height: 20px;
  line-height: 20px;
  width: 20px;
  position: absolute;
  left: 5px;
  top: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: solid 2px
    ${props => {
      const cardStatue = props.cardStatue;
      if (cardStatue === matchingStatus.SELECTED) {
        return _.values(props.buttonStyle[0])[0];
      } else if (cardStatue === matchingStatus.MATCH) {
        return DARK_GREEN_COLOR;
      } else if (cardStatue === matchingStatus.NO_MATCH) {
        return BTN_DARK_RED_COLOR;
      } else {
        return SILVER_COLOR;
      }
    }};
  background-color: ${props => {
    const cardStatue = props.cardStatue;
    if (cardStatue === matchingStatus.SELECTED) {
      return '';
    } else if (cardStatue === matchingStatus.MATCH) {
      return GREEN_COLOR;
    } else if (cardStatue === matchingStatus.NO_MATCH) {
      return RED_COLOR;
    } else {
      return '';
    }
  }};
  background-image: ${props => {
    const cardStatue = props.cardStatue;
    if (cardStatue === matchingStatus.SELECTED) {
      return LIGHT_BLUE;
    } else {
      return '';
    }
  }};
`;

// let image = require('/images/header/banana-squre-yellow.svg');
function CardQuiz({
  status,
  children,
  style,
  onPress,
  invisible = false,
  touchAble,
  gameType,
  index
}: Props) {
  const cardQuiz = (
    <TranslateButton
      status={status}
      invisible={invisible}
      onClick={invisible ? () => {} : onPress}
      disabled={invisible || touchAble}
      buttonStyle={style}
    >
      <KeyBoardSymbol cardStatue={status} buttonStyle={style}>
        {index}
      </KeyBoardSymbol>
      <LeftBox buttonStyle={style}>{getCheckboxIcon(status, style)}</LeftBox>
      {children}
    </TranslateButton>
  );
  return invisible ? <FadedText>{cardQuiz}</FadedText> : cardQuiz;
}

const getCardIconSize = status => {
  return status === matchingStatus.SELECTED || status === matchingStatus.VISIBLE
    ? '19px'
    : '35px';
};

const getCheckboxIcon = (status, style, imgSize = getCardIconSize(status)) => {
  if (status === matchingStatus.NO_MATCH) {
    return (
      <img
        src={`${PREFIX_TRANSLATE}outline-white-cross.svg`}
        alt={`${PREFIX_TRANSLATE}outline-white-cross.svg`}
      />
    );
  } else if (status === matchingStatus.MATCH) {
    return (
      <img
        src={`${PREFIX_TRANSLATE}outline-white-check.svg`}
        alt={`${PREFIX_TRANSLATE}outline-white-check.svg`}
      />
    );
  } else {
    return <CheckBox buttonStyle={style} status={status} imgSize={imgSize} />;
  }
};
export { CardQuiz };
