/* @flow */

import createSagaMiddleware from 'redux-saga';
import { createMigrate, persistStore, persistReducer } from 'redux-persist';

import reducers from '../common/reducers';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { applyMiddleware, compose, createStore } from 'redux';
import createIdbStorage from '@piotr-cz/redux-persist-idb-storage';

import { rootSaga } from '../sagas';
import updateMiddleware from './updateMiddleware';
import initialState from './initialState';

const migrations = {
  4: state => state,
  5: state => ({
    ...state,
    onboarding: initialState.onboarding,
    tracker: initialState.tracker
  })
};

export const configureStore = () => {
  const persistConfig = {
    key: 'root',
    version: 5, // Erase old state
    migrate: createMigrate(migrations, { debug: true }),
    storage: createIdbStorage({ name: 'myApp', storeName: 'keyval' }),
    blacklist: ['config', 'temporary', 'purchase']
  };

  const composeEnhancers =
    (process.env.NODE_ENV === 'development' &&
      typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const sagaMiddleware = createSagaMiddleware();
  const persistedReducer = persistReducer(persistConfig, reducers);
  const DEBUG = process.env.NODE_ENV !== 'production';
  const middleware = DEBUG
    ? [thunk, sagaMiddleware, updateMiddleware, logger]
    : [thunk, sagaMiddleware, updateMiddleware];
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );
  const persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);
  return { store, persistor };
};
