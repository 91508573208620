/* @flow */
import React, { Component } from 'react';
import { Cell } from './GrammarCell';
type Props = {
  width: number,
  heightArr: number[],
  flexArr: number[],
  data: Object,
  style: Object,
  textStyle: Object,
  flex: number,
  borderStyle: Object,
  widthArr: Object,
  isTargetLangSymbol: boolean
};
class Col extends Component<Props, any> {
  static defaultProps: any;
  render() {
    const {
      data,
      style,
      width,
      heightArr,
      flex,
      textStyle,
      borderStyle,
      isTargetLangSymbol
    } = this.props;

    return data ? (
      <div
        style={[
          width ? { width: width } : { flex: 1 },
          flex && { flex: flex },
          style
        ]}
      >
        {data.map((item, i) => {
          const height = heightArr && heightArr[i];
          return (
            <Cell
              key={i}
              data={item}
              width={width}
              height={height}
              textStyle={textStyle}
              borderStyle={borderStyle}
              isTargetLangSymbol={isTargetLangSymbol}
            />
          );
        })}
      </div>
    ) : null;
  }
}

class Cols extends Component<Props, any> {
  static defaultProps: any;
  render() {
    const {
      data,
      style,
      widthArr,
      heightArr,
      flexArr,
      textStyle,
      borderStyle
    } = this.props;
    let widthNum = 0;
    if (widthArr) {
      for (let i = 0; i < widthArr.length; i++) {
        widthNum += widthArr[i];
      }
    }

    return data ? (
      <div style={[styles.cols, widthNum && { width: widthNum }]}>
        {data.map((item, i) => {
          const flex = flexArr && flexArr[i];
          const width = widthArr && widthArr[i];
          return (
            <Col
              key={i}
              data={item}
              width={width}
              heightArr={heightArr}
              flex={flex}
              style={style}
              textStyle={textStyle}
              borderStyle={borderStyle}
            />
          );
        })}
      </div>
    ) : null;
  }
}

const styles = {
  cols: {
    flexDirection: 'row'
  }
};

export { Col, Cols };
