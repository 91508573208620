/* @flow */

export const TRIGGER_PURCHASE_PRODUCT: string = 'TRIGGER_PURCHASE_PRODUCT';
export const TRIGGER_OPEN_SHOP: string = 'TRIGGER_OPEN_SHOP';
export const FORM_GOOGLE_URL = 'https://goo.gl/forms/vBTmMKwI1sLQ16oM2';
export const IOS_APP_UNIVERSAL_ID = '1403783779';
export const LOAD_FIREBASE_PROMOTIONS = 'LOAD_FIREBASE_PROMOTIONS';
export const TOGGLE_REPAIRED_UNIT_MODAL = 'TOGGLE_REPAIRED_UNIT_MODAL';
export const DISABLE_REPAIRED_UNIT_TOOLTIPS = 'DISABLE_REPAIRED_UNIT_TOOLTIPS';
export const SET_SELECTED_PURCHASE_PRODUCT = 'SET_SELECTED_PURCHASE_PRODUCT';
export const CLOSE_PAYWALL = 'CLOSE_PAYWALL';
