// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTrackerState } from '../common/selectors';
import Tracker from '../util/tracker';

import * as TrackerActions from './actions/trackerActions';

Tracker.initTracker();

class TrackingService extends Component {
  componentDidUpdate(prevProps, _prevState) {
    const { actions, state } = this.props;
    if (!prevProps.state.tracker.startTracking && state.tracker.startTracking) {
      actions.tracker.trackEvent();
    }
  }

  render() {
    return false;
  }
}

const mapStateToProps = state => ({
  state: {
    tracker: getTrackerState(state)
  }
});

const mapDispatchToProps = dispatch => ({
  actions: {
    tracker: bindActionCreators(TrackerActions, dispatch)
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackingService);
