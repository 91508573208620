/* @flow */

import { connect } from 'react-redux';
import { PreHeaderConversationWithRouter } from '../../components/main-components';
import { getLabels, getLabel } from '../../util';
import { getCurrentCourseData } from '../../lessons/selectors';
import { getTranscriptionState } from '../../profile/selectors';

export const mapStateToProps = (state: Object, ownProps: Object) => {
  const {
    routes: { showCommonScreen },
    dialog: { translate: translationState },
    chatbots: { label }
  } = state;
  const { features } = getCurrentCourseData(state);
  const { hasTranscript } = features;

  return {
    ...ownProps,
    translationState,
    transcriptState: getTranscriptionState(state),
    showCommonScreen,
    hasTranscript,
    name: getLabel(state, label, label),

    ...getLabels(
      state,
      'txt_auto_play',
      'txt_translation',
      'modal_txt_quitLesson',
      'modal_txt_description_lesson'
    )
  };
};

export default connect(mapStateToProps)(PreHeaderConversationWithRouter);
