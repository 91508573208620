/* @flow */
import * as React from 'react';
import { WHITE } from '../../../common/constants';

type Props = {
  children?: any,
  style?: Object,
  isAnswerLengthPressed: boolean
};

function ContainerSpelling({ children, style, isAnswerLengthPressed }: Props) {
  return (
    // eslint-disable-next-line react/no-unknown-property
    <div style={styles.containerStyle} horizontal={'false'}>
      {isAnswerLengthPressed && <div style={styles.coverStyle}></div>}
      <div style={style}>{children}</div>
    </div>
  );
}

const styles = {
  containerStyle: { flex: 0, paddingHorizontal: 10 },
  coverStyle: {
    width: 811,
    height: 150,
    background: WHITE,
    position: 'absolute',
    zIndex: 1,
    opacity: 0
  }
};

export { ContainerSpelling };
