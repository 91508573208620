/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import Analytic from '../../../util/Analytic';

/* Containers */
import FlashcardContainer from '../../containers/FlashcardContainer';
import MatchingContainer from '../../containers/MatchingContainer';
import QuizContainer from '../../containers/QuizContainer';
import TranslateGameContainer from '../../containers/TranslateGameContainer';
import ListeningContainer from '../../containers/ListeningContainer';
import SpellingContainer from '../../containers/SpellingContainer';
import CorrectSentenceContainer from '../../containers/CorrectSentenceContainer';
import OrderSentenceContainer from '../../containers/OrderSentenceContainer';
import CompleteSentenceContainer from '../../containers/CompleteSentenceContainer';
import DialogContainer from '../../containers/DialogContainer';
import CompleteScreenContainer from '../../containers/CompleteScreenContainer';
import GrammarScreenContainer from '../../containers/GrammarScreenContainer';

/* Components */
import { GameHeader } from '../main-components/GameHeader';
import {
  EXAM_MAX_HEARTS,
  FLASHCARD,
  MATCHING,
  QUIZ,
  TRANSLATEGAME,
  LISTENING,
  SPELLING,
  CORRECT_SENTENCE,
  ORDER_SENTENCE,
  ORDER_SENTENCE_REVERSE,
  COMPLETE_SENTENCE,
  DIALOGUE,
  SPEAKING,
  WRITING
} from '../../constants';
import { PREFIX_LESSON } from '../../../common/constants';
import { WHITE, SHOW_TUTORIAL, TRUE, FALSE } from '../../../common/constants';
import { QuitModal } from '../sub-components/QuitModal';
import { HintDialog } from '../main-components/HintDialog';
import { MotivatedMessageCard } from '../../MotivatedMessageCard';
import SpeakingContainer from '../../containers/SpeakingContainer';
import WritingContainer from '../../containers/WritingContainer';
import { TutorialMessageCard } from '../../TutorialMessageCard';
import { stopSound } from '../../../util';

type State = {
  modal: boolean,
  isMgsShown: boolean
};
type Props = {
  isRandomTest?: boolean,
  modal_txt_quitExam: string,
  modal_txt_quitLesson: string,
  modal_txt_exam: string,
  modal_txt_lesson: string,
  modal_txt_description_exam: string,
  modal_txt_description_lesson: string,
  modal_error_description: string,
  modal_error_faq: string,
  modal_error_report: string,
  notification_text_confirm: string,
  notification_text_cancel: string,
  hint_txt_topText1: string,
  hint_txt_topText2: string,
  hint_txt_checkBox: string,
  progress: number,
  progressMax: number,
  stopDialogSound: Function,
  showCompleteScreen: boolean,
  showHintDialog: boolean,
  showNoStarsDialog: boolean,
  hintDialogEnabled: boolean,
  showReportErrorDialog: boolean,
  isExam: boolean,
  isWriting: boolean,
  toggleCompleteScreen: Function,
  toggleHintDialog: Function,
  toggleEnableHintDialog: Function,
  toggleNoStarsDialog: Function,
  toggleReportError: Function,
  toggleCommonScreen: Function,
  resetGameSkipCount: Function,
  tooltip_txt_flag: string,
  tooltip_txt_transcript: string,
  exitGame: Object,
  cancelGame: Function,
  wrongAnswered: number,
  gameWrongAnswered: number,
  replayGame: Function,
  examFailed: boolean,
  sendEmail: Function,
  screenshot: string,
  saveScreenRef: Function,
  grammar: Object,
  complete_txt_failExam: string,
  complete_txt_completed: string,
  complete_txt_earned: string,
  showGrammarScreen: boolean,
  params?: any,
  navigation?: Object,
  setQueue: Function,
  preparedReport: Function,
  hasTranscript: boolean,
  showCommonScreen: boolean,
  report_text_sendfeedback: string,
  report_text_cancle: string,
  landing_txt_connectInternet: string,
  btn_continue: string,
  match: Object,
  doCurrentGameScreen: Function,
  isCurrentGameWrong: boolean,
  motivatedMgs_txt: string,
  gameType: string,
  resetAnsweredWrongCount: Function,
  increaseAnsweredWrongCount: Function,
  toggleGameCompleteScreen: Function,
  gameWrongCount: number,
  isShowTutorial: boolean,
  showTutorial: boolean,
  setShowTutorial: Function,
  tutorial_txt_easy_learn: string,
  toggleTutorialMotivate: Function,
  isTutorialMotivate: string,
  closeTutorialMotivate: Function,
  replayChatbot: Function,
  isChatbot: boolean,
  isShowMotivation: boolean,
  writing_skip: string,
  onCloseTutorialMotivate: Function,
  setShowTutorial: Function,
  history: Object
};
const shopBackground = `${PREFIX_LESSON}image-shop-bg-md.png`;

const GameScreenContainer = styled.div`
  background-image: ${props =>
    props.showCompleteScreen ? `url(${shopBackground})` : 'unset'};
  flex: 1;
  overflow: ${props => (props.isShowTutorial ? 'scroll' : 'unset')};
  background-color: ${WHITE};
  display: block;
  align-items: center;
  height: ${props =>
    props.isShowTutorial || props.showTutorial
      ? 'calc(100vh - 100px)'
      : '100vh'};
  justify-content: center;
  border-radius: ${props =>
    props.showCompleteScreen && !props.isShowTutorial
      ? 'unset'
      : '40px 40px 0 0'};
  box-shadow: ${props =>
    props.isShowTutorial || props.showTutorial
      ? '1px 20px 58px 0px rgba(78, 78, 78, 0.75)'
      : 'unset'};
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const GameContainer = styled.div`
  flex: 1;
  flex-direction: column;
  background:${WHITE};
  ${
    //TODO to fix gamescreen small screen on another card
    '' /* min-height: ${props =>
    props.isShowTutorial || props.showTutorial ? 'unset' : '630px'}; */
  }
  height: ${props =>
    props.isShowTutorial || props.showTutorial
      ? 'calc(100vh - 100px)'
      : '100vh'};
  align-items: center;
  display: flex;
`;

class GameScreen extends React.Component<Props, State> {
  constructor(props: Object) {
    super(props);
    this.state = {
      modal: false,
      isMgsShown: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  static defaultProps: any;
  _container: Object;

  componentDidMount() {
    const {
      setQueue,
      resetAnsweredWrongCount,
      resetGameSkipCount,
      doCurrentGameScreen
    } = this.props;
    setQueue([true, false, false]);
    resetAnsweredWrongCount();
    resetGameSkipCount();
    doCurrentGameScreen();
  }

  componentDidUpdate() {
    if (this.props.gameWrongCount === 3) {
      this.setState(prevState => ({
        isMgsShown: !prevState.isMgsShown
      }));
      this.props.resetAnsweredWrongCount();
    }
  }

  submitReport() {
    stopSound();
    const ref = this._container;
    this.props.preparedReport(ref);
  }

  goToHome(isShowTutorial?: boolean) {
    const { isRandomTest, history, toggleGameCompleteScreen } = this.props;
    if (isRandomTest || isShowTutorial) {
      window.history.back();
      toggleGameCompleteScreen();
    } else {
      history.goBack();
    }
  }

  replayGame = () => {
    const { isChatbot } = this.props;
    this.props.resetAnsweredWrongCount();

    isChatbot ? this.props.replayChatbot() : this.props.replayGame();
  };

  pressCloseMessage = () => {
    this.setState(prevState => ({
      isMgsShown: !prevState.isMgsShown
    }));
  };
  pressCloseTutorialMessageCard = () => {
    this.props.closeTutorialMotivate();
  };
  checkCurrentGameAnswer = () => {
    const {
      isCurrentGameWrong,
      increaseAnsweredWrongCount,
      gameWrongCount
    } = this.props;

    if (isCurrentGameWrong) {
      increaseAnsweredWrongCount();
    } else if (isCurrentGameWrong === false) {
      this.props.resetAnsweredWrongCount();
    } else {
      if (gameWrongCount === 3) {
        this.setState(prevState => ({
          isMgsShown: !prevState.isMgsShown
        }));
        this.props.resetAnsweredWrongCount();
      }
    }
  };
  render() {
    const renderChild = ({
      gameType,
      nextGameScreen,
      getCardStyle
    }: Object): React.Element<any> => {
      switch (gameType) {
        case FLASHCARD:
          return <FlashcardContainer onPressNext={nextGameScreen} />;
        case MATCHING:
          return <MatchingContainer getCardStyle={getCardStyle} />;
        case TRANSLATEGAME:
          return <TranslateGameContainer getCardStyle={getCardStyle} />;
        case LISTENING:
          return (
            <ListeningContainer
              onPressNext={nextGameScreen}
              getCardStyle={getCardStyle}
            />
          );
        case QUIZ:
          return <QuizContainer getCardStyle={getCardStyle} />;
        case CORRECT_SENTENCE:
          return <CorrectSentenceContainer getCardStyle={getCardStyle} />;
        case SPELLING:
          return <SpellingContainer onPressNext={nextGameScreen} />;
        case ORDER_SENTENCE:
          return <OrderSentenceContainer onPressNext={nextGameScreen} />;
        case ORDER_SENTENCE_REVERSE:
          return (
            <OrderSentenceContainer
              onPressNext={nextGameScreen}
              isReverse={true}
            />
          );
        case SPEAKING:
          return <SpeakingContainer onPressNext={nextGameScreen} />;
        case WRITING:
          return <WritingContainer onPressNext={nextGameScreen} />;
        case COMPLETE_SENTENCE:
          return <CompleteSentenceContainer onPressNext={nextGameScreen} />;
        case DIALOGUE:
          return <DialogContainer onPressNext={nextGameScreen} />;
        default:
          console.warn(`Cannot load container for gameType ${gameType}`);
          return <div />;
      }
    };
    const props = this.props;
    const {
      progress = 0,
      progressMax = 100,
      showCompleteScreen,
      isExam,
      isWriting,
      wrongAnswered,
      examFailed,
      hasTranscript,
      modal_txt_quitExam,
      modal_txt_quitLesson,
      modal_txt_exam,
      modal_txt_lesson,
      modal_txt_description_exam,
      modal_txt_description_lesson,
      complete_txt_failExam,
      complete_txt_completed,
      complete_txt_earned,
      showGrammarScreen,
      tooltip_txt_flag,
      tooltip_txt_transcript,
      notification_text_confirm,
      notification_text_cancel,
      showHintDialog,
      showNoStarsDialog,
      hintDialogEnabled,
      toggleNoStarsDialog,
      toggleHintDialog,
      toggleEnableHintDialog,
      hint_txt_topText1,
      hint_txt_topText2,
      hint_txt_checkBox,
      btn_continue,
      motivatedMgs_txt,
      gameType,
      isRandomTest = false,
      isShowTutorial,
      showTutorial,
      isTutorialMotivate,
      tutorial_txt_easy_learn,
      isShowMotivation,
      writing_skip,
      onCloseTutorialMotivate,
      setShowTutorial
    } = props;
    return (
      <GameScreenContainer
        showCompleteScreen={showCompleteScreen}
        isShowTutorial={isShowTutorial}
        showTutorial={showTutorial}
        ref={r => {
          if (r) {
            this._container = r;
          }
        }}
      >
        {showCompleteScreen ? (
          <CompleteScreenContainer
            showTutorial={showTutorial}
            isShowTutorial={isShowTutorial}
            wrongAnswered={wrongAnswered}
            showCompleteScreen={showCompleteScreen}
            onNext={() => {
              Analytic.log(Analytic.key.L3, {
                category: Analytic.key.L3,
                action: 'Click'
              });
              Analytic.logGA(Analytic.key.L3, {
                category: Analytic.key.L3,
                action: 'Click'
              });
              // eslint-disable-next-line
              localStorage.setItem(SHOW_TUTORIAL, FALSE);
              this.props.setShowTutorial(
                // eslint-disable-next-line
                localStorage.getItem(SHOW_TUTORIAL) === TRUE
              );
            }}
            onReplay={this.replayGame}
            examFailed={examFailed}
            complete_txt_failExam={complete_txt_failExam}
            complete_txt_completed={complete_txt_completed}
            chatbot_txt_complete_body={''}
            complete_txt_earned={complete_txt_earned}
            modal_txt_lesson={modal_txt_lesson}
            modal_txt_exam={modal_txt_exam}
            loading_txt
            isExam={isExam}
            isChatbot={false}
          />
        ) : (
          <React.Fragment>
            <GameHeader
              progress={progress}
              progressMax={progressMax}
              modal_txt_quitLesson={modal_txt_quitLesson}
              onBack={() => {
                stopSound();
                this.toggle();
              }}
              isExam={isExam}
              isShowTutorial={isShowTutorial}
              showTutorial={showTutorial}
              isRandomTest={isRandomTest}
              isWriting={isWriting}
              heartCount={EXAM_MAX_HEARTS - wrongAnswered}
              hasTranscript={hasTranscript}
              tooltip_txt_flag={tooltip_txt_flag}
              tooltip_txt_transcript={tooltip_txt_transcript}
            />
            <GameContainer
              isShowTutorial={isShowTutorial}
              showTutorial={showTutorial}
            >
              {isTutorialMotivate ? (
                <TutorialMessageCard
                  pressCloseMessage={this.pressCloseTutorialMessageCard}
                  btn_continue={btn_continue}
                  isShowTutorial={isShowTutorial}
                  tutorial_txt_easy_learn={tutorial_txt_easy_learn}
                  writing_skip={writing_skip}
                  onCloseTutorialMotivate={onCloseTutorialMotivate}
                  setShowTutorial={setShowTutorial}
                />
              ) : null}
              {!showGrammarScreen ? (
                !isExam &&
                !isRandomTest &&
                isShowMotivation &&
                this.state.isMgsShown &&
                !isShowTutorial ? (
                  <MotivatedMessageCard
                    pressCloseMessage={this.pressCloseMessage}
                    btn_continue={btn_continue}
                    motivatedMgsText={motivatedMgs_txt}
                  />
                ) : (
                  renderChild(props)
                )
              ) : (
                <GrammarScreenContainer />
              )}
            </GameContainer>
            <QuitModal
              isOpen={this.state.modal}
              toggle={this.toggle.bind(this)}
              isExam={isExam || isRandomTest}
              modal_txt_quitExam={modal_txt_quitExam}
              modal_txt_quitLesson={modal_txt_quitLesson}
              modal_txt_description_exam={modal_txt_description_exam}
              modal_txt_description_lesson={modal_txt_description_lesson}
              notification_text_confirm={notification_text_confirm}
              notification_text_cancel={notification_text_cancel}
              goToHome={() => {
                Analytic.log(Analytic.key.L2, {
                  category: Analytic.key.L2,
                  action: 'Check',
                  label: gameType
                });
                Analytic.logGA(Analytic.key.L3, {
                  category: Analytic.key.L2,
                  action: 'Check',
                  label: gameType
                });
                this.goToHome();
              }}
            />
          </React.Fragment>
        )}
        <HintDialog
          visible={showHintDialog || showNoStarsDialog}
          isInfoDialog={showHintDialog}
          enabled={hintDialogEnabled}
          toggleHintDialog={
            showNoStarsDialog ? toggleNoStarsDialog : toggleHintDialog
          }
          toggleEnableHintDialog={toggleEnableHintDialog}
          hint_txt_topText1={hint_txt_topText1}
          hint_txt_topText2={hint_txt_topText2}
          hint_txt_checkBox={hint_txt_checkBox}
          btn_continue={btn_continue}
        />
      </GameScreenContainer>
    );
  }
}

export { GameScreen };
