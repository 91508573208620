import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as PurchaseActions from '../actions/PurchaseActions';
import InjectedCheckoutForm from '../../shop/components/main-components/StripeCheckoutForm';

class PurchaseScene extends Component {
  componentDidMount() {}

  componentDidUpdate() {}

  shouldComponentUpdate() {
    return true;
  }

  render() {
    const { actions, state } = this.props;

    return (
      <>
        {state.purchase.isModalVisible ? <InjectedCheckoutForm /> : null}
        <button onClick={actions.purchase.toggleModalVisibility}>Press</button>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    state: {
      purchase: state.purchase
    }
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      purchase: bindActionCreators(PurchaseActions, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseScene);
