import initialState from '../system/initialState';
import * as TrackerTypes from './filterConstants';

export default (state = initialState.tracker, action) => {
  switch (action.type) {
    case TrackerTypes.requestTracking:
      return {
        ...state,
        eventName: action.event,
        startTracking: true,
        tracking: false,
        trackingData: action.data,
        userProperties: action.userProps
      };
    case TrackerTypes.startTracking:
      return {
        ...state,
        startTracking: false,
        tracking: true
      };
    case TrackerTypes.completeTracking:
      return {
        ...state,
        startTracking: false,
        tracking: false
      };
    case TrackerTypes.trackingError:
      return {
        ...state,
        startTracking: false,
        error: action.error
      };
    default:
      return state;
  }
};
