/* @flow */
import * as React from 'react';
import { playSound } from '../../../util';
import styled from 'styled-components';
import { BLACK, WHITE, BLACK_HOVER } from '../../../common/constants';
import { VOICE_VERY_SLOW } from '../../../profile/constants';
import _ from 'lodash';

const snailImage = require('../../../img/icons/circle-slow-white-32-px.svg');
const snailImageActive = require('../../../img/icons/cirecleTurtleVvSlow40Px@2x.png');

const PlayButtonContainer = styled.div`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: 5px;
  ${'' /* position: absolute; */}
  left: 50%;
  top: 105%;
  bottom: -10px;
  background: ${WHITE};
  cursor: pointer;
  &:hover {
    border-color: ${BLACK_HOVER};
    #buttonIcon {
      opacity: 0.4;
    }
  }
  &:active {
    background: ${BLACK};
    #buttonIcon1 {
      display: inline !important;
    }
  }
`;

const SpeakerImage = styled.img`
  position: relative;
  width: 32px;
  height: 32px;
`;

const SpeakerImageActive = styled.img`
  display: none;
  position: absolute;
  width: 32px;
  height: 32px;
  zindex: 99;
`;

type Props = {
  soundFile: string,
  voiceSpeed: number
};

const VoiceSpeedButton = ({ soundFile, voiceSpeed }: Props) => {
  return (
    <PlayButtonContainer onClick={() => onPlay(soundFile, voiceSpeed)}>
      <SpeakerImage id="buttonIcon" src={snailImage} />
      <SpeakerImageActive id="buttonIcon1" src={snailImageActive} />
    </PlayButtonContainer>
  );
};

const onPlay = (soundFile: string, voiceSpeed: number) => {
  const slowVoiceSpeed = _.isEqual(VOICE_VERY_SLOW, voiceSpeed)
    ? VOICE_VERY_SLOW
    : voiceSpeed - VOICE_VERY_SLOW;
  playSound(soundFile, slowVoiceSpeed);
};

export { VoiceSpeedButton };
