/* @flow */
import React, { Fragment } from 'react';
import { CharacterCard } from '../sub-components';

type Props = {
  character: string,
  onPress?: Function,
  backgroundColor?: string,
  borderColor?: string,
  textColor?: string,
  invisible: boolean,
  showHint?: boolean,
  status?: boolean,
  isCompleteSentenceCard?: boolean,
  isResultShown?: boolean,
  cardStatus?: string,
  sort?: boolean,
  isDialog?: boolean,
  isAnwserLenght: boolean,
  isTargetLangSymbol: boolean
};

const TopCharacterCard = ({
  character,
  onPress,
  backgroundColor,
  borderColor,
  textColor,
  cardStatus,
  sort,
  invisible = false,
  showHint = false,
  status = false,
  isCompleteSentenceCard = false,
  isResultShown = false,
  isDialog = false,
  isAnwserLenght,
  isTargetLangSymbol
}: Props) => {
  return (
    <Fragment>
      <CharacterCard
        character={character}
        onPress={onPress}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        textColor={textColor}
        invisible={
          (!status && showHint && isResultShown) || (invisible && !showHint)
        }
        isTopCompleteSentenceCard={isCompleteSentenceCard}
        isGray={!isResultShown && showHint}
        cardStatus={cardStatus}
        sort={sort}
        isDialog={isDialog}
        isAnwserLenght={isAnwserLenght}
        isTargetLangSymbol={isTargetLangSymbol}
      />
    </Fragment>
  );
};

export { TopCharacterCard };
export default TopCharacterCard;
