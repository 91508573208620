/* @flow */
import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import styled from 'styled-components';

import {
  WHITE,
  MARIGOLD,
  DARK_YELLOW,
  DARK_GREY_1,
  BLACK_TWO_COLOR,
  PREFIX_LESSON
} from '../../../common/constants';
import { DEFAULT_FONT } from '../../../util';

const ModalImage = styled.img`
  display: flex;
  width: 150px;
  margin: auto;
  margin-top: 0 !important;
`;

const RandomConfirmButton = styled.button`
  width: 100%;
  height: 55px;
  border-radius: 20px;
  box-shadow: inset 0 -4px 0 0 ${DARK_YELLOW};
  border: solid 1px ${DARK_YELLOW};
  background-color: ${MARIGOLD};
  font-size: 18px;
  font-weight: bold;
  color: ${WHITE};
  text-transform: uppercase;
  &:hover {
    box-shadow: none;
    opacity: 0.4;
    border-color: ${MARIGOLD};
  }
  &:active {
    box-shadow: none;
    background: ${MARIGOLD};
    opacity: 1;
  }
`;

const ModalBodyText = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${DARK_GREY_1};
  width: 277px;
  line-height: 22px;
  margin: auto;
`;

const ModalContentContainer = styled.div`
  margin: auto 25px;
`;

type Props = {
  visible: boolean,
  spaced_repetition_congrats_txt: string,
  random_test_modal_confirm_txt: string,
  toggleModal: Function,
  toggle: Function
};

const lingImg = `${PREFIX_LESSON}app_img_spacedRepetition_repairedEggCircle129Px.png`;

class RepairedUnitModal extends Component<Props> {
  render() {
    const {
      visible,
      spaced_repetition_congrats_txt,
      random_test_modal_confirm_txt,
      toggleModal
    } = this.props;
    const {
      dropZone,
      contentContainer,
      descContainer,
      modalBodyContainer,
      modalHeader,
      modalFooter,
      modalContainer
    } = styles;

    return (
      <Modal
        isOpen={visible}
        transparent={true}
        onRequestClose={toggleModal}
        style={modalContainer}
      >
        <div style={dropZone}>
          <div style={contentContainer}>
            <ModalBody style={descContainer}>
              <ModalHeader style={modalHeader}>
                <ModalImage src={lingImg} alt={lingImg} />
              </ModalHeader>
              <ModalContentContainer>
                <ModalBody style={modalBodyContainer}>
                  <React.Fragment>
                    <ModalBodyText>
                      {spaced_repetition_congrats_txt}
                    </ModalBodyText>
                  </React.Fragment>
                </ModalBody>
              </ModalContentContainer>
            </ModalBody>
            <ModalFooter style={modalFooter}>
              <RandomConfirmButton onClick={toggleModal}>
                {random_test_modal_confirm_txt}
              </RandomConfirmButton>
            </ModalFooter>
          </div>
        </div>
      </Modal>
    );
  }
}

const styles = {
  modalContainer: {
    position: 'absolute',
    width: 500,
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)'
  },
  modalHeader: {
    borderWidth: 0
  },
  dropZone: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    paddingHorizontal: 56,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },
  contentContainer: {
    backgroundColor: WHITE,
    borderRadius: 13,
    paddingHorizontal: 35,
    paddingBottom: 35
  },
  descContainer: {
    alignItems: 'center',
    marginBottom: 0
  },
  imgContainer: {
    marginTop: 12,
    marginBottom: 30
  },
  modalBodyContainer: {
    textAlign: 'center',
    padding: 0
  },
  lingImage: {
    width: 178,
    height: 198
  },
  modalFooter: {
    borderTop: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 20,
    paddingRight: 40,
    paddingLeft: 40,
    paddingTop: 0
  },
  descText: {
    fontSize: 15,
    color: BLACK_TWO_COLOR,
    fontFamily: DEFAULT_FONT,
    textAlign: 'center'
  },
  confirmBtn: {
    backgroundColor: MARIGOLD,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 5,
    borderRadius: 8
  },
  confirmText: {
    fontSize: 14,
    color: BLACK_TWO_COLOR,
    fontFamily: DEFAULT_FONT
  }
};

export { RepairedUnitModal };
