/* @flow */
// $FlowFixMe
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useMemo } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import _ from 'lodash';
import {
  ProfileSubScritionAds,
  ProfileStateInfo,
  ProfileInfo
} from '../../../sub-components';
import { FollowUsSection } from '../../sub-components/FollowUsSection';
import {
  PREFIX_LESSON,
  PREFIX_BEDGE,
  IMAGE_PATH,
  SHARP_SIGN,
  BANANA_POINT_SYMBOL
} from '../../../../common/constants';
import {
  AchievementContainer,
  AchievementTable,
  AchievementTableTitle,
  Container,
  LeaderBoardContainer,
  LeaderBoardTableTitle,
  LeaderBoardTableTitleContainer,
  Line,
  NavContainer,
  ScoreText,
  SeeMoreButtonContainer,
  SpinnerContainer,
  StateContent,
  StateImg,
  StateScoreContainer,
  StateText,
  ViewAllStyle
} from './ProfileInfoSection.styles';
import { STRIPE_YEARLY } from '../../../../shop/constants';
import { AchievementList } from '../AchievementList';
import { DailyGoalSection } from '../DailyGoalSection';

const lingLoading = `${IMAGE_PATH}ling-loader.gif`;
const badgeMadal = `${PREFIX_BEDGE}madal-circle-32-px.svg`;
const bananaSqureImg = `${PREFIX_LESSON}banana-circle-32-px.svg`;
const starSqureImg = `${PREFIX_LESSON}star-circle-32-px.svg`;

type Props = {
  goal: Object,
  achievement_txt_bigSpender: string,
  achievement_txt_champion: string,
  achievement_txt_diligentMaster: string,
  achievement_txt_overAchiever: string,
  achievement_txt_title: string,
  achievement_txt_wealthy: string,
  activeTab: string,
  bananas: number,
  bannerVisible: boolean,
  championDescription: string,
  currentStreak: number,
  diligentDescription: string,
  fluency: number,
  history: Object,
  isProUser: boolean,
  levelChamp: number,
  levelSpender: number,
  levelDiligent: number,
  levelAchiever: number,
  levelWealthy: number,
  longestStreak: number,
  onBuy: Function,
  overAchieveDescription: string,
  planData: string,
  profile_info_txt: string,
  profile_txt_currentStreak: string,
  profile_txt_dayStreak: string,
  profile_txt_follow_us: string,
  profile_txt_leaderboard: string,
  profile_txt_longestStreak: string,
  profile_txt_manage_subcription: string,
  profile_txt_todayAchieve: string,
  profile_txt_viewAll: string,
  promo_txt_fluency: string,
  purchase_button_subscribe_now: string,
  purchase_txt_benefit_details_01: string,
  shop_txt_7_day_trial: string,
  spenderDescription: string,
  coins: number,
  subscriptionData: Object,
  toggleBanner: Function,
  toggleTab: Function,
  toggleSetDailyGoalModal: Function,
  user: Object,
  userInfo: Object,
  userRank: number,
  wealthyDescription: string,
  weeklyGraphData: number[],
  dailyBananas: number,
  profile_overview_txt: string,
  profile_set_your_goal_txt: string
};

const ProfileInfoSection = ({
  toggleSetDailyGoalModal,
  achievement_txt_bigSpender,
  achievement_txt_champion,
  achievement_txt_diligentMaster,
  achievement_txt_overAchiever,
  achievement_txt_title,
  achievement_txt_wealthy,
  activeTab,
  bananas,
  bannerVisible,
  championDescription,
  currentStreak,
  diligentDescription,
  fluency,
  goal,
  history,
  isProUser,
  levelAchiever,
  levelChamp,
  levelDiligent,
  levelSpender,
  levelWealthy,
  longestStreak,
  onBuy,
  overAchieveDescription,
  planData,
  profile_info_txt,
  profile_txt_currentStreak,
  profile_txt_dayStreak,
  profile_txt_follow_us,
  profile_txt_leaderboard,
  profile_txt_longestStreak,
  profile_txt_manage_subcription,
  profile_txt_todayAchieve,
  profile_txt_viewAll,
  promo_txt_fluency,
  purchase_button_subscribe_now,
  purchase_txt_benefit_details_01,
  shop_txt_7_day_trial,
  spenderDescription,
  coins,
  subscriptionData,
  toggleBanner,
  toggleTab,
  user,
  userInfo,
  userRank,
  wealthyDescription,
  weeklyGraphData,
  dailyBananas,
  profile_overview_txt,
  profile_set_your_goal_txt
}: Props) => {
  const trialUsedIP = user.trialUsedIP !== '';
  const hasPurchasedDetail = (subscriptionData: Object) =>
    (subscriptionData.display_items &&
      subscriptionData.display_items[0].type === 'sku') ||
    !_.isUndefined(subscriptionData.nextPayment);
  const hasPurchasedDetailMemo = useMemo(
    () => hasPurchasedDetail(subscriptionData),
    [
      subscriptionData.display_items && subscriptionData.display_items[0].type,
      subscriptionData.nextPayment
    ]
  );
  const lingTwoEmail = _.get(user, 'email', '');
  const { email } = userInfo;
  if (_.isUndefined(email)) {
    userInfo.email = lingTwoEmail;
  }
  return (
    <Container>
      {_.isEmpty(subscriptionData) && !isProUser ? (
        <SpinnerContainer>
          <img src={lingLoading} alt={lingLoading} />
        </SpinnerContainer>
      ) : (
        <Fragment>
          <ProfileInfo userInfo={userInfo} bananas={bananas} />
          <NavContainer>
            <Nav tabs className={'profile-nav-style'}>
              <NavItem className={'profile-tab-style-item'}>
                <NavLink
                  className={
                    activeTab === '1'
                      ? 'profile-tab-style-active'
                      : 'profile-tab-style'
                  }
                  onClick={() => {
                    toggleTab('1');
                  }}
                >
                  {profile_info_txt}
                </NavLink>
              </NavItem>
              <NavItem className={'profile-tab-style-item'}>
                <NavLink
                  className={
                    activeTab === '2'
                      ? 'profile-tab-style-active'
                      : 'profile-tab-style'
                  }
                  onClick={() => {
                    toggleTab('2');
                  }}
                >
                  {achievement_txt_title}
                </NavLink>
              </NavItem>
            </Nav>
            <Line />
          </NavContainer>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {bannerVisible &&
                (!isProUser ? (
                  <ProfileSubScritionAds
                    isProUser={isProUser}
                    shop_txt_7_day_trial={
                      trialUsedIP
                        ? purchase_button_subscribe_now
                        : shop_txt_7_day_trial
                    }
                    toggleBanner={toggleBanner}
                    purchase_txt_benefit_details_01={
                      purchase_txt_benefit_details_01
                    }
                    onBuy={() => {
                      onBuy(STRIPE_YEARLY);
                    }}
                  />
                ) : (
                  hasPurchasedDetailMemo && (
                    <ProfileSubScritionAds
                      isProUser={isProUser}
                      shop_txt_7_day_trial={profile_txt_manage_subcription}
                      toggleBanner={toggleBanner}
                      purchase_txt_benefit_details_01={planData}
                      className="ProfileSubScritionAds"
                      onBuy={() => {
                        history.push('/subscription');
                      }}
                    />
                  )
                ))}
              <DailyGoalSection
                weeklyGraphData={weeklyGraphData}
                dailyBananas={dailyBananas}
                profile_overview_txt={profile_overview_txt}
                profile_set_your_goal_txt={profile_set_your_goal_txt}
                toggleSetDailyGoalModal={toggleSetDailyGoalModal}
                goal={goal}
              />
              <StateScoreContainer isLeaderBoard={false}>
                <StateContent isLeaderBoard={false}>
                  <StateImg src={starSqureImg} />
                  <StateText>{coins}</StateText>
                </StateContent>
                <StateContent isLeaderBoard={false}>
                  <StateImg src={bananaSqureImg} />
                  <StateText>{bananas}</StateText>
                </StateContent>
              </StateScoreContainer>
              <ProfileStateInfo
                fluency={fluency}
                promo_txt_fluency={promo_txt_fluency}
                profile_txt_currentStreak={profile_txt_currentStreak}
                profile_txt_longestStreak={profile_txt_longestStreak}
                profile_txt_dayStreak={profile_txt_dayStreak}
                currentStreak={currentStreak}
                longestStreak={longestStreak}
              />
              <LeaderBoardContainer>
                <LeaderBoardTableTitleContainer>
                  <LeaderBoardTableTitle>
                    {profile_txt_leaderboard}
                  </LeaderBoardTableTitle>
                  <ViewAllStyle
                    className="ViewAllStyle"
                    onClick={() => {
                      history.push('/leader');
                    }}
                  >
                    {profile_txt_viewAll}
                  </ViewAllStyle>
                </LeaderBoardTableTitleContainer>
                {_.isNumber(bananas) && (
                  <StateScoreContainer isLeaderBoard={true}>
                    <StateContent isLeaderBoard={true}>
                      <StateText className="bananaScore">{bananas}</StateText>
                      <ScoreText className="bananaScore">
                        {BANANA_POINT_SYMBOL}
                      </ScoreText>
                    </StateContent>
                    <StateContent isLeaderBoard={true}>
                      <StateImg src={badgeMadal} alt={badgeMadal} />
                      <StateText>
                        {SHARP_SIGN}
                        {userRank}
                      </StateText>
                    </StateContent>
                  </StateScoreContainer>
                )}
                <SeeMoreButtonContainer></SeeMoreButtonContainer>
              </LeaderBoardContainer>
              <FollowUsSection profile_txt_follow_us={profile_txt_follow_us} />
            </TabPane>
            <TabPane tabId="2">
              <AchievementContainer>
                <AchievementTableTitle>
                  {profile_txt_todayAchieve}
                </AchievementTableTitle>
                <AchievementTable>
                  <AchievementList
                    levelChamp={levelChamp}
                    levelSpender={levelSpender}
                    levelDiligent={levelDiligent}
                    levelAchiever={levelAchiever}
                    levelWealthy={levelWealthy}
                    achievement_txt_diligentMaster={
                      achievement_txt_diligentMaster
                    }
                    diligentDescription={diligentDescription}
                    achievement_txt_bigSpender={achievement_txt_bigSpender}
                    spenderDescription={spenderDescription}
                    achievement_txt_champion={achievement_txt_champion}
                    championDescription={championDescription}
                    achievement_txt_wealthy={achievement_txt_wealthy}
                    wealthyDescription={wealthyDescription}
                    achievement_txt_overAchiever={achievement_txt_overAchiever}
                    overAchieveDescription={overAchieveDescription}
                  />
                </AchievementTable>
              </AchievementContainer>
            </TabPane>
          </TabContent>
        </Fragment>
      )}
    </Container>
  );
};

export { ProfileInfoSection };
