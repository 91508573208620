import React from 'react';
import { Box } from '@mui/material';
import { PREFIX_HEADER } from '../../common/constants';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

export const ProgressHeaderBar = ({
  activeScreen,
  screenLength,
  changeActiveScreen
}) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 6,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.newPrimary.deactivate,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 6,
      backgroundColor: theme.palette.newPrimary.activate,
    },
  }));

  const handleOnClickBack = () => {
    changeActiveScreen(activeScreen - 1);
  };

  const backButton = () => {
    return (
      <Box
        id="backBtn"
        onClick={() => {
          handleOnClickBack();
        }}
        sx={styles.backButtonContainer}
      >
        <img
          style={styles.icon}
          alt="back-icon"
          src={`${PREFIX_HEADER}back-icon.svg`}
        />
      </Box>
    );
  };

  return (
    <Box sx={styles.main}>
      <Box sx={styles.barContainer}>
        {activeScreen !== 0 && <Box sx={styles.backButton}>{backButton()}</Box>}
        <Box sx={styles.progressBar}>
           <BorderLinearProgress variant="determinate" value={(activeScreen / screenLength) * 100}/>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  main: {
    py: 1,
    px: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid #F1F1F1'
  },
  barContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent:'center',
    minWidth: 375,
    width: '70%',
    height: 50,
  },
  backButtonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  backButton: {
    height: 50,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    mr: 5,
  },
  progressBar: {
    width: '70%'
  },
  icon: {
    height: 15,
    width: 15,
    cursor: 'pointer'
  }
};

export default ProgressHeaderBar;
