/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { increaseAnsweredWrong } from '../actions/GamescreenActions';
import {
  logWritingGame,
  finishWriting,
  resetWritingUIState,
  resetGuideActive,
  onGuideActive,
  clickPlayAnimation,
  clickShowRedArrow,
  clickWriting
} from '../actions/WritingActions';
import { WritingWithRouter } from '../components/scenes';
import { getLabel, getLabels } from '../../util';
import { getCurrentCourseData } from '../../lessons/selectors/DataSelectors';
import { getVoiceSpeed } from '../../chatbots/selectors';
import { nextGameScreen, increaseGameSkipCount } from '../actions';
import { getTranscriptionState } from '../../profile/actions/UserSelector';
import { PRACTISE } from '../constants';

const WritingContainer = (props: Object) => {
  return <WritingWithRouter {...props} />;
};

export const mapStateToProps = (state: Object) => {
  const { character, isGuideActive, isWritingFinished } = state.writing;
  const { features } = getCurrentCourseData(state);
  const { hasTranscript } = features;
  let { path, guide } = character;
  guide = guide ? guide : path;
  const voiceSpeed = getVoiceSpeed(state);
  return {
    ...character,
    transcriptState: getTranscriptionState(state),
    writingCharacter: {
      ...character,
      svgPath: path,
      svgGuidePath: guide
    },
    ...getLabels(state, 'btn_check', 'btn_continue'),
    hasTranscript: hasTranscript,
    charId: character.id,
    original: character.original,
    isGuideActive,
    gameMode: PRACTISE,
    isWritingFinished,
    writing_txt_instruction: getLabel(
      state,
      'writing_txt_title',
      'Write what you hear.'
    ),
    writing_txt_skip: getLabel(state, 'writing_skip', 'Skip'),
    writing_txt_next: getLabel(state, 'writing_txt_next', 'Next'),
    labels: {
      game_writing_nav_animation: getLabel(
        state,
        'game_writing_nav_animation',
        'Animation'
      ),
      game_writing_nav_show_arrow: getLabel(
        state,
        'game_writing_nav_show_arrow',
        'Show Arrow'
      ),
      game_writing_nav_writing: getLabel(
        state,
        'game_writing_nav_writing',
        'Writing'
      )
    },
    voiceSpeed
  };
};

export const mapDispatchToProps = (dispatch: Function) => {
  return {
    increaseGameSkipCount: () => {
      dispatch(increaseGameSkipCount());
    },
    finishWriting: () => {
      dispatch(finishWriting());
    },
    resetUIStage: () => {
      dispatch(resetWritingUIState());
    },
    nextGameScreen: () => {
      dispatch(actions.nextGameScreen());
    },
    onSkip: (
      gameMode: string,
      charId: string,
      isScoreTriggerActive: boolean
    ) => {
      dispatch(resetGuideActive());
      dispatch(actions.nextGameScreen());
      dispatch(increaseAnsweredWrong());
    },
    onFinish: (
      gameMode: string,
      charId: string,
      isScoreTriggerActive: boolean
    ) => {
      dispatch(resetGuideActive());
      dispatch(actions.nextGameScreen());
    },
    onGuideActive: () => {
      dispatch(onGuideActive());
    },
    clickPlayAnimation: () => {
      dispatch(clickPlayAnimation());
    },
    clickShowRedArrow: () => {
      dispatch(clickShowRedArrow());
    },
    clickWriting: () => {
      dispatch(clickWriting());
    },

    pressNext: () => dispatch(nextGameScreen()),
    logWritingGame: (anwser: any) => dispatch(logWritingGame(anwser)),
    onCloseTutorialMotivate: () => dispatch(actions.onCloseTutorialMotivate())
  };
};
export { WritingContainer };
export default connect(mapStateToProps, mapDispatchToProps)(WritingContainer);
