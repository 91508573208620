import { call } from 'redux-saga/effects';
import { firebase } from '../../system/Firebase';
import BugTracker from '../../util/BugTracker';
import { LANGUAGE_FEATURES_FB_PATH } from '../../util/AssetHelper';
import axios from 'axios';

const TUTORIAL_CONTENT_PATH = 'data/text/tutorial/';
const SUFFIX_JSON = '_json';

export const fetchRemoteDatabase = async (path: string) =>
  await firebase
    .database()
    .ref(path)
    .once('value')
    .catch(error => {
      BugTracker.notify(error);
      console.warn(error);
    });

const fetchCourseStats = (langCode: string) =>
  fetchRemoteDatabase(`/data/courses/${langCode}/stats`);

export const fetchTutorial = async (target: string, native: string) => {
  return fetchRemoteDatabase(
    `${TUTORIAL_CONTENT_PATH}${target}/${native}${SUFFIX_JSON}`
  ).then(data => axios.get(data.val().url).then(tutorial => tutorial.data));
};
const fetchGeneralStats = () => fetchRemoteDatabase('/data/stats');

export function* downloadStats(langCode: string): Object {
  const courseStats = yield call(fetchCourseStats, langCode);
  const generalStats = yield call(fetchGeneralStats);
  const unitStats = generalStats.val();
  const totalStats = courseStats.val();
  const { units } = unitStats || { units: [] };
  const { totalWordCount, totalSentenceCount } = totalStats || {
    totalWordCount: null,
    totalSentenceCount: null
  };
  return { totalWordCount, totalSentenceCount, units };
}

export const fetchLanguageFeatures = () =>
  fetchRemoteDatabase(LANGUAGE_FEATURES_FB_PATH);

export const fetchFirebasePromotion = () =>
  fetchRemoteDatabase('/data/promotion');
