import { Box, Typography } from '@mui/material';
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import monkeyAnimation from '../../img/planPreparation/plan-preparation-monkey.json';
import * as TrackerActions from '../../tracker/actions/trackerActions';
import { getPlanPreparationMessages } from '../data/PlanPreparationMessages';
import { useTheme } from '@mui/material/styles';

export const PlanPreparationScreen = props => {
  const { txt_preparing_plan } = props;

  const theme = useTheme();
  const { labels } = props;

  const planPreparationMessages = getPlanPreparationMessages(labels);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      margin: '10vh auto',
      maxWidth: '640px',
      gap: 10
    },
    animation: {
      width: '364px',
      height: '229px'
    },
    animationContainer: {
      borderRadius: '28px',
      overflow: 'hidden',
      width: '364px',
      height: '229px',
      margin: 'auto'
    },
    messageBox: {
      display: 'flex',
      width: '558px',
      padding: '24px',
      flexDirection: 'column',
      gap: '16px',
      borderRadius: '16px',
      background: '#FFF',
      boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '80%'
      }
    },
    messageContent: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%'
    },
    headerText: {
      alignSelf: 'stretch',
      color: '#333',
      textAlign: 'center',
      fontFamily: 'Poppins',
      fontSize: '30px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '125%',
      gap: '40px'
    },
    loadingText: {
      color: '#333',
      fontFamily: 'Noto Sans Georgian Variable',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '150%',
      textAlign: 'start'
    }
  };

  const [state, setState] = useState({
    index: 0,
    progress: 0,
    delay: false
  });

  useEffect(() => {
    props.actions.tracker.requestTracking('screen_view', {
      screen_name: 'plan_preparation'
    });
  }, [props.actions.tracker]);

  useEffect(() => {
    const intervalDelay = 100;
    const progressDelay = 100;
    const nextItemDelay = 200;
    const maxStep = 35;

    let timeoutTimer;
    let intervalTimer;

    const updateState = () => {
      if (state.progress === 100) {
        if (state.index === planPreparationMessages.length - 1) {
          props.history.replace('/paywall');
        }
        setState(prevState => ({
          ...prevState,
          progress: 0,
          index: state.index + 1,
          delay: true
        }));
      } else {
        if (state.delay) {
          setTimeout(
            () => setState(prevState => ({ ...prevState, delay: false })),
            nextItemDelay
          );
        } else {
          const moreProgress = Math.floor(Math.random() * maxStep);
          const nextProgress = Math.min(state.progress + moreProgress, 100);
          setState(prevState => ({ ...prevState, progress: nextProgress }));
        }
      }
    };

    const startTimers = () => {
      intervalTimer = setInterval(() => {
        timeoutTimer = setTimeout(updateState, progressDelay);
      }, intervalDelay);
    };

    startTimers();

    return () => {
      clearTimeout(timeoutTimer);
      clearInterval(intervalTimer);
    };
  }, [
    state.progress,
    state.index,
    state.delay,
    props.history,
    planPreparationMessages.length
  ]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.animationContainer}>
        <Lottie
          animationData={monkeyAnimation}
          loop={true}
          style={styles.animation}
        />
      </Box>
      <Typography variant="text" sx={styles.headerText}>
        {txt_preparing_plan}
      </Typography>
      <Box sx={styles.messageBox}>
        <Box sx={styles.messageContent}>
          <Typography variant="text" sx={styles.loadingText}>
            {planPreparationMessages[state.index]}
          </Typography>
          <Typography variant="text" sx={styles.loadingText}>
            {state.progress}%
          </Typography>
        </Box>
        <BorderLinearProgress variant="determinate" value={state.progress} />
      </Box>
    </Box>
  );
};

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: '8px',
  alignSelf: 'stretch',
  borderRadius: '999px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#FEF4D7'
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: '#FF9900'
  }
}));

const mapDispatchToProps = dispatch => ({
  actions: { tracker: bindActionCreators(TrackerActions, dispatch) }
});

export default connect(null, mapDispatchToProps)(PlanPreparationScreen);
