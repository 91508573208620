import firebase from 'firebase/app';
import 'firebase/auth'; // for authentication
import 'firebase/storage'; // for storage
import 'firebase/database'; // for realtime database
import 'firebase/messaging'; // for cloud messaging
import 'firebase/analytics';
import 'firebase/remote-config';
import 'firebase/functions';

import { detect } from 'detect-browser';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

firebase.initializeApp(config);
let messaging;
if (
  navigator.userAgent.toLowerCase().indexOf('mobile') === -1 &&
  detect() &&
  detect()
    .name.toLowerCase()
    .indexOf('safari') === -1
) {
  messaging = firebase.messaging();
  // const webPublicKey = process.env.FIREBASE_WEB_PUSH_PUBLIC_KEY;
  messaging.usePublicVapidKey(
    'BHtMAWFWChwtxl-lFThNsXD5VPNPYjg9Ej4yegbs1rnIAy5rZwB-ltpxe4T6F0jr3fq8EY8PJPsfRaHxkDJMRP4'
  );
}
export const functions = firebase.functions();
export { firebase };
export { messaging };
