/* @flow */
import React from 'react';
import { Container } from '../sub-components';
import { SpellingMainArea, CardsFooter } from '.';
import { SPELLING } from '../../constants';
import { stopSound } from '../../../util';
type Props = {
  soundFile: string,
  instruction: string,
  question: string,
  topCards: any,
  bottomCards: any,
  answeredWrong: boolean,
  isResultShown: boolean,
  transcript?: any,
  byId?: Object,
  mode?: string,
  hintButtonEnabled: boolean,
  spendHint: Function,
  correctAnswer?: Object[],
  pressSpellingNextButton: Function,
  cardAlign?: string,
  isPlayable?: boolean,
  isShowCorrect?: boolean,
  scrollEnabled?: boolean,
  dialogHeader?: Object,
  isDialog?: boolean,
  btn_hint?: string,
  error_txt_game_wrong?: string,
  btn_check?: string,
  btn_continue?: string,
  isSpellingGame?: boolean,
  isTargetRightToLeftlLanguage: boolean,
  nativeRtlLanguage: boolean,
  gameType: string,
  transcriptState: boolean,
  voiceSpeed: number,
  isShowTutorial: boolean,
  isTargetLangSymbol: boolean
};

const SpellingGameScreen = ({
  soundFile,
  instruction,
  question,
  topCards,
  bottomCards,
  answeredWrong,
  isResultShown,
  transcript,
  byId,
  mode,
  hintButtonEnabled,
  spendHint,
  correctAnswer,
  pressSpellingNextButton,
  cardAlign = 'horizontal',
  isPlayable = true,
  isShowCorrect = true,
  scrollEnabled = true,
  dialogHeader = {},
  isDialog = false,
  btn_hint,
  error_txt_game_wrong,
  btn_check,
  btn_continue,
  isSpellingGame = false,
  isTargetRightToLeftlLanguage,
  nativeRtlLanguage,
  gameType,
  transcriptState,
  voiceSpeed,
  isShowTutorial,
  isTargetLangSymbol
}: Props) => {
  return (
    <>
      <Container gameType={SPELLING} isShowTutorial={isShowTutorial}>
        <SpellingMainArea
          soundFile={soundFile}
          instruction={instruction}
          question={question}
          transcript={transcript && !isSpellingGame ? transcript : ''}
          cards={topCards}
          answeredWrong={answeredWrong}
          isResultShown={isResultShown}
          correctAnswer={correctAnswer}
          byId={byId}
          isPlayable={isPlayable}
          cardAlign={cardAlign}
          scrollEnabled={scrollEnabled}
          isDialog={isDialog}
          dialogHeader={dialogHeader}
          isTargetRightToLeftlLanguage={isTargetRightToLeftlLanguage}
          nativeRtlLanguage={nativeRtlLanguage}
          gameType={gameType}
          transcriptState={transcriptState}
          voiceSpeed={voiceSpeed}
          isShowTutorial={isShowTutorial}
          isTargetLangSymbol={isTargetLangSymbol}
        />

        <CardsFooter
          hintButtonEnabled={hintButtonEnabled}
          spendHint={() => {
            stopSound();
            spendHint();
          }}
          mode={mode}
          cards={bottomCards}
          cardStatue={'bottomCards'}
          pressSpellingNextButton={() => {
            stopSound();
            pressSpellingNextButton();
          }}
          answeredWrong={answeredWrong}
          isResultShown={isResultShown}
          btn_hint={btn_hint}
          error_txt_game_wrong={error_txt_game_wrong}
          btn_check={btn_check}
          btn_continue={btn_continue}
          isDialog={isDialog}
          isShowTutorial={isShowTutorial}
          isTargetLangSymbol={isTargetLangSymbol}
        />
      </Container>
    </>
  );
};

export { SpellingGameScreen };
