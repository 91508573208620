/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import { ContainerSpelling } from '../sub-components';
import { VERY_LIGHT_PINK } from '../../../common/constants';
import { BUTTON_CHECK, BUTTON_CONTINUE } from '../../constants';
import _ from 'lodash';
type Props = {
  spendHint: Function,
  mode?: string,
  cards: React.Element<any>[],
  pressSpellingNextButton: Function,
  hintButtonEnabled: boolean,
  answeredWrong: boolean,
  isResultShown: boolean,
  btn_hint?: string,
  error_txt_game_wrong?: string,
  btn_check?: string,
  btn_continue?: string,
  isDialog?: boolean,
  isShowTutorial: boolean
};

const CardsFooterStyle = styled.div`
  &.dialogStyle {
    padding: 12px;
    border-radius: 25px;
    background: ${VERY_LIGHT_PINK};
    justifycontent: center;
    position: fixed;
    bottom: ${props => (props.isShowTutorial ? 85 : 115)}px;
    transform: translateX(-50%);
    display: inline-table;
    justify-content: center;
    left: 50%;
    margin-bottom: 5px;
  }
  &.cardsAreaStyle {
    flex-direction: row;
    min-width: ${props => (props.isShowTutorial ? 680 : 811)}px;
    margin: auto;
    max-height: 247px;
  }
`;

const CardsFooter = (props: Props) => {
  const { cards, isDialog = false, mode, isShowTutorial } = props;
  const isAnswerLengthPressed =
    !_.isNull(cards[0]) &&
    cards[0].props.correctAnswer.length ===
      _.countBy(cards[0].props.pressedIds, v => _.isNull(v)).false;

  const styles = {
    hintRowStyle: {
      flexDirection: 'row',
      backgroundColor: '#ffffff',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      position: 'fixed',
      bottom: 0,
      height: 110,
      width: '100%',
      borderTop: '1px solid #cccccc'
    },
    cardsAreaStyle: {
      flexDirection: 'row',
      minWidth: isShowTutorial ? 680 : 811,
      margin: 'auto',
      maxHeight: 247
    },
    characterRowStyle: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      flex: 1,
      display: 'flex',
      width: isShowTutorial ? 680 : 811,
      margin: 'auto',
      opacity: isAnswerLengthPressed ? 0.5 : 1
    },
    dialogRowStyle: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center'
    },
    onTopLine: {
      marginTop: 10,
      marginBottom: 110
    }
  };
  const { characterRowStyle, onTopLine, dialogRowStyle } = styles;
  const cardArea =
    isDialog && (mode === BUTTON_CHECK || mode === BUTTON_CONTINUE) ? null : (
      <CardsFooterStyle className={isDialog ? 'dialogStyle' : 'cardsAreaStyle'}>
        <ContainerSpelling
          style={isDialog ? dialogRowStyle : characterRowStyle}
          isAnswerLengthPressed={isAnswerLengthPressed}
        >
          {cards}
        </ContainerSpelling>
      </CardsFooterStyle>
    );

  return <div style={onTopLine}>{cardArea}</div>;
};

export { CardsFooter };
