/* @flow */
import * as React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import ShopScene from '../components/scenes/ShopScene/ShopScene';
import { getLabels, getLabel } from '../../util/LabelHelper';
import { checkUserReferral } from '../../util';
import { ClaimReward, getOnGoingPromotion } from '../../profile/actions';
import { setTrialUsedIP } from '../../common/actions';
import { PREFIX_SHOP } from '../../common/constants';
import { getPromoProductByBundleId } from '../selectors';
import {
  setSelectedPurchaseProduct,
  toggleClosePaywall
} from '../actions/ShopActions';
import * as OnboardingActions from '../../onboarding/actions/OnboardingActions';
import * as TrackerActions from '../../tracker/actions/trackerActions';
import { bindActionCreators } from 'redux';

const accessImg = `${PREFIX_SHOP}access@2x.png`;
const speakingImg = `${PREFIX_SHOP}speaking@2x.png`;
const plus50Img = `${PREFIX_SHOP}50Plus@2x.png`;
const grammarImg = `${PREFIX_SHOP}grammar@2x.png`;
const writingImg = `${PREFIX_SHOP}writing@2x.png`;

const ShopSceneContainer = props => <ShopScene {...props} />;

export const mapStateToProps = (state: Object) => {
  const { storage, shop, onboarding } = state;
  const { isPaywallClosed } = shop;
  const { user, configs } = storage;
  const { isShowInviteFriend } = configs;
  const { campaignComplete = false, shopRedirected = false } = onboarding || {};
  const isOnboardingShop = campaignComplete && shopRedirected;
  const monthly = process.env.REACT_APP_STRIPE_MONTHLY_USD_NEW;
  const monthly_eur = process.env.REACT_APP_STRIPE_MONTHLY_EUR_NEW;
  const monthly_pound = process.env.REACT_APP_STRIPE_MONTHLY_GBP_NEW;
  const monthly_thb = process.env.REACT_APP_STRIPE_MONTHLY_THB_NEW;
  const monthly_asd = process.env.REACT_APP_STRIPE_MONTHLY_ASD_NEW;
  const monthly_rub = process.env.REACT_APP_STRIPE_MONTHLY_RUB_NEW;
  const lifetime = process.env.REACT_APP_STRIPE_LIFETIME;
  const lifetime_eur = process.env.REACT_APP_STRIPE_LIFETIME_EUR;
  const lifetime_pound = process.env.REACT_APP_STRIPE_LIFETIME_POUND;
  const lifetime_thb = process.env.REACT_APP_STRIPE_LIFETIME_THB;
  const lifetime_asd = process.env.REACT_APP_STRIPE_LIFETIME_ASD;
  const lifetime_rub = process.env.REACT_APP_STRIPE_LIFETIME_RUB;
  const yearly = process.env.REACT_APP_STRIPE_YEARLY_USD;
  const yearly_eur = process.env.REACT_APP_STRIPE_YEARLY_EUR;
  const yearly_pound = process.env.REACT_APP_STRIPE_YEARLY_GBP;
  const yearly_thb = process.env.REACT_APP_STRIPE_YEARLY_THB;
  const yearly_asd = process.env.REACT_APP_STRIPE_YEARLY_ASD;
  const yearly_rub = process.env.REACT_APP_STRIPE_YEARLY_RUB;
  const yearly_without_trial =
    process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_USD;
  const yearly_eur_without_trial =
    process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_EUR;
  const yearly_pound_without_trial =
    process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_POUND;
  const yearly_thb_without_trial =
    process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_THB;
  const yearly_asd_without_trial =
    process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_ASD;
  const yearly_rub_without_trial =
    process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_RUB;
  const isDev = process.env.REACT_APP_DEV;
  const functions = process.env.REACT_APP_FUNCTIONS || '';
  const onGoingPromotion = getOnGoingPromotion(state);

  let suffix = '';
  let type = '';
  if (onGoingPromotion) {
    const {
      iapAndroidSuffix,
      iapIosSuffix,
      type: promotionType
    } = onGoingPromotion;
    suffix = iapAndroidSuffix || iapIosSuffix;
    type = promotionType;
  }

  const { iapIosSuffix, iapAndroidSuffix } = onGoingPromotion;

  let isShowPromotion = false;
  isShowPromotion = !_.isEmpty(iapAndroidSuffix) || !_.isEmpty(iapIosSuffix);

  let currentCampaign = checkUserReferral(user);

  let promoDetail = getPromoProductByBundleId(state, suffix, type, functions);
  const purchase_txt_benefit_01 = getLabel(
    state,
    'purchase_txt_benefit_01',
    'Unlimited Access'
  );
  const purchase_txt_benefit_details_01 = getLabel(
    state,
    'purchase_txt_benefit_details_01',
    '50 units with 2000+ words & 4000+ sentences.'
  );
  const purchase_txt_benefit_02 = getLabel(
    state,
    'purchase_txt_benefit_02',
    'Speaking Game'
  );
  const purchase_txt_benefit_details_02 = getLabel(
    state,
    'purchase_txt_benefit_details_02',
    'Evaluate how fluent you speak compared to a native person.'
  );
  const purchase_txt_benefit_03 = getLabel(
    state,
    'purchase_txt_benefit_03',
    'Grammar Tips'
  );
  const purchase_txt_benefit_details_03 = getLabel(
    state,
    'purchase_txt_benefit_details_03',
    'Master the language.'
  );
  const purchase_txt_benefit_04 = getLabel(
    state,
    'purchase_txt_benefit_04',
    'Writing Game'
  );
  const purchase_txt_benefit_details_04 = getLabel(
    state,
    'purchase_txt_benefit_details_04',
    'Polish your writing skills anytime.'
  );
  const purchase_txt_benefit_05 = getLabel(
    state,
    'purchase_txt_benefit_05',
    'Learn Any Language'
  );
  const shopSlideEntries: Object[] = [
    {
      img: accessImg,
      title: purchase_txt_benefit_01,
      des: purchase_txt_benefit_details_01
    },
    {
      img: plus50Img,
      title: purchase_txt_benefit_05
    },
    {
      img: speakingImg,
      title: purchase_txt_benefit_02,
      des: purchase_txt_benefit_details_02
    },
    {
      img: grammarImg,
      title: purchase_txt_benefit_03,
      des: purchase_txt_benefit_details_03
    },
    {
      img: writingImg,
      title: purchase_txt_benefit_04,
      des: purchase_txt_benefit_details_04
    }
  ];

  return {
    state,
    isOnboardingShop,
    shopSlideEntries,
    isPaywallClosed,
    functions,
    monthly,
    monthly_eur,
    monthly_pound,
    monthly_thb,
    monthly_asd,
    monthly_rub,
    lifetime,
    lifetime_asd,
    lifetime_eur,
    lifetime_pound,
    lifetime_rub,
    lifetime_thb,
    yearly,
    yearly_eur,
    yearly_pound,
    yearly_thb,
    yearly_rub,
    yearly_asd,
    yearly_without_trial,
    yearly_eur_without_trial,
    yearly_pound_without_trial,
    yearly_thb_without_trial,
    yearly_asd_without_trial,
    yearly_rub_without_trial,
    isDev,
    user,
    type,
    suffix,
    promoDetail: promoDetail,
    referralCampaign: currentCampaign,
    realTitle: getLabel(
      state,
      currentCampaign.earn,
      currentCampaign.defaultEarn
    ),
    realDesc1: getLabel(
      state,
      currentCampaign.join,
      currentCampaign.defaultJoin
    ),
    realDesc2: getLabel(
      state,
      currentCampaign.claim,
      currentCampaign.defaultClaim
    ),
    isShowPromotionModal: isShowPromotion,
    isShowInviteFriendModal: isShowInviteFriend,
    welcome_ling_title: getLabel(
      state,
      'welcome_ling_title',
      'Welcome to Ling'
    ),
    welcome_ling_description: getLabel(
      state,
      'welcome_ling_description',
      `We're happy you're here! Install the mobile app to start learning.`
    ),
    welcome_ling_button: getLabel(state, 'welcome_ling_button', 'Install'),
    promo_txt_benefit_02: getLabel(
      state,
      'promo_txt_benefit_02',
      'Take fun progress'
    ),
    login_field_name: getLabel(state, 'login_field_name', 'Name'),
    card_number: getLabel(state, 'card_number', 'Card number'),
    card_expiration: getLabel(state, 'card_expiration', 'MM/YY'),
    card_error_empty: getLabel(
      state,
      'card_error_empty',
      'Name cannot be empty.'
    ),
    card_error_number_not_correct: getLabel(
      state,
      'card_error_number_not_correct',
      "Card number doesn't seem correct."
    ),
    card_error_invalid_date: getLabel(
      state,
      'card_error_invalid_date',
      'Invalid card date'
    ),
    card_error_invalid_cvc: getLabel(
      state,
      'card_error_invalid_cvc',
      'Invalid CVC'
    ),
    promotion_txt_benifit_1: 'Save %s for Ling %s Premium Version',
    purchase_txt_benefit_details_05: getLabel(
      state,
      'purchase_txt_benefit_details_05',
      'Unlimited Access To All 50 Languages'
    ),
    promotion_txt_benifit_2: getLabel(
      state,
      'promotion_txt_benifit_des_2',
      'Ad-free'
    ),
    promotion_txt_benifit_des_2: getLabel(
      state,
      'promotion_txt_benifit_des_2',
      'Learn more effective without disturbance'
    ),
    promotion_txt_benifit_3: getLabel(
      state,
      'promotion_txt_benifit_3',
      'Comprehensive'
    ),
    promotion_txt_benifit_des_3: getLabel(
      state,
      'promotion_txt_benifit_des_3',
      'Evaluate how fluent you speak compared to a native person. Polish your writing skills anytime.'
    ),
    promo_txt_benefit_05: getLabel(
      state,
      'promo_txt_benefit_05',
      'Offline course and more on mobile'
    ),
    promo_txt_benefit_detail_05: getLabel(
      state,
      'promo_txt_benefit_detail_05',
      ''
    ),
    invite_friend_modal_join_one_week: getLabel(
      state,
      'invite_friend_modal_join_one_week',
      'A friend joined Ling thanks to you!'
    ),
    invite_friend_txt_one_week_title: getLabel(
      state,
      'invite_friend_txt_one_week_title',
      'You earned 1 week of Premium Version!'
    ),
    invite_friend_modal_claim_one_week: getLabel(
      state,
      'invite_friend_modal_claim_one_week',
      'Claim your 1 week of Premium version for free'
    ),
    invite_friend_modal_claim_reward: getLabel(
      state,
      'invite_friend_modal_claim_reward',
      'Claim Reward'
    ),
    trial_txt_no_thanks: getLabel(state, 'trial_txt_no_thanks', 'No, Thanks'),
    promo_txt_get_discount: getLabel(
      state,
      'promo_txt_get_discount',
      'get discount'
    ),
    random_test_modal_back_txt: getLabel(
      state,
      'random_test_modal_back_txt',
      'Back'
    ),
    profile_txt_payment_method: getLabel(
      state,
      'profile_txt_payment_method',
      'Payment Method'
    ),
    profile_txt_expire_date: getLabel(
      state,
      'profile_txt_expire_date',
      'Expiration date'
    ),
    profile_txt_card_number: getLabel(
      state,
      'profile_txt_card_number',
      'Card Number'
    ),
    profile_txt_name_card: getLabel(
      state,
      'profile_txt_name_card',
      'Name on card'
    ),
    profile_txt_recurring_billing: getLabel(
      state,
      'profile_txt_recurring_billing',
      'Recurring billing, cancel anytime.'
    ),
    profile_txt_terms_condition: getLabel(
      state,
      'profile_txt_terms_condition',
      'Terms & Conditions'
    ),
    shop_txt_checkout_title: getLabel(
      state,
      'shop_txt_checkout_title',
      `We’ll only use this if you continue
after your trial end!`
    ),
    shop_txt_checkout_title_top: getLabel(
      state,
      'shop_txt_checkout_title_top',
      'Ling Premium 60 Languages'
    ),
    shop_txt_checkout_title_monthly: getLabel(
      state,
      'shop_txt_checkout_title_monthly',
      'Monthly Subscription'
    ),
    shop_txt_checkout_title_yearly: getLabel(
      state,
      'shop_txt_checkout_title_yearly',
      'Yearly Subscription'
    ),
    shop_txt_checkout_title_lifetime: getLabel(
      state,
      'shop_txt_checkout_title_lifetime',
      'Lifetime Access'
    ),

    btn_submit: getLabel(state, 'btn_submit', 'Submit'),
    trial_date_description_txt: getLabel(
      state,
      'trial_date_description_txt',
      'After your trial ends, you will be charged {d} per year starting {date} . You can always cancel before then.'
    ),
    email_error_empty: getLabel(
      state,
      'email_error_empty',
      'Email cannot be empty.'
    ),
    email_error_wrongFormat: getLabel(
      state,
      'email_error_wrongFormat',
      'The email address is badly formatted.'
    ),
    shop_txt_product_detail_basic: getLabel(
      state,
      'shop_txt_product_detail_basic',
      'Basic'
    ),
    shop_txt_product_detail_free: getLabel(
      state,
      'shop_txt_product_detail_free',
      'Free'
    ),
    shop_txt_product_detail_subscriptions: getLabel(
      state,
      'shop_txt_product_detail_subscriptions',
      'Subscriptions'
    ),

    shop_txt_product_detail_all_topics: getLabel(
      state,
      'shop_txt_product_detail_all_topics',
      'Talk with the chatbot about 20 different topics'
    ),

    shop_txt_product_detail_beginner_lessons: getLabel(
      state,
      'shop_txt_product_detail_beginner_lessons',
      'Beginner lessons'
    ),
    shop_txt_product_detail_introductory_chatbot: getLabel(
      state,
      'shop_txt_product_detail_introductory_chatbot',
      'Introductory Chatbot'
    ),
    shop_txt_product_detail_offline_learning: getLabel(
      state,
      'shop_txt_product_detail_offline_learning',
      'Offline learning'
    ),
    shop_txt_product_detail_200_lessons_60: getLabel(
      state,
      'shop_txt_product_detail_200_lessons_60',
      '200 lessons in 60+ languages'
    ),
    shop_txt_product_detail_grammar_tips: getLabel(
      state,
      'shop_txt_product_detail_grammar_tips',
      'Grammar tips in all languages'
    ),
    shop_txt_product_detail_sync_across_devices: getLabel(
      state,
      'shop_txt_product_detail_sync_across_devices',
      'Sync across all your devices'
    ),
    purchase_txt_option_lifeTime: getLabel(
      state,
      'purchase_txt_option_lifeTime',
      'Lifetime'
    ),
    shop_txt_year: getLabel(state, 'shop_txt_year', 'year'),
    shop_txt_month: getLabel(state, 'shop_txt_month', 'month'),
    purchase_txt_option_unit: getLabel(
      state,
      'purchase_txt_option_unit',
      'per month'
    ),
    shop_txt_7_day_trial: getLabel(
      state,
      'shop_txt_7_day_trial',
      '7-Day trial'
    ),

    shop_btn_txt_buyNow: getLabel(state, 'shop_btn_txt_buyNow', 'Buy now'),

    loginPurchase_txt: getLabel(
      state,
      'loginPurchase_txt',
      'Create a profile to get power - ups, bonus skills, and more!'
    ),

    createAccount_txt_title: getLabel(
      state,
      'createAccount_txt_title',
      'Create Account'
    ),

    purchase_txt_option_popular: getLabel(
      state,
      'createAccount_txt_title',
      'popular'
    ),

    login_field_email: getLabel(state, 'login_field_email', 'Email'),
    ...getLabels(
      state,
      'trial_txt_testimonial_5',
      'trial_txt_testimonial_name_5'
    )
  };
};

const mapDispatchToProps = dispatch => ({
  ClaimReward,
  setTrialUsedIP,
  getPromoProductByBundleId,
  setSelectedPurchaseProduct: saleData =>
    dispatch(setSelectedPurchaseProduct(saleData)),
  onboarding: bindActionCreators(OnboardingActions, dispatch),
  tracker: bindActionCreators(TrackerActions, dispatch),
  toggleClosePaywall: bindActionCreators(toggleClosePaywall, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopSceneContainer);
