/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getLabel, getUnitImageUrl } from '../../util';
import {
  startGame,
  startExam,
  startSpeaking,
  startWriting
} from '../../games/actions';
import { exitChatbot } from '../../chatbots/actions';
import { LessonsSceneWithRouter } from '../components/scenes/LessonsScene';
import { getCourseData } from '../selectors';
import { loadLanguageData } from '../actions';
import { updateUserProfile } from '../../common/actions/GlobalActions';

import {
  getCurrentCourseData,
  hasLangCodeSpeaking
} from '../selectors/DataSelectors';
import { getUserLessonsProgress } from '../../profile/selectors';

export const mapStateToProps = (state: Object, ownProps: Object) => {
  const {
    data: { targetLangCode, nativeLangCode },
    lessons: lessonData,
    units: unitData
  } = state;
  const {
    match: {
      params: { id }
    }
  } = ownProps;
  const { features } = getCurrentCourseData(state);
  const { hasSpeaking, hasWriting } = features;
  const { unitId, lessons } = lessonData;
  let title = '';
  let image = '';

  if (!_.isEmpty(unitData.units[unitId - 1])) {
    title = getLabel(state, unitData.units[unitId - 1].title);
    image = getUnitImageUrl(unitId);
  }
  const courseLang = getCourseData(state, targetLangCode);
  return {
    data: courseLang,
    lessons,
    unitId: parseInt(id, 10),
    title,
    image,
    targetLangCode,
    nativeLangCode,
    progress: getUserLessonsProgress(state, unitId),
    hasSpeaking,
    hasWriting,
    hasLangCode: hasLangCodeSpeaking(state),
    lesson_txt_exam: getLabel(state, 'lesson_txt_exam'),
    lesson_txt_speaking: getLabel(state, 'lesson_txt_speaking'),
    lesson_txt_writing: getLabel(state, 'lesson_txt_writing'),
    lesson_txt_android_version: getLabel(state, 'lesson_txt_android_version'),
    tooltip_txt_lesson: getLabel(state, 'tooltip_txt_lesson'),
    overView_txt_viewAll: getLabel(state, 'overView_txt_viewAll', 'Review All'),
    lesson_txt_play: getLabel(state, 'lesson_txt_play', 'Play')
  };
};

const LessonsSceneContainer = props => <LessonsSceneWithRouter {...props} />;

export default connect(mapStateToProps, {
  startGame,
  startExam,
  startSpeaking,
  startWriting,
  loadLanguageData,
  updateUserProfile,
  exitChatbot
})(LessonsSceneContainer);
