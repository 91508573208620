/* @flow */
import * as React from 'react';
import { DEFAULT_FONT } from '../../../util/AssetHelper';
import { GREY2 } from '../../../common/constants';

type Props = {
  children: string
};
const InstructionsHeader = ({ children }: Props) => {
  return <div style={{ ...textStyle }}>{children}</div>;
};

const textStyle = {
  fontSize: 16,
  marginTop: 14,
  color: GREY2,
  fontWeight: 'bold',
  textAlign: 'center',
  fontFamily: DEFAULT_FONT
};

export { InstructionsHeader };
