/* @flow */
import * as React from 'react';
import { PlayButtonGold } from '../sub-components';
import styled from 'styled-components';
import {
  LIGHT_GREY,
  DARK_GREY,
  LIGHT_GREY_TRANSCRIPT,
  BLUE,
  LIGHT_BLUE
} from '../../../common/constants';
import { playSoundWithCallback } from '../../../util';
import { VoiceSpeedButtonGold } from './VoiceSpeedButtonGold';

type Props = {
  children?: React.Element<any>,
  style?: any,
  onPress?: () => void,
  invisible?: boolean,
  transcriptState: boolean,
  isSentenceExist: boolean,
  onPressNext?: () => void,
  transcript: string,
  translation: string,
  soundFile: string,
  original: string,
  imgSrc: string,
  voiceSpeed: number,
  isPlayed: boolean,
  setPlayingPhrase: Function,
  removePlayingPhrase: Function,
  playingPhrase: string,
  id: string,
  soundPath: string,
  isTargetLangSymbol: boolean
};

const WhiteRowStyle = styled.div`
  flex: 0;
  flex-direction: row;
  background-color: transparent;
  border-radius: 15px;
  padding: 16;
  margin: 13px;
  align-items: center;
  border: 1px solid ${LIGHT_GREY};
  justify-content: space-between;
  display: flex;
  cursor: pointer;
    &.playing {
      border: solid 1px ${BLUE};
      background-image:${LIGHT_BLUE};
    }
  }
`;
const FlashcardRowContainer = styled.div`
  position: relative;
`;

const FlexCard = styled.div`
  margin-left: 30px;
  margin-right: 140px;
`;

const SpeakerIcon = styled.img`
  margin-right: 10px;
`;

const SoundBut = styled.div`
  margin: 0 13px;
  display: flex;
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
`;

const SentenceStyle = styled.div`
  opacity: 0;
  flex: 0.25;
`;
const FlashcardText = styled.p`
  font-size: 18px;
  color: ${DARK_GREY};
  font-weight: bold;
  &.original-style {
    font-size: 25px;
  }
`;

const FlashcardTranscriptText = styled.p`
  fontsize: 18px;
  margin: 10px 0;
  font-weight: bold;
  color: ${LIGHT_GREY_TRANSCRIPT};
`;
const SoundButton = styled.div`
  display: flex;
  justify-content: center;
`;
const playingSound = require('./img/flashcard/speaker-fill-blue-28-px.png');

const FlashcardRow = ({
  children,
  style,
  isSentenceExist = true,
  original = '',
  transcriptState = true,
  transcript = '',
  translation = '',
  soundFile = '',
  imgSrc = '',
  voiceSpeed,
  setPlayingPhrase = () => {},
  removePlayingPhrase = () => {},
  playingPhrase = '',
  id = '',
  soundPath = '',
  isTargetLangSymbol
}: Props) => {
  const dataInside = isTargetLangSymbol => (
    <div>
      <FlashcardText className={isTargetLangSymbol ? 'original-style' : null}>
        {original}
      </FlashcardText>
      <FlashcardTranscriptText>
        {transcriptState ? transcript : null}
      </FlashcardTranscriptText>
      <FlashcardText>{translation}</FlashcardText>
    </div>
  );
  const row = (
    <FlashcardRowContainer>
      <WhiteRowStyle
        onClick={() =>
          onPlay(
            setPlayingPhrase,
            soundFile,
            voiceSpeed,
            soundPath,
            removePlayingPhrase
          )
        }
        className={id === playingPhrase ? 'playing' : null}
      >
        <FlexCard>{dataInside(isTargetLangSymbol)}</FlexCard>
      </WhiteRowStyle>
      <SoundBut>
        {id === playingPhrase ? (
          <SpeakerIcon src={playingSound} />
        ) : (
          <SoundButton>
            <PlayButtonGold
              soundFile={soundFile}
              isFlashcard={true}
              voiceSpeed={voiceSpeed}
              onPress={() =>
                onPlay(
                  setPlayingPhrase,
                  soundFile,
                  voiceSpeed,
                  soundPath,
                  removePlayingPhrase
                )
              }
            />
            <VoiceSpeedButtonGold
              setPlayingPhrase={setPlayingPhrase}
              removePlayingPhrase={removePlayingPhrase}
              soundFile={soundFile}
              isFlashcard={true}
              voiceSpeed={voiceSpeed}
            />
          </SoundButton>
        )}
      </SoundBut>
    </FlashcardRowContainer>
  );
  return !isSentenceExist ? <SentenceStyle>{row}</SentenceStyle> : row;
};

const onPlay = (
  beforeCallback: Function,
  soundFile: string,
  voiceSpeed: number,
  path: string,
  afterCallback: Function
) => {
  beforeCallback && beforeCallback();
  playSoundWithCallback(soundFile, voiceSpeed, undefined, () => {
    afterCallback && afterCallback();
  });
};

export { FlashcardRow };
