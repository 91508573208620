/* @flow */
import React, { Fragment } from 'react';
import styled from 'styled-components';
import {
  GREY,
  LIGHT_GREY_TRANSCRIPT_2,
  RED_COLOR,
  CARD_NAME,
  CARD_NUMBER,
  CARD_EXPIRY,
  CARD_CVC,
  EMAIL
} from '../../common/constants';
import { validateForm } from '../../util';
const visa = require('../../img/icons/visa.svg');
const mastercard = require('../../img/icons/mastercard.svg');
const amex = require('../../img/icons/amex.svg');
const discover = require('../../img/icons/discover.svg');
const hipercard = require('../../img/icons/hipercard.svg');
const diners = require('../../img/icons/diners.svg');
const JCB = require('../../img/icons/jcb.svg');
const UnionPay = require('../../img/icons/unionpay.svg');
const email = require('../../img/icons/email.svg');
const person = require('../../img/icons/person.svg');
const defaultCard = require('../../img/icons/defaultCard.svg');
const calendar = require('../../img/icons/calendar.svg');
const cvcImg = require('../../img/icons/credit-card-24-px.png');

const BoxContainer = styled.div`
  border: 1px ${GREY} solid;
  border-radius: 20px;
  margin: 13px;
  margin-bottom: 0;
  padding: 0 18px;
  flex: 0.5;
  position: relative;
`;

const TextStyle = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: ${props =>
    props.validate && props.touched ? RED_COLOR : LIGHT_GREY_TRANSCRIPT_2};
  margin: 0 !important;
  padding: 10px 0;
`;

const InputStyle = styled.input`
  font-size: 14px;
  color: ${LIGHT_GREY_TRANSCRIPT_2};
  border: none;
  &:focus {
    outline: none;
  }
`;

const IconStyle = styled.div`
  margin-right: 15px;
  height: 24px;
`;

const ImageStyle = styled.img`
  width: 32px;
  height: 21px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.icon !== 'CVC' ? 'row' : 'row-reverse')};
  margin-bottom: 20px;
  align-items: center;
  input {
    border: none;
    outline: none;
  }
`;

const ErrorTextStyle = styled.div`
  margin-top: -10px;
  position: absolute;
  bottom: 0px;
  color: ${RED_COLOR};
`;
const style = {
  width: '100%'
};

type Props = {
  title: string,
  icon: string,
  id: string,
  card_error_empty: string,
  email_error_empty: string,
  card_error_number_not_correct: string,
  card_error_invalid_date: string,
  card_error_invalid_cvc: string,
  email_error_wrongFormat: string,
  login_field_name?: string,
  inputValue: any,
  handleChange: Function,
  handleTouch: Function,
  cardType?: string,
  touched: Object,
  cardName: string,
  validCardNumber: boolean,
  validCardExpiry: boolean,
  validCardCvc: boolean
};

export const InfoInputBox = ({
  title,
  icon,
  id,
  inputValue,
  handleChange,
  handleTouch,
  cardType,
  touched,
  cardName,
  validCardNumber,
  validCardExpiry,
  validCardCvc,
  login_field_name,
  email_error_empty,
  card_error_empty,
  email_error_wrongFormat,
  card_error_number_not_correct,
  card_error_invalid_date,
  card_error_invalid_cvc
}: Props) => {
  let type = '';
  switch (cardType) {
    case 'visa':
      type = visa;
      break;
    case 'mastercard':
      type = mastercard;
      break;
    case 'amex':
      type = amex;
      break;
    case 'discover':
      type = discover;
      break;
    case 'hipercard':
      type = hipercard;
      break;
    case 'diners':
      type = diners;
      break;
    case 'unionpay':
      type = UnionPay;
      break;
    case 'jcb':
      type = JCB;
      break;
    default:
      type = '';
      break;
  }
  const validationResult = validateForm(
    id,
    cardName,
    validCardNumber,
    validCardExpiry,
    validCardCvc
  );
  return (
    <BoxContainer>
      <TextStyle
        validate={validationResult === false || validationResult === ''}
        touched={touched}
      >
        {title}
      </TextStyle>
      <InputContainer icon={icon}>
        <IconStyle>
          {icon === EMAIL && <ImageStyle src={email} />}
          {icon === 'PersonFill' && <ImageStyle src={person} />}

          {icon === 'CreditCard' && type === '' ? (
            <ImageStyle src={defaultCard} />
          ) : null}
          {icon === 'CreditCard' && type !== '' ? (
            <ImageStyle src={type} />
          ) : null}
          {icon === 'Calendar' && <ImageStyle src={calendar} />}
          {icon === 'CVC' && <ImageStyle src={cvcImg} />}
        </IconStyle>
        {icon === 'Calendar' && <div id={id} style={style} />}
        {icon === 'CVC' && <div id={id} style={style} />}
        {icon === 'CreditCard' && <div id={id} style={style} />}
        {icon === 'PersonFill' && (
          <Fragment>
            <InputStyle
              id={id}
              value={inputValue}
              onBlur={e => {
                handleTouch(id);
              }}
              onChange={event => {
                handleChange(id, event.target.value);
              }}
              placeholder={login_field_name}
            />
          </Fragment>
        )}
        {icon === EMAIL && (
          <Fragment>
            <InputStyle
              required
              type="email"
              id={id}
              value={inputValue}
              onBlur={e => {
                handleTouch(id);
              }}
              onChange={event => {
                handleChange(id, event.target.value);
              }}
              placeholder={login_field_name}
            />
          </Fragment>
        )}
      </InputContainer>

      {id === EMAIL && validationResult === false && touched && (
        <ErrorTextStyle>{email_error_wrongFormat}</ErrorTextStyle>
      )}

      {id === EMAIL && validationResult === '' && touched && (
        <ErrorTextStyle>{email_error_empty}</ErrorTextStyle>
      )}
      {id === CARD_NAME && !validationResult && touched && (
        <ErrorTextStyle>{card_error_empty}</ErrorTextStyle>
      )}
      {id === CARD_NUMBER && !validationResult && touched && (
        <ErrorTextStyle>{card_error_number_not_correct}</ErrorTextStyle>
      )}
      {id === CARD_EXPIRY && !validationResult && touched && (
        <ErrorTextStyle>{card_error_invalid_date}</ErrorTextStyle>
      )}
      {id === CARD_CVC && !validationResult && touched && (
        <ErrorTextStyle>{card_error_invalid_cvc}</ErrorTextStyle>
      )}
    </BoxContainer>
  );
};
