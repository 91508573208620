/* @flow */
import axios from 'axios';
import BugTracker from '../util/BugTracker';

import { redirect, isMobile, purchaseFailed, redirectLogin } from './';
import stripe from './PurchaseUtils';
import { CARD_PAID, CARD_SUCCEEDED, couponAPI } from '../common/constants';
import { getStripeSessionId } from './StripeProductsHandlers';

export const stripeCheckout = (
  productId: string,
  redeemCode: string,
  isDev: boolean,
  currency: string
) => {
  stripe
    .redirectToCheckout({
      lineItems: [
        {
          price: productId,
          quantity: 1
        }
      ],
      mode: 'payment',
      // Do not rely on the redirect to the successUrl for fulfilling
      // purchases, customers may not always reach the success_url after
      // a successful payment.
      // Instead use one of the strategies described in
      // https://stripe.com/docs/payments/checkout/fulfillment
      // successUrl: `${redirect(`redeem_ + ${redeemCode}`, isDev, currency)}`,
      successUrl: `${redirect(`redeem_${redeemCode}`, isDev, currency)}`,
      cancelUrl: 'https://ling-app.com/ling-gift-christmas/'
    })
    .then(function(result) {
      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer.
        alert(result.error.message);
      }
    });
};

export const purchaseStripeSuccess = (
  functions: string,
  user: Object,
  history: Object,
  redeemId: string,
  updateUserData: Function
) => {
  axios
    .post(`${functions}${couponAPI}`, {
      uid: user.uid,
      couponCode: redeemId
    })
    .then(res => {
      if (res.status === 200) {
        !res.data.success &&
          alert(
            'This coupon is already redeemed. Please contact support@simyasolutions.com if you have not redeemed this coupon before.'
          );

        updateUserData({ subscription: res.data.subscription });
        isMobile
          ? (window.location = 'https://ling-app.com/redeem-success/')
          : history.push('/');
      }
    })
    // eslint-disable-next-line handle-callback-err
    .catch(err => {
      BugTracker.notify(err);
    });
};

export const createPaymentMethod = (
  cardNumber: Object,
  email: string,
  currency: string,
  isLoading: boolean,
  isDev: boolean,
  location: Object,
  packageName: string,
  productId: string,
  functions: string,
  stateChangeHandler: Function
) => {
  stripe
    .createPaymentMethod({
      type: 'card',
      card: cardNumber
    })
    .then(result => {
      console.log('result', result);
      if (result.error) {
        alert(result.error.message);
      } else {
        stateChangeHandler('isLoading', !isLoading);
        createCustomer(
          result.paymentMethod.id,
          email,
          currency,
          isLoading,
          isDev,
          location,
          packageName,
          productId,
          functions,
          stateChangeHandler
        );
      }
    });
};

export const createCustomer = (
  paymentMethod: string,
  cardholderEmail: string,
  currency: string,
  isLoading: boolean,
  isDev: boolean,
  location: Object,
  packageName: string,
  productId: string,
  functions: string,
  stateChangeHandler: Function
) => {
  axios
    .post(
      `${functions}/validateLingWeb-stripeApi/create-customer/${packageName}`,
      {
        email: cardholderEmail,
        payment_method: paymentMethod,
        priceId: productId,
        currency: currency
      }
    )
    .then(subscription => {
      handleSubscription(
        subscription,
        cardholderEmail,
        currency,
        isLoading,
        isDev,
        location,
        packageName,
        stateChangeHandler
      );
    })
    .catch(error => {
      console.log('error: do parsing', error);
    });
};

export const handleSubscription = (
  subscription: Object,
  cardholderEmail: string,
  currency: string,
  isLoading: boolean,
  isDev: boolean,
  location: Object,
  packageName: string,
  stateChangeHandler: Function
) => {
  const { data } = subscription;
  const { code } = data;

  if (code === 200) {
    const { invoice, subscription } = data;
    if (invoice) {
      const { status } = invoice;
      if (status === CARD_PAID) {
        redirectLogin(
          `offer_stripe_${packageName}`,
          isDev,
          currency,
          invoice.id,
          cardholderEmail
        );
      }
    } else {
      const { status } = subscription;
      if (status === CARD_SUCCEEDED) {
        redirectLogin(
          `offer_stripe_${packageName}`,
          isDev,
          currency,
          subscription.id,
          cardholderEmail
        );
      }
    }
  } else {
    const { raw } = data;
    const { message } = raw;
    alert(message);
    stateChangeHandler('isLoading', !isLoading);
    purchaseFailed();
  }
};

export const createPaymentMethodAndCustomerClaim = async (
  cardNumber: Object,
  email: string,
  currency: string,
  isLoading: boolean,
  isDev: boolean,
  location: Object,
  packageName: string,
  productId: string,
  functions: string,
  stateChangeHandler: Function
) => {
  createPaymentMethod(
    cardNumber,
    email,
    currency,
    isLoading,
    isDev,
    location,
    packageName,
    productId,
    functions,
    stateChangeHandler
  );
};

export const stripeCheckoutWithPromoCode = async (
  functions: string,
  product: string,
  promoCode: string,
  isDev: boolean
) => {
  const sessionId = await getStripeSessionId(
    functions,
    product,
    promoCode,
    isDev
  );
  if (sessionId.code === 200) {
    return stripe
      .redirectToCheckout({
        sessionId: sessionId.session.id
      })
      .then(function(result) {
        if (result.error) {
          BugTracker.notify(result.error.message);
          alert(result.error.message);
        }
      });
  } else {
    BugTracker.notify('sessionId error');
  }
};

export const getCheckoutSessionData = (
  functions: string,
  checkoutSessionId: string
): Object =>
  axios
    .get(`${functions}validateLingWeb-stripeApi/sessionId/${checkoutSessionId}`)
    .then(res => {
      if (res.status !== 400) {
        return res.data;
      } else {
        console.log('stripe session error');
      }
    });
