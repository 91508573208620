import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

const OnboardingButton = ({ buttonText, clickAction, disabled, style, ...other }) => {
  return (
    <Button
      disabled={disabled}
      onClick={clickAction}
      sx={style}
      endIcon=""
      disableElevation
      variant="contained"
      {...other}
    >
      {buttonText}
    </Button>
  );
};

OnboardingButton.propTypes = {
  buttonText: PropTypes.string,
  clickAction: PropTypes.func,
  style: PropTypes.object
};

export default OnboardingButton;
