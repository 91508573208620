/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { CardRow, WordElementCard, CheckButton } from '../sub-components';
import {
  matchingStatus,
  MATCHING,
  BUTTON_DISABLED,
  BUTTON_CHECK_DISABLED,
  BUTTON_CONTINUE_DISABLED,
  BUTTON_SEND_DISABLED,
  KEYCODE_1,
  KEYCODE_2,
  KEYCODE_3,
  KEYCODE_4,
  KEYCODE_5,
  KEYCODE_6,
  KEYCODE_ENTER
} from '../../constants';

import type { Card } from '../../types';
import { stopSound } from '../../../util';
const MatchingGameSceneContainer = styled.div`
  height: 100vh;
  align-items: center;
  flex: 1;
  display: flex;
  min-height: 640px;
`;
const MatchingPageContainer = styled.div`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`;

const MatchingTitleContainer = styled.div`
  margin: 0;
  margin-bottom: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  @media (max-height: 750px) {
    margin-bottom: 35px;
  }
  @media (max-height: 690px) {
    margin-bottom: 10px;
  }
`;
class Matching extends React.Component<*> {
  componentDidMount() {
    window.scrollTo(0, 0);

    // $flow-disable-line
    document.addEventListener('keydown', this.handleEnter);
  }
  componentWillUnmount() {
    // $flow-disable-line
    document.removeEventListener('keydown', this.handleEnter);
  }
  handleEnter = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const {
      nextGameScreen,
      mode,
      pressMatchingCard,
      allIds,
      byId
    } = this.props;
    if (
      event.keyCode === KEYCODE_ENTER &&
      nextGameScreen &&
      mode !== BUTTON_DISABLED &&
      mode !== BUTTON_CHECK_DISABLED &&
      mode !== BUTTON_CONTINUE_DISABLED &&
      mode !== BUTTON_SEND_DISABLED
    ) {
      nextGameScreen();
    } else if (
      event.keyCode === KEYCODE_1 ||
      event.keyCode === KEYCODE_2 ||
      event.keyCode === KEYCODE_3 ||
      event.keyCode === KEYCODE_4 ||
      event.keyCode === KEYCODE_5 ||
      event.keyCode === KEYCODE_6
    ) {
      //NOTE: this is used because matching game allIds order is [0,2,1,3,5,4]
      const allIdsForMatching = [
        allIds[0],
        allIds[2],
        allIds[1],
        allIds[3],
        allIds[5],
        allIds[4]
      ];
      byId[allIdsForMatching[parseInt(event.key, 10) - 1]].status !==
        matchingStatus.INVISIBLE &&
        pressMatchingCard(allIdsForMatching[parseInt(event.key, 10) - 1]);
      window.scrollTo(0, 0);
    }
  };

  render() {
    const {
      title_txt_matching,
      mode,
      btn_check,
      btn_continue,
      btn_hint,
      nextGameScreen,
      coins
    } = this.props;

    return (
      <MatchingGameSceneContainer>
        <MatchingPageContainer>
          <MatchingTitleContainer>{title_txt_matching}</MatchingTitleContainer>
          {this.getCardRows()}
        </MatchingPageContainer>
        <CheckButton
          quizAnswer={''}
          onPressNext={() => {
            stopSound();
            nextGameScreen();
            window.scrollTo(0, 0);
          }}
          mode={mode}
          btn_check={btn_check}
          btn_continue={btn_continue}
          btn_hint={btn_hint}
          gameType={MATCHING}
          coins={coins}
        />
      </MatchingGameSceneContainer>
    );
  }

  getCardRows(): React.Element<any>[] {
    const { allIds, byId } = this.props;
    return _.reduce(
      allIds,
      (cardRows, cardId, i) => {
        if (i % 3 === 0) {
          const rightCardId = allIds[i + 1];
          const centerCardId = allIds[i + 2];
          const leftCard = byId[cardId];
          const rightCard = byId[rightCardId];
          const centerCard = byId[centerCardId];
          return _.concat(
            cardRows,
            <CardRow
              key={i}
              leftCard={this.getElementFromCard(leftCard, cardId, i)}
              centerCard={this.getElementFromCard(
                centerCard,
                centerCardId,
                i + 1
              )}
              rightCard={this.getElementFromCard(rightCard, rightCardId, i + 2)}
            />
          );
        }
        return cardRows;
      },
      []
    );
  }

  getElementFromCard(
    card: Card,
    cardId: number,
    i: number
  ): React.Element<any> {
    const { isImage, content, status, transcript, id } = card;
    const {
      getCardStyle,
      loadAnimationFinished,
      animationFinished,
      isTargetLangSymbol
    } = this.props;

    setTimeout(() => {
      animationFinished(status, loadAnimationFinished);
    }, 360);

    const wordCardStyle = getCardStyle(status);
    wordCardStyle.width = 170;
    return (
      <WordElementCard
        content={content}
        index={i + 1}
        id={id}
        onPress={this.onPressCard(cardId, status)}
        isImage={isImage}
        invisible={status === matchingStatus.INVISIBLE}
        touchAble={status === matchingStatus.MATCH}
        style={wordCardStyle}
        transcriptState={this.props.transcriptState}
        transcript={transcript}
        isTargetLangSymbol={isTargetLangSymbol}
        status={status}
      />
    );
  }

  onPressCard(cardId: number, status: string) {
    return () => {
      this.props.pressMatchingCard(cardId, status);
    };
  }
}

export { Matching };
