import * as amplitude from '@amplitude/analytics-browser';
import { version } from '../../package.json';

export const initAmplitude = () => {
  amplitude.init(process.env.REACT_APP_AMPLITUDE, undefined, {
    appVersion: version,
    defaultTracking: {
      pageViews: false,
      sessions: true,
      fileDownloads: false
    }
  });
};

export const setAmplitudeUserID = userID => {
  amplitude.setUserId(userID);
};

export const setAmplitudeIdentity = properties => {
  const identifyEvent = new amplitude.Identify();

  for (let [propertyName, propertyValue] of Object.entries(properties)) {
    identifyEvent.set(propertyName, propertyValue);
  }

  amplitude.identify(identifyEvent);
};

export const sendAmplitudeData = (eventType, eventProperties) => {
  amplitude.track(eventType, eventProperties);
};
