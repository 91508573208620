import { IMAGE_ENDING, getUnitName } from '../../util';
import { UPDATE_UNITS_PROGRESS, LOAD_UNIT_BOXES } from '../constants';

type Unit = {
  key: number,
  title: string,
  imageName: string,
  progress: number,
  goal: number,
  crowns: number
};

type State = {
  units: {
    [string]: Unit
  }
};

const INITIAL_STATE = {
  units: {}
};

const INITIAL_UNIT_PROGRESS = {
  progress: 0,
  goal: 7,
  crowns: 0
};

const updateUnitsProgress = (state: State, action: Object) => {
  const units = { ...state.units, ...action.units };
  return { ...state, units };
};

const loadUnitBoxes = (state: State, { unitCount }: Object) => {
  const units = {};
  for (let i = 0; i < unitCount; i++) {
    const unit = state.units[`${i}`];
    const key = i + 1;
    const unitName = getUnitName(key);

    units[i] = {
      ...INITIAL_UNIT_PROGRESS,
      ...unit,
      key,
      title: `units_txt_${unitName}`,
      imageName: `${unitName}${IMAGE_ENDING}`
    };
  }
  return { ...state, units };
};

export const UnitsSceneReducer = (
  state: State = INITIAL_STATE,
  action: Object
) => {
  switch (action.type) {
    case UPDATE_UNITS_PROGRESS:
      return updateUnitsProgress(state, action);
    case LOAD_UNIT_BOXES:
      return loadUnitBoxes(state, action);
    default:
      return state;
  }
};
