import React from 'react';
import { DARK_GREY, GREY, LIGHT_RED_1 } from '../../../../common/constants';
import { Box, FormGroup } from '@mui/material';
import { LoginStyle } from './SignUpModal.styles';

const ResetPasswordPage = props => {
  const {
    btn_continue,
    handleResetPasswordSubmit,
    email_error_fieldsMissing,
    email_error_wrongFormat,
    errorMsgFirebase,
    login_field_email,
    user,
    sendPasswordResetEmail,
    stateChangeHandler,
    successMsg,
    txt_email_field_placeholder
  } = props;

  const [errMsg, setError] = React.useState({
    emailErrorMsg: '',
    formErrorMsg: '',
    passwordErrorMsg: '',
    err: false
  });

  const styles = {
    error: {
      fontSize: 12,
      textAlign: 'left',
      paddingLeft: 3,
      display: 'flex',
      paddingTop: 1,
      color: LIGHT_RED_1,
      fontStyle: 'italic'
    },
    input: {
      border: 'none',
      outline: 'none'
    },
    inputGroup: {
      textAlign: 'left',
      border: `1px solid ${GREY}`,
      borderRadius: 1.5
    },
    inputGroupResetPassword: {
      margin: '46px 0 35px'
    },
    inputContainer: {
      pt: 2,
      px: 4,
      width: 'inherit',
      fontSize: 14
    },
    labelStyle: {
      fontWeight: 'bold',
      marginBottom: 3,
      textTransform: 'capitalize',
      color: `${props =>
        props.iserror && props.errormsg !== '' ? LIGHT_RED_1 : DARK_GREY}`
    },
    space: {
      height: 12
    }
  };

  const modalStateChangeHandler = (name: string, value: any) => {
    console.log(name, value);
    setError({
      [name]: value
    });
    console.log(errMsg);
  };

  React.useEffect(() => {
    console.log(errMsg);
  }, [errMsg]);

  return (
    <form
      id="loginForm"
      onSubmit={e =>
        handleResetPasswordSubmit(
          e,
          email_error_fieldsMissing,
          email_error_wrongFormat,
          user,
          errMsg.err,
          sendPasswordResetEmail,
          modalStateChangeHandler,
          stateChangeHandler
        )
      }
    >
      <div style={styles.inputGroupResetPassword}>
        <Box sx={styles.inputGroup}>
          <FormGroup sx={styles.inputContainer}>
            <Box
              component="label"
              htmlFor="email"
              sx={styles.labelStyle}
              iserror={errMsg}
              errormsg={errMsg.emailErrorMsg}
            >
              {login_field_email}
            </Box>
            <Box
              component="input"
              placeholder={txt_email_field_placeholder}
              id="email"
              type="text"
              sx={styles.input}
              onChange={event => {
                user.email = event.target.value;
              }}
            />
            <Box sx={styles.error}>
              {errMsg && errMsg.emailErrorMsg !== ''
                ? errMsg.emailErrorMsg
                : null}
              {errorMsgFirebase ? errorMsgFirebase : null}
            </Box>
          </FormGroup>
        </Box>
        <Box sx={styles.error}>
          {errMsg && errMsg.formErrorMsg !== '' ? errMsg.formErrorMsg : null}
        </Box>
      </div>
      {successMsg ? successMsg : null}
      <div>
        <LoginStyle type="submit">{btn_continue}</LoginStyle>
        {/* <MaterialButton
          variant="reverse-old-contained"
          buttonText={btn_continue}
          type="submit"
        /> */}
      </div>
    </form>
  );
};

export default ResetPasswordPage;
