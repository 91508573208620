/* @flow */
import styled from 'styled-components';
import {
  WHITE,
  SEA_BLUE,
  LIGHT_WHITE,
  DARK_BLUE,
  LIGHT_GREY_TRANSCRIPT_2,
  DARK_YELLOW,
  MARIGOLD,
  GREY2,
  LIGHT_GREY_2
} from '../../../common/constants';
const LeftSection = styled.div`
  margin: auto;
  margin-top: 24px;
`;

const LeftText = styled.p`
  display: ${props => (props.isCancel ? 'block' : 'none')};
  position: absolute;
  top: 260px;
  font-size: 14px;
  font-weight: bold;
  color: ${WHITE};
  border-radius: 5px;
  background-color: ${DARK_BLUE};
  padding: 3px 13px;
`;
const SubscritionAdsContainer = styled.div`
  height: 412px;
  width: 431px;
  align-items: center;
  border-radius: 10px;
  background-color: ${SEA_BLUE};
  display: flex;
  flex-direction: column;
`;
const LeftContent = styled.div`
  flex: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 350px;
  height: 287px;
  margin-bottom: 33px;
  border-radius: 10px;
`;
const LeftImg = styled.img`
  width: 100%;
`;

const RightContent = styled.div`
  flex: 0.4;
  padding-left: 35px;
  padding-right: 50px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
const RightText = styled.p`
  font-size: 16px;
  color: ${LIGHT_WHITE};
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
`;
const RightButton = styled.button`
  border-radius: 10px;
  background: ${WHITE};
  border: 0;
  box-shadow: none;
  padding: 7px 33px;
  max-width: 212px;
  margin-bottom: 27px;

  &:hover {
    opacity: 0.6;
  }
`;

const ButtonText = styled.p`
  color: ${SEA_BLUE};
  font-size: 16px;
  font-weight: bold;
  margin: 0 !important;
`;

const BotttomInputStyle = styled.div`
  display: flex;
`;

const SmallSubscritionAdsContainer = styled.div`
  height: 89px;
  border-radius: 10px;
  background-color: ${SEA_BLUE};
  display: flex;
`;

const SmallLeftContent = styled.div`
  flex: 0.4;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 33px;
  border-radius: 10px;
  width: 129px;
  height: 89px;
`;
const SmallLeftImg = styled.img`
  width: 100%;
`;
const SmallRightContent = styled.div`
  flex: 0.6;
  padding-right: 30px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
const InputContainer = styled.div`
  margin-top: 10px;
  width: 538px;
  display: ${props => (props.isUpdateMethod ? 'block' : 'none')};
`;
const SpinnerContainer = styled.div`
  display: ${props => (props.isUpdateMethod ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  height: calc(100vh - 225px);
  width: 431px;
  margin: auto;
`;

const PaymentStyle = styled.div`
  display: ${props => (props.isUpdateMethod ? 'block' : 'none')};
`;

const SubmitButton = styled.button`
  text-transform: uppercase;
  margin: 13px;
  border-radius: 20px;
  box-shadow: inset 0 -4px 0 0 ${DARK_YELLOW};
  background: ${MARIGOLD};
  border: ${DARK_YELLOW} 1px solid;
  font-size: 14px;
  height: 55px;
  width: 95%;
  padding: 0;
  color: ${WHITE};
  &:hover:enabled {
    opacity: 0.4;
    box-shadow: none;
  }
  &:active {
    opacity: 1;
    background-color: ${DARK_YELLOW};
    box-shadow: none;
  }
  &:disabled {
    box-shadow: inset 0 -4px 0 0 ${GREY2};
    border-color: ${GREY2};
    background-color: ${LIGHT_GREY_2};
  }
`;
const FormStyle = styled.form`
  display: ${props => (props.isUpdatingCard ? 'none' : 'block')};
`;

const TitleTextStyle = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: ${LIGHT_GREY_TRANSCRIPT_2};
  margin: 0 !important;
  margin-left: 13px !important;
  padding: 10px 0;
`;

export {
  FormStyle,
  TitleTextStyle,
  PaymentStyle,
  InputContainer,
  SpinnerContainer,
  SubmitButton,
  SmallRightContent,
  SmallLeftImg,
  SmallLeftContent,
  BotttomInputStyle,
  SmallSubscritionAdsContainer,
  ButtonText,
  LeftImg,
  RightContent,
  RightText,
  RightButton,
  LeftContent,
  SubscritionAdsContainer,
  LeftSection,
  LeftText
};
