import {
  initAmplitude,
  sendAmplitudeData,
  setAmplitudeIdentity
} from './Amplitude';
import Analytic, { initializeReactGA } from './Analytic';

const hasGoogle = process.env.REACT_APP_TRACKING_ID ? true : false;

export default class Tracker {
  static initTracker() {
    return new Promise((res, rej) => {
      try {
        if (hasGoogle) {
          initializeReactGA();
        }
        initAmplitude();
        res();
      } catch (error) {
        rej(error);
      }
    });
  }

  static sendTracking(eventType, eventProperties, userProperties) {
    return new Promise((res, rej) => {
      try {
        if (hasGoogle) {
          // TODO: rewrite this

          Analytic.logGA(eventType, {
            ...eventProperties,
            category: 'campaign',
            action: 'campaign_completed'
          });
        }
        if (Object.keys(userProperties).length) {
          setAmplitudeIdentity(userProperties);
        }
        sendAmplitudeData(eventType, eventProperties);
        res();
      } catch (error) {
        rej(error);
      }
    });
  }
}
