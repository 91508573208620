import { firebase } from '../../system/Firebase';
import Analytic from '../../util/Analytic';
import Compressor from 'compressorjs';
import { store } from '../../';
import {
  USER_SAVE_DATA,
  CLEAR_USER,
  USER_DEFAULT_MAX_ID,
  UPLOAD_FILE_SUCCESS
} from '../constants';
import * as TrackerActions from '../../tracker/actions/trackerActions';
import { toggleLoadingParam } from './GlobalActions';
import _ from 'lodash';
import { triggerUpdateUnitsProgress } from '../../lessons/actions/UnitActions';

import {
  PROVIDER_FACEBOOK,
  PROVIDER_APPLE,
  PROVIDER_GOOGLE,
  PROVIDER_EMAIL,
  ANONYMOUS,
  SIGNIN_METHOD
} from '../../profile/constants';
import { LOGIN_AS_GUEST } from '../../lessons/constants';
import BugTracker from '../../util/BugTracker';
import { fetchRemoteDatabase } from '../../lessons/sagas';
import {
  addToSubscribeNewsList,
  getUserData,
  isUserAnonymous
} from '../../util';
import { setRestrictedContent } from './RestrictUnitsActions';
import { setAmplitudeUserID } from '../../util/Amplitude';

export const signInWithGoogleRedirect = () => (
  dispatch: Function,
  getState: Function
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    firebase.auth().signInWithRedirect(new firebase.auth.GoogleAuthProvider());

    resolve(true);
  });
};

export const signInWithFbRedirect = () => (
  dispatch: Function,
  getState: Function
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithRedirect(new firebase.auth.FacebookAuthProvider());
    resolve(true);
  });
};

export const signInWithAppleRedirect = () => (
  dispatch: Function,
  getState: Function
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithRedirect(new firebase.auth.OAuthProvider('apple.com'));
    resolve(true);
  });
};

export const linkWithGoogleRedirect = () => (
  dispatch: Function,
  getState: Function
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line
    localStorage.setItem(SIGNIN_METHOD, PROVIDER_GOOGLE);
    firebase
      .auth()
      .currentUser.linkWithRedirect(new firebase.auth.GoogleAuthProvider());

    resolve(true);
  });
};

export const onRedirectSignInWithCredential = (credential: Object) => (
  dispatch: Function,
  getState: Function
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const state = getState();
    const { user } = state.storage;
    const { referrer } = user;
    firebase
      .auth()
      .signInWithCredential(credential)
      .then(async result => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        let token = result.credential.accessToken;
        // The signed-in user info.
        let user = result.user;
        // eslint-disable-next-line
        const signin_method = localStorage.getItem(SIGNIN_METHOD) || '';
        console.log('Login successfully user is', user, '\n With Token', token);
        await fetchRemoteDatabase(`users/${user.uid}`).then(async data => {
          const userData = await getUserData(user);
          if (referrer) {
            userData.referrer = referrer;
          }
          dispatch(linkDatabaseUser(user, signin_method, userData));
          resolve(true);
        });
      })
      .catch(function(error) {
        console.log('Error with google login', error);
        resolve(false);
      });
    resolve(true);
  });
};

export const linkWithFbRedirect = () => (
  dispatch: Function,
  getState: Function
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line
    localStorage.setItem(SIGNIN_METHOD, PROVIDER_FACEBOOK);
    firebase
      .auth()
      .currentUser.linkWithRedirect(new firebase.auth.FacebookAuthProvider());
    resolve(true);
  });
};

export const linkWithAppleRedirect = () => (
  dispatch: Function,
  getState: Function
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line
    localStorage.setItem(SIGNIN_METHOD, PROVIDER_APPLE);
    firebase
      .auth()
      .currentUser.linkWithRedirect(
        new firebase.auth.OAuthProvider('apple.com')
      );
    resolve(true);
  });
};

export const signInWithGoogle = () => (
  dispatch: Function,
  getState: Function
): Promise<boolean> => {
  let auth = firebase.auth();
  let provider = new firebase.auth.GoogleAuthProvider();
  // eslint-disable-next-line
  localStorage.setItem(SIGNIN_METHOD, PROVIDER_GOOGLE);
  const state = getState();
  const { isBusinessSignUp, user } = state.storage;
  const { referrer } = user;
  return new Promise((resolve, reject) => {
    auth.currentUser
      .linkWithPopup(provider)
      .then(async result => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        let token = result.credential.accessToken;
        // The signed-in user info.
        let user = result.user;
        console.log('result', result);
        console.log(
          'Login successfully goolge user is',
          user,
          '\n With Token',
          token
        );

        await fetchRemoteDatabase(`users/${user.uid}`).then(async data => {
          let userData = await getDatabaseUser(user).then(data => {
            return data.val();
          });
          if (isBusinessSignUp) {
            userData = { ...userData, isSignUpBusinessSuccess: true };
          }
          if (referrer) {
            userData = { ...userData, referrer: referrer };
          }
          dispatch(linkDatabaseUser(user, PROVIDER_GOOGLE, userData, true));
        });
        window.dataLayer.push({
          event: 'login',
          userId: user.uid
        });
        dispatch(setRestrictedContent(user.uid));
        Analytic.log(Analytic.key.A7, { channel: 'Google' });
        Analytic.logGA(Analytic.key.A7, {
          category: Analytic.key.A7,
          action: 'Login',
          label: 'Google'
        });
        setAmplitudeUserID(user.uid);
        dispatch(
          TrackerActions.requestTracking(
            'log_in',
            {
              user_id: user.uid,
              email: user.email,
              name: user.name,
              signed_in_at: user.signed_in_at,
              authentication_type: PROVIDER_GOOGLE
            },
            {
              user_id: user.uid,
              email: user.email,
              created_at: user.created_at,
              signed_in_at: user.signed_in_at,
              name: user.name,
              authentication_type: PROVIDER_GOOGLE
            }
          )
        );

        resolve(true);
      })
      .catch(function(error) {
        if (error.credential) {
          auth
            .signInWithCredential(error.credential)
            .then(async result => {
              // This gives you a Google Access Token. You can use it to access the Google API.
              let token = result.credential.accessToken;
              // The signed-in user info.
              let user = result.user;
              console.log(
                'Login successfully user is',
                user,
                '\n With Token',
                token
              );
              await fetchRemoteDatabase(`users/${user.uid}`).then(
                async data => {
                  const userData = await getUserData(user);
                  if (referrer) {
                    userData.referrer = referrer;
                  }
                  dispatch(
                    linkDatabaseUser(user, PROVIDER_GOOGLE, userData, false)
                  );
                  window.dataLayer.push({
                    event: 'login',
                    userId: user.uid
                  });
                  dispatch(setRestrictedContent(user.uid));
                  Analytic.log(Analytic.key.A7, { channel: 'Google' });
                  Analytic.logGA(Analytic.key.A7, {
                    category: Analytic.key.A7,
                    action: 'Login',
                    label: 'Google'
                  });
                  setAmplitudeUserID(user.uid);
                  dispatch(
                    TrackerActions.requestTracking(
                      'log_in',
                      {
                        user_id: user.uid,
                        email: user.email,
                        name: user.name,
                        signed_in_at: user.signed_in_at,
                        authentication_type: PROVIDER_GOOGLE
                      },
                      {
                        user_id: user.uid,
                        email: user.email,
                        created_at: user.created_at,
                        signed_in_at: user.signed_in_at,
                        name: user.name,
                        authentication_type: PROVIDER_GOOGLE
                      }
                    )
                  );
                  resolve(true);
                }
              );
            })
            .catch(function(error) {
              console.log('Error with google login', error);
              resolve(false);
            });
        }
      });
  });
};

export const addLoginListener = (authUser: Object) => (dispatch: Function) => {
  dispatch({
    type: LOGIN_AS_GUEST,
    authUser
  });
};

export const signUpWithEmail = (
  name: string,
  email: string,
  password: string,
  isNewsletterEnable?: boolean
) => (dispatch: Function, getState: Function): Promise<Array<any>> => {
  let auth = firebase.auth();
  const state = getState();
  const { isBusinessSignUp, user } = state.storage;
  const { referrer } = user;

  // eslint-disable-next-line
  localStorage.setItem(SIGNIN_METHOD, PROVIDER_EMAIL);
  return new Promise((resolve, reject) => {
    let credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    );

    auth.currentUser
      .linkWithCredential(credential)
      .then(async usercred => {
        let user = usercred.user;
        saveRemoteUserName(user, name);
        await fetchRemoteDatabase(`users/${user.uid}`).then(async data => {
          let userData = await getDatabaseUser(user).then(data => {
            return data.val();
          });
          if (isBusinessSignUp) {
            userData = { ...userData, isSignUpBusinessSuccess: true };
          }
          if (referrer) {
            userData = { ...userData, referrer: referrer };
          }
          dispatch(
            linkDatabaseUser(
              user,
              PROVIDER_EMAIL,
              userData,
              isNewsletterEnable && isNewsletterEnable
            )
          );
          window.dataLayer.push({
            event: 'login',
            userId: user.uid
          });
          dispatch(setRestrictedContent(user.uid));
          Analytic.log(Analytic.key.A7, { channel: 'Email' });
          Analytic.logGA(Analytic.key.A7, {
            category: Analytic.key.A7,
            action: 'Login',
            label: 'Email'
          });
          setAmplitudeUserID(user.uid);
          dispatch(
            TrackerActions.requestTracking(
              'sign_up',
              {
                user_id: user?.uid ?? '',
                email: user?.email ?? '',
                name: name ?? '',
                signed_in_at: user?.signed_in_at ?? '',
                authentication_type: PROVIDER_EMAIL
              },
              {
                user_id: user?.uid ?? '',
                email: user?.email ?? '',
                created_at: user?.metadata?.creationTime ?? '',
                signed_in_at: user?.signed_in_at ?? '',
                name: name ?? '',
                authentication_type: PROVIDER_EMAIL
              }
            )
          );

          resolve([true]);
        });
      })
      .catch(error => {
        console.log('Error with email signup', error);
        resolve([false, error]);
      });
  });
};

export const saveUserData = (user: Object) => ({
  type: USER_SAVE_DATA,
  user
});

export const clearUserData = () => ({
  type: CLEAR_USER
});

export const signInWithEmailAndPassword = (email: string, password: string) => (
  dispatch: Function,
  getState: Function
): Promise<Array<any>> => {
  let auth = firebase.auth();
  const state = getState();
  const { user } = state.storage;
  const { referrer } = user;
  // eslint-disable-next-line
  localStorage.setItem(SIGNIN_METHOD, PROVIDER_EMAIL);
  return new Promise((resolve, reject) => {
    auth
      .signInWithEmailAndPassword(email, password)
      .then(async function(result) {
        // The signed-in user info.
        let user = result.user;

        console.log('Signin successfully user is', user);
        await fetchRemoteDatabase(`users/${user.uid}`).then(data => {
          let userData = data.val();
          if (referrer) {
            userData = { ...userData, referrer: referrer };
          }
          dispatch(saveUserData(userData));
        });
        window.dataLayer.push({
          event: 'login',
          userId: user.uid
        });
        dispatch(setRestrictedContent(user.uid));
        Analytic.log(Analytic.key.A7, { channel: 'Email' });
        Analytic.logGA(Analytic.key.A7, {
          category: Analytic.key.A7,
          action: 'Login',
          label: 'Email'
        });
        const { email, name } = user.email;
        Analytic.prepareUserIdentify(email, name);
        Analytic.prepareUser();
        setAmplitudeUserID(user.uid);
        dispatch(
          TrackerActions.requestTracking(
            'log_in',
            {
              user_id: user.uid,
              email: user.email,
              name: user.name,
              signed_in_at: user.signed_in_at,
              authentication_type: PROVIDER_EMAIL
            },
            {
              user_id: user.uid,
              email: user.email,
              created_at: user.created_at,
              signed_in_at: user.signed_in_at,
              name,
              authentication_type: PROVIDER_EMAIL
            }
          )
        );
        resolve([true]);
      })
      .catch(function(error) {
        console.log(error);
        resolve([false, error]);
      });
  });
};

export const signInAnonymously = () => (
  dispatch: Function
): Promise<Array<any>> => {
  let auth = firebase.auth();
  return new Promise((resolve, reject) => {
    auth
      .signInAnonymously()
      .then(async result => {
        const { user } = result;
        dispatch(generateUserData(user, 'anonymous'));
        Analytic.prepareUser();
        Analytic.prepareUserUid();
        resolve([true]);
      })
      .catch(error => {
        // Handle Errors here.
        const errorCode = error.code;
        if (errorCode === 'auth/operation-not-allowed') {
          alert('You must enable Anonymous auth in the Firebase Console.');
        }
        resolve([false, error]);
      });
  });
};

export const sendPasswordResetEmail = (email: string) => (
  dispatch: Function
): Promise<Array<any>> => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function() {
        console.log('Email sent');
        resolve([true, 'Email sent']);
      })
      .catch(error => {
        resolve([false, error]);
      });
  });
};

export const signInWithFacebook = () => (
  dispatch: Function,
  getState: Function
): Promise<boolean> => {
  let auth = firebase.auth();
  let provider = new firebase.auth.FacebookAuthProvider();
  // eslint-disable-next-line
  localStorage.setItem(SIGNIN_METHOD, PROVIDER_FACEBOOK);
  const state = getState();
  const { isBusinessSignUp, user } = state.storage;
  const { referrer } = user;
  return new Promise((resolve, reject) => {
    auth.currentUser
      .linkWithPopup(provider)
      .then(async result => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        let token = result.credential.accessToken;
        // The signed-in user info.
        let user = result.user;

        console.log('Login successfully user is', user, '\n With Token', token);
        await fetchRemoteDatabase(`users/${user.uid}`).then(async data => {
          let userData = await getUserData(user);
          if (isBusinessSignUp) {
            userData = { ...userData, isSignUpBusinessSuccess: true };
          }
          if (referrer) {
            userData = { ...userData, referrer: referrer };
          }
          dispatch(linkDatabaseUser(user, PROVIDER_FACEBOOK, userData, true));
        });
        window.dataLayer.push({
          event: 'login',
          userId: user.uid
        });
        dispatch(setRestrictedContent(user.uid));
        Analytic.log(Analytic.key.A7, { channel: 'FB' });
        Analytic.logGA(Analytic.key.A7, {
          category: Analytic.key.A7,
          action: 'Login',
          label: 'FB'
        });
        setAmplitudeUserID(user.uid);
        dispatch(
          TrackerActions.requestTracking(
            'log_in',
            {
              user_id: user.uid,
              email: user.email,
              name: user.name,
              signed_in_at: user.signed_in_at,
              authentication_type: PROVIDER_FACEBOOK
            },
            {
              user_id: user.uid,
              email: user.email,
              created_at: user.created_at,
              signed_in_at: user.signed_in_at,
              name: user.name,
              authentication_type: PROVIDER_FACEBOOK
            }
          )
        );

        resolve(true);
      })
      .catch(function(error) {
        if (error.credential) {
          auth
            .signInWithCredential(error.credential)
            .then(async result => {
              // This gives you a Google Access Token. You can use it to access the Google API.
              let token = result.credential.accessToken;
              // The signed-in user info.
              let user = result.user;

              console.log(
                'Login successfully user is',
                user,
                '\n With Token',
                token
              );
              await fetchRemoteDatabase(`users/${user.uid}`).then(
                async data => {
                  const userData = await getUserData(user);

                  dispatch(
                    linkDatabaseUser(user, PROVIDER_FACEBOOK, userData, false)
                  );
                  window.dataLayer.push({
                    event: 'login',
                    userId: user.uid
                  });
                  dispatch(setRestrictedContent(user.uid));
                  Analytic.log(Analytic.key.A7, { channel: 'FB' });
                  Analytic.logGA(Analytic.key.A7, {
                    category: Analytic.key.A7,
                    action: 'Login',
                    label: 'FB'
                  });
                  resolve(true);
                }
              );
            })
            .catch(function(error) {
              console.log('Error with facebook login', error);
              resolve(false);
            });
        }
      });
  });
};

export const signInWithApple = () => (
  dispatch: Function,
  getState: Function
): Promise<boolean> => {
  let auth = firebase.auth();
  let provider = new firebase.auth.OAuthProvider('apple.com');
  // eslint-disable-next-line
  localStorage.setItem(SIGNIN_METHOD, 'apple');
  const state = getState();
  const { isBusinessSignUp, user } = state.storage;
  const { referrer } = user;
  return new Promise((resolve, reject) => {
    auth.currentUser
      .linkWithPopup(provider)
      .then(async result => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        let token = result.credential.accessToken;
        // The signed-in user info.
        let user = result.user;
        console.log('Login successfully user is', user, '\n With Token', token);
        await fetchRemoteDatabase(`users/${user.uid}`).then(async data => {
          let userData = await getDatabaseUser(user).then(data => {
            return data.val();
          });
          if (isBusinessSignUp) {
            userData = { ...userData, isSignUpBusinessSuccess: true };
          }
          if (referrer) {
            userData = { ...userData, referrer: referrer };
          }
          dispatch(linkDatabaseUser(user, PROVIDER_APPLE, userData, true));
        });
        window.dataLayer.push({
          event: 'login',
          userId: user.uid
        });
        dispatch(setRestrictedContent(user.uid));
        Analytic.log(Analytic.key.A7, { channel: 'Apple' });
        Analytic.logGA(Analytic.key.A7, {
          category: Analytic.key.A7,
          action: 'Login',
          label: 'Apple'
        });
        setAmplitudeUserID(user.uid);
        dispatch(
          TrackerActions.requestTracking(
            'log_in',
            {
              user_id: user.uid,
              email: user.email,
              name: user.name,
              signed_in_at: user.signed_in_at,
              authentication_type: PROVIDER_APPLE
            },
            {
              user_id: user.uid,
              email: user.email,
              created_at: user.created_at,
              signed_in_at: user.signed_in_at,
              name: user.name,
              authentication_type: PROVIDER_APPLE
            }
          )
        );

        resolve(true);
      })
      .catch(function(error) {
        if (error.credential) {
          auth
            .signInWithCredential(error.credential)
            .then(async result => {
              // This gives you a Google Access Token. You can use it to access the Google API.
              let token = result.credential.accessToken;
              // The signed-in user info.
              let user = result.user;

              console.log(
                'Login successfully user is',
                user,
                '\n With Token',
                token
              );
              await fetchRemoteDatabase(`users/${user.uid}`).then(
                async data => {
                  const userData = await getUserData(user);

                  dispatch(
                    linkDatabaseUser(user, PROVIDER_APPLE, userData, false)
                  );
                  window.dataLayer.push({
                    event: 'login',
                    userId: user.uid
                  });
                  dispatch(setRestrictedContent(user.uid));
                  Analytic.log(Analytic.key.A7, { channel: 'Apple' });
                  Analytic.logGA(Analytic.key.A7, {
                    category: Analytic.key.A7,
                    action: 'Login',
                    label: 'Apple'
                  });
                  resolve(true);
                }
              );
            })
            .catch(function(error) {
              console.log('Error with apple login', error);
              resolve(false);
            });
        }
      });
  });
};

export const signOut = () => (dispatch: Function): Promise<Array<any>> => {
  let auth = firebase.auth();
  // eslint-disable-next-line
  localStorage.setItem(SIGNIN_METHOD, '');
  return new Promise((resolve, reject) => {
    auth
      .signOut()
      .then(function() {
        dispatch(clearUserData());
        dispatch(signInAnonymously());
        resolve([true]);
      })
      .catch(function(error) {
        resolve([false, error]);
      });
  });
};

export const getUserName = (userData: any) => {
  let userName;
  const emailName = _.get(userData, 'name', false);
  const facebookCheck = _.get(userData, 'facebook.name', false);
  const appleCheck = _.get(userData, 'apple.name', false);
  const googleCheck = _.get(userData, 'google.name', false);
  // eslint-disable-next-line
  const signin_method = localStorage.getItem(SIGNIN_METHOD);
  switch (signin_method) {
    case PROVIDER_FACEBOOK:
      userName = facebookCheck;
      break;
    case PROVIDER_APPLE:
      userName = appleCheck;
      break;
    case PROVIDER_GOOGLE:
      userName = googleCheck;
      break;
    case PROVIDER_EMAIL:
      userName = emailName;
      break;
    default:
      userName = ANONYMOUS;
      break;
  }
  return userName;
};

export const createNewDatabaseUser = (
  authUser: any,
  type: string,
  existingUser: ?Object = {}
): Object => (dispatch, getState) => {
  console.log('Provider Data is', type);
  const { uid } = authUser;
  const state = getState();
  const { targetLangCode, nativeLangCode } = state.data;
  const providerSpecificData = getProviderSpecificData(authUser, type);
  const user = mergeOldUser(
    targetLangCode,
    nativeLangCode,
    providerSpecificData,
    uid,
    existingUser
  );
  try {
    firebase
      .database()
      .ref(`users/${uid}`)
      .set(user);
  } catch (error) {
    BugTracker.notify(error);
    console.warn(
      'Create user data failed. Could not create firebase user!',
      error.message,
      uid
    );
  }
  dispatch(saveUserData(user));
  dispatch(triggerUpdateUnitsProgress());
};

export const generateUserData = (
  authUser: any,
  type: string,
  existingUser: ?Object = {},
  isNewsletterEnable: boolean = false
) => async (dispatch: Function, getState: Function) => {
  console.log('Provider Data is', type);
  const { uid } = authUser;
  const state = getState();
  const { targetLangCode, nativeLangCode } = state.data;
  const providerSpecificData = getProviderSpecificData(authUser, type);
  const user = mergeOldUser(
    targetLangCode,
    nativeLangCode,
    providerSpecificData,
    uid,
    existingUser
  );

  try {
    firebase
      .database()
      .ref(`users/${uid}`)
      .set(user);
  } catch (error) {
    BugTracker.notify(error);
    console.warn(
      'Create user data failed. Could not create firebase user!',
      error.message,
      uid
    );
  }
  console.log('createNewDatabaseUser', user);
  dispatch(saveUserData(user));
  const shouldSendConfirmationEmailToUser =
    !isUserAnonymous(user) && isNewsletterEnable;

  shouldSendConfirmationEmailToUser && dispatch(addToSubscribeNewsList());
  dispatch(triggerUpdateUnitsProgress());
};

export const getDatabaseUser = (authUser: Object): Promise<Object> => {
  console.log('getDatabaseUser');
  return new Promise((resolve, reject) => {
    console.log('getDatabaseUser in executor');
    const callback = snapshot => {
      console.log('getDatabaseUser resolving promise', snapshot);
      resolve(snapshot);
    };
    getUserReference(authUser)
      .once('value', callback)

      .catch(error => {
        console.warn('getDatabaseUser Firebase error', error, authUser);
        BugTracker.breadcrumb('getDatabaseUser Firebase error', { authUser });
        BugTracker.notify(error);
        reject(error);
      });
  });
};
export const mergeOldUser = (
  targetLangCode: string,
  nativeLangCode: string,
  providerSpecificData: Object,
  uid: string,
  existingUser: ?Object = {}
) => {
  const existingVoiceSpeed = _.get(existingUser, 'voiceSpeed', 1);
  const currentVoiceSpeed = getHandledVoiceSpeed(existingVoiceSpeed);
  const newUser = {
    uid,
    ...providerSpecificData,
    ...existingUser,
    targetLangCode,
    nativeLangCode,
    voiceSpeed: currentVoiceSpeed
  };
  return newUser;
};

export const getProviderSpecificData = (
  authUser: Object,
  type: string
): Object => {
  const { providerData } = authUser;
  let providerProfile = extractProviderProfile(providerData, type);
  const { displayName, email, photoURL, uid } = providerProfile
    ? providerProfile
    : authUser;
  if (email && displayName) {
    Analytic.prepareUserIdentify(email, displayName);
    Analytic.prepareUser();
  }

  return {
    [type]: {
      id: uid,
      name: displayName,
      email,
      photoURL
    }
  };
};

export const saveRemoteUserName = (user: Object, name: string) => {
  try {
    if (user) {
      var changeName = {
        name: name
      };
      const firebaseDB = firebase.database().ref(`users/${user.uid}`);
      return firebaseDB
        .update(changeName)
        .catch(error => console.warn('Error updating remote username!', error));
    }
  } catch (error) {
    BugTracker.notify(error);
    console.warn('Save Remote Name failed!', error);
  }
};

export const extractProviderProfile = (
  providerData: Array<Object>,
  type: string
) => {
  const findPredicate = ({ providerId }) =>
    providerId ===
    ([PROVIDER_FACEBOOK, PROVIDER_GOOGLE, PROVIDER_APPLE].includes(type)
      ? `${type}.com`
      : type);

  const profile = _.find(providerData, findPredicate);
  if (profile) {
    const { email, displayName, photoURL, uid } = profile;
    return { email, displayName, photoURL, uid };
  }
};

export const linkDatabaseUser = (
  authUser: Object,
  provider: string,
  existingUser: Object,
  isNewsletterEnable: boolean = false
) => (dispatch: Function, getState: Function) => {
  dispatch(
    generateUserData(authUser, provider, existingUser, isNewsletterEnable)
  );
};

export const getUserReference = (authUser: Object) => {
  return firebase.database().ref(`users/${authUser.uid}`);
};

export const isValidEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getName = (userData: Object) => {
  const emailName = _.get(userData, 'name', false);
  const facebookCheck = _.get(userData, 'facebook.name', false);
  const appleCheck = _.get(userData, 'apple.name', false);
  const googleCheck = _.get(userData, 'google.name', false);
  // eslint-disable-next-line
  const signin_method = localStorage.getItem(SIGNIN_METHOD);
  switch (signin_method) {
    case PROVIDER_FACEBOOK:
      return facebookCheck;
    case PROVIDER_GOOGLE:
      return googleCheck;
    case PROVIDER_APPLE:
      return appleCheck;
    case PROVIDER_EMAIL:
      return emailName;
    default:
      return emailName;
  }
};

export const saveName = (userData: Object, name: string) => {
  // eslint-disable-next-line
  const signin_method = localStorage.getItem(SIGNIN_METHOD);
  switch (signin_method) {
    case PROVIDER_FACEBOOK:
      updateName(userData, name, PROVIDER_FACEBOOK);
      break;
    case PROVIDER_GOOGLE:
      updateName(userData, name, PROVIDER_GOOGLE);
      break;
    case PROVIDER_APPLE:
      updateName(userData, name, PROVIDER_APPLE);
      break;
    case PROVIDER_EMAIL:
      updateName(userData, name, PROVIDER_EMAIL);
      break;
    default:
      updateName(userData, name, PROVIDER_EMAIL);
      break;
  }
};

export const saveProfilePic = (
  userData: Object,
  url: Object,
  selectedFile: Object
) => {
  // eslint-disable-next-line
  const signin_method = localStorage.getItem(SIGNIN_METHOD);
  switch (signin_method) {
    case PROVIDER_FACEBOOK:
      updateProfilePic(userData, url, selectedFile, PROVIDER_FACEBOOK);
      break;
    case PROVIDER_GOOGLE:
      updateProfilePic(userData, url, selectedFile, PROVIDER_GOOGLE);
      break;
    case PROVIDER_APPLE:
      updateProfilePic(userData, url, selectedFile, PROVIDER_APPLE);
      break;
    case PROVIDER_EMAIL:
      updateProfilePic(userData, url, selectedFile, PROVIDER_EMAIL);
      break;
    default:
      updateProfilePic(userData, url, selectedFile, PROVIDER_EMAIL);
      break;
  }
};

const updateName = async (user: Object, name: string, type: string) => {
  const { dispatch } = store;
  if (type === PROVIDER_EMAIL) {
    firebase
      .database()
      .ref(`users/${user.uid}`)
      .update({
        name: name
      })
      .catch(error => console.log('Update User Data:', { error }));
    dispatch(
      saveUserData({
        ...user,
        name: name
      })
    );
  } else {
    firebase
      .database()
      .ref(`users/${user.uid}/${type}`)
      .update({
        name: name
      })
      .catch(error => console.log('Update User Data:', { error }));
    dispatch(
      saveUserData({
        [type]: {
          ...user[type],
          name: name
        }
      })
    );
  }
};

const updateProfilePic = async (
  user: Object,
  url: Object,
  selectedFile: Object,
  type: string
) => {
  const { dispatch } = store;
  if (url.id < USER_DEFAULT_MAX_ID + 1) {
    dispatch(toggleLoadingParam(true));
    await firebase
      .database()
      .ref(`users/${user.uid}/${type}`)
      .update({
        photoURL: url.id.toString()
      })
      .catch(error => console.log('Update User Data:', { error }));
    dispatch(
      saveUserData({
        ...user,
        [type]: {
          ...user[type],
          photoURL: url.id.toString()
        }
      })
    );
    dispatch(toggleLoadingParam(false));
  } else {
    dispatch(toggleLoadingParam(true));
    const storageRef = firebase.storage().ref(`data/img/profile/${user.uid}`);
    // eslint-disable-next-line no-new
    new Compressor(selectedFile, {
      quality: 0.4,
      success(result) {
        storageRef
          .put(result)
          .then(async snapshot => {
            console.log('snapshot: ' + snapshot.state);
            console.log(
              'progress: ' +
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            if (snapshot.state === UPLOAD_FILE_SUCCESS) {
              const downloadUrl = await storageRef.getDownloadURL();
              await firebase
                .database()
                .ref(`users/${user.uid}/${type}`)
                .update({
                  photoURL: downloadUrl
                })
                .catch(error => console.log('Update User Data:', { error }));
              dispatch(
                saveUserData({
                  ...user,
                  [type]: {
                    ...user[type],
                    photoURL: downloadUrl
                  }
                })
              );
              dispatch(toggleLoadingParam(false));
            }
          })
          .catch(error =>
            console.log('image upload error: ' + error.toString())
          );
      },
      error(err) {
        console.log(err.message);
      }
    });
  }
};

export const getHandledVoiceSpeed = (voiceSpeed: number) => {
  const soundSpeedList = [0.5, 0.75, 1, 1.25, 1.5];
  const currentVoiceSpeed = !_.includes(soundSpeedList, voiceSpeed)
    ? 1
    : voiceSpeed;
  return currentVoiceSpeed;
};

export const signInWithCustomToken = (token: string) => (
  dispatch: Function
) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithCustomToken(token)
      .then(async function(result) {
        let user = result.user;
        console.log('Signin successfully user is', user);
        await fetchRemoteDatabase(`users/${user.uid}`).then(data => {
          let userData = data.val();
          dispatch(saveUserData(userData));
        });
        // eslint-disable-next-line
        localStorage.setItem(SIGNIN_METHOD, PROVIDER_EMAIL);
        resolve(true);
      })
      .catch(error => {
        console.log('error', error);
        reject(error);
      });
  });
};
