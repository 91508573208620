/* @flow */
import _ from 'lodash';
import React, { Component } from 'react';
import styled from 'styled-components';
import DialogMessage from '../sub-components/DialogMessage';
import { CheckButton } from '../sub-components';
import { BUTTON_CONTINUE, DIALOGUE } from '../../constants';
import { PREFIX_LESSON } from '../../../common/constants';

const translatePic = `${PREFIX_LESSON}basecolor-black-translate.svg`;

const DialogContentContainer = styled.div`
  width: 100%;
  transform: translateX(${props => (props.isShowTutorial ? 0 : -50)}%);
  max-width: ${props => (props.isShowTutorial ? 'unset' : '600px')};
  left: ${props => (props.isShowTutorial ? 0 : 'unset')};
  top: ${props =>
    props.isShowTutorial
      ? props.isCollape
        ? 46
        : 113
      : props.isCollape
      ? 136
      : 195}px;
  position: absolute;
  height: ${props =>
    props.isShowTutorial
      ? props.isCollape
        ? 'calc(100vh - 258px)'
        : 'calc(100vh - 325px)'
      : props.isCollape
      ? 'calc(100vh - 248px)'
      : 'calc(100vh - 297px)'};
  border-bottom: solid 1px black;
  overflow: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

type Props = {
  dialogues: Object,
  playing: boolean,
  autoplay: boolean,
  gameState: number,
  translateState: boolean,
  transcriptState: boolean,
  startGame: Function,
  onTranslate: Function,
  onStopSound: Function,
  onPress: Function,
  onReplay: Function,
  triggerDialogAutoPlay: Function,
  dialogSaveScrollFunc: Function,
  unmountDialog: Function,
  dialog_txt_title?: string,
  dialog_btn_translate?: string,
  dialog_btn_stop?: string,
  writing_txt_replay?: string,
  btn_continue?: string,
  btn_hint?: string,
  coins: number,
  isShowTutorial: boolean,
  toggleTutorialMotivate: Function,
  isTargetLangSymbol: boolean,
  isCollape: boolean,
  writing_skip: string,
  onCloseTutorialMotivate: Function,
  setShowTutorial: Function
};

class DialogDisplay extends Component<Props, Object> {
  screen: Object;
  constructor(props: Object) {
    super(props);
    this.state = {
      screen: null
    };
  }

  componentDidMount() {
    // $flow-disable-line
    document.addEventListener('keydown', this.handleEnter);
  }

  handleEnter = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { startGame, toggleTutorialMotivate, isShowTutorial } = this.props;
    if (event.keyCode === 13) {
      if (startGame && !isShowTutorial) {
        startGame();
      } else {
        toggleTutorialMotivate();
      }
    }
  };

  UNSAFE_componentWillMount() {
    const { autoplay = true, triggerDialogAutoPlay } = this.props;
    this.props.dialogSaveScrollFunc(this.scrollDown.bind(this));
    if (autoplay) {
      triggerDialogAutoPlay();
    }
  }

  componentWillUnmount() {
    this.props.unmountDialog();
    // $flow-disable-line
    document.removeEventListener('keydown', this.handleEnter);
  }

  scrollDown(id: number) {
    if (this.screen) {
      this.screen.scrollTo({ y: id * 60 });
    }
  }

  generateChild = () => {
    const {
      dialogues,
      gameState = 0,
      translateState = false,
      transcriptState = false,
      isTargetLangSymbol,
      onPress = () => {},
      isShowTutorial,
      isCollape
    } = this.props;
    const generated = _.map(dialogues, (dialogue, index) => (
      <DialogMessage
        isFirstElement={_.keys(dialogues)[0] === index}
        gameState={gameState}
        translateState={translateState}
        transcriptState={transcriptState}
        key={index}
        index={index}
        {...dialogue}
        onPress={onPress}
        callback={this.scrollDown.bind(this)}
        isTargetLangSymbol={isTargetLangSymbol}
        isShowTutorial={isShowTutorial}
        isCollape={isCollape}
      />
    ));
    return generated;
  };

  render() {
    const {
      translateState,
      dialog_btn_stop,
      writing_txt_replay,
      playing = true,
      startGame = () => {},
      toggleTutorialMotivate = () => {},
      onTranslate = () => {},
      onStopSound = () => {},
      onReplay = () => {},
      dialog_btn_translate,
      btn_continue,
      btn_hint,
      coins,
      isShowTutorial,
      writing_skip,
      onCloseTutorialMotivate,
      setShowTutorial,
      isCollape
    } = this.props;
    return (
      <div>
        <DialogContentContainer
          isCollape={isCollape}
          isShowTutorial={isShowTutorial}
        >
          {this.generateChild()}
        </DialogContentContainer>
        <CheckButton
          quizAnswer={''}
          onPressNext={isShowTutorial ? toggleTutorialMotivate : startGame}
          gameType={DIALOGUE}
          mode={BUTTON_CONTINUE}
          btn_check={''}
          btn_continue={btn_continue}
          btn_hint={btn_hint}
          diffButt={false}
          playing={playing}
          onStopSound={onStopSound}
          onReplay={onReplay}
          dialog_btn_stop={dialog_btn_stop || ''}
          writing_txt_replay={writing_txt_replay || ''}
          onTranslate={onTranslate}
          dialog_btn_translate={dialog_btn_translate}
          translatePic={translatePic}
          translateState={translateState}
          coins={coins}
          isShowTutorial={isShowTutorial}
          writing_skip={writing_skip}
          onCloseTutorialMotivate={onCloseTutorialMotivate}
          setShowTutorial={setShowTutorial}
        />
      </div>
    );
  }
}

export { DialogDisplay };
