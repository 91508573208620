/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import { TranslateGame } from '../components/scenes/TranslateGame';
import { getQuizCards } from '../reducers';
import { mapDispatchToQuizProps } from '../selectors';
import { getLabels } from '../../util';
import { getUserCoins } from '../../profile/selectors';
import { getVoiceSpeed } from '../../chatbots/selectors';

const TranslateGameContainer = (props: Object) => {
  return <TranslateGame {...props} />;
};

export const mapStateToProps = (state: Object) => {
  const coins = getUserCoins(state);
  const voiceSpeed = getVoiceSpeed(state);

  return {
    coins,
    voiceSpeed,
    ...getQuizCards(state, true),
    ...getLabels(
      state,
      'translate_txt_title',
      'btn_check',
      'btn_continue',
      'btn_hint'
    )
  };
};

export { TranslateGameContainer };
export default connect(
  mapStateToProps,
  mapDispatchToQuizProps
)(TranslateGameContainer);
