/* @flow */
import React, { Component } from 'react';
import _ from 'lodash';
import { ChatReviews } from '../sub-components';
import styled from 'styled-components';
import { ConversationUserFooter } from './ConversationUserFooter';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const ChatbotsContainer = styled.div`
  width: 595px;
  padding-bottom: 190px;
  top: 185px;
  position: absolute;
`;
type Props = {
  choices: Array<Object>,
  translationState: boolean,
  selectedChoice: string,
  isBot: boolean,
  chatHistories: Array<Object>,
  transcriptState: boolean,
  updateSelectedChoice: Function,
  currentChat: Object,
  playSound: Function,
  playSoundWithCallback: Function,
  targetLangCode: string,
  currentChatbotId: string,
  voiceSpeed: number,
  onCloseModal: Function,
  networkError: Function,
  noMatch: Function,
  checkSpeakingAnswer: Function,
  nextChatbotVoice: Function,
  lang: Object,
  speakingErrorMsg: string,
  chatbotGlobals: Object,
  PhrasesInfo: Object,
  updateHistories: Function,
  toggleChatbotPlaying: Function,
  coursesData: Object,
  isChatbotPlaying: boolean,
  isTargetLangSymbol: boolean
};

class ConversationSection extends Component<Props> {
  newData: Object;
  constructor(props: Props) {
    super(props);
    this.newData = React.createRef();
  }
  componentDidUpdate() {
    this.newData.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }

  render() {
    const {
      choices,
      translationState,
      selectedChoice,
      transcriptState,
      chatHistories,
      isBot,
      updateSelectedChoice,
      currentChat,
      playSound,
      playSoundWithCallback,
      targetLangCode,
      voiceSpeed,
      currentChatbotId,
      onCloseModal,
      networkError,
      lang,
      noMatch,
      checkSpeakingAnswer,
      nextChatbotVoice,
      speakingErrorMsg,
      chatbotGlobals,
      PhrasesInfo,
      updateHistories,
      coursesData,
      toggleChatbotPlaying,
      isChatbotPlaying,
      isTargetLangSymbol
    } = this.props;
    return (
      <Container>
        <ChatbotsContainer ref={this.newData}>
          <ChatReviews
            chatHistories={chatHistories}
            translationState={translationState}
            transcriptState={transcriptState}
            playSound={playSound}
            isBot={isBot}
            currentChatbotId={currentChatbotId}
            voiceSpeed={voiceSpeed}
            isTargetLangSymbol={isTargetLangSymbol}
          />
          {!isBot && !_.isEmpty(currentChat) ? (
            <ConversationUserFooter
              isChatbotPlaying={isChatbotPlaying}
              onCloseModal={onCloseModal}
              networkError={networkError}
              noMatch={noMatch}
              lang={lang}
              choices={choices}
              voiceSpeed={voiceSpeed}
              translationState={translationState}
              transcriptState={transcriptState}
              selectedChoice={selectedChoice}
              updateSelectedChoice={updateSelectedChoice}
              playSoundWithCallback={playSoundWithCallback}
              targetLangCode={targetLangCode}
              checkSpeakingAnswer={checkSpeakingAnswer}
              nextChatbotVoice={nextChatbotVoice}
              speakingErrorMsg={speakingErrorMsg}
              chatbotGlobals={chatbotGlobals}
              PhrasesInfo={PhrasesInfo}
              updateHistories={updateHistories}
              coursesData={coursesData}
              toggleChatbotPlaying={toggleChatbotPlaying}
              isTargetLangSymbol={isTargetLangSymbol}
            />
          ) : null}
        </ChatbotsContainer>
      </Container>
    );
  }
}

export { ConversationSection };
