/* @flow */
import React, { Component } from 'react';
import { InfoInputBox } from '../../../../profile/sub-components/InfoInputBox';
import { getNextWeekDate } from '../../../actions';
import { mutliStringReplace } from '../../../../common/actions';
import { isValidEmail } from '../../../../util';
import stripe from '../../../../util/PurchaseUtils';
import { PRODUCT_TYPE } from '../../../constants';
import { FormHeader } from '../../sub-components/FormHeader';
import {
  PREFIX_HEADER,
  CARD_NAME,
  CARD_NUMBER,
  STRIPE_POWER_BY_TXT,
  STRIPE_TERMS_TXT,
  STRIPE_PRIVACY_TXT,
  CARD_EXPIRY,
  CARD_CVC,
  EMAIL
} from '../../../../common/constants';
import {
  BackArea,
  BotttomInputStyle,
  Container,
  FormStyle,
  Image,
  InputContainer,
  LeftStripe,
  RightStripe,
  StripeTermsStyle,
  StripeTrialText,
  SubmitButton,
  TermConditionText,
  Title
} from './CheckoutForm.styles';
import { PayPalSection } from '../../sub-components/PayPalSection';

const stripeLogo = () => (
  <svg focusable="false" width="33" height="15" fill="#afafaf">
    <g fillRule="evenodd">
      <path d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z" />
    </g>
  </svg>
);

type Props = {
  random_test_modal_back_txt: string,
  profile_txt_name_card: string,
  profile_txt_card_number: string,
  profile_txt_expire_date: string,
  profile_txt_recurring_billing: string,
  profile_txt_terms_condition: string,
  shop_txt_checkout_title: string,
  card_number: string,
  card_expiration: string,
  shop_txt_checkout_title_top: string,
  shop_txt_checkout_price: any,
  email_error_empty: string,
  card_error_empty: string,
  card_error_number_not_correct: string,
  card_error_invalid_date: string,
  card_error_invalid_cvc: string,
  login_field_name: string,
  btn_submit: string,
  packageName: string,
  email_error_wrongFormat: string,
  trial_date_description_txt: string,
  login_field_email: string,
  isClaim: boolean,
  toggleIsCheckout: Function,
  setTrialUsedIP: Function,
  createPaymentMethodAndCustomer: Function,
  txt_with_7_day_trial?: string,
  isShowingPaypal: boolean,
  handlePayWithPayPal: Function,
  txt_email_field_placeholder: string
};
type State = {
  isUpdatingCard: boolean,
  isUpdateMethod: boolean,
  Email: string,
  cardName: string,
  cardNumber: string,
  cardCvc: string,
  cardExpiry: string,
  brand: string,
  validEmail: boolean,
  validCardNumber: boolean,
  validCardExpiry: boolean,
  validCardCvc: boolean,
  touched: Object,
  isPaypalLoading: boolean
};
class CheckoutForm extends Component<Props, State> {
  elements: any;
  cardNumber: any;
  cardExpiry: any;
  cardCvc: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      isUpdatingCard: false,
      isUpdateMethod: true,
      cardName: '',
      Email: '',
      cardNumber: '',
      cardCvc: '',
      cardExpiry: '',
      brand: '',
      validEmail: false,
      validCardNumber: false,
      validCardExpiry: false,
      validCardCvc: false,
      touched: {},
      isPaypalLoading: false
    };
  }

  componentDidMount() {
    const { card_number, card_expiration } = this.props;

    this.elements = stripe.elements();

    this.cardNumber = this.elements.create(CARD_NUMBER, {
      placeholder: card_number
    });
    this.cardExpiry = this.elements.create(CARD_EXPIRY, {
      placeholder: card_expiration
    });
    this.cardCvc = this.elements.create(CARD_CVC);
    this.cardNumber.mount('#' + CARD_NUMBER);
    this.cardExpiry.mount('#' + CARD_EXPIRY);
    this.cardCvc.mount('#' + CARD_CVC);

    this.cardNumber.on('change', event => {
      if (event.brand) {
        this.setState({ brand: event.brand });
      } else {
        this.setState({ brand: '' });
      }
      this.setState({ validCardNumber: event.complete });
    });
    this.cardNumber.on('blur', e => {
      const { touched } = this.state;
      if (e && touched[e.elementType] !== true) {
        touched[e.elementType] = true;
        this.setState({
          touched
        });
      }
    });
    this.cardExpiry.on('change', event => {
      this.setState({ validCardExpiry: event.complete });
    });
    this.cardExpiry.on('blur', e => {
      const { touched } = this.state;
      if (e && touched[e.elementType] !== true) {
        touched[e.elementType] = true;
        this.setState({
          touched
        });
      }
    });
    this.cardCvc.on('change', event => {
      this.setState({ validCardCvc: event.complete });
    });
    this.cardCvc.on('blur', e => {
      const { touched } = this.state;
      if (e && touched[e.elementType] !== true) {
        touched[e.elementType] = true;
        this.setState({
          touched
        });
      }
    });
  }

  handleChange = (property: any, value: any) => {
    this.setState({ [property]: value });
  };

  validateForm = (id: string) => {
    const {
      cardName,
      Email,
      validCardNumber,
      validCardExpiry,
      validCardCvc
    } = this.state;
    const { isClaim } = this.props;
    if (isClaim) {
      switch (id) {
        case EMAIL:
          return Email.length === 0 ? '' : isValidEmail(Email);
        case CARD_NAME:
          return cardName.length !== 0;
        case CARD_NUMBER:
          return validCardNumber;
        case CARD_EXPIRY:
          return validCardExpiry;
        case CARD_CVC:
          return validCardCvc;
        default:
          break;
      }
    } else {
      switch (id) {
        case CARD_NAME:
          return cardName.length !== 0;
        case CARD_NUMBER:
          return validCardNumber;
        case CARD_EXPIRY:
          return validCardExpiry;
        case CARD_CVC:
          return validCardCvc;
        default:
          break;
      }
    }
  };

  handleTouch = (e: SyntheticEvent<HTMLInputElement>) => {
    const { touched } = this.state;
    if (e && touched[e] !== true) {
      touched[e] = true;

      this.setState({
        touched
      });
    }
  };
  handleSubmit = async (event: SyntheticEvent<HTMLButtonElement>) => {
    const {
      createPaymentMethodAndCustomer,
      isClaim,
      packageName,
      setTrialUsedIP
    } = this.props;
    const { Email } = this.state;
    event.preventDefault();
    if (packageName === PRODUCT_TYPE.yearly) {
      setTrialUsedIP();
    }
    const cardNumber = this.elements.getElement(CARD_NUMBER);
    isClaim
      ? createPaymentMethodAndCustomer(cardNumber, Email)
      : createPaymentMethodAndCustomer(cardNumber);
  };

  onPayPalButtonPressed = async (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    this.setState({ isPaypalLoading: true });
    this.props.handlePayWithPayPal();
  };

  render() {
    const {
      isUpdateMethod,
      isUpdatingCard,
      cardName,
      validCardNumber,
      validCardExpiry,
      validCardCvc
    } = this.state;
    const {
      random_test_modal_back_txt,
      profile_txt_name_card,
      profile_txt_card_number,
      profile_txt_expire_date,
      profile_txt_recurring_billing,
      profile_txt_terms_condition,
      shop_txt_checkout_title,
      login_field_name,
      btn_submit,
      toggleIsCheckout,
      card_error_empty,
      email_error_empty,
      card_error_number_not_correct,
      card_error_invalid_date,
      card_error_invalid_cvc,
      shop_txt_checkout_title_top,
      shop_txt_checkout_price,
      email_error_wrongFormat,
      login_field_email,
      isClaim,
      trial_date_description_txt,
      packageName,
      txt_with_7_day_trial,
      isShowingPaypal,
      txt_email_field_placeholder
    } = this.props;
    return (
      <Container isClaim={isClaim} isUpdateMethod={isUpdateMethod}>
        {!isClaim && (
          <BackArea
            onClick={() => toggleIsCheckout()}
            isUpdateMethod={isUpdateMethod}
            isUpdatingCard={isUpdatingCard}
          >
            <Image src={`${PREFIX_HEADER}back-icon.svg`} />
            <Title>{random_test_modal_back_txt}</Title>
          </BackArea>
        )}
        <InputContainer isUpdateMethod={isUpdateMethod}>
          <FormStyle isUpdatingCard={isUpdatingCard} onSubmit={() => {}}>
            <FormHeader
              isHeader={true}
              shop_txt_checkout_title_top={shop_txt_checkout_title_top}
              shop_txt_checkout_title={shop_txt_checkout_title}
              shop_txt_checkout_price={shop_txt_checkout_price}
              txt_with_7_day_trial={txt_with_7_day_trial}
            />
            {isClaim && (
              <InfoInputBox
                cardName={cardName}
                validCardCvc={validCardCvc}
                validCardExpiry={validCardExpiry}
                validCardNumber={validCardNumber}
                card_error_empty={card_error_empty}
                card_error_invalid_cvc={card_error_invalid_cvc}
                card_error_invalid_date={card_error_invalid_date}
                card_error_number_not_correct={card_error_number_not_correct}
                email_error_empty={email_error_empty}
                email_error_wrongFormat={email_error_wrongFormat}
                handleChange={this.handleChange}
                handleTouch={this.handleTouch}
                icon={login_field_email}
                id={EMAIL}
                inputValue={this.state.Email}
                login_field_name={txt_email_field_placeholder}
                title={login_field_email}
                touched={this.state.touched.Email}
                validateForm={this.validateForm}
              />
            )}

            {isShowingPaypal && (
              <PayPalSection
                isPaypalLoading={this.state.isPaypalLoading}
                onClick={this.onPayPalButtonPressed.bind(this)}
              />
            )}

            <InfoInputBox
              cardName={cardName}
              validCardCvc={validCardCvc}
              validCardExpiry={validCardExpiry}
              validCardNumber={validCardNumber}
              title={profile_txt_name_card}
              login_field_name={login_field_name}
              icon={'PersonFill'}
              id={CARD_NAME}
              inputValue={this.state.cardName}
              handleChange={this.handleChange}
              handleTouch={this.handleTouch}
              touched={this.state.touched.cardName}
              validateForm={this.validateForm}
              email_error_empty={email_error_empty}
              card_error_empty={card_error_empty}
              email_error_wrongFormat={email_error_wrongFormat}
              card_error_number_not_correct={card_error_number_not_correct}
              card_error_invalid_date={card_error_invalid_date}
              card_error_invalid_cvc={card_error_invalid_cvc}
            />
            <InfoInputBox
              cardName={cardName}
              validCardCvc={validCardCvc}
              validCardExpiry={validCardExpiry}
              validCardNumber={validCardNumber}
              title={profile_txt_card_number}
              icon={'CreditCard'}
              id={CARD_NUMBER}
              inputValue={this.state.cardNumber}
              handleChange={this.handleChange}
              handleTouch={this.handleTouch}
              cardType={this.state.brand}
              touched={this.state.touched.cardNumber}
              validateForm={this.validateForm}
              email_error_empty={email_error_empty}
              card_error_empty={card_error_empty}
              email_error_wrongFormat={email_error_wrongFormat}
              card_error_number_not_correct={card_error_number_not_correct}
              card_error_invalid_date={card_error_invalid_date}
              card_error_invalid_cvc={card_error_invalid_cvc}
            />
            <BotttomInputStyle>
              <InfoInputBox
                cardName={cardName}
                validCardCvc={validCardCvc}
                validCardExpiry={validCardExpiry}
                validCardNumber={validCardNumber}
                title={profile_txt_expire_date}
                icon={'Calendar'}
                id={CARD_EXPIRY}
                inputValue={this.state.cardExpiry}
                handleChange={this.handleChange}
                handleTouch={this.handleTouch}
                touched={this.state.touched.cardExpiry}
                validateForm={this.validateForm}
                email_error_wrongFormat={email_error_wrongFormat}
                email_error_empty={email_error_empty}
                card_error_empty={card_error_empty}
                card_error_number_not_correct={card_error_number_not_correct}
                card_error_invalid_date={card_error_invalid_date}
                card_error_invalid_cvc={card_error_invalid_cvc}
              />
              <InfoInputBox
                cardName={cardName}
                validCardCvc={validCardCvc}
                validCardExpiry={validCardExpiry}
                validCardNumber={validCardNumber}
                title={'CVC'}
                icon={'CVC'}
                id={CARD_CVC}
                inputValue={this.state.cardCvc}
                handleChange={this.handleChange}
                handleTouch={this.handleTouch}
                touched={this.state.touched.cardCvc}
                validateForm={this.validateForm}
                email_error_wrongFormat={email_error_wrongFormat}
                email_error_empty={email_error_empty}
                card_error_empty={card_error_empty}
                card_error_number_not_correct={card_error_number_not_correct}
                card_error_invalid_date={card_error_invalid_date}
                card_error_invalid_cvc={card_error_invalid_cvc}
              />
            </BotttomInputStyle>
            {!isClaim ? (
              <TermConditionText>
                {profile_txt_recurring_billing}{' '}
                <a
                  href="https://ling-app.com/term-of-use/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {profile_txt_terms_condition}
                </a>
              </TermConditionText>
            ) : (
              <StripeTermsStyle>
                <LeftStripe>
                  <p>{STRIPE_POWER_BY_TXT}&nbsp;</p>
                  {stripeLogo()}
                </LeftStripe>
                <RightStripe>
                  <a href="https://stripe.com/checkout/legal">
                    {STRIPE_TERMS_TXT}&nbsp;
                  </a>
                  <a href="https://stripe.com/privacy">
                    &nbsp;{STRIPE_PRIVACY_TXT}
                  </a>
                </RightStripe>
              </StripeTermsStyle>
            )}
            {isClaim ? (
              <SubmitButton
                onClick={this.handleSubmit}
                disabled={
                  !(
                    this.validateForm(EMAIL) === true &&
                    this.validateForm(EMAIL) !== '' &&
                    cardName &&
                    validCardNumber &&
                    validCardExpiry &&
                    validCardCvc
                  )
                }
                type="submit"
              >
                {btn_submit}
              </SubmitButton>
            ) : (
              <SubmitButton
                onClick={this.handleSubmit}
                disabled={
                  !(
                    cardName &&
                    validCardNumber &&
                    validCardExpiry &&
                    validCardCvc
                  )
                }
                type="submit"
              >
                {btn_submit}
              </SubmitButton>
            )}
          </FormStyle>
        </InputContainer>
        {packageName === PRODUCT_TYPE.yearly && (
          <StripeTrialText>
            {mutliStringReplace(
              {
                d: shop_txt_checkout_price,
                date:
                  getNextWeekDate().day +
                  '/' +
                  getNextWeekDate().month +
                  '/' +
                  getNextWeekDate().year
              },
              trial_date_description_txt
            )}
          </StripeTrialText>
        )}
      </Container>
    );
  }
}

export { CheckoutForm };
