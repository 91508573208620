import React, { Component } from 'react';
import styled from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';
import _ from 'lodash';
import './App.css';
import '@fontsource/poppins';
import RouterComponent from './Router';
import { FIRST_TIME, SHOW_TUTORIAL, SHOW_REMINDER } from './common/constants';
import { isLocalStorageNameSupported } from './util';
import { connect } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './style/theme';
import { getConfigState } from './config/selectors';
import { getPurchaseState } from './purchase/selectors';
import { getOnboardingState } from './onboarding/selectors';
import { bindActionCreators } from 'redux';

import * as ConfigActions from './config/ConfigActions';
import * as OnboardActions from './onboarding/actions/OnboardingActions';
import TrackerService from './tracker/TrackerService';

import { initAmplitude } from './util/Amplitude';
import { initializeReactGA } from './util/Analytic';
import { initializeFacebookPixel } from './util/FacebookPixel';
import '@fontsource/poppins';
import '@fontsource-variable/noto-sans-georgian';

const Body = styled.div`
  font-family: Helvetica;
`;

class App extends Component {
  stripePromise = null;

  componentDidMount() {
    initializeReactGA();
    initAmplitude();
    initializeFacebookPixel();
    const { state } = this.props;
    isLocalStorageNameSupported();
    this.stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY_HK);
    if (
      !_.includes(window.location.pathname, '/claim') &&
      !_.includes(window.location.pathname, '/affiliate') &&
      !_.includes(window.location.pathname, '/payment') &&
      !_.includes(window.location.pathname, '/shop') &&
      !_.includes(window.location.pathname, '/') &&
      state.onboarding.campaignId > 0 &&
      !_.includes(window.location.pathname, '/languagepicker') &&
      window.location.pathname.indexOf('payment') === -1 &&
      navigator.userAgent.toLowerCase().indexOf('mobile') > -1
    ) {
      if (
        (state.onboarding.campaignId > 0 &&
          !state.onboarding.completeCampaigns.includes(
            state.onboarding.campaignId
          )) ||
        state.config.campaignId === '0'
      ) {
        window.location.href = 'https://lingapp.page.link/oyik';
      }
    }
  }
  UNSAFE_componentWillMount() {
    const { actions } = this.props;

    actions.config.startRemoteConfig();
    // eslint-disable-next-line
    if (typeof Storage !== 'undefined' && !localStorage.getItem(FIRST_TIME)) {
      // eslint-disable-next-line
      localStorage.setItem(FIRST_TIME, true);
      // eslint-disable-next-line
      localStorage.setItem(SHOW_TUTORIAL, true);
      // eslint-disable-next-line
      localStorage.setItem(SHOW_REMINDER, true);
    }
  }

  componentWillUnmount() {
    this.stripePromise = null;
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Body>
          <RouterComponent />
          <TrackerService />
        </Body>
      </ThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    state: {
      config: getConfigState(state),
      onboarding: getOnboardingState(state),
      purchase: getPurchaseState(state)
    }
  };
}

const mapDispatchToProps = dispatch => ({
  actions: {
    config: bindActionCreators(ConfigActions, dispatch),
    onboarding: bindActionCreators(OnboardActions, dispatch)
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
