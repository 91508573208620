/* @flow */
import { PRODUCT_TYPE } from '../shop/constants';
import stripe from '../util/PurchaseUtils';
import Analytic from '../util/Analytic';
import axios from 'axios';
import _ from 'lodash';
import { getUserProperty, getStripeProductId, updateSubscription } from './';
export const toggleLoginPurchase = (
  isLoginPurchase: boolean,
  manageSceneStateChangeHandler: Function
) => {
  manageSceneStateChangeHandler('isLoginPurchase', !isLoginPurchase);
};

export const onToggleIsCheckout = (
  isProUser: boolean,
  history: Object,
  isCheckout: boolean,
  manageSceneStateChangeHandler: Function
) => {
  window.scrollTo(0, 0);
  if (isProUser) {
    history.push('/');
  } else {
    manageSceneStateChangeHandler('isCheckout', !isCheckout);
  }
};

export const onBuyYearly = (
  productName: string,
  isLoginPurchase: boolean,
  user: Object,
  currency: string,
  isProUser: boolean,
  history: Object,
  isCheckout: boolean,
  manageSceneStateChangeHandler: Function
) => {
  let userEmail = getUserProperty(user, 'email');
  let productId = getStripeProductId(currency, PRODUCT_TYPE.yearly, user);
  Analytic.log(Analytic.key.A4, {
    category: Analytic.key.A4,
    action: 'Click',
    label: productName
  });
  Analytic.logGA(Analytic.key.A4, {
    category: Analytic.key.A4,
    action: 'Click',
    label: productName
  });
  manageSceneStateChangeHandler('productId', productId);
  manageSceneStateChangeHandler('productName', productName);

  if (_.isEmpty(userEmail)) {
    toggleLoginPurchase(isLoginPurchase, manageSceneStateChangeHandler);
  } else {
    onToggleIsCheckout(
      isProUser,
      history,
      isCheckout,
      manageSceneStateChangeHandler
    );
  }
};

export const toggleDeleteAccount = (
  isDeleteAcc: boolean,
  manageSceneStateChangeHandler: Function
) => {
  manageSceneStateChangeHandler('isDeleteAcc', !isDeleteAcc);
  manageSceneStateChangeHandler('isDeleteSuccess', false);
};

export const onUpdateCard = async (
  event: SyntheticEvent<HTMLButtonElement>,
  functions: string,
  cardNumber: Object,
  cardName: string,
  isUpdateMethod: boolean,
  isUpdatingCard: boolean,
  subscriptionData: Object,
  user: Object,
  manageSceneStateChangeHandler: Function
) => {
  event.preventDefault();
  manageSceneStateChangeHandler('isUpdatingCard', !isUpdatingCard);
  const uid = _.isEmpty(user.uid) ? user.anonymous.id : user.uid;
  stripe
    .createPaymentMethod({
      type: 'card',
      card: cardNumber,
      billing_details: { name: uid }
    })
    .then(result => {
      attactCustomerCard(
        result.paymentMethod.id,
        subscriptionData,
        functions,
        isUpdateMethod,
        isUpdatingCard,
        user,
        manageSceneStateChangeHandler
      );
    });
  console.log('handleSubmit');
};
export const attactCustomerCard = (
  paymentID: string,
  subscriptionData: Object,
  functions: string,
  isUpdateMethod: boolean,
  isUpdatingCard: boolean,
  user: Object,
  manageSceneStateChangeHandler: Function
) => {
  axios
    .post(`${functions}validateLingWeb-stripeApi/payment/attach`, {
      customerid: subscriptionData.customer,
      paymentid: paymentID
    })
    .then(response => {
      updateSubscription(
        functions,
        isUpdatingCard,
        isUpdateMethod,
        user,
        manageSceneStateChangeHandler,
        paymentID,
        subscriptionData
      );
    })
    .catch(error => {
      if (error) {
        alert('Your card is declined');
        manageSceneStateChangeHandler('isUpdateMethod', !isUpdateMethod);
        manageSceneStateChangeHandler('isUpdatingCard', !isUpdatingCard);
      }
      console.log('error: do parsing', error);
    });
};
