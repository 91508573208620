// @flow
import moment from 'moment';
import { getInviteCampaigns, getUserReferral } from './';
import _ from 'lodash';
const parseStartDateString = (dateString: string) =>
  moment.utc(`${dateString} 00:00`);

const parseEndDateString = (dateString: string) =>
  moment.utc(`${dateString} 23:59`);

export const isNowWithin = (startDateString: string, endDateString: string) => {
  const now = moment.utc();
  const startDate = parseStartDateString(startDateString);
  const endDate = parseEndDateString(endDateString);
  return now.isBetween(startDate, endDate);
};
export const onExiting = (animating: any) => {
  animating = true;
};

export const onExited = (animating: any) => {
  animating = false;
};

export const onNextPromo = (
  promoCount: number,
  currentPromoId: number,
  stateChangeHandler: Function
) => {
  stateChangeHandler(
    'currentPromoId',
    currentPromoId + 1 === promoCount ? 0 : currentPromoId + 1
  );
};

export const promoNext = (
  animating: any,
  activeIndex: number,
  promoteEntries: Object[],
  setActiveIndex: Function
) => {
  if (animating) {
    return;
  }
  const nextIndex =
    activeIndex === promoteEntries.length - 1 ? 0 : activeIndex + 1;
  setActiveIndex(nextIndex);
};

export const promoPrevious = (
  animating: any,
  activeIndex: number,
  promoteEntries: Object[],
  setActiveIndex: Function
) => {
  if (animating) {
    return;
  }
  const nextIndex =
    activeIndex === 0 ? promoteEntries.length - 1 : activeIndex - 1;
  setActiveIndex(nextIndex);
};

export const next = (
  animating: any,
  activeIndex: number,
  entries: Object[],
  setActiveIndex: Function
) => {
  if (animating) {
    return;
  }
  const nextIndex = activeIndex === entries.length - 1 ? 0 : activeIndex + 1;
  setActiveIndex(nextIndex);
};

export const previous = (
  animating: any,
  activeIndex: number,
  entries: Object[],
  setActiveIndex: Function
) => {
  if (animating) {
    return;
  }
  const nextIndex = activeIndex === 0 ? entries.length - 1 : activeIndex - 1;
  setActiveIndex(nextIndex);
};

export const checkUserReferral = async (user: Object): any => {
  const { claimedReferral = {}, uid } = user;
  const campaigns = (await getInviteCampaigns().once('value')) || {};
  let campaignsList = _.map(campaigns.val(), (item, index) => {
    return {
      index,
      ...item
    };
  });
  campaignsList = _.sortBy(campaignsList, ['goal']);
  const referral = await getUserReferral(uid).once('value');
  let amountReferral = _.size(referral.val());
  let currentCampaign = {};
  _.forEach(campaignsList, campaign => {
    const { goal, index } = campaign;
    if (goal <= amountReferral && !claimedReferral[index]) {
      currentCampaign = campaign;
      return;
    }
    amountReferral -= goal;
  });
  return currentCampaign;
};

export const isInviteFriend = (
  isShowInviteFriendModal: boolean,
  referralCampaign: Object,
  stateChangeHandler: Function,
  promoId: Array<number>
) => {
  if (isShowInviteFriendModal && !_.isEmpty(referralCampaign)) {
    stateChangeHandler('promoId', promoId.concat(2));
  }
  return isShowInviteFriendModal && !_.isEmpty(referralCampaign);
};
export const isShowPromotion = (
  isShowPromotionModal: boolean,
  stateChangeHandler: Function,
  promoId: Array<number>
) => {
  if (isShowPromotionModal) {
    stateChangeHandler('promoId', promoId.concat(2));
  }
  return isShowPromotionModal;
};
