/* @flow */
import * as React from 'react';
import { SpellingGameScreen } from '../main-components';

const CompleteSentence = (props: Object) => {
  const {
    translation,
    getCharacterCards,
    getCompleteSentenceCards,
    title_txt_complete,
    btn_hint,
    error_txt_game_wrong,
    btn_check,
    btn_continue
  } = props;
  return (
    <SpellingGameScreen
      {...props}
      instruction={title_txt_complete}
      question={translation}
      transcript={null}
      topCards={getCompleteSentenceCards(props)}
      bottomCards={getCharacterCards(props)}
      btn_hint={btn_hint}
      error_txt_game_wrong={error_txt_game_wrong}
      btn_check={btn_check}
      btn_continue={btn_continue}
    />
  );
};

export { CompleteSentence };
