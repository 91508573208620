/* @flow */
import React, { PureComponent, Fragment } from 'react';
import styled from 'styled-components';
import { SILVER_COLOR } from '../../../common/constants';
import { Card, FlashcardRow } from '../../../games/components/sub-components';
import { getUnitContentImageUrl } from '../../../util';
const ab = require('../../../img/icons/word-icon-24x24.png');
const sentenceIcon = require('../../../games/components/sub-components/img/flashcard/sentence-icon.png');

const cardStyle = {
  height: 286,
  width: 220,
  borderRadius: 10,
  borderStyle: 'solid',
  borderColor: '#f1f1f1',
  borderWidth: 1,
  boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.05)',
  marginVertical: 14
};

const Container = styled.div`
  flex: 1;
  height: 100vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const ImageStyle = styled.img`
  align-self: center;
  height: 150px;
  width: 150px;
`;

const FlashcardContainer = styled.div`
  display: flex;
  margin: auto;
  width: 800px;
`;

const FlashcardImageContainer = styled.div`
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: 0px solid ${SILVER_COLOR};
  border-radius: 10px;
`;

const FlashcardRowContainer = styled.div`
  width: 100%;
`;

type Props = {
  item: Object,
  transcriptState: boolean,
  soundPath?: string,
  voiceSpeed: number
};

type State = { playingPhrase: string };

class FlashCardReview extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      playingPhrase: ''
    };
  }

  setPlayingPhrase(id: string) {
    this.setState({
      playingPhrase: id
    });
  }

  removePlayingPhrase() {
    this.setState({
      playingPhrase: ''
    });
  }
  render() {
    const { item, transcriptState, soundPath, voiceSpeed } = this.props;
    const { playingPhrase } = this.state;
    const { words, sentences } = item;
    const {
      id,
      original = '',
      translation = '',
      soundFile = '',
      transcript = ''
    } = words;
    const sentenceCard = sentences ? (
      <FlashcardRow
        id={sentences.id}
        soundFile={sentences.soundFile}
        translation={sentences.translation}
        original={sentences.original}
        transcript={sentences.transcript || ''}
        transcriptState={transcriptState || false}
        isSentenceExist={sentences || false}
        voiceSpeed={voiceSpeed}
        setPlayingPhrase={() => this.setPlayingPhrase(sentences.id)}
        removePlayingPhrase={() => this.removePlayingPhrase()}
        playingPhrase={playingPhrase}
        soundPath={soundPath}
        imgSrc={ab}
      />
    ) : null;

    const imageSource = getUnitContentImageUrl(id);

    return (
      <Fragment>
        <Container>
          <FlashcardContainer>
            <FlashcardImageContainer>
              <Card style={cardStyle}>
                <ImageStyle src={imageSource} />
              </Card>
            </FlashcardImageContainer>
            <FlashcardRowContainer>
              <FlashcardRow
                id={id}
                soundFile={soundFile}
                translation={translation}
                original={original}
                transcript={transcript}
                transcriptState={transcriptState || false}
                isSentenceExist={true}
                imgSrc={sentenceIcon}
                voiceSpeed={voiceSpeed}
                setPlayingPhrase={() => this.setPlayingPhrase(id)}
                removePlayingPhrase={() => this.removePlayingPhrase()}
                playingPhrase={playingPhrase}
                soundPath={soundPath}
              />
              {sentenceCard}
            </FlashcardRowContainer>
          </FlashcardContainer>
        </Container>
      </Fragment>
    );
  }
}

export { FlashCardReview };
