/* @flow */
import React, { Component } from 'react';
import styled from 'styled-components';
import { parseSvg, getCorrectPath } from './Helpers';

const CanvasContainer = styled.canvas`
  cursor: crosshair;
  position: absolute;
  z-index: 2;
`;

const WIDTH = 498;
const HEIGHT = 325;
const SCALE_SIZE = 2.5;

type Props = {
  path: string,
  nextStep: Function,
  strokeNumber: number,
  guideStep: number,
  onFail: Function,
  onCorrect: Function,
  onDone: Function
}

class DrawingScene extends Component<Props> {
  canvas: any;
  ctx: any;
  last_mouseX: number;
  last_mouseY: number;
  mouseX: number;
  mouseY: number;
  mousedown: any;
  answer: Array<any> = [];
  path: string;
  question: any;
  width: number;
  height: number;

  componentDidMount() {
    this.canvas = document.getElementById('canvas');
    // $FlowFixMe
    this.ctx = this.canvas.getContext('2d');
    this.canvas.setAttribute('width', WIDTH);
    this.canvas.setAttribute('height', HEIGHT);
    this.last_mouseX = 0;
    this.last_mouseY = 0;
    this.mouseX = 0;
    this.mouseY = 0;
    this.mousedown = false;
    this.width = this.ctx.canvas.width;
    this.height = this.ctx.canvas.height;
  }

  onMouseDown() {
    this.mousedown = true;
  }

  onMouseUp() {
    this.onStop();
  }

  onMouseOut() {
    if (this.mousedown) {
      this.onStop();
    }
  }

  onStop() {
    this.evaluateResult();
    this.mousedown = false;
    this.answer = [];
    this.ctx.clearRect(0, 0, WIDTH, HEIGHT);
  }

  evaluateResult() {
    const { nextStep, path: questionPath, onCorrect, onDone, onFail, guideStep, strokeNumber } = this.props;
    if (this.answer.length > 0 && questionPath) {
      const { x: firstX, y: firstY } = this.answer[0];
      const coordinates = parseSvg(questionPath);
      const coordinate = coordinates[0];
      const correctPath = getCorrectPath(coordinate);
      const { x: startX, y: startY } = correctPath[0];
      /*eslint-disable */
      // $FlowFixMe
      const question = new Path2D(questionPath);
      // $FlowFixMe
      let matrix = document.createElementNS("http://www.w3.org/2000/svg", "svg").createSVGMatrix()

      const questionCanvas = new Path2D();
      matrix = matrix.scale(SCALE_SIZE);
      questionCanvas.addPath(question, matrix);
      /*eslint-enable */
      let totalCorrect = 0;
      let isPassedImportantPoint = 0;
      this.answer.forEach(path => {
        const { x, y } = path;
        const calX = x - (firstX - (startX * SCALE_SIZE));
        const calY = y - (firstY - (startY * SCALE_SIZE));
        totalCorrect = this.ctx.isPointInStroke(questionCanvas, calX, calY) ? totalCorrect + 1 : totalCorrect;
      });

      const highestX = this.answer.reduce((last, current) => last > current.x ? last : current.x, 0);
      const lowestX = this.answer.reduce((last, current) => last < current.x ? last : current.x, highestX);
      const highestY = this.answer.reduce((last, current) => last > current.y ? last : current.y, 0);
      const lowestY = this.answer.reduce((last, current) => last < current.y ? last : current.y, highestY);

      correctPath.forEach(coordinate => {
        const { x, y } = coordinate;
        const calX = Math.round(x + (firstX - startX));
        const calY = Math.round(y + (firstY - startY));
        isPassedImportantPoint = (calX >= lowestX && calX <= highestX) && (calY >= lowestY && calY <= highestY) ? isPassedImportantPoint + 1 : isPassedImportantPoint;
      });
      const evaluate = totalCorrect / this.answer.length;
      const evaluatePassedImportantPoint = isPassedImportantPoint / correctPath.length;

      const isPassed = (evaluate > 0.10) && (evaluatePassedImportantPoint >= 0.8);
      if (isPassed) {
        nextStep();
        onCorrect();
        if (guideStep === strokeNumber) {
          onDone();
        }
      } else {
        onFail();
      }
    }
  }

  onMouseMove(e: Object) {
    this.mouseX = parseInt(e.clientX - this.ctx.canvas.offsetLeft, 10);
    this.mouseY = parseInt(e.clientY - this.ctx.canvas.offsetTop + window.pageYOffset, 10);
    if (this.mousedown) {
      this.ctx.beginPath();
      this.ctx.globalCompositeOperation = 'source-over';
      this.ctx.strokeStyle = 'black';
      this.ctx.lineWidth = 5;
      this.ctx.moveTo(this.last_mouseX, this.last_mouseY);
      this.ctx.lineTo(this.mouseX, this.mouseY);
      this.ctx.lineJoin = this.ctx.lineCap = 'round';
      this.ctx.closePath();
      this.ctx.stroke();
      this.answer.push({ x: this.mouseX, y: this.mouseY });
    }
    this.last_mouseX = this.mouseX;
    this.last_mouseY = this.mouseY;
  }

  render() {
    return (
      <CanvasContainer
        id="canvas"
        onMouseDown={this.onMouseDown.bind(this)}
        onMouseUp={this.onMouseUp.bind(this)}
        onMouseOut={this.onMouseOut.bind(this)}
        onMouseMove={(e) => this.onMouseMove(e)}
      />
    );
  }
}

export { DrawingScene };
