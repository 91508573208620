import { IMAGE_PATH } from '../../../../common/constants';

const imgPath = image => `${IMAGE_PATH}/onboarding/${image}`;

export const getPlanLabels = labels => ({
  yearly: labels['txt_dsc_annual_payment_of'],
  quarterly: labels['txt_dsc_quarterly_payment_of'],
  monthly: labels['txt_dsc_monthly_payment_of']
});

export const getReasonsData = labels => ({
  0: {
    header: labels['txt_interactive_exercises'],
    subtext: labels['txt_dsc_improve_pronunciation'],
    icon: imgPath('exercises.png')
  },
  1: {
    header: labels['txt_engaging_activities'],
    subtext: labels['txt_dsc_practice_skills'],
    icon: imgPath('activities.png')
  },
  2: {
    header: labels['txt_mix_languages'],
    subtext: labels['txt_choose_60_languages'],
    icon: imgPath('mix.png')
  },
  3: {
    header: labels['txt_proven_results'],
    subtext: labels['txt_dsc_learning_methods'],
    icon: imgPath('proven.png')
  }
});

export const getPhilosophyData = labels => ({
  1: {
    headerText: labels['txt_2_minutes'],
    subtext: labels['txt_dsc_learn_new_words']
  },
  2: {
    headerText: labels['txt_3_minutes'],
    subtext: labels['txt_dsc_review_vocabulary']
  },
  3: {
    headerText: labels['txt_5_minutes'],
    subtext: labels['txt_dsc_learn_from_natives']
  },
  4: {
    headerText: labels['txt_and_more'],
    subtext: labels['txt_dsc_explore_lessons']
  }
});

export const getTestimonialData = labels => [
  {
    text: labels['txt_review_paywall_practical_application'],
    userName: 'Srithorn Pengsawara',
    rating: '4.5',
    img: imgPath('sample-user.png')
  },
  {
    text: labels['txt_review_paywall_favourite_application'],
    userName: 'James Rowland',
    rating: '4.8',
    img: imgPath('sample-user-1.png')
  },
  {
    text: labels['txt_review_paywall_fun'],
    userName: 'Sristi Bardewa',
    rating: '4.8',
    img: imgPath('sample-user-2.png')
  },
  {
    text: labels['txt_review_paywall_good_for_beginners'],
    userName: 'Rodney Piliin',
    rating: '4.9',
    img: imgPath('sample-user-3.png')
  }
];

export const getFaqData = labels => [
  {
    id: 1,
    question: labels['txt_faq_how_many_languages'],
    answer: labels['txt_faq_answer_how_many_languages'],
    additionalInfo: [],
    endText: ''
  },
  {
    id: 2,
    question: labels['txt_faq_best_way_to_learn'],
    answer: labels['txt_faq_answer_best_way_to_learn'],
    additionalInfo: [],
    endText: ''
  },
  {
    id: 3,
    question: labels['txt_faq_learn_from_apps'],
    answer: labels['txt_faq_answer_learn_from_apps'],
    additionalInfo: [],
    endText: ''
  }
];
