/* @flow */
// $FlowFixMe for useState, useEffect
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { firebase } from '../../system/Firebase';
import { IMAGE_PATH, PREFIX_LESSON } from '../../common/constants';
import {
  LOCAL_STORAGE_CONSTANTS,
  addToOSI,
  getCheckoutSessionData,
  getLocalStorage,
  getStripePromoCodeId,
  setLocalStorage,
  stripeCheckoutWithPromoCode
} from '../../util';
import { setUTM } from '../../util/UtmHelper';
import AuthForm from '../sub-components/AuthForm/AuthForm';
import { fetchRemoteDatabase } from '../../lessons/sagas';
// $FlowFixMe for useHistory
import { useHistory, useParams } from 'react-router-dom';
// import { triggerLoadLanguageData } from '../../common/actions';

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 431px;
  z-index: 1;
`;

const Container = styled.div`
  flex-direction: column;
  background-image: linear-gradient(163deg, #fbd249 24%, #f5a623 82%);
  width: 100vw;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
  /* padding-top: 30px;
  padding-bottom: 30px; */
`;

const GiftFireworkStyle = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 0;
`;

const lingLoading = `${IMAGE_PATH}ling-loader.gif`;
const giftFirework = `${PREFIX_LESSON}gift-firework.svg`;

type Props = {
  functions: string,
  reset_password_txt_description: string,
  login_field_email: string,
  btn_continue: string,
  login_field_name: string,
  login_field_firstName: string,
  login_field_password: string,
  login_msg_reenterPassword: string,
  at_least_6_password_txt: string,
  sign_up_txt_title: string,
  login_txt_title: string,
  login_txt_forget_password_web: string,
  already_register_txt: string,
  email_txt_loginNow_link: string,
  dont_have_account_yet_txt: string,
  claim_warning_txt: string,
  signUpWithEmail: Function,
  linkWithGoogleRedirect: Function,
  signInWithFbRedirect: Function,
  linkWithFbRedirect: Function,
  linkWithAppleRedirect: Function,
  sendPasswordResetEmail: Function,
  email_error_fieldsMissing: string,
  email_error_wrongFormat: string,
  signInWithEmailAndPassword: Function,
  saveUserData: Function,
  triggerLoadLanguageLabel: Function,
  onRedirectSignInWithCredential: Function,
  createNewDatabaseUser: Function,
  email_error_matchPasswords: string,
  email_error_longerPassword: string,
  email_error_alreadyUsed: string,
  email_error_userNotFound: string,
  email_error_wrongPassword: string,
  login_txt_forget_password_title: string,
  isDev: boolean,
  txt_email_field_placeholder: string
};

const Affiliate = ({
  functions,
  reset_password_txt_description,
  login_field_email,
  btn_continue,
  login_field_name,
  login_field_firstName,
  login_field_password,
  login_msg_reenterPassword,
  at_least_6_password_txt,
  sign_up_txt_title,
  login_txt_title,
  login_txt_forget_password_web,
  already_register_txt,
  email_txt_loginNow_link,
  dont_have_account_yet_txt,
  claim_warning_txt,
  signUpWithEmail,
  linkWithGoogleRedirect,
  signInWithFbRedirect,
  linkWithFbRedirect,
  linkWithAppleRedirect,
  sendPasswordResetEmail,
  email_error_fieldsMissing,
  email_error_wrongFormat,
  onRedirectSignInWithCredential,
  signInWithEmailAndPassword,
  saveUserData,
  createNewDatabaseUser,
  email_error_matchPasswords,
  email_error_longerPassword,
  email_error_alreadyUsed,
  email_error_userNotFound,
  email_error_wrongPassword,
  login_txt_forget_password_title,
  isDev,
  triggerLoadLanguageLabel,
  txt_email_field_placeholder
}: Props) => {
  const [isAffiliateLogin, setIsAffiliateLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const url = new URL(window.location.href);
  const type = url.searchParams.get('type') || '';
  const sessionId = url.searchParams.get('sessionId') || '';
  const promoCode = url.searchParams.get('promoCode') || '';
  let auth = firebase.auth();
  const history = useHistory();
  const dispatch = useDispatch();
  let { receipt, productName } = useParams();

  useEffect(() => {
    const promoName = getLocalStorage(LOCAL_STORAGE_CONSTANTS.PROMO_NAME);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'purchase_ga-ua',
      event_action: 'purchase',
      event_category: 'ecommerce',
      event_label: receipt,
      event_value: getLocalStorage(LOCAL_STORAGE_CONSTANTS.REVENUE),
      userId: 'anonymous',
      ecommerce: {
        currencyCode: 'USD',
        purchase: {
          actionField: {
            affiliation: 'Web',
            coupon: promoName,
            id: receipt,
            revenue: getLocalStorage(LOCAL_STORAGE_CONSTANTS.REVENUE)
          },
          products: [
            {
              name: productName,
              id: getLocalStorage(LOCAL_STORAGE_CONSTANTS.PRODUCT_ID),
              price: getLocalStorage(LOCAL_STORAGE_CONSTANTS.REVENUE),
              category: getLocalStorage(LOCAL_STORAGE_CONSTANTS.CATEGORY),
              variant: '',
              quantity: 1
            }
          ]
        }
      }
    });

    window.dataLayer.push({
      event: 'purchase_ga4',
      userId: 'anonymous',
      ecommerce: {
        affiliation: 'Web',
        coupon: promoName,
        currency: 'USD',
        items: [
          {
            affiliation: 'Web',
            coupon: promoName,
            currency: 'USD',
            discount: '',
            index: 0,
            item_brand: 'ling-app',
            item_category: 'subscription',
            item_id: getLocalStorage(LOCAL_STORAGE_CONSTANTS.PRODUCT_ID),
            item_name: productName,
            price: getLocalStorage(LOCAL_STORAGE_CONSTANTS.REVENUE),
            quantity: 1
          }
        ],
        transaction_id: receipt,
        value: getLocalStorage(LOCAL_STORAGE_CONSTANTS.REVENUE)
      }
    });
  }, []);

  //NOTE: need to refactor later, need to merged without code review due to deadline
  useEffect(() => {
    (async function() {
      auth
        .getRedirectResult()
        .then(async result => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          let token = result.credential.accessToken;
          // The signed-in user info.
          let user = result.user;
          console.log(
            'Login successfully  user is',
            user,
            '\n With Token',
            token
          );
          dispatch(onRedirectSignInWithCredential(result.credential));
        })
        .catch(error => {
          // Handle Errors here.
          if (error.credential) {
            setIsLoading(true);
            dispatch(onRedirectSignInWithCredential(error.credential));
          }
        });
      auth.onAuthStateChanged(async user => {
        if (!user) {
          auth
            .signInAnonymously()
            .then(result => {
              const { user } = result;
              fetchUser(user);
            })
            .catch(error => {
              // Handle Errors here.
              var errorCode = error.code;
              if (errorCode === 'auth/operation-not-allowed') {
                alert(
                  'You must enable Anonymous auth in the Firebase Console.'
                );
              }
            });
        } else {
          if (!type) {
            const product = url.searchParams.get('product') || '';
            auth.signOut();
            setUTM();
            setLocalStorage('hostName', window.location.pathname);
            stripeCheckoutWithPromoCode(functions, product, promoCode, isDev);
          }
          if (!isLoading && type && user.isAnonymous) {
            setIsAffiliateLogin(true);
            triggerLoadLanguageLabel();
          } else if (type && !user.isAnonymous) {
            setIsAffiliateLogin(false);
            fetchRemoteDatabase(`users/${user.uid}`).then(async data => {
              const value = data && data.val();
              if (value) {
                saveUserData(value);
                const { osi = null } = await getStripePromoCodeId(promoCode);
                const { session, sub, pi } = await getCheckoutSessionData(
                  functions,
                  sessionId
                );
                const { amount_total, currency } = session;
                const receiptId = sub ? sub.latest_invoice : pi.id;
                sub
                  ? addToOSI(functions, osi, receiptId, amount_total, currency)
                  : addToOSI(functions, osi, receiptId, amount_total, currency);
                history.push(
                  `/payment/${type}/${receiptId}/?promoCode=${promoCode}`
                );
              } else {
                createNewDatabaseUser(
                  user,
                  (user.providerData[0] &&
                    user.providerData[0].providerId.split('.')[0]) ||
                    'anonymous'
                );
              }
            });
          }
        }
      });

      return () => {
        console.log('Affiliate unmount');
      };
    })();
  }, []);

  const fetchUser = (user: Object) => {
    fetchRemoteDatabase(`users/${user.uid}`).then(data => {
      const value = data && data.val();
      if (value) {
        saveUserData(value);
      } else {
        createNewDatabaseUser(
          user,
          (user.providerData[0] &&
            user.providerData[0].providerId.split('.')[0]) ||
            'anonymous'
        );
      }
    });
  };
  //NOTE: To check if label is loaded
  return isAffiliateLogin && sign_up_txt_title === 'Sign Up' ? (
    <>
      <AuthForm
        login_txt_forget_password_title={login_txt_forget_password_title}
        email_error_alreadyUsed={email_error_alreadyUsed}
        email_error_userNotFound={email_error_userNotFound}
        email_error_wrongPassword={email_error_wrongPassword}
        reset_password_txt_description={reset_password_txt_description}
        login_field_email={login_field_email}
        btn_continue={btn_continue}
        login_field_name={login_field_name}
        login_field_firstName={login_field_firstName}
        login_field_password={login_field_password}
        login_msg_reenterPassword={login_msg_reenterPassword}
        at_least_6_password_txt={at_least_6_password_txt}
        sign_up_txt_title={sign_up_txt_title}
        login_txt_title={login_txt_title}
        login_txt_forget_password_web={login_txt_forget_password_web}
        dont_have_account_yet_txt={dont_have_account_yet_txt}
        already_register_txt={already_register_txt}
        email_txt_loginNow_link={email_txt_loginNow_link}
        claim_warning_txt={claim_warning_txt}
        linkWithGoogleRedirect={linkWithGoogleRedirect}
        linkWithFbRedirect={linkWithFbRedirect}
        linkWithAppleRedirect={linkWithAppleRedirect}
        sendPasswordResetEmail={sendPasswordResetEmail}
        email_error_fieldsMissing={email_error_fieldsMissing}
        email_error_wrongFormat={email_error_wrongFormat}
        signUpWithEmail={signUpWithEmail}
        signInWithEmailAndPassword={signInWithEmailAndPassword}
        type={type}
        sessionId={sessionId}
        promoCode={promoCode}
        email_error_matchPasswords={email_error_matchPasswords}
        email_error_longerPassword={email_error_longerPassword}
        txt_email_field_placeholder={txt_email_field_placeholder}
      />
    </>
  ) : (
    <Container>
      <GiftFireworkStyle src={giftFirework} alt={giftFirework} />

      <SpinnerContainer>
        <img src={lingLoading} alt={lingLoading} />
      </SpinnerContainer>
    </Container>
  );
};

export { Affiliate };
