import React from 'react';
import { styled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import { useTheme } from '@mui/material/styles';

const SurveySlider = ({ slider, handleChange, labels }) => {
  const { unitLevel_txt_basic, unitLevel_txt_beginner, txt_native } = labels;
  const theme = useTheme();
  const marks = [
    {
      value: 5,
      label: unitLevel_txt_basic.toUpperCase()
    },
    {
      value: 25,
      label: ''
    },
    {
      value: 50,
      label: unitLevel_txt_beginner.toUpperCase()
    },
    {
      value: 75,
      label: ''
    },
    {
      value: 100,
      label: txt_native.toUpperCase()
    }
  ];

  const SurveySlider = styled(Slider)(({ theme }) => ({
    color: '#4094ED',
    height: 6,
    padding: '15px 0',
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: '#4094ED'
    },
    '& .MuiSlider-rail': {
      opacity: 0.5,
      backgroundColor: '#F1F1F1',
      width: '100%'
    },
    '& .MuiSlider-markLabel': {
      fontFamily: 'Noto Sans',
      fontSize: '12px',
      fontWeight: 400,
      color: '#999999',
      marginLeft: 13,
      [theme.breakpoints.up('sm')]: {
        marginLeft: 0
      }
    },
    '& .MuiSlider-mark': {
      color: '#C9C9B2',
      height: 6,
      marginTop: 7
    }
  }));

  return (
    <SurveySlider
      aria-label="Language Slider"
      step={null}
      valueLabelDisplay="off"
      marks={marks}
      name={slider}
      defaultValue={5}
      onChange={(event, newValue) => handleChange(event, newValue)}
      sx={{
        '& .MuiSlider-markLabel[data-index="4"]': {
          marginLeft: -4,
          [theme.breakpoints.up('sm')]: {
            marginLeft: -7
          }
        },
        '& .MuiSlider-mark[data-index="4"]': {
          left: '94% !important'
        }
      }}
    />
  );
};

export default SurveySlider;
