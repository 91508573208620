/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import { CorrectSentence } from '../components/scenes/CorrectSentence';
import { getCorrectSentenceCards } from '../reducers';
import { mapDispatchToQuizProps } from '../selectors';
import { getLabels } from '../../util';
import { getUserCoins } from '../../profile/selectors';

const CorrectSentenceContainer = (props: Object) => {
  return <CorrectSentence {...props} />;
};

export const mapStateToProps = (state: Object) => {
  const star = getUserCoins(state);

  return {
    star,
    ...getCorrectSentenceCards(state),
    ...getLabels(
      state,
      'correct_txt_title',
      'btn_check',
      'btn_continue',
      'btn_hint'
    )
  };
};

export { CorrectSentenceContainer };
export default connect(
  mapStateToProps,
  mapDispatchToQuizProps
)(CorrectSentenceContainer);
