/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import { SpeakingWithRouter } from '../components/scenes';
import { getLabels, getLabel } from '../../util';
import { getUserCoins } from '../../profile/selectors';
import { getTranscriptionState } from '../../profile/selectors';
import {
  increaseAnsweredWrong,
  nextGameScreen,
  checkSpeakingAnswer,
  speakingGameNoMatch,
  resetSpeakingErrorMsg,
  speakingGameOffline,
  speakingGameDefaultError,
  increaseGameSkipCount
} from '../actions';
import { getCurrentCourseData } from '../../lessons/selectors';
import _ from 'lodash';
import { getVoiceSpeed } from '../../chatbots/selectors';
const SpeakingContainer = (props: Object) => {
  return <SpeakingWithRouter {...props} />;
};

export const mapStateToProps = (state: Object) => {
  const {
    speaking,
    lessons,
    data: { targetLangCode },
    courses: {
      nativeLanguageFeatures: { rtlLanguage: nativeRtlLanguage }
    }
  } = state;
  const {
    phrase,
    isWord,
    isResultShown,
    answeredWrong,
    speakingErrorMsg
  } = speaking;

  const { unitId } = lessons;

  const { features } = getCurrentCourseData(state);
  const { langCodeSpeakingAndroid } = features;

  const { writtenNumbers } = getCurrentCourseData(state);
  const regexNumbers = _.join(
    _.map(writtenNumbers.allIds, i => `[${i}]`),
    '|'
  );

  const coins = getUserCoins(state);
  const voiceSpeed = getVoiceSpeed(state);

  return {
    speakingErrorMsg,
    isResultShown: isResultShown,
    answeredWrong: answeredWrong,
    targetLanguage: targetLangCode,
    lang: features,
    langCodeSpeaking: langCodeSpeakingAndroid,
    rtlLanguage: features.rtlLanguage,
    nativeRtlLanguage,
    voiceSpeed,
    ...phrase,
    isWord,
    unitId,
    transcriptState: getTranscriptionState(state),
    coins,
    writtenNumberRegex: regexNumbers,
    writtenNumber: writtenNumbers.byId,
    speaking_txt_instruction: getLabel(
      state,
      'speaking_txt_instruction',
      'Now, try to speak this word.'
    ),
    writing_txt_skip: getLabel(state, 'writing_txt_skip', 'Skip This'),
    modal_txt_speaking_no_supported_title: getLabel(
      state,
      'modal_txt_speaking_no_supported_title',
      'Sorry'
    ),
    modal_txt_speaking_no_supported_desc: getLabel(
      state,
      'modal_txt_speaking_no_supported_desc',
      'Speaking game is not supported in this browser. Please try again with Google Chrome browser.'
    ),
    writing_txt_skip_description: getLabel(
      state,
      'writing_txt_skip_description',
      "I can't speak now"
    ),
    ...getLabels(state, 'btn_check', 'btn_continue', 'afterBuy_txt_back')
  };
};

export const mapDispatchToProps = (dispatch: Function) => {
  return {
    increaseGameSkipCount: () => dispatch(increaseGameSkipCount()),
    pressNext: () => dispatch(nextGameScreen()),
    noMatch: () => dispatch(speakingGameNoMatch()),
    networkError: () => dispatch(speakingGameOffline()),
    speakingGameDefaultError: () => dispatch(speakingGameDefaultError()),
    onCloseModal: () => dispatch(resetSpeakingErrorMsg()),
    onSkip: async () => {
      await dispatch(increaseAnsweredWrong());
      await dispatch(nextGameScreen());
    },
    checkSpeakingAnswer: (answerNum: number) =>
      dispatch(checkSpeakingAnswer(answerNum))
  };
};
export { SpeakingContainer };
export default connect(mapStateToProps, mapDispatchToProps)(SpeakingContainer);
