import * as TrackerTypes from '../filterConstants';
import Tracker from '../../util/tracker';
import { AnyAction } from 'redux';
import { getFunnelUtm } from '../../onboarding/funnelUtmHelper';

export const trackEvent = () => (dispatch, getState) => {
  const trackerState = getState().tracker;
  dispatch(startTracking(trackerState.eventName));
  Tracker.sendTracking(
    trackerState.eventName,
    trackerState.trackingData,
    trackerState.userProperties
  )
    .then(() =>
      dispatch(
        completeTracking(trackerState.trackingData, trackerState.userProperties)
      )
    )
    .catch(error => dispatch(trackerError(error)));
};

export const startTracking = event => {
  return { type: TrackerTypes.startTracking, event };
};

export const requestTracking = (event, data, userProps = {}): AnyAction => {
  return {
    type: TrackerTypes.requestTracking,
    event,
    data,
    // Note: They want to send the UTMs along with user properties for all events
    userProps: { ...userProps, ...getFunnelUtm() }
  };
};

export const completeTracking = (data, userProps) => {
  return { type: TrackerTypes.completeTracking, data, userProps };
};

export const trackerError = error => {
  return { type: TrackerTypes.trackingError, error };
};
