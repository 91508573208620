/* @flow */
import styled from 'styled-components';
import {
  DARK_GREY,
  BROWN_GREY_TWO,
  WHITE,
  VERY_LIGHT_PINK_TWO,
  MARIGOLD,
  SILVER_COLOR,
  LIGHT_GREY_TRANSCRIPT_2,
  VERY_LIGHT_PINK,
  TAB_TITLE_COLOR
} from '../../../../common/constants';

const Container = styled.div`
  top: 70px;
  position: relative;
  width: 60%;
  margin: auto;
  margin-bottom: 31px;
  margin-top: 18px;
`;
const AchievementContainer = styled.div`
  margin-top: 57px;
`;
const AchievementTableTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${DARK_GREY};
`;
const AchievementTable = styled.div`
  margin-top: 52px;
`;
const AchievementRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: solid 2px ${VERY_LIGHT_PINK_TWO};
  background-color: ${WHITE};
  margin-top: 5px;
  align-items: center;
  padding: 12px 0;
`;
const BedgePic = styled.img`
  width: 90px;
  height: 90px;
  margin-right: 35px;
`;
const TextContainer = styled.div`
  flex: 1;
`;
const AchievementTitle = styled.p`
  font-size: 16px;
  color: ${DARK_GREY};
  margin: 0 !important;
`;
const AchievementCoin = styled.p`
  font-size: 14px;
  color: ${BROWN_GREY_TWO};
  margin: 0 !important;
`;
const AchievementPointContainer = styled.div``;
const AchievementPoint = styled.div`
  border-radius: 15px;
  border: solid 1px ${VERY_LIGHT_PINK_TWO};
  display: flex;
  padding: 2px 5px;
`;
const LeaderBoardContainer = styled.div`
  margin-top: 50px;
  border-top: 1px solid #c8c7cc;
`;
const LeaderBoardTableTitle = styled.p`
  color: ${LIGHT_GREY_TRANSCRIPT_2};
  font-size: 16px;
  font-weight: 600;
  opacity: 0.8;
`;
const LeaderBoardTableTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const SpinnerContainer = styled.div`
  display: ${props => (props.isUpdateMethod ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  height: calc(100vh - 225px);
  width: 431px;
  margin: auto;
`;
const StateScoreContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
  justify-content: space-around;
  margin-top: ${props => (props.isLeaderBoard ? 22 : 50)}px;
`;
const StateContent = styled.div`
  border-radius: 25px;
  border: solid 1px ${props => (props.isLeaderBoard ? SILVER_COLOR : MARIGOLD)};
  background-color: ${WHITE};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin-left: 5px;
  position: relative;
  flex-direction: column;
`;
const ScoreText = styled.p`
  text-align: center;
  color: ${TAB_TITLE_COLOR};
  font-weight: 600;
  font-size: 14px;
  position: absolute;
  &.bananaScore {
    position: unset;
    margin: 0 !important;
  }
`;
const StateImg = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
`;
const StateText = styled.p`
  font-weight: bold;
  margin: 0 !important;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  &.bananaScore {
    position: unset;
    transform: unset;
  }
`;
const StarPic = styled.img`
  height: 24px;
  width: 24px;
`;
const StarPoint = styled.p`
  margin: 0 7px !important;
  font-weight: bold;
  font-size: 16px;
`;
const ViewAllStyle = styled.button`
  height: 18px;
  opacity: 0.5;
  font-size: 12px;
  font-weight: bold;
  border: none;
  color: ${LIGHT_GREY_TRANSCRIPT_2};
`;
const SeeMoreButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const NavContainer = styled.div`
  position: relative;
`;
const Line = styled.div`
  height: 5px;
  width: 100vw;
  background: ${VERY_LIGHT_PINK};
  transform: translateX(-20%);
  top: 26px;
  position: absolute;
  z-index: -1;
`;

export {
  AchievementCoin,
  AchievementContainer,
  AchievementPoint,
  AchievementPointContainer,
  AchievementRow,
  AchievementTable,
  AchievementTableTitle,
  AchievementTitle,
  BedgePic,
  Container,
  LeaderBoardContainer,
  LeaderBoardTableTitle,
  LeaderBoardTableTitleContainer,
  Line,
  NavContainer,
  ScoreText,
  SeeMoreButtonContainer,
  SpinnerContainer,
  StarPic,
  StarPoint,
  StateContent,
  StateImg,
  StateScoreContainer,
  StateText,
  TextContainer,
  ViewAllStyle
};
