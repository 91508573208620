/* @flow */
import { getLabels, getLabel } from '../../util';
import _ from 'lodash';
import { LONGEST_STREAK } from '../constants';
import {
  getAttributes,
  getCourseUser,
  getUserProgress,
  getUserCoins,
  getSpender,
  getUserBananas,
  getLongestStreak
} from './UserSelector';
import { getCurrentCourseData } from '../../lessons/selectors/DataSelectors';

export const getAchievement = (state: Object) => {
  const { longestStreak } = getAttributes(state, LONGEST_STREAK);
  return {
    ...getLabels(
      state,
      'profile_txt_todayAchieve',
      'achievement_txt_description'
    ),
    achievement_txt_diligentMaster: getDiligentMasterLabel(state),
    achievement_txt_overAchiever: getOverAchieveLabel(state),
    achievement_txt_champion: getChampionLabel(state),
    achievement_txt_wealthy: getWealthyLabel(state),
    achievement_txt_bigSpender: getBigSpenderLabel(state),
    longestStreak
  };
};
const getDiligentMasterLabel = (state: Object) =>
  getLabel(state, 'achievement_txt_diligentMaster', 'Diligent Master');
const getOverAchieveLabel = (state: Object) =>
  getLabel(state, 'achievement_txt_overAchiever', 'Over Achiever');
const getChampionLabel = (state: Object) =>
  getLabel(state, 'achievement_txt_champion', 'Champion');
const getWealthyLabel = (state: Object) =>
  getLabel(state, 'achievement_txt_wealthy', 'Wealthy');
const getBigSpenderLabel = (state: Object) =>
  getLabel(state, 'achievement_txt_bigSpender', 'Big Spender');

export const getChampionLevelOne = (state: Object) => {
  const progress = getCourseUser(state).progress;
  const lessonOneCrowns = _.find(progress, unit => {
    const lessons = _.get(unit, 'lessons', 0);
    const match = _.reduce(
      lessons,
      (check, lesson) => {
        const crowns = _.get(lesson, 'crowns', 0);
        if (crowns === 3) {
          return check + 1;
        }
        return check;
      },
      0
    );
    return match >= 1;
  });
  return Boolean(lessonOneCrowns);
};

export const getChampionLevelTwo = (state: Object) => {
  const progress = getCourseUser(state).progress;
  const { features } = getCurrentCourseData(state);
  const { hasSpeaking, hasWriting } = features;
  let numberLesson = 5;
  if (hasSpeaking) {
    numberLesson++;
  }
  if (hasWriting) {
    numberLesson++;
  }
  const allLessonsPass = _.find(progress, unit => {
    const lessons = _.get(unit, 'lessons', null);
    const match = _.reduce(
      lessons,
      (check, lesson) => {
        const crowns = _.get(lesson, 'crowns', null);
        if (crowns === 3) {
          return check + 1;
        }
        return check;
      },
      0
    );
    return match === numberLesson;
  });
  return Boolean(allLessonsPass);
};

export const getChampionLevelThree = (state: Object) => {
  const progress = getCourseUser(state).progress;
  const checkCrowns = _.filter(progress, { crowns: 3 });
  const levelPass = checkCrowns.length >= 5;
  return levelPass;
};
export const getLevelChampion = (state: Object) => {
  const progress = getUserProgress(state);
  const levelOne = getChampionLevelOne(state);
  const levelTwo = getChampionLevelTwo(state);
  const levelThree = getChampionLevelThree(state);
  if (progress) {
    if (levelThree) {
      return 3;
    } else if (levelTwo) {
      return 2;
    } else if (levelOne) {
      return 1;
    }
  }
  return 0;
};

export const getBadge = (state: Object) => {
  const progress = getUserProgress(state) || [];
  if (progress.length > 0) {
    const amountLessonDone = isLessonDone(progress);
    const AMOUT_UNIT_FOR_EACH_BADGE = 10;
    return Math.floor(amountLessonDone / AMOUT_UNIT_FOR_EACH_BADGE);
  }
  return 0;
};

export const getListFinishedUnit = (progress: any) => {
  const result = [];
  _.forEach(progress, (element, key) => {
    isHasCrown(element) && result.push(key);
  });
  return result;
};

export const isLessonDone = (progress: Object[]) => {
  const result = progress.filter(isHasCrown);
  return result.length;
};

const isHasCrown = element => {
  return element && element.crowns >= 1;
};

export const getWealthy = (state: Object) => {
  const coins = getUserCoins(state);
  const starLevel = [20, 100, 500];
  if (coins) {
    if (coins >= starLevel[0] && coins < starLevel[1]) {
      return 1;
    } else if (coins >= starLevel[1] && coins < starLevel[2]) {
      return 2;
    } else if (coins >= starLevel[2]) {
      return 3;
    }
  }
  return 0;
};

export const getOverAchiever = (state: Object) => {
  const bananas = getUserBananas(state);
  const bananaLevel = [500, 5000, 10000];
  if (bananas) {
    if (bananas >= bananaLevel[0] && bananas < bananaLevel[1]) {
      return 1;
    } else if (bananas >= bananaLevel[1] && bananas < bananaLevel[2]) {
      return 2;
    } else if (bananas >= bananaLevel[2]) {
      return 3;
    }
  }
  return 0;
};

export const getDiligentMaster = (state: Object) => {
  const days = getLongestStreak(state);
  const dayLevel = [1, 2, 3];
  if (days === dayLevel[2]) {
    return 3;
  } else if (days === dayLevel[1]) {
    return 2;
  } else if (days === dayLevel[0]) {
    return 1;
  }
  return 0;
};

export const getBigSpender = (state: Object) => {
  const coinSpends = getSpender(state);

  const coinLevel = [10, 20, 50];
  if (coinSpends) {
    if (coinSpends >= coinLevel[0] && coinSpends < coinLevel[1]) {
      return 1;
    } else if (coinSpends >= coinLevel[1] && coinSpends < coinLevel[2]) {
      return 2;
    } else if (coinSpends >= coinLevel[2]) {
      return 3;
    }
  }
  return 0;
};

export const getTodayAchievement = (state: Object) => {
  const spender = getBigSpender(state);
  const wealthy = getWealthy(state);
  const champion = getLevelChampion(state);
  const overAchieve = getOverAchiever(state);
  const diligent = getDiligentMaster(state);
  const arrayAchieve = [spender, wealthy, champion, overAchieve, diligent];
  console.log('arrayAchieve', arrayAchieve);
  let max = 0;
  let index = 0;

  arrayAchieve.forEach((num, i) => {
    if (num >= max) {
      max = num;
      index = i;
    }
  });

  const arrayString = [
    'spender',
    'wealthy',
    'champion',
    'overAchieve',
    'diligent'
  ];
  return arrayString[index];
};
export const getDescription = (state: Object, status: string, score: number) =>
  getLabel(state, `achievement_description_${status}_${score}`);
export const getDescriptionList = (state: Object) => {
  const spender = getBigSpender(state);
  const wealthy = getWealthy(state);
  const champion = getLevelChampion(state);
  const overAchieve = getOverAchiever(state);
  const diligent = getDiligentMaster(state);
  return {
    spenderDescription: getDescription(state, 'spender', spender),
    wealthyDescription: getDescription(state, 'wealthy', wealthy),
    championDescription: getDescription(state, 'champion', champion),
    overAchieveDescription: getDescription(state, 'overAchieve', overAchieve),
    diligentDescription: getDescription(state, 'diligent', diligent)
  };
};
export const getTodayProfile = (state: Object) => {
  const statusToday = getTodayAchievement(state);
  const spender = getBigSpender(state);
  const wealthy = getWealthy(state);
  const champion = getLevelChampion(state);
  const overAchieve = getOverAchiever(state);
  const diligent = getDiligentMaster(state);

  if (statusToday === 'spender') {
    return {
      todayLabel: getBigSpenderLabel(state),
      score: spender,
      description: getDescription(state, 'spender', spender)
    };
  } else if (statusToday === 'wealthy') {
    return {
      todayLabel: getWealthyLabel(state),
      score: wealthy,
      description: getDescription(state, 'wealthy', wealthy)
    };
  } else if (statusToday === 'champion') {
    return {
      todayLabel: getChampionLabel(state),
      score: champion,
      description: getDescription(state, 'champion', champion)
    };
  } else if (statusToday === 'overAchieve') {
    return {
      todayLabel: getOverAchieveLabel(state),
      score: overAchieve,
      description: getDescription(state, 'overAchieve', overAchieve)
    };
  } else if (statusToday === 'diligent') {
    return {
      todayLabel: getDiligentMasterLabel(state),
      score: diligent,
      description: getDescription(state, 'diligent', diligent)
    };
  }
};
