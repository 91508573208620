/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import {
  PREFIX_SHOP,
  SEA_BLUE,
  LIGHT_WHITE,
  WHITE,
  DARK_BLUE,
  PRO_TXT
} from '../../common/constants';

const SubscritionAdsContainer = styled.div`
  height: 153px;
  border-radius: 10px;
  background-color: ${SEA_BLUE};
  display: flex;
  position: relative;
`;
const LeftContent = styled.div`
  flex: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 212px;
  min-width: 212px;
  margin-bottom: 33px;
  border-radius: 10px;
`;
const LeftImg = styled.img`
  width: 100%;
`;
const LeftText = styled.p`
  position: absolute;
  top: 70px;
  font-size: 14px;
  font-weight: bold;
  color: ${WHITE};
  border-radius: 5px;
  background-color: ${DARK_BLUE};
  padding: 3px 13px;
`;
const RightContent = styled.div`
  flex: 0.7;
  padding-left: 35px;
  padding-right: 50px;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;
const RightText = styled.p`
  font-size: 16px;
  color: ${LIGHT_WHITE};
  margin-bottom: 10px !important;
  margin-top: 0px !important;
`;
const RightButton = styled.button`
  border-radius: 10px;
  background: ${WHITE};
  border: 0;
  box-shadow: none;
  padding: 7px 33px;
  &:hover {
    opacity: 0.6;
  }
`;

const ButtonText = styled.p`
  color: ${SEA_BLUE};
  font-size: 16px;
  font-weight: bold;
  margin: 0 !important;
`;

const CloseButton = styled.button`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  padding: 0;
  border: 0;
  position: absolute;
  right: 12px;
  top: 12px;
`;

type Props = {
  toggleBanner: Function,
  onBuy: Function,
  purchase_txt_benefit_details_01: string,
  shop_txt_7_day_trial: string,
  isProUser: boolean
};
const blueLingImg = `${PREFIX_SHOP}pro-ling-image-350-px.png`;
const closeWhite = `${PREFIX_SHOP}basecolor-white.png`;
const ProfileSubScritionAds = ({
  isProUser,
  toggleBanner,
  shop_txt_7_day_trial,
  onBuy,
  purchase_txt_benefit_details_01
}: Props) => {
  return (
    <SubscritionAdsContainer>
      {!isProUser ? (
        <CloseButton onClick={toggleBanner}>
          <img src={closeWhite} alt={closeWhite} />
        </CloseButton>
      ) : null}

      <LeftContent>
        <LeftImg src={blueLingImg} />
        <LeftText>{PRO_TXT}</LeftText>
      </LeftContent>
      <RightContent>
        <RightText>{purchase_txt_benefit_details_01}</RightText>
        <RightButton onClick={onBuy}>
          <ButtonText>{shop_txt_7_day_trial}</ButtonText>
        </RightButton>
      </RightContent>
    </SubscritionAdsContainer>
  );
};
export { ProfileSubScritionAds };
