import { Button, Typography } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

const StartButton = ({ buttonStyle, text, buttonAction }) => {
  const theme = useTheme();
  const styles = {
    button: {
      backgroundColor: theme.palette.newPrimary.main,
      height: '52px',
      borderRadius: 9,
      textTransform: 'none',
      p: 0,
      '&:hover': {
        backgroundColor: theme.palette.newPrimary.deactivate
      },
      '&:focus': {
        outline: 'none'
      }
    },
    buttonText: {
      fontSize: '18px',
      fontWeight: 700,
      color: '#FFFFFF'
    }
  };
  return (
    <Button onClick={buttonAction} sx={[styles.button, buttonStyle]}>
      <Typography variant="subtext" sx={styles.buttonText}>
        {text}
      </Typography>
    </Button>
  );
};

export default StartButton;

StartButton.propTypes = {
  buttonStyle: PropTypes.object,
  text: PropTypes.string,
  initiateBuy: PropTypes.func
};
