/* @flow */
import { spawn } from 'redux-saga/effects';
import { watchUserChanges } from '../profile/sagas';
import BugTracker from './../util/BugTracker';
import { watchPaymentChanges } from '../profile/sagas/AccountSagas';

export function* loginAsGuest({ authUser }: Object): Object {
  try {
    yield spawn(watchUserChanges, authUser);
    yield spawn(watchPaymentChanges, authUser);
  } catch (err) {
    BugTracker.notify(err);
    console.log('Anonymous user signin error', err);
  }
}
