/* @flow */

import { PREFIX_PROFILE } from '../common/constants';

const defaultProfilePics: Array<Object> = [
  {
    id: 1,
    icon: `${PREFIX_PROFILE}avatar01.png`
  },
  {
    id: 2,
    icon: `${PREFIX_PROFILE}avatar02.png`
  },
  {
    id: 3,
    icon: `${PREFIX_PROFILE}avatar03.png`
  },
  {
    id: 4,
    icon: `${PREFIX_PROFILE}avatar04.png`
  },
  {
    id: 5,
    icon: `${PREFIX_PROFILE}avatar05.png`
  },
  {
    id: 6,
    icon: `${PREFIX_PROFILE}avatar06.png`
  }
];

export const getProfileImage = (lingProfile: string, photoURL: ?string) => {
  return Number(photoURL) < 7 && photoURL
    ? defaultProfilePics[Number(photoURL) - 1].icon
    : photoURL
    ? photoURL
    : lingProfile;
};
