/* @flow */
import { getBrowserLang, setLocalStorage } from './';
import _ from 'lodash';
import { SHOW_TUTORIAL, TRUE } from '../common/constants';
import { getStripePromoCodeId } from './StripeProductsHandlers';
import { TUTORIAL_KEY } from '../games/constants';

export const onPressUnitItem = (
  history: Object,
  unitId: number,
  isCrackedUnit: boolean,
  fetchUnitData: Function,
  nativeLangCode: string,
  targetLangCode: string,
  isUnitLocked: Function,
  disableRepairedUnitTooltips: Function
) => {
  isCrackedUnit && disableRepairedUnitTooltips();
  if (!isUnitLocked(unitId)) {
    goToUnit(history, unitId, fetchUnitData, nativeLangCode, targetLangCode);
  } else {
    history.push('/shop');
  }
};

export const goToUnit = (
  history: Object,
  unitId: number,
  fetchUnitData: Function,
  nativeLangCode: string,
  targetLangCode: string
) => {
  fetchUnitData(targetLangCode, nativeLangCode, unitId, () => {
    history.push(`/unit/${unitId}`);
  });
};

export const getData = async (
  history: Object,
  triggerLoadLanguageData: Function,
  courses: Object,
  units: Object,
  triggerUpdateUnitsProgress: Function,
  downloading: Object,
  lastSelectedUnit: number,
  setNativeLanguage: Function,
  setTargetLanguage: Function,
  setLanguageData: Function,
  setBusiness: Function,
  location: Object,
  triggerLoadInitialTutorial: Function,
  setReferrerCode: Function,
  getCustomRef: Function,
  scrollToUnit: Function
) => {
  const { search } = location;
  // eslint-disable-next-line
  const url = new URL(window.location.href);
  const lastUnitId = getLastUnitId(courses);
  if (search) {
    const queryNative = search.match(/native=([^&]*)/);
    const queryTarget = search.match(/target=([^&]*)/);
    const queryDiscount = search.match(/discount=([^&]*)/);
    const queryReferrer = search.match(/referrer=([^&]*)/);
    const queryBusiness = search.match(/business=([^&]*)/);
    const queryReferralCode = search.match(/referralCode=([^&]*)/);
    const queryReferralHashCode = search.match(/referralHashCode=([^&]*)/);
    const promoCode = url.searchParams.get('promoCode') || '';
    const isWebFunnel = url.searchParams.get('utm_source') === 'LingAppWebsite';

    if (queryReferralCode) {
      // eslint-disable-next-line
      localStorage.setItem('referralCode', queryReferralCode[1]);
    }
    if (queryReferralHashCode) {
      // eslint-disable-next-line
      localStorage.setItem('referralHashCode', queryReferralHashCode[1]);
    }
    if (queryBusiness) {
      const isBusiness = queryBusiness[1];
      setBusiness(isBusiness === 'true');
      setBusiness(true);
      history.push('/');
    } else {
      setBusiness(false);
    }
    if (queryReferrer) {
      const referrer = queryReferrer[1];
      setReferrerCode(referrer);
    }
    if (queryDiscount) {
      const discount = queryDiscount[1];
      setLocalStorage('discount', discount);
    }
    if (promoCode) {
      const { osi } = await getStripePromoCodeId(promoCode);
      setReferrerCode(osi);
    }
    if (queryNative && queryTarget) {
      const native = queryNative[1];
      const target = queryTarget[1];

      setNativeLanguage(native);
      setTargetLanguage(target);
      setLanguageData(target, native);
      // eslint-disable-next-line
      if (localStorage.getItem(SHOW_TUTORIAL) === TRUE) {
        triggerLoadInitialTutorial();
      }
      // eslint-disable-next-line
      sessionStorage.setItem('currentSession', 'old');
      if (!isWebFunnel) {
        history.push('/');
      }
    }
  } else {
    // eslint-disable-next-line
    let data = sessionStorage.getItem('currentSession');
    if (data === 'new') {
      // eslint-disable-next-line
      sessionStorage.setItem('currentSession', 'old');
    } else if (data === null) {
      // eslint-disable-next-line
      sessionStorage.setItem('currentSession', 'new');
    }

    if (
      window.location.host === 'play.ling-app.com' &&
      // eslint-disable-next-line
      sessionStorage.getItem('currentSession') === 'new' &&
      (window.location.pathname === '/' || window.location.pathname === '/home')
    ) {
      let browserNativeLang = getBrowserLang();

      setNativeLanguage(browserNativeLang);
      setTargetLanguage('th');
      setLanguageData('th', browserNativeLang);
      // eslint-disable-next-line
      sessionStorage.setItem('currentSession', 'old');
    }
  }
  triggerLoadLanguageData(courses.target, courses.native, lastUnitId);
  if (!_.isEmpty(units)) {
    triggerUpdateUnitsProgress();
  }
  if (getCustomRef(`unitRef${lastSelectedUnit}`) || !downloading) {
    scrollToUnit(lastSelectedUnit);
  }
};

export const openWordsLearn = (
  isCompleteTwoUnits: boolean,
  isFinishTwoUnit: boolean,
  history: Object,
  nativeLangCode: string,
  targetLangCode: string,
  fetchRandomTestData: Function,
  stateChangeHandler: Function
) => {
  //TODO if user finished two unit
  if (isCompleteTwoUnits) {
    fetchRandomTestData(targetLangCode, nativeLangCode, () => {
      history.push('/randomTest');
    });
  } else {
    stateChangeHandler('isFinishTwoUnit', !isFinishTwoUnit);
  }
};

export const rearrangeBussinessUnit = (
  level: number,
  isBusiness: boolean,
  unitsInLevel: Array<Object>,
  units: Object,
  rearrangeBusinessinUnit: Function,
  removeBusinessinUnit: Function
) =>
  level === 0 && isBusiness
    ? rearrangeBusinessinUnit(unitsInLevel, units)
    : level === 4 && isBusiness
    ? removeBusinessinUnit(unitsInLevel, units)
    : null;

const getLastUnitId = courses => {
  const coursesData = _.get(courses, courses.activeLangCode, {});
  return coursesData ? _.last(coursesData.unitsCached) : TUTORIAL_KEY;
};
