/* @flow */
import React, { Fragment } from 'react';
import { Tween } from 'react-gsap';
import { GREY, GREEN200, COLON_SIGN } from '../../../common/constants';
import _ from 'lodash';
import { WRITING_MODE } from '../../../games/constants';

type Props = {
  style: Object,
  guideCoordinates?: string[],
  isShowGuide?: boolean,
  noAnimation: boolean,
  strokeColor?: string,
  strokeDashoffset: number,
  onAnimationFinished?: Function,
  d: string,
  paths: Array<string>,
  guideStep: number,
  gamePlay: number,
  isFailed: boolean
};

type State = {
  isShowGuide: boolean
};

class Character extends React.Component<Props, State> {
  _x: number;
  _y: number;
  constructor(props: Props) {
    super(props);
    this.state = {
      isShowGuide: false
    };
  }
  pathObjs(pathArray: Array<string>, strokeColor?: string) {
    return _.map(pathArray, (path, key) => {
      return (
        <path
          className={key}
          transform={`translate(${this._x}, ${this._y})  scale(2.5)`}
          fill="none"
          stroke={strokeColor}
          strokeWidth={7}
          d={path}
        />
      );
    });
  }
  pathGuide(pathArray: Array<string>, strokeColor?: string, guideStep: number) {
    const { gamePlay, isFailed } = this.props;
    const currentStep = guideStep - 1;
    return _.map(_.slice(pathArray, 0, guideStep), (path, key) => {
      if (key === currentStep && (gamePlay !== WRITING_MODE || isFailed)) {
        return (
          <path
            markerEnd="url(#head)"
            className={key}
            transform={`translate(${this._x}, ${this._y})  scale(2.5)`}
            fill="none"
            stroke="#E75439"
            strokeWidth="2"
            strokeLinejoin="bevel"
            d={path}
          />
        );
      } else if (key < currentStep) {
        return (
          <path
            className={key}
            transform={`translate(${this._x}, ${this._y})  scale(2.5)`}
            fill="none"
            stroke={GREEN200}
            strokeWidth={7}
            d={path}
          />
        );
      } else {
        return (
          <path
            className={key}
            transform={`translate(${this._x}, ${this._y})  scale(2.5)`}
            fill="none"
            stroke={null}
            strokeWidth={7}
            d={path}
          />
        );
      }
    });
  }

  setLetterPosition() {
    const shadownLetter = document.getElementById('shadownLetter');
    if (shadownLetter) {
      // $flow-disable-line
      const width = shadownLetter.getBBox().width * 2.5;
      // $flow-disable-line
      const height = shadownLetter.getBBox().height * 2.5;
      // $flow-disable-line
      const x = shadownLetter.getBBox().x;
      // $flow-disable-line
      const y = shadownLetter.getBBox().y;
      this._x = (498 - width) / 2 - x * 2.5;
      this._y = (325 - height) / 2 - y * 2.5;

      this.setState({
        isShowGuide: !this.state.isShowGuide
      });
    }
  }

  componentDidMount() {
    this.setLetterPosition();
  }

  render() {
    const {
      onAnimationFinished,
      style,
      isShowGuide = false,
      strokeColor,
      d,
      paths,
      guideStep
    } = this.props;
    return (
      <Fragment>
        {strokeColor === GREY || strokeColor === null ? (
          <svg style={style}>
            <path
              id={'shadownLetter'}
              key={d}
              transform={`translate(${this._x}, ${this._y})  scale(2.5)`}
              fill="none"
              stroke={strokeColor}
              strokeWidth="7"
              d={d}
            />
            {COLON_SIGN}
          </svg>
        ) : (
          <Fragment>
            {!isShowGuide ? (
              <Tween
                key={d}
                wrapper={<svg style={style} />}
                staggerFrom={{ svgDraw: 0 }}
                staggerTo={{ svgDraw: 1 }}
                stagger={2 / paths.length}
                delay={1}
                duration={2 / paths.length}
                onCompleteAll={function() {
                  setTimeout(() => {
                    // $flow-disable-line
                    onAnimationFinished();
                  }, 1000);
                }}
              >
                {this.pathObjs(paths, strokeColor)}
              </Tween>
            ) : (
              <Tween wrapper={<svg style={style} />}>
                <defs>
                  <marker
                    id="head"
                    orient="auto"
                    markerWidth="2"
                    markerHeight="4"
                    refX="0.1"
                    refY="2"
                  >
                    <path d="M0,0 V4 L2,2 Z" fill="red" />
                  </marker>
                </defs>

                {this.pathGuide(paths, strokeColor, guideStep)}
              </Tween>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export { Character };
