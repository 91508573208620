import React, { useState } from 'react';
import TimeSlider from './subComponents/TimeSlider';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import OnboardingButton from './subComponents/OnboardingButton';

const TimeSelector = ({
  headerData,
  setSelected,
  handleScreenChange,
  labels
}) => {
  const { txt_tell_us_about_yourself, btn_continue } = labels;
  const theme = useTheme();
  const [time, setTime] = useState([9, 20]);
  const valueText = value => `${value}${value > 11 ? ':00 pm' : ':00 am'}`;

  const styles = {
    main: {
      pb: 8,
      minWidth: 375,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%'
    },
    survey: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: 80,
      width: '100%',
      mt: 6
    },
    topContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      textAlign: 'center',
      my: 14
    },
    bottomContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      width: '50%',
      minWidth: '345px',
      maxWidth: '558px',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        width: '100%'
      }
    },
    questionText: {
      mr: 4,
      maxWidth: 550
    },
    headerText: {
      fontWeight: '700',
      fontSize: '16px',
      color: '#111111',
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px'
      }
    },
    text: {
      textAlign: 'left',
      textWrap: 'wrap',
      fontSize: 30,
      [theme.breakpoints.up('sm')]: {
        fontSize: 36
      }
    },
    headerIcon: { height: '65px', mb: 4 },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '85%',
      [theme.breakpoints.up('sm')]: {
        width: '100%'
      }
    },
    onboardingButton: {
      width: '100%',
      maxWidth: '520px',
      backgroundColor: theme.palette.newPrimary.main,
      borderRadius: 9,
      textTransform: 'none',
      color: '#FFFFFF',
      fontSize: '18px',
      fontWeight: 700,
      my: 8,
      [theme.breakpoints.up('sm')]: {
        mt: 14
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.newPrimary.main,
        opacity: 0.5,
        color: '#FFFFFF'
      }
    }
  };

  return (
    <Box sx={styles.main}>
      <Box sx={styles.topContainer}>
        <Box component="img" src={headerData.icon} sx={styles.headerIcon} />
        <Typography variant="subtext" sx={styles.headerText}>
          {txt_tell_us_about_yourself}
        </Typography>
      </Box>
      <Box sx={styles.bottomContainer}>
        <Box sx={styles.questionText}>
          <Typography variant="text" sx={styles.text}>
            {headerData.text}
          </Typography>
        </Box>
        <Box sx={styles.survey}>
          <TimeSlider
            setSelected={setSelected}
            time={time}
            setTime={setTime}
            valueText={valueText}
          />
        </Box>
      </Box>
      <Box sx={styles.buttonContainer}>
        <OnboardingButton
          disabled={false}
          size="large"
          clickAction={() => {
            handleScreenChange([
              `${valueText(time[0])} - ${valueText(time[1])}`
            ]);
          }}
          buttonText={btn_continue}
          style={styles.onboardingButton}
        />
      </Box>
    </Box>
  );
};

export default TimeSelector;
