/* @flow */
import styled from 'styled-components';

const SettingContainer = styled.div`
  margin: auto;
  margin-top: 96px;
  margin-bottom: 50px;
  width: 784px !important;
  display: flex;
  flex-direction: ${props => (props.isUpdateMethod ? 'column' : 'row')};
`;

const BackArea = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 40px;
  display: ${props =>
    props.isUpdateMethod && !props.isUpdatingCard ? 'inline-flex' : 'none'};
  &:hover {
    cursor: pointer;
  }
`;
const Image = styled.img`
  width: 18px;
  height: 32px;
  margin-right: 24px;
`;

const Title = styled.p`
  font-size: 21px;
  font-weight: bold;
  color: #454545;
  margin: 0 !important;
`;
export { Title, Image, BackArea, SettingContainer };
