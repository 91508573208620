/* @flow */
import {
  resetUser,
  toggleSignUpForm,
  validate,
  validateResetPaswordForm,
  handleSignupWithEmail
} from './';
import { SIGNIN_METHOD, PROVIDER_EMAIL } from '../profile/constants';
import { getDatabaseUser } from '../common/actions';

export const toggle = (
  isLoginModalOpen: boolean,
  toggleLoginModal: Function,
  isBusinessSignUp: boolean,
  setIsBusinessSignUp: Function,
  pauseDialog: Function,
  modal: boolean,
  stateChangeHandler: Function
) => {
  if (isLoginModalOpen) {
    toggleLoginModal();
  } else {
    pauseDialog();
    stateChangeHandler('modal', !modal);
    stateChangeHandler('isLoading', false);
    stateChangeHandler('errorMsgFirebase', '');
    stateChangeHandler('isResetPassword', false);
    stateChangeHandler('isSignUp', true);
    stateChangeHandler('unitId', 0);
  }
  isBusinessSignUp && setIsBusinessSignUp();
  resetUser(stateChangeHandler);
};

export const toggleLogin = (
  isLoginModalOpen: boolean,
  toggleLoginModal: Function,
  isBusinessSignUp: boolean,
  setIsBusinessSignUp: Function,
  pauseDialog: Function,
  modal: boolean,
  isSignUp: boolean,
  stateChangeHandler: Function
) => {
  toggle(
    isLoginModalOpen,
    toggleLoginModal,
    isBusinessSignUp,
    setIsBusinessSignUp,
    pauseDialog,
    modal,
    stateChangeHandler
  );
  toggleSignUpForm('isSignUp', isSignUp, stateChangeHandler);
};

export const onLogOut = async (
  stateChangeHandler: Function,
  toggleLogOut: Function,
  signOut: Function,
  signInAnonymously: Function
) => {
  stateChangeHandler('isLoading', true);
  stateChangeHandler('errorMsgFirebase', '');
  const signMsg = await signOut();

  if (signMsg[0]) {
    const signAnonymousMsg = await signInAnonymously();
    if (signAnonymousMsg[0]) {
      stateChangeHandler('isLoading', false);

      toggleLogOut();
    }
  } else {
    stateChangeHandler('errorMsgFirebase', signMsg[1].message);
  }
};

export const onToggleResetPassword = (
  isResetPassword: boolean,
  stateChangeHandler: Function,
  modalStateChangeHandler: Function
) => {
  onResetErrorMessage(modalStateChangeHandler);
  resetUser(stateChangeHandler);
  document.forms[('loginForm': any)].reset();
  toggleResetPassword(isResetPassword, stateChangeHandler);
};

export const onResetErrorMessage = (modalStateChangeHandler: Function) => {
  modalStateChangeHandler('err', false);
  modalStateChangeHandler('emailErrorMsg', '');
  modalStateChangeHandler('passwordErrorMsg', '');
  modalStateChangeHandler('formErrorMsg', '');
};

export const toggleResetPassword = (
  isResetPassword: boolean,
  stateChangeHandler: Function
) => {
  stateChangeHandler('isResetPassword', !isResetPassword);
  stateChangeHandler('errorMsgFirebase', '');
};

export const resetErrorMsg = (stateChangeHandler: Function) => {
  stateChangeHandler('errorMsgFirebase', '');
  stateChangeHandler('successMsg', '');
};

export const onResetErrorMsg = (
  modalStateChangeHandler: Function,
  stateChangeHandler?: Function
) => {
  onResetErrorMessage(modalStateChangeHandler);
  stateChangeHandler && resetErrorMsg(stateChangeHandler);
};

export const handleLoginSubmit = async (
  event: SyntheticEvent<HTMLButtonElement>,
  email_error_matchPasswords: string,
  email_error_fieldsMissing: string,
  email_error_longerPassword: string,
  email_error_wrongFormat: string,
  err: boolean,
  userForm: Object,
  login: Function,
  modalStateChangeHandler: Function,
  stateChangeHandler?: Function
) => {
  event.preventDefault();
  onResetErrorMsg(modalStateChangeHandler, stateChangeHandler);
  const isLoginForm = true;
  await modalStateChangeHandler(
    'err',
    validate(
      isLoginForm,
      email_error_matchPasswords,
      email_error_fieldsMissing,
      email_error_longerPassword,
      email_error_wrongFormat,
      userForm,
      modalStateChangeHandler
    )
  );

  if (!err) {
    // eslint-disable-next-line
    localStorage.setItem(SIGNIN_METHOD, PROVIDER_EMAIL);
    login();
  }
};

export const handleSignUpSubmit = async (
  event: SyntheticEvent<HTMLButtonElement>,
  email_error_matchPasswords: string,
  email_error_fieldsMissing: string,
  email_error_longerPassword: string,
  email_error_wrongFormat: string,
  err: boolean,
  userForm: Object,
  signUpWithEmail: Function,
  modalStateChangeHandler: Function,
  stateChangeHandler?: Function,
  handleValidated?: Function = () => {}
) => {
  event.preventDefault();
  onResetErrorMsg(modalStateChangeHandler, stateChangeHandler);
  await modalStateChangeHandler(
    'err',
    validate(
      false,
      email_error_matchPasswords,
      email_error_fieldsMissing,
      email_error_longerPassword,
      email_error_wrongFormat,
      userForm,
      modalStateChangeHandler
    )
  );
  if (!err) {
    // eslint-disable-next-line
    localStorage.setItem(SIGNIN_METHOD, PROVIDER_EMAIL);
    signUpWithEmail(
      userForm.name,
      userForm.email,
      userForm.password,
      userForm.isNewsletterEnable
    ).then(() => handleValidated());
  }
};

export const onToggleSignUp = (
  isSignUp: boolean,
  isResetPassword: boolean,
  stateChangeHandler: Function,
  modalStateChangeHandler: Function
) => {
  onResetErrorMessage(modalStateChangeHandler);
  resetUser(stateChangeHandler);
  toggleSignUp(stateChangeHandler, isSignUp, isResetPassword);
};

export const toggleSignUp = (
  stateChangeHandler: Function,
  isSignUp: boolean,
  isResetPassword: boolean
) => {
  isResetPassword && stateChangeHandler('isResetPassword', !isResetPassword);
  !isResetPassword && stateChangeHandler('isSignUp', !isSignUp);
  stateChangeHandler('errorMsgFirebase', '');
};

export const handleResetPasswordSubmit = async (
  event: SyntheticEvent<HTMLButtonElement>,
  email_error_fieldsMissing: string,
  email_error_wrongFormat: string,
  userForm: Object,
  err: boolean,
  sendPasswordResetEmail: Function,
  modalStateChangeHandler: Function,
  stateChangeHandler: Function
) => {
  event.preventDefault();
  onResetErrorMsg(modalStateChangeHandler, stateChangeHandler);
  await modalStateChangeHandler(
    err,
    validateResetPaswordForm(
      email_error_fieldsMissing,
      email_error_wrongFormat,
      userForm,
      modalStateChangeHandler
    )
  );

  if (!err) {
    resetPassword(userForm, sendPasswordResetEmail, stateChangeHandler);
  }
};

export const resetPassword = async (
  userForm: Object,
  sendPasswordResetEmail: Function,
  stateChangeHandler: Function
) => {
  stateChangeHandler('isLoading', true);
  stateChangeHandler('errorMsgFirebase', '');
  const signMsg = await sendPasswordResetEmail(userForm.email);

  if (signMsg[0]) {
    stateChangeHandler('isEmailSent', true);
  } else {
    stateChangeHandler('errorMsgFirebase', signMsg[1].message);
  }
  stateChangeHandler('isLoading', false);
};

export const logOutClaim = async (
  signOut: Function,
  signInAnonymously: Function
) => {
  const signMsg = await signOut();
  if (signMsg[0]) {
    await signInAnonymously();
  }
};

export const onCloseEmailSent = (stateChangeHandler: Function) => {
  stateChangeHandler('isEmailSent', false);
  stateChangeHandler('isResetPassword', false);
};

export const handleSignUpSubmitClaim = async (
  event: SyntheticEvent<HTMLButtonElement>,
  email_error_matchPasswords: string,
  email_error_fieldsMissing: string,
  email_error_longerPassword: string,
  email_error_wrongFormat: string,
  email_error_alreadyUsed: string,
  email_error_userNotFound: string,
  email_error_wrongPassword: string,
  err: boolean,
  history: Object,
  user: Object,
  userForm: Object,
  isProUser: boolean,
  receipt: string,
  productName: string,
  isDev: boolean,
  currency: string,
  functions: string,
  updateUserData: Function,
  itemInfo: any[],
  redeemId: string,
  signUpWithEmail: Function,
  claimStateChangeHandler: Function
) => {
  event.preventDefault();
  onResetErrorMsg(claimStateChangeHandler);
  const isLoginForm = false;
  await claimStateChangeHandler(
    'err',
    validate(
      isLoginForm,
      email_error_matchPasswords,
      email_error_fieldsMissing,
      email_error_longerPassword,
      email_error_wrongFormat,
      userForm,
      claimStateChangeHandler
    )
  );
  if (!err) {
    // eslint-disable-next-line
    localStorage.setItem(SIGNIN_METHOD, PROVIDER_EMAIL);
    signUpWithEmailClaim(
      history,
      email_error_alreadyUsed,
      email_error_userNotFound,
      email_error_wrongPassword,
      signUpWithEmail,
      user,
      userForm,
      isProUser,
      receipt,
      productName,
      isDev,
      currency,
      functions,
      updateUserData,
      itemInfo,
      redeemId,
      claimStateChangeHandler
    );
  }
};

export const handleSignInSubmitClaim = async (
  event: SyntheticEvent<HTMLButtonElement>,
  email_error_matchPasswords: string,
  email_error_fieldsMissing: string,
  email_error_longerPassword: string,
  email_error_wrongFormat: string,
  email_error_alreadyUsed: string,
  email_error_userNotFound: string,
  email_error_wrongPassword: string,
  err: boolean,
  history: Object,
  user: Object,
  userForm: Object,
  isProUser: boolean,
  receipt: string,
  productName: string,
  isDev: boolean,
  currency: string,
  functions: string,
  updateUserData: Function,
  itemInfo: any[],
  redeemId: string,
  signInWithEmailAndPassword: Function,
  claimStateChangeHandler: Function
) => {
  event.preventDefault();
  onResetErrorMsg(claimStateChangeHandler);
  const isLoginForm = false;
  await claimStateChangeHandler(
    'err',
    validate(
      isLoginForm,
      email_error_matchPasswords,
      email_error_fieldsMissing,
      email_error_longerPassword,
      email_error_wrongFormat,
      userForm,
      claimStateChangeHandler
    )
  );
  if (!err) {
    // eslint-disable-next-line
    localStorage.setItem(SIGNIN_METHOD, PROVIDER_EMAIL);
    signInWithEmailClaim(
      history,
      email_error_alreadyUsed,
      email_error_userNotFound,
      email_error_wrongPassword,
      signInWithEmailAndPassword,
      user,
      userForm,
      isProUser,
      receipt,
      productName,
      isDev,
      currency,
      functions,
      updateUserData,
      itemInfo,
      redeemId,
      claimStateChangeHandler
    );
  }
};

export const signInWithEmailClaim = async (
  history: Object,
  email_error_alreadyUsed: string,
  email_error_userNotFound: string,
  email_error_wrongPassword: string,
  signInWithEmailAndPassword: Function,
  user: Object,
  userForm: Object,
  isProUser: boolean,
  receipt: string,
  productName: string,
  isDev: boolean,
  currency: string,
  functions: string,
  updateUserData: Function,
  itemInfo: any[],
  redeemId: string,
  claimStateChangeHandler: Function
) => {
  const { email, password } = userForm;
  claimStateChangeHandler('isLoading', true);
  claimStateChangeHandler('errorMsgFirebase', '');
  const signMsg = await signInWithEmailAndPassword(email, password);
  handleSignupWithEmail(
    signMsg,
    isProUser,
    history,
    receipt,
    productName,
    isDev,
    currency,
    functions,
    updateUserData,
    user,
    itemInfo,
    redeemId,
    email_error_alreadyUsed,
    email_error_userNotFound,
    email_error_wrongPassword,
    claimStateChangeHandler
  );
  claimStateChangeHandler('isLoading', false);
};

export const signUpWithEmailClaim = async (
  history: Object,
  email_error_alreadyUsed: string,
  email_error_userNotFound: string,
  email_error_wrongPassword: string,
  signUpWithEmail: Function,
  user: Object,
  userForm: Object,
  isProUser: boolean,
  receipt: string,
  productName: string,
  isDev: boolean,
  currency: string,
  functions: string,
  updateUserData: Function,
  itemInfo: any[],
  redeemId: string,
  claimStateChangeHandler: Function
) => {
  const { name, email, password } = userForm;
  claimStateChangeHandler('isLoading', true);
  claimStateChangeHandler('errorMsgFirebase', '');
  const signMsg = await signUpWithEmail(name, email, password);
  handleSignupWithEmail(
    signMsg,
    isProUser,
    history,
    receipt,
    productName,
    isDev,
    currency,
    functions,
    updateUserData,
    user,
    itemInfo,
    redeemId,
    email_error_alreadyUsed,
    email_error_userNotFound,
    email_error_wrongPassword,
    claimStateChangeHandler
  );
  claimStateChangeHandler('isLoading', false);
};

export const getUserData = async (user: Object) =>
  await getDatabaseUser(user).then(data => data.val());
