/* @flow */
import { connect } from 'react-redux';
import { ClaimScene } from '../../components/main-components/ClaimScene';
import { getLabel } from '../../util/LabelHelper';
import { addToSubscribeNewsList } from '../../util';
import { isUserSubscribed } from '../../profile/actions/UserSelector';

import {
  signInWithFacebook,
  signInWithGoogle,
  signInWithGoogleRedirect,
  signInWithFbRedirect,
  signInWithAppleRedirect,
  signUpWithEmail,
  signOut,
  signInAnonymously,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  saveUserData,
  createNewDatabaseUser
} from '../actions/AuthActions';
import { updateUserProfile } from '../../common/actions';
import { updateUserData, setTrialUsedIP } from '../actions';
export const mapStateToProps = (state: Object): Object => {
  const { storage } = state;
  const { user } = storage;
  const monthly_one_time_usd =
    process.env.REACT_APP_STRIPE_MONTHLY_ONE_TIME_USD;
  const monthly_one_time_eur =
    process.env.REACT_APP_STRIPE_MONTHLY_ONE_TIME_EUR;
  const monthly_one_time_pound =
    process.env.REACT_APP_STRIPE_MONTHLY_ONE_TIME_POUND;
  const monthly_one_time_thb =
    process.env.REACT_APP_STRIPE_MONTHLY_ONE_TIME_THB;
  const monthly_one_time_asd =
    process.env.REACT_APP_STRIPE_MONTHLY_ONE_TIME_ASD;
  const monthly_one_time_rub =
    process.env.REACT_APP_STRIPE_MONTHLY_ONE_TIME_RUB;
  const yearly_one_time_usd =
    process.env.REACT_APP_STRIPE_YEARLY_ONE_TIME_USD_NEW_PRICE;
  const yearly_one_time_eur =
    process.env.REACT_APP_STRIPE_YEARLY_ONE_TIME_EUR_NEW_PRICE;
  const yearly_one_time_pound =
    process.env.REACT_APP_STRIPE_YEARLY_ONE_TIME_POUND_NEW_PRICE;
  const yearly_one_time_thb =
    process.env.REACT_APP_STRIPE_YEARLY_ONE_TIME_THB_NEW_PRICE;
  const yearly_one_time_asd =
    process.env.REACT_APP_STRIPE_YEARLY_ONE_TIME_ASD_NEW_PRICE;
  const yearly_one_time_rub =
    process.env.REACT_APP_STRIPE_YEARLY_ONE_TIME_RUB_NEW_PRICE;
  const lifetime_one_time_usd = process.env.REACT_APP_STRIPE_LIFETIME;
  const lifetime_one_time_eur = process.env.REACT_APP_STRIPE_LIFETIME_EUR;
  const lifetime_one_time_pound =
    process.env.REACT_APP_STRIPE_LIFETIME_POUND_NEW;
  const lifetime_one_time_thb = process.env.REACT_APP_STRIPE_LIFETIME_THB;
  const lifetime_one_time_asd = process.env.REACT_APP_STRIPE_LIFETIME_ASD;
  const lifetime_one_time_rub = process.env.REACT_APP_STRIPE_LIFETIME_RUB;
  const lifetime_usd_20 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_20_USD;
  const lifetime_eur_20 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_20_EUR;
  const lifetime_pound_20 =
    process.env.REACT_APP_STRIPE_PROMO_LIFETIME_20_POUND;
  const lifetime_thb_20 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_20_THB;
  const lifetime_asd_20 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_20_ASD;
  const lifetime_rub_20 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_20_RUB;

  const lifetime_usd_25 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_25_USD;
  const lifetime_eur_25 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_25_EUR;
  const lifetime_pound_25 =
    process.env.REACT_APP_STRIPE_PROMO_LIFETIME_25_POUND;
  const lifetime_thb_25 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_25_THB;
  const lifetime_asd_25 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_25_ASD;
  const lifetime_rub_25 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_25_RUB;
  const lifetime_usd_50 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_50_USD;
  const lifetime_eur_50 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_50_EUR;
  const lifetime_pound_50 =
    process.env.REACT_APP_STRIPE_PROMO_LIFETIME_50_POUND;
  const lifetime_thb_50 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_50_THB;
  const lifetime_asd_50 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_50_ASD;
  const lifetime_rub_50 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_50_RUB;

  const lifetime_usd_30 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_30_USD;
  const lifetime_eur_30 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_30_EUR;
  const lifetime_pound_30 =
    process.env.REACT_APP_STRIPE_PROMO_LIFETIME_30_POUND;
  const lifetime_thb_30 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_30_THB;
  const lifetime_asd_30 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_30_ASD;
  const lifetime_rub_30 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_30_RUB;

  const lifetime_usd_40 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_40_USD;
  const lifetime_eur_40 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_40_EUR;
  const lifetime_pound_40 =
    process.env.REACT_APP_STRIPE_PROMO_LIFETIME_40_POUND;
  const lifetime_thb_40 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_40_THB;
  const lifetime_asd_40 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_40_ASD;
  const lifetime_rub_40 = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_40_RUB;

  const monthly_usd = process.env.REACT_APP_STRIPE_MONTHLY_USD_NEW;
  const monthly_eur = process.env.REACT_APP_STRIPE_MONTHLY_EUR_NEW;
  const monthly_pound = process.env.REACT_APP_STRIPE_MONTHLY_GBP_NEW;
  const monthly_thb = process.env.REACT_APP_STRIPE_MONTHLY_THB_NEW;
  const monthly_asd = process.env.REACT_APP_STRIPE_MONTHLY_ASD_NEW;
  const monthly_rub = process.env.REACT_APP_STRIPE_MONTHLY_RUB_NEW;

  const monthly_usd_20 = process.env.REACT_APP_STRIPE_PROMO_MONTHLY_20_USD;
  const monthly_eur_20 = process.env.REACT_APP_STRIPE_PROMO_MONTHLY_20_EUR;
  const monthly_pound_20 = process.env.REACT_APP_STRIPE_PROMO_MONTHLY_20_POUND;
  const monthly_thb_20 = process.env.REACT_APP_STRIPE_PROMO_MONTHLY_20_THB;
  const monthly_asd_20 = process.env.REACT_APP_STRIPE_PROMO_MONTHLY_20_ASD;
  const monthly_rub_20 = process.env.REACT_APP_STRIPE_PROMO_MONTHLY_20_RUB;

  const monthly_usd_30 = process.env.REACT_APP_STRIPE_PROMO_MONTHLY_30_USD;
  const monthly_eur_30 = process.env.REACT_APP_STRIPE_PROMO_MONTHLY_30_EUR;
  const monthly_pound_30 = process.env.REACT_APP_STRIPE_PROMO_MONTHLY_30_POUND;
  const monthly_thb_30 = process.env.REACT_APP_STRIPE_PROMO_MONTHLY_30_THB;
  const monthly_asd_30 = process.env.REACT_APP_STRIPE_PROMO_MONTHLY_30_ASD;
  const monthly_rub_30 = process.env.REACT_APP_STRIPE_PROMO_MONTHLY_30_RUB;

  const yearly = process.env.REACT_APP_STRIPE_YEARLY_USD;
  const yearly_eur = process.env.REACT_APP_STRIPE_YEARLY_EUR;
  const yearly_pound = process.env.REACT_APP_STRIPE_YEARLY_GBP;
  const yearly_thb = process.env.REACT_APP_STRIPE_YEARLY_THB;
  const yearly_asd = process.env.REACT_APP_STRIPE_YEARLY_ASD;
  const yearly_rub = process.env.REACT_APP_STRIPE_YEARLY_RUB;

  const yearly_20 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_20_USD;
  const yearly_eur_20 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_20_EUR;
  const yearly_pound_20 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_20_POUND;
  const yearly_thb_20 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_20_THB;
  const yearly_asd_20 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_20_ASD;
  const yearly_rub_20 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_20_RUB;
  const yearly_25 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_25_USD;
  const yearly_eur_25 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_25_EUR;
  const yearly_pound_25 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_25_POUND;
  const yearly_thb_25 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_25_THB;
  const yearly_asd_25 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_25_ASD;
  const yearly_rub_25 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_25_RUB;
  const yearly_30 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_30_USD;
  const yearly_eur_30 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_30_EUR;
  const yearly_pound_30 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_30_POUND;
  const yearly_thb_30 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_30_THB;
  const yearly_asd_30 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_30_ASD;
  const yearly_rub_30 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_30_RUB;
  const yearly_40 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_40_USD;
  const yearly_eur_40 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_40_EUR;
  const yearly_pound_40 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_40_POUND;
  const yearly_thb_40 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_40_THB;
  const yearly_asd_40 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_40_ASD;
  const yearly_rub_40 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_40_RUB;
  const yearly_50 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_50_USD;
  const yearly_eur_50 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_50_EUR;
  const yearly_pound_50 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_50_POUND;
  const yearly_thb_50 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_50_THB;
  const yearly_rub_50 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_50_RUB;
  const yearly_asd_50 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_50_ASD;
  const yearly_krw_50 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_50_KRW;
  const yearly_jpy_50 = process.env.REACT_APP_STRIPE_PROMO_YEARLY_50_JPY;

  const isDev = process.env.REACT_APP_DEV;
  const functions = process.env.REACT_APP_FUNCTIONS;

  return {
    user,
    isProUser: isUserSubscribed(state),
    trial_date_description_txt: getLabel(
      state,
      'trial_date_description_txt',
      'After your trial ends, you will be charged {d} per year starting {date} . You can always cancel before then.'
    ),
    login_field_email: getLabel(state, 'login_field_email', 'Email'),
    login_field_password: getLabel(state, 'login_field_password', 'Password'),
    at_least_6_password_txt: getLabel(
      state,
      'at_least_6_password_txt',
      'At least 6 characters'
    ),
    redeem_gift_txt: getLabel(state, 'redeem_gift_txt', 'Redeem Gift'),
    get_7_days_free_trial: getLabel(
      state,
      'get_7_days_free_trial',
      'Get 7 days free trial'
    ),
    unlock_60_languages: getLabel(
      state,
      'unlock_60_languages',
      'Unlock 60 languages'
    ),
    get_txt: getLabel(state, 'get_txt', 'Get'),
    off_txt: getLabel(state, 'off_txt', 'off'),
    to_txt: getLabel(state, 'to_txt', 'to'),
    login_txt_forget_password_web: getLabel(
      state,
      'login_txt_forget_password_web',
      'Forgot Password?'
    ),
    login_txt_title: getLabel(state, 'login_txt_title', 'Login'),
    already_register_txt: getLabel(
      state,
      'already_register_txt',
      'Already registered?'
    ),
    email_txt_loginNow_link: getLabel(
      state,
      'email_txt_loginNow_link',
      'Login now!'
    ),
    expoligua_msg_login: getLabel(
      state,
      'expoligua_msg_login',
      'Please signup or log in with the account that you will use or currently using in the Ling app.'
    ),
    card_number: getLabel(state, 'card_number', 'Card number'),
    card_expiration: getLabel(state, 'card_expiration', 'MM/YY'),
    card_error_empty: getLabel(
      state,
      'card_error_empty',
      'Name cannot be empty.'
    ),
    email_error_empty: getLabel(
      state,
      'email_error_empty',
      'Email cannot be empty.'
    ),
    card_error_number_not_correct: getLabel(
      state,
      'card_error_number_not_correct',
      "Card number doesn't seem correct."
    ),
    card_error_invalid_date: getLabel(
      state,
      'card_error_invalid_date',
      'Invalid card date'
    ),
    card_error_invalid_cvc: getLabel(
      state,
      'card_error_invalid_cvc',
      'Invalid CVC'
    ),
    sign_up_txt_title: getLabel(state, 'sign_up_txt_title', 'Sign Up'),
    dont_have_account_yet_txt: getLabel(
      state,
      'dont_have_account_yet_txt',
      "I don't have an account yet."
    ),
    login_field_name: getLabel(state, 'login_field_name', 'Name'),
    login_field_firstName: getLabel(
      state,
      'login_field_firstName',
      'First Name'
    ),
    login_msg_reenterPassword: getLabel(
      state,
      'login_msg_reenterPassword',
      'Reenter Password'
    ),
    email_error_matchPasswords: getLabel(
      state,
      'email_error_matchPasswords',
      'Passwords do not match.'
    ),
    email_error_fieldsMissing: getLabel(
      state,
      'email_error_fieldsMissing',
      'Please fill in all required fields.'
    ),
    email_error_longerPassword: getLabel(
      state,
      'email_error_longerPassword',
      'Password must have at least 6 characters.'
    ),
    email_error_wrongFormat: getLabel(
      state,
      'email_error_wrongFormat',
      'The email address is badly formatted.'
    ),
    email_error_alreadyUsed: getLabel(
      state,
      'email_error_alreadyUsed',
      'The email address is already in use by an existing account.'
    ),
    email_error_userNotFound: getLabel(
      state,
      'email_error_userNotFound',
      'No user exists with such email. Please register a new account.'
    ),
    email_error_wrongPassword: getLabel(
      state,
      'email_error_wrongPassword',
      'The email or password is incorrect. Please try again.'
    ),
    login_txt_forget_password_title: getLabel(
      state,
      'login_txt_forget_password_title',
      'Reset Password'
    ),
    btn_continue: getLabel(state, 'btn_continue', 'continue'),
    reset_password_txt_description: getLabel(
      state,
      'reset_password_txt_description',
      'Reset password link was sent to your email. Please check your inbox.'
    ),
    shop_txt_checkout_title_top: getLabel(
      state,
      'shop_txt_checkout_title_top',
      'Ling Premium 60 Languages'
    ),
    random_test_modal_back_txt: getLabel(
      state,
      'random_test_modal_back_txt',
      'Back'
    ),
    profile_txt_card_number: getLabel(
      state,
      'profile_txt_card_number',
      'Card Number'
    ),
    profile_txt_name_card: getLabel(
      state,
      'profile_txt_name_card',
      'Name on card'
    ),
    claim_warning_txt: getLabel(
      state,
      'claim_warning_txt',
      'Please signup or login with the email that you added in previous checkout page'
    ),
    claim_txt_first_trial_free: getLabel(
      state,
      'claim_txt_first_trial_free',
      'The first 7 days are free.'
    ),
    claim_txt_free_trial_auto_converted: getLabel(
      state,
      'claim_txt_free_trial_auto_converted',
      'The free trial will be automatically converted to a paid annual subscription after 7 days.'
    ),
    claim_txt_cancel_before_trial_end: getLabel(
      state,
      'claim_txt_cancel_before_trial_end',
      'Cancel before the trial ends to stop future charges.'
    ),
    profile_txt_recurring_billing: getLabel(
      state,
      'profile_txt_recurring_billing',
      'Recurring billing, cancel anytime.'
    ),
    profile_txt_terms_condition: getLabel(
      state,
      'profile_txt_terms_condition',
      'Terms & Conditions'
    ),
    profile_txt_expire_date: getLabel(
      state,
      'profile_txt_expire_date',
      'Expiration date'
    ),
    shop_txt_month: getLabel(state, 'shop_txt_month', 'month '),
    shop_txt_year: getLabel(state, 'shop_txt_year', 'year'),
    purchase_txt_option_lifeTime: getLabel(
      state,
      'purchase_txt_option_lifeTime',
      'lifetime'
    ),
    btn_submit: getLabel(state, 'btn_submit', 'Submit'),
    txt_email_field_placeholder: getLabel(
      state,
      'txt_email_field_placeholder',
      'username@example.com'
    ),
    monthly_one_time_usd,
    monthly_one_time_eur,
    monthly_one_time_pound,
    monthly_one_time_thb,
    monthly_one_time_asd,
    monthly_one_time_rub,
    yearly_one_time_usd,
    yearly_one_time_eur,
    yearly_one_time_pound,
    yearly_one_time_thb,
    yearly_one_time_asd,
    yearly_one_time_rub,
    lifetime_one_time_usd,
    lifetime_one_time_eur,
    lifetime_one_time_pound,
    lifetime_one_time_thb,
    lifetime_one_time_asd,
    lifetime_one_time_rub,
    lifetime_usd_20,
    lifetime_eur_20,
    lifetime_pound_20,
    lifetime_thb_20,
    lifetime_asd_20,
    lifetime_rub_20,
    lifetime_usd_25,
    lifetime_eur_25,
    lifetime_pound_25,
    lifetime_thb_25,
    lifetime_asd_25,
    lifetime_rub_25,
    lifetime_usd_30,
    lifetime_eur_30,
    lifetime_pound_30,
    lifetime_thb_30,
    lifetime_asd_30,
    lifetime_rub_30,
    lifetime_usd_40,
    lifetime_eur_40,
    lifetime_pound_40,
    lifetime_thb_40,
    lifetime_asd_40,
    lifetime_rub_40,
    lifetime_usd_50,
    lifetime_eur_50,
    lifetime_pound_50,
    lifetime_thb_50,
    lifetime_asd_50,
    lifetime_rub_50,
    monthly_usd,
    monthly_eur,
    monthly_pound,
    monthly_thb,
    monthly_asd,
    monthly_rub,
    monthly_usd_20,
    monthly_eur_20,
    monthly_pound_20,
    monthly_thb_20,
    monthly_asd_20,
    monthly_rub_20,
    monthly_usd_30,
    monthly_eur_30,
    monthly_pound_30,
    monthly_thb_30,
    monthly_asd_30,
    monthly_rub_30,
    yearly,
    yearly_eur,
    yearly_pound,
    yearly_thb,
    yearly_asd,
    yearly_rub,
    yearly_20,
    yearly_eur_20,
    yearly_pound_20,
    yearly_thb_20,
    yearly_rub_20,
    yearly_asd_20,
    yearly_25,
    yearly_eur_25,
    yearly_pound_25,
    yearly_thb_25,
    yearly_rub_25,
    yearly_asd_25,
    yearly_30,
    yearly_eur_30,
    yearly_pound_30,
    yearly_thb_30,
    yearly_rub_30,
    yearly_asd_30,
    yearly_40,
    yearly_eur_40,
    yearly_pound_40,
    yearly_thb_40,
    yearly_rub_40,
    yearly_asd_40,
    yearly_50,
    yearly_eur_50,
    yearly_pound_50,
    yearly_thb_50,
    yearly_rub_50,
    yearly_asd_50,
    yearly_krw_50,
    yearly_jpy_50,
    isDev,
    functions
  };
};

export default connect(mapStateToProps, {
  addToSubscribeNewsList,
  signInWithFacebook,
  signInWithGoogle,
  signInWithGoogleRedirect,
  signInWithFbRedirect,
  signInWithAppleRedirect,
  signUpWithEmail,
  signInWithEmailAndPassword,
  signOut,
  signInAnonymously,
  sendPasswordResetEmail,
  saveUserData,
  createNewDatabaseUser,
  setTrialUsedIP,
  updateUserData,
  updateUserProfile
})(ClaimScene);
