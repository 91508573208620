/* @flow */
import { connect } from 'react-redux';
import _ from 'lodash';
import { LessonReview } from '../components/main-components/LessonReview';
import { getCurrentUnitId } from '../../profile/selectors';
import { getLabel, getLabels } from '../../util';
import * as actions from '../../games/actions';
import { getTranscriptionState } from '../../profile/selectors';
import { loadDialogue } from '../../games/actions';
import { getCurrentCourseData } from '../../lessons/selectors/DataSelectors';
import { DIALOGUE, TUTORIAL_KEY } from '../../games/constants';

export const mapStateToProps = (state: Object): Object => {
  const { dialog, routes } = state;
  const { features } = getCurrentCourseData(state);
  const { hasTranscript } = features;
  const currentUnitId = getCurrentUnitId(state);
  const { dialogues } = _.get(
    state,
    `courses.${state.courses.activeLangCode}`,
    false
  );
  const { byId } = _.get(state, 'data.dialogHeaders', false);
  let dialogRemoveTutorial = dialogues;
  if (_.keys(dialogues)[0] === TUTORIAL_KEY) {
    dialogRemoveTutorial = _.omit(dialogues, TUTORIAL_KEY);
  }
  const dialogData = _.reduce(
    dialogRemoveTutorial,
    (result, value) => {
      const sentences = _.map(_.get(value, 'byId', []));
      const filteredSentence = _.filter(
        sentences,
        item => item && item.unit === currentUnitId
      );
      return [...result, filteredSentence];
    },
    []
  );

  const filteredDialog = _.filter(dialogData, result => {
    return result.length > 0;
  });

  const headerData = _.map(byId, result => {
    return {
      ...result,
      place: getLabel(state, result.place),
      speaker0: getLabel(state, result.speaker0),
      speaker1: getLabel(state, result.speaker1)
    };
  });
  const filteredHeader = headerData.filter(
    item => item && item.unit === _.toString(currentUnitId)
  );
  const { showReportErrorDialog, screenshot } = routes;
  const { playing } = dialog;
  return {
    playing: playing,
    dialog: { ...dialog },
    dataCollection: filteredDialog,
    headerDialog: filteredHeader,
    screen: DIALOGUE,
    title: getLabel(state, 'overView_txt_dialog', 'Dialogues'),
    // title: getLabel(state, 'overView_txt_dialog', 'Dialogues'),
    transcriptState: getTranscriptionState(state),
    translateState: dialog.translate,
    showReportErrorDialog: showReportErrorDialog,
    screenshot: screenshot,
    hasTranscript,
    ...getLabels(
      state,
      'dialogue_title_formality_formal',
      'dialogue_title_formality_informal',
      'dialog_btn_translate',
      'dialog_btn_stop',
      'writing_txt_replay',
      'modal_error_faq',
      'random_test_modal_confirm_txt',
      'modal_error_report',
      'modal_error_description',
      'report_text_sendfeedback',
      'report_text_cancle',
      'landing_txt_connectInternet',
      'btn_continue',
      'dialog_btn_stop',
      'writing_txt_replay'
    )
  };
};

export const mapDispatchToProps = (dispatch: Function) => {
  return {
    playDialogSound(soundId: string, id: number) {
      if (id !== null && id !== undefined) {
        dispatch(actions.dialogMessageActive(id));
      }
      dispatch(actions.stopDialogSound());
      dispatch(actions.playDialogSound(soundId));
    },
    dialogSaveScrollFunc(func: any) {
      dispatch(actions.dialogSaveScrollFunction(func));
    },
    pauseDialog() {
      dispatch(actions.pauseDialog());
    },
    unmountDialog() {
      dispatch(actions.stopDialogSound());
    },
    _loadDialogue(item: Object[]) {
      dispatch(loadDialogue(item));
    },
    onTranslate() {
      dispatch(actions.dialogTranslate());
    },
    toggleTranscript: () => {
      dispatch(actions.toggleTranscript());
    },
    onStopSound() {
      dispatch(actions.stopDialogSound());
      dispatch(actions.dialogMessageDeactive());
    },
    onReplay() {
      dispatch(actions.dialogReplay());
      dispatch(actions.playDialogSound(0, true));
    },
    triggerDialogAutoPlay() {
      dispatch(actions.playDialogSound(0, true));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonReview);
