/* @flow */
import * as React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import {
  GREYISH_BROWN,
  PREFIX_LESSON,
  BROWN_GREY_TWO
} from '../../../common/constants';
import { ContinueButton, Checkbox } from '../sub-components';

const star = `${PREFIX_LESSON}popover-hint.png`;
const close = `${PREFIX_LESSON}outline-time-yellow-20-px.svg`;

const CloseButton = styled.div`
  background-color: ${GREYISH_BROWN};
  border-radius: 24px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
`;

const CloseIcon = styled.img`
  height: 12px;
  width: 13px;
`;

const TitleText = styled.p`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;

const ContentImg = styled.img`
  margin-bottom: 20px;
`;

const CheckRow = styled.div`
  margin-bottom: 40px;
  display: flex;
  align-items: center;
`;

const CheckText = styled.p`
  font-size: 16px;
  color: ${BROWN_GREY_TWO};
  margin: 0;
`;

type Props = {
  visible: boolean,
  toggleHintDialog: Function,
  toggleEnableHintDialog: Function,
  enabled: boolean,
  isInfoDialog: boolean,
  hint_txt_topText1: string,
  hint_txt_topText2: string,
  hint_txt_checkBox: string,
  btn_continue: string
};

const HintDialog = ({
  visible,
  toggleHintDialog,
  toggleEnableHintDialog,
  enabled,
  btn_continue,
  hint_txt_checkBox,
  isInfoDialog = true,
  hint_txt_topText1 = '',
  hint_txt_topText2 = ''
}: Props) => {
  const { modal } = styles;

  const topText = isInfoDialog ? hint_txt_topText1 : hint_txt_topText2;
  return (
    <Modal isOpen={visible} onRequestClose={toggleHintDialog} style={modal}>
      <CloseButton onClick={toggleHintDialog}>
        <CloseIcon src={close} alt="close" />
      </CloseButton>
      <TitleText>{topText.toUpperCase()}</TitleText>
      <ContentImg src={star} alt="star" />
      {isInfoDialog && (
        <CheckRow>
          <Checkbox checked={enabled} onChange={toggleEnableHintDialog} />
          <CheckText>{hint_txt_checkBox}</CheckText>
        </CheckRow>
      )}
      <ContinueButton onNext={toggleHintDialog} btn_continue={btn_continue} />
    </Modal>
  );
};

const styles = {
  modal: {
    overlay: {
      zIndex: 100,
      backgroundColor: `${GREYISH_BROWN}70`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    content: {
      padding: 65,
      borderRadius: 29,
      boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.2)',
      top: '',
      right: '',
      bottom: '',
      left: '',
      width: 485,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      fontFamily: 'Helvetica'
    }
  }
};
export { HintDialog };
