/* @flow */
import React, { Fragment, createRef, Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  dialogMessageActive,
  dialogMessageDeactive
} from '../../actions/DialogActions';
import {
  PREFIX_LESSON,
  LIGHT_BEIGE,
  SAND_YELLOW,
  GREYISH_BROWN,
  BROWN_GREY,
  WHITE,
  MARIGOLD
} from '../../../common/constants';

const lingMale = `${PREFIX_LESSON}avatar-circle-male-60-px.svg`;
const lingFemale = `${PREFIX_LESSON}avatar-circle-female-60-px.svg`;

const LeftContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 26px;
  opacity: ${props => (props.isHide ? 0 : 1)};
`;

const LeftImageContainer = styled.img`
  width: 60px;
  height: 59px;
  margin-right: 16px;
`;

const LeftContentContainer = styled.div`
  border-radius: 30px;
  border: solid 1px ${MARIGOLD};
  background-color: ${props => (props.active ? WHITE : LIGHT_BEIGE)};
  cursor: pointer;
`;

const LeftInnerContent = styled.div`
  margin: 15px 30px;
  text-align: left;
`;

const RightContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin: 26px;
  opacity: ${props => (props.isHide ? 0 : 1)};
`;

const RightImageContainer = styled.img`
  width: 60px;
  height: 59px;
  margin-left: 16px;
`;

const RightContentContainer = styled.div`
  border-radius: 30px;
  border: solid 1px ${MARIGOLD};
  background-color: ${props => (props.active ? WHITE : SAND_YELLOW)};
  cursor: pointer;
`;

const RightInnerContent = styled.div`
  margin: 15px 30px;
  text-align: left;
`;

const MainWord = styled.p`
  font-size: ${props => (props.isTargetLangSymbol ? 25 : 16)}px;
  color: ${GREYISH_BROWN};
  diplay: block;
`;

const TranscriptWord = styled.p`
  font-size: 14px;
  color: ${BROWN_GREY};
  diplay: block;
`;

const TranslateWord = styled.p`
  font-size: 14px;
  color: ${GREYISH_BROWN};
  diplay: block;
`;

type Props = {
  id: number,
  active: boolean,
  speaker: number,
  original: string,
  soundFile: string,
  transcript: string,
  transcriptState: boolean,
  translation: string,
  translateState: boolean,
  gameState: number,
  callback: Function,
  onPress: Function,
  onActive: Function,
  playing: boolean,
  order: number,
  isFirstElement: boolean,
  isTargetLangSymbol: boolean,
  isShowTutorial: boolean
};

type State = {
  alreadyPlay: boolean,
  isHide: boolean
};

class DialogMessage extends Component<Props, State> {
  ref: Object;
  constructor(props: Props) {
    super(props);
    this.ref = createRef();
    this.state = {
      alreadyPlay: false,
      isHide: false
    };
  }

  componentDidMount() {
    const { order, gameState } = this.props;
    if (order === 0 && gameState) {
      this.handleScroll();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { playing: nextPlaying } = nextProps;
    const { playing } = this.props;
    if (!nextPlaying && playing !== nextPlaying) {
      this.setState({ isHide: false, alreadyPlay: false });
    }
  }

  componentDidUpdate() {
    const { active, playing, isFirstElement } = this.props;

    const { alreadyPlay, isHide } = this.state;
    if (playing) {
      if (!isHide && !alreadyPlay && !isFirstElement) {
        this.setState({ isHide: true });
      }
      if (active && !alreadyPlay) {
        this.setState({ isHide: false, alreadyPlay: true });
      }
    }
    active && this.handleScroll();
  }

  handleScroll() {
    this.ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }

  render() {
    const {
      id = 0,
      active = false,
      speaker = 0,
      original = 'Temporary Message',
      soundFile = 'vtr11.mp3',
      transcript = '',
      transcriptState = true,
      translation = '',
      translateState = true,
      gameState = 1,
      onPress,
      onActive = id => {},
      isShowTutorial
    } = this.props;

    const { isHide } = this.state;

    const getMainWord = () => {
      const { isTargetLangSymbol } = this.props;

      let sentence = '';
      if (gameState) {
        sentence = transcriptState ? transcript : original;
      } else {
        sentence = original;
      }
      return (
        <MainWord isTargetLangSymbol={isTargetLangSymbol}>{sentence}</MainWord>
      );
    };

    const getMainTranslate = () => {
      let sentence = '';
      if (gameState) {
        sentence = transcriptState ? transcript : original;
      } else {
        sentence = translateState || isShowTutorial ? translation : false;
      }
      if (sentence && !gameState) {
        return <TranslateWord>{sentence}</TranslateWord>;
      } else {
        return null;
      }
    };

    const getTranscriptState = () => {
      return transcriptState && !gameState ? (
        <TranscriptWord>{transcript}</TranscriptWord>
      ) : null;
    };

    const leftSpeaker = (
      <LeftContainer isHide={isHide}>
        <LeftImageContainer src={lingFemale} alt="speakerA" />
        <LeftContentContainer
          ref={this.ref}
          onClick={() => {
            this.handleScroll();
            onPress(soundFile, id);
            onActive(id);
          }}
          active={active}
        >
          <LeftInnerContent>
            {getMainWord()}
            {getTranscriptState()}
            {getMainTranslate()}
          </LeftInnerContent>
        </LeftContentContainer>
      </LeftContainer>
    );

    const rightSpeaker = (
      <RightContainer isHide={isHide}>
        <RightContentContainer
          ref={this.ref}
          onClick={() => {
            this.handleScroll();
            onPress(soundFile, id);
            onActive(id);
          }}
          active={active}
        >
          <RightInnerContent>
            {getMainWord()}
            {getTranscriptState()}
            {getMainTranslate()}
          </RightInnerContent>
        </RightContentContainer>
        <RightImageContainer src={lingMale} alt="speakerB" />
      </RightContainer>
    );

    return (
      <Fragment>{Number(speaker) === 0 ? leftSpeaker : rightSpeaker}</Fragment>
    );
  }
}

const mapStateToProps: Object = (state, ownProps) => {
  const { dialog, storage } = state;
  const { isTargetLangSymbol } = storage;
  const { playing } = dialog;
  return {
    ...ownProps,
    playing,
    isTargetLangSymbol
  };
};

const mapDispatchToProps: Object = (dispatch, ownProps) => {
  return {
    onActive: id => {
      dispatch(dialogMessageDeactive());
      dispatch(dialogMessageActive(id));
    }
  };
};

export { DialogMessage };
export default connect(mapStateToProps, mapDispatchToProps)(DialogMessage);
