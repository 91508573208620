/* Remote Config*/
import BugTracker from '../../util/BugTracker';
import { all, call, put } from 'redux-saga/effects';
import { firebase } from '../../system/Firebase';
import { loadGlobalConfig } from '../../common/actions';

/* Global Remote Config */
export const IS_SHOW_MOTIVATION = 'isShowMotivation';
export const IS_SHOW_INVITE_FRIEND = 'isShowInviteFriend';
export const IS_ENABLED_SPACED_REPETITION = 'isEnabledSpacedRepetition';
/* -------------------- */
export function* doLoadRemoteConfig(initial: boolean = true) {
  try {
    if (initial) {
      yield call(prepareDefaultConfig);
    }
    const [
      isShowMotivation,
      isShowInviteFriend,
      isEnabledSpacedRepetition
    ] = yield all([
      call(getIsShowMotivation),
      call(getIsShowInviteFriend),
      call(getIsEnabledSpacedRepetition)
    ]);

    yield put(loadGlobalConfig(isShowMotivation));
    yield put(loadGlobalConfig(isShowInviteFriend));
    yield put(loadGlobalConfig(isEnabledSpacedRepetition));
  } catch (e) {
    BugTracker.notify(e);
    console.error(e);
  }
}

export const prepareDefaultConfig = async () => {
  firebase.remoteConfig().defaultConfig = {
    isShowMotivation: false,
    isShowInviteFriend: false,
    isEnabledSpacedRepetition: false
  };
  try {
    const isActiveFetch = await firebase.remoteConfig().fetchAndActivate();

    if (!isActiveFetch && process.env.REACT_APP_DEV) {
      console.log('Fetched data not activated');
    }
  } catch (e) {
    if (
      e.domain === 'com.google.remoteconfig.ErrorDomain' ||
      e.code === 'config/failure'
    ) {
      console.log('Cannot fetching Firebase remote config', e);
    } else {
      BugTracker.notify(e);
    }
  }
};

export const getIsShowMotivation = async () => {
  const config = await firebase.remoteConfig().getBoolean(IS_SHOW_MOTIVATION);
  return { key: IS_SHOW_MOTIVATION, value: config };
};

export const getIsShowInviteFriend = async () => {
  const config = await firebase
    .remoteConfig()
    .getBoolean(IS_SHOW_INVITE_FRIEND);
  return { key: IS_SHOW_INVITE_FRIEND, value: config };
};

export const getIsEnabledSpacedRepetition = async () => {
  const config = await firebase
    .remoteConfig()
    .getBoolean(IS_ENABLED_SPACED_REPETITION);
  return { key: IS_ENABLED_SPACED_REPETITION, value: config };
};
