/* @flow */
import React from 'react';
import styled from 'styled-components';
import { WHITE } from '../../common/constants';

export const MotivatedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-item: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(${props => (props.isBanana ? -50 : -80)}%);
  min-width: 250px;
`;

export const MotivatedMsgText = styled.p`
  font-size: 13px;
  color: black;
  background: ${WHITE};
  position: relative;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  &:before {
    content: '';
    position: absolute;
    top: calc(0% - 6px);
    left: ${props => (props.isBanana ? 49 : 79)}%;
    height: 12px;
    width: 12px;
    background: ${WHITE};
    transform: translateX(-50%) rotate(45deg);
  }
`;

type Props = {
  isBanana: boolean,
  score_modal_txt: string
};

const ScoreModal = ({ isBanana, score_modal_txt }: Props) => (
  <MotivatedContainer isBanana={isBanana}>
    <MotivatedMsgText isBanana={isBanana}>{score_modal_txt}</MotivatedMsgText>
  </MotivatedContainer>
);

export { ScoreModal };
