import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

const NewSelectorCell = ({
  active,
  clickAction,
  image,
  subtext,
  text,
  customStyle,
  isLargeScreen
}) => {
  const styles = {
    image: {
      height: 44,
      width: 70,
      my: 2,
      objectFit: 'contain'
    },
    imageEmpty: {
      width: 44,
      mr: 4
    },
    inner: {
      display: 'inline-flex',
      mx: 1,
      alignItems: 'center',
      flexDirection: 'row'
    },
    outer: {
      border: 1,
      my: 2,
      borderRadius: 2,
      justifyContent: 'center',
      borderColor: active ? '#4094ED' : '#E6E6E6',
      borderStyle: 'solid',
      '&:hover': {
        borderColor: isLargeScreen ? '#4094ED' : 'none'
      },
      cursor: 'pointer'
    },
    text: {
      fontWeight: 700,
      color: '#333333',
      verticalAlign: 'center'
    },
    subtext: {
      fontSize: 14,
      pt: 0
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      p: 3.5
    }
  };

  return (
    <Box sx={styles.outer} onClick={clickAction}>
      <Box sx={styles.inner}>
        {image ? (
          <Box component="img" sx={[styles.image, customStyle]} src={image} />
        ) : (
          <Box sx={styles.imageEmpty} />
        )}
        <Box sx={styles.textContainer}>
          <Typography
            variant="subtext"
            gutterBottom={subtext ? false : true}
            sx={styles.text}
          >
            {text}
          </Typography>
          {subtext ? (
            <Typography variant="deactivatedText" sx={styles.subtext}>
              {subtext}
            </Typography>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

NewSelectorCell.propTypes = {
  active: PropTypes.bool,
  clickAction: PropTypes.func,
  image: PropTypes.string,
  text: PropTypes.string,
  subtext: PropTypes.string
};

export default NewSelectorCell;
