/* @flow */
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { PREFIX_LESSON } from '../../../common/constants';
import DefaultHeaderContainer from '../../../common/containers/DefaultHeaderContainer';
import PreHeaderConversationContainer from '../../../common/containers/PreHeaderConversationContainer';
import { ConversationSection } from '../main-components';
import { CheckButton } from '../../../games/components/sub-components';
import {
  BUTTON_SEND,
  CONVERSATION,
  BUTTON_SEND_DISABLED,
  BUTTON_CONTINUE
} from '../../../games/constants';
import CompleteScreenContainer from '../../../games/containers/CompleteScreenContainer';

type Props = {
  isBot: boolean,
  progress: number,
  progressMax: number,
  choices: Array<Object>,
  translationState: boolean,
  transcriptState: boolean,
  selectedChoice: string,
  chatLabel: string,
  chatHistories: Array<Object>,
  showCompleteScreen: boolean,
  toggleGameCompleteScreen: Function,
  exitChatbot: Function,
  replayChatbot: Function,
  chatbot_txt_complete_header: string,
  chatbot_txt_complete_body: string,
  landing_txt_connectInternet: string,
  btn_continue: string,
  preparedReport: Function,
  showReportErrorDialog: boolean,
  toggleReportError: Function,
  sendEmail: Function,
  screenshot: string,
  modal_error_faq: string,
  modal_error_report: string,
  modal_error_description: string,
  report_text_sendfeedback: string,
  report_text_cancle: string,
  goToStore: Function,
  nextChatbot: Function,
  updateChoices: Function,
  currentChat: Object,
  updateSelectedChoice: Function,
  finishChatbot: Function,
  playSound: Function,
  playSoundWithCallback: Function,
  photoURL?: string,
  targetLangCode: string,
  voiceSpeed: number,
  onShareWithImage: Function,
  translateState: boolean,
  dialog_btn_stop: string,
  playing: boolean,
  sendAnswer: Function,
  onTranslate: Function,
  onStopSound: Function,
  onReplay: Function,
  toggleTranscript: Function,
  toggleChatbotAutoPlay: Function,
  autoplay: boolean,
  resetChatbotsHistory: Function,
  addTypingSymbol: Function,
  finishChatbot: Function,
  history: Object,
  currentChatbotId: string,
  onCloseModal: Function,
  networkError: Function,
  noMatch: Function,
  checkSpeakingAnswer: Function,
  nextChatbotVoice: Function,
  lang: Object,
  speakingErrorMsg: string,
  chatbotGlobals: Object,
  PhrasesInfo: Object,
  updateHistories: Function,
  coursesData: Object,
  clearSpeakingErrorMsg: Function,
  toggleChatbotPlaying: Function,
  isTargetLangSymbol: boolean
};

type State = {
  currentChat: Object,
  choices: Array<Object>,
  isFinish: boolean
};

const translatePic = `${PREFIX_LESSON}basecolor-black-translate.svg`;

class ConversationScene extends React.Component<Props, State> {
  _container: Object;
  screen: Object;
  constructor(props: Props) {
    super(props);
    this.state = { choices: [], currentChat: {}, isFinish: false };
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const {
      isBot,
      nextChatbot,
      updateChoices,
      currentChat,
      choices
    } = nextProps;
    const { choices: prevChoices, currentChat: prevCurrentChat } = prevState;
    if (!_.isEqual(currentChat, prevCurrentChat)) {
      if (isBot) {
        setTimeout(() => {
          nextChatbot();
        }, 1000);
      } else if (!_.isEqual(choices, prevChoices) || _.isEmpty(choices)) {
        updateChoices(currentChat);
      }
    }
    return { choices, currentChat };
  }

  goToChatlist = () => {
    this.props.history.push('/chatbot');
    this.props.toggleGameCompleteScreen();
  };

  scrollDown(id: number) {
    if (this.screen) {
      this.screen.scrollTo({ y: id * 60 });
    }
  }

  componentDidMount() {
    // $flow-disable-line
    document.addEventListener('keydown', this.handleEnter);
  }
  componentWillUnmount() {
    // $flow-disable-line
    document.removeEventListener('keydown', this.handleEnter);
    this.props.clearSpeakingErrorMsg();
  }
  handleEnter = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const {
      selectedChoice,
      currentChat,
      finishChatbot,
      nextChatbot,
      clearSpeakingErrorMsg
    } = this.props;
    const { isFinish } = this.state;

    if (event.keyCode === 13) {
      if (_.isEmpty(currentChat) && selectedChoice === '' && !isFinish) {
        finishChatbot();
        this.setState({ isFinish: true });
      } else if (!_.isEmpty(currentChat) && selectedChoice !== '') {
        clearSpeakingErrorMsg();
        nextChatbot(true);
      } else if (isFinish === true) {
        this.goToChatlist();
      }
    }
  };

  render() {
    const {
      btn_continue,
      isBot,
      progress,
      progressMax,
      choices,
      translationState,
      transcriptState,
      toggleTranscript,
      selectedChoice,
      chatHistories,
      finishChatbot,
      showCompleteScreen = false,
      chatbot_txt_complete_header,
      replayChatbot,
      updateSelectedChoice,
      currentChat,
      playSound,
      playSoundWithCallback,
      targetLangCode,
      autoplay,
      translateState,
      dialog_btn_stop,
      toggleChatbotAutoPlay,
      currentChatbotId,
      voiceSpeed,
      nextChatbot,
      onTranslate = () => {},
      onCloseModal,
      networkError,
      lang,
      noMatch,
      checkSpeakingAnswer,
      nextChatbotVoice,
      speakingErrorMsg,
      chatbotGlobals,
      PhrasesInfo,
      updateHistories,
      coursesData,
      playing,
      toggleChatbotPlaying,
      isTargetLangSymbol,
      clearSpeakingErrorMsg
    } = this.props;
    return (
      <React.Fragment>
        {showCompleteScreen && _.isEmpty(currentChat) ? (
          <CompleteScreenContainer
            onNext={this.goToChatlist}
            onReplay={replayChatbot}
            complete_txt_completed={chatbot_txt_complete_header}
            isChatbot={true}
          />
        ) : (
          <React.Fragment>
            <DefaultHeaderContainer isSrollable={false} />
            <PreHeaderConversationContainer
              progress={progress}
              progressMax={progressMax}
              transcriptState={transcriptState}
              translationState={translationState}
              toggleTranscript={toggleTranscript}
            />
            <ConversationSection
              choices={_.uniqBy(choices, 'original')}
              selectedChoice={selectedChoice}
              translationState={translationState}
              transcriptState={transcriptState}
              chatHistories={chatHistories}
              isBot={isBot}
              updateSelectedChoice={updateSelectedChoice}
              currentChat={currentChat}
              playSound={playSound}
              voiceSpeed={voiceSpeed}
              playSoundWithCallback={playSoundWithCallback}
              targetLangCode={targetLangCode}
              callback={this.scrollDown.bind(this)}
              currentChatbotId={currentChatbotId}
              onCloseModal={onCloseModal}
              networkError={networkError}
              lang={lang}
              noMatch={noMatch}
              clearSpeakingErrorMsg={clearSpeakingErrorMsg}
              checkSpeakingAnswer={checkSpeakingAnswer}
              nextChatbotVoice={nextChatbotVoice}
              speakingErrorMsg={speakingErrorMsg}
              chatbotGlobals={chatbotGlobals}
              PhrasesInfo={PhrasesInfo}
              updateHistories={updateHistories}
              coursesData={coursesData}
              isChatbotPlaying={playing}
              toggleChatbotPlaying={toggleChatbotPlaying}
              isTargetLangSymbol={isTargetLangSymbol}
            />
            <CheckButton
              id={'CheckButton'}
              onPressNext={() => {
                clearSpeakingErrorMsg();
                _.isEmpty(currentChat) ? finishChatbot() : nextChatbot(true);
              }}
              isFinish={_.isEmpty(currentChat)}
              mode={
                _.isEmpty(currentChat)
                  ? BUTTON_CONTINUE
                  : selectedChoice !== ''
                  ? BUTTON_SEND
                  : BUTTON_SEND_DISABLED
              }
              btn_continue={btn_continue}
              gameType={CONVERSATION}
              answeredWrong={false}
              diffButt={false}
              playing={autoplay}
              onStopSound={toggleChatbotAutoPlay}
              onReplay={toggleChatbotAutoPlay}
              onTranslate={onTranslate}
              translatePic={translatePic}
              translateState={translateState}
              dialog_btn_stop={dialog_btn_stop || ''}
              quizAnswer={''}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const ConversationSceneWithRouter = withRouter(ConversationScene);

export { ConversationSceneWithRouter };
