/* @flow */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PreHeader from '../main-components/PreHeader';
import { ReviewCard } from '../main-components/ReviewCard';
import { ReviewHeaderCard } from '../main-components/ReviewHeaderCard';
import { PREFIX_LESSON } from '../../../common/constants';
import styled from 'styled-components';

type Props = {
  history: Object,
  overView_txt_lesson: string,
  overView_txt_vocab: string,
  overView_txt_dialog: string,
  overView_txt_grammar: string,
  triggerLoadOverview: Function,
  countObject: string,
  countDialogue: string,
  countGrammar: string,
  image: string,
  unitLevel_txt_unit: string,
  currentUnit: string,
  title: string
};

const ReviewCardWrapper = styled.div`
  margin-bottom: 85px;
`;

const circleVacabImage = `${PREFIX_LESSON}circle-vocab-60-px@2x.png`;
const circleDialogueImage = `${PREFIX_LESSON}circle-dialogue-60-px@2x.png`;
const circleGrammarImage = `${PREFIX_LESSON}circle-grammar-60-px@2x.png`;
class ReviewLessonScene extends Component<Props> {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.triggerLoadOverview();
  }

  goToLink(link: string) {
    this.props.history.push(link);
  }

  render() {
    const {
      history,
      overView_txt_lesson,
      overView_txt_vocab,
      overView_txt_dialog,
      overView_txt_grammar,
      countObject,
      countDialogue,
      countGrammar,
      image,
      title,
      currentUnit,
      unitLevel_txt_unit
    } = this.props;
    const isReview = true;
    return (
      <Fragment>
        <PreHeader
          title={overView_txt_lesson}
          history={history}
          isReview={isReview}
        />
        <ReviewHeaderCard
          image={image}
          title={title}
          currentUnit={currentUnit}
          unitLevel_txt_unit={unitLevel_txt_unit}
        />
        <ReviewCardWrapper>
          {countObject !== 0 && (
            <div onClick={() => this.goToLink('/vocabReview')}>
              <ReviewCard
                data={{
                  title: overView_txt_vocab,
                  value: countObject,
                  thumbnail_image: circleVacabImage
                }}
                index={1}
              />
            </div>
          )}
          {countDialogue !== 0 ? (
            <div onClick={() => this.goToLink('/dialogReview')}>
              <ReviewCard
                data={{
                  title: overView_txt_dialog,
                  value: countDialogue,
                  thumbnail_image: circleDialogueImage
                }}
                index={2}
              />
            </div>
          ) : null}
          {countGrammar !== 0 ? (
            <div onClick={() => this.goToLink('/grammarReview')}>
              <ReviewCard
                data={{
                  title: overView_txt_grammar,
                  value: countGrammar,
                  thumbnail_image: circleGrammarImage
                }}
                index={3}
              />
            </div>
          ) : null}
        </ReviewCardWrapper>
      </Fragment>
    );
  }
}

const ReviewLessonSceneWithRouter = withRouter(ReviewLessonScene);
export { ReviewLessonSceneWithRouter };
