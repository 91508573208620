import React from 'react';
import { IMAGE_PATH } from '../../../../common/constants';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const TestimonialCarouselMobile = ({
  testimonialData,
  currentPosition,
  styles,
  setCurrentPosition
}) => {
  const testimonial = testimonialData[currentPosition];
  const imgPath = image => `../${IMAGE_PATH}/onboarding/${image}`;

  const nextSlide = () => {
    if (currentPosition < testimonialData.length - 1) {
      setCurrentPosition(currentPosition + 1);
    }
  };

  const prevSlide = () => {
    if (currentPosition > 0) {
      setCurrentPosition(currentPosition - 1);
    }
  };

  return (
    <>
      <Box key={currentPosition} sx={styles.contentContainer}>
        <Box component="img" src={imgPath('quotes.png')} sx={styles.quoteImg} />
        <Typography variant="subtext" sx={styles.text}>
          {testimonial.text}
        </Typography>
        <Box sx={styles.userInfoContainer}>
          <Box component="img" src={testimonial.img} sx={styles.userImg} />
          <Box>
            <Typography variant="subtext" sx={styles.userNameText}>
              {testimonial.userName}
            </Typography>
            <Box sx={styles.ratingContainer}>
              <Box component="img" src={imgPath('star.png')} sx={styles.star} />
              <Typography variant="text" sx={styles.ratingText}>
                {testimonial.rating}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.arrowContainer}>
        <IconButton onClick={prevSlide} sx={styles.icon}>
          <ArrowBackIosNewIcon sx={styles.arrowIcon} />
        </IconButton>
        <IconButton onClick={nextSlide} sx={styles.icon}>
          <ArrowForwardIosIcon sx={styles.arrowIcon} />
        </IconButton>
      </Box>
    </>
  );
};

export default TestimonialCarouselMobile;
