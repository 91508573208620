/* @flow */
import React, { Component } from 'react';
import styled from 'styled-components';
import {
  PREFIX_HEADER,
  BLACK,
  MARIGOLD,
  WHITE,
  TOP_MENU_OFFSET,
  PREFIX_PROFILE,
  BANANA_POINT_SYMBOL
} from '../../common/constants';
const ReviewStyle = styled.div`
  position: fixed;
  top: ${props => (props.isScrolled ? '70px' : '225px')};
  width: 100%;
  color: ${WHITE};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
`;

const ReviewWordContainer = styled.div`
  display: flex;
  flex: 0.6;
  justify-content: space-around;
  align-items: center;
  color: ${BLACK};
`;

const ReviewWordText = styled.p`
  font-size: 19px;
  font-weight: bold;
`;
const ReviewWorddButton = styled.button`
  border-radius: 10px;
  background: black;
  color: gold;
  display: flex;
  height: 40px;
  padding-right: 19px;
  padding-left: 19px;
  align-items: center;
  border: none;
  border-radius: 20px;
  &:hover {
    opacity: 0.4;
  }
`;
const ReviewWorddButtonText = styled.p`
  margin: 0 !important;
  color: ${MARIGOLD};
  padding-right: 10px;
  padding-left: 10px;
`;
const Icon = styled.img`
  width: 24px;
  height: 28px;
`;

const BananaPoint = styled.div`
  height: 40px;
  padding: 1px 10px;
  background: ${BLACK};
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 20px;
`;
const ReviewBananaStyle = styled.div`
  align-items: center;
  font-weight: bold;
  background: ${WHITE};
  z-index: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 20px;
  max-width: 700px;
  margin: auto;
`;

const flagbanana = `${PREFIX_PROFILE}flagbanana.png`;
type State = {
  isScrolled: boolean
};

type Props = {
  promo_txt_wordsLearned: string,
  dailyBananas: number,
  wordsLearned: number,
  openWordsLearn: Function,
  random_test_review_btn: string,
  goal: Object
};
class RandomReview extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isScrolled: false
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent);
  }
  listenScrollEvent = (e: SyntheticEvent<Event>) => {
    if (window.scrollY > TOP_MENU_OFFSET) {
      this.setState({ isScrolled: true });
    } else {
      this.setState({ isScrolled: false });
    }
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenScrollEvent);
  }

  render() {
    const { isScrolled } = this.state;
    const {
      wordsLearned,
      openWordsLearn,
      random_test_review_btn,
      promo_txt_wordsLearned,
      goal,
      dailyBananas
    } = this.props;
    const { isSetDailyGoal = null, userDailyGoal } = goal;

    return (
      <ReviewStyle isScrolled={isScrolled}>
        <ReviewBananaStyle>
          <ReviewWordContainer>
            <ReviewWordText>
              {wordsLearned} {promo_txt_wordsLearned}
            </ReviewWordText>
            <ReviewWorddButton onClick={openWordsLearn}>
              <Icon src={`${PREFIX_HEADER}outline-review-24-px.png`} />
              <ReviewWorddButtonText>
                {random_test_review_btn}
              </ReviewWorddButtonText>
            </ReviewWorddButton>
          </ReviewWordContainer>
          {isSetDailyGoal && userDailyGoal && (
            <BananaPoint>
              <img src={flagbanana} alt={flagbanana} />
              <span>
                {dailyBananas}/{userDailyGoal} {BANANA_POINT_SYMBOL}
              </span>
            </BananaPoint>
          )}
        </ReviewBananaStyle>
      </ReviewStyle>
    );
  }
}

export { RandomReview };
