/* @flow */
import _ from 'lodash';

export const getChatbotGlobalsInfoData = (state: Object) =>
  _.get(state, 'data.chatbots.globals', {});

export const getChatbotTopicsData = (state: Object) =>
  _.get(state, 'data.chatbots.chatbots.allIds', []);

export const getChatbotTopicsInfoData = (state: Object) =>
  _.get(state, 'data.chatbots.chatbots.byId', {});

export const getChatbotPharsesInfoData = (state: Object) =>
  _.get(state, 'data.chatbots.chatPhrases.byId', {});

export const getVoiceSpeed = (state: Object) =>
  _.get(state, 'storage.user.voiceSpeed', 3);

export const getIsEffectEnabled = (state: Object) =>
  _.get(state, 'storage.user.isEffectEnabled', true);
