/* @flow */
import * as React from 'react';
import styled from 'styled-components';

import {
  Container,
  CardColumn,
  TranslateElementCard,
  QuestionHeader,
  CheckButton
} from '../sub-components';
import {
  CORRECT_SENTENCE,
  BUTTON_DISABLED,
  BUTTON_CHECK_DISABLED,
  BUTTON_CONTINUE_DISABLED,
  BUTTON_SEND_DISABLED,
  KEYCODE_1,
  KEYCODE_2,
  KEYCODE_3,
  KEYCODE_4,
  KEYCODE_ENTER
} from '../../constants';
import type {
  ByIdProps,
  AllIdsProps,
  QuizCardProps
} from '../../reducers/QuizReducer';
import { stopSound } from '../../../util';

const TranslatePageContainer = styled.div`
  max-width: 1024px;
  width: 100%;
`;

const CardsContainer = styled.div`
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding: 2px 25px;
`;

const CardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const TranslateStyle = styled.p`
  font-size: 16px;
  margin: 10px !important ;
`;

const CorrectSentenceStyle = styled.div`
  padding-top: 70px;
  padding-bottom: 110px;
`;

export type Props = {
  byId: ByIdProps,
  allIds: AllIdsProps,
  pressQuizCard: Function,
  pressQuizNextButton: Function,
  getCardStyle: Function,
  animationFinished: Function,
  loadAnimationFinished: boolean,
  nextGameScreen: () => void,
  mode: string,
  isAnswerWrong: boolean,
  correctWord: Object,
  correct_txt_title: string,
  btn_check: string,
  btn_continue: string,
  transcriptState: boolean,
  coins: number,
  btn_hint: string
};

class CorrectSentence extends React.Component<Props> {
  componentDidMount() {
    window.scrollTo(0, 0);
    // $flow-disable-line
    document.addEventListener('keydown', this.handleEnter);
  }
  componentWillUnmount() {
    // $flow-disable-line
    document.removeEventListener('keydown', this.handleEnter);
  }
  handleEnter = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { mode, pressQuizNextButton, byId, pressQuizCard } = this.props;

    if (
      event.keyCode === KEYCODE_ENTER &&
      pressQuizNextButton &&
      mode !== BUTTON_DISABLED &&
      mode !== BUTTON_CHECK_DISABLED &&
      mode !== BUTTON_CONTINUE_DISABLED &&
      mode !== BUTTON_SEND_DISABLED
    ) {
      pressQuizNextButton();
    } else if (
      event.keyCode === KEYCODE_1 ||
      event.keyCode === KEYCODE_2 ||
      event.keyCode === KEYCODE_3 ||
      event.keyCode === KEYCODE_4
    ) {
      const key = parseInt(event.key, 10);
      switch (key) {
        case 1:
          pressQuizCard(byId[1].id);
          break;
        case 2:
          pressQuizCard(byId[0].id);
          break;
        case 3:
          pressQuizCard(byId[3].id);
          break;
        case 4:
          pressQuizCard(byId[2].id);
          break;
        default:
          break;
      }
    }
  };
  render() {
    const {
      byId,
      correctWord,
      pressQuizNextButton,
      mode,
      isAnswerWrong,
      correct_txt_title,
      btn_check,
      btn_continue,
      btn_hint,
      coins
    } = this.props;
    const { translation } = correctWord;

    return (
      <React.Fragment>
        <CorrectSentenceStyle>
          <TranslatePageContainer>
            <QuestionHeader
              instruction={correct_txt_title}
              transcript={''}
              isPlayable={false}
            >
              {translation}
            </QuestionHeader>
          </TranslatePageContainer>
          <Container gameType={CORRECT_SENTENCE}>
            <CardsContainer>
              <CardColumn
                rightCard={this.getElementFromCard(byId[0], 2)}
                leftCard={this.getElementFromCard(byId[1], 1)}
              />
              <CardColumn
                rightCard={this.getElementFromCard(byId[2], 4)}
                leftCard={this.getElementFromCard(byId[3], 3)}
              />
            </CardsContainer>
          </Container>
        </CorrectSentenceStyle>
        <CheckButton
          quizAnswer={''}
          answeredWrong={isAnswerWrong}
          onPressNext={() => {
            stopSound();
            pressQuizNextButton();
          }}
          mode={mode}
          btn_check={btn_check}
          btn_continue={btn_continue}
          btn_hint={btn_hint}
          gameType={CORRECT_SENTENCE}
          coins={coins}
        />
      </React.Fragment>
    );
  }

  getElementFromCard(card: QuizCardProps, index: number): React.Element<any> {
    const { status, id, original, transcript } = card;
    const { getCardStyle, transcriptState } = this.props;

    const content = (
      <CardContainer>
        <TranslateStyle>{original}</TranslateStyle>
        {transcriptState ? <TranslateStyle>{transcript}</TranslateStyle> : null}
      </CardContainer>
    );

    const wordCardStyle = getCardStyle(status);

    return (
      <TranslateElementCard
        onPress={this.onPressCard(id)}
        style={wordCardStyle}
        onAnimationEnd={this.onAnimationEnd()}
        content={content}
        status={status}
        index={index}
      />
    );
  }

  onAnimationEnd() {
    return () => {
      const { loadAnimationFinished, animationFinished } = this.props;
      animationFinished(loadAnimationFinished);
    };
  }

  onPressCard(wordId: string): () => void {
    return () => {
      this.props.pressQuizCard(wordId);
    };
  }
}

export { CorrectSentence };
