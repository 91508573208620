/* @flow */
import styled from 'styled-components';
import {
  WHITE,
  GREY2,
  MARIGOLD,
  DARK_YELLOW,
  LIGHT_GREY_2,
  VERY_LIGHT_PINK_TWO,
  GREY,
  SEA_BLUE,
  BLACK
} from '../../../../common/constants';

const ModalBodyText = styled.div`
  margin: 0 20px;
`;
const ModalCloseButton = styled.div`
  cursor: pointer;
`;
const ModalBodyContainer = styled.div`
  display: 'block';
  position: relative;
`;
const ContinueStyle = styled.button`
  text-transform: uppercase;
  margin-top: 26px;
  margin-bottom: 25px;
  border-radius: 20px;
  box-shadow: inset 0 -4px 0 0 ${DARK_YELLOW};
  background: ${MARIGOLD};
  border: ${DARK_YELLOW} 1px solid;
  font-size: 14px;
  width: 157px;
  height: 55px;
  padding: 0;
  color: ${WHITE};
  bottom: -45px;
  right: 18px;
  position: absolute;
  &:disabled {
    background: ${LIGHT_GREY_2};
    box-shadow: inset 0 -4px 0 0 ${GREY2};
    border: ${GREY2} 1px solid;
  }
  &:hover:enabled {
    opacity: 0.4;
    box-shadow: none;
  }
  &:active:enabled {
    opacity: 1;
    background-color: ${DARK_YELLOW};
    box-shadow: none;
  }
`;
const ToggleButton = styled.p`
  font-size: 16px;
  text-align: start;
  align-items: flex-start;
  margin: 0 !important;
  display: flex;
  cursor: pointer;
`;
const CollapeTitle = styled.p`
  margin: 0 !important;
  margin-left: 26px !important;
`;
const CollapeIcon = styled.img`
  transform: rotate(${props => (props.arrowDirection ? 90 : -90)}deg);
  height: 17px !important;
  width: 15px !important;
  padding-right: ${props => (props.arrowDirection ? 'unset' : '8px')};
  padding-left: ${props => (props.arrowDirection ? '8px' : 'unset')};
`;
const CollapeDetail = styled.p`
  text-align: start;
  margin-left: 48px;
  width: 366px;
`;
const OtherArea = styled.textarea`
  resize: none;
  width: 348px;
  height: 120px;
  border: solid 1px ${VERY_LIGHT_PINK_TWO};
  padding: 14px 20px;
  &:focus {
    outline: none;
  }
`;

const styles = {
  modalContainer: {
    textAlign: 'center',
    borderRadius: 30,
    marginTop: 0,
    marginBottom: 0,
    height: '100%',
    width: 655,
    maxWidth: 655,
    minWidth: 655,
    transition: 'none'
  },
  modalBodyContainer: {
    padding: 0,
    paddingBottom: 36
  },
  buttonStyle: {
    borderRadius: 10,
    width: 160,
    height: 55,
    background: WHITE,
    border: 'solid 1px',
    borderColor: GREY2,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    boxShadowColor: GREY2,
    fontWeight: 'bold',
    fontSize: 18
  },
  cancelButtonStyle: {
    border: 'none',
    boxShadow: 'none',
    width: 100
  },
  confirmButtonStyle: {
    borderColor: GREY2,
    background: WHITE,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0'
  },
  modalHeader: {
    borderBottom: 'none',
    flexDirection: 'row-reverse',
    justifyContent: 'normal',
    padding: '29px 35px 7px',
    paddingBottom: 27
  },
  modalFooter: {
    borderTop: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 36,
    paddingRight: 32,
    paddingLeft: 32
  },
  contentContainer: {
    borderRadius: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    minHeight: '100%',
    height: 'auto',
    maxHeight: '100%',
    overflow: 'scroll',
    padding: '0 84px',
    border: 0,
    boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.05)'
  },
  modalHeaderContainer: {
    'modal-title': 'w-100 text-center'
  },
  inputGroup: {
    textAlign: 'left',
    borderColor: GREY,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 20
  },
  inputGroupResetPassword: {
    margin: '76px 35px 55px'
  },

  inputContainer: {
    padding: '10px 15px 15px',
    width: 'inherit',
    fontSize: 14
  },
  labelStyle: {
    fontWeight: 'bold',
    marginBottom: 12,
    textTransform: 'capitalize'
  },
  forgotTextStyle: {
    fontSize: 14,
    textAlign: 'left',
    paddingLeft: 14,
    fontWeight: 'bold',
    display: 'flex',
    paddingTop: 10
  },
  otherLoginButton: {
    borderRadius: 22,
    padding: 0,
    width: 112,
    height: 42,
    background: WHITE,
    color: BLACK,
    border: 'gray solid 1px',
    borderColor: GREY,
    borderWidth: 1,
    borderStyle: 'solid',
    margin: '0 6px',
    marginTop: 5
  },
  fbIconStyle: {
    marginLeft: 0
  },
  googleIconStyle: {
    marginRight: 0
  },
  otherLoginGroup: {
    marginBottom: 25
  },
  signUpSuggestionStyle: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  signUpStyle: {
    color: SEA_BLUE,
    cursor: 'pointer'
  },
  space: {
    height: 12
  },
  loginFormSpaceBottom: {
    paddingBottom: 4
  },
  loadingStyle: {
    margin: '134px 38px',
    color: MARIGOLD
  }
};
export {
  CollapeDetail,
  CollapeIcon,
  CollapeTitle,
  ContinueStyle,
  ModalBodyContainer,
  ModalBodyText,
  ModalCloseButton,
  OtherArea,
  ToggleButton,
  styles
};
