/* @flow */
import React from 'react';
import styled from 'styled-components';
import { LIGHT_GREY, WHITE } from '../../../common/constants';

const ReviewCardContainer = styled.div`
  width: 620px;
  height: 135px;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${LIGHT_GREY};
  background-color: ${WHITE};
  margin: auto;
  margin-bottom: 17px;
  display: flex;
  flex: 1;
  &:hover {
    cursor: pointer;
  }
`;

const ReviewIcon = styled.img`
  width: 70px;
  height: 70px;
  margin: 30px;
`;

const Title = styled.p`
  width: 318px;
  font-size: 18px;
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
`;

const ReviewCard = ({ data }: Object) => {
  const { title, value, thumbnail_image } = data;
  return (
    <ReviewCardContainer>
      <ReviewIcon src={thumbnail_image} />
      <Title>
        {title}&nbsp;{value}
      </Title>
    </ReviewCardContainer>
  );
};

export { ReviewCard };
