/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import { CompleteSentence } from '../components/scenes';
import {
  pressCharacterCard,
  pressSpellingNextButton,
  spendHint
} from '../actions';
import { onPlaySound, getLabels } from '../../util';
import { getCompleteSentenceCards, getCharacterCards } from '../util';
import { getSpelling } from '../reducers';

const CompleteSentenceContainer = (props: Object) => (
  <CompleteSentence
    {...props}
    getCompleteSentenceCards={getCompleteSentenceCards}
    getCharacterCards={getCharacterCards}
    onPlaySound={onPlaySound}
  />
);

export const mapStateToProps = (state: Object) => ({
  ...getSpelling(state),
  ...getLabels(
    state,
    'title_txt_complete',
    'btn_hint',
    'error_txt_game_wrong',
    'btn_check',
    'btn_continue'
  )
});

export { CompleteSentenceContainer };
export default connect(mapStateToProps, {
  pressCharacterCard,
  spendHint,
  pressSpellingNextButton
})(CompleteSentenceContainer);
