/* @flow */
import _ from 'lodash';
import * as types from '../constants/ActionTypes';
import {
  matchingStatus,
  MATCHING,
  REFRESH_GAME,
  CLOSE_GAME,
  BUTTON_CONTINUE,
  BUTTON_CONTINUE_DISABLED
} from '../constants';
import type { Card } from '../types';
import { getImageUrl } from '../../util';

type ById = {
  [cardId: any]: {
    isImage?: boolean,
    id?: string,
    status: string
  }
};
export type State = {
  byId: ById,
  allIds: string[]
};

const INITIAL_STATE: State = { byId: {}, allIds: [] };

export const loadMatchingGame = (state: State, action: Object): State => {
  let index = 0;
  const allIds = [];
  const { wordIds, cardIds } = action;

  const byId = wordIds.reduce((cardsDisplayed, wordId) => {
    const addCard = (isImage: boolean): ?Object => {
      const card = ++index;
      allIds.push(card);
      cardsDisplayed[card] = {
        id: wordId,
        isImage,
        status: matchingStatus.VISIBLE
      };
    };

    addCard(true);
    addCard(false);
    return cardsDisplayed;
  }, {});

  return {
    ...state,
    byId,
    allIds: cardIds
  };
};

type MatchingCard = {
  matchingCardId: number,
  isMatch: boolean
};

export const findMatchingCard = (
  byId: Object,
  cardId: number
): MatchingCard => {
  const wordId = byId[cardId].id;
  let isMatch = false;

  return {
    matchingCardId: Number(
      _.findKey((byId: Object), (c: Object, key: string) => {
        if (c.status === matchingStatus.SELECTED && Number(key) !== cardId) {
          isMatch = c.id === wordId;
          return true;
        }
      })
    ),
    isMatch
  };
};

const pressMatchingCard = (state: State, { cardId }: Object): State => {
  const { byId } = state;
  const card = byId[cardId];

  const { matchingCardId, isMatch } = findMatchingCard(byId, cardId);
  const matchingCard = byId[matchingCardId];

  if (matchingCard) {
    const status = isMatch ? matchingStatus.MATCH : matchingStatus.NO_MATCH;
    return getNewState(state, {
      ...byId,
      [matchingCardId]: {
        ...matchingCard,
        status
      },
      [cardId]: {
        ...card,
        status
      }
    });
  }

  return getNewState(state, {
    ...byId,
    [cardId]: {
      ...card,
      status:
        card.status === matchingStatus.VISIBLE
          ? matchingStatus.SELECTED
          : matchingStatus.VISIBLE
    }
  });
};

export const animationFinished = (
  state: Object,
  fromStatus: string,
  toStatus: string
) => {
  return getNewState(
    state,
    _.mapValues(state.byId, card => {
      if (card.status === fromStatus) {
        return {
          ...card,
          status: toStatus
        };
      }
      return card;
    })
  );
};

export const getNewState = (state?: State, byId: ById): State => {
  return {
    ...state,
    byId
  };
};

export const noMatchAnimationFinished = (state: Object) => {
  return animationFinished(
    state,
    matchingStatus.NO_MATCH,
    matchingStatus.VISIBLE
  );
};

export const matchAnimationFinished = (state: Object) => {
  return animationFinished(
    state,
    matchingStatus.MATCH,
    matchingStatus.INVISIBLE
  );
};

export const MatchingGameReducer = (
  state: State = INITIAL_STATE,
  action: Object
): State => {
  const { type } = action;
  switch (type) {
    case CLOSE_GAME:
      return INITIAL_STATE;
    case REFRESH_GAME:
      if (action.gameType === MATCHING) {
        return loadMatchingGame(state, action);
      }
      return state;
    case types.PRESS_MATCHING_CARD:
      return pressMatchingCard(state, action);
    case types.NO_MATCH_ANIMATION_FINISHED:
      return noMatchAnimationFinished(state);
    case types.MATCH_ANIMATION_FINISHED:
      return matchAnimationFinished(state);
    default:
      return state;
  }
};

const ANIMATIONS = {
  [matchingStatus.MATCH]: 'pulse',
  [matchingStatus.NO_MATCH]: 'jello'
};

export const getAnimation = (card: Card, loadAnimationFinished: boolean) => {
  if (loadAnimationFinished) {
    return ANIMATIONS[card.status];
  }
  return 'fadeIn';
};

export const isFinished = ({ byId }: State): boolean => {
  return _.every(byId, card => card.status === matchingStatus.INVISIBLE);
};

export const getMode = ({ byId }: Object) => {
  if (_.every(byId, card => card.status === matchingStatus.INVISIBLE)) {
    return BUTTON_CONTINUE;
  } else {
    return BUTTON_CONTINUE_DISABLED;
  }
};
export const getCardsDisplayed = (
  state: State,
  { words }: Object,
  routes: Object
) => {
  const byId = _.mapValues(state.byId, card => {
    const word = _.get(words, `byId[${card.id}]`, {});
    const { original, soundFile, imageSource, transcript } = word;
    const { isImage } = card;

    return {
      ...card,
      transcript,
      content: isImage ? getImageUrl(imageSource) : original,
      soundFile
    };
  });

  return {
    ...state,
    getAnimation,
    finished: isFinished(state),
    byId,
    loadAnimationFinished: routes.loadAnimationFinished,
    transcriptState: routes.transcript,
    mode: getMode(state)
  };
};
