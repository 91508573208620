/* @flow */
import { connect } from 'react-redux';
import { RepairedUnitModal } from '../components/main-components';
import { getLabel } from '../../util';
import { toggleRepairedUnitModal } from '../../shop/actions';

export const mapStateToProps = (state: Object) => {
  const {
    shop: { isShowRepairedUnitModal }
  } = state;

  return {
    visible: isShowRepairedUnitModal,
    spaced_repetition_congrats_txt: getLabel(
      state,
      'spaced_repetition_congrats_txt',
      'Congrats! You refreshed your knowledge and repaired this unit.'
    ),
    random_test_modal_confirm_txt: getLabel(
      state,
      'random_test_modal_confirm_txt',
      'ok'
    )
  };
};

export const mapDispatchToProps = (dispatch: Function) => {
  return {
    toggleModal: () => {
      dispatch(toggleRepairedUnitModal());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RepairedUnitModal);
