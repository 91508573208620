/* @flow */
export const LOCAL_STORAGE_CONSTANTS = {
  REDIRECT_THANKYOU_URL: 'REDIRECT_THANKYOU_URL',
  REVENUE: 'revenue', //These 4 constants are used for analytics
  PRODUCT_ID: 'productId',
  CATEGORY: 'category',
  PROMO_NAME: 'promoName'
};

// eslint-disable-next-line no-undef
export const getLocalStorage = (label: string) => localStorage.getItem(label);

export const setLocalStorage = (label: string, data: any) =>
  // eslint-disable-next-line no-undef
  localStorage.setItem(label, data);

export const removeLocalStorage = (label: string) =>
  // eslint-disable-next-line no-undef
  localStorage.removeItem(label);

export const removePromoLocalStorage = () => {
  removeLocalStorage('id');
  removeLocalStorage('promoName');
};
