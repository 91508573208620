import React from 'react';
import _ from 'lodash';
import {
  BLACK,
  BLACK_SHADE,
  DAILY_GOAL_POINT,
  DARKED_ORANGE,
  LIGHT_ORANGE,
  ORANGEY_YELLOW,
  SILVER_COLOR,
  WHITE
} from '../../../common/constants';
import styled from 'styled-components';

const DailyGoalPointContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
  &.daily-goal-point--profile {
    background: #f1f1f1;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 18px 0;
    border-radius: 15px;
  }
`;

const DailyGoalPoint = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: ${props => (props.isProfileScene ? 65 : 55)}px;
  width: ${props => (props.isProfileScene ? 65 : 55)}px;
  font-size: 14px;
  border: 1px solid ${props => (props.isSelected ? DARKED_ORANGE : BLACK_SHADE)};
  color: ${props => (props.isSelected ? ORANGEY_YELLOW : SILVER_COLOR)};
  background: ${props => (props.isSelected ? LIGHT_ORANGE : BLACK)};
  margin-right: 10px;
  border-radius: 10px;
  line-height: ${props => (props.isProfileScene ? 65 : 53)}px;
  cursor: pointer;
  > span {
    line-height: 20px;
    color: ${props => (props.isSelected ? ORANGEY_YELLOW : WHITE)};
  }
  > img {
    width: 18px;
    height: 15px;
  }
`;

const icon32CheckOrange = require('../../../img/icons/icon32CheckOrange.svg');

const renderDailySetGoalList = (
  DailySetGoalList: string[],
  isProfileScene: boolean,
  onSelectedSetGoal: Function,
  dailyGoalSelected: number
) =>
  _.map(DailySetGoalList, (setGoalItem, index) => (
    <DailyGoalPoint
      isProfileScene={isProfileScene}
      onClick={() => onSelectedSetGoal(DAILY_GOAL_POINT[setGoalItem].goal)}
      isSelected={dailyGoalSelected === DAILY_GOAL_POINT[setGoalItem].goal}
    >
      {dailyGoalSelected === DAILY_GOAL_POINT[setGoalItem].goal && (
        <img src={icon32CheckOrange} alt={icon32CheckOrange} />
      )}
      <span>{DAILY_GOAL_POINT[setGoalItem].label}</span>
    </DailyGoalPoint>
  ));
type Props = {
  dailyGoalSelected: number,
  onDailyGoalSelected: Function,
  isProfileScene: boolean
};
const DailyGoalList = ({
  dailyGoalSelected,
  onSelectedSetGoal,
  isProfileScene
}: Props) => {
  const DailySetGoalList = ['200', '400', '600'];
  return (
    <DailyGoalPointContainer
      className={isProfileScene ? 'daily-goal-point--profile' : ''}
    >
      {renderDailySetGoalList(
        DailySetGoalList,
        isProfileScene,
        onSelectedSetGoal,
        dailyGoalSelected
      )}
    </DailyGoalPointContainer>
  );
};

export { DailyGoalList };
