/* @flow */
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import CardElement from '../sub-components/CardElement';
import _ from 'lodash';
import { CHATBOTS_PADDING, IMAGE_PATH } from '../../../common/constants';

type Props = {
  chatbots: Array<Object>,
  currentChatbotId: string,
  chatbot_txt_learning: string,
  loadChatbotsData: Function,
  triggerOpenShop: Function,
  btn_continue: string,
  isLoading: boolean,
  restrictedUnits: Array<string>
};

const ChatbotsListSceneContainer = styled.div`
  width: 60%;
  margin: auto;
  margin-top: 120px;
  margin-bottom: 100px;
`;
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 225px);
`;
const ChatbotsListContainer = styled.div``;

type CustomRefs = {
  customRefs?: {}
};
const lingLoading = `${IMAGE_PATH}ling-loader.gif`;

class ChatbotsList extends Component<Props, CustomRefs> {
  customRefs: CustomRefs;
  constructor(props: Props) {
    super(props);
    this.customRefs = {};
  }

  componentDidMount() {
    const { currentChatbotId } = this.props;
    if (this.getCustomRef(`chatbotsRef${currentChatbotId}`)) {
      this.scrollToUnit(currentChatbotId);
    } else {
      window.scrollTo(0, 0);
    }
  }

  getCustomRef(refName: string) {
    // $flow-disable-line
    return this['customRefs'][refName];
  }

  setCustomRef(refName: string, ref: string) {
    // $flow-disable-line
    this['customRefs'][refName] = ref;
  }

  scrollToUnit(currentChatbotId: string) {
    const position = _.get(
      this,
      `customRefs.chatbotsRef${currentChatbotId}.current.offsetTop`,
      0
    );

    window.scrollTo(0, position - CHATBOTS_PADDING);
  }
  render() {
    const {
      chatbots,
      loadChatbotsData,
      triggerOpenShop,
      currentChatbotId,
      chatbot_txt_learning,
      isLoading,
      restrictedUnits
    }: Props = this.props;
    const getUnitRefName = key => `chatbotsRef${key}`;

    return (
      <Fragment>
        <ChatbotsListSceneContainer>
          {isLoading ? (
            <SpinnerContainer>
              <img src={lingLoading} alt={lingLoading} />
            </SpinnerContainer>
          ) : (
            <ChatbotsListContainer>
              {chatbots.map((item, index) => {
                this.setCustomRef(
                  getUnitRefName(item.id),
                  // $flow-disable-line
                  React.createRef()
                );
                const isUnitHidden = _.includes(restrictedUnits, item.id);
                return (
                  !isUnitHidden && (
                    <CardElement
                      id={item.id}
                      name={item.name}
                      key={index}
                      chatbotOrder={index + 1}
                      isChatted={item.isChatted}
                      isActive={item.isActive}
                      onPress={
                        item.isActive ? loadChatbotsData : triggerOpenShop
                      }
                      subText={
                        currentChatbotId === item.id ? chatbot_txt_learning : ''
                      }
                      scrollRef={this.getCustomRef(getUnitRefName(item.id))}
                    />
                  )
                );
              })}
            </ChatbotsListContainer>
          )}
        </ChatbotsListSceneContainer>
      </Fragment>
    );
  }
}

export { ChatbotsList };
