/* @flow */

/* Global Actions */
export const USER_SAVE_DATA: string = 'USER_SAVE_DATA';
export const USER_IS_GUEST: string = 'USER_IS_GUEST';
export const USER_IS_NOT_GUEST: string = 'USER_IS_NOT_GUEST';
export const USER_GAME_SUCCESS: string = 'USER_GAME_SUCCESS';
export const USER_LESSON_SUCCESS: string = 'USER_LESSON_SUCCESS';
export const INCREASE_GAME_SKIP_COUNT: string = 'INCREASE_GAME_SKIP_COUNT';
export const RESET_GAME_SKIP_COUNT: string = 'RESET_GAME_SKIP_COUNT';

/* Random Actions */
export const START_RANDOM_TEST: string = 'START_RANDOM_TEST';

/* Game Action */
export const LOAD_NEW_GAME: string = 'LOAD_NEW_GAME';
export const LOAD_STOP_WORDS: string = 'LOAD_STOP_WORDS';
export const CLOSE_GAME: string = 'CLOSE_GAME';
/* Flashcard Actions */
export const TOGGLE_FLASHCARD: string = 'TOGGLE_FLASHCARD';

/* Matching Game Actions */
export const PRESS_MATCHING_CARD: string = 'PRESS_MATCHING_CARD';
export const LOAD_MATCHING_GAME: string = 'LOAD_MATCHING_GAME';
export const NO_MATCH_ANIMATION_FINISHED: string =
  'NO_MATCH_ANIMATION_FINISHED';
export const MATCH_ANIMATION_FINISHED: string = 'MATCH_ANIMATION_FINISHED';
export const CHECK_SPELLING_RESULT: string = 'CHECK_SPELLING_RESULT';
export const CHECK_CORRECT_CARD: string = 'CHECK_CORRECT_CARD';

/* Quiz Actions */
export const LOAD_QUIZ: string = 'LOAD_QUIZ';
export const PRESS_QUIZ_CARD: string = 'PRESS_QUIZ_CARD';
export const QUIZ_FINISHED_NO_MATCH: string = 'QUIZ_FINISHED_NO_MATCH';
export const QUIZ_FINISHED_MATCH: string = 'QUIZ_FINISHED_MATCH';

/* Dialog Actions */
export const DIALOGUE_UPDATE: string = 'DIALOGUE_UPDATE';
export const DIALOGUE_SAVE_VIEW: string = 'DIALOGUE_SAVE_VIEW';
export const DIALOGUE_RESET_VIEW: string = 'DIALOGUE_RESET_VIEW';
export const DIALOGUE_SEQUENCE_START: string = 'DIALOGUE_SEQUENCE_START';
export const DIALOGUE_MESSAGE_ACTIVE: string = 'DIALOGUE_MESSAGE_ACTIVE';
export const DIALOGUE_MESSAGE_DEACTIVE: string = 'DIALOGUE_MESSAGE_DEACTIVE';
export const DIALOGUE_SOUND_START: string = 'DIALOGUE_SOUND_START';
export const DIALOGUE_SOUND_STOP: string = 'DIALOGUE_SOUND_STOP';
export const DIALOGUE_REPLAY: string = 'DIALOGUE_REPLAY';
export const DIALOGUE_START_GAME: string = 'DIALOGUE_START_GAME';
export const DIALOGUE_REFRESH_GAME: string = 'DIALOGUE_REFRESH_GAME';
export const DIALOGUE_FOCUS_ANSWER: string = 'DIALOGUE_FOCUS_ANSWER';
export const DIALOGUE_SELECT_ANSWER: string = 'DIALOGUE_SELECT_ANSWER';
export const DIALOGUE_CHECK_ANSWER: string = 'DIALOGUE_CHECK_ANSWER';
export const DIALOGUE_DISMISS_NOTIFY: string = 'DIALOGUE_DISMISS_NOTIFY';
export const DIALOGUE_TRANSLATE: string = 'DIALOGUE_TRANSLATE';
export const DIALOGUE_SPEND_HINT: string = 'DIALOGUE_SPEND_HINT';
export const DIALOGUE_RESET_GAME: string = 'DIALOGUE_RESET_GAME';
export const LOAD_DIALOGUE: string = 'LOAD_DIALOGUE';
export const PAUSE_DIALOGUE: string = 'PAUSE_DIALOGUE';
export const DIALOGUE_SAVE_SCROLL_FUNCTION: string =
  'DIALOGUE_SAVE_SCROLL_FUNCTION';

/* In Game Actions */
export const LOAD_ANIMATION_FINISHED: string = 'LOAD_ANIMATION_FINISHED';
export const PRESS_CHARACTER_CARD: string = 'PRESS_CHARACTER_CARD';

/* Game Screen Actions */
export const START_GAME: string = 'START_GAME';
export const START_EXAM: string = 'START_EXAM';
export const START_SPEAKING: string = 'START_SPEAKING';
export const START_WRITING: string = 'START_WRITING';
export const REFRESH_GAME: string = 'REFRESH_GAME';
export const TOGGLE_GAME_COMPLETE_SCREEN: string =
  'TOGGLE_GAME_COMPLETE_SCREEN';
export const SPEND_HINT: string = 'SPEND_HINT';
export const MOVE_TO_LOCKED_IDS: string = 'MOVE_TO_LOCKED_IDS';
export const CHECK_QUIZ_RESULT: string = 'CHECK_QUIZ_RESULT';
export const CHECK_TRANSLATIONGAME_RESULT: string =
  'CHECK_TRANSLATIONGAME_RESULT';
export const TOGGLE_GAME_COMMON_SCREEN: string = 'TOGGLE_GAME_COMMON_SCREEN';
export const TOGGLE_GAME_TRANSCRIPT: string = 'TOGGLE_GAME_TRANSCRIPT';
export const INCREASE_COMPLETED_SCREEN: string = 'INCREASE_COMPLETED_SCREEN';
export const INCREASE_ANSWERED_WRONG: string = 'INCREASE_ANSWERED_WRONG';
export const INCREASE_ANSWERED_WRONG_COUNT: string =
  'INCREASE_ANSWERED_WRONG_COUNT';
export const RESET_ANSWERED_WRONG_COUNT: string = 'RESET_ANSWERED_WRONG_COUNT';
export const SAVE_GAMESCREEN_REF: string = 'SAVE_GAMESCREEN_REF';
export const SAVE_GAMESCREEN_REPORT_TEXT: string =
  'SAVE_GAMESCREEN_REPORT_TEXT';
export const TOGGLE_GRAMMAR_SCREEN: string = 'TOGGLE_GRAMMAR_SCREEN';
export const LOAD_TRANSCRIPT: string = 'LOAD_TRANSCRIPT';
export const TOOGLE_PASSWORD_RESET_SEND_MODAL =
  'TOOGLE_PASSWORD_RESET_SEND_MODAL';

/* Hint Actions */
export const TOGGLE_HINT_DIALOG: string = 'TOGGLE_HINT_DIALOG';
export const TOGGLE_ENABLE_HINT_DIALOG: string = 'TOGGLE_ENABLE_HINT_DIALOG';
export const TOGGLE_NO_STARS_DIALOG: string = 'TOGGLE_NO_STARS_DIALOG';
export const TOGGLE_REPORT_ERROR_DIALOG: string = 'TOGGLE_REPORT_ERROR_DIALOG';

/* Chatbots Actions */
export const LOAD_CHATBOT = 'LOAD_CHATBOT';
export const LOAD_CHATBOT_NAME = 'LOAD_CHATBOT_NAME';
export const UPDATE_HISTORY = 'UPDATE_HISTORY';
export const CLOSE_COMPLETE_SCREEN = 'CLOSE_COMPLETE_SCREEN';
export const UPDATE_CURRENT = 'UPDATE_CURRENT';
export const UPDATE_CHOICE = 'UPDATE_CHOICE';
export const UPDATE_SELECTED_CHOICE = 'UPDATE_SELECTED_CHOICE';
export const RESET_CHATBOT = 'RESET_CHATBOT';
export const CHATBOT_PLAYING = 'CHATBOT_PLAYING';
export const RESET_CHATBOT_HISTORY = 'RESET_CHATBOT_HISTORY';
export const ADD_TYPING_SYMBOLE = 'ADD_TYPING_SYMBOLE';
export const TOGGLE_CHATBOT_AUTO_PLAY = 'TOGGLE_CHATBOT_AUTO_PLAY';
export const UPDATE_ERROR_IN_HISTORY = 'UPDATE_ERROR_IN_HISTORY';

// leaderboard
export const LEADERBOARD_FETCH: string = 'LEADERBOARD_FETCH';
export const FETCH_USER_RANK: string = 'FETCH_USER_RANK';
