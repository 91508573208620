import _ from 'lodash';
import axios from 'axios';
import { TUTORIAL_KEY } from '../../games/constants';

export const mergeTranslationIntoTarget = (targetCourse, nativeCourse) => {
  return _.mergeWith(
    _.cloneDeep(targetCourse),
    nativeCourse,
    (target, native, key, targetObject, nativeObject) => {
      if (key === 'translation' && target === null) {
        return nativeObject.original;
      }
      if (['original', 'transcript'].includes(key)) {
        return target;
      }
    }
  );
};

export const mergeLanguages = (target: string, native: string) => {
  return axios
    .get(`${process.env.PUBLIC_URL}/contents/${target}.json`)
    .then(targetdata => {
      let targetObj = targetdata.data;
      return axios
        .get(`${process.env.PUBLIC_URL}/contents/${native}.json`)
        .then(nativedata => {
          let nativeObj = nativedata.data;
          return {
            mergedTarget: {
              ...targetObj,
              ...mergeTranslationIntoTarget(
                {
                  words: targetObj.words,
                  sentences: targetObj.sentences,
                  dialogues: targetObj.dialogues
                },
                {
                  words: nativeObj.words,
                  sentences: nativeObj.sentences,
                  dialogues: nativeObj.dialogues
                }
              )
            }
          };
        });
    });
};

const MERGE_COURSES = `${process.env.REACT_APP_FUNCTIONS}/language-languageapi`;

export const mergeLanguagesAPI = async (
  target: string,
  native: string,
  unitId: ?string
) => {
  if (!unitId || unitId === TUTORIAL_KEY || unitId === 'content') {
    return axios
      .post(`${MERGE_COURSES}/improvemerging`, {
        targetCourse: target,
        nativeCourse: native
      })
      .then(response => response.data);
  } else {
    return await axios
      .post(`${MERGE_COURSES}/improvemergingunit`, {
        targetLangCode: target,
        nativeLangCode: native,
        unitId: unitId
      })
      .then(response => response.data);
  }
};
