/* @flow */
import styled from 'styled-components';

const SpinnerContainer = styled.div`
  display: ${props => (props.isUpdateMethod ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  height: calc(100vh - 225px);
  width: 431px;
  margin: auto;
`;
export { SpinnerContainer };
