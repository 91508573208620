/* @flow */

export const FINISH_WRITING = 'FINISH_WRITING';
export const RESET_WRITING_UI_STATE = 'RESET_WRITING_UI_STATE';

export const CLICK_WRITING = 'CLICK_WRITING';
export const CLICK_PLAY_ANIMATION = 'CLICK_PLAY_ANIMATION';
export const CLICK_SHOW_RED_ARROW = 'CLICK_SHOW_RED_ARROW';

export const ON_GUIDE_ACTIVE = 'ON_GUIDE_ACTIVE';
export const RESET_GUIDE_ACTIVE = 'RESET_GUIDE_ACTIVE';

export const FREE_HANDWRITING = 'FREE_HANDWRITING';
export const MISSING_STROKE = 'MISSING_STROKE';
export const PRACTISE = 'PRACTISE';

export const PLAY_ANIMATION = 0;
export const SHOW_RED_ARROW = 1;
export const WRITING_MODE = 2;
export const DRAW_FAIL_LIMIT = 3;
