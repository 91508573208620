import React from 'react';
import styled from 'styled-components';
import {
  DARK_BLUE,
  VERY_LIGHT_PINK,
  VERY_LIGHT_PINK_THREE
} from '../../../common/constants';

const TitleTopStyle = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  border-bottom: 1px solid ${VERY_LIGHT_PINK_THREE};
  margin: 0 !important;
  padding: 15px 0;
  span {
    color: ${DARK_BLUE};
  }
`;
const TitleTextContainer = styled.div`
  flex: 1;
  border: 1px ${VERY_LIGHT_PINK} solid;
  background: ${VERY_LIGHT_PINK};
  border-radius: 20px;
  margin: 13px;
  margin-bottom: 0;
  padding: 10px 18px;
  flex: 0.5;
  position: relative;
  ul {
    margin: 0;
    padding-left: 15px;
  }
`;
const PackageInfoStyle = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: normal;
  margin: 0 !important;
`;
const PriceInfoStyle = styled.p`
  font-weight: bold;
  font-size: 20px;
`;
const Container = styled.div`
  flex: 1;
  @media screen and (max-width: 800px) {
    width: 100vw;
    margin: auto;
  }
`;
type Props = {
  shop_txt_checkout_title_top?: string,
  shop_txt_checkout_title?: string,
  shop_txt_checkout_price?: string,
  claim_txt_first_trial_free?: string,
  claim_txt_free_trial_auto_converted?: string,
  claim_txt_cancel_before_trial_end?: string,
  isHeader: boolean,
  txt_with_7_day_trial?: string
};

const FormHeader = ({
  shop_txt_checkout_title_top,
  shop_txt_checkout_title,
  shop_txt_checkout_price,
  claim_txt_first_trial_free,
  claim_txt_free_trial_auto_converted,
  claim_txt_cancel_before_trial_end,
  isHeader,
  txt_with_7_day_trial
}: Props) => {
  return (
    <>
      {isHeader ? (
        <TitleTextContainer>
          <TitleTopStyle
            dangerouslySetInnerHTML={{
              __html: shop_txt_checkout_title_top
            }}
          />
          <PackageInfoStyle>
            <p>{shop_txt_checkout_title}</p>
            <PriceInfoStyle>{shop_txt_checkout_price}</PriceInfoStyle>
          </PackageInfoStyle>
          {txt_with_7_day_trial && <div>{txt_with_7_day_trial}</div>}
        </TitleTextContainer>
      ) : (
        <Container>
          <TitleTextContainer>
            <ul>
              <li>{claim_txt_first_trial_free}</li>
              <li>{claim_txt_free_trial_auto_converted}</li>
              <li>{claim_txt_cancel_before_trial_end}</li>
            </ul>
          </TitleTextContainer>
        </Container>
      )}
    </>
  );
};

export { FormHeader };
