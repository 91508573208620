/* @flow */
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import DefaultHeaderContainer from '../../common/containers/DefaultHeaderContainer';
import styled from 'styled-components';
import { PREFIX_HEADER, MARIGOLD, WHITE } from '../../common/constants';
import { TODAY_ID, THIS_MONTH_ID, ALL_TIME_ID } from '../actions';
import { SelectedBar } from '../sub-components/SelectedBar';
import { LeaderList } from '../sub-components/LeaderList';

type Props = {
  stats: Object,
  user: Object,
  leader_txt_title: string,
  history: Object,
  triggerFetchLeaderboard: Function,
  leaders: Array<Object>,
  flagUri: string,
  flagTarget: string,
  fetchLearningLang: Function,
  learnLang: Array<string>,
  setLangCode: Function,
  targetLangCode: string,
  name: string,
  leader_txt_thisMonth: string,
  leader_txt_today: string,
  leader_txt_allTime: string,
  stats: Object
};

type State = {
  langIndex: number,
  activeTab: number
};

const NavContainer = styled.div`
  margin-top: 20px;
  margin-right: 40px;
  margin-left: 40px;
  display: flex;
  border: ${MARIGOLD} 2px solid;
  justify-content: space-around;
`;
const NavItem = styled.div`
background: ${props => (props.active ? MARIGOLD : WHITE)}
  padding: 10px;
  flex: 1;
  text-align: center;
`;

const BackArea = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 40px;
  margin-bottom: 38px;
  &:hover {
    cursor: pointer;
  }
`;

const Title = styled.p`
  font-size: 21px;
  font-weight: bold;
  color: #454545;
  margin: 0 !important;
`;

const Image = styled.img`
  width: 18px;
  height: 32px;
  margin-right: 24px;
`;

const LeaderContainer = styled.div`
  margin: 110px auto 0;
  width: 84%;
`;

const LeaderContentContainer = styled.div``;

class LeaderScene extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      langIndex: 0,
      activeTab: 0
    };
  }
  goToBack = () => {
    this.props.history.goBack();
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    const { user, targetLangCode, setLangCode } = this.props;
    this.setState({
      langIndex: _.indexOf(user.learnLang, targetLangCode.toString())
    });
    setLangCode(targetLangCode);
    this.onSetupFetching(TODAY_ID);
  }

  onSetupFetching = (leaderboardTarget: number) => {
    const { triggerFetchLeaderboard, stats } = this.props;
    this.setState({ activeTab: leaderboardTarget });
    triggerFetchLeaderboard(stats.targetLangSelected, leaderboardTarget);
  };

  previousLang = () => {
    const { setLangCode, triggerFetchLeaderboard, user } = this.props;

    const nextIndex =
      this.state.langIndex === 0
        ? user.learnLang.length - 1
        : this.state.langIndex - 1;
    this.setState({ langIndex: nextIndex, activeTab: ALL_TIME_ID });
    setLangCode(user.learnLang[nextIndex]);

    triggerFetchLeaderboard(user.learnLang[nextIndex]);
  };

  nextLang = () => {
    const { setLangCode, triggerFetchLeaderboard, user } = this.props;
    const nextIndex =
      this.state.langIndex === user.learnLang.length - 1
        ? 0
        : this.state.langIndex + 1;
    this.setState({ langIndex: nextIndex, activeTab: ALL_TIME_ID });

    setLangCode(user.learnLang[nextIndex]);

    triggerFetchLeaderboard(user.learnLang[nextIndex]);
  };

  render() {
    const {
      leader_txt_title,
      flagUri,
      flagTarget,
      name,
      leader_txt_thisMonth,
      leader_txt_today,
      leader_txt_allTime
    } = this.props;
    const { activeTab } = this.state;
    return (
      <Fragment>
        <DefaultHeaderContainer isSrollable={false} />
        <LeaderContainer>
          <LeaderContentContainer>
            <BackArea onClick={this.goToBack}>
              <Image src={`${PREFIX_HEADER}back-icon.svg`} />
              <Title>{leader_txt_title}</Title>
            </BackArea>
            <SelectedBar
              previousLang={this.previousLang}
              nextLang={this.nextLang}
              flagUri={flagUri}
              flagTarget={flagTarget}
            />
            <NavContainer>
              <NavItem
                active={activeTab === TODAY_ID}
                onClick={() => {
                  this.onSetupFetching(TODAY_ID);
                }}
              >
                {leader_txt_today}
              </NavItem>
              <NavItem
                active={activeTab === THIS_MONTH_ID}
                onClick={() => {
                  this.onSetupFetching(THIS_MONTH_ID);
                }}
              >
                {leader_txt_thisMonth}
              </NavItem>
              <NavItem
                active={activeTab === ALL_TIME_ID}
                onClick={() => {
                  this.onSetupFetching(ALL_TIME_ID);
                }}
              >
                {leader_txt_allTime}
              </NavItem>
            </NavContainer>
            <LeaderList datas={this.props.leaders} name={name} />
          </LeaderContentContainer>
        </LeaderContainer>
      </Fragment>
    );
  }
}
const LeaderSceneWithRouter = withRouter(LeaderScene);
export { LeaderSceneWithRouter };
