/* @flow */
import * as React from 'react';
import { LIGHT_GREY } from '../../../common/constants';

type Props = {
  children?: React.Element<any>,
  style?: any,
  onClick?: () => void,
  invisible?: boolean
};

const Card = ({ children, style, onClick, invisible = false }: Props) => {
  const card = (
    <div
      style={{ ...styles.containerStyle, ...style }}
      onClick={onClick}
      disabled={invisible}
    >
      {children}
    </div>
  );
  return invisible ? <div>{card}</div> : card;
};

const styles = {
  containerStyle: {
    justifyContent: 'space-around',
    alignItems: 'center',
    display: 'flex',
    borderWidth: 2,
    borderRadius: 0,
    padding: 6,
    borderColor: LIGHT_GREY,
    margin: 8
  }
};

export { Card };
