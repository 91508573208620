/* @flow */
import * as React from 'react';
import { PrimaryText } from './PrimaryText';
import { GREY2 } from '../../../common/constants';

type Props = {
  children?: string,
  style?: Object
};
const TitleHead = ({ children, style }: Props) => {
  return (
    <PrimaryText style={{ ...containerStyle, ...style }}>
      {children}
    </PrimaryText>
  );
};

const containerStyle = {
  margin: 5,
  marginBottom: 10,
  fontSize: 26,
  textAlign: 'center',
  textAlignVertical: 'center',
  fontWeight: 'bold',
  paddingBottom: 10,
  paddingTop: 10,
  paddingLeft: 15,
  paddingRight: 15,
  borderWidth: 2,
  borderColor: GREY2,
  borderRadius: 10,
  borderStyle: 'dashed'
};

export { TitleHead };
