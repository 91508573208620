/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import {
  PREFIX_LESSON,
  BROWN_GREY_TWO,
  PREFIX_PROFILE
} from '../../common/constants';

const Container = styled.div`
  margin-top: 40px;
  margin-bottom: 27px;
  display: flex;
  text-align: center;
`;
const ContentStyle = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ProfilePic = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 50%;
`;

const NameText = styled.p`
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 4px !important;
  margin-top: 13px !important;
`;
const NameContainer = styled.div`
  flex: 0.6;
`;

const EmailText = styled.p`
  font-size: 16px;
  margin-top: 0px !important;
  color: ${BROWN_GREY_TWO};
`;

type Props = {
  userInfo: Object,
  bananas: number,
};

const lingProfile = `${PREFIX_LESSON}avatar-circle-male-60-px.svg`;

const getProfileImage = (
  photoURL: ?string,
  defaultProfilePics: Array<Object>
) =>
  Number(photoURL) < 7 && Number(photoURL) > 0 && photoURL
    ? defaultProfilePics[Number(photoURL) - 1].icon
    : photoURL
    ? photoURL
    : lingProfile;

let defaultProfilePics = [
  {
    id: 1,
    icon: `${PREFIX_PROFILE}avatar01.png`
  },
  {
    id: 2,
    icon: `${PREFIX_PROFILE}avatar02.png`
  },
  {
    id: 3,
    icon: `${PREFIX_PROFILE}avatar03.png`
  },
  {
    id: 4,
    icon: `${PREFIX_PROFILE}avatar04.png`
  },
  {
    id: 5,
    icon: `${PREFIX_PROFILE}avatar05.png`
  },
  {
    id: 6,
    icon: `${PREFIX_PROFILE}avatar06.png`
  }
];
const ProfileInfo = ({ bananas, userInfo }: Props) => {
  const { email, name, photoURL } = userInfo;
  return (
    <Container>
      <ContentStyle>
        <ProfilePic src={getProfileImage(photoURL, defaultProfilePics)} />
        <NameContainer>
          <NameText>{name}</NameText>
          {name !== 'Anonymous' ? <EmailText>{email}</EmailText> : null}
        </NameContainer>
      </ContentStyle>
    </Container>
  );
};
export { ProfileInfo };
