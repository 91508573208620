/* eslint-disable */
/*
Breaks a Javascript string into individual user-perceived "characters"
called extended grapheme clusters by implementing the Unicode UAX-29 standard, version 8.0.0

see here: https://github.com/orling/grapheme-splitter

Usage:
var splitter = new GraphemeSplitter();
//returns an array of strings, one string for each grapheme cluster
var graphemes = splitter.splitGraphemes(string);

*/
export default function GraphemeSplitter() {
  var CR = 0,
    LF = 1,
    Control = 2,
    Extend = 3,
    Regional_Indicator = 4,
    SpacingMark = 5,
    L = 6,
    V = 7,
    T = 8,
    LV = 9,
    LVT = 10,
    Other = 11;
  // Returns the next grapheme break in the string after the given index
  this.nextBreak = function(string, index) {
    if (index === undefined) {
      index = 0;
    }
    if (index < 0) {
      return 0;
    }
    if (index >= string.length - 1) {
      return string.length;
    }
    var prev = getGraphemeBreakProperty(codePointAt(string, index));
    for (var i = index + 1; i < string.length; i++) {
      // check for already processed low surrogates
      if (
        string.charCodeAt(i - 1) >= 0xd800 &&
        string.charCodeAt(i - 1) <= 0xdbff &&
        string.charCodeAt(i) >= 0xdc00 &&
        string.charCodeAt(i) <= 0xdfff
      ) {
        continue;
      }

      var next = getGraphemeBreakProperty(codePointAt(string, i));
      if (shouldBreak(prev, next)) {
        return i;
      }

      prev = next;
    }
    return string.length;
  };
  // Private function, gets a Unicode code point from a JavaScript UTF-16 string
  // handling surrogate pairs appropriately
  function codePointAt(str, idx) {
    if (idx === undefined) {
      idx = 0;
    }
    const code = str.charCodeAt(idx);
    var hi = '';
    var low = '';

    // if a high surrogate
    if (code >= 0xd800 && code <= 0xdbff && idx < str.length - 1) {
      hi = code;
      low = str.charCodeAt(idx + 1);
      if (low >= 0xdc00 && low <= 0xdfff) {
        return (hi - 0xd800) * 0x400 + (low - 0xdc00) + 0x10000;
      }
      return hi;
    }

    // if a low surrogate
    if (code >= 0xdc00 && code <= 0xdfff && idx >= 1) {
      hi = str.charCodeAt(idx - 1);
      low = code;
      if (hi >= 0xd800 && hi <= 0xdbff) {
        return (hi - 0xd800) * 0x400 + (low - 0xdc00) + 0x10000;
      }
      return low;
    }

    //just return the char if an unmatched surrogate half or a
    //single-char codepoint
    return code;
  }

  // Private function, eturns whether a break is allowed between the
  // two given grapheme breaking classes
  function shouldBreak(previous, current) {
    // GB3. CR X LF
    if (previous === CR && current === LF) {
      return false;
    } else if (previous === Control || previous === CR || previous === LF) {
      // GB4. (Control|CR|LF) ÷
      return true;
    } else if (current === Control || current === CR || current === LF) {
      // GB5. ÷ (Control|CR|LF)
      return true;
    } else if (
      previous === L &&
      (current === L || current === V || current === LV || current === LVT)
    ) {
      // GB6. L X (L|V|LV|LVT)
      return false;
    } else if (
      (previous === LV || previous === V) &&
      (current === V || current === T)
    ) {
      // GB7. (LV|V) X (V|T)
      return false;
    } else if ((previous === LVT || previous === T) && current === T) {
      // GB8. (LVT|T) X (T)
      return false;
    } else if (
      previous === Regional_Indicator &&
      current === Regional_Indicator
    ) {
      // GB8a. Regional_Indicator X Regional_Indicator
      return false;
    } else if (current === Extend) {
      // GB9. X Extend
      return false;
    } else if (current === SpacingMark) {
      // GB9a. X SpacingMark
      return false;
    }
    // GB9b. Prepend X (there are currently no characters with this class)
    // else if previous is Prepend
    //   return false

    // GB10. Any ÷ Any
    return true;
  }
  //given a Unicode code point, determines this symbol's grapheme break property
  function getGraphemeBreakProperty(code) {
    //grapheme break property for Unicode 8.0.0,
    //taken from http://www.unicode.org/Public/8.0.0/ucd/auxiliary/GraphemeBreakProperty.txt
    //and adapted to JavaScript rules

    if (
      code === 0x000d // Cc       <control-000D>
    ) {
      return CR;
    }

    if (
      code === 0x000a // Cc       <control-000A>
    ) {
      return LF;
    }

    if (
      (code >= 0x0000 && code <= 0x0009) || // Cc  [10] <control-0000>..<control-0009>
      (code >= 0x000b && code <= 0x000c) || // Cc   [2] <control-000B>..<control-000C>
      (code >= 0x000e && code <= 0x001f) || // Cc  [18] <control-000E>..<control-001F>
      (code >= 0x007f && code <= 0x009f) || // Cc  [33] <control-007F>..<control-009F>
      code === 0x00ad || // Cf       SOFT HYPHEN
      (code >= 0x0600 && code <= 0x0605) || // Cf   [6] ARABIC NUMBER SIGN..ARABIC NUMBER MARK ABOVE
      code === 0x061c || // Cf       ARABIC LETTER MARK
      code === 0x06dd || // Cf       ARABIC END OF AYAH
      code === 0x070f || // Cf       SYRIAC ABBREVIATION MARK
      code === 0x180e || // Cf       MONGOLIAN VOWEL SEPARATOR
      code === 0x200b || // Cf       ZERO WIDTH SPACE
      (code >= 0x200e && code <= 0x200f) || // Cf   [2] LEFT-TO-RIGHT MARK..RIGHT-TO-LEFT MARK
      code === 0x2028 || // Zl       LINE SEPARATOR
      code === 0x2029 || // Zp       PARAGRAPH SEPARATOR
      (code >= 0x202a && code <= 0x202e) || // Cf   [5] LEFT-TO-RIGHT EMBEDDING..RIGHT-TO-LEFT OVERRIDE
      (code >= 0x2060 && code <= 0x2064) || // Cf   [5] WORD JOINER..INVISIBLE PLUS
      code === 0x2065 || // Cn       <reserved-2065>
      (code >= 0x2066 && code <= 0x206f) || // Cf  [10] LEFT-TO-RIGHT ISOLATE..NOMINAL DIGIT SHAPES
      (code >= 0xd800 && code <= 0xdfff) || // Cs [2048] <surrogate-D800>..<surrogate-DFFF>
      code === 0xfeff || // Cf       ZERO WIDTH NO-BREAK SPACE
      (code >= 0xfff0 && code <= 0xfff8) || // Cn   [9] <reserved-FFF0>..<reserved-FFF8>
      (code >= 0xfff9 && code <= 0xfffb) || // Cf   [3] INTERLINEAR ANNOTATION ANCHOR..INTERLINEAR ANNOTATION TERMINATOR
      code === 0x110bd || // Cf       KAITHI NUMBER SIGN
      (code >= 0x1bca0 && code <= 0x1bca3) || // Cf   [4] SHORTHAND FORMAT LETTER OVERLAP..SHORTHAND FORMAT UP STEP
      (code >= 0x1d173 && code <= 0x1d17a) || // Cf   [8] MUSICAL SYMBOL BEGIN BEAM..MUSICAL SYMBOL END PHRASE
      code === 0xe0000 || // Cn       <reserved-E0000>
      code === 0xe0001 || // Cf       LANGUAGE TAG
      (code >= 0xe0002 && code <= 0xe001f) || // Cn  [30] <reserved-E0002>..<reserved-E001F>
      (code >= 0xe0020 && code <= 0xe007f) || // Cf  [96] TAG SPACE..CANCEL TAG
      (code >= 0xe0080 && code <= 0xe00ff) || // Cn [128] <reserved-E0080>..<reserved-E00FF>
      (code >= 0xe01f0 && code <= 0xe0fff) // Cn [3600] <reserved-E01F0>..<reserved-E0FFF>
    ) {
      return Control;
    }

    if (
      (code >= 0x0300 && code <= 0x036f) || // Mn [112] COMBINING GRAVE ACCENT..COMBINING LATIN SMALL LETTER X
      (code >= 0x0483 && code <= 0x0487) || // Mn   [5] COMBINING CYRILLIC TITLO..COMBINING CYRILLIC POKRYTIE
      (code >= 0x0488 && code <= 0x0489) || // Me   [2] COMBINING CYRILLIC HUNDRED THOUSANDS SIGN..COMBINING CYRILLIC MILLIONS SIGN
      (code >= 0x0591 && code <= 0x05bd) || // Mn  [45] HEBREW ACCENT ETNAHTA..HEBREW POINT METEG
      code === 0x05bf || // Mn       HEBREW POINT RAFE
      (code >= 0x05c1 && code <= 0x05c2) || // Mn   [2] HEBREW POINT SHIN DOT..HEBREW POINT SIN DOT
      (code >= 0x05c4 && code <= 0x05c5) || // Mn   [2] HEBREW MARK UPPER DOT..HEBREW MARK LOWER DOT
      code === 0x05c7 || // Mn       HEBREW POINT QAMATS QATAN
      (code >= 0x0610 && code <= 0x061a) || // Mn  [11] ARABIC SIGN SALLALLAHOU ALAYHE WASSALLAM..ARABIC SMALL KASRA
      (code >= 0x064b && code <= 0x065f) || // Mn  [21] ARABIC FATHATAN..ARABIC WAVY HAMZA BELOW
      code === 0x0670 || // Mn       ARABIC LETTER SUPERSCRIPT ALEF
      (code >= 0x06d6 && code <= 0x06dc) || // Mn   [7] ARABIC SMALL HIGH LIGATURE SAD WITH LAM WITH ALEF MAKSURA..ARABIC SMALL HIGH SEEN
      (code >= 0x06df && code <= 0x06e4) || // Mn   [6] ARABIC SMALL HIGH ROUNDED ZERO..ARABIC SMALL HIGH MADDA
      (code >= 0x06e7 && code <= 0x06e8) || // Mn   [2] ARABIC SMALL HIGH YEH..ARABIC SMALL HIGH NOON
      (code >= 0x06ea && code <= 0x06ed) || // Mn   [4] ARABIC EMPTY CENTRE LOW STOP..ARABIC SMALL LOW MEEM
      code === 0x0711 || // Mn       SYRIAC LETTER SUPERSCRIPT ALAPH
      (code >= 0x0730 && code <= 0x074a) || // Mn  [27] SYRIAC PTHAHA ABOVE..SYRIAC BARREKH
      (code >= 0x07a6 && code <= 0x07b0) || // Mn  [11] THAANA ABAFILI..THAANA SUKUN
      (code >= 0x07eb && code <= 0x07f3) || // Mn   [9] NKO COMBINING SHORT HIGH TONE..NKO COMBINING DOUBLE DOT ABOVE
      (code >= 0x0816 && code <= 0x0819) || // Mn   [4] SAMARITAN MARK IN..SAMARITAN MARK DAGESH
      (code >= 0x081b && code <= 0x0823) || // Mn   [9] SAMARITAN MARK EPENTHETIC YUT..SAMARITAN VOWEL SIGN A
      (code >= 0x0825 && code <= 0x0827) || // Mn   [3] SAMARITAN VOWEL SIGN SHORT A..SAMARITAN VOWEL SIGN U
      (code >= 0x0829 && code <= 0x082d) || // Mn   [5] SAMARITAN VOWEL SIGN LONG I..SAMARITAN MARK NEQUDAA
      (code >= 0x0859 && code <= 0x085b) || // Mn   [3] MANDAIC AFFRICATION MARK..MANDAIC GEMINATION MARK
      (code >= 0x08e3 && code <= 0x0902) || // Mn  [32] ARABIC TURNED DAMMA BELOW..DEVANAGARI SIGN ANUSVARA
      code === 0x093a || // Mn       DEVANAGARI VOWEL SIGN OE
      code === 0x093c || // Mn       DEVANAGARI SIGN NUKTA
      (code >= 0x0941 && code <= 0x0948) || // Mn   [8] DEVANAGARI VOWEL SIGN U..DEVANAGARI VOWEL SIGN AI
      code === 0x094d || // Mn       DEVANAGARI SIGN VIRAMA
      (code >= 0x0951 && code <= 0x0957) || // Mn   [7] DEVANAGARI STRESS SIGN UDATTA..DEVANAGARI VOWEL SIGN UUE
      (code >= 0x0962 && code <= 0x0963) || // Mn   [2] DEVANAGARI VOWEL SIGN VOCALIC L..DEVANAGARI VOWEL SIGN VOCALIC LL
      code === 0x0981 || // Mn       BENGALI SIGN CANDRABINDU
      code === 0x09bc || // Mn       BENGALI SIGN NUKTA
      code === 0x09be || // Mc       BENGALI VOWEL SIGN AA
      (code >= 0x09c1 && code <= 0x09c4) || // Mn   [4] BENGALI VOWEL SIGN U..BENGALI VOWEL SIGN VOCALIC RR
      code === 0x09cd || // Mn       BENGALI SIGN VIRAMA
      code === 0x09d7 || // Mc       BENGALI AU LENGTH MARK
      (code >= 0x09e2 && code <= 0x09e3) || // Mn   [2] BENGALI VOWEL SIGN VOCALIC L..BENGALI VOWEL SIGN VOCALIC LL
      (code >= 0x0a01 && code <= 0x0a02) || // Mn   [2] GURMUKHI SIGN ADAK BINDI..GURMUKHI SIGN BINDI
      code === 0x0a3c || // Mn       GURMUKHI SIGN NUKTA
      (code >= 0x0a41 && code <= 0x0a42) || // Mn   [2] GURMUKHI VOWEL SIGN U..GURMUKHI VOWEL SIGN UU
      (code >= 0x0a47 && code <= 0x0a48) || // Mn   [2] GURMUKHI VOWEL SIGN EE..GURMUKHI VOWEL SIGN AI
      (code >= 0x0a4b && code <= 0x0a4d) || // Mn   [3] GURMUKHI VOWEL SIGN OO..GURMUKHI SIGN VIRAMA
      code === 0x0a51 || // Mn       GURMUKHI SIGN UDAAT
      (code >= 0x0a70 && code <= 0x0a71) || // Mn   [2] GURMUKHI TIPPI..GURMUKHI ADDAK
      code === 0x0a75 || // Mn       GURMUKHI SIGN YAKASH
      (code >= 0x0a81 && code <= 0x0a82) || // Mn   [2] GUJARATI SIGN CANDRABINDU..GUJARATI SIGN ANUSVARA
      code === 0x0abc || // Mn       GUJARATI SIGN NUKTA
      (code >= 0x0ac1 && code <= 0x0ac5) || // Mn   [5] GUJARATI VOWEL SIGN U..GUJARATI VOWEL SIGN CANDRA E
      (code >= 0x0ac7 && code <= 0x0ac8) || // Mn   [2] GUJARATI VOWEL SIGN E..GUJARATI VOWEL SIGN AI
      code === 0x0acd || // Mn       GUJARATI SIGN VIRAMA
      (code >= 0x0ae2 && code <= 0x0ae3) || // Mn   [2] GUJARATI VOWEL SIGN VOCALIC L..GUJARATI VOWEL SIGN VOCALIC LL
      code === 0x0b01 || // Mn       ORIYA SIGN CANDRABINDU
      code === 0x0b3c || // Mn       ORIYA SIGN NUKTA
      code === 0x0b3e || // Mc       ORIYA VOWEL SIGN AA
      code === 0x0b3f || // Mn       ORIYA VOWEL SIGN I
      (code >= 0x0b41 && code <= 0x0b44) || // Mn   [4] ORIYA VOWEL SIGN U..ORIYA VOWEL SIGN VOCALIC RR
      code === 0x0b4d || // Mn       ORIYA SIGN VIRAMA
      code === 0x0b56 || // Mn       ORIYA AI LENGTH MARK
      code === 0x0b57 || // Mc       ORIYA AU LENGTH MARK
      (code >= 0x0b62 && code <= 0x0b63) || // Mn   [2] ORIYA VOWEL SIGN VOCALIC L..ORIYA VOWEL SIGN VOCALIC LL
      code === 0x0b82 || // Mn       TAMIL SIGN ANUSVARA
      code === 0x0bbe || // Mc       TAMIL VOWEL SIGN AA
      code === 0x0bc0 || // Mn       TAMIL VOWEL SIGN II
      code === 0x0bcd || // Mn       TAMIL SIGN VIRAMA
      code === 0x0bd7 || // Mc       TAMIL AU LENGTH MARK
      code === 0x0c00 || // Mn       TELUGU SIGN COMBINING CANDRABINDU ABOVE
      (code >= 0x0c3e && code <= 0x0c40) || // Mn   [3] TELUGU VOWEL SIGN AA..TELUGU VOWEL SIGN II
      (code >= 0x0c46 && code <= 0x0c48) || // Mn   [3] TELUGU VOWEL SIGN E..TELUGU VOWEL SIGN AI
      (code >= 0x0c4a && code <= 0x0c4d) || // Mn   [4] TELUGU VOWEL SIGN O..TELUGU SIGN VIRAMA
      (code >= 0x0c55 && code <= 0x0c56) || // Mn   [2] TELUGU LENGTH MARK..TELUGU AI LENGTH MARK
      (code >= 0x0c62 && code <= 0x0c63) || // Mn   [2] TELUGU VOWEL SIGN VOCALIC L..TELUGU VOWEL SIGN VOCALIC LL
      code === 0x0c81 || // Mn       KANNADA SIGN CANDRABINDU
      code === 0x0cbc || // Mn       KANNADA SIGN NUKTA
      code === 0x0cbf || // Mn       KANNADA VOWEL SIGN I
      code === 0x0cc2 || // Mc       KANNADA VOWEL SIGN UU
      code === 0x0cc6 || // Mn       KANNADA VOWEL SIGN E
      (code >= 0x0ccc && code <= 0x0ccd) || // Mn   [2] KANNADA VOWEL SIGN AU..KANNADA SIGN VIRAMA
      (code >= 0x0cd5 && code <= 0x0cd6) || // Mc   [2] KANNADA LENGTH MARK..KANNADA AI LENGTH MARK
      (code >= 0x0ce2 && code <= 0x0ce3) || // Mn   [2] KANNADA VOWEL SIGN VOCALIC L..KANNADA VOWEL SIGN VOCALIC LL
      code === 0x0d01 || // Mn       MALAYALAM SIGN CANDRABINDU
      code === 0x0d3e || // Mc       MALAYALAM VOWEL SIGN AA
      (code >= 0x0d41 && code <= 0x0d44) || // Mn   [4] MALAYALAM VOWEL SIGN U..MALAYALAM VOWEL SIGN VOCALIC RR
      code === 0x0d4d || // Mn       MALAYALAM SIGN VIRAMA
      code === 0x0d57 || // Mc       MALAYALAM AU LENGTH MARK
      (code >= 0x0d62 && code <= 0x0d63) || // Mn   [2] MALAYALAM VOWEL SIGN VOCALIC L..MALAYALAM VOWEL SIGN VOCALIC LL
      code === 0x0dca || // Mn       SINHALA SIGN AL-LAKUNA
      code === 0x0dcf || // Mc       SINHALA VOWEL SIGN AELA-PILLA
      (code >= 0x0dd2 && code <= 0x0dd4) || // Mn   [3] SINHALA VOWEL SIGN KETTI IS-PILLA..SINHALA VOWEL SIGN KETTI PAA-PILLA
      code === 0x0dd6 || // Mn       SINHALA VOWEL SIGN DIGA PAA-PILLA
      code === 0x0ddf || // Mc       SINHALA VOWEL SIGN GAYANUKITTA
      code === 0x0e31 || // Mn       THAI CHARACTER MAI HAN-AKAT
      (code >= 0x0e34 && code <= 0x0e3a) || // Mn   [7] THAI CHARACTER SARA I..THAI CHARACTER PHINTHU
      (code >= 0x0e47 && code <= 0x0e4e) || // Mn   [8] THAI CHARACTER MAITAIKHU..THAI CHARACTER YAMAKKAN
      code === 0x0eb1 || // Mn       LAO VOWEL SIGN MAI KAN
      (code >= 0x0eb4 && code <= 0x0eb9) || // Mn   [6] LAO VOWEL SIGN I..LAO VOWEL SIGN UU
      (code >= 0x0ebb && code <= 0x0ebc) || // Mn   [2] LAO VOWEL SIGN MAI KON..LAO SEMIVOWEL SIGN LO
      (code >= 0x0ec8 && code <= 0x0ecd) || // Mn   [6] LAO TONE MAI EK..LAO NIGGAHITA
      (code >= 0x0f18 && code <= 0x0f19) || // Mn   [2] TIBETAN ASTROLOGICAL SIGN -KHYUD PA..TIBETAN ASTROLOGICAL SIGN SDONG TSHUGS
      code === 0x0f35 || // Mn       TIBETAN MARK NGAS BZUNG NYI ZLA
      code === 0x0f37 || // Mn       TIBETAN MARK NGAS BZUNG SGOR RTAGS
      code === 0x0f39 || // Mn       TIBETAN MARK TSA -PHRU
      (code >= 0x0f71 && code <= 0x0f7e) || // Mn  [14] TIBETAN VOWEL SIGN AA..TIBETAN SIGN RJES SU NGA RO
      (code >= 0x0f80 && code <= 0x0f84) || // Mn   [5] TIBETAN VOWEL SIGN REVERSED I..TIBETAN MARK HALANTA
      (code >= 0x0f86 && code <= 0x0f87) || // Mn   [2] TIBETAN SIGN LCI RTAGS..TIBETAN SIGN YANG RTAGS
      (code >= 0x0f8d && code <= 0x0f97) || // Mn  [11] TIBETAN SUBJOINED SIGN LCE TSA CAN..TIBETAN SUBJOINED LETTER JA
      (code >= 0x0f99 && code <= 0x0fbc) || // Mn  [36] TIBETAN SUBJOINED LETTER NYA..TIBETAN SUBJOINED LETTER FIXED-FORM RA
      code === 0x0fc6 || // Mn       TIBETAN SYMBOL PADMA GDAN
      (code >= 0x102d && code <= 0x1030) || // Mn   [4] MYANMAR VOWEL SIGN I..MYANMAR VOWEL SIGN UU
      (code >= 0x1032 && code <= 0x1037) || // Mn   [6] MYANMAR VOWEL SIGN AI..MYANMAR SIGN DOT BELOW
      (code >= 0x1039 && code <= 0x103a) || // Mn   [2] MYANMAR SIGN VIRAMA..MYANMAR SIGN ASAT
      (code >= 0x103d && code <= 0x103e) || // Mn   [2] MYANMAR CONSONANT SIGN MEDIAL WA..MYANMAR CONSONANT SIGN MEDIAL HA
      (code >= 0x1058 && code <= 0x1059) || // Mn   [2] MYANMAR VOWEL SIGN VOCALIC L..MYANMAR VOWEL SIGN VOCALIC LL
      (code >= 0x105e && code <= 0x1060) || // Mn   [3] MYANMAR CONSONANT SIGN MON MEDIAL NA..MYANMAR CONSONANT SIGN MON MEDIAL LA
      (code >= 0x1071 && code <= 0x1074) || // Mn   [4] MYANMAR VOWEL SIGN GEBA KAREN I..MYANMAR VOWEL SIGN KAYAH EE
      code === 0x1082 || // Mn       MYANMAR CONSONANT SIGN SHAN MEDIAL WA
      (code >= 0x1085 && code <= 0x1086) || // Mn   [2] MYANMAR VOWEL SIGN SHAN E ABOVE..MYANMAR VOWEL SIGN SHAN FINAL Y
      code === 0x108d || // Mn       MYANMAR SIGN SHAN COUNCIL EMPHATIC TONE
      code === 0x109d || // Mn       MYANMAR VOWEL SIGN AITON AI
      (code >= 0x135d && code <= 0x135f) || // Mn   [3] ETHIOPIC COMBINING GEMINATION AND VOWEL LENGTH MARK..ETHIOPIC COMBINING GEMINATION MARK
      (code >= 0x1712 && code <= 0x1714) || // Mn   [3] TAGALOG VOWEL SIGN I..TAGALOG SIGN VIRAMA
      (code >= 0x1732 && code <= 0x1734) || // Mn   [3] HANUNOO VOWEL SIGN I..HANUNOO SIGN PAMUDPOD
      (code >= 0x1752 && code <= 0x1753) || // Mn   [2] BUHID VOWEL SIGN I..BUHID VOWEL SIGN U
      (code >= 0x1772 && code <= 0x1773) || // Mn   [2] TAGBANWA VOWEL SIGN I..TAGBANWA VOWEL SIGN U
      (code >= 0x17b4 && code <= 0x17b5) || // Mn   [2] KHMER VOWEL INHERENT AQ..KHMER VOWEL INHERENT AA
      (code >= 0x17b7 && code <= 0x17bd) || // Mn   [7] KHMER VOWEL SIGN I..KHMER VOWEL SIGN UA
      code === 0x17c6 || // Mn       KHMER SIGN NIKAHIT
      (code >= 0x17c9 && code <= 0x17d3) || // Mn  [11] KHMER SIGN MUUSIKATOAN..KHMER SIGN BATHAMASAT
      code === 0x17dd || // Mn       KHMER SIGN ATTHACAN
      (code >= 0x180b && code <= 0x180d) || // Mn   [3] MONGOLIAN FREE VARIATION SELECTOR ONE..MONGOLIAN FREE VARIATION SELECTOR THREE
      code === 0x18a9 || // Mn       MONGOLIAN LETTER ALI GALI DAGALGA
      (code >= 0x1920 && code <= 0x1922) || // Mn   [3] LIMBU VOWEL SIGN A..LIMBU VOWEL SIGN U
      (code >= 0x1927 && code <= 0x1928) || // Mn   [2] LIMBU VOWEL SIGN E..LIMBU VOWEL SIGN O
      code === 0x1932 || // Mn       LIMBU SMALL LETTER ANUSVARA
      (code >= 0x1939 && code <= 0x193b) || // Mn   [3] LIMBU SIGN MUKPHRENG..LIMBU SIGN SA-I
      (code >= 0x1a17 && code <= 0x1a18) || // Mn   [2] BUGINESE VOWEL SIGN I..BUGINESE VOWEL SIGN U
      code === 0x1a1b || // Mn       BUGINESE VOWEL SIGN AE
      code === 0x1a56 || // Mn       TAI THAM CONSONANT SIGN MEDIAL LA
      (code >= 0x1a58 && code <= 0x1a5e) || // Mn   [7] TAI THAM SIGN MAI KANG LAI..TAI THAM CONSONANT SIGN SA
      code === 0x1a60 || // Mn       TAI THAM SIGN SAKOT
      code === 0x1a62 || // Mn       TAI THAM VOWEL SIGN MAI SAT
      (code >= 0x1a65 && code <= 0x1a6c) || // Mn   [8] TAI THAM VOWEL SIGN I..TAI THAM VOWEL SIGN OA BELOW
      (code >= 0x1a73 && code <= 0x1a7c) || // Mn  [10] TAI THAM VOWEL SIGN OA ABOVE..TAI THAM SIGN KHUEN-LUE KARAN
      code === 0x1a7f || // Mn       TAI THAM COMBINING CRYPTOGRAMMIC DOT
      (code >= 0x1ab0 && code <= 0x1abd) || // Mn  [14] COMBINING DOUBLED CIRCUMFLEX ACCENT..COMBINING PARENTHESES BELOW
      code === 0x1abe || // Me       COMBINING PARENTHESES OVERLAY
      (code >= 0x1b00 && code <= 0x1b03) || // Mn   [4] BALINESE SIGN ULU RICEM..BALINESE SIGN SURANG
      code === 0x1b34 || // Mn       BALINESE SIGN REREKAN
      (code >= 0x1b36 && code <= 0x1b3a) || // Mn   [5] BALINESE VOWEL SIGN ULU..BALINESE VOWEL SIGN RA REPA
      code === 0x1b3c || // Mn       BALINESE VOWEL SIGN LA LENGA
      code === 0x1b42 || // Mn       BALINESE VOWEL SIGN PEPET
      (code >= 0x1b6b && code <= 0x1b73) || // Mn   [9] BALINESE MUSICAL SYMBOL COMBINING TEGEH..BALINESE MUSICAL SYMBOL COMBINING GONG
      (code >= 0x1b80 && code <= 0x1b81) || // Mn   [2] SUNDANESE SIGN PANYECEK..SUNDANESE SIGN PANGLAYAR
      (code >= 0x1ba2 && code <= 0x1ba5) || // Mn   [4] SUNDANESE CONSONANT SIGN PANYAKRA..SUNDANESE VOWEL SIGN PANYUKU
      (code >= 0x1ba8 && code <= 0x1ba9) || // Mn   [2] SUNDANESE VOWEL SIGN PAMEPET..SUNDANESE VOWEL SIGN PANEULEUNG
      (code >= 0x1bab && code <= 0x1bad) || // Mn   [3] SUNDANESE SIGN VIRAMA..SUNDANESE CONSONANT SIGN PASANGAN WA
      code === 0x1be6 || // Mn       BATAK SIGN TOMPI
      (code >= 0x1be8 && code <= 0x1be9) || // Mn   [2] BATAK VOWEL SIGN PAKPAK E..BATAK VOWEL SIGN EE
      code === 0x1bed || // Mn       BATAK VOWEL SIGN KARO O
      (code >= 0x1bef && code <= 0x1bf1) || // Mn   [3] BATAK VOWEL SIGN U FOR SIMALUNGUN SA..BATAK CONSONANT SIGN H
      (code >= 0x1c2c && code <= 0x1c33) || // Mn   [8] LEPCHA VOWEL SIGN E..LEPCHA CONSONANT SIGN T
      (code >= 0x1c36 && code <= 0x1c37) || // Mn   [2] LEPCHA SIGN RAN..LEPCHA SIGN NUKTA
      (code >= 0x1cd0 && code <= 0x1cd2) || // Mn   [3] VEDIC TONE KARSHANA..VEDIC TONE PRENKHA
      (code >= 0x1cd4 && code <= 0x1ce0) || // Mn  [13] VEDIC SIGN YAJURVEDIC MIDLINE SVARITA..VEDIC TONE RIGVEDIC KASHMIRI INDEPENDENT SVARITA
      (code >= 0x1ce2 && code <= 0x1ce8) || // Mn   [7] VEDIC SIGN VISARGA SVARITA..VEDIC SIGN VISARGA ANUDATTA WITH TAIL
      code === 0x1ced || // Mn       VEDIC SIGN TIRYAK
      code === 0x1cf4 || // Mn       VEDIC TONE CANDRA ABOVE
      (code >= 0x1cf8 && code <= 0x1cf9) || // Mn   [2] VEDIC TONE RING ABOVE..VEDIC TONE DOUBLE RING ABOVE
      (code >= 0x1dc0 && code <= 0x1df5) || // Mn  [54] COMBINING DOTTED GRAVE ACCENT..COMBINING UP TACK ABOVE
      (code >= 0x1dfc && code <= 0x1dff) || // Mn   [4] COMBINING DOUBLE INVERTED BREVE BELOW..COMBINING RIGHT ARROWHEAD AND DOWN ARROWHEAD BELOW
      (code >= 0x200c && code <= 0x200d) || // Cf   [2] ZERO WIDTH NON-JOINER..ZERO WIDTH JOINER
      (code >= 0x20d0 && code <= 0x20dc) || // Mn  [13] COMBINING LEFT HARPOON ABOVE..COMBINING FOUR DOTS ABOVE
      (code >= 0x20dd && code <= 0x20e0) || // Me   [4] COMBINING ENCLOSING CIRCLE..COMBINING ENCLOSING CIRCLE BACKSLASH
      code === 0x20e1 || // Mn       COMBINING LEFT RIGHT ARROW ABOVE
      (code >= 0x20e2 && code <= 0x20e4) || // Me   [3] COMBINING ENCLOSING SCREEN..COMBINING ENCLOSING UPWARD POINTING TRIANGLE
      (code >= 0x20e5 && code <= 0x20f0) || // Mn  [12] COMBINING REVERSE SOLIDUS OVERLAY..COMBINING ASTERISK ABOVE
      (code >= 0x2cef && code <= 0x2cf1) || // Mn   [3] COPTIC COMBINING NI ABOVE..COPTIC COMBINING SPIRITUS LENIS
      code === 0x2d7f || // Mn       TIFINAGH CONSONANT JOINER
      (code >= 0x2de0 && code <= 0x2dff) || // Mn  [32] COMBINING CYRILLIC LETTER BE..COMBINING CYRILLIC LETTER IOTIFIED BIG YUS
      (code >= 0x302a && code <= 0x302d) || // Mn   [4] IDEOGRAPHIC LEVEL TONE MARK..IDEOGRAPHIC ENTERING TONE MARK
      (code >= 0x302e && code <= 0x302f) || // Mc   [2] HANGUL SINGLE DOT TONE MARK..HANGUL DOUBLE DOT TONE MARK
      (code >= 0x3099 && code <= 0x309a) || // Mn   [2] COMBINING KATAKANA-HIRAGANA VOICED SOUND MARK..COMBINING KATAKANA-HIRAGANA SEMI-VOICED SOUND MARK
      code === 0xa66f || // Mn       COMBINING CYRILLIC VZMET
      (code >= 0xa670 && code <= 0xa672) || // Me   [3] COMBINING CYRILLIC TEN MILLIONS SIGN..COMBINING CYRILLIC THOUSAND MILLIONS SIGN
      (code >= 0xa674 && code <= 0xa67d) || // Mn  [10] COMBINING CYRILLIC LETTER UKRAINIAN IE..COMBINING CYRILLIC PAYEROK
      (code >= 0xa69e && code <= 0xa69f) || // Mn   [2] COMBINING CYRILLIC LETTER EF..COMBINING CYRILLIC LETTER IOTIFIED E
      (code >= 0xa6f0 && code <= 0xa6f1) || // Mn   [2] BAMUM COMBINING MARK KOQNDON..BAMUM COMBINING MARK TUKWENTIS
      code === 0xa802 || // Mn       SYLOTI NAGRI SIGN DVISVARA
      code === 0xa806 || // Mn       SYLOTI NAGRI SIGN HASANTA
      code === 0xa80b || // Mn       SYLOTI NAGRI SIGN ANUSVARA
      (code >= 0xa825 && code <= 0xa826) || // Mn   [2] SYLOTI NAGRI VOWEL SIGN U..SYLOTI NAGRI VOWEL SIGN E
      code === 0xa8c4 || // Mn       SAURASHTRA SIGN VIRAMA
      (code >= 0xa8e0 && code <= 0xa8f1) || // Mn  [18] COMBINING DEVANAGARI DIGIT ZERO..COMBINING DEVANAGARI SIGN AVAGRAHA
      (code >= 0xa926 && code <= 0xa92d) || // Mn   [8] KAYAH LI VOWEL UE..KAYAH LI TONE CALYA PLOPHU
      (code >= 0xa947 && code <= 0xa951) || // Mn  [11] REJANG VOWEL SIGN I..REJANG CONSONANT SIGN R
      (code >= 0xa980 && code <= 0xa982) || // Mn   [3] JAVANESE SIGN PANYANGGA..JAVANESE SIGN LAYAR
      code === 0xa9b3 || // Mn       JAVANESE SIGN CECAK TELU
      (code >= 0xa9b6 && code <= 0xa9b9) || // Mn   [4] JAVANESE VOWEL SIGN WULU..JAVANESE VOWEL SIGN SUKU MENDUT
      code === 0xa9bc || // Mn       JAVANESE VOWEL SIGN PEPET
      code === 0xa9e5 || // Mn       MYANMAR SIGN SHAN SAW
      (code >= 0xaa29 && code <= 0xaa2e) || // Mn   [6] CHAM VOWEL SIGN AA..CHAM VOWEL SIGN OE
      (code >= 0xaa31 && code <= 0xaa32) || // Mn   [2] CHAM VOWEL SIGN AU..CHAM VOWEL SIGN UE
      (code >= 0xaa35 && code <= 0xaa36) || // Mn   [2] CHAM CONSONANT SIGN LA..CHAM CONSONANT SIGN WA
      code === 0xaa43 || // Mn       CHAM CONSONANT SIGN FINAL NG
      code === 0xaa4c || // Mn       CHAM CONSONANT SIGN FINAL M
      code === 0xaa7c || // Mn       MYANMAR SIGN TAI LAING TONE-2
      code === 0xaab0 || // Mn       TAI VIET MAI KANG
      (code >= 0xaab2 && code <= 0xaab4) || // Mn   [3] TAI VIET VOWEL I..TAI VIET VOWEL U
      (code >= 0xaab7 && code <= 0xaab8) || // Mn   [2] TAI VIET MAI KHIT..TAI VIET VOWEL IA
      (code >= 0xaabe && code <= 0xaabf) || // Mn   [2] TAI VIET VOWEL AM..TAI VIET TONE MAI EK
      code === 0xaac1 || // Mn       TAI VIET TONE MAI THO
      (code >= 0xaaec && code <= 0xaaed) || // Mn   [2] MEETEI MAYEK VOWEL SIGN UU..MEETEI MAYEK VOWEL SIGN AAI
      code === 0xaaf6 || // Mn       MEETEI MAYEK VIRAMA
      code === 0xabe5 || // Mn       MEETEI MAYEK VOWEL SIGN ANAP
      code === 0xabe8 || // Mn       MEETEI MAYEK VOWEL SIGN UNAP
      code === 0xabed || // Mn       MEETEI MAYEK APUN IYEK
      code === 0xfb1e || // Mn       HEBREW POINT JUDEO-SPANISH VARIKA
      (code >= 0xfe00 && code <= 0xfe0f) || // Mn  [16] VARIATION SELECTOR-1..VARIATION SELECTOR-16
      (code >= 0xfe20 && code <= 0xfe2f) || // Mn  [16] COMBINING LIGATURE LEFT HALF..COMBINING CYRILLIC TITLO RIGHT HALF
      (code >= 0xff9e && code <= 0xff9f) || // Lm   [2] HALFWIDTH KATAKANA VOICED SOUND MARK..HALFWIDTH KATAKANA SEMI-VOICED SOUND MARK
      code === 0x101fd || // Mn       PHAISTOS DISC SIGN COMBINING OBLIQUE STROKE
      code === 0x102e0 || // Mn       COPTIC EPACT THOUSANDS MARK
      (code >= 0x10376 && code <= 0x1037a) || // Mn   [5] COMBINING OLD PERMIC LETTER AN..COMBINING OLD PERMIC LETTER SII
      (code >= 0x10a01 && code <= 0x10a03) || // Mn   [3] KHAROSHTHI VOWEL SIGN I..KHAROSHTHI VOWEL SIGN VOCALIC R
      (code >= 0x10a05 && code <= 0x10a06) || // Mn   [2] KHAROSHTHI VOWEL SIGN E..KHAROSHTHI VOWEL SIGN O
      (code >= 0x10a0c && code <= 0x10a0f) || // Mn   [4] KHAROSHTHI VOWEL LENGTH MARK..KHAROSHTHI SIGN VISARGA
      (code >= 0x10a38 && code <= 0x10a3a) || // Mn   [3] KHAROSHTHI SIGN BAR ABOVE..KHAROSHTHI SIGN DOT BELOW
      code === 0x10a3f || // Mn       KHAROSHTHI VIRAMA
      (code >= 0x10ae5 && code <= 0x10ae6) || // Mn   [2] MANICHAEAN ABBREVIATION MARK ABOVE..MANICHAEAN ABBREVIATION MARK BELOW
      code === 0x11001 || // Mn       BRAHMI SIGN ANUSVARA
      (code >= 0x11038 && code <= 0x11046) || // Mn  [15] BRAHMI VOWEL SIGN AA..BRAHMI VIRAMA
      (code >= 0x1107f && code <= 0x11081) || // Mn   [3] BRAHMI NUMBER JOINER..KAITHI SIGN ANUSVARA
      (code >= 0x110b3 && code <= 0x110b6) || // Mn   [4] KAITHI VOWEL SIGN U..KAITHI VOWEL SIGN AI
      (code >= 0x110b9 && code <= 0x110ba) || // Mn   [2] KAITHI SIGN VIRAMA..KAITHI SIGN NUKTA
      (code >= 0x11100 && code <= 0x11102) || // Mn   [3] CHAKMA SIGN CANDRABINDU..CHAKMA SIGN VISARGA
      (code >= 0x11127 && code <= 0x1112b) || // Mn   [5] CHAKMA VOWEL SIGN A..CHAKMA VOWEL SIGN UU
      (code >= 0x1112d && code <= 0x11134) || // Mn   [8] CHAKMA VOWEL SIGN AI..CHAKMA MAAYYAA
      code === 0x11173 || // Mn       MAHAJANI SIGN NUKTA
      (code >= 0x11180 && code <= 0x11181) || // Mn   [2] SHARADA SIGN CANDRABINDU..SHARADA SIGN ANUSVARA
      (code >= 0x111b6 && code <= 0x111be) || // Mn   [9] SHARADA VOWEL SIGN U..SHARADA VOWEL SIGN O
      (code >= 0x111ca && code <= 0x111cc) || // Mn   [3] SHARADA SIGN NUKTA..SHARADA EXTRA SHORT VOWEL MARK
      (code >= 0x1122f && code <= 0x11231) || // Mn   [3] KHOJKI VOWEL SIGN U..KHOJKI VOWEL SIGN AI
      code === 0x11234 || // Mn       KHOJKI SIGN ANUSVARA
      (code >= 0x11236 && code <= 0x11237) || // Mn   [2] KHOJKI SIGN NUKTA..KHOJKI SIGN SHADDA
      code === 0x112df || // Mn       KHUDAWADI SIGN ANUSVARA
      (code >= 0x112e3 && code <= 0x112ea) || // Mn   [8] KHUDAWADI VOWEL SIGN U..KHUDAWADI SIGN VIRAMA
      (code >= 0x11300 && code <= 0x11301) || // Mn   [2] GRANTHA SIGN COMBINING ANUSVARA ABOVE..GRANTHA SIGN CANDRABINDU
      code === 0x1133c || // Mn       GRANTHA SIGN NUKTA
      code === 0x1133e || // Mc       GRANTHA VOWEL SIGN AA
      code === 0x11340 || // Mn       GRANTHA VOWEL SIGN II
      code === 0x11357 || // Mc       GRANTHA AU LENGTH MARK
      (code >= 0x11366 && code <= 0x1136c) || // Mn   [7] COMBINING GRANTHA DIGIT ZERO..COMBINING GRANTHA DIGIT SIX
      (code >= 0x11370 && code <= 0x11374) || // Mn   [5] COMBINING GRANTHA LETTER A..COMBINING GRANTHA LETTER PA
      code === 0x114b0 || // Mc       TIRHUTA VOWEL SIGN AA
      (code >= 0x114b3 && code <= 0x114b8) || // Mn   [6] TIRHUTA VOWEL SIGN U..TIRHUTA VOWEL SIGN VOCALIC LL
      code === 0x114ba || // Mn       TIRHUTA VOWEL SIGN SHORT E
      code === 0x114bd || // Mc       TIRHUTA VOWEL SIGN SHORT O
      (code >= 0x114bf && code <= 0x114c0) || // Mn   [2] TIRHUTA SIGN CANDRABINDU..TIRHUTA SIGN ANUSVARA
      (code >= 0x114c2 && code <= 0x114c3) || // Mn   [2] TIRHUTA SIGN VIRAMA..TIRHUTA SIGN NUKTA
      code === 0x115af || // Mc       SIDDHAM VOWEL SIGN AA
      (code >= 0x115b2 && code <= 0x115b5) || // Mn   [4] SIDDHAM VOWEL SIGN U..SIDDHAM VOWEL SIGN VOCALIC RR
      (code >= 0x115bc && code <= 0x115bd) || // Mn   [2] SIDDHAM SIGN CANDRABINDU..SIDDHAM SIGN ANUSVARA
      (code >= 0x115bf && code <= 0x115c0) || // Mn   [2] SIDDHAM SIGN VIRAMA..SIDDHAM SIGN NUKTA
      (code >= 0x115dc && code <= 0x115dd) || // Mn   [2] SIDDHAM VOWEL SIGN ALTERNATE U..SIDDHAM VOWEL SIGN ALTERNATE UU
      (code >= 0x11633 && code <= 0x1163a) || // Mn   [8] MODI VOWEL SIGN U..MODI VOWEL SIGN AI
      code === 0x1163d || // Mn       MODI SIGN ANUSVARA
      (code >= 0x1163f && code <= 0x11640) || // Mn   [2] MODI SIGN VIRAMA..MODI SIGN ARDHACANDRA
      code === 0x116ab || // Mn       TAKRI SIGN ANUSVARA
      code === 0x116ad || // Mn       TAKRI VOWEL SIGN AA
      (code >= 0x116b0 && code <= 0x116b5) || // Mn   [6] TAKRI VOWEL SIGN U..TAKRI VOWEL SIGN AU
      code === 0x116b7 || // Mn       TAKRI SIGN NUKTA
      (code >= 0x1171d && code <= 0x1171f) || // Mn   [3] AHOM CONSONANT SIGN MEDIAL LA..AHOM CONSONANT SIGN MEDIAL LIGATING RA
      (code >= 0x11722 && code <= 0x11725) || // Mn   [4] AHOM VOWEL SIGN I..AHOM VOWEL SIGN UU
      (code >= 0x11727 && code <= 0x1172b) || // Mn   [5] AHOM VOWEL SIGN AW..AHOM SIGN KILLER
      (code >= 0x16af0 && code <= 0x16af4) || // Mn   [5] BASSA VAH COMBINING HIGH TONE..BASSA VAH COMBINING HIGH-LOW TONE
      (code >= 0x16b30 && code <= 0x16b36) || // Mn   [7] PAHAWH HMONG MARK CIM TUB..PAHAWH HMONG MARK CIM TAUM
      (code >= 0x16f8f && code <= 0x16f92) || // Mn   [4] MIAO TONE RIGHT..MIAO TONE BELOW
      (code >= 0x1bc9d && code <= 0x1bc9e) || // Mn   [2] DUPLOYAN THICK LETTER SELECTOR..DUPLOYAN DOUBLE MARK
      code === 0x1d165 || // Mc       MUSICAL SYMBOL COMBINING STEM
      (code >= 0x1d167 && code <= 0x1d169) || // Mn   [3] MUSICAL SYMBOL COMBINING TREMOLO-1..MUSICAL SYMBOL COMBINING TREMOLO-3
      (code >= 0x1d16e && code <= 0x1d172) || // Mc   [5] MUSICAL SYMBOL COMBINING FLAG-1..MUSICAL SYMBOL COMBINING FLAG-5
      (code >= 0x1d17b && code <= 0x1d182) || // Mn   [8] MUSICAL SYMBOL COMBINING ACCENT..MUSICAL SYMBOL COMBINING LOURE
      (code >= 0x1d185 && code <= 0x1d18b) || // Mn   [7] MUSICAL SYMBOL COMBINING DOIT..MUSICAL SYMBOL COMBINING TRIPLE TONGUE
      (code >= 0x1d1aa && code <= 0x1d1ad) || // Mn   [4] MUSICAL SYMBOL COMBINING DOWN BOW..MUSICAL SYMBOL COMBINING SNAP PIZZICATO
      (code >= 0x1d242 && code <= 0x1d244) || // Mn   [3] COMBINING GREEK MUSICAL TRISEME..COMBINING GREEK MUSICAL PENTASEME
      (code >= 0x1da00 && code <= 0x1da36) || // Mn  [55] SIGNWRITING HEAD RIM..SIGNWRITING AIR SUCKING IN
      (code >= 0x1da3b && code <= 0x1da6c) || // Mn  [50] SIGNWRITING MOUTH CLOSED NEUTRAL..SIGNWRITING EXCITEMENT
      code === 0x1da75 || // Mn       SIGNWRITING UPPER BODY TILTING FROM HIP JOINTS
      code === 0x1da84 || // Mn       SIGNWRITING LOCATION HEAD NECK
      (code >= 0x1da9b && code <= 0x1da9f) || // Mn   [5] SIGNWRITING FILL MODIFIER-2..SIGNWRITING FILL MODIFIER-6
      (code >= 0x1daa1 && code <= 0x1daaf) || // Mn  [15] SIGNWRITING ROTATION MODIFIER-2..SIGNWRITING ROTATION MODIFIER-16
      (code >= 0x1e8d0 && code <= 0x1e8d6) || // Mn   [7] MENDE KIKAKUI COMBINING NUMBER TEENS..MENDE KIKAKUI COMBINING NUMBER MILLIONS
      (code >= 0xe0100 && code <= 0xe01ef) // Mn [240] VARIATION SELECTOR-17..VARIATION SELECTOR-256
    ) {
      return Extend;
    }

    if (
      code >= 0x1f1e6 &&
      code <= 0x1f1ff // So  [26] REGIONAL INDICATOR SYMBOL LETTER A..REGIONAL INDICATOR SYMBOL LETTER Z
    ) {
      return Regional_Indicator;
    }

    if (
      code === 0x0903 || // Mc       DEVANAGARI SIGN VISARGA
      code === 0x093b || // Mc       DEVANAGARI VOWEL SIGN OOE
      (code >= 0x093e && code <= 0x0940) || // Mc   [3] DEVANAGARI VOWEL SIGN AA..DEVANAGARI VOWEL SIGN II
      (code >= 0x0949 && code <= 0x094c) || // Mc   [4] DEVANAGARI VOWEL SIGN CANDRA O..DEVANAGARI VOWEL SIGN AU
      (code >= 0x094e && code <= 0x094f) || // Mc   [2] DEVANAGARI VOWEL SIGN PRISHTHAMATRA E..DEVANAGARI VOWEL SIGN AW
      (code >= 0x0982 && code <= 0x0983) || // Mc   [2] BENGALI SIGN ANUSVARA..BENGALI SIGN VISARGA
      (code >= 0x09bf && code <= 0x09c0) || // Mc   [2] BENGALI VOWEL SIGN I..BENGALI VOWEL SIGN II
      (code >= 0x09c7 && code <= 0x09c8) || // Mc   [2] BENGALI VOWEL SIGN E..BENGALI VOWEL SIGN AI
      (code >= 0x09cb && code <= 0x09cc) || // Mc   [2] BENGALI VOWEL SIGN O..BENGALI VOWEL SIGN AU
      code === 0x0a03 || // Mc       GURMUKHI SIGN VISARGA
      (code >= 0x0a3e && code <= 0x0a40) || // Mc   [3] GURMUKHI VOWEL SIGN AA..GURMUKHI VOWEL SIGN II
      code === 0x0a83 || // Mc       GUJARATI SIGN VISARGA
      (code >= 0x0abe && code <= 0x0ac0) || // Mc   [3] GUJARATI VOWEL SIGN AA..GUJARATI VOWEL SIGN II
      code === 0x0ac9 || // Mc       GUJARATI VOWEL SIGN CANDRA O
      (code >= 0x0acb && code <= 0x0acc) || // Mc   [2] GUJARATI VOWEL SIGN O..GUJARATI VOWEL SIGN AU
      (code >= 0x0b02 && code <= 0x0b03) || // Mc   [2] ORIYA SIGN ANUSVARA..ORIYA SIGN VISARGA
      code === 0x0b40 || // Mc       ORIYA VOWEL SIGN II
      (code >= 0x0b47 && code <= 0x0b48) || // Mc   [2] ORIYA VOWEL SIGN E..ORIYA VOWEL SIGN AI
      (code >= 0x0b4b && code <= 0x0b4c) || // Mc   [2] ORIYA VOWEL SIGN O..ORIYA VOWEL SIGN AU
      code === 0x0bbf || // Mc       TAMIL VOWEL SIGN I
      (code >= 0x0bc1 && code <= 0x0bc2) || // Mc   [2] TAMIL VOWEL SIGN U..TAMIL VOWEL SIGN UU
      (code >= 0x0bc6 && code <= 0x0bc8) || // Mc   [3] TAMIL VOWEL SIGN E..TAMIL VOWEL SIGN AI
      (code >= 0x0bca && code <= 0x0bcc) || // Mc   [3] TAMIL VOWEL SIGN O..TAMIL VOWEL SIGN AU
      (code >= 0x0c01 && code <= 0x0c03) || // Mc   [3] TELUGU SIGN CANDRABINDU..TELUGU SIGN VISARGA
      (code >= 0x0c41 && code <= 0x0c44) || // Mc   [4] TELUGU VOWEL SIGN U..TELUGU VOWEL SIGN VOCALIC RR
      (code >= 0x0c82 && code <= 0x0c83) || // Mc   [2] KANNADA SIGN ANUSVARA..KANNADA SIGN VISARGA
      code === 0x0cbe || // Mc       KANNADA VOWEL SIGN AA
      (code >= 0x0cc0 && code <= 0x0cc1) || // Mc   [2] KANNADA VOWEL SIGN II..KANNADA VOWEL SIGN U
      (code >= 0x0cc3 && code <= 0x0cc4) || // Mc   [2] KANNADA VOWEL SIGN VOCALIC R..KANNADA VOWEL SIGN VOCALIC RR
      (code >= 0x0cc7 && code <= 0x0cc8) || // Mc   [2] KANNADA VOWEL SIGN EE..KANNADA VOWEL SIGN AI
      (code >= 0x0cca && code <= 0x0ccb) || // Mc   [2] KANNADA VOWEL SIGN O..KANNADA VOWEL SIGN OO
      (code >= 0x0d02 && code <= 0x0d03) || // Mc   [2] MALAYALAM SIGN ANUSVARA..MALAYALAM SIGN VISARGA
      (code >= 0x0d3f && code <= 0x0d40) || // Mc   [2] MALAYALAM VOWEL SIGN I..MALAYALAM VOWEL SIGN II
      (code >= 0x0d46 && code <= 0x0d48) || // Mc   [3] MALAYALAM VOWEL SIGN E..MALAYALAM VOWEL SIGN AI
      (code >= 0x0d4a && code <= 0x0d4c) || // Mc   [3] MALAYALAM VOWEL SIGN O..MALAYALAM VOWEL SIGN AU
      (code >= 0x0d82 && code <= 0x0d83) || // Mc   [2] SINHALA SIGN ANUSVARAYA..SINHALA SIGN VISARGAYA
      (code >= 0x0dd0 && code <= 0x0dd1) || // Mc   [2] SINHALA VOWEL SIGN KETTI AEDA-PILLA..SINHALA VOWEL SIGN DIGA AEDA-PILLA
      (code >= 0x0dd8 && code <= 0x0dde) || // Mc   [7] SINHALA VOWEL SIGN GAETTA-PILLA..SINHALA VOWEL SIGN KOMBUVA HAA GAYANUKITTA
      (code >= 0x0df2 && code <= 0x0df3) || // Mc   [2] SINHALA VOWEL SIGN DIGA GAETTA-PILLA..SINHALA VOWEL SIGN DIGA GAYANUKITTA
      code === 0x0e33 || // Lo       THAI CHARACTER SARA AM
      code === 0x0eb3 || // Lo       LAO VOWEL SIGN AM
      (code >= 0x0f3e && code <= 0x0f3f) || // Mc   [2] TIBETAN SIGN YAR TSHES..TIBETAN SIGN MAR TSHES
      code === 0x0f7f || // Mc       TIBETAN SIGN RNAM BCAD
      code === 0x1031 || // Mc       MYANMAR VOWEL SIGN E
      (code >= 0x103b && code <= 0x103c) || // Mc   [2] MYANMAR CONSONANT SIGN MEDIAL YA..MYANMAR CONSONANT SIGN MEDIAL RA
      (code >= 0x1056 && code <= 0x1057) || // Mc   [2] MYANMAR VOWEL SIGN VOCALIC R..MYANMAR VOWEL SIGN VOCALIC RR
      code === 0x1084 || // Mc       MYANMAR VOWEL SIGN SHAN E
      code === 0x17b6 || // Mc       KHMER VOWEL SIGN AA
      (code >= 0x17be && code <= 0x17c5) || // Mc   [8] KHMER VOWEL SIGN OE..KHMER VOWEL SIGN AU
      (code >= 0x17c7 && code <= 0x17c8) || // Mc   [2] KHMER SIGN REAHMUK..KHMER SIGN YUUKALEAPINTU
      (code >= 0x1923 && code <= 0x1926) || // Mc   [4] LIMBU VOWEL SIGN EE..LIMBU VOWEL SIGN AU
      (code >= 0x1929 && code <= 0x192b) || // Mc   [3] LIMBU SUBJOINED LETTER YA..LIMBU SUBJOINED LETTER WA
      (code >= 0x1930 && code <= 0x1931) || // Mc   [2] LIMBU SMALL LETTER KA..LIMBU SMALL LETTER NGA
      (code >= 0x1933 && code <= 0x1938) || // Mc   [6] LIMBU SMALL LETTER TA..LIMBU SMALL LETTER LA
      (code >= 0x1a19 && code <= 0x1a1a) || // Mc   [2] BUGINESE VOWEL SIGN E..BUGINESE VOWEL SIGN O
      code === 0x1a55 || // Mc       TAI THAM CONSONANT SIGN MEDIAL RA
      code === 0x1a57 || // Mc       TAI THAM CONSONANT SIGN LA TANG LAI
      (code >= 0x1a6d && code <= 0x1a72) || // Mc   [6] TAI THAM VOWEL SIGN OY..TAI THAM VOWEL SIGN THAM AI
      code === 0x1b04 || // Mc       BALINESE SIGN BISAH
      code === 0x1b35 || // Mc       BALINESE VOWEL SIGN TEDUNG
      code === 0x1b3b || // Mc       BALINESE VOWEL SIGN RA REPA TEDUNG
      (code >= 0x1b3d && code <= 0x1b41) || // Mc   [5] BALINESE VOWEL SIGN LA LENGA TEDUNG..BALINESE VOWEL SIGN TALING REPA TEDUNG
      (code >= 0x1b43 && code <= 0x1b44) || // Mc   [2] BALINESE VOWEL SIGN PEPET TEDUNG..BALINESE ADEG ADEG
      code === 0x1b82 || // Mc       SUNDANESE SIGN PANGWISAD
      code === 0x1ba1 || // Mc       SUNDANESE CONSONANT SIGN PAMINGKAL
      (code >= 0x1ba6 && code <= 0x1ba7) || // Mc   [2] SUNDANESE VOWEL SIGN PANAELAENG..SUNDANESE VOWEL SIGN PANOLONG
      code === 0x1baa || // Mc       SUNDANESE SIGN PAMAAEH
      code === 0x1be7 || // Mc       BATAK VOWEL SIGN E
      (code >= 0x1bea && code <= 0x1bec) || // Mc   [3] BATAK VOWEL SIGN I..BATAK VOWEL SIGN O
      code === 0x1bee || // Mc       BATAK VOWEL SIGN U
      (code >= 0x1bf2 && code <= 0x1bf3) || // Mc   [2] BATAK PANGOLAT..BATAK PANONGONAN
      (code >= 0x1c24 && code <= 0x1c2b) || // Mc   [8] LEPCHA SUBJOINED LETTER YA..LEPCHA VOWEL SIGN UU
      (code >= 0x1c34 && code <= 0x1c35) || // Mc   [2] LEPCHA CONSONANT SIGN NYIN-DO..LEPCHA CONSONANT SIGN KANG
      code === 0x1ce1 || // Mc       VEDIC TONE ATHARVAVEDIC INDEPENDENT SVARITA
      (code >= 0x1cf2 && code <= 0x1cf3) || // Mc   [2] VEDIC SIGN ARDHAVISARGA..VEDIC SIGN ROTATED ARDHAVISARGA
      (code >= 0xa823 && code <= 0xa824) || // Mc   [2] SYLOTI NAGRI VOWEL SIGN A..SYLOTI NAGRI VOWEL SIGN I
      code === 0xa827 || // Mc       SYLOTI NAGRI VOWEL SIGN OO
      (code >= 0xa880 && code <= 0xa881) || // Mc   [2] SAURASHTRA SIGN ANUSVARA..SAURASHTRA SIGN VISARGA
      (code >= 0xa8b4 && code <= 0xa8c3) || // Mc  [16] SAURASHTRA CONSONANT SIGN HAARU..SAURASHTRA VOWEL SIGN AU
      (code >= 0xa952 && code <= 0xa953) || // Mc   [2] REJANG CONSONANT SIGN H..REJANG VIRAMA
      code === 0xa983 || // Mc       JAVANESE SIGN WIGNYAN
      (code >= 0xa9b4 && code <= 0xa9b5) || // Mc   [2] JAVANESE VOWEL SIGN TARUNG..JAVANESE VOWEL SIGN TOLONG
      (code >= 0xa9ba && code <= 0xa9bb) || // Mc   [2] JAVANESE VOWEL SIGN TALING..JAVANESE VOWEL SIGN DIRGA MURE
      (code >= 0xa9bd && code <= 0xa9c0) || // Mc   [4] JAVANESE CONSONANT SIGN KERET..JAVANESE PANGKON
      (code >= 0xaa2f && code <= 0xaa30) || // Mc   [2] CHAM VOWEL SIGN O..CHAM VOWEL SIGN AI
      (code >= 0xaa33 && code <= 0xaa34) || // Mc   [2] CHAM CONSONANT SIGN YA..CHAM CONSONANT SIGN RA
      code === 0xaa4d || // Mc       CHAM CONSONANT SIGN FINAL H
      code === 0xaaeb || // Mc       MEETEI MAYEK VOWEL SIGN II
      (code >= 0xaaee && code <= 0xaaef) || // Mc   [2] MEETEI MAYEK VOWEL SIGN AU..MEETEI MAYEK VOWEL SIGN AAU
      code === 0xaaf5 || // Mc       MEETEI MAYEK VOWEL SIGN VISARGA
      (code >= 0xabe3 && code <= 0xabe4) || // Mc   [2] MEETEI MAYEK VOWEL SIGN ONAP..MEETEI MAYEK VOWEL SIGN INAP
      (code >= 0xabe6 && code <= 0xabe7) || // Mc   [2] MEETEI MAYEK VOWEL SIGN YENAP..MEETEI MAYEK VOWEL SIGN SOUNAP
      (code >= 0xabe9 && code <= 0xabea) || // Mc   [2] MEETEI MAYEK VOWEL SIGN CHEINAP..MEETEI MAYEK VOWEL SIGN NUNG
      code === 0xabec || // Mc       MEETEI MAYEK LUM IYEK
      code === 0x11000 || // Mc       BRAHMI SIGN CANDRABINDU
      code === 0x11002 || // Mc       BRAHMI SIGN VISARGA
      code === 0x11082 || // Mc       KAITHI SIGN VISARGA
      (code >= 0x110b0 && code <= 0x110b2) || // Mc   [3] KAITHI VOWEL SIGN AA..KAITHI VOWEL SIGN II
      (code >= 0x110b7 && code <= 0x110b8) || // Mc   [2] KAITHI VOWEL SIGN O..KAITHI VOWEL SIGN AU
      code === 0x1112c || // Mc       CHAKMA VOWEL SIGN E
      code === 0x11182 || // Mc       SHARADA SIGN VISARGA
      (code >= 0x111b3 && code <= 0x111b5) || // Mc   [3] SHARADA VOWEL SIGN AA..SHARADA VOWEL SIGN II
      (code >= 0x111bf && code <= 0x111c0) || // Mc   [2] SHARADA VOWEL SIGN AU..SHARADA SIGN VIRAMA
      (code >= 0x1122c && code <= 0x1122e) || // Mc   [3] KHOJKI VOWEL SIGN AA..KHOJKI VOWEL SIGN II
      (code >= 0x11232 && code <= 0x11233) || // Mc   [2] KHOJKI VOWEL SIGN O..KHOJKI VOWEL SIGN AU
      code === 0x11235 || // Mc       KHOJKI SIGN VIRAMA
      (code >= 0x112e0 && code <= 0x112e2) || // Mc   [3] KHUDAWADI VOWEL SIGN AA..KHUDAWADI VOWEL SIGN II
      (code >= 0x11302 && code <= 0x11303) || // Mc   [2] GRANTHA SIGN ANUSVARA..GRANTHA SIGN VISARGA
      code === 0x1133f || // Mc       GRANTHA VOWEL SIGN I
      (code >= 0x11341 && code <= 0x11344) || // Mc   [4] GRANTHA VOWEL SIGN U..GRANTHA VOWEL SIGN VOCALIC RR
      (code >= 0x11347 && code <= 0x11348) || // Mc   [2] GRANTHA VOWEL SIGN EE..GRANTHA VOWEL SIGN AI
      (code >= 0x1134b && code <= 0x1134d) || // Mc   [3] GRANTHA VOWEL SIGN OO..GRANTHA SIGN VIRAMA
      (code >= 0x11362 && code <= 0x11363) || // Mc   [2] GRANTHA VOWEL SIGN VOCALIC L..GRANTHA VOWEL SIGN VOCALIC LL
      (code >= 0x114b1 && code <= 0x114b2) || // Mc   [2] TIRHUTA VOWEL SIGN I..TIRHUTA VOWEL SIGN II
      code === 0x114b9 || // Mc       TIRHUTA VOWEL SIGN E
      (code >= 0x114bb && code <= 0x114bc) || // Mc   [2] TIRHUTA VOWEL SIGN AI..TIRHUTA VOWEL SIGN O
      code === 0x114be || // Mc       TIRHUTA VOWEL SIGN AU
      code === 0x114c1 || // Mc       TIRHUTA SIGN VISARGA
      (code >= 0x115b0 && code <= 0x115b1) || // Mc   [2] SIDDHAM VOWEL SIGN I..SIDDHAM VOWEL SIGN II
      (code >= 0x115b8 && code <= 0x115bb) || // Mc   [4] SIDDHAM VOWEL SIGN E..SIDDHAM VOWEL SIGN AU
      code === 0x115be || // Mc       SIDDHAM SIGN VISARGA
      (code >= 0x11630 && code <= 0x11632) || // Mc   [3] MODI VOWEL SIGN AA..MODI VOWEL SIGN II
      (code >= 0x1163b && code <= 0x1163c) || // Mc   [2] MODI VOWEL SIGN O..MODI VOWEL SIGN AU
      code === 0x1163e || // Mc       MODI SIGN VISARGA
      code === 0x116ac || // Mc       TAKRI SIGN VISARGA
      (code >= 0x116ae && code <= 0x116af) || // Mc   [2] TAKRI VOWEL SIGN I..TAKRI VOWEL SIGN II
      code === 0x116b6 || // Mc       TAKRI SIGN VIRAMA
      (code >= 0x11720 && code <= 0x11721) || // Mc   [2] AHOM VOWEL SIGN A..AHOM VOWEL SIGN AA
      code === 0x11726 || // Mc       AHOM VOWEL SIGN E
      (code >= 0x16f51 && code <= 0x16f7e) || // Mc  [46] MIAO SIGN ASPIRATION..MIAO VOWEL SIGN NG
      code === 0x1d166 || // Mc       MUSICAL SYMBOL COMBINING SPRECHGESANG STEM
      code === 0x1d16d // Mc       MUSICAL SYMBOL COMBINING AUGMENTATION DOT
    ) {
      return SpacingMark;
    }

    if (
      (code >= 0x1100 && code <= 0x115f) || // Lo  [96] HANGUL CHOSEONG KIYEOK..HANGUL CHOSEONG FILLER
      (code >= 0xa960 && code <= 0xa97c) // Lo  [29] HANGUL CHOSEONG TIKEUT-MIEUM..HANGUL CHOSEONG SSANGYEORINHIEUH
    ) {
      return L;
    }

    if (
      (code >= 0x1160 && code <= 0x11a7) || // Lo  [72] HANGUL JUNGSEONG FILLER..HANGUL JUNGSEONG O-YAE
      (code >= 0xd7b0 && code <= 0xd7c6) // Lo  [23] HANGUL JUNGSEONG O-YEO..HANGUL JUNGSEONG ARAEA-E
    ) {
      return V;
    }

    if (
      (code >= 0x11a8 && code <= 0x11ff) || // Lo  [88] HANGUL JONGSEONG KIYEOK..HANGUL JONGSEONG SSANGNIEUN
      (code >= 0xd7cb && code <= 0xd7fb) // Lo  [49] HANGUL JONGSEONG NIEUN-RIEUL..HANGUL JONGSEONG PHIEUPH-THIEUTH
    ) {
      return T;
    }

    if (
      code === 0xac00 || // Lo       HANGUL SYLLABLE GA
      code === 0xac1c || // Lo       HANGUL SYLLABLE GAE
      code === 0xac38 || // Lo       HANGUL SYLLABLE GYA
      code === 0xac54 || // Lo       HANGUL SYLLABLE GYAE
      code === 0xac70 || // Lo       HANGUL SYLLABLE GEO
      code === 0xac8c || // Lo       HANGUL SYLLABLE GE
      code === 0xaca8 || // Lo       HANGUL SYLLABLE GYEO
      code === 0xacc4 || // Lo       HANGUL SYLLABLE GYE
      code === 0xace0 || // Lo       HANGUL SYLLABLE GO
      code === 0xacfc || // Lo       HANGUL SYLLABLE GWA
      code === 0xad18 || // Lo       HANGUL SYLLABLE GWAE
      code === 0xad34 || // Lo       HANGUL SYLLABLE GOE
      code === 0xad50 || // Lo       HANGUL SYLLABLE GYO
      code === 0xad6c || // Lo       HANGUL SYLLABLE GU
      code === 0xad88 || // Lo       HANGUL SYLLABLE GWEO
      code === 0xada4 || // Lo       HANGUL SYLLABLE GWE
      code === 0xadc0 || // Lo       HANGUL SYLLABLE GWI
      code === 0xaddc || // Lo       HANGUL SYLLABLE GYU
      code === 0xadf8 || // Lo       HANGUL SYLLABLE GEU
      code === 0xae14 || // Lo       HANGUL SYLLABLE GYI
      code === 0xae30 || // Lo       HANGUL SYLLABLE GI
      code === 0xae4c || // Lo       HANGUL SYLLABLE GGA
      code === 0xae68 || // Lo       HANGUL SYLLABLE GGAE
      code === 0xae84 || // Lo       HANGUL SYLLABLE GGYA
      code === 0xaea0 || // Lo       HANGUL SYLLABLE GGYAE
      code === 0xaebc || // Lo       HANGUL SYLLABLE GGEO
      code === 0xaed8 || // Lo       HANGUL SYLLABLE GGE
      code === 0xaef4 || // Lo       HANGUL SYLLABLE GGYEO
      code === 0xaf10 || // Lo       HANGUL SYLLABLE GGYE
      code === 0xaf2c || // Lo       HANGUL SYLLABLE GGO
      code === 0xaf48 || // Lo       HANGUL SYLLABLE GGWA
      code === 0xaf64 || // Lo       HANGUL SYLLABLE GGWAE
      code === 0xaf80 || // Lo       HANGUL SYLLABLE GGOE
      code === 0xaf9c || // Lo       HANGUL SYLLABLE GGYO
      code === 0xafb8 || // Lo       HANGUL SYLLABLE GGU
      code === 0xafd4 || // Lo       HANGUL SYLLABLE GGWEO
      code === 0xaff0 || // Lo       HANGUL SYLLABLE GGWE
      code === 0xb00c || // Lo       HANGUL SYLLABLE GGWI
      code === 0xb028 || // Lo       HANGUL SYLLABLE GGYU
      code === 0xb044 || // Lo       HANGUL SYLLABLE GGEU
      code === 0xb060 || // Lo       HANGUL SYLLABLE GGYI
      code === 0xb07c || // Lo       HANGUL SYLLABLE GGI
      code === 0xb098 || // Lo       HANGUL SYLLABLE NA
      code === 0xb0b4 || // Lo       HANGUL SYLLABLE NAE
      code === 0xb0d0 || // Lo       HANGUL SYLLABLE NYA
      code === 0xb0ec || // Lo       HANGUL SYLLABLE NYAE
      code === 0xb108 || // Lo       HANGUL SYLLABLE NEO
      code === 0xb124 || // Lo       HANGUL SYLLABLE NE
      code === 0xb140 || // Lo       HANGUL SYLLABLE NYEO
      code === 0xb15c || // Lo       HANGUL SYLLABLE NYE
      code === 0xb178 || // Lo       HANGUL SYLLABLE NO
      code === 0xb194 || // Lo       HANGUL SYLLABLE NWA
      code === 0xb1b0 || // Lo       HANGUL SYLLABLE NWAE
      code === 0xb1cc || // Lo       HANGUL SYLLABLE NOE
      code === 0xb1e8 || // Lo       HANGUL SYLLABLE NYO
      code === 0xb204 || // Lo       HANGUL SYLLABLE NU
      code === 0xb220 || // Lo       HANGUL SYLLABLE NWEO
      code === 0xb23c || // Lo       HANGUL SYLLABLE NWE
      code === 0xb258 || // Lo       HANGUL SYLLABLE NWI
      code === 0xb274 || // Lo       HANGUL SYLLABLE NYU
      code === 0xb290 || // Lo       HANGUL SYLLABLE NEU
      code === 0xb2ac || // Lo       HANGUL SYLLABLE NYI
      code === 0xb2c8 || // Lo       HANGUL SYLLABLE NI
      code === 0xb2e4 || // Lo       HANGUL SYLLABLE DA
      code === 0xb300 || // Lo       HANGUL SYLLABLE DAE
      code === 0xb31c || // Lo       HANGUL SYLLABLE DYA
      code === 0xb338 || // Lo       HANGUL SYLLABLE DYAE
      code === 0xb354 || // Lo       HANGUL SYLLABLE DEO
      code === 0xb370 || // Lo       HANGUL SYLLABLE DE
      code === 0xb38c || // Lo       HANGUL SYLLABLE DYEO
      code === 0xb3a8 || // Lo       HANGUL SYLLABLE DYE
      code === 0xb3c4 || // Lo       HANGUL SYLLABLE DO
      code === 0xb3e0 || // Lo       HANGUL SYLLABLE DWA
      code === 0xb3fc || // Lo       HANGUL SYLLABLE DWAE
      code === 0xb418 || // Lo       HANGUL SYLLABLE DOE
      code === 0xb434 || // Lo       HANGUL SYLLABLE DYO
      code === 0xb450 || // Lo       HANGUL SYLLABLE DU
      code === 0xb46c || // Lo       HANGUL SYLLABLE DWEO
      code === 0xb488 || // Lo       HANGUL SYLLABLE DWE
      code === 0xb4a4 || // Lo       HANGUL SYLLABLE DWI
      code === 0xb4c0 || // Lo       HANGUL SYLLABLE DYU
      code === 0xb4dc || // Lo       HANGUL SYLLABLE DEU
      code === 0xb4f8 || // Lo       HANGUL SYLLABLE DYI
      code === 0xb514 || // Lo       HANGUL SYLLABLE DI
      code === 0xb530 || // Lo       HANGUL SYLLABLE DDA
      code === 0xb54c || // Lo       HANGUL SYLLABLE DDAE
      code === 0xb568 || // Lo       HANGUL SYLLABLE DDYA
      code === 0xb584 || // Lo       HANGUL SYLLABLE DDYAE
      code === 0xb5a0 || // Lo       HANGUL SYLLABLE DDEO
      code === 0xb5bc || // Lo       HANGUL SYLLABLE DDE
      code === 0xb5d8 || // Lo       HANGUL SYLLABLE DDYEO
      code === 0xb5f4 || // Lo       HANGUL SYLLABLE DDYE
      code === 0xb610 || // Lo       HANGUL SYLLABLE DDO
      code === 0xb62c || // Lo       HANGUL SYLLABLE DDWA
      code === 0xb648 || // Lo       HANGUL SYLLABLE DDWAE
      code === 0xb664 || // Lo       HANGUL SYLLABLE DDOE
      code === 0xb680 || // Lo       HANGUL SYLLABLE DDYO
      code === 0xb69c || // Lo       HANGUL SYLLABLE DDU
      code === 0xb6b8 || // Lo       HANGUL SYLLABLE DDWEO
      code === 0xb6d4 || // Lo       HANGUL SYLLABLE DDWE
      code === 0xb6f0 || // Lo       HANGUL SYLLABLE DDWI
      code === 0xb70c || // Lo       HANGUL SYLLABLE DDYU
      code === 0xb728 || // Lo       HANGUL SYLLABLE DDEU
      code === 0xb744 || // Lo       HANGUL SYLLABLE DDYI
      code === 0xb760 || // Lo       HANGUL SYLLABLE DDI
      code === 0xb77c || // Lo       HANGUL SYLLABLE RA
      code === 0xb798 || // Lo       HANGUL SYLLABLE RAE
      code === 0xb7b4 || // Lo       HANGUL SYLLABLE RYA
      code === 0xb7d0 || // Lo       HANGUL SYLLABLE RYAE
      code === 0xb7ec || // Lo       HANGUL SYLLABLE REO
      code === 0xb808 || // Lo       HANGUL SYLLABLE RE
      code === 0xb824 || // Lo       HANGUL SYLLABLE RYEO
      code === 0xb840 || // Lo       HANGUL SYLLABLE RYE
      code === 0xb85c || // Lo       HANGUL SYLLABLE RO
      code === 0xb878 || // Lo       HANGUL SYLLABLE RWA
      code === 0xb894 || // Lo       HANGUL SYLLABLE RWAE
      code === 0xb8b0 || // Lo       HANGUL SYLLABLE ROE
      code === 0xb8cc || // Lo       HANGUL SYLLABLE RYO
      code === 0xb8e8 || // Lo       HANGUL SYLLABLE RU
      code === 0xb904 || // Lo       HANGUL SYLLABLE RWEO
      code === 0xb920 || // Lo       HANGUL SYLLABLE RWE
      code === 0xb93c || // Lo       HANGUL SYLLABLE RWI
      code === 0xb958 || // Lo       HANGUL SYLLABLE RYU
      code === 0xb974 || // Lo       HANGUL SYLLABLE REU
      code === 0xb990 || // Lo       HANGUL SYLLABLE RYI
      code === 0xb9ac || // Lo       HANGUL SYLLABLE RI
      code === 0xb9c8 || // Lo       HANGUL SYLLABLE MA
      code === 0xb9e4 || // Lo       HANGUL SYLLABLE MAE
      code === 0xba00 || // Lo       HANGUL SYLLABLE MYA
      code === 0xba1c || // Lo       HANGUL SYLLABLE MYAE
      code === 0xba38 || // Lo       HANGUL SYLLABLE MEO
      code === 0xba54 || // Lo       HANGUL SYLLABLE ME
      code === 0xba70 || // Lo       HANGUL SYLLABLE MYEO
      code === 0xba8c || // Lo       HANGUL SYLLABLE MYE
      code === 0xbaa8 || // Lo       HANGUL SYLLABLE MO
      code === 0xbac4 || // Lo       HANGUL SYLLABLE MWA
      code === 0xbae0 || // Lo       HANGUL SYLLABLE MWAE
      code === 0xbafc || // Lo       HANGUL SYLLABLE MOE
      code === 0xbb18 || // Lo       HANGUL SYLLABLE MYO
      code === 0xbb34 || // Lo       HANGUL SYLLABLE MU
      code === 0xbb50 || // Lo       HANGUL SYLLABLE MWEO
      code === 0xbb6c || // Lo       HANGUL SYLLABLE MWE
      code === 0xbb88 || // Lo       HANGUL SYLLABLE MWI
      code === 0xbba4 || // Lo       HANGUL SYLLABLE MYU
      code === 0xbbc0 || // Lo       HANGUL SYLLABLE MEU
      code === 0xbbdc || // Lo       HANGUL SYLLABLE MYI
      code === 0xbbf8 || // Lo       HANGUL SYLLABLE MI
      code === 0xbc14 || // Lo       HANGUL SYLLABLE BA
      code === 0xbc30 || // Lo       HANGUL SYLLABLE BAE
      code === 0xbc4c || // Lo       HANGUL SYLLABLE BYA
      code === 0xbc68 || // Lo       HANGUL SYLLABLE BYAE
      code === 0xbc84 || // Lo       HANGUL SYLLABLE BEO
      code === 0xbca0 || // Lo       HANGUL SYLLABLE BE
      code === 0xbcbc || // Lo       HANGUL SYLLABLE BYEO
      code === 0xbcd8 || // Lo       HANGUL SYLLABLE BYE
      code === 0xbcf4 || // Lo       HANGUL SYLLABLE BO
      code === 0xbd10 || // Lo       HANGUL SYLLABLE BWA
      code === 0xbd2c || // Lo       HANGUL SYLLABLE BWAE
      code === 0xbd48 || // Lo       HANGUL SYLLABLE BOE
      code === 0xbd64 || // Lo       HANGUL SYLLABLE BYO
      code === 0xbd80 || // Lo       HANGUL SYLLABLE BU
      code === 0xbd9c || // Lo       HANGUL SYLLABLE BWEO
      code === 0xbdb8 || // Lo       HANGUL SYLLABLE BWE
      code === 0xbdd4 || // Lo       HANGUL SYLLABLE BWI
      code === 0xbdf0 || // Lo       HANGUL SYLLABLE BYU
      code === 0xbe0c || // Lo       HANGUL SYLLABLE BEU
      code === 0xbe28 || // Lo       HANGUL SYLLABLE BYI
      code === 0xbe44 || // Lo       HANGUL SYLLABLE BI
      code === 0xbe60 || // Lo       HANGUL SYLLABLE BBA
      code === 0xbe7c || // Lo       HANGUL SYLLABLE BBAE
      code === 0xbe98 || // Lo       HANGUL SYLLABLE BBYA
      code === 0xbeb4 || // Lo       HANGUL SYLLABLE BBYAE
      code === 0xbed0 || // Lo       HANGUL SYLLABLE BBEO
      code === 0xbeec || // Lo       HANGUL SYLLABLE BBE
      code === 0xbf08 || // Lo       HANGUL SYLLABLE BBYEO
      code === 0xbf24 || // Lo       HANGUL SYLLABLE BBYE
      code === 0xbf40 || // Lo       HANGUL SYLLABLE BBO
      code === 0xbf5c || // Lo       HANGUL SYLLABLE BBWA
      code === 0xbf78 || // Lo       HANGUL SYLLABLE BBWAE
      code === 0xbf94 || // Lo       HANGUL SYLLABLE BBOE
      code === 0xbfb0 || // Lo       HANGUL SYLLABLE BBYO
      code === 0xbfcc || // Lo       HANGUL SYLLABLE BBU
      code === 0xbfe8 || // Lo       HANGUL SYLLABLE BBWEO
      code === 0xc004 || // Lo       HANGUL SYLLABLE BBWE
      code === 0xc020 || // Lo       HANGUL SYLLABLE BBWI
      code === 0xc03c || // Lo       HANGUL SYLLABLE BBYU
      code === 0xc058 || // Lo       HANGUL SYLLABLE BBEU
      code === 0xc074 || // Lo       HANGUL SYLLABLE BBYI
      code === 0xc090 || // Lo       HANGUL SYLLABLE BBI
      code === 0xc0ac || // Lo       HANGUL SYLLABLE SA
      code === 0xc0c8 || // Lo       HANGUL SYLLABLE SAE
      code === 0xc0e4 || // Lo       HANGUL SYLLABLE SYA
      code === 0xc100 || // Lo       HANGUL SYLLABLE SYAE
      code === 0xc11c || // Lo       HANGUL SYLLABLE SEO
      code === 0xc138 || // Lo       HANGUL SYLLABLE SE
      code === 0xc154 || // Lo       HANGUL SYLLABLE SYEO
      code === 0xc170 || // Lo       HANGUL SYLLABLE SYE
      code === 0xc18c || // Lo       HANGUL SYLLABLE SO
      code === 0xc1a8 || // Lo       HANGUL SYLLABLE SWA
      code === 0xc1c4 || // Lo       HANGUL SYLLABLE SWAE
      code === 0xc1e0 || // Lo       HANGUL SYLLABLE SOE
      code === 0xc1fc || // Lo       HANGUL SYLLABLE SYO
      code === 0xc218 || // Lo       HANGUL SYLLABLE SU
      code === 0xc234 || // Lo       HANGUL SYLLABLE SWEO
      code === 0xc250 || // Lo       HANGUL SYLLABLE SWE
      code === 0xc26c || // Lo       HANGUL SYLLABLE SWI
      code === 0xc288 || // Lo       HANGUL SYLLABLE SYU
      code === 0xc2a4 || // Lo       HANGUL SYLLABLE SEU
      code === 0xc2c0 || // Lo       HANGUL SYLLABLE SYI
      code === 0xc2dc || // Lo       HANGUL SYLLABLE SI
      code === 0xc2f8 || // Lo       HANGUL SYLLABLE SSA
      code === 0xc314 || // Lo       HANGUL SYLLABLE SSAE
      code === 0xc330 || // Lo       HANGUL SYLLABLE SSYA
      code === 0xc34c || // Lo       HANGUL SYLLABLE SSYAE
      code === 0xc368 || // Lo       HANGUL SYLLABLE SSEO
      code === 0xc384 || // Lo       HANGUL SYLLABLE SSE
      code === 0xc3a0 || // Lo       HANGUL SYLLABLE SSYEO
      code === 0xc3bc || // Lo       HANGUL SYLLABLE SSYE
      code === 0xc3d8 || // Lo       HANGUL SYLLABLE SSO
      code === 0xc3f4 || // Lo       HANGUL SYLLABLE SSWA
      code === 0xc410 || // Lo       HANGUL SYLLABLE SSWAE
      code === 0xc42c || // Lo       HANGUL SYLLABLE SSOE
      code === 0xc448 || // Lo       HANGUL SYLLABLE SSYO
      code === 0xc464 || // Lo       HANGUL SYLLABLE SSU
      code === 0xc480 || // Lo       HANGUL SYLLABLE SSWEO
      code === 0xc49c || // Lo       HANGUL SYLLABLE SSWE
      code === 0xc4b8 || // Lo       HANGUL SYLLABLE SSWI
      code === 0xc4d4 || // Lo       HANGUL SYLLABLE SSYU
      code === 0xc4f0 || // Lo       HANGUL SYLLABLE SSEU
      code === 0xc50c || // Lo       HANGUL SYLLABLE SSYI
      code === 0xc528 || // Lo       HANGUL SYLLABLE SSI
      code === 0xc544 || // Lo       HANGUL SYLLABLE A
      code === 0xc560 || // Lo       HANGUL SYLLABLE AE
      code === 0xc57c || // Lo       HANGUL SYLLABLE YA
      code === 0xc598 || // Lo       HANGUL SYLLABLE YAE
      code === 0xc5b4 || // Lo       HANGUL SYLLABLE EO
      code === 0xc5d0 || // Lo       HANGUL SYLLABLE E
      code === 0xc5ec || // Lo       HANGUL SYLLABLE YEO
      code === 0xc608 || // Lo       HANGUL SYLLABLE YE
      code === 0xc624 || // Lo       HANGUL SYLLABLE O
      code === 0xc640 || // Lo       HANGUL SYLLABLE WA
      code === 0xc65c || // Lo       HANGUL SYLLABLE WAE
      code === 0xc678 || // Lo       HANGUL SYLLABLE OE
      code === 0xc694 || // Lo       HANGUL SYLLABLE YO
      code === 0xc6b0 || // Lo       HANGUL SYLLABLE U
      code === 0xc6cc || // Lo       HANGUL SYLLABLE WEO
      code === 0xc6e8 || // Lo       HANGUL SYLLABLE WE
      code === 0xc704 || // Lo       HANGUL SYLLABLE WI
      code === 0xc720 || // Lo       HANGUL SYLLABLE YU
      code === 0xc73c || // Lo       HANGUL SYLLABLE EU
      code === 0xc758 || // Lo       HANGUL SYLLABLE YI
      code === 0xc774 || // Lo       HANGUL SYLLABLE I
      code === 0xc790 || // Lo       HANGUL SYLLABLE JA
      code === 0xc7ac || // Lo       HANGUL SYLLABLE JAE
      code === 0xc7c8 || // Lo       HANGUL SYLLABLE JYA
      code === 0xc7e4 || // Lo       HANGUL SYLLABLE JYAE
      code === 0xc800 || // Lo       HANGUL SYLLABLE JEO
      code === 0xc81c || // Lo       HANGUL SYLLABLE JE
      code === 0xc838 || // Lo       HANGUL SYLLABLE JYEO
      code === 0xc854 || // Lo       HANGUL SYLLABLE JYE
      code === 0xc870 || // Lo       HANGUL SYLLABLE JO
      code === 0xc88c || // Lo       HANGUL SYLLABLE JWA
      code === 0xc8a8 || // Lo       HANGUL SYLLABLE JWAE
      code === 0xc8c4 || // Lo       HANGUL SYLLABLE JOE
      code === 0xc8e0 || // Lo       HANGUL SYLLABLE JYO
      code === 0xc8fc || // Lo       HANGUL SYLLABLE JU
      code === 0xc918 || // Lo       HANGUL SYLLABLE JWEO
      code === 0xc934 || // Lo       HANGUL SYLLABLE JWE
      code === 0xc950 || // Lo       HANGUL SYLLABLE JWI
      code === 0xc96c || // Lo       HANGUL SYLLABLE JYU
      code === 0xc988 || // Lo       HANGUL SYLLABLE JEU
      code === 0xc9a4 || // Lo       HANGUL SYLLABLE JYI
      code === 0xc9c0 || // Lo       HANGUL SYLLABLE JI
      code === 0xc9dc || // Lo       HANGUL SYLLABLE JJA
      code === 0xc9f8 || // Lo       HANGUL SYLLABLE JJAE
      code === 0xca14 || // Lo       HANGUL SYLLABLE JJYA
      code === 0xca30 || // Lo       HANGUL SYLLABLE JJYAE
      code === 0xca4c || // Lo       HANGUL SYLLABLE JJEO
      code === 0xca68 || // Lo       HANGUL SYLLABLE JJE
      code === 0xca84 || // Lo       HANGUL SYLLABLE JJYEO
      code === 0xcaa0 || // Lo       HANGUL SYLLABLE JJYE
      code === 0xcabc || // Lo       HANGUL SYLLABLE JJO
      code === 0xcad8 || // Lo       HANGUL SYLLABLE JJWA
      code === 0xcaf4 || // Lo       HANGUL SYLLABLE JJWAE
      code === 0xcb10 || // Lo       HANGUL SYLLABLE JJOE
      code === 0xcb2c || // Lo       HANGUL SYLLABLE JJYO
      code === 0xcb48 || // Lo       HANGUL SYLLABLE JJU
      code === 0xcb64 || // Lo       HANGUL SYLLABLE JJWEO
      code === 0xcb80 || // Lo       HANGUL SYLLABLE JJWE
      code === 0xcb9c || // Lo       HANGUL SYLLABLE JJWI
      code === 0xcbb8 || // Lo       HANGUL SYLLABLE JJYU
      code === 0xcbd4 || // Lo       HANGUL SYLLABLE JJEU
      code === 0xcbf0 || // Lo       HANGUL SYLLABLE JJYI
      code === 0xcc0c || // Lo       HANGUL SYLLABLE JJI
      code === 0xcc28 || // Lo       HANGUL SYLLABLE CA
      code === 0xcc44 || // Lo       HANGUL SYLLABLE CAE
      code === 0xcc60 || // Lo       HANGUL SYLLABLE CYA
      code === 0xcc7c || // Lo       HANGUL SYLLABLE CYAE
      code === 0xcc98 || // Lo       HANGUL SYLLABLE CEO
      code === 0xccb4 || // Lo       HANGUL SYLLABLE CE
      code === 0xccd0 || // Lo       HANGUL SYLLABLE CYEO
      code === 0xccec || // Lo       HANGUL SYLLABLE CYE
      code === 0xcd08 || // Lo       HANGUL SYLLABLE CO
      code === 0xcd24 || // Lo       HANGUL SYLLABLE CWA
      code === 0xcd40 || // Lo       HANGUL SYLLABLE CWAE
      code === 0xcd5c || // Lo       HANGUL SYLLABLE COE
      code === 0xcd78 || // Lo       HANGUL SYLLABLE CYO
      code === 0xcd94 || // Lo       HANGUL SYLLABLE CU
      code === 0xcdb0 || // Lo       HANGUL SYLLABLE CWEO
      code === 0xcdcc || // Lo       HANGUL SYLLABLE CWE
      code === 0xcde8 || // Lo       HANGUL SYLLABLE CWI
      code === 0xce04 || // Lo       HANGUL SYLLABLE CYU
      code === 0xce20 || // Lo       HANGUL SYLLABLE CEU
      code === 0xce3c || // Lo       HANGUL SYLLABLE CYI
      code === 0xce58 || // Lo       HANGUL SYLLABLE CI
      code === 0xce74 || // Lo       HANGUL SYLLABLE KA
      code === 0xce90 || // Lo       HANGUL SYLLABLE KAE
      code === 0xceac || // Lo       HANGUL SYLLABLE KYA
      code === 0xcec8 || // Lo       HANGUL SYLLABLE KYAE
      code === 0xcee4 || // Lo       HANGUL SYLLABLE KEO
      code === 0xcf00 || // Lo       HANGUL SYLLABLE KE
      code === 0xcf1c || // Lo       HANGUL SYLLABLE KYEO
      code === 0xcf38 || // Lo       HANGUL SYLLABLE KYE
      code === 0xcf54 || // Lo       HANGUL SYLLABLE KO
      code === 0xcf70 || // Lo       HANGUL SYLLABLE KWA
      code === 0xcf8c || // Lo       HANGUL SYLLABLE KWAE
      code === 0xcfa8 || // Lo       HANGUL SYLLABLE KOE
      code === 0xcfc4 || // Lo       HANGUL SYLLABLE KYO
      code === 0xcfe0 || // Lo       HANGUL SYLLABLE KU
      code === 0xcffc || // Lo       HANGUL SYLLABLE KWEO
      code === 0xd018 || // Lo       HANGUL SYLLABLE KWE
      code === 0xd034 || // Lo       HANGUL SYLLABLE KWI
      code === 0xd050 || // Lo       HANGUL SYLLABLE KYU
      code === 0xd06c || // Lo       HANGUL SYLLABLE KEU
      code === 0xd088 || // Lo       HANGUL SYLLABLE KYI
      code === 0xd0a4 || // Lo       HANGUL SYLLABLE KI
      code === 0xd0c0 || // Lo       HANGUL SYLLABLE TA
      code === 0xd0dc || // Lo       HANGUL SYLLABLE TAE
      code === 0xd0f8 || // Lo       HANGUL SYLLABLE TYA
      code === 0xd114 || // Lo       HANGUL SYLLABLE TYAE
      code === 0xd130 || // Lo       HANGUL SYLLABLE TEO
      code === 0xd14c || // Lo       HANGUL SYLLABLE TE
      code === 0xd168 || // Lo       HANGUL SYLLABLE TYEO
      code === 0xd184 || // Lo       HANGUL SYLLABLE TYE
      code === 0xd1a0 || // Lo       HANGUL SYLLABLE TO
      code === 0xd1bc || // Lo       HANGUL SYLLABLE TWA
      code === 0xd1d8 || // Lo       HANGUL SYLLABLE TWAE
      code === 0xd1f4 || // Lo       HANGUL SYLLABLE TOE
      code === 0xd210 || // Lo       HANGUL SYLLABLE TYO
      code === 0xd22c || // Lo       HANGUL SYLLABLE TU
      code === 0xd248 || // Lo       HANGUL SYLLABLE TWEO
      code === 0xd264 || // Lo       HANGUL SYLLABLE TWE
      code === 0xd280 || // Lo       HANGUL SYLLABLE TWI
      code === 0xd29c || // Lo       HANGUL SYLLABLE TYU
      code === 0xd2b8 || // Lo       HANGUL SYLLABLE TEU
      code === 0xd2d4 || // Lo       HANGUL SYLLABLE TYI
      code === 0xd2f0 || // Lo       HANGUL SYLLABLE TI
      code === 0xd30c || // Lo       HANGUL SYLLABLE PA
      code === 0xd328 || // Lo       HANGUL SYLLABLE PAE
      code === 0xd344 || // Lo       HANGUL SYLLABLE PYA
      code === 0xd360 || // Lo       HANGUL SYLLABLE PYAE
      code === 0xd37c || // Lo       HANGUL SYLLABLE PEO
      code === 0xd398 || // Lo       HANGUL SYLLABLE PE
      code === 0xd3b4 || // Lo       HANGUL SYLLABLE PYEO
      code === 0xd3d0 || // Lo       HANGUL SYLLABLE PYE
      code === 0xd3ec || // Lo       HANGUL SYLLABLE PO
      code === 0xd408 || // Lo       HANGUL SYLLABLE PWA
      code === 0xd424 || // Lo       HANGUL SYLLABLE PWAE
      code === 0xd440 || // Lo       HANGUL SYLLABLE POE
      code === 0xd45c || // Lo       HANGUL SYLLABLE PYO
      code === 0xd478 || // Lo       HANGUL SYLLABLE PU
      code === 0xd494 || // Lo       HANGUL SYLLABLE PWEO
      code === 0xd4b0 || // Lo       HANGUL SYLLABLE PWE
      code === 0xd4cc || // Lo       HANGUL SYLLABLE PWI
      code === 0xd4e8 || // Lo       HANGUL SYLLABLE PYU
      code === 0xd504 || // Lo       HANGUL SYLLABLE PEU
      code === 0xd520 || // Lo       HANGUL SYLLABLE PYI
      code === 0xd53c || // Lo       HANGUL SYLLABLE PI
      code === 0xd558 || // Lo       HANGUL SYLLABLE HA
      code === 0xd574 || // Lo       HANGUL SYLLABLE HAE
      code === 0xd590 || // Lo       HANGUL SYLLABLE HYA
      code === 0xd5ac || // Lo       HANGUL SYLLABLE HYAE
      code === 0xd5c8 || // Lo       HANGUL SYLLABLE HEO
      code === 0xd5e4 || // Lo       HANGUL SYLLABLE HE
      code === 0xd600 || // Lo       HANGUL SYLLABLE HYEO
      code === 0xd61c || // Lo       HANGUL SYLLABLE HYE
      code === 0xd638 || // Lo       HANGUL SYLLABLE HO
      code === 0xd654 || // Lo       HANGUL SYLLABLE HWA
      code === 0xd670 || // Lo       HANGUL SYLLABLE HWAE
      code === 0xd68c || // Lo       HANGUL SYLLABLE HOE
      code === 0xd6a8 || // Lo       HANGUL SYLLABLE HYO
      code === 0xd6c4 || // Lo       HANGUL SYLLABLE HU
      code === 0xd6e0 || // Lo       HANGUL SYLLABLE HWEO
      code === 0xd6fc || // Lo       HANGUL SYLLABLE HWE
      code === 0xd718 || // Lo       HANGUL SYLLABLE HWI
      code === 0xd734 || // Lo       HANGUL SYLLABLE HYU
      code === 0xd750 || // Lo       HANGUL SYLLABLE HEU
      code === 0xd76c || // Lo       HANGUL SYLLABLE HYI
      code === 0xd788 // Lo       HANGUL SYLLABLE HI
    ) {
      return LV;
    }

    if (
      (code >= 0xac01 && code <= 0xac1b) || // Lo  [27] HANGUL SYLLABLE GAG..HANGUL SYLLABLE GAH
      (code >= 0xac1d && code <= 0xac37) || // Lo  [27] HANGUL SYLLABLE GAEG..HANGUL SYLLABLE GAEH
      (code >= 0xac39 && code <= 0xac53) || // Lo  [27] HANGUL SYLLABLE GYAG..HANGUL SYLLABLE GYAH
      (code >= 0xac55 && code <= 0xac6f) || // Lo  [27] HANGUL SYLLABLE GYAEG..HANGUL SYLLABLE GYAEH
      (code >= 0xac71 && code <= 0xac8b) || // Lo  [27] HANGUL SYLLABLE GEOG..HANGUL SYLLABLE GEOH
      (code >= 0xac8d && code <= 0xaca7) || // Lo  [27] HANGUL SYLLABLE GEG..HANGUL SYLLABLE GEH
      (code >= 0xaca9 && code <= 0xacc3) || // Lo  [27] HANGUL SYLLABLE GYEOG..HANGUL SYLLABLE GYEOH
      (code >= 0xacc5 && code <= 0xacdf) || // Lo  [27] HANGUL SYLLABLE GYEG..HANGUL SYLLABLE GYEH
      (code >= 0xace1 && code <= 0xacfb) || // Lo  [27] HANGUL SYLLABLE GOG..HANGUL SYLLABLE GOH
      (code >= 0xacfd && code <= 0xad17) || // Lo  [27] HANGUL SYLLABLE GWAG..HANGUL SYLLABLE GWAH
      (code >= 0xad19 && code <= 0xad33) || // Lo  [27] HANGUL SYLLABLE GWAEG..HANGUL SYLLABLE GWAEH
      (code >= 0xad35 && code <= 0xad4f) || // Lo  [27] HANGUL SYLLABLE GOEG..HANGUL SYLLABLE GOEH
      (code >= 0xad51 && code <= 0xad6b) || // Lo  [27] HANGUL SYLLABLE GYOG..HANGUL SYLLABLE GYOH
      (code >= 0xad6d && code <= 0xad87) || // Lo  [27] HANGUL SYLLABLE GUG..HANGUL SYLLABLE GUH
      (code >= 0xad89 && code <= 0xada3) || // Lo  [27] HANGUL SYLLABLE GWEOG..HANGUL SYLLABLE GWEOH
      (code >= 0xada5 && code <= 0xadbf) || // Lo  [27] HANGUL SYLLABLE GWEG..HANGUL SYLLABLE GWEH
      (code >= 0xadc1 && code <= 0xaddb) || // Lo  [27] HANGUL SYLLABLE GWIG..HANGUL SYLLABLE GWIH
      (code >= 0xaddd && code <= 0xadf7) || // Lo  [27] HANGUL SYLLABLE GYUG..HANGUL SYLLABLE GYUH
      (code >= 0xadf9 && code <= 0xae13) || // Lo  [27] HANGUL SYLLABLE GEUG..HANGUL SYLLABLE GEUH
      (code >= 0xae15 && code <= 0xae2f) || // Lo  [27] HANGUL SYLLABLE GYIG..HANGUL SYLLABLE GYIH
      (code >= 0xae31 && code <= 0xae4b) || // Lo  [27] HANGUL SYLLABLE GIG..HANGUL SYLLABLE GIH
      (code >= 0xae4d && code <= 0xae67) || // Lo  [27] HANGUL SYLLABLE GGAG..HANGUL SYLLABLE GGAH
      (code >= 0xae69 && code <= 0xae83) || // Lo  [27] HANGUL SYLLABLE GGAEG..HANGUL SYLLABLE GGAEH
      (code >= 0xae85 && code <= 0xae9f) || // Lo  [27] HANGUL SYLLABLE GGYAG..HANGUL SYLLABLE GGYAH
      (code >= 0xaea1 && code <= 0xaebb) || // Lo  [27] HANGUL SYLLABLE GGYAEG..HANGUL SYLLABLE GGYAEH
      (code >= 0xaebd && code <= 0xaed7) || // Lo  [27] HANGUL SYLLABLE GGEOG..HANGUL SYLLABLE GGEOH
      (code >= 0xaed9 && code <= 0xaef3) || // Lo  [27] HANGUL SYLLABLE GGEG..HANGUL SYLLABLE GGEH
      (code >= 0xaef5 && code <= 0xaf0f) || // Lo  [27] HANGUL SYLLABLE GGYEOG..HANGUL SYLLABLE GGYEOH
      (code >= 0xaf11 && code <= 0xaf2b) || // Lo  [27] HANGUL SYLLABLE GGYEG..HANGUL SYLLABLE GGYEH
      (code >= 0xaf2d && code <= 0xaf47) || // Lo  [27] HANGUL SYLLABLE GGOG..HANGUL SYLLABLE GGOH
      (code >= 0xaf49 && code <= 0xaf63) || // Lo  [27] HANGUL SYLLABLE GGWAG..HANGUL SYLLABLE GGWAH
      (code >= 0xaf65 && code <= 0xaf7f) || // Lo  [27] HANGUL SYLLABLE GGWAEG..HANGUL SYLLABLE GGWAEH
      (code >= 0xaf81 && code <= 0xaf9b) || // Lo  [27] HANGUL SYLLABLE GGOEG..HANGUL SYLLABLE GGOEH
      (code >= 0xaf9d && code <= 0xafb7) || // Lo  [27] HANGUL SYLLABLE GGYOG..HANGUL SYLLABLE GGYOH
      (code >= 0xafb9 && code <= 0xafd3) || // Lo  [27] HANGUL SYLLABLE GGUG..HANGUL SYLLABLE GGUH
      (code >= 0xafd5 && code <= 0xafef) || // Lo  [27] HANGUL SYLLABLE GGWEOG..HANGUL SYLLABLE GGWEOH
      (code >= 0xaff1 && code <= 0xb00b) || // Lo  [27] HANGUL SYLLABLE GGWEG..HANGUL SYLLABLE GGWEH
      (code >= 0xb00d && code <= 0xb027) || // Lo  [27] HANGUL SYLLABLE GGWIG..HANGUL SYLLABLE GGWIH
      (code >= 0xb029 && code <= 0xb043) || // Lo  [27] HANGUL SYLLABLE GGYUG..HANGUL SYLLABLE GGYUH
      (code >= 0xb045 && code <= 0xb05f) || // Lo  [27] HANGUL SYLLABLE GGEUG..HANGUL SYLLABLE GGEUH
      (code >= 0xb061 && code <= 0xb07b) || // Lo  [27] HANGUL SYLLABLE GGYIG..HANGUL SYLLABLE GGYIH
      (code >= 0xb07d && code <= 0xb097) || // Lo  [27] HANGUL SYLLABLE GGIG..HANGUL SYLLABLE GGIH
      (code >= 0xb099 && code <= 0xb0b3) || // Lo  [27] HANGUL SYLLABLE NAG..HANGUL SYLLABLE NAH
      (code >= 0xb0b5 && code <= 0xb0cf) || // Lo  [27] HANGUL SYLLABLE NAEG..HANGUL SYLLABLE NAEH
      (code >= 0xb0d1 && code <= 0xb0eb) || // Lo  [27] HANGUL SYLLABLE NYAG..HANGUL SYLLABLE NYAH
      (code >= 0xb0ed && code <= 0xb107) || // Lo  [27] HANGUL SYLLABLE NYAEG..HANGUL SYLLABLE NYAEH
      (code >= 0xb109 && code <= 0xb123) || // Lo  [27] HANGUL SYLLABLE NEOG..HANGUL SYLLABLE NEOH
      (code >= 0xb125 && code <= 0xb13f) || // Lo  [27] HANGUL SYLLABLE NEG..HANGUL SYLLABLE NEH
      (code >= 0xb141 && code <= 0xb15b) || // Lo  [27] HANGUL SYLLABLE NYEOG..HANGUL SYLLABLE NYEOH
      (code >= 0xb15d && code <= 0xb177) || // Lo  [27] HANGUL SYLLABLE NYEG..HANGUL SYLLABLE NYEH
      (code >= 0xb179 && code <= 0xb193) || // Lo  [27] HANGUL SYLLABLE NOG..HANGUL SYLLABLE NOH
      (code >= 0xb195 && code <= 0xb1af) || // Lo  [27] HANGUL SYLLABLE NWAG..HANGUL SYLLABLE NWAH
      (code >= 0xb1b1 && code <= 0xb1cb) || // Lo  [27] HANGUL SYLLABLE NWAEG..HANGUL SYLLABLE NWAEH
      (code >= 0xb1cd && code <= 0xb1e7) || // Lo  [27] HANGUL SYLLABLE NOEG..HANGUL SYLLABLE NOEH
      (code >= 0xb1e9 && code <= 0xb203) || // Lo  [27] HANGUL SYLLABLE NYOG..HANGUL SYLLABLE NYOH
      (code >= 0xb205 && code <= 0xb21f) || // Lo  [27] HANGUL SYLLABLE NUG..HANGUL SYLLABLE NUH
      (code >= 0xb221 && code <= 0xb23b) || // Lo  [27] HANGUL SYLLABLE NWEOG..HANGUL SYLLABLE NWEOH
      (code >= 0xb23d && code <= 0xb257) || // Lo  [27] HANGUL SYLLABLE NWEG..HANGUL SYLLABLE NWEH
      (code >= 0xb259 && code <= 0xb273) || // Lo  [27] HANGUL SYLLABLE NWIG..HANGUL SYLLABLE NWIH
      (code >= 0xb275 && code <= 0xb28f) || // Lo  [27] HANGUL SYLLABLE NYUG..HANGUL SYLLABLE NYUH
      (code >= 0xb291 && code <= 0xb2ab) || // Lo  [27] HANGUL SYLLABLE NEUG..HANGUL SYLLABLE NEUH
      (code >= 0xb2ad && code <= 0xb2c7) || // Lo  [27] HANGUL SYLLABLE NYIG..HANGUL SYLLABLE NYIH
      (code >= 0xb2c9 && code <= 0xb2e3) || // Lo  [27] HANGUL SYLLABLE NIG..HANGUL SYLLABLE NIH
      (code >= 0xb2e5 && code <= 0xb2ff) || // Lo  [27] HANGUL SYLLABLE DAG..HANGUL SYLLABLE DAH
      (code >= 0xb301 && code <= 0xb31b) || // Lo  [27] HANGUL SYLLABLE DAEG..HANGUL SYLLABLE DAEH
      (code >= 0xb31d && code <= 0xb337) || // Lo  [27] HANGUL SYLLABLE DYAG..HANGUL SYLLABLE DYAH
      (code >= 0xb339 && code <= 0xb353) || // Lo  [27] HANGUL SYLLABLE DYAEG..HANGUL SYLLABLE DYAEH
      (code >= 0xb355 && code <= 0xb36f) || // Lo  [27] HANGUL SYLLABLE DEOG..HANGUL SYLLABLE DEOH
      (code >= 0xb371 && code <= 0xb38b) || // Lo  [27] HANGUL SYLLABLE DEG..HANGUL SYLLABLE DEH
      (code >= 0xb38d && code <= 0xb3a7) || // Lo  [27] HANGUL SYLLABLE DYEOG..HANGUL SYLLABLE DYEOH
      (code >= 0xb3a9 && code <= 0xb3c3) || // Lo  [27] HANGUL SYLLABLE DYEG..HANGUL SYLLABLE DYEH
      (code >= 0xb3c5 && code <= 0xb3df) || // Lo  [27] HANGUL SYLLABLE DOG..HANGUL SYLLABLE DOH
      (code >= 0xb3e1 && code <= 0xb3fb) || // Lo  [27] HANGUL SYLLABLE DWAG..HANGUL SYLLABLE DWAH
      (code >= 0xb3fd && code <= 0xb417) || // Lo  [27] HANGUL SYLLABLE DWAEG..HANGUL SYLLABLE DWAEH
      (code >= 0xb419 && code <= 0xb433) || // Lo  [27] HANGUL SYLLABLE DOEG..HANGUL SYLLABLE DOEH
      (code >= 0xb435 && code <= 0xb44f) || // Lo  [27] HANGUL SYLLABLE DYOG..HANGUL SYLLABLE DYOH
      (code >= 0xb451 && code <= 0xb46b) || // Lo  [27] HANGUL SYLLABLE DUG..HANGUL SYLLABLE DUH
      (code >= 0xb46d && code <= 0xb487) || // Lo  [27] HANGUL SYLLABLE DWEOG..HANGUL SYLLABLE DWEOH
      (code >= 0xb489 && code <= 0xb4a3) || // Lo  [27] HANGUL SYLLABLE DWEG..HANGUL SYLLABLE DWEH
      (code >= 0xb4a5 && code <= 0xb4bf) || // Lo  [27] HANGUL SYLLABLE DWIG..HANGUL SYLLABLE DWIH
      (code >= 0xb4c1 && code <= 0xb4db) || // Lo  [27] HANGUL SYLLABLE DYUG..HANGUL SYLLABLE DYUH
      (code >= 0xb4dd && code <= 0xb4f7) || // Lo  [27] HANGUL SYLLABLE DEUG..HANGUL SYLLABLE DEUH
      (code >= 0xb4f9 && code <= 0xb513) || // Lo  [27] HANGUL SYLLABLE DYIG..HANGUL SYLLABLE DYIH
      (code >= 0xb515 && code <= 0xb52f) || // Lo  [27] HANGUL SYLLABLE DIG..HANGUL SYLLABLE DIH
      (code >= 0xb531 && code <= 0xb54b) || // Lo  [27] HANGUL SYLLABLE DDAG..HANGUL SYLLABLE DDAH
      (code >= 0xb54d && code <= 0xb567) || // Lo  [27] HANGUL SYLLABLE DDAEG..HANGUL SYLLABLE DDAEH
      (code >= 0xb569 && code <= 0xb583) || // Lo  [27] HANGUL SYLLABLE DDYAG..HANGUL SYLLABLE DDYAH
      (code >= 0xb585 && code <= 0xb59f) || // Lo  [27] HANGUL SYLLABLE DDYAEG..HANGUL SYLLABLE DDYAEH
      (code >= 0xb5a1 && code <= 0xb5bb) || // Lo  [27] HANGUL SYLLABLE DDEOG..HANGUL SYLLABLE DDEOH
      (code >= 0xb5bd && code <= 0xb5d7) || // Lo  [27] HANGUL SYLLABLE DDEG..HANGUL SYLLABLE DDEH
      (code >= 0xb5d9 && code <= 0xb5f3) || // Lo  [27] HANGUL SYLLABLE DDYEOG..HANGUL SYLLABLE DDYEOH
      (code >= 0xb5f5 && code <= 0xb60f) || // Lo  [27] HANGUL SYLLABLE DDYEG..HANGUL SYLLABLE DDYEH
      (code >= 0xb611 && code <= 0xb62b) || // Lo  [27] HANGUL SYLLABLE DDOG..HANGUL SYLLABLE DDOH
      (code >= 0xb62d && code <= 0xb647) || // Lo  [27] HANGUL SYLLABLE DDWAG..HANGUL SYLLABLE DDWAH
      (code >= 0xb649 && code <= 0xb663) || // Lo  [27] HANGUL SYLLABLE DDWAEG..HANGUL SYLLABLE DDWAEH
      (code >= 0xb665 && code <= 0xb67f) || // Lo  [27] HANGUL SYLLABLE DDOEG..HANGUL SYLLABLE DDOEH
      (code >= 0xb681 && code <= 0xb69b) || // Lo  [27] HANGUL SYLLABLE DDYOG..HANGUL SYLLABLE DDYOH
      (code >= 0xb69d && code <= 0xb6b7) || // Lo  [27] HANGUL SYLLABLE DDUG..HANGUL SYLLABLE DDUH
      (code >= 0xb6b9 && code <= 0xb6d3) || // Lo  [27] HANGUL SYLLABLE DDWEOG..HANGUL SYLLABLE DDWEOH
      (code >= 0xb6d5 && code <= 0xb6ef) || // Lo  [27] HANGUL SYLLABLE DDWEG..HANGUL SYLLABLE DDWEH
      (code >= 0xb6f1 && code <= 0xb70b) || // Lo  [27] HANGUL SYLLABLE DDWIG..HANGUL SYLLABLE DDWIH
      (code >= 0xb70d && code <= 0xb727) || // Lo  [27] HANGUL SYLLABLE DDYUG..HANGUL SYLLABLE DDYUH
      (code >= 0xb729 && code <= 0xb743) || // Lo  [27] HANGUL SYLLABLE DDEUG..HANGUL SYLLABLE DDEUH
      (code >= 0xb745 && code <= 0xb75f) || // Lo  [27] HANGUL SYLLABLE DDYIG..HANGUL SYLLABLE DDYIH
      (code >= 0xb761 && code <= 0xb77b) || // Lo  [27] HANGUL SYLLABLE DDIG..HANGUL SYLLABLE DDIH
      (code >= 0xb77d && code <= 0xb797) || // Lo  [27] HANGUL SYLLABLE RAG..HANGUL SYLLABLE RAH
      (code >= 0xb799 && code <= 0xb7b3) || // Lo  [27] HANGUL SYLLABLE RAEG..HANGUL SYLLABLE RAEH
      (code >= 0xb7b5 && code <= 0xb7cf) || // Lo  [27] HANGUL SYLLABLE RYAG..HANGUL SYLLABLE RYAH
      (code >= 0xb7d1 && code <= 0xb7eb) || // Lo  [27] HANGUL SYLLABLE RYAEG..HANGUL SYLLABLE RYAEH
      (code >= 0xb7ed && code <= 0xb807) || // Lo  [27] HANGUL SYLLABLE REOG..HANGUL SYLLABLE REOH
      (code >= 0xb809 && code <= 0xb823) || // Lo  [27] HANGUL SYLLABLE REG..HANGUL SYLLABLE REH
      (code >= 0xb825 && code <= 0xb83f) || // Lo  [27] HANGUL SYLLABLE RYEOG..HANGUL SYLLABLE RYEOH
      (code >= 0xb841 && code <= 0xb85b) || // Lo  [27] HANGUL SYLLABLE RYEG..HANGUL SYLLABLE RYEH
      (code >= 0xb85d && code <= 0xb877) || // Lo  [27] HANGUL SYLLABLE ROG..HANGUL SYLLABLE ROH
      (code >= 0xb879 && code <= 0xb893) || // Lo  [27] HANGUL SYLLABLE RWAG..HANGUL SYLLABLE RWAH
      (code >= 0xb895 && code <= 0xb8af) || // Lo  [27] HANGUL SYLLABLE RWAEG..HANGUL SYLLABLE RWAEH
      (code >= 0xb8b1 && code <= 0xb8cb) || // Lo  [27] HANGUL SYLLABLE ROEG..HANGUL SYLLABLE ROEH
      (code >= 0xb8cd && code <= 0xb8e7) || // Lo  [27] HANGUL SYLLABLE RYOG..HANGUL SYLLABLE RYOH
      (code >= 0xb8e9 && code <= 0xb903) || // Lo  [27] HANGUL SYLLABLE RUG..HANGUL SYLLABLE RUH
      (code >= 0xb905 && code <= 0xb91f) || // Lo  [27] HANGUL SYLLABLE RWEOG..HANGUL SYLLABLE RWEOH
      (code >= 0xb921 && code <= 0xb93b) || // Lo  [27] HANGUL SYLLABLE RWEG..HANGUL SYLLABLE RWEH
      (code >= 0xb93d && code <= 0xb957) || // Lo  [27] HANGUL SYLLABLE RWIG..HANGUL SYLLABLE RWIH
      (code >= 0xb959 && code <= 0xb973) || // Lo  [27] HANGUL SYLLABLE RYUG..HANGUL SYLLABLE RYUH
      (code >= 0xb975 && code <= 0xb98f) || // Lo  [27] HANGUL SYLLABLE REUG..HANGUL SYLLABLE REUH
      (code >= 0xb991 && code <= 0xb9ab) || // Lo  [27] HANGUL SYLLABLE RYIG..HANGUL SYLLABLE RYIH
      (code >= 0xb9ad && code <= 0xb9c7) || // Lo  [27] HANGUL SYLLABLE RIG..HANGUL SYLLABLE RIH
      (code >= 0xb9c9 && code <= 0xb9e3) || // Lo  [27] HANGUL SYLLABLE MAG..HANGUL SYLLABLE MAH
      (code >= 0xb9e5 && code <= 0xb9ff) || // Lo  [27] HANGUL SYLLABLE MAEG..HANGUL SYLLABLE MAEH
      (code >= 0xba01 && code <= 0xba1b) || // Lo  [27] HANGUL SYLLABLE MYAG..HANGUL SYLLABLE MYAH
      (code >= 0xba1d && code <= 0xba37) || // Lo  [27] HANGUL SYLLABLE MYAEG..HANGUL SYLLABLE MYAEH
      (code >= 0xba39 && code <= 0xba53) || // Lo  [27] HANGUL SYLLABLE MEOG..HANGUL SYLLABLE MEOH
      (code >= 0xba55 && code <= 0xba6f) || // Lo  [27] HANGUL SYLLABLE MEG..HANGUL SYLLABLE MEH
      (code >= 0xba71 && code <= 0xba8b) || // Lo  [27] HANGUL SYLLABLE MYEOG..HANGUL SYLLABLE MYEOH
      (code >= 0xba8d && code <= 0xbaa7) || // Lo  [27] HANGUL SYLLABLE MYEG..HANGUL SYLLABLE MYEH
      (code >= 0xbaa9 && code <= 0xbac3) || // Lo  [27] HANGUL SYLLABLE MOG..HANGUL SYLLABLE MOH
      (code >= 0xbac5 && code <= 0xbadf) || // Lo  [27] HANGUL SYLLABLE MWAG..HANGUL SYLLABLE MWAH
      (code >= 0xbae1 && code <= 0xbafb) || // Lo  [27] HANGUL SYLLABLE MWAEG..HANGUL SYLLABLE MWAEH
      (code >= 0xbafd && code <= 0xbb17) || // Lo  [27] HANGUL SYLLABLE MOEG..HANGUL SYLLABLE MOEH
      (code >= 0xbb19 && code <= 0xbb33) || // Lo  [27] HANGUL SYLLABLE MYOG..HANGUL SYLLABLE MYOH
      (code >= 0xbb35 && code <= 0xbb4f) || // Lo  [27] HANGUL SYLLABLE MUG..HANGUL SYLLABLE MUH
      (code >= 0xbb51 && code <= 0xbb6b) || // Lo  [27] HANGUL SYLLABLE MWEOG..HANGUL SYLLABLE MWEOH
      (code >= 0xbb6d && code <= 0xbb87) || // Lo  [27] HANGUL SYLLABLE MWEG..HANGUL SYLLABLE MWEH
      (code >= 0xbb89 && code <= 0xbba3) || // Lo  [27] HANGUL SYLLABLE MWIG..HANGUL SYLLABLE MWIH
      (code >= 0xbba5 && code <= 0xbbbf) || // Lo  [27] HANGUL SYLLABLE MYUG..HANGUL SYLLABLE MYUH
      (code >= 0xbbc1 && code <= 0xbbdb) || // Lo  [27] HANGUL SYLLABLE MEUG..HANGUL SYLLABLE MEUH
      (code >= 0xbbdd && code <= 0xbbf7) || // Lo  [27] HANGUL SYLLABLE MYIG..HANGUL SYLLABLE MYIH
      (code >= 0xbbf9 && code <= 0xbc13) || // Lo  [27] HANGUL SYLLABLE MIG..HANGUL SYLLABLE MIH
      (code >= 0xbc15 && code <= 0xbc2f) || // Lo  [27] HANGUL SYLLABLE BAG..HANGUL SYLLABLE BAH
      (code >= 0xbc31 && code <= 0xbc4b) || // Lo  [27] HANGUL SYLLABLE BAEG..HANGUL SYLLABLE BAEH
      (code >= 0xbc4d && code <= 0xbc67) || // Lo  [27] HANGUL SYLLABLE BYAG..HANGUL SYLLABLE BYAH
      (code >= 0xbc69 && code <= 0xbc83) || // Lo  [27] HANGUL SYLLABLE BYAEG..HANGUL SYLLABLE BYAEH
      (code >= 0xbc85 && code <= 0xbc9f) || // Lo  [27] HANGUL SYLLABLE BEOG..HANGUL SYLLABLE BEOH
      (code >= 0xbca1 && code <= 0xbcbb) || // Lo  [27] HANGUL SYLLABLE BEG..HANGUL SYLLABLE BEH
      (code >= 0xbcbd && code <= 0xbcd7) || // Lo  [27] HANGUL SYLLABLE BYEOG..HANGUL SYLLABLE BYEOH
      (code >= 0xbcd9 && code <= 0xbcf3) || // Lo  [27] HANGUL SYLLABLE BYEG..HANGUL SYLLABLE BYEH
      (code >= 0xbcf5 && code <= 0xbd0f) || // Lo  [27] HANGUL SYLLABLE BOG..HANGUL SYLLABLE BOH
      (code >= 0xbd11 && code <= 0xbd2b) || // Lo  [27] HANGUL SYLLABLE BWAG..HANGUL SYLLABLE BWAH
      (code >= 0xbd2d && code <= 0xbd47) || // Lo  [27] HANGUL SYLLABLE BWAEG..HANGUL SYLLABLE BWAEH
      (code >= 0xbd49 && code <= 0xbd63) || // Lo  [27] HANGUL SYLLABLE BOEG..HANGUL SYLLABLE BOEH
      (code >= 0xbd65 && code <= 0xbd7f) || // Lo  [27] HANGUL SYLLABLE BYOG..HANGUL SYLLABLE BYOH
      (code >= 0xbd81 && code <= 0xbd9b) || // Lo  [27] HANGUL SYLLABLE BUG..HANGUL SYLLABLE BUH
      (code >= 0xbd9d && code <= 0xbdb7) || // Lo  [27] HANGUL SYLLABLE BWEOG..HANGUL SYLLABLE BWEOH
      (code >= 0xbdb9 && code <= 0xbdd3) || // Lo  [27] HANGUL SYLLABLE BWEG..HANGUL SYLLABLE BWEH
      (code >= 0xbdd5 && code <= 0xbdef) || // Lo  [27] HANGUL SYLLABLE BWIG..HANGUL SYLLABLE BWIH
      (code >= 0xbdf1 && code <= 0xbe0b) || // Lo  [27] HANGUL SYLLABLE BYUG..HANGUL SYLLABLE BYUH
      (code >= 0xbe0d && code <= 0xbe27) || // Lo  [27] HANGUL SYLLABLE BEUG..HANGUL SYLLABLE BEUH
      (code >= 0xbe29 && code <= 0xbe43) || // Lo  [27] HANGUL SYLLABLE BYIG..HANGUL SYLLABLE BYIH
      (code >= 0xbe45 && code <= 0xbe5f) || // Lo  [27] HANGUL SYLLABLE BIG..HANGUL SYLLABLE BIH
      (code >= 0xbe61 && code <= 0xbe7b) || // Lo  [27] HANGUL SYLLABLE BBAG..HANGUL SYLLABLE BBAH
      (code >= 0xbe7d && code <= 0xbe97) || // Lo  [27] HANGUL SYLLABLE BBAEG..HANGUL SYLLABLE BBAEH
      (code >= 0xbe99 && code <= 0xbeb3) || // Lo  [27] HANGUL SYLLABLE BBYAG..HANGUL SYLLABLE BBYAH
      (code >= 0xbeb5 && code <= 0xbecf) || // Lo  [27] HANGUL SYLLABLE BBYAEG..HANGUL SYLLABLE BBYAEH
      (code >= 0xbed1 && code <= 0xbeeb) || // Lo  [27] HANGUL SYLLABLE BBEOG..HANGUL SYLLABLE BBEOH
      (code >= 0xbeed && code <= 0xbf07) || // Lo  [27] HANGUL SYLLABLE BBEG..HANGUL SYLLABLE BBEH
      (code >= 0xbf09 && code <= 0xbf23) || // Lo  [27] HANGUL SYLLABLE BBYEOG..HANGUL SYLLABLE BBYEOH
      (code >= 0xbf25 && code <= 0xbf3f) || // Lo  [27] HANGUL SYLLABLE BBYEG..HANGUL SYLLABLE BBYEH
      (code >= 0xbf41 && code <= 0xbf5b) || // Lo  [27] HANGUL SYLLABLE BBOG..HANGUL SYLLABLE BBOH
      (code >= 0xbf5d && code <= 0xbf77) || // Lo  [27] HANGUL SYLLABLE BBWAG..HANGUL SYLLABLE BBWAH
      (code >= 0xbf79 && code <= 0xbf93) || // Lo  [27] HANGUL SYLLABLE BBWAEG..HANGUL SYLLABLE BBWAEH
      (code >= 0xbf95 && code <= 0xbfaf) || // Lo  [27] HANGUL SYLLABLE BBOEG..HANGUL SYLLABLE BBOEH
      (code >= 0xbfb1 && code <= 0xbfcb) || // Lo  [27] HANGUL SYLLABLE BBYOG..HANGUL SYLLABLE BBYOH
      (code >= 0xbfcd && code <= 0xbfe7) || // Lo  [27] HANGUL SYLLABLE BBUG..HANGUL SYLLABLE BBUH
      (code >= 0xbfe9 && code <= 0xc003) || // Lo  [27] HANGUL SYLLABLE BBWEOG..HANGUL SYLLABLE BBWEOH
      (code >= 0xc005 && code <= 0xc01f) || // Lo  [27] HANGUL SYLLABLE BBWEG..HANGUL SYLLABLE BBWEH
      (code >= 0xc021 && code <= 0xc03b) || // Lo  [27] HANGUL SYLLABLE BBWIG..HANGUL SYLLABLE BBWIH
      (code >= 0xc03d && code <= 0xc057) || // Lo  [27] HANGUL SYLLABLE BBYUG..HANGUL SYLLABLE BBYUH
      (code >= 0xc059 && code <= 0xc073) || // Lo  [27] HANGUL SYLLABLE BBEUG..HANGUL SYLLABLE BBEUH
      (code >= 0xc075 && code <= 0xc08f) || // Lo  [27] HANGUL SYLLABLE BBYIG..HANGUL SYLLABLE BBYIH
      (code >= 0xc091 && code <= 0xc0ab) || // Lo  [27] HANGUL SYLLABLE BBIG..HANGUL SYLLABLE BBIH
      (code >= 0xc0ad && code <= 0xc0c7) || // Lo  [27] HANGUL SYLLABLE SAG..HANGUL SYLLABLE SAH
      (code >= 0xc0c9 && code <= 0xc0e3) || // Lo  [27] HANGUL SYLLABLE SAEG..HANGUL SYLLABLE SAEH
      (code >= 0xc0e5 && code <= 0xc0ff) || // Lo  [27] HANGUL SYLLABLE SYAG..HANGUL SYLLABLE SYAH
      (code >= 0xc101 && code <= 0xc11b) || // Lo  [27] HANGUL SYLLABLE SYAEG..HANGUL SYLLABLE SYAEH
      (code >= 0xc11d && code <= 0xc137) || // Lo  [27] HANGUL SYLLABLE SEOG..HANGUL SYLLABLE SEOH
      (code >= 0xc139 && code <= 0xc153) || // Lo  [27] HANGUL SYLLABLE SEG..HANGUL SYLLABLE SEH
      (code >= 0xc155 && code <= 0xc16f) || // Lo  [27] HANGUL SYLLABLE SYEOG..HANGUL SYLLABLE SYEOH
      (code >= 0xc171 && code <= 0xc18b) || // Lo  [27] HANGUL SYLLABLE SYEG..HANGUL SYLLABLE SYEH
      (code >= 0xc18d && code <= 0xc1a7) || // Lo  [27] HANGUL SYLLABLE SOG..HANGUL SYLLABLE SOH
      (code >= 0xc1a9 && code <= 0xc1c3) || // Lo  [27] HANGUL SYLLABLE SWAG..HANGUL SYLLABLE SWAH
      (code >= 0xc1c5 && code <= 0xc1df) || // Lo  [27] HANGUL SYLLABLE SWAEG..HANGUL SYLLABLE SWAEH
      (code >= 0xc1e1 && code <= 0xc1fb) || // Lo  [27] HANGUL SYLLABLE SOEG..HANGUL SYLLABLE SOEH
      (code >= 0xc1fd && code <= 0xc217) || // Lo  [27] HANGUL SYLLABLE SYOG..HANGUL SYLLABLE SYOH
      (code >= 0xc219 && code <= 0xc233) || // Lo  [27] HANGUL SYLLABLE SUG..HANGUL SYLLABLE SUH
      (code >= 0xc235 && code <= 0xc24f) || // Lo  [27] HANGUL SYLLABLE SWEOG..HANGUL SYLLABLE SWEOH
      (code >= 0xc251 && code <= 0xc26b) || // Lo  [27] HANGUL SYLLABLE SWEG..HANGUL SYLLABLE SWEH
      (code >= 0xc26d && code <= 0xc287) || // Lo  [27] HANGUL SYLLABLE SWIG..HANGUL SYLLABLE SWIH
      (code >= 0xc289 && code <= 0xc2a3) || // Lo  [27] HANGUL SYLLABLE SYUG..HANGUL SYLLABLE SYUH
      (code >= 0xc2a5 && code <= 0xc2bf) || // Lo  [27] HANGUL SYLLABLE SEUG..HANGUL SYLLABLE SEUH
      (code >= 0xc2c1 && code <= 0xc2db) || // Lo  [27] HANGUL SYLLABLE SYIG..HANGUL SYLLABLE SYIH
      (code >= 0xc2dd && code <= 0xc2f7) || // Lo  [27] HANGUL SYLLABLE SIG..HANGUL SYLLABLE SIH
      (code >= 0xc2f9 && code <= 0xc313) || // Lo  [27] HANGUL SYLLABLE SSAG..HANGUL SYLLABLE SSAH
      (code >= 0xc315 && code <= 0xc32f) || // Lo  [27] HANGUL SYLLABLE SSAEG..HANGUL SYLLABLE SSAEH
      (code >= 0xc331 && code <= 0xc34b) || // Lo  [27] HANGUL SYLLABLE SSYAG..HANGUL SYLLABLE SSYAH
      (code >= 0xc34d && code <= 0xc367) || // Lo  [27] HANGUL SYLLABLE SSYAEG..HANGUL SYLLABLE SSYAEH
      (code >= 0xc369 && code <= 0xc383) || // Lo  [27] HANGUL SYLLABLE SSEOG..HANGUL SYLLABLE SSEOH
      (code >= 0xc385 && code <= 0xc39f) || // Lo  [27] HANGUL SYLLABLE SSEG..HANGUL SYLLABLE SSEH
      (code >= 0xc3a1 && code <= 0xc3bb) || // Lo  [27] HANGUL SYLLABLE SSYEOG..HANGUL SYLLABLE SSYEOH
      (code >= 0xc3bd && code <= 0xc3d7) || // Lo  [27] HANGUL SYLLABLE SSYEG..HANGUL SYLLABLE SSYEH
      (code >= 0xc3d9 && code <= 0xc3f3) || // Lo  [27] HANGUL SYLLABLE SSOG..HANGUL SYLLABLE SSOH
      (code >= 0xc3f5 && code <= 0xc40f) || // Lo  [27] HANGUL SYLLABLE SSWAG..HANGUL SYLLABLE SSWAH
      (code >= 0xc411 && code <= 0xc42b) || // Lo  [27] HANGUL SYLLABLE SSWAEG..HANGUL SYLLABLE SSWAEH
      (code >= 0xc42d && code <= 0xc447) || // Lo  [27] HANGUL SYLLABLE SSOEG..HANGUL SYLLABLE SSOEH
      (code >= 0xc449 && code <= 0xc463) || // Lo  [27] HANGUL SYLLABLE SSYOG..HANGUL SYLLABLE SSYOH
      (code >= 0xc465 && code <= 0xc47f) || // Lo  [27] HANGUL SYLLABLE SSUG..HANGUL SYLLABLE SSUH
      (code >= 0xc481 && code <= 0xc49b) || // Lo  [27] HANGUL SYLLABLE SSWEOG..HANGUL SYLLABLE SSWEOH
      (code >= 0xc49d && code <= 0xc4b7) || // Lo  [27] HANGUL SYLLABLE SSWEG..HANGUL SYLLABLE SSWEH
      (code >= 0xc4b9 && code <= 0xc4d3) || // Lo  [27] HANGUL SYLLABLE SSWIG..HANGUL SYLLABLE SSWIH
      (code >= 0xc4d5 && code <= 0xc4ef) || // Lo  [27] HANGUL SYLLABLE SSYUG..HANGUL SYLLABLE SSYUH
      (code >= 0xc4f1 && code <= 0xc50b) || // Lo  [27] HANGUL SYLLABLE SSEUG..HANGUL SYLLABLE SSEUH
      (code >= 0xc50d && code <= 0xc527) || // Lo  [27] HANGUL SYLLABLE SSYIG..HANGUL SYLLABLE SSYIH
      (code >= 0xc529 && code <= 0xc543) || // Lo  [27] HANGUL SYLLABLE SSIG..HANGUL SYLLABLE SSIH
      (code >= 0xc545 && code <= 0xc55f) || // Lo  [27] HANGUL SYLLABLE AG..HANGUL SYLLABLE AH
      (code >= 0xc561 && code <= 0xc57b) || // Lo  [27] HANGUL SYLLABLE AEG..HANGUL SYLLABLE AEH
      (code >= 0xc57d && code <= 0xc597) || // Lo  [27] HANGUL SYLLABLE YAG..HANGUL SYLLABLE YAH
      (code >= 0xc599 && code <= 0xc5b3) || // Lo  [27] HANGUL SYLLABLE YAEG..HANGUL SYLLABLE YAEH
      (code >= 0xc5b5 && code <= 0xc5cf) || // Lo  [27] HANGUL SYLLABLE EOG..HANGUL SYLLABLE EOH
      (code >= 0xc5d1 && code <= 0xc5eb) || // Lo  [27] HANGUL SYLLABLE EG..HANGUL SYLLABLE EH
      (code >= 0xc5ed && code <= 0xc607) || // Lo  [27] HANGUL SYLLABLE YEOG..HANGUL SYLLABLE YEOH
      (code >= 0xc609 && code <= 0xc623) || // Lo  [27] HANGUL SYLLABLE YEG..HANGUL SYLLABLE YEH
      (code >= 0xc625 && code <= 0xc63f) || // Lo  [27] HANGUL SYLLABLE OG..HANGUL SYLLABLE OH
      (code >= 0xc641 && code <= 0xc65b) || // Lo  [27] HANGUL SYLLABLE WAG..HANGUL SYLLABLE WAH
      (code >= 0xc65d && code <= 0xc677) || // Lo  [27] HANGUL SYLLABLE WAEG..HANGUL SYLLABLE WAEH
      (code >= 0xc679 && code <= 0xc693) || // Lo  [27] HANGUL SYLLABLE OEG..HANGUL SYLLABLE OEH
      (code >= 0xc695 && code <= 0xc6af) || // Lo  [27] HANGUL SYLLABLE YOG..HANGUL SYLLABLE YOH
      (code >= 0xc6b1 && code <= 0xc6cb) || // Lo  [27] HANGUL SYLLABLE UG..HANGUL SYLLABLE UH
      (code >= 0xc6cd && code <= 0xc6e7) || // Lo  [27] HANGUL SYLLABLE WEOG..HANGUL SYLLABLE WEOH
      (code >= 0xc6e9 && code <= 0xc703) || // Lo  [27] HANGUL SYLLABLE WEG..HANGUL SYLLABLE WEH
      (code >= 0xc705 && code <= 0xc71f) || // Lo  [27] HANGUL SYLLABLE WIG..HANGUL SYLLABLE WIH
      (code >= 0xc721 && code <= 0xc73b) || // Lo  [27] HANGUL SYLLABLE YUG..HANGUL SYLLABLE YUH
      (code >= 0xc73d && code <= 0xc757) || // Lo  [27] HANGUL SYLLABLE EUG..HANGUL SYLLABLE EUH
      (code >= 0xc759 && code <= 0xc773) || // Lo  [27] HANGUL SYLLABLE YIG..HANGUL SYLLABLE YIH
      (code >= 0xc775 && code <= 0xc78f) || // Lo  [27] HANGUL SYLLABLE IG..HANGUL SYLLABLE IH
      (code >= 0xc791 && code <= 0xc7ab) || // Lo  [27] HANGUL SYLLABLE JAG..HANGUL SYLLABLE JAH
      (code >= 0xc7ad && code <= 0xc7c7) || // Lo  [27] HANGUL SYLLABLE JAEG..HANGUL SYLLABLE JAEH
      (code >= 0xc7c9 && code <= 0xc7e3) || // Lo  [27] HANGUL SYLLABLE JYAG..HANGUL SYLLABLE JYAH
      (code >= 0xc7e5 && code <= 0xc7ff) || // Lo  [27] HANGUL SYLLABLE JYAEG..HANGUL SYLLABLE JYAEH
      (code >= 0xc801 && code <= 0xc81b) || // Lo  [27] HANGUL SYLLABLE JEOG..HANGUL SYLLABLE JEOH
      (code >= 0xc81d && code <= 0xc837) || // Lo  [27] HANGUL SYLLABLE JEG..HANGUL SYLLABLE JEH
      (code >= 0xc839 && code <= 0xc853) || // Lo  [27] HANGUL SYLLABLE JYEOG..HANGUL SYLLABLE JYEOH
      (code >= 0xc855 && code <= 0xc86f) || // Lo  [27] HANGUL SYLLABLE JYEG..HANGUL SYLLABLE JYEH
      (code >= 0xc871 && code <= 0xc88b) || // Lo  [27] HANGUL SYLLABLE JOG..HANGUL SYLLABLE JOH
      (code >= 0xc88d && code <= 0xc8a7) || // Lo  [27] HANGUL SYLLABLE JWAG..HANGUL SYLLABLE JWAH
      (code >= 0xc8a9 && code <= 0xc8c3) || // Lo  [27] HANGUL SYLLABLE JWAEG..HANGUL SYLLABLE JWAEH
      (code >= 0xc8c5 && code <= 0xc8df) || // Lo  [27] HANGUL SYLLABLE JOEG..HANGUL SYLLABLE JOEH
      (code >= 0xc8e1 && code <= 0xc8fb) || // Lo  [27] HANGUL SYLLABLE JYOG..HANGUL SYLLABLE JYOH
      (code >= 0xc8fd && code <= 0xc917) || // Lo  [27] HANGUL SYLLABLE JUG..HANGUL SYLLABLE JUH
      (code >= 0xc919 && code <= 0xc933) || // Lo  [27] HANGUL SYLLABLE JWEOG..HANGUL SYLLABLE JWEOH
      (code >= 0xc935 && code <= 0xc94f) || // Lo  [27] HANGUL SYLLABLE JWEG..HANGUL SYLLABLE JWEH
      (code >= 0xc951 && code <= 0xc96b) || // Lo  [27] HANGUL SYLLABLE JWIG..HANGUL SYLLABLE JWIH
      (code >= 0xc96d && code <= 0xc987) || // Lo  [27] HANGUL SYLLABLE JYUG..HANGUL SYLLABLE JYUH
      (code >= 0xc989 && code <= 0xc9a3) || // Lo  [27] HANGUL SYLLABLE JEUG..HANGUL SYLLABLE JEUH
      (code >= 0xc9a5 && code <= 0xc9bf) || // Lo  [27] HANGUL SYLLABLE JYIG..HANGUL SYLLABLE JYIH
      (code >= 0xc9c1 && code <= 0xc9db) || // Lo  [27] HANGUL SYLLABLE JIG..HANGUL SYLLABLE JIH
      (code >= 0xc9dd && code <= 0xc9f7) || // Lo  [27] HANGUL SYLLABLE JJAG..HANGUL SYLLABLE JJAH
      (code >= 0xc9f9 && code <= 0xca13) || // Lo  [27] HANGUL SYLLABLE JJAEG..HANGUL SYLLABLE JJAEH
      (code >= 0xca15 && code <= 0xca2f) || // Lo  [27] HANGUL SYLLABLE JJYAG..HANGUL SYLLABLE JJYAH
      (code >= 0xca31 && code <= 0xca4b) || // Lo  [27] HANGUL SYLLABLE JJYAEG..HANGUL SYLLABLE JJYAEH
      (code >= 0xca4d && code <= 0xca67) || // Lo  [27] HANGUL SYLLABLE JJEOG..HANGUL SYLLABLE JJEOH
      (code >= 0xca69 && code <= 0xca83) || // Lo  [27] HANGUL SYLLABLE JJEG..HANGUL SYLLABLE JJEH
      (code >= 0xca85 && code <= 0xca9f) || // Lo  [27] HANGUL SYLLABLE JJYEOG..HANGUL SYLLABLE JJYEOH
      (code >= 0xcaa1 && code <= 0xcabb) || // Lo  [27] HANGUL SYLLABLE JJYEG..HANGUL SYLLABLE JJYEH
      (code >= 0xcabd && code <= 0xcad7) || // Lo  [27] HANGUL SYLLABLE JJOG..HANGUL SYLLABLE JJOH
      (code >= 0xcad9 && code <= 0xcaf3) || // Lo  [27] HANGUL SYLLABLE JJWAG..HANGUL SYLLABLE JJWAH
      (code >= 0xcaf5 && code <= 0xcb0f) || // Lo  [27] HANGUL SYLLABLE JJWAEG..HANGUL SYLLABLE JJWAEH
      (code >= 0xcb11 && code <= 0xcb2b) || // Lo  [27] HANGUL SYLLABLE JJOEG..HANGUL SYLLABLE JJOEH
      (code >= 0xcb2d && code <= 0xcb47) || // Lo  [27] HANGUL SYLLABLE JJYOG..HANGUL SYLLABLE JJYOH
      (code >= 0xcb49 && code <= 0xcb63) || // Lo  [27] HANGUL SYLLABLE JJUG..HANGUL SYLLABLE JJUH
      (code >= 0xcb65 && code <= 0xcb7f) || // Lo  [27] HANGUL SYLLABLE JJWEOG..HANGUL SYLLABLE JJWEOH
      (code >= 0xcb81 && code <= 0xcb9b) || // Lo  [27] HANGUL SYLLABLE JJWEG..HANGUL SYLLABLE JJWEH
      (code >= 0xcb9d && code <= 0xcbb7) || // Lo  [27] HANGUL SYLLABLE JJWIG..HANGUL SYLLABLE JJWIH
      (code >= 0xcbb9 && code <= 0xcbd3) || // Lo  [27] HANGUL SYLLABLE JJYUG..HANGUL SYLLABLE JJYUH
      (code >= 0xcbd5 && code <= 0xcbef) || // Lo  [27] HANGUL SYLLABLE JJEUG..HANGUL SYLLABLE JJEUH
      (code >= 0xcbf1 && code <= 0xcc0b) || // Lo  [27] HANGUL SYLLABLE JJYIG..HANGUL SYLLABLE JJYIH
      (code >= 0xcc0d && code <= 0xcc27) || // Lo  [27] HANGUL SYLLABLE JJIG..HANGUL SYLLABLE JJIH
      (code >= 0xcc29 && code <= 0xcc43) || // Lo  [27] HANGUL SYLLABLE CAG..HANGUL SYLLABLE CAH
      (code >= 0xcc45 && code <= 0xcc5f) || // Lo  [27] HANGUL SYLLABLE CAEG..HANGUL SYLLABLE CAEH
      (code >= 0xcc61 && code <= 0xcc7b) || // Lo  [27] HANGUL SYLLABLE CYAG..HANGUL SYLLABLE CYAH
      (code >= 0xcc7d && code <= 0xcc97) || // Lo  [27] HANGUL SYLLABLE CYAEG..HANGUL SYLLABLE CYAEH
      (code >= 0xcc99 && code <= 0xccb3) || // Lo  [27] HANGUL SYLLABLE CEOG..HANGUL SYLLABLE CEOH
      (code >= 0xccb5 && code <= 0xcccf) || // Lo  [27] HANGUL SYLLABLE CEG..HANGUL SYLLABLE CEH
      (code >= 0xccd1 && code <= 0xcceb) || // Lo  [27] HANGUL SYLLABLE CYEOG..HANGUL SYLLABLE CYEOH
      (code >= 0xcced && code <= 0xcd07) || // Lo  [27] HANGUL SYLLABLE CYEG..HANGUL SYLLABLE CYEH
      (code >= 0xcd09 && code <= 0xcd23) || // Lo  [27] HANGUL SYLLABLE COG..HANGUL SYLLABLE COH
      (code >= 0xcd25 && code <= 0xcd3f) || // Lo  [27] HANGUL SYLLABLE CWAG..HANGUL SYLLABLE CWAH
      (code >= 0xcd41 && code <= 0xcd5b) || // Lo  [27] HANGUL SYLLABLE CWAEG..HANGUL SYLLABLE CWAEH
      (code >= 0xcd5d && code <= 0xcd77) || // Lo  [27] HANGUL SYLLABLE COEG..HANGUL SYLLABLE COEH
      (code >= 0xcd79 && code <= 0xcd93) || // Lo  [27] HANGUL SYLLABLE CYOG..HANGUL SYLLABLE CYOH
      (code >= 0xcd95 && code <= 0xcdaf) || // Lo  [27] HANGUL SYLLABLE CUG..HANGUL SYLLABLE CUH
      (code >= 0xcdb1 && code <= 0xcdcb) || // Lo  [27] HANGUL SYLLABLE CWEOG..HANGUL SYLLABLE CWEOH
      (code >= 0xcdcd && code <= 0xcde7) || // Lo  [27] HANGUL SYLLABLE CWEG..HANGUL SYLLABLE CWEH
      (code >= 0xcde9 && code <= 0xce03) || // Lo  [27] HANGUL SYLLABLE CWIG..HANGUL SYLLABLE CWIH
      (code >= 0xce05 && code <= 0xce1f) || // Lo  [27] HANGUL SYLLABLE CYUG..HANGUL SYLLABLE CYUH
      (code >= 0xce21 && code <= 0xce3b) || // Lo  [27] HANGUL SYLLABLE CEUG..HANGUL SYLLABLE CEUH
      (code >= 0xce3d && code <= 0xce57) || // Lo  [27] HANGUL SYLLABLE CYIG..HANGUL SYLLABLE CYIH
      (code >= 0xce59 && code <= 0xce73) || // Lo  [27] HANGUL SYLLABLE CIG..HANGUL SYLLABLE CIH
      (code >= 0xce75 && code <= 0xce8f) || // Lo  [27] HANGUL SYLLABLE KAG..HANGUL SYLLABLE KAH
      (code >= 0xce91 && code <= 0xceab) || // Lo  [27] HANGUL SYLLABLE KAEG..HANGUL SYLLABLE KAEH
      (code >= 0xcead && code <= 0xcec7) || // Lo  [27] HANGUL SYLLABLE KYAG..HANGUL SYLLABLE KYAH
      (code >= 0xcec9 && code <= 0xcee3) || // Lo  [27] HANGUL SYLLABLE KYAEG..HANGUL SYLLABLE KYAEH
      (code >= 0xcee5 && code <= 0xceff) || // Lo  [27] HANGUL SYLLABLE KEOG..HANGUL SYLLABLE KEOH
      (code >= 0xcf01 && code <= 0xcf1b) || // Lo  [27] HANGUL SYLLABLE KEG..HANGUL SYLLABLE KEH
      (code >= 0xcf1d && code <= 0xcf37) || // Lo  [27] HANGUL SYLLABLE KYEOG..HANGUL SYLLABLE KYEOH
      (code >= 0xcf39 && code <= 0xcf53) || // Lo  [27] HANGUL SYLLABLE KYEG..HANGUL SYLLABLE KYEH
      (code >= 0xcf55 && code <= 0xcf6f) || // Lo  [27] HANGUL SYLLABLE KOG..HANGUL SYLLABLE KOH
      (code >= 0xcf71 && code <= 0xcf8b) || // Lo  [27] HANGUL SYLLABLE KWAG..HANGUL SYLLABLE KWAH
      (code >= 0xcf8d && code <= 0xcfa7) || // Lo  [27] HANGUL SYLLABLE KWAEG..HANGUL SYLLABLE KWAEH
      (code >= 0xcfa9 && code <= 0xcfc3) || // Lo  [27] HANGUL SYLLABLE KOEG..HANGUL SYLLABLE KOEH
      (code >= 0xcfc5 && code <= 0xcfdf) || // Lo  [27] HANGUL SYLLABLE KYOG..HANGUL SYLLABLE KYOH
      (code >= 0xcfe1 && code <= 0xcffb) || // Lo  [27] HANGUL SYLLABLE KUG..HANGUL SYLLABLE KUH
      (code >= 0xcffd && code <= 0xd017) || // Lo  [27] HANGUL SYLLABLE KWEOG..HANGUL SYLLABLE KWEOH
      (code >= 0xd019 && code <= 0xd033) || // Lo  [27] HANGUL SYLLABLE KWEG..HANGUL SYLLABLE KWEH
      (code >= 0xd035 && code <= 0xd04f) || // Lo  [27] HANGUL SYLLABLE KWIG..HANGUL SYLLABLE KWIH
      (code >= 0xd051 && code <= 0xd06b) || // Lo  [27] HANGUL SYLLABLE KYUG..HANGUL SYLLABLE KYUH
      (code >= 0xd06d && code <= 0xd087) || // Lo  [27] HANGUL SYLLABLE KEUG..HANGUL SYLLABLE KEUH
      (code >= 0xd089 && code <= 0xd0a3) || // Lo  [27] HANGUL SYLLABLE KYIG..HANGUL SYLLABLE KYIH
      (code >= 0xd0a5 && code <= 0xd0bf) || // Lo  [27] HANGUL SYLLABLE KIG..HANGUL SYLLABLE KIH
      (code >= 0xd0c1 && code <= 0xd0db) || // Lo  [27] HANGUL SYLLABLE TAG..HANGUL SYLLABLE TAH
      (code >= 0xd0dd && code <= 0xd0f7) || // Lo  [27] HANGUL SYLLABLE TAEG..HANGUL SYLLABLE TAEH
      (code >= 0xd0f9 && code <= 0xd113) || // Lo  [27] HANGUL SYLLABLE TYAG..HANGUL SYLLABLE TYAH
      (code >= 0xd115 && code <= 0xd12f) || // Lo  [27] HANGUL SYLLABLE TYAEG..HANGUL SYLLABLE TYAEH
      (code >= 0xd131 && code <= 0xd14b) || // Lo  [27] HANGUL SYLLABLE TEOG..HANGUL SYLLABLE TEOH
      (code >= 0xd14d && code <= 0xd167) || // Lo  [27] HANGUL SYLLABLE TEG..HANGUL SYLLABLE TEH
      (code >= 0xd169 && code <= 0xd183) || // Lo  [27] HANGUL SYLLABLE TYEOG..HANGUL SYLLABLE TYEOH
      (code >= 0xd185 && code <= 0xd19f) || // Lo  [27] HANGUL SYLLABLE TYEG..HANGUL SYLLABLE TYEH
      (code >= 0xd1a1 && code <= 0xd1bb) || // Lo  [27] HANGUL SYLLABLE TOG..HANGUL SYLLABLE TOH
      (code >= 0xd1bd && code <= 0xd1d7) || // Lo  [27] HANGUL SYLLABLE TWAG..HANGUL SYLLABLE TWAH
      (code >= 0xd1d9 && code <= 0xd1f3) || // Lo  [27] HANGUL SYLLABLE TWAEG..HANGUL SYLLABLE TWAEH
      (code >= 0xd1f5 && code <= 0xd20f) || // Lo  [27] HANGUL SYLLABLE TOEG..HANGUL SYLLABLE TOEH
      (code >= 0xd211 && code <= 0xd22b) || // Lo  [27] HANGUL SYLLABLE TYOG..HANGUL SYLLABLE TYOH
      (code >= 0xd22d && code <= 0xd247) || // Lo  [27] HANGUL SYLLABLE TUG..HANGUL SYLLABLE TUH
      (code >= 0xd249 && code <= 0xd263) || // Lo  [27] HANGUL SYLLABLE TWEOG..HANGUL SYLLABLE TWEOH
      (code >= 0xd265 && code <= 0xd27f) || // Lo  [27] HANGUL SYLLABLE TWEG..HANGUL SYLLABLE TWEH
      (code >= 0xd281 && code <= 0xd29b) || // Lo  [27] HANGUL SYLLABLE TWIG..HANGUL SYLLABLE TWIH
      (code >= 0xd29d && code <= 0xd2b7) || // Lo  [27] HANGUL SYLLABLE TYUG..HANGUL SYLLABLE TYUH
      (code >= 0xd2b9 && code <= 0xd2d3) || // Lo  [27] HANGUL SYLLABLE TEUG..HANGUL SYLLABLE TEUH
      (code >= 0xd2d5 && code <= 0xd2ef) || // Lo  [27] HANGUL SYLLABLE TYIG..HANGUL SYLLABLE TYIH
      (code >= 0xd2f1 && code <= 0xd30b) || // Lo  [27] HANGUL SYLLABLE TIG..HANGUL SYLLABLE TIH
      (code >= 0xd30d && code <= 0xd327) || // Lo  [27] HANGUL SYLLABLE PAG..HANGUL SYLLABLE PAH
      (code >= 0xd329 && code <= 0xd343) || // Lo  [27] HANGUL SYLLABLE PAEG..HANGUL SYLLABLE PAEH
      (code >= 0xd345 && code <= 0xd35f) || // Lo  [27] HANGUL SYLLABLE PYAG..HANGUL SYLLABLE PYAH
      (code >= 0xd361 && code <= 0xd37b) || // Lo  [27] HANGUL SYLLABLE PYAEG..HANGUL SYLLABLE PYAEH
      (code >= 0xd37d && code <= 0xd397) || // Lo  [27] HANGUL SYLLABLE PEOG..HANGUL SYLLABLE PEOH
      (code >= 0xd399 && code <= 0xd3b3) || // Lo  [27] HANGUL SYLLABLE PEG..HANGUL SYLLABLE PEH
      (code >= 0xd3b5 && code <= 0xd3cf) || // Lo  [27] HANGUL SYLLABLE PYEOG..HANGUL SYLLABLE PYEOH
      (code >= 0xd3d1 && code <= 0xd3eb) || // Lo  [27] HANGUL SYLLABLE PYEG..HANGUL SYLLABLE PYEH
      (code >= 0xd3ed && code <= 0xd407) || // Lo  [27] HANGUL SYLLABLE POG..HANGUL SYLLABLE POH
      (code >= 0xd409 && code <= 0xd423) || // Lo  [27] HANGUL SYLLABLE PWAG..HANGUL SYLLABLE PWAH
      (code >= 0xd425 && code <= 0xd43f) || // Lo  [27] HANGUL SYLLABLE PWAEG..HANGUL SYLLABLE PWAEH
      (code >= 0xd441 && code <= 0xd45b) || // Lo  [27] HANGUL SYLLABLE POEG..HANGUL SYLLABLE POEH
      (code >= 0xd45d && code <= 0xd477) || // Lo  [27] HANGUL SYLLABLE PYOG..HANGUL SYLLABLE PYOH
      (code >= 0xd479 && code <= 0xd493) || // Lo  [27] HANGUL SYLLABLE PUG..HANGUL SYLLABLE PUH
      (code >= 0xd495 && code <= 0xd4af) || // Lo  [27] HANGUL SYLLABLE PWEOG..HANGUL SYLLABLE PWEOH
      (code >= 0xd4b1 && code <= 0xd4cb) || // Lo  [27] HANGUL SYLLABLE PWEG..HANGUL SYLLABLE PWEH
      (code >= 0xd4cd && code <= 0xd4e7) || // Lo  [27] HANGUL SYLLABLE PWIG..HANGUL SYLLABLE PWIH
      (code >= 0xd4e9 && code <= 0xd503) || // Lo  [27] HANGUL SYLLABLE PYUG..HANGUL SYLLABLE PYUH
      (code >= 0xd505 && code <= 0xd51f) || // Lo  [27] HANGUL SYLLABLE PEUG..HANGUL SYLLABLE PEUH
      (code >= 0xd521 && code <= 0xd53b) || // Lo  [27] HANGUL SYLLABLE PYIG..HANGUL SYLLABLE PYIH
      (code >= 0xd53d && code <= 0xd557) || // Lo  [27] HANGUL SYLLABLE PIG..HANGUL SYLLABLE PIH
      (code >= 0xd559 && code <= 0xd573) || // Lo  [27] HANGUL SYLLABLE HAG..HANGUL SYLLABLE HAH
      (code >= 0xd575 && code <= 0xd58f) || // Lo  [27] HANGUL SYLLABLE HAEG..HANGUL SYLLABLE HAEH
      (code >= 0xd591 && code <= 0xd5ab) || // Lo  [27] HANGUL SYLLABLE HYAG..HANGUL SYLLABLE HYAH
      (code >= 0xd5ad && code <= 0xd5c7) || // Lo  [27] HANGUL SYLLABLE HYAEG..HANGUL SYLLABLE HYAEH
      (code >= 0xd5c9 && code <= 0xd5e3) || // Lo  [27] HANGUL SYLLABLE HEOG..HANGUL SYLLABLE HEOH
      (code >= 0xd5e5 && code <= 0xd5ff) || // Lo  [27] HANGUL SYLLABLE HEG..HANGUL SYLLABLE HEH
      (code >= 0xd601 && code <= 0xd61b) || // Lo  [27] HANGUL SYLLABLE HYEOG..HANGUL SYLLABLE HYEOH
      (code >= 0xd61d && code <= 0xd637) || // Lo  [27] HANGUL SYLLABLE HYEG..HANGUL SYLLABLE HYEH
      (code >= 0xd639 && code <= 0xd653) || // Lo  [27] HANGUL SYLLABLE HOG..HANGUL SYLLABLE HOH
      (code >= 0xd655 && code <= 0xd66f) || // Lo  [27] HANGUL SYLLABLE HWAG..HANGUL SYLLABLE HWAH
      (code >= 0xd671 && code <= 0xd68b) || // Lo  [27] HANGUL SYLLABLE HWAEG..HANGUL SYLLABLE HWAEH
      (code >= 0xd68d && code <= 0xd6a7) || // Lo  [27] HANGUL SYLLABLE HOEG..HANGUL SYLLABLE HOEH
      (code >= 0xd6a9 && code <= 0xd6c3) || // Lo  [27] HANGUL SYLLABLE HYOG..HANGUL SYLLABLE HYOH
      (code >= 0xd6c5 && code <= 0xd6df) || // Lo  [27] HANGUL SYLLABLE HUG..HANGUL SYLLABLE HUH
      (code >= 0xd6e1 && code <= 0xd6fb) || // Lo  [27] HANGUL SYLLABLE HWEOG..HANGUL SYLLABLE HWEOH
      (code >= 0xd6fd && code <= 0xd717) || // Lo  [27] HANGUL SYLLABLE HWEG..HANGUL SYLLABLE HWEH
      (code >= 0xd719 && code <= 0xd733) || // Lo  [27] HANGUL SYLLABLE HWIG..HANGUL SYLLABLE HWIH
      (code >= 0xd735 && code <= 0xd74f) || // Lo  [27] HANGUL SYLLABLE HYUG..HANGUL SYLLABLE HYUH
      (code >= 0xd751 && code <= 0xd76b) || // Lo  [27] HANGUL SYLLABLE HEUG..HANGUL SYLLABLE HEUH
      (code >= 0xd76d && code <= 0xd787) || // Lo  [27] HANGUL SYLLABLE HYIG..HANGUL SYLLABLE HYIH
      (code >= 0xd789 && code <= 0xd7a3) // Lo  [27] HANGUL SYLLABLE HIG..HANGUL SYLLABLE HIH
    ) {
      return LVT;
    }

    //all unlisted characters have a grapheme break property of "Other"
    return Other;
  }

  // Breaks the given string into an array of grapheme cluster strings
  this.splitGraphemes = function(str) {
    var res = [];
    var index = 0;
    var brk;
    while ((brk = this.nextBreak(str, index)) < str.length) {
      res.push(str.slice(index, brk));
      index = brk;
    }
    if (index < str.length) {
      res.push(str.slice(index));
    }
    return res;
  };

  // Returns the number of grapheme clusters there are in the given string
  this.countGraphemes = function(str) {
    var count = 0;
    var index = 0;
    var brk;
    while ((brk = this.nextBreak(str, index)) < str.length) {
      index = brk;
      count++;
    }
    if (index < str.length) {
      count++;
    }
    return count;
  };

  return this;
}
