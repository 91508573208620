/* @flow */
import * as React from 'react';
import { InstructionsHeader, Translation, TitleHead, PlayButton } from '.';
import styled from 'styled-components';
import { GREY2 } from '../../../common/constants';
import { VoiceSpeedButton } from './VoiceSpeedButton';
type Props = {
  children?: any,
  instruction: string,
  soundFile: string,
  transcript?: string,
  isPlayable?: boolean,
  isTranslateGame?: boolean,
  isCorrectGame?: boolean,
  translate?: string,
  voiceSpeed: number,
  isShowTutorial: boolean
};

const SpellingHeaderContainer = styled.div`
  text-align: center;
  margin-bottom: ${props => (props.isShowTutorial ? 0 : 43)}px;
  margin-top: 50px;
  @media (max-height: 740px) {
    margin-top: ${props =>
      props.isTranslateGame || props.isCorrectGame ? 0 : 50}px;
  }
  @media (max-height: 680px) {
    margin-top: ${props =>
      props.isTranslateGame ? 0 : props.isShowTutorial ? 25 : 50}px;
    margin-bottom: 0;
  }
`;

const TranslationText = styled.div`
  font-size: 26px;
  margin-top: 10px;
`;

const TranscriptText = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-top: 8px;
  color: ${GREY2};
`;

const SoundButton = styled.div`
  display: flex;
  justify-content: center;
`;

const QuestionHeader = ({
  children,
  instruction,
  isTranslateGame,
  isCorrectGame,
  soundFile,
  transcript = '',
  isPlayable = true,
  translate = '',
  voiceSpeed,
  isShowTutorial
}: Props) => {
  const showTranslation = (
    <Translation>
      <TitleHead>{children}</TitleHead>
      {isPlayable ? (
        <SoundButton>
          <PlayButton soundFile={soundFile} voiceSpeed={voiceSpeed} />
          <VoiceSpeedButton soundFile={soundFile} voiceSpeed={voiceSpeed} />
        </SoundButton>
      ) : null}
    </Translation>
  );

  const showTranslate = translate ? (
    <Translation>
      <p>{translate}</p>
    </Translation>
  ) : null;

  return (
    <SpellingHeaderContainer
      isTranslateGame={isTranslateGame}
      isCorrectGame={isCorrectGame}
      isShowTutorial={isShowTutorial}
    >
      <InstructionsHeader>{instruction}</InstructionsHeader>
      <TranslationText>{showTranslation}</TranslationText>
      {transcript ? (
        <Translation>
          <TranscriptText>{transcript}</TranscriptText>
        </Translation>
      ) : (
        <Translation>
          <TranscriptText>&nbsp;</TranscriptText>
        </Translation>
      )}
      {showTranslate}
    </SpellingHeaderContainer>
  );
};

export { QuestionHeader };
