import { connect } from 'react-redux';
import _ from 'lodash';
import { ChatbotsSceneWithRouter } from '../components/scenes/ChatbotsScene';
import {
  getFlagTargetLanguage,
  getCourseUser,
  isUserSubscribed
} from '../../profile/selectors';
import { getLabels, getLabel } from '../../util';
import { startChatBot, exitChatbot } from '../actions';
import { getChatbotTopicsData, getChatbotTopicsInfoData } from '../selectors';
import { fetchUnitData } from '../../common/actions/LanguageActions';
import { toggleChatbotLoaded } from '../../common/actions/GlobalActions';

export const mapStateToProps = (state: Object) => {
  const chatbotsData = getChatbotTopicsData(state);
  const chatbotsInfo = getChatbotTopicsInfoData(state);
  const { currentChatbotId, progressChatbot = {} } = getCourseUser(state);
  const numberFreeChatbots = 3;

  const chatbots = _.map(chatbotsData, (chatbotId: string, index: number) => {
    const chatbotInfo = chatbotsInfo[chatbotId];
    const label = chatbotInfo ? chatbotInfo.label : '';

    return {
      id: chatbotId,
      name: getLabel(state, label, label),
      isChatted: progressChatbot[chatbotId]
        ? progressChatbot[chatbotId].isFinished
        : false,
      isActive: index < numberFreeChatbots || isUserSubscribed(state)
    };
  });
  const {
    data: { targetLangCode, nativeLangCode },
    storage
  } = state;

  const { isLoading, chatbotLoaded, noAccessChatbotUnits = [] } = storage;

  return {
    chatbots,
    isLoading,
    chatbotLoaded,
    currentChatbotId,
    targetLangCode,
    nativeLangCode,
    noAccessChatbotUnits,
    ...getFlagTargetLanguage(state),
    ...getLabels(
      state,
      'chatbot_txt_learning',
      'modal_txt_internet_offline',
      'btn_continue'
    )
  };
};

export const mapDispatchToProps = (dispatch: Function) => ({
  loadChatbotsData: (chatbotId: string) => dispatch(startChatBot(chatbotId)),
  resetChatbot: () => dispatch(exitChatbot()),
  toggleChatbotLoaded: (chatbotLoaded: string) =>
    dispatch(toggleChatbotLoaded(chatbotLoaded)),
  fetchUnitData: (
    target: string,
    native: string,
    unitId?: number,
    afterCallback?: Function
  ) => dispatch(fetchUnitData(target, native, unitId, afterCallback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatbotsSceneWithRouter);
