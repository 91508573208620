/* @flow */
import * as React from 'react';
import { Container } from './Container';
import { ORDER_SENTENCE_REVERSE } from '../../constants';
import {
  LIGHT_BLUE_2,
  LIGHT_BLUE_GRADIENT,
  WHITE
} from '../../../common/constants';

type Props = {
  children?: any,
  mode?: string,
  answeredWrong?: boolean,
  isDialog: boolean,
  gameType: string,
  isTargetRightToLeftlLanguage: boolean,
  nativeRtlLanguage: boolean,
  transcriptState: boolean,
  isShowTutorial: boolean
};

const CardBox = ({
  children,
  mode,
  answeredWrong,
  isDialog,
  gameType,
  isTargetRightToLeftlLanguage,
  nativeRtlLanguage,
  transcriptState,
  isShowTutorial,
  isTargetLangSymbol
}: Props) => {
  const spellingReverse = () => {
    const isReverseGame = gameType === ORDER_SENTENCE_REVERSE;
    if (isTargetRightToLeftlLanguage && !isReverseGame && !transcriptState) {
      return 'row-reverse';
    } else {
      return 'row';
    }
  };
  const styles = {
    characterRowStyle: {
      flexWrap: 'wrap',
      justifyContent: !isDialog ? 'flex-start' : 'center',
      borderWidth: '2px',
      borderStyle: 'solid',
      flex: 1,
      width: isDialog ? 'auto' : isShowTutorial ? 680 : 811,
      minHeight: isShowTutorial ? 'unset' : 131,
      borderRadius: 10,
      paddingHorizontal: 12,
      paddingVertical: 4,
      borderColor: isDialog ? WHITE : LIGHT_BLUE_2,
      backgroundImage: isDialog ? WHITE : LIGHT_BLUE_GRADIENT,
      margin: 'auto',
      marginBottom: isDialog && isShowTutorial ? 60 : 'unset',
      display: 'flex'
    }
  };

  return (
    <Container
      style={{
        ...styles.characterRowStyle,
        flexDirection: mode === 'horizontal' ? spellingReverse() : 'column',
        backgroundColor: answeredWrong && !isDialog ? '#ffd7d6' : 'transparent'
      }}
    >
      {children}
    </Container>
  );
};

export { CardBox };
