/* @flow */
export const BUTTON_CHECK: string = 'BUTTON_CHECK';
export const BUTTON_DISABLED: string = 'BUTTON_DISABLED';
export const BUTTON_CONTINUE: string = 'BUTTON_CONTINUE';
export const BUTTON_CONTINUE_DISABLED: string = 'BUTTON_CONTINUE_DISABLED';
export const BUTTON_CHECK_DISABLED: string = 'BUTTON_CHECK_DISABLED';
export const BUTTON_SPEAKER: string = 'BUTTON_SPEAKER';
export const BUTTON_CANCEL: string = 'BUTTON_CANCEL';
export const BUTTON_SEND: string = 'BUTTON_SEND';
export const BUTTON_SEND_DISABLED: string = 'BUTTON_SEND_DISABLED';
export const EXAM_MAX_HEARTS: number = 3;

export const SPEAKING_GAME_INDEX: number = 5;
export const EXAM_INDEX: number = 6;
export const WRITING_GAME_INDEX: number = 7;
export const RANDOM_GAME_INDEX: number = 0;
export const BAR_HEIGHT: number = 15;
export const BAR_EXAM_HEIGHT: number = 10;
export const BAR_CONVERSATION_HEIGHT: number = 8;

export const SPEAKING_TOGGLE_SPEAKING: string = 'SPEAKING_TOGGLE_SPEAKING';
export const SPEAKING_SAVE_RECORDER: string = 'SPEAKING_SAVE_RECORDER';
export const SPEAKING_ANSWER: string = 'SPEAKING_ANSWER';
export const SPEAKING_ERROR: string = 'SPEAKING_ERROR';
export const SPEAKING_CLEAR_ERROR: string = 'SPEAKING_CLEAR_ERROR';
export const IS_SPEAKING_RECORDED: number = 3;

export const MIC_BUTTON_ID: string = 'micButtom';
//Keycode
export const KEYCODE_1 = 49;
export const KEYCODE_2 = 50;
export const KEYCODE_3 = 51;
export const KEYCODE_4 = 52;
export const KEYCODE_5 = 53;
export const KEYCODE_6 = 54;
export const KEYCODE_ENTER = 13;

export const SET_GAME_RESULT = 'SET_GAME_RESULT';
