/* @flow */
import React, { Component } from 'react';
import { CardQuiz } from '../../CardQuiz';
import { TRANSLATEGAME } from '../../constants';

const CardToShow = ({
  status,
  style,
  content,
  onPress,
  invisible = false,
  isTargetLangSymbol,
  index
}) => {
  return (
    <CardQuiz
      status={status}
      style={style}
      onPress={onPress}
      invisible={invisible}
      content={content}
      gameType={TRANSLATEGAME}
      isTargetLangSymbol={isTargetLangSymbol}
      index={index}
    >
      {content}
    </CardQuiz>
  );
};

type Props = {
  style?: Object,
  content: any,
  onPress?: Function,
  isImage?: boolean,
  invisible?: boolean,
  animation?: ?string,
  onAnimationEnd?: Function,
  transcriptState?: boolean,
  transcript?: string,
  status: string,
  isTargetLangSymbol: boolean,
  index: number
};

class TranslateElementCard extends Component<Props> {
  render() {
    const { animation, onAnimationEnd } = this.props;

    if (animation) {
      return (
        // eslint-disable-next-line react/no-unknown-property
        <div animation={animation} onAnimationEnd={onAnimationEnd}>
          {CardToShow(this.props)}
        </div>
      );
    }
    return CardToShow(this.props);
  }
}

export { TranslateElementCard };
