/* @flow */
import React, { Component } from 'react';
import { Cell } from './GrammarCell';
type Props = {
  data: Object,
  style: Object,
  textStyle: Object,
  isTargetLangSymbol: boolean
};
class Row extends Component<Props, any> {
  static defaultProps: any;
  render() {
    const { data, style, textStyle, isTargetLangSymbol } = this.props;

    return data ? (
      <div style={{ ...styles.row, ...style }}>
        {data.map((item, i) => {
          return (
            <Cell
              key={i}
              data={item}
              textStyle={textStyle}
              isTargetLangSymbol={isTargetLangSymbol}
            />
          );
        })}
      </div>
    ) : null;
  }
}

class Rows extends Component<Props, any> {
  static defaultProps: any;
  render() {
    const { data, style, textStyle, isTargetLangSymbol } = this.props;

    return data ? (
      <div>
        {data.map((item, i) => {
          return (
            <Row
              key={i}
              data={item}
              style={style}
              textStyle={textStyle}
              isTargetLangSymbol={isTargetLangSymbol}
            />
          );
        })}
      </div>
    ) : null;
  }
}

const styles = {
  row: {
    flexDirection: 'row',
    overflow: 'hidden',
    display: 'flex'
  }
};

export { Row, Rows };
