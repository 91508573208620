/* @flow */
import * as React from 'react';
/* @flow */
import './QuitModal.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import {
  GREYISH_BROWN,
  PREFIX_LESSON,
  PREFIX_HEADER,
  IMAGE_PATH,
  WHITE,
  GREY,
  GREY2
} from '../../../common/constants';

const ModalImage = styled.img`
  padding-top: 14px;
  padding-bottom: 10px;
`;

const ModalTitle = styled.h1`
  font-size: 28px;
  margin-bottom: 18px;
  margin-top: 10px;
  color: ${GREYISH_BROWN};
  font-weight: bold;
`;

const ModalBodyText = styled.p`
  font-size: 18px;
  color: ${GREYISH_BROWN};
  line-height: 21px;
  margin: 0px;
`;

const ModalContentContainer = styled.div`
  width: 230px;
  margin: auto;
`;

const ModalCloseButton = styled.button`
  position: fixed;
  opacity: 1;
  padding: 16px 20px;
  padding-right: 0;
`;

const ModalBodyContainer = styled.div`
  display: ${props => (props.isLoading ? 'block' : 'none')};
  display: flex;
  justify-content: center;
`;

const LingLoading = styled.img`
  margin: 134px 38px;
`;

const styles = {
  modalContainer: {
    width: '485px',
    height: '457px',
    textAlign: 'center',
    borderRadius: 30
  },
  modalBodyContainer: {
    padding: 0,
    paddingBottom: 20,
    textAlign: 'center'
  },
  buttonStyle: {
    borderRadius: 10,
    width: 160,
    height: 55,
    background: WHITE,
    border: 'solid 1px',
    borderColor: GREY2,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    boxShadowColor: GREY2,
    fontWeight: 'bold',
    fontSize: 18
  },
  cancelButtonStyle: {
    border: 'none',
    boxShadow: 'none',
    width: 100
  },
  confirmButtonStyle: {
    borderColor: GREY2,
    background: WHITE,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    width: 133,
    height: 41
  },
  modalHeader: {
    borderBottom: 'none',
    flexDirection: 'row-reverse',
    justifyContent: 'normal'
  },
  modalFooter: {
    borderTop: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 30,
    paddingRight: 32,
    paddingLeft: 32
  },
  contentContainer: {
    borderRadius: 30
  },
  modalHeaderContainer: {
    'modal-title': 'w-100 text-center'
  }
};

const dangerIcon = `${PREFIX_LESSON}basecolor-round-warning-120-px.svg`;
const closeIcon = `${PREFIX_LESSON}basecolor-black.svg`;
const logoutIcon = `${PREFIX_HEADER}basecolor-circle-shutdown-yellow.svg`;
const lingLoading = `${IMAGE_PATH}ling-loader.gif`;
type Props = {
  isOpen: boolean,
  toggle: Function,
  isLogOut?: boolean,
  goToHome: Function,
  logout?: Function,
  isExam: boolean,
  modal_txt_quitExam: string,
  modal_txt_quitLesson: string,
  modal_txt_description_exam: string,
  modal_txt_description_lesson: string,
  notification_text_confirm: string,
  notification_text_cancel: string,
  login_msg_loseProgressDialog?: string,
  logout_txt?: string,
  isLoading?: Function
};

const QuitModal = ({
  isOpen,
  toggle,
  isLogOut,
  goToHome,
  logout,
  isExam = true,
  modal_txt_quitExam,
  modal_txt_quitLesson,
  modal_txt_description_exam,
  modal_txt_description_lesson,
  notification_text_confirm,
  notification_text_cancel,
  login_msg_loseProgressDialog,
  logout_txt,
  isLoading
}: Props) => {
  const closeBtn = (
    <ModalCloseButton className="close" onClick={toggle}>
      <img src={closeIcon} alt={closeIcon} />
    </ModalCloseButton>
  );

  const {
    modalHeader,
    modalBodyContainer,
    modalFooter,
    buttonStyle,
    cancelButtonStyle,
    confirmButtonStyle
  } = styles;
  return (
    <Modal centered isOpen={isOpen} contentClassName="contentContainer">
      {isLoading ? null : (
        <ModalHeader close={closeBtn} toggle={toggle} style={modalHeader}>
          <ModalImage
            src={!isLogOut ? dangerIcon : logoutIcon}
            alt={!isLogOut ? dangerIcon : logoutIcon}
          />
        </ModalHeader>
      )}
      {!isLoading ? (
        <React.Fragment>
          <ModalContentContainer>
            <ModalBody style={modalBodyContainer}>
              {!isLogOut ? (
                <React.Fragment>
                  <ModalTitle>
                    {isExam ? modal_txt_quitExam : modal_txt_quitLesson}
                  </ModalTitle>
                  <ModalBodyText
                    dangerouslySetInnerHTML={{
                      __html: getModalDescriptionText(
                        isExam
                          ? modal_txt_description_exam
                          : modal_txt_description_lesson
                      )
                    }}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <ModalTitle>{logout_txt}</ModalTitle>
                  <ModalBodyText
                    dangerouslySetInnerHTML={{
                      __html: getModalDescriptionText(
                        login_msg_loseProgressDialog
                      )
                    }}
                  />
                </React.Fragment>
              )}
            </ModalBody>
          </ModalContentContainer>

          <ModalFooter style={modalFooter}>
            <Button
              className="cancelButton"
              style={{ ...buttonStyle, ...cancelButtonStyle }}
              onClick={toggle}
            >
              {notification_text_cancel}
            </Button>
            <Button
              id="1"
              className="confirmButton"
              style={{ ...buttonStyle, ...confirmButtonStyle }}
              onClick={isLogOut ? logout : goToHome}
            >
              {notification_text_confirm}
            </Button>
          </ModalFooter>
        </React.Fragment>
      ) : (
        <ModalBodyContainer isLoading={isLoading}>
          <LingLoading src={lingLoading} />
        </ModalBodyContainer>
      )}
    </Modal>
  );
};

const getModalDescriptionText = descriptionText => {
  if (descriptionText) {
    descriptionText = descriptionText.replace(/\n/g, '<br />');
  }
  return descriptionText;
};
export { QuitModal };
