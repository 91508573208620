/* @flow */
import _ from 'lodash';
import {
  DIALOGUE,
  DIALOGUE_SOUND_START,
  DIALOGUE_SOUND_STOP,
  DIALOGUE_MESSAGE_ACTIVE,
  DIALOGUE_MESSAGE_DEACTIVE,
  DIALOGUE_START_GAME,
  DIALOGUE_REFRESH_GAME,
  DIALOGUE_CHECK_ANSWER,
  DIALOGUE_FOCUS_ANSWER,
  DIALOGUE_SELECT_ANSWER,
  DIALOGUE_DISMISS_NOTIFY,
  DIALOGUE_REPLAY,
  DIALOGUE_TRANSLATE,
  DIALOGUE_RESET_VIEW,
  DIALOGUE_SPEND_HINT,
  DIALOGUE_RESET_GAME,
  DIALOGUE_SAVE_SCROLL_FUNCTION,
  REFRESH_GAME,
  CLOSE_GAME,
  LOAD_DIALOGUE,
  PAUSE_DIALOGUE
} from '../constants';

const INITIAL_STATE = {
  title: 'Listen to this Dialog',
  state: 0,
  targetSlot: 0,
  currentSound: null,
  currentSequenceSound: 0,
  autoplay: true,
  sequence: 0,
  playing: true,
  pausing: false,
  dialogues: [],
  translate: false,
  questions: [],
  view: null,
  hintState: false,
  toggleCheck: false,
  func: null
};
/* Utility */
const increasePointer = (questions, id) => {
  for (let i = 0; i < questions.length; i++) {
    if (questions[i] === id) {
      if (i + 1 < questions.length) {
        return questions[i + 1];
      } else if (i + 1 >= questions.length) {
        return questions[0];
      }
      return questions[i];
    }
  }
};

/* Methods Start */
const dialogStartGame = (state, action) => {
  return {
    ...state,
    state: 1,
    translate: false
  };
};

const dialogRefresh = (state, action) => {
  return {
    ...state,
    state: 0
  };
};

const dialogFocusAnswer = (state, action) => {
  let data = {
    ...state
  };
  let dialogues = state.dialogues;
  let target = dialogues[action.id];
  if (
    target.isGame &&
    (target.answer.original !== '' || target.answer.transcript !== '') &&
    !data.toggleCheck
  ) {
    dialogues[target.answerId].isSelected = false;
    dialogues[action.id].answer = { original: '', transcript: '' };
    dialogues[action.id].answerId = 0;
    dialogues[action.id].status = 0;
    // data.targetSlot = decreasePointer(state.targetSlot);
  }
  data.targetSlot = action.id;
  data.dialogues = dialogues;
  return data;
};

const dialogSelectAnswer = (state, action) => {
  let questions = state.questions;
  let newMessage = state.dialogues;
  let target = newMessage[state.targetSlot];
  let data = { ...state };
  if (target !== undefined && target.isGame && target.status !== 1) {
    newMessage[state.targetSlot].answer = {
      original: newMessage[action.id].word.original,
      transcript: newMessage[action.id].word.transcript
    };
    newMessage[state.targetSlot].answerId = action.id;
    newMessage[action.id].isSelected = !newMessage[action.id].isSelected;
    data.dialogues = newMessage;

    let nextSlot = increasePointer(questions, state.targetSlot);

    data.targetSlot = nextSlot;
  }
  data.dialogues = newMessage;
  return data;
};

const dialogCheckAnswer = (state, action) => {
  return {
    ...state,
    toggleCheck: true,
    pass: action.status,
    dialogues: action.dialogues
  };
};

const dialogDismissNotify = (state, action) => {
  return {
    ...state,
    toggleCheck: false
  };
};

const dialogReplay = (state, action) => {
  return {
    ...state,
    playing: true
  };
};

const playDialogSound = (state, action) => {
  let subState = {
    currentSound: action.sound,
    autoplay: false,
    playing: true
  };
  if (action.sequence !== null) {
    return {
      ...state,
      ...subState,
      sequence: action.sequence
    };
  } else {
    return {
      ...state,
      ...subState
    };
  }
};

const stopDialogSound = (state, action) => {
  if (state.currentSound !== null && state.currentSound !== undefined) {
    state.currentSound.stop();
  }
  return { ...state, playing: false };
};
const pauseDialog = (state, action) => {
  return {
    ...state,
    currentSequenceSound: action.currentSound,
    pausing: !state.pausing
  };
};

const dialogTranslate = (state, action) => {
  return { ...state, translate: !state.translate };
};

const dialogMessageActive = (state, action) => {
  let dialogues = { ...state.dialogues };
  const dialogueIds = state.dialogueIds;
  const id = Number.isInteger(action.id) ? dialogueIds[action.id] : action.id;
  console.log(id);
  dialogues[id].active = true;
  const index = _.indexOf(dialogueIds, id);
  if (state.state === 0) {
    console.log('Check: ', index);
    state.func(index);
  }
  return { ...state, dialogues };
};

const dialogMessageDeactive = (state, action) => {
  let dialogues = { ...state.dialogues };
  for (let i in dialogues) {
    dialogues[i].active = false;
  }
  return { ...state, dialogues };
};

const dialogResetView = (state, action) => {
  state.func(0);
  return { ...state };
};

const dialogSpendHint = (state, action) => {
  return { ...state, hintState: true };
};

const dialogResetGame = (state, action) => {
  return { ...state, ...INITIAL_STATE };
};

const dialogSaveScrollFunction = (state, action) => {
  return { ...state, func: action.func };
};

const loadDialog = (
  state: Object,
  {
    dialogues,
    dialogueIds,
    questions = [],
    questionIds = [],
    answers = []
  }: Object
) => {
  return {
    ...state,
    dialogues,
    dialogueIds,
    questions,
    questionIds,
    answers
  };
};
/* Methods End */

export const DialogReducer = (
  state: Object = INITIAL_STATE,
  action: Object
) => {
  switch (action.type) {
    case CLOSE_GAME:
      return INITIAL_STATE;
    case REFRESH_GAME:
      if (action.gameType === DIALOGUE) {
        return loadDialog(state, action);
      } else {
        return state;
      }
    case LOAD_DIALOGUE:
      return loadDialog(state, action);
    case PAUSE_DIALOGUE:
      return pauseDialog(state, action);
    case DIALOGUE_SPEND_HINT:
      return dialogSpendHint(state, action);
    case DIALOGUE_RESET_VIEW:
      return dialogResetView(state, action);
    case DIALOGUE_SOUND_START:
      return playDialogSound(state, action);
    case DIALOGUE_SOUND_STOP:
      return stopDialogSound(state, action);
    case DIALOGUE_REPLAY:
      return dialogReplay(state, action);
    case DIALOGUE_MESSAGE_ACTIVE:
      return dialogMessageActive(state, action);
    case DIALOGUE_MESSAGE_DEACTIVE:
      return dialogMessageDeactive(state, action);
    case DIALOGUE_START_GAME:
      return dialogStartGame(state, action);
    case DIALOGUE_REFRESH_GAME:
      return dialogRefresh(state, action);
    case DIALOGUE_FOCUS_ANSWER:
      return dialogFocusAnswer(state, action);
    case DIALOGUE_SELECT_ANSWER:
      return dialogSelectAnswer(state, action);
    case DIALOGUE_CHECK_ANSWER:
      return dialogCheckAnswer(state, action);
    case DIALOGUE_DISMISS_NOTIFY:
      return dialogDismissNotify(state, action);
    case DIALOGUE_TRANSLATE:
      return dialogTranslate(state, action);
    case DIALOGUE_RESET_GAME:
      return dialogResetGame(state, action);
    case DIALOGUE_SAVE_SCROLL_FUNCTION:
      return dialogSaveScrollFunction(state, action);
    default:
      return state;
  }
};
