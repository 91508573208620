// import { createSelector } from 'reselect';

import { createSelector } from 'reselect';

export const getConfigState = state => state.config;

export const selectRemoteConfigState = createSelector(
  getConfigState,
  config => config.started
);
