/* @flow */
import React from 'react';
import { Button } from 'reactstrap';
import {
  SHOW_TUTORIAL,
  TRUE,
  FALSE,
  GREY2,
  WHITE,
  GREY
} from '../../../common/constants';

type Props = {
  writing_skip: string,
  onCloseTutorialMotivate: Function,
  setShowTutorial: Function
};
const SkipButton = ({
  writing_skip,
  setShowTutorial,
  onCloseTutorialMotivate
}: Props) => {
  const { buttonStyle, skipButtonStyle } = styles;
  return (
    <Button
      className="confirmButton"
      style={{ ...buttonStyle, ...skipButtonStyle }}
      onClick={() => {
        // eslint-disable-next-line
        localStorage.setItem(SHOW_TUTORIAL, FALSE);
        onCloseTutorialMotivate();
        setShowTutorial(
          // eslint-disable-next-line
          localStorage.getItem(SHOW_TUTORIAL) === TRUE
        );
      }}
    >
      {writing_skip}
    </Button>
  );
};

const styles = {
  buttonStyle: {
    borderRadius: 15,
    height: 55,
    width: 130,
    background: WHITE,
    border: 'solid 1px',
    borderColor: GREY2,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    boxShadowColor: GREY2,
    fontWeight: 'bold',
    fontSize: 18,
    margin: 'auto',
    paddingRight: 42,
    paddingLeft: 42
  },
  skipButtonStyle: {
    borderColor: GREY2,
    background: WHITE,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    marginLeft: 26,
    minWidth: 160,
    height: 55,
    borderRadius: 20,
    marginRight: 26,
    border: 'solid 1px #f7b500',
    opacity: 1,
    padding: 0
  }
};

export { SkipButton };
