/* eslint-disable max-lines */
/* @flow */
import React, { Component, Fragment } from 'react';
import './SignUpModal/SignUpModal.css';
import { IMAGE_PATH, PREFIX_HEADER } from '../../../common/constants';
import _ from 'lodash';
import {
  BottomText,
  LingLoading,
  LoginBox,
  LoginLink,
  ModalBodyContainer,
  ModalBodyText,
  ModalContentContainer,
  ProgressBarBox,
  ArrowBox,
  styles
} from './SignUpModal/SignUpModal.styles';
import {
  handleResetPasswordSubmit,
  handleSignUpSubmit,
  onToggleResetPassword,
  onToggleSignUp,
  handleLoginSubmit,
  getUserReferral,
  signInWithGoogleRedirect
} from '../../../util';
import { getLabel, getLabels } from '../../../util/LabelHelper';
import { ResetPasswordModal } from './ResetPasswordModal';
import { LoginModalFormModal } from './LoginModalFormModal';
import ResetPasswordPage from './SignUpModal/ResetPasswordPage';
import { isUserSubscribed } from '../../../profile/selectors';
import { getConfigState } from '../../../config/selectors';
import { getUser } from '../../../profile/actions';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import {
  Box,
  Typography,
  IconButton,
  InputAdornment,
  Link,
  styled,
  Button,
  LinearProgress,
  TextField
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import type { ButtonProps } from '@mui/material/Button';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { connect } from 'react-redux';
import { getDataState, getStorageState } from '../../../common/selectors';
import { withRouter } from 'react-router';
import { fetchUnitData } from '../../../lessons/actions';
import {
  getInviteCampaigns,
  pauseDialog,
  setIsBusinessSignUp,
  setSignUpBusinessSuccess,
  toggleLoginModal
} from '../../actions';
import {
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithApple,
  signInWithEmailAndPassword,
  signInWithFacebook,
  signInWithGoogle,
  signUpWithEmail
} from '../../../common/actions';
import { signOut } from '../../../profile/sagas';
import OnboardingButton from '../../../onboarding/selectorComponents/subComponents/OnboardingButton';
import { IOSSwitch } from './Switch';
import { requestTracking } from '../../../tracker/actions/trackerActions';
import { isMobile } from 'react-device-detect';
const imgPath = image => `${IMAGE_PATH}onboarding/${image}`;
const lingLoading = `${IMAGE_PATH}ling-loader.gif`;
type Props = {
  sign_in_opt_msg: string,
  email_error_fieldsMissing: string,
  email_error_wrongFormat: string,
  email_error_matchPasswords: string,
  email_error_fieldsMissing: string,
  email_error_longerPassword: string,
  email_error_alreadyUsed: string,
  email_error_userNotFound: string,
  email_error_wrongPassword: string,
  isOpen: boolean,
  isResetPassword: boolean,
  isSignUp: boolean,
  signInWithApple: Function,
  signInWithFacebook: Function,
  signInWithGoogle: Function,
  sendPasswordResetEmail: Function,
  login_field_name: string,
  login_field_firstName: string,
  login_msg_reenterPassword: string,
  login_field_email: string,
  login_field_password: string,
  isLoading: boolean,
  successMsg: string,
  btn_continue: string,
  isEmailSent: boolean,
  reset_password_txt_description: string,
  closeEmailSent: Function,
  sign_up_txt_title: string,
  login_txt_title: string,
  email_txt_loginNow_link: string,
  already_register_txt: string,
  login_txt_forget_password_title: string,
  login_txt_forget_password_web: string,
  dont_have_account_yet_txt: string,
  at_least_6_password_txt: string,
  txt_error_email_already_registered: string,
  txt_error_cannot_blank: string,
  txt_error_invalid_email_address: string,
  txt_error_password_must_contain: string,
  txt_whats_name: string,
  txt_whats_email: string,
  txt_choose_a_password: string,
  txt_enter_name: string,
  txt_enter_password: string,
  txt_signup_create_account_agreeement: string,
  txt_term_of_use: string,
  purchase_txt_policy: string,
  email_txt_login: string,
  to_txt: string,
  txt_name_char_length: string,
  txt_error_email_cannot_blank: string,
  txt_error_password_cannot_blank: string,
  txt_space_not_allowed: string,
  txt_error_password_must_contain: string,
  txt_dsc_save_profile: string,
  txt_thanks: string,
  user: Object,
  login: Function,
  signUpWithEmail: Function,
  toggleResetPassword: Function,
  fetchUnitData: Function,
  targetLangCode: string,
  nativeLangCode: string,
  toggleCheckout: Function,
  history: Object,
  itemInfo: Object,
  unitIdBussiness: number,
  isLoginModalOpen: boolean,
  toggleLoginModal: Function,
  isBusinessSignUp: boolean,
  setIsBusinessSignUp: Function,
  pauseDialog: Function,
  modal: boolean,
  isNewsletterEnable: boolean,
  requestTracking: Function,
  txt_email_field_placeholder: string
};
type State = {
  err: boolean,
  emailErrorMsg: string,
  isNewsletterEnable: boolean,
  isResetPassword: boolean,
  isSignUp: boolean,
  passwordErrorMsg: string,
  formErrorMsg: string,
  user: Object,
  registrationStep: number,
  isShowPassword: boolean,
  isAlreadyCheckPassword: boolean,
  isLoading: boolean,
  errorMsgFirebase: string,
  validated: boolean,
  errorCode: string,
  isShowLoginButtonForMobile: boolean
};
class SignUpPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isNewsletterEnable: false,
      isResetPassword: false,
      isLoading: false,
      isSignUp: true,
      err: false,
      errorMsgFirebase: '',
      emailErrorMsg: '',
      passwordErrorMsg: '',
      formErrorMsg: '',
      user: {
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
      },
      validated: false,
      registrationStep: 0,
      isShowPassword: false,
      isAlreadyCheckPassword: false,
      errorCode: '',
      isShowLoginButtonForMobile: false
    };
  }

  componentDidMount() {
    // const { state } = this.props;
    // console.log(state.storage.user.anonymous, state.storage.user.uid);
    // if (state.storage.user.email && state.storage.user.name) {
    //   this.props.history.push('/');
    // }
    const mode = new URLSearchParams(window.location.search).get('mode');
    if (mode === 'signIn') {
      this.setState({ isSignUp: false });
    }
  }

  handleValidation = () => {
    this.setState({ validated: !this.state.validated });
  };

  handleSignUpWithEmail = async () => {
    const {
      isBusinessSignUp,
      setSignUpBusinessSuccess,
      fetchUnitData,
      targetLangCode,
      nativeLangCode,
      history,
      signUpWithEmail
    } = this.props;
    const { name, email, password } = this.state.user;

    this.setState({ isLoading: true, errorMsgFirebase: '' });
    const signMsg = await signUpWithEmail(
      name,
      email,
      password,
      this.state.isNewsletterEnable
    );

    if (signMsg[0]) {
      if (isBusinessSignUp) {
        setSignUpBusinessSuccess();
      }
      if (this.unitIdBussiness === 42) {
        fetchUnitData(targetLangCode, nativeLangCode, 42, () => {
          history.push('/unit/42');
        });
      } else {
        history.push('/paywall');
      }
    } else {
      const errMsg = signMsg[1] ?? '';
      this.setState({
        err: true,
        errorMsgFirebase: errMsg ? this.getEmailLoginError(errMsg).message : '',
        errorCode: errMsg?.code,
        isLoading: false
      });
    }
  };

  modalStateChangeHandler = (name: string, value: any) => {
    this.setState({
      [name]: value
    });
  };

  UNSAFE_componentWillUpdate(prevProps, prevState) {
    const { state } = this.props;

    if (state.storage?.user?.anonymous?.id !== state.storage?.user?.uid) {
      this.props.history.push('/');
    }

    console.log(this.state.validated);
    if (!prevState.validated && this.state.validated && !this.state.err) {
      this.props.history.push('/planPreparation');
    }
  }

  _onNewsletterStateHandler = () =>
    this.setState({ isNewsletterEnable: !this.state.isNewsletterEnable });

  handleOnChange = (event: any, formKey: string) => {
    this.modalStateChangeHandler('user', {
      ...this.props.user,
      [formKey]: event.target.value
    });
  };

  getEmailLoginError = ({ code, message }) => {
    const {
      email_error_userNotFound,
      email_error_longerPassword,
      email_error_wrongPassword,
      txt_error_email_already_registered
    } = this.props;

    switch (code) {
      case 'auth/user-not-found':
        return {
          message: email_error_userNotFound
        };
      case 'auth/wrong-password':
        return {
          message: email_error_wrongPassword
        };
      case 'auth/email-already-in-use':
        this.setState({
          registrationStep: 2,
          formErrorMsg: txt_error_email_already_registered
        });
        return {
          message: txt_error_email_already_registered
        };
      case 'auth/weak-password':
        return {
          message: email_error_longerPassword
        };
      case 'auth/provider-already-linked':
        this.setState({
          registrationStep: 2,
          formErrorMsg: txt_error_email_already_registered
        });
        return {
          message: txt_error_email_already_registered
        };
      default:
        return {
          message: message
        };
    }
  };

  login = async () => {
    const { user } = this.state;
    const { email, password } = user;
    console.log('login?');
    this.setState({ isLoading: true, errorMsgFirebase: '' });
    const signMsg = await this.props.signInWithEmailAndPassword(
      email,
      password
    );
    if (signMsg[0]) {
      if (this.unitIdBussiness === 42) {
        this.props.history.push('/unit/42');
      } else {
        this.props.history.push('/shop', { source: 'onboarding' });
        if (!_.isEmpty(this.state.itemInfo)) {
          console.log('redirect shop');
        }
      }
    } else {
      this.setState({
        errorMsgFirebase: this.getEmailLoginError(signMsg[1]).message
      });
      this.setState({ isLoading: false });
    }
  };

  render() {
    const {
      passwordErrorMsg,
      emailErrorMsg,
      formErrorMsg,
      isResetPassword,
      isSignUp
    } = this.state;
    const {
      login_field_email,
      login_field_password,
      isLoading,
      successMsg,
      btn_continue,
      isEmailSent,
      reset_password_txt_description,
      closeEmailSent,
      sign_up_txt_title = 'Sign Up', // For missing label
      login_txt_title,
      email_txt_loginNow_link,
      already_register_txt,
      login_txt_forget_password_web,
      dont_have_account_yet_txt,
      at_least_6_password_txt,
      email_error_matchPasswords,
      email_error_fieldsMissing,
      email_error_longerPassword,
      email_error_wrongFormat,
      signInWithGoogle,
      fetchUnitData,
      targetLangCode,
      nativeLangCode,
      toggleCheckout,
      history,
      itemInfo,
      unitIdBussiness,
      isLoginModalOpen,
      isBusinessSignUp,
      sendPasswordResetEmail,
      setIsBusinessSignUp,
      pauseDialog,
      modal,
      signInWithApple,
      signInWithFacebook,
      requestTracking,
      txt_whats_name,
      txt_whats_email,
      sign_in_opt_msg,
      txt_choose_a_password,
      txt_enter_name,
      txt_enter_password,
      txt_signup_create_account_agreeement,
      txt_term_of_use,
      purchase_txt_policy,
      email_txt_login,
      to_txt,
      txt_name_char_length,
      txt_error_email_cannot_blank,
      txt_error_password_cannot_blank,
      txt_space_not_allowed,
      txt_error_password_must_contain,
      txt_dsc_save_profile,
      txt_thanks,
      txt_error_cannot_blank,
      txt_error_invalid_email_address,
      txt_email_field_placeholder
    } = this.props;

    const dynamicLinks = 'https://ling-app.onelink.me/Ue3y/ki1wwbb3';

    const { signUpSuggestionStyle, signUpStyle, loginFormSpaceBottom } = styles;

    const newStyles = {
      outer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column'
      },
      container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: {
          md: 20,
          xs: 5
        }
      },
      title: {
        display: 'flex',
        justifyContent: 'center',
        my: 6
      }
    };

    if (
      this.state.errorCode === 'auth/email-already-in-use' ||
      this.state.errorCode === 'auth/provider-already-linked'
    ) {
      let trackingDetails = {
        email: this.state.user.email,
        error: 'already_in_use'
      };
      requestTracking('enter_email', trackingDetails, trackingDetails);
      this.setState({
        errorCode: '',
        isShowLoginButtonForMobile: true
      });
    }

    const validateFormByID = (id: string, isOnClick?: boolean): boolean => {
      const { user } = this.state;
      let hasError = false;

      if (id === 'name') {
        if (user.name.trim() === '') {
          this.setState({ formErrorMsg: txt_error_cannot_blank });
          hasError = true;
        } else if (user.name.length < 3) {
          this.setState({
            formErrorMsg: txt_name_char_length
          });
          hasError = true;
        }
      } else if (id === 'email') {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (user.email.trim() === '') {
          this.setState({
            formErrorMsg: txt_error_email_cannot_blank
          });
          isOnClick &&
            requestTracking(
              'enter_email',
              {
                email: this.state.user.email,
                error: 'empty_field'
              },
              {
                email: this.state.user.email,
                error: 'empty_field'
              }
            );
          hasError = true;
        } else if (!emailRegex.test(user.email)) {
          this.setState({ formErrorMsg: txt_error_invalid_email_address });
          requestTracking(
            'enter_email',
            {
              email: this.state.user.email,
              error: 'invalid_format'
            },
            {
              email: this.state.user.email,
              error: 'invalid_format'
            }
          );
          hasError = true;
        } else {
          isOnClick &&
            requestTracking(
              'enter_email',
              {
                email: this.state.user.email,
                error: 'no'
              },
              {
                email: this.state.user.email,
                error: 'no'
              }
            );
        }
      } else if (id === 'password') {
        if (user.password === '') {
          this.setState({
            formErrorMsg: txt_error_password_cannot_blank
          });
          hasError = true;
        } else if (/\s/.test(user.password)) {
          this.setState({
            formErrorMsg: txt_space_not_allowed
          });
          hasError = true;
        } else if (user.password.length < 6) {
          this.setState({
            formErrorMsg: txt_error_password_must_contain
          });
          hasError = true;
        }
      }

      // If no errors, clear any previous error message
      if (!hasError) {
        this.setState({ formErrorMsg: '', err: false });
      }

      // Return the validity of the form (opposite of hasError)
      return !hasError;
    };

    const handleValidation = e => {
      const id = e.target.id;
      validateFormByID(id);
    };

    const handleChange = e => {
      this.setState(prevState => ({
        user: {
          ...prevState.user,
          [e.target.id]: e.target.value
        }
      }));
    };

    const handleBlur = e => {
      handleValidation(e);
    };

    const handleOnContinue = id => {
      const isValid = validateFormByID(id, true);
      if (isValid) {
        this.setState(prevState => ({
          registrationStep: prevState.registrationStep + 1
        }));
      }
    };

    const handleOnSubmit = event => {
      handleSignUpSubmit(
        event,
        email_error_matchPasswords,
        email_error_fieldsMissing,
        email_error_longerPassword,
        email_error_wrongFormat,
        this.state.err,
        {
          ...this.state.user,
          isNewsletterEnable: this.state.isNewsletterEnable
        },
        this.handleSignUpWithEmail,
        this.modalStateChangeHandler,
        this.modalStateChangeHandler,
        this.handleValidation
      );
    };

    const registrationStepObject = [
      {
        title: txt_thanks,
        body: (
          <Box sx={styles.formContainer}>
            <Box
              component="img"
              src={imgPath('thanks-save-profile.png')}
              sx={styles.image}
            />
            <Typography variant="subtitle1" sx={styles.detail}>
              {txt_dsc_save_profile}
            </Typography>
          </Box>
        ),
        onClick: () => {
          this.setState(prevState => ({
            registrationStep: prevState.registrationStep + 1
          }));
        }
      },
      {
        title: txt_whats_name,
        body: (
          <Box sx={styles.boxTextField}>
            <TextField
              id="name"
              placeholder={txt_enter_name}
              variant="outlined"
              fullWidth
              required
              style={styles.textField}
              value={this.state.user.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!this.state.formErrorMsg}
              helperText={this.state.formErrorMsg ?? ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon fontSize="large" />
                  </InputAdornment>
                )
              }}
            />
          </Box>
        ),
        onClick: () => {
          handleOnContinue('name');
          requestTracking(
            'enter_name',
            {
              name: this.state.user.name
            },
            {
              name: this.state.user.name
            }
          );
        }
      },
      {
        title: txt_whats_email,
        body: (
          <Box sx={styles.boxTextField}>
            <TextField
              id="email"
              placeholder={txt_email_field_placeholder}
              variant="outlined"
              fullWidth
              required
              style={styles.textField}
              value={this.state.user.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!this.state.formErrorMsg}
              helperText={this.state.formErrorMsg ?? ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon fontSize="large" />
                  </InputAdornment>
                )
              }}
            />
            <Box style={styles.switchStye}>
              <Typography variant="caption" sx={styles.detail}>
                {sign_in_opt_msg}
              </Typography>

              <Box>
                <IOSSwitch
                  checked={this.state.isNewsletterEnable}
                  onChange={() => {
                    this.setState(prevState => ({
                      isNewsletterEnable: !prevState.isNewsletterEnable
                    }));
                  }}
                />
              </Box>
            </Box>
          </Box>
        ),
        onClick: event => {
          if (this.state.isAlreadyCheckPassword) {
            handleOnSubmit(event);
          } else {
            handleOnContinue('email');
          }
        }
      },
      {
        title: txt_choose_a_password,
        body: (
          <Box sx={styles.boxTextField}>
            <TextField
              id="password"
              placeholder={txt_enter_password}
              variant="outlined"
              type={this.state.isShowPassword ? 'text' : 'password'}
              fullWidth
              required
              style={styles.textField}
              value={this.state.user.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!this.state.formErrorMsg}
              helperText={this.state.formErrorMsg ?? ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon fontSize="large" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        this.setState(prevState => ({
                          isShowPassword: !prevState.isShowPassword
                        }));
                      }}
                      edge="end"
                    >
                      {this.state.isShowPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Typography variant="caption" sx={styles.detail}>
              {txt_signup_create_account_agreeement}{' '}
            </Typography>
            <Typography variant="caption" sx={styles.detail}>
              <Link
                style={styles.linkTouAndPP}
                href="https://ling-app.com/terms-of-use/"
              >
                {txt_term_of_use}
              </Link>
              {to_txt}
              <Link
                style={styles.linkTouAndPP}
                href="https://ling-app.com/privacy-policy/"
              >
                {purchase_txt_policy}
              </Link>
            </Typography>
          </Box>
        ),
        onClick: event => {
          this.setState({ isAlreadyCheckPassword: true });
          requestTracking('enter_password', {});
          validateFormByID('password') && handleOnSubmit(event);
        }
      }
    ];

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
      height: 6,
      borderRadius: 6,
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.newPrimary.deactivate
      },
      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 6,
        backgroundColor: theme.palette.newPrimary.activate
      }
    }));

    const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
      color: theme.palette.common.black,
      backgroundColor: theme.palette.background.default,
      borderColor: theme.palette.newPrimary.activate,
      border: `3px solid var(--Button-active, ${theme.palette.newPrimary.activate})`,
      borderRadius: 'var(--10, 40px)',
      fontSize: theme.typography.h5.fontSize,
      fontWeight: theme.typography.h5.fontWeight,
      '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.newPrimary.activate,
        border: `3px solid var(--Button-active, ${theme.palette.newPrimary.activate})`
      }
    }));

    const backButton = () => {
      return (
        <Box
          id="backBtn"
          onClick={() => {
            this.setState({ formErrorMsg: '', err: false });
            this.setState(
              isSignUp
                ? prevState => ({
                    registrationStep: prevState.registrationStep - 1
                  })
                : { isSignUp: !isSignUp }
            );
          }}
          sx={styles.backButtonContainer}
        >
          <img
            style={styles.icon}
            alt="back-icon"
            src={`${PREFIX_HEADER}back-icon.svg`}
          />
        </Box>
      );
    };

    return (
      <Box sx={newStyles.outer}>
        <Box sx={styles.mainBar}>
          <Box sx={styles.barContainer}>
            <ArrowBox>
              {(this.state.registrationStep > 0 || !this.state.isSignUp) && (
                <Box sx={styles.backButton}>{backButton()}</Box>
              )}
            </ArrowBox>
            <ProgressBarBox>
              <Box sx={styles.progressBar}>
                <BorderLinearProgress variant="determinate" value={80} />
              </Box>
            </ProgressBarBox>
            <LoginBox>
              {(!isMobile || this.state.isShowLoginButtonForMobile) &&
                this.state.isSignUp && (
                  <ColorButton
                    style={styles.loginButtonStyle}
                    variant="outlined"
                    onClick={() => {
                      if (isMobile && this.state.isShowLoginButtonForMobile) {
                        window.location.replace(dynamicLinks);
                      } else {
                        onToggleSignUp(
                          isSignUp,
                          isResetPassword,
                          this.modalStateChangeHandler,
                          this.modalStateChangeHandler
                        );
                      }
                    }}
                  >
                    {email_txt_login}
                  </ColorButton>
                )}
            </LoginBox>
          </Box>
        </Box>
        <Box sx={newStyles.container}>
          {isResetPassword ? (
            isEmailSent ? (
              <ResetPasswordModal
                closeEmailSent={() =>
                  closeEmailSent(isResetPassword, this.modalStateChangeHandler)
                }
                reset_password_txt_description={reset_password_txt_description}
              />
            ) : (
              <Fragment>
                <ModalBodyContainer isLoading={isLoading}>
                  <LingLoading src={lingLoading} />
                </ModalBodyContainer>
                <ModalBodyContainer isLoading={!isLoading}>
                  <Box sx={{ w: 1 / 1 }}>
                    <ModalBodyText>
                      <ResetPasswordPage
                        btn_continue={btn_continue}
                        handleResetPasswordSubmit={handleResetPasswordSubmit}
                        email_error_fieldsMissing={email_error_fieldsMissing}
                        email_error_wrongFormat={email_error_wrongFormat}
                        errorMsgFirebase={this.state.errorMsgFirebase}
                        login_field_email={login_field_email}
                        user={this.state.user}
                        sendPasswordResetEmail={sendPasswordResetEmail}
                        modalStateChangeHandler={this.modalStateChangeHandler}
                        stateChangeHandler={this.modalStateChangeHandler}
                        successMsg={successMsg}
                        txt_email_field_placeholder={
                          txt_email_field_placeholder
                        }
                      />
                    </ModalBodyText>
                  </Box>
                </ModalBodyContainer>
              </Fragment>
            )
          ) : (
            <>
              {isSignUp ? (
                <>
                  <ModalBodyContainer
                    isLoading={isLoading || this.state.isLoading}
                  >
                    <LingLoading src={lingLoading} />
                  </ModalBodyContainer>
                  {!this.state.isLoading && (
                    <>
                      <Typography variant="h4" sx={newStyles.title}>
                        {
                          registrationStepObject[this.state.registrationStep]
                            .title
                        }
                      </Typography>
                      {registrationStepObject[this.state.registrationStep].body}

                      <OnboardingButton
                        disabled={this.state.err || !!this.state.formErrorMsg}
                        size="large"
                        clickAction={e => {
                          registrationStepObject[
                            this.state.registrationStep
                          ].onClick(e);
                        }}
                        buttonText={btn_continue}
                        style={styles.button}
                      />
                    </>
                  )}
                </>
              ) : (
                <ModalContentContainer>
                  <ModalBodyContainer isLoading={isLoading}>
                    <LingLoading src={lingLoading} />
                  </ModalBodyContainer>
                  <ModalBodyContainer isLoading={!isLoading}>
                    <ModalBodyText>
                      {!isSignUp && (
                        <LoginModalFormModal
                          handleOnChange={this.handleOnChange}
                          stateChangeHandler={this.modalStateChangeHandler}
                          fetchUnitData={fetchUnitData}
                          targetLangCode={targetLangCode}
                          nativeLangCode={nativeLangCode}
                          toggleCheckout={toggleCheckout}
                          history={history}
                          itemInfo={itemInfo}
                          unitIdBussiness={unitIdBussiness}
                          isLoginModalOpen={isLoginModalOpen}
                          toggleLoginModal={this.handleValidation}
                          isBusinessSignUp={isBusinessSignUp}
                          setIsBusinessSignUp={setIsBusinessSignUp}
                          pauseDialog={pauseDialog}
                          modal={modal}
                          handleLoginSubmit={e =>
                            handleLoginSubmit(
                              e,
                              email_error_matchPasswords,
                              email_error_fieldsMissing,
                              email_error_longerPassword,
                              email_error_wrongFormat,
                              this.state.err,
                              this.state.user,
                              this.login,
                              this.modalStateChangeHandler,
                              this.modalStateChangeHandler
                            )
                          }
                          errorMsgFirebase={this.state.errorMsgFirebase}
                          login_field_email={login_field_email}
                          user={this.state.user}
                          login_field_password={login_field_password}
                          at_least_6_password_txt={at_least_6_password_txt}
                          passwordErrorMsg={passwordErrorMsg}
                          emailErrorMsg={emailErrorMsg}
                          err={this.state.err}
                          formErrorMsg={formErrorMsg}
                          toggleResetPassword={() =>
                            onToggleResetPassword(
                              isResetPassword,
                              this.modalStateChangeHandler,
                              this.modalStateChangeHandler
                            )
                          }
                          login_txt_forget_password_web={
                            login_txt_forget_password_web
                          }
                          login_txt_title={login_txt_title}
                          signInWithGoogle={signInWithGoogle}
                          signInWithFacebook={signInWithFacebook}
                          signInWithApple={signInWithApple}
                          txt_email_field_placeholder={
                            txt_email_field_placeholder
                          }
                        />
                      )}
                    </ModalBodyText>
                    <span style={signUpSuggestionStyle}>
                      {!isSignUp ? (
                        <Fragment>
                          <BottomText>
                            {dont_have_account_yet_txt}{' '}
                            <LoginLink
                              style={signUpStyle}
                              onClick={() => {
                                // return to sign up email page
                                if (this.state.registrationStep > 2) {
                                  this.setState({ registrationStep: 2 });
                                }
                                onToggleSignUp(
                                  isSignUp,
                                  isResetPassword,
                                  this.modalStateChangeHandler,
                                  this.modalStateChangeHandler
                                );
                              }}
                            >
                              {sign_up_txt_title}
                            </LoginLink>
                          </BottomText>
                          <div style={loginFormSpaceBottom} />
                        </Fragment>
                      ) : (
                        <Fragment>
                          <BottomText>
                            {already_register_txt}{' '}
                            <LoginLink
                              style={signUpStyle}
                              onClick={() =>
                                onToggleSignUp(
                                  isSignUp,
                                  isResetPassword,
                                  this.modalStateChangeHandler,
                                  this.modalStateChangeHandler
                                )
                              }
                            >
                              {email_txt_loginNow_link}
                            </LoginLink>
                          </BottomText>
                        </Fragment>
                      )}
                    </span>
                  </ModalBodyContainer>
                </ModalContentContainer>
              )}
            </>
          )}
        </Box>
      </Box>
    );
  }
}

export const mapStateToProps = (state: Object) => {
  const {
    data: {
      courses,
      fetchingUnitId: downloading,
      targetLangCode,
      nativeLangCode
    },
    storage,
    dialog,
    shop
  } = state;
  const { isShowRepairedUnitTooltipsAlready } = shop;
  const { questions } = dialog;
  const {
    user,
    isShowTutorial,
    isLoading,
    isLoginModalOpen,
    isBusiness,
    isBusinessSignUp,
    noAccessUnits = []
  } = storage;
  const { reminders, dailyGoal } = user;
  let units = [];

  let firstCrackedUnitId = 0;
  const labels = getLabels(
    state,
    'createAccount_txt_title',
    'notification_text_confirm',
    'notification_text_cancel',
    'login_msg_loseProgressDialog',
    'logout_txt',
    'sign_in_opt_msg',
    'email_error_matchPasswords',
    'email_error_fieldsMissing',
    'email_error_longerPassword',
    'email_error_wrongFormat',
    'email_txt_loginNow',
    'login_txt_forget_password',
    'login_field_name',
    'login_field_firstName',
    'login_msg_reenterPassword',
    'login_field_email',
    'login_field_password',
    'reset_password_txt_description',
    'btn_continue',
    'sign_up_txt_title',
    'login_txt_title',
    'login_txt_forget_password_title',
    'login_txt_forget_password_web',
    'email_txt_loginNow_link',
    'already_register_txt',
    'dont_have_account_yet_txt',
    'at_least_6_password_txt',
    'email_error_alreadyUsed',
    'email_error_userNotFound',
    'email_error_wrongPassword',
    'setting_txt_settingHeader',
    'modal_error_faq',
    'profile_txt_headerProfile',
    'footer_txt_shop',
    'footer_txt_learn',
    'footer_txt_chatbot'
  );

  return {
    dailyGoal,
    questions: questions,
    units,
    isBusiness,
    noAccessUnits,
    isBusinessSignUp,
    newReminder: reminders,
    courses,
    downloading,
    targetLangCode,
    nativeLangCode,
    isLoading,
    isLoginModalOpen,
    user,
    isProUser: isUserSubscribed(state),
    isShowTutorial,
    login_txt_title: getLabel(state, 'login_txt_title', 'Sign In'),
    edit_profile_save_txt: getLabel(state, 'edit_profile_save_txt', 'Save'),
    setting_txt_reminder_title: getLabel(
      state,
      'setting_txt_reminder_title',
      'Reminder'
    ),
    setting_txt_day_select: getLabel(
      state,
      'setting_txt_day_select',
      'What days whould you like to be reminded to learn?'
    ),
    setting_txt_time_select: getLabel(
      state,
      'setting_txt_time_select',
      'What time of the day?'
    ),
    ...labels,
    ...getUser(state),
    ...getLabels(
      state,
      'my_daily_goal_txt',
      'unitLevel_txt_basic',
      'unitLevel_txt_beginner',
      'unitLevel_txt_intermediate',
      'unitLevel_txt_afterintermediate',
      'unitLevel_txt_advance',
      'unitLevel_txt_unit',
      'random_test_modal_txt',
      'txt_signup_create_account_agreeement',
      'purchase_txt_policy',
      'txt_email_field_placeholder'
    ),
    sign_up_txt_title: getLabel(state, 'sign_up_txt_title', 'Sign Up'),
    spaced_repetition_open_txt: getLabel(
      state,
      'spaced_repetition_open_txt',
      'Open this unit to review and repair the unit.'
    ),
    random_test_review_btn: getLabel(state, 'random_test_review_btn', 'Review'),
    random_test_modal_txt: getLabel(
      state,
      'random_test_modal_txt',
      'Please come back \n after you finish at least 2 Units and get at least 1 crown per unit'
    ),
    random_test_modal_confirm_txt: getLabel(
      state,
      'random_test_modal_confirm_txt',
      'Ok'
    ),
    createAccount_txt_title: getLabel(
      state,
      'createAccount_txt_title',
      'Create Account'
    ),
    promo_txt_wordsLearned: getLabel(
      state,
      'promo_txt_wordsLearned',
      'Words Learned'
    ),
    loginPurchase_txt: getLabel(state, 'loginPurchase_txt', 'Create Account'),
    txt_error_email_already_registered: getLabel(
      state,
      'txt_error_email_already_registered',
      'This email address is already registered with Ling. Log in or use a different email address.'
    ),
    txt_whats_name: getLabel(state, 'txt_whats_name', 'What’s your name?'),
    sign_in_opt_msg: getLabel(
      state,
      'sign_in_opt_msg',
      'Sign me up for Ling updates, learning tips, and exclusive offers!'
    ),
    txt_enter_name: getLabel(state, 'txt_enter_name', 'Enter Name'),
    txt_enter_password: getLabel(state, 'txt_enter_password', 'Enter Password'),
    txt_term_of_use: getLabel(state, 'txt_term_of_use', 'Terms of Use'),
    purchase_txt_policy: getLabel(
      state,
      'purchase_txt_policy',
      'Privacy Policy'
    ),
    email_txt_login: getLabel(state, 'email_txt_login', 'Log in'),
    to_txt: getLabel(state, 'to_txt', 'and'),
    txt_choose_a_password: getLabel(
      state,
      'txt_choose_a_password',
      'Choose a password'
    ),
    txt_whats_email:
      getLabel(state, 'txt_whats_email') ||
      getLabel(state, "txt_what's_email") ||
      'What’s your email?',
    txt_name_char_length: getLabel(
      state,
      'txt_name_char_length',
      'Name must be at least 3 characters long.'
    ),
    txt_error_email_cannot_blank: getLabel(
      state,
      'txt_error_email_cannot_blank',
      'The “Email” field cannot be left blank.'
    ),
    txt_error_password_cannot_blank: getLabel(
      state,
      'txt_error_password_cannot_blank',
      'The “Password” field cannot be left blank.'
    ),
    txt_space_not_allowed: getLabel(
      state,
      'txt_space_not_allowed',
      "Spaces aren't allowed for passwords. Pick a new password."
    ),
    txt_error_password_must_contain: getLabel(
      state,
      'txt_error_password_must_contain',
      'Password must contain at least 6 characters.'
    ),
    txt_dsc_save_profile: getLabel(
      state,
      'txt_dsc_save_profile',
      "Now let's save your profile information and finalize your learning plan!"
    ),
    txt_thanks: getLabel(state, 'txt_thanks', 'Thanks'),
    isShowRepairedUnitTooltipsAlready,
    firstCrackedUnitId,
    state: {
      config: getConfigState(state),
      data: getDataState(state),
      storage: getStorageState(state)
    }
  };
};

export default connect(mapStateToProps, {
  fetchUnitData,
  toggleLoginModal,
  setIsBusinessSignUp,
  setSignUpBusinessSuccess,
  getUserReferral,
  getInviteCampaigns,
  signInWithEmailAndPassword,
  signInAnonymously,
  signInWithFacebook,
  signInWithGoogle,
  signInWithGoogleRedirect,
  signInWithApple,
  signUpWithEmail,
  sendPasswordResetEmail,
  signOut,
  pauseDialog,
  requestTracking
})(withRouter(SignUpPage));
