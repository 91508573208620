/* @flow */

import { connect } from 'react-redux';
import _ from 'lodash';
import {
  signInWithEmailAndPassword,
  signInAnonymously,
  signInWithFacebook,
  signInWithGoogle,
  signInWithGoogleRedirect,
  signInWithApple,
  signUpWithEmail,
  sendPasswordResetEmail,
  signOut
} from '../actions/AuthActions';
import {
  getUserReferral,
  getInviteCampaigns,
  toggleLoginModal,
  setIsBusinessSignUp,
  setSignUpBusinessSuccess
} from '../actions';
import { pauseDialog } from '../../games/actions';
import { DefaultHeaderWithRouter } from '../../components/main-components/DefaultHeader';
import { getFlagTargetLanguage } from '../../profile/selectors/LanguageSelector';
import {
  getUserBananas,
  getUserCoins,
  getUser
} from '../../profile/selectors/UserSelector';
import moment from 'moment';
import { fetchUnitData } from '../../common/actions/LanguageActions';
import { getLabels, getLabel } from '../../util/LabelHelper';
import { isUserSubscribed } from '../../profile/selectors/UserSelector';
import { getOnGoingPromotion, getNameAndEmail } from '../../profile/actions';

export const mapStateToProps = (state: Object, ownProps: Object) => {
  const { storage, data } = state;
  const {
    user,
    configs,
    isLoginModalOpen,
    isBusiness,
    isBusinessSignUp
  } = storage;
  const { targetLangCode, nativeLangCode } = data;
  const { isShowInviteFriend } = configs;
  const isProUser = isUserSubscribed(state);
  const onGoingPromotion = getOnGoingPromotion(state);
  const { iapIosSuffix, iapAndroidSuffix } = onGoingPromotion;
  let isShowGift = false;
  const affiliateExpire = _.get(user, 'affiliateExpire', moment('2019-01-01')); // to make it alway before so it will be false
  isShowGift =
    !_.isEmpty(iapAndroidSuffix) ||
    !_.isEmpty(iapIosSuffix) ||
    moment(affiliateExpire).isAfter(moment());

  const isDev = process.env.REACT_APP_DEV;
  const labels = getLabels(
    state,
    'createAccount_txt_title',
    'notification_text_confirm',
    'notification_text_cancel',
    'login_msg_loseProgressDialog',
    'logout_txt',
    'sign_in_opt_msg',
    'email_error_matchPasswords',
    'email_error_fieldsMissing',
    'email_error_longerPassword',
    'email_error_wrongFormat',
    'email_txt_loginNow',
    'login_txt_forget_password',
    'login_field_name',
    'login_field_firstName',
    'login_msg_reenterPassword',
    'login_field_email',
    'login_field_password',
    'reset_password_txt_description',
    'btn_continue',
    'sign_up_txt_title',
    'login_txt_title',
    'login_txt_forget_password_title',
    'login_txt_forget_password_web',
    'email_txt_loginNow_link',
    'already_register_txt',
    'dont_have_account_yet_txt',
    'at_least_6_password_txt',
    'email_error_alreadyUsed',
    'email_error_userNotFound',
    'email_error_wrongPassword',
    'setting_txt_settingHeader',
    'modal_error_faq',
    'profile_txt_headerProfile',
    'footer_txt_shop',
    'footer_txt_learn',
    'footer_txt_chatbot',
    'txt_email_field_placeholder'
  );
  return {
    user,
    name: getNameAndEmail(user),
    isDev,
    isBusiness,
    targetLangCode,
    nativeLangCode,
    isProUser,
    modal_error_other: getLabel(state, 'modal_error_other', 'Other'),
    modal_error_answer_should_be_accepted: getLabel(
      state,
      'modal_error_answer_should_be_accepted',
      'My answer should be accepted.'
    ),
    modal_error_answer_duplicate: getLabel(
      state,
      'modal_error_answer_duplicate',
      'One or more answers is a duplicate.'
    ),
    modal_error_image_missing: getLabel(
      state,
      'modal_error_image_missing',
      'An image is missing.'
    ),
    modal_error_audio_not_match: getLabel(
      state,
      'modal_error_audio_not_match',
      'The audio does not match with the text.'
    ),
    modal_error_audio_missing: getLabel(
      state,
      'modal_error_audio_missing',
      'The audio is missing.'
    ),
    modal_error_audio_not_clear: getLabel(
      state,
      'modal_error_audio_not_clear',
      'The audio is not clear.'
    ),
    modal_error_report: getLabel(state, 'modal_error_report', 'Error Report'),
    modal_error_report_txt: getLabel(state, 'modal_error_report_txt', 'Report'),
    modal_error_faq: getLabel(state, 'modal_error_faq', 'FAQ'),
    banana_score_modal_txt: getLabel(
      state,
      'banana_score_modal_txt',
      'Get more banana to rank higher in the leaderboard'
    ),
    star_score_modal_txt: getLabel(
      state,
      'star_score_modal_txt',
      'Earn star when you finished any lesson to use for hints in the games'
    ),
    isBusinessSignUp: isBusinessSignUp,
    showGift: isShowGift && !isProUser,
    isShowInviteFriend: isShowInviteFriend && !isProUser,
    isLoginModalOpen: isLoginModalOpen,
    isUserSubscribed: isUserSubscribed(state),
    ...labels,
    faq: state.data.faq,
    bananas: getUserBananas(state),
    userCoins: getUserCoins(state),
    ...getUser(state),
    ...getFlagTargetLanguage(state),
    ...ownProps
  };
};

export default connect(mapStateToProps, {
  fetchUnitData,
  toggleLoginModal,
  setIsBusinessSignUp,
  setSignUpBusinessSuccess,
  getUserReferral,
  getInviteCampaigns,
  signInWithEmailAndPassword,
  signInAnonymously,
  signInWithFacebook,
  signInWithGoogle,
  signInWithGoogleRedirect,
  signInWithApple,
  signUpWithEmail,
  sendPasswordResetEmail,
  signOut,
  pauseDialog
})(DefaultHeaderWithRouter);
