/* @flow */
import * as types from '../constants/ActionTypes';
import type { Game as State } from '../types';
export type { Game as State } from '../types';

const INITIAL_STATE: State = {
  words: {},
  sentences: {},
  grammar: {},
  dialogues: {},
  key: '',
  gameOrder: []
};
const closeLesson = (state: Object, game: Object) => ({
  ...INITIAL_STATE
});
const startLesson = (state: Object, game: Object) => ({
  ...state,
  ...game
});

const newGameScreen = (state: Object, gameOrder: Object) => ({
  ...state,
  gameOrder: gameOrder ? gameOrder : state.gameOrder
});

export const GameReducer = (
  state: State = INITIAL_STATE,
  action: Object
): State => {
  const { type, lesson } = action;
  switch (type) {
    case types.CLOSE_GAME:
      return closeLesson(state, action.gameOrder);
    case types.REFRESH_GAME:
      return newGameScreen(state, action.gameOrder);
    case types.START_GAME:
    case types.START_EXAM:
    case types.START_SPEAKING:
    case types.START_RANDOM_TEST:
    case types.START_WRITING:
      return startLesson(state, lesson);
    default:
      return state;
  }
};
