/* @flow */
import _ from 'lodash';
import {
  LOAD_CHATBOT,
  UPDATE_HISTORY,
  UPDATE_CURRENT,
  UPDATE_CHOICE,
  UPDATE_SELECTED_CHOICE,
  RESET_CHATBOT,
  TOGGLE_CHATBOT_AUTO_PLAY,
  UPDATE_ERROR_IN_HISTORY,
  LOAD_CHATBOT_NAME,
  RESET_CHATBOT_HISTORY,
  CHATBOT_PLAYING
} from '../../games/constants';

type State = {
  remainChatbots: Array<Object>,
  chatHistories: Array<Object>,
  choices: Array<Object>,
  currentChat: Object,
  selectedChoice: string,
  progressMax: number,
  autoplay: boolean,
  name: string,
  label: string,
  isChatbot: boolean,
  playing: boolean
};

const INITIAL_STATE = {
  remainChatbots: [],
  chatHistories: [],
  choices: [],
  currentChat: {},
  selectedChoice: '',
  progressMax: 0,
  autoplay: true,
  name: '',
  playing: false,
  label: '',
  isChatbot: false
};

const loadChatbots = (state: State, data: Array<Object>) => {
  _.reverse(data);
  return {
    ...state,
    remainChatbots: data,
    progressMax: data.length,
    isChatbot: true
  };
};

const chatbotPlaying = (state: State, playing: boolean) => {
  return { ...state, playing };
};

const loadChatbotsName = (state: State, label: string) => ({
  ...state,
  label
});

const updateHistory = (state: State, chatHistories: Array<Object>) => ({
  ...state,
  chatHistories,
  choices: [],
  selectedChoice: ''
});

const updateCurrent = (state: State, currentChat: Object) => ({
  ...state,
  currentChat
});

const updateChoices = (state: State, choices: Array<Object>) => ({
  ...state,
  choices
});

const updateSelectedChoice = (state: State, selectedChoice: string) => ({
  ...state,
  selectedChoice
});

const toggleAutoPlay = (state: State, autoplay: boolean) => ({
  ...state,
  autoplay
});

const updateErrorInHistory = (state: State, chatHistories: Array<Object>) => ({
  ...state,
  chatHistories
});

const resetChatbot = (state: State) => ({
  ...state,
  remainChatbots: [],
  chatHistories: [],
  choices: [],
  currentChat: {},
  selectedChoice: '',
  progressMax: 0,
  isChatbot: false,
  playing: false
});

const resetChatbotsHistory = (state: State) => ({
  ...state,
  chatHistories: []
});

export const ChatbotsReducers = (
  state: State = INITIAL_STATE,
  action: Object = {}
) => {
  switch (action.type) {
    case LOAD_CHATBOT:
      return loadChatbots(state, action.data);
    case LOAD_CHATBOT_NAME:
      return loadChatbotsName(state, action.data);
    case UPDATE_HISTORY:
      return updateHistory(state, action.chatHistories);
    case UPDATE_CURRENT:
      return updateCurrent(state, action.currentChat);
    case UPDATE_CHOICE:
      return updateChoices(state, action.choices);
    case UPDATE_SELECTED_CHOICE:
      return updateSelectedChoice(state, action.selectedChoice);
    case TOGGLE_CHATBOT_AUTO_PLAY:
      return toggleAutoPlay(state, action.autoplay);
    case UPDATE_ERROR_IN_HISTORY:
      return updateErrorInHistory(state, action.chatHistories);
    case RESET_CHATBOT_HISTORY:
      return resetChatbotsHistory(state);
    case RESET_CHATBOT:
      return resetChatbot(state);
    case CHATBOT_PLAYING:
      return chatbotPlaying(state, action.playing);
    default:
      return state;
  }
};
