/* @flow */
import {
  CLOSE_COMPLETE_SCREEN,
  DIALOGUE,
  EXAM_INDEX,
  INCREASE_ANSWERED_WRONG,
  INCREASE_ANSWERED_WRONG_COUNT,
  INCREASE_COMPLETED_SCREEN,
  INCREASE_GAME_SKIP_COUNT,
  MOVE_TO_LOCKED_IDS,
  ORDER_SENTENCE,
  ORDER_SENTENCE_REVERSE,
  RANDOM_GAME_INDEX,
  REFRESH_GAME,
  RESET_ANSWERED_WRONG_COUNT,
  RESET_GAME_SKIP_COUNT,
  SPEAKING,
  SPEAKING_GAME_INDEX,
  SPELLING,
  SPEND_HINT,
  TOGGLE_ENABLE_HINT_DIALOG,
  TOGGLE_GAME_COMPLETE_SCREEN,
  TOGGLE_GAME_TRANSCRIPT,
  TOGGLE_GRAMMAR_SCREEN,
  TOGGLE_HINT_DIALOG,
  TOGGLE_NO_STARS_DIALOG,
  TOP_CARDS,
  WRITING_GAME_INDEX
} from '../constants';
import _ from 'lodash';
import { SET_QUEUE } from '../../common/constants';
import * as global from '../../common/actions/GlobalActions';
import BugTracker from '../../util/BugTracker';

import { getCurrentCourseData, getStopwords } from '../../lessons/selectors';
import {
  triggerUpdateUnitsProgress,
  loadStopWords
} from '../../lessons/actions';
import {
  getSpender,
  getTranscriptionState
} from '../../profile/selectors';
import {
  getUserCoins,
} from '../../profile/selectors/UserSelector';
import {
  refreshGame,
  getOrderSentenceChars,
  getDialogueChars,
  getGameData,
  startGame,
  startExam,
  startSpeaking,
  startWriting
} from './GameActions';
import { reloadGameOnToggleTranscript } from '../util';
import Analytic from '../../util/Analytic';
import { gameSound } from '../../util';
import {
  newGameOrderWhenAnsweredWrong,
  getUnitIdAndLessonId
} from '../selectors';
import { dialogRefreshGame } from './DialogActions';
import { getIsEffectEnabled } from '../../chatbots/selectors';
import { getUserCrowns } from '../../profile/actions/UserSelector';
import { replayRandomTest } from '../../lessons/actions/RandomTestActions';
export const toggleCompleteScreen = (examFailed: boolean = false) => {
  return (dispatch: Function, getState: Function) => {
    const state = getState();
    const { game, routes } = getState();
    const { gameType, gameWrongAnswered, showCompleteScreen } = routes;
    const isEffectEnabled = getIsEffectEnabled(state);
    if (examFailed) {
      Analytic.log(Analytic.key.L6, {
        content: game.key,
        game_type: gameType,
        answer: gameWrongAnswered
      });
      Analytic.logGA(Analytic.key.L6, {
        category: Analytic.key.L6,
        action: 'Check',
        label: gameType,
        value: gameWrongAnswered
      });
    }
    dispatch(triggerUpdateUnitsProgress());
    dispatch({
      type: TOGGLE_GAME_COMPLETE_SCREEN,
      examFailed
    });

    if (!showCompleteScreen) {
      if (examFailed) {
        if (isEffectEnabled) {
          gameSound('fail');
        }
      } else {
        if (isEffectEnabled) {
          gameSound('finish');
        }
      }
    }
  };
};

export const closeCompleteScreen = () => ({
  type: CLOSE_COMPLETE_SCREEN
});

export const toggleGrammarScreen = () => ({ type: TOGGLE_GRAMMAR_SCREEN });

export const toggleTranscript = () => {
  return (dispatch: Function, getState: Function) => {
    const state = getState();
    const { game, routes, spelling } = state;
    const { gameType } = routes;
    const transcript = getTranscriptionState(state);

    if (!transcript) {
      Analytic.log(Analytic.key.F3, {
        content: game.key,
        game_type: routes.gameType,
        answer: routes.gameWrongAnswered
      });
      Analytic.logGA(Analytic.key.F3, {
        category: Analytic.key.F3,
        action: 'Click',
        label: routes.gameType,
        value: routes.gameWrongAnswered
      });
    }
    if (reloadGameOnToggleTranscript(gameType)) {
      if (!spelling.isResultShown) {
        dispatch(toggleGameTranscript(gameType));
      }
      dispatch(refreshGame());
    } else {
      dispatch(toggleGameTranscript(gameType));
    }
  };
};

const toggleGameTranscript = (gameType: string) => (
  dispatch: Function,
  getState: Function
) => {
  const state = getState();
  const { game, dialog, spelling, routes } = getState();
  const { gameType } = routes;
  const transcription = getTranscriptionState(state);
  let newWords;
  if (gameType === ORDER_SENTENCE) {
    newWords = getOrderSentenceChars(
      game.sentences.byId,
      spelling.wordId,
      !transcription
    );
  } else if (gameType === DIALOGUE) {
    newWords = getDialogueChars(
      dialog.dialogues,
      dialog.questions,
      dialog.questionIds,
      !transcription
    );
  }
  dispatch({
    type: TOGGLE_GAME_TRANSCRIPT,
    newWords
  });
  dispatch(global.updateUserProfile({ transcription: !transcription }));
};

export const pushGameScreen = (
  gameData: Object,
  gameOrder: Array<Object>,
  isRestart: boolean = false
) => (dispatch: Function) => {
  dispatch(newGameScreen(gameData, gameOrder));
};

export const newGameScreen = (gameData: Object, gameOrder: Object[]) => (
  dispatch: Function,
  getState: Function
) => {
  const { features } = getCurrentCourseData(getState());
  const stopWords = getStopwords(getState());
  dispatch(loadStopWords(stopWords));
  dispatch({ type: REFRESH_GAME, gameOrder, features, ...gameData });
};

export const refreshGameScreen = (gameData: Object, gameOrder: Object[]) => (
  dispatch: Function,
  getState: Function
) => {
  const { features } = getCurrentCourseData(getState());

  dispatch({
    type: REFRESH_GAME,
    refresh: true,
    gameOrder,
    features,
    ...gameData
  });
};

export const increaseAnsweredWrong = () => ({
  type: INCREASE_ANSWERED_WRONG
});

export const increaseAnsweredWrongCount = () => ({
  type: INCREASE_ANSWERED_WRONG_COUNT
});

export const resetAnsweredWrongCount = () => ({
  type: RESET_ANSWERED_WRONG_COUNT
});

export const increaseGameSkipCount = () => ({
  type: INCREASE_GAME_SKIP_COUNT
});

export const resetGameSkipCount = () => ({
  type: RESET_GAME_SKIP_COUNT
});

export const spendHint = (callback: Function = () => {}) => (
  dispatch: Function,
  getState: Function
) => {
  const state = getState();
  const { game, routes } = state;
  const { gameType } = routes;

  if (Number(getUserCoins(state)) > 0) {
    if (routes.hintDialogEnabled) {
      dispatch(toggleHintDialog());
    }

    dispatch(spendHintAction());

    if (typeof callback === typeof Function) {
      callback();
    }
    const state = getState();
    const { spelling } = state;
    const { byId, pressedIds, correctAnswer } = spelling;

    const hintCardIds = getHintCardIndex(
      gameType === ORDER_SENTENCE_REVERSE ? correctAnswer : Object.values(byId),
      pressedIds,
      TOP_CARDS,
      getState().spelling.hintMode,
      gameType
    );

    _.forEach(hintCardIds, data => {
      const charId = data.index;
      dispatch({ type: MOVE_TO_LOCKED_IDS, charId });
    });
  } else {
    Analytic.log(Analytic.key.F2, {
      content: game.key,
      game_type: routes.gameType,
      answer: routes.gameWrongAnswered
    });
    Analytic.logGA(Analytic.key.F2, {
      category: Analytic.key.F2,
      action: 'Check',
      label: routes.gameType,
      value: routes.gameWrongAnswered
    });

    dispatch(toggleNoStarsDialog());
  }
};

export const toggleHintDialog = () => ({ type: TOGGLE_HINT_DIALOG });

export const toggleEnableHintDialog = () => ({
  type: TOGGLE_ENABLE_HINT_DIALOG
});

export const toggleNoStarsDialog = () => ({ type: TOGGLE_NO_STARS_DIALOG });

export const spendHintAction = () => (
  dispatch: Function,
  getState: Function
) => {
  const state = getState();
  const { game, routes } = state;
  Analytic.log(Analytic.key.F1, {
    content: game.key,
    game_type: routes.gameType,
    answer: routes.gameWrongAnswered
  });
  Analytic.logGA(Analytic.key.F1, {
    category: Analytic.key.F1,
    label: routes.gameType,
    value: routes.gameWrongAnswered,
    action: 'Check'
  });
  const coins = Number(getUserCoins(state)) - 1;
  const coinsSpent = Number(getSpender(state)) + 1;
  dispatch(
    global.updateUserProfile({
      coins: coins,
      coinsSpent: coinsSpent
    })
  );

  dispatch({ type: SPEND_HINT, game });
  dispatch(playSound());
};

const playSound = () => {
  return (dispatch: Function, getState: Function) => {
    const state = getState();
    const isEffectEnabled = getIsEffectEnabled(state);
    if (isEffectEnabled) {
      gameSound('hint');
    }
  };
};

export const increaseCompletedScreen = () => ({
  type: INCREASE_COMPLETED_SCREEN
});

export const setQueue = (queue: Array<boolean>) => ({
  type: SET_QUEUE,
  queue: queue
});

export const doCurrentGameScreen = () => (
  // $FlowFixMe
  dispatch: Dispatch,
  // $FlowFixMe
  getState: GetState
) => {
  const state = getState();
  const { game, routes } = state;
  const { gameScreensCompleted, isExam, gameType, isCurrentGameWrong } = routes;
  const gameOrder =
    isExam || [DIALOGUE, SPEAKING].includes(gameType)
      ? game.gameOrder
      : newGameOrderWhenAnsweredWrong(
          game.gameOrder,
          gameScreensCompleted,
          isCurrentGameWrong
        );

  gameType === DIALOGUE && dispatch(dialogRefreshGame());

  dispatch(
    newGameScreen(
      getGameData(gameScreensCompleted, game, gameOrder, state),
      gameOrder
    )
  );
};

export const replayGame = () => (dispatch: Function, getState: Function) => {
  const state = getState();
  const { game, routes } = state;
  const { unitId, lessonId } = getUnitIdAndLessonId(state);
  const stars = getUserCrowns(state, unitId, lessonId);
  Analytic.log(Analytic.key.L4, {
    content: game.key,
    game_type: routes.gameType,
    answer: routes.gameWrongAnswered,
    stars
  });
  Analytic.logGA(Analytic.key.L4, {
    category: Analytic.key.L4,
    action: 'Click',
    label: routes.gameType,
    value: routes.gameWrongAnswered
  });
  if (routes.isRandomTest) {
    dispatch(getGameRestartAction(0, 0));
  } else {
    dispatch(getGameRestartAction(unitId, lessonId));
  }
};
export const getGameRestartAction = (unitId: number, lessonId: number) => {
  switch (lessonId) {
    case EXAM_INDEX:
      return startExam(unitId, true);
    case SPEAKING_GAME_INDEX:
      return startSpeaking(unitId, true);
    case WRITING_GAME_INDEX:
      return startWriting(unitId, true);
    case RANDOM_GAME_INDEX:
      return replayRandomTest();
    default:
      return startGame(lessonId, unitId, true);
  }
};

export const getHintCardIndex = (
  id: any,
  ids: any,
  cardStatue: string,
  hintMode: boolean,
  gameType: string
) => {
  if (hintMode === true) {
    if (
      gameType === SPELLING ||
      gameType === ORDER_SENTENCE ||
      gameType === DIALOGUE
    ) {
      let tempIds = [];
      let randomItem = [];
      _.forEach(ids, (value, key) => {
        if (value === null) {
          tempIds.push({ index: key, value: value });
        }
      });
      let idLenght = 0;
      id.map((item, key) => {
        if (ids[key] === null) {
          return idLenght++;
        }
        return idLenght;
      });

      _.times(_.ceil(tempIds.length / 2), function() {
        const randomId = Math.floor(Math.random() * Math.floor(idLenght));
        const temp = tempIds[randomId];
        _.remove(tempIds, function(n) {
          try {
            return n.index === temp.index;
          } catch (e) {
            console.log('Error', e);
            console.log('Input', temp, n);
            BugTracker.notify(e);

            return false;
          }
        });
        randomItem.push(temp);
        idLenght--;
      });
      return randomItem;
    } else if (gameType === ORDER_SENTENCE_REVERSE) {
      let tempIds = [];
      let randomItem = [];
      _.forEach(id, (value, key) => {
        if (ids[key] === null) {
          tempIds.push({ index: key, value: value });
        }
      });
      let idLenght = 0;
      id.map((item, key) => {
        if (ids[key] === null) {
          return idLenght++;
        }
        return idLenght;
      });

      _.times(_.ceil(tempIds.length / 2), function() {
        const randomId = Math.floor(Math.random() * Math.floor(idLenght));
        const temp = tempIds[randomId];
        _.remove(tempIds, function(n) {
          try {
            return n.index === temp.index;
          } catch (e) {
            console.log('Error', e);
            console.log('Input', temp, n);
            BugTracker.notify(e);

            return false;
          }
        });
        randomItem.push(temp);
        idLenght--;
      });
      return randomItem;
    }
  }
};
