import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MotivationButton from '../selectorComponents/subComponents/MotivationButton';

const MotivationalScreen = ({
  headerData,
  handleScreenChange,
  selected,
  setSelected,
  labels
}) => {
  const { modals_btt_no, modals_btt_yes } = labels;

  const theme = useTheme();

  const motivationChoice = {
    no: 1,
    yes: 2
  };

  // Proceed to next screen right away on choice selection for Motivation screens
  useEffect(() => {
    if (selected) {
      handleScreenChange(selected);
    }
  }, [selected, handleScreenChange]);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      h: '1/2',
      w: '1/1'
    },
    topContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      height: '20%',
      width: 250,
      textAlign: 'center',
      mt: 10,
      [theme.breakpoints.up('sm')]: {
        width: 360
      }
    },
    img: {
      height: '65px',
      mb: 4
    },
    header: {
      fontWeight: '700',
      fontSize: 16,
      [theme.breakpoints.up('sm')]: {
        fontSize: 20
      }
    },
    bottomContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      width: '80%',
      height: '30%',
      mt: 13,
      mb: 6
    },
    questionText: {
      fontSize: 30,
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: 36
      }
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%'
    },
    noButton: {
      backgroundColor: theme.palette.buttons.red.active,
      borderRadius: 2,
      width: '170px',
      height: '48px',
      padding: '0',
      '&:hover': {
        backgroundColor: theme.palette.buttons.red.hover
      },
      margin: '12px',
      textTransform: 'none',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      color: '#FFFFFF'
    },
    yesButton: {
      backgroundColor: theme.palette.buttons.green.active,
      borderRadius: 2,
      width: '170px',
      height: '48px',
      padding: '0',
      '&:hover': {
        backgroundColor: theme.palette.buttons.green.hover
      },
      margin: '12px',
      textTransform: 'none',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      color: '#FFFFFF'
    }
  };
  return (
    <Box sx={styles.container}>
      <Box sx={styles.topContainer}>
        <Box component="img" src={headerData.icon} sx={styles.img} />
        <Typography variant="subtext" sx={styles.header}>
          {headerData.text}
        </Typography>
      </Box>
      <Box sx={styles.bottomContainer}>
        <Typography variant="text" sx={styles.questionText}>
          {headerData.subtext}
        </Typography>
      </Box>
      <Box sx={styles.buttonContainer}>
        <MotivationButton
          setSelected={setSelected}
          motivationChoice={motivationChoice.no}
          buttonStyle={styles.noButton}
          buttonText={modals_btt_no}
        />
        <MotivationButton
          setSelected={setSelected}
          motivationChoice={motivationChoice.yes}
          buttonStyle={styles.yesButton}
          buttonText={modals_btt_yes}
        />
      </Box>
    </Box>
  );
};

export default MotivationalScreen;
