/* @flow */
// $FlowFixMe for useState
import React, { useState } from 'react';
import { CAROUSEL_SPEED, PREFIX_SHOP } from '../../../common/constants';
import { CarouselItem, Carousel, CarouselIndicators } from 'reactstrap';
import { next, previous, onExiting, onExited } from '../../../util';
import {
  HeroContentTextStyle,
  HeroContentHeaderStyle,
  HeroContentImageStyle,
  HeroContentImageContainerStyle,
  HeroContentStyle,
  CarouselContainer,
  HeroContentSubTextStyle,
  IndicatorsSpace
} from './ShopSlices.styles';
type Props = {
  isPromo: boolean,
  animating: any,
  shopSlideEntries: Object[]
};
const lingProImg = `${PREFIX_SHOP}ling-pro-image.png`;

const ShopSlices = ({ shopSlideEntries, isPromo, animating }: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <CarouselContainer isPromo={isPromo}>
      <Carousel
        activeIndex={activeIndex}
        next={() =>
          next(animating, activeIndex, shopSlideEntries, setActiveIndex)
        }
        previous={() =>
          previous(animating, activeIndex, shopSlideEntries, setActiveIndex)
        }
        ride="carousel"
        pause={false}
        interval={CAROUSEL_SPEED}
      >
        {shopSlideEntries.map((item, index) => (
          <CarouselItem
            onExiting={onExiting}
            onExited={onExited}
            key={item.title}
          >
            <HeroContentStyle isPromo={isPromo}>
              <HeroContentImageContainerStyle>
                <HeroContentImageStyle src={item.img} />
              </HeroContentImageContainerStyle>
              <img src={lingProImg} alt={lingProImg} />
              <HeroContentTextStyle isPromo={isPromo}>
                <HeroContentHeaderStyle>{item.title}</HeroContentHeaderStyle>
                {item.des && (
                  <HeroContentSubTextStyle>{item.des}</HeroContentSubTextStyle>
                )}
              </HeroContentTextStyle>
            </HeroContentStyle>
          </CarouselItem>
        ))}
        <IndicatorsSpace />

        <CarouselIndicators
          items={shopSlideEntries}
          activeIndex={activeIndex}
          onClickHandler={setActiveIndex}
        />
      </Carousel>
    </CarouselContainer>
  );
};

export { ShopSlices };
