/* @flow */
import * as React from 'react';
import { playSound, stopSound } from '../../../util';
import {
  CardColumn,
  TranslateElementCard,
  Container,
  CheckButton,
  QuestionHeader
} from '../sub-components';
import type {
  ByIdProps,
  AllIdsProps,
  QuizCardProps
} from '../../reducers/QuizReducer';
import {
  TRANSLATEGAME,
  BUTTON_DISABLED,
  BUTTON_CHECK_DISABLED,
  BUTTON_CONTINUE_DISABLED,
  BUTTON_SEND_DISABLED,
  KEYCODE_1,
  KEYCODE_2,
  KEYCODE_3,
  KEYCODE_4,
  KEYCODE_ENTER
} from '../../constants';

import styled from 'styled-components';
const TranslateGameSceneContainer = styled.div`
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100vw;
  display: flex;
  min-height: 680px;
`;
export type Props = {
  byId: ByIdProps,
  allIds: AllIdsProps,
  correctWord: Object,
  pressQuizCard: Function,
  getCardStyle: Function,
  pressQuizNextButton: Function,
  animationFinished: Function,
  loadAnimationFinished: boolean,
  nextGameScreen: () => void,
  mode: string,
  isAnswerWrong: boolean,
  translate_txt_title: string,
  transcriptState: any,
  btn_check: string,
  btn_continue: string,
  btn_hint: string,
  coins: number,
  voiceSpeed: number
};

const TranslatePageContainer = styled.div`
  max-width: 1024px;
  width: 100%;
  margin-top: 110px;
  @media (min-height: 730px) {
    margin-top: 0px;
  }
`;

const CardsContainer = styled.div`
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding: 2px 25px;
  margin-bottom: ${props =>
    props.gameType === TRANSLATEGAME ? '110px' : 'unset'};
`;

const TranslateStyle = styled.p`
  font-size: 18px;
  margin: 10px !important;
`;

const TrabslateContentContainer = styled.div`
  max-width: 1024px;
`;
class TranslateGame extends React.Component<Props> {
  componentDidMount() {
    window.scrollTo(0, 0);
    // $flow-disable-line
    document.addEventListener('keydown', this.handleEnter);
  }
  componentWillUnmount() {
    // $flow-disable-line
    document.removeEventListener('keydown', this.handleEnter);
  }
  handleEnter = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { pressQuizNextButton, mode, byId, pressQuizCard } = this.props;
    if (
      event.keyCode === KEYCODE_ENTER &&
      pressQuizNextButton &&
      mode !== BUTTON_DISABLED &&
      mode !== BUTTON_CHECK_DISABLED &&
      mode !== BUTTON_CONTINUE_DISABLED &&
      mode !== BUTTON_SEND_DISABLED
    ) {
      pressQuizNextButton();
    } else if (
      event.keyCode === KEYCODE_1 ||
      event.keyCode === KEYCODE_2 ||
      event.keyCode === KEYCODE_3 ||
      event.keyCode === KEYCODE_4
    ) {
      const key = parseInt(event.key, 10);
      switch (key) {
        case 1:
          pressQuizCard(byId[2].id);
          break;
        case 2:
          pressQuizCard(byId[1].id);
          break;
        case 3:
          pressQuizCard(byId[4].id);
          break;
        case 4:
          pressQuizCard(byId[3].id);
          break;
        default:
          break;
      }
    }
  };

  render() {
    const {
      byId,
      correctWord,
      pressQuizNextButton,
      mode,
      transcriptState,
      isAnswerWrong,
      translate_txt_title,
      btn_check,
      btn_continue,
      btn_hint,
      coins,
      voiceSpeed
    } = this.props;

    const { original, transcript, soundFile } = correctWord;
    const isTranslateGame = true;
    return (
      <TranslateGameSceneContainer>
        <TrabslateContentContainer>
          <TranslatePageContainer>
            <QuestionHeader
              isTranslateGame={isTranslateGame}
              soundFile={soundFile}
              instruction={translate_txt_title}
              transcript={transcriptState ? transcript : ''}
              voiceSpeed={voiceSpeed}
            >
              {original}
            </QuestionHeader>
          </TranslatePageContainer>
          <Container gameType={TRANSLATEGAME}>
            <CardsContainer gameType={TRANSLATEGAME}>
              <CardColumn
                rightCard={this.getElementFromCard(byId[1], 2)}
                leftCard={this.getElementFromCard(byId[2], 1)}
              />
              <CardColumn
                rightCard={this.getElementFromCard(byId[3], 4)}
                leftCard={this.getElementFromCard(byId[4], 3)}
              />
            </CardsContainer>
          </Container>
        </TrabslateContentContainer>

        <CheckButton
          quizAnswer={''}
          answeredWrong={isAnswerWrong}
          onPressNext={() => {
            stopSound();
            pressQuizNextButton();
          }}
          mode={mode}
          btn_check={btn_check}
          btn_continue={btn_continue}
          btn_hint={btn_hint}
          gameType={TRANSLATEGAME}
          coins={coins}
        />
      </TranslateGameSceneContainer>
    );
  }

  getElementFromCard(card: QuizCardProps, index: number): React.Element<any> {
    const { status, id, translation } = card;
    const { getCardStyle } = this.props;
    const content = <TranslateStyle>{translation}</TranslateStyle>;

    const wordCardStyle = getCardStyle(status);

    return (
      <TranslateElementCard
        onPress={this.onPressCard(id)}
        style={wordCardStyle}
        onAnimationEnd={this.onAnimationEnd()}
        content={content}
        status={status}
        index={index}
      />
    );
  }

  onAnimationEnd() {
    return () => {
      const { loadAnimationFinished, animationFinished } = this.props;
      animationFinished(loadAnimationFinished);
    };
  }

  onPressCard(wordId: string): () => void {
    return () => {
      this.props.pressQuizCard(wordId);
    };
  }
  onPressTitle(soundFile: string, voiceSpeed: number): () => void {
    return () => {
      playSound(soundFile, voiceSpeed);
    };
  }
}

export { TranslateGame };
