/* @flow */
import _ from 'lodash';

import { DEFAULT_TARGET_LANGUAGE_CODE } from '../common/constants';

export const getData = ({ data }: Object) => data;

export const getLessons = ({ lessons }: Object) => lessons;

export const getCourses = ({ courses }: Object) => courses;

export const getTargetLangCode = ({ data }: Object) => data.targetLangCode;

export const getNativeLangCode = ({ data }: Object) => data.nativeLangCode;

export const getStopwords = ({ courses }: Object) =>
  _.get(courses, 'nativeLanguageFeatures.stopWords', []);

export const getStorage = ({ storage }: Object) => storage;

export const isDialogHeadersDownloaded = ({ data }: Object) =>
  data.dialogHeadersDownloaded;

export const getCurrentCourseData = ({ courses }: Object) => {
  const activeLangCode = _.get(
    courses,
    'activeLangCode',
    DEFAULT_TARGET_LANGUAGE_CODE
  );
  return _.get(courses, activeLangCode, { features: {} });
};

export const hasTranscript = (state: Object) => {
  return _.get(getCurrentCourseData(state), 'features.hasTranscript', false);
};

export const hasWriting = (state: Object) => {
  return _.get(getCurrentCourseData(state), 'features.hasWriting', false);
};

export const hasFeatures = (state: Object) => {
  return _.get(getCurrentCourseData(state), 'features', false);
};

export const getCourseData = ({ courses }: Object, langCode: string) =>
  _.get(courses, langCode, null);

export const isWrittenNumbersLoaded = ({ data, courses }: Object) =>
  _.get(courses, `${data.targetLangCode}.isWrittenNumbersLoaded`, false);

export const getFileWatchers = ({ courses }: Object) =>
  _.get(courses, 'fileWatchers', []);

export const getCachedData = ({ data }: Object, unitId: number) => {
  const { courses } = data;
  let merge = _.get(courses, `${courses.native}.${courses.target}`, false);
  if (merge) {
    const { words, sentences, dialogues, grammar, characters } = merge;
    return {
      words: getUnitEntries(words, unitId),
      sentences: getUnitEntries(sentences, unitId),
      dialogues: getUnitEntries(dialogues, unitId),
      grammar: grammar,
      characters: characters,
      nativeLangCode: courses.native
    };
  }
};

export const getUnitEntries = (phrases: Object, unitId: number) => {
  let unitEntries = {};
  for (const [key, value] of Object.entries(phrases)) {
    if (Number(_.split(key, ',')[0]) === unitId) {
      unitEntries[key] = value;
    }
  }
  return unitEntries;
};
