/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import {
  BLUE,
  LIGHT_BLUE,
  CLEAR_BLUE,
  WHITE,
  BLACK,
  LIGHT_GREY,
  GREEN_COLOR,
  DARK_GREEN_COLOR,
  BTN_DARK_RED_COLOR,
  RED_COLOR,
  LIGHT_GREY_TRANSCRIPT_2,
  PREFIX_EXAM,
  quizStatus
} from '../../../common/constants';
import { getUnitContentImageUrl } from '../../../util';
const { MATCH, NO_MATCH, SELECTED, VISIBLE } = quizStatus;

const QuizCardContainer = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 20px;
  width: ${props => (props.isShowTutorial ? '153' : '212')}px;
  height: auto;
  padding: ${props => (props.isShowTutorial ? '0' : '30')} 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border: solid 4px
    ${props => {
      const cardStatue = props.cardStatue;
      if (cardStatue === SELECTED) {
        return BLUE;
      } else if (cardStatue === MATCH) {
        return GREEN_COLOR;
      } else if (cardStatue === NO_MATCH) {
        return BTN_DARK_RED_COLOR;
      } else {
        return LIGHT_GREY;
      }
    }};
  background-color: ${props => {
    const cardStatue = props.cardStatue;
    if (cardStatue === SELECTED) {
      return '';
    } else if (cardStatue === MATCH) {
      return GREEN_COLOR;
    } else if (cardStatue === NO_MATCH) {
      return RED_COLOR;
    } else {
      return '';
    }
  }};
  background-image: ${props => {
    const cardStatue = props.cardStatue;
    if (cardStatue === SELECTED) {
      return LIGHT_BLUE;
    } else {
      return '';
    }
  }};
  color: ${props => {
    const cardStatue = props.cardStatue;
    if (cardStatue === SELECTED || cardStatue === VISIBLE) {
      return BLACK;
    } else {
      return WHITE;
    }
  }};
  box-shadow: inset 0 -8px 0 0 ${props => {
      const cardStatue = props.cardStatue;
      if (cardStatue === SELECTED) {
        return CLEAR_BLUE;
      } else {
        return LIGHT_GREY;
      }
    }};
`;

const QuizImage = styled.img`
  width: 80px;
  height: 80px;
  margin: 20px;
`;

const QuizTextContainer = styled.div``;

const QuizAnswerText = styled.p`
  display: ${props => (props.isShowTutorial ? 'none' : 'block')};
  font-size: 18px;
  font-weight: bold;
  line-height: 1.56;
  margin: 0px;
  color: ${props =>
    props.cardStatue === MATCH || props.cardStatue === NO_MATCH
      ? WHITE
      : LIGHT_GREY_TRANSCRIPT_2};
  &.original-style {
    font-size: 25px;
  }
`;

const QuizImageNull = styled.img`
  height: 80px;
  margin: 20px;
`;

const KeyBoardSymbol = styled.span`
  border-radius: 11px;
  color: ${props => {
    const cardStatue = props.cardStatue;
    if (cardStatue === SELECTED) {
      return BLUE;
    } else if (cardStatue === MATCH) {
      return DARK_GREEN_COLOR;
    } else if (cardStatue === NO_MATCH) {
      return BTN_DARK_RED_COLOR;
    } else {
      return LIGHT_GREY;
    }
  }};
  font-size: 15px;
  font-weight: 700;
  height: 30px;
  line-height: 30px;
  width: 30px;
  position: absolute;
  left: 5px;
  top: 5px;
  border: solid 4px
    ${props => {
      const cardStatue = props.cardStatue;
      if (cardStatue === SELECTED) {
        return BLUE;
      } else if (cardStatue === MATCH) {
        return DARK_GREEN_COLOR;
      } else if (cardStatue === NO_MATCH) {
        return BTN_DARK_RED_COLOR;
      } else {
        return LIGHT_GREY;
      }
    }};
  background-color: ${props => {
    const cardStatue = props.cardStatue;
    if (cardStatue === SELECTED) {
      return '';
    } else if (cardStatue === MATCH) {
      return GREEN_COLOR;
    } else if (cardStatue === NO_MATCH) {
      return RED_COLOR;
    } else {
      return '';
    }
  }};
  background-image: ${props => {
    const cardStatue = props.cardStatue;
    if (cardStatue === SELECTED) {
      return LIGHT_BLUE;
    } else {
      return '';
    }
  }};
`;
const questionImg = `${PREFIX_EXAM}questionMarkDraw.png`;
const QuizCard = ({
  card,
  transcriptState,
  isShowImage = false,
  isShowTutorial,
  isTargetLangSymbol,
  index
}: Object) => {
  const { status, id, original, transcript = '' } = card;

  return (
    <QuizCardContainer cardStatue={status} isShowTutorial={isShowTutorial}>
      <KeyBoardSymbol cardStatue={status}>{index}</KeyBoardSymbol>
      {isShowImage ? (
        <QuizImage src={getUnitContentImageUrl(id)} alt={id} />
      ) : (
        <QuizImageNull src={questionImg} alt={questionImg} />
      )}
      <QuizTextContainer>
        <QuizAnswerText
          className={isTargetLangSymbol ? 'original-style' : null}
          cardStatue={status}
        >
          {original}
        </QuizAnswerText>
        {!transcriptState || !transcript ? (
          <QuizAnswerText isShowTutorial={isShowTutorial} cardStatue={status}>
            &nbsp;
          </QuizAnswerText>
        ) : (
          <QuizAnswerText cardStatue={status}>{transcript}</QuizAnswerText>
        )}
      </QuizTextContainer>
    </QuizCardContainer>
  );
};

export { QuizCard };
