/* @flow */
import * as React from 'react';
import './QuitModal.css';
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import {
  GREYISH_BROWN,
  PREFIX_LESSON,
  PREFIX_HEADER,
  WHITE,
  GREY,
  GREY2
} from '../../../common/constants';

const ModalImage = styled.img`
  padding-top: 32px;
  padding-bottom: 0px;
`;

const ModalTitle = styled.h1`
  font-size: 28px;
  margin-bottom: 0;
  color: ${GREYISH_BROWN};
  font-weight: bold;
`;

const ModalBodyText = styled.p`
  font-size: 18px;
  color: ${GREYISH_BROWN};
  line-height: 21px;
  margin-top: 7px !important;
`;

const ModalContentContainer = styled.div`
  width: 230px;
  margin: auto;
`;

const ModalCloseButton = styled.button`
  position: fixed;
  opacity: 1;
  padding: 16px 20px;
`;

const ComfirmButton = styled.button`
  border-radius: 50px;
  padding: 0;
  border: none;
  height: 80px;
  width: 80px;
  & img {
    height: 80px;
    width: 80px;
  }
`;

const styles = {
  modalContainer: {
    width: '460px',
    height: '480px',
    textAlign: 'center',
    borderRadius: 30
  },
  modalBodyContainer: {
    padding: 0,
    paddingBottom: 21
  },
  buttonStyle: {
    borderRadius: 10,
    width: 160,
    height: 55,
    background: WHITE,
    border: 'solid 1px',
    borderColor: GREY2,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    boxShadowColor: GREY2,
    fontWeight: 'bold',
    fontSize: 18
  },
  cancelButtonStyle: {
    border: 'none',
    boxShadow: 'none',
    width: 100
  },
  confirmButtonStyle: {
    borderColor: GREY2,
    background: WHITE,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0'
  },
  modalHeader: {
    borderBottom: 'none',
    flexDirection: 'row-reverse',
    justifyContent: 'normal'
  },
  modalFooter: {
    borderTop: 'none',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 36,
    paddingRight: 32,
    paddingLeft: 32
  },
  contentContainer: {
    borderRadius: 30
  },
  modalHeaderContainer: {
    'modal-title': 'w-100 text-center'
  }
};

const dangerIcon = `${PREFIX_HEADER}basecolor-rocket-circle-yellow-176-px.svg`;
const closeIcon = `${PREFIX_LESSON}basecolor-black.svg`;
const confirmIcon = `${PREFIX_HEADER}icon-24-circle.svg`;

type Props = {
  closeEmailSent: Function,
  reset_password_txt_description: string
};

const ResetPasswordModal = ({
  closeEmailSent,
  reset_password_txt_description
}: Props) => {
  const closeBtn = (
    <ModalCloseButton className="close" onClick={closeEmailSent}>
      <img src={closeIcon} alt={closeIcon} />
    </ModalCloseButton>
  );

  const { modalHeader, modalBodyContainer, modalFooter } = styles;
  return (
    <React.Fragment>
      <ModalHeader close={closeBtn} style={modalHeader}>
        <ModalImage src={dangerIcon} alt={dangerIcon} />
      </ModalHeader>
      <ModalContentContainer>
        <ModalBody style={modalBodyContainer}>
          <ModalTitle />
          <ModalBodyText
            dangerouslySetInnerHTML={{
              __html: getModalDescriptionText(reset_password_txt_description)
            }}
          />
        </ModalBody>
      </ModalContentContainer>

      <ModalFooter style={modalFooter}>
        <ComfirmButton
          onClick={closeEmailSent}
          className="confirmButton"
          // style={{ ...buttonStyle, ...confirmButtonStyle }}
        >
          <img src={confirmIcon} alt={confirmIcon} />
        </ComfirmButton>
      </ModalFooter>
    </React.Fragment>
  );
};

const getModalDescriptionText = descriptionText => {
  // descriptionText = descriptionText.replace(/\n/g, '<br />');
  return descriptionText;
};
export { ResetPasswordModal };
