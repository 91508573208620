import _ from 'lodash';
import publicIp from 'public-ip';
import hash from 'hash.js';
import * as momentTimezone from 'moment-timezone';
import {
  HIDE_NETWORK_ERROR_DIALOG,
  TOGGLE_SPINNER,
  TRIGGER_SIGN_OUT,
  COURSE_MIGRATION,
  LOAD_GLOBAL_CONFIG,
  LOAD_BUNDLE_FEATURE,
  SET_SHOW_TUTORIAL,
  TOGGLE_TUTORIAL_MOTIVATE,
  CLOSE_TUTORIAL_MOTIVATE,
  SET_LOADING,
  SET_CHATBOT_LOADED,
  TOGGLE_LOGIN_MODAL,
  SET_IS_BUSINESS_SIGN_UP,
  SET_SIGN_UP_BUSINESS_SUCCESS,
  SET_TRIAL_USED_IP,
  SET_BUSINESS,
  progressPath
} from '../constants';
import {
  SET_LOADING_PARAM,
  SET_IS_LANG_SYMBOL,
  SET_DAILY_GOAL,
  SET_REFERRER
} from '../../common/constants/ActionTypes';
import { USER_SAVE_DATA, SPEAKING_ERROR } from '../../games/constants';
import {
  TRIGGER_LOAD_INITIAL_LABELS,
  TRIGGER_LOAD_INITIAL_TUTORIAL
} from '../../lessons/constants';
import bugtracker from '../../util/BugTracker';
import { getCourseUser } from '../../profile/selectors';
import { firebase } from '../../system/Firebase';
import { PROFILE_PROPERTIES } from '../../profile/constants';
import { INITIAL_USER_PROGESS } from '../../profile/sagas/AccountSagas';
import { fetchRemoteDatabase } from '../../lessons/sagas';
import { fetchLearningLang } from '../../profile/actions';
import moment from 'moment';
import { getNameAndEmail } from '../../profile/actions/UserSelector';
import { INITIAL_USER } from '../reducers/GlobalReducer';
import { getHandledVoiceSpeed } from './AuthActions';

export const getNotificationTime = (now: any, notificationTime: Object) => {
  if (notificationTime.isSameOrBefore(now)) {
    const newDay = notificationTime.add(1, 'days');
    return newDay;
  }
  return notificationTime;
};
export const saveUsersData = (user: Object) => ({
  type: USER_SAVE_DATA,
  user
});

export const triggerLoadInitialLabels = () => ({
  type: TRIGGER_LOAD_INITIAL_LABELS
});
export const triggerLoadInitialTutorial = () => ({
  type: TRIGGER_LOAD_INITIAL_TUTORIAL
});
export const signOutSaga = () => ({
  type: TRIGGER_SIGN_OUT
});

export const languageFeature = () => async (
  dispatch: Function,
  getState: Function
) => {
  const {
    data: { targetLangCode }
  } = getState();
  let feature = await fetchRemoteDatabase(
    `data/languageFeatures/byId/${targetLangCode}`
  );
  feature = feature.val();

  return dispatch({
    type: LOAD_BUNDLE_FEATURE,
    feature: feature.val()
  });
};

export const openLandingScreen = () => {};

type Provider = {
  id: string,
  name?: string,
  email?: string,
  photoURL?: string
};

export type AuthUser = {
  uid: string,
  email?: Provider,
  facebook?: Provider,
  apple?: Provider,
  google?: Provider,
  anonymous?: Provider
};

/* Logical Actions */

export const updateUserData = (
  newUser: Object,
  currentUserProfile: ?Object
) => async (dispatch: Function, getState: Function) => {
  let combinedUser = mergeUsers(getState, newUser, currentUserProfile);
  const { name = '', email } = getNameAndEmail(getState().storage) || {};
  const state = getState();
  const { targetLangCode } = state.data;
  const { user } = state.storage;
  const { uid } = combinedUser || {};
  const currentUser = firebase.auth().currentUser;
  const isAnonymous = _.get(currentUser, 'isAnonymous', false);

  if (!isAnonymous && _.isString(targetLangCode)) {
    firebase
      .database()
      .ref(`${progressPath}/${targetLangCode}/${uid}`)
      .update({
        updateTime: Date.now(),
        bananas: _.get(combinedUser, `${targetLangCode}.bananas`, 0),
        uid: uid,
        name: name
      })
      .catch(error => {
        console.log('Update User Data:', { error, combinedUser });
        bugtracker.notify(error);
      });
    let hashIp = '';
    (async () => {
      const ip = await publicIp
        .v4({
          timeout: 3000
        })
        .then(async res => {
          return res;
        })
        .catch(e => {
          console.log('IP error', e);
        });

      const hashedPassword = hash
        .sha256()
        .update(ip)
        .digest('hex');

      hashIp = hashedPassword.replace(/[^a-zA-Z0-9]/g, '');
      if (_.get(user, 'referral', false)) {
        let today = new Date();
        let dd = today.getDate();

        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) {
          dd = `0${dd}`;
        }

        if (mm < 10) {
          mm = `0${mm}`;
        }
        today = `${mm}-${dd}-${yyyy}`;

        if (
          !(await verifyExitedUserReferral(
            email,
            _.get(user, 'referral', false),
            _.get(user, 'referralHashCode', false),
            hashIp
          ))
        ) {
          firebase
            .database()
            .ref(`referral/${user.referral}/${hashIp}`)
            .set({
              updateTime: Date.now(),
              email: email
            })
            .catch(error => console.log('Update User Data:', { error }));
          // eslint-disable-next-line
          localStorage.setItem('referralCode', 'null');
          // eslint-disable-next-line
          localStorage.setItem('referralHashCode', 'null');
        }
      }
    })();
  }

  if (uid) {
    bugtracker.setUser(uid, name, email);
    if (_.has(newUser, 'subscribed')) {
      const { subscribed } = newUser;
      combinedUser = {
        ...combinedUser,
        [targetLangCode]: {
          ...combinedUser[targetLangCode],
          subscribed: subscribed
        },
        targetLangCode: targetLangCode,
        subscribed: subscribed
      };
    } else if (_.has(newUser, 'lifetime')) {
      const { lifetime } = newUser;
      combinedUser = {
        ...combinedUser,
        [targetLangCode]: {
          ...combinedUser[targetLangCode],
          lifetime: lifetime
        },
        targetLangCode: targetLangCode,
        lifetime: lifetime
      };
    } else if (_.has(newUser, 'currentChatbotId')) {
      const { currentChatbotId } = newUser;
      combinedUser = {
        ...combinedUser,
        [targetLangCode]: {
          ...combinedUser[targetLangCode],
          currentChatbotId: currentChatbotId
        },
        targetLangCode: targetLangCode
      };
    } else if (_.has(newUser, 'goal')) {
      const { goal } = combinedUser;
      combinedUser = {
        ...combinedUser,
        [targetLangCode]: {
          ...combinedUser[targetLangCode],
          goal: goal
        },
        targetLangCode: targetLangCode
      };
    } else {
      combinedUser = {
        ...combinedUser,
        [targetLangCode]: {
          ...combinedUser[targetLangCode]
        },
        targetLangCode: targetLangCode,
        subscribed: _.get(combinedUser, `${targetLangCode}.subscribed`, false)
      };
    }

    let newProgressObject = {};
    if (combinedUser[targetLangCode].progress) {
      _.forEach(combinedUser[targetLangCode].progress, (progress, key) => {
        if (progress) {
          let newLessonObject = {};
          _.forEach(progress.lessons, (lesson, index) => {
            if (lesson) {
              newLessonObject[index] = lesson;
            }
          });
          progress.lessons = newLessonObject;
          newProgressObject[key] = progress;
        }
      });
    }
    combinedUser[targetLangCode].progress = newProgressObject;

    combinedUser = {
      ...combinedUser,
      lastedSignIn: moment().format('YYYY-MM-DD-HH')
    };
    if (isAnonymous) {
      combinedUser = {
        ...INITIAL_USER,
        ...combinedUser
      };
    }
    if (_.hasIn(combinedUser[targetLangCode], 'stars')) {
      const stars = _.get(combinedUser, `${targetLangCode}.stars`, 0);
      const coins = _.get(combinedUser, `${targetLangCode}.coins`, 0);
      _.set(combinedUser, `${targetLangCode}.coins`, coins + stars);
      combinedUser = _.omit(combinedUser, `${targetLangCode}.stars`);
    }
    try {
      firebase
        .database()
        .ref(`users/${uid}`)
        .set(combinedUser)
        .catch(error =>
          console.log('Update User Data:', { error, combinedUser })
        );
    } catch (e) {
      bugtracker.notify(e);
    }
    dispatch(saveUsersData(combinedUser));

    const learnLang = await fetchLearningLang(
      user.uid,
      targetLangCode,
      user
    ).then(result => {
      return _.keys(_.pickBy(result.val(), 'coins'));
    });

    combinedUser['learnLang'] = learnLang;
    dispatch(saveUsersData(combinedUser));
  } else {
    console.log(`user uid is ${uid} when updating user data!`);
  }
};

export const updateRemoteReminder = (
  uid: string,
  nativeLangCode: string,
  clientToken: string,
  reminderData: Object
) => {
  firebase
    .database()
    .ref(`reminders/${uid}`)
    .set({
      ...reminderData,
      nativeCode: nativeLangCode,
      clientToken: clientToken,
      bangkokTime: convertToThaiTimeZone(reminderData).bangkokTime,
      bangkokWeekdays: convertToThaiTimeZone(reminderData).bangkokWeekdays
    })
    .catch(error => bugtracker.notify(error));
};

export const mergeUsers = (
  getState: Function,
  newUser: Object,
  currentUserProfile: ?Object
) => {
  const state = getState();
  const { targetLangCode } = state.data;
  const userLogin = state.storage.user;
  if (currentUserProfile && targetLangCode) {
    return {
      ..._.omit(userLogin, _.intersection(PROFILE_PROPERTIES, _.keys(newUser))),
      [targetLangCode]: {
        ...INITIAL_USER_PROGESS,
        ...currentUserProfile,
        ...newUser
      }
    };
  }
  return {
    ...userLogin,
    ...newUser
  };
};

export const updateUserProfile = (newUser: Object) => (
  dispatch: Function,
  getState: Function
) => {
  const currentUser = getCourseUser(getState());
  const existingVoiceSpeed = _.get(currentUser, 'voiceSpeed', 1);
  const currentVoiceSpeed = getHandledVoiceSpeed(existingVoiceSpeed);
  const newUpdatedUser = { ...newUser, voiceSpeed: currentVoiceSpeed };
  dispatch(updateUserData(newUpdatedUser, currentUser));
};

export const hideNetworkErrorModal = () => ({
  type: HIDE_NETWORK_ERROR_DIALOG
});

export const toggleSpinner = () => ({
  type: TOGGLE_SPINNER
});

export const toggleCustomDialog = (text?: string) => ({
  type: SPEAKING_ERROR,
  text
});

export const setCloseShowTutorial = (value: boolean) => (
  dispatch: Function
) => {
  setTimeout(() => {
    dispatch({
      type: SET_SHOW_TUTORIAL,
      value
    });
  }, 10);
};

export const setDailyGoal = (value: number) => (dispatch: Function) => {
  dispatch({
    type: SET_DAILY_GOAL,
    value
  });
};

export const setShowTutorial = (value: boolean) => (dispatch: Function) => {
  if (!value) {
    window.history.back();
  }
  setTimeout(() => {
    dispatch({
      type: SET_SHOW_TUTORIAL,
      value
    });
  }, 10);
};

export const toggleLoading = () => ({
  type: SET_LOADING
});

export const toggleLoginModal = () => ({
  type: TOGGLE_LOGIN_MODAL
});

export const setIsBusinessSignUp = () => ({
  type: SET_IS_BUSINESS_SIGN_UP
});

export const setSignUpBusinessSuccess = () => ({
  type: SET_SIGN_UP_BUSINESS_SUCCESS
});

export const setReferrer = (referrer: string) => ({
  type: SET_REFERRER,
  referrer
});

export const setTrialUsedIP = () => async (
  dispatch: Function,
  getState: Function
) => {
  const state = getState();
  const { user } = state.storage;
  const ip = await publicIp
    .v4({
      timeout: 3000
    })
    .then(async res => {
      return res;
    })
    .catch(e => {
      console.log('IP error', e);
    });
  dispatch({ type: SET_TRIAL_USED_IP, trialUsedIP: ip });
  firebase
    .database()
    .ref(`users/${user.uid}`)
    .set({
      trialUsedIP: ip
    })
    .catch(error => console.log('Update User Data:', { error }));
};

export const toggleLoadingParam = (param: boolean) => ({
  type: SET_LOADING_PARAM,
  param
});

export const setBusiness = (param: boolean) => ({
  type: SET_BUSINESS,
  param
});

export const toggleChatbotLoaded = (param: string) => ({
  type: SET_CHATBOT_LOADED,
  param
});

export const toggleTutorialMotivate = () => ({
  type: TOGGLE_TUTORIAL_MOTIVATE
});

export const onCloseTutorialMotivate = () => ({
  type: CLOSE_TUTORIAL_MOTIVATE
});

export const setIsLangSymbol = (target: string) => (dispatch: Function) =>
  dispatch({
    type: SET_IS_LANG_SYMBOL,
    target
  });

export const loadGlobalConfig = ({ key, value }: Object) => ({
  type: LOAD_GLOBAL_CONFIG,
  key,
  value
});
export const getUserReferral = (uid: string) => {
  return firebase.database().ref(`referral/${uid}`);
};
export const getInviteCampaigns = () => {
  return firebase.database().ref('data/referralCampaigns');
};
export const migrate = (data: Object) => ({ type: COURSE_MIGRATION, data });

export const migrateOldData = () => (
  dispatch: Function,
  getState: Function
) => {
  const { data } = getState();

  if (
    data &&
    data.targetLangCode &&
    data.words &&
    data.sentences &&
    data.characters &&
    data.dialogues &&
    data.unitsCached &&
    data.grammar &&
    data.writtenNumbers
  ) {
    dispatch(migrate(data));
  }
};

export const verifyExitedUserReferral = async (
  email: string,
  referralUid: string,
  referralHashCode: string,
  hashIp: string
) => {
  let isExisted = false;
  const referral = await getUserReferral(referralUid).once('value');
  let amountReferral = referral.val();

  _.forEach(amountReferral, function(value, key) {
    if (
      _.get(value, 'email', null) === email ||
      key === hashIp ||
      referralHashCode === hashIp
    ) {
      isExisted = true;
    }
  });
  return isExisted;
};

export const setReminders = (reminderData: Object) => (
  dispatch: Function,
  getState: Function
) => {
  const state = getState();
  const { user } = state.storage;
  const { nativeLangCode, uid } = user;
  const clientToken = user.clientToken ? user.clientToken : false;
  try {
    if (clientToken) {
      dispatch(
        updateUserData({
          reminders: {
            ...reminderData,
            nativeCode: nativeLangCode,
            clientToken: clientToken,
            bangkokTime: convertToThaiTimeZone(reminderData).bangkokTime,
            bangkokWeekdays: convertToThaiTimeZone(reminderData).bangkokWeekdays
          }
        })
      );
      reminderData.isSetReminder &&
        updateRemoteReminder(uid, nativeLangCode, clientToken, reminderData);
    } else {
      dispatch(
        updateUserData({
          reminders: {
            ...reminderData,
            nativeCode: nativeLangCode,
            bangkokTime: convertToThaiTimeZone(reminderData).bangkokTime,
            bangkokWeekdays: convertToThaiTimeZone(reminderData).bangkokWeekdays
          }
        })
      );
    }
    !reminderData.isSetReminder && dispatch(removeReminder());
  } catch (e) {
    console.log('error', e);
  }
};

export const removeReminder = () => (
  dispatch: Function,
  getState: Function
) => {
  const state = getState();
  const { user } = state.storage;
  const { uid } = user;
  try {
    firebase
      .database()
      .ref(`reminders/${uid}`)
      .remove()
      .catch(error => bugtracker.notify(error));
  } catch (e) {
    bugtracker.notify(e);
  }
};

//set client Instance ID token
export const setClientToken = (token: string) => (
  dispatch: Function,
  getState: Function
) => {
  const state = getState();
  const { user } = state.storage;
  const { reminders, nativeLangCode } = user;
  firebase
    .database()
    .ref(`reminders/${user.uid}`)
    .set({
      ...reminders,
      nativeCode: nativeLangCode,
      clientToken: token
    })
    .catch(error => console.log('Update User Data:', { error }));
  dispatch(updateUserData({ clientToken: token }));
};

export const convertToThaiTimeZone = (reminderData: Object) => {
  let bangkokWeekdays = [];
  const m = momentTimezone.tz(
    '2020-11-15 ' + reminderData.timeReminder,
    momentTimezone.tz.guess()
  );
  const dayChange =
    m.tz('Asia/Bangkok').format('e') -
    m.tz(momentTimezone.tz.guess()).format('e');
  if (dayChange === 1) {
    _.forEach(reminderData.weekDays, (value, index) => {
      if (index - dayChange === -1) {
        bangkokWeekdays.push({
          ...reminderData.weekDays[0],
          isChecked: reminderData.weekDays[6].isChecked
        });
      } else {
        bangkokWeekdays.push({
          ...reminderData.weekDays[index],
          isChecked: reminderData.weekDays[index - dayChange].isChecked
        });
      }
    });
  } else if (dayChange === -1) {
    _.forEach(reminderData.weekDays, (value, index) => {
      if (index - dayChange === 7) {
        bangkokWeekdays.push({
          ...reminderData.weekDays[index],
          isChecked: reminderData.weekDays[0].isChecked
        });
      } else {
        bangkokWeekdays.push({
          ...reminderData.weekDays[index],
          isChecked: reminderData.weekDays[index - dayChange].isChecked
        });
      }
    });
  } else {
    bangkokWeekdays = reminderData.weekDays;
  }
  return {
    bangkokTime: m.tz('Asia/Bangkok').format('HH:mm'),
    bangkokWeekdays: bangkokWeekdays
  };
};

export const getUrlParam = (paramId: string) => {
  const queryString = window.location.search;
  // eslint-disable-next-line no-undef
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(paramId);
};
