/* @flow */
import styled from 'styled-components';
import {
  DARK_YELLOW,
  MARIGOLD,
  GREY2,
  GREY,
  DARK_BLUE,
  LIGHT_GREY_2,
  VERY_LIGHT_PINK_THREE,
  WHITE,
  STRIPE_GREY,
  VERY_LIGHT_PINK,
  GREYISH_BROWN,
  SEA_BLUE
} from '../../../../common/constants';
const InputContainer = styled.div`
  margin-top: 10px;
  max-width: 538px;
  width: 100vw;
  display: ${props => (props.isUpdateMethod ? 'block' : 'none')};
  margin: auto;
`;
const FormStyle = styled.form`
  display: ${props => (props.isUpdatingCard ? 'none' : 'block')};
`;
const TitleTextContainer = styled.div`
  border: 1px ${VERY_LIGHT_PINK} solid;
  background: ${VERY_LIGHT_PINK};
  border-radius: 20px;
  margin: 13px;
  margin-bottom: 0;
  padding: 0 18px;
  flex: 0.5;
  position: relative;
`;
const TitleTopStyle = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  border-bottom: 1px solid ${VERY_LIGHT_PINK_THREE};
  margin: 0 !important;
  padding: 15px 0;
  span {
    color: ${DARK_BLUE};
  }
`;
const PriceInfoStyle = styled.p`
  font-weight: bold;
  font-size: 20px;
`;
const PackageInfoStyle = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: normal;
  margin: 0 !important;
`;
const SubmitButton = styled.button`
  text-transform: uppercase;
  margin: 13px;
  border-radius: 20px;
  box-shadow: inset 0 -4px 0 0 ${DARK_YELLOW};
  background: ${MARIGOLD};
  border: ${DARK_YELLOW} 1px solid;
  font-size: 14px;
  height: 55px;
  width: 95%;
  padding: 0;
  color: ${WHITE};
  &:hover:enabled {
    opacity: 0.4;
    box-shadow: none;
  }
  &:active {
    opacity: 1;
    background-color: ${DARK_YELLOW};
    box-shadow: none;
  }
  &:disabled {
    box-shadow: inset 0 -4px 0 0 ${GREY2};
    border-color: ${GREY2};
    background-color: ${LIGHT_GREY_2};
  }
`;
const BotttomInputStyle = styled.div`
  display: flex;
  overflow: auto;
`;
const BackArea = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 40px;
  display: ${props =>
    props.isUpdateMethod && !props.isUpdatingCard ? 'inline-flex' : 'none'};
  &:hover {
    cursor: pointer;
  }
`;
const TermConditionText = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: ${GREYISH_BROWN};
  text-align: center;
  margin-top: 40px !important;
  a {
    cursor: pointer;
    text-decoration: none;
    color: ${SEA_BLUE}!important;
  }
`;
const Title = styled.p`
  font-size: 21px;
  font-weight: bold;
  color: #454545;
  margin: 0 !important;
`;
const Image = styled.img`
  width: 18px;
  height: 32px;
  margin-right: 24px;
`;
const Container = styled.div`
  margin: auto;
  margin-top: ${props => (props.isClaim ? 'none' : '110px')};
  margin-bottom: 50px;
  max-width: 534px !important;
  justify-content: center;
  display: flex;
  flex-direction: ${props => (props.isUpdateMethod ? 'column' : 'row')};
`;
const StripeTermsStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: ${STRIPE_GREY};
  margin: 30px 0 25px;
`;
const LeftStripe = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0.5;
  padding-right: 15px;
  border-right: 1px solid ${GREY};
  p {
    margin: 0 !important;
  }
`;
const RightStripe = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 0.5;
  padding-left: 15px;
  a {
    color: ${STRIPE_GREY};
    text-decoration: none;
  }
`;
const StripeTrialText = styled.p`
  font-size: 14px;
  margin: auto;
  width: 50%;
  text-align: center;
  color: ${STRIPE_GREY};
`;

export {
  BackArea,
  BotttomInputStyle,
  Container,
  FormStyle,
  Image,
  InputContainer,
  LeftStripe,
  PackageInfoStyle,
  PriceInfoStyle,
  RightStripe,
  StripeTermsStyle,
  StripeTrialText,
  SubmitButton,
  TermConditionText,
  Title,
  TitleTextContainer,
  TitleTopStyle
};
