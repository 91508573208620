import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

const MaterialButton = ({ buttonText, clickAction, disabled, ...other }) => (
  <Button
    disabled={disabled}
    onClick={clickAction}
    color="primary"
    endIcon=""
    variant="contained"
    {...other}
  >
    {buttonText}
  </Button>
);

MaterialButton.propTypes = {
  buttonText: PropTypes.string,
  clickAction: PropTypes.func
};

export default MaterialButton;
