import React from 'react';
import { ElementsConsumer, PaymentElement } from '@stripe/react-stripe-js';
import { Modal } from '@material-ui/core';

class StripeCheckoutForm extends React.Component {
  handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: '' // only need when not a modal
      }
    });

    if (result.error) {
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  render() {
    return (
      <Modal
        open={true}
        onClose={() => {}}
        aria-labelledby="payment-modal-title"
        aria-describedby="payment-modal-description"
      >
        <form onSubmit={this.handleSubmit}>
          <PaymentElement />
          <button disabled={!this.props.stripe}>Submit</button>
        </form>
      </Modal>
    );
  }
}

export default function InjectedCheckoutForm() {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <StripeCheckoutForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}
