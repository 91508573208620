/* @flow */
import * as React from 'react';
import _ from 'lodash';
import fp from 'lodash/fp';
import { Title } from '../components/sub-components';
import { TopCharacterCard } from '../components/main-components';
import {
  CharacterCard,
  DialogQuestion,
  DialogMessage
} from '../components/sub-components';
import { spellingStatus, TOP_CARDS } from '../constants';
import {
  GREEN_COLOR,
  RED_COLOR,
  GREEN_COLOR2,
  RED_COLOR2,
  MARIGOLD,
  BLACK,
  GREYISH_BROWN,
  WHITE,
  VERY_LIGHT_PINK,
  GREY
} from '../../common/constants';

export const getDialogueCards = (props: Object, saveRef: Function) => {
  const {
    gameState = 1,
    translateState,
    transcriptState,
    pressMessage,
    dialog,
    byId,
    rtlLanguage,
    nativeRtlLanguage,
    pressedIds,
    notYetPressedIds,
    isTargetLangSymbol
  } = props;
  const { questions, allIds, questionIds } = dialog;
  const matching = byId;
  const answer = [...props.pressedIds];
  const pointer = _.findKey(pressedIds, _.isNull);
  const current = _.indexOf(allIds, questions[pointer]);
  const selected = _.indexOf(notYetPressedIds, null);

  let lists = [];
  _.forEach(allIds, (data, key) => {
    const match = _.indexOf(questions, data);
    if (match !== -1) {
      const text = matching[match + 1];
      const charId = answer.shift();
      const questionOrders = questionIds[match];
      let showCorrect = false;
      let card = {};

      if (charId) {
        card.character = matching[charId].character;
        card.status = matching[charId].status;
        let color;
        let borderColor;
        let textColor;
        if (card.status === spellingStatus.CORRECT) {
          color = GREEN_COLOR2;
          borderColor = GREEN_COLOR;
          textColor = GREEN_COLOR;
        } else if (card.status === spellingStatus.WRONG) {
          color = RED_COLOR2;
          borderColor = RED_COLOR;
          textColor = RED_COLOR;
          showCorrect = true;
        } else {
          color = WHITE;
          borderColor = MARIGOLD;
          textColor = GREYISH_BROWN;
        }
        card.backgroundColor = color;
        card.borderColor = borderColor;
        card.textColor = textColor;
        card.showHint = false;
      } else {
        card.character = '';
        card.status = null;
        card.backgroundColor = WHITE;
        card.borderColor = MARIGOLD;
        card.textColor = GREY;
      }
      lists.push(
        <DialogQuestion
          key={data}
          question={text}
          questionOrders={questionOrders}
          current={current}
          gameState={gameState}
          card={card}
          translateState={translateState}
          transcriptState={transcriptState}
          onPressCard={onPressCard(props, charId)}
          onPress={pressMessage}
          showCorrect={showCorrect}
          {...dialog.byId[data]}
          rtlLanguage={rtlLanguage}
          nativeRtlLanguage={nativeRtlLanguage}
          selected={selected}
          isTargetLangSymbol={isTargetLangSymbol}
        />
      );
    } else {
      lists.push(
        <DialogMessage
          key={data}
          gameState={gameState}
          translateState={translateState}
          transcriptState={transcriptState}
          onPress={pressMessage}
          isTargetLangSymbol={isTargetLangSymbol}
          {...dialog.byId[data]}
        />
      );
    }
  });

  const render = lists;
  return render;
};

export const getCompleteSentenceCards = (
  props: Object
): React.Element<any>[] => {
  const topCharacterCards = [...getTopCharacterCards(props)];
  const regularCharacters = [...getRegularCharactersForCompleteSentence(props)];
  const result = [];

  for (
    let i = 0;
    !fp.isEmpty(topCharacterCards) || !fp.isEmpty(regularCharacters);
    i++
  ) {
    const key = i.toString();
    if (
      !fp.isEmpty(regularCharacters) &&
      fp.compose(fp.head, fp.keys, fp.head)(regularCharacters) === key
    ) {
      result.push(getRegularCharacterElement(regularCharacters.shift(), key));
    } else {
      const topCharacterCard = topCharacterCards.shift();
      if (topCharacterCard) {
        result.push({
          ...topCharacterCard,
          key,
          props: {
            ...topCharacterCard.props,
            isCompleteSentenceCard: true
          }
        });
      }
    }
  }
  return result;
};

//Creates a regular character: The part of the sentence that can't be moved
//in the complete sentence game.
const getRegularCharacterElement = (card: Object, key: string) => (
  <Title key={key}>{fp.compose(fp.first, fp.values)(card)}</Title>
);

export const getRegularCharactersForCompleteSentence = ({
  byId,
  allIds
}: Object): Object[] => {
  return _.map(fp.omit(allIds, byId), ({ character }, id) => ({
    [Number(id) - 1]: character
  }));
};

export const getRegularCharactersForDialogue = ({
  byId,
  allIds
}: Object): Object[] => {
  return _.map(fp.omit(allIds, byId), (sentence, id) => ({
    [Number(id) - 1]: sentence
  }));
};

//these cards are the ones on the upper part of the screen.
//They represent the cards that the player already pressed.
export const getTopCharacterCards = (
  props: Object,
  isSort?: boolean
): React.Element<any>[] =>
  getCards(
    props.pressedIds,
    getElement(TopCharacterCard),
    getColor(TopCharacterCard),
    getBorderColor(TopCharacterCard),
    getTextColor(TopCharacterCard),
    props,
    props.false,
    false,
    TOP_CARDS,
    isSort
  );

//these cards are the ones on the bottom part of the screen.
//They represent the cards that the player has not yet pressed.
export const getCharacterCards = (
  props: Object,
  isDialog: boolean = false
): React.Element<any>[] =>
  getCards(
    props.notYetPressedIds,
    getElement(CharacterCard),
    getColor(CharacterCard),
    getBorderColor(CharacterCard),
    getTextColor(CharacterCard),
    props,
    false,
    true,
    'bottomCards',
    false,
    isDialog
  );

const getCards = (
  ids,
  getElement: Function,
  getColor: Function,
  getBorderColor: Function,
  getTextColor: Function,
  props: Object,
  hintMode,
  isChoice?: boolean = false,
  cardStatue?: string,
  isSort?: boolean,
  isDialog?: boolean = false
): React.Element<any>[] => {
  return ids.map((charId: string, key: number) => {
    let character,
      status,
      color,
      borderColor,
      textColor,
      invisible,
      showHint,
      char,
      regex,
      cardStatus,
      sort,
      isAnwserLenght;
    const { byId } = props;
    isAnwserLenght = props.correctAnswer.length >= key + 1;

    //cards that are not pressed are represented by null and should be invisible
    if (charId && byId[charId].character !== ' ') {
      char = byId[charId].character;
      regex = char;
      character = regex;
      status = byId[charId].status;
      color = isDialog ? WHITE : getColor(status);
      borderColor = getBorderColor(status);
      textColor = getTextColor(status);
      showHint = false;
      cardStatus = cardStatue;
      sort = isSort;
    } else if (charId && byId[charId].character === ' ') {
      char = byId[charId].character;
      regex = char;
      character = regex;
      status = byId[charId].status;
      color = getColor(status);
      borderColor = getBorderColor(status);
      textColor = getTextColor(status);
      showHint = false;
      cardStatus = cardStatue;
      sort = isSort;
    } else {
      const ids = props.allIds[key];
      const choice = isChoice ? byId[ids].character : '';

      invisible = true;
      try {
        character = choice;
      } catch (e) {}
      status = null;
      color = null;
      if (isDialog) {
        borderColor = VERY_LIGHT_PINK;
      } else {
        borderColor = GREY;
      }
      textColor = null;
      cardStatus = cardStatue;
      sort = isSort;
    }
    const isResultShown = _.get(props, 'isResultShown', false);
    const getElementCard = () => {
      if (status || (showHint && !isResultShown)) {
        return getElement(
          props,
          key.toString(),
          charId,
          character,
          color,
          borderColor,
          textColor,
          invisible,
          showHint,
          status,
          cardStatus,
          sort,
          isAnwserLenght,
          key + 1,
          isDialog
        );
      } else {
        if (!showHint && !isResultShown) {
          if (isDialog) {
            return null;
          }
          return getElement(
            props,
            key.toString(),
            charId,
            character,
            color,
            borderColor,
            textColor,
            invisible,
            showHint,
            status,
            cardStatus,
            sort,
            isAnwserLenght,
            key + 1,
            isDialog
          );
        } else {
          return null;
        }
      }
    };
    return getElementCard();
  });
};

// $FlowFixMe
const getElement = fp.curry(
  (
    element: Object,
    props: Object,
    key: string,
    charId: string,
    character: string,
    backgroundColor: string,
    borderColor: string,
    textColor: string,
    invisible: boolean,
    showHint: boolean,
    status,
    cardStatus: string,
    sort: boolean,
    isAnwserLenght: boolean,
    index: number,
    isDialog: boolean
  ) =>
    React.createElement(
      element,
      {
        key,
        character,
        onPress: onPressCard(props, charId),
        backgroundColor,
        borderColor,
        textColor,
        invisible,
        showHint,
        status,
        cardStatus,
        sort,
        isAnwserLenght,
        index,
        isDialog,
        ...props
      },
      null
    )
);

const onPressCard = (props: Object, charId: string) => () =>
  props.pressCharacterCard(charId);

export const getColor = fp.curry(
  (element: React.Element<any>, status: string) => {
    if (element === TopCharacterCard) {
      if (status === spellingStatus.CORRECT) {
        return GREEN_COLOR2;
      } else if (status === spellingStatus.WRONG) {
        return RED_COLOR2;
      } else {
        return WHITE;
      }
    }
  }
);

export const getBorderColor = fp.curry(
  (element: React.Element<any>, status: string) => {
    if (element === TopCharacterCard) {
      if (status === spellingStatus.CORRECT) {
        return GREEN_COLOR;
      } else if (status === spellingStatus.WRONG) {
        return RED_COLOR;
      } else {
        return MARIGOLD;
      }
    } else {
      return MARIGOLD;
    }
  }
);

export const getTextColor = fp.curry(
  (element: React.Element<any>, status: string) => {
    if (element === TopCharacterCard) {
      if (status === spellingStatus.CORRECT) {
        return GREEN_COLOR;
      } else if (status === spellingStatus.WRONG) {
        return RED_COLOR;
      } else {
        return BLACK;
      }
    } else {
      return BLACK;
    }
  }
);
