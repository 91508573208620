/* @flow */
import * as React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import {
  PREFIX_LESSON,
  PREFIX_SHOP,
  WHITE,
  GREY,
  GREY2
} from '../../../common/constants';
import { toggleLoginPurchase } from '../../../util';
export const LeftContent = styled.div`
  background: url(${props => props.loginPurchaseBG});
  justify-content: center;
  display: flex;
  align-items: center;
  width: 175px;
  border-radius: 20px 0 0 20px;
`;

const LeftContentImg = styled.img`
  height: 153px;
`;

const RightContent = styled.div`
  width: 345px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContentContainer = styled.div`
  ${'' /* width: 230px;
    margin: auto; */}
  display:flex;
  min-height: 320px;
`;

const ModalCloseButton = styled.button`
  position: fixed;
  opacity: 1;
  margin: 0.5rem 1rem 1rem -1rem !important;
  padding: 0 !important;
`;

const RightContentText = styled.p`
  font-weight: bold;
  font-size: 24px;
  color: #4a4a4a;
  width: 272px;
  margin: auto;
  margin-top: 44px !important;
  margin-bottom: 47px !important;
  line-height: normal;
`;

const CreateAccountButton = styled.button`
  min-width: 216px;
  height: 50px;
  box-shadow: rgb(247, 181, 0) 0px -4px 0px 0px inset;
  background-color: rgb(255, 204, 0);
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(247, 181, 0);
  border-image: initial;
  color: #ffffff;
  &:hover {
    box-shadow: none;
    opacity: 0.4;
    border-color: rgb(255, 204, 0);
  }
`;

const styles = {
  modalContainer: {
    maxWidth: '521px',
    height: '320px',
    textAlign: 'center',
    borderRadius: 30
  },
  modalBodyContainer: {
    padding: 0,
    paddingBottom: 20
  },
  buttonStyle: {},
  cancelButtonStyle: {
    border: 'none',
    boxShadow: 'none',
    width: 100
  },
  confirmButtonStyle: {
    borderColor: GREY2,
    background: WHITE,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    width: 133,
    height: 41
  },
  modalHeader: {
    borderBottom: 'none',
    flexDirection: 'row-reverse',
    justifyContent: 'normal',
    padding: 0
  },
  modalFooter: {
    borderTop: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 30,
    paddingRight: 32,
    paddingLeft: 32
  },
  contentContainer: {
    borderRadius: 30,
    width: 'auto'
  },
  modalHeaderContainer: {
    'modal-title': 'w-100 text-center'
  },
  loadingStyle: {
    margin: '134px 38px',
    color: '#ffcc00'
  }
};

// const dangerIcon = `${PREFIX_LESSON}basecolor-round-warning-120-px.svg`;
const closeIcon = `${PREFIX_LESSON}basecolor-black.svg`;
const loginPurchaseBG = `${PREFIX_SHOP}yellow-wave-background.png`;
const leftLingImg = `${PREFIX_SHOP}ling-say-hello-140-px.png`;

type Props = {
  isLoginPurchase: boolean,
  manageSceneStateChangeHandler: Function,
  toggleCreateAccount: Function,
  createAccount_txt_title: string,
  loginPurchase_txt: string
};

const PurchaseLoginModal = ({
  loginPurchase_txt,
  createAccount_txt_title,
  manageSceneStateChangeHandler,
  toggleCreateAccount,
  isLoginPurchase
}: Props) => {
  const closeBtn = (
    <ModalCloseButton
      className="close"
      onClick={() =>
        toggleLoginPurchase(isLoginPurchase, manageSceneStateChangeHandler)
      }
    >
      <img src={closeIcon} alt={closeIcon} />
    </ModalCloseButton>
  );
  const { modalContainer, modalHeader } = styles;
  return (
    <Modal
      isOpen={isLoginPurchase}
      toggle={() =>
        toggleLoginPurchase(isLoginPurchase, manageSceneStateChangeHandler)
      }
      style={modalContainer}
      contentClassName="contentContainer"
      centered
    >
      <ModalHeader close={closeBtn} style={modalHeader} />
      <ModalContentContainer>
        <LeftContent loginPurchaseBG={loginPurchaseBG}>
          <LeftContentImg src={leftLingImg} className="leftLingImg" />
        </LeftContent>
        <RightContent>
          <div>
            <RightContentText>{loginPurchase_txt}</RightContentText>
            <CreateAccountButton onClick={toggleCreateAccount}>
              {createAccount_txt_title}
            </CreateAccountButton>
          </div>
        </RightContent>
      </ModalContentContainer>
    </Modal>
  );
};

export { PurchaseLoginModal };
