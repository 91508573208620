/* @flow */
import _ from 'lodash';
import { LOAD_STATS } from '../../lessons/constants';
import {
  TOOGLE_PASSWORD_RESET_SEND_MODAL,
  LEADERBOARD_FETCH,
  FETCH_USER_RANK
} from '../constants';
import { SET_LANGCODE } from '../../profile/constants';

type ById = {
  [string]: {
    totalWordCount: number,
    totalSentenceCount: number,
    id: string
  }
};

type Lesson = {
  id: number,
  wordCount: number,
  sentenceCount: number,
  dialogueCount: number
};

type Unit = Lesson[];

type State = {
  byId: ById,
  allIds: string[],
  units: Unit[],
  targetLangSelected: ?string,
  modalVisible?: boolean,
  leaders: Object[]
};

export const INITIAL_STATE = {
  byId: {},
  allIds: [],
  units: [],
  targetLangSelected: null,
  modalVisible: false,
  leaders: [],
  userRank: {}
};

export const loadStats = (state: State, action: Object): State => {
  const { byId, allIds, leaders } = state;
  const {
    targetLangCode,
    totalWordCount,
    totalSentenceCount,
    totalDialogueCount,
    units
  } = action;
  return {
    byId: {
      ...byId,
      [targetLangCode]: {
        totalWordCount,
        totalSentenceCount,
        totalDialogueCount,
        id: targetLangCode
      }
    },
    allIds: _.uniq([...allIds, targetLangCode]),
    targetLangSelected: targetLangCode,
    units,
    leaders
  };
};

const toogleModalVisible = (state: State) => {
  const { modalVisible } = state;
  return {
    ...state,
    modalVisible: !modalVisible
  };
};

const fetchLeaderBoard = (state: State, { collection }: Object) => {
  console.log('Collection Leader', collection);

  return {
    ...state,
    leaders: collection
  };
};
const fetchUserRank = (state: State, { data }: Object) => {
  return {
    ...state,
    userRank: data
  };
};
const setTargetLangSelected = (state: State, { targetLang }: Object) => {
  return {
    ...state,
    targetLangSelected: targetLang
  };
};

export const StatsReducer = (
  state: State = INITIAL_STATE,
  action: Object
): State => {
  switch (action.type) {
    case LOAD_STATS:
      return loadStats(state, action);
    case LEADERBOARD_FETCH:
      return fetchLeaderBoard(state, action);
    case FETCH_USER_RANK:
      return fetchUserRank(state, action);
    case SET_LANGCODE:
      return setTargetLangSelected(state, action);
    case TOOGLE_PASSWORD_RESET_SEND_MODAL:
      return toogleModalVisible(state);
    default:
      return state;
  }
};
