/* @flow */
import * as React from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';

import { InputGroup } from 'reactstrap';

import {
  WHITE,
  MARIGOLD,
  GREY2,
  SEA_BLUE,
  BLACK,
  GREY,
  LIGHT_RED_1,
  DARK_GREY,
  DARK_YELLOW,
  PREFIX_HEADER,
  LIGHT_GREY_2
} from '../../../common/constants';
import { googleLogin, appleLogin, fbLogin } from '../../../util';
import { Box } from '@mui/material';
const InputLabel = styled.label`
  color: ${props =>
    props.isError && props.errorMsg !== '' ? LIGHT_RED_1 : DARK_GREY};
`;
const InputPlace = styled.input`
  width: 100%;
  border: none;
  outline: none;
  background-color: ${WHITE} !important;
`;
const InputErrorTextStyle = styled.span`
  font-size: 12px;
  text-align: left;
  padding-left: 0;
  display: flex;
  padding-top: 5px;
  font-size: 14px;
  text-align: left;
  color: ${LIGHT_RED_1};
  font-style: italic;
`;

const NewsletterContainerStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: -20px;
`;

const NewsletterTextStyle = styled.p`
  font-size: 12px;
  text-align: left;
  padding-left: 0;
  display: flex;
  font-size: 14px;
  text-align: left;
  padding-right: 10px;
`;

const LoginStyle = styled.button`
  text-transform: uppercase;
  margin-top: 26px;
  margin-bottom: 25px;
  border-radius: 20px;
  box-shadow: inset 0 -4px 0 0 ${DARK_YELLOW};
  background: ${MARIGOLD};
  border: ${DARK_YELLOW} 1px solid;
  font-size: 14px;
  width: 157px;
  height: 55px;
  padding: 0;
  color: ${WHITE};
  &:hover {
    opacity: 0.4;
    box-shadow: none;
  }
  &:active {
    opacity: 1;
    background-color: ${DARK_YELLOW};
    box-shadow: none;
  }
`;
const ErrorTextStyle = styled.span`
  font-size: 12px;
  text-align: left;
  padding-left: 14px;
  display: flex;
  padding-top: 5px;
  font-size: 14px;
  text-align: left;
  color: ${LIGHT_RED_1};
  font-style: italic;
`;
const fbIcon = `${PREFIX_HEADER}f-logo-login.svg`;
const googleIcon = `${PREFIX_HEADER}g-logo-login.svg`;
const appleIcon = `${PREFIX_HEADER}Apple_logo_white.svg`;
type Props = {
  handleSignUpSubmit: Function,
  login_field_name: string,
  login_field_firstName: string,
  err: boolean,
  emailErrorMsg: string,
  login_field_email: string,
  login_msg_reenterPassword: string,
  user: Object,
  login_field_password: string,
  at_least_6_password_txt: string,
  passwordErrorMsg: string,
  errorMsgFirebase: string,
  sign_up_txt_title: string,
  sign_in_opt_msg: string,
  formErrorMsg: string,
  signInWithApple: Function,
  signInWithFacebook: Function,
  signInWithGoogle: Function,
  fetchUnitData: Function,
  targetLangCode: string,
  nativeLangCode: string,
  toggleCheckout: Function,
  history: Object,
  itemInfo: Object,
  unitIdBussiness: number,
  isLoginModalOpen: boolean,
  toggleLoginModal: Function,
  isBusinessSignUp: boolean,
  setIsBusinessSignUp: Function,
  pauseDialog: Function,
  modal: boolean,
  stateChangeHandler: Function,
  handleOnChange: Function,
  isNewsletterEnable: boolean,
  _onNewsletterStateHandler: Function,
  txt_email_field_placeholder: string
};

const SignUpModalFormModal = ({
  handleSignUpSubmit,
  login_field_name,
  login_field_firstName,
  login_msg_reenterPassword,
  sign_up_txt_title,
  formErrorMsg,
  err,
  emailErrorMsg,
  login_field_email,
  user,
  sign_in_opt_msg,
  login_field_password,
  at_least_6_password_txt,
  errorMsgFirebase,
  passwordErrorMsg,
  signInWithGoogle,
  signInWithFacebook,
  signInWithApple,
  fetchUnitData,
  targetLangCode,
  nativeLangCode,
  toggleCheckout,
  history,
  itemInfo,
  unitIdBussiness,
  isLoginModalOpen,
  toggleLoginModal,
  isBusinessSignUp,
  setIsBusinessSignUp,
  pauseDialog,
  modal,
  stateChangeHandler,
  handleOnChange,
  isNewsletterEnable,
  _onNewsletterStateHandler,
  txt_email_field_placeholder
}: Props) => {
  const {
    inputGroup,
    inputContainer,
    labelStyle,
    otherLoginGroup,
    otherLoginButton,
    appleIconStyle,
    googleIconStyle
  } = styles;

  const newStyles = {
    social: {
      img: {
        width: 23,
        height: 23
      },
      '&:hover': {
        opacity: 0.4
      },
      '&:active': {
        opacity: 1
      }
    }
  };

  return (
    <>
      <form id="loginForm" onSubmit={e => handleSignUpSubmit(e)}>
        <div style={inputGroup}>
          <InputGroup style={inputContainer}>
            <label htmlFor="name" style={labelStyle}>
              {login_field_name}
            </label>
            <InputPlace
              placeholder={login_field_firstName}
              id="name"
              onChange={event1 => (user.name = event1.target.value)}
            />
            <InputErrorTextStyle />
          </InputGroup>
          <InputGroup className="border-top" style={inputContainer}>
            <InputLabel
              htmlFor="email"
              style={labelStyle}
              isError={err}
              errorMsg={emailErrorMsg}
            >
              {login_field_email}
            </InputLabel>
            <InputPlace
              placeholder={txt_email_field_placeholder}
              id="email"
              onChange={event => (user.email = event.target.value)}
            />
            <InputErrorTextStyle>
              {err && emailErrorMsg !== '' ? emailErrorMsg : null}
              {errorMsgFirebase ? errorMsgFirebase : null}
            </InputErrorTextStyle>
          </InputGroup>
          <InputGroup className="border-top" style={inputContainer}>
            <InputLabel
              htmlFor="password"
              style={labelStyle}
              isError={err}
              errorMsg={passwordErrorMsg}
            >
              {login_field_password}
            </InputLabel>
            <InputPlace
              placeholder={at_least_6_password_txt}
              id="password"
              type="password"
              onChange={event => (user.password = event.target.value)}
            />
            <InputErrorTextStyle>
              {err && passwordErrorMsg !== '' ? passwordErrorMsg : null}
            </InputErrorTextStyle>
          </InputGroup>
          <InputGroup className="border-top" style={inputContainer}>
            <label htmlFor="confirmPassword" style={labelStyle}>
              {login_msg_reenterPassword}
            </label>
            <InputPlace
              placeholder={at_least_6_password_txt}
              id="confirmPassword"
              type="password"
              onChange={event => (user.confirmPassword = event.target.value)}
            />
          </InputGroup>
        </div>
        <NewsletterContainerStyle>
          <NewsletterTextStyle>{sign_in_opt_msg}</NewsletterTextStyle>
          <Switch
            onChange={_onNewsletterStateHandler}
            checked={isNewsletterEnable}
            offColor={LIGHT_GREY_2}
            onColor={MARIGOLD}
            uncheckedIcon={false}
            checkedIcon={false}
            width={51}
            height={30}
          />
        </NewsletterContainerStyle>
        <ErrorTextStyle>
          {err && formErrorMsg !== '' ? formErrorMsg : null}
        </ErrorTextStyle>
        <LoginStyle type="submit"> {sign_up_txt_title}</LoginStyle>
      </form>
      <div style={otherLoginGroup}>
        <Box
          component="button"
          sx={newStyles.social}
          style={{
            ...otherLoginButton,
            ...appleIconStyle
          }}
          onClick={() => {
            appleLogin(
              signInWithApple,
              fetchUnitData,
              targetLangCode,
              nativeLangCode,
              toggleCheckout,
              history,
              itemInfo,
              unitIdBussiness,
              isLoginModalOpen,
              toggleLoginModal,
              isBusinessSignUp,
              setIsBusinessSignUp,
              pauseDialog,
              modal,
              stateChangeHandler
            );
          }}
        >
          <img src={appleIcon} alt={appleIcon} />
        </Box>
        <Box
          component="button"
          sx={newStyles.social}
          style={{ ...otherLoginButton }}
          onClick={() => {
            fbLogin(
              signInWithFacebook,
              fetchUnitData,
              targetLangCode,
              nativeLangCode,
              toggleCheckout,
              history,
              itemInfo,
              unitIdBussiness,
              isLoginModalOpen,
              toggleLoginModal,
              isBusinessSignUp,
              setIsBusinessSignUp,
              pauseDialog,
              modal,
              stateChangeHandler
            );
          }}
        >
          <img src={fbIcon} alt={fbIcon} />
        </Box>
        <Box
          component="button"
          sx={newStyles.social}
          style={{
            ...otherLoginButton,
            ...googleIconStyle
          }}
          onClick={() => {
            googleLogin(
              signInWithGoogle,
              fetchUnitData,
              targetLangCode,
              nativeLangCode,
              toggleCheckout,
              history,
              itemInfo,
              unitIdBussiness,
              isLoginModalOpen,
              toggleLoginModal,
              isBusinessSignUp,
              setIsBusinessSignUp,
              pauseDialog,
              modal,
              stateChangeHandler
            );
          }}
        >
          <img src={googleIcon} alt={googleIcon} />
        </Box>
      </div>
    </>
  );
};
const styles = {
  modalContainer: {
    width: '460px',
    height: '457px',
    textAlign: 'center',
    borderRadius: 30
  },
  modalBodyContainer: {
    padding: 0,
    paddingBottom: 36
  },
  buttonStyle: {
    borderRadius: 10,
    width: 160,
    height: 55,
    background: WHITE,
    border: 'solid 1px',
    borderColor: GREY2,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    boxShadowColor: GREY2,
    fontWeight: 'bold',
    fontSize: 18
  },
  cancelButtonStyle: {
    border: 'none',
    boxShadow: 'none',
    width: 100
  },
  confirmButtonStyle: {
    borderColor: GREY2,
    background: WHITE,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0'
  },
  modalHeader: {
    borderBottom: 'none',
    flexDirection: 'row-reverse',
    justifyContent: 'normal',
    padding: '29px 35px',
    paddingBottom: 27
  },
  modalFooter: {
    borderTop: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 36,
    paddingRight: 32,
    paddingLeft: 32
  },
  contentContainer: {
    borderRadius: 30
  },
  modalHeaderContainer: {
    'modal-title': 'w-100 text-center'
  },
  inputGroup: {
    textAlign: 'left',
    borderColor: GREY,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 20
  },
  inputGroupResetPassword: {
    margin: '76px 35px 55px'
  },

  inputContainer: {
    padding: '10px 15px 15px',
    width: 'inherit',
    fontSize: 14
  },
  labelStyle: {
    fontWeight: 'bold',
    marginBottom: 12,
    textTransform: 'capitalize'
  },
  forgotTextStyle: {
    fontSize: 14,
    textAlign: 'left',
    paddingLeft: 14,
    fontWeight: 'bold',
    display: 'flex',
    paddingTop: 10
  },
  otherLoginButton: {
    borderRadius: 22,
    padding: 0,
    width: 80,
    height: 42,
    background: WHITE,
    color: BLACK,
    border: 'gray solid 1px',
    borderColor: GREY,
    borderWidth: 1,
    borderStyle: 'solid',
    margin: '0 6px',
    marginTop: 5
  },
  appleIconStyle: {
    marginLeft: 0
  },
  googleIconStyle: {
    marginRight: 0
  },
  otherLoginGroup: {
    marginBottom: 25
  },
  signUpSuggestionStyle: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  signUpStyle: {
    color: SEA_BLUE,
    cursor: 'pointer'
  },
  space: {
    height: 12
  },
  loginFormSpaceBottom: {
    paddingBottom: 4
  },
  loadingStyle: {
    margin: '134px 38px',
    color: MARIGOLD
  }
};

export { SignUpModalFormModal };
