/* @flow */
import * as React from 'react';
import { DEFAULT_FONT } from '../../../util/AssetHelper';

type Props = {
  children?: string,
  style?: any
};

const PrimaryText = ({ children, style }: Props) => {
  return <div style={{ ...containerStyle, ...style }}> {children} </div>;
};

const containerStyle = {
  opacity: 0.87,
  color: 'black',
  fontFamily: DEFAULT_FONT
};

export { PrimaryText };
