/* @flow */
import _ from 'lodash';

import { DEFAULT_TARGET_LANGUAGE_CODE } from '../constants';

export const getData = ({ data }: Object) => data;

export const getTargetLangCode = ({ data }: Object) => data.targetLangCode;

export const getNativeLangCode = ({ data }: Object) => data.nativeLangCode;

export const getCurrentCourseData = ({ courses }: Object) => {
  const activeLangCode = _.get(
    courses,
    'activeLangCode',
    DEFAULT_TARGET_LANGUAGE_CODE
  );
  return _.get(courses, activeLangCode, { features: {} });
};

export const hasTranscript = (state: Object) => {
  return _.get(getCurrentCourseData(state), 'features.hasTranscript', false);
};

export const hasLangCodeSpeaking = (state: Object) =>
  _.get(getCurrentCourseData(state), 'features.langCodeSpeaking', false);

export const getCourseData = ({ courses }: Object, langCode: string) =>
  _.get(courses, langCode, null);

export const getCachedData = ({ data, courses }: Object, unitId: number) => {
  const { targetLangCode, nativeLangCode } = data;
  const targetLangData = _.get(courses, targetLangCode, {
    words: [],
    sentences: [],
    dialogues: [],
    grammar: [],
    characters: []
  });
  const { words, sentences, dialogues, grammar, characters } = targetLangData;
  return {
    words: getUnitEntries(words, unitId),
    sentences: getUnitEntries(sentences, unitId),
    dialogues: getUnitEntries(dialogues, unitId),
    grammar: grammar,
    characters: characters,
    nativeLangCode: nativeLangCode
  };
};

export const getUnitEntries = (phrases: Object, unitId: number) => {
  let unitEntries = {};
  for (const [key, value] of Object.entries(phrases)) {
    if (Number(_.split(key, ',')[0]) === Number(unitId)) {
      unitEntries[key] = value;
    }
  }

  return unitEntries;
};

export const getStopwords = ({ courses }: Object) =>
  _.get(courses, 'nativeLanguageFeatures.stopWords', ['a', 'an']);
