/* @flow */
import _ from 'lodash';

export const getLabels = (state: Object, ...labelKeys: string[]) => {
  const labels = {};
  for (const labelKey of labelKeys) {
    labels[labelKey] = getLabel(state, labelKey, labelKey);
  }
  return labels;
};

export const getLabel = (
  state: Object,
  name: string,
  defaultName: ?string = name
) => {
  return correctLineFeed(
    _.get(state, `data.labels.byId[${name}].content`, defaultName)
  );
};

export const getLanguageLabel = (state: Object, flagTarget: string) => {
  return getLabel(state, `target_txt_${flagTarget.replace('_', '-')}`);
};

export const getLanguageNativeLang = (state: Object, langCode: string) => {
  const { langs } = state.storage;
  return _.get(langs[langCode], 'nativeLang', '');
};

export const getLabelWithParameters = (
  state: Object,
  name: string,
  param: any,
  defaultText: ?string
) => {
  return _.replace(getLabel(state, name, defaultText), '%d', `${param}`);
};

export const correctLineFeed = (label: string) => {
  return _.replace(label, /\\n/g, '\n');
};

export const getLabelWithString = (
  state: Object,
  name: string,
  param: string
) => {
  return _.replace(getLabel(state, name), '%s', getLabel(state, param));
};

export const getBrowserLang = () => {
  var browserLang =
    window.navigator.language === 'zh-tw'
      ? window.navigator.language
      : window.navigator.language.substring(0, 2);
  let browserNativeLang = 'en';
  switch (browserLang) {
    case 'en':
      browserNativeLang = 'en';
      break;
    case 'es':
      browserNativeLang = 'es';
      break;
    case 'fr':
      browserNativeLang = 'fr';
      break;
    case 'de':
      browserNativeLang = 'de';
      break;
    case 'it':
      browserNativeLang = 'it';
      break;
    case 'ja':
      browserNativeLang = 'ja';
      break;
    case 'ko':
      browserNativeLang = 'ko';
      break;
    case 'zh':
      browserNativeLang = 'zh';
      break;
    case 'zh-tw':
      browserNativeLang = 'zh-TW';
      break;
    case 'th':
      browserNativeLang = 'th';
      break;
    case 'vi':
      browserNativeLang = 'vi';
      break;
    default:
      browserNativeLang = 'en';
  }
  return browserNativeLang;
};
