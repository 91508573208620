/* @flow */
import { call, select, put } from 'redux-saga/effects';
import {
  fetchData,
  cancelDownload,
  saveLanguageData
} from '../../lessons/actions';
import _ from 'lodash';
import { fetchTutorial } from './RemoteDatabaseSagas';

import { TUTORIAL_KEY } from '../../games/constants';
import { downloadFeaturesIntoStateForTargetLanguage } from '../../lessons/sagas/LanguageFeaturesSagas';
import { getTargetLangCode, getNativeLangCode } from '../../lessons/selectors';
import { SHOW_TUTORIAL, FALSE } from '../../common/constants';
import { store } from '../../index';
import { setCloseShowTutorial } from '../../games/actions';

export function* selectTutorial(): Object {
  try {
    yield put(fetchData(TUTORIAL_KEY));
    // yield put(downloadTotalFiles(TOTAL_DOWNLOAD_PROCESS));
    yield call(downloadFeaturesIntoStateForTargetLanguage);
    // yield put(fileDownloaded(START_DOWNLOAD_TUTORIAL));
    yield call(doLoadInitialTutorials);
    // yield put(cancelDownload());
    // yield put(startTutorial());
  } catch (err) {
    // BugTracker.notify(err);
    yield put(cancelDownload());
    const course = _.get(
      store.getState().courses,
      store.getState().courses.activeLangCode,
      null
    );
    if (!_.includes(course.words, '0.0')) {
      //  eslint-disable-next-line
      localStorage.setItem(SHOW_TUTORIAL, FALSE);
      yield put(setCloseShowTutorial(false));
    }
  }
}

export function* doLoadInitialTutorials(): Object {
  const target = yield select(getTargetLangCode);
  const native = yield select(getNativeLangCode);

  const tutorialData = yield call(fetchTutorial, target, native);

  const { words, sentences, dialogues } = tutorialData;

  const mapContent = {
    words: {
      [TUTORIAL_KEY]: words
    },
    sentences: {
      [TUTORIAL_KEY]: sentences
    },
    dialogues: {
      [TUTORIAL_KEY]: dialogues
    }
  };

  yield put(saveLanguageData(mapContent, 0));
}
