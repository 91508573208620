/* @flow */
import {
  PREFIX_UNITS,
  PREFIX_COURSES,
  IMAGE_PATH,
  PREFIX_UNITS_CONTENT,
  USED_INDIA_FLAG
} from '../common/constants';
export const IMAGE_ENDING = '.png';

const size = '@3x';
export const TUTORIALS_ASSET_FB_PATH = 'data/zip/tutorial/';
export const TUTORIALS_PATH = '/text/tutorials/';

export const getImageUrl = (imageName: string) => {
  imageName = imageName ? imageName.replace('.png', '') : imageName;
  return `${IMAGE_PATH}${imageName}${size}${IMAGE_ENDING}`;
};

export const getUnitImageUrl = (key: any) =>
  `${PREFIX_UNITS}${getUnitName(key)}${size}${IMAGE_ENDING}`;

export const getUnitContentImageUrl = (key: any) =>
  `${PREFIX_UNITS_CONTENT}${key}${size}${IMAGE_ENDING}`;

export const getFlagImageUrl = (flagName: string) =>
  USED_INDIA_FLAG.includes(flagName)
    ? `${PREFIX_COURSES}${'hi'}${size}${IMAGE_ENDING}`
    : `${PREFIX_COURSES}${flagName.replace('_', '-')}${size}${IMAGE_ENDING}`;

export const getUnitName = (unitId: number) =>
  unitId < 10 ? `unit0${unitId}` : `unit${unitId}`;

export const DEFAULT_FONT = 'Helvetica';

export const LANGUAGE_FEATURES_FB_PATH = 'data/languageFeatures/';

export const getAppLanguage = () => {
  const bundle = navigator.language;
  const slice = bundle.split('-');
  const language = slice.shift();
  return getCorrectedAppLanguage(language);
};

const getCorrectedAppLanguage = language =>
  language === 'ling' || language === 'thai' ? 'th' : language;

export const parseGrammarTable = (tableString: any) => {
  let parsedTable;
  try {
    console.log('BEFORE TABLE', tableString);
    parsedTable = JSON.parse(tableString);
    console.log('PARSED TABLE', parsedTable);
  } catch (e) {
    parsedTable = false;
    console.log('json parse error', e);
  }
  return parsedTable;
};
