import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { getTestimonialData } from './data/paywallData';
import { useMediaQuery, useTheme } from '@mui/material';
import TestimonialCarouselLarge from './subComponents/TestimonialCarouselLarge';
import TestimonialCarouselMobile from './subComponents/TestimonialCarouselMobile';

const Testimonials = ({ labels }) => {
  const { txt_join_5mil_learners, txt_kudos_learners } = labels;
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [currentPosition, setCurrentPosition] = useState(0);

  const styles = {
    container: {
      overflowX: 'hidden',
      position: 'relative',
      mt: 40,
      backgroundColor: '#EAF3FC',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      py: 30,
      height: '525px',
      [theme.breakpoints.up('sm')]: {
        height: 'auto'
      }
    },
    headerContainer: {
      width: '80%',
      maxWidth: '700px',
      textAlign: 'center',
      mb: 20
    },
    headerText: {
      fontSize: '30px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '36px'
      }
    },
    contentContainer: {
      backgroundColor: '#FFFFFF',
      display: 'flex',
      flexDirection: 'column',
      transform: isLargeScreen
        ? `translateX(${currentPosition * 108}%)`
        : 'none',
      transition: 'transform 0.5s ease',
      minWidth: '300px',
      p: 6,
      borderRadius: 7,
      mx: 6,
      [theme.breakpoints.up('sm')]: {
        width: '380px'
      }
    },
    testimonialContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'flex-start',
      overflowX: 'auto',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'flex-end'
      }
    },
    quoteImg: {
      width: '37px',
      heigh: '28px',
      mb: 4
    },
    userInfoContainer: {
      display: 'flex',
      mt: 4
    },
    text: {
      fontSize: '18px'
    },
    userNameText: {
      fontWeight: 700
    },
    ratingText: {
      fontSize: '16px'
    },
    userImg: {
      height: '40px',
      width: '40px',
      mr: 3
    },
    ratingContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    star: {
      height: '16px',
      width: '16px',
      mr: 3
    },
    arrowContainer: {
      display: 'flex',
      width: '150px',
      justifyContent: 'space-around',
      position: 'absolute',
      bottom: 40
    },
    arrowIcon: { color: 'white', height: '30px', width: '30px' },
    icon: {
      backgroundColor: '#333333',
      '&:focus': {
        outline: 'none'
      }
    }
  };

  const testimonialData = getTestimonialData(labels);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.headerContainer}>
        <Typography variant="text" sx={styles.headerText}>
          {isLargeScreen ? txt_join_5mil_learners : txt_kudos_learners}
        </Typography>
      </Box>
      {isLargeScreen ? (
        <TestimonialCarouselLarge
          testimonialData={testimonialData}
          currentPosition={currentPosition}
          styles={styles}
          setCurrentPosition={setCurrentPosition}
        />
      ) : (
        <TestimonialCarouselMobile
          testimonialData={testimonialData}
          currentPosition={currentPosition}
          styles={styles}
          setCurrentPosition={setCurrentPosition}
        />
      )}
    </Box>
  );
};

export default Testimonials;
