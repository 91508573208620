import { Box, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import OnboardingButton from '../selectorComponents/subComponents/OnboardingButton';
import SurveySlider from '../selectorComponents/SurveySlider';
import NewSurveySelector from '../selectorComponents/NewSurveySelector';
import {
  calculateLanguageLevel,
  convertScoresToScale
} from './userLanguageLevelHelpers';

const getLabelForSlider = (labels, key) => {
  return {
    reading: labels['txt_reading_skill'],
    listening: labels['txt_listening_skill'],
    writing: labels['lesson_txt_writing'],
    grammar: labels['overview_txt_grammar'],
    speaking: labels['lesson_txt_speaking'],
    vocabulary: labels['overview_txt_vocab']
  }[key];
};

const UserLanguageLevel = ({
  headerData,
  screenText,
  selected,
  setSelected,
  handleScreenChange,
  labels
}) => {
  const { txt_tell_us_about_yourself, btn_continue, btn_cant_answer } = labels;

  const theme = useTheme();

  // prevents re-rendering for slider
  const surveyValuesRef = useRef({
    reading: 5,
    listening: 5,
    writing: 5,
    grammar: 5,
    speaking: 5,
    vocabulary: 5
  });

  const [altLanguageSurvey, setAltLanguageSurvey] = useState(false);

  const handleChange = (event, newValue) => {
    const { name } = event.target;
    surveyValuesRef.current[name] = newValue;
  };

  const setScoresData = () => {
    const userScores = Object.values(surveyValuesRef.current);

    const convertedUserScores = convertScoresToScale(userScores);

    const scoreData = {
      languageSkill: calculateLanguageLevel(convertedUserScores),
      readingSkill: convertedUserScores[0],
      listeningSkill: convertedUserScores[1],
      writingSkill: convertedUserScores[2],
      grammarSkill: convertedUserScores[3],
      speakingSkill: convertedUserScores[4],
      vocabularySkill: convertedUserScores[5]
    };

    setSelected(scoreData);

    return scoreData;
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      pb: 15
    },
    topContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      textAlign: 'center',
      mt: 10
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    img: {
      height: '65px',
      mb: 4
    },
    headerContainer: {
      mb: 4
    },
    header: {
      fontWeight: '700',
      fontSize: '16px',
      color: '#111111',
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px'
      }
    },
    subtext: {
      fontSize: '16x',
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px'
      }
    },
    bottomContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      width: '50%',
      minWidth: '345px',
      maxWidth: '600px',
      mt: 13,
      mb: 6,
      alignItems: 'center'
    },
    questionText: {
      fontSize: '30px',
      fontWeight: '600',
      color: '##111111',
      textAlign: 'left',
      [theme.breakpoints.up('sm')]: {
        fontSize: '36px'
      }
    },
    selectionBox: {
      width: '90%',
      ml: -7,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        ml: 0
      }
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '85%',
      [theme.breakpoints.up('sm')]: {
        width: '100%'
      }
    },
    onboardingButton: {
      width: '100%',
      maxWidth: '600px',
      backgroundColor: theme.palette.newPrimary.main,
      borderRadius: 9,
      textTransform: 'none',
      color: '#FFFFFF',
      fontSize: '18px',
      fontWeight: 700,
      my: 8,
      [theme.breakpoints.up('sm')]: {
        mt: 14
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.newPrimary.main,
        opacity: 0.5,
        color: '#FFFFFF'
      }
    },
    labelContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      mt: 12,
      width: '100%'
    },
    label: {
      fontSize: '18px',
      fontWeight: 700
    },
    linkText: {
      color: theme.palette.newPrimary.main,
      cursor: 'pointer'
    }
  };

  return (
    <Box sx={styles.container}>
      {altLanguageSurvey ? (
        <Box sx={styles.main}>
          <NewSurveySelector
            headerData={headerData}
            screenText={screenText}
            selected={selected}
            setSelected={setSelected}
            handleScreenChange={handleScreenChange}
            labels={labels}
          />
        </Box>
      ) : (
        <>
          <Box sx={styles.topContainer}>
            <Box component="img" src={headerData.icon} sx={styles.img} />
            <Typography variant="subtext" sx={styles.header}>
              {txt_tell_us_about_yourself}
            </Typography>
          </Box>
          <Box sx={styles.bottomContainer}>
            <Box sx={styles.headerContainer}>
              <Typography variant="text" sx={styles.questionText}>
                {headerData.text}
              </Typography>
            </Box>
            {!altLanguageSurvey && (
              <Typography variant="subtext" sx={styles.subtext}>
                {headerData.subtext}
              </Typography>
            )}
            <Box sx={styles.selectionBox}>
              {Object.keys(surveyValuesRef.current).map((slider, index) => (
                <Box key={index} sx={styles.labelContainer}>
                  <Typography variant="subtext" sx={styles.label}>
                    {getLabelForSlider(labels, slider)}
                  </Typography>
                  <SurveySlider
                    slider={slider}
                    handleChange={handleChange}
                    labels={labels}
                  />
                </Box>
              ))}
            </Box>
          </Box>
          <Box sx={styles.buttonContainer}>
            <OnboardingButton
              disabled={false}
              size="large"
              clickAction={() => {
                handleScreenChange(setScoresData());
              }}
              buttonText={btn_continue}
              style={styles.onboardingButton}
            />
          </Box>
        </>
      )}
      {!altLanguageSurvey && (
        <Typography
          variant="subtext"
          sx={[styles.linkText, styles.label]}
          onClick={() => {
            setAltLanguageSurvey(true);
          }}
        >
          {btn_cant_answer}
        </Typography>
      )}
    </Box>
  );
};

export default UserLanguageLevel;
