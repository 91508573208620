/* @flow */
export const calIndexLevel = (currentUnit: number) => {
  if (currentUnit < 11) {
    return 0;
  } else if (currentUnit < 21 && currentUnit > 10) {
    return 1;
  } else if (currentUnit >= 21 && currentUnit < 31) {
    return 2;
  } else if (currentUnit >= 31 && currentUnit < 41) {
    return 3;
  } else if (currentUnit >= 41) {
    return 4;
  } else {
    return 0;
  }
};
