/* @flow */
import { connect } from 'react-redux';
import { AchievementScene } from '../components/scenes';
import { getLabels } from '../../util';
import {
  getAchievement,
  getLevelChampion,
  getBigSpender,
  getDiligentMaster,
  getOverAchiever,
  getWealthy,
  getDescriptionList
} from '../selectors/AchievementSelector';
export const mapStateToProps = (state: Object) => {
  return {
    ...getLabels(state, 'achievement_txt_title'),
    ...getAchievement(state),
    levelChamp: getLevelChampion(state),
    levelSpender: getBigSpender(state),
    levelDiligent: getDiligentMaster(state),
    levelAchiever: getOverAchiever(state),
    levelWealthy: getWealthy(state),
    ...getDescriptionList(state)
  };
};
export default connect(mapStateToProps)(AchievementScene);
