import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import OnboardingButton from './subComponents/OnboardingButton';

const UserInfoSelector = ({
  headerData,
  screenText,
  selected,
  setSelected,
  handleScreenChange,
  labels
}) => {
  const { btn_continue } = labels;

  const theme = useTheme();

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    topContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      textAlign: 'center',
      mt: 10
    },
    img: {
      height: '65px',
      mb: 4
    },
    header: {
      fontWeight: '700',
      fontSize: '18px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px'
      }
    },
    bottomContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      width: '50%',
      minWidth: '345px',
      maxWidth: '558px',
      mt: 13,
      mb: 6,
      alignItems: 'flex-start',
      [theme.breakpoints.up('sm')]: {
        ml: 12.5
      }
    },
    questionText: {
      fontSize: '30px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '36px'
      },
      fontWeight: '700',
      color: '##111111'
    },
    selectionBox: {
      display: 'flex',
      maxWidth: '558px',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      textAlign: 'center',
      mt: 10
    },
    selectedItem: {
      border: '2px solid #4094ED'
    },
    selectionText: {
      fontSize: '18px'
    },
    selectionItem: {
      border: '2px solid #E6E6E6',
      width: 'auto',
      py: 2.5,
      px: 3,
      borderRadius: 2,
      mr: 5,
      mb: 4,
      cursor: 'pointer'
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    },
    onboardingButton: {
      width: '100%',
      maxWidth: '558px',
      backgroundColor: theme.palette.newPrimary.main,
      borderRadius: 9,
      textTransform: 'none',
      color: '#FFFFFF',
      fontSize: '18px',
      fontWeight: 700,
      mt: 8,
      [theme.breakpoints.up('sm')]: {
        mt: 14
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.newPrimary.main,
        opacity: 0.5,
        color: '#FFFFFF'
      }
    }
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.topContainer}>
        <Box component="img" src={headerData.icon} sx={styles.img} />
        <Typography variant="subtext" sx={styles.header}>
          {headerData.text}
        </Typography>
      </Box>
      <Box sx={styles.bottomContainer}>
        <Typography variant="text" sx={styles.questionText}>
          {headerData.subtext}
        </Typography>
        <Box sx={styles.selectionBox}>
          {screenText.map(option => {
            return (
              <Box
                key={option.text}
                sx={[
                  styles.selectionItem,
                  selected === option.id ? styles.selectedItem : {}
                ]}
                onClick={() => setSelected(option.id)}
              >
                <Box>
                  <Typography variant="subtext" sx={styles.selectionText}>
                    {`${option.subtext} ${option.text}`}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box sx={styles.buttonContainer}>
          <OnboardingButton
            disabled={!selected}
            size="large"
            clickAction={() => {
              handleScreenChange(selected);
            }}
            buttonText={btn_continue}
            style={styles.onboardingButton}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default UserInfoSelector;
