/* @flow */
import React, { Fragment, Component } from 'react';
import _ from 'lodash';
import { InfoInputBox } from '../InfoInputBox';
import { PaymentInfoBox } from '../PaymentInfoBox';
import stripe from '../../../util/PurchaseUtils';
import {
  onBuyYearly,
  toggleDeleteAccount,
  onUpdateCard,
  updatePaymentMethod,
  validPaymentForm,
  isUpdatePayment
} from '../../../util';
import {
  PREFIX_SHOP,
  IMAGE_PATH,
  STRIPE,
  CARD_NAME,
  CARD_NUMBER,
  CARD_EXPIRY,
  CARD_CVC,
  PRO_TXT
} from '../../../common/constants';
import {
  FormStyle,
  TitleTextStyle,
  PaymentStyle,
  InputContainer,
  SpinnerContainer,
  SubmitButton,
  SmallRightContent,
  SmallLeftImg,
  SmallLeftContent,
  BotttomInputStyle,
  SmallSubscritionAdsContainer,
  ButtonText,
  LeftImg,
  RightContent,
  RightText,
  RightButton,
  LeftContent,
  SubscritionAdsContainer,
  LeftSection,
  LeftText
} from './ManageSubscriptionLeftSection.styles';

const lingLoading = `${IMAGE_PATH}ling-loader.gif`;
const blueLingImg = `${PREFIX_SHOP}pro-ling-image-350-px.png`;

type Props = {
  btn_submit: string,
  card: string,
  card_error_empty: string,
  card_error_invalid_cvc: string,
  card_error_invalid_date: string,
  card_error_number_not_correct: string,
  card_expiration: string,
  card_number: string,
  email_error_empty: string,
  email_error_wrongFormat: string,
  isLifetime: boolean,
  isProUser: boolean,
  isUpdateMethod: boolean,
  isUpdatingCard: boolean,
  login_field_name: string,
  onReactivateSubscription: Function,
  periodEnd: string,
  planData: string,
  planName: string,
  profile_txt_cancel_subscription: string,
  profile_txt_card_number: string,
  profile_txt_current_plan: string,
  profile_txt_expire_date: string,
  profile_txt_name_card: string,
  profile_txt_next_payment: string,
  isDeleteAcc: boolean,
  profile_txt_on: string,
  profile_txt_payment_method: string,
  profile_txt_reactivate: string,
  profile_txt_subscription_expire: string,
  profile_txt_visa_expire: string,
  purchase_button_subscribe_now: string,
  purchase_txt_benefit_details_01: string,
  shop_txt_7_day_trial: string,
  subscriptionData: any,
  update_subscription_methob_txt_profile: string,
  user: Object,
  isLoginPurchase: boolean,
  currency: string,
  history: Object,
  isCheckout: boolean,
  manageSceneStateChangeHandler: Function,
  functions: string
};

type State = {
  cardName: string,
  cardNumber: string,
  cardCvc: string,
  cardExpiry: string,
  brand: string,
  validCardNumber: boolean,
  validCardExpiry: boolean,
  validCardCvc: boolean,
  touched: Object
};
class ManageSubscriptionLeftSection extends Component<Props, State> {
  elements: any;
  cardNumber: any;
  cardExpiry: any;
  cardCvc: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      cardName: '',
      cardNumber: '',
      cardCvc: '',
      cardExpiry: '',
      brand: '',
      validCardNumber: false,
      validCardExpiry: false,
      validCardCvc: false,
      touched: {}
    };
  }

  componentDidMount() {
    const { card_number, card_expiration } = this.props;
    this.elements = stripe.elements();
    this.cardNumber = this.elements.create(CARD_NUMBER, {
      placeholder: card_number
    });
    this.cardExpiry = this.elements.create(CARD_EXPIRY, {
      placeholder: card_expiration
    });
    this.cardCvc = this.elements.create(CARD_CVC);
    this.cardNumber.mount('#' + CARD_NUMBER);
    this.cardExpiry.mount('#' + CARD_EXPIRY);
    this.cardCvc.mount('#' + CARD_CVC);
    this.cardNumber.on('change', event => {
      if (event.brand) {
        this.setState({ brand: event.brand });
      } else {
        this.setState({ brand: '' });
      }
      this.setState({ validCardNumber: event.complete });
    });
    this.cardNumber.on('blur', e => {
      const { touched } = this.state;
      if (e && touched[e.elementType] !== true) {
        touched[e.elementType] = true;
        this.setState({
          touched
        });
      }
    });
    this.cardExpiry.on('change', event => {
      this.setState({ validCardExpiry: event.complete });
    });
    this.cardExpiry.on('blur', e => {
      const { touched } = this.state;
      if (e && touched[e.elementType] !== true) {
        touched[e.elementType] = true;
        this.setState({
          touched
        });
      }
    });
    this.cardCvc.on('change', event => {
      this.setState({ validCardCvc: event.complete });
    });
    this.cardCvc.on('blur', e => {
      const { touched } = this.state;
      if (e && touched[e.elementType] !== true) {
        touched[e.elementType] = true;
        this.setState({
          touched
        });
      }
    });
  }

  handleChange = (property: any, value: any) => {
    this.setState({ [property]: value });
  };
  handleTouch = (e: SyntheticEvent<HTMLInputElement>) => {
    const { touched } = this.state;
    if (e && touched[e] !== true) {
      touched[e] = true;
      this.setState({
        touched
      });
    }
  };
  handleUpdatePaymentMethod = (e: SyntheticEvent<HTMLButtonElement>) => {
    const {
      isUpdateMethod,
      isUpdatingCard,
      subscriptionData,
      user,
      functions,
      manageSceneStateChangeHandler
    } = this.props;
    e.preventDefault();
    const cardNumber = this.elements.getElement(CARD_NUMBER);
    onUpdateCard(
      e,
      functions,
      cardNumber,
      this.state.cardName,
      isUpdateMethod,
      isUpdatingCard,
      subscriptionData,
      user,
      manageSceneStateChangeHandler
    );
  };
  render() {
    const {
      isUpdatingCard,
      subscriptionData,
      isProUser,
      isUpdateMethod,
      profile_txt_payment_method,
      planData,
      profile_txt_next_payment,
      profile_txt_name_card,
      login_field_name,
      card_error_invalid_cvc,
      card_error_invalid_date,
      card_error_number_not_correct,
      email_error_empty,
      email_error_wrongFormat,
      card_number,
      card_expiration,
      card_error_empty,
      profile_txt_card_number,
      profile_txt_expire_date,
      btn_submit,
      profile_txt_current_plan,
      profile_txt_cancel_subscription,
      purchase_button_subscribe_now,
      shop_txt_7_day_trial,
      purchase_txt_benefit_details_01,
      profile_txt_subscription_expire,
      periodEnd,
      profile_txt_on,
      onReactivateSubscription,
      profile_txt_reactivate,
      user,
      isLifetime,
      card,
      profile_txt_visa_expire,
      update_subscription_methob_txt_profile,
      planName,
      isLoginPurchase,
      currency,
      functions,
      history,
      isDeleteAcc,
      isCheckout,
      manageSceneStateChangeHandler
    } = this.props;
    const trialUsedIP = user.trialUsedIP !== '';
    const { subscription } = user;
    const {
      cardName,
      cardNumber,
      cardCvc,
      cardExpiry,
      brand,
      validCardNumber,
      validCardExpiry,
      validCardCvc,
      touched
    } = this.state;
    return (
      <LeftSection>
        <Fragment>
          {_.isEmpty(subscriptionData) && isProUser && (
            <SpinnerContainer>
              <img src={lingLoading} alt={lingLoading} />
            </SpinnerContainer>
          )}
          <InputContainer isUpdateMethod={isUpdateMethod}>
            {isUpdatingCard && (
              <SpinnerContainer>
                <img src={lingLoading} alt={lingLoading} />
              </SpinnerContainer>
            )}
            <FormStyle
              isUpdatingCard={isUpdatingCard}
              onSubmit={e => this.handleUpdatePaymentMethod(e)}
            >
              <TitleTextStyle>{profile_txt_payment_method}</TitleTextStyle>
              <InfoInputBox
                title={profile_txt_name_card}
                icon={'PersonFill'}
                id={CARD_NAME}
                cardName={cardName}
                validCardNumber={validCardNumber}
                validCardCvc={validCardCvc}
                validCardExpiry={validCardExpiry}
                inputValue={cardName}
                handleChange={this.handleChange}
                handleTouch={this.handleTouch}
                touched={touched.cardName}
                email_error_empty={email_error_empty}
                email_error_wrongFormat={email_error_wrongFormat}
                card_number={card_number}
                card_expiration={card_expiration}
                card_error_empty={card_error_empty}
                card_error_number_not_correct={card_error_number_not_correct}
                card_error_invalid_date={card_error_invalid_date}
                card_error_invalid_cvc={card_error_invalid_cvc}
                login_field_name={login_field_name}
              />
              <InfoInputBox
                title={profile_txt_card_number}
                icon={'CreditCard'}
                id={CARD_NUMBER}
                inputValue={cardNumber}
                cardName={cardName}
                validCardNumber={validCardNumber}
                validCardCvc={validCardCvc}
                validCardExpiry={validCardExpiry}
                handleChange={this.handleChange}
                email_error_empty={email_error_empty}
                email_error_wrongFormat={email_error_wrongFormat}
                cardType={brand}
                handleTouch={this.handleTouch}
                touched={touched.cardNumber}
                card_number={card_number}
                card_expiration={card_expiration}
                card_error_empty={card_error_empty}
                card_error_number_not_correct={card_error_number_not_correct}
                card_error_invalid_date={card_error_invalid_date}
                card_error_invalid_cvc={card_error_invalid_cvc}
                login_field_name={login_field_name}
              />
              <BotttomInputStyle>
                <InfoInputBox
                  title={profile_txt_expire_date}
                  icon={'Calendar'}
                  id={CARD_EXPIRY}
                  cardName={cardName}
                  validCardNumber={validCardNumber}
                  validCardCvc={validCardCvc}
                  validCardExpiry={validCardExpiry}
                  inputValue={cardExpiry}
                  handleChange={this.handleChange}
                  handleTouch={this.handleTouch}
                  email_error_empty={email_error_empty}
                  email_error_wrongFormat={email_error_wrongFormat}
                  touched={touched.cardExpiry}
                  card_number={card_number}
                  card_expiration={card_expiration}
                  card_error_empty={card_error_empty}
                  card_error_number_not_correct={card_error_number_not_correct}
                  card_error_invalid_date={card_error_invalid_date}
                  card_error_invalid_cvc={card_error_invalid_cvc}
                  login_field_name={login_field_name}
                />
                <InfoInputBox
                  title={'CVC'}
                  icon={'CVC'}
                  id={CARD_CVC}
                  cardName={cardName}
                  validCardNumber={validCardNumber}
                  validCardCvc={validCardCvc}
                  validCardExpiry={validCardExpiry}
                  inputValue={cardCvc}
                  handleChange={this.handleChange}
                  handleTouch={this.handleTouch}
                  touched={touched.cardCvc}
                  email_error_empty={email_error_empty}
                  email_error_wrongFormat={email_error_wrongFormat}
                  card_number={card_number}
                  card_expiration={card_expiration}
                  card_error_empty={card_error_empty}
                  card_error_number_not_correct={card_error_number_not_correct}
                  card_error_invalid_date={card_error_invalid_date}
                  card_error_invalid_cvc={card_error_invalid_cvc}
                  login_field_name={login_field_name}
                />
              </BotttomInputStyle>
              <SubmitButton
                disabled={
                  !validPaymentForm(
                    cardName,
                    validCardNumber,
                    validCardExpiry,
                    validCardCvc
                  )
                }
                type="submit"
              >
                {btn_submit}
              </SubmitButton>
            </FormStyle>
          </InputContainer>
          <PaymentStyle
            isUpdateMethod={isUpdatePayment(
              subscription,
              subscriptionData,
              isProUser,
              isUpdateMethod
            )}
          >
            {!isProUser ? (
              <SubscritionAdsContainer>
                <LeftContent>
                  <LeftImg src={blueLingImg} />
                </LeftContent>
                <RightContent>
                  <RightText>{purchase_txt_benefit_details_01}</RightText>
                  <RightButton>
                    <ButtonText
                      onClick={() =>
                        onBuyYearly(
                          'stripe_yearly',
                          isLoginPurchase,
                          user,
                          currency,
                          isProUser,
                          history,
                          isCheckout,
                          manageSceneStateChangeHandler
                        )
                      }
                    >
                      {trialUsedIP
                        ? purchase_button_subscribe_now
                        : shop_txt_7_day_trial}
                    </ButtonText>
                  </RightButton>
                </RightContent>
              </SubscritionAdsContainer>
            ) : isProUser && subscriptionData.canceled_at ? (
              <SubscritionAdsContainer>
                <LeftContent>
                  <LeftImg src={blueLingImg} />
                  <LeftText isCancel={subscriptionData.canceled_at}>
                    {PRO_TXT}
                  </LeftText>
                </LeftContent>
                <RightContent>
                  <RightText>
                    {profile_txt_subscription_expire} {profile_txt_on}{' '}
                    {periodEnd}
                  </RightText>
                  <RightButton>
                    <ButtonText onClick={onReactivateSubscription}>
                      {profile_txt_reactivate}
                    </ButtonText>
                  </RightButton>
                </RightContent>
              </SubscritionAdsContainer>
            ) : (
              !_.isEmpty(subscriptionData) && (
                <Fragment>
                  <SmallSubscritionAdsContainer>
                    <SmallLeftContent>
                      <SmallLeftImg src={blueLingImg} />
                    </SmallLeftContent>
                    <SmallRightContent>
                      <RightText>{purchase_txt_benefit_details_01}</RightText>
                    </SmallRightContent>
                  </SmallSubscritionAdsContainer>
                  <PaymentInfoBox
                    isLifetime={isLifetime}
                    titleText={profile_txt_current_plan}
                    type={subscriptionData.type}
                    descriptionText={planName}
                    buttonText={
                      subscriptionData.type !== STRIPE
                        ? ''
                        : profile_txt_cancel_subscription
                    }
                    onSubscription={() =>
                      toggleDeleteAccount(
                        isDeleteAcc,
                        manageSceneStateChangeHandler
                      )
                    }
                  />
                  {!isLifetime && (
                    <PaymentInfoBox
                      titleText={
                        subscription && subscription.isPromo
                          ? 'Subscription data'
                          : profile_txt_next_payment
                      }
                      descriptionText={planData}
                      buttonText={''}
                    />
                  )}
                  {!(subscription && subscription.isPromo) && (
                    <PaymentInfoBox
                      id="PaymentInfoBox"
                      isLifetime={isLifetime}
                      titleText={profile_txt_payment_method}
                      descriptionText={card}
                      profile_txt_visa_expire={profile_txt_visa_expire}
                      buttonText={
                        subscriptionData.type !== STRIPE
                          ? ''
                          : update_subscription_methob_txt_profile
                      }
                      onSubscription={() =>
                        updatePaymentMethod(
                          false,
                          functions,
                          isUpdatingCard,
                          isUpdateMethod,
                          user,
                          manageSceneStateChangeHandler
                        )
                      }
                    />
                  )}
                </Fragment>
              )
            )}
          </PaymentStyle>
        </Fragment>
      </LeftSection>
    );
  }
}

export { ManageSubscriptionLeftSection };
