/* @flow */
import React, { Component } from 'react';
import { SpellingGameScreen } from '../main-components';
import { CheckButton } from '../sub-components';
import styled from 'styled-components';

import {
  ORDER_SENTENCE,
  BUTTON_DISABLED,
  BUTTON_CHECK_DISABLED,
  BUTTON_CONTINUE_DISABLED,
  BUTTON_SEND_DISABLED
} from '../../constants';
import { stopSound } from '../../../util';

const OrderSentenceGameSceneContainer = styled.div`
  margin-top: 90px;
  padding-bottom: 110px;
  ${'' /* align-items: center;
  flex: 1;
  display: ${props => (props.isShowTutorial ? 'unset' : 'flex')};
  min-height: ${props => (props.isShowTutorial ? 'unset' : '700px')}; */}
`;
type Props = {
  translation: string,
  getCharacterCards: Function,
  getTopCharacterCards: Function,
  isReverse: boolean,
  transcriptState: boolean,
  transcript: any,
  title_txt_sort: string,
  btn_hint: string,
  error_txt_game_wrong: string,
  btn_check: string,
  btn_continue: string,
  isTargetRightToLeftlLanguage: boolean,
  nativeRtlLanguage: boolean,
  pressSpellingNextButton: Function,
  mode: string,
  spendHint: Function,
  coins: number,
  voiceSpeed: number,
  original: string,
  answeredWrong: boolean,
  hintButtonEnabled: boolean,
  isResultShown: boolean,
  soundFile: string,
  isShowTutorial: boolean,
  writing_skip: string,
  gameType: string,
  onCloseTutorialMotivate: Function,
  setShowTutorial: Function
};

type State = {};
export class OrderSentence extends Component<Props, State> {
  componentDidMount() {
    window.scrollTo(0, 0);

    // $flow-disable-line
    document.addEventListener('keydown', this.handleEnter);
  }
  componentDidUpdate(prevProps: Props) {
    if (this.props.original !== prevProps.original) {
      window.scrollTo(0, 0);
    }
  }
  componentWillUnmount() {
    window.scrollTo(0, 0);
    // $flow-disable-line
    document.removeEventListener('keydown', this.handleEnter);
  }
  handleEnter = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { pressSpellingNextButton, mode } = this.props;
    if (
      event.keyCode === 13 &&
      pressSpellingNextButton &&
      mode !== BUTTON_DISABLED &&
      mode !== BUTTON_CHECK_DISABLED &&
      mode !== BUTTON_CONTINUE_DISABLED &&
      mode !== BUTTON_SEND_DISABLED
    ) {
      pressSpellingNextButton();
    }
  };
  render() {
    const {
      translation,
      original,
      getCharacterCards,
      getTopCharacterCards,
      isReverse = false,
      transcriptState,
      transcript,
      title_txt_sort,
      btn_hint,
      error_txt_game_wrong,
      btn_check,
      btn_continue,
      isTargetRightToLeftlLanguage,
      nativeRtlLanguage,
      pressSpellingNextButton,
      mode,
      spendHint,
      coins,
      voiceSpeed,
      answeredWrong,
      hintButtonEnabled,
      isResultShown,
      soundFile,
      isShowTutorial,
      writing_skip,
      onCloseTutorialMotivate,
      setShowTutorial,
      gameType
    } = this.props;
    return (
      <OrderSentenceGameSceneContainer isShowTutorial={isShowTutorial}>
        <SpellingGameScreen
          isTargetLangSymbol={true}
          {...this.props}
          soundFile={soundFile}
          isResultShown={isResultShown}
          hintButtonEnabled={hintButtonEnabled}
          answeredWrong={answeredWrong}
          instruction={title_txt_sort}
          question={isReverse ? original : translation}
          transcript={isReverse && transcriptState ? transcript : null}
          topCards={getTopCharacterCards(this.props, true)}
          bottomCards={getCharacterCards(this.props)}
          btn_hint={btn_hint}
          error_txt_game_wrong={error_txt_game_wrong}
          btn_check={btn_check}
          btn_continue={btn_continue}
          isTargetRightToLeftlLanguage={isTargetRightToLeftlLanguage}
          nativeRtlLanguage={nativeRtlLanguage}
          gameType={gameType}
          voiceSpeed={voiceSpeed}
          isShowTutorial={isShowTutorial}
        />
        <CheckButton
          gameType={ORDER_SENTENCE}
          quizAnswer={''}
          {...this.props}
          onPressNext={() => {
            stopSound();
            pressSpellingNextButton();
          }}
          mode={mode}
          btn_check={btn_check}
          btn_continue={btn_continue}
          btn_hint={btn_hint}
          spendHint={spendHint}
          coins={coins}
          isShowTutorial={isShowTutorial}
          writing_skip={writing_skip}
          onCloseTutorialMotivate={onCloseTutorialMotivate}
          setShowTutorial={setShowTutorial}
        />
      </OrderSentenceGameSceneContainer>
    );
  }
}
