import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { PersistGate } from 'redux-persist/integration/react';
import { configureStore } from './system/ConfigureStore';
import '@fontsource/poppins';
import '@fontsource/noto-sans';

export const { store, persistor } = configureStore();
//NOTE: this is used to remove all console.log in production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
}

const AppWithRouter = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

ReactDOM.render(<AppWithRouter />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
