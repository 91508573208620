/* @flow */
import { firebase } from '../system/Firebase';
import { PROGRESS_PATH } from '../common/constants';
import bugtracker from '../util/BugTracker';
import { updateUserData } from '../games/actions';
import { getCurrentUserGoalData, isUserAnonymous } from '.';
import axios from 'axios';
import moment from 'moment';

export const updateUserProgressBananas = async (
  bananasNumber: number,
  targetLangCode: string,
  uid: string,
  bananas: number
) => {
  const { monthlyBananas = 0, dailyBananas = 0 } = await firebase
    .database()
    .ref(`${PROGRESS_PATH}/${targetLangCode}/${uid}`)
    .once('value')
    .then(snapshot => {
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        console.log('No data available');
        return {};
      }
    });
  return firebase
    .database()
    .ref(`${PROGRESS_PATH}/${targetLangCode}/${uid}`)
    .update({
      monthlyBananas: monthlyBananas + bananas,
      dailyBananas: dailyBananas + bananas
    })
    .catch(error => bugtracker.notify(error));
};

export const isMobile = () =>
  navigator.userAgent.toLowerCase().indexOf('mobile') > -1;

export const saveDailyGoal = ({
  isSetDailyGoal = null,
  userDailyGoal
}: Object) => (dispatch: Function, getState: Function) => {
  const state = getState();
  const stateGoal = getCurrentUserGoalData(state);
  stateGoal['isSetDailyGoal'] = isSetDailyGoal;
  stateGoal['userDailyGoal'] = userDailyGoal;
  dispatch(updateUserData({ goal: stateGoal }));
};

export const getUserProfile = (user: Object) =>
  user.facebook || user.google || user.email || user.apple;

export const updateUserPromotion = (functions: string, user: Object) => (
  dispatch: Function,
  getState: Function
) => {
  const expolinguaPromo = {
    subscription: {
      isPromo: true,
      promoExpirationDate: moment(Date.now() + 86400000 * 14).format(
        'YYYY-MM-DD'
      )
    }
  };
  axios
    .post(
      `${functions}/validateLingWeb-stripeApi/app/${user.uid}`,
      expolinguaPromo
    )
    .then(data => {
      if (!isUserAnonymous(user)) {
        dispatch(
          updateUserData({ subscription: expolinguaPromo.subscription })
        );
      }
    })
    .catch(error => {
      console.log('error: do parsing', error);
    });
};
