import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import { downloadStats } from '.';
import { loadStats, loadUnitBoxes } from '../actions';
import BugTracker from '../../util/BugTracker';
import { getData } from '../selectors';
import { loadAllTargetLanguages } from '../../common/actions';

export function* fetchUnitMetaData(data: Object): Object {
  if (process.env.REACT_APP_DEV) {
    console.log('Load global assets: Get stats...', data);
  }
  try {
    const units = yield call(getStats, data);
    yield put(loadUnitBoxes(_.compact(units).length));
    if (process.env.REACT_APP_DEV) {
      console.log('Load global assets: stats loaded...', units);
    }
  } catch (err) {
    BugTracker.notify(err);
    console.log('Error loading stats!', err);
  }
}

export function* fetchAlltarget(): Object {
  yield call(loadAllTargetLanguages);
}

export function* fetchUnitMeta(): Object {
  const data = yield select(getData);
  try {
    const units = yield call(getStats, data);
    yield put(loadUnitBoxes(_.compact(units).length));
    console.log('Load global assets: stats loaded...', units);
  } catch (err) {
    BugTracker.notify(err);
    console.log('Error loading stats!', err);
  }
}

export function* getStats(data: Object): Object {
  const { targetLangCode } = data;
  const { totalWordCount, totalSentenceCount, units } = yield call(
    downloadStats,
    targetLangCode
  );
  yield put(
    loadStats(targetLangCode, totalWordCount, totalSentenceCount, units)
  );
  return units;
}
