import initialState from '../system/initialState';
import * as ConfigFilters from './constants';

export default (state = initialState.config, action) => {
  switch (action.type) {
    case ConfigFilters.startInit:
      return {
        ...state,
        started: false
      };
    case ConfigFilters.initConfig:
      return {
        ...state,
        started: true
      };
    case ConfigFilters.dataUpdate: {
      const newValues = {};

      for (const [k, v] of Object.entries(action.data)) {
        Object.assign(newValues, { [k]: v._value });
      }

      return {
        ...state,
        fetched: true,
        requestFetch: false,
        ...newValues
      };
    }
    case ConfigFilters.dataComplete:
      return {
        ...state,
        fetched: true
      };
    case ConfigFilters.dataRequest:
      return {
        ...state,
        fetched: false,
        requestFetch: true
      };
    default:
      return state;
  }
};
