/* @flow */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PREFIX_HEADER } from '../../common/constants';
import * as actions from '../../games/actions';
import { onCloseTutorialMotivate } from '../../common/actions';
import { getLabel } from '../../util';
import {
  startTutorial,
  toggleTranscript,
  updateUserProfile,
  dialogTranslate
} from '../../games/actions';
import {
  getTranscriptionState,
  getHasTranscript
} from '../../profile/selectors';
import { Box } from '@mui/material';
import MaterialButton from '../sub-components/MaterialButton';

type TutorialSceneState = {
  isScrolled: boolean
};

type TutorialSceneProps = {
  tutorial_txt_start: string,
  unitId: number,
  startTutorial: Function,
  history: Object,
  transcriptState: boolean,
  toggleTranscript: Function,
  updateUserProfile: Function,
  showTutorial: boolean,
  getHasTranscript: boolean,
  onTranslate: Function,
  dialogueIds: Array<number>
};

const lingTestComment = `${PREFIX_HEADER}ling-test-comment-bg.png`;
const masterlingBlackboard = `${PREFIX_HEADER}masterling-image-blackboard-300-px.png`;

class TutorialScene extends Component<TutorialSceneProps, TutorialSceneState> {
  constructor(props: TutorialSceneProps) {
    super(props);
    this.state = {
      isScrolled: false
    };
  }
  componentDidMount() {
    const {
      transcriptState,
      toggleTranscript,
      onTranslate,
      getHasTranscript
    } = this.props;
    onTranslate();

    if (!transcriptState && getHasTranscript) {
      toggleTranscript();
    }
  }
  UNSAFE_componentWillMount() {
    this.props.updateUserProfile({ lastUserUnitId: 0 });
  }

  handlePress = () => {
    const { history, startTutorial, dialogueIds } = this.props;
    startTutorial(dialogueIds);
    history.push('/tutorial');
  };
  render() {
    const { tutorial_txt_start } = this.props;

    const styles = {
      button: {
        color: 'common.white',
        mb: 4
      },
      container: {
        display: 'flex',
        flexDirection: 'row',
        height: {
          md: 'calc(100vh - 100px)',
          xs: '100%'
        },
        backgroundColor: 'primary.main',
        position: 'relative'
      },
      hero: {
        height: 350,
        backgroundColor: 'common.white',
        borderRadius: 7,
        boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.2)',
        position: 'absolute',
        zIndex: 1,
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        py: 6
      },
      mainImage: {
        maxWidth: 300,
        maxHeight: 187,
        py: 1,
        px: 7
      },
      bottomImage: {
        position: 'fixed',
        height: 187,
        bottom: 0,
        width: '100%',
        display: 'flex',
        left: '50%',
        transform: 'translateX(-50%)'
      }
    };

    return (
      <Box sx={styles.container}>
        <Box sx={styles.hero}>
          <Box
            component="img"
            sx={styles.mainImage}
            src={masterlingBlackboard}
            alt={masterlingBlackboard}
          />
          <MaterialButton
            sx={styles.button}
            size="large"
            buttonText={tutorial_txt_start}
            clickAction={this.handlePress}
          />
        </Box>
        <Box component="img" sx={styles.bottomImage} src={lingTestComment} />
      </Box>
    );
  }
}

const mapStateToProps = (state: Object): Object => {
  const { dialog } = state;
  const { dialogueIds } = dialog;
  return {
    dialogueIds,
    getHasTranscript: getHasTranscript(state),
    transcriptState: getTranscriptionState(state),
    tutorial_txt_start: getLabel(state, 'tutorial_txt_start', 'Start Tutorial')
  };
};

export const mapDispatchToProps = (dispatch: Function) => {
  return {
    startTutorial: (dialogueIds: Array<number>) => {
      if (dialogueIds && dialogueIds.length > 0) {
        dispatch(onCloseTutorialMotivate());
        dispatch(actions.stopDialogSound());
        dispatch(actions.dialogMessageDeactive());
        dispatch(actions.dialogReplay());
        dispatch(actions.playDialogSound(0, true));
      }
      dispatch(startTutorial());
    },

    toggleTranscript: () => dispatch(toggleTranscript()),
    onTranslate: () => {
      dispatch(dialogTranslate());
    },
    updateUserProfile: (id: string) =>
      dispatch(updateUserProfile({ lastUserUnitId: id }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TutorialScene);
