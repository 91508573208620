/* @flow */
import GraphemeSplitter from './Grapheme-splitter';

export const split = (word: string): string[] => {
  return new GraphemeSplitter().splitGraphemes(word);
};

export const splitByCharAt = (word: string): string[] => {
  let result = [];
  for (let index = 0; index < word.length; index++) {
    result.push(word.charAt(index));
  }
  return result;
};
