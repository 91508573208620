/* @flow */
import * as React from 'react';
import { QuestionHeader, CardBox } from '../sub-components';
import styled from 'styled-components';

const SpellingContainer = styled.div`
  alight-item: center;
  flex: ${props => (props.isDialog ? 1 : 'none')};
  @media (max-height: 730px) {
    margin-top: ${props =>
      props.isDialog || props.isShowTutorial ? 1 : '0px'};
  }

  @media (min-height: 730px) {
    margin-top: ${props =>
      props.isDialog || props.isShowTutorial ? 1 : '0px'};
  }
`;
type Props = {
  soundFile: string,
  instruction: string,
  question: string,
  cards: React.Element<any>[],
  answeredWrong?: boolean,
  isResultShown: boolean,
  correctAnswer?: Object[],
  byId?: Object,
  imageSource?: string,
  transcript?: string,
  isPlayable?: boolean,
  cardAlign?: string,
  scrollEnabled?: boolean,
  dialogHeader?: Object,
  isDialog?: boolean,
  isTargetRightToLeftlLanguage: boolean,
  nativeRtlLanguage: boolean,
  isTargetRightToLeftlLanguage: boolean,
  gameType: string,
  transcriptState: boolean,
  cardStatue?: string,
  voiceSpeed: number,
  isShowTutorial: boolean,
  isTargetLangSymbol: boolean
};

const SpellingMainArea = ({
  soundFile,
  instruction,
  question,
  cards,
  answeredWrong,
  isResultShown,
  correctAnswer,
  byId,
  imageSource,
  transcript,
  cardAlign = 'horizontal',
  isPlayable = true,
  scrollEnabled = false,
  dialogHeader = {},
  isDialog = false,
  gameType,
  isTargetRightToLeftlLanguage,
  nativeRtlLanguage,
  transcriptState,
  cardStatue,
  voiceSpeed,
  isShowTutorial,
  isTargetLangSymbol
}: Props) => {
  return (
    <SpellingContainer isDialog={isDialog} isShowTutorial={isShowTutorial}>
      {isDialog ? null : (
        <QuestionHeader
          soundFile={soundFile}
          instruction={instruction}
          transcript={transcript}
          isPlayable={isPlayable}
          isDialog={isDialog}
          cardStatue={cardStatue}
          voiceSpeed={voiceSpeed}
          isShowTutorial={isShowTutorial}
        >
          {question}
        </QuestionHeader>
      )}
      <CardBox
        mode={cardAlign}
        answeredWrong={answeredWrong}
        isDialog={isDialog}
        gameType={gameType}
        isTargetRightToLeftlLanguage={isTargetRightToLeftlLanguage}
        nativeRtlLanguage={nativeRtlLanguage}
        transcriptState={transcriptState}
        isShowTutorial={isShowTutorial}
        isTargetLangSymbol={isTargetLangSymbol}
      >
        {cards}
      </CardBox>
    </SpellingContainer>
  );
};

export { SpellingMainArea };
