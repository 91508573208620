import React, { useState } from 'react';
import styled from 'styled-components';
import { Carousel, CarouselItem, CarouselIndicators } from 'reactstrap';
import _ from 'lodash';
import {
  CAROUSEL_SPEED,
  PREFIX_LESSON,
  PREFIX_SHOP,
  RATE_STARS_NUMBER,
  VERY_LIGHT_PINK,
  WHITE
} from '../../../common/constants';
const TestimonialTitleStyle = styled.h3`
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.25px;
  text-align: center;
  color: ${WHITE};
`;
const TestimonialStyle = styled.div`
  display: block;
  background: gray;
  justify-content: space-around;
  text-align: center;
  flex-direction: column;
  background: url(${props => props.imgUrl});
  background-size: cover;
  background-repeat-y: no-repeat;
  padding: 25px 0;
`;
const TestimonialNameStyle = styled.p``;
const TestimonialNameContainerStyle = styled.div`
  flex: 0.3;
`;
export const TestimonialDescriptionStyle = styled.p`
  margin-bottom: 10px;
  border-radius: 50%;
`;

const TestimonialContentStyle = styled.div`
  text-align: center;
  margin: auto;
  width: 350px;
  border-radius: 21px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
  border: solid 4px ${VERY_LIGHT_PINK};
  background-image: linear-gradient(to top, ${VERY_LIGHT_PINK}, ${WHITE});
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;
const TestimonialDescriptionContainerStyle = styled.div`
  flex: 0.7;
  display: flex;
  margin: 0 25px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;
export const CarouselContainer = styled.div`
  margin-bottom: 5px;
  z-index: 1;
`;
const RateStarContainer = styled.div`
  margin-top: 20px;
`;
const RateStarImg = styled.img`
  height: 30px;
`;

const starIcon = `${PREFIX_LESSON}fill-yellow-star-21-px@2x.png`;
type Props = {
  trial_txt_testimonial_name_1: string,
  trial_txt_testimonial_1: string,
  trial_txt_testimonial_name_2: string,
  trial_txt_testimonial_2: string,
  trial_txt_testimonial_name_3: string,
  trial_txt_testimonial_3: string,
  trial_txt_testimonial_name_4: string,
  trial_txt_testimonial_4: string,
  shop_txt_testimonial_title: string,
  animating: boolean
};
const Testimonials = ({
  trial_txt_testimonial_name_1,
  trial_txt_testimonial_1,
  trial_txt_testimonial_name_2,
  trial_txt_testimonial_2,
  trial_txt_testimonial_name_3,
  trial_txt_testimonial_3,
  trial_txt_testimonial_name_4,
  trial_txt_testimonial_4,
  shop_txt_testimonial_title,
  animating
}: Props) => {
  const [testimonialActiveIndex, setTestimonialActiveIndex] = useState(0);

  const nextTestimonial = (event: SyntheticEvent<HTMLInputElement>) => {
    if (animating) {
      return;
    }
    const nextIndex =
      testimonialActiveIndex === testimonialItems.length - 1
        ? 0
        : testimonialActiveIndex + 1;
    setTestimonialActiveIndex(nextIndex);
  };
  const goToIndexTestimonial = (newIndex: any) => {
    if (animating) {
      return;
    }
    setTestimonialActiveIndex(newIndex);
  };
  const onExiting = (event: SyntheticEvent<HTMLInputElement>) => {
    animating = true;
  };

  const onExited = (event: SyntheticEvent<HTMLInputElement>) => {
    animating = false;
  };

  const previousTestimonial = (event: SyntheticEvent<HTMLInputElement>) => {
    if (animating) {
      return;
    }
    const nextIndex =
      testimonialActiveIndex === 0
        ? testimonialItems.length - 1
        : testimonialActiveIndex - 1;
    setTestimonialActiveIndex(nextIndex);
  };
  const testimonialItems = [
    {
      name: trial_txt_testimonial_name_1,
      des: trial_txt_testimonial_1
    },
    {
      name: trial_txt_testimonial_name_2,
      des: trial_txt_testimonial_2
    },
    {
      name: trial_txt_testimonial_name_3,
      des: trial_txt_testimonial_3
    },
    {
      name: trial_txt_testimonial_name_4,
      des: trial_txt_testimonial_4
    }
  ];
  const testimonialList = testimonialItems.map((item, index) => (
    <CarouselItem onExiting={onExiting} onExited={onExited} key={index}>
      <TestimonialContentStyle>
        <RateStarContainer>
          {_.times(RATE_STARS_NUMBER, number => (
            <RateStarImg src={starIcon} alt={starIcon} key={number} />
          ))}
        </RateStarContainer>
        <TestimonialDescriptionContainerStyle>
          <TestimonialDescriptionStyle>
            {item.des}
          </TestimonialDescriptionStyle>
        </TestimonialDescriptionContainerStyle>

        <TestimonialNameContainerStyle>
          <TestimonialNameStyle>{item.name}</TestimonialNameStyle>
        </TestimonialNameContainerStyle>
      </TestimonialContentStyle>
    </CarouselItem>
  ));

  return (
    <TestimonialStyle imgUrl={`${PREFIX_SHOP}testimonial-bg.jpg`}>
      <TestimonialTitleStyle>
        {shop_txt_testimonial_title}
      </TestimonialTitleStyle>
      <CarouselContainer isPromo={true}>
        <Carousel
          activeIndex={testimonialActiveIndex}
          next={nextTestimonial}
          previous={previousTestimonial}
          ride="carousel"
          pause={false}
          interval={CAROUSEL_SPEED}
        >
          {testimonialList}
          <CarouselIndicators
            items={testimonialItems}
            activeIndex={testimonialActiveIndex}
            onClickHandler={goToIndexTestimonial}
          />
        </Carousel>
      </CarouselContainer>
    </TestimonialStyle>
  );
};

export { Testimonials };
