/* @flow */
import styled from 'styled-components';
import {
  GREYISH_BROWN,
  WHITE,
  MARIGOLD,
  DARK_YELLOW,
  LIGHT_RED_1,
  DARK_GREY,
  GREY,
  GREY2,
  SEA_BLUE,
  BLACK
} from '../../../../common/constants';
const ModalBodyText = styled.div`
  font-size: 18px;
  color: ${GREYISH_BROWN};
  line-height: 21px;
  margin-bottom: 13px !important;
`;
const ModalContentContainer = styled.div`
  width: auto;
  margin: 0 38px;
`;
const ModalCloseButton = styled.button`
  position: fixed;
  opacity: 1;
  padding: 7px 0px !important;
  margin-left: 16px !important;
  margin-bottom: 10px !important;
`;
const LoginStyle = styled.button`
  text-transform: uppercase;
  margin-top: 26px;
  margin-bottom: 25px;
  border-radius: 20px;
  box-shadow: inset 0 -4px 0 0 ${DARK_YELLOW};
  background: ${MARIGOLD};
  border: ${DARK_YELLOW} 1px solid;
  font-size: 14px;
  width: 157px;
  height: 55px;
  padding: 0;
  color: ${WHITE};
  &:hover {
    opacity: 0.4;
    box-shadow: none;
  }
  &:active {
    opacity: 1;
    background-color: ${DARK_YELLOW};
    box-shadow: none;
  }
`;
const InputPlace = styled.input`
  width: 100%;
  border: none;
  outline: none;
  background-color: ${WHITE} !important;
`;
const BottomText = styled.div`
  margin-top: 13px;
`;
const ModalResetPasswordContainer = styled.div`
  margin: 0px;
`;
const ErrorTextStyle = styled.span`
  font-size: 12px;
  text-align: left;
  padding-left: 14px;
  display: flex;
  padding-top: 5px;
  font-size: 14px;
  text-align: left;
  color: ${LIGHT_RED_1};
  font-style: italic;
`;
const InputErrorTextStyle = styled.span`
  font-size: 12px;
  text-align: left;
  padding-left: 0;
  display: flex;
  padding-top: 5px;
  font-size: 14px;
  text-align: left;
  color: ${LIGHT_RED_1};
  font-style: italic;
`;
const ForgotTextStyle = styled.span`
  font-size: 14px;
  text-align: left;
  padding-left: 14px;
  font-weight: bold;
  display: flex;
  padding-top: 0px;
  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
  &:active {
    opacity: 1;
  }
`;
const SocialButton = styled.button`
  img {
    width: 23px;
    height: 23px;
  }
  &:hover {
    opacity: 0.4;
  }
  &:active {
    opacity: 1;
  }
`;
const InputLabel = styled.label`
  color: ${props =>
    props.isError && props.errorMsg !== '' ? LIGHT_RED_1 : DARK_GREY};
`;
const ModalBodyContainer = styled.div`
  display: ${props => (props.isLoading ? 'block' : 'none')};
`;
const LoginLink = styled.span`
  &:hover {
    opacity: 0.4;
  }
`;
const LingLoading = styled.img`
  margin: 94px 38px 174px;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Box = styled.div`
  margin: 10px;
  padding: 20px;
  text-align: center;

  @media (max-width: 600px) {
    flex-grow: 1;
  }
`;

export const ArrowBox = styled(Box)`
  flex-grow: 1;
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 600px) {
    margin: 0px;
    padding: 0px;
    flex-grow: 0;
    display: flex;
    order: 2;
    justify-content: flex-end;
  }
`;

export const ProgressBarBox = styled(Box)`
  flex-grow: 2;
  padding: 10px 0px;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  max-width: 750px;
  @media (max-width: 600px) {
    flex-grow: 3;
    order: 3;
  }
`;

export const LoginBox = styled(Box)`
  flex-grow: 1;
  padding: 0px;
  margin: 0px;
  display: flex;

  @media (max-width: 600px) {
    flex-grow: 0;
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: flex-end;
    order: 3;
  }
`;

const styles = {
  modalContainer: {
    width: '460px',
    height: '457px',
    textAlign: 'center',
    borderRadius: 30
  },
  modalBodyContainer: {
    padding: 0,
    paddingBottom: 36
  },
  buttonStyle: {
    borderRadius: 10,
    width: 160,
    height: 55,
    background: WHITE,
    border: 'solid 1px',
    borderColor: GREY2,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    boxShadowColor: GREY2,
    fontWeight: 'bold',
    fontSize: 18
  },
  cancelButtonStyle: {
    border: 'none',
    boxShadow: 'none',
    width: 100
  },
  confirmButtonStyle: {
    borderColor: GREY2,
    background: WHITE,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0'
  },
  modalHeader: {
    borderBottom: 'none',
    flexDirection: 'row-reverse',
    justifyContent: 'normal',
    padding: '29px 35px',
    paddingBottom: 27
  },
  modalFooter: {
    borderTop: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 36,
    paddingRight: 32,
    paddingLeft: 32
  },
  contentContainer: {
    borderRadius: 30
  },
  modalHeaderContainer: {
    'modal-title': 'w-100 text-center'
  },
  inputGroup: {
    textAlign: 'left',
    borderColor: GREY,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 20
  },
  inputGroupResetPassword: {
    margin: '76px 35px 55px'
  },

  inputContainer: {
    padding: '10px 15px 15px',
    width: 'inherit',
    fontSize: 14
  },
  labelStyle: {
    fontWeight: 'bold',
    marginBottom: 12,
    textTransform: 'capitalize'
  },
  forgotTextStyle: {
    fontSize: 14,
    textAlign: 'left',
    paddingLeft: 14,
    fontWeight: 'bold',
    display: 'flex',
    paddingTop: 10
  },
  otherLoginButton: {
    borderRadius: 22,
    padding: 0,
    width: 80,
    height: 42,
    background: WHITE,
    color: BLACK,
    border: 'gray solid 1px',
    borderColor: GREY,
    borderWidth: 1,
    borderStyle: 'solid',
    margin: '0 6px',
    marginTop: 5
  },
  appleIconStyle: {
    marginLeft: 0
  },
  googleIconStyle: {
    marginRight: 0
  },
  otherLoginGroup: {
    marginBottom: 25
  },
  signUpSuggestionStyle: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  signUpStyle: {
    color: SEA_BLUE,
    cursor: 'pointer'
  },
  space: {
    height: 12
  },
  loginFormSpaceBottom: {
    paddingBottom: 4
  },
  loadingStyle: {
    margin: '134px 38px',
    color: MARIGOLD
  },
  container: {
    backgroundColor: '#EAF3FC',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    py: 7,
    px: 3,
    textAlign: 'center',
    overflowY: 'hidden',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    mt: 15
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    px: 5,
    minWidth: '345px',
    justifyContent: 'space-between',
    fontColor: '#111111',
    mb: 8
  },
  text: {
    fontSize: '30px',
    fontColor: '#111111'
  },
  detail: {
    textAlign: 'center',
    paddingRight: 3,
    alignItems: 'center'
  },
  image: {
    mb: 5,
    width: '80%',
    maxWidth: '750px'
  },
  button: {
    width: '85%',
    maxWidth: '558px',
    borderRadius: 9,
    textTransform: 'none',
    color: '#FFFFFF',
    fontSize: '18px',
    fontWeight: 700,
    mt: 9,
    mb: 5,
    position: 'absolute',
    bottom: 5
  },
  boxTextField: {
    maxWidth: 500,
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  textField: {
    borderRadius: 16,
    marginBottom: 15,
    padding: 0,
    width: '95%'
  },
  switchStye: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    mb: 2
  },
  formContainer: {
    width: '100%',
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  mainBar: {
    py: 1,
    px: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid #F1F1F1'
  },
  barContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: 375,
    width: '100%',
    flexWrap: 'wrap'
  },
  progressBar: {
    marginLeft: 5,
    marginRight: 5,
    flex: 1
  },
  linkTouAndPP: {
    padding: 10
  },
  lingLogo: {
    display: 'flex',
    marginLeft: 4
  },
  loginButtonStyle: {
    marginRight: 20
  },
  backButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: ''
  },
  backButtonContainer: {
    display: 'flex',
    flexDirection: 'row',

    alignItems: 'center'
  },
  icon: {
    height: 15,
    width: 15,
    cursor: 'pointer'
  }
};

export {
  BottomText,
  ErrorTextStyle,
  ForgotTextStyle,
  InputErrorTextStyle,
  InputLabel,
  InputPlace,
  LingLoading,
  LoginLink,
  LoginStyle,
  ModalBodyContainer,
  ModalBodyText,
  ModalCloseButton,
  ModalContentContainer,
  ModalResetPasswordContainer,
  SocialButton,
  styles
};
