/* @flow */
import _ from 'lodash';
import {
  PROMO_LIFETIME,
  PRODUCT_TYPE,
  PROMO_YEARLY_20,
  PROMO_MONTYLY_20
} from '../constants/index';
import axios from 'axios';
import BugTracker from './../../util/BugTracker';
import { getCurrency } from '../../util';

export const getPromoProductByBundleId = async (
  state: Object,
  bundleId: string,
  type: string,
  functions: string
) => {
  const language = window.navigator.language;
  const currency = getCurrency(language);
  let promoDetail = {};
  let usdPrice = {};
  await axios
    .get(`${functions}validateLingWeb-stripeApi/products/get`)
    .then(res => {
      if (res.status === 200) {
        _.forEach(res.data.plans.data, value => {
          if (!_.isEmpty(value)) {
            if (
              value.id ===
              getProductPrice(
                bundleId,
                (currency ? currency : 'usd').toUpperCase()
              )
            ) {
              promoDetail = {
                percentOff: 20,
                id: value.id,
                value: value.price / 100,
                type: type === PROMO_LIFETIME ? PRODUCT_TYPE.lifetime : type
              };
            } else if (
              value.id ===
              getProductPrice(
                bundleId,
                value.currency.toUpperCase() === 'USD'
                  ? 'USD'
                  : value.currency.toUpperCase()
              )
            ) {
              usdPrice = {
                percentOff: 20,
                id: value.id,
                value: value.price / 100,
                type: type === PROMO_LIFETIME ? PRODUCT_TYPE.lifetime : type
              };
            }
          }
        });
        _.forEach(res.data.skus.data, value => {
          if (!_.isEmpty(value)) {
            if (
              value.id ===
              getProductPrice(
                bundleId,
                (currency ? currency : 'usd').toUpperCase()
              )
            ) {
              promoDetail = {
                percentOff: 20,
                id: value.id,
                value: value.price / 100,
                type: type === PROMO_LIFETIME ? PRODUCT_TYPE.lifetime : type
              };
            } else if (
              value.id ===
              getProductPrice(
                bundleId,
                value.currency.toUpperCase() === 'USD'
                  ? 'USD'
                  : value.currency.toUpperCase()
              )
            ) {
              usdPrice = {
                percentOff: 20,
                id: value.id,
                value: value.price / 100,
                type: type === PROMO_LIFETIME ? PRODUCT_TYPE.lifetime : type
              };
            }
          }
        });
      }
    })
    .catch(err => {
      BugTracker.notify(err);
      console.error(err);
    });

  return promoDetail === {} ? usdPrice : promoDetail;
};

export const getProductPrice = (promoId: string, currency: string): any => {
  let stripePromoPriceId = '';

  if (promoId === PROMO_MONTYLY_20) {
    switch (currency) {
      case 'USD':
        stripePromoPriceId = process.env.REACT_APP_STRIPE_PROMO_MONTHLY_20_USD;
        break;
      case 'EUR':
        stripePromoPriceId = process.env.REACT_APP_STRIPE_PROMO_MONTHLY_20_EUR;
        break;
      case 'THB':
        stripePromoPriceId = process.env.REACT_APP_STRIPE_PROMO_MONTHLY_20_THB;
        break;
      case 'GBP':
        stripePromoPriceId =
          process.env.REACT_APP_STRIPE_PROMO_MONTHLY_20_POUND;
        break;
      case 'RUB':
        stripePromoPriceId = process.env.REACT_APP_STRIPE_PROMO_MONTHLY_20_RUB;
        break;
      case 'AUD':
        stripePromoPriceId = process.env.REACT_APP_STRIPE_PROMO_MONTHLY_20_ASD;
        break;
      default:
        stripePromoPriceId = process.env.REACT_APP_STRIPE_PROMO_MONTHLY_20_USD;
    }
  } else if (promoId === PROMO_YEARLY_20) {
    switch (currency) {
      case 'USD':
        stripePromoPriceId = process.env.REACT_APP_STRIPE_PROMO_YEARLY_20_USD;
        break;
      case 'EUR':
        stripePromoPriceId = process.env.REACT_APP_STRIPE_PROMO_YEARLY_20_EUR;
        break;
      case 'THB':
        stripePromoPriceId = process.env.REACT_APP_STRIPE_PROMO_YEARLY_20_THB;
        break;
      case 'GBP':
        stripePromoPriceId = process.env.REACT_APP_STRIPE_PROMO_YEARLY_20_POUND;
        break;
      case 'RUB':
        stripePromoPriceId = process.env.REACT_APP_STRIPE_PROMO_YEARLY_20_RUB;
        break;
      case 'AUD':
        stripePromoPriceId = process.env.REACT_APP_STRIPE_PROMO_YEARLY_20_ASD;
        break;
      default:
        stripePromoPriceId = process.env.REACT_APP_STRIPE_PROMO_YEARLY_20_USD;
    }
  } else {
    switch (currency) {
      case 'USD':
        stripePromoPriceId = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_20_USD;
        break;
      case 'EUR':
        stripePromoPriceId = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_20_EUR;
        break;
      case 'THB':
        stripePromoPriceId = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_20_THB;
        break;
      case 'GBP':
        stripePromoPriceId =
          process.env.REACT_APP_STRIPE_PROMO_LIFETIME_20_POUND;
        break;
      case 'RUB':
        stripePromoPriceId = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_20_RUB;
        break;
      case 'AUD':
        stripePromoPriceId = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_20_ASD;
        break;
      default:
        stripePromoPriceId = process.env.REACT_APP_STRIPE_PROMO_LIFETIME_20_USD;
    }
  }

  return stripePromoPriceId;
};
