/* @flow */
import { TOGGLE_IS_UNITS_PROGRESS_UPDATED } from '../../lessons/constants';
type State = {};

const INITIAL_STATE = {
  isFetchProgressFinished: false
};

/* Methods Start */

const toggleisUnitsProgressUpdated = (state: State) => {
  return { ...state, isFetchProgressFinished: true };
};

/* Methods End */

export default (state: State = INITIAL_STATE, action: Object) => {
  switch (action.type) {
    case TOGGLE_IS_UNITS_PROGRESS_UPDATED:
      return toggleisUnitsProgressUpdated(state);
    default:
      return state;
  }
};
