/* @flow */
import { connect } from 'react-redux';
import { CompleteScreen } from '../components/main-components';
import { checkScore } from '../util';
import {
  getLabelWithString,
  getLabels,
  getLabel,
  getLabelWithParameters
} from '../../util';

export const mapStateToProps = (state: Object, ownProps: Object) => {
  const { routes, storage } = state;
  const {
    isExam,
    isRandomTest,
    gameWrongAnswered,
    isSpeaking,
    isWriting,
    gameSkipCount
  } = routes;
  const { isShowTutorial } = storage;
  const { isTutorial } = ownProps;
  const isChatbot = state.chatbots.isChatbot;
  const completed = 'complete_txt_completed';
  const promo_txt_congrats = 'promo_txt_congrats';
  const exam = 'modal_txt_exam';
  const lesson = 'modal_txt_lesson';
  const showCompleteLabel = getLabelWithString(
    state,
    completed,
    isExam || isRandomTest ? exam : isShowTutorial ? lesson : promo_txt_congrats
  );
  const inviteMessage = getLabelWithParameters(
    state,
    'invite_txt_share',
    'https://ling-app.com/',
    'Hey! I’m learning a language with Ling. It’s awesome & fun! Learn with me %d'
  );
  let score = 0;
  if (isTutorial) {
    score = 100;
  } else if (isRandomTest) {
    score = 10 - gameWrongAnswered;
  } else {
    score = checkScore(gameWrongAnswered);
  }
  return {
    ...ownProps,
    inviteMessage,
    isSpeaking,
    isWriting,
    gameSkipCount,
    score: score,
    showCompleteLabel,
    isChatbot,
    isShowTutorial,
    promo_txt_congrats: getLabel(state, 'promo_txt_congrats', 'congratulation'),
    btn_replay: getLabel(state, 'btn_replay', 'replay'),
    game_txt_you_got: getLabel(state, 'game_txt_you_got', "you've got"),
    game_txt_keep_up_good_work: getLabel(
      state,
      'game_txt_keep_up_good_work',
      'keep up the good work!'
    ),
    game_txt_and_1_coin: getLabel(state, 'game_txt_and_1_coin', 'and 1 Coin.'),
    txt_result_tutorial: getLabel(
      state,
      'txt_result_tutorial',
      'Trial lesson completed'
    ),
    tutorial_tooltips_btn: getLabel(state, 'getLabels', 'Keep going'),
    complete_screen_share_txt: getLabel(
      state,
      'complete_screen_share_txt',
      'share to'
    ),
    chatbot_txt_complete_title: getLabel(
      state,
      'chatbot_txt_complete_title',
      'Awesome!'
    ),
    ...getLabels(
      state,
      'landing_txt_connectInternet',
      'btn_continue',
      'chatbot_txt_complete_body',
      'invite_friend_text_invite'
    )
  };
};

export default connect(mapStateToProps)(CompleteScreen);
