import React, { useState } from 'react';
import { TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@material-ui/styles';
import {
  MARIGOLD,
  VERY_LIGHT_PINK,
  VERY_LIGHT_PINK_TWO
} from '../../../common/constants';
import styled from 'styled-components';

// TODO: Remove this and use global theme
const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: MARIGOLD
      }
    },

    MuiPickersDay: {
      day: {
        color: MARIGOLD
      },
      daySelected: {
        backgroundColor: MARIGOLD
      },
      dayDisabled: {
        color: MARIGOLD
      },
      current: {
        color: MARIGOLD
      }
    },
    MuiPickersModal: {
      dialogAction: {
        color: MARIGOLD
      }
    },
    MuiInput: {
      underline: {
        '&&:hover:not(.Mui-disabled):before': {
          borderBottomWidth: 0
        },
        '&&:hover': {
          borderBottomWidth: 0
        },
        '&&:before': {
          borderBottomWidth: 0
        },
        '&&:after': {
          borderBottomWidth: 0
        }
      }
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: MARIGOLD
      }
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: MARIGOLD
      },
      noPoint: {
        backgroundColor: MARIGOLD
      },
      thumb: {
        border: '14px solid' + MARIGOLD
      }
    },

    MuiPickersClockNumber: {
      clockNumberSelected: {
        backgroundColor: MARIGOLD
      }
    },
    MuiButton: {
      label: {
        color: MARIGOLD
      }
    },
    MuiFormControl: { root: { width: '110px' } },
    MuiInputBase: {
      root: {
        color: 'unset'
      },
      input: {
        paddingRight: 20,
        borderRadius: 10,
        boxShadow: `inset 0 -2px 0 0 ${VERY_LIGHT_PINK}`,
        borderStyle: 'solid',
        borderColor: VERY_LIGHT_PINK_TWO,
        borderWidth: 2,
        textAlign: 'center',
        cursor: 'pointer'
      }
    }
  }
});
const TimePickerContainer = styled.div`
  position: relative;
`;

const ClockStyle = styled.img`
  position: absolute;
  right: 6px;
  top: 6px;
`;

const greyClock = require('../../../img/icons/outline-gray-clock-21-px.png');
function TimePickerClass(props) {
  const initTime = new Date();
  const initValue = props.defaultValue.split(':');
  initTime.setHours(initValue[0]);
  initTime.setMinutes(initValue[1]);
  const [selectedDate, handleDateChange] = useState(new Date(initTime));
  const setTime = value => {
    props.handleTimeChange(value);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={materialTheme}>
        <TimePickerContainer>
          <TimePicker
            minutesStep={30}
            initialFocusedDate={new Date(initTime)}
            onAccept={selectedDate =>
              setTime(
                ('0' + new Date(selectedDate).getHours()).slice(-2) +
                  ':' +
                  ('0' + new Date(selectedDate).getMinutes()).slice(-2)
              )
            }
            value={selectedDate}
            onChange={handleDateChange}
          />
          <ClockStyle src={greyClock} />
        </TimePickerContainer>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default TimePickerClass;
