/* @flow */
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import './ProfileScene.css';
import DefaultHeaderContainer from '../../../common/containers/DefaultHeaderContainer';
import { getCurrency, findCurrency } from '../../../util';
import { CheckoutForm } from '../../../shop/components/main-components/CheckoutForm/CheckoutForm';
import {
  getStripeProductId,
  getUserSubscriptionDataDetail,
  getStripeYearlyPrice,
  createPaymentMethodAndCustomerShop,
  getUserProperty
} from '../../../util';
import { ProfileInfoSection } from '../sub-components/ProfileInfoSection/ProfileInfoSection';
import styled from 'styled-components';
import { IMAGE_PATH, DEFAULT_YEARLY_PRICE } from '../../../common/constants';
import { PRODUCT_TYPE } from '../../../shop/constants';

import Analytic from '../../../util/Analytic';
import { getPriceNumber, getUserSubscriptionText } from '../../../util';
import { PurchaseLoginModal } from '../../../shop/components/main-components/PurchaseLoginModal';
import { SetDailyGoalModal } from '../../../shop/components/main-components/SetDailyGoalModal';

const SpinnerContainer = styled.div`
  display: ${props => (props.isUpdateMethod ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  height: calc(100vh - 225px);
  width: 431px;
  margin: auto;
`;

const lingLoading = `${IMAGE_PATH}ling-loader.gif`;

type Props = {
  goal: Object,
  userInfo: Object,
  bananas: number,
  coins: number,
  fluency: number,
  promo_txt_fluency: string,
  profile_txt_currentStreak: string,
  profile_txt_longestStreak: string,
  profile_txt_dayStreak: string,
  currentStreak: number,
  longestStreak: number,
  profile_txt_leaderboard: string,
  profile_txt_todayAchieve: string,
  levelChamp: number,
  levelSpender: number,
  levelDiligent: number,
  levelAchiever: number,
  levelWealthy: number,
  dailyBananas: number,
  weeklyGraphData: number[],
  achievement_txt_diligentMaster: string,
  diligentDescription: string,
  achievement_txt_bigSpender: string,
  spenderDescription: string,
  achievement_txt_champion: string,
  championDescription: string,
  profile_overview_txt: string,
  profile_set_your_goal_txt: string,
  achievement_txt_wealthy: string,
  wealthyDescription: string,
  achievement_txt_overAchiever: string,
  overAchieveDescription: string,
  profile_txt_todayAchieve: string,
  seeMore: string,
  profile_txt_manage_subcription: string,
  triggerFetchLeaderboard: Function,
  updateUserData: Function,
  setTrialUsedIP: Function,
  history: Object,
  user: Object,
  isDev: boolean,
  trial_date_description_txt: string,
  my_daily_goal_txt: string,
  yearly: string,
  yearly_eur: string,
  yearly_pound: string,
  yearly_thb: string,
  yearly_rub: string,
  yearly_asd: string,
  yearly_without_trial: string,
  yearly_eur_without_trial: string,
  yearly_pound_without_trial: string,
  yearly_thb_without_trial: string,
  yearly_asd_without_trial: string,
  yearly_rub_without_trial: string,
  email_error_empty: string,
  email_error_wrongFormat: string,
  login_field_email: string,
  random_test_modal_back_txt: string,
  profile_txt_name_card: string,
  profile_txt_card_number: string,
  profile_txt_expire_date: string,
  profile_txt_recurring_billing: string,
  profile_txt_terms_condition: string,
  btn_submit: string,
  shop_txt_7_day_trial: string,
  profile_txt_shop_promo: string,
  purchase_txt_benefit_details_01: string,
  profile_txt_follow_us: string,
  functions: string,
  achievement_txt_title: string,
  profile_txt_viewAll: string,
  profile_info_txt: string,
  card_error_empty: string,
  shop_txt_checkout_title_yearly: string,
  shop_txt_checkout_title_top: string,
  card_error_number_not_correct: string,
  card_error_invalid_date: string,
  card_error_invalid_cvc: string,
  login_field_name: string,
  card_number: string,
  card_expiration: string,
  userRank: number,
  isInviteFriPromo: boolean,
  loginPurchase_txt: string,
  createAccount_txt_title: string,
  purchase_button_subscribe_now: string,
  setDailyGoal: Function,
  updateUserProfile: Function,
  edit_profile_save_txt: string,
  isProUser: boolean,
  saveDailyGoal: Function,
  profile_txt_on: string,
  profile_txt_past_due_msg: string,
  profile_txt_charged: string,
  profile_txt_1_year_ling_pro: string,
  profile_txt_1_month_ling_pro: string,
  profile_txt_for: string,
  profile_txt_of: string
};

type State = {
  bannerVisible: boolean,
  subscriptionData: any,
  usdYearPrice: number,
  yearPrice: number,
  currencySymbol: Object,
  currency: string,
  activeTab: string,
  isLoginPurchase: boolean,
  itemInfo: Array<Object>,
  productName: string,
  productId: string,
  isCheckout: boolean,
  isUpdatingCard: boolean,
  isOpenSetGoalModal: boolean,
  dailyGoalSelected: null | number
};

export default class ProfileScene extends Component<Props, State> {
  child: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      bannerVisible: true,
      subscriptionData: {},
      yearPrice: DEFAULT_YEARLY_PRICE,
      usdYearPrice: DEFAULT_YEARLY_PRICE,
      currencySymbol: {},
      currency: '',
      activeTab: '1',
      isLoginPurchase: false,
      itemInfo: [],
      productName: '',
      productId: '',
      isCheckout: false,
      isUpdatingCard: false,
      isOpenSetGoalModal: false,
      dailyGoalSelected: 0
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      user,
      functions,
      updateUserData,
      triggerFetchLeaderboard
    } = this.props;
    const language = window.navigator.language;
    const currency = getCurrency(language)
      ? getCurrency(language).toUpperCase()
      : 'USD';
    this.setState({
      currency: getCurrency(language),
      currencySymbol: findCurrency((currency ? currency : 'usd').toUpperCase())
    });
    getUserSubscriptionDataDetail(
      user,
      functions,
      updateUserData,
      this.stateChangeHandler
    );
    triggerFetchLeaderboard();
    updateUserData();
    getStripeYearlyPrice(functions, currency, user, this.stateChangeHandler);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.userInfo.name !== prevProps.userInfo.name) {
      this.props.triggerFetchLeaderboard();
    }
  }
  stateChangeHandler = (name: string, value: any) => {
    this.setState({
      [name]: value
    });
  };
  toggleLoginPurchase = () => {
    this.setState({
      isLoginPurchase: !this.state.isLoginPurchase
    });
  };

  toggleSetDailyGoalModal = () => {
    this.setState({
      dailyGoalSelected: this.props.goal.userDailyGoal,
      isOpenSetGoalModal: !this.state.isOpenSetGoalModal
    });
  };

  saveDailyGoal = () => {
    const { saveDailyGoal } = this.props;
    const { dailyGoalSelected, isOpenSetGoalModal } = this.state;
    saveDailyGoal({
      isSetDailyGoal: true,
      userDailyGoal: dailyGoalSelected
    });
    this.setState({
      isOpenSetGoalModal: !isOpenSetGoalModal
    });
  };

  onSelectedSetGoal = (goal: number) => {
    this.setState({
      dailyGoalSelected: goal
    });
  };

  toggleCheckout = () => {
    this.toggleIsCheckout();
  };
  toggleBanner = () => {
    this.setState({ bannerVisible: false });
  };

  onBuy = (productName: string) => {
    const { user } = this.props;
    const { currency } = this.state;
    let userEmail = getUserProperty(user, 'email');
    let productId = getStripeProductId(currency, PRODUCT_TYPE.yearly, user);
    Analytic.log(Analytic.key.A4, {
      category: Analytic.key.A4,
      action: 'Click',
      label: productName
    });
    Analytic.logGA(Analytic.key.A4, {
      category: Analytic.key.A4,
      action: 'Click',
      label: productName
    });
    this.setState({
      productId: productId,
      productName: productName
    });
    if (_.isEmpty(userEmail)) {
      this.toggleLoginPurchase();
    } else {
      this.toggleIsCheckout();
    }
  };
  toggleIsCheckout = () => {
    const { isProUser, history } = this.props;
    window.scrollTo(0, 0);
    if (isProUser) {
      history.push('/');
    } else {
      this.setState({ isCheckout: !this.state.isCheckout });
    }
  };

  createPaymentMethodAndCustomer = async (cardNumber: Object) => {
    const { user, isDev, history, functions } = this.props;
    const {
      isUpdatingCard,
      currency,
      productId,
      isLoginPurchase,
      productName
    } = this.state;
    createPaymentMethodAndCustomerShop(
      cardNumber,
      user,
      isDev,
      history,
      currency,
      productId,
      isUpdatingCard,
      isLoginPurchase,
      productName,
      functions,
      this.stateChangeHandler
    );
  };

  toggleCreateAccount = () => {
    const {
      productId,
      currencySymbol,
      usdYearPrice,
      yearPrice,
      productName
    } = this.state;
    this.toggleLoginPurchase();
    this.child.toggleCreateAccount(
      productId,
      productName,
      (yearPrice !== 0 ? yearPrice : usdYearPrice) / 100,
      currencySymbol.code
    );
  };

  render() {
    const {
      isProUser,
      userInfo,
      bananas,
      coins,
      fluency,
      promo_txt_fluency,
      profile_txt_currentStreak,
      profile_txt_longestStreak,
      profile_txt_dayStreak,
      longestStreak,
      currentStreak,
      profile_txt_leaderboard,
      profile_txt_todayAchieve,
      levelChamp,
      levelSpender,
      levelDiligent,
      levelAchiever,
      levelWealthy,
      achievement_txt_diligentMaster,
      diligentDescription,
      achievement_txt_bigSpender,
      spenderDescription,
      achievement_txt_champion,
      championDescription,
      achievement_txt_wealthy,
      wealthyDescription,
      achievement_txt_overAchiever,
      overAchieveDescription,
      profile_info_txt,
      achievement_txt_title,
      my_daily_goal_txt,
      random_test_modal_back_txt,
      profile_txt_terms_condition,
      purchase_button_subscribe_now,
      btn_submit,
      profile_txt_name_card,
      profile_txt_card_number,
      profile_txt_expire_date,
      profile_txt_recurring_billing,
      profile_txt_viewAll,
      userRank,
      shop_txt_7_day_trial,
      history,
      purchase_txt_benefit_details_01,
      profile_txt_manage_subcription,
      weeklyGraphData,
      profile_txt_follow_us,
      loginPurchase_txt,
      createAccount_txt_title,
      card_error_empty,
      card_error_number_not_correct,
      card_error_invalid_date,
      card_error_invalid_cvc,
      login_field_name,
      card_number,
      card_expiration,
      user,
      functions,
      shop_txt_checkout_title_top,
      shop_txt_checkout_title_yearly,
      email_error_empty,
      email_error_wrongFormat,
      login_field_email,
      edit_profile_save_txt,
      trial_date_description_txt,
      setTrialUsedIP,
      profile_overview_txt,
      profile_set_your_goal_txt,
      dailyBananas,
      goal,
      profile_txt_on,
      profile_txt_past_due_msg,
      profile_txt_charged,
      profile_txt_1_year_ling_pro,
      profile_txt_1_month_ling_pro,
      profile_txt_for,
      profile_txt_of
    } = this.props;
    const {
      subscriptionData,
      bannerVisible,
      activeTab,
      currency,
      isCheckout,
      isUpdatingCard,
      usdYearPrice,
      yearPrice,
      currencySymbol
    } = this.state;

    return (
      <Fragment>
        <DefaultHeaderContainer
          isSrollable={false}
          history={history}
          onRef={ref => (this.child = ref)}
          toggleCheckout={this.toggleIsCheckout}
          toggleCreateAccount
        />
        {isCheckout ? (
          <Fragment>
            {isUpdatingCard ? (
              <SpinnerContainer>
                <img src={lingLoading} alt={lingLoading} />
              </SpinnerContainer>
            ) : (
              <CheckoutForm
                trial_date_description_txt={trial_date_description_txt}
                email_error_empty={email_error_empty}
                email_error_wrongFormat={email_error_wrongFormat}
                login_field_email={login_field_email}
                packageName={'yearly'}
                isClaim={false}
                login_field_name={login_field_name}
                card_number={card_number}
                card_expiration={card_expiration}
                card_error_empty={card_error_empty}
                card_error_number_not_correct={card_error_number_not_correct}
                card_error_invalid_date={card_error_invalid_date}
                card_error_invalid_cvc={card_error_invalid_cvc}
                functions={functions}
                shop_txt_checkout_price={getPriceNumber(
                  yearPrice,
                  usdYearPrice,
                  currencySymbol
                )}
                toggleIsCheckout={this.toggleIsCheckout}
                shop_txt_checkout_title={shop_txt_checkout_title_yearly}
                shop_txt_checkout_title_top={shop_txt_checkout_title_top}
                random_test_modal_back_txt={random_test_modal_back_txt}
                profile_txt_name_card={profile_txt_name_card}
                profile_txt_card_number={profile_txt_card_number}
                profile_txt_expire_date={profile_txt_expire_date}
                profile_txt_recurring_billing={profile_txt_recurring_billing}
                createPaymentMethodAndCustomer={
                  this.createPaymentMethodAndCustomer
                }
                profile_txt_terms_condition={profile_txt_terms_condition}
                btn_submit={btn_submit}
                setTrialUsedIP={setTrialUsedIP}
              />
            )}
          </Fragment>
        ) : (
          <ProfileInfoSection
            goal={goal}
            toggleSetDailyGoalModal={this.toggleSetDailyGoalModal}
            weeklyGraphData={weeklyGraphData}
            dailyBananas={dailyBananas}
            profile_overview_txt={profile_overview_txt}
            profile_set_your_goal_txt={profile_set_your_goal_txt}
            achievement_txt_bigSpender={achievement_txt_bigSpender}
            achievement_txt_champion={achievement_txt_champion}
            achievement_txt_diligentMaster={achievement_txt_diligentMaster}
            achievement_txt_overAchiever={achievement_txt_overAchiever}
            achievement_txt_title={achievement_txt_title}
            achievement_txt_wealthy={achievement_txt_wealthy}
            activeTab={activeTab}
            bananas={bananas}
            bannerVisible={bannerVisible}
            championDescription={championDescription}
            currentStreak={currentStreak}
            diligentDescription={diligentDescription}
            fluency={fluency}
            history={history}
            isProUser={isProUser}
            levelAchiever={levelAchiever}
            levelChamp={levelChamp}
            levelDiligent={levelDiligent}
            levelSpender={levelSpender}
            levelWealthy={levelWealthy}
            longestStreak={longestStreak}
            onBuy={this.onBuy}
            overAchieveDescription={overAchieveDescription}
            planData={getUserSubscriptionText(
              user,
              subscriptionData,
              currency,
              profile_txt_on,
              profile_txt_charged,
              profile_txt_for,
              profile_txt_of,
              profile_txt_1_year_ling_pro,
              profile_txt_1_month_ling_pro,
              profile_txt_past_due_msg
            )}
            profile_info_txt={profile_info_txt}
            profile_txt_currentStreak={profile_txt_currentStreak}
            profile_txt_dayStreak={profile_txt_dayStreak}
            profile_txt_follow_us={profile_txt_follow_us}
            profile_txt_leaderboard={profile_txt_leaderboard}
            profile_txt_longestStreak={profile_txt_longestStreak}
            profile_txt_manage_subcription={profile_txt_manage_subcription}
            profile_txt_todayAchieve={profile_txt_todayAchieve}
            profile_txt_viewAll={profile_txt_viewAll}
            promo_txt_fluency={promo_txt_fluency}
            purchase_button_subscribe_now={purchase_button_subscribe_now}
            purchase_txt_benefit_details_01={purchase_txt_benefit_details_01}
            shop_txt_7_day_trial={shop_txt_7_day_trial}
            spenderDescription={spenderDescription}
            coins={coins}
            subscriptionData={subscriptionData}
            toggleBanner={() => {
              this.setState({ bannerVisible: false });
            }}
            toggleTab={(tab: string) => {
              if (this.state.activeTab !== tab) {
                this.setState({ activeTab: tab });
              }
            }}
            user={user}
            userInfo={userInfo}
            userRank={userRank}
            wealthyDescription={wealthyDescription}
          />
        )}
        <SetDailyGoalModal
          edit_profile_save_txt={edit_profile_save_txt}
          my_daily_goal_txt={my_daily_goal_txt}
          saveDailyGoal={this.saveDailyGoal}
          toggle={() => this.toggleSetDailyGoalModal()}
          isOpenSetGoalModal={this.state.isOpenSetGoalModal}
          dailyGoalSelected={this.state.dailyGoalSelected}
          onSelectedSetGoal={this.onSelectedSetGoal}
        />
        <PurchaseLoginModal
          createAccount_txt_title={createAccount_txt_title}
          loginPurchase_txt={loginPurchase_txt}
          isLoginPurchase={this.state.isLoginPurchase}
          manageSceneStateChangeHandler={this.stateChangeHandler}
          toggleCreateAccount={this.toggleCreateAccount}
        />
      </Fragment>
    );
  }
}
