/* @flow */
import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import platform from 'platform';
import {
  PREFIX_LESSON,
  PREFIX_HEADER,
  WHITE,
  GREY,
  GREY2,
  MARIGOLD,
  SEA_BLUE,
  BLACK,
  DARK_YELLOW,
  LIGHT_GREY_2
} from '../../../common/constants';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Firebase from '../../../util/Firebase';
import { getLanguageLabel } from '../../../util';
import { getNameAndEmail } from '../../../profile/selectors';

const ModalBodyText = styled.div`
  margin: 0 20px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalCloseButton = styled.div`
  cursor: pointer;
`;

const ModalBodyContainer = styled.div`
  display: 'block';
  position: relative;
`;

const ContinueStyle = styled.button`
  text-transform: uppercase;
  margin-top: 57px;
  margin-bottom: 100px;
  border-radius: 20px;
  box-shadow: inset 0 -4px 0 0 ${DARK_YELLOW};
  background: ${MARIGOLD};
  border: ${DARK_YELLOW} 1px solid;
  font-size: 14px;
  width: 157px;
  height: 55px;
  padding: 0;
  color: ${WHITE};
  bottom: -45px;
  right: 18px;
  &:disabled {
    background: ${LIGHT_GREY_2};
    box-shadow: inset 0 -4px 0 0 ${GREY2};
    border: ${GREY2} 1px solid;
  }
  &:hover:enabled {
    opacity: 0.4;
    box-shadow: none;
  }
  &:active:enabled {
    opacity: 1;
    background-color: ${DARK_YELLOW};
    box-shadow: none;
  }
`;

const GrammarHelpContainer = styled.div`
  width: 227px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const CommentImg = styled.img`
  width: 75px;
  height: 75px;
`;
const HelpGrammarText = styled.p`
  font-size: 16px;
  line-height: 20px;
`;

const styles = {
  modalContainer: {
    textAlign: 'center',
    borderRadius: 30,
    marginTop: 0,
    marginBottom: 0,
    height: '100%',
    width: 655,
    maxWidth: 655,
    minWidth: 655,
    transition: 'none'
  },
  modalBodyContainer: {
    padding: 0,
    paddingBottom: 36
  },
  buttonStyle: {
    borderRadius: 10,
    width: 160,
    height: 55,
    background: WHITE,
    border: 'solid 1px',
    borderColor: GREY2,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    boxShadowColor: GREY2,
    fontWeight: 'bold',
    fontSize: 18
  },
  cancelButtonStyle: {
    border: 'none',
    boxShadow: 'none',
    width: 100
  },
  confirmButtonStyle: {
    borderColor: GREY2,
    background: WHITE,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0'
  },
  modalHeader: {
    borderBottom: 'none',
    flexDirection: 'row-reverse',
    justifyContent: 'normal',
    padding: 0,
    height: 0
  },
  modalBody: {
    padding: 0
  },
  modalFooter: {
    borderTop: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 36,
    paddingRight: 32,
    paddingLeft: 32
  },
  contentContainer: {
    borderRadius: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    minHeight: '100%',
    height: 'auto',
    maxHeight: '100%',
    overflow: 'scroll',
    padding: '0 84px',
    border: 0,
    boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.05)'
  },
  modalHeaderContainer: {
    'modal-title': 'w-100 text-center'
  },
  inputGroup: {
    textAlign: 'left',
    borderColor: GREY,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 20
  },
  inputGroupResetPassword: {
    margin: '76px 35px 55px'
  },

  inputContainer: {
    padding: '10px 15px 15px',
    width: 'inherit',
    fontSize: 14
  },
  labelStyle: {
    fontWeight: 'bold',
    marginBottom: 12,
    textTransform: 'capitalize'
  },
  forgotTextStyle: {
    fontSize: 14,
    textAlign: 'left',
    paddingLeft: 14,
    fontWeight: 'bold',
    display: 'flex',
    paddingTop: 10
  },
  otherLoginButton: {
    borderRadius: 22,
    padding: 0,
    width: 112,
    height: 42,
    background: WHITE,
    color: BLACK,
    border: 'gray solid 1px',
    borderColor: GREY,
    borderWidth: 1,
    borderStyle: 'solid',
    margin: '0 6px',
    marginTop: 5
  },
  fbIconStyle: {
    marginLeft: 0
  },
  googleIconStyle: {
    marginRight: 0
  },
  otherLoginGroup: {
    marginBottom: 25
  },
  signUpSuggestionStyle: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  signUpStyle: {
    color: SEA_BLUE,
    cursor: 'pointer'
  },
  space: {
    height: 12
  },
  loginFormSpaceBottom: {
    paddingBottom: 4
  },
  loadingStyle: {
    margin: '134px 38px',
    color: MARIGOLD
  }
};

const closeIcon = `${PREFIX_LESSON}basecolor-black.svg`;

type Props = {
  isOpen: boolean,
  toggleReport: Function,
  grammar_help_txt: string,
  nativeLangCode: string,
  targetLangCode: string,
  nativeLanguage: string,
  unitObject: Object,
  user: Object,
  userInfo: Object,
  random_test_modal_confirm_txt: string
};

type State = {
  modalState: boolean
};

const OutlineComment = `${PREFIX_HEADER}outline-comment-28-px@3x.png`;
class GrammarHelpTranslateModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalState: false
    };
  }

  toggleModal = () => {
    this.setState(prevState => ({ modalState: !prevState.modalState }));
  };

  onSubmit = () => {
    this.props.toggleReport();
    const linebreak = '%0D%0A';
    const { currentUser } = Firebase.auth();
    const {
      nativeLangCode,
      targetLangCode,
      nativeLanguage,
      unitObject,
      user,
      userInfo
    } = this.props;
    const { name: platformName, version, product, manufacturer, os } = platform;
    const { width, height } = window.screen;
    const subject = '[Ling] Grammar Translating Application';
    const body = `
    I'd like to apply to translate Grammar Content in Ling app in ${nativeLanguage} ${linebreak}
    System info: ${linebreak}
      Screen Size : ${width} x ${height} ${linebreak}
      Browser : ${platformName} ${linebreak}
      Versions: ${version} ${linebreak}
      Product : ${product} ${linebreak}
      Manufacturer : ${manufacturer} ${linebreak}
      OS : ${os} ${linebreak}
      nativeLangCode : ${nativeLangCode} ${linebreak}
      targetLangCode : ${targetLangCode} ${linebreak}
      UNIT : ${unitObject[0]} ${linebreak}
      LESSON : ${unitObject[1]} ${linebreak}
      User : ${
        currentUser.isAnonymous
          ? 'Anonymous'
          : `${linebreak}
        UID : ${user.uid} ${linebreak}
        name : ${userInfo.name} ${linebreak}`
      }${linebreak}
`;
    window.location.href = `mailto:support@simyasolutions.com?subject=${subject}&body=${body}`;
  };

  componentDidMount() {
    // $flow-disable-line
    document.addEventListener('keydown', this.handleEnter);
  }
  componentWillUnmount() {
    // $flow-disable-line
    document.removeEventListener('keydown', this.handleEnter);
  }
  handleEnter = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  };

  render() {
    const { modalContainer, modalHeader, modalBody } = styles;
    const {
      isOpen,
      toggleReport,
      grammar_help_txt,
      random_test_modal_confirm_txt
    } = this.props;
    const closeBtn = (
      <ModalCloseButton
        className="reportModalClose"
        onClick={() => toggleReport()}
      >
        <img src={closeIcon} alt={closeIcon} />
      </ModalCloseButton>
    );

    return (
      <Fragment>
        <Modal
          isOpen={isOpen}
          style={modalContainer}
          contentClassName="reportContentContainer"
          backdropClassName="reportModalBackdrop"
          centered
        >
          <Fragment>
            <ModalBodyContainer>
              <ModalHeader
                style={modalHeader}
                external={null}
                close={closeBtn}
              ></ModalHeader>
              <ModalBody style={modalBody}>
                <ModalBodyText>
                  <GrammarHelpContainer>
                    <CommentImg src={OutlineComment} />
                    <HelpGrammarText>{grammar_help_txt}</HelpGrammarText>
                    <ContinueStyle onClick={this.onSubmit}>
                      {random_test_modal_confirm_txt}
                    </ContinueStyle>
                  </GrammarHelpContainer>
                </ModalBodyText>
              </ModalBody>
            </ModalBodyContainer>
          </Fragment>
        </Modal>
      </Fragment>
    );
  }
}

export const mapStateToProps = (state: Object) => {
  const { data, game, storage } = state;
  const { user } = storage;
  const { nativeLangCode, targetLangCode } = data;
  const unitObject = game.key.split(',');
  const nativeLanguage = getLanguageLabel(state, nativeLangCode);

  return {
    nativeLangCode,
    targetLangCode,
    nativeLanguage,
    unitObject,
    user,
    userInfo: getNameAndEmail(storage)
  };
};

export default connect(mapStateToProps)(GrammarHelpTranslateModal);
