/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import { Spelling } from '../components/scenes';
import * as actions from '../actions';
import { getTopCharacterCards, getCharacterCards } from '../util';
import { getLabels, onPlaySound } from '../../util';
import { getSpelling } from '../reducers';
import { getTranscriptionState, getUserCoins } from '../../profile/selectors';
import { getVoiceSpeed } from '../../chatbots/selectors';

const SpellingContainer = (props: Object) => (
  <Spelling
    {...props}
    getTopCharacterCards={getTopCharacterCards}
    getCharacterCards={getCharacterCards}
    onPlaySound={onPlaySound}
  />
);

export const mapStateToProps = (state: Object) => {
  const {
    courses: {
      nativeLanguageFeatures: { rtlLanguage: nativeRtlLanguage }
    },

    storage
  } = state;
  const { isShowTutorial, isTargetLangSymbol } = storage;
  const coins = getUserCoins(state);
  const voiceSpeed = getVoiceSpeed(state);

  return {
    coins,
    ...getSpelling(state, true),
    nativeRtlLanguage,
    isShowTutorial,
    isTargetLangSymbol,

    transcriptState: getTranscriptionState(state),
    ...getLabels(
      state,
      'btn_hint',
      'title_txt_spelling',
      'error_txt_game_wrong',
      'btn_check',
      'btn_continue'
    ),
    voiceSpeed
  };
};

export { SpellingContainer };
export default connect(mapStateToProps, actions)(SpellingContainer);
