/* @flow */
import _ from 'lodash';
import fp from 'lodash/fp';
import {
  PRESS_CHARACTER_CARD,
  SPEND_HINT,
  MOVE_TO_LOCKED_IDS,
  CHECK_SPELLING_RESULT,
  CHECK_CORRECT_CARD,
  TOGGLE_GAME_TRANSCRIPT,
  BUTTON_CONTINUE,
  BUTTON_CHECK,
  BUTTON_CHECK_DISABLED,
  ORDER_SENTENCE,
  ORDER_SENTENCE_REVERSE,
  DIALOGUE,
  spellingStatus,
  REFRESH_GAME,
  CLOSE_GAME,
  LOAD_STOP_WORDS,
  BUTTON_CONTINUE_DISABLED,
  SPELLING,
  IS_WRONG
} from '../constants';
import { isSpellingGame } from '../util/GameUtils';
import bugTracker from '../../util/BugTracker';

type Character = {
  character: string,
  status: string
};

type ById = {
  [charId: ?string | ?number]: Character
};

type AllIds = Array<?string | ?number>;

export type State = {
  byId: ById,
  allIds: AllIds,
  wordId?: string,
  imageSource?: string,
  pressedIds?: (?any)[],
  notYetPressedIds?: (?any)[],
  lockedIds?: (?number)[],
  additionalWords: number[],
  isResultShown?: boolean,
  answeredWrong?: boolean,
  hintMode?: boolean,
  stopWords: string[],
  globalStopWords: string[],
  isSpacedLang?: boolean,
  gameType: string
};

const INITIAL_STATE: State = {
  byId: {},
  allIds: [],
  wordId: '',
  imageSource: '',
  pressedIds: [],
  notYetPressedIds: [],
  lockedIds: [],
  additionalWords: [],
  isResultShown: false,
  answeredWrong: false,
  hintMode: false,
  stopWords: [],
  globalStopWords: [],
  isSpacedLang: false,
  gameType: ''
};

const loadSpelling = (
  state: State,
  {
    wordId,
    characters,
    order,
    imageSource,
    pressedIds,
    additionalWords,
    gameType,
    features
  }: Object
): State => {
  const nullArray = _.map(order, id => null);
  const notYetPressedIdsTail = _.difference(order, pressedIds);
  const notYetPressedIds = _.concat(
    notYetPressedIdsTail,
    _.slice(nullArray, notYetPressedIdsTail.length)
  );

  return {
    byId: getById(characters),
    allIds: order,
    pressedIds: pressedIds ? pressedIds : nullArray,
    notYetPressedIds,
    lockedIds: pressedIds ? pressedIds : [],
    additionalWords: additionalWords ? additionalWords : [],
    wordId,
    imageSource,
    isResultShown: false,
    answeredWrong: false,
    hintMode: false,
    globalStopWords: state.globalStopWords,
    stopWords: gameType === ORDER_SENTENCE_REVERSE ? state.globalStopWords : [],
    isSpacedLang: features.isSpacedLang,
    gameType: gameType,
    rtlLanguage: features.rtlLanguage
  };
};

const getById = (chars: string[]): ById => {
  return chars.reduce(
    (byId, character, i) => ({
      ...byId,
      [i + 1]: {
        character,
        status: spellingStatus.VISIBLE
      }
    }),
    {}
  );
};

const pressCharacterCard = (state: State, charId: string): State => {
  const { byId, pressedIds, notYetPressedIds } = state;
  const newState = { ...state };
  newState.pressedIds = moveCard(pressedIds, charId);
  newState.notYetPressedIds = moveCard(notYetPressedIds, charId);
  let isValid = {};
  if (charId) {
    isValid = {
      ...newState,
      byId: {
        ...byId,
        [charId]: {
          ...byId[charId],
          status: spellingStatus.VISIBLE
        }
      }
    };
  } else {
    isValid = { ...state };
  }
  return isValid;
};

const moveCharacterCard = (state: State, charId: string): State => {
  const { byId, pressedIds, notYetPressedIds } = state;
  const newState = { ...state };

  newState.pressedIds = moveCardIndex(pressedIds, charId);
  newState.lockedIds = newState.pressedIds;
  newState.notYetPressedIds = moveCardIndex(notYetPressedIds, charId);

  let isValid = {};
  if (Number(charId) >= 0) {
    isValid = {
      ...newState,
      byId: {
        ...byId,
        [charId + 1]: {
          ...byId[charId + 1],
          status: spellingStatus.VISIBLE
        }
      }
    };
    // isValid = { ...state };
  } else {
    isValid = { ...state };
  }
  return isValid;
};

const checkSpellingResult = state => {
  const { additionalWords, stopWords, isSpacedLang, gameType } = state;
  let correctAnswer = getCorrectAnswer(
    state.byId,
    additionalWords,
    gameType,
    false,
    stopWords,
    isSpacedLang
  );
  let pressedIds = _.map(state.pressedIds, item => item);
  const pressedCardLength = _.countBy(pressedIds, x => x === null).false || 0;

  const digestedACorrectAnswerAfter = _.filter(correctAnswer, [IS_WRONG, true]);

  let digestedACorrectAnswerCount = 0;
  if (pressedCardLength === digestedACorrectAnswerAfter.length) {
    _.map(pressedIds, (pressedId, index) => {
      if (
        comparePressIdWithAnswer(
          pressedId,
          state,
          digestedACorrectAnswerAfter,
          index
        )
      ) {
        digestedACorrectAnswerCount++;
      }
    });
  }
  const byId = _.mapValues(state.byId, (card, charId) => {
    if (digestedACorrectAnswerCount !== digestedACorrectAnswerAfter.length) {
      if (isCardPressed(state, charId)) {
        return {
          ...card,
          status: coloredCard(state, charId)
        };
      }
    } else {
      return {
        ...card,
        status: spellingStatus.CORRECT
      };
    }
    return card;
  });
  correctAnswer = getAnswer(
    state,
    state.byId,
    additionalWords,
    gameType,
    false,
    stopWords,
    isSpacedLang
  );
  if (correctAnswer[0].character === 'undefined') {
    correctAnswer.splice(0, 1);
  }
  return {
    ...state,
    byId,
    isResultShown: true,
    answeredWrong: answeredWrong(byId, state),
    correctAnswer
  };
};

const answeredWrong = (
  byId,
  { allIds, additionalWords, pressedIds, stopWords }: Object
) => {
  const hasWrong = _.some(
    byId,
    ({ status }) => status === spellingStatus.WRONG
  );
  if (allIds && additionalWords && pressedIds) {
    const wrongIds = _.difference(
      _.difference(allIds, additionalWords),
      _.compact(pressedIds)
    );

    const isPressedWrong = !_.isEmpty(
      removeStopWords(byId, wrongIds, stopWords)
    );
    return hasWrong || isPressedWrong;
  }
  return false;
};

const checkCorrectCard = state => {
  const { additionalWords, stopWords, isSpacedLang, gameType } = state;
  const correctAnswer = getCorrectAnswer(
    state.byId,
    additionalWords,
    gameType,
    false,
    stopWords,
    isSpacedLang
  );
  let pressedIds = _.map(state.pressedIds, item => item);
  const pressedCardLength = _.countBy(pressedIds, x => x === null).false || 0;

  const digestedACorrectAnswerAfter = _.filter(correctAnswer, [IS_WRONG, true]);

  let digestedACorrectAnswerCount = 0;
  if (pressedCardLength === digestedACorrectAnswerAfter.length) {
    _.map(pressedIds, (pressedId, index) => {
      if (
        comparePressIdWithAnswer(
          pressedId,
          state,
          digestedACorrectAnswerAfter,
          index
        )
      ) {
        digestedACorrectAnswerCount++;
      }
    });
  }
  const byId = _.mapValues(state.byId, (card, charId) => {
    if (digestedACorrectAnswerCount !== digestedACorrectAnswerAfter.length) {
      if (isCardPressed(state, charId)) {
        return {
          ...card,
          status: spellingStatus.CORRECT
        };
      }
    } else {
      return {
        ...card,
        status: spellingStatus.CORRECT
      };
    }
    return card;
  });
  delete byId['0'];
  return {
    ...state,
    byId,
    correctAnswer
  };
};

const spendHint = (state, { game }: Object) => {
  const { additionalWords, stopWords, isSpacedLang, gameType } = state;
  const { words, sentences } = game;
  let content;
  if (gameType === SPELLING) {
    content = words;
  } else {
    content = sentences;
  }
  let data;

  if (gameType !== DIALOGUE) {
    const wordId = content.byId[state.wordId];
    const { transcript } = wordId;
    data = {
      transcript
    };
  }
  const transcript = data && data.transcript ? data.transcript : null;
  let pressedIds = _.map(state.pressedIds, item => item);
  if (gameType !== SPELLING) {
    shiftNullValue(state, pressedIds);
  }

  const pressedCardLength = _.countBy(pressedIds, x => x === null).false || 0;
  let notYetPressedIds = _.map(state.notYetPressedIds, item => item);
  const correctAnswer = getCorrectAnswer(
    state.byId,
    additionalWords,
    gameType,
    transcript,
    stopWords,
    isSpacedLang
  );
  const digestedACorrectAnswerAfter = _.filter(correctAnswer, [IS_WRONG, true]);

  let digestedACorrectAnswerCount = 0;
  if (pressedCardLength === digestedACorrectAnswerAfter.length) {
    _.map(pressedIds, (pressedId, index) => {
      if (
        comparePressIdWithAnswer(
          pressedId,
          state,
          digestedACorrectAnswerAfter,
          index
        )
      ) {
        digestedACorrectAnswerCount++;
      }
    });
  }

  let byId = {};
  if (digestedACorrectAnswerCount === digestedACorrectAnswerAfter.length) {
    byId = _.mapValues(state.byId, (card, charId) => {
      let status = card.status;

      return { ...card, status };
    });
  } else {
    byId = _.mapValues(state.byId, (card, charId) => {
      let status = card.status;
      if (isCardPressed(state, charId)) {
        if (!isCardCorrect(state, charId)) {
          pressedIds = moveCard(pressedIds, charId);
          notYetPressedIds = moveCard(notYetPressedIds, charId);
        }
      }
      return { ...card, status };
    });
  }

  if (correctAnswer[0].character === 'undefined') {
    correctAnswer.splice(0, 1);
  }
  return {
    ...state,
    byId,
    pressedIds,
    notYetPressedIds,
    correctAnswer,
    hintMode: true
  };
};

const shiftNullValue = (state, pressedIds) =>
  // $FlowFixMe
  _.forEach(state.pressedIds, function(pressedId, index) {
    if (pressedId === null) {
      pressedIds.push(pressedIds.splice(index, 1)[0]);
    }
  });

const comparePressIdWithAnswer = (
  pressedId,
  state,
  digestedACorrectAnswerAfter,
  index
) => {
  try {
    if (
      pressedId !== null &&
      state.byId[pressedId].character.replace(' ', '').toLowerCase() ===
        digestedACorrectAnswerAfter[index].character
          .replace(' ', '')
          .toLowerCase()
    ) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    bugTracker.notify(e);
  }
};
const getAnswer = (
  state: State,
  byId: ?Object,
  additionalWords: number[],
  gameType: string,
  transcript: ?boolean,
  stopWords: string[],
  isSpacedLang: ?boolean
) => {
  let pressedIds = _.map(state.pressedIds, item => item);
  if (gameType !== SPELLING) {
    shiftNullValue(state, pressedIds);
  }

  const correctAnswer = getCorrectAnswer(
    state.byId,
    additionalWords,
    gameType,
    transcript,
    stopWords,
    isSpacedLang
  );

  const digestedACorrectAnswer = _.filter(correctAnswer, [IS_WRONG, true]);

  const pressedCardLength = _.countBy(pressedIds, x => x === null).false || 0;

  let correctAnswerAfterCheck = getCorrectAnswer(
    state.byId,
    additionalWords,
    gameType,
    transcript,
    stopWords,
    isSpacedLang
  );
  let digestedACorrectAnswerCount = 0;

  if (state.hintMode || state.isResultShown) {
    if (digestedACorrectAnswer.length === pressedCardLength) {
      _.mapValues(state.byId, (card, charId) => {
        let status = card.status;

        if (isCardPressed(state, charId)) {
          if (isCardCorrect(state, charId)) {
            status = spellingStatus.CORRECT;
          }
        }

        return { ...card, status };
      });
      _.map(pressedIds, (pressedId, index) => {
        if (
          comparePressIdWithAnswer(
            pressedId,
            state,
            digestedACorrectAnswer,
            index
          )
        ) {
          digestedACorrectAnswerCount++;
        }
      });

      if (digestedACorrectAnswerCount === pressedCardLength) {
        correctAnswerAfterCheck = digestedACorrectAnswer;
      }
    }
  }

  return correctAnswerAfterCheck;
};

const setStopWords = (state: State, { stopWords }) => ({
  ...state,
  globalStopWords: _.map(stopWords, _.toLower)
});

const toggleGameTranscript = (state, { newWords }) =>
  newWords ? { ...state, byId: getById(newWords) } : state;

const isCardPressed = ({ pressedIds }, charId) =>
  // $FlowFixMe
  pressedIds.includes(Number(charId));

const moveCard = (pressedIds?: any[], charId) => {
  const charNumber = Number(charId);
  if (!pressedIds) {
    return pressedIds;
  }
  //if card is already in the list, replace it by null
  if (pressedIds.includes(charNumber)) {
    return _.map(pressedIds, id => (id === charNumber ? null : id));
  }
  //if not, add it in place of the leftmost null entry
  const newPressedIds = [...pressedIds];
  newPressedIds[_.findIndex(pressedIds, id => id === null)] = charNumber;
  return newPressedIds;
};

const moveCardIndex = (pressedIds?: any[], charId) => {
  const charNumber = Number(charId);

  if (!pressedIds) {
    return pressedIds;
  }
  if (pressedIds.includes(charNumber + 1)) {
    return _.map(pressedIds, (id, index) =>
      id === charNumber + 1 ? null : id
    );
  }
  pressedIds[charNumber] = charNumber + 1;
  return pressedIds;
};

const coloredCard = (state: State, charId: string): string => {
  return isCardCorrect(state, charId)
    ? spellingStatus.CORRECT
    : spellingStatus.WRONG;
};

const isStopWord = (word: ?string, stopWords: string[]) => {
  return _.includes(stopWords, _.toLower(word));
};

const removeStopWords = (byId: Object, ids: string[], stopWords: string[]) => {
  return _.reject(ids, id => isStopWord(byId[id].character, stopWords));
};

export const isCardCorrect = (state: Object, charId: string) => {
  const { byId, additionalWords, pressedIds } = state;
  const selectedChar = getCharacter(byId, charId);
  let result = false;
  const correctChar = correctCharacter(
    { ...state, pressedIds: pressedIds },
    charId
  );
  result = selectedChar === correctChar;

  if (additionalWords.includes(Number(charId))) {
    result = false;
  }
  return result;
};

const getCharacter = (byId: ById, charId: ?string): ?string => {
  if (charId) {
    return byId[charId].character;
  }
};

export const correctCharacter = (
  { allIds, byId, pressedIds, stopWords, gameType }: State,
  charId: string
): ?string => {
  const newPressedIds =
    gameType === ORDER_SENTENCE_REVERSE
      ? _.filter(pressedIds, e => e !== null)
      : pressedIds;
  const index = _.sortBy(allIds)[_.indexOf(newPressedIds, Number(charId))];
  if (index) {
    if (gameType === ORDER_SENTENCE_REVERSE) {
      return _.get(byId, `[${charId}].character`, null);
    } else {
      return _.get(byId, `[${index}].character`, null);
    }
  }
  return null;
};

const getMode = (
  pressedIds,
  isOrderSentenceReverse,
  isResultShown,
  isAnswerWrong
) => {
  if (pressedIds && pressedIds.includes(null) && !isOrderSentenceReverse) {
    return BUTTON_CHECK_DISABLED;
  } else if (isResultShown) {
    return BUTTON_CONTINUE;
  } else if (pressedIds && pressedIds[0] === null && isOrderSentenceReverse) {
    return BUTTON_CHECK_DISABLED;
  } else {
    return BUTTON_CHECK;
  }
};

const isOrderSentenceReverse = (gameType: string): boolean =>
  gameType === ORDER_SENTENCE_REVERSE;

export const isOrderSentence = (gameType: string): boolean =>
  gameType === ORDER_SENTENCE;

const getSoundData = (soundFile: ?Object, wordId: string) => {
  if (soundFile) {
    return soundFile;
  } else {
    bugTracker.notify(
      new Error(`Could not get sound file in spelling: ${wordId}`)
    );
    console.warn(`Could not get sound file in spelling: ${wordId}`);
    return null;
  }
};
export const getSpelling = (
  state: State,
  { byId }: Object,
  gameType: string
) => {
  const {
    pressedIds,
    isResultShown,
    hintMode,
    additionalWords,
    stopWords,
    isSpacedLang,
    answeredWrong
  } = state;
  let data;

  if (gameType !== DIALOGUE) {
    const wordId = byId[state.wordId];

    const soundFile = getSoundData(wordId.soundFile, wordId);
    const { translation, transcript, original } = wordId;
    data = {
      soundFile,
      translation,
      transcript,
      original
    };
  }

  const mode = getMode(
    pressedIds,
    isOrderSentenceReverse(gameType),
    isResultShown,
    answeredWrong
  );
  const transcript = data && data.transcript ? data.transcript : null;

  const checkOrderSentence = (gameType: string, mode: string) => {
    if (isOrderSentenceReverse(gameType)) {
      if (mode === BUTTON_CHECK_DISABLED) {
        return true;
      } else if (mode === BUTTON_CHECK) {
        return true;
      } else if (mode === BUTTON_CONTINUE) {
        return false;
      } else if (mode === BUTTON_CONTINUE_DISABLED) {
        return false;
      }
    } else {
      return mode === BUTTON_CHECK_DISABLED;
    }
  };

  const correctAnswer = getAnswer(
    state,
    state.byId,
    additionalWords,
    gameType,
    transcript,
    stopWords,
    isSpacedLang
  );
  if (correctAnswer[0].character === 'undefined') {
    correctAnswer.splice(0, 1);
  }
  return {
    ...state,
    ...data,
    mode,
    hintButtonEnabled: !hintMode && checkOrderSentence(gameType, mode),
    correctAnswer
  };
};

const getCorrectAnswer = (
  byId: ?Object,
  additionalWords: number[],
  gameType: string,
  transcript: ?boolean,
  stopWords: string[],
  isSpacedLang: ?boolean
) => {
  const filter = fp.reject.convert({ cap: false })((_, charId) =>
    additionalWords.includes(Number(charId))
  );
  const mapAnswers = fp.map(({ status, character }) => ({
    //TODO: make this more general when other languages are introduced
    //(not all languages have spaces between words)
    character:
      character +
      (isOrderSentenceReverse(gameType) ||
      ((transcript || isSpacedLang) && isOrderSentence(gameType))
        ? ' '
        : ''),
    isWrong: !isStopWord(character, stopWords),
    status: status
  }));

  return fp.compose(mapAnswers, filter)(byId);
};

export const SpellingReducer = (
  state: State = INITIAL_STATE,
  action: Object
): State => {
  const { type } = action;
  switch (type) {
    case CLOSE_GAME:
      return INITIAL_STATE;
    case REFRESH_GAME:
      if (isSpellingGame(action.gameType)) {
        return loadSpelling(state, action);
      } else {
        return state;
      }
    case PRESS_CHARACTER_CARD:
      return pressCharacterCard(state, action.charId);
    case MOVE_TO_LOCKED_IDS:
      return moveCharacterCard(state, action.charId);
    case SPEND_HINT:
      return spendHint(state, action);
    case CHECK_SPELLING_RESULT:
      return checkSpellingResult(state);
    case CHECK_CORRECT_CARD:
      return checkCorrectCard(state);
    case TOGGLE_GAME_TRANSCRIPT:
      return toggleGameTranscript(state, action);
    case LOAD_STOP_WORDS:
      return setStopWords(state, action);
    default:
      return state;
  }
};
