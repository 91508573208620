import { createSelector } from 'reselect';

export const getDataState = state => state.data;

export const selectTestimonials = createSelector(
  getDataState,
  data => [
    'trial_txt_testimonial_1',
    'trial_txt_testimonial_2',
    'trial_txt_testimonial_3',
    'trial_txt_testimonial_4',
    'trial_txt_testimonial_5'
  ].reduce((a, c) => Object.assign(a, { [c]: data.labels.byId[c].content }), {})
);

export const selectTestimonialName = createSelector(
  getDataState,
  data => [
    'trial_txt_testimonial_name_1',
    'trial_txt_testimonial_name_2',
    'trial_txt_testimonial_name_3',
    'trial_txt_testimonial_name_4',
    'trial_txt_testimonial_name_5',
  ].reduce((a, c) => Object.assign(a, { [c]: data.labels.byId[c].content }), {})
);
