/* @flow */
import _ from 'lodash';
import * as fromSpelling from './SpellingReducer';
import * as fromQuiz from './QuizReducer';
import * as fromMatching from './MatchingGameReducer';
import { getTranscriptionState } from '../../profile/selectors/UserSelector';

export const getCardsDisplayed = (state: Object) => {
  const { matchingCardsDisplayed, game, routes } = state;
  const transcription = getTranscriptionState(state);

  if (_.isEmpty(matchingCardsDisplayed)) {
    return state;
  }
  return fromMatching.getCardsDisplayed(matchingCardsDisplayed, game, {
    ...routes,
    transcript: transcription
  });
};

export const getQuizCards = (
  state: Object,
  isTranslateGame: boolean = false
) => {
  const { quizCards, game, routes } = state;
  const transcription = getTranscriptionState(state);
  return fromQuiz.getQuizCards(
    quizCards,
    game,
    { ...routes, transcript: transcription },
    isTranslateGame
  );
};

export const getCorrectSentenceCards = (state: Object) => {
  const { quizCards, game, routes } = state;
  const transcription = getTranscriptionState(state);
  return fromQuiz.getCorrectSentenceCards(quizCards, game, {
    ...routes,
    transcript: transcription
  });
};

export const getSpelling = (state: Object, isWord: boolean = false) => {
  const { game, spelling, routes } = state;
  const { words, sentences } = game;
  let content;
  if (isWord) {
    content = words;
  } else {
    content = sentences;
  }
  return fromSpelling.getSpelling(spelling, content, routes.gameType);
};
