/* @flow */
import styled from 'styled-components';
import {
  GREYISH_BROWN,
  WHITE,
  LIGHT_GREY,
  BROWN_GREY_TWO,
  GREY2,
  MARIGOLD,
  BLACK,
  DARK_YELLOW,
  LIGHT_GREY_2
} from '../../../../common/constants';

const ModalBodyText = styled.div`
  font-size: 18px;
  color: ${GREYISH_BROWN};
  line-height: 21px;
  margin-bottom: 13px !important;
`;
const ModalCloseButton = styled.button`
  position: fixed;
  opacity: 1;
  padding: 7px 0px !important;
  margin-left: 16px !important;
  margin-bottom: 10px !important;
`;
const LoginStyle = styled.button`
  text-transform: uppercase;
  margin-top: 26px;
  border-radius: 20px;
  box-shadow: inset 0 -4px 0 0 ${DARK_YELLOW};
  background: ${MARIGOLD};
  border: ${DARK_YELLOW} 1px solid;
  font-size: 14px;
  width: 157px;
  height: 55px;
  padding: 0;
  color: ${WHITE};

  &:disabled {
    background: ${LIGHT_GREY_2};
    box-shadow: inset 0 -4px 0 0 ${GREY2};
    border: ${GREY2} 1px solid;
  }
  &:hover:enabled {
    opacity: 0.4;
    box-shadow: none;
  }
  &:active:enabled {
    opacity: 1;
    background-color: ${DARK_YELLOW};
    box-shadow: none;
  }
`;
const ModalResetPasswordContainer = styled.div`
  margin: 0px;
`;
const DayLabel = styled.label`
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  line-height: 40px;
  padding: 7px;
  cursor: pointer;

  & > div {
    border: 2px solid ${props => (props.isHomePage ? LIGHT_GREY : WHITE)};
    background: ${props => (props.isHomePage ? LIGHT_GREY : MARIGOLD)};
    color: ${props => (props.isHomePage ? BROWN_GREY_TWO : WHITE)};
    border-radius: 50%;
    line-height: 34px;
    height: 36px;
  }
`;
const DaySelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  margin-top: ${props => (props.isHomePage ? 30 : 0)}px;
  justify-content: center;
`;
const DayCheckBox = styled.input`
  position: absolute;
  visibility: hidden;
  width: 30px;
  height: 30px;

  &:checked + ${DayLabel} {
    background: ${WHITE};
    border-radius: ${props =>
      props.checkPrev
        ? props.checkNext
          ? '0px'
          : '0px 15px 15px 0px'
        : props.checkNext
        ? '15px 0px 0px 15px'
        : '15px'};
    & > div {
      background: ${props => (props.isHomePage ? BLACK : WHITE)};
      border: 2px solid ${props => (props.isHomePage ? LIGHT_GREY : MARIGOLD)};
      color: ${props => (props.isHomePage ? WHITE : MARIGOLD)};
    }
  }
`;
const ContentContainer = styled.div`
  margin: ${props => (props.isHomePage ? '20px 30px' : '20px')};
`;
const DayOptionText = styled.p`
  text-align: left;
  padding: ${props => (props.isHomePage ? 0 : '10px 20px;')};
`;
const TimeOptionText = styled.p`
  margin-top: 28px !important;
  margin-bottom: 28px !important;
  text-align: ${props => (props.isHomePage ? 'left' : 'center')};
`;
const ModalBodyContainer = styled.div`
  display: block;
`;
const DayOptionContainer = styled.div`
  background: ${props => (props.isHomePage ? WHITE : MARIGOLD)};
  border-radius: 20px;
  & > p {
    color: ${props => (props.isHomePage ? BLACK : WHITE)};
  }
`;

export {
  ContentContainer,
  DayCheckBox,
  DayLabel,
  DayOptionContainer,
  DayOptionText,
  DaySelectorContainer,
  LoginStyle,
  ModalBodyContainer,
  ModalBodyText,
  ModalCloseButton,
  ModalResetPasswordContainer,
  TimeOptionText
};
