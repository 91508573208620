/* @flow */
import * as React from 'react';
import { playSound } from '../../../util';
import styled from 'styled-components';
import { BLACK, WHITE, BLACK_HOVER } from '../../../common/constants';

const speakerImage = require('../../../img/icons/outline-sound-icon.svg');
const speakerImageActive = require('../../../img/icons/outline-sound-icon-active.svg');

const PlayButtonContainer = styled.div`
  border: solid 1px ${BLACK};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  left: 50%;
  top: 105%;
  bottom: -10px;
  background: ${WHITE};
  cursor: pointer;
  &:hover {
    border-color: ${BLACK_HOVER};
    #buttonIcon {
      opacity: 0.4;
    }
  }
  &:active {
    background: ${BLACK};
    #buttonIcon1 {
      display: inline !important;
    }
  }
`;

const SpeakerImage = styled.img`
  position: relative;
`;

const SpeakerImageActive = styled.img`
  display: none;
  position: absolute;
  zindex: 99;
`;

type Props = {
  soundFile: string,
  voiceSpeed: number
};

const PlayButton = ({ soundFile, voiceSpeed }: Props) => {
  return (
    <PlayButtonContainer onClick={() => onPlay(soundFile, voiceSpeed)}>
      <SpeakerImage id="buttonIcon" src={speakerImage} />
      <SpeakerImageActive id="buttonIcon1" src={speakerImageActive} />
    </PlayButtonContainer>
  );
};

const onPlay = (soundFile: string, voiceSpeed: number) =>
  playSound(soundFile, voiceSpeed);

export { PlayButton };
