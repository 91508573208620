/* @flow */
import axios from 'axios';

export const addToOSI = (
  functions: string,
  referrerCode: string | null,
  transactionId: string | null,
  amount: number,
  baseCurrencyCode: string
) => {
  return axios
    .post(`${functions}validateLingWeb-stripeApi/osi`, {
      osi: referrerCode,
      sessionId: transactionId,
      amount_total: amount,
      currency: baseCurrencyCode
    })
    .then(response => response.data);
};
