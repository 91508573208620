/* @flow */
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { VERY_LIGHT_PINK, PREFIX_LESSON, BLACK } from '../../common/constants';

const Container = styled.div``;
const SelectedBarContainer = styled.div`
  width: 320px;
  border-radius: 10px;
  border: solid 1px ${VERY_LIGHT_PINK};
  display: flex;
  padding: 20px 10px;
  margin: auto;
`;
const BackNextButton = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 0;
  border: 0;
  img {
    width: 32px;
    height: 32px;
  }
`;
const MiddleContent = styled.div`
  flex: 1;
  justify-content: center;
  display: flex;
  align-items: center;
`;
const FlagImg = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;
const LangagueText = styled.p`
  margin: 0 !important;
  font-size: 22px;
  font-weight: bold;
  color: ${BLACK};
`;
const leftArrow = `${PREFIX_LESSON}circle-arrow-left-32-px@2x.png`;
const rightArrow = `${PREFIX_LESSON}circle-arrow-right-32-px@2x.png`;

type Props = {
  flagUri: string,
  flagTarget: string,
  previousLang: Function,
  nextLang: Function
};
const SelectedBar = ({
  flagUri,
  flagTarget,
  previousLang,
  nextLang
}: Props) => {
  return (
    <Fragment>
      <Container>
        <SelectedBarContainer>
          <BackNextButton onClick={previousLang}>
            <img src={leftArrow} alt={leftArrow} />
          </BackNextButton>
          <MiddleContent>
            <FlagImg src={flagUri} alt={flagUri} />
            <LangagueText>{flagTarget}</LangagueText>
          </MiddleContent>
          <BackNextButton onClick={nextLang}>
            <img src={rightArrow} alt={rightArrow} />
          </BackNextButton>
        </SelectedBarContainer>
      </Container>
    </Fragment>
  );
};
export { SelectedBar };
