/* @flow */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import { HeaderMenuWithRouter } from '../sub-components/HeaderMenu';
import { getInviteCampaigns, getUserReferral } from '../../games/actions';

import {
  IMAGE_PATH,
  PREFIX_HEADER,
  MARIGOLD,
  TOP_MENU_OFFSET
} from '../../common/constants';
import { Box } from '@mui/material';

const ImageContainerLarge = styled.div`
  display: flex;
  justify-content: center;
  z-index: -2;
  display: ${props =>
    props.isShowTutorial || props.showTutorial
      ? 'none'
      : props.isScrolled
      ? 'none'
      : 'flex'};
`;

const Logo = styled.img`
  width: 129px;
  height: 67px;
`;

const MenuImage = styled.img`
  width: 234px;
  height: 135px;
`;
type State = {
  isScrolled: any,
  referralCampaign: Object
};

type Props = {
  isSrollable: boolean,
  bananas: number,
  userCoins: number,
  name: string,
  photoURL: string,
  profile_txt_nameGuest: string,
  flagUri: string,
  history: Object,
  createAccount_txt_title: string,
  notification_text_confirm: string,
  notification_text_cancel: string,
  login_msg_loseProgressDialog: string,
  logout_txt: string,
  toggleCheckout: Function,
  signOut: Function,
  signInWithEmailAndPassword: Function,
  signInWithFacebook: Function,
  signInWithGoogle: Function,
  signInWithApple: Function,
  signUpWithEmail: Function,
  sendPasswordResetEmail: Function,
  signInAnonymously: Function,
  toggleCreateAccount: Function,
  toggleCreateAccountBusiness: Function,
  pauseDialog: Function,
  email_error_matchPasswords: string,
  email_error_fieldsMissing: string,
  email_error_longerPassword: string,
  email_error_wrongFormat: string,
  login_field_name: string,
  login_field_firstName: string,
  login_msg_reenterPassword: string,
  login_field_email: string,
  login_field_password: string,
  email_txt_loginNow: string,
  reset_password_txt_description: string,
  btn_continue: Function,
  sign_up_txt_title: string,
  login_txt_title: string,
  email_txt_loginNow_link: string,
  already_register_txt: string,
  login_txt_forget_password_title: string,
  login_txt_forget_password_web: string,
  dont_have_account_yet_txt: string,
  at_least_6_password_txt: string,
  email_error_alreadyUsed: string,
  email_error_userNotFound: string,
  email_error_wrongPassword: string,
  setting_txt_settingHeader: string,
  modal_error_faq: string,
  profile_txt_headerProfile: string,
  location: Object,
  footer_txt_shop: string,
  footer_txt_learn: string,
  footer_txt_chatbot: string,
  onRef: Function,
  isProUser: boolean,
  faq: Array<Object>,
  isDev: boolean,
  user: Object,
  isShowTutorial: boolean,
  showTutorial: boolean,
  getInviteCampaigns: Function,
  getUserReferral: Function,
  isUserSubscribed: boolean,
  showGift: boolean,
  isShowInviteFriend: boolean,
  isLoginModalOpen: boolean,
  toggleLoginModal: Function,
  setIsBusinessSignUp: Function,
  setSignUpBusinessSuccess: Function,
  fetchUnitData: Function,
  isBusinessSignUp: boolean,
  targetLangCode: string,
  nativeLangCode: string,
  banana_score_modal_txt: string,
  star_score_modal_txt: string,
  modal_error_other: string,
  modal_error_answer_should_be_accepted: string,
  modal_error_answer_duplicate: string,
  modal_error_image_missing: string,
  modal_error_audio_not_match: string,
  modal_error_audio_missing: string,
  modal_error_audio_not_clear: string,
  modal_error_report_txt: string,
  modal_error_report: string,
  modal_error_faq: string,
  sign_in_opt_msg: string,
  txt_email_field_placeholder: string
};

class DefaultHeader extends Component<Props, State> {
  child: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      isScrolled: null,
      referralCampaign: {}
    };
  }

  async UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { user } = this.props;

    const { claimedReferral = {}, uid } = user;
    const campaigns = await getInviteCampaigns().once('value');
    let campaignsList = _.map(campaigns.val(), (item, index) => {
      return {
        index,
        ...item
      };
    });
    campaignsList = _.sortBy(campaignsList, ['goal']);
    const referral = await getUserReferral(uid).once('value');
    let amountReferral = _.size(referral.val());
    let currentCampaign = {};

    _.forEach(campaignsList, campaign => {
      const { goal, index } = campaign;
      if (goal <= amountReferral && !claimedReferral[index]) {
        currentCampaign = campaign;
        return;
      }
      amountReferral -= goal;
    });

    this.setState({ referralCampaign: currentCampaign });
  }

  componentDidMount() {
    try {
      const { onRef } = this.props;

      onRef(this);
    } catch (error) {}
    if (!this.props.isSrollable) {
      this.setState({ isScrolled: true });
    }
    window.addEventListener('scroll', this.listenScrollEvent);
  }
  componentWillUnmount() {
    try {
      this.props.onRef(undefined);
    } catch (error) {}
  }

  gotoHome() {
    this.props.history.push('/');
  }
  toggleCreateAccountLanguagePicker = () => {
    this.child.toggleCreateAccountLanguagePicker();
  };
  toggleCreateAccount(itemInfo, productName, price, currency) {
    this.child.toggleCreateAccount(itemInfo, productName, price, currency);
  }
  toggleCreateAccountBusiness(unit) {
    this.child.toggleCreateAccountBusiness(unit);
  }

  toggle = () => {
    this.child.toggle();
  };
  listenScrollEvent = (e: SyntheticEvent<Event>) => {
    if (this.props.isSrollable) {
      if (window.scrollY > TOP_MENU_OFFSET) {
        this.setState({ isScrolled: true });
      } else {
        this.setState({ isScrolled: false });
      }
    }
  };

  renderHeader() {
    const { isScrolled, referralCampaign = {} } = this.state;
    const {
      bananas,
      userCoins,
      name,
      photoURL,
      profile_txt_nameGuest,
      flagUri,
      history,
      createAccount_txt_title,
      notification_text_confirm,
      notification_text_cancel,
      login_msg_loseProgressDialog,
      logout_txt,
      signOut,
      signInWithEmailAndPassword,
      signInAnonymously,
      signInWithFacebook,
      signInWithGoogle,
      signInWithApple,
      signUpWithEmail,
      sendPasswordResetEmail,
      sign_in_opt_msg,
      email_error_matchPasswords,
      email_error_fieldsMissing,
      email_error_longerPassword,
      email_error_wrongFormat,
      login_field_name,
      login_field_firstName,
      login_msg_reenterPassword,
      login_field_email,
      login_field_password,
      email_txt_loginNow,
      reset_password_txt_description,
      btn_continue,
      sign_up_txt_title,
      login_txt_title,
      email_txt_loginNow_link,
      already_register_txt,
      login_txt_forget_password_title,
      login_txt_forget_password_web,
      dont_have_account_yet_txt,
      at_least_6_password_txt,
      email_error_alreadyUsed,
      email_error_userNotFound,
      email_error_wrongPassword,
      setting_txt_settingHeader,
      modal_error_faq,
      modal_error_other,
      modal_error_answer_should_be_accepted,
      modal_error_answer_duplicate,
      modal_error_image_missing,
      modal_error_audio_not_match,
      modal_error_audio_missing,
      modal_error_audio_not_clear,
      modal_error_report_txt,
      modal_error_report,
      profile_txt_headerProfile,
      footer_txt_shop,
      footer_txt_learn,
      footer_txt_chatbot,
      location,
      isProUser,
      faq,
      isDev,
      user,
      isShowTutorial,
      showGift,
      isShowInviteFriend,
      isLoginModalOpen,
      toggleLoginModal,
      setIsBusinessSignUp,
      setSignUpBusinessSuccess,
      isBusinessSignUp,
      fetchUnitData,
      targetLangCode,
      nativeLangCode,
      banana_score_modal_txt,
      star_score_modal_txt,
      pauseDialog,
      txt_email_field_placeholder
    } = this.props;
    const isInviteFriend = isShowInviteFriend && !_.isEmpty(referralCampaign);
    let promoCount = 0;

    [showGift, isInviteFriend].forEach(v => (v ? promoCount++ : v));
    return (
      <HeaderMenuWithRouter
        modal_error_other={modal_error_other}
        modal_error_answer_should_be_accepted={
          modal_error_answer_should_be_accepted
        }
        sign_in_opt_msg={sign_in_opt_msg}
        modal_error_answer_duplicate={modal_error_answer_duplicate}
        modal_error_image_missing={modal_error_image_missing}
        modal_error_audio_not_match={modal_error_audio_not_match}
        modal_error_audio_missing={modal_error_audio_missing}
        modal_error_audio_not_clear={modal_error_audio_not_clear}
        modal_error_report_txt={modal_error_report_txt}
        modal_error_report={modal_error_report}
        modal_error_faq={modal_error_faq}
        pauseDialog={pauseDialog}
        toggleCheckout={this.props.toggleCheckout}
        targetLangCode={targetLangCode}
        nativeLangCode={nativeLangCode}
        fetchUnitData={fetchUnitData}
        setSignUpBusinessSuccess={setSignUpBusinessSuccess}
        isBusinessSignUp={isBusinessSignUp}
        setIsBusinessSignUp={setIsBusinessSignUp}
        toggleLoginModal={toggleLoginModal}
        isLoginModalOpen={isLoginModalOpen}
        isScrolled={isScrolled}
        isProUser={isProUser}
        flagUri={flagUri}
        bananas={bananas}
        coins={userCoins}
        name={name}
        profile_txt_nameGuest={profile_txt_nameGuest}
        history={history}
        createAccount_txt_title={createAccount_txt_title}
        notification_text_confirm={notification_text_confirm}
        notification_text_cancel={notification_text_cancel}
        login_msg_loseProgressDialog={login_msg_loseProgressDialog}
        logout_txt={logout_txt}
        signOut={signOut}
        photoURL={photoURL}
        signInWithEmailAndPassword={signInWithEmailAndPassword}
        signInAnonymously={signInAnonymously}
        signInWithFacebook={signInWithFacebook}
        signInWithGoogle={signInWithGoogle}
        signInWithApple={signInWithApple}
        signUpWithEmail={signUpWithEmail}
        sendPasswordResetEmail={sendPasswordResetEmail}
        email_error_matchPasswords={email_error_matchPasswords}
        email_error_fieldsMissing={email_error_fieldsMissing}
        email_error_longerPassword={email_error_longerPassword}
        email_error_wrongFormat={email_error_wrongFormat}
        login_field_name={login_field_name}
        login_field_firstName={login_field_firstName}
        login_msg_reenterPassword={login_msg_reenterPassword}
        login_field_email={login_field_email}
        login_field_password={login_field_password}
        email_txt_loginNow={email_txt_loginNow}
        reset_password_txt_description={reset_password_txt_description}
        btn_continue={btn_continue}
        sign_up_txt_title={sign_up_txt_title}
        login_txt_title={login_txt_title}
        email_txt_loginNow_link={email_txt_loginNow_link}
        already_register_txt={already_register_txt}
        login_txt_forget_password_title={login_txt_forget_password_title}
        login_txt_forget_password_web={login_txt_forget_password_web}
        dont_have_account_yet_txt={dont_have_account_yet_txt}
        at_least_6_password_txt={at_least_6_password_txt}
        email_error_alreadyUsed={email_error_alreadyUsed}
        email_error_userNotFound={email_error_userNotFound}
        email_error_wrongPassword={email_error_wrongPassword}
        setting_txt_settingHeader={setting_txt_settingHeader}
        profile_txt_headerProfile={profile_txt_headerProfile}
        footer_txt_shop={footer_txt_shop}
        footer_txt_learn={footer_txt_learn}
        footer_txt_chatbot={footer_txt_chatbot}
        banana_score_modal_txt={banana_score_modal_txt}
        star_score_modal_txt={star_score_modal_txt}
        location={location}
        onRef={ref => (this.child = ref)}
        faq={faq}
        isDev={isDev}
        user={user}
        isShowTutorial={isShowTutorial}
        promoCount={promoCount}
        showGift={
          showGift || (isShowInviteFriend && !_.isEmpty(referralCampaign))
        }
        txt_email_field_placeholder={txt_email_field_placeholder}
      />
    );
  }

  render() {
    const { isScrolled } = this.state;
    const { isShowTutorial, showTutorial } = this.props;

    const styles = {
      container: {
        top: 0,
        width: 1,
        height: isShowTutorial || showTutorial ? 70 : isScrolled ? 70 : 225,
        backgroundColor: MARIGOLD,
        position: 'fixed',
        zIndex: 1024,
        display: 'flex',
        flexDirection: isScrolled ? 'row' : 'column',
        mb: 52,
        justifyContent: 'space-between'
      },
      headerContainer: {
        width: 1,
        display: 'flex',
        flexDirection: 'row'
      },
      logoContainer: {
        maxWidth: 129,
        display: {
          md: 'flex',
          xs: 'none'
        },
        flex: 1,
        zIndex: 0,
        cursor: 'pointer',
        justifyContent: isScrolled ? 'flex-start' : 'center'
      }
    };

    return (
      <Box sx={styles.container}>
        <Box sx={styles.headerContainer}>
          <Box sx={styles.logoContainer}>
            <Logo isScrolled={isScrolled} src={`${IMAGE_PATH}Logo.png`} />
          </Box>
          {isScrolled === false ? null : this.renderHeader()}
          {isScrolled === false ? this.renderHeader() : null}
        </Box>
        <ImageContainerLarge
          isScrolled={isScrolled}
          isShowTutorial={isShowTutorial}
          showTutorial={showTutorial}
        >
          <MenuImage
            isScrolled={isScrolled}
            src={`${PREFIX_HEADER}ling-extra-header-image.png`}
          />
        </ImageContainerLarge>
      </Box>
    );
  }
}

const DefaultHeaderWithRouter = withRouter(DefaultHeader);
export { DefaultHeaderWithRouter };
