import React, { Fragment } from 'react';
import _ from 'lodash';
import { UnitCard, UnitLevel } from '.';
import styled from 'styled-components';
import {
  getUserProfile,
  rearrangeBusinessinUnit,
  rearrangeBussinessUnit,
  removeBusinessinUnit
} from '../../util';

const UnitContainer = styled.div`
  display: grid;
  width: 100vw;
  grid-template-columns: repeat(auto-fill, minmax(220px, 300px));
`;

type Props = {
  unitLevels: number[],
  units: Object,
  unitLevel_txt_basic: string,
  unitLevel_txt_beginner: string,
  unitLevel_txt_intermediate: string,
  unitLevel_txt_afterintermediate: string,
  unitLevel_txt_advance: string,
  unitLevel_txt_unit: string,
  isBusiness: boolean,
  setCustomRef: Function,
  getUnitRefName: Function,
  getCustomRef: Function,
  spaced_repetition_open_txt: string,
  toggleCreateAccountBusiness: Function,
  user: Object,
  loginPurchase_txt: string,
  createAccount_txt_title: string,
  targetLangCode: string,
  nativeLangCode: string,
  fetchUnitData: Function,
  isUnitLocked: Function,
  features: Object,
  unitContinue: any,
  history: Object,
  firstCrackedUnitId: number,
  isShowRepairedUnitTooltipsAlready: boolean,
  disableRepairedUnitTooltips: Function,
  Popper: Function,
  restrictedUnits: Array<number>
};
const UnitList = ({
  unitLevels,
  units,
  unitLevel_txt_basic,
  unitLevel_txt_beginner,
  unitLevel_txt_intermediate,
  unitLevel_txt_afterintermediate,
  unitLevel_txt_advance,
  unitLevel_txt_unit,
  isBusiness,
  setCustomRef,
  getUnitRefName,
  getCustomRef,
  spaced_repetition_open_txt,
  toggleCreateAccountBusiness,
  user,
  loginPurchase_txt,
  createAccount_txt_title,
  targetLangCode,
  nativeLangCode,
  fetchUnitData,
  isUnitLocked,
  features,
  unitContinue,
  history,
  firstCrackedUnitId,
  isShowRepairedUnitTooltipsAlready,
  disableRepairedUnitTooltips,
  Popper,
  restrictedUnits
}: Props) => {
  return _.map(unitLevels, level => {
    const unitsInLevel = _.filter(units, ['level', level]);
    return (
      <Fragment key={level}>
        <UnitLevel
          level={level}
          unitLevel_txt_basic={unitLevel_txt_basic}
          unitLevel_txt_beginner={unitLevel_txt_beginner}
          unitLevel_txt_intermediate={unitLevel_txt_intermediate}
          unitLevel_txt_afterintermediate={unitLevel_txt_afterintermediate}
          unitLevel_txt_advance={unitLevel_txt_advance}
          unitLevel_txt_unit={unitLevel_txt_unit}
        />
        <UnitContainer>
          {
            (rearrangeBussinessUnit(
              level,
              isBusiness,
              unitsInLevel,
              units,
              rearrangeBusinessinUnit,
              removeBusinessinUnit
            ),
            _.map(unitsInLevel, unit => {
              const { key, isCrackedUnit = false } = unit;
              setCustomRef(
                getUnitRefName(key),
                // $flow-disable-line
                React.createRef()
              );
              const isUnitHidden = _.includes(restrictedUnits, key);
              return (
                !isUnitHidden && (
                  <UnitCard
                    spaced_repetition_open_txt={spaced_repetition_open_txt}
                    toggleCreateAccountBusiness={toggleCreateAccountBusiness}
                    isLogin={getUserProfile(user)}
                    createAccount_txt_title={createAccount_txt_title}
                    loginPurchase_txt={loginPurchase_txt}
                    isBusiness={isBusiness}
                    targetLangCode={targetLangCode}
                    nativeLangCode={nativeLangCode}
                    fetchUnitData={fetchUnitData}
                    isUnitLocked={isUnitLocked}
                    features={features}
                    key={key}
                    unitContinue={unitContinue}
                    history={history}
                    unit={unit}
                    scrollRef={getCustomRef(getUnitRefName(key))}
                    isShowPopover={
                      key === firstCrackedUnitId &&
                      !isShowRepairedUnitTooltipsAlready
                    }
                    isShowCrack={isCrackedUnit}
                    disableRepairedUnitTooltips={disableRepairedUnitTooltips}
                  />
                )
              );
            }))
          }
          {firstCrackedUnitId > 0 &&
            !isShowRepairedUnitTooltipsAlready &&
            Popper(
              firstCrackedUnitId,
              spaced_repetition_open_txt,
              disableRepairedUnitTooltips,
              history,
              fetchUnitData,
              nativeLangCode,
              targetLangCode,
              isUnitLocked
            )}
        </UnitContainer>
      </Fragment>
    );
  });
};

export { UnitList };
