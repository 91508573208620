/* @flow */
import React, { Fragment, PureComponent } from 'react';
import { DialogHeader } from '../../../games/components/sub-components';
import DialogMessage from '../../../games/components/sub-components/DialogMessage';

import _ from 'lodash';
import styled from 'styled-components';

type Props = {
  items: Object[],
  onPress: Function,
  headerDialog: Object,
  translateState: boolean,
  transcriptState: boolean,
  unmountDialog: Function,
  dialogSaveScrollFunc: Function,
  triggerDialogAutoPlay: Function
};

const DialogContentContainer = styled.div`
  width: 595px;
  margin: auto;
  margin-top: 210px;
  margin-bottom: 110px;
`;
class DialogReview extends PureComponent<Props, Object> {
  screen: Object;
  constructor(props: Props) {
    super(props);
    this.state = {
      screen: null,
      isCollapsed: false
    };
  }
  UNSAFE_componentWillMount() {
    const { triggerDialogAutoPlay } = this.props;
    this.props.dialogSaveScrollFunc(this.scrollDown.bind(this));
    triggerDialogAutoPlay();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.props.unmountDialog();
  }

  toggleHeader = (event: any) => {
    this.setState({
      isCollapsed: event
    });
  };
  scrollDown(id: number) {
    if (this.screen) {
      this.screen.scrollTo({ y: id * 60 });
    }
  }

  generateChild = () => {
    const {
      items,
      translateState = false,
      transcriptState = false,
      onPress = () => {}
    } = this.props;

    const generated = _.map(items, (dialogue, index) => (
      <DialogMessage
        gameState={0}
        translateState={translateState}
        transcriptState={transcriptState}
        key={index}
        {...dialogue}
        onPress={onPress}
        callback={this.scrollDown.bind(this)}
      />
    ));
    return generated;
  };

  getDialogHeader() {
    const { headerDialog } = this.props;
    const {
      formality,
      place,
      speaker0,
      speaker1,
      dialogue_title_formality_formal,
      dialogue_title_formality_informal
    } = headerDialog;
    const render = (
      <DialogHeader
        toggleHeader={this.toggleHeader}
        place={place}
        formality={formality}
        speaker0={speaker0}
        speaker1={speaker1}
        dialogue_title_formality_formal={dialogue_title_formality_formal}
        dialogue_title_formality_informal={dialogue_title_formality_informal}
      />
    );
    return render;
  }
  render() {
    return (
      <Fragment>
        {this.getDialogHeader()}
        <DialogContentContainer>{this.generateChild()}</DialogContentContainer>
      </Fragment>
    );
  }
}

export { DialogReview };
