/* @flow */
import _ from 'lodash';

export const getIndexedArray = (elements: any[]): number[] => {
  let elementId = 1;
  return elements.map(() => elementId++);
};

export const shuffledArray = (elements: any[]) =>
  _.shuffle(getIndexedArray(elements));

export const insertAt = (
  arrays: Array<Object>,
  index: number,
  array: Object,
  order: number
) => {
  arrays.splice(index, 0, array[order]);
  return arrays;
};
export const removeAt = (arrays: Array<Object>) => {
  arrays.splice(0, 1);
  arrays.splice(0, 1);
  return arrays;
};

export const rearrangeBusinessinUnit = (
  unitsInLevel: Array<Object>,
  units: Object
) => {
  insertAt(unitsInLevel, 2, units, 40);
  insertAt(unitsInLevel, 3, units, 41);
};
export const removeBusinessinUnit = (
  unitsInLevel: Array<Object>,
  units: Object
) => {
  removeAt(unitsInLevel);
};
