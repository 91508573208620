import styled from 'styled-components';
import {
  MARIGOLD,
  WHITE,
  GREY2,
  GREY,
  SEA_BLUE,
  LIGHT_RED_1,
  DARK_GREY,
  GREYISH_BROWN,
  DARK_YELLOW,
  BLACK_OPACITY_SHADOW_COLOR,
  BLACK
} from '../../common/constants';

export const Container = styled.div`
  background-image: ${props =>
    props.isCheckout
      ? 'linear-gradient(163deg, #fff 24%, #fff 82%)'
      : 'linear-gradient(163deg, #fbd249 24%, #f5a623 82%)'};
  width: 100vw;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 800px;
`;
export const ContentContainer = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;
  min-height: 800px;
`;
export const ModalBodyText = styled.div`
  font-size: 18px;
  color: ${GREYISH_BROWN};
  line-height: 21px;
  margin-bottom: 13px !important;
`;

export const FormContainer = styled.div`
  width: 460px;
  @media (max-width: 468px) {
    width: auto;
  }
  height: auto;
  border-radius: 30px;
  background: ${WHITE};
  box-shadow: 0px 2px 20px ${BLACK_OPACITY_SHADOW_COLOR};
  z-index: 1;
`;

export const FormHeader = styled.h5`
  border-bottom: none;
  flex-direction: row-reverse;
  justifycontent: normal;
  padding: 29px 35px;
  @media (max-width: 468px) {
    padding: 29px 20px;
  }
  padding-bottom: 27px;
  text-transform: capitalize;
  text-align: left;

  font-size: 18px;
  font-weight: bold;
`;

export const WarningText = styled.p`
  margin: 0px 35px 18px !important;
  font-size: 14px;
`;

export const ModalContentContainer = styled.div`
  width: auto;
  margin: 0 38px;
  @media (max-width: 468px) {
    margin: 0 10px;
  }
`;

export const Logo = styled.img`
  height: 100%;
`;

export const Title = styled.h2`
  font-size: 26px;
  text-align: left;
  margin: 25px 0;
  margin-left: 10px;
  color: ${BLACK};
  font-weight: bold;
`;

export const InputLabel = styled.label`
  color: ${props =>
    props.isError && props.errorMsg !== '' ? LIGHT_RED_1 : DARK_GREY};
`;

export const ModalBodyContainer = styled.div`
  display: block;
`;
export const ContinueContainer = styled.div`
  text-align: center;
`;

export const InputGroupContainer = styled.div`
  text-align: left;
  border-color: ${GREY};
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 431px;
`;
export const LoginLink = styled.span`
  &:hover {
    opacity: 0.4;
  }
`;

export const InputPlace = styled.input`
  width: 100%;
  border: none;
  outline: none;
  background-color: ${WHITE} !important;
`;
export const LoginStyle = styled.button`
  text-transform: uppercase;
  margin-top: 26px;
  margin-bottom: 25px;
  border-radius: 20px;
  box-shadow: inset 0 -4px 0 0 ${DARK_YELLOW};
  background: ${MARIGOLD};
  border: ${DARK_YELLOW} 1px solid;
  font-size: 14px;
  width: 157px;
  height: 55px;
  padding: 0;
  color: ${WHITE};
  &:hover {
    opacity: 0.4;
    box-shadow: none;
  }
  &:active {
    opacity: 1;
    background-color: ${DARK_YELLOW};
    box-shadow: none;
  }
`;
export const ResetPasswordContainer = styled.div`
  text-align: center;
`;
export const ComfirmButton = styled.button`
  border-radius: 50px;
  margin-top: 20px;
  padding: 0px;
  border: none;
  height: 80px;
  width: 80px;
  & img {
    height: 80px;
    width: 80px;
  }
`;
export const BottomText = styled.div`
  margin-top: 13px;
  text-align: center;
`;
export const SignUpSuggestionStyle = styled.span`
  font-size: 14px;
  font-weight: bold;
`;
export const SocialButton = styled.button`
  img {
    width: 23px;
    height: 23px;
  }
  &:hover {
    opacity: 0.4;
  }
  &:active {
    opacity: 1;
  }
`;
export const ErrorTextStyle = styled.span`
  font-size: 12px;
  text-align: left;
  padding-left: 14px;
  display: flex;
  padding-top: 5px;
  font-size: 14px;
  text-align: left;
  color: ${LIGHT_RED_1};
  font-style: italic;
`;

export const InputErrorTextStyle = styled.span`
  font-size: 12px;
  text-align: left;
  padding-left: 0;
  display: flex;
  padding-top: 5px;
  font-size: 14px;
  text-align: left;
  color: ${LIGHT_RED_1};
  font-style: italic;
`;
export const ResetPasswordImg = styled.img`
  padding-top: 32px;
  padding-bottom: 16px;
`;
export const ForgotTextStyle = styled.span`
  font-size: 14px;
  text-align: left;
  padding-left: 14px;
  font-weight: bold;
  padding-top: 0px;
  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
  &:active {
    opacity: 1;
  }
`;
export const LoginButtonContainer = styled.div`
  text-align: center;
`;
export const GiftFireworkStyle = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 0;
`;
export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
`;
export const CheckoutFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
  }
`;
export const CheckoutContainer = styled.div`
  flex: 1;
  @media screen and (max-width: 800px) {
    margin: auto;
  }
`;
export const LeftEmptySpace = styled.div`
  flex: 1;
  @media screen and (max-width: 1100px) {
    flex: 0.5;
  }
  @media screen and (max-width: 950px) {
    flex: 0;
  }
`;

export const styles = {
  modalContainer: {
    width: '460px',
    height: '457px',
    textAlign: 'center',
    borderRadius: 30
  },
  modalBodyContainer: {
    padding: 0,
    paddingBottom: 36
  },
  buttonStyle: {
    borderRadius: 10,
    width: 160,
    height: 55,
    background: WHITE,
    border: 'solid 1px',
    borderColor: GREY2,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    boxShadowColor: GREY2,
    fontWeight: 'bold',
    fontSize: 18
  },
  cancelButtonStyle: {
    border: 'none',
    boxShadow: 'none',
    width: 100
  },
  confirmButtonStyle: {
    borderColor: GREY2,
    background: WHITE,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0'
  },
  modalHeader: {
    borderBottom: 'none',
    flexDirection: 'row-reverse',
    justifyContent: 'normal',
    padding: '29px 35px',
    paddingBottom: 27
  },
  modalFooter: {
    borderTop: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 36,
    paddingRight: 32,
    paddingLeft: 32
  },
  contentContainer: {
    borderRadius: 30
  },
  modalHeaderContainer: {
    'modal-title': 'w-100 text-center'
  },
  inputGroup: {
    textAlign: 'left',
    borderColor: GREY,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 20
  },

  inputContainer: {
    padding: '10px 15px 15px',
    width: 'inherit',
    fontSize: 14
  },
  labelStyle: {
    fontWeight: 'bold',
    marginBottom: 12,
    textTransform: 'capitalize'
  },

  appleIconStyle: {
    marginLeft: 0
  },
  googleIconStyle: {
    marginRight: 0
  },

  forgotTextStyle: {
    fontSize: 14,
    textAlign: 'left',
    paddingLeft: 14,
    fontWeight: 'bold',
    display: 'flex',
    paddingTop: 10
  },

  otherLoginButton: {
    borderRadius: 22,
    padding: 0,
    width: 80,
    height: 42,
    background: WHITE,
    color: BLACK,
    border: 'gray solid 1px',
    borderColor: GREY,
    borderWidth: 1,
    borderStyle: 'solid',
    margin: '0 6px',
    marginTop: 5
  },

  otherLoginGroup: {
    marginBottom: 25,
    textAlign: 'center'
  },
  signUpSuggestionStyle: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  signUpStyle: {
    color: SEA_BLUE,
    cursor: 'pointer'
  },
  space: {
    height: 12
  },
  loginFormSpaceBottom: {
    paddingBottom: 4
  },
  loadingStyle: {
    margin: '134px 38px',
    color: MARIGOLD
  }
};

export default styles;
