/* @flow */
import { currencyList, currencyDetailList } from '../common/constants';

export const getCountryCode = (localeString: string) => {
  let components = localeString.split('-');
  if (components.length === 2) {
    return components.pop();
  }
  return localeString;
};
export const getCurrency = (locale: string) => {
  const countryCode = getCountryCode(locale).toUpperCase();
  if (countryCode in currencyList) {
    return currencyList[countryCode];
  }
  return 'USD';
};
export const findCurrency = (currencyCode: string) => {
  return currencyDetailList[currencyCode];
};
