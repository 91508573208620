/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import { Flashcard } from '../components/scenes';
import {
  analyticFlashcard,
  setShowTutorial,
  onCloseTutorialMotivate
} from '../actions';
import { getLabels, getUnitContentImageUrl, getLabel } from '../../util';
// import bugTracker from '../../util/BugTracker';
import { getTranscriptionState, getUserCoins } from '../../profile/selectors';
import { getVoiceSpeed } from '../../chatbots/selectors';

const FlashcardContainer = (props: Object) => <Flashcard {...props} />;

export const mapStateToProps = (state: Object) => {
  const { game, flashcard, routes, storage } = state;
  const { wordId, sentenceId } = flashcard;
  const { words, sentences } = game;
  const { showGrammarScreen } = routes;
  const { isShowTutorial, isTargetLangSymbol } = storage;
  const transcript = getTranscriptionState(state);
  const word = words.byId[wordId];
  let sentence = {};
  if (sentenceId === undefined || sentenceId === null) {
    sentence = {};
  } else {
    sentence = sentences.byId[sentenceId];
  }

  const imageSource = getUnitContentImageUrl(wordId);
  const voiceSpeed = getVoiceSpeed(state);
  const coins = getUserCoins(state);

  return {
    coins,
    phrase: { ...word, imageSource },
    sentenceCard: { ...sentence, imageSource },
    isSentenceExist: Boolean(sentenceId),
    transcriptState: transcript,
    isTargetLangSymbol,
    showGrammarScreen: showGrammarScreen,
    ...getLabels(
      state,
      'title_txt_vocabulary',
      'btn_check',
      'btn_continue',
      'btn_hint'
    ),
    flashgame_txt_new_vocabulary: getLabel(
      state,
      'flashgame_txt_new_vocabulary',
      'New Vocabulary'
    ),
    writing_skip: getLabel(state, 'writing_skip', 'Skip'),
    voiceSpeed,
    isShowTutorial
  };
};
export const mapDispatchToProps = (dispatch: Function) => {
  return {
    analyticFlashcard: () => dispatch(analyticFlashcard()),
    setShowTutorial: (value: boolean) => dispatch(setShowTutorial(value)),
    onCloseTutorialMotivate: () => dispatch(onCloseTutorialMotivate())
  };
};

export { FlashcardContainer };
export default connect(mapStateToProps, mapDispatchToProps)(FlashcardContainer);
