// @flow
import moment from 'moment';

export const getCurrentWeekDayMondayBased = (): number => {
  const sundayBasedWeekDay = moment().weekday();
  const isSunday = sundayBasedWeekDay === 0;
  const mondayBasedWeekDay = isSunday ? 6 : sundayBasedWeekDay - 1;
  return mondayBasedWeekDay;
};

export const getCurrentWeekNumber = (): number => {
  return moment().weeks();
};
