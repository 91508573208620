/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import { ThankYouScene } from '../components/scenes/ThankYouScene';
import { getLabel } from '../../util/LabelHelper';
import { triggerLoadLanguageLabel } from '../../common/actions/LanguageActions';
import {
  addLoginListener,
  createNewDatabaseUser,
  saveUserData
} from '../../common/actions/AuthActions';
import { updateUserData } from '../../common/actions/GlobalActions';
import { isUserSubscribed } from '../../profile/actions/UserSelector';
import { bindActionCreators } from 'redux';
import * as TrackerActions from '../../tracker/actions/trackerActions';
import {
  selectMonthlyPrice,
  selectQuarterlyPrice,
  selectYearlyPrice,
  selectMonthlyPriceStripeUk,
  selectQuarterlyPriceStripeUk,
  selectYearlyPriceStripeUk
} from '../../onboarding/selectors';

const ThankYouSceneContainer = props => <ThankYouScene {...props} />;

export const mapStateToProps = (state: Object) => {
  const { shop, storage } = state;
  const { selectedPurchaseProduct } = shop;
  return {
    isUserSubscribed: isUserSubscribed(state),
    afterBuy_txt_title: getLabel(
      state,
      'afterBuy_txt_title',
      'Thank you for your purchase!'
    ),
    afterBuy_txt_body: getLabel(
      state,
      'afterBuy_txt_body',
      'You just unlocked the Premium version.'
    ),
    processing_payment_wait_txt: getLabel(
      state,
      'processing_payment_wait_txt',
      'Please Wait,{indent} We are processing your payment,{indent} do not close the browser or refresh the page. Thank you!'
    ),
    writing_txt_next: getLabel(state, 'writing_txt_next', 'Next'),
    txt_dsc_download_app: getLabel(
      state,
      'txt_dsc_download_app',
      "Download the app and login with the same account you've registered to start your language journey with Ling!"
    ),
    txt_dsc_learning_in_browser: getLabel(
      state,
      'txt_dsc_learning_in_browser',
      'Or you can start learning directly on your browser!'
    ),
    btn_continue_in_browser: getLabel(
      state,
      'btn_continue_in_browser',
      'Continue in browser'
    ),
    selectedPurchaseProduct,
    prices: {
      monthlyPrice: selectMonthlyPrice(state),
      quarterlyPrice: selectQuarterlyPrice(state),
      yearlyPrice: selectYearlyPrice(state),
      monthlyPriceStripeUk: selectMonthlyPriceStripeUk(state),
      quarterlyPriceStripeUk: selectQuarterlyPriceStripeUk(state),
      yearlyPriceStripeUk: selectYearlyPriceStripeUk(state)
    },
    uid: storage.user.uid
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveUserData,
    createNewDatabaseUser,
    updateUserData,
    addLoginListener,
    triggerLoadLanguageLabel,
    tracker: bindActionCreators(TrackerActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThankYouSceneContainer);
