/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Title = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: #454545;
  margin-top: 52px;
  margin-bottom: 20px;
  text-align: center;
`;

type Props = {
  level: number,
  unitLevel_txt_basic: string,
  unitLevel_txt_beginner: string,
  unitLevel_txt_advance: string,
  unitLevel_txt_afterintermediate: string,
  unitLevel_txt_intermediate: string
};

class UnitLevel extends React.PureComponent<Props> {
  render() {
    const {
      level = 0,
      unitLevel_txt_basic,
      unitLevel_txt_beginner,
      unitLevel_txt_advance,
      unitLevel_txt_afterintermediate,
      unitLevel_txt_intermediate
    } = this.props;

    const getName = [
      unitLevel_txt_basic,
      unitLevel_txt_beginner,
      unitLevel_txt_intermediate,
      unitLevel_txt_afterintermediate,
      unitLevel_txt_advance
    ];

    return <Title>{getName[level]}</Title>;
  }
}

export { UnitLevel };
