/* @flow */
import _ from 'lodash';
import * as actions from '../lessons/actions';
import { call, put, select } from 'redux-saga/effects';
import { getCachedData, getData } from './DataSelector';
import { getCourseData } from '../lessons/selectors';
import Analytic from '../util/Analytic';
import bugtracker from './../util/BugTracker';
import { store } from '../';
import {
  loadUnitsData,
  saveUnitsData
} from '../common/actions/LanguageActions';
import {
  getChatbotTopicsInfoData,
  getChatbotPharsesInfoData
} from '../chatbots/selectors';
import {
  loadChatbotsData,
  loadChatbotsName,
  updateCurrent,
  resetChatbot
} from '../chatbots/actions';
import { triggerLoadInitialTutorial } from '../common/actions';
import { SHOW_TUTORIAL, TRUE } from '../common/constants';

export function* getUnit(unitId: number): Object {
  return yield select(getCachedData, unitId);
}

export function* watchLoadUnitData({
  unitId = 1,
  goToNextScene
}: Object): Object {
  const unit = yield call(getUnit, unitId);
  if (unit) {
    yield call(loadUnitIntoState, unitId, unit);
  }
}

export function* loadUnitIntoState(unitId: number, unit: Object): Object {
  try {
    console.log('call load language data ', unit, unitId);
    yield put(loadUnitsData(unit, unitId));
    yield put(saveUnitsData(unitId));
  } catch (error) {
    bugtracker.notify(error);
    console.warn('Error in loadUnitIntoState:', error);
  }
}

//This is called when opening any chatbot
export function* watchLoadChatbotData({
  chatbotTopic = 'chat01'
}: Object): Object {
  const { getState } = store;

  const state = getState();
  yield put(actions.fetchData(chatbotTopic));

  yield put(resetChatbot());
  const { targetLangCode } = yield select(getData);

  Analytic.log(Analytic.key.U2, { content: chatbotTopic });
  Analytic.logGA(Analytic.key.U2, {
    category: Analytic.key.U2,
    label: chatbotTopic
  });
  const parsed = _.get(state.courses, `${targetLangCode}`);

  const chatbotTopicData = yield select(getChatbotTopicsInfoData);

  const allPhrases = _.get(chatbotTopicData, `${chatbotTopic}.allPhrases`, []);
  const PhrasesInfo = yield select(getChatbotPharsesInfoData);

  const mergedChatbot = allPhrases.map(phrase => {
    const phraseData = _.get(PhrasesInfo, phrase, {});
    const { type, unit, lesson, id, order, isBot } = phraseData;

    const unitData = _.get(parsed, `${type}.${unit},${lesson}.byId.${id}`);

    const { original, translation, transcript, soundFile, speaker } = unitData;
    return {
      id,
      order,
      isBot,
      original,
      translation,
      transcript,
      soundFile,
      speaker
    };
  });

  const chatbotInfo = chatbotTopicData[chatbotTopic];
  let label = '';

  try {
    label = chatbotInfo.label;
  } catch (e) {
    console.log(e);
  }

  yield put(loadChatbotsData(mergedChatbot));
  yield put(loadChatbotsName(label));
  // eslint-disable-next-line
  if (localStorage.getItem(SHOW_TUTORIAL) === TRUE) {
    yield put(triggerLoadInitialTutorial());
  }
  yield put(updateCurrent());
}

export function* verifyInMemoryCourseCache(unitId: number): any {
  const { targetLangCode } = yield select(getData);
  const course = yield select(getCourseData, targetLangCode);

  const isUnitCached = isCached(course, unitId);
  return Boolean(isUnitCached);
}
/* Validating Unit */
export const isCached = (course: Object, unitId: number) =>
  Boolean(
    _.chain(course)
      .get('unitsCached')
      .find(item => item === unitId)
      .value()
  );

export const isLanguageChange = (
  course: Object,
  unitId: number,
  nativeLangCode: string
) => {
  const unitCachedLanguage = _.get(course, 'unitCachedLanguage', null);
  const filterId = _.filter(unitCachedLanguage, function(o) {
    return o.byId === unitId;
  });
  const result = _.map(filterId, x => x.langCode);
  const checkResult = _.isEqual(result[0], nativeLangCode);
  return checkResult;
};
