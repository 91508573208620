import _ from 'lodash';

import { start } from '../../games/actions';
import { START_RANDOM_TEST } from '../../games/constants';
import { getPhrases, getAll, getExamOrder } from '../../games/selectors';
import { fetchRandomTestData } from '../../common/actions/LanguageActions';

export const replayRandomTest = () => (
  dispatch: Function,
  getState: Function
) => {
  const state = getState();
  const {
    data: { targetLangCode, nativeLangCode }
  } = state;
  dispatch(fetchRandomTestData(targetLangCode, nativeLangCode, () => {}));
};

export const getRandomTest = (units: Array<string>) => (
  dispatch: Function,
  getState: Function
) => {
  dispatch(start(getRandomTestGame, START_RANDOM_TEST, units, 0));
};

export const getRandomTestGame = (
  { courses }: Object,
  randomTestUnits: Array<number>
) => {
  const allWords = { allIds: [], byId: {} };
  const allSentences = { allIds: [], byId: {} };
  const allDialogues = { allIds: [], byId: {} };

  for (const unitId of randomTestUnits) {
    const data = _.get(courses, courses.activeLangCode, {});
    const { words, sentences, dialogues } = getPhrases(data, getAll(unitId));
    allWords.allIds = allWords.allIds.concat(words.allIds);
    allWords.byId = { ...allWords.byId, ...words.byId };

    allSentences.allIds = allSentences.allIds.concat(sentences.allIds);
    allSentences.byId = { ...allSentences.byId, ...sentences.byId };

    allDialogues.allIds = allDialogues.allIds.concat(dialogues.allIds);
    allDialogues.byId = { ...allDialogues.byId, ...dialogues.byId };
  }

  return {
    words: allWords,
    sentences: allSentences,
    dialogues: allDialogues,
    key: `${randomTestUnits.join('&')}`,
    gameOrder: getExamOrder(
      allWords.allIds,
      allSentences.allIds,
      allDialogues.allIds
    )
  };
};
