/* @flow */
import React, { Component, Fragment } from 'react';
import Linkifier from 'react-linkifier';
import _ from 'lodash';
import {
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Collapse
} from 'reactstrap';
import './../SignUpModal/SignUpModal.css';
import platform from 'platform';
import { PREFIX_LESSON, PREFIX_HEADER } from '../../../../common/constants';
import {
  CollapeDetail,
  CollapeIcon,
  CollapeTitle,
  ContinueStyle,
  ModalBodyContainer,
  ModalBodyText,
  ModalCloseButton,
  OtherArea,
  ToggleButton,
  styles
} from './ReportModal.styles';
import './ReportModal.css';
import moment from 'moment';
import { connect } from 'react-redux';
import Firebase from '../../../../util/Firebase';

const closeIcon = `${PREFIX_LESSON}basecolor-black.svg`;

type Props = {
  isOpen: boolean,
  btn_continue?: string,
  toggleReport: Function,
  nativeLangCode: string,
  targetLangCode: string,
  unitObject: Object,
  user: Object,
  faq: Array<Object>,
  modal_error_other: string,
  modal_error_answer_should_be_accepted: string,
  modal_error_answer_duplicate: string,
  modal_error_image_missing: string,
  modal_error_audio_not_match: string,
  modal_error_audio_missing: string,
  modal_error_audio_not_clear: string,
  modal_error_report_txt: string,
  modal_error_report: string,
  modal_error_faq: string
};

type State = {
  activeTab: string,
  isOtherSelected: boolean,
  reportReason: string
};

const BackArrowIcon = `${PREFIX_HEADER}back-icon.svg`;
class ReportModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      err: false,
      emailErrorMsg: '',
      passwordErrorMsg: '',
      formErrorMsg: '',
      activeTab: '1',
      isOtherSelected: false,
      isFAQOpen1: false,
      isFAQOpen2: false,
      isFAQOpen3: false,
      isFAQOpen4: false,
      isFAQOpen5: false,
      isFAQOpen6: false,
      isFAQOpen7: false,
      isFAQOpen8: false,
      reportReason: ''
    };
  }

  toggleTab = (tab: string) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  toggleFAQ = (FAQState: string) => {
    this.setState(prevState => ({ [FAQState]: !prevState[FAQState] }));
  };

  onSubmit = () => {
    const linebreak = '%0D%0A';
    const { currentUser } = Firebase.auth();
    console.log('currentUser', currentUser);
    const { nativeLangCode, targetLangCode, unitObject, user } = this.props;
    console.log(this.state.reportReason);
    const date = moment();
    const subject = `[Ling Web] Report Tracking ${date.format('X')}`;
    const body = `
    Description: ${this.state.reportReason} ${linebreak}
    System info: ${linebreak}
      Screen Size : ${window.screen.width} x ${
      window.screen.height
    } ${linebreak}
      Browser : ${platform.name} ${linebreak}
      Versions: ${platform.version} ${linebreak}
      Product : ${platform.product} ${linebreak}
      Manufacturer : ${platform.manufacturer} ${linebreak}
      OS : ${platform.os} ${linebreak}
      nativeLangCode : ${nativeLangCode} ${linebreak}
      targetLangCode : ${targetLangCode} ${linebreak}
      UNIT : ${unitObject[0]} ${linebreak}
      LESSON : ${unitObject[1]} ${linebreak}
      User : ${
        currentUser.isAnonymous
          ? 'Anonymous'
          : `${linebreak}
        UID : ${user.uid} ${linebreak}
        name : ${user.name} ${linebreak}`
      }${linebreak}
`;
    window.location.href = `mailto:support@simyasolutions.com?subject=${subject}&body=${body}`;
  };

  changeHandler = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ isOtherSelected: false, reportReason: event.target.value });
  };

  changeOther = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ isOtherSelected: true, reportReason: event.target.value });
  };

  render() {
    const {
      isOpen,
      toggleReport,
      faq,
      btn_continue,
      modal_error_other,
      modal_error_answer_should_be_accepted,
      modal_error_answer_duplicate,
      modal_error_image_missing,
      modal_error_audio_not_match,
      modal_error_audio_missing,
      modal_error_audio_not_clear,
      modal_error_report_txt,
      modal_error_report,
      modal_error_faq
    } = this.props;
    const { modalContainer, modalHeader } = styles;
    const { activeTab } = this.state;
    const closeBtn = (
      <ModalCloseButton className="reportModalClose" onClick={toggleReport}>
        <img src={closeIcon} alt={closeIcon} />
      </ModalCloseButton>
    );

    return (
      <Fragment>
        <Modal
          fade={false}
          isOpen={isOpen}
          style={modalContainer}
          contentClassName="reportContentContainer"
          backdropClassName="reportModalBackdrop"
          centered
        >
          <Fragment>
            <ModalHeader style={modalHeader} external={null} close={closeBtn}>
              {modal_error_report}
            </ModalHeader>
            <ModalBodyContainer>
              <ModalBody>
                <ModalBodyText>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={activeTab === '1' ? 'active' : 'unset'}
                        onClick={() => {
                          this.toggleTab('1');
                        }}
                      >
                        {modal_error_report_txt}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === '2' ? 'active' : 'unset'}
                        onClick={() => {
                          this.toggleTab('2');
                        }}
                      >
                        {modal_error_faq}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <form className="reportErrorForm">
                        <div className="radio">
                          <label>
                            <input
                              name="errorReport"
                              type="radio"
                              value="The audio is not clear"
                              onChange={this.changeHandler}
                            />
                            {modal_error_audio_not_clear}
                          </label>
                        </div>
                        <div className="radio">
                          <label>
                            <input
                              type="radio"
                              name="errorReport"
                              value="The audio is missing"
                              onChange={this.changeHandler}
                            />
                            {modal_error_audio_missing}
                          </label>
                        </div>
                        <div className="radio">
                          <label>
                            <input
                              type="radio"
                              name="errorReport"
                              value="The audio does not match with the text"
                              onChange={this.changeHandler}
                            />
                            {modal_error_audio_not_match}
                          </label>
                        </div>
                        <div className="radio">
                          <label>
                            <input
                              type="radio"
                              name="errorReport"
                              value="An image is missing"
                              onChange={this.changeHandler}
                            />
                            {modal_error_image_missing}
                          </label>
                        </div>
                        <div className="radio">
                          <label>
                            <input
                              type="radio"
                              name="errorReport"
                              value="One or more answers is a duplicate"
                              onChange={this.changeHandler}
                            />
                            {modal_error_answer_duplicate}
                          </label>
                        </div>
                        <div className="radio">
                          <label>
                            <input
                              type="radio"
                              name="errorReport"
                              value="My answer should be accepted"
                              onChange={this.changeHandler}
                            />
                            {modal_error_answer_should_be_accepted}
                          </label>
                        </div>
                        <div className="radio">
                          <label>
                            <input
                              type="radio"
                              name="errorReport"
                              value=""
                              onChange={this.changeOther}
                            />
                            {modal_error_other}
                          </label>
                        </div>
                        {this.state.isOtherSelected ? (
                          <OtherArea
                            onChange={this.changeOther}
                            placeholder="Say something here."
                          />
                        ) : null}
                      </form>
                    </TabPane>
                    <TabPane tabId="2">
                      {!_.isEmpty(faq) ? (
                        faq.map((faqText, index) => {
                          return (
                            <Fragment key={index}>
                              <ToggleButton
                                onClick={() => {
                                  this.toggleFAQ(`isFAQOpen${index + 1}`);
                                }}
                              >
                                <CollapeIcon
                                  src={BackArrowIcon}
                                  arrowDirection={
                                    this.state[`isFAQOpen${index + 1}`]
                                  }
                                />
                                <CollapeTitle>{faqText.title}</CollapeTitle>
                              </ToggleButton>
                              <Collapse
                                isOpen={this.state[`isFAQOpen${index + 1}`]}
                              >
                                <CollapeDetail>
                                  <Linkifier target="_blank">
                                    {faqText.content}
                                  </Linkifier>
                                </CollapeDetail>
                              </Collapse>
                            </Fragment>
                          );
                        })
                      ) : (
                        <div />
                      )}
                    </TabPane>
                  </TabContent>
                </ModalBodyText>
              </ModalBody>
              {this.state.activeTab === '1' ? (
                <ContinueStyle
                  disabled={this.state.reportReason === '' ? true : false}
                  onClick={this.onSubmit}
                >
                  {btn_continue}
                </ContinueStyle>
              ) : (
                <ContinueStyle onClick={toggleReport}>
                  {btn_continue}
                </ContinueStyle>
              )}
            </ModalBodyContainer>
          </Fragment>
        </Modal>
      </Fragment>
    );
  }
}

export const mapStateToProps = (state: Object) => {
  const { data, game, storage } = state;
  const unitObject = game.key.split(',');
  return {
    nativeLangCode: data.nativeLangCode,
    targetLangCode: data.targetLangCode,
    unitObject,
    user: storage.user
  };
};

export default connect(mapStateToProps)(ReportModal);
