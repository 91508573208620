import { Box, FormControl, RadioGroup, Typography } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import StartButton from './subComponents/StartButton';
import PaywallPlanSelection from './subComponents/PaywallPlanSelection';
import PropTypes from 'prop-types';

const PaywallPlans = ({
  headerData,
  selectedValue,
  initiateBuy,
  setSelectedValue,
  priceData,
  labels
}) => {
  const {
    txt_dsc_agree_to_be_charged_monthly,
    txt_dsc_agree_to_be_charged_quarterly,
    txt_dsc_agree_to_be_charged_after_trial,
    btn_get_my_plan
  } = labels;
  const theme = useTheme();

  const styles = {
    page: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    },
    container: {
      mt: 12,
      display: 'flex',
      width: '90%',
      maxWidth: '558px',
      justifyContent: 'center',
      alignItems: 'center'
    },
    displayColumn: {
      display: 'flex',
      flexDirection: 'column'
    },
    image: { width: '112px', height: '48px' },
    headerText: {
      fontSize: '30px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '36px'
      }
    },
    headerSubtext: {
      textAlign: 'center',
      fontSize: '16px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px'
      }
    },
    headerContainer: {
      alignItems: 'center',
      gap: '24px',
      width: '334px'
    },
    selectionContainer: {
      mt: 8,
      width: '100%'
    },
    plansContainer: {
      width: '100%',
      maxWidth: '558px',
      mt: 16
    },
    button: {
      maxWidth: '558px',
      width: '100%',
      mt: 10,
      mb: 6
    },
    disclaimerText: {
      fontSize: '10px',
      color: '#999999'
    },
    link: {
      textDecoration: 'none',
      color: '#999999',
      borderBottom: '1px solid #999999',
      '&:hover': {
        textDecoration: 'none'
      }
    }
  };

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  const disclaimerText = planType => {
    switch (planType) {
      case 'monthly':
        return txt_dsc_agree_to_be_charged_monthly.replace(
          '%s',
          `${priceData.monthly.symbol}${priceData.monthly.price}`
        );
      case 'quarterly':
        return txt_dsc_agree_to_be_charged_quarterly.replace(
          '%s',
          `${priceData.quarterly.symbol}${priceData.quarterly.price}`
        );
      case 'yearly':
        return txt_dsc_agree_to_be_charged_after_trial.replace(
          '%s',
          `${priceData.yearly.symbol}${priceData.yearly.price}`
        );
      default:
        return '';
    }
  };

  return (
    <Box sx={styles.page}>
      <Box sx={[styles.container, styles.displayColumn]}>
        <Box sx={[styles.headerContainer, styles.displayColumn]}>
          <Box component="img" src={headerData.icon} sx={styles.image} />
          <Typography variant="text" sx={styles.headerText}>
            {headerData.text}
          </Typography>
          <Typography variant="subtext" sx={styles.headerSubtext}>
            {headerData.subtext}
          </Typography>
        </Box>
        <FormControl component="fieldset" sx={styles.selectionContainer}>
          <RadioGroup
            aria-label="gender"
            name="gender"
            value={selectedValue}
            onChange={handleChange}
          >
            <Box sx={styles.plansContainer}>
              <PaywallPlanSelection
                selectedValue={selectedValue}
                commonStyle={styles.displayColumn}
                theme={theme}
                priceData={priceData}
                labels={labels}
              />
            </Box>
          </RadioGroup>
        </FormControl>
        <StartButton
          buttonStyle={styles.button}
          text={btn_get_my_plan}
          buttonAction={initiateBuy}
        />
        <Typography variant="subtext" sx={styles.disclaimerText}>
          <a
            href="https://ling-app.com/money-back-guarantee/"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
          >
            {disclaimerText(selectedValue)}
          </a>
        </Typography>
      </Box>
    </Box>
  );
};

export default PaywallPlans;

PaywallPlans.propTypes = {
  headerData: PropTypes.shape({
    text: PropTypes.string,
    subtext: PropTypes.string,
    icon: PropTypes.string,
    target: PropTypes.string
  }),
  selectedValue: PropTypes.string,
  initiateBuy: PropTypes.func,
  setSelectedValue: PropTypes.func,
  priceData: PropTypes.shape({
    yearly: PropTypes.shape({
      price: PropTypes.string,
      currency: PropTypes.string
    }),
    monthly: PropTypes.shape({
      price: PropTypes.string,
      currency: PropTypes.string
    }),
    quarterly: PropTypes.shape({
      price: PropTypes.string,
      currency: PropTypes.string
    })
  }),
  labels: PropTypes.objectOf(PropTypes.string)
};
