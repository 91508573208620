/* @flow */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  PREFIX_HEADER,
  MARIGOLD,
  LIGHT_GREY,
  WHITE,
  DARK_GREY,
  VERY_LIGHT_PINK_TWO,
  WHITE_TWO
} from '../../common/constants';
import { BAR_CONVERSATION_HEIGHT } from '../../games/constants';
import GameScoreContainer from '../../games/containers/GameScoreContainer';
import { LingProgressBar } from '../sub-components';

const PreHeaderConversationContainer = styled.div`
  top: 70px;
  background: ${LIGHT_GREY};
  position: fixed;
  z-index: 99;
  border-bottom: 2px solid ${LIGHT_GREY};
  width: 100vw;
  height: 70px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.02),
    0 7px 20px 0 rgba(0, 0, 0, 0.03);
background-image: linear-gradient(top, ${WHITE_TWO}), ${WHITE});

`;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const HeaderConversationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  height: 100%;
  max-width: 1024px;
`;

const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const Image = styled.img`
  width: 18px;
  height: 32px;
`;

const BarContainer = styled.div`
  width: 100%;
  margin: 0 30px;
`;

const HeaderConversationTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${DARK_GREY};
  margin-bottom: 10px !important;
  margin-top: 0px !important;
`;

type State = {
  isScrolled: boolean
};

type Props = {
  isSrollable: boolean,
  bananas: number,
  coins: number,
  name: string,
  photoURL: string,
  profile_txt_nameGuest: string,
  flagUri: string,
  history: Object,
  createAccount_txt_title: string,
  notification_text_confirm: string,
  notification_text_cancel: string,
  login_msg_loseProgressDialog: string,
  logout_txt: string,
  signOut: Function,
  signInWithEmailAndPassword: Function,
  signInWithFacebook: Function,
  signInWithGoogle: Function,
  signUpWithEmail: Function,
  sendPasswordResetEmail: Function,
  signInAnonymously: Function,
  toggleCreateAccount: Function,
  email_error_matchPasswords: string,
  email_error_fieldsMissing: string,
  email_error_longerPassword: string,
  email_error_wrongFormat: string,
  login_field_name: string,
  login_field_firstName: string,
  login_msg_reenterPassword: string,
  login_field_email: string,
  login_field_password: string,
  email_txt_loginNow: string,
  reset_password_txt_description: string,
  btn_continue: Function,
  sign_up_txt_title: string,
  login_txt_title: string,
  email_txt_loginNow_link: string,
  already_register_txt: string,
  login_txt_forget_password_title: string,
  login_txt_forget_password_web: string,
  dont_have_account_yet_txt: string,
  at_least_6_password_txt: string,
  email_error_alreadyUsed: string,
  email_error_userNotFound: string,
  email_error_wrongPassword: string,
  setting_txt_settingHeader: string,
  modal_error_faq: string,
  profile_txt_headerProfile: string,
  location: Object,
  footer_txt_shop: string,
  footer_txt_learn: string,
  footer_txt_chatbot: string,
  onRef: Function,
  hasTranscript: boolean,
  isExam: boolean,
  progress: number,
  progressMax: number,
  translationState: boolean
};

class PreHeaderConversation extends Component<Props, State> {
  child: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      isScrolled: false
    };
  }
  componentDidMount() {
    try {
      this.props.onRef(this);
    } catch (error) {}
    if (!this.props.isSrollable) {
      this.setState({ isScrolled: true });
    }
    window.addEventListener('scroll', this.listenScrollEvent);
  }
  componentWillUnmount() {
    try {
      this.props.onRef(undefined);
    } catch (error) {}
  }
  gotoHome() {
    this.props.history.push('/');
  }
  toggleCreateAccount() {
    this.child.toggleCreateAccount();
  }
  listenScrollEvent = (e: SyntheticEvent<Event>) => {
    if (this.props.isSrollable) {
      if (window.scrollY > 20) {
        this.setState({ isScrolled: true });
      } else {
        this.setState({ isScrolled: false });
      }
    }
  };

  goToBack() {
    this.props.history.goBack();
  }

  render() {
    const { name, progress, progressMax, hasTranscript } = this.props;

    return (
      <PreHeaderConversationContainer>
        <Container>
          <HeaderConversationContainer>
            <BackButton
              id="backBtn"
              onClick={() => {
                this.goToBack();
              }}
            >
              <Image src={`${PREFIX_HEADER}back-icon.svg`} />
            </BackButton>
            <BarContainer>
              <HeaderConversationTitle>{name}</HeaderConversationTitle>
              <LingProgressBar
                backgroundColor={VERY_LIGHT_PINK_TWO}
                color={MARIGOLD}
                progress={progress}
                progressMax={progressMax}
                barHeight={BAR_CONVERSATION_HEIGHT}
              />
            </BarContainer>
            <GameScoreContainer
              hasTranscript={hasTranscript}
              isGameHeader={true}
              isExam={true}
              screen={'conversation'}
            />
          </HeaderConversationContainer>
        </Container>
      </PreHeaderConversationContainer>
    );
  }
}

const PreHeaderConversationWithRouter = withRouter(PreHeaderConversation);
export { PreHeaderConversationWithRouter };
