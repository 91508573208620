/* @flow */
import axios from 'axios';
import stripe, { StripeUK } from './PurchaseUtils';
import _ from 'lodash';
import {
  redirectProfileSceneAfterPurchased,
  purchaseFailed,
  toggleDeleteAccount
} from './';
import { CARD_PAID, CARD_SUCCEEDED, PAST_DUE } from '../common/constants';
import {
  SIGNIN_METHOD,
  PROVIDER_FACEBOOK,
  PROVIDER_APPLE,
  PROVIDER_GOOGLE,
  PROVIDER_EMAIL
} from '../profile/constants';

export const createPaymentMethodAndCustomerShop = async (
  cardNumber: Object,
  user: Object,
  isDev: boolean,
  history: Object,
  currency: string,
  productId: string,
  isUpdatingCard: boolean,
  isLoginPurchase: boolean,
  productName: string,
  functions: string,
  stateChangeHandler: Function,
  isOverridingTrialFromPlan: boolean = false
) => {
  let userEmail = getUserEmail(user);
  const uid = _.isEmpty(user.uid) ? user.anonymous.id : user.uid;

  if (_.isEmpty(userEmail)) {
    stateChangeHandler('isLoginPurchase', !isLoginPurchase);
  } else {
    stripe
      .createPaymentMethod({
        type: 'card',
        card: cardNumber,
        billing_details: { email: userEmail }
      })
      .then(result => {
        stateChangeHandler('isUpdatingCard', !isUpdatingCard);

        createCustomerShop(
          result.paymentMethod.id,
          userEmail,
          isDev,
          history,
          currency,
          productId,
          isUpdatingCard,
          productName,
          functions,
          stateChangeHandler,
          { firebaseUserID: uid },
          isOverridingTrialFromPlan
        );
      });
  }
};

export const createCustomerShop = async (
  paymentMethod: string,
  cardholderEmail: string,
  isDev: boolean,
  history: Object,
  currency: string,
  productId: string,
  isUpdatingCard: boolean,
  productName: string,
  functions: string,
  stateChangeHandler: Function,
  metadata: Object,
  isOverridingTrialFromPlan: boolean
) => {
  axios
    .post(
      `${functions}/validateLingWeb-stripeApi/create-customer/${productName}`,
      {
        email: cardholderEmail,
        payment_method: paymentMethod,
        priceId: productId,
        currency,
        metadata,
        isOverridingTrialFromPlan
      }
    )
    .then(subscription => {
      handleSubscriptionShop(
        subscription,
        isDev,
        history,
        currency,
        isUpdatingCard,
        productName,
        stateChangeHandler
      );
    })
    .catch(error => {
      console.log('error: do parsing', error);
    });
};

export const handleSubscriptionShop = (
  subscription: Object,
  isDev: boolean,
  history: Object,
  currency: string,
  isUpdatingCard: boolean,
  productName: string,
  stateChangeHandler: Function
) => {
  const { data } = subscription;
  const { code } = data;
  if (code === 200) {
    const { invoice, subscription } = data;
    if (invoice) {
      const { status, id } = invoice;
      if (status === CARD_PAID) {
        redirectProfileSceneAfterPurchased(
          history,
          productName,
          id,
          isDev,
          currency
        );
      }
    } else {
      const { status, id } = subscription;
      if (status === CARD_SUCCEEDED) {
        redirectProfileSceneAfterPurchased(
          history,
          productName,
          id,
          isDev,
          currency
        );
      }
    }
  } else {
    const { raw } = data;
    const { message } = raw;
    alert(message);
    stateChangeHandler('isUpdatingCard', !isUpdatingCard);
    purchaseFailed();
  }
};

export const afterDeleteAcc = (
  functions: string,
  subscription: Object,
  stateChangeHandler: Function
) => {
  const activeSubscription = _.get(
    subscription,
    `purchaseHistory.${subscription.activeSubscription.split('.').join('_')}`,
    false
  );
  console.log('activeSubscription', activeSubscription);
  if (activeSubscription.type === 'stripe') {
    axios
      .get(
        `${functions}validateLingWeb-stripeApi/${activeSubscription.ReceiptData}`
      )
      .then(res => {
        if (res.data.code !== 400) {
          stateChangeHandler('subscriptionData', {
            ...res.data,
            type: activeSubscription.type
          });
        }
      });
  } else {
    stateChangeHandler('subscriptionData', activeSubscription.type);
  }
};

export const onReactivateSubscription = (
  subscriptionData: Object,
  functions: string,
  isDeleteSuccess: boolean,
  user: Object,
  stateChangeHandler: Function
) => {
  const { subscription } = user;
  axios
    .post(`${functions}validateLingWeb-stripeApi/resubscribed`, {
      subscription: subscriptionData.id
    })
    .then(res => {
      const isResponseStatusError = res.data.code !== 400;
      stateChangeHandler('subscriptionData', {});

      if (isResponseStatusError) {
        const activeSubscription = _.get(
          subscription,
          `purchaseHistory.${subscription.activeSubscription
            .split('.')
            .join('_')}`,
          false
        );
        axios
          .get(
            `${functions}validateLingWeb-stripeApi/${activeSubscription.ReceiptData}`
          )
          .then(res => {
            if (isResponseStatusError) {
              stateChangeHandler('subscriptionData', {
                ...res.data,
                type: activeSubscription.type
              });
            }
          });
        stateChangeHandler('isDeleteSuccess', !isDeleteSuccess);
      }
    });
};

export const getActiveSubscription = (subscription: Object) =>
  _.get(
    subscription,
    `purchaseHistory.${subscription.activeSubscription.split('.').join('_')}`,
    false
  );

export const updateSubscription = (
  functions: string,
  isUpdatingCard: boolean,
  isUpdateMethod: boolean,
  user: Object,
  manageSceneStateChangeHandler: Function,
  paymentID: string,
  subscriptionData: Object
) => {
  axios
    .post(`${functions}validateLingWeb-stripeApi/payment/updatePayment`, {
      subid: subscriptionData.id,
      paymentid: paymentID
    })
    .then(response => {
      updatePaymentMethod(
        true,
        functions,
        isUpdatingCard,
        isUpdateMethod,
        user,
        manageSceneStateChangeHandler
      );
      console.log(response);
    })
    .catch(error => {
      console.log('error: do parsing', error);
    });
};

export const updatePaymentMethod = (
  isUpdate: boolean = false,
  functions: string,
  isUpdatingCard: boolean,
  isUpdateMethod: boolean,
  user: Object,
  manageSceneStateChangeHandler: Function
) => {
  const { subscription } = user;
  if (!isUpdate) {
    manageSceneStateChangeHandler('isUpdateMethod', !isUpdateMethod);
  }
  if (isUpdate) {
    manageSceneStateChangeHandler('state.cardName', '');
    manageSceneStateChangeHandler('subscriptionData', {});
    manageSceneStateChangeHandler('isUpdateMethod', false);
    manageSceneStateChangeHandler('isUpdatingCard', false);
    manageSceneStateChangeHandler('touched', {});
    onUpdatePaymentMethod(
      functions,
      isUpdatingCard,
      subscription,
      manageSceneStateChangeHandler
    );
  }
};

export const onUpdatePaymentMethod = (
  functions: string,
  isUpdatingCard: boolean,
  subscription: Object,
  stateChangeHandler: Function
) => {
  const activeSubscription = getActiveSubscription(subscription);
  axios
    .get(
      `${functions}validateLingWeb-stripeApi/${activeSubscription.ReceiptData}`
    )
    .then(res => {
      if (res.data.code !== 400) {
        stateChangeHandler('subscriptionData', {
          ...res.data,
          type: activeSubscription.type
        });
      }
    });
};

export const onCancelMethod = (
  subscriptionData: Object,
  functions: string,
  isDeleteSuccess: boolean,
  stateChangeHandler: Function,
  subscriptionStatus: string,
  updateUserProfile: Function
) => {
  console.log('onCancelMethod');
  if (subscriptionData.type === 'stripe') {
    const subscription = subscriptionData;
    console.log(subscription);
    axios
      .post(`${functions}validateLingWeb-stripeApi/unsubscribed`, {
        subscription: {
          ...subscription
        }
      })
      .then(res => {
        console.log('res', res);
        if (subscriptionStatus === PAST_DUE) {
          updateUserProfile({ isProUser: false });
        }
        stateChangeHandler('isDeleteSuccess', !isDeleteSuccess);
      });
  }
};

export const onAfterDeleteAcc = (
  user: Object,
  functions: string,
  isDeleteAcc: boolean,
  manageSceneStateChangeHandler: Function
) => {
  const { subscription } = user;
  afterDeleteAcc(functions, subscription, manageSceneStateChangeHandler);
  toggleDeleteAccount(isDeleteAcc, manageSceneStateChangeHandler);
};

export const getUserEmail = (user: Object) => {
  const { facebook, google, email, apple } = user;
  // eslint-disable-next-line
  const signin_method = localStorage.getItem(SIGNIN_METHOD);
  let userEmail = '';
  switch (signin_method) {
    case PROVIDER_FACEBOOK:
      userEmail = facebook.email;
      break;
    case PROVIDER_APPLE:
      userEmail = apple.email;
      break;
    case PROVIDER_GOOGLE:
      userEmail = google.email;
      break;
    case PROVIDER_EMAIL:
      userEmail = email.email;
      break;
    default:
      break;
  }
  return userEmail;
};

export const fetchPayPalPrices = async (priceIds: string[] = []) => {
  const requestBody = { priceIds };
  const response = await axios.post(
    `${process.env.REACT_APP_FUNCTIONS}/stripeUkApi/get-prices`,
    requestBody,
    {
      headers: {
        'x-auth': process.env.REACT_APP_FUNCTIONS_STRIPE_UK_AUTH_HEADER,
        'Content-Type': 'application/json'
      }
    }
  );
  return response.data;
};

export const setupPaypal = async (user: Object) => {
  const email = getUserEmail(user);
  if (email.length === 0) {
    const message = 'No email is set for this user, cannot setup Paypal!';
    console.error(message);
    alert(message);
    return;
  }
  const uid = user.uid ?? user.anonymous.id;

  const requestBody = { email, metadata: { firebaseUserID: uid } };
  const response = await axios.post(
    `${process.env.REACT_APP_FUNCTIONS}/stripeUkApi/paypal/create-client-secret`,
    requestBody,
    {
      headers: {
        'x-auth': process.env.REACT_APP_FUNCTIONS_STRIPE_UK_AUTH_HEADER,
        'Content-Type': 'application/json'
      }
    }
  );
  const { clientSecret } = response.data;
  const { error } = await StripeUK.confirmPayPalSetup(clientSecret, {
    // Note: this is supposed to be 'return_url: `${window.location.origin}/thanks?checkoutType=paypal`'
    // But Firebase Hosting refresh everything and prevent the page from functioning properly,
    // so we need to go the the / page briefly first.
    return_url: `${window.location.origin}${
      // Only on production, we have '/learn'
      process.env.REACT_APP_DEV === 'true' ? '' : '/learn'
    }?checkoutType=paypal`,
    mandate_data: {
      customer_acceptance: {
        type: 'online',
        online: {
          infer_from_client: true
        }
      }
    }
  });
  if (error) {
    const message = 'Error setting up Paypal, please try again!';
    console.error(message, error);
    alert(message);
  }
};

export const createPaypalSubscription = async (
  uid: string,
  setupIntentId: string,
  priceId: string
) => {
  const requestBody = {
    uid,
    setupIntentId,
    priceId
  };
  const response = await axios.post(
    `${process.env.REACT_APP_FUNCTIONS}/stripeUkApi/paypal/create-subscription`,
    requestBody,
    {
      headers: {
        'x-auth': process.env.REACT_APP_FUNCTIONS_STRIPE_UK_AUTH_HEADER,
        'Content-Type': 'application/json'
      }
    }
  );
  return response.data; // { success, error }
};
