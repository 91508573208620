/* @flow */
import { connect } from 'react-redux';
import ProfileScene from '../components/scenes/ProfileScene';
import { getNameAndEmail, getUser, isUserSubscribed } from '../selectors';
import { triggerFetchLeaderboard } from '../actions/AccountActions';
import {
  updateUserData,
  setTrialUsedIP,
  updateUserProfile
} from '../../common/actions';
import _ from 'lodash';
import {
  getAchievement,
  getLevelChampion,
  getBigSpender,
  getDiligentMaster,
  getOverAchiever,
  getWealthy,
  getDescriptionList
} from '../actions';
import {
  getCurrentUserGoalData,
  getCurrentWeekDayMondayBased,
  getLabel,
  saveDailyGoal
} from '../../util';

export const mapStateToProps = (state: Object) => {
  const { storage, stats } = state;
  const { leaders } = stats;
  const { user } = storage;
  const goal = getCurrentUserGoalData(state);
  const { isSetDailyGoal = null, userDailyGoal, weeklyGraphData } = goal;
  const todayWeekday = getCurrentWeekDayMondayBased();
  const arrayIndex = todayWeekday === 0 ? 6 : todayWeekday - 1;
  const dailyBananas = weeklyGraphData[arrayIndex];
  const yearly = process.env.REACT_APP_STRIPE_YEARLY_USD;
  const yearly_eur = process.env.REACT_APP_STRIPE_YEARLY_EUR;
  const yearly_pound = process.env.REACT_APP_STRIPE_YEARLY_GBP;
  const yearly_thb = process.env.REACT_APP_STRIPE_YEARLY_THB;
  const yearly_asd = process.env.REACT_APP_STRIPE_YEARLY_ASD;
  const yearly_rub = process.env.REACT_APP_STRIPE_YEARLY_RUB;
  const yearly_without_trial =
    process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_USD;
  const yearly_eur_without_trial =
    process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_EUR;
  const yearly_pound_without_trial =
    process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_POUND;
  const yearly_thb_without_trial =
    process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_THB;
  const yearly_asd_without_trial =
    process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_ASD;
  const yearly_rub_without_trial =
    process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_RUB;
  const isDev = process.env.REACT_APP_DEV;
  const functions = process.env.REACT_APP_FUNCTIONS;
  const currentLeader = _.find(leaders, item => item.key === user.uid);
  let rank = 0;
  if (currentLeader) {
    rank = currentLeader.index;
  }
  let userRank = Number(rank) > 0 ? rank : '?';
  return {
    user,
    goal,
    yearly,
    yearly_eur,
    yearly_pound,
    yearly_thb,
    yearly_rub,
    yearly_asd,
    yearly_without_trial,
    yearly_eur_without_trial,
    yearly_pound_without_trial,
    yearly_thb_without_trial,
    yearly_asd_without_trial,
    yearly_rub_without_trial,
    isDev,
    isSetDailyGoal,
    userDailyGoal,
    weeklyGraphData,
    dailyBananas,
    functions,
    userRank,
    isProUser: isUserSubscribed(state),
    ...getUser(state),
    ...getAchievement(state),
    levelChamp: getLevelChampion(state),
    levelSpender: getBigSpender(state),
    levelDiligent: getDiligentMaster(state),
    levelAchiever: getOverAchiever(state),
    levelWealthy: getWealthy(state),
    ...getDescriptionList(state),
    userInfo: getNameAndEmail(storage),
    seeMore: getLabel(state, 'txt_see_more', 'See more'),
    profile_txt_shop_promo: getLabel(
      state,
      'profile_txt_shop_promo',
      'profile_txt_shop_promo'
    ),
    profile_overview_txt: getLabel(
      state,
      'profile_overview_txt',
      'Overview(BP)'
    ),
    profile_set_your_goal_txt: getLabel(
      state,
      'profile_set_your_goal_txt',
      'Set your daily goal'
    ),
    invite_friend_txt: getLabel(state, 'invite_friend_txt', 'Invite Friend'),
    purchase_txt_benefit_details_01: getLabel(
      state,
      'purchase_txt_benefit_details_01',
      'purchase_txt_benefit_details_01'
    ),

    shop_txt_7_day_trial: getLabel(
      state,
      'shop_txt_7_day_trial',
      '7 Days Free Trial'
    ),
    profile_txt_manage_subcription: getLabel(
      state,
      'profile_txt_manage_subcription',
      'Manage subscription'
    ),
    profile_txt_follow_us: getLabel(
      state,
      'profile_txt_follow_us',
      'Follow Us'
    ),
    profile_info_txt: getLabel(state, 'profile_info_txt', 'info'),
    profile_txt_viewAll: getLabel(state, 'profile_txt_viewAll', 'view all'),
    random_test_modal_back_txt: getLabel(
      state,
      'random_test_modal_back_txt',
      'Back'
    ),
    profile_txt_card_number: getLabel(
      state,
      'profile_txt_card_number',
      'Card Number'
    ),
    profile_txt_expire_date: getLabel(
      state,
      'profile_txt_expire_date',
      'Expiration date'
    ),
    profile_txt_name_card: getLabel(
      state,
      'profile_txt_name_card',
      'Name on card'
    ),
    profile_txt_terms_condition: getLabel(
      state,
      'profile_txt_terms_condition',
      'Terms & Conditions'
    ),

    btn_submit: getLabel(state, 'btn_submit', 'Submit'),
    shop_txt_checkout_title: getLabel(
      state,
      'shop_txt_checkout_title',
      `We’ll only use this if you continue
after your trial end!`
    ),
    shop_txt_checkout_title_top: getLabel(
      state,
      'shop_txt_checkout_title_top',
      'Ling Premium 60 Languages'
    ),
    shop_txt_checkout_title_yearly: getLabel(
      state,
      'shop_txt_checkout_title_yearly',
      'Yearly Subscription'
    ),
    profile_txt_recurring_billing: getLabel(
      state,
      'profile_txt_recurring_billing',
      'Recurring billing, cancel anytime.'
    ),
    invite_friend_txt_get_free_premium: getLabel(
      state,
      'invite_friend_txt_get_free_premium',
      'Get Ling Premium Version for free!'
    ),
    invite_friend_txt_premium_desc: getLabel(
      state,
      'invite_friend_txt_premium_desc',
      'Refer friends for full access to 50 Units in all languages'
    ),
    achievement_txt_title: getLabel(
      state,
      'achievement_txt_title',
      'Achievements'
    ),
    loginPurchase_txt: getLabel(
      state,
      'loginPurchase_txt',
      'Create a profile to get power - ups, bonus skills, and more!'
    ),
    createAccount_txt_title: getLabel(
      state,
      'createAccount_txt_title',
      'create account'
    ),
    login_field_name: getLabel(state, 'login_field_name', 'Name'),
    card_number: getLabel(state, 'card_number', 'Card number'),
    card_expiration: getLabel(state, 'card_expiration', 'MM/YY'),
    card_error_empty: getLabel(
      state,
      'card_error_empty',
      'Name cannot be empty.'
    ),
    card_error_number_not_correct: getLabel(
      state,
      'card_error_number_not_correct',
      "Card number doesn't seem correct."
    ),
    card_error_invalid_date: getLabel(
      state,
      'card_error_invalid_date',
      'Invalid card date'
    ),
    card_error_invalid_cvc: getLabel(
      state,
      'card_error_invalid_cvc',
      'Invalid CVC'
    ),
    trial_date_description_txt: getLabel(
      state,
      'trial_date_description_txt',
      'After your trial ends, you will be charged {d} per year starting {date} . You can always cancel before then.'
    ),
    email_error_empty: getLabel(
      state,
      'email_error_empty',
      'Email cannot be empty.'
    ),
    purchase_button_subscribe_now: getLabel(
      state,
      'purchase_button_subscribe_now',
      'Subscribe Now!'
    ),
    email_error_wrongFormat: getLabel(
      state,
      'email_error_wrongFormat',
      'The email address is badly formatted.'
    ),
    login_field_email: getLabel(state, 'login_field_email', 'Email'),
    my_daily_goal_txt: getLabel(state, 'my_daily_goal_txt', 'My daily goal'),
    edit_profile_save_txt: getLabel(state, 'edit_profile_save_txt', 'save'),
    profile_txt_on: getLabel(state, 'profile_txt_on', 'On'),
    profile_txt_past_due_msg: getLabel(
      state,
      'profile_txt_past_due_msg',
      'Your payment is overdue. Please resolve your outstanding balance or update the payment method.'
    ),
    profile_txt_1_year_ling_pro: getLabel(
      state,
      'profile_txt_1_year_ling_pro',
      '1 year of Ling Pro'
    ),
    profile_txt_1_month_ling_pro: getLabel(
      state,
      'profile_txt_1_month_ling_pro',
      '1 month of Ling Pro'
    ),
    profile_txt_charged: getLabel(
      state,
      'profile_txt_charged',
      'will be charged'
    ),
    profile_txt_for: getLabel(state, 'profile_txt_for', 'for'),
    profile_txt_of: getLabel(state, 'profile_txt_of', 'of')
  };
};
export default connect(mapStateToProps, {
  triggerFetchLeaderboard,
  updateUserData,
  saveDailyGoal,
  setTrialUsedIP,
  updateUserProfile
})(ProfileScene);
