/* @flow */
import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import {
  DARK_GREEN_COLOR,
  GREEN_COLOR,
  DARK_RED_COLOR,
  RED_COLOR,
  DARK_YELLOW,
  MARIGOLD,
  WHITE,
  LIGHT_RED_COLOR,
  LIGHT_GREEN_COLOR,
  LIGHT_GREY_2,
  GREY2,
  LIGHT_GREY,
  SCREEN_SIZE,
  LIGHT_RED_2,
  LIGHT_GREEN_2,
  PERCENT_SIGN
} from '../../../common/constants';
import {
  BUTTON_CONTINUE,
  BUTTON_DISABLED,
  BUTTON_CHECK,
  BUTTON_SPEAKER,
  BUTTON_CHECK_DISABLED,
  BUTTON_CANCEL,
  BUTTON_CONTINUE_DISABLED,
  FLASHCARD,
  SPELLING,
  QUIZ,
  MATCHING,
  ORDER_SENTENCE_REVERSE,
  ORDER_SENTENCE,
  TRANSLATEGAME,
  DIALOGUE,
  DIALOGUE_GAME,
  CORRECT_SENTENCE,
  GRAMMAR,
  CONVERSATION,
  BUTTON_SEND,
  BUTTON_SEND_DISABLED,
  SPEAKING,
  WRITING
} from '../../constants';
import { quizStatus, PREFIX_CHATBOT } from '../../../common/constants/';
import { HintButton } from './HintButton';
import { ResultText } from './ResultText';
import { CorrectAnswer } from './CorrectAnswer';
import { DialogReplayButton } from './DialogReplayButton';
import { DialogTranslateButton } from './DialogTranslateButton';
import { SkipButton } from './SkipButton';

const isNotGameType = (gameType: string) =>
  gameType !== QUIZ &&
  gameType !== MATCHING &&
  gameType !== SPELLING &&
  gameType !== ORDER_SENTENCE &&
  gameType !== TRANSLATEGAME &&
  gameType !== DIALOGUE &&
  gameType !== DIALOGUE_GAME &&
  gameType !== ORDER_SENTENCE_REVERSE;

const ButtonContainer = styled.button`
  min-width: 160px;
  height: 55px;
  border-radius: 20px;
  margin-right: ${props => (props.isShowTutorial ? '26px' : 'unset')};
  box-shadow: inset 0 -4px 0 0 ${props => (props.isSpecificColor ? DARK_YELLOW : props.borderColor)};
  border: solid 1px
    ${props => (props.isSpecificColor ? DARK_YELLOW : props.borderColor)};
  background-color: ${props =>
    props.isSpecificColor ? MARIGOLD : props.color};
  opacity: ${props => props.opacity};
  &:hover:enabled {
    box-shadow: none;
    opacity: 0.4;
    border-color: ${props => {
      if (props.quizAnswer === quizStatus.NO_MATCH) {
        return DARK_RED_COLOR;
      } else if (props.quizAnswer === quizStatus.MATCH) {
        return DARK_GREEN_COLOR;
      } else {
        if (props.isSpecificColor) {
          return MARIGOLD;
        } else {
          return props.color;
        }
      }
    }};
  }
  &:active:enabled {
    box-shadow: none;
    background: ${props => {
      if (props.quizAnswer === quizStatus.NO_MATCH) {
        return DARK_RED_COLOR;
      } else if (props.quizAnswer === quizStatus.MATCH) {
        return DARK_GREEN_COLOR;
      } else {
        if (props.isSpecificColor) {
          return MARIGOLD;
        }
        return props.color;
      }
    }};
    opacity: 1;
  }
`;

const CheckButtonText = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${WHITE};
  margin: 0 !important;
`;

const PercentileContainer = styled.div`
  width: 90px;
  height: 90px;
  border: solid 1px ${props => (props.isCorrect ? GREEN_COLOR : RED_COLOR)};
  border-radius: 50%;
  background: ${props => (props.isCorrect ? LIGHT_GREEN_2 : LIGHT_RED_2)};
  position: relative;
`;

const PercentileNum = styled.p`
  position: absolute;
  right: 50%;
  top: 50%;
  margin: 0 !important;
  transform: translate(50%, -50%);
  color: ${props => (props.isCorrect ? GREEN_COLOR : RED_COLOR)};
  font-size: 24px;
`;

const AnswerContainer = styled.div`
  display: flex;
  ${'' /* justify-content: ${props =>
    props.isShowTutorial
      ? props.gameType === ORDER_SENTENCE_REVERSE
        ? 'space-between'
        : 'flex-end'
      : 'space-between'}; */}
  justify-content:space-between;
  flex: 1;
  align-items: center;
  min-height: 100px;
  max-width: 1024px;
  margin: auto;
  @media (max-width: ${SCREEN_SIZE.big}) {
    margin: auto 20px;
  }
`;

const CheckButtonContainer = styled.div`
  position: fixed;
  z-index: ${props => (props.mode === 'BUTTON_CONTINUE' ? 2 : 1)};
  bottom: 0;
  left: 0;
  background-color: ${props => props.backgroundColor};
  height: 110px;
  width: ${props => (props.isShowTutorial ? '680px' : '100%')};
  border-top: 2px solid ${LIGHT_GREY};
`;

const RightButtonContainer = styled.div`
  display: ${props =>
    (!props.isShowTutorial &&
      !props.isResultShown &&
      isNotGameType(props.gameType)) ||
    (props.gameType === DIALOGUE_GAME && props.isShowTutorial)
      ? 'none'
      : 'flex'};
`;

const CenterButtonContainer = styled.div`
  display: ${props =>
    (props.isShowTutorial && !props.isResultShown) ||
    (props.gameType === DIALOGUE_GAME && props.isShowTutorial)
      ? 'none'
      : 'flex'};
`;

type Props = {
  onPressNext: Function,
  mode: string,
  diffButt: boolean,
  btn_continue: string,
  btn_check: string,
  quizAnswer: Object,
  spendHint: Function,
  btn_hint: string,
  hintButtonEnabled: boolean,
  gameType: string,
  answeredWrong: Object,
  isResultShown: boolean,
  correctAnswer: Object[],
  byId: Object,
  isShowCorrect: boolean,
  playing: boolean,
  onStopSound: Function,
  onReplay: Function,
  dialog_btn_stop: string,
  writing_txt_replay: string,
  onTranslate: Function,
  dialog_btn_translate: string,
  translatePic: string,
  translateState: boolean,
  hintMode: boolean,
  coins: number,
  isFinish: boolean,
  percentile: number,
  isShowTutorial: boolean,
  writing_skip: string,
  onCloseTutorialMotivate: Function,
  setShowTutorial: Function
};

const sendIcon = `${PREFIX_CHATBOT}rocket-send.svg`;

const CheckButton = ({
  onPressNext,
  mode = BUTTON_CHECK,
  diffButt,
  btn_continue,
  btn_check,
  quizAnswer = {},
  spendHint,
  btn_hint,
  hintButtonEnabled,
  gameType,
  answeredWrong,
  isResultShown,
  correctAnswer,
  byId,
  isShowCorrect,
  playing,
  onStopSound,
  onReplay,
  dialog_btn_stop,
  writing_txt_replay,
  onTranslate,
  dialog_btn_translate,
  translatePic,
  translateState,
  hintMode,
  coins,
  isFinish,
  percentile,
  isShowTutorial,
  writing_skip,
  onCloseTutorialMotivate,
  setShowTutorial
}: Props) => {
  let status = '',
    backgroundColor = WHITE,
    borderColor = DARK_GREEN_COLOR,
    color = GREEN_COLOR,
    opacity = 1;
  switch (mode) {
    case BUTTON_CHECK:
      status = btn_check;
      break;
    case BUTTON_DISABLED:
      status = btn_continue;
      borderColor = GREY2;
      color = LIGHT_GREY_2;
      break;
    case BUTTON_CANCEL:
      break;
    case BUTTON_CONTINUE:
      status = btn_continue;
      break;
    case BUTTON_CONTINUE_DISABLED:
      status = btn_continue;
      borderColor = GREY2;
      color = LIGHT_GREY_2;
      break;
    case BUTTON_SPEAKER:
      break;
    case BUTTON_CHECK_DISABLED:
      status = btn_check;
      borderColor = GREY2;
      color = LIGHT_GREY_2;
      break;
    case BUTTON_SEND_DISABLED:
      status = btn_check;
      borderColor = GREY2;
      color = LIGHT_GREY_2;
      opacity = 0.4;
      break;
    default:
      break;
  }
  if (quizAnswer.status === quizStatus.NO_MATCH) {
    borderColor = DARK_RED_COLOR;
    backgroundColor = LIGHT_RED_COLOR;
    color = RED_COLOR;
  } else if (quizAnswer.status === quizStatus.MATCH) {
    borderColor = DARK_GREEN_COLOR;
    backgroundColor = LIGHT_GREEN_COLOR;
    color = GREEN_COLOR;
  }

  if (answeredWrong === true && isResultShown) {
    borderColor = DARK_RED_COLOR;
    backgroundColor = LIGHT_RED_COLOR;
    color = RED_COLOR;
  } else if (answeredWrong === false && isResultShown) {
    borderColor = DARK_GREEN_COLOR;
    backgroundColor = LIGHT_GREEN_COLOR;
    color = GREEN_COLOR;
  }

  if (
    answeredWrong === true &&
    (gameType === TRANSLATEGAME || gameType === CORRECT_SENTENCE) &&
    mode === BUTTON_CONTINUE
  ) {
    borderColor = DARK_RED_COLOR;
    backgroundColor = LIGHT_RED_COLOR;
    color = RED_COLOR;
  } else if (
    answeredWrong === false &&
    (gameType === TRANSLATEGAME || gameType === CORRECT_SENTENCE) &&
    mode === BUTTON_CONTINUE
  ) {
    borderColor = DARK_GREEN_COLOR;
    backgroundColor = LIGHT_GREEN_COLOR;
    color = GREEN_COLOR;
  }

  return (
    <CheckButtonContainer
      isShowTutorial={isShowTutorial}
      backgroundColor={backgroundColor}
      mode={mode}
    >
      <AnswerContainer isShowTutorial={isShowTutorial} gameType={gameType}>
        {gameType === CONVERSATION ? null : (
          <RightButtonContainer
            gameType={gameType}
            isResultShown={isResultShown}
            isShowTutorial={isShowTutorial}
          >
            {getBottomButton(
              mode,
              spendHint,
              hintButtonEnabled,
              btn_hint,
              quizAnswer,
              answeredWrong,
              gameType,
              hintMode,
              coins,
              percentile,
              isResultShown,
              isShowTutorial,
              writing_skip,
              onCloseTutorialMotivate,
              setShowTutorial
            )}
            {gameType !== DIALOGUE_GAME && (
              <CorrectAnswer
                answeredWrong={answeredWrong}
                isResultShown={isResultShown}
                correctAnswer={correctAnswer}
                byId={byId}
              />
            )}
          </RightButtonContainer>
        )}

        {getMiddleButton(
          gameType,
          onTranslate,
          dialog_btn_translate,
          playing,
          onStopSound,
          onReplay,
          dialog_btn_stop,
          writing_txt_replay,
          translatePic,
          translateState,
          isShowTutorial
        )}

        {getCheckButton(
          mode,
          onPressNext,
          color,
          borderColor,
          quizAnswer,
          status,
          gameType,
          opacity,
          isResultShown,
          isShowTutorial
        )}
      </AnswerContainer>
    </CheckButtonContainer>
  );
};

const getMiddleButton = (
  gameType,
  onTranslate,
  dialog_btn_translate,
  playing,
  onStopSound,
  onReplay,
  dialog_btn_stop,
  writing_txt_replay,
  translatePic,
  translateState,
  isShowTutorial
) => {
  if (gameType === DIALOGUE || gameType === CONVERSATION) {
    return (
      <CenterButtonContainer isShowTutorial={isShowTutorial}>
        <DialogReplayButton
          playing={playing}
          onStopSound={onStopSound}
          onReplay={onReplay}
          dialog_btn_stop={dialog_btn_stop || ''}
          writing_txt_replay={writing_txt_replay || ''}
          isReview={false}
        />
        <DialogTranslateButton
          onTranslate={onTranslate}
          translatePic={translatePic}
          dialog_btn_translate={dialog_btn_translate}
          translateState={translateState}
        />
      </CenterButtonContainer>
    );
  }
  return <div />;
};

const getCheckButton = (
  mode,
  onPressNext,
  color,
  borderColor,
  quizAnswer,
  status,
  gameType,
  opacity,
  isResultShown,
  isShowTutorial
) => {
  return (
    <ButtonContainer
      disabled={
        mode === BUTTON_DISABLED ||
        mode === BUTTON_CHECK_DISABLED ||
        mode === BUTTON_CONTINUE_DISABLED ||
        mode === BUTTON_SEND_DISABLED
      }
      onClick={onPressNext}
      color={color}
      isSpecificColor={isSpecificColor(gameType, mode, isResultShown)}
      borderColor={borderColor}
      quizAnswer={quizAnswer.status}
      gameType={gameType}
      mode={mode}
      opacity={opacity}
      isShowTutorial={isShowTutorial}
    >
      <CheckButtonText>
        {' '}
        {mode === BUTTON_SEND || mode === BUTTON_SEND_DISABLED ? (
          <img src={sendIcon} alt={sendIcon} />
        ) : (
          status
        )}
      </CheckButtonText>
    </ButtonContainer>
  );
};

const getBottomButton = (
  mode,
  spendHint,
  hintButtonEnabled,
  btn_hint,
  quizAnswer,
  answeredWrong,
  gameType,
  hintMode,
  coins,
  percentile,
  isResultShown,
  isShowTutorial,
  writing_skip,
  onCloseTutorialMotivate,
  setShowTutorial
) => {
  if (isShowTutorial) {
    return (
      _.isEmpty(quizAnswer) &&
      !isResultShown && (
        <SkipButton
          writing_skip={writing_skip}
          onCloseTutorialMotivate={onCloseTutorialMotivate}
          setShowTutorial={setShowTutorial}
        />
      )
    );
  } else {
    if (
      (mode !== BUTTON_CONTINUE &&
        _.isEmpty(quizAnswer) === true &&
        gameType !== GRAMMAR &&
        gameType !== CONVERSATION) ||
      gameType === DIALOGUE ||
      (gameType === MATCHING && mode === BUTTON_CONTINUE) ||
      (gameType === SPEAKING && mode === BUTTON_CONTINUE && !isResultShown) ||
      (gameType === WRITING && mode === BUTTON_CONTINUE)
    ) {
      if (gameType === QUIZ) {
        hintButtonEnabled = false;
      }
      return (
        <HintButton
          onPress={spendHint}
          hintButtonEnabled={hintButtonEnabled}
          btn_hint={btn_hint}
          hintMode={hintMode}
          coins={coins}
        />
      );
    } else {
      if (
        (quizAnswer !== '' &&
          answeredWrong !== null &&
          gameType !== GRAMMAR &&
          gameType !== SPEAKING &&
          gameType !== WRITING &&
          gameType !== CONVERSATION) ||
        gameType === MATCHING ||
        gameType === SPELLING ||
        gameType === ORDER_SENTENCE_REVERSE ||
        gameType === ORDER_SENTENCE ||
        gameType === TRANSLATEGAME ||
        gameType === CORRECT_SENTENCE ||
        gameType === DIALOGUE_GAME
      ) {
        return (
          <ResultText
            gameType={gameType}
            quizAnswer={quizAnswer}
            answeredWrong={answeredWrong}
          />
        );
      } else if (
        gameType === SPEAKING &&
        mode === BUTTON_CONTINUE &&
        isResultShown
      ) {
        return (
          <PercentileContainer isCorrect={percentile === 100}>
            <PercentileNum isCorrect={percentile === 100}>
              {percentile}
              {PERCENT_SIGN}
            </PercentileNum>
          </PercentileContainer>
        );
      }
      return <div />;
    }
  }
};

const isSpecificColor = (gameType, mode, isResultShown) => {
  return gameType === FLASHCARD ||
    gameType === DIALOGUE ||
    (gameType === DIALOGUE_GAME && mode === BUTTON_CHECK) ||
    (gameType === TRANSLATEGAME && mode === BUTTON_CHECK) ||
    (gameType === CORRECT_SENTENCE && mode === BUTTON_CHECK) ||
    (gameType === QUIZ && mode === BUTTON_CHECK) ||
    (gameType === SPELLING && mode === BUTTON_CHECK) ||
    (gameType === ORDER_SENTENCE && mode === BUTTON_CHECK) ||
    (gameType === ORDER_SENTENCE_REVERSE && mode === BUTTON_CHECK) ||
    (gameType === GRAMMAR && mode === BUTTON_CONTINUE) ||
    (gameType === CONVERSATION && mode === BUTTON_SEND) ||
    (gameType === CONVERSATION && mode === BUTTON_CONTINUE) ||
    (gameType === SPEAKING && mode === BUTTON_CONTINUE && !isResultShown) ||
    (gameType === WRITING && mode === BUTTON_CONTINUE)
    ? true
    : false;
};

export { CheckButton };
