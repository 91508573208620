import { LOAD_OVERVIEW } from '../constants';
import _ from 'lodash';
import { getLabel } from '../../util/LabelHelper';

export const triggerLoadOverview = () => (
  dispatch: Function,
  getState: Function
) => {
  const state = getState();
  let countGrammar = 0;
  const { lessons: lessonData, units: unitData, stats, courses, data } = state;
  const countObject = stats.units;
  const { unitId } = lessonData;
  const dataCount = countObject[unitId];

  try {
    const grammars =
      courses[courses['activeLangCode']].grammar[data.courses.native][unitId];
    if (grammars) {
      countGrammar = _.size(grammars.titles.allIds);
    }
  } catch (e) {}

  const collection = {
    title: getLabel(
      state,
      (unitData.units[unitId - 1] && unitData.units[unitId - 1].title) ||
        'Introduction'
    ),
    countObject: _.sumBy(dataCount, 'wordCount'),
    countGrammar: countGrammar,
    countDialogue: _.sumBy(dataCount, o => {
      if (o && o.dialogueCount > 0) {
        return 1;
      }
    })
  };
  dispatch(loadOverview(collection));
};

export const loadOverview = (unitOverview: Object) => ({
  type: LOAD_OVERVIEW,
  unitOverview
});
