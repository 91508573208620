/* @flow */
import _ from 'lodash';
import fp from 'lodash/fp';
import * as types from '../constants';
import {
  quizStatus,
  QUIZ,
  TRANSLATEGAME,
  CORRECT_SENTENCE,
  REFRESH_GAME,
  CLOSE_GAME,
  LISTENING
} from '../constants';
import type { State as RoutesState } from './GamescreenReducer';
import type { State as GameState } from './GameReducer';
import {
  BUTTON_CHECK_DISABLED,
  BUTTON_CHECK,
  BUTTON_CONTINUE
} from '../constants';
import { getImageUrl } from '../../util';

type Card = {
  id: string,
  status: string,
  correct: boolean
};

type ById = {
  [cardId: any]: Card
};

type AllIds = number[];

export type State = {
  byId: ById,
  allIds: AllIds,
  frozen: boolean,
  answeredWrong: boolean
};

const INITIAL_STATE = {
  byId: {},
  allIds: [],
  frozen: false,
  answeredWrong: false
};

const loadQuiz = (state: State, { wordIds, correctWord }: Object): State => {
  const byId = wordIds.reduce(
    (byId, id, i) => ({
      ...byId,
      [i + 1]: {
        id,
        status: quizStatus.VISIBLE,
        correct: correctWord === id
      }
    }),
    {}
  );

  return {
    byId,
    allIds: [1, 2, 3, 4],
    frozen: false,
    answeredWrong: false
  };
};

const loadCorrectSentenceGame = (
  state: State,
  { byId, allIds }: Object
): State => {
  return {
    ...state,
    byId: _.mapValues(byId, word => ({ ...word, status: 'VISIBLE' })),
    allIds,
    frozen: false,
    answeredWrong: false
  };
};

const getCard = (
  byId: ById,
  predicate: Card => boolean
): { key: any, card: Card } => {
  const key = _.findKey(byId, predicate);
  return {
    key,
    card: byId[key]
  };
};

const pressQuizCard = (state, { wordId }) => {
  const { byId } = state;
  const { card: pressedCard, key: pressedCardId } = getCard(
    byId,
    ({ id }) => id === wordId
  );

  return {
    ...state,
    byId: {
      ...fp.mapValues(card => ({ ...card, status: quizStatus.VISIBLE }), byId),
      [pressedCardId]: {
        ...pressedCard,
        status:
          pressedCard.status === quizStatus.SELECTED
            ? quizStatus.VISIBLE
            : quizStatus.SELECTED
      }
    }
  };
};

const checkQuizResult = state => {
  const { byId } = state;
  const selected = fp.compose(
    fp.head,
    fp.toPairs,
    fp.pickBy(card => card.status === quizStatus.SELECTED)
  )(byId);

  const selectedCardId = fp.head(selected);
  const selectedCard = fp.last(selected);

  const { card: correctCard, key: correctCardId } = getCard(
    byId,
    ({ correct }) => correct
  );

  return {
    ...state,
    byId: {
      ...byId,
      [correctCardId]: {
        ...correctCard,
        status: quizStatus.MATCH
      },
      // $flow-disable-line
      [selectedCardId]: {
        ...selectedCard,
        status: selectedCard.correct ? quizStatus.MATCH : quizStatus.NO_MATCH
      }
    },
    frozen: true,
    answeredWrong: !selectedCard.correct
  };
};
export const QuizReducer = (
  state: State = INITIAL_STATE,
  action: Object
): State => {
  const { type } = action;
  switch (type) {
    case CLOSE_GAME:
      return INITIAL_STATE;
    case REFRESH_GAME:
      if ([QUIZ, TRANSLATEGAME, LISTENING].includes(action.gameType)) {
        return loadQuiz(state, action);
      } else if (action.gameType === CORRECT_SENTENCE) {
        return loadCorrectSentenceGame(state, action);
      } else {
        return state;
      }
    case types.PRESS_QUIZ_CARD:
      return pressQuizCard(state, action);
    case types.CHECK_QUIZ_RESULT:
      return checkQuizResult(state);
    default:
      return state;
  }
};

export type QuizCardProps = {
  id: string,
  status: string,
  soundFile: string,
  original: string,
  translation: string,
  imageSource: string,
  transcript: string
};

export type ByIdProps = {
  [cardId: number]: QuizCardProps
};

export type AllIdsProps = number[];

export type QuizProps = {
  byId: ByIdProps | Object[],
  allIds: AllIdsProps,
  correctWord: Object,
  getQuizAnimation: (QuizCardProps, boolean) => ?string
};

const ANIMATIONS = {
  [quizStatus.MATCH]: 'pulse',
  [quizStatus.NO_MATCH]: 'pulse'
};

export const getQuizAnimation = (
  card: QuizCardProps,
  loadAnimationFinished: boolean
): ?string => {
  if (loadAnimationFinished) {
    return ANIMATIONS[card.status];
  }
  return 'fadeIn';
};

export const getCorrectSentenceCards = (
  { byId, allIds, answeredWrong }: State,
  game: GameState,
  routes: RoutesState
): QuizProps => ({
  byId: _.map(allIds, id => byId[id]),
  allIds,
  getQuizAnimation,
  correctWord: byId[2],
  mode: getMode(byId, answeredWrong),
  isAnswerWrong: answeredWrong,
  loadAnimationFinished: routes.loadAnimationFinished,
  transcriptState: routes.transcript
});

export const getQuizCards = (
  { byId, allIds, answeredWrong, frozen }: State,
  game: GameState,
  routes: RoutesState,
  isTranslateGame: boolean = false
): QuizProps => ({
  byId: isTranslateGame
    ? getNewByIdTranslateGame(byId, game)
    : getNewById(byId, game),
  allIds,
  correctWord: isTranslateGame
    ? getCorrectSentence(byId, game)
    : getCorrectWord(byId, game),
  getQuizAnimation,
  mode: getMode(byId, answeredWrong),
  isAnswerWrong: answeredWrong,
  loadAnimationFinished: routes.loadAnimationFinished,
  transcriptState: routes.transcript,
  frozen: frozen
});

const getMode = (byId: Object, isAnswerWrong: boolean) => {
  if (fp.every(({ status }) => status === quizStatus.VISIBLE, byId)) {
    return BUTTON_CHECK_DISABLED;
  } else if (_.some(byId, ({ status }) => status === quizStatus.SELECTED)) {
    return BUTTON_CHECK;
  } else {
    return BUTTON_CONTINUE;
  }
};
export const getCorrectWord = (byId: Object, game: Object) => {
  const { byId: gameById } = game.words;
  if (!gameById) {
    // throw 'QuizReducer: game.byId must not be null!';
    console.warn('QuizReducer: game.byId must not be null!');
  }
  const { translation, soundFile } = gameById[
    // $FlowFixMe
    fp.find(({ correct }) => correct, byId).id
  ];
  return { translation, soundFile };
};

export const getNewById = (byId: Object, game: Object) => {
  return _.mapValues(byId, card => {
    const word = game.words.byId[card.id];
    const { soundFile, original, imageSource, transcript } = word;
    const { id, status } = card;
    return {
      id,
      status,
      soundFile,
      original,
      imageSource: getImageUrl(imageSource),
      transcript
    };
  });
};
export const getNewByIdTranslateGame = (byId: Object, game: Object) => {
  return _.mapValues(byId, card => {
    const sentence = game.sentences.byId[card.id];
    const { translation } = sentence;
    const { id, status } = card;
    return {
      id,
      status,
      translation
    };
  });
};
export const getCorrectSentence = (byId: Object, game: Object) => {
  const { original, soundFile, transcript } = game.sentences.byId[
    // $FlowFixMe
    fp.find(({ correct }) => correct, byId).id
  ];
  return { original, soundFile, transcript };
};
