import { Box, Typography } from '@mui/material';
import React from 'react';
import { IMAGE_PATH } from '../../../common/constants';
import StartButton from './subComponents/StartButton';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

const Guarantees = ({ targetLang, initiateBuy, userSkillLevel, labels }) => {
  const {
    txt_dsc_personalized_plan,
    unitLevel_txt_basic,
    txt_elementary_level,
    unitLevel_txt_beginner,
    unitLevel_txt_intermediate,
    unitLevel_txt_advance,
    txt_money_back_guarantee,
    txt_dsc_money_back_guarantee,
    txt_money_back_policy,
    txt_grow_your_skills,
    btn_get_my_plan
  } = labels;
  const imgPath = image => `${IMAGE_PATH}/onboarding/${image}`;
  const theme = useTheme();

  const text = {
    1: txt_dsc_personalized_plan
      .replace('%s', unitLevel_txt_basic)
      .replace('%s', txt_elementary_level),
    2: txt_dsc_personalized_plan
      .replace('%s', txt_elementary_level)
      .replace('%s', unitLevel_txt_beginner),
    3: txt_dsc_personalized_plan
      .replace('%s', unitLevel_txt_beginner)
      .replace('%s', unitLevel_txt_intermediate),
    4: txt_dsc_personalized_plan
      .replace('%s', unitLevel_txt_intermediate)
      .replace('%s', unitLevel_txt_advance),
    5: txt_dsc_personalized_plan
      .replace('%s', unitLevel_txt_advance)
      .replace('%s', targetLang)
  };

  const styles = {
    container: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      flexDirection: 'column',
      mt: 40
    },
    boxContainer: {
      width: '95%',
      display: 'flex',
      justifyContent: 'center',
      mb: 12,
      flexWrap: 'wrap',
      [theme.breakpoints.up('sm')]: {
        flexWrap: 'nowrap'
      }
    },
    box: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: '500px',
      backgroundColor: '#E6FCD9',
      p: 10,
      borderRadius: 6,
      m: 3
    },
    leftBox: {
      backgroundColor: '#E6FCD9'
    },
    rightBox: {
      backgroundColor: '#FFF9EB'
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      mt: 8
    },
    imgLeft: {
      width: '100%'
    },
    imgRight: {
      width: '100%'
    },
    text: {
      fontSize: '24px',
      fontWeight: 600,
      textAlign: 'left',
      [theme.breakpoints.up('sm')]: {
        fontSize: '30px'
      }
    },
    subtext: {
      fontSize: '16px',
      pt: 5,
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px'
      }
    },
    button: {
      width: '210px'
    },
    link: {
      textDecoration: 'none',
      color: '#595959',
      borderBottom: '1px solid #595959',
      marginLeft: 5,
      '&:hover': {
        textDecoration: 'none'
      }
    }
  };
  return (
    <Box sx={styles.container}>
      <Box sx={styles.boxContainer}>
        <Box sx={[styles.box, styles.leftBox]}>
          <Box
            component="img"
            src={imgPath('money-back-guarantee.png')}
            sx={styles.imgLeft}
          />
          <Box sx={styles.textContainer}>
            <Typography variant="text" sx={styles.text}>
              {txt_money_back_guarantee}
            </Typography>
            <Typography variant="subtext" sx={styles.subtext}>
              {txt_dsc_money_back_guarantee}
              <a
                href="https://ling-app.com/money-back-guarantee/"
                target="_blank"
                rel="noopener noreferrer"
                style={styles.link}
              >
                {' '}
                {txt_money_back_policy}
              </a>
              .
            </Typography>
          </Box>
        </Box>
        <Box sx={[styles.box, styles.rightBox]}>
          <Box
            component="img"
            src={imgPath('grow-skills.png')}
            sx={styles.imgRight}
          />
          <Box sx={styles.textContainer}>
            <Typography variant="text" sx={styles.text}>
              {txt_grow_your_skills.replace('%s', targetLang)}
            </Typography>
            <Typography variant="subtext" sx={styles.subtext}>
              {text[userSkillLevel]}
            </Typography>
          </Box>
        </Box>
      </Box>
      <StartButton
        buttonStyle={styles.button}
        text={btn_get_my_plan}
        buttonAction={initiateBuy}
      />
    </Box>
  );
};

export default Guarantees;

Guarantees.propTypes = {
  targetLang: PropTypes.string,
  initiateBuy: PropTypes.func
};
