/* @flow */
// labelSagas from App
import _ from 'lodash';
import axios from 'axios';
import { firebase } from '../../system/Firebase';
import { mergeLanguagesAPI } from '../contents/ContentActions';
import {
  SAVE_LANGUAGE_DATA,
  LOAD_UNITS_DATA,
  SAVE_UNITS_DATA,
  TRIGGER_LOAD_LANGUAGE_DATA,
  LOAD_LANG_CODES_INDEX,
  SET_LANGUAGE,
  FETCH_UNITS_DATA,
  TRIGGER_LOAD_LANGUAGE_LABEL,
  FETCH_UNITS_DATA_RANDOM_TEST
} from '../constants/ActionTypes';
import { call, put, select } from 'redux-saga/effects';
import {
  fetchUnitMetaData,
  fetchRemoteDatabase,
  loadTargetLanguages
} from '../../lessons/sagas';

import { getData, getNativeLangCode } from '../../lessons/selectors';
import BugTracker from '../../util/BugTracker';
import {
  downloadFeaturesIntoStateForTargetLanguage,
  downloadFeaturesIntoStateForNativeLanguage
} from '../../lessons/sagas/LanguageFeaturesSagas';
import * as actions from '../../lessons/actions';
import {
  FETCH_UNIT_META_DATA,
  LOAD_ALL_TARGET_LANGUAGES,
  LOAD_LANGUAGE_DATA
} from '../../lessons/constants';
import {
  fetchData,
  saveUnitCache,
  loadTargetLangCodes
} from '../../lessons/actions';
import { verifyInMemoryCourseCache } from '../../sagas/LanguageSaga';
import { toggleLoadingParam, setReminders } from './GlobalActions';
import { loadFirebasePromotion } from './../../shop/sagas';
import { doLoadRemoteConfig } from '../../lessons/sagas/RemoteConfigSagas';
import { getUserProgress, getListFinishedUnit } from '../../profile/selectors';
import { startRandomTest } from '../../games/actions';
import { TUTORIAL_KEY } from '../../games/constants';
import {
  LOCAL_CHATBOTS,
  LOCAL_LABELS,
  LOCAL_DIALOGUE_HEADERS
} from '../constants';

// startApp from App

export function* startWeb({
  target,
  native
}: {
  target: string,
  native: string
}): Object {
  const data = yield select(getData);
  const unitId = TUTORIAL_KEY;
  try {
    yield call(doLoadInitialLabels);
    yield call(fetchUnitMetaData, data);
    yield call(doLoadRemoteConfig, true);
    yield call(loadLanguageData, { target, native, unitId });
    yield put(actions.fetchData(unitId));
    yield call(downloadFeaturesIntoStateForTargetLanguage);
    yield call(downloadFeaturesIntoStateForNativeLanguage);
    yield call(loadFAQ);
    yield call(loadFirebasePromotion);
    yield call(loadAllTargetLanguages);
    yield put(actions.cancelDownload());
  } catch (err) {
    BugTracker.notify(err);
    console.error(err);
    //Ignore itunes connect error
    if (
      err &&
      ![
        'Cannot connect to iTunes Store',
        'The operation couldn’t be completed. (SKErrorDomain error 0.)'
      ].includes(err.message)
    ) {
      BugTracker.notify(err);
    }
  }
}

export function* loadLanguageData({
  target,
  native,
  unitId,
  afterCallback = () => {}
}: Object): Object {
  const isUnitCached = yield call(verifyInMemoryCourseCache, unitId);
  if (!isUnitCached) {
    yield put(toggleLoadingParam(true));
    yield put(fetchData(unitId));
    const merge = yield call(mergeLanguagesAPI, target, native, unitId);
    if (unitId && unitId !== TUTORIAL_KEY) {
      yield put(saveLanguageData(merge, target));
    } else {
      yield put(saveLanguageData(merge['mergedTarget'], target));
    }
    yield put(saveUnitCache(unitId));
    yield put(toggleLoadingParam(false));
  }
  if (afterCallback) {
    afterCallback();
  }
}

export function* loadAllTargetLanguages(): Object {
  const targetLanguages = yield call(loadTargetLanguages);
  yield put(loadTargetLangCodes(targetLanguages));
}

export function* loadLanguageDataRandomTest({
  target,
  native,
  unitId,
  randomTestUnits,
  afterCallback = () => {}
}: Object): Object {
  const isUnitCached = yield call(verifyInMemoryCourseCache, unitId);
  if (!isUnitCached) {
    yield put(toggleLoadingParam(true));
    yield put(fetchData(unitId));
    const merge = yield call(mergeLanguagesAPI, target, native, unitId);
    if (unitId && unitId !== TUTORIAL_KEY) {
      yield put(saveLanguageData(merge, target));
    } else {
      yield put(saveLanguageData(merge['mergedTarget'], target));
    }
    yield put(saveUnitCache(unitId));
    yield put(toggleLoadingParam(false));
  }
  if (afterCallback) {
    yield put(startRandomTest(randomTestUnits));
    afterCallback();
  }
}

export function* doLoadInitialLabels(): Object {
  const newNativeLangCode = yield select(getNativeLangCode);

  // const dialogHeader = yield call(loadDialogHeaders);
  const labels = yield call(fetchLabels, newNativeLangCode);
  yield put(actions.loadLabels(labels));

  const chatbotsLabels = yield call(fetchChatbots);
  yield put(actions.loadChatbots(chatbotsLabels));

  const dialogHeaderLabels = yield call(fetchDialogHeaders);
  yield put(actions.loadDialogHeaders(dialogHeaderLabels));
}

export const fetchDialogHeaders = () => {
  return axios.get(LOCAL_DIALOGUE_HEADERS).then(response => {
    return response.data;
  });
};

export const fetchChatbots = () => {
  return axios.get(LOCAL_CHATBOTS).then(response => {
    return response.data;
  });
};

export const doLoadFAQ = (): Promise<any> => {
  return new Promise(resolve => {
    firebase
      .database()
      .ref('data/faq')
      .on('value', resolve);
  });
};

export function* loadFAQ(): Object {
  const snapshot = yield call(doLoadFAQ);

  const data = snapshot.val();

  if (data) {
    yield put(actions.updateFAQ(data));
  }
}

export const fetchLabels = (nativeLangCode: string) => {
  return axios.get(`${LOCAL_LABELS}/${nativeLangCode}.json`).then(response => {
    return response.data;
  });
};

export const triggerLoadLanguageData = (target: string, native: string) => (
  dispatch: Function
) => {
  dispatch({
    type: TRIGGER_LOAD_LANGUAGE_DATA,
    target,
    native
  });
};

export const triggerFetchUnitMetaData = () => (dispatch: Function) => {
  dispatch({
    type: FETCH_UNIT_META_DATA
  });
};

export const triggerLoadAllTargetLanguages = () => (dispatch: Function) => {
  dispatch({
    type: LOAD_ALL_TARGET_LANGUAGES
  });
};

export const triggerLoadLanguageLabel = () => (dispatch: Function) => {
  dispatch({
    type: TRIGGER_LOAD_LANGUAGE_LABEL
  });
};

export const fetchLanguageData = () => (dispatch: Function) => {
  return fetchRemoteDatabase('data/languageFeatures/byId').then(data => {
    dispatch({
      type: LOAD_LANG_CODES_INDEX,
      langs: data.val()
    });
  });
};

export const setLanguageData = (target: string, native: string) => (
  dispatch: Function,
  getState: Function
) => {
  const state = getState();
  const { user } = state.storage;
  const clientToken = user.clientToken ? user.clientToken : false;
  if (clientToken) {
    firebase
      .database()
      .ref(`reminders/${user.uid}`)
      .set({
        ...user.reminders,
        nativeCode: native
      })
      .catch(error => console.log('Update User Data:', { error }));
    dispatch(
      setReminders({
        ...user.reminders,
        nativeCode: native
      })
    );
  }
  dispatch({
    type: SET_LANGUAGE,
    target: target,
    native: native
  });
};

export const saveLanguageData = (merge: Object, target: string) => (
  dispatch: Function
) => {
  dispatch({ type: SAVE_LANGUAGE_DATA, data: merge, target });
};

export const loadUnitsData = (data: Object, unitId: number) => ({
  type: LOAD_LANGUAGE_DATA,
  data,
  unitId
});

export const saveUnitsData = (unitid: number) => (dispatch: Function) => {
  dispatch({
    type: SAVE_UNITS_DATA,
    lesson: {
      unitid: unitid
    }
  });
};

export const loadUNITSData = (unitId: number) => {
  return {
    type: LOAD_UNITS_DATA,
    unitId
  };
};

export const fetchUnitData = (
  target: string,
  native: string,
  unitId?: number,
  afterCallback?: Function
) => (dispatch: Function) => {
  dispatch({
    type: FETCH_UNITS_DATA,
    target,
    native,
    unitId,
    afterCallback
  });
};
export const fetchRandomTestData = (
  target: string,
  native: string,
  afterCallback: Function
) => (dispatch: Function, getState: Function) => {
  const state = getState();
  const { courses } = state;
  const userProgress = getUserProgress(state);
  const listFinished = getListFinishedUnit(userProgress);
  const randomTestUnits = _.sampleSize(listFinished, 2);
  const data = _.get(courses, courses.activeLangCode, {});
  let isUnitCached = false;
  let isNotCachedUnitId = -1;

  for (const unit of randomTestUnits) {
    if (_.indexOf(data.unitsCached, unit) !== -1) {
      isUnitCached = true;
    } else {
      isNotCachedUnitId = unit;
    }
  }
  if (isUnitCached) {
    dispatch(startRandomTest(randomTestUnits));
    afterCallback();
  } else {
    dispatch({
      type: FETCH_UNITS_DATA_RANDOM_TEST,
      target,
      native,
      unitId: isNotCachedUnitId,
      randomTestUnits,
      afterCallback
    });
  }
};

export const getUnitName = (unitId: number) =>
  unitId < 10 ? `unit0${unitId}` : `unit${unitId}`;

export const mutliStringReplace = (object: Object, string: string) => {
  let val = string;
  let entries = Object.entries(object);
  entries.filter(para => {
    let find = '{' + para[0] + '}';
    let regExp = new RegExp(find, 'g');
    //$FlowFixMe
    val = val.replace(regExp, para[1]);
    return val;
  });
  return val;
};

export const isLanguageRightToLeft = (langcode: string) => {
  const rightToLeftLanguage = [
    'ar',
    'arc',
    'dv',
    'fa',
    'ha',
    'he',
    'khw',
    'ks',
    'ku',
    'ps',
    'ur',
    'yi'
  ];
  return rightToLeftLanguage.indexOf(langcode) > -1;
};
