/* @flow */

import { messaging } from './Firebase';
export const requestFirebaseNotificationPermission = (): Promise<any> =>
  new Promise((resolve, reject) => {
    messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .then(firebaseToken => {
        resolve(firebaseToken);
      })
      .catch(err => {
        reject(err);
      });
  });

export const onMessageListener = (): Promise<any> =>
  new Promise(resolve => {
    messaging.onMessage(payload => {
      resolve(payload);
    });
  });

export const getToken = () => {
  messaging.onTokenRefresh(() => {
    messaging
      .getToken()
      .then(refreshedToken => {
        console.log('browserToken', refreshedToken);
        setTokenSentToServer(false);
        sendTokenToServer(refreshedToken);
      })
      .catch(err => {
        console.log('Unable to retrieve refreshed token ', err);
      });
  });
};

const setTokenSentToServer = sent => {
  window.localStorage.setItem('sentToServer', sent ? '1' : '0');
};

export const sendTokenToServer = (currentToken: string) => {
  if (!isTokenSentToServer()) {
    console.log('Sending token to server...');
    setTokenSentToServer(true);
    return currentToken;
  } else {
    console.log(
      "Token already sent to server so won't send it again " +
        'unless it changes'
    );
    return false;
  }
};

export const isTokenSentToServer = () => {
  return window.localStorage.getItem('sentToServer') === '1';
};
