/* @flow */
import { TOGGLE_FLASHCARD, REFRESH_GAME, CLOSE_GAME } from '../constants';
import type { FlashcardState as State } from '../types';
export type { FlashcardState as State } from '../types';

const INITIAL_STATE: State = {
  wordId: '',
  sentenceId: '',
  showSentence: false
};

const loadFlashcard = ({ wordId, sentenceId }) => ({
  wordId,
  sentenceId,
  showSentence: false
});

const toggleFlashcard = (state: State) => ({
  ...state,
  showSentence: !state.showSentence
});

export const FlashcardReducer = (
  state: State = INITIAL_STATE,
  action: Object
): State => {
  switch (action.type) {
    case CLOSE_GAME:
      return INITIAL_STATE;
    case REFRESH_GAME:
      return loadFlashcard(action);
    case TOGGLE_FLASHCARD:
      return toggleFlashcard(state);
    default:
      return state;
  }
};
