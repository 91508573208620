/* @flow */
import styled from 'styled-components';
import React, { Fragment } from 'react';
import {
  LIGHT_GREY,
  PREFIX_LESSON,
  WHITE,
  SLASH_SIGN
} from '../../../common/constants';
import { DialogTranslateButton } from '../../../games/components/sub-components/DialogTranslateButton';
import { DIALOGUE } from '../../../games/constants';
import { DialogReplayButton } from '../../../games/components/sub-components/DialogReplayButton';

type Props = {
  size: number,
  index: number,
  prevUnit: Function,
  nextUnit: Function,
  onTranslate: Function,
  translatePic: string,
  dialog_btn_translate: string,
  translateState: boolean,
  screen: string,
  writing_txt_replay: string,
  dialog_btn_stop: string,
  onStopSound: Function,
  onReplay: Function,
  pauseDialog: Function,
  playing: boolean,
  isReview: boolean
};

type State = {};

const FooterReviewContainer = styled.div`
  position: fixed;
  bottom: 0;
  background: ${WHITE};
  height: 110px;
  width: 100%;
  border-top: 2px solid ${LIGHT_GREY};
  padding-left: 40px;
  padding-right: 40px;
`;

const FooterContentContainer = styled.div`
  display: flex;
  max-width: 1024px;
  align-items: center;
  margin: auto;
`;

const FooterReviewContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 0.6;
  align-items: center;
  min-height: 100px;
  max-width: 344px;
  margin: auto;
`;

const RightContent = styled.div`
  flex: 0.2;
`;
const LeftContent = styled.div`
  flex: 0.2;
  display: flex;
`;

const PreviousButton = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

const NextButton = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

const nextIcon = `${PREFIX_LESSON}icon-next@2x.png`;
const previousIcon = `${PREFIX_LESSON}icon-back@2x.png`;
class FooterReview extends React.Component<Props, State> {
  render() {
    const {
      index,
      size,
      prevUnit,
      nextUnit,
      onTranslate,
      translatePic,
      dialog_btn_translate,
      translateState,
      screen,
      dialog_btn_stop,
      writing_txt_replay,
      onStopSound,
      onReplay,
      playing,
      isReview
    } = this.props;
    return (
      <Fragment>
        <FooterReviewContainer>
          <FooterContentContainer>
            <LeftContent>
              {screen === DIALOGUE ? (
                <Fragment>
                  <DialogReplayButton
                    playing={playing}
                    onStopSound={onStopSound}
                    onReplay={onReplay}
                    dialog_btn_stop={dialog_btn_stop || ''}
                    writing_txt_replay={writing_txt_replay || ''}
                    isReview={isReview}
                  />
                  <DialogTranslateButton
                    onTranslate={onTranslate}
                    translatePic={translatePic}
                    dialog_btn_translate={dialog_btn_translate}
                    translateState={translateState}
                  />
                </Fragment>
              ) : null}
            </LeftContent>
            <FooterReviewContent>
              <PreviousButton src={previousIcon} onClick={prevUnit} />
              {index}
              {SLASH_SIGN}
              {size}
              <NextButton src={nextIcon} onClick={nextUnit} />
            </FooterReviewContent>
            <RightContent></RightContent>
          </FooterContentContainer>
        </FooterReviewContainer>
      </Fragment>
    );
  }
}

export { FooterReview };
