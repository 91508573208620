/* @flow */
import React, { Component, Fragment } from 'react';
import {
  SOUND_PATH,
  BLACK_OPACITY_COLOR,
  BLACK,
  BLACK_HOVER,
  PREFIX_CHATBOT,
  MARIGOLD,
  DARK_YELLOW
} from '../../../common/constants';
import styled from 'styled-components';
import _ from 'lodash';
import {
  LIGHT_GREY_SHADOW,
  GREY,
  LIGHT_GREY_TRANSCRIPT_2,
  GREYISH_BROWN,
  BROWN_GREY,
  WHITE,
  SAND_YELLOW,
  PREFIX_LESSON,
  PREFIX_HEADER
} from '../../../common/constants';

import BugTracker from './../../../util/BugTracker';
import { playSound } from '../../../util';
import { evaluateChatbot } from '../../../util/SpeakingEvaluator';
import { speakingGameDefaultError } from '../../../games/actions';
import { VOICE_VERY_SLOW } from '../../../profile/constants';

type Props = {
  targetLangCode: string,
  lang: Object,
  choices: Array<Object>,
  playSoundWithCallback: Function,
  translationState: boolean,
  transcriptState: boolean,
  selectedChoice: string,
  updateSelectedChoice: Function,
  voiceSpeed: number,
  speakingErrorMsg: string,
  onCloseModal: Function,
  noMatch: Function,
  networkError: Function,
  nextChatbotVoice: Function,
  lang: Object,
  chatbotGlobals: Object,
  updateHistories: Function,
  coursesData: Object,
  PhrasesInfo: Object,
  toggleChatbotPlaying: Function,
  isChatbotPlaying: boolean,
  isTargetLangSymbol: boolean
};

type State = {
  isSpeaking: number,
  result: string,
  selected: string,
  disabled: boolean,
  isRecord: number,
  isSpeakSupported: boolean,
  recognized: boolean,
  toggleCheck: boolean,
  correctAnswer: any,
  isResultShown: boolean,
  isShowReplay: boolean
};

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  border-right: 1px solid ${LIGHT_GREY_SHADOW};
`;
const AnswerContainer = styled.div`
  position: relative;
  display: flex;
`;
const RecorderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 19px;
  align-items: center;
  position: relative;
  min-height: 100%;
`;

const MicButton = styled.button`
  padding: 0;
  border: none;
  border-radius: 50%;
  height: 60px;
  box-shadow: 0 2px 20px 0 ${BLACK_OPACITY_COLOR};
  background: ${props => (props.isRecord ? BLACK : '')};

  &:hover {
    background: ${BLACK_HOVER};
    #buttonIcon1 {
      opacity: 0.4;
    }
  }
  &:active {
    background: ${BLACK};
    #buttonIcon1 {
      display: inline !important;
    }
  }
`;

const PadButton1 = styled.div`
  background: ${GREYISH_BROWN};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MicStylePad = styled.img`
  border: 0;
  border-radius: 100px;
  height: 29px;
  width: 20px;
  padding: 16px 20px;
`;

const SpeakErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-item: center;
  position: absolute;
  top: calc(50% - -45px);
`;

const SpeakErrorImg = styled.img`
  width: 45px;
`;
const SpeakErrorText = styled.p`
  text-align: center;
`;
const SpeakErrorTClose = styled.img`
  width: 14px;
  height: 14px;
  cursor: pointer;
  opacity: 0.3;
`;
const SnailIcon = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: 12px;
  display: ${props => (props.selected ? 'block' : 'none')};
  &:hover {
    opacity: ${props => (props.selected ? 0.4 : 1)};
  }
`;
const SpeakErrorMsgText = styled.div`
  font-size: 16px;
  background: ${WHITE};
  position: relative;
  border: solid 1px ${GREY};
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 307px;
  height: 54px;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    top: -11px;
    left: 50%;
    height: 20px;
    width: 20px;
    background: ${WHITE};
    transform: translateX(-50%) rotate(45deg);
    ${'' /* transform: translateX(-50%); */}

    border-top: inherit;
    border-left: inherit;
    box-shadow: inherit;
  }
`;

const AnswersContent = styled.div`
  border-radius: 10px;
  box-shadow: inset 0 -4px 0 0 ${props => (props.selected ? DARK_YELLOW : LIGHT_GREY_SHADOW)};
  border: solid 1px ${props => (props.selected ? MARIGOLD : GREY)};
  border-radius: 10px;
  margin: 5px 12px;
  padding: 6px 12px;
  align-self: center;
  display: flex;
  align-items: center;
  line-height: 50px;
  text-align: center;
  flex-direction: row;
  cursor: pointer;
  background-color: ${props => (props.selected ? MARIGOLD : WHITE)};
  padding-right: ${props => (props.selected ? 40 : 12)}px;
  &:hover {
    opacity: ${props => (props.selected ? 1 : 0.4)};
  }
`;
const AnswerText = styled.p`
  font-size: 18px;
  color: ${LIGHT_GREY_TRANSCRIPT_2};
  margin: 0 !important;
  line-height: 30px;
`;

const MainWord = styled.p`
  font-size: ${props => (props.isTargetLangSymbol ? 25 : 16)}px;
  color: ${GREYISH_BROWN};
  margin: 0 !important;
`;

const TranscriptWord = styled.p`
  font-size: 14px;
  color: ${BROWN_GREY};
  margin: 0 !important;
  line-height: 30px;
`;

const TranslateWord = styled.p`
  font-size: 14px;
  color: ${GREYISH_BROWN};
  margin: 0 !important;
  line-height: 30px;
`;

const RightContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin: 26px;
  opacity: ${props => (props.isHide ? 0 : 1)};
`;

const RightImageContainer = styled.img`
  width: 60px;
  height: 59px;
  margin-left: 16px;
`;
const Image = styled.img`
  width: 20px;
  height: 20px;
`;
const CancelButton = styled.div`
  display: ${props => (props.isRecord ? 'flex' : 'none')};
  flex-direction: row;
  margin-right: 14px;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;
const RightContentContainer = styled.div`
  border-radius: 30px;
  border: solid 1px ${MARIGOLD};
  background-color: ${props => (props.active ? WHITE : SAND_YELLOW)};
`;
const RecorderBarContainer = styled.div`
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
  width: 400px;
  height: 74px;
  padding-right: 12px;
  margin-top: 40px;
  border-right: 1px solid ${LIGHT_GREY_SHADOW};
`;
const RightInnerContent = styled.div`
  margin: 15px 30px;
  text-align: left;
`;

const lingMale = `${PREFIX_LESSON}avatar-circle-male-60-px.svg`;
const speakErrorClose = `${PREFIX_HEADER}close-icon.svg`;
const lingHelloImg = `${PREFIX_HEADER}master-ling-hi-circle-image.svg`;

const micImage = require('../../../img/icons/yellow@2x.png');
const snailIcon = `${PREFIX_CHATBOT}snail-fill-color-32-px.png`;

let recognition;

class ConversationUserFooter extends Component<Props, State> {
  instance: any;
  constructor(props: Props) {
    if (_.isEmpty(recognition)) {
      recognition = new window.webkitSpeechRecognition();
    }
    super(props);
    this.state = {
      selected: '',
      disabled: false,
      isRecord: 0,
      isSpeakSupported: true,
      isSpeaking: 0,
      result: '',
      recognized: false,
      toggleCheck: false,
      correctAnswer: [],
      isShowReplay: false,
      isResultShown: false
    };

    this.stopRecording = this.stopRecording.bind(this);
  }
  componentDidMount(): void {
    const {
      lang: { langCodeSpeakingAndroid },
      noMatch,
      networkError
    } = this.props;
    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      console.log('SpeechRecognition supported');
    } else {
      this.setState({
        isSpeakSupported: false
      });
    }
    window.addEventListener('online', () => {
      this.props.onCloseModal();
    });
    if (recognition) {
      recognition.interimResults = false;
      recognition.continuous = true;
      recognition.lang = langCodeSpeakingAndroid;
      recognition.onaudiostart = function() {
        console.log('Audio capture start');
      };
      recognition.onaudioend = function() {
        console.log('Audio capture stop');
      };
      recognition.onerror = function(event) {
        this.setState({
          isRecord: 0
        });
        switch (event.error) {
          case 'not-allowed':
            speakingGameDefaultError();
            break;
          case 'network':
            networkError();
            break;
          case 'no-speech':
            noMatch();
            break;
          default:
            speakingGameDefaultError();
        }
      }.bind(this);
      recognition.onresult = function(event) {
        const { resultIndex } = event;
        console.log(
          _.get(event, `results.${resultIndex}.${resultIndex}.transcript`, '')
        );

        this.stopRecording(
          recognition,
          _.get(event, `results.${resultIndex}.${resultIndex}.transcript`, '')
        );
        this.props.onCloseModal();
      }.bind(this);
    }
  }
  startRecording = (recognition: Function) => {
    this.props.onCloseModal();
    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      console.log('SpeechRecognition supported');
    } else {
      this.toggleSpeakingWarningModal();
    }

    recognition.start();
    this.setState({
      isRecord: 1
    });
  };
  toggleSpeakingWarningModal = () => {
    this.setState({
      isSpeakSupported: !this.state.isSpeakSupported
    });
  };
  stopRecording = (recognition: Function, result: string) => {
    console.log('Result', result);
    this.setState({
      isRecord: 0,
      result
    });
    recognition.stop();

    const successCallback = (correctAnswer: any) => {
      this.setState(state => ({
        ...state,
        recognized: true,
        toggleCheck: true,
        isSpeaking: 3,
        correctAnswer: correctAnswer,
        isResultShown: true
      }));
      const { nextChatbotVoice } = this.props;
      nextChatbotVoice(correctAnswer);
    };
    const failCallback = (correctAnswer: any, percentile: number) => {
      this.setState(state => ({
        ...state,
        recognized: false,
        toggleCheck: true,
        isResultShown: true,
        correctAnswer: correctAnswer,
        isSpeaking: 3,
        isShowReplay: true
      }));
      const { updateHistories, PhrasesInfo, coursesData } = this.props;
      const speakingError = _.get(
        this.props.chatbotGlobals,
        'byId.chat00.allPhrases',
        []
      );
      const mergedChatbotError = speakingError.map(error => {
        const phraseData = _.get(PhrasesInfo, error, {});
        const { type, unit, lesson, id, order, isBot } = phraseData;

        const unitData = _.get(
          coursesData,
          `${type}.${unit},${lesson}.byId.${id}`
        );

        const {
          original,
          translation,
          transcript,
          soundFile,
          speaker
        } = unitData;
        return {
          id,
          order,
          isBot,
          original,
          translation,
          transcript,
          soundFile,
          speaker,
          isError: true
        };
      });

      const callback = () => {
        updateHistories(mergedChatbotError[1]);
      };
      updateHistories(mergedChatbotError[0], callback);
      console.log('Answer wrong');
    };

    const remind = aMessage => {
      this.props.noMatch();
      // $flow-disable-line
      this.timeoutID = undefined;
    };
    const cancel = () => {
      // $flow-disable-line
      window.clearTimeout(this.timeoutID);
    };
    const setup = () => {
      // $flow-disable-line
      if (typeof this.timeoutID === 'number') {
        cancel();
      }
      // $flow-disable-line
      this.timeoutID = window.setTimeout(
        function(msg) {
          remind(msg);
        },
        500,
        'Wake up!'
      );
    };

    if (result) {
      cancel();
      evaluateChatbot(this.props, result, successCallback, failCallback);
    } else {
      setup();
    }
  };
  getMainWord = (original: string) => {
    return <AnswerText>{original}</AnswerText>;
  };
  getTranscript = (transcriptState: boolean, transcript: string) => {
    return transcriptState ? (
      <TranscriptWord>{transcript}</TranscriptWord>
    ) : null;
  };
  getTranslate = (translationState: boolean, translation: string) => {
    return translationState ? (
      <TranslateWord>{translation}</TranslateWord>
    ) : null;
  };
  selectConversationAnswer(
    soundFile: string,
    targetLangCode: string,
    id: string
  ) {
    const {
      updateSelectedChoice,
      playSoundWithCallback,
      voiceSpeed,
      toggleChatbotPlaying
    } = this.props;

    this.setState({ disabled: true });
    updateSelectedChoice('');
    playSoundWithCallback(
      soundFile,
      voiceSpeed,
      `${SOUND_PATH}/${targetLangCode}`,
      () => {
        updateSelectedChoice(id);
        this.setState({ disabled: false });
      },
      () => {},
      () => {
        toggleChatbotPlaying(false);
      },
      true,
      id
    );
    toggleChatbotPlaying(true);

    this.setState({ selected: id });
  }
  onPlay = (soundFile: string, voiceSpeed: number) => {
    const { toggleChatbotPlaying } = this.props;
    const slowVoiceSpeed = _.isEqual(VOICE_VERY_SLOW, voiceSpeed)
      ? VOICE_VERY_SLOW
      : voiceSpeed - VOICE_VERY_SLOW;
    toggleChatbotPlaying(true);
    playSound(soundFile, slowVoiceSpeed, '', 1, () =>
      toggleChatbotPlaying(false)
    );
  };
  renderChoice(card: Object) {
    const { original, transcript, id, translation, soundFile } = card;
    const {
      translationState,
      transcriptState,
      targetLangCode,
      isChatbotPlaying,
      voiceSpeed,
      isTargetLangSymbol
    } = this.props;
    const { selected } = this.state;

    return (
      <AnswerContainer>
        <AnswersContent
          onClick={() =>
            this.selectConversationAnswer(soundFile, targetLangCode, id)
          }
          isChatbotPlaying={isChatbotPlaying}
          key={id}
          selected={selected === id}
        >
          <div>
            {this.getMainWord(original, isTargetLangSymbol)}
            {this.getTranscript(transcriptState, transcript)}
            {this.getTranslate(translationState, translation)}
          </div>
        </AnswersContent>
        <SnailIcon
          onClick={() => this.onPlay(soundFile, voiceSpeed)}
          src={snailIcon}
          alt={snailIcon}
          selected={selected === id}
        />
      </AnswerContainer>
    );
  }
  getMainWord = (original: string, isTargetLangSymbol: boolean) => {
    return (
      <MainWord isTargetLangSymbol={isTargetLangSymbol}>{original}</MainWord>
    );
  };

  render() {
    const {
      choices,
      onCloseModal,
      speakingErrorMsg,
      isTargetLangSymbol
    } = this.props;
    const { isRecord, isSpeakSupported } = this.state;

    return (
      <Fragment>
        <RightContainer>
          <RightContentContainer>
            <RightInnerContent>
              {this.getMainWord('...', isTargetLangSymbol)}
            </RightInnerContent>
          </RightContentContainer>
          <RightImageContainer src={lingMale} alt="speakerB" />
        </RightContainer>
        <Footer>
          {!isRecord ? (
            <FooterContainer>
              {choices && choices.map(choice => this.renderChoice(choice))}
            </FooterContainer>
          ) : (
            <RecorderBarContainer>
              <div className="progress-bar">
                <div className="progress-bar-value" />
              </div>
              <CancelButton
                isRecord={isRecord}
                onClick={() => {
                  console.log('Stop');
                  this.stopRecording(recognition, '');
                }}
              >
                <Image src={`${PREFIX_HEADER}close-icon.svg`} />
              </CancelButton>
            </RecorderBarContainer>
          )}
          {isSpeakSupported ? (
            <RecorderContainer>
              <MicButton
                isRecord={isRecord}
                onClick={() => {
                  if (isRecord) {
                    try {
                      this.stopRecording(recognition, '');
                    } catch (e) {
                      console.log('recording already stop');
                      BugTracker.notify(e);
                    }
                  } else {
                    try {
                      this.startRecording(recognition);
                    } catch (e) {
                      console.log('recording already start');
                      BugTracker.notify(e);
                    }
                  }
                }}
              >
                <PadButton1 id="buttonIcon1">
                  <MicStylePad src={micImage} />
                </PadButton1>
              </MicButton>
              {speakingErrorMsg ? (
                <SpeakErrorContainer>
                  <SpeakErrorMsgText>
                    <SpeakErrorImg src={lingHelloImg} alt={lingHelloImg} />
                    <SpeakErrorText>{speakingErrorMsg}</SpeakErrorText>
                    <SpeakErrorTClose
                      onClick={() => onCloseModal()}
                      src={speakErrorClose}
                      alt={speakErrorClose}
                    />
                  </SpeakErrorMsgText>
                </SpeakErrorContainer>
              ) : null}
            </RecorderContainer>
          ) : null}
        </Footer>
      </Fragment>
    );
  }
}

export { ConversationUserFooter };
