/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import { Listening } from '../components/scenes';
import { getLabel } from '../../util';
import { getVoiceSpeed } from '../../chatbots/selectors';
import { getQuizCards } from '../reducers';
import { mapDispatchToQuizProps } from '../selectors';
import { getTranscriptionState, getUserCoins } from '../../profile/selectors';

const ListeningContainer = props => {
  return <Listening {...props} />;
};

export const mapStateToProps = (state: Object) => {
  const btn_check = getLabel(state, 'btn_check', 'Check');
  const btn_continue = getLabel(state, 'btn_continue', 'Continue');
  const listening_txt_what_did_you_hear = getLabel(
    state,
    'listening_txt_what_did_you_hear',
    'What did you hear?'
  );

  const voiceSpeed = getVoiceSpeed(state);
  const coins = getUserCoins(state);

  return {
    coins,
    ...getQuizCards(state),
    transcriptState: getTranscriptionState(state),
    voiceSpeed,
    btn_check,
    btn_continue,
    listening_txt_what_did_you_hear
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToQuizProps
)(ListeningContainer);
