import ReactPixel from 'react-facebook-pixel';
import BugTracker from './BugTracker';

export const initializeFacebookPixel = () => {
  try {
    const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: process.env.NODE_ENV !== 'production'
    };
    ReactPixel.init(
      process.env.REACT_APP_FACEBOOK_PIXEL_ID,
      advancedMatching,
      options
    );
  } catch (err) {
    BugTracker.notify(err);
  }
};

export const trackFacebookPixelPurchase = (
  value = 0,
  currency = '',
  isTrial = false
) => {
  const currencyUppercase = currency.toUpperCase();
  // As per conversation with Max: https://app.clickup.com/t/9003040700/LING-4587?comment=90160020431698
  const valueWithRatio = isTrial ? value * 0.3 : value;
  const valueRounded = Number(valueWithRatio.toFixed(2));
  const event = {
    value: valueRounded,
    currency: currencyUppercase
  };
  ReactPixel.track('Lead');
  ReactPixel.track('Purchase', event);
};
