/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import {
  VERY_LIGHT_PINK_TWO,
  BROWN_GREY_TWO,
  PERCENT_SIGN,
  DARK_GREY
} from '../../common/constants';

const ProfileStateConainer = styled.div`
  height: 110px;
  border-radius: 126px;
  border: solid 1px ${VERY_LIGHT_PINK_TWO};
  display: flex;
  margin-top:60px;
}
`;
const StateContent = styled.div`
  flex: 3.25;
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  padding-top: 17px;
  &.middle {
    border-right: 1px solid ${VERY_LIGHT_PINK_TWO};
    border-left: 1px solid ${VERY_LIGHT_PINK_TWO};
    flex: 3.5;
  }
`;
const StateText = styled.div`
  font-size: 14px;
  color: ${BROWN_GREY_TWO};
  margin-bottom: 3px;
`;
const StateValue = styled.div`
  font-size: 24px;
  color: ${DARK_GREY};
  font-weight: bold;
`;

type Props = {
  fluency: number,
  promo_txt_fluency?: string,
  profile_txt_currentStreak?: string,
  profile_txt_longestStreak?: string,
  profile_txt_dayStreak?: string,
  currentStreak?: number,
  longestStreak?: number
};

const ProfileStateInfo = ({
  fluency,
  promo_txt_fluency,
  profile_txt_currentStreak,
  profile_txt_longestStreak,
  profile_txt_dayStreak,
  currentStreak = 0,
  longestStreak = 0
}: Props) => {
  return (
    <ProfileStateConainer>
      <StateContent>
        <StateText>{promo_txt_fluency}</StateText>
        <StateValue>
          {fluency}
          {PERCENT_SIGN}
        </StateValue>
      </StateContent>
      <StateContent className="middle">
        <StateText>{profile_txt_currentStreak}</StateText>
        <StateValue>{currentStreak}</StateValue>
        <StateText>{profile_txt_dayStreak}</StateText>
      </StateContent>
      <StateContent>
        <StateText>{profile_txt_longestStreak}</StateText>
        <StateValue>{longestStreak}</StateValue>
        <StateText>{profile_txt_dayStreak}</StateText>
      </StateContent>
    </ProfileStateConainer>
  );
};
export { ProfileStateInfo };
