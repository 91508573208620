import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import PaywallPlans from './PaywallPlans';
import Features from './Features';
import Reasons from './Reasons';
import { IMAGE_PATH } from '../../../common/constants';
import Guarantees from './Guarantees';
import Awards from './Awards';
import Philosophy from './Philosophy';
import Testimonials from './Testimonials';
import FAQ from './FAQ';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as TrackerActions from '../../../tracker/actions/trackerActions';
import {
  selectCampaign,
  selectCampaignSelections,
  selectTargetLanguageName,
  selectMonthlyPrice,
  selectQuarterlyPrice,
  selectYearlyPrice,
  selectMonthlyPriceStripeUk,
  selectQuarterlyPriceStripeUk,
  selectYearlyPriceStripeUk,
  selectTargetLanguageCode
} from '../../selectors';
import { fetchStripeProductDetails } from '../../actions/OnboardingActions';
import { CheckoutForm } from '../../../shop/components/main-components/CheckoutForm/CheckoutForm';
import {
  createPaymentMethodAndCustomerShop,
  findCurrency,
  getCheckoutTitle,
  // getLabels,
  getPriceNumber
} from '../../../util';
import { setSelectedPurchaseProduct } from '../../../shop/actions';
import { useTheme } from '@mui/material/styles';
import { setupPaypal } from '../../../util/StripePaymentProfilePageHelper';
import { getLabels, getLanguageLabel } from '../../../util/LabelHelper';
import { persistor } from '../../../index';

// This prevent users from double charges when double/triple click (lock to can only call once every 10 seconds)
const createPaymentMethodAndCustomerShopThrottled = _.throttle(
  createPaymentMethodAndCustomerShop,
  10000
);

const lingLoading = `${IMAGE_PATH}ling-loader.gif`;

const PaywallRoot = props => {
  const theme = useTheme();

  const styles = {
    container: {
      overflowY: 'auto',
      overflowX: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      mt: 12
    },
    img: {
      width: '120%',
      mt: 40,
      [theme.breakpoints.up('sm')]: {
        width: '100%'
      }
    },
    spinningMonkeyContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh'
    }
  };

  const [selectedValue, setSelectedValue] = useState('yearly');
  const [isCheckout, setIsCheckout] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { labels } = props;

  const getPrice = (priceState = {}) => {
    const { usd, local } = priceState;
    return local ?? usd;
  };

  const priceData = {
    monthly: getPrice(props.state.onboarding.monthlyPrice),
    quarterly: getPrice(props.state.onboarding.quarterlyPrice),
    yearly: getPrice(props.state.onboarding.yearlyPrice),
    monthlyStripeUk: getPrice(props.state.onboarding.monthlyPriceStripeUk),
    quarterlyStripeUk: getPrice(props.state.onboarding.quarterlyPriceStripeUk),
    yearlyStripeUk: getPrice(props.state.onboarding.yearlyPriceStripeUk)
  };

  const initiateBuy = () => {
    setIsCheckout(prev => !prev);
    const currentDate = new Date();
    const isoDateString = currentDate.toISOString();

    props.setSelectedPurchaseProduct({
      stripeId: priceData[selectedValue].priceId,
      priceUnitAmount: priceData[selectedValue].priceUnitAmount,
      currency: priceData[selectedValue].currency,
      productName: selectedValue,
      sourceScreen: 'onboarding'
    });

    props.tracker.requestTracking(
      'tap_purchase_now',
      {
        source: 'web_onboarding',
        paywall_name: 'web_paywall#0',
        screen_type: 'shop_screen',
        plan: selectedValue,
        plan_amount: priceData[selectedValue].price.replace(/,/g, '.'),
        proceeds_amount: priceData[selectedValue].price.replace(/,/g, '.'),
        currency: priceData[selectedValue].currency,
        plan_amount_usd:
          props.state.onboarding[`${selectedValue}Price`].usd.price,
        vendor_product_id: priceData[selectedValue].productId,
        purchase_time: isoDateString,
        store: 'web'
      },
      {
        paywall_name: 'web_paywall#0'
      }
    );
  };

  const imgPath = image => `${IMAGE_PATH}/${image}`;

  useEffect(() => {
    props.fetchStripeProductDetails();
    // eslint-disable-next-line
  }, []); // Fetch products only once

  useEffect(() => {
    if (props.state.onboarding.monthlyPrice?.usd.price) {
      setIsLoading(false);
    } // prevents loading 0.00 price
  }, [props.state.onboarding.monthlyPrice, setIsLoading]);

  useEffect(() => {
    props.tracker.requestTracking(
      'open_paywall',
      {
        source: 'web_onboarding',
        paywall_name: 'Standard',
        screen_type: 'sales_screen'
      },
      {
        paywall_name: 'Standard'
      }
    );
  }, [props.tracker]);

  const headerData = {
    text: labels['paywall_txt_choose_plan'],
    subtext: labels['txt_dsc_unlock_potential'],
    icon: imgPath('linger.png'),
    target: props.state.localizedTargetLanguageName
  };

  const userSkillLevel = () => {
    const selected = props.state.onboarding.selectionObject.values[9];
    const skillLevel =
      selected instanceof Object ? selected.languageSkill : selected;

    return skillLevel;
  };

  const createPaymentMethodAndCustomer = async (cardNumber: Object) => {
    const { user, isDev, history, functions } = props;

    const isUpdatingCard = false;
    const currency = priceData[selectedValue].currency;
    const productId = priceData[selectedValue].priceId;
    const isLoginPurchase = false;
    const productName = selectedValue;
    // Note: Plans on Stripe for yearly only has trial period set for USD for cards payment, we need to override, otherwise
    const isOverridingTrialFromPlan =
      selectedValue === 'yearly' && currency !== 'USD';

    createPaymentMethodAndCustomerShopThrottled(
      cardNumber,
      user,
      isDev,
      history,
      currency,
      productId,
      isUpdatingCard,
      isLoginPurchase,
      productName,
      functions,
      () => {}, // No stateChangeHandler needed here
      isOverridingTrialFromPlan
    );
  };

  const handlePayWithPayPal = async () => {
    const { user } = props;
    const priceDataStripeUk = priceData[`${selectedValue}StripeUk`];
    props.setSelectedPurchaseProduct({
      stripeId: priceDataStripeUk.priceId,
      priceUnitAmount: priceDataStripeUk.priceUnitAmount,
      currency: priceDataStripeUk.currency,
      productName: selectedValue,
      sourceScreen: 'onboarding'
    });
    await persistor.flush(); // Make sure the selected purchase product is saved to local storage before navigating to other site
    setupPaypal(user);
  };

  return (
    <>
      {isCheckout ? (
        <CheckoutForm
          // We are requested to hide this for now so we set as empty string
          trial_date_description_txt={''}
          isClaim={false}
          card_number={labels.card_number}
          card_expiration={labels.card_expiration}
          card_error_empty={labels.card_error_empty}
          card_error_number_not_correct={labels.card_error_number_not_correct}
          email_error_empty={labels.email_error_empty}
          login_field_email={labels.login_field_email}
          email_error_wrongFormat={labels.email_error_wrongFormat}
          card_error_invalid_date={labels.card_error_invalid_date}
          card_error_invalid_cvc={labels.card_error_invalid_cvc}
          login_field_name={labels.login_field_name}
          toggleIsCheckout={() => setIsCheckout(!isCheckout)}
          shop_txt_checkout_title={getCheckoutTitle(
            selectedValue,
            selectedValue === 'quarterly'
              ? labels.paywall_txt_months
              : labels.shop_txt_month,
            labels.shop_txt_year,
            labels.purchase_txt_option_lifeTime
          )}
          packageName={selectedValue}
          shop_txt_checkout_price={getPriceNumber(
            priceData[selectedValue].price
              .split(priceData[selectedValue].decimalSeparator)
              .join(''),
            0,
            findCurrency(priceData[selectedValue].currency.toUpperCase())
          )}
          shop_txt_checkout_title_top={labels.shop_txt_checkout_title_top}
          random_test_modal_back_txt={labels.random_test_modal_back_txt}
          profile_txt_name_card={labels.profile_txt_name_card}
          profile_txt_card_number={labels.profile_txt_card_number}
          profile_txt_expire_date={labels.profile_txt_expire_date}
          profile_txt_recurring_billing={labels.profile_txt_recurring_billing}
          createPaymentMethodAndCustomer={createPaymentMethodAndCustomer}
          profile_txt_terms_condition={labels.profile_txt_terms_condition}
          btn_submit={labels.btn_submit}
          setTrialUsedIP={() => {}} // No request to track
          txt_with_7_day_trial={
            selectedValue === 'yearly' ? labels.txt_with_7_day_trial : ''
          }
          isShowingPaypal={['EUR', 'GBP', 'USD', 'AUD', 'CAD'].includes(
            findCurrency(priceData[selectedValue].currency.toUpperCase()).code
          )}
          handlePayWithPayPal={handlePayWithPayPal}
        />
      ) : isLoading ? (
        <Box sx={styles.spinningMonkeyContainer}>
          <img src={lingLoading} alt={lingLoading} />
        </Box>
      ) : (
        <Box sx={styles.container}>
          <PaywallPlans
            headerData={headerData}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            initiateBuy={initiateBuy}
            priceData={priceData}
            labels={labels}
          />
          <Features labels={labels} />
          <Reasons initiateBuy={initiateBuy} labels={labels} />
          <Box
            component="img"
            src={imgPath('/onboarding/cannon.png')}
            sx={styles.img}
          />
          <Guarantees
            targetLang={headerData.target}
            initiateBuy={initiateBuy}
            userSkillLevel={userSkillLevel()}
            labels={labels}
          />
          <Awards labels={labels} />
          <Philosophy initiateBuy={initiateBuy} labels={labels} />
          <Testimonials labels={labels} />
          <FAQ initiateBuy={initiateBuy} labels={labels} />
        </Box>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  state: {
    languageData: [selectTargetLanguageName(state)],
    onboarding: {
      selectionObject: selectCampaignSelections(state),
      campaignId: selectCampaign(state),
      monthlyPrice: selectMonthlyPrice(state),
      quarterlyPrice: selectQuarterlyPrice(state),
      yearlyPrice: selectYearlyPrice(state),
      monthlyPriceStripeUk: selectMonthlyPriceStripeUk(state),
      quarterlyPriceStripeUk: selectQuarterlyPriceStripeUk(state),
      yearlyPriceStripeUk: selectYearlyPriceStripeUk(state)
    },
    localizedTargetLanguageName: getLanguageLabel(
      state,
      selectTargetLanguageCode(state)
    )
  },
  labels: getLabels(
    state,
    'trial_date_description_txt',
    'card_number',
    'card_expiration',
    'card_error_empty',
    'email_error_wrongFormat',
    'card_error_invalid_cvc',
    'btn_submit',
    'profile_txt_terms_condition',
    'profile_txt_recurring_billing',
    'card_error_number_not_correct',
    'profile_txt_expire_date',
    'profile_txt_card_number',
    'email_error_empty',
    'profile_txt_name_card',
    'login_field_email',
    'random_test_modal_back_txt',
    'shop_txt_checkout_title_top',
    'card_error_invalid_date',
    'login_field_name',
    'shop_txt_month',
    'paywall_txt_months',
    'shop_txt_year',
    'purchase_txt_option_lifeTime',
    'txt_with_7_day_trial',
    'txt_dsc_agree_to_be_charged_after_trial',
    'txt_dsc_agree_to_be_charged_quarterly',
    'txt_dsc_agree_to_be_charged_monthly',
    'txt_price_per_week',
    'txt_save_x',
    'txt_dsc_unlock_potential',
    'txt_trust_with_5_million_learners',
    'txt_money_back_guarantee',
    'txt_why_coming_back_to_Ling',
    'txt_awards',
    'txt_dsc_check_awards',
    'txt_philosophy',
    'txt_2_minutes',
    'txt_3_minutes',
    'txt_5_minutes',
    'txt_and_more',
    'txt_dsc_learn_new_words',
    'txt_dsc_review_vocabulary',
    'txt_dsc_learn_from_natives',
    'txt_dsc_explore_lessons',
    'btn_start_learning',
    'btn_get_my_plan',
    'txt_review_paywall_practical_application',
    'txt_review_paywall_favourite_application',
    'txt_review_paywall_fun',
    'txt_review_paywall_good_for_beginners',
    'txt_dsc_annual_payment_of',
    'txt_dsc_quarterly_payment_of',
    'txt_dsc_monthly_payment_of',
    'txt_interactive_exercises',
    'txt_engaging_activities',
    'txt_proven_results',
    'txt_dsc_improve_pronunciation',
    'txt_dsc_practice_skills',
    'txt_dsc_learning_methods',
    'txt_grow_your_skills',
    'txt_faq',
    'txt_faq_how_many_languages',
    'txt_faq_best_way_to_learn',
    'txt_faq_learn_from_apps',
    'txt_faq_answer_how_many_languages',
    'txt_faq_answer_best_way_to_learn',
    'txt_faq_answer_learn_from_apps',
    'txt_dsc_download_app',
    'txt_dsc_learning_in_browser',
    'btn_continue_in_browser',
    'paywall_txt_choose_plan',
    'txt_free_trial_label',
    'txt_featured_on_app_store',
    'txt_featured_on_play_store',
    'purchase_txt_option_popular',
    'txt_money_back_policy',
    'txt_join_5mil_learners',
    'txt_kudos_learners',
    'txt_choose_60_languages',
    'txt_language_to_learn',
    'txt_dsc_money_back_guarantee',
    'txt_dsc_personalized_plan',
    'txt_mix_languages',
    'unitLevel_txt_basic',
    'txt_elementary_level',
    'unitLevel_txt_beginner',
    'unitLevel_txt_intermediate',
    'unitLevel_txt_advance'
  ),
  user: state.storage.user,
  functions: process.env.REACT_APP_FUNCTIONS,
  isDev: process.env.REACT_APP_DEV
});

const mapDispatchToProps = dispatch => ({
  tracker: bindActionCreators(TrackerActions, dispatch),
  fetchStripeProductDetails: () => dispatch(fetchStripeProductDetails()),
  setSelectedPurchaseProduct: product =>
    dispatch(setSelectedPurchaseProduct(product))
});

export default connect(mapStateToProps, mapDispatchToProps)(PaywallRoot);
