/* @flow */
import moment from 'moment';
import {
  SPELLING,
  ORDER_SENTENCE,
  ORDER_SENTENCE_REVERSE,
  COMPLETE_SENTENCE,
  CORRECT_SENTENCE,
  DIALOGUE,
  TRANSLATEGAME,
  QUIZ,
  LISTENING
} from '../constants';

export const isMainGame = (gameType: ?string) =>
  [
    QUIZ,
    SPELLING,
    ORDER_SENTENCE,
    ORDER_SENTENCE_REVERSE,
    COMPLETE_SENTENCE,
    TRANSLATEGAME,
    CORRECT_SENTENCE,
    DIALOGUE,
    LISTENING
  ].includes(gameType);

export const isSpellingGame = (gameType: ?string) =>
  [
    SPELLING,
    ORDER_SENTENCE,
    ORDER_SENTENCE_REVERSE,
    COMPLETE_SENTENCE,
    DIALOGUE
  ].includes(gameType);

export const reloadGameOnToggleTranscript = (gameType: string) =>
  [SPELLING, COMPLETE_SENTENCE].includes(gameType);

export const getToday = (seconds: number = getNow()) => {
  return moment.unix(seconds).format('YYYY-MM-DD');
};

const getNow = () => moment().unix();

export const checkScore = (wrongAnswered: number) => {
  if (wrongAnswered >= 2 && wrongAnswered < 5) {
    return 150;
  } else if (wrongAnswered < 2) {
    return 200;
  } else {
    return 100;
  }
};
