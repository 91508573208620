export const priceWithDecimal = (price, decimalDigits, separator) =>
  price.slice(0, -decimalDigits) + separator + price.slice(-decimalDigits);

export const availableCurrencies = ['EUR', 'GBP', 'THB', 'ASD', 'RUB', 'CAD'];

export const getCurrencyPriceIds = (currency, isStripeUk) => {
  return [
    `REACT_APP_STRIPE_MONTHLY_${currency}_NEW`,
    `REACT_APP_STRIPE_QUARTERLY_${currency}`,
    `REACT_APP_STRIPE_YEARLY_${currency}`
  ].map(envName => {
    return process.env[`${envName}${isStripeUk ? '_STRIPE_UK' : ''}`];
  });
};

export const calculatePricePerWeek = (price, frequency, separator) => {
  const decimalPrice = price.replace(/,/g, '.');
  const weeksPerFrequency = {
    yearly: 52,
    quarterly: 13,
    monthly: 4
  };
  const pricePerWeek = decimalPrice / weeksPerFrequency[frequency];
  const roundedPricePerWeek = Math.round(pricePerWeek * 10) / 10;
  return roundedPricePerWeek
    .toFixed(2)
    .split('.')
    .join(separator);
};
