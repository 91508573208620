/* @flow */
export const UPDATE_UNITS_PROGRESS: string = 'UPDATE_UNITS_PROGRESS';

export const LOAD_LANGUAGE_DATA: string = 'LOAD_LANGUAGE_DATA';
export const LOAD_LABELS: string = 'LOAD_LABELS';
export const LOAD_CHATBOTS: string = 'LOAD_CHATBOTS';
export const LOAD_STATS: string = 'LOAD_STATS';
export const LOAD_UNIT_BOXES: string = 'LOAD_UNIT_BOXES';
export const LOAD_LANGUAGE_FEATURES: string = 'LOAD_LANGUAGE_FEATURES';
export const LOAD_STOP_WORDS: string = 'LOAD_STOP_WORDS';
export const LOAD_TARGET_LANG_CODES: string = 'LOAD_TARGET_LANG_CODES';
export const LOAD_LANG_CODES_INDEX: string = 'LOAD_LANG_CODES_INDEX';
export const SET_TARGET_LANGUAGE: string = 'SET_TARGET_LANGUAGE';
export const SET_NATIVE_LANGUAGE: string = 'SET_NATIVE_LANGUAGE';
export const FETCH_LANGUAGE_DATA: string = 'FETCH_LANGUAGE_DATA';
export const UPDATE_FIRST_TIME: string = 'UPDATE_FIRST_TIME';

export const TRIGGER_LOAD_INITIAL_TUTORIAL: string =
  'TRIGGER_LOAD_INITIAL_TUTORIAL';
export const FETCH_UNIT_META_DATA: string = 'FETCH_UNIT_META_DATA';
export const TRIGGER_LOAD_UNIVERSAL_DATA: string =
  'TRIGGER_LOAD_UNIVERSAL_DATA';
export const LOGIN_AS_GUEST: string = 'LOGIN_AS_GUEST';
export const TRIGGER_LOAD_GLOBAL_DATA: string = 'TRIGGER_LOAD_GLOBAL_DATA';
export const TRIGGER_LOAD_LANGUAGE_DATA: string = 'TRIGGER_LOAD_LANGUAGE_DATA';
export const TRIGGER_LOAD_UNIT_DATA: string = 'TRIGGER_LOAD_UNIT_DATA';
export const TRIGGER_LOAD_CHATBOT_DATA: string = 'TRIGGER_LOAD_CHATBOT_DATA';
export const TOGGLE_IS_UNITS_PROGRESS_UPDATED: string =
  'TOGGLE_IS_UNITS_PROGRESS_UPDATED';
export const TRIGGER_UPDATE_UNITS_PROGRESS: string =
  'TRIGGER_UPDATE_UNITS_PROGRESS';
export const TRIGGER_LOAD_INITIAL_LABELS: string =
  'TRIGGER_LOAD_INITIAL_LABELS';
export const TRIGGER_LOAD_LANGUAGE_FEATURES: string =
  'TRIGGER_LOAD_LANGUAGE_FEATURES';
export const DOWNLOAD_FILES: string = 'DOWNLOAD_FILES';
export const FILE_DOWNLOADED: string = 'FILE_DOWNLOADED';
export const CHANGE_CONTENT: string = 'CHANGE_CONTENT';
export const CANCEL_DOWNLOAD: string = 'CANCEL_DOWNLOAD';
export const CHANGE_LABELS: string = 'CHANGE_LABELS';
export const CANCEL_LABEL_WATCHER: string = 'CANCEL_LABEL_WATCHER';
export const FINISH_DOWNLOAD_GLOBAL_ASSETS: string =
  'FINISH_DOWNLOAD_GLOBAL_ASSETS';
export const SET_WRITTEN_NUMBERS_LOADED: string = 'SET_WRITTEN_NUMBERS_LOADED';
export const LOAD_WRITTEN_NUMBERS: string = 'LOAD_WRITTEN_NUMBERS';
export const TOGGLE_NOTIFICATION_ENABLED: string =
  'TOGGLE_NOTIFICATION_ENABLED';
export const SET_HAS_ASKED_REMINDER_PERMISSION: string =
  'SET_HAS_ASKED_REMINDER_PERMISSION';
export const ADD_FILE_WATCHER: string = 'ADD_FILE_WATCHER';

export const INIT_FAQ: string = 'INIT_FAQ';

export const INIT_APP: string = 'INIT_APP';
export const LOAD_DIALOG_HEADER: string = 'LOAD_DIALOG_HEADER';
export const DIALOG_DOWNLOADED: string = 'DIALOG_DOWNLOADED';
export const TOGGLE_PERMISSION_MODAL: string = 'TOGGLE_PERMISSION_MODAL';
export const SET_NOTIFICATION_ALLOWED: string = 'SET_NOTIFICATION_ALLOWED';
export const SET_DOWNLOAD_PHASE_LABEL: string = 'SET_DOWNLOAD_PHASE_LABEL';
export const SET_TOGGLE_ONBOARDING: string = 'SET_TOGGLE_ONBOARDING';
export const LOAD_OVERVIEW: string = 'LOAD_OVERVIEW';
export const LOAD_NATIVE_LANGUAGE_FEATURES: string =
  'LOAD_NATIVE_LANGUAGE_FEATURES';
export const OPEN_CHATBOT = 'OPEN_CHATBOT';
export const LOAD_ALL_TARGET_LANGUAGES = 'LOAD_ALL_TARGET_LANGUAGES';
