/* @flow */
import React, { Fragment } from 'react';
import { Card } from './Card';
import { Title } from './Title';
import { LIGHT_GREY, WHITE, LIGHT_GREY_3 } from '../../../common/constants';
import {
  KEYCODE_1,
  KEYCODE_2,
  KEYCODE_3,
  KEYCODE_4,
  KEYCODE_5
} from '../../constants';

import styled from 'styled-components';

const TOPCARDS = 'topCards';
const BOTTOMCARDS = 'bottomCards';

const KeyBoardSymbol = styled.span`
  border-radius: 5px;
  color: ${LIGHT_GREY_3};
  font-size: 15px;
  font-weight: 700;
  height: 15px;
  line-height: 15px;
  width: 15px;
  position: absolute;
  left: 1px;
  top: 1px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: solid 2px ${LIGHT_GREY_3};
`;

type Props = {
  character: string,
  invisible?: boolean,
  onPress?: Function,
  backgroundColor?: string,
  borderColor?: string,
  textColor?: string,
  invisible: boolean,
  isTopCompleteSentenceCard: boolean,
  isGray?: boolean,
  cardStatus?: string,
  sort?: boolean,
  showHint: boolean,
  isDialog: boolean,
  isAnwserLenght: boolean,
  isTargetLangSymbol: boolean,
  gameType: string,
  index?: number
};

class CharacterCard extends React.Component<Props> {
  componentDidMount() {
    // $flow-disable-line
    document.addEventListener('keydown', this.handleEnter);
  }
  componentWillUnmount() {
    // $flow-disable-line
    document.removeEventListener('keydown', this.handleEnter);
  }
  handleEnter = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { onPress, index, gameType } = this.props;
    if (
      (event.keyCode === KEYCODE_1 ||
        event.keyCode === KEYCODE_2 ||
        event.keyCode === KEYCODE_3 ||
        event.keyCode === KEYCODE_4 ||
        event.keyCode === KEYCODE_5) &&
      gameType === 'DIALOGUE'
    ) {
      if (parseInt(event.key, 10) === index) {
        onPress && onPress();
      }
    }
  };

  render() {
    const {
      index,
      character = '',
      onPress,
      backgroundColor,
      borderColor = WHITE,
      textColor,
      cardStatus,
      sort,
      invisible = false,
      isTopCompleteSentenceCard = false,
      isDialog = false,
      showHint = false,
      isAnwserLenght,
      isTargetLangSymbol
    } = this.props;
    const style = {
      defaultStyle: {
        backgroundColor,
        borderColor
      },
      boxShadow: {
        boxShadow: isDialog ? 'none' : `inset 0 -4px 0 0 ${LIGHT_GREY}`
      },
      hideBoxShadow: {
        boxShadow: 'none'
      },
      hideBox: {
        opacity: 0,
        width: 20,
        margin: 0,
        minWidth: 0
      },
      hideStyle: {
        opacity: 0
      },
      borderColorStyle: {
        borderColor: LIGHT_GREY
      },
      hideBoxBorder: {
        border: 'none',
        boxShadow: 'none'
      },
      showBox: {
        border: `solid 2px ${borderColor}`,
        boxShadow: `inset 0 -4px 0 0 ${LIGHT_GREY}`
      },
      borderWhite: {
        borderColor: WHITE,
        boxShadow: 'none'
      },
      dialogStyle: {
        borderWidth: '1px',
        minWidth: '50px'
      },
      containerStyle: {
        height: '50px',
        borderWidth: '2px',
        borderTopStyle: 'solid',
        position: 'relative',

        borderStyle: 'solid',
        borderRadius: '10px',
        minWidth: '50px',
        borderColor: LIGHT_GREY
      }
    };
    const {
      defaultStyle,
      hideBox,
      hideBoxShadow,
      boxShadow,
      hideStyle,
      borderColorStyle,
      hideBoxBorder,
      showBox,
      borderWhite,
      dialogStyle,
      containerStyle
    } = style;
    let characterToShow = character;

    const isEmptyTopCard =
      cardStatus === TOPCARDS && invisible && sort && !showHint;

    if (
      (invisible && isTopCompleteSentenceCard && !showHint) ||
      isEmptyTopCard
    ) {
      characterToShow = '';
    } else if (backgroundColor === null && characterToShow !== '') {
      characterToShow = '';
    }

    const getCardStatusStyle =
      isDialog && cardStatus !== BOTTOMCARDS ? dialogStyle : containerStyle;

    const getTopCardsBorderColor =
      cardStatus === TOPCARDS && invisible && sort ? borderColorStyle : {};

    const getBottomCardsBorderStyle =
      cardStatus === BOTTOMCARDS && invisible ? borderWhite : {};

    const isHideCard =
      isEmptyTopCard && !isAnwserLenght ? hideBoxBorder : showBox;

    const isEmptyCard =
      backgroundColor !== null && characterToShow === '' && !isDialog
        ? hideStyle
        : {};

    const isTopEmptyCard =
      (cardStatus === TOPCARDS &&
        backgroundColor === undefined &&
        characterToShow === ' ') ||
      (backgroundColor !== undefined && characterToShow === ' ')
        ? hideBox
        : boxShadow;

    const isHideBottomCard =
      cardStatus === BOTTOMCARDS && invisible ? hideBoxShadow : {};

    return (
      <Card
        style={{
          ...getCardStatusStyle,
          ...defaultStyle,
          ...boxShadow,
          ...isTopEmptyCard,
          ...isHideBottomCard,
          ...isEmptyCard,
          ...getTopCardsBorderColor,
          ...isHideCard,
          ...getBottomCardsBorderStyle
        }}
        onClick={onPress}
        invisible={invisible && !isTopCompleteSentenceCard}
      >
        <Fragment>
          {cardStatus === BOTTOMCARDS && isDialog && (
            <KeyBoardSymbol>{index}</KeyBoardSymbol>
          )}
          <Title
            isTargetLangSymbol={isTargetLangSymbol}
            textColor={textColor}
            isDialog={isDialog}
          >
            {characterToShow}
          </Title>
        </Fragment>
      </Card>
    );
  }
}

export { CharacterCard };
