/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import { PREFIX_QUIZ, GREY, WHITE } from '../../../common/constants';

const InfoBtn = styled.button`
    height: 55px;
    border-radius: 20px;
    box-shadow: inset 0 -4px 0 0 #dedede;
    border: solid 1px ${GREY};
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 10px;
    background: ${WHITE};
`;

const Icon = styled.img`
    margin-left: 10px;
`;

const ButtonText = styled.p`
    font-size: 16px;
    font-weight: bold;
    margin: 0 10px;
`;

type Props = { icon: string, btnText: string, onClick: Function };

const InfoButton = ({ icon, btnText, onClick }: Props) => (
    <InfoBtn onClick={onClick}>
        <Icon src={`${PREFIX_QUIZ}` + icon} alt={`${PREFIX_QUIZ}` + icon} />
        <ButtonText>{btnText}</ButtonText>
    </InfoBtn>
);
export { InfoButton };
