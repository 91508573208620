/* @flow */
import {
  LOAD_FIREBASE_PROMOTIONS,
  TOGGLE_REPAIRED_UNIT_MODAL,
  DISABLE_REPAIRED_UNIT_TOOLTIPS,
  SET_SELECTED_PURCHASE_PRODUCT,
  CLOSE_PAYWALL
} from '../constants';

type State = {
  firebasePromotions: Object,
  isShowRepairedUnitModal: boolean,
  isShowRepairedUnitTooltipsAlready: boolean,
  selectedPurchaseProduct: {
    stripeId: string,
    priceUnitAmount: number,
    currency: string,
    productName: string
  },
  isPaywallClosed: boolean
};

const INITIAL_STATE = {
  firebasePromotions: {},
  isShowRepairedUnitModal: false,
  isShowRepairedUnitTooltipsAlready: false,
  selectedPurchaseProduct: {
    stripeId: '',
    priceUnitAmount: 0,
    currency: '',
    productName: ''
  },
  isPaywallClosed: false
};

const loadFirebasePromotion = (state: State, action) => {
  return { ...state, firebasePromotions: action.payload };
};

const toggleRepairedUnitModal = (state: State) => {
  return { ...state, isShowRepairedUnitModal: !state.isShowRepairedUnitModal };
};
const disableRepairedUnitTooltips = (state: State) => {
  return { ...state, isShowRepairedUnitTooltipsAlready: true };
};

const setSelectedPurchaseProduct = (state: State, action: Object) => {
  return { ...state, selectedPurchaseProduct: action.payload };
};

export const ShopReducer = (
  state: State = INITIAL_STATE,
  action: Object
): State => {
  switch (action.type) {
    case LOAD_FIREBASE_PROMOTIONS:
      return loadFirebasePromotion(state, action);
    case TOGGLE_REPAIRED_UNIT_MODAL:
      return toggleRepairedUnitModal(state);
    case DISABLE_REPAIRED_UNIT_TOOLTIPS:
      return disableRepairedUnitTooltips(state);
    case SET_SELECTED_PURCHASE_PRODUCT:
      return setSelectedPurchaseProduct(state, action);
    case CLOSE_PAYWALL:
      return { ...state, isPaywallClosed: true };
    default:
      return state;
  }
};
