/* @flow */
import { takeEvery, call } from 'redux-saga/effects';
import BugTracker from './../util/BugTracker';
import {
  LOGIN_AS_GUEST,
  TRIGGER_UPDATE_UNITS_PROGRESS,
  TRIGGER_LOAD_CHATBOT_DATA,
  TRIGGER_LOAD_INITIAL_TUTORIAL,
  FETCH_UNIT_META_DATA,
  LOAD_ALL_TARGET_LANGUAGES
} from '../lessons/constants';
import {
  fetchAlltarget,
  fetchUnitMeta,
  updateUnitProgress
} from '../lessons/sagas';
import {
  LOAD_UNITS_DATA,
  FETCH_UNITS_DATA,
  FETCH_UNITS_DATA_RANDOM_TEST,
  TRIGGER_LOAD_LANGUAGE_DATA,
  TRIGGER_LOAD_LANGUAGE_LABEL
} from '../common/constants/ActionTypes';
import {
  startWeb,
  loadLanguageData,
  loadLanguageDataRandomTest,
  doLoadInitialLabels
} from '../common/actions/LanguageActions';
import { watchLoadUnitData, watchLoadChatbotData } from './LanguageSaga';
import { loginAsGuest } from './GuestSagas';
import { selectTutorial } from '../lessons/sagas/TutorialSagas';
const catchError = (saga: Function) =>
  function*(...args) {
    try {
      yield call(saga, ...args);
    } catch (error) {
      BugTracker.notify(error);
      console.warn(`Error in Saga: ${error.message}`, error.stack);
    }
  };

const doTakeEvery = (type: string, saga: Function, ...args) =>
  takeEvery(type, catchError(saga), ...args);

export function* rootSaga(): Object {
  yield doTakeEvery(TRIGGER_LOAD_LANGUAGE_DATA, startWeb);
  yield doTakeEvery(TRIGGER_LOAD_LANGUAGE_LABEL, doLoadInitialLabels);
  yield doTakeEvery(LOAD_UNITS_DATA, watchLoadUnitData);
  yield doTakeEvery(FETCH_UNITS_DATA, loadLanguageData);
  yield doTakeEvery(FETCH_UNITS_DATA_RANDOM_TEST, loadLanguageDataRandomTest);
  yield doTakeEvery(TRIGGER_UPDATE_UNITS_PROGRESS, updateUnitProgress);
  yield doTakeEvery(LOGIN_AS_GUEST, loginAsGuest);
  yield doTakeEvery(TRIGGER_LOAD_CHATBOT_DATA, watchLoadChatbotData);
  yield doTakeEvery(TRIGGER_LOAD_INITIAL_TUTORIAL, selectTutorial);
  yield doTakeEvery(FETCH_UNIT_META_DATA, fetchUnitMeta);
  yield doTakeEvery(LOAD_ALL_TARGET_LANGUAGES, fetchAlltarget);
}
