import { createSelector } from 'reselect';
import _ from 'lodash';

export const getOnboardingState = state => state.onboarding;
export const getDataState = state => state.data;
export const getStorageState = state => state.storage;

export const selectCampaign = createSelector(
  getOnboardingState,
  onboardState => onboardState.campaignId
);

export const selectCompletedCampaigns = createSelector(
  getOnboardingState,
  onboardState => onboardState.completeCampaigns
);

export const selectActiveScreen = createSelector(
  getOnboardingState,
  onboardState => onboardState.activeScreen
);

export const selectCampaignSelections = createSelector(
  getOnboardingState,
  selectCampaign,
  (onboardState, campaignId) =>
    onboardState.selections.reduce((a, c) => (c.id === campaignId ? c : a), {
      id: campaignId,
      values: []
    })
);

export const selectCampaignStatus = createSelector(
  getOnboardingState,
  onboarding => onboarding.campaignComplete
);

export const selectRedirectStatus = createSelector(
  getOnboardingState,
  onboarding => onboarding.shopRedirected
);

export const selectTargetLanguageCode = createSelector(
  getDataState,
  data => data.targetLangCode
);

export const selectLanguageObject = createSelector(
  getStorageState,
  storage => storage.langs
);

export const selectTargetLanguageName = createSelector(
  [selectLanguageObject, selectTargetLanguageCode],
  (langs, targetLangCode) => _.get(langs, `${targetLangCode}.lang`, '')
);

export const selectNativeLanguageCode = createSelector(
  getDataState,
  data => data.nativeLangCode
);

export const selectMonthlyPrice = createSelector(
  getOnboardingState,
  onboardState => onboardState.monthlyPrice
);

export const selectQuarterlyPrice = createSelector(
  getOnboardingState,
  onboardState => onboardState.quarterlyPrice
);

export const selectYearlyPrice = createSelector(
  getOnboardingState,
  onboardState => onboardState.yearlyPrice
);

export const selectMonthlyPriceStripeUk = createSelector(
  getOnboardingState,
  onboardState => onboardState.monthlyPriceStripeUk
);

export const selectQuarterlyPriceStripeUk = createSelector(
  getOnboardingState,
  onboardState => onboardState.quarterlyPriceStripeUk
);

export const selectYearlyPriceStripeUk = createSelector(
  getOnboardingState,
  onboardState => onboardState.yearlyPriceStripeUk
);
