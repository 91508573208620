/* @flow */
import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { firebase } from '../../system/Firebase';
import BugTracker from '../../util/BugTracker';
import styled from 'styled-components';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem as UnstyledDropdownItem
} from 'reactstrap';
import DefaultHeaderContainer from '../../common/containers/DefaultHeaderContainer';
import {
  GREY,
  GREYISH_BROWN,
  WHITE,
  LIGHT_GREY,
  BLUEISH_GREY
} from '../../common/constants/Color';
import { SHOW_TUTORIAL, TRUE, IMAGE_PATH } from '../../common/constants';
import { fetchRemoteDatabase } from '../../lessons/sagas';
import { toggleChildCreateAccountLanguagePicker } from '../../util';

type Props = {
  labels: Object,
  location: any,
  addToSubscribeNewsList: Function,
  nativeLangCode: string,
  targetLangCode: string,
  triggerFetchUnitMetaData: Function,
  getFlagImageUrl: Function,
  getLanguageLabel: Function,
  getLanguageNativeLang: Function,
  fetchLanguageData: Function,
  setLanguageData: Function,
  setNativeLanguage: Function,
  setTargetLanguage: Function,
  triggerLoadLanguageLabel: Function,
  triggerLoadInitialTutorial: Function,
  triggerLoadLanguageData: Function,
  saveUserData: Function,
  updateUserData: Function,
  createNewDatabaseUser: Function,
  toggleChatbotLoaded: Function,
  fetchUnitData: Function,
  nativeLanguages: Array<Object>,
  targetLanguages: Array<Object>,
  history: any,
  triggerLoadAllTargetLanguages: Function
};

type State = {
  native: string,
  target: string,
  search: string,
  dropDownSearch: string,
  isNativeDropdownOpen: boolean
};

const searchIcon = require('../../img/icons/search-gray-18-px.png');
const checkIcon = require('../../img/icons/check-icon.png');
const arrowDownIcon = require('../../img/icons/selector-arrow-down.png');
const arrowUpIcon = require('../../img/icons/selector-arrow-up.png');

const MainContainer = styled.div`
  margin: 0 auto;
  max-width: 1024px;
`;

const ContentContainer = styled.div`
  padding-top: 225px;
`;

const ContentPadder = styled.div`
  padding-left: 30px;
  padding-right: 30px;
`;

const OptionBar = styled.div`
  display: flex;
  justify-content: center;
`;

const CoursesContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  padding: 50px 0px;
`;

const CourseBox = styled.div`
  align-items: center;
  border: solid 2px ${LIGHT_GREY};
  border-radius: 10px;
  box-shadow: inset 0 -4px 0 0 ${LIGHT_GREY};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 220px;
  justify-content: center;
  width: 220px;
  position: relative;
`;

const CourseBoxCheckBoxIcon = styled.img`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 30px;
  height: 30px;
`;

const CourseBoxFlagImage = styled.img`
  height: 100px;
  width: 100px;
`;

const CourseBoxLabel = styled.div`
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${GREYISH_BROWN};
  padding-top: 20px;
`;

const HeaderLabel = styled.h3`
  font-family: Helvetica;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 50px 0px;
  text-align: left;
  color: ${GREYISH_BROWN};
`;

const SearchBox = styled.div`
  align-items: center;
  border: solid 2px ${GREYISH_BROWN};
  border-radius: 10px;
  display: flex;
  height: 45px;
  width: 300px;
`;

const SeachBoxIcon = styled.img`
  height: 16px;
  padding: 0 10px;
  width: 16px;
`;
const LanguageISpeakStyle = styled.span`
  font-size: 14px;
`;
const SearchBoxInput = styled.input`
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: normal;
  outline: none;
  width: 80%;
`;

const DropdownToggleContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${GREYISH_BROWN};
`;

const DropdownToggleContentIcon = styled.img`
  width: 12px;
  height: 12px;
`;

const DropDownInput = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -1;
`;

const DropdownItem = styled(UnstyledDropdownItem)`
  background-color: ${props => (props.iscurrentnative ? GREYISH_BROWN : WHITE)};
  color: ${props => (props.iscurrentnative ? WHITE : GREYISH_BROWN)};
  &:hover {
    background-color: ${GREY};
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 225px;
  height: calc(100vh - 225px);
`;
const lingLoading = `${IMAGE_PATH}ling-loader.gif`;

class LanguagePickerScene extends Component<Props, State> {
  previousLanguages: Array<Object>;
  lastFilterResult: Array<Object>;
  child: any;
  constructor(props: Props) {
    super(props);
    const { nativeLangCode, targetLangCode } = this.props;
    this.state = {
      native: nativeLangCode,
      target: targetLangCode,
      search: '',
      dropDownSearch: '',
      isNativeDropdownOpen: false
    };
    this.previousLanguages = [];
    this.lastFilterResult = [];
  }

  componentDidMount() {
    const {
      setNativeLanguage,
      setLanguageData,
      location,
      triggerLoadAllTargetLanguages
    } = this.props;
    this.toggleCreateAccount();
    const { search = '' } = location || {};
    if (search) {
      const queryNative = search.match(/native=([^&]*)/);
      const queryTarget = search.match(/target=([^&]*)/);
      console.log('Query', queryNative);
      console.log('Query', queryTarget);

      if (queryNative && queryTarget) {
        const native = queryNative[1];
        const target = queryTarget[1];
        setNativeLanguage(native);
        setLanguageData(target, native);
      }
    }

    const { fetchLanguageData, triggerLoadLanguageLabel } = this.props;
    window.scrollTo(0, 0);

    let auth = firebase.auth();

    auth.onAuthStateChanged(user => {
      if (!user) {
        auth
          .signInAnonymously()
          .then(result => {
            const { user } = result;

            this.fetchUser(user);
            fetchLanguageData();
            triggerLoadLanguageLabel();
            triggerLoadAllTargetLanguages();
          })
          .catch(error => {
            // Handle Errors here.
            var errorCode = error.code;

            if (errorCode === 'auth/operation-not-allowed') {
              alert('You must enable Anonymous auth in the Firebase Console.');
            }
            BugTracker.notify(error);
          });
      } else {
        fetchLanguageData();
        triggerLoadLanguageLabel();
        triggerLoadAllTargetLanguages();
      }
    });
  }

  fetchUser(user: Object) {
    fetchRemoteDatabase(`users/${user.uid}`).then(data => {
      const { saveUserData, createNewDatabaseUser } = this.props;
      const value = data && data.val();
      if (value) {
        saveUserData(value);
      } else {
        createNewDatabaseUser(user, 'anonymous');
      }
    });
  }

  handleNativeLanguageSelect(langCode: string) {
    const { setNativeLanguage, triggerLoadLanguageData } = this.props;
    const { target } = this.state;
    this.setState({ native: langCode });
    setNativeLanguage(langCode);
    triggerLoadLanguageData(target, langCode);
  }

  handleCourseSelect(langCode: string) {
    this.setState({ target: langCode });
    const {
      setNativeLanguage,
      setTargetLanguage,
      setLanguageData,
      triggerLoadInitialTutorial,
      toggleChatbotLoaded,
      history,
      fetchUnitData
    } = this.props;
    const { native } = this.state;
    this.setState({ target: langCode });
    setNativeLanguage(native);
    setTargetLanguage(langCode);
    setLanguageData(langCode, native);
    // eslint-disable-next-line
    if (localStorage.getItem(SHOW_TUTORIAL) === TRUE) {
      triggerLoadInitialTutorial();
      toggleChatbotLoaded();
      history.push('/');
    } else {
      //NOTE:this use to load lesson data when user change language
      fetchUnitData(langCode, native, 1, () => {
        toggleChatbotLoaded();
        history.push('/');
      });
    }
    toggleChatbotLoaded(false);
    this.props.triggerFetchUnitMetaData();

    this.props.history.push('/');
  }

  renderCourses() {
    const { getFlagImageUrl, targetLanguages, getLanguageLabel } = this.props;
    const { target, search } = this.state;
    const languages = _.filter(targetLanguages, language => {
      return getLanguageLabel(language.langCode)
        .toLowerCase()
        .includes(search.toLowerCase());
    });
    let toRender;
    if (_.isEmpty(languages)) {
      toRender = this.lastFilterResult;
    } else {
      toRender = languages;
      this.lastFilterResult = languages;
    }
    return _.map(toRender, language => {
      const { langCode } = language;
      const isCurrentCourse = langCode === target;
      return (
        <CourseBox
          key={langCode}
          onClick={() => this.handleCourseSelect(langCode)}
        >
          {isCurrentCourse ? (
            <CourseBoxCheckBoxIcon src={checkIcon} alt="selected" />
          ) : null}
          <CourseBoxFlagImage
            src={getFlagImageUrl(langCode)}
            alt={`${langCode.toUpperCase()} Flag`}
          />
          <CourseBoxLabel>{getLanguageLabel(langCode)}</CourseBoxLabel>
        </CourseBox>
      );
    });
  }
  renderNativeSelector() {
    const {
      nativeLanguages,
      getLanguageLabel,
      getLanguageNativeLang
    } = this.props;
    const { native, dropDownSearch } = this.state;
    const languages = _.filter(nativeLanguages, language => {
      return getLanguageLabel(language.langCode)
        .toLowerCase()
        .includes(dropDownSearch.toLowerCase());
    });
    if (languages.length !== 0) {
      this.previousLanguages = languages;
    }
    // $flow-disable-line
    return this.previousLanguages.map(language => {
      const { langCode } = language;
      const isCurrentNative = langCode === native;
      return (
        <DropdownItem
          key={langCode}
          onClick={() => {
            this.handleNativeLanguageSelect(langCode);
          }}
          iscurrentnative={isCurrentNative ? 'true' : undefined}
        >
          <span>{getLanguageNativeLang(langCode)}</span>
          <br />
          <LanguageISpeakStyle>
            {getLanguageLabel(langCode)}
          </LanguageISpeakStyle>
        </DropdownItem>
      );
    });
  }

  onSeachInputChange(event: SyntheticInputEvent<HTMLInputElement>) {
    this.setState({ search: event.target.value });
  }

  onDropdownSeachInputChange(event: SyntheticInputEvent<HTMLInputElement>) {
    this.setState({ dropDownSearch: event.target.value });
  }

  toggleNativeDropdown() {
    const { isNativeDropdownOpen } = this.state;
    this.setState({
      isNativeDropdownOpen: !isNativeDropdownOpen,
      dropDownSearch: ''
    });
  }

  toggleCreateAccount = () => {
    toggleChildCreateAccountLanguagePicker(this.child);
  };

  render() {
    const { native, isNativeDropdownOpen } = this.state;
    const { history, labels, getLanguageLabel } = this.props;
    const { dropdownToggleStyle, dropdownMenuStyle } = styles;
    const {
      chooseCourse_txt_title,
      searchLanguages_txt_title,
      iSpeak_txt_title
    } = labels;

    return (
      <div>
        <DefaultHeaderContainer
          toggleChildCreateAccountLanguagePicker
          onRef={ref => (this.child = ref)}
          isSrollable={true}
          history={history}
        />
        <MainContainer>
          {chooseCourse_txt_title === 'chooseCourse_txt_title' ? (
            <SpinnerContainer>
              <img src={lingLoading} alt={lingLoading} />
            </SpinnerContainer>
          ) : (
            <Fragment>
              <ContentContainer>
                <ContentPadder>
                  <HeaderLabel>{chooseCourse_txt_title}</HeaderLabel>
                </ContentPadder>
              </ContentContainer>
              <ContentPadder>
                <OptionBar>
                  <SearchBox>
                    <SeachBoxIcon src={searchIcon} />
                    <SearchBoxInput
                      type="text"
                      placeholder={searchLanguages_txt_title}
                      onChange={this.onSeachInputChange.bind(this)}
                    />
                  </SearchBox>
                  <Dropdown
                    isOpen={isNativeDropdownOpen}
                    toggle={this.toggleNativeDropdown.bind(this)}
                    direction="down"
                    onChange={this.onDropdownSeachInputChange.bind(this)}
                  >
                    <DropdownToggle style={dropdownToggleStyle}>
                      <DropdownToggleContent>
                        {`${iSpeak_txt_title} ${getLanguageLabel(native)}`}
                        <DropdownToggleContentIcon
                          src={
                            isNativeDropdownOpen ? arrowUpIcon : arrowDownIcon
                          }
                        />
                      </DropdownToggleContent>
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          fn: data => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                ...dropdownMenuStyle
                              }
                            };
                          }
                        }
                      }}
                    >
                      <DropDownInput
                        type="text"
                        id="dropDownSearch"
                        onChange={this.onDropdownSeachInputChange.bind(this)}
                        autoFocus
                      />
                      {this.renderNativeSelector()}
                    </DropdownMenu>
                  </Dropdown>
                </OptionBar>
              </ContentPadder>
              <ContentPadder>
                <CoursesContainer>{this.renderCourses()}</CoursesContainer>
              </ContentPadder>
            </Fragment>
          )}
        </MainContainer>
      </div>
    );
  }
}

const styles = {
  dropdownToggleStyle: {
    marginLeft: '10px',
    width: '160px',
    height: '33px',
    borderRadius: '10px',
    border: `solid 2px ${GREYISH_BROWN}`,
    backgroundColor: WHITE,
    color: GREYISH_BROWN
  },
  dropdownMenuStyle: {
    overflow: 'auto',
    width: '95%',
    minWidth: '155px',
    maxHeight: '200px',
    borderRadius: '10px',
    boxShadow: `0 0 0 1px ${BLUEISH_GREY}`,
    backgroundColor: WHITE,
    marginTop: '10px',
    zIndex: 1024,
    overflowX: 'hidden'
  }
};

export { LanguagePickerScene };
