/* eslint-disable max-lines */
/* @flow */
import React, { Component, Fragment } from 'react';
import './ShopScene.css';
import {
  fetchStringShopPrice,
  getUserProperty,
  getStripeProductId,
  getCheckoutTitle,
  getPriceNumber,
  createPaymentMethodAndCustomerShop,
  toggleChildCreateAccount,
  isMobile
} from '../../../../util';
import { PREFIX_SHOP, IMAGE_PATH } from '../../../../common/constants';
import {
  SpinnerContainer,
  HeroStyle,
  ShopContainerStyle,
  SaleSectionContainerButtomStyle
} from './ShopScene.styles';
import type {
  ShopSceneProps,
  ShopSceneState
} from '../../../types/ShopScene.types';
import { ProductBenefits } from '../../main-components/ProductBenefits';
import { getCurrency, findCurrency } from '../../../../util';
import { CheckoutForm } from '../../main-components/CheckoutForm/CheckoutForm';
import { PromotionScene, ShopSlices, SaleSection } from '../../main-components';
import Analytic from '../../../../util/Analytic';
import DefaultHeaderContainer from '../../../../common/containers/DefaultHeaderContainer';
import _ from 'lodash';
import { PurchaseLoginModal } from '../../main-components/PurchaseLoginModal';
import TestimonialList from '../../sub-components/TestimonialList';
import { WelcomeLingModal } from './WelcomeLingModal';

const lingLoading = `${IMAGE_PATH}ling-loader.gif`;

export default class ShopScene extends Component<
  ShopSceneProps,
  ShopSceneState
> {
  promoteEntries: Object[];
  child: any;

  animating: boolean;

  static defaultProps = {
    animating: false
  };

  constructor(props: ShopSceneProps) {
    super(props);
    this.state = {
      activeIndex: 0,
      isLoginPurchase: false,
      currency: '',
      isUpdatingCard: false,
      monthPrice: 1149,
      yearPrice: 7999,
      usdMonthPrice: 1149,
      usdYearPrice: 7999,
      lifeTimePrice: 14999,
      usdLifeTimePrice: 14999,
      productId: '',
      productName: '',
      isPromo: false,
      currentPromoId: 0,
      allPromoACount: 1,
      currencySymbol: {},
      isCheckout: false,
      price: 0,
      usdPrice: 0
    };
    Analytic.log(Analytic.key.A6, {
      category: Analytic.key.A6,
      action: 'Check'
    });
  }
  UNSAFE_componentWillMount() {
    const {
      match: { path }
    } = this.props;
    if (path === '/promo_shop') {
      this.setState({
        isPromo: true
      });
    }
  }
  async componentDidMount() {
    const {
      user,
      history,
      functions,
      state,
      tracker,
      isOnboardingShop
    } = this.props;
    const language = window.navigator.language;
    const currency = getCurrency(language);
    this.setState({
      currencySymbol: findCurrency((currency ? currency : 'usd').toUpperCase()),
      currency: currency
    });
    window.scrollTo(0, 0);
    Analytic.log(Analytic.key.A6);
    Analytic.logGA(Analytic.key.A6, {
      category: Analytic.key.A6
    });
    tracker.requestTracking(
      'open_paywall',
      {
        user_id: user.uid,
        source: isOnboardingShop
          ? 'onboarding'
          : history.location.source
          ? history.location.source
          : 'shop_page',
        paywall_name: 'Standard',
        screen_type: 'sales_screen'
      },
      {
        source: isOnboardingShop
          ? 'onboarding'
          : history.location.source
          ? history.location.source
          : 'shop_page',
        campaignId: this.props.state.onboarding.campaignId,
        paywall_name: 'Standard'
      }
    );
    fetchStringShopPrice(functions, currency, user, this.stateChangeHandler);

    if (state.onboarding.campaignId > 0 && !state.onboarding.shopRedirected) {
      this.props.onboarding.redirectShop();
    }
  }
  onBuy = (
    productName: string,
    price: number,
    usdPrice: number,
    purchaseProductData?: Object,
    sourceScreen?: string
  ) => {
    const {
      user,
      setSelectedPurchaseProduct,
      tracker,
      isOnboardingShop,
      history
    } = this.props;
    const { currency } = this.state;
    let userEmail = getUserProperty(user, 'email');
    let productId = getStripeProductId(currency, productName, user);
    this.setState({
      productId: productId,
      productName: productName,
      price: price,
      usdPrice: usdPrice
    });
    setSelectedPurchaseProduct({ ...purchaseProductData, sourceScreen });
    if (_.isEmpty(userEmail)) {
      this.toggleLoginPurchase();
    } else {
      this.toggleIsCheckout();
    }
    tracker.requestTracking(
      'tap_purchase_now',
      {
        user_id: user.uid,
        source: isOnboardingShop
          ? 'onboarding'
          : history.location.source
          ? history.location.source
          : 'shop_page',
        paywall_name: 'Standard',
        screen_type: 'sales_screen'
      },
      {
        source: isOnboardingShop
          ? 'onboarding'
          : history.location.source
          ? history.location.source
          : 'shop_page',
        campaignId: this.props.state.onboarding.campaignId,
        paywall_name: 'Standard',
        store: 'web',
        vendor_product_id: productId,
        plan: productName,
        plan_amount: price,
        proceeds_amount: price,
        currency: currency
      }
    );
  };

  toggleCheckout = () => {
    this.toggleIsCheckout();
  };
  toggleIsCheckout = () => {
    window.scrollTo(0, 0);
    this.setState({ isCheckout: !this.state.isCheckout });
  };
  stateChangeHandler = (name: string, value: any) => {
    this.setState({
      [name]: value
    });
  };
  toggleLoginPurchase = () => {
    this.setState({
      isLoginPurchase: !this.state.isLoginPurchase
    });
  };
  toggleCreateAccount = () => {
    const {
      productId,
      currencySymbol,
      price,
      usdPrice,
      productName
    } = this.state;
    const { promoDetail } = this.props;
    this.toggleLoginPurchase();
    toggleChildCreateAccount(
      productName,
      this.child,
      productId,
      currencySymbol,
      price,
      usdPrice,
      promoDetail
    );
  };
  createPaymentMethodAndCustomer = async (cardNumber: Object) => {
    const { user, isDev, history, functions } = this.props;
    const {
      isUpdatingCard,
      currency,
      productId,
      isLoginPurchase,
      productName
    } = this.state;
    createPaymentMethodAndCustomerShop(
      cardNumber,
      user,
      isDev,
      history,
      currency,
      productId,
      isUpdatingCard,
      isLoginPurchase,
      productName,
      functions,
      this.stateChangeHandler
    );
  };

  render() {
    const {
      activeIndex,
      currency,
      monthPrice,
      yearPrice,
      lifeTimePrice,
      currencySymbol,
      usdYearPrice,
      usdMonthPrice,
      usdLifeTimePrice,
      isPromo,
      currentPromoId,
      productName,
      isCheckout,
      isUpdatingCard,
      price,
      usdPrice
    } = this.state;
    const {
      referralCampaign,
      realDesc1,
      realDesc2,
      realTitle,
      purchase_txt_option_lifeTime,
      purchase_txt_option_unit,
      shop_txt_month,
      shop_txt_year,
      shop_btn_txt_buyNow,
      shop_txt_7_day_trial,
      setTrialUsedIP,
      loginPurchase_txt,
      createAccount_txt_title,
      trial_txt_no_thanks,
      trial_date_description_txt,
      promo_txt_get_discount,
      history,
      invite_friend_modal_claim_reward,
      ClaimReward,
      isShowPromotionModal,
      random_test_modal_back_txt,
      profile_txt_name_card,
      profile_txt_card_number,
      profile_txt_expire_date,
      profile_txt_recurring_billing,
      profile_txt_terms_condition,
      shop_txt_checkout_title_top,
      card_number,
      card_expiration,
      card_error_empty,
      card_error_number_not_correct,
      card_error_invalid_date,
      card_error_invalid_cvc,
      email_error_empty,
      email_error_wrongFormat,
      login_field_email,
      login_field_name,
      btn_submit,
      functions,
      user,
      welcome_ling_button,
      isPaywallClosed,
      toggleClosePaywall,
      welcome_ling_title,
      welcome_ling_description,
      purchase_txt_option_popular,
      shop_txt_product_detail_basic,
      shop_txt_product_detail_free,
      shop_txt_product_detail_subscriptions,
      shop_txt_product_detail_200_lessons_60,
      shop_txt_product_detail_beginner_lessons,
      shop_txt_product_detail_introductory_chatbot,
      shop_txt_product_detail_sync_across_devices,
      shop_txt_product_detail_all_topics,
      shop_txt_product_detail_grammar_tips,
      shop_txt_checkout_title_lifetime,
      promotion_txt_benifit_1,
      isShowInviteFriendModal,
      promo_txt_benefit_05,
      purchase_txt_benefit_details_05,
      promotion_txt_benifit_2,
      promotion_txt_benifit_des_2,
      promotion_txt_benifit_des_3,
      promotion_txt_benifit_3,
      promoDetail,
      shopSlideEntries
    } = this.props;
    return (
      <Fragment>
        {isPromo ? (
          <PromotionScene
            promotion_txt_benifit_1={promotion_txt_benifit_1}
            purchase_txt_benefit_details_05={purchase_txt_benefit_details_05}
            promotion_txt_benifit_2={promotion_txt_benifit_2}
            promotion_txt_benifit_des_2={promotion_txt_benifit_des_2}
            promotion_txt_benifit_des_3={promotion_txt_benifit_des_3}
            promotion_txt_benifit_3={promotion_txt_benifit_3}
            stateChangeHandler={this.stateChangeHandler}
            currentPromoId={currentPromoId}
            isShowPromotionModal={isShowPromotionModal}
            isShowInviteFriendModal={isShowInviteFriendModal}
            referralCampaign={referralCampaign}
            trial_txt_no_thanks={trial_txt_no_thanks}
            history={history}
            isPromo={isPromo}
            activeIndex={activeIndex}
            child={this.child}
            promoteEntries={this.promoteEntries}
            onBuy={this.onBuy}
            promoDetail={promoDetail}
            currency={currency}
            promo_txt_get_discount={promo_txt_get_discount}
            realTitle={realTitle}
            realDesc1={realDesc1}
            realDesc2={realDesc2}
            ClaimReward={ClaimReward}
            promo_txt_benefit_05={promo_txt_benefit_05}
            invite_friend_modal_claim_reward={invite_friend_modal_claim_reward}
            animating={this.animating}
          />
        ) : (
          <Fragment>
            {!isMobile() && !isCheckout && (
              <DefaultHeaderContainer
                toggleCreateAccount
                isSrollable={false}
                onRef={ref => (this.child = ref)}
                toggleCheckout={this.toggleCheckout}
              />
            )}
            {isMobile() && !isCheckout && (
              <div
                style={{
                  width: '100%',
                  height: 40,
                  position: 'absolute',
                  top: 0,
                  backgroundColor: '#FED630',
                  zIndex: 2
                }}
              />
            )}
            {isMobile() && !isCheckout && (
              <button onClick={() => toggleClosePaywall()}>
                <img
                  src={require('../../../../img/icons/xclose-paywall.png')}
                  alt="close"
                  style={{
                    position: 'absolute',
                    height: 40,
                    width: 40,
                    top: 50,
                    right: 50,
                    zIndex: 2
                  }}
                />
              </button>
            )}
            {isUpdatingCard ? (
              <SpinnerContainer>
                <img src={lingLoading} alt={lingLoading} />
              </SpinnerContainer>
            ) : (
              <ShopContainerStyle>
                {isCheckout ? (
                  <CheckoutForm
                    trial_date_description_txt={trial_date_description_txt}
                    isClaim={false}
                    card_number={card_number}
                    card_expiration={card_expiration}
                    card_error_empty={card_error_empty}
                    card_error_number_not_correct={
                      card_error_number_not_correct
                    }
                    email_error_empty={email_error_empty}
                    login_field_email={login_field_email}
                    email_error_wrongFormat={email_error_wrongFormat}
                    card_error_invalid_date={card_error_invalid_date}
                    card_error_invalid_cvc={card_error_invalid_cvc}
                    login_field_name={login_field_name}
                    functions={functions}
                    toggleIsCheckout={this.toggleIsCheckout}
                    shop_txt_checkout_title={getCheckoutTitle(
                      productName,
                      shop_txt_month,
                      shop_txt_year,
                      purchase_txt_option_lifeTime
                    )}
                    packageName={productName}
                    shop_txt_checkout_price={getPriceNumber(
                      price,
                      usdPrice,
                      currencySymbol
                    )}
                    shop_txt_checkout_title_top={shop_txt_checkout_title_top}
                    random_test_modal_back_txt={random_test_modal_back_txt}
                    profile_txt_name_card={profile_txt_name_card}
                    profile_txt_card_number={profile_txt_card_number}
                    profile_txt_expire_date={profile_txt_expire_date}
                    profile_txt_recurring_billing={
                      profile_txt_recurring_billing
                    }
                    createPaymentMethodAndCustomer={
                      this.createPaymentMethodAndCustomer
                    }
                    profile_txt_terms_condition={profile_txt_terms_condition}
                    btn_submit={btn_submit}
                    setTrialUsedIP={setTrialUsedIP}
                  />
                ) : (
                  <>
                    <HeroStyle imgUrl={`${PREFIX_SHOP}image-shop-bg-md.svg`}>
                      <ShopSlices
                        shopSlideEntries={shopSlideEntries}
                        isPromo={isPromo}
                        animating={this.animating}
                      />
                      <SaleSection
                        currency={currency}
                        functions={functions}
                        onBuy={this.onBuy}
                        usdLifeTimePrice={usdLifeTimePrice}
                        purchase_txt_option_unit={purchase_txt_option_unit}
                        shop_txt_month={shop_txt_month}
                        shop_btn_txt_buyNow={shop_btn_txt_buyNow}
                        usdMonthPrice={usdMonthPrice}
                        monthPrice={monthPrice}
                        yearPrice={yearPrice}
                        lifeTimePrice={lifeTimePrice}
                        currencySymbol={currencySymbol}
                        isRecommend={true}
                        usdYearPrice={usdYearPrice}
                        purchase_txt_option_lifeTime={
                          purchase_txt_option_lifeTime
                        }
                        isLifeTime={true}
                        shop_txt_year={shop_txt_year}
                        shop_txt_7_day_trial={shop_txt_7_day_trial}
                        user={user}
                      />
                    </HeroStyle>
                    <TestimonialList animating={this.animating} />
                    <ProductBenefits
                      currencySymbol={currencySymbol.symbol}
                      currencyCode={currencySymbol.code}
                      shop_txt_month={shop_txt_month}
                      shop_txt_year={shop_txt_year}
                      yearPrice={yearPrice}
                      usdYearPrice={usdYearPrice}
                      lifeTimePrice={lifeTimePrice}
                      usdLifeTimePrice={usdLifeTimePrice}
                      purchase_txt_option_popular={purchase_txt_option_popular}
                      shop_txt_product_detail_basic={
                        shop_txt_product_detail_basic
                      }
                      shop_txt_product_detail_free={
                        shop_txt_product_detail_free
                      }
                      shop_txt_product_detail_subscriptions={
                        shop_txt_product_detail_subscriptions
                      }
                      purchase_txt_option_lifeTime={
                        purchase_txt_option_lifeTime
                      }
                      shop_txt_product_detail_200_lessons_60={
                        shop_txt_product_detail_200_lessons_60
                      }
                      shop_txt_product_detail_beginner_lessons={
                        shop_txt_product_detail_beginner_lessons
                      }
                      shop_txt_product_detail_introductory_chatbot={
                        shop_txt_product_detail_introductory_chatbot
                      }
                      shop_txt_product_detail_sync_across_devices={
                        shop_txt_product_detail_sync_across_devices
                      }
                      shop_txt_product_detail_all_topics={
                        shop_txt_product_detail_all_topics
                      }
                      shop_txt_product_detail_grammar_tips={
                        shop_txt_product_detail_grammar_tips
                      }
                      shop_txt_checkout_title_lifetime={
                        shop_txt_checkout_title_lifetime
                      }
                    />
                    <SaleSectionContainerButtomStyle
                      imgUrl={`${PREFIX_SHOP}image-shop-bg-md.svg`}
                    >
                      <SaleSection
                        currency={currency}
                        functions={functions}
                        onBuy={this.onBuy}
                        usdLifeTimePrice={usdLifeTimePrice}
                        purchase_txt_option_unit={purchase_txt_option_unit}
                        shop_txt_month={shop_txt_month}
                        shop_btn_txt_buyNow={shop_btn_txt_buyNow}
                        usdMonthPrice={usdMonthPrice}
                        monthPrice={monthPrice}
                        yearPrice={yearPrice}
                        lifeTimePrice={lifeTimePrice}
                        currencySymbol={currencySymbol}
                        isRecommend={true}
                        usdYearPrice={usdYearPrice}
                        purchase_txt_option_lifeTime={
                          purchase_txt_option_lifeTime
                        }
                        isLifeTime={true}
                        shop_txt_year={shop_txt_year}
                        shop_txt_7_day_trial={shop_txt_7_day_trial}
                        user={user}
                      />
                    </SaleSectionContainerButtomStyle>
                  </>
                )}
              </ShopContainerStyle>
            )}
          </Fragment>
        )}
        <PurchaseLoginModal
          toggle={this.toggleLoginPurchase}
          createAccount_txt_title={createAccount_txt_title}
          loginPurchase_txt={loginPurchase_txt}
          isLoginPurchase={this.state.isLoginPurchase}
          manageSceneStateChangeHandler={this.stateChangeHandler}
          toggleCreateAccount={this.toggleCreateAccount}
        />
        {isPaywallClosed &&
          navigator.userAgent.toLowerCase().indexOf('mobile') > -1 && (
            <WelcomeLingModal
              title={welcome_ling_title}
              description={welcome_ling_description}
              buttonName={welcome_ling_button}
            />
          )}
      </Fragment>
    );
  }
}
