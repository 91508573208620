/* @flow */
import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { GRAMMAR, BUTTON_CONTINUE } from '../../constants';
import { Table, Row, Rows, CheckButton } from '../sub-components';
import {
  DARK_GREY,
  MARIGOLD,
  WHITE,
  LAVENDER_COLOR,
  PREFIX_HEADER,
  GREY
} from '../../../common/constants';
import GrammarHelpTranslateModal from './GrammarHelpTranslateModal';

const GrammarContainer = styled.div`
  width: 100%;
  margin: auto;
  margin-bottom: 173px;
  min-height: 415px;
  height: 100%;
  overflow-y: scroll;
  padding-right: 17px;
  box-sizing: content-box;
`;

const HeadText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BigTextStyle = styled.p`
  font-size: 32px;
  font-weight: bold;
  color: ${DARK_GREY};
`;

const SubtitleFont = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${DARK_GREY};
`;

const GapStyle = styled.div`
  margin-top: 35px !important;
  margin-bottom: ${props => (props.isGrammarTable ? 15 : 115)}px !important;
  border-bottom: solid ${LAVENDER_COLOR}
    ${props => (props.isGrammarTable || props.lastItem ? 0 : 1)}px;
`;
const GrammarScreenContainer = styled.div`
  width: 60%;
  overflow: hidden;
  margin-top: 92px;
  margin-bottom: 110px;
`;
const SuggestButton = styled.button`
  width: 55px;
  height: 55px;
  border-radius: 15px;
  background: ${WHITE};
  border: solid 2px ${GREY};
  padding: 0;

  &:hover {
    opacity: 0.4;
  }
  &:active {
    opacity: 1;
  }
`;
const CommentImg = styled.img`
  width: 32px;
  height: 32px;
`;

type State = {
  modalState: boolean
};
type Props = {
  toggleGrammarScreen: Function,
  grammar: Object,
  purchase_txt_benefit_03: string,
  btn_check: string,
  btn_continue: string,
  grammar_help_txt: string,
  isTargetLangSymbol: boolean
};
const OutlineComment = `${PREFIX_HEADER}outline-comment-28-px@2x.png`;
class GrammarScreen extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalState: false
    };
  }
  toggleGrammarHelpModal = () => {
    this.setState(prevState => ({ modalState: !prevState.modalState }));
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    // $flow-disable-line
    document.addEventListener('keydown', this.handleEnter);
  }
  componentWillUnmount() {
    // $flow-disable-line
    document.removeEventListener('keydown', this.handleEnter);
  }
  handleEnter = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { toggleGrammarScreen } = this.props;
    if (event.keyCode === 13) {
      if (toggleGrammarScreen) {
        toggleGrammarScreen();
      }
    }
  };

  render() {
    const {
      toggleGrammarScreen,
      grammar = { text: '', details: [] },
      btn_check,
      btn_continue,
      grammar_help_txt,
      isTargetLangSymbol
    } = this.props;
    const { textStyle, tableStyle, rowHeader, textHeader } = styles;
    const { text, details } = grammar;
    const sortDetails = _.sortBy(details, function(detail) {
      return detail.isGrammarTable === true ? 0 : 1;
    });
    const sortDetailLen = sortDetails.length;
    return (
      <Fragment>
        <GrammarScreenContainer>
          <GrammarContainer>
            <HeadText>
              <BigTextStyle>{text}</BigTextStyle>
              <SuggestButton onClick={() => this.toggleGrammarHelpModal()}>
                <CommentImg src={OutlineComment} />
              </SuggestButton>
            </HeadText>
            {sortDetails.map((section, index) => (
              <GapStyle
                lastItem={sortDetailLen - 1 === index}
                key={index}
                isGrammarTable={section.isGrammarTable}
              >
                <SubtitleFont>
                  {_.toUpper(_.get(section, 'subtitle.text', ''))}
                </SubtitleFont>
                {section && section.isGrammarTable ? (
                  <Table style={tableStyle}>
                    <Row
                      data={_.get(section, 'text.header')}
                      style={rowHeader}
                      textStyle={textHeader}
                      isTargetLangSymbol={isTargetLangSymbol}
                    />
                    <Rows
                      data={_.get(section, 'text.data')}
                      style={styles.row}
                      textStyle={styles.text}
                      isTargetLangSymbol={isTargetLangSymbol}
                    />
                  </Table>
                ) : (
                  <p style={textStyle}>{section.text}</p>
                )}
              </GapStyle>
            ))}
          </GrammarContainer>
        </GrammarScreenContainer>
        <GrammarHelpTranslateModal
          isOpen={this.state.modalState}
          toggleReport={this.toggleGrammarHelpModal}
          grammar_help_txt={grammar_help_txt}
        />
        <CheckButton
          onPressNext={toggleGrammarScreen}
          mode={BUTTON_CONTINUE}
          btn_check={btn_check}
          btn_continue={btn_continue}
          gameType={GRAMMAR}
        />
      </Fragment>
    );
  }
}

const styles = {
  headText: {
    paddingBottom: 5,
    flexDirection: 'row'
  },
  bigTextStyle: {
    fontSize: 34,
    fontWeight: 'bold',
    color: '#454545'
  },
  subtitleFont: {
    fontWeight: 'bold',
    fontSize: 18,
    color: '#ffca45'
  },
  textStyle: { fontSize: 18 },
  rowHeader: {
    backgroundColor: MARIGOLD
  },
  textHeader: {
    textAlign: 'center',
    margin: 10,
    color: WHITE,
    fontWeight: 'bold'
  },
  row: { backgroundColor: WHITE },
  text: { textAlign: 'center', margin: 10 },
  tableStyle: { width: '100%', marginBottom: 37, paddingBottom: 37 }
};
export { GrammarScreen };
