/* @flow */
import React, { Component } from 'react';
import { SpellingGameScreen } from '../main-components';
import { CheckButton } from '../sub-components';
import styled from 'styled-components';

import {
  SPELLING,
  BUTTON_DISABLED,
  BUTTON_CHECK_DISABLED,
  BUTTON_CONTINUE_DISABLED,
  BUTTON_SEND_DISABLED
} from '../../constants';
import { stopSound } from '../../../util';
const SpellingGameSceneContainer = styled.div`
  height: 100vh;
  align-items: center;
  flex: 1;
  display: ${props => (props.isShowTutorial ? 'unset' : 'flex')};
  min-height: ${props => (props.isShowTutorial ? 'unset' : '700px')};
`;
type Props = {
  answeredWrong: boolean,
  isResultShown: boolean,
  soundFile: string,
  translation: string,
  pressSpellingNextButton: Function,
  spendHint: Function,
  mode: string,
  hintMode: any,
  hintButtonEnabled: boolean,
  getTopCharacterCards: Function,
  getCharacterCards: Function,
  correctAnswer: Object[],
  byId: Object,
  btn_hint: string,
  title_txt_spelling: string,
  error_txt_game_wrong: string,
  btn_check: string,
  btn_continue: string,
  rtlLanguage: boolean,
  nativeRtlLanguage: boolean,
  gameType: string,
  transcriptState: boolean,
  coins: number,
  cardStatue: string,
  voiceSpeed: number,
  isShowTutorial: boolean,
  isTargetLangSymbol: boolean
};

export class Spelling extends Component<Props> {
  componentDidMount() {
    window.scrollTo(0, 0);

    // $flow-disable-line
    document.addEventListener('keydown', this.handleEnter);
  }
  componentWillUnmount() {
    // $flow-disable-line
    document.removeEventListener('keydown', this.handleEnter);
  }
  handleEnter = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { pressSpellingNextButton, mode } = this.props;
    if (
      event.keyCode === 13 &&
      pressSpellingNextButton &&
      mode !== BUTTON_DISABLED &&
      mode !== BUTTON_CHECK_DISABLED &&
      mode !== BUTTON_CONTINUE_DISABLED &&
      mode !== BUTTON_SEND_DISABLED
    ) {
      pressSpellingNextButton();
      window.scrollTo(0, 0);
    }
  };

  render() {
    const {
      translation,
      getTopCharacterCards,
      getCharacterCards,
      btn_hint,
      title_txt_spelling,
      error_txt_game_wrong,
      btn_check,
      btn_continue,
      rtlLanguage,
      nativeRtlLanguage,
      gameType,
      transcriptState,
      pressSpellingNextButton,
      mode,
      spendHint,
      coins,
      voiceSpeed,
      isShowTutorial,
      isTargetLangSymbol
    } = this.props;
    return (
      <SpellingGameSceneContainer isShowTutorial={isShowTutorial}>
        <SpellingGameScreen
          {...this.props}
          isTargetRightToLeftlLanguage={false}
          instruction={title_txt_spelling}
          question={translation}
          topCards={getTopCharacterCards(this.props)}
          bottomCards={getCharacterCards(this.props)}
          btn_hint={btn_hint}
          error_txt_game_wrong={error_txt_game_wrong}
          btn_check={btn_check}
          btn_continue={btn_continue}
          isSpellingGame={true}
          rtlLanguage={rtlLanguage}
          nativeRtlLanguage={nativeRtlLanguage}
          gameType={gameType}
          transcriptState={transcriptState}
          voiceSpeed={voiceSpeed}
          isShowTutorial={isShowTutorial}
          isTargetLangSymbol={isTargetLangSymbol}
        />
        <CheckButton
          gameType={SPELLING}
          hintMode={true}
          quizAnswer={''}
          {...this.props}
          onPressNext={() => {
            stopSound();
            pressSpellingNextButton();
            window.scrollTo(0, 0);
          }}
          mode={mode}
          spendHint={spendHint}
          btn_check={btn_check}
          btn_continue={btn_continue}
          btn_hint={btn_hint}
          coins={coins}
        />
      </SpellingGameSceneContainer>
    );
  }
}
