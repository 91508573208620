/* @flow */
import _ from 'lodash';
import bugtracker from './BugTracker';

export const handleSpeakingError = (e: Object) => {
  if (_.startsWith(e.message, 'SPEAKING_GAME:')) {
    bugtracker.notify(e);
    alert(
      'Oops! This screen could not be opened. We will fix the problem as soon as possible.'
    );
  }
};

export const isLocalStorageNameSupported = (testKey: string = 'test') => {
  let storage = window.sessionStorage;
  try {
    storage.setItem(testKey, '1');
    storage.removeItem(testKey);
    return true;
  } catch (error) {
    alert(
      'Your web browser does not support storing settings locally. If you are using Safari, please update it to latest version to solve the issue.'
    );
    return false;
  }
};
