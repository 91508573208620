/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import {
  pressMatchingCard,
  matchAnimationFinished,
  noMatchAnimationFinished,
  analyticMatching
} from '../actions/MatchingActions';
import { nextGameScreen, finishLoadAnimation } from '../actions';
import { Matching } from '../components/scenes';
import { getCardsDisplayed } from '../reducers/index';
import { matchingStatus } from '../constants';
import { getLabels } from '../../util';
import { getUserCoins } from '../../profile/selectors';

const NUMBER_OF_CARDS = 6;

class MatchingContainer extends React.Component<*> {
  render() {
    return <Matching {...this.props} />;
  }
}

export const mapStateToProps = (state: Object) => ({
  isTargetLangSymbol: state.storage.isTargetLangSymbol,
  coins: getUserCoins(state),
  ...getLabels(
    state,
    'title_txt_matching',
    'quiz_txt_title',
    'btn_check',
    'btn_continue',
    'btn_hint'
  ),
  ...getCardsDisplayed(state)
});

const dispatchMatchAction = (dispatch: Function, status: string) => {
  switch (status) {
    case matchingStatus.NO_MATCH:
      dispatch(noMatchAnimationFinished());
      break;
    case matchingStatus.MATCH:
      dispatch(matchAnimationFinished());
      break;
    default:
      break;
  }
};

export const mapDispatchToProps = (dispatch: Function) => {
  let finishAnimationThunk = finishLoadAnimation(NUMBER_OF_CARDS);
  return {
    pressMatchingCard(cardId: number, status: string) {
      dispatch(pressMatchingCard(cardId, status));
    },
    nextGameScreen() {
      dispatch(analyticMatching());
      dispatch(nextGameScreen());
    },
    animationFinished(status: string, loadAnimationFinished: boolean = false) {
      if (loadAnimationFinished) {
        dispatchMatchAction(dispatch, status);
      } else {
        dispatch(finishAnimationThunk);
      }
    }
  };
};

export { MatchingContainer };
export default connect(mapStateToProps, mapDispatchToProps)(MatchingContainer);
