import {
  UPDATE_UNITS_PROGRESS,
  TOGGLE_IS_UNITS_PROGRESS_UPDATED,
  TRIGGER_UPDATE_UNITS_PROGRESS,
  LOAD_UNIT_BOXES
} from '../constants';

export const onUpdateUnitsProgress = (units: Object) => ({
  type: UPDATE_UNITS_PROGRESS,
  units
});

export const toggleIsUnitsProgressUpdated = () => ({
  type: TOGGLE_IS_UNITS_PROGRESS_UPDATED
});

export const triggerUpdateUnitsProgress = () => ({
  type: TRIGGER_UPDATE_UNITS_PROGRESS
});

export const loadUnitBoxes = (unitCount: number) => ({
  type: LOAD_UNIT_BOXES,
  unitCount
});
