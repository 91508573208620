/* @flow */
import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: ${props => `${props.barHeight}px`};
  border-radius: 7px;
  background-color: ${props => props.backgroundColor};
`;

const Progress = styled.div`
  width: ${props => `${props.filled}%`};
  height: ${props => `${props.barHeight}px`};
  border-radius: 7px;
  background-color: ${props => props.color};
`;

const LingProgressBar = ({
  barHeight = 10,
  progressMax = 100,
  progress = 0,
  percentage = 0,
  filled = 0,
  style = {},
  color = 'rgb(255,202,65)',
  borderColor = 'rgb(255,202,65)',
  backgroundColor = 'white'
}: Object) => {
  percentage = Math.ceil((progress * 100) / progressMax);
  return (
    <Container barHeight={barHeight} backgroundColor={backgroundColor}>
      <Progress barHeight={barHeight} color={color} filled={percentage} />
    </Container>
  );
};

export { LingProgressBar };
