/* @flow */
import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import {
  LIGHT_GREY,
  WHITE,
  BLACK,
  LIGHT_GREY_CARD,
  MARIGOLD,
  SILVER_COLOR,
  DARK_GREY,
  DARK_YELLOW,
  PREFIX_SHOP,
  NUMBER_DURATION_SUBSCRIPTION
} from '../../../common/constants';
import { Box } from '@mui/material';

export const CardHeader = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  width: 222px;
  margin-left: -1px;
  margin-top: -1px;
  height: ${props => (props.isRecommend ? 160 : 117)}px;
  font-weight: bold;
  border-radius: 20px 20px 0 0;
  justify-content: ${props => (props.isRecommend ? 'unset' : 'center')};
  color: ${props => (props.isRecommend ? WHITE : DARK_GREY)};
  background-color: ${props => (props.isRecommend ? BLACK : LIGHT_GREY_CARD)};
`;

const CardBody = styled.div``;
const CardText = styled.div`
  p {
    font-size: 32px;
    span {
      font-size: 21px;
      vertical-align: text-top;
      color: ${MARIGOLD};
    }
    &.priceText {
      font-weight: bold;
      font-size: 16px;
      color: ${SILVER_COLOR};
      text-transform: uppercase;
      margin-top: 0px !important;
      margin-bottom: 26px !important;
    }
    &.price {
      margin-top: 23px !important;
      margin-bottom: 0px !important;
    }
    &.lifetimeContentText {
      height: 86px;
      line-height: 86px;
      span {
        vertical-align: top;
      }
    }
  }
`;
const CurrencyCode = styled.span`
  vertical-align: middle !important;
`;
const CardHeaderPrice = styled.div`
  font-size: 32px;
  line-height: 37px;
`;
const CardHeaderPriceText = styled.div`
  font-size: 18px;
  line-height: 37px;
  text-transform: uppercase;
`;

const CardButton = styled.button`
  border-radius: 22.5px;
  font-size: 16px;
  border: solid 1px ${MARIGOLD};
  background-color: ${MARIGOLD};
  color: ${WHITE};
  width: 96px;
  height: 30px;
  &:hover {
    opacity: 0.4;
    box-shadow: none;
  }
  &:active {
    opacity: 1;
    background-color: ${DARK_YELLOW};
    box-shadow: none;
  }
`;
const CardTrialText = styled.div`
  background: #4cb4ff;
  color: white;
  font-size: 16px;
  height: 47px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  width: 222px;
  margin-left: -1px;
  margin-bottom: -1px;
  bottom: 0;
  border-radius: 0 0 20px 20px;
`;

const CardHeaderFullPrice = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${MARIGOLD};
`;

const CrownImg = styled.div`
  line-height: 43px;
`;
type Props = {
  purchase_txt_option_lifeTime?: string,
  purchase_txt_option_unit?: string,
  shop_txt_month?: string,
  shop_txt_year?: string,
  shop_btn_txt_buyNow: string,
  shop_txt_7_day_trial?: string,
  trialUsedIP?: string,
  onBuy: Function,
  isLifeTime?: boolean,
  isRecommend?: boolean,
  amount: number,
  currencySymbol: string,
  currencyCode: string,
  isDisabled: boolean
};

class SaleCard extends Component<Props> {
  render() {
    const {
      isRecommend,
      isLifeTime,
      onBuy,
      purchase_txt_option_lifeTime,
      purchase_txt_option_unit,
      shop_txt_month,
      shop_txt_year,
      shop_btn_txt_buyNow,
      shop_txt_7_day_trial,
      trialUsedIP,
      amount,
      currencySymbol,
      currencyCode,
      isDisabled
    } = this.props;

    const styles = {
      container: {
        position: 'relative',
        width: 220,
        height: isRecommend ? (trialUsedIP === '' ? 400 : 362) : 318,
        borderRadius: 4,
        boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.3)',
        border: `solid 1px ${LIGHT_GREY}`,
        backgroundColor: WHITE,
        top: {
          md: `${isRecommend ? -43 : 0}px`
        },
        mt: {
          xs: 6
        },
        cursor: 'pointer'
      }
    };

    return (
      !isDisabled && (
        <Box
          sx={styles.container}
          onClick={() => onBuy(isRecommend, isLifeTime)}
        >
          <CardHeader isRecommend={isRecommend}>
            {isRecommend ? (
              <Fragment>
                <CrownImg>
                  <img
                    src={`${PREFIX_SHOP}basecolor-crown-black-icon-36-px.svg`}
                    alt={`${PREFIX_SHOP}basecolor-crown-black-icon-36-px.svg`}
                  />
                </CrownImg>
                <CardHeaderPrice>
                  {NUMBER_DURATION_SUBSCRIPTION}
                </CardHeaderPrice>
                <CardHeaderPriceText>{shop_txt_year}</CardHeaderPriceText>
                <CardHeaderFullPrice>
                  <span>
                    {currencyCode === 'EUR' ||
                    currencyCode === 'THB' ||
                    currencyCode === 'GBP' ||
                    currencyCode === 'RUB'
                      ? currencySymbol
                      : '$'}
                  </span>
                  {amount}
                  {currencyCode === 'AUD' ? <span>{currencyCode}</span> : null}
                </CardHeaderFullPrice>
              </Fragment>
            ) : (
              <Fragment>
                {isLifeTime ? (
                  <CardHeaderPriceText>
                    {purchase_txt_option_lifeTime}
                  </CardHeaderPriceText>
                ) : (
                  <Fragment>
                    <CardHeaderPrice>
                      {NUMBER_DURATION_SUBSCRIPTION}
                    </CardHeaderPrice>
                    <CardHeaderPriceText>{shop_txt_month}</CardHeaderPriceText>
                  </Fragment>
                )}
              </Fragment>
            )}
          </CardHeader>
          <CardBody>
            {isLifeTime ? (
              <Fragment>
                <CardText>
                  <p className="lifetimeContentText">
                    <span>
                      {currencyCode === 'EUR' ||
                      currencyCode === 'THB' ||
                      currencyCode === 'GBP' ||
                      currencyCode === 'RUB'
                        ? currencySymbol
                        : '$'}
                    </span>
                    {amount}
                    {currencyCode === 'AUD' ? (
                      <CurrencyCode>{currencyCode}</CurrencyCode>
                    ) : null}
                  </p>
                </CardText>
                <CardButton onClick={() => onBuy(isRecommend, isLifeTime)}>
                  {shop_btn_txt_buyNow}
                </CardButton>
              </Fragment>
            ) : (
              <Fragment>
                <CardText>
                  <p className="price">
                    <span>
                      {currencyCode === 'EUR' ||
                      currencyCode === 'THB' ||
                      currencyCode === 'GBP' ||
                      currencyCode === 'RUB'
                        ? currencySymbol
                        : '$'}
                    </span>
                    {isRecommend ? (amount / 12).toFixed(2) : amount}
                    {currencyCode === 'AUD' ? (
                      <CurrencyCode>{currencyCode}</CurrencyCode>
                    ) : null}
                  </p>
                  <p className="priceText">{purchase_txt_option_unit}</p>
                </CardText>
                <CardButton
                  className="1"
                  onClick={() => onBuy(isRecommend, isLifeTime)}
                >
                  {shop_btn_txt_buyNow}
                </CardButton>
              </Fragment>
            )}

            {isRecommend && trialUsedIP === '' && (
              <CardTrialText>{shop_txt_7_day_trial}</CardTrialText>
            )}
          </CardBody>
        </Box>
      )
    );
  }
}

export { SaleCard };
