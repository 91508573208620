/* @flow */

import {
  LOAD_FIREBASE_PROMOTIONS,
  TOGGLE_REPAIRED_UNIT_MODAL,
  DISABLE_REPAIRED_UNIT_TOOLTIPS,
  SET_SELECTED_PURCHASE_PRODUCT,
  CLOSE_PAYWALL
} from '../constants';
export const loadFirebasePromotion = (promotions: Object) => ({
  type: LOAD_FIREBASE_PROMOTIONS,
  payload: promotions
});

export const toggleRepairedUnitModal = () => ({
  type: TOGGLE_REPAIRED_UNIT_MODAL
});

export const toggleClosePaywall = () => ({
  type: CLOSE_PAYWALL
});

export const disableRepairedUnitTooltips = () => ({
  type: DISABLE_REPAIRED_UNIT_TOOLTIPS
});
export const getNextWeekDate = () => {
  const firstDay = new Date();
  const nextWeek = new Date(firstDay.getTime() + 7 * 24 * 60 * 60 * 1000);
  return {
    day: ('0' + nextWeek.getDate()).slice(-2),
    month: ('0' + (nextWeek.getMonth() + 1)).slice(-2),
    year: nextWeek.getFullYear()
  };
};

export const setSelectedPurchaseProduct = (saleData: Object) => ({
  type: SET_SELECTED_PURCHASE_PRODUCT,
  payload: saleData
});
