/* @flow */
import * as types from '../constants';
import { nextGameScreen } from './GameActions';
import { gameSound as utilGameSound } from '../../util';
// import { isScreenshotsApp } from '../../util/AssetHelper';
import Analytic from '../../util/Analytic';
import { getIsEffectEnabled } from '../../chatbots/selectors';

type Dispatch = Object => void;
type GetState = () => Object;

export const pressQuizCard = (wordId: string) => {
  return (dispatch: Dispatch, getState: GetState) => {
    if (!getState().quizCards.frozen) {
      dispatch({
        type: types.PRESS_QUIZ_CARD,
        wordId
      });
    }
  };
};

export const pressQuizNextButton = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    // if (isScreenshotsApp()) {
    // dispatch(nextGameScreen());
    // } else

    if (getState().quizCards.frozen) {
      const { game, routes, quizCards } = getState();
      Analytic.log(Analytic.key.G1, {
        game_type: routes.gameType,
        content: game.key,
        answer: quizCards.answeredWrong
      });
      Analytic.logGA(Analytic.key.G1, {
        category: Analytic.key.G1,
        action: 'Click',
        label: routes.gameType,
        value: quizCards.answeredWrong
      });
      dispatch(nextGameScreen());
    } else {
      dispatch({
        type: types.CHECK_QUIZ_RESULT
      });
      dispatch(playSound());
      // setTimeout(() => {
      //   if (!getState().quizCards.answeredWrong) {
      // dispatch(nextGameScreen());
      //   }
      // }, 1000);
    }
  };
};

const playSound = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const isEffectEnabled = getIsEffectEnabled(state);
    const gameSound = isEffectEnabled ? utilGameSound : arg => {};
    getState().quizCards.answeredWrong
      ? gameSound('incorrect')
      : gameSound('correct');
  };
};
