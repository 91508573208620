/* @flow */
import React, { Component } from 'react';
/* @flow */
import styled from 'styled-components';
import {
  PREFIX_LESSON,
  GREYISH_BROWN,
  WHITE_TWO,
  WHITE,
  VERY_LIGHT_PINK,
  VERY_LIGHT_PINK_TWO,
  GREY
} from '../../../common/constants';

const pin = `${PREFIX_LESSON}basecolor-pin-map-black-16-px.svg`;
const formalIcon = `${PREFIX_LESSON}chat-formal-icon.png`;
const informalIcon = `${PREFIX_LESSON}chat-informal-icon.png`;
const lingMale = `${PREFIX_LESSON}avatar-circle-male-60-px.svg`;
const lingFemale = `${PREFIX_LESSON}avatar-circle-female-60-px.svg`;
const headerIcon = `${PREFIX_LESSON}basecolor-gray-circle-arrow-down-32-px.svg`;

const HeaderContainer = styled.div`
  top: 90px;
  position: ${props => (props.isShowTutorial ? 'unset' : 'fixed')};
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.02);
  border: solid 1px
    ${props => (props.isShowTutorial ? 'unset' : VERY_LIGHT_PINK)};
  background-image: ${props =>
    props.isShowTutorial
      ? 'unset'
      : `linear-gradient(to top, ${WHITE_TWO}, ${WHITE});`};
  display: flex;
  flex: 1;
  width: 100%;
  z-index: ${props => (props.isShowTutorial ? 0 : 90)};
  justify-content: center;
`;

const ContentContainer = styled.div`
  max-width: 1024px;
  display: block;
  width: 100%;
`;

const UpperContainer = styled.div`
  max-width: 1024px;
  display: flex;
  flex: 1;
  flex-direction: ${props => (props.isShowTutorial ? 'column' : 'unset')};
  width: 100%;
  height: 67px;
`;

const LeftContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${props =>
    props.isShowTutorial ? 'space-between' : 'unset'};
  margin: ${props => (props.isShowTutorial ? '5px' : '15px')} 44px;
  align-items: center;
`;

const RightContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${props =>
    props.isShowTutorial ? 'space-between' : 'flex-end'};
  margin: 10px 44px;
`;

const Pin = styled.img`
  width: 16px;
  height: 22px;
  margin-right: 10px;
`;

const PlaceText = styled.p`
  font-size: 21px;
  font-weight: bold;
  color: ${GREYISH_BROWN};
  margin: 0 ${props => (props.isShowTutorial ? '!important' : null)};
  margin-right: 15px;
`;

const FormalContainer = styled.div`
  border-left: solid 1px ${VERY_LIGHT_PINK_TWO};
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  height: 35px;
`;

const CheckIcon = styled.img`
  margin-right: 12px;
`;
const Location = styled.div`
  display: flex;
  align-items: center;
`;
const CheckText = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: ${GREYISH_BROWN};
`;

const SpeakerContainer = styled.div`
  display: flex;
  margin-left: 30px;
  align-items: center;
`;

const SpeakerText = styled.p`
  font-size: 14px;
  color: ${GREY};
  margin: ${props => (props.isShowTutorial ? '0px' : null)} !important;
  margin-left: 20px !important;
  vertical-align: middle;
  line-height: 40px;
  height: 40px;
`;

const SpeakerImg = styled.img`
  width: 40px;
  height: 40px;
`;

const CollapseContainer = styled.div`
  max-width: 1024px;
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.02);
  border-bottom: solid 1px ${VERY_LIGHT_PINK};
`;

const CollapseButton = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 0;
  border: 0;
  margin: 7px 0;
`;

const CollapseIcon = styled.img`
  margin: 0 !important;
  width: 32px;
  height: 32px;
  transform: rotate(${props => (props.isCollapsed ? 180 : 0)}deg);
`;

type Props = {
  place?: string,
  formality?: boolean,
  speaker0?: string,
  speaker1?: string,
  dialogue_title_formality_formal?: string,
  dialogue_title_formality_informal?: string,
  isShowTutorial: boolean,
  toggleHeader: Function
};

type State = {
  isCollapsed: boolean
};

class DialogHeader extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isCollapsed: true
    };
  }

  toggleCollapse() {
    const { isCollapsed } = this.state;
    const { toggleHeader } = this.props;
    this.setState({ isCollapsed: !isCollapsed });
    toggleHeader(isCollapsed);
  }

  render() {
    const {
      place = 'resturant',
      formality,
      speaker0 = 'ling',
      speaker1 = 'ling',
      dialogue_title_formality_formal = 'formal',
      dialogue_title_formality_informal = 'informal',
      isShowTutorial
    } = this.props;

    const { isCollapsed } = this.state;

    const checkIcon = formality ? formalIcon : informalIcon;
    const checkFormal = formality
      ? dialogue_title_formality_formal
      : dialogue_title_formality_informal;
    return (
      <HeaderContainer isShowTutorial={isShowTutorial}>
        <ContentContainer>
          {isCollapsed ? (
            <UpperContainer isShowTutorial={isShowTutorial}>
              <LeftContainer isShowTutorial={isShowTutorial}>
                <Location>
                  <Pin src={pin} alt="pin" />
                  <PlaceText isShowTutorial={isShowTutorial}>{place}</PlaceText>
                </Location>
                <FormalContainer>
                  <CheckIcon src={checkIcon} alt="checkIcon" />
                  <CheckText>{checkFormal}</CheckText>
                </FormalContainer>
              </LeftContainer>
              <RightContainer isShowTutorial={isShowTutorial}>
                <SpeakerContainer>
                  <SpeakerImg src={lingFemale} alt="lingFemale" />
                  <SpeakerText isShowTutorial={isShowTutorial}>
                    {speaker0}
                  </SpeakerText>
                </SpeakerContainer>
                <SpeakerContainer>
                  <SpeakerImg src={lingMale} alt="lingMale" />
                  <SpeakerText isShowTutorial={isShowTutorial}>
                    {speaker1}
                  </SpeakerText>
                </SpeakerContainer>
              </RightContainer>
            </UpperContainer>
          ) : null}
          <CollapseContainer>
            <CollapseButton onClick={() => this.toggleCollapse()}>
              <CollapseIcon src={headerIcon} isCollapsed={isCollapsed} />
            </CollapseButton>
          </CollapseContainer>
        </ContentContainer>
      </HeaderContainer>
    );
  }
}

export { DialogHeader };
