import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Lottie from 'lottie-react';
import { useMediaQuery } from '@mui/material';
import { IMAGE_PATH } from '../../../common/constants';
import { Button } from '@mui/material';
import confettiAnimation from '../../../shop/data/confetti.json';

const ThankYouPage = ({ buttonAction, labels }) => {
  const headerData = {
    text: labels['afterBuy_txt_title'],
    subtext: labels['txt_dsc_download_app'],
    footerText: labels['txt_dsc_learning_in_browser']
  };
  const theme = useTheme();
  const imgPath = image => `${IMAGE_PATH}shop/${image}`;

  const styles = {
    container: {
      backgroundColor: '#EAF3FC',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      pt: 14,
      height: '100vh',
      width: '100vw',
      textAlign: 'center',
      overflowY: 'hidden',
      position: 'fixed',
      [theme.breakpoints.up('sm')]: {
        fontSize: '36px',
        pt: 22
      }
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      px: 5,
      minWidth: '345px',
      maxWidth: '550px',
      justifyContent: 'space-between',
      fontColor: '#111111',
      zIndex: 2,
      mt: 6,
      mb: 10
    },
    button: {
      backgroundColor: '#EAF3FC',
      width: 246,
      height: '38px',
      borderRadius: 9,
      border: '2px #FF9900 solid',
      textTransform: 'none',
      color: '#FF9900',
      fontSize: '18px',
      fontWeight: 700,
      mt: 8
    },
    text: {
      fontSize: '30px',
      fontColor: '#111111',
      [theme.breakpoints.up('sm')]: {
        fontSize: '36px'
      }
    },
    subtext: {
      fontSize: '16px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px'
      }
    },
    imageContainer: {
      width: '100%',
      position: 'fixed',
      bottom: 0,
      maxHeight: '250px',
      [theme.breakpoints.up('sm')]: {
        maxHeight: '300px'
      },
      [theme.breakpoints.up('lg')]: {
        maxHeight: '600px'
      }
    },
    image: {
      width: '100%'
    },
    logo: {
      width: 112,
      mb: 6,
      [theme.breakpoints.up('sm')]: {
        mb: 10
      }
    },
    deactivatedText: {
      fontSize: '10px'
    },
    badgeContainer: {
      width: '90%',
      maxWidth: 500,
      display: 'flex',
      justifyContent: 'space-around',
      mb: 8,
      zIndex: 10
    },
    badge: {
      width: '80%',
      minWidth: 160,
      cursor: 'pointer'
    },
    bottomContainer: {
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    confetti: {
      position: 'absolute',
      width: '70%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  };

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box sx={styles.container}>
      <Lottie
        animationData={confettiAnimation}
        loop={false}
        style={styles.confetti}
      />
      <Box
        component="img"
        src={imgPath('ling-logo-icon.png')}
        sx={styles.logo}
      />
      <Typography variant="text" sx={styles.text}>
        {headerData.text}
      </Typography>
      <Box sx={styles.textContainer}>
        <Typography variant="subtext" sx={styles.subtext}>
          {headerData.subtext}
        </Typography>
      </Box>
      <Box sx={styles.imageContainer}>
        <Box
          component="img"
          src={
            isLargeScreen
              ? imgPath('thank-you-scene.png')
              : imgPath('thank-you-scene-mobile.png')
          }
          sx={styles.image}
        />
      </Box>
      <Box sx={styles.badgeContainer}>
        <a
          href="https://ling-app.onelink.me/Ue3y/pjf20f39"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Box
            component="img"
            src={imgPath('app-store-badge.png')}
            sx={styles.badge}
          />
        </a>
        <a
          href="https://ling-app.onelink.me/Ue3y/wyw0tm6v"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Box
            component="img"
            src={imgPath('google-play-badge.png')}
            sx={styles.badge}
          />
        </a>
      </Box>
      {isLargeScreen && (
        <Box sx={styles.bottomContainer}>
          <Typography variant="subtext" sx={styles.subtext}>
            {headerData.footerText}
          </Typography>
          <Button
            disabled={false}
            onClick={buttonAction}
            sx={styles.button}
            disableElevation
          >
            {labels['btn_continue_in_browser']}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ThankYouPage;
