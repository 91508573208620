/* @flow */
import * as React from 'react';
type Props = {
  children?: any,
  style?: Object
};

function Translation({ children, style }: Props) {
  return <div style={{ ...containerStyle, ...style }}>{children}</div>;
}

const containerStyle = {
  flexDirection: 'column',
  alignContent: 'center',
  padding: 1,
  flexWrap: 'wrap',
  display: 'flex'
};

export { Translation };
