import _ from 'lodash';
import {
  SAVE_SCENE,
  LOAD_GLOBAL_CONFIG,
  SYMBOL_LANGUAGE,
  WEEK_DAYS_DATA
} from '../constants/index';
import {
  LOAD_LANG_CODES_INDEX,
  SET_VOICE_SPEED,
  SET_SHOW_TUTORIAL,
  SET_DAILY_GOAL,
  SET_LOADING,
  SET_CHATBOT_LOADED,
  SET_BUSINESS,
  TOGGLE_SOUND_EFFECT,
  TOGGLE_REMINDER,
  TOGGLE_TUTORIAL_MOTIVATE,
  CLOSE_TUTORIAL_MOTIVATE,
  SET_LOADING_PARAM,
  SET_IS_LANG_SYMBOL,
  TOGGLE_LOGIN_MODAL,
  SET_IS_BUSINESS_SIGN_UP,
  SET_SIGN_UP_BUSINESS_SUCCESS,
  SET_TRIAL_USED_IP,
  SET_REFERRER,
  SET_NO_ACCESS_UNITS,
  SET_NO_ACCESS_CHATBOT_UNITS,
  UPDATE_SUBSCRIPTIONS
} from '../constants/ActionTypes';
import { USER_SAVE_DATA, CLEAR_USER } from '../constants';
import { updateUserProfile } from '../actions/GlobalActions';
import { DEFAULT_WEEKLY_GRAPH_DATA } from '../../profile/constants';
import moment from 'moment';

type State = {
  localStorage: ?Object,
  initApp: boolean,
  user: {
    isSignUpBusinessSuccess: boolean,
    trialUsedIP: string,
    voiceSpeed: number,
    isEffectEnabled: boolean,
    reminders: Object
  },
  payments: {
    isLifetime: boolean,
    isProUser: boolean
  },
  faq: Object,
  isTutorialMotivate: boolean,
  isLoginModalOpen: boolean,
  isShowTutorial: boolean,
  isLoading: boolean,
  chatbotLoaded: string,
  scene: string,
  configs: Object,
  langs: Array<any>,
  isTargetLangSymbol: boolean,
  isBusinessSignUp: boolean,
  noAccessUnits: Array<number>,
  noAccessChatbotUnits: Array<string>
};

export const INITIAL_USER = {
  voiceSpeed: 3,
  isEffectEnabled: true,
  isSignUpBusinessSuccess: false,
  trialUsedIP: '',
  goal: {
    isSetDailyGoal: false,
    userDailyGoal: 0,
    weeklyGraphData: DEFAULT_WEEKLY_GRAPH_DATA,
    weekNumber: moment().week()
  },
  reminders: {
    isSetReminder: false,
    timeReminder: '09:30',
    weekDays: WEEK_DAYS_DATA
  }
};

const INITIAL_STATE = {
  localStorage: null,
  initApp: false,
  user: INITIAL_USER,
  payments: {
    isLifetime: false,
    isProUser: false
  },
  faq: {},
  isShowTutorial: false,
  isTutorialMotivate: false,
  isLoading: false,
  chatbotLoaded: 'chat00',
  scene: 'lesson',
  isLoginModalOpen: false,
  configs: {
    useProductionDatabase: true,
    numberOfFreeUnits: 5,
    shopScreenVariant: 1,
    isShowMotivation: false,
    isShowInviteFriend: false,
    isEnabledSpacedRepetition: false
  },
  langs: [],
  isTargetLangSymbol: true,
  isBusiness: false,
  isBusinessSignUp: false,
  noAccessUnits: [],
  noAccessChatbotUnits: []
};

/* Methods Start */
const saveUserData = (state, { user }) => ({
  ...state,
  user: { ...state.user, ...user }
});

const updateUserPayments = (state, { payments }) => ({
  ...state,
  payments: { ...state.payments, ...payments }
});

const clearUser = (state: Object) => {
  return { ...state, user: INITIAL_USER };
};

const setVoiceSpeed = (state: State, action: Object): State => {
  const alteredUser = {
    ...state.user,
    voiceSpeed: action.speed
  };
  updateUserProfile({ voiceSpeed: action.speed });
  return { ...state, user: alteredUser };
};

const setDailyGoal = (state, { value }) => {
  const alteredUser = {
    ...state.user,
    dailyGoal: value
  };
  updateUserProfile({ dailyGoal: value });
  return { ...state, user: alteredUser };
};

const setShowTutorial = (state, { value }) => {
  return { ...state, isShowTutorial: value };
};
const setLoading = state => {
  return { ...state, isLoading: !state.isLoading };
};

const toggleLoginModal = state => {
  return { ...state, isLoginModalOpen: !state.isLoginModalOpen };
};

const setBusiness = (state, param) => {
  return { ...state, isBusiness: param };
};

const setLoadingWithParam = (state, param) => {
  return { ...state, isLoading: param };
};
const setChatbotLoaded = (state, param) => {
  return { ...state, chatbotLoaded: param };
};

const saveLangsCode = (state, { langs }) => ({
  ...state,
  langs
});

const currentScene = (state, { scene }) => {
  return { ...state, scene };
};

const setIsLangSymbol = (state, { target }) => {
  return {
    ...state,
    isTargetLangSymbol: _.includes(SYMBOL_LANGUAGE, target)
  };
};

const toggleTutorialMotivate = state => {
  return {
    ...state,
    isTutorialMotivate: state.isShowTutorial
      ? !state.isTutorialMotivate
      : state.isTutorialMotivate
  };
};

const closeTutorialMotivate = state => {
  return {
    ...state,
    isTutorialMotivate: false
  };
};

const setIsBusinessSignUp = state => {
  return {
    ...state,
    isBusinessSignUp: !state.isBusinessSignUp
  };
};

const setSignUpBusinessSuccess = state => {
  const alteredUser = {
    ...state.user,
    isSignUpBusinessSuccess: true
  };
  updateUserProfile({ isSignUpBusinessSuccess: true });
  return { ...state, user: alteredUser };
};

const setReferrer = (state: State, action: Object): State => {
  const alteredUser = {
    ...state.user,
    referrer: action.referrer
  };
  updateUserProfile({ referrer: action.referrer });
  return { ...state, user: alteredUser };
};

const setTrialUsedIP = (state: State, action: Object): State => {
  const alteredUser = {
    ...state.user,
    trialUsedIP: action.trialUsedIP
  };
  updateUserProfile({ trialUsedIP: action.trialUsedIP });
  return { ...state, user: alteredUser };
};

const toggleSoundEffect = (state: State, action: Object): State => {
  const alteredUser = {
    ...state.user,
    isEffectEnabled: action.isEffect
  };
  updateUserProfile({ isEffectEnabled: action.isEffect });
  return { ...state, user: alteredUser };
};

const toggleReminder = (state: State, action: Object): State => {
  const alteredUser = {
    ...state.user,
    reminders: action.reminder
  };
  updateUserProfile({ reminders: action.reminder });

  return { ...state, user: alteredUser };
};

const loadGlobalConfig = (state, { key, value }) => {
  const currentConfig = { ...state.configs, [key]: value };
  return { ...state, configs: currentConfig };
};

const setNoAccessUnits = (state: State, action: Object) => {
  return { ...state, noAccessUnits: action.payload };
};

const setNoAccessChatbotUnits = (state: State, action: Object) => {
  return { ...state, noAccessChatbotUnits: action.payload };
};

/* Methods End */

export default (state: State = INITIAL_STATE, action: Object) => {
  switch (action.type) {
    case USER_SAVE_DATA:
      return saveUserData(state, action);
    case SAVE_SCENE:
      return currentScene(state, action);
    case LOAD_GLOBAL_CONFIG:
      return loadGlobalConfig(state, action);
    case LOAD_LANG_CODES_INDEX:
      return saveLangsCode(state, action);
    case SET_VOICE_SPEED:
      return setVoiceSpeed(state, action);
    case SET_SHOW_TUTORIAL:
      return setShowTutorial(state, action);
    case SET_DAILY_GOAL:
      return setDailyGoal(state, action);
    case SET_LOADING:
      return setLoading(state);
    case SET_LOADING_PARAM:
      return setLoadingWithParam(state, action.param);
    case SET_BUSINESS:
      return setBusiness(state, action.param);
    case SET_CHATBOT_LOADED:
      return setChatbotLoaded(state, action.param);
    case TOGGLE_TUTORIAL_MOTIVATE:
      return toggleTutorialMotivate(state);
    case CLOSE_TUTORIAL_MOTIVATE:
      return closeTutorialMotivate(state);
    case SET_IS_LANG_SYMBOL:
      return setIsLangSymbol(state, action);
    case TOGGLE_SOUND_EFFECT:
      return toggleSoundEffect(state, action);
    case TOGGLE_REMINDER:
      return toggleReminder(state, action);
    case CLEAR_USER:
      return clearUser(state);
    case SET_IS_BUSINESS_SIGN_UP:
      return setIsBusinessSignUp(state);
    case SET_SIGN_UP_BUSINESS_SUCCESS:
      return setSignUpBusinessSuccess(state);
    case SET_TRIAL_USED_IP:
      return setTrialUsedIP(state, action);
    case SET_REFERRER:
      return setReferrer(state, action);
    case TOGGLE_LOGIN_MODAL:
      return toggleLoginModal(state);
    case SET_NO_ACCESS_UNITS:
      return setNoAccessUnits(state, action);
    case SET_NO_ACCESS_CHATBOT_UNITS:
      return setNoAccessChatbotUnits(state, action);
    case UPDATE_SUBSCRIPTIONS:
      return updateUserPayments(state, action);
    default:
      return state;
  }
};
