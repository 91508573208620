import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { IMAGE_PATH } from '../../../common/constants';

const Features = ({ labels }) => {
  const { txt_trust_with_5_million_learners } = labels;
  const theme = useTheme();
  const styles = {
    container: {
      width: '100%',
      maxWidth: '558px',
      mt: 40,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center'
    },
    textContainer: {
      width: '90%'
    },
    text: {
      color: '#595959',
      fontSize: '24px',
      fontWeight: 600
    },
    featuresContainer: {
      width: '558px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      mt: 8,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: 8
      }
    },
    featuresImg1: {
      width: '198px',
      height: '57px'
    },
    featuresImg2: {
      width: '134.965px',
      height: '23.854px'
    },
    featuresImg3: {
      width: '129.002px',
      height: '33.019px'
    }
  };
  const imgPath = image => `${IMAGE_PATH}/onboarding/${image}`;
  return (
    <Box sx={styles.container}>
      <Box sx={styles.textContainer}>
        <Typography variant="text" sx={styles.text}>
          {txt_trust_with_5_million_learners}
        </Typography>
      </Box>
      <Box sx={styles.featuresContainer}>
        <Box
          component="img"
          src={imgPath('evening-post.png')}
          sx={styles.featuresImg1}
        ></Box>
        <Box
          component="img"
          src={imgPath('buzzfeed.png')}
          sx={styles.featuresImg2}
        ></Box>
        <Box
          component="img"
          src={imgPath('vogue.png')}
          sx={styles.featuresImg3}
        ></Box>
      </Box>
    </Box>
  );
};

export default Features;
