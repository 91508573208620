/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import { GREYISH_BROWN, WHITE, GREY } from '../../../common/constants';

const ButtonWrapper = styled.div`
  display: flex;
  height: 55px;
  width: 55px;
  border-radius: 15px;
  flex-direction: row;
  margin: 0 6px;
  border-width: 2px;
  justify-content: center;
  align-items: center;
  border-style: solid;
  cursor: pointer;
`;

const ButtonStopWrapper = styled(ButtonWrapper)`
  background-color: ${GREYISH_BROWN};
  border-color: ${GREYISH_BROWN};
`;

const ButtonPlayWrapper = styled(ButtonWrapper)`
  background-color: ${WHITE};
  border-color: ${GREY};
`;

const Image = styled.img`
  height: 30px;
  width: 30px;
`;

type Props = {
  onTranslate: Function,
  translatePic: string,
  dialog_btn_translate: string,
  translateState: boolean
};
const DialogTranslateButton = ({
  onTranslate,
  translatePic,
  dialog_btn_translate,
  translateState
}: Props) => {
  return translateState ? (
    <ButtonStopWrapper onClick={onTranslate}>
      <Image src={translatePic} alt={translatePic} />
    </ButtonStopWrapper>
  ) : (
    <ButtonPlayWrapper onClick={onTranslate}>
      <Image src={translatePic} alt={translatePic} />
    </ButtonPlayWrapper>
  );
};

export { DialogTranslateButton };
