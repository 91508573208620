import initialState from '../../system/initialState';

const PurchaseReducer = (state = initialState.purchase, action) => {
  switch (action.type) {
    case 'MODAL_VISIBILITY':
      return {
        ...state, isModalVisible: !state.isModalVisible,
      };
    default:
      return { ...state };
  }
};

export default PurchaseReducer;
