/* @flow */
import _ from 'lodash';
import { PRESS_CHARACTER_CARD, DIALOGUE } from '../constants';
import * as types from '../constants';
import { nextGameScreen } from './GameActions';
import Analytic from '../../util/Analytic';
import { gameSound as utilGameSound } from '../../util';
import { getIsEffectEnabled } from '../../chatbots/selectors';

export const pressCharacterCard = (charId: string) => {
  return (dispatch: Function, getState: Function) => {
    const { isResultShown, lockedIds, pressedIds } = getState().spelling;
    if (!isResultShown && !_.includes(lockedIds, Number(charId))) {
      const { gameType } = getState().routes;
      console.log(getState());

      if (gameType === DIALOGUE) {
        const { func, questions, dialogueIds } = getState().dialog;
        const selected = _.indexOf(pressedIds, charId);
        const pointer = _.findKey(pressedIds, _.isNull);
        const index = _.indexOf(dialogueIds, questions[pointer]);
        // console.log(selected);
        if (pointer && selected === -1) {
          func(index);
        }
      }
      dispatch({
        type: PRESS_CHARACTER_CARD,
        charId
      });
    }
  };
};

export const pressSpellingNextButton = () => {
  return (dispatch: Function, getState: Function) => {
    if (
      getState().storage.isShowTutorial &&
      getState().spelling.isResultShown
    ) {
      dispatch(nextGameScreen());
    } else if (getState().spelling.isResultShown) {
      const { game, routes, spelling } = getState();
      Analytic.log(Analytic.key.G1, {
        game_type: routes.gameType,
        content: game.key,
        answer: !spelling.answeredWrong
      });
      Analytic.logGA(Analytic.key.G1, {
        category: Analytic.key.G1,
        action: 'Click',
        label: routes.gameType,
        value: !spelling.answeredWrong
      });
      dispatch(nextGameScreen());
    } else {
      dispatch({
        type: types.CHECK_SPELLING_RESULT
      });
      dispatch(playSound());
    }
  };
};

const playSound = () => {
  return (dispatch: Function, getState: Function) => {
    const state = getState();
    const isEffectEnabled = getIsEffectEnabled(state);
    const gameSound = isEffectEnabled ? utilGameSound : arg => {};
    if (getState().spelling.isResultShown) {
      if (getState().spelling.answeredWrong) {
        gameSound('incorrect');
      } else {
        gameSound('correct');
      }
    }
  };
};
