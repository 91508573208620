/* @flow */
import React from 'react';
import styled from 'styled-components';
import { getProfileImage } from '../../util';
import {
  GREYISH_BROWN,
  WHITE,
  BROWN_GREY_TWO,
  SEA_BLUE,
  DARK_GREY_1,
  VERY_LIGHT_PINK_TWO,
  SETTING_GREY_BACKGROUND,
  PREFIX_HEADER,
  PRO_TXT,
  LING_TXT
} from '../../common/constants';
import { ANONYMOUS } from '../constants';

const RightSection = styled.div`
  margin-left: 28px;
  margin-top: 24px;
  display: ${props => (props.isUpdateMethod ? 'none' : 'block')};
`;

const ProfileBox = styled.div`
  border: 1px solid ${VERY_LIGHT_PINK_TWO};
  width: auto;
  margin-bottom: 10px;
  border-radius: 20px;
  padding-top: 20px;
  &.coupon {
    padding-top: 10px;
  }
`;

const UserInfoContainer = styled.div`
  margin-left: 8px;
  margin-top: 7px;
  display: ${props => (props.name === ANONYMOUS ? 'flex' : 'unset')};
  align-items: ${props => (props.name === ANONYMOUS ? 'center' : 'unset')};
`;
const ProfileContainer = styled.div`
  display: flex;
  padding-left: 38px;
  padding-right: 38px;
  margin-bottom: 41px;
`;

const ProfileImg = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;
const UserNameText = styled.p`
  font-size: 21px;
  font-weight: bold;
  color: ${GREYISH_BROWN};
  margin: 0 !important;
  line-height: 29px;
`;
const UserEmailText = styled.p`
  font-size: 16px;
  color: ${BROWN_GREY_TWO};
  margin: 0 !important;
`;
const GotoProfileText = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${SEA_BLUE};
  text-align: start;
  padding-left: 20px;
  cursor: pointer;
  margin-bottom: 10px !important;
`;
const LogOutText = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${DARK_GREY_1};
  text-align: start;
  padding-left: 20px;
  margin-top: 10px !important;
  cursor: pointer;
`;

const SettingText = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${DARK_GREY_1};
  text-align: start;
  padding-left: 20px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-radius: 10px;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  cursor: pointer;
  span {
    background: ${SEA_BLUE};
    padding: 3px 13px;
    border-radius: 6px;
    color: ${WHITE};
    font-size: 14px;
  }
  &.redeemTitle {
    font-size: 18px;
    padding-left: 13px;
    margin-top: 0px !important;
    cursor: unset;
  }
  &.setting {
    background: ${SETTING_GREY_BACKGROUND};
  }
`;

const lingProfile = `${PREFIX_HEADER}Male.png`;
type Props = {
  signOut: Function,
  logout_txt: string,
  setting_txt_settingHeader: string,
  location: Object,
  footer_txt_profile: string,
  history: Object,
  isUpdateMethod: boolean,
  photoURL: string,
  name: string,
  userInfo: Object
};
const ManageSubscriptionRightSection = ({
  signOut,
  logout_txt,
  setting_txt_settingHeader,
  location,
  footer_txt_profile,
  history,
  isUpdateMethod,
  photoURL,
  name,
  userInfo
}: Props) => {
  const { pathname } = location;
  return (
    <RightSection isUpdateMethod={isUpdateMethod}>
      <ProfileBox>
        <ProfileContainer>
          <ProfileImg src={getProfileImage(lingProfile, photoURL)} />
          <UserInfoContainer name={name}>
            <UserNameText>{name}</UserNameText>
            {name !== ANONYMOUS ? (
              <UserEmailText>{userInfo.email}</UserEmailText>
            ) : null}
          </UserInfoContainer>
        </ProfileContainer>
        <GotoProfileText
          id="GotoProfileText"
          onClick={() => {
            history.push('/profile');
          }}
        >
          {footer_txt_profile}
        </GotoProfileText>
        <SettingText
          id="SettingText"
          onClick={() => {
            history.push('/setting');
          }}
          className={pathname === '/setting' ? 'setting' : null}
        >
          {setting_txt_settingHeader}
        </SettingText>
        <SettingText
          id="SettingText"
          onClick={() => {
            history.push('/subscription');
          }}
          className={pathname === '/subscription' ? 'setting' : null}
        >
          {LING_TXT} <span>{PRO_TXT}</span>
        </SettingText>
        {name !== ANONYMOUS && (
          <LogOutText
            id="LogOutText"
            onClick={() => {
              history.push('/');
              signOut();
            }}
          >
            {logout_txt}
          </LogOutText>
        )}
      </ProfileBox>
    </RightSection>
  );
};
export { ManageSubscriptionRightSection };
