/* @flow */
import _ from 'lodash';
import { parseSVG, makeAbsolute } from 'svg-path-parser';

export const parseSvg = (coordinates: string = '') => {
  return _(coordinates.split('M'))
    .compact()
    .map(item => {
      const parsed = parseSVG(`M${item}`);
      makeAbsolute(parsed);
      return parsed;
    })
    .value();
};

export const getCorrectPath = (coordinate: Object[]) => {
  return _.reduce(
    coordinate,
    (correct: Array<Object>, path: Object) => [
      ...correct,
      ...convertPathToObject(path)
    ],
    []
  );
};

export const convertPathToObject = (path: Object) => {
  return transformPathForCorrection(path, getPathObject());
};

export const transformPathForCorrection = (path: Object, getPath: Function) => {
  return transformPath(path, getPath, getPath.bind(null, path.x, path.y));
};

export const transformPath = (
  path: Object,
  getPath: Function,
  getHorizontalPath: Function
) => {
  const { code, x, y, x1, y1, x2, y2 } = path;
  switch (code) {
    case 'M':
    case 'L':
    case 'T':
    case 'V':
      return [getPath(x, y)];
    case 'H':
      return [getHorizontalPath()]; // Read the spec of H it start reading y before x
    case 'Q':
      return [getPath(x1, y1), getPath(x, y)];
    case 'S':
      return [getPath(x2, y2), getPath(x, y)];
    case 'C':
      return [getPath(x1, y1), getPath(x2, y2), getPath(x, y)];
    default:
      return [];
  }
};

export const getPathObject = () => (x: number, y: number) => {
  return {
    x,
    y
  };
};
