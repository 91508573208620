/* @flow */
import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { Card, FlashcardRow, CheckButton } from '../sub-components';
import { playSoundWithCallback, stopSound } from '../../../util';
import { BUTTON_CONTINUE, FLASHCARD } from '../../constants';
import styled from 'styled-components';
import { SILVER_COLOR, LIGHT_GREY } from '../../../common/constants';

const ab = require('../../../img/icons/word-icon-24x24.png');
const sentenceIcon = require('../sub-components/img/flashcard/sentence-icon.png');

type Props = {
  transcriptState?: boolean,
  isShowTutorial: boolean,
  isSentenceExist?: boolean,
  analyticFlashcard?: Function,
  voiceSpeed: number,
  coins: number,
  isTargetLangSymbol: boolean,
  setShowTutorial: Function,
  phrase: {
    transcript?: string,
    original: string,
    translation: string,
    soundFile: string,
    imageSource: string,
    transcript: string,
    id: string
  },
  btn_check?: string,
  btn_continue?: string,
  title_txt_vocabulary?: string,
  btn_hint?: string,
  sentenceCard: {
    transcript?: string,
    original: string,
    translation: string,
    soundFile: string,
    imageSource: string,
    id: string
  },
  onPressNext?: () => void,
  onPressFlashcard?: Function,
  showGrammarScreen?: boolean,
  id: string,
  soundPath: string,
  flashgame_txt_new_vocabulary: string,
  writing_skip: string,
  onCloseTutorialMotivate: Function
};
type State = { playingPhrase: string };

class Flashcard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      playingPhrase: ''
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    // $flow-disable-line
    document.addEventListener('keydown', this.handleEnter);
  }
  componentWillUnmount() {
    window.scrollTo(0, 0);

    // $flow-disable-line
    document.removeEventListener('keydown', this.handleEnter);
  }
  handleEnter = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { onPressNext } = this.props;
    if (event.keyCode === 13) {
      if (onPressNext) {
        onPressNext();
        window.scrollTo(0, 0);
      }
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const currentSound = _.get(this.props, 'phrase.soundFile', null);
    const nextSound = _.get(nextProps, 'phrase.soundFile', null);
    const nextId = _.get(nextProps, 'phrase.id', null);
    const nextSentenceSound = _.get(nextProps, 'sentenceCard.soundFile', null);
    const nextSentenceId = _.get(nextProps, 'sentenceCard.id', null);
    const isSentenceExist = _.get(nextProps, 'isSentenceExist', false);
    const { showGrammarScreen } = this.props;
    const { showGrammarScreen: nextShowGrammar } = nextProps;

    if (
      (currentSound !== nextSound && !nextShowGrammar) ||
      showGrammarScreen !== nextShowGrammar
    ) {
      this.playSequence(
        isSentenceExist,
        nextSound,
        nextId,
        nextSentenceSound,
        nextSentenceId
      );
    }
  }

  UNSAFE_componentWillMount() {
    const { showGrammarScreen, isSentenceExist } = this.props;
    if (!showGrammarScreen) {
      this.playSequence(isSentenceExist);
    }
  }

  setPlayingPhrase(id: string) {
    this.setState({
      playingPhrase: id
    });
  }

  removePlayingPhrase() {
    this.setState({
      playingPhrase: ''
    });
  }

  playSequence(
    isSentenceExist: boolean = false,
    nextSoundFile?: string,
    nextId?: string,
    nextSentenceSoundFile?: string,
    nextSentenceId?: string
  ) {
    const { phrase, voiceSpeed, soundPath, sentenceCard } = this.props;
    const { soundFile, id } = phrase;
    const { soundFile: sentenceSoundFile, id: sentenceId } = sentenceCard;
    this.onPlay(
      () => this.setPlayingPhrase(nextId || id),
      nextSoundFile || soundFile,
      voiceSpeed,
      soundPath,
      () => {
        this.removePlayingPhrase();
        if (isSentenceExist) {
          this.onPlay(
            () => this.setPlayingPhrase(nextSentenceId || sentenceId),
            nextSentenceSoundFile || sentenceSoundFile,
            voiceSpeed,
            soundPath,
            () => this.removePlayingPhrase()
          );
        }
      }
    );
  }
  playVocab() {
    const { phrase, voiceSpeed, soundPath } = this.props;
    const { soundFile, id } = phrase;
    this.onPlay(
      () => this.setPlayingPhrase(id),
      soundFile,
      voiceSpeed,
      soundPath,
      () => this.removePlayingPhrase()
    );
  }
  onPlay(
    beforeCallback: Function,
    soundFile: string,
    voiceSpeed: number,
    path: string,
    afterCallback: Function
  ) {
    beforeCallback && beforeCallback();
    playSoundWithCallback(soundFile, voiceSpeed, path, () => {
      afterCallback && afterCallback();
    });
  }

  render() {
    const {
      transcriptState,
      phrase,
      isSentenceExist,
      onPressNext,
      analyticFlashcard,
      btn_check,
      btn_continue,
      sentenceCard,
      voiceSpeed,
      coins,
      soundPath,
      isShowTutorial,
      flashgame_txt_new_vocabulary,
      isTargetLangSymbol,
      writing_skip,
      onCloseTutorialMotivate,
      setShowTutorial
    } = this.props;

    const { playingPhrase } = this.state;

    const {
      original = '',
      translation = '',
      imageSource,
      soundFile = '',
      transcript = '',
      id
    } = phrase;

    const {
      original: sentenceOriginal = '',
      translation: sentenceTranslation = '',
      soundFile: sentenceSoundFile = '',
      transcript: sentenceTranscript = '',
      id: sentenceId
    } = sentenceCard;

    const cardStyle = {
      height: 286,
      width: 220,
      borderRadius: 10,
      borderStyle: 'solid',
      borderColor: LIGHT_GREY,
      borderWidth: 1,
      boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.05)',
      marginVertical: 14
    };

    const Container = styled.div`
      flex: 1;
      height: 100vh;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding-top: ${props => (props.isShowTutorial ? 'unset' : '92px')};
      padding-bottom: ${props => (props.isShowTutorial ? '100px' : '112px')};
    `;
    const Title = styled.p`
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px !important;
      text-transform: capitalize;
    `;

    const ImageStyle = styled.img`
      align-self: center;
      height: 150px;
      width: 150px;
    `;

    const FlashcardContainer = styled.div`
      display: flex;
      margin: auto;
      width: ${props => (props.isShowTutorial ? 680 : 800)}px;
    `;

    const FlashcardImageContainer = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      border: 0px solid ${SILVER_COLOR};
      border-radius: 10px;
    `;

    const FlashcardRowContainer = styled.div`
      width: 100%;
    `;

    return (
      <Fragment>
        <Container isShowTutorial={isShowTutorial}>
          <div>
            <Title>{flashgame_txt_new_vocabulary}</Title>
            <FlashcardContainer isShowTutorial={isShowTutorial}>
              <FlashcardImageContainer>
                <Card
                  style={cardStyle}
                  onClick={() =>
                    onPlay(
                      () => this.setPlayingPhrase(id),
                      soundFile,
                      voiceSpeed,
                      soundPath,
                      () => this.removePlayingPhrase()
                    )
                  }
                >
                  <ImageStyle src={imageSource} />
                </Card>
              </FlashcardImageContainer>
              <FlashcardRowContainer>
                <FlashcardRow
                  id={id}
                  soundFile={soundFile}
                  translation={translation}
                  original={original}
                  transcript={transcript}
                  transcriptState={transcriptState || false}
                  isSentenceExist={true}
                  imgSrc={sentenceIcon}
                  voiceSpeed={voiceSpeed}
                  setPlayingPhrase={() => this.setPlayingPhrase(id)}
                  removePlayingPhrase={() => this.removePlayingPhrase()}
                  playingPhrase={playingPhrase}
                  soundPath={soundPath}
                  isTargetLangSymbol={isTargetLangSymbol}
                />
                <FlashcardRow
                  id={sentenceId}
                  soundFile={sentenceSoundFile}
                  translation={sentenceTranslation}
                  original={sentenceOriginal}
                  transcript={sentenceTranscript || ''}
                  transcriptState={transcriptState || false}
                  isSentenceExist={isSentenceExist || false}
                  imgSrc={ab}
                  voiceSpeed={voiceSpeed}
                  setPlayingPhrase={() => this.setPlayingPhrase(sentenceId)}
                  removePlayingPhrase={() => this.removePlayingPhrase()}
                  playingPhrase={playingPhrase}
                  soundPath={soundPath}
                  isTargetLangSymbol={isTargetLangSymbol}
                />
              </FlashcardRowContainer>
            </FlashcardContainer>
          </div>
        </Container>
        <CheckButton
          quizAnswer={''}
          onPressNext={() => {
            stopSound();
            if (onPressNext) {
              onPressNext();
              window.scrollTo(0, 0);
            }
            if (analyticFlashcard) {
              analyticFlashcard();
            }
          }}
          mode={BUTTON_CONTINUE}
          btn_check={btn_check}
          btn_continue={btn_continue}
          btn_hint={''}
          gameType={FLASHCARD}
          coins={coins}
          isShowTutorial={isShowTutorial}
          writing_skip={writing_skip}
          onCloseTutorialMotivate={onCloseTutorialMotivate}
          setShowTutorial={setShowTutorial}
        />
      </Fragment>
    );
  }
}

const onPlay = (
  beforeCallback: Function,
  soundFile: string,
  voiceSpeed: number,
  path: string,
  afterCallback: Function
) => {
  beforeCallback && beforeCallback();
  playSoundWithCallback(soundFile, voiceSpeed, undefined, () => {
    afterCallback && afterCallback();
  });
};

export { Flashcard };
export default Flashcard;
