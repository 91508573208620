/* @flow */
import React from 'react';
import styled from 'styled-components';
import { LAVENDER_COLOR } from '../../../common/constants';

const ReviewHeaderContainer = styled.div`
  width: 220px;
  height: 249px;
  border-radius: 40px;
  border: ${LAVENDER_COLOR} 3px solid;
  background-color: ${LAVENDER_COLOR};
  margin: auto;
  margin-top: 160px;
  margin-bottom: 46px;
`;

const ReviewHeaderContent = styled.div`
  width: 220px;
  height: 162px;
  opacity: ${props => props.opacity};
  background-image: url(${props => props.url});
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
`;

const Title = styled.p`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-top: 15px !important;
`;
const ReviewHeaderCard = ({
  image,
  title,
  unitLevel_txt_unit,
  currentUnit
}: Object) => {
  return (
    <ReviewHeaderContainer>
      <ReviewHeaderContent url={image} />
      <Title>
        {unitLevel_txt_unit}&nbsp;{currentUnit}
        <br /> {title}
      </Title>
    </ReviewHeaderContainer>
  );
};

export { ReviewHeaderCard };
