/* @flow */
import styled from 'styled-components';
import {
  MARIGOLD,
  WHITE,
  BLACK,
  BLACK_TWO_COLOR
} from '../../common/constants';
const TutorialContainerStyle = styled.div`
  width: ${props =>
    props.isShowTutorial || props.showTutorial ? '680px' : 'unset'};
  margin: ${props =>
    props.isShowTutorial || props.showTutorial ? 'auto' : 'unset'};
  margin-top: ${props =>
    props.isShowTutorial || props.showTutorial ? '30px' : 'unset'};
  position: ${props =>
    props.isShowTutorial || props.showTutorial ? '30px' : 'unset'};
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const BackgroundBottomImage = styled.img`
  display: ${props =>
    props.isShowTutorial || props.showTutorial ? 'unset' : 'none'};
  position: absolute;
  bottom: 0;
  height: 184px;
  left: 50%;
  transform: translateX(-50%);
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 225px);
`;
const Box = styled.div`
  background-color: ${BLACK};
  margin: auto;
  border-radius: 15px;
  position: absolute;
  top: ${props => props.popoverTop}px;
  left: ${props => props.popoverLeft}px;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    top: -10px;
    left: 15%;
    height: 20px;
    width: 20px;
    background: ${BLACK_TWO_COLOR};
    transform: translateX(-50%) rotate(45deg);
    ${'' /* transform: translateX(-50%); */}

    border-bottom: inherit;
    border-right: inherit;
    box-shadow: inherit;
  }
`;
const InnerBox = styled.div`
  background-color: ${BLACK_TWO_COLOR};
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  text-align: center;
  box-shadow: inset 0 -4px 0 0 ${BLACK};
  &:hover {
    box-shadow: unset;
  }
`;
const CrackOpenText = styled.p`
  flex: 0.8;
  color: ${WHITE};
  font-size: 17px;
`;
const ImageContainer = styled.div`
  flex: 0.2;
  alignitems: center;
  padding-top: 5px;
  padding-bottom: 5px;
`;
const EggCrackImageModal = styled.img`
  width: 31px;
  height: 38px;
`;

const Container = styled.div`
  top: ${props =>
    (props.isShowTutorial || props.showTutorial) && !props.isFinishTutorial
      ? 70
      : 380}px;
  position: relative;
  width: 100vw;
  background: ${props =>
    (props.isShowTutorial || props.showTutorial) && !props.isFinishTutorial
      ? MARIGOLD
      : WHITE};
  height: ${props => (props.isShowTutorial ? 'calc(100vh - 70px)' : 'unset')};
`;
export {
  TutorialContainerStyle,
  BackgroundBottomImage,
  SpinnerContainer,
  Box,
  InnerBox,
  CrackOpenText,
  ImageContainer,
  EggCrackImageModal,
  Container
};
