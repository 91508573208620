import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import OnboardingButton from '../selectorComponents/subComponents/OnboardingButton';
import { useTheme } from '@mui/material/styles';
import { IMAGE_PATH } from '../../common/constants';
import { useMediaQuery } from '@mui/material';

const IntroductionScreen = ({
  headerData,
  handleScreenChange,
  trackScreenView,
  labels
}) => {
  useEffect(() => {
    trackScreenView('intro_screen');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Call only once
  const {
    btn_begin_quiz,
    trial_txt_terms_of_service,
    purchase_txt_policy,
    txt_money_back_policy
  } = labels;
  const theme = useTheme();
  const imgPath = image => `${IMAGE_PATH}onboarding/${image}`;
  const styles = {
    container: {
      backgroundColor: '#D2EEFF',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      pt: 14,
      height: '100vh',
      [theme.breakpoints.up('sm')]: {
        fontSize: '36px',
        pt: 22,
        height: '80vh'
      },
      textAlign: 'center'
    },
    headerContainer: { maxWidth: 500, mb: 6 },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      px: 5,
      width: '38%',
      minWidth: 345,
      maxWidth: 450,
      height: 150,
      justifyContent: 'space-between',
      fontColor: '#111111',
      zIndex: 2
    },
    button: {
      backgroundColor: theme.palette.newPrimary.activate,
      width: '70%',
      minWidth: '300px',
      maxWidth: '364px',
      height: '38px',
      borderRadius: 9,
      textTransform: 'none',
      color: '#FFFFFF',
      fontSize: '18px',
      fontWeight: 700,
      mt: 8
    },
    text: {
      fontSize: '30px',
      fontColor: '#111111',
      [theme.breakpoints.up('sm')]: {
        fontSize: '36px'
      }
    },
    subtext: {
      fontSize: '18px'
    },
    imageContainer: {
      width: '100%',
      position: 'absolute',
      bottom: 0,
      maxHeight: '250px',
      [theme.breakpoints.up('sm')]: {
        maxHeight: '300px'
      },
      [theme.breakpoints.up('lg')]: {
        maxHeight: '600px'
      }
    },
    image: {
      width: '100%'
    },
    deactivatedText: {
      fontSize: '10px',
      borderBottom: '1px solid #999999',
      '&:focus': {
        outline: 'none'
      }
    },
    footerContainer: {
      width: '345px',
      height: '35px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      zIndex: 2,
      mt: 4,
      [theme.breakpoints.up('sm')]: {
        mt: 0
      }
    },
    link: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none'
      }
    }
  };
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Box sx={styles.container}>
      <Box sx={styles.headerContainer}>
        <Typography variant="text" sx={styles.text}>
          {headerData.text}
        </Typography>
      </Box>
      <Box sx={styles.textContainer}>
        <Typography variant="subtext" sx={styles.subtext}>
          {headerData.subtext}
        </Typography>
        <OnboardingButton
          disabled={false}
          size="large"
          clickAction={() => {
            handleScreenChange('');
          }}
          buttonText={btn_begin_quiz}
          style={styles.button}
        />
      </Box>
      <Box sx={styles.imageContainer}>
        <Box
          component="img"
          src={
            isLargeScreen
              ? imgPath('introduction.png')
              : imgPath('introduction-mobile.png')
          }
          sx={styles.image}
        />
      </Box>
      <Box sx={styles.footerContainer}>
        <a
          href="https://ling-app.com/terms-of-use/"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.link}
        >
          <Typography variant="deactivatedText" sx={styles.deactivatedText}>
            {trial_txt_terms_of_service}
          </Typography>
        </a>
        <a
          href="https://ling-app.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.link}
        >
          <Typography variant="deactivatedText" sx={styles.deactivatedText}>
            {purchase_txt_policy}
          </Typography>
        </a>
        <a
          href="https://ling-app.com/money-back-guarantee/"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.link}
        >
          <Typography variant="deactivatedText" sx={styles.deactivatedText}>
            {txt_money_back_policy}
          </Typography>
        </a>
      </Box>
    </Box>
  );
};

export default IntroductionScreen;
