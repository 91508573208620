/* @flow */
import { connect } from 'react-redux';
import { GrammarScreen } from '../components/main-components';
import { toggleGrammarScreen } from '../actions';
import _ from 'lodash';
import { correctLineFeed, getLabels, getLabel } from '../../util';

export const mapStateToProps = (state: Object) => {
  const { game, storage } = state;
  const { isTargetLangSymbol } = storage;
  const { grammar } = game;
  const parseGrammarTable = tableString => {
    let parsedTable;
    try {
      parsedTable = JSON.parse(tableString);
    } catch (e) {
      parsedTable = false;
    }
    return parsedTable;
  };
  const correctedGrammar = {
    ...grammar,
    details: _.map(grammar.details, section => {
      const grammarTable = parseGrammarTable(section.text);
      return {
        ...section,
        isGrammarTable: Boolean(grammarTable),
        text: grammarTable ? grammarTable : correctLineFeed(section.text)
      };
    })
  };

  return {
    grammar: correctedGrammar,
    isTargetLangSymbol,
    ...getLabels(
      state,
      'purchase_txt_benefit_03',
      'btn_check',
      'btn_continue',
      'help_translate_txt'
    ),
    grammar_help_txt: getLabel(
      state,
      'grammar_help_txt',
      'Are you interested in helping us translate this screen into your language?'
    )
  };
};

export { GrammarScreen };
export default connect(mapStateToProps, { toggleGrammarScreen })(GrammarScreen);
