/* @flow */
import axios from 'axios';
import _ from 'lodash';
import { firebase } from '../system/Firebase';
import BugTracker from './BugTracker';
import { couponAPI } from '../common/constants';
import { updateUserData } from '../common/actions';
import Analytic from '../util/Analytic';
import { PRODUCT_TYPE, PROMO_NAME, ID } from '../shop/constants';
import {
  redirectToPage,
  redirectWithPromo,
  getUrlParam,
  getStripeOneTimeProductId,
  stripeCheckout,
  getStripeDiscountProductId,
  getUserProperty,
  logOutClaim,
  purchaseFailed
} from './';
import { isMobile } from './';
export const validateRedeemCode = (
  redeemCode: string,
  functions: string,
  user: Object,
  history: Object
) => {
  if (
    redeemCode !== 'monthly' &&
    redeemCode !== 'yearly' &&
    redeemCode !== 'lifetime'
  ) {
    axios
      .post(`${functions}${couponAPI}`, {
        uid: user.uid,
        couponCode: redeemCode
      })
      .then(res => {
        if (res.status === 200) {
          !res.data.success &&
            alert(
              'This coupon is already redeemed. Please contact support@simyasolutions.com if you have not redeemed this coupon before.'
            );

          updateUserData({ subscription: res.data.subscription });
          isMobile
            ? (window.location = 'https://ling-app.com/redeem-success/')
            : redirectToPage(history, '/');
        }
      })
      // eslint-disable-next-line handle-callback-err
      .catch(err => {
        BugTracker.notify(err);
      });
  }
};

export const getRedeemTitle = (
  id: number,
  packageName: string,
  shop_txt_checkout_title_top: string
) =>
  parseInt(id, 10) !== 0
    ? ` <span>${parseInt(id, 10)}% OFF</span> for 60+ Languages`
    : packageName === PRODUCT_TYPE.yearly
    ? '7 day <span>Free</span> for 60+ Languages'
    : shop_txt_checkout_title_top;

export const isRedeem = (redeemId: string) =>
  redeemId === 'monthly' || redeemId === 'yearly' || redeemId === 'lifetime';

export const onRedeemSuccessfully = (functions: string, redeemId: string) => {
  axios
    .get(`${functions}/validateLingWeb-stripeApi/coupon/${redeemId}`)
    .then(res => {
      if (res.status === 200) {
        if (
          !res.data.success &&
          res.data.status &&
          res.data.status === 'invalid'
        ) {
          alert('The code is incorrect.');
          window.location.replace('https://ling-app.com/ling-gift-redeem/');
        }
      }
    })
    // eslint-disable-next-line handle-callback-err
    .catch(err => {
      BugTracker.notify(err);
    });
};
export const isClaimReward = (currentPromoId: number) => currentPromoId === 2;

export const handleFetchUserDataAfterRedirect = (
  functions: string,
  location: Object,
  isDev: boolean,
  user: Object,
  isProUser: boolean,
  history: Object,
  signOut: Function,
  productName: string,
  receipt: string,
  currency: string,
  packageName: string,
  id: number,
  signInAnonymously: Function,
  redeemId: string,
  fetchUser: Function,
  saveUserData: Function,
  createNewDatabaseUser: Function,
  fetchRemoteDatabase: Function,
  isFirebaseNotAllow: Function,
  claimObjectStateChangeHandler: Function
) => {
  let auth = firebase.auth();
  if (redeemId) {
    if (!_.isEmpty(redeemId) && !isRedeem(redeemId)) {
      onRedeemSuccessfully(functions, redeemId);
    }
    auth.onAuthStateChanged(user => {
      if (!user) {
        auth
          .signInAnonymously()
          .then(result => {
            const { user } = result;
            fetchUser(user, saveUserData, createNewDatabaseUser);
            onCheckout(
              location,
              isDev,
              user,
              isProUser,
              functions,
              history,
              signOut,
              productName,
              receipt,
              currency,
              packageName,
              id,
              signInAnonymously,
              claimObjectStateChangeHandler,
              redeemId,
              'redeem'
            );
          })
          .catch(error => {
            isFirebaseNotAllow(error);
          });
      } else {
        fetchRemoteDatabase(`users/${user.uid}`).then(data => {
          const value = data.val();
          if (value) {
            saveUserData(value);
          } else {
            createNewDatabaseUser(
              user,
              (user.providerData[0] &&
                user.providerData[0].providerId.split('.')[0]) ||
                'anonymous'
            );
          }
          onCheckout(
            location,
            isDev,
            user,
            isProUser,
            functions,
            history,
            signOut,
            productName,
            receipt,
            currency,
            packageName,
            id,
            signInAnonymously,
            claimObjectStateChangeHandler,
            redeemId,
            'redeem'
          );
        });
      }
    });
  } else {
    auth.onAuthStateChanged(user => {
      if (!user) {
        auth
          .signInAnonymously()
          .then(result => {
            const { user } = result;
            fetchUser(user, saveUserData, createNewDatabaseUser);
            onCheckout(
              location,
              isDev,
              user,
              isProUser,
              functions,
              history,
              signOut,
              productName,
              receipt,
              currency,
              packageName,
              id,
              signInAnonymously,
              claimObjectStateChangeHandler
            );
          })
          .catch(error => {
            isFirebaseNotAllow(error);
          });
      } else {
        fetchRemoteDatabase(`users/${user.uid}`).then(data => {
          const value = data.val();
          if (value) {
            saveUserData(value);
          } else {
            createNewDatabaseUser(
              user,
              (user.providerData[0] &&
                user.providerData[0].providerId.split('.')[0]) ||
                'anonymous'
            );
          }
          onCheckout(
            location,
            isDev,
            user,
            isProUser,
            functions,
            history,
            signOut,
            productName,
            receipt,
            currency,
            packageName,
            id,
            signInAnonymously,
            claimObjectStateChangeHandler
          );
        });
      }
    });
  }
};

export const onCheckout = (
  location: Object,
  isDev: boolean,
  user: Object,
  isProUser: boolean,
  functions: string,
  history: Object,
  signOut: Function,
  productName: string,
  receipt: string,
  currency: string,
  packageName: string,
  id: number,
  signInAnonymously: Function,
  claimObjectStateChangeHandler: Function,
  redeemId: string = '',
  type: string = ''
) => {
  const { search } = location;
  const cardholderEmail = getUrlParam('cardholderEmail');
  if (isRedeem(redeemId)) {
    let productId = getStripeOneTimeProductId(redeemId, currency);
    stripeCheckout(productId, redeemId, isDev, currency);
  } else {
    if (type !== 'redeem') {
      let productId = getStripeDiscountProductId(
        packageName,
        id,
        currency,
        claimObjectStateChangeHandler
      );
      claimObjectStateChangeHandler({
        productId: productId,
        itemInfo: [{ plan: productId, quantity: 1 }]
      });
    }
    let userEmail = getUserProperty(user, 'email');
    if (id === 0 && userEmail === '') {
      claimObjectStateChangeHandler('isLoading', false);
    }
    if (!_.isEmpty(userEmail)) {
      if (isProUser && !isMobile) {
        history.push('/');
      } else if (!_.isEmpty(receipt)) {
        userEmail === cardholderEmail
          ? redirectWithPromo(productName, isDev, receipt)
          : purchaseFailed();
      } else if (redeemId) {
        validateRedeemCode(redeemId, functions, user, history);
      } else {
        logOutClaim(signOut, signInAnonymously);
      }
    } else {
      if (
        // eslint-disable-next-line
        !_.isEmpty(localStorage.getItem(ID)) &&
        id === 0
      ) {
        claimObjectStateChangeHandler({
          // eslint-disable-next-line
          id: parseInt(localStorage.getItem(ID), 10) || 0,
          isLoading: false
        });
        // eslint-disable-next-line
      } else {
        const promoName = search.match(/promoName=([^&]*)/);
        const id = search.match(/id=([^&]*)/);
        // eslint-disable-next-line
        localStorage.setItem(PROMO_NAME, promoName && promoName[1]);
        // eslint-disable-next-line
        localStorage.setItem(ID, id && id[1]);
        claimObjectStateChangeHandler({
          isLoading: false,
          isCheckout: true
        });
      }
    }
    Analytic.log(Analytic.key.A4, {
      category: Analytic.key.A4,
      action: 'Click',
      label: productName
    });
    Analytic.logGA(Analytic.key.A4, {
      category: Analytic.key.A4,
      action: 'Click',
      label: productName
    });
  }
};
