/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import {
  MARIGOLD,
  BANANA_POINT_SYMBOL,
  PLUS_SIGN_SYMBOL,
  MULTI_BY_1_TXT
} from '../../../common/constants';

const banana = require('../../../img/icons/banana.png');
const coin = require('../../../img/icons/coin-icon.png');

const EarnText = styled.p`
  background: transparent;
  font-size: 16px;
  color: ${MARIGOLD};
  margin: 0px !important;
  text-align: center;
`;

const TextScore1 = styled.p`
  margin: 0px !important;
  background: transparent;
  font-size: 40px;
  color: ${MARIGOLD};
`;
const SubScore = styled.span`
  font-size: ${props => (props.isExam ? 'unset' : '20px')};
  font-weight: ${props => (props.isExam ? 'unset' : 'bold')};
  display: ${props => (props.isExam ? 'flex' : 'unset')};
  justify-content: ${props => (props.isExam ? 'center' : 'unset')};
  align-items: ${props => (props.isExam ? 'center' : 'unset')};
`;
const SecondSub = styled.p`
  margin-top: 0 !important;
  &::first-letter {
    text-transform: uppercase;
  }
`;
const ScoreContainer = styled.p`
  width: ${props => (props.isExam ? 'unset' : '349px')};
  height: ${props => (props.isExam ? 'unset' : '63px')};
  text-align: ${props => (props.isExam ? 'unset' : 'center')};
  margin-top: ${props => (!props.isExam ? '15px !important' : '0 !important')};
  margin-bottom: ${props => (!props.isExam ? '15px !important' : 'unset')};
  font-size: ${props => (!props.isExam ? '20px' : 'unset')};
  position: ${props => (props.isExam ? 'absolute' : 'unset')};
  left: ${props => (props.isExam ? '50%' : 'unset')};
  top: ${props => (props.isExam ? '50%' : 'unset')};
  transform: ${props => (props.isExam ? 'translate(-50%, -7%)' : 'unset')};
  &::first-letter {
    text-transform: uppercase;
  }
`;
const BpStyle = styled.img`
  marginright: 5px;
  width: 20px;
  height: 20px;
`;
const SubScore2 = styled.p`
  margin: 0px !important;
  background: transparent;
  font-size: 18px;
  color: ${MARIGOLD};
`;

const RowStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 30px;
  margin-top: 10px;
`;
const PerfectStyle = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  border-top: 1px ${MARIGOLD} solid;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
  align-items: center;
`;
const TextScore2 = styled.p`
  background: transparent;
  font-size: 30px;
  color: ${MARIGOLD};
  margin: 0 !important;
`;

const BananStyle = styled.img`
  width: 20px;
  height: 20px;
`;

const PerfexStyle = styled.div`
  padding-left: 15px;
`;

const TextPerfectStyle = styled.p`
  background: transparent;
  font-size: 18px;
  color: ${MARIGOLD};
  text-align: center;
  margin: 8px !important;
`;
const MarginStyle = styled.img`
  margin: 5px;
  width: 20px;
  height: 20px;
`;
type Props = {
  wrongAnswered: number,
  examFailed: boolean,
  score: number,
  isRandomTest: boolean,
  complete_txt_earned: string,
  game_txt_you_got: string,
  game_txt_keep_up_good_work: string,
  game_txt_and_1_coin: string,
  isShowTutorial: boolean,
  isExam: boolean,
  isChatbot?: boolean,
  isSpeaking: boolean,
  isWriting: boolean,
  gameSkipCount: number
};
const ShowPerfect = ({
  wrongAnswered = 0,
  examFailed = false,
  isRandomTest = false,
  score,
  complete_txt_earned,
  game_txt_you_got,
  game_txt_keep_up_good_work,
  game_txt_and_1_coin,
  isShowTutorial,
  isExam,
  isChatbot,
  isSpeaking,
  isWriting,
  gameSkipCount
}: Props) => {
  return examFailed ? (
    <ScoreContainer>
      <EarnText>{complete_txt_earned}</EarnText>
      <TextScore1>
        {PLUS_SIGN_SYMBOL}
        {score}
      </TextScore1>
      <SubScore>
        <BpStyle src={banana} />
        <SubScore2>{BANANA_POINT_SYMBOL}</SubScore2>
      </SubScore>
    </ScoreContainer>
  ) : !isChatbot && isShowTutorial ? (
    <ScoreContainer>
      <EarnText>{complete_txt_earned}</EarnText>
      <RowStyle>
        <TextScore2>
          {PLUS_SIGN_SYMBOL}
          {score}
        </TextScore2>
        <BananStyle src={banana} />
      </RowStyle>
      {!isRandomTest && (
        <PerfectStyle>
          <PerfexStyle>
            <TextPerfectStyle>{MULTI_BY_1_TXT}</TextPerfectStyle>
          </PerfexStyle>
          <MarginStyle src={coin} />
        </PerfectStyle>
      )}
    </ScoreContainer>
  ) : !isChatbot ? (
    <ScoreContainer>
      {game_txt_you_got}{' '}
      <SubScore>
        {' '}
        {score} {BANANA_POINT_SYMBOL}{' '}
        {(isSpeaking || isWriting) &&
          gameSkipCount === 0 &&
          game_txt_and_1_coin}
      </SubScore>
      <br />
      <SecondSub>{game_txt_keep_up_good_work}</SecondSub>
    </ScoreContainer>
  ) : (
    <ScoreContainer>
      <SecondSub>{game_txt_keep_up_good_work}</SecondSub>
    </ScoreContainer>
  );
};

export { ShowPerfect };
