/* @flow */
import React, { Component } from 'react';
import { LAVENDER_COLOR } from '../../../common/constants';

type Props = {
  style: Object,
  textStyle: Object,
  data: Object,
  isTargetLangSymbol: boolean
};
class Cell extends Component<Props, any> {
  static defaultProps: any;
  render() {
    const { data, style, textStyle, isTargetLangSymbol } = this.props;

    const styles = {
      cell: {
        justifyContent: 'center',
        flex: 1
      },
      text: {
        backgroundColor: 'transparent',
        fontSize: isTargetLangSymbol ? 25 : 16
      }
    };
    const textDom = React.isValidElement(data) ? (
      data
    ) : (
      <div style={{ ...textStyle, ...styles.text }}>{data}</div>
    );
    let borderWidth = 2;
    let borderColor = LAVENDER_COLOR;

    return (
      <div
        style={{
          borderBottomWidth: borderWidth,
          borderColor: borderColor,
          ...styles.cell,
          ...style
        }}
      >
        {textDom}
      </div>
    );
  }
}

export { Cell };
