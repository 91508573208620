/* @flow */
import _ from 'lodash';
import moment from 'moment';
import {
  updateUserData,
  updateUserProfile
} from '../../common/actions/GlobalActions';
import { getToday } from '../util';
import {
  getCurrentWeekDayMondayBased,
  getCurrentWeekNumber,
  updateUserProgressBananas
} from '../../util';
import Analytic from '../../util/Analytic';
import {
  isUserSubscribed,
  getUserCoins,
  getUserBananas,
  getCurrentStreak,
  getLastDayPlayed,
  getLongestStreak,
  getCourseUser
  // getUserExamCrowns
} from '../../profile/selectors';
import { getUserExamCrowns } from '../../profile/actions/UserSelector';
import BugTracker from '../../util/BugTracker';
import { toggleRepairedUnitModal } from '../../shop/actions';
import { updateWeeklyGraph } from '.';

export const getLessonId = (state: Object) => {
  const { isExam, isSpeaking, isWriting } = state.routes;
  const key = state.game.key.split(',');
  if (isExam) {
    return 7;
  } else if (isSpeaking) {
    return 5;
  } else if (isWriting) {
    return 6;
  }
  return key[1];
};

const getResultCrowns = (
  state: Object,
  wrongAnswered: number,
  crowns: number
) => {
  const { isExam, isWriting } = state.routes;

  if (isExam) {
    return checkWrongAnswersExam(wrongAnswered);
  } else if (isWriting) {
    return checkWrongAnswersWriting(wrongAnswered);
  }
  return crowns;
};

export const updateCurrentChatbot = (currentChatbotId: string) => (
  dispatch: Function,
  getState: Function
) => {
  dispatch(updateUserProfile({ currentChatbotId }));
};

export const updateProgressChatbot = () => (
  dispatch: Function,
  getState: Function
) => {
  const state = getState();
  const { progressChatbot = {}, currentChatbotId } = getCourseUser(state);
  progressChatbot[currentChatbotId] = { isFinished: true };
  dispatch(updateUserProfile({ progressChatbot }));
};

export const updateProgress = (
  lessonId: number,
  unit: Object,
  progress: any
) => (dispatch: Function, getState: Function) => {
  const state = getState();
  const { routes, storage, data } = state;
  const {
    isExam,
    isSpeaking,
    isWriting,
    gameWrongAnswered,
    isRandomTest,
    gameSkipCount
  } = routes;
  const { isShowTutorial, configs, user } = storage;
  const { targetLangCode } = data;
  const { uid } = user;
  const { unitId } = state.lessons;
  const { key } = state.game;
  const { isEnabledSpacedRepetition } = configs;
  let lesson = unit.lessons[lessonId];
  let { bananas, crowns, coins, wrongAnswered } = getLessonProgress(
    lesson,
    state.routes.gameWrongAnswered
  );
  if (isShowTutorial) {
    bananas = 100;
    coins = 1;
  }
  if (isRandomTest) {
    bananas = 10 - gameWrongAnswered;
    coins = 0;
  }
  if (!isExam && !isSpeaking && !isWriting) {
    coins = 1;
  } else if ((isSpeaking || isWriting) && gameSkipCount === 0) {
    coins = 1;
  }

  if (gameSkipCount > 0) {
    coins = 0;
  }

  const resultCrowns = getResultCrowns(state, wrongAnswered, crowns);
  lesson = {
    finished: true,
    wrongAnswered,
    crowns: resultCrowns
  };

  const today = getToday();
  const { repairedCount, lastDayPlayed = today } = unit;
  unit.lessons[lessonId] = lesson;
  // update streak based on day
  const { currentStreak, longestStreak } = getUpdatedStreaks(state);
  // get longestStreak before current and longest state is mutated
  const longestUpdatedStreak = Math.max(currentStreak, longestStreak); // type error from get function returning undefined

  const isUnitCrack = isEnabledSpacedRepetition
    ? isCrackedUnit(lastDayPlayed, repairedCount)
    : false;
  if (isUnitCrack) {
    unit.repairedCount = repairedCount + 1;
    dispatch(toggleRepairedUnitModal());
  } else {
    unit.repairedCount = repairedCount;
  }
  unit.lastDayPlayed = today;
  _.set(progress, unitId, getUnitProgress(unit, isExam, wrongAnswered));
  Analytic.log(Analytic.key.L3, {
    content: key,
    coins
  });
  Analytic.logGA(Analytic.key.L3, {
    category: Analytic.key.L3,
    action: 'Check',
    label: state.game.key,
    value: coins
  });

  if (_.size(_.get(progress, `[${unitId}].lessons`, 0)) >= 7) {
    const exam = getUserExamCrowns(state, unitId);
    try {
      Analytic.log(Analytic.key.U3, {
        content: unitId,
        coins: exam
      });
      Analytic.logGA(Analytic.key.U3, {
        category: Analytic.key.U3,
        action: 'Get',
        label: unitId,
        value: exam
      });
    } catch (e) {
      BugTracker.notify(e);
    }
  }
  if (!isUserSubscribed(state) && unitId <= 2) {
    Analytic.log(Analytic.key.A1);
    Analytic.logGA(Analytic.key.A1, {
      category: Analytic.key.A1,
      action: 'Click'
    });
  }
  const bananasNumber = getUserBananas(state) || 0;
  updateUserProgressBananas(bananasNumber, targetLangCode, uid, bananas);
  dispatch(updateWeeklyGraph(bananas));
  dispatch(
    updateUserProfile({
      progress,
      coins: getUserCoins(state) + coins,
      bananas: bananasNumber + bananas,
      currentStreak,
      lastDayPlayed: today,
      longestStreak: longestUpdatedStreak
    })
  );
};

declare type StreakOutput = {
  currentStreak: number,
  longestStreak: number
}

// fetch and update the currentStreak and longestStreak
const getUpdatedStreaks = (state: Object): StreakOutput => {
  const currentStreak = getCurrentStreak(state);
  const longestStreak = getLongestStreak(state) || 0;
  const lastDayPlayed = getLastDayPlayed(state);

  if (currentStreak) {
    if (isToday(String(lastDayPlayed))) {
      // if today was lastDay return existing streaks
      return { currentStreak, longestStreak };
    } else if (isYesterday(String(lastDayPlayed))) {
      // if lastDay was yesterday update currentStreak and compare for longestStreak
      return { currentStreak: currentStreak + 1, longestStreak: Math.max(currentStreak + 1, longestStreak) };
    } else {
      // if lastDay gt one day, verify the longestStreak (corner case) and reset currentSteak
      return { currentStreak: 1, longestStreak: Math.max(currentStreak, longestStreak) };
    }
  }
  return { currentStreak: 1, longestStreak };
};

export const resetStreak = () => (dispatch: Function, getState: Function) => {
  const state = getState();
  const lastDayPlayed = getLastDayPlayed(state);
  const currentStreak =
    isToday(String(lastDayPlayed)) || isYesterday(String(lastDayPlayed))
      ? getCurrentStreak(state)
      : 0;

  dispatch(updateUserProfile({ currentStreak }));
};

const isToday = (date: string) => isSameDate(today(), date);

const isYesterday = (date: string) =>
  isSameDate(today().subtract(1, 'days'), date);

const today = () => moment(getToday());

const isSameDate = (date: Object, otherDate: string) => date.isSame(otherDate);

const getUnitProgress = (
  unit: Object,
  isExam: boolean,
  wrongAnswered: number
) => {
  unit.done = _.reduce(
    unit.lessons,
    (done, lesson) => {
      if (lesson) {
        return done + Number(lesson.finished);
      } else {
        return done;
      }
    },
    0
  );
  unit.lastDayPlayed = getToday();
  unit.crowns = unit.crowns || 0;

  if (isExam) {
    const examCrowns = checkWrongAnswersExam(wrongAnswered);
    unit.crowns = examCrowns;
  }
  return unit;
};

const getLessonProgress = (lesson, wrongs) => {
  const wrongAnswered =
    !lesson || lesson.wrongAnswered > wrongs ? wrongs : lesson.wrongAnswered;
  return { ...checkWrongAnswers(wrongAnswered), wrongAnswered };
};

export const prepareProgress = (progress: any, unitId: number) => {
  let unit = {
    done: 0,
    lessons: [],
    repairedCount: 0,
    lastDayPlayed: getToday()
  };
  if (!progress) {
    progress = [];
  }
  if (progress[unitId]) {
    unit = { ...unit, ...progress[unitId] };
    if (_.isEmpty(unit.done)) {
      unit.done = 0;
    }
  }
  if (unit.done === 5) {
    Analytic.log(Analytic.key.U3, {
      content: unitId
    });
    Analytic.logGA(Analytic.key.U3, {
      category: Analytic.key.U3,
      label: unitId
    });
  }
  return unit;
};

export const checkWrongAnswers = (wrongs: number) => {
  let bananas = 0,
    crowns = 0,
    coins = 0;
  if (wrongs >= 5) {
    bananas = 100;
    crowns = 1;
  } else if (wrongs >= 2) {
    bananas = 150;
    crowns = 2;
  } else if (wrongs < 2) {
    bananas = 200;
    coins = 1;
    crowns = 3;
  }
  return { bananas, crowns, coins };
};

export const checkWrongAnswersExam = (wrongs: number) => {
  if (wrongs >= 2) {
    return 1;
  } else if (wrongs >= 1) {
    return 2;
  } else if (wrongs < 1) {
    return 3;
  }
  return 0;
};

export const checkWrongAnswersWriting = (wrongs: number) => {
  if (wrongs > 1) {
    return 1;
  } else if (wrongs === 1) {
    return 2;
  } else {
    return 3;
  }
};

const AMOUNT_OF_DAY_PER_COUNT = 21;

export const isCrackedUnit = (lastDayPlayed: string, repairedCount: number) => {
  const now = moment(getToday());
  const amountOfDay = AMOUNT_OF_DAY_PER_COUNT * (repairedCount + 1);
  const crackDay = moment(lastDayPlayed)
    .add(amountOfDay, 'days')
    .startOf('day')
    .format('YYYY-MM-DD');
  return now.isAfter(crackDay);
};

export const getUpdatedWeeklyGoal = (goal: Object, dailyBananas: number) => {
  const clonedGoal = { ...goal };
  const arrayIndex = getCurrentWeekDayMondayBased();
  const { weekNumber } = clonedGoal;
  const currentWeekNumber = getCurrentWeekNumber();
  const isSameWeek = weekNumber === currentWeekNumber;
  const { weeklyGraphData } = clonedGoal;
  const isSunday = arrayIndex === 6;
  const isSundayOfNextWeek = isSunday && currentWeekNumber - weekNumber === 1;
  const shouldReset = !isSameWeek && !isSundayOfNextWeek;
  let clonedWeeklyGraphData;
  if (shouldReset) {
    clonedWeeklyGraphData = [0, 0, 0, 0, 0, 0, 0];
    clonedGoal['weekNumber'] = currentWeekNumber;
  } else {
    clonedWeeklyGraphData = [..._.toArray(weeklyGraphData)];
  }
  const existingBananas = clonedWeeklyGraphData[arrayIndex];
  const bananasSum = existingBananas + dailyBananas;
  clonedWeeklyGraphData[arrayIndex] = bananasSum;
  const newGoal = {
    ...clonedGoal,
    weeklyGraphData: clonedWeeklyGraphData
  };
  return newGoal;
};

export const resetWeeklyGoal = (goal: Object) => (
  dispatch: Function,
  getState: Function
) => {
  const clonedGoal = { ...goal };
  const arrayIndex = getCurrentWeekDayMondayBased();
  const { weekNumber } = clonedGoal;
  const currentWeekNumber = getCurrentWeekNumber();
  const isSameWeek = weekNumber === currentWeekNumber;
  const isSunday = arrayIndex === 6;
  const isSundayOfNextWeek = isSunday && currentWeekNumber - weekNumber === 1;
  const shouldReset = !isSameWeek && !isSundayOfNextWeek;
  if (shouldReset) {
    let clonedWeeklyGraphData = [0, 0, 0, 0, 0, 0, 0];
    clonedGoal['weekNumber'] = currentWeekNumber;
    const newGoal = {
      ...clonedGoal,
      weeklyGraphData: clonedWeeklyGraphData
    };
    dispatch(updateUserData({ goal: newGoal }));
  }
};
