/* @flow */
export const AMBER200: string = '#ffca45';
export const BLACK: string = '#000000';
export const BLACK_HOVER: string = 'rgba(0, 0, 0, .4)';
export const BLACK_OPACITY_COLOR: string = 'rgba(0, 0, 0, 0.3)';
export const BLACK_OPACITY_SHADOW: string = 'rgba(0, 0, 0, 0.05)';
export const BLACK_OPACITY_SHADOW_COLOR: string = 'rgba(0, 0, 0, 0.15)';
export const BLACK_TWO_COLOR: string = '#333333';
export const BLOOD_RED: string = '#bc5552';
export const BLUE: string = '#61a8db';
export const BLUEISH_GREY: string = '#e0e7ee';
export const BROWN_GREY: string = '#878787';
export const BROWN_GREY_TWO: string = '#adadad';
export const BTN_DARK_RED_COLOR: string = '#dc6760';
export const CAMERA_GREY: string = '#d0d0d0';
export const CLEAR_BLUE: string = 'rgba(97, 168, 219, 0.65)';
export const DARK_BLUE: string = '#4a90e2';
export const DARK_BLUE_2: string = '#0d5eb7';
export const DARK_BLUE_BG: string = '#1c262f';
export const DARK_GREEN_COLOR: string = '#69b11b';
export const DARK_GREY: string = '#454545';
export const DARK_GREY_1: string = '#4a4a4a';
export const DARK_GREY_2: string = 'rgb(51, 51, 51)';
export const DARK_GREY_3: string = '#bdbdbd';
export const DARK_ORANGE: string = '#f5a623';
export const DARK_RED_COLOR: string = '#bc5454';
export const DARK_SEA_BLUE: string = '#002c4d';
export const DARK_YELLOW: string = '#f7b500';
export const EGG_YELLOW: string = '#ffdc50';
export const GRAYISH_BLUE: string = '#C8C7CC';
export const GRAY_BLUE: string = '#e9ecef';
export const GREEN200: string = '#18d975';
export const GREEN_COLOR2: string = '#eeffdc';
export const GREEN_COLOR: string = '#7ed321';
export const GREY2: string = '#ccccc0';
export const GREY: string = '#cccccc';
export const GREYISH_BROWN: string = '#454545';
export const GREY_ORANGE: string = '#e6b800';
export const LAVENDER_COLOR: string = '#efeff4';
export const LIGHT_BEIGE: string = '#fff0b4';
export const LIGHT_BLUE: string =
  'linear-gradient(to bottom, #ebf8ff, #e6f5ff)';
export const LIGHT_BLUE_2: string = '#dcf0ff';
export const LIGHT_BLUE_4: string = '#3988e6';
export const LIGHT_BLUE_5: string = '#3ca5ef';
export const LIGHT_BLUE_GRADIENT: string =
  'linear-gradient(to bottom, #ffffff, #f3faff)';
export const LIGHT_BLUE_SHADOW: string = '#f3faff';
export const LIGHT_GREEN: string = '#18d975';
export const LIGHT_GREEN_2: string = '#BFF08B';
export const LIGHT_GREEN_COLOR: string = '#eeffdc';
export const LIGHT_GREY: string = '#f1f1f1';
export const LIGHT_GREY_2: string = '#dcdcdc';
export const LIGHT_GREY_3: string = '#d4d4d8';
export const LIGHT_GREY_CARD: string = '#f9f9f9';
export const LIGHT_GREY_HOVER: string = '#6a6868';
export const LIGHT_GREY_SHADOW: string = '#dedede';
export const LIGHT_GREY_TRANSCRIPT: string = '#c2bdbd';
export const LIGHT_GREY_TRANSCRIPT_2: string = '#333333';
export const LIGHT_RED_1: string = '#e7562e';
export const LIGHT_RED_2: string = '#f9b7b7';
export const LIGHT_RED_COLOR: string = '#ffd3d3';
export const LIGHT_WHITE: string = '#b0deff';
export const LIGHT_YELLOW: string = '#ffe800';
export const LIGHT_YELLOW_2: string = '#ffdd57';
export const MARIGOLD: string = '#ffcc00';
export const NOTIFICATION_COLOR: string = '#ff0654';
export const ORANGE: string = 'rgba(255, 204, 0, 1)';
export const PURE_ORANGE: string = '#F5AF00';
export const RED200: string = '#ff3437';
export const RED_COLOR2: string = '#ffd3d3';
export const RED_COLOR: string = '#f47070';
export const SAND_YELLOW: string = '#ffe062';
export const SEA_BLUE: string = '#4cb4ff';
export const SETTING_GREY_BACKGROUND: string = '#f6f7f9';
export const SILVER_COLOR: string = '#c8c7cc';
export const STRIPE_GREY: string = '#afafaf';
export const TAB_TITLE_COLOR: string = '#8a8a8f';
export const VERY_LIGHT_GREY: string = '#9b9b9b';
export const VERY_LIGHT_PINK: string = '#f1f1f1';
export const VERY_LIGHT_PINK_THREE: string = '#d8d8d8';
export const VERY_LIGHT_PINK_TWO: string = '#e7e7e7';
export const WHITE: string = '#ffffff';
export const WHITE_TWO: string = '#f8f8f8';
export const PALE_ORANGE: string = '#9a7e31';
export const DARK_BLUE_3: string = '#1f2730';
export const DARK_BLUE_4: string = '#106cd5';
export const BLACK_SHADE: string = 'rgb(51, 51, 51)';
export const LIGHT_ORANGE: string = 'rgb(255, 224, 98)';
export const DARKED_ORANGE: string = 'rgb(254, 204, 5)';
export const ORANGEY_YELLOW: string = 'rgb(245, 166, 35)';
export const LIGHT_BLACK: string = 'rgb(32, 32, 32)';
