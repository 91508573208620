import CampaignList from './data/CampaignList';
import { getLangNameFromCode } from './utils/getLangNameFromCode';

// check if need to send old way or new way of tracking, if true, its new way of tracking events
const isScreenViewEvent = (campaign, activeScreen) =>
  campaign.userProps[activeScreen].screenName !== '';

//check if tracking has specific user prop name needed for analytics
const hasCustomUserProp = (campaign, activeScreen) =>
  campaign.userProps[activeScreen].userProps;

const userChoice = (campaign, activeScreen, campaignId, selected) => {
  return campaign.screenText[activeScreen] &&
    campaign.screenText[activeScreen][0].analyticValue &&
    !Array.isArray(selected)
    ? CampaignList('lang_code')[campaignId].screenText[activeScreen][
        selected - 1
      ].analyticValue
    : selected;
};

export const campaignData = (
  keyName,
  activeScreen,
  campaign,
  campaignId,
  selected
) => {
  // converts selected number to string from CampaignScreenText data and returns object{eventProperty: userChoice}
  return {
    [campaign.userProps[activeScreen][keyName]]: userChoice(
      campaign,
      activeScreen,
      campaignId,
      selected.languageSkill ?? selected
    )
  };
};

const getCampaignEventData = (campaign, activeScreen, selected, campaignId) => {
  // old way of tracking
  if (!isScreenViewEvent(campaign, activeScreen)) {
    return {
      user_choice: userChoice(campaign, activeScreen, campaignId, selected)
    };
  }

  const screenName = campaign.userProps[activeScreen].screenName;

  // check if selected is from UserLanguageLevel component where its an object
  if (screenName === 'language_skills' && selected.languageSkill) {
    return { screen_name: 'language_aspects' };
  }

  return { screen_name: screenName };
};

export const getEventData = ({
  campaignId,
  activeScreen,
  nativeLanguage,
  selected,
  campaign
}) => {
  const languagePickerEventData = {
    is_during_trial: false, // New onboarding users will never have trial,
    native_language_iso: nativeLanguage,
    native_language: getLangNameFromCode(nativeLanguage),
    target_language_iso: selected,
    target_language: getLangNameFromCode(selected),
    source: 'onboarding'
  };

  const campaignEventData = getCampaignEventData(
    campaign,
    activeScreen,
    selected,
    campaignId
  );

  const baseEventData = isScreenViewEvent(campaign, activeScreen)
    ? {}
    : { campaign_id: campaignId };

  const isLanguagePicker = activeScreen === 1;

  if (isLanguagePicker) {
    return { ...baseEventData, ...languagePickerEventData };
  } else {
    return { ...baseEventData, ...campaignEventData };
  }
};

export function getUserProps({
  campaignId,
  activeScreen,
  nativeLanguage,
  selected,
  campaign
}) {
  const languagePickerUserData = {
    is_during_trial: false, // New onboarding users will never have trial,
    native_language_iso: nativeLanguage,
    native_language: getLangNameFromCode(nativeLanguage),
    target_language_iso: selected,
    target_language: getLangNameFromCode(selected),
    source: 'onboarding'
  };

  const campaignUserData = isScreenViewEvent(campaign, activeScreen)
    ? campaignData('screenName', activeScreen, campaign, campaignId, selected)
    : campaignData(
        hasCustomUserProp(campaign, activeScreen) ? 'userProps' : 'eventName',
        activeScreen,
        campaign,
        campaignId,
        selected
      );

  const isLanguagePicker = activeScreen === 1;

  if (isLanguagePicker) {
    return languagePickerUserData;
  } else if (selected.languageSkill) {
    // If selected data is coming from UserLanguageLevel survey, send 7 tracking objects
    return [
      campaignUserData,
      { reading_skill: selected.readingSkill },
      { listening_skill: selected.listeningSkill },
      { writing_skill: selected.writingSkill },
      { grammar_skill: selected.grammarSkill },
      { speaking_skill: selected.speakingSkill },
      { vocabulary_skill: selected.vocabularySkill }
    ];
  } else {
    return campaignUserData;
  }
}
