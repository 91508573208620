import { fetchRemoteDatabase } from '../../lessons/sagas';
import bugtracker from '../../util/BugTracker';
import {
  SET_NO_ACCESS_UNITS,
  SET_NO_ACCESS_CHATBOT_UNITS
} from '../constants/ActionTypes';

export const getUserInOrganizationRestrictionList = async (userID: string) => {
  try {
    const path = `organizations/users/${userID}`;
    const snapshot = await fetchRemoteDatabase(path);
    return snapshot.val();
  } catch (error) {
    console.log(error);
    bugtracker.notify(error);
  }
};

export const getNoAccessContentOfOrganization = async (
  userID: string,
  option: string
) => {
  try {
    const organizationID = await getUserInOrganizationRestrictionList(userID);
    const path = `organizations/details/${organizationID}/${option}`;
    const snapshot = await fetchRemoteDatabase(path);
    return snapshot.val();
  } catch (error) {
    console.log(error);
    bugtracker.notify(error);
  }
};

export const setNoAccessUnits = (userID: string) => async (
  dispatch: Function
) => {
  try {
    const noAccessUnits = await getNoAccessContentOfOrganization(
      userID,
      'noAccessUnits'
    );
    dispatch({
      type: SET_NO_ACCESS_UNITS,
      payload: noAccessUnits
    });
  } catch (error) {
    console.log(error);
    bugtracker.notify(error);
  }
};

export const setNoAccessChatbotUnits = (userID: string) => async (
  dispatch: Function
) => {
  try {
    const noAccessChatbotUnits = await getNoAccessContentOfOrganization(
      userID,
      'noAccessChatbotsUnits'
    );
    dispatch({
      type: SET_NO_ACCESS_CHATBOT_UNITS,
      payload: noAccessChatbotUnits
    });
  } catch (error) {
    console.log(error);
    bugtracker.notify(error);
  }
};

export const setRestrictedContent = (userID: string) => (
  dispatch: Function
) => {
  dispatch(setNoAccessUnits(userID));
  dispatch(setNoAccessChatbotUnits(userID));
};
