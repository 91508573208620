/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import {
  DARK_GREY_1,
  VERY_LIGHT_PINK_TWO,
  DARK_BLUE,
  VERY_LIGHT_GREY
} from '../../common/constants';

const LeftSectionContainer = styled.div`
  width: 431px;
`;
const BoxContainer = styled.div`
  border: solid 1px ${VERY_LIGHT_PINK_TWO};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding-left: 67px;
  padding-right: 67px;
`;
const DescriptionStyle = styled.p`
  text-align: center;
  color: ${VERY_LIGHT_GREY};
  margin-top: 24px !important;
`;
const TitleStyle = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${DARK_GREY_1};
`;
const ButtonStyle = styled.button`
  color: ${DARK_BLUE};
  background: white;
  border: none;
  font-weight: bold;
  width: auto;
  margin: auto;
  margin-bottom: 20px;
  text-transform: uppercase;
  &:hover {
    opacity: 0.6;
  }
`;
type Props = {
  descriptionText: any,
  buttonText: string,
  titleText: string,
  profile_txt_visa_expire?: string,
  onSubscription?: Function,
  isLifetime?: any
};
export const PaymentInfoBox = ({
  titleText,
  descriptionText,
  buttonText,
  onSubscription,
  profile_txt_visa_expire,
  isLifetime
}: Props) => {
  return (
    <LeftSectionContainer>
      <TitleStyle>{titleText}</TitleStyle>
      <BoxContainer>
        <DescriptionStyle>{descriptionText}</DescriptionStyle>
        {buttonText !== '' && !isLifetime ? (
          <ButtonStyle onClick={onSubscription}>{buttonText}</ButtonStyle>
        ) : null}
      </BoxContainer>
    </LeftSectionContainer>
  );
};
