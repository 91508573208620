/* @flow */
import React from 'react';
import styled from 'styled-components';

import {
  PREFIX_CROWN,
  MARIGOLD,
  WHITE,
  LIGHT_GREY_TRANSCRIPT_2
} from '../../../common/constants';

const ExamContainer = styled.div`
  width: 220px;
  height: 294px;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #f1f1f1;
  background-color: white;
  display: flex;
  flex-direction: column;
  &:hover {
    cursor: pointer;
  }
`;
const ReviewButton = styled.button`
  width: 150px;
  height: 55px;
  border-radius: 10px;
  box-shadow: inset 0 -4px 0 0 #f7b500;
  background: ${MARIGOLD};
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  font-size: 16px;
  color: ${WHITE};
  margin-bottom: 20px;
  ${'' /* margin-bottom: 22px; */}
  &.review {
    color: ${LIGHT_GREY_TRANSCRIPT_2};
  }
  &:hover {
    cursor: pointer;
    opacity: 0.4;
  }
`;
const UnitContent = styled.div`
  width: 220px;
  height: 190px;
  background-image: url(${props => props.url});
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  padding-top: 12px;
`;

const CrownContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  padding: 0 30px;
`;

const CrownImage = styled.img`
  width: 39px;
  height: 39px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  flex: 1;
  text-align: center;
  margin-bottom: 10px auto !important;
`;

const yesCrown = () => (
  <CrownImage src={`${PREFIX_CROWN}filled-crown-icon.svg`} />
);
const noCrown = () => <CrownImage src={`${PREFIX_CROWN}crown-icon.svg`} />;

type Props = {
  startExam: Function,
  unitId: number,
  history: any,
  crowns: number,
  image: string,
  title: string,
  lesson_txt_play: string
};
class ExamCard extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handlePress = this.handlePress.bind(this);
  }
  handlePress = () => {
    const { startExam, unitId, history } = this.props;
    startExam(unitId);
    history.push(`/unit/${unitId}/exam`);
  };
  render() {
    const { crowns, image, title, lesson_txt_play } = this.props;
    return (
      <ExamContainer onClick={this.handlePress}>
        <UnitContent url={image}>
          <CrownContainer>
            {crowns >= 1 ? yesCrown() : noCrown()}
            {crowns >= 2 ? yesCrown() : noCrown()}
            {crowns >= 3 ? yesCrown() : noCrown()}
          </CrownContainer>
        </UnitContent>
        <Title>{title}</Title>
        <ButtonContainer>
          <ReviewButton>{lesson_txt_play}</ReviewButton>
        </ButtonContainer>
      </ExamContainer>
    );
  }
}

export { ExamCard };
