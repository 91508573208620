/* @flow */
// $FlowFixMe for useState, useEffect, useCallback
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { PRODUCT_TYPE } from '../../constants';
import { SaleCard } from './SaleCard';
import { getLocalStorage, getStripeProductId } from '../../../util';
import { DISCOUNT } from '../../../common/constants';
import { getProductName } from '../../../util/ProductHelper';
import _ from 'lodash';
import { Box } from '@mui/material';

type Props = {
  onBuy: Function,
  usdLifeTimePrice: number,
  purchase_txt_option_unit: string,
  shop_txt_month: string,
  shop_btn_txt_buyNow: string,
  usdMonthPrice: number,
  currencySymbol: Object,
  isRecommend: boolean,
  usdYearPrice: number,
  purchase_txt_option_lifeTime: string,
  isLifeTime: boolean,
  shop_txt_year: string,
  shop_txt_7_day_trial: string,
  functions: string,
  currency: string,
  user: Object
};

type SaleData = {
  stripeId: string,
  priceUnitAmount: number,
  currency: string,
  productName: string
};

const defaultSaleData: SaleData = {
  stripeId: '',
  priceUnitAmount: 0,
  currency: '',
  productName: ''
};

const SaleSection = ({
  functions,
  onBuy,
  usdLifeTimePrice,
  purchase_txt_option_unit,
  shop_txt_month,
  shop_btn_txt_buyNow,
  usdMonthPrice,
  currencySymbol,
  isRecommend,
  usdYearPrice,
  purchase_txt_option_lifeTime,
  isLifeTime,
  shop_txt_year,
  shop_txt_7_day_trial,
  currency,
  user
}: Props) => {
  const [monthlySaleData, setMonthlySaleData] = useState<SaleData>(
    defaultSaleData
  );
  const [yearlySaleData, setYearlySaleData] = useState<SaleData>(
    defaultSaleData
  );
  const [lifetimeSaleData, setLifetimeSaleData] = useState<SaleData>(
    defaultSaleData
  );

  const discount = getLocalStorage(DISCOUNT);
  const monthlyPriceId =
    getStripeProductId(
      currency,
      getProductName(discount, PRODUCT_TYPE.monthly),
      user
    ) || '';
  const yearlyPriceId =
    getStripeProductId(
      currency,
      getProductName(discount, PRODUCT_TYPE.yearly),
      user
    ) || '';
  const lifetimePriceId =
    getStripeProductId(
      currency,
      getProductName(discount, PRODUCT_TYPE.lifetime),
      user
    ) || '';

  const constructSaleData = useCallback((dataKey: string, data: Object) => {
    const stripeId = _.get(data, 'id', '');
    const priceUnitAmount = _.get(data, 'unit_amount', 0);
    const currency = _.get(data, 'currency', '');
    const productName = getProductName(discount, dataKey);
    const trialPeriodDays = _.get(data, 'recurring.trial_period_days', 0) ?? 0;
    const saleData = {
      stripeId,
      priceUnitAmount,
      currency,
      productName,
      trialPeriodDays
    };
    return saleData;
  }, []);

  const fetchStripeProductDetail = useCallback(priceId => {
    return axios
      .get(`${functions}/validateLingWeb-stripeApi/products/price/${priceId}`)
      .then(res => res.data)
      .catch(error => {
        console.error(error);
      });
  }, []);

  // monthly
  useEffect(() => {
    if (monthlySaleData.priceUnitAmount === 0) {
      fetchStripeProductDetail(monthlyPriceId).then(data => {
        setMonthlySaleData(constructSaleData(PRODUCT_TYPE.monthly, data));
      });
    }
  }); // TODO: Move to Redux

  // yearly
  useEffect(() => {
    if (yearlySaleData.priceUnitAmount === 0) {
      fetchStripeProductDetail(yearlyPriceId).then(data => {
        setYearlySaleData(constructSaleData(PRODUCT_TYPE.yearly, data));
      });
    }
  }); // TODO: Move to Redux

  // lifetime
  useEffect(() => {
    if (lifetimeSaleData.priceUnitAmount === 0) {
      fetchStripeProductDetail(lifetimePriceId).then(data => {
        setLifetimeSaleData(constructSaleData(PRODUCT_TYPE.lifetime, data));
      });
    }
  }); // TODO: Move to Redux

  const styles = {
    container: {
      maxWidth: 740,
      width: '100%',
      display: 'flex',
      flexDirection: { md: 'row', xs: 'column' },
      alignContent: 'center',
      justifyContent: { md: 'space-between', xs: 'center' },
      alignItems: { md: 'flex-start', xs: 'center' },
      pb: {
        xs: 5
      }
    },
    outer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  };

  return (
    <Box sx={styles.outer}>
      <Box sx={styles.container}>
        <SaleCard
          onBuy={() => {
            onBuy(
              getProductName(discount, PRODUCT_TYPE.monthly),
              monthlySaleData.priceUnitAmount,
              usdMonthPrice,
              monthlySaleData,
              'sales_section'
            );
          }}
          purchase_txt_option_unit={purchase_txt_option_unit}
          shop_txt_month={shop_txt_month}
          shop_btn_txt_buyNow={shop_btn_txt_buyNow}
          amount={monthlySaleData.priceUnitAmount / 100}
          currencySymbol={currencySymbol.symbol}
          currencyCode={currencySymbol.code}
          isDisabled={monthlySaleData.priceUnitAmount === 0}
        />
        <SaleCard
          isRecommend={isRecommend}
          onBuy={() => {
            onBuy(
              getProductName(discount, PRODUCT_TYPE.yearly),
              yearlySaleData.priceUnitAmount,
              usdYearPrice,
              yearlySaleData
            );
          }}
          purchase_txt_option_unit={purchase_txt_option_unit}
          amount={yearlySaleData.priceUnitAmount / 100}
          currencySymbol={currencySymbol.symbol}
          currencyCode={currencySymbol.code}
          shop_txt_year={shop_txt_year}
          shop_btn_txt_buyNow={shop_btn_txt_buyNow}
          shop_txt_7_day_trial={shop_txt_7_day_trial}
          trialUsedIP={user.trialUsedIP || ''}
          isDisabled={yearlySaleData.priceUnitAmount === 0}
        />
        <SaleCard
          isLifeTime={isLifeTime}
          onBuy={() => {
            onBuy(
              getProductName(discount, PRODUCT_TYPE.lifetime),
              lifetimeSaleData.priceUnitAmount,
              usdLifeTimePrice,
              lifetimeSaleData
            );
          }}
          amount={lifetimeSaleData.priceUnitAmount / 100}
          currencySymbol={currencySymbol.symbol}
          currencyCode={currencySymbol.code}
          purchase_txt_option_lifeTime={purchase_txt_option_lifeTime}
          shop_btn_txt_buyNow={shop_btn_txt_buyNow}
          isDisabled={lifetimeSaleData.priceUnitAmount === 0}
        />
      </Box>
    </Box>
  );
};

export { SaleSection };
