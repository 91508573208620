import { Box, FormControlLabel, Radio, Typography } from '@mui/material';
import React from 'react';
import { getPlanLabels } from '../data/paywallData';
import PropTypes from 'prop-types';
import { calculatePricePerWeek } from '../paywallHelpers';

const PaywallPlanSelection = ({
  selectedValue,
  commonStyle,
  theme,
  priceData,
  labels
}) => {
  const {
    txt_free_trial_label,
    txt_price_per_week,
    txt_save_x,
    purchase_txt_option_popular
  } = labels;
  const styles = {
    plan: {
      borderRadius: 4,
      border: '1px solid #E6E6E6',
      width: '100%',
      display: 'flex',
      mb: 6,
      position: 'relative',
      zIndex: 2,
      flexWrap: 'wrap',
      backgroundColor: 'white',
      [theme.breakpoints.up('sm')]: {
        flexWrap: 'nowrap'
      }
    },
    planDescription: {
      alignItems: 'flex-start',
      ml: 3,
      mt: 1
    },
    currentPriceText: {
      fontSize: '24px',
      fontWeight: 600
    },
    oldPriceText: {
      textDecorationLine: 'line-through'
    },
    selectedPlan: {
      border: `1px solid ${theme.palette.newPrimary.main}`
    },
    activeTrial: {
      backgroundColor: theme.palette.newPrimary.main,
      py: 2,
      textAlign: 'center',
      borderRadius: 4,
      height: '50px',
      zIndex: -1,
      position: 'absolute',
      width: '100.4%',
      top: 25
    },
    deactivatedTrial: {
      backgroundColor: '#CCCCCC'
    },
    bannerContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      flexGrow: 1,
      mx: 6,
      mb: 4,
      mt: 1,
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'flex-end',
        m: 4
      }
    },
    banner: {
      py: 1,
      px: 3,
      borderRadius: 2,
      [theme.breakpoints.up('sm')]: {
        position: 'absolute'
      }
    },
    bestOfferBanner: {
      backgroundColor: theme.palette.buttons.green.active
    },
    popularBanner: {
      backgroundColor: '#4094ED'
    },
    whiteText: {
      color: '#FFFFFF',
      fontWeight: 700
    },
    radioButtonContainer: {
      width: '100%',
      m: 5,
      flexWrap: 'wrap'
    },
    radioButton: {
      width: '100%',
      justifyContent: 'flex-start',
      [theme.breakpoints.up('sm')]: {
        width: '10%'
      }
    }
  };

  const planLabels = getPlanLabels(labels);
  const plans = Object.keys(planLabels);

  return (
    <>
      {plans.map(planType => {
        return (
          <Box key={planType}>
            {planType === 'yearly' && (
              <Box
                sx={[
                  styles.activeTrial,
                  selectedValue !== 'yearly' ? styles.deactivatedTrial : {}
                ]}
              >
                <Typography variant="subtext" sx={styles.whiteText}>
                  {txt_free_trial_label}
                </Typography>
              </Box>
            )}
            <Box
              sx={[
                styles.plan,
                planType === selectedValue ? styles.selectedPlan : {}
              ]}
            >
              <FormControlLabel
                sx={styles.radioButtonContainer}
                value={planType}
                control={<Radio color="newPrimary" sx={styles.radioButton} />}
                label={
                  <Box sx={[styles.planDescription, commonStyle]}>
                    <Box sx={commonStyle}>
                      <Box>
                        <Typography variant="text" sx={styles.currentPriceText}>
                          {`${
                            priceData[planType].symbol
                          }${calculatePricePerWeek(
                            priceData[planType].price,
                            planType,
                            priceData[planType].decimalSeparator
                          )} `}
                        </Typography>
                        <Typography variant="deactivatedText">
                          {txt_price_per_week.replace('%s', '')
                          // Some label languages still have the excessive "%s", so we remove it here for now too.
                          }
                        </Typography>
                      </Box>
                    </Box>
                    <Typography variant="subtext">
                      {planLabels[planType].replace(
                        '%s',
                        `${priceData[planType].symbol}${priceData[planType].price}`
                      )}
                    </Typography>
                  </Box>
                }
              />
              <Box sx={styles.bannerContainer}>
                {planType === 'yearly' && (
                  <Typography
                    variant="subtext"
                    sx={[
                      styles.bestOfferBanner,
                      styles.banner,
                      styles.whiteText
                    ]}
                  >
                    {txt_save_x.replace('%d', '50')}
                  </Typography>
                )}
                {planType === 'quarterly' && (
                  <Typography
                    variant="subtext"
                    sx={[styles.popularBanner, styles.banner, styles.whiteText]}
                  >
                    {purchase_txt_option_popular}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default PaywallPlanSelection;

PaywallPlanSelection.propTypes = {
  selectedValue: PropTypes.string,
  commonStyle: PropTypes.object,
  theme: PropTypes.object,
  riceData: PropTypes.shape({
    yearly: PropTypes.shape({
      price: PropTypes.string,
      currency: PropTypes.string
    }),
    monthly: PropTypes.shape({
      price: PropTypes.string,
      currency: PropTypes.string
    }),
    quarterly: PropTypes.shape({
      price: PropTypes.string,
      currency: PropTypes.string
    })
  })
};
