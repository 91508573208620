/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import Analytic from '../../../util/Analytic';
import _ from 'lodash';
import { LessonCard } from '../sub-components/LessonCard';
import { FIRST_TIME } from '../../../common/constants';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  margin-bottom: 53px;
`;

const onPressCard = (
  startGame: Function,
  lessonId: number,
  unitId: number,
  history: Object,
  finished: boolean
) => () => {
  startGame(lessonId, unitId);
  // eslint-disable-next-line
  localStorage.setItem(FIRST_TIME, 'false');
  history.push(`/unit/${unitId}/lesson/${lessonId}`);

  if (finished) {
    Analytic.log(Analytic.key.L5, {
      category: Analytic.key.L5,
      action: 'Log',
      label: `${unitId},${lessonId}`
    });
    Analytic.logGA(Analytic.key.L5, {
      category: Analytic.key.L5,
      action: 'Log',
      label: `${unitId},${lessonId}`
    });
  }
};

type Props = {
  lessons: any[],
  startGame: Function,
  unitId: number,
  progress: Object,
  history: Object,
  isUnitLocked: Function
};
const LessonCardsWrapper = ({
  lessons = [],
  startGame,
  unitId,
  progress,
  history,
  isUnitLocked
}: Props) => {
  progress = progress !== undefined ? progress : {};
  const format = (key, lesson) => {
    const newLesson = progress && lesson ? progress[lesson.lessonId] : {};
    const crowns = newLesson ? newLesson.crowns : 0;
    console.log('lesson', lesson);
    const finished = newLesson ? newLesson.finished : false;
    // eslint-disable-next-line
    const isFirstTime = localStorage.getItem(FIRST_TIME);
    return (
      !_.isEmpty(lesson) && (
        <LessonCard
          isFirstTime={isFirstTime}
          key={key}
          index={key}
          {...lesson}
          crowns={crowns}
          finished={finished}
          isUnitLocked={isUnitLocked}
          onPress={onPressCard(
            startGame,
            lesson.lessonId,
            unitId,
            history,
            finished
          )}
        />
      )
    );
  };

  const chunk = () =>
    lessons.reduce((rows, lesson, i) => rows.concat(format(i, lesson)), []);

  const card = <Wrapper>{chunk()}</Wrapper>;
  console.log('lessons', lessons);
  return card;
};

export { LessonCardsWrapper };
