/* @flow */
import { connect } from 'react-redux';
import { getNameAndEmail, getUser } from '../selectors';
import { signOut } from '../../common/actions/AuthActions';
import { isUserSubscribed } from '../../profile/actions/UserSelector';
import { updateUserData, setTrialUsedIP } from './../../common/actions';
import { getLabel, getLabels } from '../../util';
import ManageSubscriptionScene from '../scenes/ManageSubscriptionScene/ManageSubscriptionScene';

export const mapStateToProps = (state: Object) => {
  const { storage } = state;
  const { user } = storage;
  const yearly = process.env.REACT_APP_STRIPE_YEARLY_USD;
  const yearly_eur = process.env.REACT_APP_STRIPE_YEARLY_EUR;
  const yearly_pound = process.env.REACT_APP_STRIPE_YEARLY_GBP;
  const yearly_thb = process.env.REACT_APP_STRIPE_YEARLY_THB;
  const yearly_asd = process.env.REACT_APP_STRIPE_YEARLY_ASD;
  const yearly_rub = process.env.REACT_APP_STRIPE_YEARLY_RUB;
  const yearly_without_trial =
    process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_USD;
  const yearly_eur_without_trial =
    process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_EUR;
  const yearly_pound_without_trial =
    process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_POUND;
  const yearly_thb_without_trial =
    process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_THB;
  const yearly_asd_without_trial =
    process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_ASD;
  const yearly_rub_without_trial =
    process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_RUB;
  const isDev = process.env.REACT_APP_DEV;
  const functions = process.env.REACT_APP_FUNCTIONS;

  return {
    userInfo: getNameAndEmail(storage),
    ...getUser(state),
    isProUser: isUserSubscribed(state),
    functions,
    user,
    yearly,
    yearly_eur,
    yearly_pound,
    yearly_thb,
    yearly_rub,
    yearly_asd,
    yearly_without_trial,
    yearly_eur_without_trial,
    yearly_pound_without_trial,
    yearly_thb_without_trial,
    yearly_asd_without_trial,
    yearly_rub_without_trial,
    isDev,
    footer_txt_profile: getLabel(
      state,
      'footer_txt_profile',
      'footer_txt_profile'
    ),
    cancel_subscription_txt_profile: getLabel(
      state,
      'cancel_subscription_txt_profile',
      'Cancel Subscription'
    ),
    update_subscription_methob_txt_profile: getLabel(
      state,
      'profile_txt_update_subscription_method',
      'Update Payment Method'
    ),
    profile_txt_reactivate: getLabel(
      state,
      'profile_txt_reactivate',
      'Reactivate Subscription'
    ),
    purchase_button_subscribe_now: getLabel(
      state,
      'purchase_button_subscribe_now',
      'Subscribe Now!'
    ),
    current_plan_txt_profile: getLabel(
      state,
      'current_plan_txt_profile',
      'Current Plan'
    ),
    profile_txt_subscription_expire: getLabel(
      state,
      'profile_txt_subscription_expire',
      'You subscription will expire'
    ),
    trial_date_description_txt: getLabel(
      state,
      'trial_date_description_txt',
      'After your trial ends, you will be charged {d} per year starting {date} . You can always cancel before then.'
    ),
    shop_txt_checkout_title_top: getLabel(
      state,
      'shop_txt_checkout_title_top',
      'Ling Premium 60 Languages'
    ),
    shop_txt_checkout_title_yearly: getLabel(
      state,
      'shop_txt_checkout_title_yearly',
      'Yearly Subscription'
    ),
    profile_txt_1_year_ling_pro: getLabel(
      state,
      'profile_txt_1_year_ling_pro',
      '1 year of Ling Pro'
    ),
    profile_txt_1_month_ling_pro: getLabel(
      state,
      'profile_txt_1_month_ling_pro',
      '1 month of Ling Pro'
    ),
    shop_txt_checkout_title_monthly: getLabel(
      state,
      'shop_txt_checkout_title_monthly',
      'Monthly Subscription'
    ),
    shop_txt_checkout_title_lifetime: getLabel(
      state,
      'shop_txt_checkout_title_lifetime',
      'Lifetime access'
    ),
    next_payment_txt_profile: getLabel(
      state,
      'next_payment_txt_profile',
      'Next Payment'
    ),
    random_test_modal_confirm_txt: getLabel(
      state,
      'random_test_modal_confirm_txt',
      'ok'
    ),
    profile_txt_plan: getLabel(state, 'profile_txt_plan', 'You are using'),
    profile_txt_lifetime_plan: getLabel(
      state,
      'profile_txt_lifetime_plan',
      'Ling Lifetime Package'
    ),
    profile_txt_past_due_msg: getLabel(
      state,
      'profile_txt_past_due_msg',
      'Your payment is overdue. Please resolve your outstanding balance or update the payment method.'
    ),
    profile_txt_yearly_plan: getLabel(
      state,
      'profile_txt_yearly_plan',
      'Ling Yearly Plan'
    ),
    profile_txt_monthly_plan: getLabel(
      state,
      'profile_txt_monthly_plan',
      'Ling Monthly Plan'
    ),
    payment_methob_txt_profile: getLabel(state, 'payment_methob_txt_profile'),
    profile_txt_cancel_subscription: getLabel(
      state,
      'profile_txt_cancel_subscription',
      'Cancel Subscription'
    ),

    profile_txt_current_plan: getLabel(
      state,
      'profile_txt_current_plan',
      'Current Plan'
    ),
    profile_txt_visa_expire: getLabel(
      state,
      'profile_txt_visa_expire',
      'Visa ending in'
    ),
    profile_txt_next_payment: getLabel(
      state,
      'profile_txt_next_payment',
      'Next Payment'
    ),
    profile_txt_payment_method: getLabel(
      state,
      'profile_txt_payment_method',
      'Payment Method'
    ),
    purchase_txt_benefit_details_01: getLabel(
      state,
      'purchase_txt_benefit_details_01',
      'purchase_txt_benefit_details_01'
    ),

    shop_txt_7_day_trial: getLabel(
      state,
      'shop_txt_7_day_trial',
      '7 Days Free Trial'
    ),
    shop_txt_checkout_title: getLabel(
      state,
      'shop_txt_checkout_title',
      `We’ll only use this if you continue
after your trial end!`
    ),
    profile_txt_recurring_billing: getLabel(
      state,
      'profile_txt_recurring_billing',
      'Recurring billing, cancel anytime.'
    ),

    profile_txt_terms_condition: getLabel(
      state,
      'profile_txt_terms_condition',
      'Terms & Conditions'
    ),

    btn_submit: getLabel(state, 'btn_submit', 'Submit'),
    profile_txt_expire_date: getLabel(
      state,
      'profile_txt_expire_date',
      'Expiration date'
    ),
    profile_txt_name_card: getLabel(
      state,
      'profile_txt_name_card',
      'Name on card'
    ),
    random_test_modal_back_txt: getLabel(
      state,
      'random_test_modal_back_txt',
      'Back'
    ),

    profile_txt_card_number: getLabel(
      state,
      'profile_txt_card_number',
      'Card Number'
    ),
    profile_txt_success_title: getLabel(
      state,
      'profile_txt_success_title',
      'Sad to see you go...'
    ),
    profile_txt_success_dec: getLabel(
      state,
      'profile_txt_success_dec',
      'You subscription is canceled. You can continue to enjoy your Ling Pro benefits until'
    ),
    profile_txt_cancel_comfirm: getLabel(
      state,
      'profile_txt_cancel_comfirm',
      'Yes, Cancel'
    ),
    profile_txt_cancel_denies: getLabel(
      state,
      'profile_txt_cancel_denies',
      "No, Don't Cancel"
    ),
    profile_txt_cancel_subscription_des: getLabel(
      state,
      'profile_txt_cancel_subscription_des',
      'Are you sure you want to cancel your subscription? You will lose the great benefits of ad-free lessons and an opportunity to support Ling'
    ),
    profile_txt_on: getLabel(state, 'profile_txt_on', 'On'),
    profile_txt_past_due_cancel_modal_des: getLabel(
      state,
      'profile_txt_past_due_cancel_modal_des',
      'Your subscription is canceled. You can always subscribe to Ling Pro again from our website.'
    ),
    profile_txt_charged: getLabel(
      state,
      'profile_txt_charged',
      'will be charged'
    ),
    profile_txt_for: getLabel(state, 'profile_txt_for', 'for'),
    profile_txt_of: getLabel(state, 'profile_txt_of', 'of'),
    ...getLabels(
      state,
      'purchase_txt_option_goal',
      'setting_txt_settingHeader',
      'setting_txt_contactUs',
      'report_text_sendfeedback',
      'setting_txt_aboutUs',
      'createAccount_txt_title',
      'setting_txt_otherApp',
      'btn_continue',
      'loginPurchase_txt',
      'logout_txt',
      'setting_txt_effect',
      'setting_txt_voice_speed',
      'setting_voice_speed_1_txt',
      'setting_voice_speed_2_txt',
      'setting_voice_speed_3_txt',
      'setting_voice_speed_4_txt',
      'setting_voice_speed_5_txt'
    )
  };
};

export const mapDispatchToProps = (dispatch: Function) => {
  return {
    signOut: () => {
      dispatch(signOut());
    },
    updateUserProfile: (newUser: Object) => {
      dispatch(updateUserData(newUser));
    },
    setTrialUsedIP: () => {
      dispatch(setTrialUsedIP());
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageSubscriptionScene);
