/* @flow */
export const matchingStatus: Object = {
  VISIBLE: 'VISIBLE',
  SELECTED: 'SELECTED',
  MATCH: 'MATCH',
  NO_MATCH: 'NO_MATCH',
  INVISIBLE: 'INVISIBLE'
};

export const quizStatus: Object = {
  VISIBLE: 'VISIBLE',
  SELECTED: 'SELECTED',
  MATCH: 'MATCH',
  NO_MATCH: 'NO_MATCH'
};

export const translateGameStatus: Object = {
  VISIBLE: 'VISIBLE',
  SELECTED: 'SELECTED',
  MATCH: 'MATCH',
  NO_MATCH: 'NO_MATCH'
};

export const spellingStatus: Object = {
  VISIBLE: 'VISIBLE',
  CORRECT: 'CORRECT',
  WRONG: 'WRONG'
};

export const LEFT: string = 'left';
export const RIGHT: string = 'right';

export const CARD_NAME: string = 'cardName';
export const CARD_NUMBER: string = 'cardNumber';
export const CARD_EXPIRY: string = 'cardExpiry';
export const CARD_CVC: string = 'cardCvc';
export const CARD_PAID: string = 'paid';
export const CARD_SUCCEEDED: string = 'succeeded';
export const EMAIL: string = 'Email';
export const SEVEN_DAY_TRIAL: string = '7daytrial';
export const DAILY_GOAL_POINT: Object = {
  '200': { label: '200 BP', goal: 200 },
  '400': { label: '400 BP', goal: 400 },
  '600': { label: '600 BP', goal: 600 }
};
