/* @flow */
import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Check, Close } from '@material-ui/icons';
import { Box } from '@mui/material';
import {
  DARK_BLUE_3,
  DARK_BLUE_4,
  MARIGOLD,
  PRODUCT_TYPE_COLUMN_NUMBER,
  WHITE
} from '../../../common/constants';
import { PRODUCT_DETAIL_TABLE_COLUMN } from '../../constants';
import type { IProductTypes } from '../../constants';
import {
  getMonthlyOfYearlyProduct,
  getProductCurrencySymbol,
  getProductPrice
} from '../../../util/ProductHelper';

const ProductColumnBedge = styled.div`
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  height: 30px;
  width: 100%;
  background: ${DARK_BLUE_4};
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  vertical-align: middle;
  top: 0;
  left: 0;
  border-radius: 20px 20px 0px 0px;
`;
const ProductBenefitDetail = styled.div`
  background: ${DARK_BLUE_3};
  color: ${WHITE};
  width: 100%;
  display: inline-block;
  overflow: auto;
`;
const ProductTitleContainer = styled.div`
  height: 90px;
`;
const ProductTitle = styled.div`
  font-weight: bold;
`;
const ProductPriceNumber = styled.span`
  font-weight: ${props =>
    props.columnOrder === 2 || props.columnOrder === 3 ? 'bold' : 'normal'};
  color: ${props =>
    props.columnOrder === 2 || props.columnOrder === 3 ? MARIGOLD : WHITE};
`;
const ProductPrice = styled.div`
  font-weight: lighter;
  &.yearly {
    font-size: 14px;
    font-weight: normal;
  }
`;

const ProductBenefitColumn = styled.div`
  margin: 0px 3px;
  padding: 47px 33px 76px 13px;
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%);
  border: solid 1px #495b70;
  flex: 0.166;
  padding-left: 13px;

  &.name {
    flex: 0.5;
    text-align: end;
    padding-right: 13px;
  }
  &.isSubscription {
    position: relative;
  }
`;
const ProductBenefitRow = styled.div`
  padding-bottom: 18px;
`;

type Props = {
  shop_txt_month: string,
  shop_txt_year: string,
  purchase_txt_option_popular: string,
  shop_txt_product_detail_basic: string,
  shop_txt_product_detail_free: string,
  shop_txt_product_detail_subscriptions: string,
  purchase_txt_option_lifeTime: string,
  shop_txt_product_detail_200_lessons_60: string,
  shop_txt_product_detail_beginner_lessons: string,
  shop_txt_product_detail_introductory_chatbot: string,
  shop_txt_product_detail_sync_across_devices: string,
  shop_txt_product_detail_all_topics: string,
  shop_txt_product_detail_grammar_tips: string,
  shop_txt_checkout_title_lifetime: string,
  currencySymbol: string,
  currencyCode: string,
  yearPrice: number,
  usdYearPrice: number,
  lifeTimePrice: number,
  usdLifeTimePrice: number
};

const ProductBenefits = ({
  shop_txt_month,
  shop_txt_year,
  purchase_txt_option_popular,
  shop_txt_product_detail_basic,
  shop_txt_product_detail_free,
  shop_txt_product_detail_subscriptions,
  purchase_txt_option_lifeTime,
  shop_txt_product_detail_200_lessons_60,
  shop_txt_product_detail_beginner_lessons,
  shop_txt_product_detail_introductory_chatbot,
  shop_txt_product_detail_sync_across_devices,
  shop_txt_product_detail_all_topics,
  shop_txt_product_detail_grammar_tips,
  shop_txt_checkout_title_lifetime,
  yearPrice,
  usdYearPrice,
  lifeTimePrice,
  usdLifeTimePrice,
  currencySymbol,
  currencyCode
}: Props) => {
  const productTypes: IProductTypes[] = [
    {
      isSubscription: false,
      name: '',
      price: ''
    },
    {
      isSubscription: false,
      name: shop_txt_product_detail_basic,
      price: shop_txt_product_detail_free
    },
    {
      isSubscription: true,
      name: shop_txt_product_detail_subscriptions,
      price:
        getProductCurrencySymbol(currencyCode, currencySymbol) +
        getMonthlyOfYearlyProduct(yearPrice, usdYearPrice),
      yearlyPrice: getProductPrice(yearPrice, usdYearPrice)
    },
    {
      isSubscription: false,
      name: purchase_txt_option_lifeTime,
      price:
        getProductCurrencySymbol(currencyCode, currencySymbol) +
        getProductPrice(lifeTimePrice, usdLifeTimePrice)
    }
  ];
  const productBenefits = [
    {
      name: shop_txt_product_detail_beginner_lessons,
      isBasic: true,
      isSubscription: true,
      isLifeTime: true
    },
    {
      name: shop_txt_product_detail_introductory_chatbot,
      isBasic: true,
      isSubscription: true,
      isLifeTime: true
    },
    {
      name: shop_txt_product_detail_200_lessons_60,
      isBasic: false,
      isSubscription: true,
      isLifeTime: true
    },
    {
      name: shop_txt_product_detail_sync_across_devices,
      isBasic: false,
      isSubscription: true,
      isLifeTime: true
    },
    {
      name: shop_txt_product_detail_all_topics,
      isBasic: false,
      isSubscription: true,
      isLifeTime: true
    },
    {
      name: shop_txt_product_detail_grammar_tips,
      isBasic: false,
      isSubscription: true,
      isLifeTime: true
    },
    {
      name: shop_txt_checkout_title_lifetime,
      isBasic: false,
      isSubscription: false,
      isLifeTime: true
    }
  ];

  const styles = {
    check: {
      color: MARIGOLD
    },
    close: {
      color: 'grey'
    },
    benefits: {
      display: 'flex',
      margin: 'auto',
      width: 1,
      py: 35
    }
  };

  return (
    <ProductBenefitDetail>
      <Box sx={styles.benefits}>
        {_.times(PRODUCT_TYPE_COLUMN_NUMBER, number => {
          const { name, price, yearlyPrice } = productTypes[number];
          return (
            <ProductBenefitColumn
              className={PRODUCT_DETAIL_TABLE_COLUMN[number.toString()]}
              key={number}
            >
              <ProductColumnBedge>
                {purchase_txt_option_popular}
              </ProductColumnBedge>
              <ProductTitleContainer>
                <ProductTitle>{name}</ProductTitle>
                <ProductPrice>
                  <ProductPriceNumber columnOrder={number}>
                    {price}
                  </ProductPriceNumber>
                  {number === 2 && '/' + shop_txt_month}
                </ProductPrice>
                {productTypes[number].isSubscription && (
                  <ProductPrice className="yearly">
                    1 {shop_txt_year} {yearlyPrice}
                  </ProductPrice>
                )}
              </ProductTitleContainer>

              {productBenefits.map((item, index) => (
                <ProductBenefitRow key={index}>
                  {number === 0 ? (
                    item[PRODUCT_DETAIL_TABLE_COLUMN[number.toString()]]
                  ) : item[PRODUCT_DETAIL_TABLE_COLUMN[number.toString()]] ? (
                    <Check style={styles.check} />
                  ) : (
                    <Close style={styles.close} />
                  )}
                </ProductBenefitRow>
              ))}
            </ProductBenefitColumn>
          );
        })}
      </Box>
    </ProductBenefitDetail>
  );
};
export { ProductBenefits };
