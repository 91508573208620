/* @flow */
import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { PREFIX_HEADER, WHITE } from '../../../common/constants';
import GameScoreContainer from '../../../games/containers/GameScoreContainer';
import { getUserBananas, getUserCoins } from '../../../profile/selectors';

const Container = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  background: ${WHITE};
  margin-bottom: ${props => (props.isReview ? 35 : 52)}px;
  padding-top: 36px;
  position: ${props => (props.isReview ? 'fixed' : 'unset')};
  top: 0;
  z-index: 10;
`;

const BackArea = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 40px;
  margin-bottom: 52px;
  &:hover {
    cursor: pointer;
  }
`;

const HeaderContainer = styled.div`
  max-width: 1024px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
`;

const Image = styled.img`
  width: 18px;
  height: 32px;
  margin-right: 24px;
`;

const Title = styled.p`
  font-size: 21px;
  font-weight: bold;
  color: #454545;
  margin: 0 !important;
`;
type Props = {
  history: any,
  title: string,
  hasTranscript: boolean,
  isReview: boolean,
  screen: string
};

export class PreHeader extends Component<Props> {
  goToHome = () => {
    this.props.history.push('/');
  };

  goToBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { title, hasTranscript, isReview = false, screen } = this.props;
    return (
      <Container isReview={isReview}>
        <HeaderContainer>
          <BackArea onClick={isReview ? this.goToBack : this.goToHome}>
            <Image src={`${PREFIX_HEADER}back-icon.svg`} />
            <Title>{title}</Title>
          </BackArea>
          <GameScoreContainer
            hasTranscript={hasTranscript}
            isReview={true}
            screen={screen}
          />
        </HeaderContainer>
      </Container>
    );
  }
}

export const mapStateToProps = (state: Object, ownProps: Object) => {
  return {
    ...ownProps,
    bananas: getUserBananas(state),
    coins: getUserCoins(state)
  };
};

export default connect(mapStateToProps)(PreHeader);
