/* @flow */
import _ from 'lodash';
// ES module-style import
import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import version from '../../package.json';
import { getAppLanguage } from './AssetHelper';
const WARNING_SEVERITY = 'warning';
const ERROR_SEVERITY = 'error';
export const WARNING_MESSAGES = [
  'Network request failed',
  'The email address is already in use by another account.',
  'User has already been linked to the given provider.',
  'The password is invalid or the user does not have a password.',
  'There is no user record corresponding to this identifier. The user may have been deleted.'
];
export const IS_NOTIFICATION_ENABLED_DEFAULT = false;
/* Utility */
export const reportAppLanguage = (targetLanguage: string) => {
  return {
    appLanguage: getAppLanguage(),
    targetLanguage: targetLanguage
  };
};

export const getSeverity = (e: Error) => {
  return _.some(WARNING_MESSAGES, warning => _.includes(e.message, warning))
    ? WARNING_SEVERITY
    : ERROR_SEVERITY;
};
const releaseStage =
  process.env.REACT_APP_DEV === 'true' ? 'development' : 'production';
Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact(React)],
  logger: null,
  appVersion: version,
  releaseStage
});

const instance = {};
instance.breadcrumb = (message: string, parameter: any) => {
  console.log('Set breadcrumb', parameter, message);
  Bugsnag.leaveBreadcrumb(message, { type: 'log', ...parameter });
};

instance.notify = (e: any) => {
  if (!(e instanceof Error)) {
    e = new Error(e);
  }
  Bugsnag.notify(e, report => {
    report.severity = getSeverity(e);
    console.log('Reporting error...', e);
  });
};

instance.setUser = (user: string, name: string, email: string) => {
  if (user) {
    Bugsnag.setUser(user, name || '', email || '');
  }
};
export default instance;
