/* @flow */
// $FlowFixMe for useState, useEffect
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import _ from 'lodash';
import { onPressUnitItem } from '../../util';
import TutorialScene from '../../components/main-components/TutorialScene';
import GameScreenContainer from '../../games/containers/GameScreenContainer';
import { IMAGE_PATH, PREFIX_LESSON } from '../../common/constants';

import {
  TutorialContainerStyle,
  SpinnerContainer,
  Box,
  InnerBox,
  CrackOpenText,
  ImageContainer,
  EggCrackImageModal,
  Container
} from './HomeSceneContent.styles';
import { TUTORIAL_KEY } from '../../games/constants';
import { UnitList } from './UnitList';
import { firebase } from '../../system/Firebase';

const lingLoading = `${IMAGE_PATH}ling-loader.gif`;
const eggCrack = `${PREFIX_LESSON}app_img_spacedRepetition_eggYellow38Px@3x.png`;

type HomeSceneContentProps = {
  createAccount_txt_title: string,
  currentCourse: Object,
  disableRepairedUnitTooltips: Function,
  fetchUnitData: Function,
  firstCrackedUnitId: number,
  getCustomRef: Function,
  history: Object,
  isBusiness: boolean,
  isShowTutorial: boolean,
  isFinishTutorial: boolean,
  isLoading: boolean,
  isShowRepairedUnitTooltipsAlready: boolean,
  isUnitLocked: Function,
  loginPurchase_txt: string,
  nativeLangCode: string,
  setCustomRef: Function,
  showTutorial: boolean,
  spaced_repetition_open_txt: string,
  targetLangCode: string,
  toggleCreateAccountBusiness: Function,
  unitContinue: any,
  unitLevel_txt_advance: string,
  unitLevel_txt_afterintermediate: string,
  unitLevel_txt_basic: string,
  unitLevel_txt_beginner: string,
  unitLevel_txt_intermediate: string,
  unitLevel_txt_unit: string,
  units: Object,
  user: Object,
  restrictedUnits: Array<number>,
  updateUserData: Function
};

const Popper = (
  firstCrackedUnitId: number,
  spaced_repetition_open_txt: string,
  disableRepairedUnitTooltips: Function,
  history: Object,
  fetchUnitData: Function,
  nativeLangCode: string,
  targetLangCode: string,
  isUnitLocked: Function
) => {
  const stickyContainer = document.getElementById(
    firstCrackedUnitId.toString()
  );
  const checkOnline = async (unitId: number, isCrackedUnit: boolean) => {
    disableRepairedUnitTooltips();
    onPressUnitItem(
      history,
      unitId,
      isCrackedUnit,
      fetchUnitData,
      nativeLangCode,
      targetLangCode,
      isUnitLocked,
      disableRepairedUnitTooltips
    );
  };
  const popoverTop =
    firstCrackedUnitId === 1
      ? 408
      : stickyContainer &&
        stickyContainer.offsetHeight + stickyContainer.offsetTop - 20;
  const popoverLeft =
    firstCrackedUnitId === 1
      ? 75
      : stickyContainer && stickyContainer.offsetLeft - 20;

  return (
    <Box
      popoverTop={popoverTop}
      popoverLeft={popoverLeft}
      onClick={() => checkOnline(firstCrackedUnitId, true)}
    >
      <InnerBox>
        <CrackOpenText>{spaced_repetition_open_txt}</CrackOpenText>
        <ImageContainer>
          <EggCrackImageModal src={eggCrack} alt="eggCrack" />
        </ImageContainer>
      </InnerBox>
    </Box>
  );
};
const HomeSceneContent = ({
  createAccount_txt_title,
  currentCourse,
  disableRepairedUnitTooltips,
  fetchUnitData,
  firstCrackedUnitId,
  getCustomRef,
  history,
  isBusiness,
  isFinishTutorial,
  isLoading,
  isShowRepairedUnitTooltipsAlready,
  isUnitLocked,
  loginPurchase_txt,
  nativeLangCode,
  setCustomRef,
  showTutorial,
  spaced_repetition_open_txt,
  targetLangCode,
  toggleCreateAccountBusiness,
  unitContinue,
  unitLevel_txt_advance,
  unitLevel_txt_afterintermediate,
  unitLevel_txt_basic,
  unitLevel_txt_beginner,
  unitLevel_txt_intermediate,
  unitLevel_txt_unit,
  units,
  user,
  isShowTutorial,
  restrictedUnits,
  updateUserData
}: HomeSceneContentProps) => {
  const { features, words } = currentCourse;
  const currentUser = firebase.auth().currentUser;
  const isAnonymous = _.get(currentUser, 'isAnonymous', false);
  const languageProgress = _.get(user, targetLangCode, {});

  useEffect(() => {
    if (!isAnonymous && _.has(languageProgress, 'stars')) {
      updateUserData();
    }
  }, [targetLangCode]);

  const isTutorialLoad = _.isEmpty(words)
    ? false
    : _.isEmpty(words[TUTORIAL_KEY]) || !_.keys(words).length === 1;

  const unitLevels = [0, 1, 2, 3, 4];
  const getUnitRefName = key => `unitRef${key}`;
  const showLoading =
    _.isEmpty(features) ||
    _.isEmpty(words) ||
    (_.isEmpty(user) && isLoading) ||
    (showTutorial && isTutorialLoad) ||
    isLoading;

  // TODO: fix rerenders
  // console.log('home content');
  return (
    <Container
      isShowTutorial={isShowTutorial}
      showTutorial={showTutorial}
      isFinishTutorial={isFinishTutorial}
    >
      {showLoading ? (
        <SpinnerContainer>
          <img src={lingLoading} alt={lingLoading} />
        </SpinnerContainer>
      ) : isShowTutorial && !showTutorial && !isFinishTutorial ? (
        <TutorialScene history={history} showTutorial={showTutorial} />
      ) : showTutorial ? (
        <TutorialContainerStyle
          showTutorial={showTutorial}
          isShowTutorial={isShowTutorial}
        >
          <GameScreenContainer showTutorial={showTutorial} />
        </TutorialContainerStyle>
      ) : (
        <UnitList
          unitLevels={unitLevels}
          units={units}
          restrictedUnits={restrictedUnits}
          unitLevel_txt_basic={unitLevel_txt_basic}
          unitLevel_txt_beginner={unitLevel_txt_beginner}
          unitLevel_txt_intermediate={unitLevel_txt_intermediate}
          unitLevel_txt_afterintermediate={unitLevel_txt_afterintermediate}
          unitLevel_txt_advance={unitLevel_txt_advance}
          unitLevel_txt_unit={unitLevel_txt_unit}
          isBusiness={isBusiness}
          setCustomRef={setCustomRef}
          getUnitRefName={getUnitRefName}
          getCustomRef={getCustomRef}
          spaced_repetition_open_txt={spaced_repetition_open_txt}
          toggleCreateAccountBusiness={toggleCreateAccountBusiness}
          user={user}
          loginPurchase_txt={loginPurchase_txt}
          createAccount_txt_title={createAccount_txt_title}
          targetLangCode={targetLangCode}
          nativeLangCode={nativeLangCode}
          fetchUnitData={fetchUnitData}
          isUnitLocked={isUnitLocked}
          features={features}
          unitContinue={unitContinue}
          history={history}
          firstCrackedUnitId={firstCrackedUnitId}
          isShowRepairedUnitTooltipsAlready={isShowRepairedUnitTooltipsAlready}
          disableRepairedUnitTooltips={disableRepairedUnitTooltips}
          Popper={Popper}
        />
      )}
    </Container>
  );
};
export { HomeSceneContent };
