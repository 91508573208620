import _ from 'lodash';
import fp from 'lodash/fp';
import { bindActionCreators } from 'redux';

import {
  pressQuizCard,
  pressQuizNextButton,
  finishLoadAnimation,
  setShowTutorial,
  onCloseTutorialMotivate
} from '../actions';
import { getGameOrder, getExamOrder } from '.';
import { getUnitEntries } from '../../lessons/selectors';
import {
  EXAM_INDEX,
  SPEAKING_GAME_INDEX,
  WRITING_GAME_INDEX,
  TUTORIAL_ID,
  DIALOGUE,
  FLASHCARD,
  QUIZ,
  ORDER_SENTENCE_REVERSE
} from '../constants';
import { DEFAULT_NATIVE_LANGUAGE_CODE } from '../../lessons/constants';
import { getSpeakingOrder, getWritingOrder } from './GameOrder';

const NUMBER_OF_CARDS = 4;
const DIALOG_SPEAKER0 = 'Speaker A';
const DIALOG_SPEAKER1 = 'Speaker B';
const DIALOG_PLACE = 'Any Place';
const DIALOG_FORMALITY = true;

const validateGameData = (
  data: Object,
  activeLangCode: string,
  unitId: number,
  lessonId: number
) => (attribute: string) => {
  if (!data[attribute]) {
    console.warn(
      `Could not find attribute ${attribute} for activeLangCode ${activeLangCode} unitId ${unitId} lessonId ${lessonId}`
    );
  }
};

const validateAllData = (
  data: Object,
  courses: Object,
  unitId: number,
  lessonId: number
) => {
  if (!data) {
    console.warn(
      `Could not find data for activeLangCode ${courses.activeLangCode} unitId ${unitId} lessonId ${lessonId}`
    );
  }
  const validate = validateGameData(
    data,
    courses.activeLangCode,
    unitId,
    lessonId
  );
  validate('words');
  validate('sentences');
  validate('dialogues');
};

export const getSpeaking = ({ courses }: Object, unitId: number) => {
  const data = _.get(courses, courses.activeLangCode, {});
  const { words, sentences } = getPhrases(data, getAll(unitId));
  return {
    words,
    sentences,
    key: `${unitId},${SPEAKING_GAME_INDEX}`,
    gameOrder: getSpeakingOrder(words.allIds, sentences.allIds)
  };
};

export const getWriting = ({ courses }: Object, unitId: number) => {
  const data = _.get(courses, courses.activeLangCode, {});
  const characters = data.characters[unitId];
  return {
    characters,
    key: `${unitId},${WRITING_GAME_INDEX}`,
    gameOrder: getWritingOrder(characters.allIds)
  };
};

export const getGame = (
  { courses }: Object,
  unitId: number,
  lessonId: number
) => {
  const data = _.get(courses, courses.activeLangCode, null);
  validateAllData(data, courses, unitId, lessonId);

  const key = _.join([unitId, lessonId], ',');
  const get = getOne(key);

  const { words, sentences, dialogues } = getPhrases(data, get);
  let gameOrder = [];

  if (unitId === TUTORIAL_ID) {
    const WOMAN_PHRASE_ID = 'a0101000';
    const I_AM_WOMAN_PHRASE_ID = 'a0101100';

    gameOrder = [
      { gameType: DIALOGUE },
      { gameType: FLASHCARD, phraseId: WOMAN_PHRASE_ID },
      { gameType: QUIZ, phraseId: WOMAN_PHRASE_ID },
      { gameType: ORDER_SENTENCE_REVERSE, phraseId: I_AM_WOMAN_PHRASE_ID }
    ];
  } else {
    gameOrder = getGameOrder(words.allIds, sentences.allIds, dialogues.allIds);
  }
  return {
    words,
    sentences,
    dialogues,
    grammar: getGrammar(courses, data.grammar, unitId, lessonId),
    key,
    gameOrder
  };
};

export const getGrammar = (
  nativeLang: string,
  grammar: ?Object,
  unitId: ?number,
  lessonId: number
) => {
  if (grammar) {
    const checkGrammar = _.isEmpty(
      _.get(grammar[nativeLang], `${1}.details.byId.d1.text`)
    );

    const grammarContent = checkGrammar
      ? grammar[DEFAULT_NATIVE_LANGUAGE_CODE]
      : grammar[nativeLang];
    const grammarUnit = _.get(grammarContent, unitId);

    if (!grammarUnit) {
      return {};
    }
    const getById = node => grammarUnit[node].byId;
    const titleById = getById('titles');
    const detailById = getById('details');
    const subtitleById = getById('subtitles');

    const title = _.find(
      titleById,
      ({ unit, lesson }) => unit === unitId && lesson === lessonId
    );

    if (title) {
      const details = title.details.map(id => {
        const detail = detailById[id];
        return { ...detail, subtitle: subtitleById[detail.subtitle] };
      });
      return { ...title, details };
    }
  }

  return {};
};

export const getCheckGrammar = (state: Object) => {
  return _.get(state, 'game.grammar.id', null);
};

export const getPhrases = (data: Object, get: Function) => {
  const { words, sentences, dialogues, characters } = data;

  return {
    words: get(words),
    sentences: get(sentences),
    dialogues: get(dialogues),
    characters
  };
};

const getOne = fp.curry((key: string, phrases: Object) => {
  const lessonPhrases = phrases ? phrases[key] : null;
  if (lessonPhrases) {
    return lessonPhrases;
  }
  console.warn(`Could not find content for key ${key}!`);
});

export const mapDispatchToQuizProps = (dispatch: Function) => {
  const finishAnimationThunk = finishLoadAnimation(NUMBER_OF_CARDS);
  return {
    ...bindActionCreators(
      {
        pressQuizCard,
        pressQuizNextButton
      },
      dispatch
    ),
    animationFinished(loadAnimationFinished: boolean) {
      return dispatch(finishAnimationThunk);
    },
    setShowTutorial(value: boolean) {
      return dispatch(setShowTutorial(value));
    },
    onCloseTutorialMotivate() {
      return dispatch(onCloseTutorialMotivate());
    }
  };
};

export const getDialogHeader = (state: Object) => {
  const defaultDialogHeader = {
    speaker0: DIALOG_SPEAKER0,
    speaker1: DIALOG_SPEAKER1,
    place: DIALOG_PLACE,
    formality: DIALOG_FORMALITY
  };
  const result = _.get(state, 'data.dialogHeaders.byId', null);

  if (!result) {
    return defaultDialogHeader;
  }
  const key = getUnitIdAndLessonId(state);
  const unitId = key.unitId;
  const lessonId = key.lessonId;
  const data =
    'h' +
    _.padStart(String(unitId), 2, String(0)) +
    _.padStart(String(lessonId), 2, String(0));
  const resultData = result[data];

  if (!resultData) {
    return defaultDialogHeader;
  }
  const {
    speaker0 = DIALOG_SPEAKER0,
    speaker1 = DIALOG_SPEAKER1,
    place = DIALOG_PLACE,
    formality = DIALOG_FORMALITY
  } = resultData;

  return {
    speaker0,
    speaker1,
    place,
    formality
  };
};

const getKey = (state: Object) => state.game.key.split(',');

export const getUnitIdAndLessonId = (state: Object) => {
  const key = getKey(state);
  return {
    unitId: Number(key[0]),
    lessonId: Number(key[1])
  };
};

export const getExam = ({ courses }: Object, unitId: number) => {
  const data = _.get(courses, courses.activeLangCode, {});
  const { words, sentences, dialogues } = getPhrases(data, getAll(unitId));
  return {
    words,
    sentences,
    dialogues,
    key: `${unitId},${EXAM_INDEX}`,
    gameOrder: getExamOrder(words.allIds, sentences.allIds, dialogues.allIds)
  };
};

export const getAll = fp.curry((unitId: number, phrases: Object) => {
  let byId = {},
    allIds = [];
  if (phrases) {
    const allLessons = getUnitEntries(phrases, unitId);

    _.forEach(allLessons, lesson => {
      allIds = allIds.concat(lesson.allIds);
      byId = { ...byId, ...lesson.byId };
    });
  }

  return { byId, allIds };
});

export const getAllGrammar = (
  grammar: ?Object,
  unitId: ?number,
  nativeLang: ?string
) => {
  const grammarUnitData = _.get(grammar, nativeLang);

  const grammarUnit = _.get(grammarUnitData, unitId);

  const max = _.size(grammarUnit.titles.allIds);

  let grammarList = [];
  for (var i = 0; i < max; i++) {
    grammarList[i] = getGrammar(nativeLang, grammar, unitId, i + 1);
  }

  return grammarList;
};
