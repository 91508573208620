import moment from 'moment';
import { WeekDayData } from './staticData';

// type ConfigProps = {};

// declare type InitialStateProps = {
//   config: ConfigProps
// };

const initialState = {
  config: {
    started: false,
    fetched: false,
    requestFetch: false,
    campaignId: '0'
  },
  courses: {
    native: 'en',
    target: 'th',
    activeLangCode: 'th',
    nativeLanguageFeatures: {},
    fetchingUnitId: '1'
  },
  data: {
    fetchingUnitId: null,
    totalFileSize: null,
    fileSizeDownloaded: null,
    labels: { byId: {}, allIds: [] },
    targetLangCode: 'th',
    nativeLangCode: 'en',
    globalAssetsDownloaded: false,
    faq: {},
    targetLangCodes: [],
    storedTargetLangCodes: [],
    hasAskedReminderPermissions: true,
    setToggleOnBoarding: true,
    dialogHeaders: { byId: {}, allIds: [] },
    dialogHeadersDownloaded: false,
    stopWords: [],
    targetLangCodesIndex: [],
    chatbots: {},
    courses: {
      native: 'en',
      target: 'th'
    }
  },
  dialog: {
    title: 'Listen to this Dialog',
    state: 0,
    targetSlot: 0,
    currentSound: null,
    currentSequenceSound: 0,
    autoplay: true,
    sequence: 0,
    playing: true,
    pausing: false,
    dialogues: [],
    translate: false,
    questions: [],
    view: null,
    hintState: false,
    toggleCheck: false,
    func: null
  },
  flashcard: {
    wordId: '',
    sentenceId: '',
    showSentence: false
  },
  game: {
    words: {},
    sentences: {},
    grammar: {},
    dialogues: {},
    key: '',
    gameOrder: []
  },
  lessons: {
    lessons: [],
    unitId: 0,
    permissionModalVisible: false
  },
  matchingCardsDisplayed: {},
  storage: {
    localStorage: null,
    initApp: false,
    user: {
      targetLangCode: 'th',
      nativeLangCode: 'en',
      voiceSpeed: 3,
      isEffectEnabled: true,
      isSignUpBusinessSuccess: false,
      trialUsedIP: '',
      goal: {
        isSetDailyGoal: false,
        userDailyGoal: 0,
        weeklyGraphData: [0, 0, 0, 0, 0, 0, 0],
        weekNumber: moment().week()
      },
      reminders: {
        isSetReminder: false,
        timeReminder: '09:30',
        weekDays: WeekDayData
      }
    },
    payments: {
      isLifetime: false,
      isProUser: false
    },
    faq: {},
    isShowTutorial: false,
    isTutorialMotivate: false,
    isLoading: false,
    chatbotLoaded: 'chat00',
    scene: 'lesson',
    isLoginModalOpen: false,
    configs: {
      useProductionDatabase: true,
      numberOfFreeUnits: 5,
      shopScreenVariant: 1,
      isShowMotivation: false,
      isShowInviteFriend: false,
      isEnabledSpacedRepetition: false
    },
    langs: {},
    isTargetLangSymbol: true,
    isBusiness: false,
    isBusinessSignUp: false,
    noAccessUnits: [],
    noAccessChatbotUnits: []
  },
  onboarding: {
    activeScreen: 6,
    campaignId: 0,
    selections: [],
    completeCampaigns: [],
    campaignComplete: false,
    shopRedirected: false,
    monthlyPrice: {
      usd: {
        price: '',
        currency: '',
        symbol: '',
        priceId: '',
        decimalSeparator: '',
        priceUnitAmount: 0,
        productId: '',
        trialPeriodDays: 0
      }
    },
    yearlyPrice: {
      usd: {
        price: '',
        currency: '',
        symbol: '',
        priceId: '',
        decimalSeparator: '',
        priceUnitAmount: 0,
        productId: '',
        trialPeriodDays: 0
      }
    },
    quarterlyPrice: {
      usd: {
        price: '',
        currency: '',
        symbol: '',
        priceId: '',
        decimalSeparator: '',
        priceUnitAmount: 0,
        productId: '',
        trialPeriodDays: 0
      }
    },
    monthlyPriceStripeUk: {
      usd: {
        price: '',
        currency: '',
        symbol: '',
        priceId: '',
        decimalSeparator: '',
        priceUnitAmount: 0,
        productId: '',
        trialPeriodDays: 0
      }
    },
    quarterlyPriceStripeUk: {
      usd: {
        price: '',
        currency: '',
        symbol: '',
        priceId: '',
        decimalSeparator: '',
        priceUnitAmount: 0,
        productId: '',
        trialPeriodDays: 0
      }
    },
    yearlyPriceStripeUk: {
      usd: {
        price: '',
        currency: '',
        symbol: '',
        priceId: '',
        decimalSeparator: '',
        priceUnitAmount: 0,
        productId: '',
        trialPeriodDays: 0
      }
    }
  },
  purchase: {
    isModalVisible: false
  },
  quizCards: {
    byId: {},
    allIds: [],
    frozen: false,
    answeredWrong: false
  },
  spelling: {
    byId: {},
    allIds: [],
    wordId: '',
    imageSource: '',
    pressedIds: [],
    notYetPressedIds: [],
    lockedIds: [],
    additionalWords: [],
    isResultShown: false,
    answeredWrong: false,
    hintMode: false,
    stopWords: [],
    globalStopWords: [],
    isSpacedLang: false,
    gameType: ''
  },
  speaking: {
    phrase: {},
    phraseId: null,
    answers: '',
    isSpeaking: 0,
    isWord: false,
    recorder: null,
    isSpacedLang: false,
    answeredWrong: false,
    speakingErrorMsg: ''
  },
  tracker: {
    eventName: '',
    startTracking: false,
    tracking: false,
    trackingData: {},
    trackingError: '',
    userProperties: {}
  },
  units: {},
  shop: {
    firebasePromotions: {},
    isShowRepairedUnitModal: false,
    isShowRepairedUnitTooltipsAlready: false,
    selectedPurchaseProduct: {
      stripeId: '',
      priceUnitAmount: 0,
      currency: '',
      productName: ''
    },
    isPaywallClosed: false
  }
};

export default initialState;
