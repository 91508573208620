/* @flow */
import _ from 'lodash';
import { firebase } from '../system/Firebase';
import { requestFirebaseNotificationPermission } from '../system/FirebaseMessage';
import Analytic from '../util/Analytic';
import BugTracker from './BugTracker';
import { store } from '../';
import { convertToThaiTimeZone } from '../common/actions/GlobalActions';
import { fetchRemoteDatabase } from '../lessons/sagas';
import Compressor from 'compressorjs';
import moment from 'moment';

import {
  PROVIDER_FACEBOOK,
  PROVIDER_APPLE,
  PROVIDER_GOOGLE,
  PROVIDER_EMAIL,
  ANONYMOUS,
  SIGNIN_METHOD,
  DEFAULT_WEEKLY_GRAPH_DATA
} from '../profile/constants';
import { SHOW_TUTORIAL, SHOW_REMINDER, TRUE, FALSE } from '../common/constants';
import { TUTORIAL_KEY } from '../games/constants';
import { USER_DEFAULT_MAX_ID, UPLOAD_FILE_SUCCESS } from '../common/constants';
import { saveUserData } from '../common/actions/AuthActions';
import { toggleLoadingParam } from '../common/actions';

export const getUserProperty = (user: Object = {}, property: string = '') => {
  const providers = ['facebook', 'google', 'apple', 'email'];
  for (const provider of providers) {
    const path =
      property === 'name' && provider === 'email'
        ? `${property}`
        : `${provider}.${property}`;
    if (_.has(user, path)) {
      return _.get(user, path);
    }
  }
  return '';
};

export const fetchUser = (
  user: Object,
  saveUserData: Function,
  createNewDatabaseUser: Function
) => {
  fetchRemoteDatabase(`users/${user.uid}`).then(data => {
    const value = data.val();
    if (value) {
      saveUserData(value);
    } else {
      createNewDatabaseUser(
        user,
        (user.providerData[0] &&
          user.providerData[0].providerId.split('.')[0]) ||
          'anonymous'
      );
    }
  });
};

export const isFirebaseNotAllow = (error: Object) =>
  error.code === 'auth/operation-not-allowed' &&
  alert('You must enable Anonymous auth in the Firebase Console.');

export const saveName = (userData: Object, name: string) => {
  // eslint-disable-next-line
  const signin_method = localStorage.getItem(SIGNIN_METHOD);
  switch (signin_method) {
    case PROVIDER_FACEBOOK:
      updateName(userData, name, PROVIDER_FACEBOOK);
      break;
    case PROVIDER_GOOGLE:
      updateName(userData, name, PROVIDER_GOOGLE);
      break;
    case PROVIDER_APPLE:
      updateName(userData, name, PROVIDER_APPLE);
      break;
    case PROVIDER_EMAIL:
      updateName(userData, name, PROVIDER_EMAIL);
      break;
    default:
      updateName(userData, name, PROVIDER_EMAIL);
      break;
  }
};

export const saveProfilePic = (
  userData: Object,
  url: Object,
  selectedFile: Object
) => {
  // eslint-disable-next-line
  const signin_method = localStorage.getItem(SIGNIN_METHOD);
  switch (signin_method) {
    case PROVIDER_FACEBOOK:
      updateProfilePic(userData, url, selectedFile, PROVIDER_FACEBOOK);
      break;
    case PROVIDER_GOOGLE:
      updateProfilePic(userData, url, selectedFile, PROVIDER_GOOGLE);
      break;
    case PROVIDER_APPLE:
      updateProfilePic(userData, url, selectedFile, PROVIDER_APPLE);
      break;
    case PROVIDER_EMAIL:
      updateProfilePic(userData, url, selectedFile, PROVIDER_EMAIL);
      break;
    default:
      updateProfilePic(userData, url, selectedFile, PROVIDER_EMAIL);
      break;
  }
};

const updateName = async (user: Object, name: string, type: string) => {
  const { dispatch } = store;
  if (type === PROVIDER_EMAIL) {
    firebase
      .database()
      .ref(`users/${user.uid}`)
      .update({
        name: name
      })
      .catch(error => console.log('Update User Data:', { error }));
    dispatch(
      saveUserData({
        ...user,
        name: name
      })
    );
  } else {
    firebase
      .database()
      .ref(`users/${user.uid}/${type}`)
      .update({
        name: name
      })
      .catch(error => console.log('Update User Data:', { error }));
    dispatch(
      saveUserData({
        [type]: {
          ...user[type],
          name: name
        }
      })
    );
  }
};

const updateProfilePic = async (
  user: Object,
  url: Object,
  selectedFile: Object,
  type: string
) => {
  const { dispatch } = store;
  if (url.id < USER_DEFAULT_MAX_ID + 1) {
    dispatch(toggleLoadingParam(true));
    await firebase
      .database()
      .ref(`users/${user.uid}/${type}`)
      .update({
        photoURL: url.id.toString()
      })
      .catch(error => console.log('Update User Data:', { error }));
    dispatch(
      saveUserData({
        ...user,
        [type]: {
          ...user[type],
          photoURL: url.id.toString()
        }
      })
    );
    dispatch(toggleLoadingParam(false));
  } else {
    dispatch(toggleLoadingParam(true));
    const storageRef = firebase.storage().ref(`data/img/profile/${user.uid}`);
    // eslint-disable-next-line no-new
    new Compressor(selectedFile, {
      quality: 0.4,
      success(result) {
        storageRef
          .put(result)
          .then(async snapshot => {
            console.log('snapshot: ' + snapshot.state);
            console.log(
              'progress: ' +
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            if (snapshot.state === UPLOAD_FILE_SUCCESS) {
              const downloadUrl = await storageRef.getDownloadURL();
              await firebase
                .database()
                .ref(`users/${user.uid}/${type}`)
                .update({
                  photoURL: downloadUrl
                })
                .catch(error => console.log('Update User Data:', { error }));
              dispatch(
                saveUserData({
                  ...user,
                  [type]: {
                    ...user[type],
                    photoURL: downloadUrl
                  }
                })
              );
              dispatch(toggleLoadingParam(false));
            }
          })
          .catch(error =>
            console.log('image upload error: ' + error.toString())
          );
      },
      error(err) {
        BugTracker.notify(err);

        console.log(err.message);
      }
    });
  }
};

export const getName = (userData: Object) => {
  const emailName = _.get(userData, 'name', false);
  const facebookCheck = _.get(userData, 'facebook.name', false);
  const appleCheck = _.get(userData, 'apple.name', false);
  const googleCheck = _.get(userData, 'google.name', false);
  // eslint-disable-next-line
  const signin_method = localStorage.getItem(SIGNIN_METHOD);
  switch (signin_method) {
    case PROVIDER_FACEBOOK:
      return facebookCheck;
    case PROVIDER_GOOGLE:
      return googleCheck;
    case PROVIDER_APPLE:
      return appleCheck;
    case PROVIDER_EMAIL:
      return emailName;
    default:
      return emailName;
  }
};

export const extractProviderProfile = (
  providerData: Array<Object>,
  type: string
) => {
  const findPredicate = ({ providerId }) =>
    providerId ===
    ([PROVIDER_FACEBOOK, PROVIDER_GOOGLE, PROVIDER_APPLE].includes(type)
      ? `${type}.com`
      : type);

  const profile = _.find(providerData, findPredicate);
  if (profile) {
    const { email, displayName, photoURL, uid } = profile;
    return { email, displayName, photoURL, uid };
  }
};
export const getUserReference = (authUser: Object) => {
  return firebase.database().ref(`users/${authUser.uid}`);
};

export const saveRemoteUserName = (user: Object, name: string) => {
  try {
    if (user) {
      var changeName = {
        name: name
      };
      const firebaseDB = firebase.database().ref(`users/${user.uid}`);
      return firebaseDB
        .update(changeName)
        .catch(error => console.warn('Error updating remote username!', error));
    }
  } catch (error) {
    BugTracker.notify(error);
    console.warn('Save Remote Name failed!', error);
  }
};
export const getProviderSpecificData = (
  authUser: Object,
  type: string
): Object => {
  const { providerData } = authUser;
  let providerProfile = extractProviderProfile(providerData, type);
  const { displayName, email, photoURL, uid } = providerProfile
    ? providerProfile
    : authUser;
  if (email && displayName) {
    Analytic.prepareUserIdentify(email, displayName);
    Analytic.prepareUser();
  }

  return {
    [type]: {
      id: uid,
      name: displayName,
      email,
      photoURL
    }
  };
};

export const mergeOldUser = (
  targetLangCode: string,
  nativeLangCode: string,
  providerSpecificData: Object,
  uid: string,
  existingUser: ?Object = {}
) => {
  const newUser = {
    uid,
    ...providerSpecificData,
    ...existingUser,
    targetLangCode,
    nativeLangCode
  };
  return newUser;
};

export const getUserName = (userData: any) => {
  let userName;
  const emailName = _.get(userData, 'name', false);
  const facebookCheck = _.get(userData, 'facebook.name', false);
  const appleCheck = _.get(userData, 'apple.name', false);
  const googleCheck = _.get(userData, 'google.name', false);
  // eslint-disable-next-line
  const signin_method = localStorage.getItem(SIGNIN_METHOD);
  switch (signin_method) {
    case PROVIDER_FACEBOOK:
      userName = facebookCheck;
      break;
    case PROVIDER_APPLE:
      userName = appleCheck;
      break;
    case PROVIDER_GOOGLE:
      userName = googleCheck;
      break;
    case PROVIDER_EMAIL:
      userName = emailName;
      break;
    default:
      userName = ANONYMOUS;
      break;
  }
  return userName;
};

export const verifyExitedUserReferral = async (
  email: string,
  referralUid: string,
  referralHashCode: string,
  hashIp: string
) => {
  let isExisted = false;
  const referral = await getUserReferral(referralUid).once('value');
  let amountReferral = referral.val();

  _.forEach(amountReferral, function(value, key) {
    if (
      _.get(value, 'email', null) === email ||
      key === hashIp ||
      referralHashCode === hashIp
    ) {
      isExisted = true;
    }
  });
  return isExisted;
};

export const getUserReferral = (uid: string) => {
  return firebase.database().ref(`referral/${uid}`);
};
export const getInviteCampaigns = () => {
  return firebase.database().ref('data/referralCampaigns');
};

export const updateRemoteReminder = (
  uid: string,
  nativeLangCode: string,
  clientToken: string,
  reminderData: Object
) => {
  firebase
    .database()
    .ref(`reminders/${uid}`)
    .set({
      ...reminderData,
      nativeCode: nativeLangCode,
      clientToken: clientToken,
      bangkokTime: convertToThaiTimeZone(reminderData).bangkokTime,
      bangkokWeekdays: convertToThaiTimeZone(reminderData).bangkokWeekdays
    })
    .catch(error => BugTracker.notify(error));
};

export const loadUserDataHomeScreen = (
  history: Object,
  currentCourse: Object,
  courses: Object,
  isReminder: any,
  location: Object,
  match: Object,
  questions: Array<string>,
  setShowTutorial: Function,
  triggerLoadLanguageData: Function,
  setIsLangSymbol: Function,
  toggleLoginModal: Function,
  setClientToken: Function,
  stateChangeHandler: Function
) => {
  const { search } = location;
  const querySessionId = search.match(/sessionId=([^&]*)/);
  const queryProduct = search.match(/product=([^&]*)/);
  const queryPromoCode = search.match(/promoCode=([^&]*)/);
  const queryType = search.match(/type=([^&]*)/);
  const queryIsAffiliate = search.match(/isAffiliate=([^&]*)/);
  const queryIsPrice = search.match(/isPrice=([^&]*)/);
  //get UTM data
  const utm_source = search.match(/utm_source=([^&]*)/);
  const utm_medium = search.match(/utm_medium=([^&]*)/);
  const utm_campaign = search.match(/utm_campaign=([^&]*)/);

  if (queryIsAffiliate) {
    if (queryType) {
      if (queryPromoCode) {
        history.push(
          `/affiliate/?type=${queryType[1]}&sessionId=${querySessionId[1]}&promoCode=${queryPromoCode[1]}`
        );
      } else {
        let url = `/affiliate/?type=${queryType[1]}&sessionId=${querySessionId[1]}`;
        if (utm_source && utm_medium && utm_campaign) {
          url += `&utm_source=${utm_source[1]}&utm_medium=${utm_medium[1]}&utm_campaign=${utm_campaign[1]}`;
        }
        history.push(url);
      }
    } else {
      if (queryPromoCode) {
        history.push(
          `/affiliate/?product=${queryProduct[1]}&promoCode=${queryPromoCode[1]}`
        );
      } else {
        history.push(`/affiliate/?product=${queryProduct[1]}`);
      }
    }
  } else if (queryIsPrice) {
    if (queryType) {
      if (queryPromoCode) {
        history.push(
          `/price/?type=${queryType[1]}&sessionId=${querySessionId[1]}&promoCode=${queryPromoCode[1]}`
        );
      } else {
        let url = `/price/?type=${queryType[1]}&sessionId=${querySessionId[1]}`;
        if (utm_source && utm_medium && utm_campaign) {
          url += `&utm_source=${utm_source[1]}&utm_medium=${utm_medium[1]}&utm_campaign=${utm_campaign[1]}`;
        }
        history.push(url);
      }
    } else {
      if (queryPromoCode) {
        history.push(
          `/price/?product=${queryProduct[1]}&promoCode=${queryPromoCode[1]}`
        );
      } else {
        history.push(`/price/?product=${queryProduct[1]}`);
      }
    }
  }

  const { words } = currentCourse;
  const { path } = match;
  if (
    _.keys(words).length !== 0 &&
    _.keys(words).indexOf(TUTORIAL_KEY) === -1
  ) {
    // eslint-disable-next-line
    // TODO: Update the way this works
    localStorage.setItem(SHOW_TUTORIAL, FALSE);
  }

  const { target, native } = courses;

  if (isReminder) {
    requestFirebaseNotificationPermission()
      .then(firebaseToken => {
        console.log('token', firebaseToken);
        setClientToken && setClientToken(firebaseToken);
      })
      .catch(err => {
        BugTracker.notify(err);
        return err;
      });
  }

  triggerLoadLanguageData(target, native);
  setIsLangSymbol(target);
  // eslint-disable-next-line
  if (localStorage.getItem(SHOW_TUTORIAL) === TRUE) {
    // eslint-disable-next-line
    setShowTutorial(localStorage.getItem(SHOW_TUTORIAL) === TRUE);
  } else {
    stateChangeHandler('isFinishTutorial', true);
  }

  // eslint-disable-next-line
  if (localStorage.getItem(SHOW_REMINDER) === TRUE) {
    stateChangeHandler('isRemiderOpen', true);
  }
  if (search) {
    const queryLogin = search.match(/login=([^&]*)/);
    if (queryLogin) {
      var a = queryLogin['input'].replace('login=1&', '');
      history.push(a);
      toggleLoginModal();
    }
  }
  if (path === '/tutorial') {
    if (_.size(questions) === 0) {
      history.push('/');
    } else {
      stateChangeHandler('showTutorial', true);
    }
  }
};

export const saveNewName = (
  user: Object,
  newName: string,
  saveName: Function,
  stateChangeHandler: Function
) => {
  saveName(user, newName);
  stateChangeHandler('isNameChange', false);
  stateChangeHandler('editingId', '');
};
export const onChangeName = (
  text: string,
  name: string,
  stateChangeHandler: Function
) => {
  if (name !== text) {
    stateChangeHandler('newName', text);
    stateChangeHandler('isNameChange', true);
  } else {
    stateChangeHandler('newName', text);
    stateChangeHandler('isNameChange', false);
  }
};
export const getCurrentUserGoalData = (state: Object) => {
  const { targetLangCode } = state.storage.user || {};
  const { goal } = state.storage.user[targetLangCode] || {};
  const {
    weekNumber = moment().week(),
    weeklyGraphData = DEFAULT_WEEKLY_GRAPH_DATA,
    isSetDailyGoal = false,
    userDailyGoal = 0
  } = goal || {};
  const weeklyGraph = {
    isSetDailyGoal,
    userDailyGoal,
    weekNumber,
    weeklyGraphData
  };
  return weeklyGraph;
};

export const isUserAnonymous = (user: Object) => {
  if (user.google || user.facebook || user.apple || user.email) {
    return false;
  }
  return true;
};

export const getUnitProgressLingTwoUser = (
  user: Object,
  targetLanguageCode: string,
  unitNumber: number,
  progress: number
) => {
  const unitProgress = _.get(
    user,
    `${targetLanguageCode}.progress.${unitNumber}.lessons`,
    {}
  );
  const progressHandler = _.size(_.filter(unitProgress, unit => unit));
  const currentProgress = _.isNil(progress)
    ? progressHandler
    : _.gte(progressHandler, progress)
    ? progressHandler
    : progress;
  return currentProgress;
};
