import { Box, Typography } from '@mui/material';
import React from 'react';
import { IMAGE_PATH } from '../../../common/constants';
import StartButton from './subComponents/StartButton';
import { getReasonsData } from './data/paywallData';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const Reasons = ({ initiateBuy, labels }) => {
  const { txt_why_coming_back_to_Ling, btn_start_learning } = labels;
  const imgPath = image => `${IMAGE_PATH}/onboarding/${image}`;
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      mt: 40,
      width: '100%'
    },
    headerText: {
      fontSize: '30px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '36px'
      }
    },
    headerContainer: {
      textAlign: 'center',
      width: '90%'
    },
    subContainer: {
      width: '80%',
      mt: 20,
      display: 'flex',
      justifyContent: 'space-around',
      px: 37,
      flexWrap: 'wrap',
      [theme.breakpoints.up('sm')]: {
        flexWrap: 'nowrap'
      }
    },
    phoneImg: {
      width: '205px',
      height: '444px',
      [theme.breakpoints.down('sm')]: {
        mb: 12
      }
    },
    reasonContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      height: '350px',
      pt: 12,
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-evenly',
        pt: 0,
        mt: 0,
        gap: 4
      }
    },
    reasonBox: {
      display: 'flex',
      width: '360px',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-evenly'
      }
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      mr: 4,
      height: '230px',
      [theme.breakpoints.down('sm')]: {
        height: 'auto'
      }
    },
    reasonHeader: {
      fontSize: '24px',
      fontWeight: 600,
      pb: 3
    },
    left: {
      textAlign: 'right'
    },
    right: {
      textAlign: 'left'
    },
    icon: {
      width: '49px',
      height: '49px',
      ml: 2,
      mr: 6,
      [theme.breakpoints.up('sm')]: {
        mx: 6
      }
    },
    buttonStyle: {
      width: '218px',
      mt: 4
    }
  };

  const renderReasons = (data, side) => {
    return (
      <Box sx={styles.reasonBox}>
        {side === 'right' && (
          <Box component="img" src={data.icon} sx={styles.icon}></Box>
        )}
        <Box sx={styles.textContainer}>
          <Typography variant="text" sx={[styles.reasonHeader, styles[side]]}>
            {data.header}
          </Typography>
          <Typography variant="subtext" sx={styles[side]}>
            {data.subtext}
          </Typography>
        </Box>
        {side === 'left' && (
          <Box component="img" src={data.icon} sx={styles.icon}></Box>
        )}
      </Box>
    );
  };

  const reasonsData = getReasonsData(labels);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.headerContainer}>
        <Typography variant="text" sx={styles.headerText}>
          {txt_why_coming_back_to_Ling}
        </Typography>
      </Box>
      <Box sx={styles.subContainer}>
        {!isLargeScreen && (
          <Box
            component="img"
            src={imgPath('engaging-activities.png')}
            sx={styles.phoneImg}
          ></Box>
        )}
        <Box sx={styles.reasonContainer}>
          {renderReasons(reasonsData[0], isLargeScreen ? 'left' : 'right')}
          {renderReasons(reasonsData[1], isLargeScreen ? 'left' : 'right')}
        </Box>
        {isLargeScreen && (
          <Box
            component="img"
            src={imgPath('engaging-activities.png')}
            sx={styles.phoneImg}
          ></Box>
        )}
        <Box sx={styles.reasonContainer}>
          {renderReasons(reasonsData[2], 'right')}
          {renderReasons(reasonsData[3], 'right')}
        </Box>
      </Box>
      <StartButton
        buttonStyle={styles.buttonStyle}
        text={btn_start_learning}
        buttonAction={initiateBuy}
      />
    </Box>
  );
};

export default Reasons;

Reasons.propTypes = {
  initiateBuy: PropTypes.func
};
