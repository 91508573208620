/* @flow */

import { call, put } from 'redux-saga/effects';
import { loadFirebasePromotion as saveFirebasePromotion } from '../../shop/actions';
import BugTracker from '../../util/BugTracker';

import { fetchFirebasePromotion } from '../../lessons/sagas';
export function* loadFirebasePromotion(): any {
  let promotions = {};
  try {
    promotions = (yield call(fetchFirebasePromotion)).val();
  } catch (error) {
    BugTracker.notify(error);
    console.log(error);
  }
  yield put(saveFirebasePromotion(promotions));
}
