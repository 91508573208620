/* @flow */
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import { firebase } from '../system/Firebase';
import { findCurrency, getCurrency, getLocalStorage, setLocalStorage } from '.';
import { getUTM } from './UtmHelper';
import {
  PRODUCT_TYPE,
  STRIPE_MONTHY,
  STRIPE_YEARLY,
  STRIPE_LIFETIME
} from '../shop/constants';
import {
  CURRENCY,
  PAST_DUE,
  PROMO_CODE_PATH,
  STRIPE_INTERVAL_MONTH,
  STRIPE_PRODUCT_LIFETIME_0_PRICE,
  STRIPE_PRODUCT_MONTHLY_0_PRICE,
  STRIPE_PRODUCT_YEARLY_0_PRICE
} from '../common/constants';
import BugTracker from './BugTracker';
export const getStripeOneTimeProductId = (type: string, currency: string) => {
  const stripeOneTimeProductId = {
    [PRODUCT_TYPE.yearly]: {
      [CURRENCY.USD]:
        process.env.REACT_APP_STRIPE_YEARLY_ONE_TIME_USD_NEW_PRICE,
      [CURRENCY.EUR]:
        process.env.REACT_APP_STRIPE_YEARLY_ONE_TIME_EUR_NEW_PRICE,
      [CURRENCY.GBP]:
        process.env.REACT_APP_STRIPE_YEARLY_ONE_TIME_POUND_NEW_PRICE,
      [CURRENCY.THB]:
        process.env.REACT_APP_STRIPE_YEARLY_ONE_TIME_THB_NEW_PRICE,
      [CURRENCY.AUD]:
        process.env.REACT_APP_STRIPE_YEARLY_ONE_TIME_ASD_NEW_PRICE,
      [CURRENCY.RUB]: process.env.REACT_APP_STRIPE_YEARLY_ONE_TIME_RUB_NEW_PRICE
    },
    [PRODUCT_TYPE.monthly]: {
      [CURRENCY.USD]: process.env.REACT_APP_STRIPE_MONTHLY_ONE_TIME_USD,
      [CURRENCY.EUR]: process.env.REACT_APP_STRIPE_MONTHLY_ONE_TIME_EUR,
      [CURRENCY.GBP]: process.env.REACT_APP_STRIPE_MONTHLY_ONE_TIME_POUND,
      [CURRENCY.THB]: process.env.REACT_APP_STRIPE_MONTHLY_ONE_TIME_THB,
      [CURRENCY.AUD]: process.env.REACT_APP_STRIPE_MONTHLY_ONE_TIME_ASD,
      [CURRENCY.RUB]: process.env.REACT_APP_STRIPE_MONTHLY_ONE_TIME_RUB
    },
    [PRODUCT_TYPE.lifetime]: {
      [CURRENCY.USD]: process.env.REACT_APP_STRIPE_LIFETIME,
      [CURRENCY.EUR]: process.env.REACT_APP_STRIPE_LIFETIME_EUR,
      [CURRENCY.GBP]: process.env.REACT_APP_STRIPE_LIFETIME_POUND,
      [CURRENCY.THB]: process.env.REACT_APP_STRIPE_LIFETIME_THB,
      [CURRENCY.AUD]: process.env.REACT_APP_STRIPE_LIFETIME_ASD,
      [CURRENCY.RUB]: process.env.REACT_APP_STRIPE_LIFETIME_RUB
    }
  };
  return stripeOneTimeProductId[type][currency];
};

export const getStripeDiscountProductId = (
  type: string,
  discountPercent: number,
  currency: string,
  stateChangeHandler: Function
) => {
  const stripeDiscountMonthlyProductId = {
    '0': {
      [CURRENCY.USD]: process.env.REACT_APP_STRIPE_MONTHLY_USD_NEW,
      [CURRENCY.EUR]: process.env.REACT_APP_STRIPE_MONTHLY_EUR_NEW,
      [CURRENCY.GBP]: process.env.REACT_APP_STRIPE_MONTHLY_GBP_NEW,
      [CURRENCY.THB]: process.env.REACT_APP_STRIPE_MONTHLY_THB_NEW,
      [CURRENCY.AUD]: process.env.REACT_APP_STRIPE_MONTHLY_ASD_NEW,
      [CURRENCY.RUB]: process.env.REACT_APP_STRIPE_MONTHLY_RUB_NEW
    },
    '20': {
      [CURRENCY.USD]: process.env.REACT_APP_STRIPE_PROMO_MONTHLY_20_USD,
      [CURRENCY.EUR]: process.env.REACT_APP_STRIPE_PROMO_MONTHLY_20_EUR,
      [CURRENCY.GBP]: process.env.REACT_APP_STRIPE_PROMO_MONTHLY_20_POUND,
      [CURRENCY.THB]: process.env.REACT_APP_STRIPE_PROMO_MONTHLY_20_THB,
      [CURRENCY.AUD]: process.env.REACT_APP_STRIPE_PROMO_MONTHLY_20_ASD,
      [CURRENCY.RUB]: process.env.REACT_APP_STRIPE_PROMO_MONTHLY_20_RUB
    },
    '30': {
      [CURRENCY.USD]: process.env.REACT_APP_STRIPE_PROMO_MONTHLY_30_USD,
      [CURRENCY.EUR]: process.env.REACT_APP_STRIPE_PROMO_MONTHLY_30_EUR,
      [CURRENCY.GBP]: process.env.REACT_APP_STRIPE_PROMO_MONTHLY_30_POUND,
      [CURRENCY.THB]: process.env.REACT_APP_STRIPE_PROMO_MONTHLY_30_THB,
      [CURRENCY.AUD]: process.env.REACT_APP_STRIPE_PROMO_MONTHLY_30_ASD,
      [CURRENCY.RUB]: process.env.REACT_APP_STRIPE_PROMO_MONTHLY_30_RUB
    }
  };

  const stripeDiscountYearlyProductId = {
    '0': {
      [CURRENCY.USD]: process.env.REACT_APP_STRIPE_YEARLY_USD,
      [CURRENCY.EUR]: process.env.REACT_APP_STRIPE_YEARLY_EUR,
      [CURRENCY.GBP]: process.env.REACT_APP_STRIPE_YEARLY_GBP,
      [CURRENCY.THB]: process.env.REACT_APP_STRIPE_YEARLY_THB,
      [CURRENCY.AUD]: process.env.REACT_APP_STRIPE_YEARLY_ASD,
      [CURRENCY.RUB]: process.env.REACT_APP_STRIPE_YEARLY_RUB
    },
    '20': {
      [CURRENCY.USD]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_20_USD,
      [CURRENCY.EUR]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_20_EUR,
      [CURRENCY.GBP]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_20_POUND,
      [CURRENCY.THB]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_20_THB,
      [CURRENCY.AUD]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_20_ASD,
      [CURRENCY.RUB]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_20_RUB
    },
    '25': {
      [CURRENCY.USD]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_25_USD,
      [CURRENCY.EUR]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_25_EUR,
      [CURRENCY.GBP]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_25_POUND,
      [CURRENCY.THB]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_25_THB,
      [CURRENCY.AUD]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_25_ASD,
      [CURRENCY.RUB]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_25_RUB
    },

    '30': {
      [CURRENCY.USD]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_30_USD,
      [CURRENCY.EUR]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_30_EUR,
      [CURRENCY.GBP]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_30_POUND,
      [CURRENCY.THB]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_30_THB,
      [CURRENCY.AUD]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_30_ASD,
      [CURRENCY.RUB]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_30_RUB
    },
    '40': {
      [CURRENCY.USD]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_40_USD,
      [CURRENCY.EUR]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_40_EUR,
      [CURRENCY.GBP]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_40_POUND,
      [CURRENCY.THB]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_40_THB,
      [CURRENCY.AUD]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_40_ASD,
      [CURRENCY.RUB]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_40_RUB
    },
    '50': {
      [CURRENCY.USD]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_50_USD,
      [CURRENCY.EUR]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_50_EUR,
      [CURRENCY.GBP]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_50_POUND,
      [CURRENCY.THB]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_50_THB,
      [CURRENCY.AUD]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_50_ASD,
      [CURRENCY.RUB]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_50_RUB
    }
  };

  const stripeDiscountLifetimeProductId = {
    '0': {
      [CURRENCY.USD]: process.env.REACT_APP_STRIPE_LIFETIME,
      [CURRENCY.EUR]: process.env.REACT_APP_STRIPE_LIFETIME_EUR,
      [CURRENCY.GBP]: process.env.REACT_APP_STRIPE_LIFETIME_POUND,
      [CURRENCY.THB]: process.env.REACT_APP_STRIPE_LIFETIME_THB,
      [CURRENCY.AUD]: process.env.REACT_APP_STRIPE_LIFETIME_ASD,
      [CURRENCY.RUB]: process.env.REACT_APP_STRIPE_LIFETIME_RUB
    },
    '20': {
      [CURRENCY.USD]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_20_USD,
      [CURRENCY.EUR]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_20_EUR,
      [CURRENCY.GBP]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_20_POUND,
      [CURRENCY.THB]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_20_THB,
      [CURRENCY.AUD]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_20_ASD,
      [CURRENCY.RUB]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_20_RUB
    },
    '25': {
      [CURRENCY.USD]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_25_USD,
      [CURRENCY.EUR]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_25_EUR,
      [CURRENCY.GBP]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_25_POUND,
      [CURRENCY.THB]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_25_THB,
      [CURRENCY.AUD]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_25_ASD,
      [CURRENCY.RUB]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_25RUB
    },
    '30': {
      [CURRENCY.USD]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_30_USD,
      [CURRENCY.EUR]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_30_EUR,
      [CURRENCY.GBP]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_30_POUND,
      [CURRENCY.THB]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_30_THB,
      [CURRENCY.AUD]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_30_ASD,
      [CURRENCY.RUB]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_30_RUB
    },
    '50': {
      [CURRENCY.USD]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_50_USD,
      [CURRENCY.EUR]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_50_EUR,
      [CURRENCY.GBP]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_50_POUND,
      [CURRENCY.THB]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_50_THB,
      [CURRENCY.AUD]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_50_ASD,
      [CURRENCY.RUB]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_50_RUB
    }
  };

  stateChangeHandler(
    'packageName',
    type === PRODUCT_TYPE.yearly
      ? STRIPE_YEARLY
      : type === PRODUCT_TYPE.monthly
      ? STRIPE_MONTHY
      : STRIPE_LIFETIME
  );
  return type === PRODUCT_TYPE.yearly
    ? stripeDiscountYearlyProductId[discountPercent.toString()][currency]
    : type === PRODUCT_TYPE.monthly
    ? stripeDiscountMonthlyProductId[discountPercent.toString()][currency]
    : stripeDiscountLifetimeProductId[discountPercent.toString()][currency];
};

export const getStripeProductId = (
  currency: string,
  productName: string,
  user: Object,
  promoId?: string
) => {
  // const isTrialUsedIP = user.trialUsedIP !== '';
  //NOTE: request from marketing team to show only yearly with trial
  const isTrialUsedIP = false;
  const stripeMonthlyProductId = {
    [CURRENCY.USD]: process.env.REACT_APP_STRIPE_MONTHLY_USD_NEW,
    [CURRENCY.EUR]: process.env.REACT_APP_STRIPE_MONTHLY_EUR_NEW,
    [CURRENCY.GBP]: process.env.REACT_APP_STRIPE_MONTHLY_GBP_NEW,
    [CURRENCY.THB]: process.env.REACT_APP_STRIPE_MONTHLY_THB_NEW,
    [CURRENCY.AUD]: process.env.REACT_APP_STRIPE_MONTHLY_ASD_NEW,
    [CURRENCY.RUB]: process.env.REACT_APP_STRIPE_MONTHLY_RUB_NEW
  };

  const stripeMonthlyProductId0Price = {
    [CURRENCY.USD]: process.env.REACT_APP_STRIPE_PROMO_MONTHY_100_USD
  };

  const stripeYearlyWithoutTrialProductId = {
    [CURRENCY.USD]: process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_USD,
    [CURRENCY.EUR]: process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_EUR,
    [CURRENCY.GBP]: process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_POUND,
    [CURRENCY.THB]: process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_THB,
    [CURRENCY.AUD]: process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_ASD,
    [CURRENCY.RUB]: process.env.REACT_APP_STRIPE_YEARLY_WITHOUT_TRIAL_RUB
  };
  const stripeYearlyProductId = {
    [CURRENCY.USD]: process.env.REACT_APP_STRIPE_YEARLY_USD,
    [CURRENCY.EUR]: process.env.REACT_APP_STRIPE_YEARLY_EUR,
    [CURRENCY.GBP]: process.env.REACT_APP_STRIPE_YEARLY_GBP,
    [CURRENCY.THB]: process.env.REACT_APP_STRIPE_YEARLY_THB,
    [CURRENCY.AUD]: process.env.REACT_APP_STRIPE_YEARLY_ASD,
    [CURRENCY.RUB]: process.env.REACT_APP_STRIPE_YEARLY_RUB
  };

  const stripeYearlyProductId0Price = {
    [CURRENCY.USD]: process.env.REACT_APP_STRIPE_PROMO_YEARLY_100_USD
  };

  const stripeLifetimeProductId = {
    [CURRENCY.USD]: process.env.REACT_APP_STRIPE_LIFETIME,
    [CURRENCY.EUR]: process.env.REACT_APP_STRIPE_LIFETIME_EUR,
    [CURRENCY.GBP]: process.env.REACT_APP_STRIPE_LIFETIME_POUND,
    [CURRENCY.THB]: process.env.REACT_APP_STRIPE_LIFETIME_THB,
    [CURRENCY.AUD]: process.env.REACT_APP_STRIPE_LIFETIME_ASD,
    [CURRENCY.RUB]: process.env.REACT_APP_STRIPE_LIFETIME_RUB
  };

  const stripeLifetimeProductId0Price = {
    [CURRENCY.USD]: process.env.REACT_APP_STRIPE_PROMO_LIFETIME_100_USD
  };

  return productName === PRODUCT_TYPE.monthly
    ? stripeMonthlyProductId[currency] || stripeMonthlyProductId[CURRENCY.USD]
    : productName === STRIPE_PRODUCT_MONTHLY_0_PRICE
    ? stripeMonthlyProductId0Price[currency] ||
      stripeMonthlyProductId0Price[CURRENCY.USD]
    : productName === PRODUCT_TYPE.yearly
    ? isTrialUsedIP
      ? stripeYearlyWithoutTrialProductId[currency] ||
        stripeYearlyWithoutTrialProductId[CURRENCY.USD]
      : productName === STRIPE_PRODUCT_YEARLY_0_PRICE
      ? stripeYearlyProductId0Price[currency] ||
        stripeYearlyProductId0Price[CURRENCY.USD]
      : stripeYearlyProductId[currency] || stripeYearlyProductId[CURRENCY.USD]
    : productName === STRIPE_PRODUCT_YEARLY_0_PRICE
    ? stripeYearlyProductId0Price[currency] ||
      stripeYearlyProductId0Price[CURRENCY.USD]
    : productName === PRODUCT_TYPE.lifetime
    ? stripeLifetimeProductId[currency] || stripeLifetimeProductId[CURRENCY.USD]
    : productName === STRIPE_PRODUCT_LIFETIME_0_PRICE
    ? stripeLifetimeProductId0Price[currency] ||
      stripeLifetimeProductId0Price[CURRENCY.USD]
    : promoId;
};

export const getProductInfo = (
  functions: string,
  productId: string,
  stateChangeHandler: Function
) => {
  const idType =
    productId.split('_').shift() === 'prod'
      ? 'product'
      : productId.split('_').shift() === 'price'
      ? 'price'
      : productId.split('_').shift() === 'plan'
      ? 'plan'
      : 'sku';

  // eslint-disable-next-line
  localStorage.setItem('category', 'promotion');
  // eslint-disable-next-line
  localStorage.setItem('productId', productId);
  axios
    .get(
      `${functions}/validateLingWeb-stripeApi/products/${idType}/${productId}`
    )
    .then(async res => {
      if (res.data) {
        if (_.isUndefined(res.data.unit_amount)) {
          stateChangeHandler(
            'price',
            findCurrency(
              (res.data.currency
                ? res.data.currency
                : CURRENCY.USD
              ).toUpperCase()
            ).symbol +
              res.data.amount / 100
          );
          const rateDate = await axios.get(
            `https://api.exchangerate.host/latest?base==${res.data.currency.toUpperCase()}`
          );
          const rate = rateDate.data.rates['USD'];
          const convertedAmount = (res.data.amount / 100) * rate;
          // eslint-disable-next-line
          localStorage.setItem(
            'revenue',
            //$FlowFixMe
            res.data.currency === CURRENCY.USD
              ? res.data.amount / 100
              : convertedAmount
          );
        } else {
          stateChangeHandler(
            'price',
            findCurrency(
              (res.data.currency
                ? res.data.currency
                : CURRENCY.USD
              ).toUpperCase()
            ).symbol +
              res.data.unit_amount / 100
          );
          const rateDate = await axios.get(
            `https://api.exchangerate.host/latest?base==${res.data.currency.toUpperCase()}`
          );
          const rate = rateDate.data.rates['USD'];
          const convertedAmount = (res.data.unit_amount / 100) * rate;
          // eslint-disable-next-line
          localStorage.setItem(
            'revenue',
            //$FlowFixMe
            res.data.currency === CURRENCY.USD
              ? res.data.unit_amount / 100
              : convertedAmount
          );
        }
      }
    })
    // eslint-disable-next-line handle-callback-err
    .catch(err => BugTracker.notify(err));
};

export const getProductTypeLabel = (
  productName: string,
  shop_txt_month: string,
  shop_txt_year: string,
  purchase_txt_option_lifeTime: string
) =>
  productName === STRIPE_MONTHY
    ? '1 ' + shop_txt_month
    : productName === STRIPE_YEARLY
    ? '1 ' + shop_txt_year
    : purchase_txt_option_lifeTime;

export const getStripeProductPrice = (
  functions: string,
  user: Object,
  stateChangeHandler: Function
) => {
  const language = window.navigator.language;
  const currency = getCurrency(language);
  axios
    .get(`${functions}validateLingWeb-stripeApi/products/get`)
    .then(res => {
      if (res.data.code !== 400) {
        _.forEach(res.data.plans.data, value => {
          if (
            value.interval === 'year' &&
            value.currency.toUpperCase() ===
              (currency ? currency : 'usd').toUpperCase() &&
            _.includes([value.id], getStripeProductId(currency, 'yearly', user))
          ) {
            stateChangeHandler('yearPrice', value.amount);
          } else if (
            value.interval === 'month' &&
            value.currency.toUpperCase() ===
              (currency ? currency : 'usd').toUpperCase() &&
            _.includes(
              [value.id],
              getStripeProductId(currency, 'monthly', user)
            )
          ) {
            stateChangeHandler('monthPrice', value.amount);
          } else {
            if (
              value.interval === 'year' &&
              value.currency.toUpperCase() === CURRENCY.USD
            ) {
              stateChangeHandler('usdYearPrice', value.amount);
            } else if (
              value.interval === 'month' &&
              value.currency.toUpperCase() === CURRENCY.USD
            ) {
              stateChangeHandler('usdMonthPrice', value.amount);
            }
          }
        });
        _.forEach(res.data.skus.data, value => {
          if (!_.isEmpty(value)) {
            if (
              value.currency.toUpperCase() ===
                (currency ? currency : 'usd').toUpperCase() &&
              _.includes(
                [value.id],
                getStripeProductId(currency, 'lifetime', user)
              )
            ) {
              stateChangeHandler('lifeTimePrice', value.price);
            } else if (
              value.currency.toUpperCase() === CURRENCY.USD &&
              _.includes(
                [value.id],
                getStripeProductId(currency, 'lifetime', user)
              )
            ) {
              stateChangeHandler('usdLifeTimePrice', value.price);
            }
          }
        });
        stateChangeHandler('currency', currency);
        stateChangeHandler('currencySymbol', findCurrency(currency));
      }
    })
    .catch(error => {
      BugTracker.notify(error);
      console.log('error: do parsing', error);
    });
};

export const getUserSubscriptionText = (
  user: Object,
  subscriptionData: Object,
  currency: string,
  profile_txt_on: string,
  profile_txt_charged: string,
  profile_txt_for: string,
  profile_txt_of: string,
  profile_txt_1_year_ling_pro: string,
  profile_txt_1_month_ling_pro: string,
  profile_txt_past_due_msg: string
) => {
  const { subscription } = user;
  let planData =
    subscription && subscription.isPromo
      ? subscription.promoExpirationDate === 'lifetime'
        ? `Your subscription for Ling Premium Version is ${subscription.promoExpirationDate}`
        : `Your subscription for Ling Premium Version will end on  ${subscription.promoExpirationDate}`
      : subscriptionData.nextPayment === ''
      ? 'You are using Ling Lifetime Plan'
      : (subscriptionData.type === 'android' ? '' : 'Next payment date is') +
        `${subscriptionData.nextPayment}`;
  let periodEnd = _.get(subscriptionData, 'current_period_end', false);

  periodEnd = moment(periodEnd * 1000).format('YYYY.MM.DD,');
  if (subscriptionData.type === 'stripe') {
    if (
      subscriptionData.display_items &&
      subscriptionData.display_items[0].type === 'sku'
    ) {
      planData = 'You are using Ling Lifetime Plan';
    } else if (subscriptionData.status === PAST_DUE) {
      planData = profile_txt_past_due_msg;
    } else {
      const plan = subscriptionData.plan;
      const planDataDes = getPlanData(
        profile_txt_on,
        periodEnd,
        profile_txt_charged,
        profile_txt_for,
        plan.currency,
        plan.amount,
        plan.interval,
        profile_txt_1_month_ling_pro,
        profile_txt_1_year_ling_pro
      );
      planData = planDataDes.charAt(0).toUpperCase() + planDataDes.slice(1);
      console.log('PlanData', planData);
    }
  }
  return planData;
};

export const getStripeSessionId = async (
  functions: string,
  product: string = '',
  promoCode: string = '',
  isDev: boolean
) => {
  const language = window.navigator.language;
  const currency = getCurrency(language);
  const productId = getStripeProductId(currency, product, {
    trialUsedIP: 'used'
  });
  //NOTE: this data will be used in data layer
  setLocalStorage('promoName', 'Purchase');
  setLocalStorage('productId', productId);
  setLocalStorage('revenue', 0);
  setLocalStorage('category', 'purchased');
  const hostName = getLocalStorage('hostName');
  const { utm_source, utm_medium, utm_campaign } = getUTM();
  const { promoCodeId = '' } = await getStripePromoCodeId(promoCode);
  return axios
    .post(`${functions}validateLingWeb-stripeApi/sessionsWithPromo`, {
      isDev,
      product: product,
      promoCode: promoCodeId,
      coupon: promoCode,
      productId: productId,
      utm_source,
      utm_medium,
      utm_campaign,
      hostName
    })
    .then(response => response.data);
};

export const getStripePromoCodeId = (promoCode: string) => {
  return firebase
    .database()
    .ref(`${PROMO_CODE_PATH}/${promoCode}`)
    .once('value')
    .then(snapshot => {
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        BugTracker.notify('promotion code doesnt exist');
        return 'promotion code doesnt exist';
      }
    });
};

export const getPlanData = (
  profile_txt_on: string,
  periodEnd: string,
  profile_txt_charged: string,
  profile_txt_for: string,
  currency: string,
  amount: number,
  interval: string,
  profile_txt_1_month_ling_pro: string,
  profile_txt_1_year_ling_pro: string
) => {
  return `${profile_txt_on} ${periodEnd} ${profile_txt_charged} ${currency.toUpperCase()}${amount /
    100} ${
    interval === STRIPE_INTERVAL_MONTH
      ? profile_txt_1_month_ling_pro
      : profile_txt_1_year_ling_pro
  }`;
};
