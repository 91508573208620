import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IMAGE_PATH } from '../../../common/constants';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { handleOutsideRefClick } from '../../utils/handleOutsideRefClick';

const NativeLanguageSelector = ({
  dropDownSearch,
  native,
  nativeLanguages = [],
  getLanguageLabel,
  getLanguageNativeLang,
  handleNativeLanguageSelect,
  labels
}) => {
  const { iSpeak_txt_title } = labels;

  let previousLanguages = [];
  const languages = (nativeLanguages || []).filter(language =>
    getLanguageLabel(language.langCode)
      .toLowerCase()
      .includes(dropDownSearch.toLowerCase())
  );

  if (languages.length !== 0) {
    previousLanguages = languages;
  }
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const theme = useTheme();
  const imgPath = langCode => `${IMAGE_PATH}/onboarding/flags/${langCode}`;

  // TODO: LOCALIZATION - change this to count characters instead after localization
  const longLanguages = ['in', 'ru', 'sl', 'uk'];

  const selectLanguage = e => {
    handleNativeLanguageSelect(e.currentTarget.id);
    setDropDownOpen(false);
  };

  const ref = useRef(null);

  useEffect(() => {
    const listener = handleOutsideRefClick(ref, () => setDropDownOpen(false));
    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };
  }, []);

  const styles = {
    main: { display: 'flex', flexDirection: 'column', alignItems: 'flex-end' },
    container: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      pr: 4 // Allow user to click nearby, until proper design update
    },
    labelTextContainer: {
      display: 'flex',
      flexWrap: longLanguages.includes(native) ? 'wrap' : 'nowrap'
    },
    flagIcon: { objectFit: 'contain', mx: 3, maxWidth: 45 },
    langText: {
      textWrap: 'nowrap',
      fontSize: 30,
      textAlign: 'left',
      [theme.breakpoints.up('sm')]: {
        fontSize: 36
      }
    },
    menuItem: { display: 'flex', py: 3, alignItems: 'center' },
    dropDownText: {
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: 16,
      textWrap: 'nowrap',
      textAlign: 'left'
    },
    checkContainer: {
      width: 100,
      display: 'flex',
      justifyContent: 'flex-end',
      mr: 2
    },
    arrowIcon: {
      fontSize: 30
    },
    dropDownContainer: {
      height: 400,
      width: 250,
      overflowY: 'auto',
      boxShadow: '0px 0px 6px 0px #00000014',
      borderRadius: 3,
      position: 'relative',
      backgroundColor: '#FFFFFF',
      zIndex: 2,
      cursor: 'pointer'
    },
    lightText: { color: '#B3B3B3' },
    hidden: {
      display: 'none'
    }
  };
  // $flow-disable-line
  return (
    <Box sx={styles.main}>
      <Box
        ref={ref}
        sx={styles.container}
        onClick={() => setDropDownOpen(!dropDownOpen)}
      >
        <Box sx={styles.labelTextContainer}>
          <Typography variant="text" sx={[styles.langText, styles.lightText]}>
            {iSpeak_txt_title}
          </Typography>
          <Box
            component="img"
            sx={styles.flagIcon}
            src={imgPath(`${native}-flag.png`)}
          />
          <Typography variant="text" sx={styles.langText}>
            {getLanguageNativeLang(native)}
          </Typography>
        </Box>
        <Box>
          {dropDownOpen ? (
            <ArrowDropUpIcon sx={styles.arrowIcon} />
          ) : (
            <ArrowDropDownIcon sx={styles.arrowIcon} />
          )}
        </Box>
      </Box>
      <Box sx={[styles.dropDownContainer, !dropDownOpen && styles.hidden]}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {previousLanguages.map(language => {
            const { langCode } = language;
            return (
              <Box
                onClick={selectLanguage}
                key={langCode}
                id={langCode}
                sx={styles.menuItem}
              >
                <Box
                  component="img"
                  sx={styles.flagIcon}
                  src={imgPath(`${langCode}-flag.png`)}
                  alt={`${langCode.toUpperCase()} Flag`}
                  id={langCode}
                />
                <Typography
                  id={langCode}
                  variant="text"
                  sx={[
                    styles.dropDownText,
                    { fontWeight: native === langCode ? 700 : 400 }
                  ]}
                >
                  {getLanguageNativeLang(langCode)}
                </Typography>
                {native === langCode && (
                  <Box sx={styles.checkContainer}>
                    <Box component="img" src={imgPath('orange-check.png ')} />
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default NativeLanguageSelector;
