/* @flow */
export const RELEASE_STAGE_DEV: string = 'development';
export const RELEASE_STAGE_SCREENSHOTS: string = 'screenshots';
export const RELEASE_STAGE_STAGING: string = 'staging';
export const RELEASE_STAGE_PROD: string = 'production';
export const LOAD_GLOBAL_CONFIG: string = 'LOAD_GLOBAL_CONFIG';
export const LOAD_BUNDLE_FEATURE: string = 'LOAD_BUNDLE_FEATURE';
export const STRIPE: string = 'stripe';
export const PURGE_VERSION: number = 0;
export const CHATBOTS_PADDING: number = 100;
export const REVIEW_CONTENT_TOP_MAGGIN: number = 110;
export const TOP_MENU_OFFSET: number = 70;
export const RESET: string = 'RESET';
export const OFFER: string = 'offer';
export const REDEEM: string = 'redeem';
export const IS_SHOW_INVITE_FRIEND: string = 'isShowInviteFriend';
export const USED_INDIA_FLAG: Array<string> = [
  'hi',
  'kn',
  'ml',
  'pu',
  'te',
  'gu',
  'mr'
];
export const SYMBOL_LANGUAGE: Array<string> = [
  'th',
  'km',
  'lo',
  'ja',
  'zh',
  'ko',
  'ru',
  'ar',
  'hi',
  'ne',
  'ur',
  'bn',
  'fa',
  'ta',
  'ka',
  'te',
  'my',
  'he',
  'ml',
  'pa',
  'pu',
  'yue',
  'kn',
  'hy'
];
export const WEEK_DAYS_DATA: Array<Object> = [
  {
    day: 'M',
    dayShort: 'Mon',
    id: 0,
    isChecked: true
  },
  {
    day: 'T',
    dayShort: 'Tue',
    id: 1,
    isChecked: true
  },
  {
    day: 'W',
    dayShort: 'Wed',
    id: 2,
    isChecked: true
  },
  {
    day: 'T',
    dayShort: 'Thu',
    id: 3,
    isChecked: true
  },
  {
    day: 'F',
    dayShort: 'Fri',
    id: 4,
    isChecked: true
  },
  {
    day: 'S',
    dayShort: 'Sat',
    id: 5,
    isChecked: true
  },
  {
    day: 'S',
    dayShort: 'Sun',
    id: 6,
    isChecked: true
  }
];

export const CURRENCY: Object = {
  USD: 'USD',
  EUR: 'EUR',
  THB: 'THB',
  GBP: 'GBP',
  RUB: 'RUB',
  AUD: 'AUD',
  JPY: 'JPY',
  KRW: 'KRW'
};

export const MAX_LEADEERBOARD_ID: number = 5;
export const NUMBER_TO_MAKE_INDEX_ID: number = 1;
export const ID_FOR_UNKNOWN_RANK: number = 0;
export const NUMBER_DURATION_SUBSCRIPTION: number = 1;
export const SLASH_SIGN: string = '/';
export const STRIPE_POWER_BY_TXT: string = 'Powered by';
export const STRIPE_TERMS_TXT: string = 'Terms';
export const STRIPE_PRIVACY_TXT: string = 'Privacy';
export const PRO_TXT: string = 'Pro';
export const LING_TXT: string = 'Ling';
export const PERCENT_SIGN: string = '%';
export const SHARP_SIGN: string = '#';
export const BANANA_POINT_SYMBOL: string = 'Bp';
export const PLUS_SIGN_SYMBOL: string = '+';
export const MULTI_BY_1_TXT: string = 'x 1';
export const COLON_SIGN: string = ':';
export const PROGRESS_PATH = '/progress/';
export const PROMO_CODE_PATH = '/promoCode/';
export const PRODUCT_TYPE_COLUMN_NUMBER = 4;
export const RATE_STARS_NUMBER = 5;
export const DEFAULT_YEARLY_PRICE = 7999;
export const CAROUSEL_SPEED = 10000;
export const STRIPE_INTERVAL_MONTH = 'month';
export const STRIPE_INTERVAL_YEAR = 'year';
export const STRIPE_PRODUCT_MONTHLY_0_PRICE = 'monthly0';
export const STRIPE_PRODUCT_YEARLY_0_PRICE = 'yearly0';
export const STRIPE_PRODUCT_LIFETIME_0_PRICE = 'lifetime0';
export const LING_WEB_BASE_URL = 'https://play.ling-app.com';
export const DISCOUNT = 'discount';
//NOTE:this code is used by analytic team to get full discount for testing
export const FULL_DISCOUNT_ENCRYPT_CODE =
  'm5WpcCN9rMYiA1q0whblCWgnys7Ea3eFtuPwZcVaZ';
export const PROMO_NAME_LIST = [
  '7daytrial',
  '7daytrialserbian',
  '7daytriallithuanian',
  '7daytrialalbanian',
  '7daytrialthai',
  '7daytrialmalaysian'
];
export const UTM_CAMPAIGN_USERS_CANCELED: string =
  'ling-offer-users-canceled-subscription';

//Stripe Status
export const PAST_DUE: string = 'past_due';
