import * as OnboardActions from '../filterConstants';
import axios from 'axios';
import { findCurrency, getCurrency } from '../../util';
import {
  availableCurrencies,
  getCurrencyPriceIds,
  priceWithDecimal
} from '../components/PaywallComponents/paywallHelpers';

export function changeCampaign(campaignId) {
  console.log(`change campaign? ${campaignId}`);
  return {
    type: OnboardActions.ChangeCampaign,
    campaignId: parseInt(campaignId, 2)
  };
}

export function changeActiveScreen(screenId) {
  return { type: OnboardActions.ChangeScreen, screenId };
}

export function resetCampaign() {
  return { type: OnboardActions.ResetCampaign };
}

export function redirectShop() {
  return { type: OnboardActions.RedirectShop };
}

export function updateSelections(
  campaignId,
  selectionObject,
  value,
  reset = false
) {
  return {
    type: OnboardActions.SetSelection,
    campaignId,
    reset,
    selectionObject,
    value
  };
}

export function completeCampaign(campaignId) {
  return { type: OnboardActions.CompleteCampaign, campaignId };
}

export const setMonthlyPrices = price => {
  return { type: OnboardActions.SetMonthlyPrice, payload: price };
};

export const setYearlyPrices = price => {
  return { type: OnboardActions.SetYearlyPrice, payload: price };
};

export const setQuarterlyPrices = price => {
  return { type: OnboardActions.SetQuarterlyPrice, payload: price };
};

export const setMonthlyPricesStripeUk = price => {
  return { type: OnboardActions.SetMonthlyPriceStripeUk, payload: price };
};

export const setQuarterlyPricesStripeUk = price => {
  return { type: OnboardActions.SetQuarterlyPriceStripeUk, payload: price };
};

export const setYearlyPricesStripeUk = price => {
  return { type: OnboardActions.SetYearlyPriceStripeUk, payload: price };
};

export const fetchStripeProductDetails = () => {
  const language = window.navigator.language;
  const usdCurrency = 'USD';
  const userCurrency = getCurrency(language);

  return dispatch => {
    fetchPricesForStripeHk(
      dispatch,
      getCurrencyPriceIds(usdCurrency, false),
      usdCurrency
    );
    if (availableCurrencies.includes(userCurrency)) {
      fetchPricesForStripeHk(
        dispatch,
        getCurrencyPriceIds(userCurrency, false),
        userCurrency
      );
    }

    fetchPricesForStripeUk(dispatch, getCurrencyPriceIds(userCurrency, true));
    if (availableCurrencies.includes(userCurrency)) {
      fetchPricesForStripeUk(
        dispatch,
        getCurrencyPriceIds(userCurrency, true),
        userCurrency
      );
    }
  };
};

const dispatchPriceUpdate = (dispatch, data, actionCreator, currencySymbol) => {
  dispatch(
    actionCreator({
      price: priceWithDecimal(
        data.unit_amount_decimal,
        currencySymbol.decimalDigits,
        currencySymbol.decimalSeparator
      ),
      currency: data.currency,
      symbol: currencySymbol.symbol,
      priceId: data.id,
      decimalSeparator: currencySymbol.decimalSeparator,
      priceUnitAmount: data.unit_amount, // For existing Facebook Pixel tracking,
      productId: data.product,
      trialPeriodDays: data.recurring.trial_period_days ?? 0
    })
  );
};

const fetchPricesForStripeHk = (dispatch, priceTypes, currency) => {
  Promise.all(
    priceTypes.map(params => {
      return axios
        .get(
          `${process.env.REACT_APP_FUNCTIONS}/validateLingWeb-stripeApi/products/price/${params}`
        )
        .then(res => res)
        .catch(error => {
          console.error(error);
        });
    })
  )
    .then(([monthlyData, quarterlyData, yearlyData]) => {
      const currencySymbol = findCurrency(currency.toUpperCase());
      dispatchPriceUpdate(
        dispatch,
        monthlyData.data,
        setMonthlyPrices,
        currencySymbol
      );
      dispatchPriceUpdate(
        dispatch,
        quarterlyData.data,
        setQuarterlyPrices,
        currencySymbol
      );
      dispatchPriceUpdate(
        dispatch,
        yearlyData.data,
        setYearlyPrices,
        currencySymbol
      );
    })
    .catch(error => {
      console.error(error);
    });
};

const fetchPricesForStripeUk = async (dispatch, priceIds) => {
  const requestBody = { priceIds: priceIds };
  const response = await axios.post(
    `${process.env.REACT_APP_FUNCTIONS}/stripeUkApi/get-prices`,
    requestBody,
    {
      headers: {
        'x-auth': process.env.REACT_APP_FUNCTIONS_STRIPE_UK_AUTH_HEADER,
        'Content-Type': 'application/json'
      }
    }
  );

  const [monthly, quarterly, yearly] = response.data.prices;
  const { currency } = monthly;
  const currencySymbol = findCurrency(currency.toUpperCase());
  dispatchPriceUpdate(
    dispatch,
    monthly,
    setMonthlyPricesStripeUk,
    currencySymbol
  );
  dispatchPriceUpdate(
    dispatch,
    quarterly,
    setQuarterlyPricesStripeUk,
    currencySymbol
  );
  dispatchPriceUpdate(
    dispatch,
    yearly,
    setYearlyPricesStripeUk,
    currencySymbol
  );
};
