/* @flow */
// $FlowFixMe for useState
import React, { useState } from 'react';
// $FlowFixMe for useHistory
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  GREYISH_BROWN,
  PREFIX_HEADER,
  IMAGE_PATH,
  PREFIX_LESSON,
  LIGHT_RED_1,
  WHITE,
  DARK_YELLOW,
  MARIGOLD,
  GREY,
  DARK_GREY,
  SEA_BLUE,
  BLACK,
  GREY2,
  BLACK_OPACITY_SHADOW_COLOR
} from '../../../common/constants';

import { ModalBody, InputGroup } from 'reactstrap';
import {
  PROVIDER_FACEBOOK,
  PROVIDER_GOOGLE,
  PROVIDER_APPLE,
  SIGNIN_METHOD,
  PROVIDER_EMAIL
} from '../../../profile/constants';
import { isValidEmail } from '../../../common/actions';

const ResetPasswordContainer = styled.div`
  text-align: center;
`;
const ResetPasswordImg = styled.img`
  padding-top: 32px;
  padding-bottom: 16px;
`;
const ModalBodyText = styled.div`
  font-size: 18px;
  color: ${GREYISH_BROWN};
  line-height: 21px;
  margin-bottom: 13px !important;
`;
const ComfirmButton = styled.button`
  border-radius: 50px;
  margin-top: 20px;
  padding: 0px;
  border: none;
  height: 80px;
  width: 80px;
  & img {
    height: 80px;
    width: 80px;
  }
`;

const InputLabel = styled.label`
  color: ${props =>
    props.isError && props.errorMsg !== '' ? LIGHT_RED_1 : DARK_GREY};
`;

const ContinueContainer = styled.div`
  text-align: center;
`;

const InputGroupContainer = styled.div`
  text-align: left;
  border-color: ${GREY};
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
`;

const InputPlace = styled.input`
  width: 100%;
  border: none;
  outline: none;
  background-color: ${WHITE} !important;
`;
const LoginStyle = styled.button`
  text-transform: uppercase;
  margin-top: 26px;
  margin-bottom: 25px;
  border-radius: 20px;
  box-shadow: inset 0 -4px 0 0 ${DARK_YELLOW};
  background: ${MARIGOLD};
  border: ${DARK_YELLOW} 1px solid;
  font-size: 14px;
  width: 157px;
  height: 55px;
  padding: 0;
  color: ${WHITE};
  &:hover {
    opacity: 0.4;
    box-shadow: none;
  }
  &:active {
    opacity: 1;
    background-color: ${DARK_YELLOW};
    box-shadow: none;
  }
`;

const SocialButton = styled.button`
  img {
    width: 23px;
    height: 23px;
  }
  &:hover {
    opacity: 0.4;
  }
  &:active {
    opacity: 1;
  }
`;
const ErrorTextStyle = styled.span`
  font-size: 12px;
  text-align: left;
  padding-left: 14px;
  display: flex;
  padding-top: 5px;
  font-size: 14px;
  text-align: left;
  color: ${LIGHT_RED_1};
  font-style: italic;
`;

const InputErrorTextStyle = styled.span`
  font-size: 12px;
  text-align: left;
  padding-left: 0;
  display: flex;
  padding-top: 5px;
  font-size: 14px;
  text-align: left;
  color: ${LIGHT_RED_1};
  font-style: italic;
`;
const FormContainer = styled.div`
  width: 460px;
  @media (max-width: 468px) {
    width: auto;
  }
  height: auto;
  border-radius: 30px;
  background: ${WHITE};
  box-shadow: 0px 2px 20px ${BLACK_OPACITY_SHADOW_COLOR};
  z-index: 1;
`;
const BottomText = styled.div`
  margin-top: 13px;
  text-align: center;
`;
const SignUpSuggestionStyle = styled.span`
  font-size: 14px;
  font-weight: bold;
`;
const FormHeader = styled.h5`
  border-bottom: none;
  flex-direction: row-reverse;
  justifycontent: normal;
  padding: 29px 35px;
  @media (max-width: 468px) {
    padding: 29px 20px;
  }
  padding-bottom: 27px;
  text-transform: capitalize;
  text-align: left;

  font-size: 18px;
  font-weight: bold;
`;
const GiftFireworkStyle = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 0;
`;
const WarningText = styled.p`
  margin: 0px 35px 18px !important;
  font-size: 14px;
`;

const ModalContentContainer = styled.div`
  width: auto;
  margin: 0 38px;
  @media (max-width: 468px) {
    margin: 0 10px;
  }
`;

const ForgotTextStyle = styled.span`
  font-size: 14px;
  text-align: left;
  padding-left: 14px;
  font-weight: bold;
  padding-top: 0px;
  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
  &:active {
    opacity: 1;
  }
`;
const Container = styled.div`
  flex-direction: column;
  background-image: linear-gradient(163deg, #fbd249 24%, #f5a623 82%);
  width: 100vw;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 800px;
  padding-top: 30px;
  padding-bottom: 30px;
`;
const LoginButtonContainer = styled.div`
  text-align: center;
`;
const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
`;
const ModalBodyContainer = styled.div`
  display: block;
`;
const LoginLink = styled.span`
  &:hover {
    opacity: 0.4;
  }
`;
const Logo = styled.img`
  height: 100%;
`;
// const Title = styled.h2`
//   font-size: 26px;
//   text-align: left;
//   margin: 25px 0;
//   margin-left: 10px;
//   color: ${BLACK};
//   font-weight: bold;
// `;
const styles = {
  modalContainer: {
    width: '460px',
    height: '457px',
    textAlign: 'center',
    borderRadius: 30
  },
  modalBodyContainer: {
    padding: 0,
    paddingBottom: 36
  },
  buttonStyle: {
    borderRadius: 10,
    width: 160,
    height: 55,
    background: WHITE,
    border: 'solid 1px',
    borderColor: GREY2,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    boxShadowColor: GREY2,
    fontWeight: 'bold',
    fontSize: 18
  },
  cancelButtonStyle: {
    border: 'none',
    boxShadow: 'none',
    width: 100
  },
  confirmButtonStyle: {
    borderColor: GREY2,
    background: WHITE,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0'
  },
  modalHeader: {
    borderBottom: 'none',
    flexDirection: 'row-reverse',
    justifyContent: 'normal',
    padding: '29px 35px',
    paddingBottom: 27
  },
  modalFooter: {
    borderTop: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 36,
    paddingRight: 32,
    paddingLeft: 32
  },
  contentContainer: {
    borderRadius: 30
  },
  modalHeaderContainer: {
    'modal-title': 'w-100 text-center'
  },
  inputGroup: {
    textAlign: 'left',
    borderColor: GREY,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 20
  },

  inputContainer: {
    padding: '10px 15px 15px',
    width: 'inherit',
    fontSize: 14
  },
  labelStyle: {
    fontWeight: 'bold',
    marginBottom: 12,
    textTransform: 'capitalize'
  },

  appleIconStyle: {
    marginLeft: 0
  },
  googleIconStyle: {
    marginRight: 0
  },

  forgotTextStyle: {
    fontSize: 14,
    textAlign: 'left',
    paddingLeft: 14,
    fontWeight: 'bold',
    display: 'flex',
    paddingTop: 10
  },

  otherLoginButton: {
    borderRadius: 22,
    padding: 0,
    width: 80,
    height: 42,
    background: WHITE,
    color: BLACK,
    border: 'gray solid 1px',
    borderColor: GREY,
    borderWidth: 1,
    borderStyle: 'solid',
    margin: '0 6px',
    marginTop: 5
  },

  otherLoginGroup: {
    marginBottom: 25,
    textAlign: 'center'
  },
  signUpSuggestionStyle: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  signUpStyle: {
    color: SEA_BLUE,
    cursor: 'pointer'
  },
  space: {
    height: 12
  },
  loginFormSpaceBottom: {
    paddingBottom: 4
  },
  loadingStyle: {
    margin: '134px 38px',
    color: MARIGOLD
  }
};
const dangerIcon = `${PREFIX_HEADER}basecolor-rocket-circle-yellow-176-px.svg`;
const confirmIcon = `${PREFIX_HEADER}icon-24-circle.svg`;
const fbIcon = `${PREFIX_HEADER}f-logo-login.svg`;
const googleIcon = `${PREFIX_HEADER}g-logo-login.svg`;
const appleIcon = `${PREFIX_HEADER}Apple_logo_white.svg`;
const lingAppShadowIcon = `${IMAGE_PATH}lingAppShadowIcon.png`;
const giftFirework = `${PREFIX_LESSON}gift-firework.svg`;

const initialUserFormState = {
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  errorMsgFirebase: ''
};

const initialErrorMsgState = {
  isError: false,
  emailErrorMsg: '',
  passwordErrorMsg: '',
  formErrorMsg: ''
};
type Props = {
  reset_password_txt_description: string,
  login_field_email: string,
  btn_continue: string,
  login_field_name: string,
  login_field_firstName: string,
  login_field_password: string,
  login_msg_reenterPassword: string,
  at_least_6_password_txt: string,
  sign_up_txt_title: string,
  login_txt_title: string,
  login_txt_forget_password_title: string,
  login_txt_forget_password_web: string,
  already_register_txt: string,
  email_txt_loginNow_link: string,
  dont_have_account_yet_txt: string,
  claim_warning_txt: string,
  linkWithGoogleRedirect: Function,
  linkWithFbRedirect: Function,
  linkWithAppleRedirect: Function,
  sendPasswordResetEmail: Function,
  email_error_fieldsMissing: string,
  email_error_wrongFormat: string,
  email_error_matchPasswords: string,
  email_error_longerPassword: string,
  email_error_alreadyUsed: string,
  email_error_userNotFound: string,
  email_error_wrongPassword: string,
  sessionId: string,
  promoCode: string,
  type: string,
  signUpWithEmail: Function,
  signInWithEmailAndPassword: Function,
  txt_email_field_placeholder: string
};
const AuthForm = ({
  reset_password_txt_description,
  login_field_email,
  btn_continue,
  login_field_name,
  login_field_firstName,
  login_field_password,
  login_msg_reenterPassword,
  at_least_6_password_txt,
  sign_up_txt_title,
  login_txt_title,
  login_txt_forget_password_title,
  login_txt_forget_password_web,
  already_register_txt,
  email_txt_loginNow_link,
  dont_have_account_yet_txt,
  claim_warning_txt,
  linkWithGoogleRedirect,
  linkWithFbRedirect,
  linkWithAppleRedirect,
  sendPasswordResetEmail,
  email_error_fieldsMissing,
  email_error_wrongFormat,
  email_error_matchPasswords,
  email_error_longerPassword,
  email_error_alreadyUsed,
  email_error_userNotFound,
  signInWithEmailAndPassword,
  email_error_wrongPassword,
  type = '',
  signUpWithEmail,
  sessionId = '',
  promoCode = '',
  txt_email_field_placeholder
}: Props) => {
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isSignUp, setIsSignUp] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [successMsg, setSuccessMsg] = useState('');
  const [userForm, setUserForm] = useState(initialUserFormState);
  const [errorMsg, setErrorMsg] = useState(initialErrorMsgState);
  // eslint-disable-next-line no-unused-vars
  const [receipt, setReceipt] = useState('');

  const {
    isError,
    emailErrorMsg,
    passwordErrorMsg,
    formErrorMsg,
    errorMsgFirebase
  } = errorMsg;
  const {
    inputGroup,
    inputContainer,
    labelStyle,
    space,
    otherLoginGroup,
    otherLoginButton,
    googleIconStyle,
    appleIconStyle,
    modalBodyContainer,
    loginFormSpaceBottom,
    signUpStyle
  } = styles;

  const loginGoogleRedirect = () => {
    // eslint-disable-next-line
    localStorage.setItem(SIGNIN_METHOD, PROVIDER_GOOGLE);
    linkWithGoogleRedirect();
  };
  const loginFbRedirect = () => {
    // eslint-disable-next-line
    localStorage.setItem(SIGNIN_METHOD, PROVIDER_FACEBOOK);
    linkWithFbRedirect();
  };

  const loginAppleRedirect = () => {
    // eslint-disable-next-line
    localStorage.setItem(SIGNIN_METHOD, PROVIDER_APPLE);
    linkWithAppleRedirect();
  };

  const handleResetPasswordSubmit = async (
    event: SyntheticEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    const isErr = validateResetPaswordForm();
    setErrorMsg(prevState => ({ ...prevState, isError: isErr }));

    if (!isErr) {
      resetPassword();
    }
  };
  const resetPassword = async () => {
    const { email } = userForm;
    console.log('email', email);
    setIsLoading(true);
    setUserForm(prevState => ({
      ...prevState,
      errorMsgFirebase: ''
    }));
    const signMsg = await sendPasswordResetEmail(email);

    if (signMsg[1] && signMsg[1].message) {
      setUserForm(prevState => ({
        ...prevState,
        errorMsgFirebase: signMsg[1].message
      }));
    } else {
      setIsEmailSent(true);
    }
    setIsLoading(false);
  };
  const handleSignUpSubmit = async (
    event: SyntheticEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setErrorMsg({ ...initialErrorMsgState });
    const isLoginForm = false;
    const isErr = validate(isLoginForm);
    setErrorMsg(prevState => ({ ...prevState, isError: isErr }));

    if (!isErr) {
      // eslint-disable-next-line
      localStorage.setItem(SIGNIN_METHOD, PROVIDER_EMAIL);
      onSignUpWithEmail();
    }
  };
  const onSignUpWithEmail = async () => {
    const { name, email, password } = userForm;
    setIsLoading(true);
    setUserForm(prevState => ({
      ...prevState,
      errorMsgFirebase: ''
    }));

    const signMsg = await signUpWithEmail(name, email, password);

    if (signMsg[0]) {
      history.push(`/payment/${type}/${sessionId}/?promoCode=${promoCode}`);
      // this.toggle();
    } else {
      setUserForm(prevState => ({
        ...prevState,
        errorMsgFirebase: getEmailLoginError(signMsg[1]).message
      }));
      setIsLoading(false);
    }
  };

  const validateResetPaswordForm = () => {
    const { email } = userForm;

    let isError = false;
    let emailErrorMsg = '';
    let formErrorMsg = '';
    if (email.length === 0) {
      isError = true;
      formErrorMsg = email_error_fieldsMissing;
    } else {
      if (!isValidEmail(email)) {
        isError = true;
        emailErrorMsg = email_error_wrongFormat;
      }
    }
    setErrorMsg(prevState => ({
      ...prevState,
      formErrorMsg: formErrorMsg,
      emailErrorMsg: emailErrorMsg
    }));

    return isError;
  };

  const validate = (isLoginForm: boolean) => {
    let isError = false;
    let emailErrorMsg = '';
    let passwordErrorMsg = '';
    let formErrorMsg = '';
    if (isLoginForm) {
      const { email, password } = userForm;
      if (email.length === 0 || password.length === 0) {
        isError = true;
        formErrorMsg = email_error_fieldsMissing;
      } else {
        if (!isValidEmail(email)) {
          isError = true;
          emailErrorMsg = email_error_wrongFormat;
        }
        if (password.length < 6) {
          isError = true;
          passwordErrorMsg = email_error_longerPassword;
        }
      }
    } else {
      const { name, email, password, confirmPassword } = userForm;
      if (
        name.length === 0 ||
        email.length === 0 ||
        password.length === 0 ||
        confirmPassword.length === 0
      ) {
        isError = true;
        formErrorMsg = email_error_fieldsMissing;
      } else {
        if (!isValidEmail(email)) {
          isError = true;
          emailErrorMsg = email_error_wrongFormat;
        }
        if (password.length < 6) {
          isError = true;
          passwordErrorMsg = email_error_longerPassword;
        }
        if (confirmPassword !== password) {
          isError = true;
          passwordErrorMsg = email_error_matchPasswords;
        }
      }
    }
    setErrorMsg(prevState => ({
      ...prevState,
      formErrorMsg: formErrorMsg,
      emailErrorMsg: emailErrorMsg,
      passwordErrorMsg: passwordErrorMsg
    }));

    return isError;
  };
  const handleLoginSubmit = async (
    event: SyntheticEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setErrorMsg({ ...initialErrorMsgState });
    const isLoginForm = true;
    const isErr = validate(isLoginForm);
    setErrorMsg(prevState => ({ ...prevState, isError: isErr }));

    if (!isErr) {
      // eslint-disable-next-line
      localStorage.setItem(SIGNIN_METHOD, PROVIDER_EMAIL);
      login();
    }
  };
  const login = async () => {
    const { email, password } = userForm;
    setIsLoading(true);
    setUserForm(prevState => ({
      ...prevState,
      errorMsgFirebase: ''
    }));
    const signMsg = await signInWithEmailAndPassword(email, password);

    if (signMsg[0]) {
      history.push(`/payment/${type}/${sessionId}/?promoCode=${promoCode}`);
    } else {
      setUserForm(prevState => ({
        ...prevState,
        errorMsgFirebase: getEmailLoginError(signMsg[1]).message
      }));

      setIsLoading(false);
    }
  };

  const getEmailLoginError = ({ code, message }: Object) => {
    switch (code) {
      case 'auth/user-not-found':
        return {
          message: email_error_userNotFound
        };
      case 'auth/wrong-password':
        return {
          message: email_error_wrongPassword
        };
      case 'auth/email-already-in-use':
        return {
          message: email_error_alreadyUsed
        };
      default:
        return {
          message
        };
    }
  };
  return (
    <Container>
      <GiftFireworkStyle src={giftFirework} alt={giftFirework} />

      <HeaderTitle>
        <Logo src={lingAppShadowIcon} />
      </HeaderTitle>

      <FormContainer>
        {!isEmailSent && (
          <FormHeader>
            {isResetPassword
              ? login_txt_forget_password_title
              : isSignUp
              ? sign_up_txt_title
              : login_txt_title}
          </FormHeader>
        )}
        <WarningText>{claim_warning_txt}</WarningText>
        <ModalContentContainer>
          <ModalBodyContainer>
            <ModalBody style={modalBodyContainer}>
              <ModalBodyText>
                {isResetPassword ? (
                  <>
                    {isEmailSent ? (
                      <ResetPasswordContainer>
                        <ResetPasswordImg src={dangerIcon} alt={dangerIcon} />
                        <ModalBodyText
                          dangerouslySetInnerHTML={{
                            __html: reset_password_txt_description
                          }}
                        />
                        <div>
                          <ComfirmButton
                            onClick={() => {
                              setIsResetPassword(false);
                            }}
                            className="confirmButton"
                          >
                            <img src={confirmIcon} alt={confirmIcon} />
                          </ComfirmButton>
                        </div>
                      </ResetPasswordContainer>
                    ) : (
                      <form
                        id="loginForm"
                        onSubmit={e => handleResetPasswordSubmit(e)}
                      >
                        <div>
                          <div style={inputGroup}>
                            <InputGroup style={inputContainer}>
                              <InputLabel
                                htmlFor="email"
                                style={labelStyle}
                                isError={isError}
                                errorMsg={emailErrorMsg}
                              >
                                {login_field_email}
                              </InputLabel>
                              <InputPlace
                                placeholder={txt_email_field_placeholder}
                                id="email"
                                type="text"
                                onChange={event =>
                                  setUserForm({
                                    ...userForm,
                                    email: event.target.value
                                  })
                                }
                              />
                              <ErrorTextStyle>
                                {isError && emailErrorMsg !== ''
                                  ? emailErrorMsg
                                  : null}
                                {errorMsgFirebase ? errorMsgFirebase : null}
                              </ErrorTextStyle>
                            </InputGroup>
                          </div>
                          <ErrorTextStyle>
                            {isError && formErrorMsg !== ''
                              ? formErrorMsg
                              : null}
                          </ErrorTextStyle>
                        </div>
                        {successMsg ? successMsg : null}
                        <ContinueContainer>
                          <LoginStyle type="submit">{btn_continue}</LoginStyle>
                        </ContinueContainer>
                      </form>
                    )}
                  </>
                ) : (
                  <>
                    {isSignUp ? (
                      <>
                        <form
                          id="loginForm"
                          onSubmit={e => handleSignUpSubmit(e)}
                        >
                          <div style={inputGroup}>
                            <InputGroup style={inputContainer}>
                              <label htmlFor="name" style={labelStyle}>
                                {login_field_name}
                              </label>
                              <InputPlace
                                placeholder={login_field_firstName}
                                id="name"
                                onChange={event =>
                                  setUserForm({
                                    ...userForm,
                                    name: event.target.value
                                  })
                                }
                              />
                              <InputErrorTextStyle />
                            </InputGroup>
                            <InputGroup
                              className="border-top"
                              style={inputContainer}
                            >
                              <InputLabel
                                htmlFor="email"
                                style={labelStyle}
                                isError={isError}
                                errorMsg={emailErrorMsg}
                              >
                                {login_field_email}
                              </InputLabel>
                              <InputPlace
                                placeholder={txt_email_field_placeholder}
                                id="email"
                                onChange={event =>
                                  setUserForm({
                                    ...userForm,
                                    email: event.target.value
                                  })
                                }
                              />
                              <InputErrorTextStyle>
                                {isError && emailErrorMsg !== ''
                                  ? emailErrorMsg
                                  : null}
                                {errorMsgFirebase ? errorMsgFirebase : null}
                              </InputErrorTextStyle>
                            </InputGroup>
                            <InputGroup
                              className="border-top"
                              style={inputContainer}
                            >
                              <InputLabel
                                htmlFor="password"
                                style={labelStyle}
                                isError={isError}
                                errorMsg={passwordErrorMsg}
                              >
                                {login_field_password}
                              </InputLabel>
                              <InputPlace
                                placeholder={at_least_6_password_txt}
                                id="password"
                                type="password"
                                onChange={event =>
                                  setUserForm({
                                    ...userForm,
                                    password: event.target.value
                                  })
                                }
                              />
                              <InputErrorTextStyle>
                                {isError && passwordErrorMsg !== ''
                                  ? passwordErrorMsg
                                  : null}
                              </InputErrorTextStyle>
                            </InputGroup>
                            <InputGroup
                              className="border-top"
                              style={inputContainer}
                            >
                              <label
                                htmlFor="confirmPassword"
                                style={labelStyle}
                              >
                                {login_msg_reenterPassword}
                              </label>
                              <InputPlace
                                placeholder={at_least_6_password_txt}
                                id="confirmPassword"
                                type="password"
                                onChange={event =>
                                  setUserForm({
                                    ...userForm,
                                    confirmPassword: event.target.value
                                  })
                                }
                              />
                            </InputGroup>
                          </div>
                          <ErrorTextStyle>
                            {isError && formErrorMsg !== ''
                              ? formErrorMsg
                              : null}
                          </ErrorTextStyle>
                          <LoginButtonContainer>
                            <LoginStyle type="submit">
                              {' '}
                              {sign_up_txt_title}
                            </LoginStyle>
                          </LoginButtonContainer>
                        </form>
                        <div style={otherLoginGroup}>
                          <SocialButton
                            style={{
                              ...otherLoginButton,
                              ...appleIconStyle
                            }}
                            onClick={() => loginAppleRedirect()}
                          >
                            <img src={appleIcon} alt={appleIcon} />
                          </SocialButton>
                          <SocialButton
                            style={{ ...otherLoginButton }}
                            onClick={() => loginFbRedirect()}
                          >
                            <img src={fbIcon} alt={fbIcon} />
                          </SocialButton>
                          <SocialButton
                            style={{
                              ...otherLoginButton,
                              ...googleIconStyle
                            }}
                            onClick={() => loginGoogleRedirect()}
                          >
                            <img src={googleIcon} alt={googleIcon} />
                          </SocialButton>
                        </div>
                      </>
                    ) : (
                      <>
                        <form
                          id="loginForm"
                          onSubmit={e => handleLoginSubmit(e)}
                        >
                          <InputGroupContainer>
                            <InputGroup style={inputContainer}>
                              <InputLabel
                                htmlFor="email"
                                style={labelStyle}
                                isError={isError}
                                errorMsg={emailErrorMsg}
                              >
                                {login_field_email}
                              </InputLabel>
                              <InputPlace
                                placeholder={txt_email_field_placeholder}
                                id="email1"
                                type="text"
                                className="InputPlace"
                                onChange={event =>
                                  setUserForm({
                                    ...userForm,
                                    email: event.target.value
                                  })
                                }
                              />
                              <InputErrorTextStyle>
                                {isError && emailErrorMsg !== ''
                                  ? emailErrorMsg
                                  : null}
                              </InputErrorTextStyle>
                            </InputGroup>
                            <InputGroup
                              type="email"
                              className="border-top"
                              style={inputContainer}
                            >
                              <InputLabel
                                htmlFor="password"
                                style={labelStyle}
                                isError={isError}
                                errorMsg={passwordErrorMsg}
                              >
                                {login_field_password}
                              </InputLabel>
                              <InputPlace
                                className="InputPlace"
                                type="password"
                                placeholder={at_least_6_password_txt}
                                onChange={event =>
                                  setUserForm({
                                    ...userForm,
                                    password: event.target.value
                                  })
                                }
                              />
                              <InputErrorTextStyle>
                                {isError && passwordErrorMsg !== ''
                                  ? passwordErrorMsg
                                  : null}
                                {errorMsgFirebase ? errorMsgFirebase : null}
                              </InputErrorTextStyle>
                            </InputGroup>
                          </InputGroupContainer>
                          <ErrorTextStyle>
                            {isError && formErrorMsg !== ''
                              ? formErrorMsg
                              : null}
                          </ErrorTextStyle>
                          <ForgotTextStyle
                            id="ForgotTextStyle"
                            onClick={() => setIsResetPassword(true)}
                          >
                            {login_txt_forget_password_web}
                          </ForgotTextStyle>
                          <div style={space} />
                          <LoginButtonContainer>
                            <LoginStyle type="submit">
                              {' '}
                              {isSignUp ? sign_up_txt_title : login_txt_title}
                            </LoginStyle>
                          </LoginButtonContainer>
                        </form>
                        <div style={otherLoginGroup}>
                          <SocialButton
                            style={{
                              ...otherLoginButton,
                              ...appleIconStyle
                            }}
                            onClick={() => loginAppleRedirect()}
                          >
                            <img src={appleIcon} alt={appleIcon} />
                          </SocialButton>
                          <SocialButton
                            style={{ ...otherLoginButton }}
                            onClick={() => loginFbRedirect()}
                          >
                            <img src={fbIcon} alt={fbIcon} />
                          </SocialButton>
                          <SocialButton
                            style={{
                              ...otherLoginButton,
                              ...googleIconStyle
                            }}
                            onClick={() => loginGoogleRedirect()}
                          >
                            <img src={googleIcon} alt={googleIcon} />
                          </SocialButton>
                        </div>
                      </>
                    )}
                  </>
                )}
              </ModalBodyText>
              {!isEmailSent && (
                <SignUpSuggestionStyle>
                  {isResetPassword || isSignUp ? (
                    <BottomText>
                      {already_register_txt}{' '}
                      <LoginLink
                        id="LoginLink"
                        style={signUpStyle}
                        onClick={() => {
                          setIsSignUp(false);
                          setIsResetPassword(false);
                        }}
                      >
                        {email_txt_loginNow_link}
                      </LoginLink>
                    </BottomText>
                  ) : (
                    <BottomText>
                      {dont_have_account_yet_txt}
                      <LoginLink
                        id="LoginLink"
                        style={signUpStyle}
                        onClick={() => {
                          setIsSignUp(true);
                          setIsResetPassword(false);
                        }}
                      >
                        {sign_up_txt_title}
                      </LoginLink>
                    </BottomText>
                  )}
                  <div style={loginFormSpaceBottom} />
                </SignUpSuggestionStyle>
              )}
            </ModalBody>
          </ModalBodyContainer>
        </ModalContentContainer>
      </FormContainer>
    </Container>
  );
};

export default AuthForm;
