/* @flow */
import _ from 'lodash';
import { getUserProgress, isUserSubscribed } from '../../profile/selectors';
import { getToday } from '../../games/util';
export const getUnits = (state: Object) => {
  const newUnits = _.cloneDeep(state.units.units);

  for (let i in newUnits) {
    let unit = newUnits[i];
    if (unit !== null) {
      const { key } = unit;
      const currentUnit = _.get(getUserProgress(state), key);
      if (currentUnit) {
        unit.progress = currentUnit.done;
        unit.crowns = currentUnit.crowns;
        unit.lastDayPlayed = currentUnit.lastDayPlayed || '2020-07-15';
        unit.repairedCount = currentUnit.repairedCount || 0;
      } else {
        unit.progress = 0;
        unit.crowns = 0;
        unit.lastDayPlayed = getToday();
        unit.repairedCount = 0;
      }
    }
  }
  return newUnits;
};

const DEFAULT_FREE_NUMBER_OF_UNITS = 2;
export const getNumberFreeUnits = (state: Object) =>
  DEFAULT_FREE_NUMBER_OF_UNITS > 0
    ? DEFAULT_FREE_NUMBER_OF_UNITS
    : state.storage.configs.numberOfFreeUnits;

export const isUnitLocked = _.curry(
  (
    state: Object,
    unitId: ?number //false
  ) => {
    return !isUserSubscribed(state)
      ? Boolean(unitId) && state.storage.isBusiness
        ? //$FlowFixMe
          unitId > getNumberFreeUnits(state) && unitId !== 41 && unitId !== 42
        : //$FlowFixMe
          unitId > getNumberFreeUnits(state)
      : false;
  }
);

export const isCompleteTwoUnits = (state: Object) => {
  const { storage, data } = state;
  const { targetLangCode } = data;
  const { user } = storage;
  let completeTwoUnit = 0;
  if (
    _.keys(_.get(user, `${targetLangCode}.progress`, [])).filter(
      x => x !== 'null'
    ).length -
      1 >
    1
  ) {
    _.forEach(user[targetLangCode].progress, function(unit) {
      const crowns = _.get(unit, 'crowns', 0);
      if (crowns > 0) {
        completeTwoUnit++;
      }
    });
  }

  return completeTwoUnit > 1;
};
