import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import ProgressHeaderBar from './ProgressHeaderBar';
import { getEventData, getUserProps } from '../onboardingHelper';
import { useTheme } from '@mui/material/styles';
// import { useSelector } from 'react-redux';
// import { selectLanguages } from '../../common/selectors';

export const OnboardingScreen = props => {
  const [selected, setSelected] = useState('');
  // FIXME: enable this when language data is server-side
  // const [langs, _] = useSelector(selectLanguages);

  const {
    activeScreen,
    campaign,
    changeActiveScreen,
    campaignId,
    completeCampaign,
    nativeLanguage,
    requestTracking,
    selectionObject,
    updateSelections,
    labels
  } = props;

  const theme = useTheme();

  // Intended for intro_screen and language_picker
  const trackScreenView = useCallback(
    screenName => {
      const eventData = {
        ...getEventData({
          campaignId,
          activeScreen,
          nativeLanguage,
          selected: '',
          campaign,
          screenName
        }),
        screen_name: screenName
      };

      const userProps = {
        ...getUserProps({
          campaignId,
          activeScreen,
          nativeLanguage,
          selected: '',
          campaign,
          screenName
        }),
        screen_name: screenName
      };

      requestTracking('screen_view', eventData, userProps);
    },
    [activeScreen, campaign, campaignId, nativeLanguage, requestTracking]
  );

  const handleScreenChange = useCallback(
    userSelection => {
      let selected = userSelection;
      // selected is not always immediately available, so we pass in selections manually

      updateSelections(campaignId, selectionObject, selected);

      if (campaign.userProps[activeScreen].eventName) {
        // TODO: make event factory for these

        const eventData = getEventData({
          campaignId,
          activeScreen,
          nativeLanguage,
          selected,
          campaign
        });

        const userProps = getUserProps({
          campaignId,
          activeScreen,
          nativeLanguage,
          selected,
          campaign
        });

        requestTracking(
          campaign.userProps[activeScreen].eventName,
          eventData,
          Array.isArray(userProps)
            ? userProps.reduce((acc, obj) => Object.assign(acc, obj), {}) // userProps for "language_aspects" is Object[] and need to be combined
            : userProps
        );
      }

      if (activeScreen === campaign.screens.length - 1) {
        // Allows events to be fired even after we went pass this page
        setTimeout(() => {
          completeCampaign(campaignId);
        }, 500);
      } else {
        changeActiveScreen(activeScreen + 1);
      }
      setSelected(null);
    },
    [
      activeScreen,
      campaign,
      campaignId,
      changeActiveScreen,
      completeCampaign,
      nativeLanguage,
      requestTracking,
      selectionObject,
      updateSelections
    ]
  );

  const isStaticScreen = [0, 2, 4];

  const styles = {
    container: {
      overflowX: 'hidden',
      height: '100vh',
      overflowY: isStaticScreen.includes(activeScreen) ? 'hidden' : 'auto',
      [theme.breakpoints.up('sm')]: {
        height: '100%'
      }
    }
  };
  return campaign && campaign.screens.length ? (
    <Box sx={styles.container}>
      <ProgressHeaderBar
        activeScreen={activeScreen}
        screenLength={campaign.screens.length - 1}
        changeActiveScreen={changeActiveScreen}
      />
      <Box>
        {React.createElement(campaign.screens[activeScreen], {
          headerData: campaign.headerData[activeScreen],
          screenText: campaign.screenText[activeScreen],
          selected,
          setSelected,
          handleScreenChange,
          activeScreen,
          trackScreenView,
          labels
        })}
      </Box>
    </Box>
  ) : null;
};

OnboardingScreen.propTypes = {
  activeScreen: PropTypes.number,
  campaignId: PropTypes.number,
  selections: PropTypes.shape({
    id: PropTypes.number,
    values: PropTypes.arrayOf(PropTypes.number)
  }),
  changeActiveScreen: PropTypes.func,
  changeCampaign: PropTypes.func,
  languageData: PropTypes.arrayOf(PropTypes.string),
  setSelections: PropTypes.func,
  labels: PropTypes.objectOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string
    })
  )
};

export default OnboardingScreen;
