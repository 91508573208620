/* @flow */
import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import BugTracker from '../../../../util/BugTracker';
import { setArrayCheckedRemindedDays } from '../../../../util';

import './../SignUpModal/SignUpModal.css';
import {
  PREFIX_LESSON,
  WHITE,
  GREY,
  GREY2,
  MARIGOLD,
  SEA_BLUE,
  BLACK
} from '../../../../common/constants';
import {
  ContentContainer,
  DayCheckBox,
  DayLabel,
  DayOptionContainer,
  DayOptionText,
  DaySelectorContainer,
  LoginStyle,
  ModalBodyContainer,
  ModalBodyText,
  ModalCloseButton,
  ModalResetPasswordContainer,
  TimeOptionText
} from './DailyReminderModal.styles';
import { requestFirebaseNotificationPermission } from './../../../../system/FirebaseMessage';
import _ from 'lodash';
import TimePickerClass from './../TimePicker';

const styles = {
  modalContainer: {
    width: '460px',
    height: '457px',
    textAlign: 'center',
    borderRadius: 30
  },
  modalBodyContainer: {
    padding: 0,
    paddingBottom: 36
  },
  buttonStyle: {
    borderRadius: 10,
    width: 160,
    height: 55,
    background: WHITE,
    border: 'solid 1px',
    borderColor: GREY2,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    boxShadowColor: GREY2,
    fontWeight: 'bold',
    fontSize: 18
  },
  cancelButtonStyle: {
    border: 'none',
    boxShadow: 'none',
    width: 100
  },
  confirmButtonStyle: {
    borderColor: GREY2,
    background: WHITE,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0'
  },
  modalHeader: {
    borderBottom: 'none',
    flexDirection: 'row-reverse',
    justifyContent: 'normal',
    padding: '25px 35px',
    paddingBottom: 20,
    backgroundColor: MARIGOLD,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30
  },
  modalFooter: {
    borderTop: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 36,
    paddingRight: 32,
    paddingLeft: 32
  },
  contentContainer: {
    borderRadius: 30
  },
  modalHeaderContainer: {
    'modal-title': 'w-100 text-center'
  },
  inputGroup: {
    textAlign: 'left',
    borderColor: GREY,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 20
  },
  inputGroupResetPassword: {
    margin: '76px 35px 55px'
  },

  inputContainer: {
    padding: '10px 15px 15px',
    width: 'inherit',
    fontSize: 14
  },
  labelStyle: {
    fontWeight: 'bold',
    marginBottom: 12,
    textTransform: 'capitalize'
  },
  forgotTextStyle: {
    fontSize: 14,
    textAlign: 'left',
    paddingLeft: 14,
    fontWeight: 'bold',
    display: 'flex',
    paddingTop: 10
  },
  otherLoginButton: {
    borderRadius: 22,
    padding: 0,
    width: 112,
    height: 42,
    background: WHITE,
    color: BLACK,
    border: 'gray solid 1px',
    borderColor: GREY,
    borderWidth: 1,
    borderStyle: 'solid',
    margin: '0 6px',
    marginTop: 5
  },
  fbIconStyle: {
    marginLeft: 0
  },
  googleIconStyle: {
    marginRight: 0
  },
  otherLoginGroup: {
    marginBottom: 25
  },
  signUpSuggestionStyle: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  signUpStyle: {
    color: SEA_BLUE,
    cursor: 'pointer'
  },
  space: {
    height: 12
  },
  loginFormSpaceBottom: {
    paddingBottom: 4
  },
  loadingStyle: {
    margin: '134px 38px',
    color: MARIGOLD
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    width: 200
  }
};

const closeIcon = `${PREFIX_LESSON}basecolor-black.svg`;

type Props = {
  isOpen: boolean,
  isHomePage: boolean,
  toggle: Function,
  reminderData?: Object,
  stateChangeHandler: Function,
  closeReminder: Function,
  setReminders: Function,
  setClientToken?: Function,
  nativeLangCode: string,
  edit_profile_save_txt: string,
  setting_txt_reminder_title: string,
  setting_txt_day_select: string,
  setting_txt_time_select: string
};

type State = {
  weekDays: Array<Object>,
  timeReminder: string,
  isSetReminder: boolean,
  dayCheckCount: number
};

class DailyReminderModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSetReminder: true,
      weekDays: [
        {
          id: 0,
          day: 'M',
          dayShort: 'Mon',
          isChecked: true
        },
        {
          id: 1,
          day: 'T',
          dayShort: 'Tue',
          isChecked: true
        },
        {
          id: 2,
          day: 'W',
          dayShort: 'Wed',
          isChecked: true
        },
        {
          id: 3,
          day: 'T',
          dayShort: 'Thu',
          isChecked: true
        },
        {
          id: 4,
          day: 'F',
          dayShort: 'Fri',
          isChecked: true
        },
        {
          id: 5,
          day: 'S',
          dayShort: 'Sat',
          isChecked: true
        },
        {
          id: 6,
          day: 'S',
          dayShort: 'Sun',
          isChecked: true
        }
      ],
      timeReminder: '09:30',
      dayCheckCount: 7
    };
  }
  componentDidMount(): void {
    this.getReminderData();
  }
  getReminderData = () => {
    const { reminderData } = this.props;
    if (reminderData) {
      const { weekDays, timeReminder, isSetReminder } = reminderData;
      this.setState({
        weekDays: weekDays,
        timeReminder: timeReminder,
        isSetReminder: isSetReminder
      });
    }
  };
  handleChecked = (event: SyntheticEvent<HTMLInputElement>) => {
    const weekDays = this.state.weekDays;

    _.forEach(weekDays, weekDay => {
      // $flow-disable-line
      if ('id' + weekDay.id === event.target.id) {
        // $flow-disable-line
        weekDay.isChecked = event.target.checked;
      }
    });

    this.setState({
      weekDays: weekDays,
      dayCheckCount: _.countBy(weekDays, v => v.isChecked === true).true
        ? _.countBy(weekDays, v => v.isChecked === true).true
        : 0
    });
  };

  handleTimeChange = (timeData: string) => {
    this.setState({ timeReminder: timeData });
  };
  onSave = () => {
    const { weekDays, timeReminder, isSetReminder } = this.state;
    const {
      isHomePage,
      setClientToken,
      setReminders,
      nativeLangCode,
      stateChangeHandler,
      reminderData,
      toggle
    } = this.props;
    if (isHomePage) {
      if (
        //$FlowFixMe
        Notification.permission === 'denied' // eslint-disable-line
      ) {
        alert('Notification is disabled, please enable it and try again');
      } else {
        requestFirebaseNotificationPermission()
          .then(firebaseToken => {
            setClientToken && setClientToken(firebaseToken);
            setReminders({
              nativeCode: nativeLangCode,
              weekDays: weekDays,
              timeReminder: timeReminder,
              isSetReminder: isSetReminder
            });
            toggle();

            // eslint-disable-next-line no-console
            console.log(firebaseToken);
          })
          .catch(err => {
            toggle();
            BugTracker.notify(err);

            return err;
          });
      }
    } else {
      setArrayCheckedRemindedDays(weekDays, stateChangeHandler);

      setReminders({
        nativeCode: nativeLangCode,
        weekDays: weekDays,
        timeReminder: timeReminder,
        // $flow-disable-line
        isSetReminder: reminderData.isSetReminder
      });
      toggle();
    }
  };
  render() {
    const {
      isHomePage,
      isOpen,
      closeReminder,
      edit_profile_save_txt,
      setting_txt_reminder_title,
      setting_txt_day_select,
      setting_txt_time_select
    } = this.props;
    const { modalContainer, modalHeader, modalBodyContainer } = styles;
    const { weekDays, dayCheckCount, timeReminder } = this.state;
    const closeBtn = (
      <ModalCloseButton
        className="close"
        onClick={() => {
          this.getReminderData();
          closeReminder();
        }}
      >
        <img src={closeIcon} alt={closeIcon} />
      </ModalCloseButton>
    );

    return (
      <Modal
        isOpen={isOpen}
        style={modalContainer}
        contentClassName="contentContainer"
        centered
      >
        <ModalHeader
          close={closeBtn}
          style={modalHeader}
          external={null}
          cssModule={{ 'modal-title': 'w-100 text-center' }}
        >
          {setting_txt_reminder_title}
        </ModalHeader>

        <ModalBodyContainer>
          <ModalResetPasswordContainer>
            <ModalBody style={modalBodyContainer}>
              <ModalBodyText>
                <div>
                  <ContentContainer isHomePage={isHomePage}>
                    <DayOptionContainer isHomePage={isHomePage}>
                      <DayOptionText isHomePage={isHomePage}>
                        {setting_txt_day_select}
                      </DayOptionText>
                      <DaySelectorContainer isHomePage={isHomePage}>
                        {_.map(weekDays, (day, index) => (
                          <Fragment key={index}>
                            <DayCheckBox
                              checked={weekDays[index].isChecked}
                              checkPrev={
                                index !== 0
                                  ? weekDays[index - 1] &&
                                    weekDays[index - 1].isChecked
                                  : false
                              }
                              checkNext={
                                index !== 6
                                  ? weekDays[index + 1] &&
                                    weekDays[index + 1].isChecked
                                  : false
                              }
                              onClick={this.handleChecked.bind(this)}
                              isHomePage={isHomePage}
                              type="checkbox"
                              id={'id' + index}
                              order={index}
                            />
                            <DayLabel
                              isHomePage={isHomePage}
                              for={'id' + index}
                            >
                              <div>{day.day}</div>
                            </DayLabel>
                          </Fragment>
                        ))}
                      </DaySelectorContainer>
                    </DayOptionContainer>
                    <TimeOptionText isHomePage={isHomePage}>
                      {setting_txt_time_select}
                    </TimeOptionText>
                    <div>
                      <TimePickerClass
                        defaultValue={timeReminder}
                        handleTimeChange={time => this.handleTimeChange(time)}
                      />
                    </div>
                    <LoginStyle
                      disabled={dayCheckCount === 0}
                      onClick={() => this.onSave()}
                    >
                      {edit_profile_save_txt}
                    </LoginStyle>
                  </ContentContainer>
                </div>
              </ModalBodyText>
            </ModalBody>
          </ModalResetPasswordContainer>
        </ModalBodyContainer>
      </Modal>
    );
  }
}

export { DailyReminderModal };
