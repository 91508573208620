// @flow
import {
  FULL_DISCOUNT_ENCRYPT_CODE,
  STRIPE_PRODUCT_MONTHLY_0_PRICE,
  STRIPE_PRODUCT_YEARLY_0_PRICE,
  STRIPE_PRODUCT_LIFETIME_0_PRICE
} from '../common/constants';
import { PRODUCT_TYPE } from '../shop/constants';
export const getMonthlyOfYearlyProduct = (
  yearPrice: number,
  usdYearPrice: number
) => ((yearPrice !== 0 ? yearPrice : usdYearPrice) / 100 / 12).toFixed(2);

export const getProductPrice = (price: number, usdPrice: number) =>
  (price !== 0 ? price : usdPrice) / 100;

export const getProductCurrencySymbol = (
  currencyCode: string,
  currencySymbol: string
) =>
  currencyCode === 'EUR' ||
  currencyCode === 'THB' ||
  currencyCode === 'GBP' ||
  currencyCode === 'RUB'
    ? currencySymbol
    : '$';

export const getProductName = (discount: ?string, type: string) => {
  const zeroPriceProductName = {
    [PRODUCT_TYPE.monthly]: STRIPE_PRODUCT_MONTHLY_0_PRICE,
    [PRODUCT_TYPE.yearly]: STRIPE_PRODUCT_YEARLY_0_PRICE,
    [PRODUCT_TYPE.lifetime]: STRIPE_PRODUCT_LIFETIME_0_PRICE
  };
  return discount === FULL_DISCOUNT_ENCRYPT_CODE
    ? zeroPriceProductName[type]
    : type;
};
