/* @flow */
export const FLASHCARD: string = 'FLASHCARD';
export const MATCHING: string = 'MATCHING';
export const QUIZ: string = 'QUIZ';
export const TRANSLATEGAME: string = 'TRANSLATEGAME';
export const LISTENING: string = 'LISTENING';
export const SPELLING: string = 'SPELLING';
export const ORDER_SENTENCE: string = 'ORDER_SENTENCE';
export const ORDER_SENTENCE_REVERSE: string = 'ORDER_SENTENCE_REVERSE';
export const COMPLETE_SENTENCE: string = 'COMPLETE_SENTENCE';
export const DIALOGUE: string = 'DIALOGUE';
export const SPEAKING: string = 'SPEAKING';
export const WRITING: string = 'WRITING';
export const CORRECT_SENTENCE: string = 'CORRECT_SENTENCE';
export const DIALOGUE_GAME: string = 'DIALOGUE_GAME';
export const GRAMMAR: string = 'GRAMMAR';
export const CONVERSATION: string = 'CONVERSATION';

export const TUTORIAL_ID: number = 0;
export const TUTORIAL_KEY: string = '0,0';
