/* @flow */
import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './SignUpModal.css';
import { PREFIX_LESSON, IMAGE_PATH } from '../../../../common/constants';
import {
  BottomText,
  LingLoading,
  LoginLink,
  ModalBodyContainer,
  ModalBodyText,
  ModalCloseButton,
  ModalContentContainer,
  ModalResetPasswordContainer,
  styles
} from './SignUpModal.styles';
import {
  handleResetPasswordSubmit,
  toggle,
  handleSignUpSubmit,
  onToggleResetPassword,
  onToggleSignUp,
  handleLoginSubmit
} from '../../../../util';
import { ResetPasswordModal } from './../ResetPasswordModal';
import { LoginModalFormModal } from './../LoginModalFormModal';
import { SignUpModalFormModal } from './../SignUpModalFormModal';
import ResetPasswordPage from './ResetPasswordPage';
const closeIcon = `${PREFIX_LESSON}basecolor-black.svg`;

const lingLoading = `${IMAGE_PATH}ling-loader.gif`;
type Props = {
  sign_in_opt_msg: string,
  email_error_fieldsMissing: string,
  email_error_wrongFormat: string,
  email_error_matchPasswords: string,
  email_error_fieldsMissing: string,
  email_error_longerPassword: string,
  errorMsgFirebase: string,
  isOpen: boolean,
  isResetPassword: boolean,
  isSignUp: boolean,
  signInWithApple: Function,
  signInWithFacebook: Function,
  signInWithGoogle: Function,
  sendPasswordResetEmail: Function,
  login_field_name: string,
  login_field_firstName: string,
  login_msg_reenterPassword: string,
  login_field_email: string,
  login_field_password: string,
  isLoading: boolean,
  successMsg: string,
  btn_continue: string,
  isEmailSent: boolean,
  reset_password_txt_description: string,
  closeEmailSent: Function,
  sign_up_txt_title: string,
  login_txt_title: string,
  email_txt_loginNow_link: string,
  already_register_txt: string,
  login_txt_forget_password_title: string,
  login_txt_forget_password_web: string,
  dont_have_account_yet_txt: string,
  at_least_6_password_txt: string,
  user: Object,
  login: Function,
  signUpWithEmail: Function,
  resetUser: Function,
  toggleResetPassword: Function,
  fetchUnitData: Function,
  targetLangCode: string,
  nativeLangCode: string,
  toggleCheckout: Function,
  history: Object,
  itemInfo: Object,
  unitIdBussiness: number,
  isLoginModalOpen: boolean,
  toggleLoginModal: Function,
  isBusinessSignUp: boolean,
  setIsBusinessSignUp: Function,
  pauseDialog: Function,
  modal: boolean,
  stateChangeHandler: Function,
  isNewsletterEnable: boolean,
  _onNewsletterStateHandler: Function,
  txt_email_field_placeholder: string
};
type State = {
  err: boolean,
  emailErrorMsg: string,
  passwordErrorMsg: string,
  formErrorMsg: string
};
class SignUpModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      err: false,
      emailErrorMsg: '',
      passwordErrorMsg: '',
      formErrorMsg: ''
    };
  }
  modalStateChangeHandler = (name: string, value: any) => {
    this.setState({
      [name]: value
    });
  };

  handleOnChange = (event: any, formKey: string) => {
    const { user, stateChangeHandler } = this.props;
    const newuser = {
      ...user,
      [formKey]: event.target.value
    };
    stateChangeHandler('user', newuser);
  };

  handleValidated = () => {
    this.props.history.push('/');
  };

  render() {
    const { passwordErrorMsg, emailErrorMsg, formErrorMsg, err } = this.state;
    const {
      isOpen,
      isResetPassword,
      isSignUp,
      user,
      isNewsletterEnable,
      sign_in_opt_msg,
      login_field_name,
      login_field_firstName,
      login_msg_reenterPassword,
      login_field_email,
      login_field_password,
      isLoading,
      errorMsgFirebase,
      successMsg,
      btn_continue,
      signUpWithEmail,
      isEmailSent,
      reset_password_txt_description,
      closeEmailSent,
      sign_up_txt_title = 'Sign Up', // For missing label
      login_txt_title,
      email_txt_loginNow_link,
      already_register_txt,
      login_txt_forget_password_title,
      login_txt_forget_password_web,
      dont_have_account_yet_txt,
      at_least_6_password_txt,
      email_error_matchPasswords,
      email_error_fieldsMissing,
      email_error_longerPassword,
      email_error_wrongFormat,
      signInWithGoogle,
      fetchUnitData,
      targetLangCode,
      nativeLangCode,
      toggleCheckout,
      history,
      itemInfo,
      unitIdBussiness,
      isLoginModalOpen,
      toggleLoginModal,
      isBusinessSignUp,
      sendPasswordResetEmail,
      login,
      setIsBusinessSignUp,
      pauseDialog,
      modal,
      stateChangeHandler,
      signInWithApple,
      signInWithFacebook,
      _onNewsletterStateHandler,
      txt_email_field_placeholder
    } = this.props;
    const {
      modalContainer,
      modalHeader,
      modalBodyContainer,
      signUpSuggestionStyle,
      signUpStyle,
      loginFormSpaceBottom
    } = styles;
    // eslint-disable-next-line no-undef
    const hideCloseButton = localStorage.getItem('isExpolingua') === 'true';
    const closeBtn = (
      <ModalCloseButton
        className="close"
        onClick={() =>
          toggle(
            isLoginModalOpen,
            toggleLoginModal,
            isBusinessSignUp,
            setIsBusinessSignUp,
            pauseDialog,
            modal,
            stateChangeHandler
          )
        }
      >
        <img src={closeIcon} alt={closeIcon} />
      </ModalCloseButton>
    );

    return (
      <Fragment>
        {isResetPassword ? (
          <Modal
            isOpen={isOpen}
            style={modalContainer}
            contentClassName="contentContainer"
            centered
          >
            {isEmailSent ? (
              <ResetPasswordModal
                closeEmailSent={() =>
                  closeEmailSent(isResetPassword, stateChangeHandler)
                }
                reset_password_txt_description={reset_password_txt_description}
              />
            ) : (
              <Fragment>
                {isLoading ? (
                  <ModalHeader style={modalHeader} external={null}>
                    {login_txt_forget_password_title}
                  </ModalHeader>
                ) : (
                  <ModalHeader close={closeBtn} style={modalHeader}>
                    {login_txt_forget_password_title}
                  </ModalHeader>
                )}
                <ModalBodyContainer isLoading={isLoading}>
                  <LingLoading src={lingLoading} />
                </ModalBodyContainer>
                <ModalBodyContainer isLoading={!isLoading}>
                  <ModalResetPasswordContainer>
                    <ModalBody style={modalBodyContainer}>
                      <ModalBodyText>
                        <ResetPasswordPage
                          btn_continue={btn_continue}
                          handleResetPasswordSubmit={handleResetPasswordSubmit}
                          email_error_fieldsMissing={email_error_fieldsMissing}
                          email_error_wrongFormat={email_error_wrongFormat}
                          errorMsgFirebase={errorMsgFirebase}
                          login_field_email={login_field_email}
                          user={user}
                          sendPasswordResetEmail={sendPasswordResetEmail}
                          modalStateChangeHandler={this.modalStateChangeHandler}
                          stateChangeHandler={stateChangeHandler}
                          successMsg={successMsg}
                        />
                      </ModalBodyText>
                    </ModalBody>
                  </ModalResetPasswordContainer>
                </ModalBodyContainer>
              </Fragment>
            )}
          </Modal>
        ) : (
          <Modal
            isOpen={isOpen}
            backdrop={hideCloseButton ? false : true}
            keyboard={hideCloseButton ? false : true}
            toggle={() =>
              toggle(
                isLoginModalOpen,
                toggleLoginModal,
                isBusinessSignUp,
                setIsBusinessSignUp,
                pauseDialog,
                modal,
                stateChangeHandler
              )
            }
            style={modalContainer}
            contentClassName="contentContainer"
            centered
          >
            {isLoading ? (
              <ModalHeader style={modalHeader} external={null}>
                {isSignUp ? sign_up_txt_title : login_txt_title}
              </ModalHeader>
            ) : hideCloseButton ? (
              <ModalHeader style={modalHeader}>
                {isSignUp ? sign_up_txt_title : login_txt_title}
              </ModalHeader>
            ) : (
              <ModalHeader
                toggle={() =>
                  toggle(
                    isLoginModalOpen,
                    toggleLoginModal,
                    isBusinessSignUp,
                    setIsBusinessSignUp,
                    pauseDialog,
                    modal,
                    stateChangeHandler
                  )
                }
                close={closeBtn}
                style={modalHeader}
              >
                {isSignUp ? sign_up_txt_title : login_txt_title}
              </ModalHeader>
            )}
            <ModalContentContainer>
              <ModalBodyContainer isLoading={isLoading}>
                <LingLoading src={lingLoading} />
              </ModalBodyContainer>
              <ModalBodyContainer isLoading={!isLoading}>
                <ModalBody style={modalBodyContainer}>
                  <ModalBodyText>
                    {isSignUp ? (
                      <SignUpModalFormModal
                        handleSignUpSubmit={e =>
                          handleSignUpSubmit(
                            e,
                            email_error_matchPasswords,
                            email_error_fieldsMissing,
                            email_error_longerPassword,
                            email_error_wrongFormat,
                            err,
                            user,
                            signUpWithEmail,
                            this.modalStateChangeHandler,
                            stateChangeHandler,
                            this.handleValidated
                          )
                        }
                        sign_in_opt_msg={sign_in_opt_msg}
                        isNewsletterEnable={isNewsletterEnable}
                        _onNewsletterStateHandler={_onNewsletterStateHandler}
                        handleOnChange={this.handleOnChange}
                        errorMsgFirebase={errorMsgFirebase}
                        login_field_email={login_field_email}
                        sign_up_txt_title={sign_up_txt_title}
                        user={user}
                        login_field_name={login_field_name}
                        login_field_firstName={login_field_firstName}
                        login_msg_reenterPassword={login_msg_reenterPassword}
                        login_field_password={login_field_password}
                        at_least_6_password_txt={at_least_6_password_txt}
                        passwordErrorMsg={passwordErrorMsg}
                        emailErrorMsg={emailErrorMsg}
                        err={err}
                        formErrorMsg={formErrorMsg}
                        fetchUnitData={fetchUnitData}
                        targetLangCode={targetLangCode}
                        nativeLangCode={nativeLangCode}
                        toggleCheckout={toggleCheckout}
                        history={history}
                        itemInfo={itemInfo}
                        unitIdBussiness={unitIdBussiness}
                        isLoginModalOpen={isLoginModalOpen}
                        toggleLoginModal={toggleLoginModal}
                        isBusinessSignUp={isBusinessSignUp}
                        setIsBusinessSignUp={setIsBusinessSignUp}
                        pauseDialog={pauseDialog}
                        modal={modal}
                        stateChangeHandler={stateChangeHandler}
                        signInWithGoogle={signInWithGoogle}
                        signInWithFacebook={signInWithFacebook}
                        signInWithApple={signInWithApple}
                        txt_email_field_placeholder={
                          txt_email_field_placeholder
                        }
                      />
                    ) : (
                      <LoginModalFormModal
                        handleOnChange={this.handleOnChange}
                        stateChangeHandler={stateChangeHandler}
                        fetchUnitData={fetchUnitData}
                        targetLangCode={targetLangCode}
                        nativeLangCode={nativeLangCode}
                        toggleCheckout={toggleCheckout}
                        history={history}
                        itemInfo={itemInfo}
                        unitIdBussiness={unitIdBussiness}
                        isLoginModalOpen={isLoginModalOpen}
                        toggleLoginModal={toggleLoginModal}
                        isBusinessSignUp={isBusinessSignUp}
                        setIsBusinessSignUp={setIsBusinessSignUp}
                        pauseDialog={pauseDialog}
                        modal={modal}
                        handleLoginSubmit={e =>
                          handleLoginSubmit(
                            e,
                            email_error_matchPasswords,
                            email_error_fieldsMissing,
                            email_error_longerPassword,
                            email_error_wrongFormat,
                            err,
                            user,
                            login,
                            this.modalStateChangeHandler,
                            stateChangeHandler
                          )
                        }
                        errorMsgFirebase={errorMsgFirebase}
                        login_field_email={login_field_email}
                        user={user}
                        login_field_password={login_field_password}
                        at_least_6_password_txt={at_least_6_password_txt}
                        passwordErrorMsg={passwordErrorMsg}
                        emailErrorMsg={emailErrorMsg}
                        err={err}
                        formErrorMsg={formErrorMsg}
                        toggleResetPassword={() =>
                          onToggleResetPassword(
                            isResetPassword,
                            stateChangeHandler,
                            this.modalStateChangeHandler
                          )
                        }
                        login_txt_forget_password_web={
                          login_txt_forget_password_web
                        }
                        login_txt_title={login_txt_title}
                        signInWithGoogle={signInWithGoogle}
                        signInWithFacebook={signInWithFacebook}
                        signInWithApple={signInWithApple}
                        txt_email_field_placeholder={
                          txt_email_field_placeholder
                        }
                      />
                    )}
                  </ModalBodyText>
                  <span style={signUpSuggestionStyle}>
                    {!isSignUp ? (
                      <Fragment>
                        <BottomText>
                          {dont_have_account_yet_txt}{' '}
                          <LoginLink
                            style={signUpStyle}
                            onClick={() =>
                              onToggleSignUp(
                                isSignUp,
                                isResetPassword,
                                stateChangeHandler,
                                this.modalStateChangeHandler
                              )
                            }
                          >
                            {sign_up_txt_title}
                          </LoginLink>
                        </BottomText>
                        <div style={loginFormSpaceBottom} />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <BottomText>
                          {already_register_txt}{' '}
                          <LoginLink
                            style={signUpStyle}
                            onClick={() =>
                              onToggleSignUp(
                                isSignUp,
                                isResetPassword,
                                stateChangeHandler,
                                this.modalStateChangeHandler
                              )
                            }
                          >
                            {email_txt_loginNow_link}
                          </LoginLink>
                        </BottomText>
                      </Fragment>
                    )}
                  </span>
                </ModalBody>
              </ModalBodyContainer>
            </ModalContentContainer>
          </Modal>
        )}
      </Fragment>
    );
  }
}
export { SignUpModal };
