/* @flow */
import { SPEAKING_ANSWER, SPEAKING_CLEAR_ERROR } from '../constants';
import { increaseAnsweredWrong } from './GamescreenActions';
import { getLabel } from '../../util';
import { toggleCustomDialog } from '../../common/actions';
import Analytic from '../../util/Analytic';

export const checkSpeakingAnswer = (numberErrors: number) => {
  return (dispatch: Function, getState: Function) => {
    if (numberErrors === 0) {
      dispatch({
        type: SPEAKING_ANSWER,
        answeredWrong: false
      });
    } else {
      dispatch({
        type: SPEAKING_ANSWER,
        answeredWrong: true
      });
      dispatch(increaseAnsweredWrong());
    }
  };
};
export const clearSpeakingErrorMsg = () => {
  return (dispatch: Function, getState: Function) => {
    dispatch({
      type: SPEAKING_CLEAR_ERROR
    });
  };
};

export const speakingGameOffline = () => (
  dispatch: Function,
  getState: Function
) => {
  dispatch(
    toggleCustomDialog(
      getLabel(
        getState(),
        'modal_txt_speaking_offline',
        'Please enable internet access for the speaking game.'
      )
    )
  );
};

export const speakingGameNoMatch = () => (
  dispatch: Function,
  getState: Function
) => {
  dispatch(
    toggleCustomDialog(
      getLabel(
        getState(),
        'modal_txt_speaking_no_match',
        'Please speak more slowly and try again.'
      )
    )
  );
};

export const speakingGameDefaultError = () => (
  dispatch: Function,
  getState: Function
) => {
  dispatch(
    toggleCustomDialog(
      getLabel(
        getState(),
        'modal_txt_speaking_default_error',
        'Unknown error. Please contact our support.'
      )
    )
  );
};
export const resetSpeakingErrorMsg = () => (
  dispatch: Function,
  getState: Function
) => {
  dispatch(toggleCustomDialog(''));
};
export const logSpeakingGame = (answer: boolean) => {
  return (dispatch: Function, getState: Function) => {
    const { game, routes } = getState();
    Analytic.log(Analytic.key.G1, {
      game_type: routes.gameType,
      content: game.key,
      answer: answer
    });
    Analytic.logGA(Analytic.key.G1, {
      category: Analytic.key.G1,
      action: routes.gameType,
      label: answer
    });
  };
};
