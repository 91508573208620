/* @flow */
import _ from 'lodash';
import {
  CARD_NAME,
  CARD_NUMBER,
  CARD_EXPIRY,
  CARD_CVC
} from '../common/constants';
export const getEmailLoginError = (
  { code, message }: Object,
  email_error_alreadyUsed: string,
  email_error_userNotFound: string,
  email_error_wrongPassword: string
) => {
  switch (code) {
    case 'auth/user-not-found':
      return {
        message: email_error_userNotFound
      };
    case 'auth/wrong-password':
      return {
        message: email_error_wrongPassword
      };
    case 'auth/email-already-in-use':
      return {
        message: email_error_alreadyUsed
      };
    default:
      return {
        message
      };
  }
};

export const validate = (
  isLoginForm: boolean,
  email_error_matchPasswords: string,
  email_error_fieldsMissing: string,
  email_error_longerPassword: string,
  email_error_wrongFormat: string,
  userForm: Object,
  stateChangeHandler: Function
) => {
  let isError = false;

  if (isLoginForm) {
    const { email, password } = userForm;
    if (email.length === 0 || password.length === 0) {
      isError = true;
      stateChangeHandler('formErrorMsg', email_error_fieldsMissing);
    } else {
      if (!isValidEmail(email)) {
        isError = true;
        stateChangeHandler('emailErrorMsg', email_error_wrongFormat);
      }
      if (password.length < 6) {
        isError = true;
        stateChangeHandler('passwordErrorMsg', email_error_longerPassword);
      }
    }
  } else {
    const { name, email, password, confirmPassword } = userForm;
    if (
      name.length === 0 ||
      email.length === 0 ||
      password.length === 0 ||
      confirmPassword.length === 0
    ) {
      isError = true;
      stateChangeHandler('formErrorMsg', email_error_fieldsMissing);
    } else {
      if (!isValidEmail(email)) {
        isError = true;
        stateChangeHandler('emailErrorMsg', email_error_wrongFormat);
      }
      if (password.length < 6) {
        isError = true;
        stateChangeHandler('passwordErrorMsg', email_error_longerPassword);
      }
      if (confirmPassword !== password) {
        isError = true;
        stateChangeHandler('passwordErrorMsg', email_error_matchPasswords);
      }
    }
  }

  console.log(isError);
  return isError;
};

export const validateResetPaswordForm = (
  email_error_fieldsMissing: string,
  email_error_wrongFormat: string,
  userForm: Object,
  stateChangeHandler: Function
) => {
  const { email } = userForm;
  let isError = false;
  let emailErrorMsg, formErrorMsg;

  if (email.length === 0) {
    isError = true;
    formErrorMsg = email_error_fieldsMissing;
  } else {
    if (!isValidEmail(email)) {
      isError = true;
      emailErrorMsg = email_error_wrongFormat;
    }
  }
  stateChangeHandler('formErrorMsg', formErrorMsg);
  stateChangeHandler('emailErrorMsg', emailErrorMsg);

  return isError;
};

export const toggleEmailSent = (
  stateChangeHandler: Function,
  isResetPassword: boolean
) => {
  stateChangeHandler('isResetPassword', !isResetPassword);
  stateChangeHandler('isEmailSent', false);
};

export const isValidEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateFormInput = (
  isLoginForm: boolean,
  user: Object,
  email_error_matchPasswords: string,
  email_error_fieldsMissing: string,
  email_error_longerPassword: string,
  email_error_wrongFormat: string,
  errorMsgFirebase: string
) => {
  let isError = false;
  let emailErrorMsg = '';
  let passwordErrorMsg = '';
  let formErrorMsg = '';
  if (isLoginForm) {
    const { email = '', password = '' } = user;
    if (email.length === 0 || password.length === 0) {
      isError = true;
      formErrorMsg = email_error_fieldsMissing;
    } else {
      if (!isValidEmail(email)) {
        isError = true;
        emailErrorMsg = email_error_wrongFormat;
      }
      if (password.length < 6) {
        isError = true;
        passwordErrorMsg = email_error_longerPassword;
      }
    }
  } else {
    const { name = '', email = '', password = '', confirmPassword = '' } = user;
    if (
      name.length === 0 ||
      email.length === 0 ||
      password.length === 0 ||
      confirmPassword.length === 0
    ) {
      isError = true;
      formErrorMsg = email_error_fieldsMissing;
    } else {
      if (!isValidEmail(email)) {
        isError = true;
        emailErrorMsg = email_error_wrongFormat;
      }
      if (password.length < 6) {
        isError = true;
        passwordErrorMsg = email_error_longerPassword;
      }
      if (confirmPassword !== password) {
        isError = true;
        passwordErrorMsg = email_error_matchPasswords;
      }
    }
  }
  return {
    isError,
    emailErrorMsg,
    passwordErrorMsg,
    formErrorMsg
  };
};

export const resetUser = (stateChangeHandler: Function) => {
  stateChangeHandler('userForm', {
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
};

export const resetPassoword = async (
  email: string,
  sendPasswordResetEmail: Function,
  stateChangeHandler: Function
) => {
  await sendPasswordResetEmail(email);
  stateChangeHandler('isEmailSent', true);
};

export const validateForm = (
  id: string,
  cardName: string = '',
  validCardNumber: boolean,
  validCardExpiry: boolean,
  validCardCvc: boolean
) => {
  switch (id) {
    case CARD_NAME:
      return cardName.length === 0 ? false : true;
    case CARD_NUMBER:
      return validCardNumber;
    case CARD_EXPIRY:
      return validCardExpiry;
    case CARD_CVC:
      return validCardCvc;
    default:
      break;
  }
};

export const validPaymentForm = (
  cardName: string,
  validCardNumber: boolean,
  validCardExpiry: boolean,
  validCardCvc: boolean
) => cardName && validCardNumber && validCardExpiry && validCardCvc;

export const isUpdatePayment = (
  subscription: Object,
  subscriptionData: Object,
  isProUser: boolean,
  isUpdateMethod: boolean
) =>
  subscription && subscription.isPromo && isProUser
    ? true
    : !(_.isEmpty(subscriptionData) && isProUser) && !isUpdateMethod;
