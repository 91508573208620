/* @flow */
import * as React from 'react';
import styled from 'styled-components';
import { PREFIX_HEADER, WHITE, GREY } from '../common/constants';
import { CheckButton } from './components/sub-components';
import { BUTTON_CONTINUE, DIALOGUE } from './constants';

type Props = {
  pressCloseMessage: Function,
  isShowTutorial: boolean,
  btn_continue: string,
  tutorial_txt_easy_learn: string,
  writing_skip: string,
  onCloseTutorialMotivate: Function,
  setShowTutorial: Function
};

type State = {
  imageIsReady: boolean
};

const TutorialMessageContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-item: center;
  width: 100%;
  justify-content: flex-end;
  bottom: 150px;
  position: absolute;
`;
const ImageStyle = styled.img`
  height: 150px;
`;
const TutorialMessageCardContainer = styled.div`
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 3;
  border-radius: ${props => (props.isShowTutorial ? '40px 40px 0 0' : 'unset')};
`;

const TutorialMsgText = styled.p`
  font-size: 16px;
  background: ${WHITE};
  position: relative;
  border: solid 1px ${GREY};
  padding: 20px 40px;
  border-radius: 10px;
  height: 48px;
  justify-content: center;
  align-items: center;
  display: flex;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 20px;
    height: 20px;
    width: 20px;
    background: ${WHITE};
    transform: translateX(-50%) rotate(135deg);
    border-bottom: inherit;
    border-right: inherit;
    box-shadow: inherit;
  }
`;

const lingHelloImg = `${PREFIX_HEADER}master-ling-hi-circle-image.svg`;

class TutorialMessageCard extends React.Component<Props, State> {
  constructor(props: Object) {
    super(props);
    this.state = {
      imageIsReady: false
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-undef
    const img = new Image();
    img.src = lingHelloImg;
    img.onload = () => {
      // when it finishes loading, update the component state
      this.setState({ imageIsReady: true });
    };
    // $flow-disable-line
    document.addEventListener('keydown', this.handleEnter);
  }
  componentWillUnmount() {
    // $flow-disable-line
    document.removeEventListener('keydown', this.handleEnter);
  }
  handleEnter = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { pressCloseMessage } = this.props;
    if (event.keyCode === 13 && pressCloseMessage) {
      pressCloseMessage();
    }
  };

  render() {
    const {
      pressCloseMessage,
      btn_continue,
      tutorial_txt_easy_learn,
      isShowTutorial,
      writing_skip,
      onCloseTutorialMotivate,
      setShowTutorial
    } = this.props;
    const { imageIsReady } = this.state;

    if (imageIsReady) {
      return (
        <React.Fragment>
          <TutorialMessageCardContainer isShowTutorial={isShowTutorial}>
            <TutorialMessageContainer>
              <TutorialMsgText>{tutorial_txt_easy_learn}</TutorialMsgText>
              <ImageStyle src={lingHelloImg} alt={lingHelloImg} />
            </TutorialMessageContainer>
            <CheckButton
              onPressNext={pressCloseMessage}
              mode={BUTTON_CONTINUE}
              btn_continue={btn_continue}
              gameType={DIALOGUE}
              answeredWrong={false}
              isShowTutorial={isShowTutorial}
              writing_skip={writing_skip}
              onCloseTutorialMotivate={onCloseTutorialMotivate}
              setShowTutorial={setShowTutorial}
            />
          </TutorialMessageCardContainer>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

export { TutorialMessageCard };
