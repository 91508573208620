import * as React from 'react';
import styled from 'styled-components';
import { QuizCard } from '../sub-components/QuizCard';

const QuizCardContainer = styled.div`
  margin: 0 ${props => (props.isShowTutorial ? 2 : 10)}px;
`;

class QuizCardsWrapper extends React.Component {
  render() {
    const {
      card,
      onPressCard,
      transcriptState,
      isShowImage,
      isShowTutorial,
      isTargetLangSymbol,
      index
    } = this.props;
    return (
      <QuizCardContainer onClick={onPressCard} isShowTutorial={isShowTutorial}>
        <QuizCard
          index={index}
          isShowImage={isShowImage}
          transcriptState={transcriptState}
          card={card}
          isShowTutorial={isShowTutorial}
          isTargetLangSymbol={isTargetLangSymbol}
        />
      </QuizCardContainer>
    );
  }
}

export { QuizCardsWrapper };
