/* @flow */
import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import {
  GREYISH_BROWN,
  PREFIX_LESSON,
  WHITE,
  GREY,
  GREY2,
  MARIGOLD
} from '../../../common/constants';

const ModalImage = styled.img`
  padding: 35px 38px;
  background: ${MARIGOLD};
  border-radius: 65px;
`;

const ModalTitle = styled.h1`
  font-size: 28px;
  margin-bottom: 13px;
  color: ${GREYISH_BROWN};
  font-weight: bold;
  margin-top: 0px !important;
`;

const ModalBodyText = styled.p`
  font-size: 18px;
  color: ${GREYISH_BROWN};
  line-height: 31px;
  margin: 0px;
  margin-top: 13px !important;
`;

const ModalContentContainer = styled.div`
  margin: auto 25px;
`;

const styles = {
  modalContainer: {
    width: '485px',
    height: '457px',
    textAlign: 'center',
    borderRadius: 30
  },
  modalBodyContainer: {
    padding: 0,
    paddingBottom: 20,
    textAlign: 'center'
  },
  buttonStyle: {
    borderRadius: 15,
    width: 160,
    height: 55,
    background: WHITE,
    border: 'solid 1px',
    borderColor: GREY2,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    boxShadowColor: GREY2,
    fontWeight: 'bold',
    fontSize: 18,
    margin: 'auto',
    paddingRight: 56,
    paddingLeft: 56
  },
  cancelButtonStyle: {
    border: 'none',
    boxShadow: 'none',
    width: 100
  },
  confirmButtonStyle: {
    borderColor: GREY2,
    background: WHITE,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    width: 133,
    height: 41
  },
  modalHeader: {
    borderBottom: 'none',
    flexDirection: 'row-reverse',
    justifyContent: 'normal',
    padding: 0,
    marginTop: 44,
    marginBottom: 29
  },
  modalFooter: {
    borderTop: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 30,
    paddingRight: 32,
    paddingLeft: 32,
    paddingTop: 22
  },
  contentContainer: {
    borderRadius: 30
  },
  modalHeaderContainer: {
    'modal-title': 'w-100 text-center'
  }
};

const closeMicIcon = `${PREFIX_LESSON}microphone-with-slash-interface-symbol-for-mute-audio.svg`;
type Props = {
  isOpen: boolean,
  goToHome: Function,
  modal_txt_speaking_no_supported_title: string,
  afterBuy_txt_back: string,
  modal_txt_speaking_no_supported_desc: string
};

const SpeakingNotSupportModal = ({
  isOpen,
  goToHome,
  modal_txt_speaking_no_supported_title,
  afterBuy_txt_back,
  modal_txt_speaking_no_supported_desc
}: Props) => {
  const {
    modalHeader,
    modalBodyContainer,
    modalFooter,
    buttonStyle,
    confirmButtonStyle,
    modalContainer
  } = styles;
  return (
    <Modal
      centered
      style={modalContainer}
      isOpen={isOpen}
      contentClassName="contentContainer"
    >
      <ModalHeader style={modalHeader}>
        <ModalImage src={closeMicIcon} alt={closeMicIcon} />
      </ModalHeader>
      <React.Fragment>
        <ModalContentContainer>
          <ModalBody style={modalBodyContainer}>
            <React.Fragment>
              <ModalTitle>{modal_txt_speaking_no_supported_title}</ModalTitle>
              <ModalBodyText>
                {modal_txt_speaking_no_supported_desc}
              </ModalBodyText>
            </React.Fragment>
          </ModalBody>
        </ModalContentContainer>

        <ModalFooter style={modalFooter}>
          <Button
            className="confirmButton"
            style={{ ...buttonStyle, ...confirmButtonStyle }}
            onClick={goToHome}
          >
            {afterBuy_txt_back}
          </Button>
        </ModalFooter>
      </React.Fragment>
    </Modal>
  );
};

export { SpeakingNotSupportModal };
