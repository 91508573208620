/* @flow */
import _ from 'lodash';
import BugTracker from './../../util/BugTracker';
import publicIp from 'public-ip';
import hash from 'hash.js';
import { IOS_APP_UNIVERSAL_ID } from '../../shop/constants';
import { saveUsersData } from './GlobalActions';
export const InviteFriend = async (userData: Object) => {
  let AndroidPrefix = 'com.simyasolutions.ling';
  let IOSPreix = 'com.sanfanstudios.thai';
  let IOS_UNIVERSAL_ID = IOS_APP_UNIVERSAL_ID;
  const firebaseDynamicLinkPrefix = process.env.REACT_APP_DEV
    ? 'https://lingtest.page.link'
    : 'https://lingapp.page.link';
  let hashIp = '';
  const ip = await publicIp
    .v4({
      timeout: 3000
    })
    .then(async res => {
      return res;
    })
    .catch(e => {
      console.log('IP error', e);
    });
  const hashedPassword = hash
    .sha256()
    .update(ip)
    .digest('hex');

  hashIp = hashedPassword.replace(/[^a-zA-Z0-9]/g, '');

  const deepLink = `https://www.ling-app.com/?referralCode=${userData.uid}%26referralHashCode=${hashIp}`;
  const longDynamicLink = `${firebaseDynamicLinkPrefix +
    '?apn=' +
    AndroidPrefix +
    '&ibi=' +
    IOSPreix +
    '&isi=' +
    IOS_UNIVERSAL_ID +
    '&link=' +
    deepLink}`;
  try {
    return longDynamicLink;
  } catch (e) {
    //in case user cancel share error will throw handle here
    BugTracker.notify(e);
  }
};

export const getReferralCode = () => {
  // eslint-disable-next-line
  if (typeof Storage !== 'undefined' && localStorage.getItem('referralCode')) {
    // eslint-disable-next-line
    return localStorage.getItem('referralCode');
  } else {
    return null;
  }
};

export const setReferralCode = () => (
  dispatch: Function,
  getState: Function
) => {
  const state = getState();
  const user = state.storage.user;
  const inviteId = getReferralCode();
  // eslint-disable-next-line
  const referralHashCode = localStorage.getItem('referralHashCode');

  if (inviteId !== user.uid && _.get(user, 'referral', false) === false) {
    dispatch(
      saveUsersData({
        ...user,
        referral: inviteId,
        referralHashCode: referralHashCode
      })
    );
  }
};

export const setReferrerCode = (referrerCode: string) => (
  dispatch: Function,
  getState: Function
) => {
  const state = getState();
  const user = state.storage.user;
  if (referrerCode !== null) {
    dispatch(
      saveUsersData({
        ...user,
        referrer: referrerCode
      })
    );
  }
};
