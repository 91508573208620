/* @flow */
import React, { Component, Fragment } from 'react';
import DefaultHeaderContainer from '../../../common/containers/DefaultHeaderContainer';
import styled from 'styled-components';
import { PREFIX_HEADER } from '../../../common/constants';
import { AchievementList } from '../sub-components/AchievementList';

type Props = {
  achievement_txt_title: string,
  history: Object,
  levelChamp: number,
  levelSpender: number,
  levelDiligent: number,
  levelAchiever: number,
  levelWealthy: number,
  achievement_txt_diligentMaster: string,
  diligentDescription: string,
  achievement_txt_bigSpender: string,
  spenderDescription: string,
  achievement_txt_champion: string,
  championDescription: string,
  achievement_txt_wealthy: string,
  wealthyDescription: string,
  achievement_txt_overAchiever: string,
  overAchieveDescription: string
};

type State = {};

const BackArea = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 52px;
  &:hover {
    cursor: pointer;
  }
`;

const Title = styled.p`
  font-size: 21px;
  font-weight: bold;
  color: #454545;
  margin: 0 !important;
`;

const Image = styled.img`
  width: 18px;
  height: 32px;
  margin-right: 16px;
`;

const LeaderContainer = styled.div`
  margin: 110px auto 0;
  width: 77%;
`;

const LeaderContentContainer = styled.div``;

class AchievementScene extends Component<Props, State> {
  goToBack = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      achievement_txt_title,
      levelChamp,
      levelSpender,
      levelDiligent,
      levelAchiever,
      levelWealthy,
      achievement_txt_diligentMaster,
      diligentDescription,
      achievement_txt_bigSpender,
      spenderDescription,
      achievement_txt_champion,
      championDescription,
      achievement_txt_wealthy,
      wealthyDescription,
      achievement_txt_overAchiever,
      overAchieveDescription
    } = this.props;

    return (
      <Fragment>
        <DefaultHeaderContainer isSrollable={false} />
        <LeaderContainer>
          <LeaderContentContainer>
            <BackArea className="goBackButton" onClick={this.goToBack}>
              <Image src={`${PREFIX_HEADER}back-icon.svg`} />
              <Title>{achievement_txt_title}</Title>
            </BackArea>
            <AchievementList
              levelChamp={levelChamp}
              levelSpender={levelSpender}
              levelDiligent={levelDiligent}
              levelAchiever={levelAchiever}
              levelWealthy={levelWealthy}
              achievement_txt_diligentMaster={achievement_txt_diligentMaster}
              diligentDescription={diligentDescription}
              achievement_txt_bigSpender={achievement_txt_bigSpender}
              spenderDescription={spenderDescription}
              achievement_txt_champion={achievement_txt_champion}
              championDescription={championDescription}
              achievement_txt_wealthy={achievement_txt_wealthy}
              wealthyDescription={wealthyDescription}
              achievement_txt_overAchiever={achievement_txt_overAchiever}
              overAchieveDescription={overAchieveDescription}
            />
          </LeaderContentContainer>
        </LeaderContainer>
      </Fragment>
    );
  }
}

export { AchievementScene };
