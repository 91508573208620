/* @flow */

import { combineReducers } from 'redux';

// import data from './dataReducer';
import GlobalReducer from './GlobalReducer';
import TemporaryReducer from './TemporaryReducer';

import { MatchingGameReducer } from '../../games/reducers/MatchingGameReducer';

import { SpellingReducer } from '../../games/reducers/SpellingReducer';

import { SpeakingReducer } from '../../games/reducers/SpeakingReducer';
import { WritingReducer } from '../../games/reducers/WritingReducer';
import { DialogReducer } from '../../games/reducers/DialogReducer';
import { StatsReducer } from '../../games/reducers/StatsReducer';
import { FlashcardReducer } from '../../games/reducers/FlashcardReducer';
import { GameReducer } from '../../games/reducers/GameReducer';
import { GamescreenReducer } from '../../games/reducers/GamescreenReducer';
import { QuizReducer } from '../../games/reducers/QuizReducer';

import { LessonsReducer } from '../../lessons/reducers/LessonsSceneReducer';
import { UnitsSceneReducer } from '../../lessons/reducers/UnitsSceneReducer';
import { LoadLanguagesDataReducer } from '../../lessons/reducers/LoadLanguageDataReducer';
import { CoursesReducer } from '../../lessons/reducers/CoursesReducer';

import { ChatbotsReducers } from '../../chatbots/reducers/ChatbotsReducers';
import { ShopReducer } from '../../shop/reducers/ShopReducer';
import PurchaseReducer from '../../purchase/reducers/PurchaseReducer';
import ConfigReducer from '../../config/configReducer';
import OnboardingReducer from '../../onboarding/OnboardingReducer';
import TrackerReducer from '../../tracker/trackerReducer';

const reducers = combineReducers({
  config: ConfigReducer,
  game: GameReducer,
  matchingCardsDisplayed: MatchingGameReducer,
  data: LoadLanguagesDataReducer,
  storage: GlobalReducer,
  lessons: LessonsReducer,
  courses: CoursesReducer,
  units: UnitsSceneReducer,
  flashcard: FlashcardReducer,
  quizCards: QuizReducer,
  spelling: SpellingReducer,
  shop: ShopReducer,
  stats: StatsReducer,
  speaking: SpeakingReducer,
  tracker: TrackerReducer,
  writing: WritingReducer,
  onboarding: OnboardingReducer,
  routes: GamescreenReducer,
  dialog: DialogReducer,
  chatbots: ChatbotsReducers,
  temporary: TemporaryReducer,
  purchase: PurchaseReducer
});

export default reducers;
