/* @flow */
import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import Analytic from '../../../util/Analytic';
import {
  PREFIX_LESSON,
  LIGHT_GREY,
  WHITE,
  PREFIX_HEADER
} from '../../../common/constants';

const LessonContainer = styled.div`
  position: relative;
  width: 620px;
  height: 135px;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${LIGHT_GREY};
  background-color: ${WHITE};
  margin-bottom: 17px;
  display: flex;
  flex: 1;
  &:hover {
    cursor: pointer;
  }
`;

const FinishedIcon = styled.img`
  width: 70px;
  height: 70px;
  margin: 30px;
`;

const Title = styled.p`
  width: 318px;
  font-size: 18px;
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
`;

const ScoreContainer = styled.div`
  display: flex;
  margin-right: 35px;
  flex: 1;
  justify-content: flex-end;
  height: inherit;
  align-items: center;
`;

export const keyFrame = keyframes`
   0% { transform: rotate(0deg); }
  10% {  transform: rotate(-30deg); }
  20% {  transform: rotate(30deg); }
  30% {  transform: rotate(-40deg); }
  40% {  transform: rotate(20deg); }
  50% {  transform: rotate(0deg); }
  100% {  transform: rotate(0deg); }
`;
const HandWhite = styled.img`
  position: absolute;
  bottom: -20px;
  right: -20px;
  animation: ${keyFrame} 1.5s ease-in-out infinite;
  animation-delay: 1s;
  transform-origin: bottom center;
`;
const CheckIcon = styled.img`
  margin-right: 15px;
`;

const uncheckImg = `${PREFIX_LESSON}check-unactive-icon.svg`;
const checkImg = `${PREFIX_LESSON}check-active-icon.svg`;
const handWhite = `${PREFIX_HEADER}hand-white-60-px.png`;

const yesChecked = () => <CheckIcon src={checkImg} />;
const noChecked = () => <CheckIcon src={uncheckImg} />;

type Props = {
  title: string,
  crowns: number,
  finished: boolean,
  onPress: Function,
  index: number,
  isFirstTime: string
};

const LessonCard = ({
  title,
  crowns = 0,
  finished = false,
  index,
  isFirstTime,
  onPress = () => {}
}: Props) => {
  if (crowns !== 0) {
    Analytic.log(Analytic.key.L5, {
      category: Analytic.key.L5,
      action: 'Click'
    });
    Analytic.logGA(Analytic.key.L5, {
      category: Analytic.key.L5,
      action: 'Click'
    });
  }
  return (
    <LessonContainer onClick={onPress}>
      {isFirstTime === 'true' && index === 0 ? (
        <HandWhite src={handWhite} alt={handWhite} />
      ) : null}

      <FinishedIcon
        src={`${PREFIX_LESSON}${
          finished ? 'finish-ico-44x44.png' : 'basecolor-comment-70-px.svg'
        }`}
      />
      <Title>{title}</Title>
      <ScoreContainer>
        {crowns >= 1 ? yesChecked() : noChecked()}
        {crowns >= 2 ? yesChecked() : noChecked()}
        {crowns >= 3 ? yesChecked() : noChecked()}
      </ScoreContainer>
    </LessonContainer>
  );
};

export { LessonCard };
