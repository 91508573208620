/* @flow */
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import {
  GREY2,
  BLACK,
  BLACK_HOVER,
  VERY_LIGHT_GREY,
  WHITE,
  BLUE,
  VERY_LIGHT_PINK,
  PREFIX_LESSON,
  GREY
} from '../../../common/constants';

import {
  PlayAnimationWriting,
  ShowRedArrowWriting,
  WritingModeWriting,
  PlayAnimationWritingSwap
} from '../sub-components/writing';
import { playSound, stopSound } from '../../../util';
import {
  WRITING_MODE,
  PLAY_ANIMATION,
  SHOW_RED_ARROW,
  MISSING_STROKE,
  FREE_HANDWRITING,
  PRACTISE,
  DRAW_FAIL_LIMIT,
  BUTTON_CONTINUE,
  WRITING,
  KEYCODE_ENTER
} from '../../constants';
import { CheckButton } from '../sub-components';

const Container = styled.div`
  margin: auto;
  @media (max-height: 690px) {
    padding-top: 96px;
    padding-bottom: 115px;
  }
`;
const ContentContainer = styled.div``;

const InstructionsHeader = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${GREY2};
  text-align: center;
  margin-bottom: 0px !important;
`;

const TextStyle = styled.p`
  font-size: 26px;
  font-weight: bold;
  color: ${BLACK};
  text-align: center;
  margin-top: 2px !important;
  margin-bottom: 9px !important;
`;

const QuestionContainer = styled.div`
  position: relative;
  width: 140px;
  margin: auto;
  margin-top: 15px;
`;
const QuestionLine = styled.div`
  height: 2px;
  width: 140px;
  background: ${BLACK};
`;

const PlayButtonContainer = styled.div`
  border: solid 1px ${BLACK};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  bottom: -10px;
  margin-bottom: 5px;
  background: ${WHITE};
  cursor: pointer;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: -15px;
  &:hover {
    border-color: ${BLACK_HOVER};
    #buttonIcon {
      opacity: 0.4;
    }
  }
  &:active {
    background: ${BLACK};
    #buttonIcon1 {
      display: inline !important;
    }
  }
`;

const SpeakerImage = styled.img`
  position: relative;
`;

const SpeakerImageActive = styled.img`
  display: none;
  position: absolute;
  zindex: 99;
`;

const WritingBlank = styled.div`
  width: 616px;
  height: 325px;
  border-radius: 20px;
  border: solid 4px ${BLUE};
  display: flex;
  flex-direction: row;
`;

const TranscriptStyle = styled.p`
  font-size: 16px;
  color: ${VERY_LIGHT_GREY};
  margin-top: 18 !important;
  text-align: center;
`;
const WritingContainer = styled.div`
  margin-top: 33px;
`;
const TabButtonContainer = styled.div`
  height: inherit;
`;
const LeftSectionButton = styled.div`
  height: 106px;
  width: 116px;
  border: solid 4px ${VERY_LIGHT_PINK};
  background-color: ${WHITE};
  div {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
  }
  &.animationTabButton {
    border-radius: 15px 0 0 0;
    border: solid 4px
      ${props =>
        props.currentGameIndex === PLAY_ANIMATION ? BLUE : VERY_LIGHT_PINK};
    border-right: 0px;
    border-bottom: ${props =>
      props.currentGameIndex === WRITING_MODE
        ? `4px solid ${VERY_LIGHT_PINK}`
        : 0};
    box-shadow: ${props =>
      props.currentGameIndex === PLAY_ANIMATION
        ? `0px 4px 0px 0px ${BLUE}`
        : `4px 0px 0px 0px ${BLUE}`};
    z-index: ${props => (props.currentGameIndex === PLAY_ANIMATION ? 2 : 0)};
  }
  &.arrowTabButton {
    border: ${props =>
      props.currentGameIndex === SHOW_RED_ARROW
        ? `4px solid ${BLUE}`
        : `solid 4px ${VERY_LIGHT_PINK}`};
    border-bottom: ${props =>
      props.currentGameIndex !== WRITING_MODE
        ? props.currentGameIndex === SHOW_RED_ARROW
          ? `4px solid ${BLUE}`
          : `4px solid ${VERY_LIGHT_PINK}`
        : 0};
    border-right: 0px;
    border-top: ${props =>
      props.currentGameIndex === SHOW_RED_ARROW ? `4px solid ${BLUE}` : 0}px;
    box-shadow: ${props =>
      props.currentGameIndex === SHOW_RED_ARROW
        ? 'unset'
        : `4px 0 0 0 ${BLUE}`};
    z-index: ${props => (props.currentGameIndex === SHOW_RED_ARROW ? 2 : 0)};
    padding-right: ${props =>
      props.currentGameIndex === SHOW_RED_ARROW ? 3 : 0}px;
    padding-top: ${props =>
      props.currentGameIndex === SHOW_RED_ARROW ||
      props.currentGameIndex === WRITING_MODE
        ? 0
        : 4}px;
  }
  &.writingTabButton {
    border: ${props =>
      props.currentGameIndex === WRITING_MODE
        ? `4px solid ${BLUE}`
        : `4px solid ${VERY_LIGHT_PINK}`};
    border-top: ${props =>
      props.currentGameIndex === WRITING_MODE ? `4px solid ${BLUE}` : 0};
    border-radius: 0 0 0 15px;
    box-shadow: ${props =>
      props.currentGameIndex === WRITING_MODE ? 'unset' : `4px 0 0 0 ${BLUE}`};
    border-right: 0px;
    height: 105px;
    padding-right: ${props =>
      props.currentGameIndex === WRITING_MODE ? 3 : 0}px;
  }
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 116px;
  margin-top: -4px;
  margin-left: -4px;
`;
const RightSection = styled.div`
  width: 498px;
  height: 325px;
  background-image: url(${props => props.img});
  margin-left: 6px;
`;

const LeftButtonIcon = styled.img``;
const LeftButtonText = styled.p`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: ${props => (props.isBlue ? BLUE : BLACK)};
`;

type WritingCharacter = {
  id: string,
  original: string,
  transcript: string,
  path: string,
  guidePath: string,
  svgPath: string,
  svgGuidePath: string
};

type Props = {
  writing_txt_instruction: string,
  original: string,
  transcriptState: boolean,
  transcript: string,
  soundFile: string,
  voiceSpeed: number,
  gameMode: string,
  writingCharacter: WritingCharacter,
  isWritingFinished: boolean,
  onFinish: Function,
  charId: string,
  isGuideActive: boolean,
  nextGameScreen: Function,
  logWritingGame: Function,
  finishWriting: Function,
  btn_check: string,
  btn_continue: string,
  pressNext: Function,
  onSkip: Function,
  increaseGameSkipCount: Function,
  labels: Object
};

type State = {
  drawFailCounter: number,
  isShowAnimation: boolean,
  currentGameIndex: number,
  gamePlay: number,
  allGamesFinished: boolean,
  isShowGuide: boolean,
  isSkip: boolean
};

const speakerImage = require('../../../img/icons/outline-sound-icon.svg');
const speakerImageActive = require('../../../img/icons/outline-sound-icon-active.svg');
const paperBG = `${PREFIX_LESSON}line-paper-bg.png`;
const playBlue = `${PREFIX_LESSON}fill-play-blue-21-px.svg`;
const playBlack = `${PREFIX_LESSON}fill-play-black-21-px.svg`;
const featherBlue = `${PREFIX_LESSON}fill-feather-blue-21-px.svg`;
const featherBlack = `${PREFIX_LESSON}fill-feather-black-21-px.svg`;
const arrowBlack = `${PREFIX_LESSON}outline-stroke-black-arrow-24-px.svg`;
const arrowBlue = `${PREFIX_LESSON}outline-stroke-blue-arrow-24-px.svg`;

const INITIAL_STATE = {
  drawFailCounter: 0,
  currentGameIndex: 0,
  gamePlay: 0,
  isShowAnimation: false,
  allGamesFinished: false,
  isShowGuide: false,
  isSkip: false
};

class Writing extends Component<Props, State> {
  static defaultProps: Props;
  state: State;
  _container: ?Object;
  _canvas: ?Object;
  constructor(props: Props) {
    super(props);
    const initialIndex = 0;
    this.state = {
      ...INITIAL_STATE,
      currentGameIndex: initialIndex,
      gamePlay: initialIndex
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      writingCharacter: oldWritingCharacter,
      gameMode: oldGameMode
    } = this.props;
    const { writingCharacter, gameMode } = nextProps;
    if (oldWritingCharacter.original !== writingCharacter.original) {
      if (this.state.allGamesFinished) {
        this.setState({
          drawFailCounter: 0,
          allGamesFinished: false
        });
      }
      if (gameMode === MISSING_STROKE || FREE_HANDWRITING) {
        this.prepareGameState(2);
      }
      if (oldGameMode === PRACTISE) {
        this.prepareGameState(0);
      }
    }
    if (
      oldWritingCharacter.original !== writingCharacter.original &&
      gameMode === PRACTISE
    ) {
      this.prepareGameState(PLAY_ANIMATION);
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    // $flow-disable-line
    document.addEventListener('keydown', this.handleEnter);
  }
  componentWillUnmount() {
    // $flow-disable-line
    document.removeEventListener('keydown', this.handleEnter);
  }
  handleEnter = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === KEYCODE_ENTER) {
      this.getCheckButton();
    }
  };

  getNewGameMode(props: Props = this.props) {
    return this.isGame(props) ? WRITING_MODE : PLAY_ANIMATION;
  }

  goToNextGameMode(props: Props = this.props) {
    const { gameMode, charId, isGuideActive } = props;
    if (this.state.currentGameIndex !== PLAY_ANIMATION) {
      this.setState({ currentGameIndex: PLAY_ANIMATION });
    }
    this.props.onFinish(gameMode, charId, isGuideActive);
  }

  getShowGuideState(state: State = this.state) {
    const { drawFailCounter, gamePlay, isShowGuide } = state;
    if (drawFailCounter === DRAW_FAIL_LIMIT) {
      return true;
    }

    if (gamePlay !== PLAY_ANIMATION) {
      return true;
    }

    return isShowGuide;
  }

  getColorStroke(gameMode: string, gamePlay: number) {
    if (gamePlay === WRITING_MODE) {
      return null;
    }
    return GREY;
  }

  getStrokeDashoffset(props: Props = this.props) {
    return 0;
  }

  prepareGameState(gamePlay: number) {
    this.setState({
      gamePlay,
      isShowAnimation: false,
      currentGameIndex: gamePlay,
      isShowGuide: false,
      drawFailCounter: 0
    });
  }

  isGame(props: Props = this.props) {
    return [FREE_HANDWRITING, MISSING_STROKE].includes(props.gameMode);
  }

  getWritingComponent() {
    const { gamePlay, drawFailCounter, isSkip } = this.state;
    const {
      nextGameScreen,
      gameMode,
      charId,
      writingCharacter,
      logWritingGame,
      finishWriting
    } = this.props;

    const writingUIProps = {
      onCorrect: () => {
        this.setState({
          isShowGuide: false,
          drawFailCounter: 0
        });
        logWritingGame(true);
      },
      onDone: () => {
        finishWriting();
        if (gamePlay === SHOW_RED_ARROW) {
          setTimeout(() => {
            this.prepareGameState(WRITING_MODE);
          }, 1000);
        } else if (gamePlay === WRITING_MODE) {
          this.setState({ allGamesFinished: true });
          setTimeout(() => {
            nextGameScreen();
          }, 1000);
        }
      },
      onFail: () => {
        if (drawFailCounter < DRAW_FAIL_LIMIT) {
          this.setState({
            drawFailCounter: drawFailCounter + 1
          });
        }
      },
      strokeColor: this.getColorStroke.bind(this)(gameMode, gamePlay),
      id: charId,
      gameMode: gameMode,
      isShowGuide: this.getShowGuideState(),
      isFailed: drawFailCounter === DRAW_FAIL_LIMIT,
      isShowAnimation: gamePlay === PLAY_ANIMATION,
      guideCoordinates: writingCharacter ? writingCharacter.svgGuidePath : '',
      coordinates: writingCharacter ? writingCharacter.svgPath : '',
      strokeDashoffset: this.getStrokeDashoffset(),
      onAnimationFinished: () => {
        if (gamePlay === PLAY_ANIMATION) {
          if (!this.isGame()) {
            this.prepareGameState(SHOW_RED_ARROW);
          } else {
            this.prepareGameState(WRITING_MODE);
          }
        }
      },
      isLockTouches: gamePlay === PLAY_ANIMATION,
      gamePlay
    };

    switch (gamePlay) {
      case PLAY_ANIMATION:
        return isSkip ? (
          <PlayAnimationWriting {...writingUIProps} />
        ) : (
          <PlayAnimationWritingSwap {...writingUIProps} />
        );
      case SHOW_RED_ARROW:
        return <ShowRedArrowWriting {...writingUIProps} />;
      default:
        return <WritingModeWriting {...writingUIProps} />;
    }
  }

  resetState() {
    this.setState({
      ...INITIAL_STATE,
      allGamesFinished: this.state.allGamesFinished
    });
  }

  getCheckButton() {
    const {
      btn_check,
      btn_continue,
      onSkip = () => {},
      increaseGameSkipCount
    } = this.props;
    //if(isSKip true)
    if (true) {
      return (
        <CheckButton
          quizAnswer={''}
          mode={BUTTON_CONTINUE}
          btn_check={btn_check}
          btn_continue={btn_continue}
          gameType={WRITING}
          onPressNext={() => {
            stopSound();
            increaseGameSkipCount();

            onSkip();
          }}
        />
      );
    } else {
      return (
        <CheckButton
          quizAnswer={''}
          mode={BUTTON_CONTINUE}
          btn_check={btn_check}
          btn_continue={btn_continue}
          gameType={WRITING}
        />
      );
    }
  }

  render() {
    const {
      writing_txt_instruction,
      labels,
      original,
      transcriptState,
      transcript,
      voiceSpeed,
      charId
    } = this.props;

    const {
      game_writing_nav_animation,
      game_writing_nav_show_arrow,
      game_writing_nav_writing
    } = labels;

    const { currentGameIndex } = this.state;

    return (
      <Fragment>
        <Container>
          <ContentContainer>
            <InstructionsHeader>{writing_txt_instruction}</InstructionsHeader>
            <TextStyle>{original}</TextStyle>
            <QuestionContainer>
              <QuestionLine />
              <PlayButtonContainer
                onClick={() => playSound(`${charId}.mp3`, voiceSpeed)}
              >
                <SpeakerImage id="buttonIcon" src={speakerImage} />
                <SpeakerImageActive id="buttonIcon1" src={speakerImageActive} />
              </PlayButtonContainer>
            </QuestionContainer>
            {transcriptState ? (
              <TranscriptStyle>{transcript}</TranscriptStyle>
            ) : (
              ''
            )}
          </ContentContainer>
          <WritingContainer>
            <WritingBlank>
              <LeftSection>
                <LeftSectionButton
                  currentGameIndex={currentGameIndex}
                  className="animationTabButton"
                  onClick={() => {
                    this.prepareGameState.bind(this)(0);
                  }}
                >
                  <TabButtonContainer>
                    {currentGameIndex === PLAY_ANIMATION ? (
                      <LeftButtonIcon src={playBlue} />
                    ) : (
                      <LeftButtonIcon src={playBlack} />
                    )}
                    <LeftButtonText
                      isBlue={currentGameIndex === PLAY_ANIMATION}
                    >
                      {game_writing_nav_animation}
                    </LeftButtonText>
                  </TabButtonContainer>
                </LeftSectionButton>
                <LeftSectionButton
                  currentGameIndex={currentGameIndex}
                  className="arrowTabButton"
                  onClick={() => {
                    this.prepareGameState.bind(this)(SHOW_RED_ARROW);
                  }}
                >
                  <TabButtonContainer>
                    {currentGameIndex === SHOW_RED_ARROW ? (
                      <LeftButtonIcon src={arrowBlue} />
                    ) : (
                      <LeftButtonIcon src={arrowBlack} />
                    )}
                    <LeftButtonText
                      isBlue={currentGameIndex === SHOW_RED_ARROW}
                    >
                      {game_writing_nav_show_arrow}
                    </LeftButtonText>
                  </TabButtonContainer>
                </LeftSectionButton>
                <LeftSectionButton
                  currentGameIndex={this.state.currentGameIndex}
                  className="writingTabButton"
                  onClick={() => {
                    this.prepareGameState.bind(this)(WRITING_MODE);
                  }}
                >
                  <TabButtonContainer>
                    {currentGameIndex === WRITING_MODE ? (
                      <LeftButtonIcon src={featherBlue} />
                    ) : (
                      <LeftButtonIcon src={featherBlack} />
                    )}
                    <LeftButtonText isBlue={currentGameIndex === WRITING_MODE}>
                      {game_writing_nav_writing}
                    </LeftButtonText>
                  </TabButtonContainer>
                </LeftSectionButton>
              </LeftSection>
              <RightSection img={paperBG}>
                {this.getWritingComponent()}
              </RightSection>
            </WritingBlank>
          </WritingContainer>
        </Container>
        {this.getCheckButton()}
      </Fragment>
    );
  }
}

const WritingWithRouter = withRouter(Writing);
export { WritingWithRouter };
