/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { OrderSentence } from '../components/scenes';
import { getTopCharacterCards, getCharacterCards } from '../util';
import { getSpelling } from '../reducers';
import { getLabels, onPlaySound } from '../../util';
import { getTranscriptionState, getUserCoins } from '../../profile/selectors';
import { getVoiceSpeed } from '../../chatbots/selectors';
import { isLanguageRightToLeft } from '../../common/actions';

const OrderSentenceContainer = (props: Object) => (
  <OrderSentence
    {...props}
    getTopCharacterCards={getTopCharacterCards}
    getCharacterCards={getCharacterCards}
    onPlaySound={onPlaySound}
  />
);

export const mapStateToProps = (state: Object) => {
  const {
    courses: {
      nativeLanguageFeatures: { rtlLanguage: nativeRtlLanguage }
    },
    storage,
    routes
  } = state;
  const { isShowTutorial, isTargetLangSymbol, user } = storage;
  const { gameType } = routes;
  const { targetLangCode } = user;
  const voiceSpeed = getVoiceSpeed(state);
  const coins = getUserCoins(state);
  return {
    coins,
    ...getSpelling(state),
    nativeRtlLanguage,
    gameType,
    isTargetRightToLeftlLanguage: isLanguageRightToLeft(targetLangCode),
    transcriptState: getTranscriptionState(state),
    ...getLabels(
      state,
      'title_txt_sort',
      'btn_hint',
      'error_txt_game_wrong',
      'btn_check',
      'btn_continue',
      'writing_skip'
    ),
    isShowTutorial,
    isTargetLangSymbol,
    voiceSpeed
  };
};

export { OrderSentenceContainer };
export default connect(mapStateToProps, actions)(OrderSentenceContainer);
