/* @flow */
import React, { Component } from 'react';
import styled from 'styled-components';
import {
  WHITE,
  LIGHT_GREY,
  BLACK_OPACITY_SHADOW,
  PREFIX_CHATBOT,
  PREFIX_LESSON,
  PREFIX_HEADER,
  VERY_LIGHT_PINK_TWO,
  VERY_LIGHT_PINK_THREE,
  DARK_GREY,
  BROWN_GREY_TWO,
  MARIGOLD
} from '../../../common/constants';

const markIcon = `${PREFIX_LESSON}finish-ico-44x44.png`;
const lockImg = `${PREFIX_LESSON}lock-icon-28x28.png`;
const chatIcon = `${PREFIX_HEADER}outline-chat-icon-21-px.svg`;

type Props = {
  id: string,
  name: string,
  isActive: boolean,
  subText: string,
  isChatted: boolean,
  onPress: Function,
  chatbotOrder: number,
  scrollRef: Object
};

const CardContainer = styled.div`
  max-height: 135px;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 ${BLACK_OPACITY_SHADOW};
  border: solid 1px ${LIGHT_GREY};
  background-color: ${WHITE};
  display: flex;
  align-items: center;
  padding: 19px;
  margin-top: 10px;
  cursor: pointer;
`;
const ImgContainer = styled.div`
  flex: 0.2;
  max-width: 100px;
  min-width: 100px;
  height: 100px;
  background-image: url(${props => props.introImg});
  background-size: cover;
  background-position: left;
  border-radius: 50%;
  border: solid 1px ${VERY_LIGHT_PINK_TWO};
  background-color: ${VERY_LIGHT_PINK_THREE};
`;
const MarkImg = styled.img`
  width: inherit;
  height: inherit;
`;

const CardTitle = styled.div`
  flex: 0.7;
  margin-left: 40px;
`;
const CardTitleText = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${DARK_GREY};
  margin: 0px !important;
`;

const SubText = styled.p`
  color: ${BROWN_GREY_TWO};
  font-size: 14px;
  opacity: 0.5;
  margin: 0px !important;
`;

const ChatImage = styled.img`
  width: 24px;
  height: 24px;
  border: solid 1px white;
  padding: 10px;
  border-radius: 50%;
  background: ${MARIGOLD};
  margin-right: 10px;
`;

const LockImage = styled.img`
  margin-right: 10px;
`;

const CardStyle = styled.div`
  opacity: 0.5;
`;

const IconStyle = styled.div`
  flex: 0.1;
`;

export default class CardElement extends Component<Props> {
  renderChild() {
    const {
      name,
      isActive,
      subText,
      isChatted,
      onPress,
      id,
      chatbotOrder,
      scrollRef
    } = this.props;
    return (
      <CardContainer
        ref={scrollRef}
        onClick={async () => {
          onPress(chatbotOrder, id);
        }}
      >
        <ImgContainer
          introImg={`${PREFIX_CHATBOT}${id === 'chat09' ? 'chat06' : id}.png`}
        >
          {isChatted && <MarkImg src={markIcon} />}
        </ImgContainer>
        <CardTitle>
          <CardTitleText>{name}</CardTitleText>
          {subText ? <SubText>{subText}</SubText> : null}
        </CardTitle>
        <IconStyle>
          {!isActive && <LockImage src={lockImg} />}
          {subText && <ChatImage src={chatIcon} />}
        </IconStyle>
      </CardContainer>
    );
  }

  render() {
    const { isActive } = this.props;
    const card = this.renderChild();
    return !isActive ? <CardStyle>{card}</CardStyle> : card;
  }
}
