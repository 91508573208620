import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OnboardingScreen from './OnboardingScreen';
import {
  selectActiveScreen,
  selectCampaign,
  selectCampaignSelections,
  selectCampaignStatus,
  selectCompletedCampaigns,
  selectNativeLanguageCode,
  selectRedirectStatus,
  selectTargetLanguageName,
  selectTargetLanguageCode
} from '../selectors';
import * as OnboardActions from '../actions/OnboardingActions';
import * as TrackerActions from '../../tracker/actions/trackerActions';

import { getUserState } from '../../common/reducers/selectors';
import { getConfigState } from '../../config/selectors';
import { selectEventData } from '../../common/selectors';
import { Redirect } from 'react-router';
import CampaignList from '../data/CampaignList';
import { saveFunnelUtm } from '../funnelUtmHelper';
import { getLabels, getLanguageLabel } from '../../util/LabelHelper';

class OnboardingRoot extends Component {
  componentDidUpdate(prevProps, _prevState) {
    const { actions, state } = this.props;
    if (state.user.uid && state.user.anonymous.id !== state.user.uid) {
      // non anonymous
    }

    if (
      prevProps.state.onboarding.campaignComplete !==
      state.onboarding.campaignComplete
    ) {
      actions.tracker.requestTracking('campaign_complete', state.campaignData);
    }
  }

  componentDidMount() {
    const { actions, state } = this.props;
    if (
      !state.onboarding.completeCampaigns.includes(state.onboarding.campaignId)
    ) {
      actions.onboarding.resetCampaign();
    } else {
      if (
        !state.onboarding.campaignComplete ||
        state.onboarding.shopRedirected
      ) {
        this.props.history.push('/');
      }
    }
    saveFunnelUtm();
  }

  render() {
    const { actions, state, labels } = this.props;

    const campaign = CampaignList(state.localizedTargetLanguageName, labels)[
      state.onboarding.campaignId
    ];

    return state.onboarding.campaignComplete ? (
      !state.onboarding.shopRedirected ? (
        state.user.uid && state.user.anonymous.id !== state.user.uid ? (
          <Redirect
            to={{ pathname: '/shop', state: { source: 'onboarding' } }}
          />
        ) : (
          <Redirect push to="/signup" />
        )
      ) : (
        <Redirect to="/" />
      )
    ) : (
      <OnboardingScreen
        campaign={campaign}
        activeScreen={state.onboarding.activeScreen}
        campaignId={state.onboarding.campaignId}
        changeActiveScreen={actions.onboarding.changeActiveScreen}
        completeCampaign={actions.onboarding.completeCampaign}
        languageData={state.languageData}
        requestTracking={actions.tracker.requestTracking}
        selectionObject={state.onboarding.selectionObject}
        nativeLanguage={state.languageData[1]}
        updateSelections={actions.onboarding.updateSelections}
        labels={labels}
      />
    );
  }
}

const mapStateToProps = state => ({
  state: {
    config: getConfigState(state),
    onboarding: {
      activeScreen: selectActiveScreen(state),
      campaignComplete: selectCampaignStatus(state),
      campaignId: selectCampaign(state),
      completeCampaigns: selectCompletedCampaigns(state),
      selectionObject: selectCampaignSelections(state),
      shopRedirected: selectRedirectStatus(state)
    },
    user: getUserState(state),
    languageData: [
      selectTargetLanguageName(state),
      selectNativeLanguageCode(state)
    ],
    campaignData: selectEventData(state),
    localizedTargetLanguageName: getLanguageLabel(
      state,
      selectTargetLanguageCode(state)
    )
  },
  labels: getLabels(
    state,
    'btn_begin_quiz',
    'btn_cant_answer',
    'btn_continue_in_browser',
    'txt_2_minutes',
    'txt_3_minutes',
    'txt_3_months',
    'txt_5_minutes',
    'txt_adapting_topics',
    'txt_agree_with_statement',
    'txt_analyzing_goals',
    'txt_answer_simple_questions',
    'txt_aspects_to_improve',
    'txt_awards',
    'txt_can_speak_native',
    'txt_career',
    'txt_choose_a_password',
    'txt_daily_life',
    'txt_desc_answer_some_questions',
    'txt_desc_rate_skills',
    'txt_describe_experiences',
    'txt_discuss_complex_topics',
    'txt_dsc_check_awards',
    'txt_dsc_creating_account',
    'txt_dsc_download_app',
    'txt_dsc_explore_lessons',
    'txt_dsc_improve_pronunciation',
    'txt_dsc_learn_from_natives',
    'txt_dsc_learn_new_words',
    'txt_dsc_learning_in_browser',
    'txt_dsc_learning_methods',
    'txt_dsc_overlooked_languages',
    'txt_dsc_personalized_plan',
    'txt_dsc_practice_skills',
    'txt_dsc_review_vocabulary',
    'txt_dsc_save_profile',
    'txt_dsc_unlock_potential',
    'txt_elementary_level',
    'txt_email_field_placeholder',
    'txt_engaging_activities',
    'txt_enter_name',
    'txt_enter_password',
    'txt_error_cannot_blank',
    'txt_error_email_already_registered',
    'txt_error_invalid_email_address',
    'txt_error_password_must_contain',
    'txt_female',
    'txt_goal_for_learning',
    'txt_goal_level_basic_topics',
    'txt_goal_level_become_fluent',
    'txt_goal_level_become_guru',
    'txt_goal_level_express_opinions',
    'txt_goal_level_start_communicating',
    'txt_grow_your_skills',
    'txt_health',
    'txt_individualizing_plan',
    'txt_interactive_exercises',
    'txt_join_5mil_learners',
    'txt_language_styles',
    'txt_learning_basics',
    'txt_listening_skill',
    'txt_male',
    'txt_motivation_age',
    'txt_motivation_beginner_level',
    'txt_motivation_boost_career',
    'txt_motivation_connect_with_others',
    'txt_motivation_daily_reminder',
    'txt_motivation_expert_level',
    'txt_motivation_explore_background',
    'txt_motivation_for_travel',
    'txt_motivation_intermediate_level',
    'txt_motivation_language_selector',
    'txt_motivation_like_to_learn',
    'txt_motivation_live_abroad',
    'txt_motivation_other',
    'txt_motivation_study_time',
    'txt_native',
    'txt_no',
    'txt_other',
    'txt_overlooked_languages',
    'txt_philosophy',
    'txt_phrasal_verbs',
    'txt_planning_schedule',
    'txt_preparing_activities',
    'txt_preparing_plan',
    'txt_reading_skill',
    'txt_search_unit_title',
    'txt_select_language',
    'txt_spelling',
    'txt_statement_boost_career',
    'txt_statement_discuss_topics_with_friends',
    'txt_statement_ready_for_journeys',
    'txt_statement_relocate',
    'txt_statement_want_to_stay_informed',
    'txt_studying_frequency',
    'txt_technology',
    'txt_tenses',
    'txt_thanks',
    'txt_time_per_week',
    'txt_time_to_learn',
    'txt_times_per_week',
    'txt_topics',
    'txt_trust_with_5_million_learners',
    'txt_what_email',
    'txt_what_gender',
    'txt_what_name',
    'txt_words_per_week',
    'trial_txt_terms_of_service',
    'purchase_txt_policy',
    'txt_tell_us_about_yourself',
    'txt_how_old',
    'survey_question_2',
    'btn_continue',
    'unitLevel_txt_basic',
    'unitLevel_txt_beginner',
    'txt_native',
    'unitLevel_txt_intermediate',
    'unitLevel_txt_advance',
    'overview_txt_vocab',
    'unitLevel_txt_proficient',
    'survey_question_2_title_choice_1',
    'survey_question_2_title_choice_3',
    'units_txt_unit21',
    'units_txt_unit41',
    'units_txt_unit28',
    'survey_question_4_choice_1',
    'setting_txt_googleSearch',
    'survey_question_4_choice_3',
    'survey_question_4_choice_4',
    'survey_question_4_choice_fb_ig',
    'setting_txt_appMarket',
    'lesson_txt_writing',
    'overview_txt_grammar',
    'lesson_txt_speaking',
    'overview_txt_vocab',
    'txt_boost_language_skills',
    'btn_food',
    'survey_question_2_title_choice_2',
    'survey_question_2_title_choice_4',
    'survey_question_4',
    'txt_learning_basics',
    'txt_answer_simple_questions',
    'txt_describe_experiences',
    'txt_discuss_complex_topics',
    'txt_can_speak_native',
    'unitLevel_txt_basic',
    'modals_btt_yes',
    'modals_btt_no',
    'survey_question_4_choice_tiktok',
    'survey_question_4_choice_youtube',
    'txt_analyzing_goals',
    'txt_planning_schedule',
    'txt_individualizing_plan',
    'txt_preparing_activities',
    'txt_why_learn_language',
    'txt_how_your_language_skills'
  )
});

const mapDispatchToProps = dispatch => ({
  actions: {
    onboarding: bindActionCreators(OnboardActions, dispatch),
    tracker: bindActionCreators(TrackerActions, dispatch)
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingRoot);
