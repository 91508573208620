// this returns user's level based on average scores from survey averageScore >= 0 && averageScore <= 1, user level is 1, etc.
export const languageProficiency = averageScore => {
  if (averageScore >= 3.5 && averageScore <= 4) {
    return 5;
  }

  return Math.min(5, Math.floor(averageScore) + 1);
};

export const convertScoresToScale = scores => {
  return scores.map(value => Math.floor(value / 25));
};

export const calculateLanguageLevel = scores => {
  const totalScore = scores.reduce((sum, score) => sum + score, 0);
  const averageScore = totalScore / scores.length;
  const level = languageProficiency(averageScore);

  return level;
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
