import {
  CHECK_QUIZ_RESULT,
  CHECK_SPELLING_RESULT,
  SET_GAME_RESULT
} from '../games/constants';
import * as ConfigFilters from '../config/constants';
import * as OnboardingFilters from '../onboarding/filterConstants';

const updateMiddleware = store => next => action => {
  const state = store.getState();

  switch (action.type) {
    case CHECK_QUIZ_RESULT:
    case CHECK_SPELLING_RESULT:
      store.dispatch({
        spellingAnswerWrong: state.spelling.answeredWrong,
        quizAnswerWrong: state.quizCards.answeredWrong,
        type: SET_GAME_RESULT
      });
      next(action);
      break;
    case ConfigFilters.dataUpdate:
      store.dispatch({
        type: OnboardingFilters.ChangeCampaign,
        campaign: parseInt(action.data.campaignId._value, 2)
      });
      next(action);
      break;
    default:
      next(action);
      break;
  }
};

export default updateMiddleware;
