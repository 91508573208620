/* @flow */
// $FlowFixMe for useState
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { sprintf } from 'sprintf-js';
import {
  PREFIX_SHOP,
  WHITE,
  DARK_YELLOW,
  DARK_SEA_BLUE,
  MARIGOLD,
  DARK_BLUE,
  PREFIX_HEADER,
  DARK_BLUE_2,
  SLASH_SIGN
} from '../../../common/constants';
import DefaultHeaderContainer from '../../../common/containers/DefaultHeaderContainer';
import { SlidesPromotion } from './SlidesPromotion';
import {
  onNextPromo,
  isShowPromotion,
  isInviteFriend,
  isClaimReward
} from '../../../util';

const HideHeaderMenu = styled.div`
  visibility: hidden;
`;
const PromoteShopContainer = styled.div`
  background: ${DARK_SEA_BLUE};
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
const CarouselContainer = styled.div`
  margin-bottom: ${props => (props.isPromo ? 5 : 50)}px;
  z-index: 1;
`;
const GiftIconContainer = styled.button`
  z-index: 2;
  align-items: center;
  height: 52px;
  width: 83px;
  border-radius: 20px;
  background-color: ${DARK_BLUE};
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  box-shadow: inset 0 -4px 0 0 ${DARK_BLUE_2};
  border: solid 1px ${DARK_BLUE_2};
  justify-content: center;
  align-items: center;
  display: flex;
  padding-left: 17px;
  padding-right: 21px;
  margin-left: -16px;
  margin-top: 15px;
  position: absolute;
  align-self: flex-end;
  &:hover {
    box-shadow: none;
    border-color: ${DARK_BLUE};
  }
  &:active {
    color: ${WHITE};
    background: ${DARK_BLUE_2};
    box-shadow: none;
    border-color: ${DARK_BLUE_2};
  }
`;
const PromoButton = styled.button`
  width: 217.9px;
  min-height: 50px;
  border-radius: 15px;
  text-transform: capitalize;
  box-shadow: inset 0 -4px 0 0 ${DARK_YELLOW};
  border: solid 1px ${MARIGOLD};
  background-color: ${MARIGOLD};
  color: ${WHITE};
  z-index: 1;
  font-weight: bold;
  margin: auto;
  margin-top: 0px;
  &:hover {
    box-shadow: none;
    border-color: ${MARIGOLD};
  }
  &:active {
    background: ${DARK_YELLOW};
    box-shadow: none;
    border-color: ${DARK_YELLOW};
  }
`;
const FirewordBackGround = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 0;
`;
const HeroContentSubTextStyle = styled.div`
  line-height: initial;
  margin-top: 7px;
`;
const GiftNumber = styled.p`
  color: ${WHITE};
  font-size: 18px;
  margin: 0 !important;
  margin-left: 15px !important;
`;
const MainContainer = styled.div`
  margin-top: 50px;
  height: calc(100vh - 108px);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const RejectBtn = styled.button`
  width: 217.9px;
  height: 50px;
  margin-right: 45px;
  border-radius: 15px;
  box-shadow: inset 0 -4px 0 0 ${WHITE};
  border: solid 1px ${WHITE};
  background-color: ${DARK_SEA_BLUE};
  color: ${WHITE};
  z-index: 1;
  font-weight: bold;
  &:hover {
    box-shadow: none;
    border-color: ${WHITE};
  }
  &:active {
    color: ${DARK_SEA_BLUE};
    background: ${WHITE};
    box-shadow: none;
    border-color: ${WHITE};
  }
`;
const HeroContentStyle = styled.div`
  width: 400px;
  height: 185px;
  margin: auto;
  margin-bottom: ${props =>
    props.isPromo ? (props.isClaimReward ? 179 : 165) : 88}px;
  margin-top: ${props =>
    props.isPromo ? (props.isClaimReward ? '100px' : '93px') : 'unset'};
  text-align: center;
`;
const LiquidBackGround = styled.img`
  width: 677px;
  height: 519px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  bottom: 0;
  z-index: 0;
`;
const HeroContentImageContainerStyle = styled.div``;
const HeroContentImageStyle = styled.img`
  height: ${props => (props.isClaimReward ? 140 : 120)}px;
  margin-bottom: 10px;
  border-radius: 50%;
`;
const HeroContentHeaderStyle = styled.div`
  text-transform: capitalize;
  font-size: 21px;
  font-weight: bold;
`;
const HeroContentTextStyle = styled.div`
  color: ${WHITE};
  margin-top: ${props =>
    props.isPromo ? (props.isClaimReward ? 0 : 15) : 23}px;
  margin-bottom: 85px;
`;
const FooterContainer = styled.div`
  height: 108px;
  border-top: rgba(255, 255, 255, 0.13) solid 2px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: ${DARK_SEA_BLUE};
`;
type Props = {
  currentPromoId: number,
  isShowPromotionModal: boolean,
  trial_txt_no_thanks: string,
  history: Object,
  isPromo: boolean,
  activeIndex: number,
  child: any,
  onBuy: Function,
  promoDetail: Object,
  currency: string,
  promo_txt_get_discount: string,
  isShowInviteFriendModal: boolean,
  realTitle: string,
  realDesc1: string,
  realDesc2: string,
  ClaimReward: Function,
  referralCampaign: Object,
  invite_friend_modal_claim_reward: string,
  animating: any,
  promotion_txt_benifit_1: string,
  purchase_txt_benefit_details_05: string,
  promotion_txt_benifit_2: string,
  promotion_txt_benifit_des_2: string,
  promotion_txt_benifit_des_3: string,
  promotion_txt_benifit_3: string,
  promo_txt_benefit_05: string,
  isShowPromotionModal: boolean,
  isShowInviteFriendModal: boolean,
  stateChangeHandler: Function
};
const giftIcon = `${PREFIX_HEADER}fill-gift-25-px.png`;
const liquidBg = `${PREFIX_SHOP}liquid-bg-image@2x.png`;
const bgFirework = `${PREFIX_SHOP}background-firework.png`;
const giftBlue = `${PREFIX_SHOP}gift-blue-circle-100-px@2x.png`;
const promoBenefit02 = `${PREFIX_SHOP}ling-promo-benefit-02.png`;
const promoBenefit03 = `${PREFIX_SHOP}ling-promo-benefit-03.png`;
const promoBenefit04 = `${PREFIX_SHOP}ling-promo-benefit-04.png`;
const promoBenefit05 = `${PREFIX_SHOP}ling-promo-benefit-05.png`;

const PromotionScene = ({
  currentPromoId,
  isShowPromotionModal,
  trial_txt_no_thanks,
  history,
  isPromo,
  child,
  onBuy,
  promo_txt_get_discount,
  isShowInviteFriendModal,
  currency,
  realTitle,
  realDesc1,
  realDesc2,
  ClaimReward,
  invite_friend_modal_claim_reward,
  animating,
  promoDetail,
  promotion_txt_benifit_1,
  purchase_txt_benefit_details_05,
  promotion_txt_benifit_2,
  promotion_txt_benifit_des_2,
  promotion_txt_benifit_des_3,
  promotion_txt_benifit_3,
  referralCampaign,
  promo_txt_benefit_05,
  stateChangeHandler
}: Props) => {
  const [promoId, setPromoId] = useState([]);
  const [promoCount, setPromoCount] = useState(0);
  const promoteEntries = [
    {
      img: promoBenefit02,
      title: promotion_txt_benifit_1,
      des: purchase_txt_benefit_details_05
    },
    {
      img: promoBenefit03,
      title: promotion_txt_benifit_2,
      des: promotion_txt_benifit_des_2
    },
    {
      img: promoBenefit04,
      title: promotion_txt_benifit_3,
      des: promotion_txt_benifit_des_3
    },
    {
      img: promoBenefit05,
      title: promo_txt_benefit_05
    }
  ];
  useEffect(() => {
    [
      isShowPromotion(isShowPromotionModal, setPromoId, promoId),
      isInviteFriend(
        isShowInviteFriendModal,
        referralCampaign,
        setPromoId,
        promoId
      )
    ].forEach(v => (v ? setPromoCount[promoCount + 1] : v));
    return () => {};
  }, []);

  const { percentOff, type: promoType } = promoDetail;
  promoteEntries[0].title = sprintf(
    `${promotion_txt_benifit_1}`,
    percentOff + '%',
    promoType
  );

  return (
    <>
      <HideHeaderMenu>
        <DefaultHeaderContainer
          isSrollable={false}
          onRef={ref => (child = ref)}
        />
      </HideHeaderMenu>
      <PromoteShopContainer>
        <GiftIconContainer
          onClick={() =>
            onNextPromo(promoCount, currentPromoId, stateChangeHandler)
          }
        >
          <img src={giftIcon} alt="" />
          <GiftNumber>
            {currentPromoId + 1}
            {SLASH_SIGN}
            {promoCount}
          </GiftNumber>
        </GiftIconContainer>
        <MainContainer>
          {isShowPromotionModal && promoId[currentPromoId] === 1 && (
            <>
              <SlidesPromotion
                promo_txt_benefit_05={promo_txt_benefit_05}
                promoDetail={promoDetail}
                isPromo={isPromo}
                animating={animating}
                promotion_txt_benifit_1={promotion_txt_benifit_1}
                purchase_txt_benefit_details_05={
                  purchase_txt_benefit_details_05
                }
                promotion_txt_benifit_2={promotion_txt_benifit_2}
                promotion_txt_benifit_des_2={promotion_txt_benifit_des_2}
                promotion_txt_benifit_des_3={promotion_txt_benifit_des_3}
                promotion_txt_benifit_3={promotion_txt_benifit_3}
              />

              <PromoButton
                onClick={() =>
                  onBuy(currency, promoDetail.type, promoDetail.id, {}, 'promotion_scene')
                }
              >
                {promo_txt_get_discount}
              </PromoButton>
              <FirewordBackGround src={bgFirework} alt={bgFirework} />
            </>
          )}
          {isShowInviteFriendModal && promoId[currentPromoId] === 2 && (
            <>
              <CarouselContainer isPromo={isPromo}>
                <HeroContentStyle
                  isPromo={isPromo}
                  isClaimReward={isClaimReward}
                >
                  <HeroContentImageContainerStyle>
                    <HeroContentImageStyle
                      src={giftBlue}
                      isClaimReward={isClaimReward}
                    />
                  </HeroContentImageContainerStyle>
                  <HeroContentTextStyle
                    isPromo={isPromo}
                    isClaimReward={isClaimReward}
                  >
                    <HeroContentHeaderStyle>{realTitle}</HeroContentHeaderStyle>
                    <HeroContentSubTextStyle>
                      {realDesc1} <br />
                      {realDesc2}
                    </HeroContentSubTextStyle>
                  </HeroContentTextStyle>
                </HeroContentStyle>
              </CarouselContainer>
              <PromoButton
                onClick={() => {
                  ClaimReward(referralCampaign);
                  history.push('/invite');
                }}
              >
                {invite_friend_modal_claim_reward}
              </PromoButton>
              <LiquidBackGround src={liquidBg} alt={liquidBg} />
            </>
          )}
        </MainContainer>
        <FooterContainer>
          <RejectBtn
            onClick={() => {
              history.push('/');
            }}
          >
            {trial_txt_no_thanks}
          </RejectBtn>
        </FooterContainer>
      </PromoteShopContainer>
    </>
  );
};

export { PromotionScene };
