/* @flow */
import * as React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import {
  PREFIX_LESSON,
  WHITE,
  GREY,
  GREY2,
  DARK_GREY_1,
  DARK_YELLOW,
  MARIGOLD
} from '../../common/constants';

const ModalImage = styled.img`
  width: 209px;
  margin-top: 40px !important;
`;

const ModalCloseButton = styled.button`
  position: fixed;
  opacity: 1;
  &.close {
    padding: 32px 32px 1px 1px !important;
  }
`;
const RandomConfirmButton = styled.button`
  width: 100%;
  height: 55px;
  border-radius: 20px;
  box-shadow: inset 0 -4px 0 0 ${DARK_YELLOW};
  border: solid 1px ${DARK_YELLOW};
  background-color: ${MARIGOLD};
  font-size: 18px;
  font-weight: bold;
  color: ${WHITE};
  text-transform: uppercase;
  &:hover {
    box-shadow: none;
    opacity: 0.4;
    border-color: ${MARIGOLD};
  }
  &:active {
    box-shadow: none;
    background: ${MARIGOLD};
    opacity: 1;
  }
`;

const ModalBodyText = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${DARK_GREY_1};
  width: 277px;
  line-height: 22px;
  margin: auto;
  margin-top: 0 !important;
`;

const ModalContentContainer = styled.div`
  margin: auto 25px;
`;

const styles = {
  modalContainer: {
    width: '485px',
    height: '457px',
    textAlign: 'center',
    borderRadius: 30
  },
  modalBodyContainer: {
    padding: 0,
    paddingBottom: 20,
    textAlign: 'center'
  },
  buttonStyle: {
    borderRadius: 15,
    height: 55,
    background: WHITE,
    border: 'solid 1px',
    borderColor: GREY2,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    boxShadowColor: GREY2,
    fontWeight: 'bold',
    fontSize: 18,
    margin: 'auto',
    paddingRight: 42,
    paddingLeft: 42
  },
  cancelButtonStyle: {
    border: 'none',
    boxShadow: 'none',
    width: 100
  },
  confirmButtonStyle: {
    borderColor: GREY2,
    background: WHITE,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    width: 133,
    height: 41
  },
  modalHeader: {
    borderBottom: 'none',
    flexDirection: 'row-reverse',
    justifyContent: 'normal',
    padding: 0,
    marginBottom: 29
  },
  modalFooter: {
    borderTop: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 30,
    paddingRight: 40,
    paddingLeft: 40,
    paddingTop: 22
  },
  contentContainer: {
    borderRadius: 30
  },
  modalHeaderContainer: {
    'modal-title': 'w-100 text-center'
  }
};

const closeIcon = `${PREFIX_LESSON}basecolor-black.svg`;
type Props = {
  isOpen: boolean,
  toggle: Function,
  random_test_modal_confirm_txt: string,
  random_test_modal_txt: string,
  random_test_img: string
};

const RandomReviewWarningModal = ({
  isOpen,
  toggle,
  random_test_img,
  random_test_modal_txt,
  random_test_modal_confirm_txt
}: Props) => {
  const closeBtn = (
    <ModalCloseButton className="close" onClick={toggle}>
      <img src={closeIcon} alt={closeIcon} />
    </ModalCloseButton>
  );
  const {
    modalHeader,
    modalBodyContainer,
    modalFooter,
    modalContainer
  } = styles;
  return (
    <Modal
      centered
      style={modalContainer}
      isOpen={isOpen}
      contentClassName="contentContainer"
    >
      <ModalHeader style={modalHeader} close={closeBtn}>
        <ModalImage src={random_test_img} alt={random_test_img} />
      </ModalHeader>
      <ModalContentContainer>
        <ModalBody style={modalBodyContainer}>
          <React.Fragment>
            <ModalBodyText>{random_test_modal_txt}</ModalBodyText>
          </React.Fragment>
        </ModalBody>
      </ModalContentContainer>

      <ModalFooter style={modalFooter}>
        <RandomConfirmButton onClick={toggle}>
          {random_test_modal_confirm_txt}
        </RandomConfirmButton>
      </ModalFooter>
    </Modal>
  );
};

export { RandomReviewWarningModal };
