/* @flow */
const { PUBLIC_URL } = process.env;
const CONTENT_PATH: string = 'https://play.ling-app.com/resources/';

export const TUTORIAL_PATH: string = `${CONTENT_PATH}/sounds/`;
export const SOUND_PATH: string = `${CONTENT_PATH}/sounds/`;
export const PREFIX_UNITS: string = `${CONTENT_PATH}global/units/`;
export const PREFIX_UNIT: string = `${CONTENT_PATH}images/`;
export const PREFIX_COURSES: string = `${CONTENT_PATH}global/courses/`;
export const IMAGE_PATH: string = `${PUBLIC_URL || ''}/images/`;
export const SOUND_ASSET_PATH: string = `${PUBLIC_URL || ''}/sounds/`;

export const PREFIX_BEDGE: string = `${IMAGE_PATH}bedge/`;
export const PREFIX_HEADER: string = `${IMAGE_PATH}header/`;
export const PREFIX_PROFILE: string = `${IMAGE_PATH}profile/`;
export const PREFIX_CHATBOT: string = `${IMAGE_PATH}chatbot/`;
export const PREFIX_SHOP: string = `${IMAGE_PATH}shop/`;
export const PREFIX_TRANSLATE: string = `${IMAGE_PATH}translate/`;
export const PREFIX_LISTENING: string = `${IMAGE_PATH}listening/`;
export const PREFIX_LESSON: string = `${IMAGE_PATH}lesson/`;
export const PREFIX_CROWN: string = `${IMAGE_PATH}crown/`;
export const PREFIX_BOOK: string = `${IMAGE_PATH}book/`;
export const PREFIX_QUIZ: string = `${IMAGE_PATH}quiz/`;
export const PREFIX_EXAM: string = `${IMAGE_PATH}exam/`;
export const PREFIX_UNITS_CONTENT: string = `${PREFIX_UNIT}`;
export const progressPath = '/progress/';

export const LOCAL_LABELS: string = `${CONTENT_PATH}labels/`;
export const LOCAL_CHATBOTS: string = `${CONTENT_PATH}chatbots/chatbots.json`;
export const LOCAL_DIALOGUE_HEADERS: string = `${CONTENT_PATH}labels/dialog-headers.json`;
