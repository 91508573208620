import { createTheme } from '@mui/material';
import {
  MARIGOLD,
  VERY_LIGHT_PINK,
  VERY_LIGHT_PINK_TWO
} from '../common/constants/Color';
import { grey, yellow, red, green } from '@mui/material/colors';

const theme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        mark: {
          backgroundColor: 'currentColor',
          opacity: 1
        },
        markActive: {
          opacity: 1,
          backgroundColor: 'currentColor'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            borderRadius: 3.5
          })
      },
      variants: [
        {
          props: { variant: 'reverse-contained' },
          style: {
            backgroundColor: MARIGOLD,
            padding: 4,
            color: grey[50]
          }
        }
      ]
    }
  },
  overrides: {
    // TODO: remove all of these overrides
    body: {
      margin: 0,
      padding: '0 !important',
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale'
    },
    code: {
      fontFamily:
        "source-code-pro, Menlo, Monaco, Consolas, 'Courier New' monospace"
    },
    ':root': {
      '--grayish-blue': '#c8c8cd',
      '--primary-color': '#ffcc00',
      '--strong-blue-twenty': '#0572ce20',
      '--dark-grayish-blue': '#282c34',
      '--soft-cyan': '#61dafb'
    },
    p: {
      marginTop: '18px !important',
      marginBottom: '18px !important'
    },
    '*, ::after, ::before': {
      boxSizing: 'content-box !important'
    },
    '.loading-bar': {
      display: 'flex',
      height: 5,
      width: '100%',
      maxWidth: 320,
      boxShadow: 'inset 0px 0px 0px 1px var(--grayish-blue)',
      borderRadius: 50,
      overflow: 'hidden'
    },
    '.progress-bar-value': {
      width: '100%',
      height: '100%',
      backgroundColor: 'var(--primary-color)',
      animation: 'indeterminateAnimation 2s infinite linear',
      transformOrigin: '0% 100%'
    },
    '.progress-bar-value-stop': {
      width: '100%',
      height: '100%',
      opacity: 0
    },
    '.progress-bar': {
      height: 4,
      backgroundColor: 'var(--strong-blue-twenty)',
      width: '100%',
      overflow: 'hidden'
    },
    '.App-logo': {
      animation: 'App-logo-spin infinite 20s linear',
      height: '40vmin'
    },
    '.App-header': {
      backgroundColor: 'var(--dark-grayish-blue)',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 'calc(10px + 2vmin)',
      color: 'white'
    },
    '.App-link': {
      color: 'var(--soft-cyan)'
    },
    '@keyframes indeterminateAnimation': {
      '0%': {
        transform: 'translateX(0) scaleX(0)'
      },
      '40%': {
        transform: 'translateX(0) scaleX(0.4)'
      },
      '100%': {
        transform: 'translateX(100%) scaleX(0.5)'
      }
    },
    '@keyframes App-logo-spin': {
      from: {
        transform: 'rotate(0deg)'
      },
      to: {
        transform: 'rotate(360deg)'
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: MARIGOLD
      }
    },

    MuiPickersDay: {
      day: {
        color: MARIGOLD
      },
      daySelected: {
        backgroundColor: MARIGOLD
      },
      dayDisabled: {
        color: MARIGOLD
      },
      current: {
        color: MARIGOLD
      }
    },
    MuiPickersModal: {
      dialogAction: {
        color: MARIGOLD
      }
    },
    MuiInput: {
      underline: {
        '&&:hover:not(.Mui-disabled):before': {
          borderBottomWidth: 0
        },
        '&&:hover': {
          borderBottomWidth: 0
        },
        '&&:before': {
          borderBottomWidth: 0
        },
        '&&:after': {
          borderBottomWidth: 0
        }
      }
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: MARIGOLD
      }
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: MARIGOLD
      },
      noPoint: {
        backgroundColor: MARIGOLD
      },
      thumb: {
        border: '14px solid' + MARIGOLD
      }
    },

    MuiPickersClockNumber: {
      clockNumberSelected: {
        backgroundColor: MARIGOLD
      }
    },
    MuiButton: {
      label: {
        color: MARIGOLD
      }
    },
    MuiFormControl: { root: { width: '110px' } },
    MuiInputBase: {
      root: {
        color: 'unset'
      },
      input: {
        paddingRight: 20,
        borderRadius: 10,
        boxShadow: `inset 0 -2px 0 0 ${VERY_LIGHT_PINK}`,
        borderStyle: 'solid',
        borderColor: VERY_LIGHT_PINK_TWO,
        borderWidth: 2,
        textAlign: 'center',
        cursor: 'pointer'
      }
    }
  },
  spacing: 4,
  palette: {
    mode: 'light',
    common: {
      black: grey[900],
      white: grey[50]
    },
    primary: {
      dark: '#b28704',
      main: '#ffcc00',
      light: '#ffcd38'
    },
    secondary: {
      main: yellow[800]
    },
    newPrimary: {
      main: '#FF9900',
      activate: '#FF9900',
      deactivate: '#FEF4D7'
      //TODO: rename since we are using as new primary color?
    },
    background: {
      paper: '#fff',
      default: '#fff'
    },
    buttons: {
      red: {
        active: red[500],
        hover: red[800]
      },
      green: {
        active: green[500],
        hover: green[800]
      }
    }
  },
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536
  },
  shape: {
    borderRadius: 4
  },
  typography: {
    fontFamily: 'Helvetica',
    fontSize: 14,
    h4: {
      fontWeight: 800,
      fontSize: 20
    },
    h5: {
      fontWeight: 800,
      fontSize: 18
    },
    text: {
      fontFamily: 'Poppins',
      margin: '0 !important',
      color: '#333333',
      fontSize: '36px',
      fontWeight: 600,
      textAlign: 'center'
      //TODO: Update the fonts in theme once new onboarding is completed.
    },
    subtext: {
      fontFamily: 'Noto Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      margin: '0 !important',
      color: '#111111'
    },
    deactivatedText: {
      fontFamily: 'Noto Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      margin: '0 !important',
      color: '#999999'
    }
  }
});

export default theme;
