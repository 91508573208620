/* @flow */
import styled from 'styled-components';
import { MARIGOLD, WHITE } from '../../../common/constants';

const HeaderContainer = styled.div`
  position: relative;
`;
const ContainerStyle = styled.div`
  background: ${props =>
    props.isShowTutorial || props.showTutorial ? MARIGOLD : WHITE};
`;

export { ContainerStyle, HeaderContainer };
