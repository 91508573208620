/* @flow */
import { connect } from 'react-redux';
import _ from 'lodash';
import { LessonReview } from '../components/main-components/LessonReview';
import { getCurrentUnitId } from '../../profile/selectors';
import * as actions from '../../games/actions';
import { correctLineFeed, parseGrammarTable, getLabel } from '../../util';
import { getAllGrammar } from '../../games/selectors';
import { GRAMMAR } from '../../games/constants';

export const mapStateToProps = (state: Object): Object => {
  const currentUnitId = getCurrentUnitId(state);

  const { grammar } = _.get(
    state,
    `courses.${state.courses.activeLangCode}`,
    false
  );
  const grammarData = getAllGrammar(
    grammar,
    currentUnitId,
    state.data.nativeLangCode
  );

  const correctedGrammar = _.reduce(
    grammarData,
    (result, value: Object) => {
      const details = _.map(value.details, item => {
        const grammarTable = parseGrammarTable(item.text);
        return {
          ...item,
          isGrammarTable: Boolean(grammarTable),
          text: grammarTable ? grammarTable : correctLineFeed(item.text)
        };
      });
      result.push({
        ...value,
        details: details
      });
      return result;
    },
    []
  );
  return {
    random_test_modal_confirm_txt: getLabel(
      state,
      'random_test_modal_confirm_txt',
      'Ok'
    ),
    btn_send: getLabel(state, 'btn_send', 'Send'),
    grammar_help_txt: getLabel(
      state,
      'grammar_help_txt',
      'Are you interested in helping us translate this screen into your language?'
    ),
    dataCollection: correctedGrammar,
    screen: GRAMMAR,
    title: getLabel(state, 'overView_txt_grammar', 'Grammars')
  };
};

export default connect(mapStateToProps, actions)(LessonReview);
