import initialState from '../system/initialState';
import * as OnboardActions from './filterConstants';

export default (state = initialState.onboarding, action) => {
  switch (action.type) {
    case OnboardActions.ChangeCampaign:
      return {
        ...state,
        campaignId: action.campaign
      };
    case OnboardActions.ChangeScreen:
      return {
        ...state,
        activeScreen: action.screenId
      };
    case OnboardActions.SetSelection: {
      const currentState = JSON.parse(JSON.stringify(state));
      const selectionsIndex = currentState.selections.findIndex(
        s => s.id === action.selectionObject.id
      );

      let newObject = JSON.parse(JSON.stringify(action.selectionObject));
      newObject.values.push(action.value);

      if (selectionsIndex !== -1 && !action.reset) {
        currentState.selections[selectionsIndex] = newObject;
      } else {
        currentState.selections = [newObject];
      }

      return {
        ...state,
        selections: currentState.selections
      };
    }
    case OnboardActions.ResetCampaign:
      return {
        ...state,
        activeScreen: 0,
        campaignComplete: false,
        selections: []
      };
    case OnboardActions.RedirectShop:
      return {
        ...state,
        shopRedirected: true
      };
    case OnboardActions.CompleteCampaign:
      return {
        ...state,
        campaignComplete: true,
        completeCampaigns: state.completeCampaigns.includes(action.campaignId)
          ? state.completeCampaigns
          : [...state.completeCampaigns, action.campaignId]
      };
    case OnboardActions.SetMonthlyPrice:
      return {
        ...state,
        monthlyPrice: {
          ...state.monthlyPrice,
          [action.payload.currency === 'usd' ? 'usd' : 'local']: action.payload
        }
      };
    case OnboardActions.SetYearlyPrice:
      return {
        ...state,
        yearlyPrice: {
          ...state.yearlyPrice,
          [action.payload.currency === 'usd' ? 'usd' : 'local']: action.payload
        }
      };
    case OnboardActions.SetQuarterlyPrice:
      return {
        ...state,
        quarterlyPrice: {
          ...state.quarterlyPrice,
          [action.payload.currency === 'usd' ? 'usd' : 'local']: action.payload
        }
      };
    case OnboardActions.SetMonthlyPriceStripeUk:
      return {
        ...state,
        monthlyPriceStripeUk: {
          ...state.monthlyPriceStripeUk,
          [action.payload.currency === 'usd' ? 'usd' : 'local']: action.payload
        }
      };
    case OnboardActions.SetQuarterlyPriceStripeUk:
      return {
        ...state,
        quarterlyPriceStripeUk: {
          ...state.quarterlyPriceStripeUk,
          [action.payload.currency === 'usd' ? 'usd' : 'local']: action.payload
        }
      };
    case OnboardActions.SetYearlyPriceStripeUk:
      return {
        ...state,
        yearlyPriceStripeUk: {
          ...state.yearlyPriceStripeUk,
          [action.payload.currency === 'usd' ? 'usd' : 'local']: action.payload
        }
      };
    default:
      return state;
  }
};
