/* @flow */
import React from 'react';
import styled from 'styled-components';
import {
  PREFIX_LESSON,
  PREFIX_PROFILE,
  ORANGEY_YELLOW,
  BLACK_TWO_COLOR,
  VERY_LIGHT_PINK
} from '../../../common/constants';
import { DailyGoalChart } from '../../sub-components/DailyGoalChart';
const SetGoalContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SetGoalTitle = styled.p`
  color: ${BLACK_TWO_COLOR};
`;
const SetGoalButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    height: 24px;
  }
`;
const BananaScore = styled.p`
  margin-left: 5px;
  color: ${ORANGEY_YELLOW};
`;
const ArrowImg = styled.img`
  height: 24px;
  transform: rotate(180deg);
`;

const ChartContainer = styled.div`
  padding: 14px 35px 31px 24px;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 5%);
  border: solid 2px ${VERY_LIGHT_PINK};
  margin-top: 10px;
`;

type Props = {
  profile_overview_txt: string,
  profile_set_your_goal_txt: string,
  goal: Object,
  toggleSetDailyGoalModal: Function,
  dailyBananas: number,
  weeklyGraphData: number[]
};

const flagbanana = `${PREFIX_PROFILE}flagbanana.png`;
const arrow = `${PREFIX_LESSON}arrow-left-yellow.png`;

const DailyGoalSection = ({
  profile_overview_txt,
  profile_set_your_goal_txt,
  goal,
  toggleSetDailyGoalModal,
  dailyBananas,
  weeklyGraphData
}: Props) => {
  const { isSetDailyGoal = null, userDailyGoal } = goal;
  return (
    <ChartContainer>
      <SetGoalContainer>
        <SetGoalTitle>{profile_overview_txt}</SetGoalTitle>
        <SetGoalButtonContainer onClick={() => toggleSetDailyGoalModal()}>
          <img src={flagbanana} alt={flagbanana} />
          <BananaScore>
            {isSetDailyGoal && userDailyGoal
              ? `${dailyBananas}/${userDailyGoal}`
              : profile_set_your_goal_txt}
          </BananaScore>
          <ArrowImg src={arrow} alt={arrow} />
        </SetGoalButtonContainer>
      </SetGoalContainer>
      <DailyGoalChart weeklyGraphData={weeklyGraphData} />
    </ChartContainer>
  );
};

export { DailyGoalSection };
