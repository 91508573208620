/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { GameScreen } from '../components/scenes/GameScreen';
import { matchingStatus } from '../constants';
import { getLabels, getLabel } from '../../util/LabelHelper';
import { getCurrentCourseData } from '../../lessons/selectors/DataSelectors';
import {
  WHITE,
  LIGHT_GREY,
  RED_COLOR,
  GREEN_COLOR,
  SEA_BLUE,
  GREEN200,
  RED200
} from '../../common/constants';

const GameScreenContainer = (props: Object) => {
  const modifiedProps = { ...props };
  return <GameScreen {...modifiedProps} />;
};

export const getCardStyle = (status: string): { backgroundColor?: string } => {
  const backgroundColor = getColor(status);
  var borderColor;
  var color;

  if (backgroundColor) {
    if (status === matchingStatus.SELECTED) {
      borderColor = SEA_BLUE;
    } else if (status === matchingStatus.MATCH) {
      borderColor = GREEN_COLOR;
      color = WHITE;
    } else if (status === matchingStatus.NO_MATCH) {
      borderColor = RED_COLOR;
      color = WHITE;
    }
    return {
      'border-color': borderColor,
      'background-color': backgroundColor,
      color
    };
  } else if (status === matchingStatus.VISIBLE) {
    return {
      'border-color': LIGHT_GREY
    };
  }
  return {};
};

const getColor = (status: string) =>
  ({
    [matchingStatus.SELECTED]: WHITE,
    [matchingStatus.MATCH]: GREEN200,
    [matchingStatus.NO_MATCH]: RED200
  }[status]);

export const mapStateToProps = (state: Object) => {
  const { routes, game, storage, chatbots } = state;
  const { isChatbot } = chatbots;

  const { features } = getCurrentCourseData(state);
  const { hasTranscript } = features;
  const { isShowTutorial, isTutorialMotivate, configs } = storage;
  const { isShowMotivation } = configs;

  const labels = getLabels(
    state,
    'modal_txt_quitExam',
    'modal_txt_quitLesson',
    'modal_txt_exam',
    'modal_txt_lesson',
    'modal_txt_description_exam',
    'modal_txt_description_lesson',
    'hint_txt_topText1',
    'hint_txt_topText2',
    'hint_txt_checkBox',
    'complete_txt_failExam',
    'complete_txt_completed',
    'complete_txt_earned',
    'tooltip_txt_flag',
    'tooltip_txt_transcript',
    'modal_error_faq',
    'modal_error_report',
    'modal_error_description',
    'report_text_sendfeedback',
    'report_text_cancle',
    'landing_txt_connectInternet',
    'btn_continue',
    'notification_text_confirm',
    'notification_text_cancel',
    'motivatedMgs_txt',
    'writing_skip'
  );
  const {
    gameType,
    gameScreensCompleted,
    loadAnimationFinished,
    showCommonScreen,
    showCompleteScreen,
    showHintDialog,
    hintDialogEnabled,
    showNoStarsDialog,
    showReportErrorDialog,
    isCurrentGameWrong,
    gameWrongAnswered,
    isExam,
    isRandomTest,
    isWriting,
    examFailed,
    screenshot,
    showGrammarScreen,
    gameWrongAnsweredCount
  } = routes;

  return {
    ...labels,
    isShowMotivation,
    progress: gameScreensCompleted,
    progressMax: game.gameOrder.length,
    gameWrongCount: gameWrongAnsweredCount,
    gameType,
    showCommonScreen,
    showCompleteScreen,
    loadAnimationFinished,
    getCardStyle,
    showHintDialog,
    isShowTutorial,
    hintDialogEnabled,
    showNoStarsDialog,
    showReportErrorDialog,
    isRandomTest,
    isCurrentGameWrong,
    wrongAnswered: gameWrongAnswered,
    isExam,
    isChatbot,
    isWriting,
    isTutorialMotivate,
    hasTranscript,
    grammar: game.grammar,
    examFailed,
    screenshot,
    showGrammarScreen,
    tutorial_txt_easy_learn: getLabel(
      state,
      'tutorial_tooltips_desc',
      "now let's see how easy it is to learn"
    )
  };
};

export { GameScreenContainer };
export default connect(mapStateToProps, actions)(GameScreenContainer);
