/* @flow */
import {
  START_GAME,
  START_EXAM,
  START_SPEAKING,
  START_WRITING,
  LOAD_ANIMATION_FINISHED,
  TOGGLE_GAME_COMMON_SCREEN,
  TOGGLE_GAME_COMPLETE_SCREEN,
  TOGGLE_GRAMMAR_SCREEN,
  TOGGLE_HINT_DIALOG,
  TOGGLE_ENABLE_HINT_DIALOG,
  TOGGLE_NO_STARS_DIALOG,
  TOGGLE_REPORT_ERROR_DIALOG,
  INCREASE_COMPLETED_SCREEN,
  INCREASE_ANSWERED_WRONG,
  SAVE_GAMESCREEN_REF,
  SAVE_GAMESCREEN_REPORT_TEXT,
  REFRESH_GAME,
  LOAD_TRANSCRIPT,
  CLOSE_COMPLETE_SCREEN,
  INCREASE_ANSWERED_WRONG_COUNT,
  RESET_ANSWERED_WRONG_COUNT,
  START_RANDOM_TEST,
  INCREASE_GAME_SKIP_COUNT,
  RESET_GAME_SKIP_COUNT,
  SET_GAME_RESULT
} from '../constants';
import { GAME_SCREEN } from '../../common/constants';
import { isSpellingGame, isMainGame } from '../util/GameUtils';

export type State = {
  gameScreensCompleted: number,
  gameWrongAnswered: number,
  gameType: ?string,
  loadAnimationFinished: boolean,
  showCommonScreen: boolean,
  showCompleteScreen: boolean,
  transcript: boolean,
  showHintDialog: boolean,
  hintDialogEnabled: boolean,
  showNoStarsDialog: boolean,
  showReportErrorDialog: boolean,
  showGrammarScreen: boolean,
  isExam: boolean,
  isSpeaking: boolean,
  isWriting: boolean,
  examFailed: boolean,
  screenshot: any,
  gameScreenRef: Object,
  report: string,
  isCurrentGameWrong: ?boolean,
  route: string,
  isLesson: boolean,
  gameWrongAnsweredCount: number,
  gameSkipCount: number
};

export const INITIAL_STATE = {
  gameScreensCompleted: 0,
  gameWrongAnswered: 0,
  gameWrongAnsweredCount: 0,
  gameSkipCount: 0,
  gameType: null,
  loadAnimationFinished: false,
  showCommonScreen: false,
  showCompleteScreen: false,
  transcript: true,
  showHintDialog: false,
  hintDialogEnabled: true,
  showNoStarsDialog: false,
  showReportErrorDialog: false,
  showGrammarScreen: false,
  isExam: false,
  isSpeaking: false,
  isWriting: false,
  examFailed: false,
  screenshot: null,
  gameScreenRef: null,
  report: '',
  isCurrentGameWrong: null,
  route: '',
  isLesson: false,
  isRandomTest: false
};

export const isNewGameScreenPushed = ({ routeName }: Object): boolean =>
  routeName === GAME_SCREEN;

const resetGameData = ({
  gameType,
  transcript,
  hintDialogEnabled
}): Object => ({
  ...INITIAL_STATE,
  gameScreensCompleted: 0,
  gameType,
  transcript,
  hintDialogEnabled
});

const loadTranscript = (state: State, { hasTranscript }: Object) => ({
  ...state,
  transcript: hasTranscript && state.transcript
});

const resetExamData = (state: State) => ({
  ...resetGameData(state),
  isExam: true
});

const resetSpeakingData = (state: State) => ({
  ...resetGameData(state),
  isSpeaking: true
});

const resetWritingData = (state: State) => ({
  ...resetGameData(state),
  isWriting: true
});

const increaseCompletedScreen = (state: State) => ({
  ...state,
  gameScreensCompleted: state.gameScreensCompleted + 1
});

const increaseAnsweredWrong = (state: State) => ({
  ...state,
  gameWrongAnswered: state.gameWrongAnswered + 1
});

const increaseAnsweredWrongCount = (state: State) => ({
  ...state,
  gameWrongAnsweredCount: state.gameWrongAnsweredCount + 1
});
const increaseGameSkipCount = (state: State) => ({
  ...state,
  gameSkipCount: state.gameSkipCount + 1
});

const resetGameSkipCount = (state: State) => ({
  ...state,
  gameSkipCount: 0
});

const resetAnsweredWrongCount = (state: State) => ({
  ...state,
  gameWrongAnsweredCount: 0
});

const nextGameScreen = (state: State, { gameType }: Object) => ({
  ...state,
  gameType,
  loadAnimationFinished: false,
  isCurrentGameWrong: null
});

const finishLoadAnimation = (state: State) => ({
  ...state,
  loadAnimationFinished: true
});

const toggleGameCommonScreen = (state: State) => ({
  ...state,
  showCommonScreen: !state.showCommonScreen
});

const toggleGameCompleteScreen = (state: State, { examFailed }: Object) => ({
  ...state,
  showCompleteScreen: !state.showCompleteScreen,
  examFailed
});

const closeCompleteScreen = (state: State) => ({
  ...state,
  showCompleteScreen: false
});
const toggleHintDialog = (state: State) => ({
  ...state,
  showHintDialog: !state.showHintDialog
});

const toggleEnableHintDialog = (state: State) => ({
  ...state,
  hintDialogEnabled: !state.hintDialogEnabled
});

const toggleNoStarsDialog = (state: State) => ({
  ...state,
  showNoStarsDialog: !state.showNoStarsDialog
});

const toggleReportError = (state: State, action: Object) => ({
  ...state,
  showReportErrorDialog: !state.showReportErrorDialog,
  screenshot: action.screenshot
});

const resetRandomTestData = (state: State) => ({
  ...resetGameData(state),
  isRandomTest: true
});

const toggleGrammarScreen = (state: State) => ({
  ...state,
  showGrammarScreen: !state.showGrammarScreen
});

const saveScreenRef = (state: State, action: Object) => ({
  ...state,
  gameScreenRef: action.ref
});
const saveReportText = (state: State, action: Object) => ({
  ...state,
  report: action.report
});
/* End Additional Reducer */

export const GamescreenReducer = (
  state: Object = INITIAL_STATE,
  action: Object = { refresh: false }
) => {
  switch (action.type) {
    case REFRESH_GAME:
      return nextGameScreen(state, action);
    case START_GAME:
      return resetGameData(state);
    case START_EXAM:
      return resetExamData(state);
    case START_SPEAKING:
      return resetSpeakingData(state);
    case START_WRITING:
      return resetWritingData(state);
    case START_RANDOM_TEST:
      return resetRandomTestData(state);
    case LOAD_TRANSCRIPT:
      return loadTranscript(state, action);
    case LOAD_ANIMATION_FINISHED:
      return finishLoadAnimation(state);
    case INCREASE_COMPLETED_SCREEN:
      return increaseCompletedScreen(state);
    case INCREASE_ANSWERED_WRONG:
      return increaseAnsweredWrong(state);
    case INCREASE_ANSWERED_WRONG_COUNT:
      return increaseAnsweredWrongCount(state);
    case RESET_ANSWERED_WRONG_COUNT:
      return resetAnsweredWrongCount(state);
    case TOGGLE_GAME_COMMON_SCREEN:
      return toggleGameCommonScreen(state);
    case TOGGLE_GAME_COMPLETE_SCREEN:
      return toggleGameCompleteScreen(state, action);
    case TOGGLE_GRAMMAR_SCREEN:
      return toggleGrammarScreen(state);
    case CLOSE_COMPLETE_SCREEN:
      return closeCompleteScreen(state);
    case TOGGLE_HINT_DIALOG:
      return toggleHintDialog(state);
    case TOGGLE_ENABLE_HINT_DIALOG:
      return toggleEnableHintDialog(state);
    case TOGGLE_NO_STARS_DIALOG:
      return toggleNoStarsDialog(state);
    case INCREASE_GAME_SKIP_COUNT:
      return increaseGameSkipCount(state);
    case RESET_GAME_SKIP_COUNT:
      return resetGameSkipCount(state);
    case TOGGLE_REPORT_ERROR_DIALOG:
      return toggleReportError(state, action);
    case SAVE_GAMESCREEN_REF:
      return saveScreenRef(state, action);
    case SAVE_GAMESCREEN_REPORT_TEXT:
      return saveReportText(state, action);
    case SET_GAME_RESULT:
      return {
        ...state,
        isCurrentGameWrong:
          isMainGame(state.gameType) &&
          (isSpellingGame(state.gameType) ? action.spellingAnswerWrong : action.quizAnswerWrong)
      }
    default:
      return state;
  }
};

export const setGameResult = (
  state: Object,
  spellingWrong: boolean,
  quizWrong: boolean
) => {
  const { gameType } = state;
  return {
    ...state,
    isCurrentGameWrong:
      isMainGame(gameType) &&
      (isSpellingGame(gameType) ? spellingWrong : quizWrong)
  };
};
