/* @flow */
import {
  SPEAKING,
  REFRESH_GAME,
  SPEAKING_TOGGLE_SPEAKING,
  SPEAKING_SAVE_RECORDER,
  CLOSE_GAME,
  SPEAKING_ANSWER,
  SPEAKING_ERROR,
  SPEAKING_CLEAR_ERROR
} from '../constants';

export const INITIAL_STATE = {
  phrase: {},
  phraseId: null,
  answers: '',
  isSpeaking: 0,
  isWord: false,
  recorder: null,
  isSpacedLang: false,
  answeredWrong: false,
  speakingErrorMsg: ''
};

const loadSpeaking = (
  state: Object,
  { phrase, phraseId, isWord, isSpacedLang }: Object
): Object => {
  return {
    ...INITIAL_STATE,
    phrase,
    phraseId,
    isWord,
    isSpacedLang
  };
};

const loadRecoder = (state: Object, action: Object) => {
  return {
    ...state,
    recorder: action.recorder
  };
};

const toggleSpeaking = (state: Object, action: Object) => ({
  ...state,
  isSpeaking: action.state
});

const checkAnswer = (state: Object, action: Object) => ({
  ...state,
  answeredWrong: action.answeredWrong
});

const loadSpeakingError = (state: Object, action: Object) => {
  return {
    ...state,
    speakingErrorMsg: action.text
  };
};
const clearSpeakingError = (state: Object) => {
  return {
    ...state,
    speakingErrorMsg: ''
  };
};

export const SpeakingReducer = (
  state: Object = INITIAL_STATE,
  action: Object
) => {
  const { type } = action;

  switch (type) {
    case CLOSE_GAME:
      return INITIAL_STATE;
    case REFRESH_GAME:
      if (action.gameType === SPEAKING) {
        return loadSpeaking(state, action);
      } else {
        return state;
      }
    case SPEAKING_TOGGLE_SPEAKING:
      return toggleSpeaking(state, action);
    case SPEAKING_SAVE_RECORDER:
      return loadRecoder(state, action);
    case SPEAKING_ANSWER:
      return checkAnswer(state, action);
    case SPEAKING_CLEAR_ERROR:
      return clearSpeakingError(state);
    case SPEAKING_ERROR:
      return loadSpeakingError(state, action);
    default:
      return state;
  }
};
