const localStorageKey = 'web_funnel_utm';

export const saveFunnelUtm = () => {
  const params = new URLSearchParams(window.location.search);
  const utm = {};
  params.forEach((value, key) => {
    // Note: currently we have:
    // 'utm_source',
    // 'utm_medium',
    // 'utm_campaign',
    // 'utm_term',
    // 'utm_name',
    // 'utm_content',
    // 'utm_page'
    //
    // But anything starting with `utm_` will be saved here.
    if (key.startsWith('utm_')) {
      utm[key] = value;
    }
  });
  const value = JSON.stringify(utm);
  localStorage.setItem(localStorageKey, value);
};

export const getFunnelUtm = () => {
  const value = localStorage.getItem(localStorageKey);
  if (typeof value === 'string') {
    return JSON.parse(value);
  } else {
    return {};
  }
};
