/* @flow */
import _ from 'lodash';
import moment from 'moment';
import { getUser } from '../selectors';
import { firebase } from '../../system/Firebase';

import {
  LEADERBOARD_FETCH,
  LOGIN_TO_FACEBOOK,
  LOGIN_TO_GOOGLE,
  ANONYMOUS,
  TOGGLE_ALREADY_LINKED_DIALOG,
  LINK_FACEBOOK,
  LINK_GOOGLE,
  FORM_REGISTER_ERROR_UPDATE,
  REGISTER_BY_EMAIL,
  LOGIN_BY_EMAIL,
  RESET_ERROR,
  RESET_PASSWORD,
  TOOGLE_PASSWORD_RESET_SEND_MODAL
} from '../constants';
import {
  PREFIX_LESSON,
  DEFAULT_TARGET_LANGUAGE_CODE
} from '../../common/constants';
import { FETCH_USER_RANK } from '../../games/constants';
import { isNowWithin } from '../../util/PromotionHelper';

type Promotion = {
  endDate: string,
  iapAndroidSuffix: string,
  iapIosSuffix: string,
  isActive: boolean,
  startDate: string,
  type: string
};

const LEADER_NUMBER = 5;

export const loginToFacebook = () => ({
  type: LOGIN_TO_FACEBOOK
});

export const loginToGoogle = () => ({
  type: LOGIN_TO_GOOGLE
});

export const toggleAlreadyLinkedDialog = () => ({
  type: TOGGLE_ALREADY_LINKED_DIALOG
});

export const linkFacebook = () => ({
  type: LINK_FACEBOOK
});

export const linkGoogle = () => ({
  type: LINK_GOOGLE
});

export const resetErrors = () => ({
  type: RESET_ERROR
});

export const registerError = (errors: Object, message?: string) => ({
  type: FORM_REGISTER_ERROR_UPDATE,
  errors,
  message
});

export const registerByEmail = (form: Object) => ({
  type: REGISTER_BY_EMAIL,
  form
});

export const loginByEmail = (form: Object) => ({
  type: LOGIN_BY_EMAIL,
  form
});

export const resetPassword = (form: Object) => ({
  type: RESET_PASSWORD,
  form
});

export const tooglePasswordResetSentModal = () => ({
  type: TOOGLE_PASSWORD_RESET_SEND_MODAL
});

const lingProfile1 = `${PREFIX_LESSON}avatar-circle-male-60-px.svg`;
const lingProfile2 = `${PREFIX_LESSON}avatar-circle-female-60-px.svg`;
const lingArray = [lingProfile1, lingProfile2];

export const TODAY_ID = 0;
export const THIS_MONTH_ID = 1;
export const ALL_TIME_ID = 2;

export const fetchUserProgressPoint = (uid: string, targetLangCode: string) => {
  return firebase.database().ref(`progress/${targetLangCode}/${uid}`);
};

export const fetchAllTimeFirebase = (targetLangCode: any) => {
  return firebase
    .database()
    .ref(`leaderboard/top20/${targetLangCode}`)
    .orderByChild('bananas')
    .limitToLast(20);
};

export const fetchMonthlyFirebase = (targetLangCode: any) => {
  return firebase
    .database()
    .ref(`leaderboard/top20Monthly/${targetLangCode}`)
    .orderByChild('monthlyBananas')
    .limitToLast(20);
};

export const fetchDailyFirebase = (targetLangCode: any) => {
  return firebase
    .database()
    .ref(`leaderboard/top20Daily/${targetLangCode}`)
    .orderByChild('dailyBananas')
    .limitToLast(20);
};

export const getAllTimeRankUser = (uid: any, targetLangCode: any) => {
  return firebase.database().ref(`progress/${targetLangCode}/${uid}`);
};

export const getUserName = (state: Object, userData: any) => {
  const emailName = _.get(userData, 'name', false);
  const facebookCheck = _.get(userData, 'facebook.name', false);
  const appleCheck = _.get(userData, 'apple.name', false);
  const googleCheck = _.get(userData, 'google.name', false);
  if (emailName) {
    return emailName;
  } else if (facebookCheck) {
    return facebookCheck;
  } else if (googleCheck) {
    return googleCheck;
  } else if (appleCheck) {
    return appleCheck;
  } else {
    return ANONYMOUS;
  }
};

export const setupFirebase = (targetLangCode: any) => {
  return firebase
    .database()
    .ref(`leaderboard/top20/${targetLangCode}`)
    .orderByChild('bananas')
    .limitToLast(20);
};

export const getRankUser = (uid: any, targetLangCode: any) => {
  return firebase.database().ref(`leaderboard/${targetLangCode}/${uid}/rank`);
};
export const fetchLearningLang = (
  uid: string,
  targetLangCode: ?string,
  user: Object
): Promise<Object> => {
  return new Promise((resolve, reject) => {
    const callback = async snapshot => {
      console.log(
        'getDatabaseUser resolving promise',
        _.keys(_.pickBy(snapshot.val(), 'coins'))
      );
      await firebase
        .database()
        .ref(`users/${uid}`)
        .update({ learnLang: _.keys(_.pickBy(snapshot.val(), 'coins')) });
      resolve(snapshot);
    };

    if (_.has(user, 'learnLang')) {
      const { learnLang } = user;
      if (_.indexOf(learnLang, targetLangCode) === -1) {
        learnLang[Object.values(learnLang).length] = targetLangCode;
        const currentLearnLang = learnLang;

        firebase
          .database()
          .ref(`users/${uid}`)
          .update({ learnLang: currentLearnLang })
          .then(() => {
            firebase
              .database()
              .ref(`users/${uid}`)
              .once('value')
              .then(async snapshot => {
                resolve(snapshot);
              });
          });
      }
    } else {
      firebase
        .database()
        .ref(`users/${uid}`)
        .once('value', callback)
        .catch(error => {
          console.warn('getDatabaseUser Firebase error', error);
          reject(error);
        });
    }
  });
};

export const setTargetLang = (targetLang: string) => (
  dispatch: Function,
  getState: Function
) => {
  dispatch(setLangCode(targetLang));
};

export const fetchLeaderBoardPoint = (targetLangCode: string, type: number) => {
  if (type === ALL_TIME_ID) {
    return fetchAllTimeFirebase(targetLangCode);
  } else if (type === THIS_MONTH_ID) {
    return fetchMonthlyFirebase(targetLangCode);
  } else {
    return fetchDailyFirebase(targetLangCode);
  }
};
export const getLeadersRef = (type: number, targetLangCode: string) => {
  switch (type) {
    case THIS_MONTH_ID:
      return fetchMonthlyFirebase(targetLangCode);
    case ALL_TIME_ID:
      return fetchAllTimeFirebase(targetLangCode);
    case TODAY_ID:
      return fetchDailyFirebase(targetLangCode);
    default:
      return fetchAllTimeFirebase(targetLangCode);
  }
};
export const getBananasKey = (type: number) => {
  switch (type) {
    case THIS_MONTH_ID:
      return 'monthlyBananas';
    case ALL_TIME_ID:
      return 'bananas';
    case TODAY_ID:
      return 'dailyBananas';
    default:
      return 'bananas';
  }
};

export const triggerFetchLeaderboard = (
  targetLang?: string,
  type?: number = ALL_TIME_ID
) => async (dispatch: Function, getState: Function) => {
  const state = getState();
  const { storage, data } = state;
  const targetLangCode =
    targetLang || _.get(data, 'targetLangCode', DEFAULT_TARGET_LANGUAGE_CODE);
  const leadersRef = getLeadersRef(type, targetLangCode);
  const response = await leadersRef.once('value');
  let collection = (response.val() || []).filter(n => n);

  const user = getUser(state);
  const userData = _.get(storage, 'user', null);
  const { uid } = userData;
  const rankRef = getAllTimeRankUser(uid, targetLangCode);
  let rankResponse = await rankRef.once('value');
  rankResponse = rankResponse.val() || {};

  const rankUser = _.get(rankResponse, getBananasKey(type), '?');
  const bananasKey = getBananasKey(type);
  const userBanana = _.get(rankResponse, `${bananasKey}`, 0);
  const userName = getUserName(state, userData);
  const ordered = _.orderBy(collection, item => item[bananasKey] || 0, 'desc');
  const mapped = _.chain(ordered)
    .map((leader, index) => ({
      leaderName: leader.name ? leader.name : 'Anonymous',
      leaderBananas: leader[bananasKey] || 0,
      index: index + 1,
      leaderImages: lingArray[_.random(0, 1)],
      leaderCurrent: leader.uid === userData.uid,
      key: leader.uid
    }))
    .value();

  let mappedSixth = _.take(mapped, LEADER_NUMBER);
  const checkRankUser = rankUser ? rankUser : -1;
  const userIndex = getUserIndex(
    user,
    ordered,
    userData,
    userBanana,
    checkRankUser
  );

  const userIndexOrder = _.findIndex(mappedSixth, index => index.key === uid);
  mappedSixth.splice(userIndexOrder, 1);
  if (userBanana >= 0) {
    mappedSixth.push({
      key: userData.uid,
      leaderName: user.loggedIn ? userName : 'Not Registered',
      leaderBananas: userBanana,
      index: userIndex > 0 ? userIndex : '?',
      leaderImages: lingArray[_.random(0, 1)],
      leaderCurrent: true
    });

    if (userIndex > 0) {
      const mappedSixthClone = _.cloneDeep(mappedSixth);
      mappedSixth = _.orderBy(mappedSixthClone, ['leaderBananas'], ['desc']);
      for (let index = 0; index < mappedSixth.length; index++) {
        mappedSixth[index].index = index + 1;
      }
    }
  }
  dispatch(fetchLeaderBoard(mappedSixth));
};

export const getUserIndex = (
  user: Object,
  ordered: Array<Object>,
  userData: Object,
  userBanana: any,
  checkRankUser: any
) => {
  let userIndex;
  const findUserIndex = _.findIndex(ordered, item => item.uid === userData.uid);

  if (user.loggedIn && findUserIndex !== -1) {
    userIndex = findUserIndex;
  } else {
    const checkUserIndex = _.findIndex(
      ordered,
      item => userBanana >= item.bananas
    );

    if (checkUserIndex >= 0) {
      userIndex = checkUserIndex + 1;
    } else {
      userIndex = checkRankUser;
    }
  }
  return userIndex;
};

export const fetchLeaderBoard = (collection: Array<Object>) => ({
  type: LEADERBOARD_FETCH,
  collection
});
export const fetchUserRank = (data: Object) => ({
  type: FETCH_USER_RANK,
  data
});
export const setLangCode = (targetLang: string) => ({
  type: 'SET_LANGCODE',
  targetLang
});

export const getOnGoingPromotion = (state: Object) => {
  const defaultPromotions = {};
  const promotions = _.get(state, 'shop.firebasePromotions', defaultPromotions);

  const onGoingPromotion = _.find(promotions, (promotion: Promotion) => {
    const {
      startDate,
      endDate,
      iapIosSuffix,
      iapAndroidSuffix,
      isActive
    } = promotion;
    return (
      isNowWithin(startDate, endDate) &&
      isActive &&
      (iapIosSuffix || iapAndroidSuffix)
    );
  });

  return (
    onGoingPromotion || {
      type: 'monthly',
      iapIosSuffix: '',
      iapAndroidSuffix: ''
    }
  );
};
const promoPath = 'subscription/promoExpirationDate';
export const ClaimReward = (campaign: Object) => (
  dispatch: Function,
  getState: Function
) => {
  const user = getState().storage.user;
  const ref = firebase.database().ref(`users/${user.uid}/${promoPath}`);
  ref.once('value').then(result => {
    let expirationDate = result.val();
    if (expirationDate === null) {
      const date = moment()
        .add(campaign.value, 'days')
        .format('YYYY-MM-DD');
      expirationDate = date;
    } else {
      let date = moment(expirationDate);
      if (date.isBefore(moment())) {
        date = moment().add(campaign.value, 'days');
      } else {
        date.add(campaign.value, 'days');
      }
      expirationDate = date.format('YYYY-MM-DD');
    }
    ref.set(expirationDate);

    const claimedRef = firebase
      .database()
      .ref(`users/${user.uid}/claimedReferral`);
    claimedRef.once('value').then(result => {
      let claimedReferral = result.val();
      if (claimedReferral === null) {
        claimedReferral = {};
      }
      claimedReferral[campaign.index] = true;
      claimedRef.set(claimedReferral);
    });
  });
};
