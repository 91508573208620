import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { IMAGE_PATH } from '../../../common/constants';
import StartButton from './subComponents/StartButton';
import { useTheme } from '@mui/material/styles';
import { getPhilosophyData } from './data/paywallData';
import PropTypes from 'prop-types';

const Philosophy = ({ initiateBuy, labels }) => {
  const { txt_philosophy, btn_start_learning } = labels;
  const theme = useTheme();
  const imgPath = image => `${IMAGE_PATH}/onboarding/${image}`;
  const images = {
    1: imgPath('2-minutes.png'),
    2: imgPath('3-minutes.png'),
    3: imgPath('5-minutes.png'),
    4: imgPath('and-more-step.png')
  };
  const [activeText, setActiveText] = useState('1');
  const [activeImg, setActiveImg] = useState(images['1']);
  const styles = {
    container: {
      width: '100%',
      mt: 40,
      alignItems: 'center'
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column'
    },
    headerContainer: {
      width: '70%',
      textAlign: 'center'
    },
    headerText: {
      fontSize: '30px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '36px'
      }
    },
    contentContainer: {
      width: '90%',
      display: 'flex',
      justifyContent: 'space-around',
      mt: 3,
      flexWrap: 'wrap',
      [theme.breakpoints.up('sm')]: {
        flexWrap: 'nowrap',
        width: '75%',
        mt: 20
      }
    },
    img: {
      width: '100%',
      maxWidth: '388px',
      m: 6
    },
    subContainer: {
      width: '100%',
      justifyContent: 'space-between',
      m: 0,
      p: 5,
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        alignItems: 'flex-start',
        m: 6
      }
    },
    contentBox: {
      display: 'flex',
      my: 3,
      width: '100%'
    },
    circle: {
      backgroundColor: theme.palette.newPrimary.main,
      borderRadius: '50%',
      height: '50px',
      width: '70px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      mr: 8,
      [theme.breakpoints.up('sm')]: {
        width: '50px'
      }
    },
    numberText: {
      fontSize: '24px'
    },
    subtext: {
      color: '#33333'
    },
    textContainer: {
      width: '400px',
      alignItems: 'flex-start'
    },
    deactivateText: {
      opacity: 0.4
    },
    buttonStyle: {
      width: '223px',
      mt: 10,
      [theme.breakpoints.up('sm')]: {
        mt: 6
      }
    }
  };

  const handleMouseEnter = event => {
    setActiveText(event.target.id);
    setActiveImg(images[event.target.id]);
  };

  const renderContent = philosophyData => {
    const keys = Object.keys(philosophyData);
    return keys.map(key => {
      return (
        <Box
          onMouseEnter={event => handleMouseEnter(event)}
          id={key}
          key={key}
          sx={[
            styles.contentBox,
            activeText !== key ? styles.deactivateText : {}
          ]}
        >
          <Box
            onMouseEnter={event => handleMouseEnter(event)}
            id={key}
            sx={styles.circle}
          >
            <Typography
              onMouseEnter={event => handleMouseEnter(event)}
              id={key}
              variant="text"
              sx={styles.numberText}
            >
              {key}
            </Typography>
          </Box>
          <Box
            onMouseEnter={event => handleMouseEnter(event)}
            id={key}
            sx={[styles.textContainer, styles.flexColumn]}
          >
            <Typography
              onMouseEnter={event => handleMouseEnter(event)}
              id={key}
              variant="text"
              sx={styles.numberText}
            >
              {philosophyData[key].headerText}
            </Typography>
            <Typography
              onMouseEnter={event => handleMouseEnter(event)}
              id={key}
              variant="subtext"
              sx={styles.subtext}
            >
              {philosophyData[key].subtext}
            </Typography>
          </Box>
        </Box>
      );
    });
  };
  return (
    <Box sx={[styles.container, styles.flexColumn]}>
      <Box sx={styles.headerContainer}>
        <Typography variant="text" sx={styles.headerText}>
          {txt_philosophy}
        </Typography>
      </Box>
      <Box sx={styles.contentContainer}>
        <Box component="img" src={activeImg} sx={styles.img} />
        <Box sx={[styles.subContainer, styles.flexColumn]}>
          {renderContent(getPhilosophyData(labels))}
          <StartButton
            buttonStyle={styles.buttonStyle}
            text={btn_start_learning}
            buttonAction={initiateBuy}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Philosophy;

Philosophy.propTypes = {
  initiateBuy: PropTypes.func
};
