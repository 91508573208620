/* @flow */
import React, { Fragment } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import _ from 'lodash';
import styled from 'styled-components';
import {
  PREFIX_LESSON,
  IMAGE_PATH,
  PREFIX_PROFILE,
  CAMERA_GREY,
  WHITE,
  GREY,
  GREY2,
  DARK_GREY_1,
  VERY_LIGHT_GREY,
  VERY_LIGHT_PINK_TWO,
  LIGHT_BLUE_4,
  LIGHT_BLUE_5,
  LIGHT_GREY_2,
  LIGHT_GREY,
  BLACK
} from '../../common/constants';

const ModalImage = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 50%;
`;

const BodyRowStyle = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 24px;
  padding-right: 24px;
  flex: 0.5;
  &.topRow {
    border-bottom: 1px solid ${GREY};
  }
`;
const BodyRowLeft = styled.div`
  flex: 0.3;
`;
const BodyRowMiddle = styled.div`
  flex: 0.4;
  text-align: left;
  &.inputName {
    flex: 0.7;
  }
`;
const BodyRowRight = styled.div`
  flex: 0.3;
`;
const BodyRowLeftText = styled.p`
  color: ${VERY_LIGHT_GREY};
  font-size: 14px;
  text-transform: capitalize;
  text-align: left;
  &.topRow {
    margin-top: 0px !important;
  }
  &.name {
    font-weight: bold;
    color: ${BLACK};
  }
`;
const ProfilePicContainer = styled.div`
  height: 250px;
  display: flex;
  margin: 4px;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: scroll;
  padding-bottom: 77px;
`;
const BodyRowLeftInput = styled.input`
  border-radius: 10px;
  width: 97%;
  border: solid 1px ${VERY_LIGHT_PINK_TWO};
  padding: 11px;
  padding-right: 0px;
  &:focus {
    outline: none;
  }
`;
const EditSaveButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 7px;
  &.profilePic {
    position: absolute;
    bottom: 30px;
    right: 20px;
  }
`;
const FileUploadButton = styled.input`
  opacity: 0;
  position: absolute;
  overflow: hidden;
`;
const EditSaveButton = styled.button`
  text-transform: capitalize;
  margin-bottom: 25px;
  border-radius: 10px;
  box-shadow: inset 0 -4px 0 0 ${LIGHT_GREY};
  background: ${WHITE};
  border: ${GREY} 1px solid;
  font-size: 14px;
  width: 113px;
  height: 45px;
  padding: 0;
  color: ${DARK_GREY_1};
  bottom: 17px;
  right: 125px;
  &.saveBtn {
    width: auto;
    box-shadow: inset 0 -4px 0 0 ${LIGHT_BLUE_4};
    border: solid 1px ${LIGHT_BLUE_4};
    background-color: ${LIGHT_BLUE_5};
    color: ${WHITE};
    font-size: 16px;
    padding: 0px 20px;
    margin-bottom: 0 !important;
    &:disabled {
      box-shadow: inset 0 -4px 0 0 ${GREY};
      border: solid 1px ${GREY2};
      background-color: ${LIGHT_GREY_2};
    }
  }
  &.cancelBtn {
    width: auto;
    font-size: 16px;
    padding: 0px 20px;
    margin-left: 7px;
    margin-bottom: 0 !important;
  }
  &:disabled {
    background: ${LIGHT_GREY_2};
    box-shadow: inset 0 -4px 0 0 ${GREY2};
    border: ${GREY2} 1px solid;
  }
  &:hover:enabled {
    opacity: 0.4;
    box-shadow: none;
  }
  &:active:enabled {
    opacity: 1;
    background-color: ${GREY2};
    box-shadow: none;
  }
`;
const ModalCloseButton = styled.button`
  position: fixed;
  opacity: 1;
  &.close {
    padding: 32px 16px 1px 1px !important;
  }
`;

const ModalBodyContainer = styled.div`
  display: ${props => (props.isLoading ? 'block' : 'none')};
  display: flex;
  justify-content: center;
`;
const ModalContentContainer = styled.div`
  margin: auto;
`;

const ProfileContainer = styled.div`
  width: 77px;
  height: 77px;
  border-radius: 10px;
  margin: 5px;
  position: relative;
`;

const CheckIconStyle = styled.img`
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 1;
`;
const PicIcon = styled.img`
  border-radius: 10px;
  width: 77px;
  height: 77px;
`;

const CameraContainer = styled.div`
  background-color: ${CAMERA_GREY};
  width: 77px;
  height: 77px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
`;
const LingLoading = styled.img`
  margin: 134px 38px;
`;
const styles = {
  modalContainer: {
    maxWidth: '600px',
    height: '237px',
    textAlign: 'center',
    borderRadius: 30
  },
  modalBodyContainer: {
    width: '530px',
    marginTop: 10,
    marginBottom: 20,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: GREY,
    backgroundColor: WHITE,
    display: 'flex',
    flexDirection: 'column'
  },
  buttonStyle: {
    borderRadius: 15,
    height: 55,
    background: WHITE,
    border: 'solid 1px',
    borderColor: GREY2,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    boxShadowColor: GREY2,
    fontWeight: 'bold',
    fontSize: 18,
    margin: 'auto',
    paddingRight: 42,
    paddingLeft: 42
  },
  cancelButtonStyle: {
    border: 'none',
    boxShadow: 'none',
    width: 100
  },
  confirmButtonStyle: {
    borderColor: GREY2,
    background: WHITE,
    color: GREY,
    boxShadow: 'inset 0 -4px 0 0',
    width: 133,
    height: 41
  },
  modalHeader: {
    borderBottom: 'none',
    marginLeft: 33,
    marginTop: 20,
    flexDirection: 'row-reverse',
    textTransform: 'capitalize',
    justifyContent: 'normal',
    fontWeight: 'bold',
    fontSize: 18,
    padding: 0,
    marginBottom: 29
  },
  modalFooter: {
    borderTop: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 30,
    paddingRight: 32,
    paddingLeft: 32,
    paddingTop: 22
  },
  contentContainer: {
    borderRadius: 30
  },
  modalHeaderContainer: {
    'modal-title': 'w-100 text-center'
  },

  closeStyle: {
    margin: '-2rem 0rem -1rem auto'
  }
};

const lingLoading = `${IMAGE_PATH}ling-loader.gif`;
const closeIcon = `${PREFIX_LESSON}basecolor-black.svg`;
const fillCamera = `${PREFIX_PROFILE}fillCamera32Px.png`;
const checkIcon = `${PREFIX_PROFILE}checkWhiteCircleGreen25Px.png`;

type Props = {
  isOpen: boolean,
  goToHome: Function,
  toggleEdit: Function,
  onSelectProfile: Function,
  setEditId: Function,
  modal_btn_redeem_go_home: string,
  modal_txt_redeem: string,
  modal_header_txt: string,
  profileImg: string,
  isLoading: boolean,
  isEditing: boolean,
  isNameChange: boolean,
  editingId: string,
  profileSelectId: number,
  onChange: Function,
  saveNewName: Function,
  updateProfilePic: Function,
  selectPhotoTapped: Function,
  newName: string,
  edit_profile_save_txt: string,
  loading_txt_cancel: string,
  edit_profile_photo_txt: string,
  edit_profile_edit_txt: string,
  login_field_name: string,
  defaultProfilePics: Array<Object>
};
const EditProfileModal = ({
  isOpen,
  goToHome,
  toggleEdit,
  modal_header_txt,
  profileImg,
  modal_txt_redeem,
  modal_btn_redeem_go_home,
  isLoading,
  selectPhotoTapped,
  profileSelectId,
  onSelectProfile,
  isEditing,
  editingId,
  setEditId,
  onChange,
  newName,
  updateProfilePic,
  isNameChange,
  saveNewName,
  defaultProfilePics,
  edit_profile_save_txt,
  loading_txt_cancel,
  edit_profile_photo_txt,
  edit_profile_edit_txt,
  login_field_name
}: Props) => {
  const closeBtn = (
    <ModalCloseButton
      className="close"
      style={styles.closeStyle}
      onClick={toggleEdit}
    >
      <img src={closeIcon} alt={closeIcon} />
    </ModalCloseButton>
  );

  const { modalHeader, modalBodyContainer, modalContainer } = styles;
  return (
    <Modal
      centered
      style={modalContainer}
      isOpen={isOpen}
      contentClassName="contentContainer"
    >
      {!isLoading && (
        <ModalHeader
          style={modalHeader}
          close={closeBtn}
          cssModule={{ 'modal-title': 'w-100 font-weight-bold text-left' }}
        >
          {modal_header_txt}
        </ModalHeader>
      )}
      {!isLoading ? (
        <Fragment>
          <ModalContentContainer>
            <ModalBody style={modalBodyContainer}>
              {editingId === 'pic' ? (
                <ProfilePicContainer>
                  <CameraContainer>
                    <FileUploadButton
                      type="file"
                      onChange={selectPhotoTapped}
                    />
                    <img src={fillCamera} alt={fillCamera} />
                  </CameraContainer>
                  {_.map(defaultProfilePics, (profilePic, index) => (
                    <ProfileContainer
                      key={index}
                      onClick={() => onSelectProfile(profilePic.id)}
                    >
                      {profilePic.id === profileSelectId && (
                        <CheckIconStyle src={checkIcon} />
                      )}
                      <PicIcon src={profilePic.icon} />
                    </ProfileContainer>
                  ))}
                  <EditSaveButtonContainer className="profilePic">
                    <EditSaveButton
                      disabled={profileSelectId === 0}
                      className="saveBtn"
                      onClick={() => updateProfilePic()}
                    >
                      {edit_profile_save_txt}
                    </EditSaveButton>
                    <EditSaveButton
                      className="cancelBtn"
                      onClick={() => setEditId('')}
                    >
                      {loading_txt_cancel}
                    </EditSaveButton>
                  </EditSaveButtonContainer>
                </ProfilePicContainer>
              ) : (
                <Fragment>
                  <BodyRowStyle className="topRow">
                    <BodyRowLeft>
                      <BodyRowLeftText className="topRow">
                        {edit_profile_photo_txt}
                      </BodyRowLeftText>
                    </BodyRowLeft>
                    <BodyRowMiddle>
                      <ModalImage src={profileImg} alt={profileImg} />
                    </BodyRowMiddle>
                    <BodyRowRight>
                      <EditSaveButton onClick={() => setEditId('pic')}>
                        {edit_profile_edit_txt}
                      </EditSaveButton>
                    </BodyRowRight>
                  </BodyRowStyle>
                  {editingId === 'name' ? (
                    <BodyRowStyle>
                      <BodyRowLeft>
                        <BodyRowLeftText>{login_field_name}</BodyRowLeftText>
                      </BodyRowLeft>
                      <BodyRowMiddle className="inputName">
                        <BodyRowLeftInput
                          value={newName}
                          onChange={event => onChange(event.target.value)}
                        />
                        <EditSaveButtonContainer>
                          <EditSaveButton
                            className="saveBtn"
                            disabled={!isNameChange || newName === ''}
                            onClick={() => saveNewName()}
                          >
                            {edit_profile_save_txt}
                          </EditSaveButton>
                          <EditSaveButton
                            className="cancelBtn"
                            onClick={() => setEditId('')}
                          >
                            {loading_txt_cancel}
                          </EditSaveButton>
                        </EditSaveButtonContainer>
                      </BodyRowMiddle>
                    </BodyRowStyle>
                  ) : (
                    <BodyRowStyle>
                      <BodyRowLeft>
                        <BodyRowLeftText>{login_field_name}</BodyRowLeftText>
                      </BodyRowLeft>
                      <BodyRowMiddle>
                        <BodyRowLeftText className="name">
                          {newName}
                        </BodyRowLeftText>
                      </BodyRowMiddle>
                      <BodyRowRight>
                        <EditSaveButton onClick={() => setEditId('name')}>
                          {edit_profile_edit_txt}
                        </EditSaveButton>
                      </BodyRowRight>
                    </BodyRowStyle>
                  )}
                </Fragment>
              )}
            </ModalBody>
          </ModalContentContainer>
        </Fragment>
      ) : (
        <ModalBodyContainer isLoading={isLoading}>
          <LingLoading src={lingLoading} />
        </ModalBodyContainer>
      )}
    </Modal>
  );
};

export { EditProfileModal };
