import React from 'react';
import { IMAGE_PATH } from '../../../common/constants';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Awards = ({ labels }) => {
  const {
    txt_awards,
    txt_dsc_check_awards,
    txt_featured_on_app_store,
    txt_featured_on_play_store
  } = labels;
  const imgPath = image => `${IMAGE_PATH}/onboarding/${image}`;
  const theme = useTheme();
  const styles = {
    container: {
      w: 1 / 1,
      mt: 40,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    backgroundImg: {
      height: '704px',
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        height: 'auto',
        minHeight: '600px'
      }
    },
    headerText: {
      fontSize: '30px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '36px'
      }
    },
    contentContainer: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      width: '260px',
      height: '125px',
      justifyContent: 'space-between',
      mb: 8,
      [theme.breakpoints.up('sm')]: {
        mb: 15
      }
    },
    awardsContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-around',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row'
      }
    },
    awards: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      mt: 4
    },
    logo: {
      height: '48px',
      mb: 3
    },
    ratingText: {
      fontSize: '24px',
      fontWeight: 600
    },
    awardsImg: {
      width: '176px'
    },
    awardsTextBox: {
      width: '100px',
      textAlign: 'center',
      position: 'absolute',
      bottom: 13
    },
    awardsText: {
      fontSize: '14px',
      color: '#33333'
    }
  };
  return (
    <Box sx={styles.container}>
      <Box
        component="img"
        src={imgPath('wavy-background.png')}
        sx={styles.backgroundImg}
      />
      <Box sx={styles.contentContainer}>
        <Box sx={styles.textContainer}>
          <Typography variant="text" sx={styles.headerText}>
            {txt_awards}
          </Typography>
          <Typography variant="subtext">{txt_dsc_check_awards}</Typography>
        </Box>
        <Box sx={styles.awardsContainer}>
          <Box sx={styles.awards}>
            <Box
              component="img"
              src={imgPath('apple-logo.png')}
              sx={styles.logo}
            />
            <Typography variant="text" sx={styles.ratingText}>
              4.7
            </Typography>
            <Box component="img" src={imgPath('awards.png')} sx={styles.logo} />
            <Box sx={styles.awardsTextBox}>
              <Typography variant="subtext" sx={styles.awardsText}>
                {txt_featured_on_app_store}
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.awards}>
            <Box
              component="img"
              src={imgPath('playstore-logo.png')}
              sx={styles.logo}
            />
            <Typography variant="text" sx={styles.ratingText}>
              4.5
            </Typography>
            <Box component="img" src={imgPath('awards.png')} sx={styles.logo} />
            <Box sx={styles.awardsTextBox}>
              <Typography variant="subtext" sx={styles.awardsText}>
                {txt_featured_on_play_store}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Awards;
