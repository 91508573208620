/* @flow */
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { SpellingGameScreen } from '../main-components';
import { CheckButton } from '../sub-components';
import {
  DIALOGUE_GAME,
  BUTTON_DISABLED,
  BUTTON_CHECK_DISABLED,
  BUTTON_CONTINUE_DISABLED,
  BUTTON_SEND_DISABLED
} from '../../constants';

const DialogContentContainer = styled.div`
  width: 595px;
  padding-bottom: ${props => (props.isShowTutorial ? 115 : 150)}px;
  top: ${props =>
    props.isShowTutorial
      ? props.isCollape
        ? 46
        : 113
      : props.isCollape
      ? 136
      : 185}px;
  position: absolute;
  height: ${props => (props.isShowTutorial ? '50vh' : 'none')};
  overflow: ${props => (props.isShowTutorial ? 'scroll' : 'none')};
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

type Props = {
  translation: string,
  transcriptState: boolean,
  getCharacterCards: Function,
  getDialogueCards: Function,
  dialogSaveScrollFunc: Function,
  btn_hint: string,
  btn_check: string,
  btn_continue: string,
  error_txt_game_wrong: string,
  title_txt_complete: string,
  onStopSound: Function,
  spendHint: Function,
  soundFile: string,
  pressSpellingNextButton: Function,
  isResultShown: boolean,
  hintButtonEnabled: boolean,
  answeredWrong: boolean,
  rtlLanguage: boolean,
  nativeRtlLanguage: boolean,
  mode: string,
  onPressNext: Function,
  coins: number,
  voiceSpeed: number,
  isShowTutorial: boolean,
  isTargetLangSymbol: boolean,
  writing_skip: boolean,
  onCloseTutorialMotivate: Function,
  setShowTutorial: Function,
  isCollape: boolean
};

class DialogGame extends Component<Props> {
  static defaultProps: Props;
  props: Props;

  componentDidMount() {
    this.props.onStopSound();
    // $flow-disable-line
    document.addEventListener('keydown', this.handleEnter);
  }

  componentWillUnmount() {
    // $flow-disable-line
    document.removeEventListener('keydown', this.handleEnter);
  }
  handleEnter = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { pressSpellingNextButton, mode } = this.props;
    if (
      event.keyCode === 13 &&
      pressSpellingNextButton &&
      mode !== BUTTON_DISABLED &&
      mode !== BUTTON_CHECK_DISABLED &&
      mode !== BUTTON_CONTINUE_DISABLED &&
      mode !== BUTTON_SEND_DISABLED
    ) {
      pressSpellingNextButton();
    }
  };

  render() {
    const {
      translation,
      transcriptState,
      getCharacterCards,
      getDialogueCards,
      dialogSaveScrollFunc,
      btn_hint,
      btn_check,
      btn_continue,
      error_txt_game_wrong,
      title_txt_complete,
      rtlLanguage,
      nativeRtlLanguage,
      mode,
      pressSpellingNextButton,
      spendHint,
      coins,
      voiceSpeed,
      isShowTutorial,
      isTargetLangSymbol,
      writing_skip,
      onCloseTutorialMotivate,
      setShowTutorial,
      isCollape
    }: Object = this.props;
    return (
      <Fragment>
        <DialogContentContainer
          isCollape={isCollape}
          isShowTutorial={isShowTutorial}
        >
          <SpellingGameScreen
            redeem_modal_btn_home={''}
            spelling_error_txt_description={''}
            spelling_error_txt_title={''}
            history={{}}
            native={''}
            target={''}
            triggerLoadLanguageData={() => ({})}
            isTargetRightToLeftlLanguage={false}
            {...this.props}
            instruction={title_txt_complete}
            question={translation}
            transcript={transcriptState}
            topCards={getDialogueCards(this.props)}
            bottomCards={getCharacterCards(this.props, true)}
            cardAlign={'vertical'}
            isPlayable={false}
            isShowCorrect={false}
            scrollEnabled={false}
            saveScrollView={dialogSaveScrollFunc}
            btn_hint={btn_hint}
            btn_check={btn_check}
            btn_continue={btn_continue}
            error_txt_game_wrong={error_txt_game_wrong}
            gameType={''}
            rtlLanguage={rtlLanguage}
            nativeRtlLanguage={nativeRtlLanguage}
            isDialog={true}
            voiceSpeed={voiceSpeed}
            isShowTutorial={isShowTutorial}
            isTargetLangSymbol={isTargetLangSymbol}
          />
        </DialogContentContainer>
        <CheckButton
          {...this.props}
          quizAnswer={''}
          onPressNext={pressSpellingNextButton}
          gameType={DIALOGUE_GAME}
          mode={mode}
          btn_check={btn_check}
          btn_continue={btn_continue}
          btn_hint={btn_hint}
          diffButt={false}
          spendHint={spendHint}
          coins={coins}
          isShowTutorial={isShowTutorial}
          writing_skip={writing_skip}
          onCloseTutorialMotivate={onCloseTutorialMotivate}
          setShowTutorial={setShowTutorial}
        />
      </Fragment>
    );
  }
}
export { DialogGame };
