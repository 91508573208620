/* @flow */
import * as React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { Dialog } from '../components/scenes';
import { getDialogueCards, getCharacterCards } from '../util';
import { getSpelling } from '../reducers';
import {
  pressCharacterCard,
  pressSpellingNextButton,
  spendHint
} from '../actions';
import { getLabels } from '../../util';
import { getTranscriptionState, getUserCoins } from '../../profile/selectors';
import { getCurrentCourseData } from '../../lessons/selectors/DataSelectors';
import { getVoiceSpeed } from '../../chatbots/selectors';
const DialogContainer = (props: Object) => {
  return (
    <Dialog
      {...props}
      getDialogueCards={getDialogueCards}
      getCharacterCards={getCharacterCards}
    />
  );
};

export const mapStateToProps = (state: Object) => {
  const {
    dialog,
    courses: {
      nativeLanguageFeatures: { rtlLanguage: nativeRtlLanguage }
    },
    storage
  } = state;
  const { isShowTutorial, isTargetLangSymbol } = storage;
  const { features } = getCurrentCourseData(state);
  const coins = getUserCoins(state);
  const voiceSpeed = getVoiceSpeed(state);

  return {
    coins,
    dialog: { ...dialog },
    transcriptState: getTranscriptionState(state),
    translateState: dialog.translate,
    spelling: { ...getSpelling(state) },
    rtlLanguage: features.rtlLanguage,
    nativeRtlLanguage,
    isTargetLangSymbol,
    voiceSpeed,
    isShowTutorial,
    ...getLabels(
      state,
      'btn_hint',
      'btn_check',
      'btn_continue',
      'title_txt_complete',
      'error_txt_game_wrong',
      'dialog_btn_translate',
      'dialog_btn_stop',
      'writing_txt_replay',
      'dialog_txt_title',
      'writing_skip'
    )
  };
};

export const mapDispatchToProps = (dispatch: Function) => {
  return {
    pressCharacterCard: (charId: any) => {
      dispatch(pressCharacterCard(charId));
    },
    pressSpellingNextButton: () => {
      dispatch(pressSpellingNextButton());
    },
    spendHint: () => {
      dispatch(spendHint());
    },
    onCloseTutorialMotivate: () => {
      dispatch(actions.onCloseTutorialMotivate());
    },
    startGame() {
      dispatch(actions.dialogStartGame());
      dispatch(actions.stopDialogSound());
      dispatch(actions.dialogMessageDeactive());
    },
    toggleTutorialMotivate: () => {
      dispatch(actions.toggleTutorialMotivate());
      dispatch(actions.stopDialogSound());
    },
    triggerDialogAutoPlay() {
      dispatch(actions.playDialogSound(0, true));
    },
    onReplay() {
      dispatch(actions.dialogReplay());
      dispatch(actions.playDialogSound(0, true));
    },
    playDialogSound(soundId: string, id: any) {
      if (id !== null && id !== undefined) {
        dispatch(actions.dialogMessageActive(id));
      }
      dispatch(actions.stopDialogSound());
      dispatch(actions.playDialogSound(soundId));
    },
    onStopSound() {
      dispatch(actions.stopDialogSound());
      dispatch(actions.dialogMessageDeactive());
    },
    onTranslate() {
      dispatch(actions.dialogTranslate());
    },
    onPressNext(state: Object) {
      dispatch(actions.dialogCheckAnswer());
    },
    dismissNotify() {
      dispatch(actions.dialogDismissNotify());
    },
    // saveDialogView(view) {
    //   dispatch(actions.dialogSaveView(view));
    // },
    dialogSaveScrollFunc(func: any) {
      dispatch(actions.dialogSaveScrollFunction(func));
    },
    unmountDialog() {
      dispatch(actions.stopDialogSound());
    },
    setShowTutorial(value: boolean) {
      dispatch(actions.setShowTutorial(value));
    }
  };
};

export { DialogContainer };
export default connect(mapStateToProps, mapDispatchToProps)(DialogContainer);
