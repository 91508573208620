/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Box, Slider, Typography } from '@mui/material';
import moment from 'moment-timezone';
import { useTheme } from '@mui/material/styles';

const marks = [
  { value: 1, label: 1 },
  { value: 6, label: '' },
  { value: 12, label: 12 },
  { value: 18, label: '' },
  { value: 24, label: 24 }
];

export const TimeSlider = ({ setSelected, time, setTime, valueText }) => {
  const theme = useTheme();
  const styles = {
    container: { width: '100%', display: 'flex', flexDirection: 'column' },
    slider: {
      color: '#4094ED',
      height: 6,
      padding: '15px 0',
      '.MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#4094ED'
      },
      '& .MuiSlider-rail': {
        opacity: 0.5,
        backgroundColor: '#F1F1F1',
        width: '100%'
      },
      '& .MuiSlider-markLabel': {
        fontFamily: 'Noto Sans',
        fontSize: '12px',
        fontWeight: 400,
        color: '#999999',
        mt: 2.5,
        [theme.breakpoints.up('sm')]: {
          marginLeft: 0
        }
      },
      '& .MuiSlider-mark': {
        color: '#C9C9B2',
        height: 8,
        marginTop: 8,
        top: 0,
        width: '1px'
      }
    },
    headerText: {
      fontWeight: '700',
      fontSize: '16px',
      color: '#111111',
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px'
      }
    },
    textContainer: {
      my: 4
    }
  };

  // calculates time zone
  // Detect the current time zone
  const userTimeZone = moment.tz.guess();
  // Get the UTC offset in hours and minutes
  const utcOffset = moment.tz(userTimeZone).format('Z');
  const timeZoneInfo = `(GMT${utcOffset}) time in ${userTimeZone}`;

  const handleChange = (event, value) => setTime(value);

  useEffect(() => {
    setSelected([valueText(time[0]), valueText(time[1])]);
  }, [time]);

  return (
    <Box sx={styles.container}>
      <Typography variant="subtext" sx={styles.headerText}>
        {valueText(time[0])} - {valueText(time[1])}
      </Typography>
      <Box sx={styles.textContainer}>
        <Typography variant="deactivatedText">{timeZoneInfo}</Typography>
      </Box>
      <Slider
        value={time}
        onChange={handleChange}
        aria-labelledby="time-slider"
        getAriaValueText={valueText}
        color="secondary"
        min={1}
        max={24}
        marks={marks}
        sx={[styles.slider]}
      />
    </Box>
  );
};

export default TimeSlider;
