export const BANANAS = 'bananas';
export const STARS = 'stars';
export const COINS = 'coins';
export const CURRENT_STREAK = 'currentStreak';
export const SPENDER = 'coinsSpent';
export const LAST_DAY_PLAYED = 'lastDayPlayed';
export const LONGEST_STREAK = 'longestStreak';
export const IS_SUBSCRIBED = 'subscribed';
export const CURRENT_UNIT_ID = 'currentUnitId';
export const LAST_USER_UNIT_ID = 'lastUserUnitId';
export const CROWNS = 'crowns';
export const PROGRESS = 'progress';
export const TRANSCRIPTION = 'transcription';
export const PROFILE_PROPERTIES = [
  BANANAS,
  COINS,
  CURRENT_STREAK,
  LAST_DAY_PLAYED,
  LONGEST_STREAK,
  IS_SUBSCRIBED,
  CURRENT_UNIT_ID,
  LAST_USER_UNIT_ID,
  CROWNS,
  PROGRESS,
  TRANSCRIPTION
];
export const DEFAULT_WEEKLY_GRAPH_DATA = [0, 0, 0, 0, 0, 0, 0];
