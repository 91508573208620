/* @flow */
import React, { Fragment } from 'react';
import styled from 'styled-components';

import {
  PREFIX_HEADER,
  LIGHT_GREY_SHADOW,
  GREY,
  DARK_GREY
} from '../../../common/constants';
import { DIALOGUE, FLASHCARD } from '../../constants';
import ReportModal from './ReportModal/ReportModal';

const ScoreSection = styled.div`
  align-self: right;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  font-weight: bold;
  margin-left: 12px;
  margin-right: 18px;
  &:last-child {
    margin-right: 0px;
  }
`;

const SquareIconContainer = styled.div`
  margin-right: 20px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  &.headButton {
    display: flex;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 10px;
    box-shadow: inset 0 -4px 0 0 ${LIGHT_GREY_SHADOW};
    border: solid 1px ${GREY};
    &:last-child {
      margin-right: 0px;
    }
  }

  #flagIcon {
    position: relative;
  }
  #flagIconActive {
    display: none;
    position: absolute;
    z-index: 99;
  }
  &:hover {
    background: rgba(241, 241, 241, 0.4);
    box-shadow: none;
    border-color: rgba(241, 241, 241, 0.4);
  }
  &:active {
    background: ${DARK_GREY};
    #flagIconActive {
      display: inline !important;
    }
  }
`;

const LessonReviewIcon = styled.div`
  display: flex;
  align-items: center;
  max-height: 35px;
  margin-right: 20px;
  &p {
    font-size: 14px;
  }
`;

const SquareIcon = styled.img`
  width: 45px;
  height: 45px;
  margin-right: ${props => (props.isGameHeader ? 20 : 10)}px;
  &.yellow-banana {
    height: 45px;
  }
  &.headButtonIcon {
    width: 35px;
    height: 35px;
  }
  &.flagIcon {
    width: 24px;
    margin-right: 0;
  }
  &.transcriptIcon {
    margin-right: 0;
    width: 28px;
    height: max-content;
  }
`;

const ScoreNumber = styled.p`
  margin-bottom: 0;
`;

const ReviewTranscript = styled.div`
  margin-top: -5px;
`;

type State = {
  isReportError: boolean
};
type Props = {
  transcript: boolean,
  onShowReportError: Function,
  isWriting: boolean,
  hasTranscript: boolean,
  bananas: number,
  coins: number,
  onActive: Function,
  toggleTranscript: Function,
  isGameHeader: boolean,
  isExam: boolean,
  isReview: boolean,
  screen: string,
  faq: Array<Object>,
  btn_continue: string,
  modal_error_other: string,
  modal_error_answer_should_be_accepted: string,
  modal_error_answer_duplicate: string,
  modal_error_image_missing: string,
  modal_error_audio_not_match: string,
  modal_error_audio_missing: string,
  modal_error_audio_not_clear: string,
  modal_error_report_txt: string,
  modal_error_report: string,
  modal_error_faq: string
};

class GameScore extends React.Component<Props, State> {
  constructor(props: Object) {
    super(props);
    this.state = {
      isReportError: false
    };
  }
  toggleReportModal = () => {
    this.setState(prevState => ({
      isReportError: !prevState.isReportError
    }));
  };
  render() {
    const {
      transcript = true,
      hasTranscript,
      bananas = 0,
      coins = 5,
      toggleTranscript = () => {},
      isGameHeader,
      isExam = false,
      isReview,
      screen,
      faq,
      btn_continue,
      modal_error_other,
      modal_error_answer_should_be_accepted,
      modal_error_answer_duplicate,
      modal_error_image_missing,
      modal_error_audio_not_match,
      modal_error_audio_missing,
      modal_error_audio_not_clear,
      modal_error_report_txt,
      modal_error_report,
      modal_error_faq
    } = this.props;
    return (
      <Fragment>
        <ScoreSection className={screen}>
          {isGameHeader ? (
            !isExam && (
              <SquareIcon
                id="yellow-banana"
                isGameHeader={isGameHeader}
                src={`${PREFIX_HEADER}banana-squre-yellow.svg`}
              />
            )
          ) : (
            <LessonReviewIcon>
              <SquareIcon
                className="headButtonIcon"
                src={`${PREFIX_HEADER}banana-squre-black.svg`}
              />
              <ScoreNumber>{bananas}</ScoreNumber>
            </LessonReviewIcon>
          )}

          {isGameHeader ? null : (
            <LessonReviewIcon>
              <SquareIcon
                className="headButtonIcon"
                src={`${PREFIX_HEADER}star-squre-black.svg`}
              />
              <ScoreNumber>{coins}</ScoreNumber>
            </LessonReviewIcon>
          )}
          {isGameHeader ? (
            <SquareIconContainer
              className="headButton"
              onClick={this.toggleReportModal}
            >
              <SquareIcon
                id="flagIcon"
                className="flagIcon"
                src={`${PREFIX_HEADER}outline-flag-black.svg`}
              />
              <SquareIcon
                id="flagIconActive"
                className="flagIcon"
                src={`${PREFIX_HEADER}outline-flag-white.svg`}
              />
            </SquareIconContainer>
          ) : null}
          {(isReview && hasTranscript && screen === DIALOGUE) ||
          (hasTranscript && screen === FLASHCARD) ? (
            <ReviewTranscript>
              <SquareIconContainer
                className={'headButton'}
                onClick={toggleTranscript}
              >
                {transcript ? (
                  <SquareIcon
                    className="transcriptIcon"
                    src={`${PREFIX_HEADER}fillEnableTranslate21Px@2x.png`}
                  />
                ) : (
                  <SquareIcon
                    className="transcriptIcon"
                    src={`${PREFIX_HEADER}fillDisableTranslate21Px@2x.png`}
                  />
                )}
              </SquareIconContainer>
            </ReviewTranscript>
          ) : null}
          {hasTranscript ? (
            <SquareIconContainer
              className={'headButton'}
              onClick={toggleTranscript}
            >
              {transcript ? (
                <SquareIcon
                  className="transcriptIcon"
                  src={`${PREFIX_HEADER}fillEnableTranslate21Px@2x.png`}
                />
              ) : (
                <SquareIcon
                  className="transcriptIcon"
                  src={`${PREFIX_HEADER}fillDisableTranslate21Px@2x.png`}
                />
              )}
            </SquareIconContainer>
          ) : null}
        </ScoreSection>
        <ReportModal
          modal_error_other={modal_error_other}
          modal_error_answer_should_be_accepted={
            modal_error_answer_should_be_accepted
          }
          modal_error_answer_duplicate={modal_error_answer_duplicate}
          modal_error_image_missing={modal_error_image_missing}
          modal_error_audio_not_match={modal_error_audio_not_match}
          modal_error_audio_missing={modal_error_audio_missing}
          modal_error_audio_not_clear={modal_error_audio_not_clear}
          modal_error_report_txt={modal_error_report_txt}
          modal_error_report={modal_error_report}
          modal_error_faq={modal_error_faq}
          isOpen={this.state.isReportError}
          toggleReport={this.toggleReportModal}
          faq={faq}
          btn_continue={btn_continue}
        />
      </Fragment>
    );
  }
}
export { GameScore };
